<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <!-- ContenidoBase-Zona-->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Zonas de Clientes'" :descripcion="'Crear, Modificar y Desactivar Zonas de Clientes'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
           />
      </tr>
      <!-- fn ContenidoBase-Zona -->
      <tr>
        <!-- BarraPrimaria-Zona  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>

                </center>


                <BarraDinamicaNormal
              :nombre="'Zonas de Clientes'"
              @EmitDAta="datostabla"
              @EmitBuscar="filtro"
              :SeachNombre="'Búsqueda general'"
              :list="list"

              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            />

              </template>
            </v-tooltip>
            
          </div>
        </td>
        <!-- fn BarraPrimaria-Zona  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
           <!-- Formulario-Zona  -->
            <div class="px-2">

              <v-card color="grey lighten-3">

                <div class="px-2">

                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" lg="12" md="8" sm="6">
                        <v-text-field v-model="zonas.descripcion" :rules="[$rules.required]"
                          autocomplete="off" label="Descripcion" required outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="edit" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="12" md="12" sm="12">
                        <v-switch label="Activo" v-model="zonas.activado"></v-switch>
                      </v-col>
                    </v-row>

                  </v-form>


                </div>
              </v-card>
            </div>
            <!-- fn Formulario-Zona  -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <!-- <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>  -->

          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Descripcion', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
     <!-- ------------------------------------------------------ fn tb-data todo general -->
   <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   <!-- ------------------------------------------------------ Modal-notificacion general -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>
<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormalPlus.vue";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";

export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico


  },
  created() {
    var json = {
      titulo: "",
      descripcion: "",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CXCTiposClientesNew",
        "CXCTiposClientesSave",
        "CXCTiposClientesRemove",
        "CXCTiposClientesAnular",
        "CXCTiposClientesPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXCTiposClientesNew", this.new);
    // EventBus.$on("CXCTiposClientesSave", this.save);
    // EventBus.$on("CXCTiposClientesRemove", this.remove);
    // EventBus.$on("CXCTiposClientesAnular", this.remove);
    // EventBus.$on("CXCTiposClientesPrintList", this.imprimirList);
    EventBus.$on("emitCXCTiposClientes", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({


    dialogimprimirList: false,
    datosImprecion: {
      nombre: 'TIPOS CLIENTES',
      UrlImprimir: '/zona/Imprimir',
      UrlExel: '/zona/Excel',
      UrlCorreo: '/zona/Correo',
      secuencia: 1,
      documento: 0
    },

    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    zonas: {
      activado: true,
      borrado: false,
      codigo: 0,
      descripcion: "",
      fechaCreacion: null,
      fechaModificacion:null,
      usuarioCreacion: "",
      usuarioModifico: "",
    },
    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    files: [],
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipos Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    formData: null,
    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {
      //this.windowSize = e
    },

  // Abriendo una panel donde mustra los datos en una tabla.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {

        this.filtro()
        // EventBus.$emit("actualizaBarraDinaicaLim");


      }

    },

    // Un método que se llama cuando el usuario hace clic en una fila de la tabla.
    datostabla(e) {
      this.zonas = JSON.parse(JSON.stringify(e));
      this.acti_tb_data = false
    },
   // Envío de una solicitud POST al servidor.
    save() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("loading", true);
        if (this.zonas.codigo > 0) {
            this.zonas.fechaModificacion= new Date()
            this.zonas.usuarioModifico= this.$store.getters.GetdatosInfUsu.usuario.username
           }else{
            this.zonas.fechaCreacion= new Date()
           this.zonas.usuarioCreacion= this.$store.getters.GetdatosInfUsu.usuario.username
           }

        this.$axios
          .post(this.$hostname + this.$hName + "/zona/salvar/", this.zonas, { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.alerta("Dato guardado correctamente!", "bien")})
          .catch(error => this.alerta("Dato no guardado correctamente", "error"));
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
    remove() {
      if (this.zonas.codigo != 0 && this.zonas.codigo != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else {
        this.alerta("Seleccione un tipos de cliente para eliminar", "error")
      }


    },
    // Envío de una solicitud de publicación al servidor.
    eliminar() {
      if (this.zonas.codigo != 0) {
        EventBus.$emit("loading", true);
        this.$axios
          .post(this.$hostname + this.$hName + "/zona/borrar/", this.zonas, { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.alerta("Dato eliminado correctamente!", "bien");
          })
          .catch(error =>
            this.alerta("El tipo de cliente que está intentando borrar lo está usando algún cliente", "error"));
      }

    },
    anular() {
      if (this.Elim_notif.estado) {

        this.$axios
          .post(this.$hostname + this.$hName + "/zona/borrar/", this.zonas, { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.alerta("Dato eliminado correctamente!", "bien") })
          .catch(error => this.alerta("Dato no eliminado correctamente", "error"));
      }
    },
  // Un método que se llama cuando el usuario hace clic en un botón y 
  //activa un modal con la opcion de impreciones.
    imprimirList() {
      
      // this.dialogimprimirList = true
      EventBus.$emit("loading", true);

      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/zona/imprimir/", { headers: headers }).then(res => {
        EventBus.$emit("loading", false);
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/
          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'Zonas');

      })
      .catch(error =>{EventBus.$emit("loading", false); });


    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },


    // Creando un nuevo objeto y asignándolo a la variable zonas.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.zonas= {
      activado: true,
      borrado: false,
      codigo: 0,
      descripcion: "",
      fechaCreacion: null,
      fechaModificacion:null,
      usuarioCreacion: "",
      usuarioModifico: "",
    },
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

  // Hacer una llamada API al backend y obtener los datos de la base de datos.
    async filtro() {


      await this.$axios
        .get(this.$hostname + this.$hName + "/zona/findall", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });

    }
  },


  computed: {

    ActDescBtn() {


      if (this.zonas.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }


      if (this.zonas.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }


      this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))   
    }
  }


};
</script>

<style>

</style>
