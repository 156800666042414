<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
           :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">

        <ContenidoBase
            :titulo="'Reporte de Cuadres de Cajas'"
            :descripcion="'Imprime los Cuadres de Cajas de los Puntos de Venta'"
            :EstadoBtn="EstadoBtn"
            @abrirBarraTablaDinamica="abrirTabla"
            @MethodNuevo="newwnuevo"
            @MethodGuardar="save"
            @MethodEliminar="remove"
            @MethodAnular="anular"
            @MethodImprimir="imprimirList"

        />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                        small
                        color="yellow accent-4"
                        @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn
                    >
                    <v-btn
                        small
                        dark
                        color="deep-purple accent-2"
                        @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn
                    >
                  </v-btn-toggle>
                </center>
                <!--                <BarraBusqueda2-->
                <!--                    :url="-->
                <!--                $hostname + $hName + '/services/tipocliente/findalltipoclientes'-->
                <!--              "-->
                <!--                    :nombre="'Tipo de Clientes'"-->
                <!--                    :headers="headers"-->
                <!--                    :slotname="'item.descripcion'"-->
                <!--                    :emitnombre="'emitReportesCuadresCajas'"-->
                <!--                    :SeachNombre="'Búsqueda por descripcion '"-->
                <!--                    :slotlist="slotlist"-->
                <!--                />-->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
<!-- Formulario-Reporte de Cuadres de Cajas -->

            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar
                    flat
                    color="#BDBDBD"
                    dense
                    style="border: 1px solid #000000;"
                >


                  <v-avatar tile size="45">
                    <img
                        src="@/assets/iconos_micromarket/custom-reports.png"
                        alt="Reporte"
                    >
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;">Cuadres de Cajas</span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="cerrarmodalcuenta"
                  >
                   <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox
                            autocomplete="off"
                            dense
                            outlined
                            label="Sucursal:"
                            :items="RSucursal"
                            item-text="descripcion"
                            v-model="CuadresCajas.sucursal"
                            @focus="cargarSucursal"
                            @blur="SelctCbSucursal"

                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>

                        <v-switch
v-if="$store.getters.GetdatosInfUsu.usuario.dsc"
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="DSC:"
              v-model="CuadresCajas.dsc"
            >
          <template v-slot:label>
         <strong v-if="CuadresCajas.dsc==true" style="color:#000000;">DSC: Si</strong>
         <strong v-if="CuadresCajas.dsc==false" style="color:#000000;">DSC: No</strong>
        </template>
            
            
            </v-switch>
                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">


                        <!------------------------------------------- caja-->

                        <v-combobox
                            v-model="CuadresCajas.caja"
                            label="caja"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RCaja"
                            item-text="descripcion"
                            @blur="SelctCbCaja"
                            @focus="cargarCaja"

                        >
                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-cube"
                            ></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn columna 2 -->


                      <!-- Columna 3 -->
                      <v-col cols="12" md="12" sm="12">

                        <v-card>

                          <v-toolbar
                              flat
                              dense
                              color="#BDBDBD"
                              class="d-flex justify-center"
                          >
                            <span>CIERRES DE CAJA</span>
                          </v-toolbar>
                          <v-data-table
                              class="elevation-1"
                              dense
                              :show-select="true"
                              :single-select="true"
                              v-model="cierreSelect"
                              :headers="[
                                  { text: 'Num.cierre', value: 'cierrescajasPK.secuencia',width:'auto'},
                                  { text: 'Fecha cierre', value: 'fecha',width:'auto'},
                                  { text: 'Cajero', value: 'cajero',width:'auto'},
                                  { text: 'ventas', value: 'efectivo',width:'auto'}
                                ]"
                              :items="RCierres"
                              :items-per-page="TbPaginator.itemsPerPage"
                              :page="TbPaginator.page"
                              :pageCount="TbPaginator.pageCount"
                              @item-selected="datostablaSelect"
                              @click:row="datostabla"
                              item-key="indx"
                              @pagination="cargarPaginacionAll"
                              :server-items-length="TbTotalItem"
                          >


                            <template v-slot:item.efectivo="props">
                              <span style="display:none;">{{props.item.indx = RCierres.indexOf(props.item)}}</span>
                              <span>{{ currencyFormatter(props.item.efectivo) }}</span>
                            </template>

                            <template v-slot:item.fecha="props">
                              <span>{{ FormatoFecha(props.item.fecha) }}</span>
                            </template>

                            <template v-slot:body.append v-if="windowSize.width >600">
                            <tr>
                              <td class="border-top-bottom"><strong></strong></td>
                              <td class="border-top-bottom"><strong></strong></td>
                              <td class="border-top-bottom"><strong></strong></td>
                              <td class="border-top-bottom"><strong class="d-flex justify-end"
                                                                    style="font-size:15px; color: #00838F;">Total:</strong></td>
                              <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                                {{ currencyFormatter(totalVentas) }} </strong></td>
                            </tr>
</template>

<template v-slot:footer v-if="windowSize.width<=600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>CANTIDAD PEDIDA:5</span>
      <span>CANTIDAD EXISTENCIA:5</span>
      <span>VENTAS:5</span>
      <span>COSTO:5</span>
      <span>IMPORTE:5</span>

    </v-list-item-content>
  </v-list-item>

 </template>

</v-data-table>
</v-card>

</v-col>


<!-- fn Columna 3 -->


</v-row>


</v-form>
</v-container>

<hr>
<ModalReporteFiltros
    @DataReporteBasico="DDataReporteBasico"
    :Datos="CuadresCajas"
/>

</v-card>
</v-container>
<!-- Formulario-Reporte de Cuadres de Cajas -->
</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

<div class="overflow-y-auto "
     v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

  <!--
          <BarraBusqueda2
                :url="$hostname + $hName + '/services/caja/findAll'"
                :nombre="'Cajas'"
                :headers="headers"
                :slotname="'item.descripcion'"
                :emitnombre="'emitCaja'"
                :slotlist="slotlist"
                :SeachNombre="'Búsqueda por descripción caja'"
              /> -->
</div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog
    label="myAlert"
    v-model="acti_tb_data"
    persistent
    max-width="100%"
    max-height="100%"
    transition="fab-transition"
>
<v-card>
  <v-toolbar flat color="#BDBDBD">
    <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
    <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
    <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

    <v-spacer></v-spacer>
    <v-btn
        color="grey darken-4"
        fab
        small
        @click="acti_tb_data = !acti_tb_data"
    >
      <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
    </v-btn>
  </v-toolbar>

  <v-card-text>
    <v-card>
      <v-card-title>
        <v-text-field
            v-model="search"
            @keyup="filtro"
            label="Buscar"
            single-line
            hide-details
        >
          <template v-slot:prepend-inner>
            <i style="font-size: 35px;" class="fa fa-search"></i>
          </template>
        </v-text-field>
      </v-card-title>

      <v-data-table
          :headers="slotHtabla"
          dense
          :items="list"
          :search="search"
          @click:row="datostabla"
      >
        <template v-slot:item.porciento="props">
          <span>{{ currencyFormatter(props.item.porciento) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-card-text>
</v-card>
</v-dialog>
<!-- ------------------------------------------------------ Elim_notif-->
<v-dialog
    label="myAlert"
    v-model="Elim_notif.estado"
    persistent
    max-width="350"
    transition="fab-transition"
>
<template justify="center" v-slot:activator="{ on }"></template>

<v-card>
  <v-card-title>
    <h4>
      <i style="font-size: 28px;" class="fa fa-warning"></i>
      CONFIRMACION
      <i style="font-size: 28px;" class="fa fa-warning"></i>
    </h4>
  </v-card-title>
  <hr/>

  <v-card-text>
    <h5>{{ Elim_notif.nombre }}</h5>
  </v-card-text>

  <hr/>
  <v-card-actions>
    <v-btn
        @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
        small
    >
      <i style="font-size: 20px;" class="fa fa-check"></i> Si
    </v-btn>

    <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
      <i style="font-size: 20px;" class="fa fa-ban"></i> No
    </v-btn>
  </v-card-actions>
</v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Elim_notif -->

<!------------otras funciones---------------->
<v-snackbar
    v-model="aalert.estado"
    :color="aalert.color"
    style="margin-top: 20%;"
    :vertical="true"
    :timeout="120000"
    top="top"
>
{{ aalert.nombre }}
<v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>
</template>

<script>
import {EventBus} from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import {currencyFormatter, FormatoFecha, HoraFormatter} from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {
    var json = {
      titulo: "Reporte de Cuadres de Cajas",
      descripcion: "Imprime los Cuadres de Cajas de los Puntos de Venta",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ReportesCuadresCajasNew",
        "ReportesCuadresCajasSave",
        "ReportesCuadresCajasRemove",
        "ReportesCuadresCajasAnular",
        "ReportesCuadresCajasPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReportesCuadresCajasNew", this.new);
    // EventBus.$on("ReportesCuadresCajasSave", this.save);
    // EventBus.$on("ReportesCuadresCajasRemove", this.remove);
    // EventBus.$on("ReportesCuadresCajasAnular", this.remove);
    // EventBus.$on("ReportesCuadresCajasPrintList", this.imprimirList);
    EventBus.$on("emitReportesCuadresCajas", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({
    ListCaja: [],
    Rpropietario: [],
    RSucursal: [],
    RCierres: [],
    RCaja: [],
    ListaTiposClientes: [],
    windowSize: {width: "auto", height: "auto"},

TbTotalItem:0,
TbPaginator: {
        page:1,
        itemsPerPage: 10,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },



    CuadresCajas: {
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      caja: null,
      sucursal: null,
      numero_cierre: null,
      nombre: 'REPORTE DE CUADRES DE CAJA',
      UrlImprimir: '/reportes-pv/ImprimirListReporteCuadresCajas',
      UrlExel: '/reportes-pv/ExcelListReporteCuadresCajas',
      UrlCorreo: '/reportes-pv/CorreoListReporteCuadresCajas',
      dsc:false
    },
    totalVentas: 0,
    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },
    cierreSelect:[],
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{text: "Tipo Clientes", value: "descripcion"}],

    slotlist: [
      {dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:"},
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      {dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo"},
    ],

    slotHtabla: [
      {text: "Código", value: "codigo"},
      {text: "Descripcion", value: "descripcion"}
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,
    onResize(e) {
      this.windowSize = e
    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    async cargarTodo() {
    },
  // Un método que se llama cuando el usuario selecciona un elemento del menú desplegable.
    datostablaSelect(e){
        this.datostabla(e.item)
    },
    // Empujando la fila seleccionada en una matriz.
    datostabla(e) {
      this.cierreSelect=[]
      this.acti_tb_data = false
      this.cierreSelect.push(e) 
      this.CuadresCajas.numero_cierre = e.cierrescajasPK.secuencia
    },
    save() {
    },
    remove() {
      if (this.CuadresCajas.codigo != 0 && this.CuadresCajas.codigo != null) {
        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else {
        this.alerta("Seleccione un tipo de cliente para eliminar", "error")
      }
    },
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
    },
    // Llamar a una API y obtener los datos de la API.
    cargarCaja() {
      let headers = this.$store.getters.GetheadersAxios
      let idSucursal = this.CuadresCajas.sucursal.codigo
      this.$axios.get(this.$hostname + this.$hName + '/cajas/find/sucursal/' + idSucursal, {headers})
          .then(res => (this.RCaja = res.data))
    },

   // Llamar a una API y obtener los datos de la API.
    cargarSucursal() {
      let headers = this.$store.getters.GetheadersAxios
      this.$axios
          .get(this.$hostname + this.$hName + '/sucursal/findallActivos/', {headers})
          .then(res => (this.RSucursal = res.data))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {

      if (this.CuadresCajas.sucursal == null) {

        this.CuadresCajas.sucursal = ''
      }

      if (this.CuadresCajas.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.CuadresCajas.sucursal = ''
        return

      }

      this.cargarCaja()
    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCaja() {

      if (this.CuadresCajas.caja == null) {
        this.CuadresCajas.caja = ''
      }

      if (this.CuadresCajas.caja.codigo == undefined) {

        this.alerta("Seleccione un caja", "error");
        this.CuadresCajas.caja = ''
        return

      } else {
        this.cargarCierresCaja()
      }


    },


cargarPaginacionAll(e) {
if(e.itemsPerPage==-1){e.itemsPerPage=15}
this.TbPaginator= JSON.parse(JSON.stringify(e))

this.cargarCierresCaja()

    },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {

    },
    DDataReporteBasico() {
    },
    // Hacer una solicitud al servidor para obtener los datos.
    cargarCierresCaja() {

if(this.CuadresCajas.sucursal!=null && this.CuadresCajas.caja!=null ){

      let codigoSucursal = this.CuadresCajas.sucursal.codigo
      let codigoCaja = this.CuadresCajas.caja.codigo
      let headers = this.$store.getters.GetheadersAxios

var obj=this.TbPaginator.itemsPerPage+"/"+this.TbPaginator.pageStart+"/"+null+"/"+codigoSucursal+"/"+codigoCaja+"/"+null+"/"+null+"/"+null
this.$axios.get(this.$hostname+this.$hName+'/cierrecaja/findListCierrecajaPlus/'+obj ,{headers:this.$store.getters.GetheadersAxios})
.then(res=>{ 
  this.RCierres=[],this.totalVentas=0,
this.RCierres=res.data.ListItems, this.TbTotalItem=res.data.TbTotalItem,
this.RCierres.forEach((cierre) => {
              this.totalVentas += cierre.efectivo
            })
          })
}

      // this.$axios
      //     .get(
      //         this.$hostname +
      //         this.$hName +
      //         `/cierrecaja/findall/${Limite}/${Ultim}/${codigoSucursal}/${codigoCaja}`,
      //         {headers}
      //     )
      //     .then(res => {
      //       this.RCierres = res.data
      //       this.RCierres.forEach((cierre) => {
      //         this.totalVentas += cierre.efectivo
      //       })
      //     })
    }
  }
};
</script>

<style></style>
