<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
    <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte Existencias y Ventas por Proveedor'"
  :descripcion="'Imprime Existencias y Ventas por Proveedor'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
    
    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReporteExistenciasVentas'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
    
    
<span class="font-weight-light" style="font-size: 20px;" > Impresion de Existencias y Ventas por Proveedor </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
   

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="ExistenciasVentas.desde"
        @blur="BlurDesde">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<!-------------------------------------------------- proveedor :rules="[$rules.required]" -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            
            label=" * Proveedor:"
            :items="Rproveedor"
            item-text="nombre"
            v-model="ExistenciasVentas.provee"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            :rules="[$rules.required]"
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>                                 
</v-combobox>



</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="ExistenciasVentas.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>    
                <!-- :rules="[$rules.required]" -->
                <v-combobox v-model="ExistenciasVentas.almacen" label=" * Almacén"
                
                          outlined dense autocomplete="off" :items="Ralmacen" item-text="descripcion"
                          @blur="SelctCbAlmacen" @focus="CargarRalmacen">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                          </template>
                        </v-combobox>          

                        


</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="ExistenciasVentas"
/>

  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
 
  
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() { 
    var json = { 
      titulo: "Reporte Depósito Facturas al Cobro",
      descripcion: "Imprime las Facturas Pendientes por Cobrar de Clientes.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false
     
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);

    // EventBus.$on("emitReporteExistenciasVentas", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
  },

  data:()=>  ({


      Rproveedor:[],
      Ralmacen:[],
      windowSize:{width:"auto", height:"auto"},
      ExistenciasVentas: {
        nombre:' Existencias y Ventas por Proveedor',
        UrlImprimir: '/reportesCXP/ImprimirExistenciasVentasProveedor', 
        UrlExel: '/reportesCXP/ExcelExistenciasVentasProveedor', 
        UrlCorreo: '/reportesCXP/CorreoExistenciasVentasProveedor', 
        desde: fechaNueva(),
        hasta:fechaNueva(),
        provee:null,
        almacen:null
        
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
     

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
onResize(e){
//this.windowSize=e
},

validarObjecto() {

if (!this.$refs.form.validate()) {
  this.$refs.ModRepFilt.detener = true;
  return;
} else {
  this.$refs.ModRepFilt.detener = false;
}

//this.ExistenciasVentas.where = "f.fecha >='" + this.ExistenciasVentas.desde + "' and f.fecha<='" + this.ExistenciasVentas.hasta + "' ";

var where=" p.codigo > 0 and p.activo=true "

if(this.ExistenciasVentas.provee!=null){
  where+=" and pro.codigo="+this.ExistenciasVentas.provee.codigo
  this.ExistenciasVentas.proveedor=this.ExistenciasVentas.provee.nombre
}
var codalm=0
if(this.ExistenciasVentas.almacen!=null){
  where+=" and b.almacen = "+this.ExistenciasVentas.almacen.codigo
  codalm=this.ExistenciasVentas.almacen.codigo
}

this.ExistenciasVentas.where=where
this.ExistenciasVentas.codalm=codalm

},


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {},
    save() {},
    remove() {},
    eliminar() {},
    anular() { },
    imprimirList() {},
    DDataReporteBasico(){},
    newwnuevo() {
      (this.ExistenciasVentas = {
        desde:fechaNueva(),
        hasta:fechaNueva(),
        proveedor:null,
        almacen:null
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


 BlurDesde(){

if(this.ExistenciasVentas.desde.toString().length==0){
  this.ExistenciasVentas.desde=this.fechaNueva()
}


 },


 BlurHasta(){

if(this.ExistenciasVentas.hasta.toString().length==0){
  this.ExistenciasVentas.hasta=this.fechaNueva()
}

 },

 cargarProveedor(){
this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))
},

SelctCbProveedor(){


  var _this = this;
      setTimeout(function () {
        if (_this.ExistenciasVentas.provee == null) {
          _this.ExistenciasVentas.provee= "";
        }

        if (!_this.ExistenciasVentas.provee.hasOwnProperty('codigo')) {
          _this.ExistenciasVentas.provee = "";
          _this.ExistenciasVentas.provee = null;
          return;
        }
      }, 300);

},

CbFilProveedor(e) {
     

if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
    },


    CargarRalmacen() {

this.$axios
  .get(this.$hostname + this.$hName + "/almacen/activos",
    { headers: this.$store.getters.GetheadersAxios }
  )
  .then(res => {
    this.Ralmacen = res.data;
  });


},
    
    
SelctCbAlmacen() {
  var _this = this;
      setTimeout(function () {
        if (_this.ExistenciasVentas.almacen == null) {
          _this.ExistenciasVentas.almacen= "";
        }

        if (!_this.ExistenciasVentas.almacen.hasOwnProperty('codigo')) {
          _this.ExistenciasVentas.almacen = "";
          _this.ExistenciasVentas.almacen = null;
          return;
        }
      }, 300);
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


  
 
  },

  computed:{

ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
//EventBus.$emit("ActDesaBotones", objjct);
}
}
};
</script>

<style></style>
