<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Inventario Físico'" :descripcion="'Inventario Físico.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodCargarProducto="CargarProducto" @MethodImprimirproductoInv="ImprimirproductoInv" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>



                  </v-btn-toggle>
                </center>
                <!-- <v-navigation-drawer >  -->

                <!-- [
              { text: 'Código', value: 'secuencia' },
              { text: 'cliente', value: 'cliente.nombre' },
              { text: 'valor', value: 'valor' },
              { text: 'concepto', value: 'comentario' },
              ]
              [
              {Text:'Secuencia',NomValue:'secuencia', Ctsl:1, Type:0, State:true},
              {Text:'Sucursal',NomValue:'sucursal', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
              {Text:'fecha', NomValue:'fecha',  Ctsl:1, Type:1, State:true},
              {Text:'concepto', NomValue:'comentario',  Ctsl:1, Type:0, State:true},
              {Text:'Valor Libro', NomValue:'valorLibro',  Ctsl:1, Type:2, State:true},
              {Text:'Valor Fisico', NomValue:'valorInv',  Ctsl:1, Type:2, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ] -->



                <BarraDinamicaLimitePlus :PropsNombre="'Inventario fisico'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Código', NomValue: 'secuencia', Ctsl: 1, Type: 0, State: true },
                  { Text: 'Sucursal', NomValue: 'sucursal', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
                  { Text: 'Fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
                  { Text: 'valor fisico $', NomValue: 'valorfisico', Ctsl: 1, Type: 2, State: true },
                  { Text: 'valor libro $', NomValue: 'valorlibro', Ctsl: 1, Type: 2, State: true },
                  { Text: 'Inventario abierto', Text2: 'Inventario Cerrado', NomValue: 'activo', Ctsl: 1, Type: 0.4, State: true },
                  ]" />





                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">

              <v-card color="grey lighten-3" >
                <!-- <v-container> -->


                  <v-form ref="formInfFisico" v-model="valid" lazy-validation>
                    <input type="number" v-model="InventarioFisico.codigo" style="display:none;" />
                    <v-row class="px-2">
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="3" sm="3">
                        <!----------------------------------------------  Fecha orden -->
                        <v-text-field dense outlined type="date" label="* Fecha:" :rules="[rules.required]"
                          v-model="InventarioFisico.fecha" style="font-size:12px;">

                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <v-col cols="12" md="5" sm="5">
                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="* Succursal:" :items="RSucursal"
                          item-text="descripcion" v-model="InventarioFisico.sucursal" @blur="SelctCbSucursal"
                          @focus="cargarSucursal" style="font-size:12px;" :rules="[rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="4" sm="4">
                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="* Comentario:" rows="1" dense outlined
                          v-model="InventarioFisico.comentario" :rules="[rules.required]" style="font-size:12px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting-o"></i>
                          </template>
                        </v-textarea>

                      </v-col>
                      <!--  fn columna 2 ------------------------------>

                    </v-row>
                  </v-form>
                <!-- </v-container> -->

                <!--------------------------------------------------- tb add producot  -->
                <v-col cols="12" md="12" sm="12" style="margin-top:-45px;">

                  <div v-if="InventarioFisico.secuencia == 0">
                    <TablaProductoInventario :ListProd="ListProd" :EntradaAlmacen="almacen" :PropsUnidadminima="true"
                      :PropsInventarioFisico="true" @DataTotalList="DDataTotalList" />
                  </div>
                  <div style="border: 1px solid #000000;" v-if="InventarioFisico.secuencia > 0">


                    <v-card>

                      <v-toolbar flat color="#BDBDBD" dense>
                        <v-toolbar-title class="font-weight-light">
                          <v-card-title>DETALLE INVENTARIO FíSICO</v-card-title>
                        </v-toolbar-title>
                      </v-toolbar>

                      <v-data-table :headers="[
                        { text: 'CÓDIGO', sortable: false, value: 'producto.codigo', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'PRODUCTO', sortable: false, value: 'producto.descripcion', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'CANTIDAD', sortable: false, align: 'center', value: 'cantidad', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'EXISTENCIA', sortable: false, value: 'existencia', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'FACTOR', sortable: false, align: 'center', value: 'unidadProducto.factor', width: 'auto', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'COSTO', sortable: false, align: 'center', value: 'costo', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'IMPORTE', sortable: false, align: 'center', value: 'valor', width: 'auto', sortable: false, class: ['no-gutters black--text'] }
                      ]" dense :items="ListProd" :items-per-page="50" :footer-props="arrayPropiedases" class="elevation-1 TbSize13" item-key="indx">

                        <template v-slot:item.valor="props">
                          <span style="display:none;">{{ props.item.indx = ListProd.indexOf(props.item) }}</span>
                          <span>{{ currencyFormatter((props.item.valor) * (props.item.cantidad)) }}</span>
                        </template>

                        <template v-slot:item.costo="props">
                          <span>{{ currencyFormatter(props.item.costo) }}</span>
                        </template>

                        <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end"
            style="font-size:15px; color: #00838F;">Total:</strong></td>

        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(TotalProd.costo) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(TotalProd.importe) }} </strong></td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>Total Costo:{{ currencyFormatter(TotalProd.cost) }}</span>
      <span>Total Importe:{{ currencyFormatter(TotalProd.importe) }}</span>
    </v-list-item-content>
  </v-list-item>

</template>

</v-data-table>


</v-card>
</div>

</v-col>
<!--------------------------------------------------- fn tb add producot -->


</v-card>
</div>

</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


    <!-- <BarraBusqueda2
        :url="$hostname + $hName + '/caja/findAll'"
        :nombre="'Cajas'"
        :headers="headers"
        :slotname="'item.descripcion'"
        :emitnombre="'emitCaja'"
        :slotlist="slotlist"
        :SeachNombre="'Búsqueda por descripción caja'"
      /> -->
  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->

<!-- [
        {text: "Secuencia", value: "secuencia"},
        {text: "Fecha", value: "fecha"},
        {text: "Concepto", value: "comentario"},
        {text: "valorlibro", value: "valorlibro"},
        {text: "valorfisico", value: "valorfisico"},
        {text: "Porciento", value: "porciento"}
      ] -->


<!-------------------------------------------------------- tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light">
        REPORTE
      </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por código"
            single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
            <template v-slot:append-outer>
              <v-btn color="teal darken-2" dark small
                @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                <i style="font-size: 25px;" class="fa fa-search"> </i>
                Filtros
              </v-btn>
            </template>


          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
          { text: 'Código', value: 'secuencia', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Documento', value: 'sucursal.descripcion', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Fecha', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
          { text: 'valor fisico $', value: 'valorfisico', sortable: false, class: ['no-gutters black--text'] },
          { text: 'valor libro $', value: 'valorlibro', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Estado', value: 'activo', sortable: false, class: ['no-gutters black--text'] }
        ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.codigo" @click:row="datostabla"
          :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">
          <template v-slot:item.valorfisico="props">
            <span>{{ currencyFormatter(props.item.valorfisico) }}</span>
          </template>
          <template v-slot:item.valorlibro="props">
            <span>{{ currencyFormatter(props.item.valorlibro) }}</span>
          </template>

          <template v-slot:item.fecha="props">
            <span>{{ FormatoFecha(props.item.fecha) }}</span>
          </template>

          <template v-slot:item.activo="props">
            <span v-if="props.item.activo == true">Inventario abierto</span>
            <span v-if="props.item.activo == false">Inventario Cerrado</span>
          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->


<!-------------------------------------------------------- notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec == 'anularInvFisico'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>



      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!--------------------------------------------------------  fn notif -->



<!--------------------------------------------------------   alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->


<!-- ------------------------------------------------------ Modal- nueva Produecto-->
<v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogAddProducto" persistent
  max-width="90%" max-height="80%">

  <AgregarProducto :statusFoms="dialogAddProducto" @EmitAgregarProducto="RecAgregarProducto" />
</v-dialog>
<!-- ------------------------------------------------------ fn Modal- nueva Produecto-->


<!-- ------------------------------------------------------ Modal  Inventario fisico-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogInventario" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> Cargar producto</v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="cerrarmodalInventario">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>
      <v-form ref="formInventario" v-model="valid" lazy-validation>
        <v-row>

          <v-col cols="12" md="12" sm="12">
            <!-- ----------------------------- letra :rules="[$rules.required]"-->
            <v-text-field outlined label="Letra"  v-model="Inventarioproducto.letra" dense
              maxlength="1">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-font"></i>
              </template>
            </v-text-field>
          </v-col>


          <v-col cols="12" md="6" sm="6">


            <!-------------------------------------------------- Almacén -->
            <v-combobox autocomplete="off" dense outlined label="* Almacén:" :items="Ralmacen" item-text="descripcion"
              v-model="Inventarioproducto.almacen" @blur="SelctCbAlmacen" @focus="CargarRalmacen">
              <template v-slot:prepend>
                <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                <!-- <i style="font-size: 20px;" class="fa fa-user-circle"></i> -->
              </template>
            </v-combobox>

            <!----------------------------------------------  Departamento -->
            <v-combobox autocomplete="off" dense outlined label="Departamento:" :items="RDepartamento"
              item-text="descripcion" v-model="Inventarioproducto.departamento" @focus="CargarRDepartement"
              @blur="SelctCbDepartement">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-building"></i>
              </template>
            </v-combobox>
            

            <v-radio-group class="mt-0" v-model="Inventarioproducto.opcExit" column>
                            <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> -->
                            <!-- <v-col cols="12" md="6"></v-col> -->

                            <v-radio label="Todos las opciones" color="green darken-3" :value="0"></v-radio>
                            <v-radio label="Solo existencia negativo" color="teal lighten-2" :value="1"></v-radio>
                            <v-radio label="Solo existencia" color="teal lighten-2" :value="2"></v-radio>
                            <v-radio label="Existencia cero" color="cyan lighten-2" :value="3"></v-radio>
                            
                          </v-radio-group>

            <!-- Cambio 09-08-2024 -->
            <v-switch v-if="false" class="mt-0" color="teal" label="" @change="changeSolonegativo"
                                v-model="Inventarioproducto.solonegativo">
                                <template v-slot:label>
                                  <strong v-if="Inventarioproducto.solonegativo == true"
                                    style="color:#000000; font-size:13px;">Solo existencia negativo: Si</strong>
                                  <strong v-if="Inventarioproducto.solonegativo == false"
                                    style="color:#000000; font-size:13px;">Solo existencia negativo: No</strong>
                                </template>
                              </v-switch>

                              <v-switch v-if="false" class="mt-0" color="teal" label="" @change="changeSoloexistencia"
                                v-model="Inventarioproducto.soloexistencia">
                                <template v-slot:label>
                                  <strong v-if="Inventarioproducto.soloexistencia == true"
                                    style="color:#000000; font-size:13px;">Solo existencia: Si</strong>
                                  <strong v-if="Inventarioproducto.soloexistencia == false"
                                    style="color:#000000; font-size:13px;">Solo existencia: No</strong>
                                </template>
                              </v-switch>


          </v-col>

          <v-col cols="12" md="6" sm="6">
            <v-combobox autocomplete="off" dense outlined label="* Fabricante:" :items="RFabricante" item-text="nombre"
              v-model="Inventarioproducto.fabricante" @focus="CargarRFabricantes" @blur="SelctCbFabricantes">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-wrench"></i>
              </template>

            </v-combobox>
            <!-------------------------------------------------- proveedor -->
            <v-combobox autocomplete="off" dense outlined label="* proveedor:" :items="Rproveedor" item-text="nombre"
              v-model="Inventarioproducto.proveedor" @blur="SelctCbProveedor" @focus="cargarProveedor"
              @keyup="CbFilProveedor">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
              </template>

            </v-combobox>

            <v-radio-group class="mt-0" v-model="Inventarioproducto.tipoinventario" row>
                            <!-- <v-row></v-row>
                          <v-col cols="12" md="6"></v-col> -->
                            <!-- <v-col cols="12" md="6"></v-col> -->
                            <v-radio label="Productos terminados" color="green darken-3" value="T"></v-radio>
                            <v-radio label="Materia Prima" color="teal lighten-2" value="M"></v-radio>
                            <v-radio label="Material Gastable" color="cyan lighten-2" value="G"></v-radio>
                          </v-radio-group>
          </v-col>



        </v-row>
      </v-form>

    </v-card-text>

    <hr />

    <div v-if="CargarOrImprimir == false">
      <ModalReporteFiltros ref="ModRepfil2" @DataReporteBasico="DDataInvProducto" :Datos="LinkDataInventarioproducto" />
    </div>



    <v-btn-toggle dense v-if="CargarOrImprimir == true" rounded class="d-flex justify-end">


      <v-btn color="lime lighten-1" @click.prevent="ajusteinventariocero">
        <i style="font-size: 28px;" class="fa fa-recycle"> </i>
        llevar inventario a cero
      </v-btn>

      <v-btn color="blue" @click.prevent="cargarDetalleProductos">
        <i style="font-size: 28px;" class="fa fa-upload"> </i>
        Cargar
      </v-btn>
    </v-btn-toggle>
    <hr />

  </v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn Modal Inventario fisico-->

<!-- ------------------------------------------------------notif_Aplicar-->


<v-dialog label="myAlert" v-model="notif_Aplicar.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif_Aplicar.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn @click.prevent="AplicarProducto(), (notif_Aplicar.estado = !notif_Aplicar.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="notif_Aplicar.estado = !notif_Aplicar.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif_Aplicar -->


<!-- ------------------------------------------------------ Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>

      <v-row>

        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="6" sm="6">

          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>


          <!-------------------------------------------------- Sucursal -->
          <v-combobox autocomplete="off" dense outlined label="* Succursal:" :items="RSucursal" item-text="descripcion"
            v-model="Modallfiltro.sucursal" @blur="SelctCbSucursalFiltro" @focus="cargarSucursal"
            style="font-size:12px;">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>



        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="12" md="6" sm="6">

          <!-------------------------------------------------- Sucursal -->


          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-2 -->

      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle rounded class="" dense>


        <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>

<!-------------------------------------------------------- fn Modal filtro -->






<!-- ------------------------------------------------------ Modal- Reportes-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE INVENTARIO FÍSICO</b> </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" md="6" sm="6">

            <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
              :rules="[$rules.required]" @blur="ReportBlurDesde">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>


            <v-combobox v-model="datosImprecionAll.almacen" label="Almacén" :rules="[$rules.required]"
                          outlined dense autocomplete="off" :items="Ralmacen" item-text="descripcion"
                          @blur="SelctCbAlmacenImprec" @focus="CargarRalmacen">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                          </template>
                        </v-combobox>



          </v-col>

          <v-col cols="12" md="6" sm="6">
            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
              :rules="[$rules.required]" @blur="ReportBlurHasta">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>



          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


</v-dialog>
<!-- ------------------------------------------------------ fn Modal reportes -->


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- ------------------------------------------------------  Modal Loader -->

<v-dialog v-model="Reparaloading" persistent width="300">
  <v-card color="grey darken-2" dark>
    <v-card-text>
      <br>
      <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
    </v-card-text>
  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal loader -->




<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>

</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter, fechaNueva } from "@/js/Funciones.js";
import TablaProductoInventario from "../../Inventario/UtilidadComponents/TablaProductoInventario";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    AgregarProducto,
    ContenidoBase,
    BarraDinamicaLimitePlus,
    ModalReporteFiltros,
    ModalReporteBasico,
    TablaProductoInventario
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitInventarioFisico", this.datostabla);
    EventBus.$on("INVInventarioFisicoNew", this.new);
    EventBus.$on("INVInventarioFisicoSave", this.save);
    EventBus.$on("INVInventarioFisicoRemove", this.remove);
    EventBus.$on("INVInventarioFisicoAnular", this.anular);
    EventBus.$on("INVInventarioFisicoPrintList", this.imprimirList);
    EventBus.$on("INVInventarioFisicoCargarProducto", this.CargarProducto);
    EventBus.$on("INVInventarioFisicoProductoInv", this.ProductoInv)
    EventBus.$on("onResize", this.onResize);
    this.filtro();

    this.findAlmacenPrincipal()
    console.log(".")
  },

  created() {
    var json = {
      titulo: "Inventario Físico",
      descripcion: "Inventario Físico.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      cargarProducto: true,
      productoInv: true,
      accion: [
        "INVInventarioFisicoNew",
        "INVInventarioFisicoSave",
        "INVInventarioFisicoRemove",
        "INVInventarioFisicoAnular",
        "INVInventarioFisicoPrintList",
        "INVInventarioFisicoVercuenta",
        "INVInventarioFisicoSaavetem",
        "INVInventarioFisicoRestaurartem",
        "INVInventarioFisicoImportarDato",
        "INVInventarioFisicoCargarProducto",
        "INVInventarioFisicoProductoInv"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      dialogAddProducto: false,
      dialogEtiqueta: false,
      dialogInventario: false,
      CargarOrImprimir: true,
      Rproveedor: [],
      RDepartamento: [],
      Ralmacen: [],
      RSucursal: [],
      RFabricante: [],
      dialogimprimirList: false,

      datosImprecion: {
        nombre: 'INVENTARIO FÍSICO',
        UrlImprimir: '/inventariofisico/imprimir',
        UrlExel: '/inventariofisico/excel',
        UrlCorreo: '/inventariofisico/correo',
        secuencia: 0,
        documento: 0,
        almacen:null
      },
      Reparaloading: false,
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE INVENTARIO FÍSICO',
        UrlImprimir: '/inventariofisico/ImprimirList',
        UrlExel: '/inventariofisico/ExcelList',
        UrlCorreo: '/inventariofisico/CorreoList',
        documento: null,
        desde: fechaNueva(),
        hasta: fechaNueva(),
        sucursal: null

      },
      aalert: {
        estado: false,
        color: null
      },
      notif_Aplicar: {
        estado: false,
        nombre: "Este proceso ajustara los balances de los productos en el invetario fisico digitado"
      },
      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
      ListProd: [],
      almacen: null,
      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      search: '',
      ObjTemp: null,
      Inventarioproducto: {
        letra: "",
        almacen: null,
        departamento: null,
        fabricante: null,
        proveedor: null,
        solonegativo:false,
        tipoinventario:'T',
        soloexistencia:false,
        opcExit:0
      },

      LinkDataInventarioproducto: {
        nombre: 'INVENTARIO FÍSICO',
        UrlImprimir: '/inventariofisico/ImprimirListProInv',
        UrlExel: '/inventariofisico/excelListProInv',
        UrlCorreo: '/inventariofisico/CorreoListProInv',
      },

      InventarioFisico: {
        empresa: 1,
        fecha: fechaNueva(),
        comentario: "",
        activo: false,
        secuencia: 0,
        valorlibro: 0.00,
        valorfisico: 0.00,
        sucursal: null,
        borrado: false,
        inventariofisicoDetalleList: [],
        almacen: null
      },

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        sucursal: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },




      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: false,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },

      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],


      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    onResize(e) {
      //  this.windowSize = e
    },
    RecAgregarProducto(e) {
      if (e.estado == 'cerrar') {
        this.dialogAddProducto = e.false
      }
      if (e.estado == 'datos') {
        console.log('emitiendo datos')
        console.log(e.datos)
      }
    },
    CargarProducto() {
      this.dialogInventario = true
      this.CargarOrImprimir = true
    },
    ImprimirproductoInv() {

      this.dialogInventario = true
      this.CargarOrImprimir = false
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil2.CrearObjecto = _this.validarObjectoImprProd;
      }, 300);

      //ModRepfil2,dialogInventario
    },

    validarObjectoImprProd() {
      var where = "p.codigo>0";
      if (!this.$refs.formInventario.validate()) {
        this.alerta("Debe seleccionar una letra", "error");
        this.$refs.ModRepfil2.detener = true;
      } else {
        this.$refs.ModRepfil2.detener = false;
      }
      this.LinkDataInventarioproducto.Condicion = ""
      if (this.Inventarioproducto.almacen != null) {
        where += ` and a.codigo= ${this.Inventarioproducto.almacen.codigo} `
        this.LinkDataInventarioproducto.almacen = this.Inventarioproducto.almacen.descripcion
      }

      if (this.Inventarioproducto.departamento != null) {
        where += ` and p.departamento= ${this.Inventarioproducto.departamento.codigo} `
        this.LinkDataInventarioproducto.Condicion += ` Departamento:    ${this.Inventarioproducto.departamento.descripcion}  `
      }

      if (this.Inventarioproducto.departamento != null) {
        where += ` and p.fabricante=${this.Inventarioproducto.departamento.codigo} `
        this.LinkDataInventarioproducto.Condicion += ` Fabricante:    ${this.Inventarioproducto.fabricante.nombre}   `
      }


      if (this.Inventarioproducto.proveedor != null) {
        where += ` and sp.proveedor= ${this.Inventarioproducto.proveedor.codigo} `
        this.LinkDataInventarioproducto.Condicion += `   Proveedor:    ${this.Inventarioproducto.proveedor.nombre}   `
      }

      if (this.Inventarioproducto.letra != null && this.Inventarioproducto.letra.length > 0) {
        where += ` and p.descripcion ilike '${this.Inventarioproducto.letra}%'`;
      }

/*"Todos las opciones = 0"
                "Solo existencia negativo= 1"
                "Solo existencia= 2"
                "Existencia cero 3"*/ 
                var sne=this.Inventarioproducto.opcExit

        if(sne==1){where +=" AND bi.balance <0";}
        if(sne==2){where +=" AND bi.balance >0";}
        if(sne==3){where +=" AND bi.balance =0";}             

/*if(this.Inventarioproducto.soloexistencia==true){
  where +=` and bi.balance>0 `
}

if(this.Inventarioproducto.solonegativo==true){
  where +=` and bi.balance<0 `
}*/


      this.LinkDataInventarioproducto.where = where
      this.LinkDataInventarioproducto.orden = this.Inventarioproducto.letra
      this.LinkDataInventarioproducto.fecha = this.fechaNueva()

      //    ic.imprimir("ListadoInventarioFisico", parametros, "inv");

    },



    cargarDetalleProductos() {
      // this.Reparaloading = true
      EventBus.$emit("loading", true);
      var letra = "null", alm = 0, fab = 0, dep = 0, pro = 0;
      this.ListProd = []
      if (this.Inventarioproducto.letra != "") {
        letra = this.Inventarioproducto.letra;
      }
      if (this.Inventarioproducto.almacen != null && this.Inventarioproducto.almacen.hasOwnProperty('codigo')) {
        alm = this.Inventarioproducto.almacen.codigo;
      }else{
        this.alerta("seleccione un almacen", "error")
       return;
      }

      if (this.Inventarioproducto.departamento != null) {
        dep = this.Inventarioproducto.departamento.codigo;
      }
      if (this.Inventarioproducto.fabricante != null) {
        fab = this.Inventarioproducto.fabricante.codigo;
      }
      if (this.Inventarioproducto.proveedor != null) {
        pro = this.Inventarioproducto.proveedor.codigo;
      }
                /*"Todos las opciones = 0"
                "Solo existencia negativo= 1"
                "Solo existencia= 2"
                "Existencia cero 3"*/ 
      /*var sne=this.Inventarioproducto.solonegativo
      var sex=this.Inventarioproducto.soloexistencia*/
      
      var tipi=this.Inventarioproducto.tipoinventario
        var sne=this.Inventarioproducto.opcExit
      this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/cargar/' + letra + '/' + alm + '/' + fab + '/' + dep + '/' + pro+'/'+sne+'/'+tipi,
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        var lista = []
        res.data.forEach(e => {
        if(e.existencia){}
          var detalle = {
            movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
            fechavencimiento: null,
            cantidad: 0,
            costo: e.costo,
            valor: e.costo,
            impuesto: this.calcularImpuesto(e),
            impuestoIncluido: this.impuestoIncuidos(e),
            id: 0,
            borrado: false,
            almacen: this.almacen,
            unidad: e.unidades,
            producto: e.productos,
            codigoProducto: this.GetCodigoProdUni(e),
            existencia: e.existencias,
            unidadProducto: e
          }
          lista.push(detalle)
        });
        // this.Reparaloading = false
        EventBus.$emit("loading", false);
        this.ListProd = lista;
        this.dialogInventario = false
      }
      )

    },

    ajusteinventariocero() {
      // this.Reparaloading = true
      EventBus.$emit("loading", true);
    
     var OBJ={
      letra:"null",
      almacen:0,
      fabricante:0,
      proveedor:0,
      departamento:0,
      solonegativo:this.Inventarioproducto.solonegativo,
      tipoinv:this.Inventarioproducto.tipoinventario,
      opcexitencia:this.Inventarioproducto.opcExit
     }


      this.ListProd = []
      if (this.Inventarioproducto.letra != "") {
        OBJ.letra = this.Inventarioproducto.letra;
      }
      if (this.Inventarioproducto.almacen != null && this.Inventarioproducto.almacen.hasOwnProperty('codigo') ) {
        OBJ.almacen = this.Inventarioproducto.almacen.codigo;
      }else{
        this.alerta("seleccione un almacen", "error")
       return;
      }

      if (this.Inventarioproducto.departamento != null  && this.Inventarioproducto.departamento.hasOwnProperty('codigo')) {
        OBJ.departamento = this.Inventarioproducto.departamento.codigo;
      }
      if (this.Inventarioproducto.fabricante != null && this.Inventarioproducto.fabricante.hasOwnProperty('codigo')) {
        OBJ.fabricante = this.Inventarioproducto.fabricante.codigo;
      }
      if (this.Inventarioproducto.proveedor != null && this.Inventarioproducto.proveedor.hasOwnProperty('codigo')) {
        OBJ.proveedor = this.Inventarioproducto.proveedor.codigo;
      }

      this.$axios
        .post(this.$hostname + this.$hName + "/inventariofisico/ajusteinventariocero",OBJ,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{
           this.alerta("llevar inventario a cero!", "bien")
        })
        .catch(error =>this.alerta("proceso no generado correctamente", "error"));




    },


    impuestoIncuidos(e) {
      if (e == null) {
        return false;
      }
      if (e.productos == null) {
        return false
      }
      if (e.productos.impuestoincluido == null) {
        return false
      }
      return e.productos.impuestoincluido
    },
    GetCodigoProdUni(unidaproducto) {
      var pk = unidaproducto.unidadesProductosPK;
      var codigoP = ('000000' + pk.producto).substring(('000000' + pk.producto).length - 6, ('000000' + pk.producto).length)
      var codigoU = ('0000' + pk.unidad).substring(('0000' + pk.unidad).length - 4, ('0000' + pk.unidad).length)
      return codigoP + codigoU;
    },
    calcularImpuesto(unidadProducto) {
      if (unidadProducto.productos == null) {
        return 0.00
      }
      if (unidadProducto.productos.impuesto == null) {
        return 0.00
      }
      var tasa = (unidadProducto.productos.impuesto.tasa == null || unidadProducto.productos.impuesto.tasa == 0 ? 1 : unidadProducto.productos.impuesto.tasa) / 100
      var valImpuesto = unidadProducto.costo * tasa;
      return valImpuesto
    },
    cerrarmodalInventario() {
      this.CargarOrImprimir = true
      this.dialogInventario = false
      this.Inventarioproducto = {
        nombre: 'INVENTARIO FÍSICO',
        UrlImprimir: '/inventario/printListado',
        UrlExel: '/inventario/excelList',
        UrlCorreo: '/inventario/correoList',
        letra: "",
        almacen: null,
        departamento: null,
        fabricante: null,
        proveedor: null,
        solonegativo:false,
        tipoinventario:'T',
        soloexistencia:false


      }

    },

    SelctCbSucursal() {
      if (this.InventarioFisico.sucursal == null) {
        this.InventarioFisico.sucursal = ''
      }

      if (this.InventarioFisico.sucursal.codigo == undefined) {
        this.alerta("Seleccione un sucursal", "error");
        this.InventarioFisico.sucursal = ''
        return
      }
      if (this.InventarioFisico.sucursal != null && this.InventarioFisico.sucursal.almacen != null) {
        this.ActualizaAlmacen = true
        this.CargarAlmacen1(this.InventarioFisico.sucursal.almacen);
      }
    },
    cargarSucursal() {
      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then(res => { this.RSucursal = res.data })
    },


    SelctCbAlmacen() {
      if (this.Inventarioproducto.almacen == null) {
        this.Inventarioproducto.almacen = "";
      }

      if (this.Inventarioproducto.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.Inventarioproducto.almacen = "";
        return;
      }
    },
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos", {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },
    CargarAlmacen1(codigo) {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/" + codigo, {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.almacen = res.data;
        });
    },
    addClikProd() {


    },
    cerrarModalAddProd() {
      this.dialogAddProducto = false
      this.newProducto()
    },

    newCrearProducto() {


    },


    newProducto() {


      this.ObjTemp = null

    },

    save() {
      var valInv = 0.00, valLib = 0.00;
      this.InventarioFisico.inventariofisicoDetalleList = []
      if (this.$refs.formInfFisico.validate()) {
        if (this.ejecucion == true) {
          // this.Reparaloading = true
          EventBus.$emit("loading", true);
          this.ejecucion = false;
          if (this.ListProd.filter(e=>e.unidadProducto!=null && e.unidadProducto.factor!=1).length>0){
                this.alerta("Existen productos con el factor erroneo ","error")
                return  
          }

          var ObjListUniMin = this.ListProd.filter((ee) => ee.unidadProducto!=null && ee.unidadProducto.unidadminima==false);
           if(ObjListUniMin.length>0){
            var msjUn=`el Producto:=> ' ${ObjListUniMin[0].producto.descripcion.trim()} - ${ObjListUni[0].unidad.descripcion.trim()}'. no esta marcado como unidad base`
            this.alerta(msjUn, "error");
          return
        }


          var ObjListUni = this.ListProd.filter((ee) => ee.unidadProducto!=null && ee.unidadProducto.unidadminima==true && ee.unidadProducto.factor>1);
           if(ObjListUni.length>0){
            var msj=`el Producto:=> ' ${ObjListUni[0].producto.descripcion.trim()} - ${ObjListUni[0].unidad.descripcion.trim()}'. Esta marcado como unidad base y su factor es mayor a 1`
          this.alerta(msj, "error");
          return
        }


          if (this.ListProd.filter(e=>e.unidadProducto!=null && e.unidadProducto.unidadminima==true && e.unidadProducto.factor>1).length>0){
                this.alerta("Existen productos con el factor erroneo ","error")
                return  
          }


          var listArrys=[]
          this.ListProd.forEach(e => {
            if (e.producto != null) {
              var detalle = {
                cantidad: e.cantidad,
                costo: e.costo,
                balance: e.balance,
                id: 0,
                secuencia: 0,
                lote: ('0000'+e.lote).substring(('0000'+e.lote).length-4,('0000'+e.lote).length),
                borrado: false,
                fechaVencimiento: null,
                almacen: e.almacen,
                unidad: e.unidad,
                producto: e.producto
              }
              valLib += e.cantidad * e.existencia;
              valInv += e.cantidad * e.costo;
              listArrys.push(detalle)
            }

          });
          this.InventarioFisico.valorfisico = valInv;
          this.InventarioFisico.valorlibro = valLib;
          var alm = {
            codigo: this.InventarioFisico.sucursal.almacen
          }
          this.InventarioFisico.almacen = alm

          // this.inventariofisicoDetalleList = this.ListProd
  
          this.InventarioFisico.inventariofisicoDetalleList = listArrys.filter((ee) => ee.producto != null);

          this.$axios
            .post(
              this.$hostname + this.$hName + "/inventariofisico/save", this.InventarioFisico,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => {
              this.alerta("Dato guardado correctamente", "bien")
              
            })
            .catch(error => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }




    },


    NotifMessage() {

      if (this.InventarioFisico.secuencia != 0 && this.InventarioFisico.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "anularInvFisico"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un inventario para Anular", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

    },




    eliminar() {

    },

    anular() {
      if (this.InventarioFisico.secuencia != 0 && this.InventarioFisico.secuencia != null) {
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/inventariofisico/remove", this.InventarioFisico,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato eliminado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      }
    },
    imprimirList() {
      if (this.InventarioFisico.secuencia > 0) {
        this.datosImprecion.codigo = this.InventarioFisico.secuencia
        //if(this.InventarioFisico.almacen!=null){this.datosImprecion.almacen=this.InventarioFisico.almacen.codigo}
        //ic.imprimir("ListaProductoSinInventario", parametros, "inv");


        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un recibo para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this= this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      console.log('viendo klk-->',this.datosImprecionAll)
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      this.form
      var where = "i.secuencia>0";
      where += `  and i.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and i.fecha<= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `
      var condicion= `Desde: ${this.FormatoFecha(this.datosImprecionAll.desde)}   Hasta: ${this.FormatoFecha(this.datosImprecionAll.hasta)}`

          if(this.datosImprecionAll.almacen!=null && this.datosImprecionAll.almacen.hasOwnProperty('codigo')){
              where += ` and i.almacen=${this.datosImprecionAll.almacen.codigo}`  
              condicion +=`        Almacen: ${this.datosImprecionAll.almacen.descripcion.trim()}  `

          }


this.datosImprecionAll.Condicion = condicion
      
      this.datosImprecionAll.where = where

      //      ic.imprimir("InventariosFisico", parametros, "inv");
    },




    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.InventarioFisico = {
        empresa: 1,
        fecha: fechaNueva(),
        comentario: "",
        activo: false,
        secuencia: 0,
        valorlibro: 0.00,
        valorfisico: 0.00,
        sucursal: null,
        borrado: false,
        inventariofisicoDetalleList: [],
        almacen: null,
        solonegativo:false,
        tipoinventario:'T'
      };
      this.ListProd = []
      this.ejecucion = true;
      // this.Reparaloading = false;
      EventBus.$emit("loading", false);
      //  EventBus.$emit("actualizaBarraBusqueda2");
    },

    datostabla(e) {
      this.TotalProd = { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }
      this.InventarioFisico = e;
      this.InventarioFisico.fecha = new Date(e.fecha).toJSON().split("T")[0]
      //this.ListProd = JSON.parse(JSON.stringify(e.inventariofisicoDetalleList))
      this.acti_tb_data = false;
      //console.log(e);
      this.buscarDetalle(e.secuencia)
    },
    buscarDetalle(inv) {
      this.ListProd = []
      this.$axios.get(this.$hostname + this.$hName + '/inventariofisico/detallle/' + inv,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          console.log(res.data)
          res.data.forEach(e => {
            var detalle = {
              movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
              fechavencimiento: null,
              cantidad: e.cantidad,
              costo: e.costo,
              valor: e.costo,
              impuesto: 0,
              id: 0,
              borrado: false,
              almacen: e.almacen,
              unidad: e.unidad,
              producto: e.producto,
              //codigoProducto:('000000'+e.producto.codigo).substring(('000000'+e.producto.codigo).length-6,('000000'+e.producto.codigo).length)+('0000'+e.unidad.codigo).substring(('0000'+e.unidad.codigo).length-4,('0000'+e.unidad.codigo).length),
              existencia: e.balance,
              unidadProducto: e.unidadProducto
            }

            this.TotalProd.costo += e.costo
            this.TotalProd.importe += ((e.costo) * (e.cantidad))
            this.ListProd.push(detalle)
          });
        })
    },
    BuscarFiltros() {

      var e = {
        opcion: 1,
        Modallfiltro: this.Modallfiltro,
        url: this.$hostname + this.$hName + '/inventariofisico/findAllFiltro'
      }

      EventBus.$emit('BarraDinamLimitadaFiltro', e);

      this.dialogFiltros = false
    },


    CerrarFiltro(e) {

      this.Modallfiltro = {
        Desde: fechaNueva(),
        Hasta: fechaNueva(),
      }
      if (e == 1) {

        this.dialogFiltros = false

      }

      if (e == 2) {

        var e = {
          opcion: e,
          Modallfiltro: this.Modallfiltro,
          url: this.$hostname + this.$hName + '/inventariofisico/findAllFiltro',
          urlcount: this.$hostname + this.$hName + '/inventariofisico/countAllFiltro'
        }

        EventBus.$emit('BarraDinamLimitadaFiltro', e);
      }
    },


    FiltroBlurDesde() {
      if (this.Modallfiltro.Desde.toString().length == 0) {
        this.Modallfiltro.Desde = this.fechaNueva()
      }

    },


    FiltroBlurHasta() {

      if (this.Modallfiltro.Hasta.toString().length == 0) {
        this.Modallfiltro.Hasta = this.fechaNueva()
      }

    },


    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    DDataTotalList(e) {

      this.TotalProd = JSON.parse(JSON.stringify(e))

    },

    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.almacen = res.data
        })
    },


    CargarRDepartement() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/departamento/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RDepartamento = res.data)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },


    SelctCbDepartement() {
      if (this.Inventarioproducto.departamento == null) {
        this.Inventarioproducto.departamento = "";
      }

      if (this.Inventarioproducto.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.Inventarioproducto.departamento = "";
        return;
      }
    },



    CargarRFabricantes() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/fabricante/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RFabricante = res.data)
        .catch()
    },

    SelctCbFabricantes() {
      if (this.Inventarioproducto.fabricante == null) {
        this.Inventarioproducto.fabricante = "";
      }

      if (this.Inventarioproducto.fabricante.codigo == undefined) {
        this.alerta("Seleccione un fabricante", "error");
        this.Inventarioproducto.fabricante = "";
        return;
      }
    },


    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))

    },

    CbFilProveedor(e) {
      if (e.target.value.length >= 2) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }
    },


    SelctCbProveedor() {
      if (this.Inventarioproducto.proveedor == null) {

        this.Inventarioproducto.proveedor = ''
      }

      if (this.Inventarioproducto.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.Inventarioproducto.proveedor = ''
        return

      }

    },

    DDataInvProducto(e) {
      //this.dialogInventario=e
    },

    SelctCbSucursalFiltro() {

      if (this.Modallfiltro.sucursal == null) {

        this.Modallfiltro.sucursal = ''
      }

      if (this.Modallfiltro.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.Modallfiltro.sucursal = ''
        return

      }


    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        sucursal: null,
        Desde: null,
        Hasta: null,
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }
      var suc = null
      if (this.Modallfiltro.sucursal != null) { suc = this.Modallfiltro.sucursal.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + suc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/inventariofisico/findListInveFisicoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    changeSoloexistencia(e){
var _this = this;
        setTimeout(function () {
         if(e==true){
          _this.Inventarioproducto.solonegativo = false
         }
        }, 500);

    },

    changeSolonegativo(e){
var _this = this;
        setTimeout(function () {
         if(e==true){
          _this.Inventarioproducto.soloexistencia = false
         }
        }, 500);

    },


    SelctCbAlmacenImprec() {
      var _this = this;
      setTimeout(function () {
        if (_this.datosImprecionAll.almacen== null) {
          _this.datosImprecionAll.almacen= "";
        }

        if (!_this.datosImprecionAll.almacen.hasOwnProperty('codigo')) {
          _this.datosImprecionAll.almacen= "";
          _this.datosImprecionAll.almacen= null;
          return;
        }
      }, 300);
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        // this.Reparaloading = false
        EventBus.$emit("loading", false);
      }
    },



    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/inventariofisico/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },


    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {

    ActDescBtn() {

      if (this.InventarioFisico.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: true,
          ImprimirproductoInv: true,
          etiquetaXfactura: false
        }
      }


      if (this.InventarioFisico.secuencia <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: true,
          ImprimirproductoInv: true,
          etiquetaXfactura: false
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }

};
</script>

<style>

</style>
