<template>
  <div>
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Conciliación Bancaria'" :descripcion="'Conciliación Bancaria'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px" class="fa fa-eye"> </i>
                    ver
                  </v-btn>
                </center>
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/conciliacion-bancaria/l'"
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBANConciliacionBancaria'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- Tab panellll ---------->
            <v-col cols="12" md="12" sm="12">
              <v-card dense>
                <v-tabs v-model="tab" background-color="blue-grey lighten-4" color="cyan darken-3" centered
                  icons-and-text grow dense>
                  <v-tabs-slider></v-tabs-slider>

                  <v-tab href="#tab-1">
                    Nueva Conciliación
                    <i style="font-size: 20px" class="fa fa-file-o"></i>
                  </v-tab>

                  <v-tab href="#tab-2">
                    Buscar
                    <i style="font-size: 20px" class="fa fa-search"></i>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <!-------------------------------------------- Tab item 1 -->
                  <v-tab-item :value="'tab-1'">
                    <v-card flat>
                      <v-card-text>
                        <v-col cols="12" md="12" sm="12" style="margin-top: -35px">
                          <v-row>
                            <!--Cols 1 emitido  -->
                            <v-col cols="12" md="4" sm="4">
                              <v-card color="grey lighten-3">
                                <center><b> Cheques Emitidos</b></center>
                                <v-row>
                                  <v-col cols="12" md="6" sm="6">
                                    <!-- ----------------------------- Cheques Emitidos -->
                                    <VueAutonumeric label="(Cantidad):" autocomplete="off" required outlined dense
                                      style="font-size: 13px" 
                                      v-model="conciliacionBancaria.cantidadChequesEmitidos"
                                      disabled
                                      id="formdisabledInput"
                                      >
                                      <template v-slot:prepend>
                                        <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                      </template>
                                    </VueAutonumeric>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="6">
                                    <!-- ----------------------------- Cheques Emitidos -->
                                    <!--v-model="conciliacionBancaria.chequeEmitidoMes"  -->
                                    <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required
                                    :value="getTasaMonedaSelect(conciliacionBancaria.chequeEmitidoMes)"
                                      outlined dense disabled 
                                      id="formdisabledInput"
                                      >
                                      <template v-slot:prepend>
                                        <i style="font-size: 20px" class="fa fa-money"></i>
                                      </template>
                                    </VueAutonumeric>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                            <!--fn Cols 1 emitido  -->
                            <!--Cols 2 emitido  -->
                            <v-col cols="12" md="4" sm="4">
                              <v-card color="grey lighten-3">
                                <center><b> Depósitos Emitidos</b></center>
                                <v-row>
                                  <v-col cols="12" md="6" sm="6">
                                    <!-- ----------------------------- descuento -->
                                    <VueAutonumeric label="(Cantidad):" autocomplete="off" style="font-size: 13px"
                                      required outlined dense v-model="conciliacionBancaria.cantidadDepositoEmitidos" disabled
                                      id="formdisabledInput"
                                      >
                                      <template v-slot:prepend>
                                        <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                      </template>
                                    </VueAutonumeric>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="6">
                                    <!-- ----------------------------- descuento -->
                                    <!-- v-model="conciliacionBancaria.depositoEmitidoMes" -->
                                    <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required
                                      outlined dense :value="getTasaMonedaSelect(conciliacionBancaria.depositoEmitidoMes)" disabled
                                      id="formdisabledInput"
                                      >
                                      <template v-slot:prepend>
                                        <i style="font-size: 20px" class="fa fa-money"></i>
                                      </template>
                                    </VueAutonumeric>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                            <!-- fn Cols 2 emitido  -->

                            <!-- Cols 3 emitido  -->
                            <v-col cols="12" md="4" sm="4">
                              <v-card color="grey lighten-3">
                                <center><b>Cheque en Tránsito</b></center>
                                <v-row>
                                  <v-col cols="12" md="6" sm="6">
                                    <!-- ----------------------------- Cantidad -->
                                    <VueAutonumeric label="(Cantidad):" autocomplete="off" style="font-size: 13px"
                                      required outlined dense v-model="conciliacionBancaria.cantidadChequeTransito" disabled
                                      id="formdisabledInput"
                                      >
                                      <template v-slot:prepend>
                                        <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                      </template>
                                    </VueAutonumeric>
                                  </v-col>
                                  <v-col cols="12" md="6" sm="6">
                                    <!-- ----------------------------- Valor -->
                                    <!-- v-model="conciliacionBancaria.chequeTrasitoMes"  -->
                                    <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required
                                      outlined dense :value="getTasaMonedaSelect(conciliacionBancaria.chequeTrasitoMes)" disabled
                                      id="formdisabledInput"
                                      >
                                      <template v-slot:prepend>
                                        <i style="font-size: 20px" class="fa fa-money"></i>
                                      </template>
                                    </VueAutonumeric>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                            <!-- fn Cols 3 emitido  -->

                            <!-- Cols 4 Moneda  -->
                          <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0"  v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

                          <v-row>
                                  <v-col cols="12" md="6" sm="6"  :class="`d-flex justify-end`">
                                  <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                                  :rules="[$rules.required]" v-model="conciliacionBancaria.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
                                  :disabled="conciliacionBancaria.cuentaBanco==null"
                                  @change="ChangeMonedaTasa" hide-details>

<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-money"></i>
</template>

<template v-slot:selection="{ attrs, item, parent, selected }">
<span id="ImputColorDisable">
{{ item.descripcion }} ({{ item.nombrecorto }})
</span>

</template>


<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
</p>
</v-col>
</template>
                                  </v-combobox>
                                 </v-col>
                                 <v-col cols="12" md="6" sm="6"  :class="`d-flex justify-start`">
                                  <VueAutonumeric hide-details v-if="conciliacionBancaria.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
                                  :disabled="conciliacionBancaria.cuentaBanco==null"
v-model.number="conciliacionBancaria.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
                                  </VueAutonumeric>
                                 </v-col>
                         </v-row>
                            <!-- <v-card color="grey lighten-3"> </v-card>    -->
                            </v-col>
                            <!-- fn Cols 4 Moneda  -->
                          </v-row>
                        </v-col>
                        <!-- style="margin-top: -35px" -->
                        <v-tabs @change="changeTabs" v-model="Subtab" background-color="blue-grey lighten-4"
                          color="cyan darken-3" centered icons-and-text grow dense>
                          <v-tabs-slider></v-tabs-slider>

                          <v-tab href="#tab-1">
                            Conciliación
                            <i style="font-size: 20px" class="fa fa-handshake-o"></i>
                          </v-tab>

                          <v-tab href="#tab-2">
                            Cheque
                            <i style="font-size: 20px" class="fa fa-file-text-o"></i>
                          </v-tab>

                          <v-tab href="#tab-3">
                            Depósito
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="Subtab">
                          <!-------------------------------------------- subTab item 1 -->
                          <v-tab-item :value="'tab-1'">
                            <v-card dense flat>
                              <v-card-text>
                                <v-tabs-items>
                                  <v-toolbar dense dark color="indigo">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-toolbar-title v-on="on">
                                          Conciliación
                                        </v-toolbar-title>
                                      </template>
                                      <i style="font-size: 20px" dark dense class="fa fa-info-circle">
                                        <span> Conciliación : </span></i>
                                    </v-tooltip>

                                    <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

                                    <v-spacer></v-spacer>

                                    <v-card-actions>
                                      <v-sheet id="scrolling-techniques-7" class="overflow-x-auto"
                                        :max-width="windowSize.width - 70" color="transparent">
                                        <v-btn-toggle>
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                              <v-btn id="boton-mini" v-on="on" color="light-blue lighten-3" small dark>
                                                <i style="font-size: 20px" class="fa fa-refresh">
                                                </i>
                                                actualizar
                                              </v-btn>
                                            </template>
                                            <span>actualizar</span>
                                          </v-tooltip>
                                        </v-btn-toggle>
                                      </v-sheet>
                                    </v-card-actions>
                                  </v-toolbar>

                                  <v-row>
                                    <v-col cols="12" md="6" sm="6">
                                      <v-row>
                                        <v-col cols="12" md="6" sm="6">
                                          <!-------------------------------------------------------- Cuenta Bancaria -->
                                          <v-combobox v-model="conciliacionBancaria.cuentaBanco" label="Cuenta Bancaria:"
                                            required outlined dense :rules="[$rules.required]"
                                            autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                                            @focus="cargarCuentaBanco" @blur="SelctCbCuentaBanco"
                                            @change="buscarDatosConciliacion">
                                            <template v-slot:selection="{ attrs, item, parent, selected}">
                                           <span class="black--text" v-if="item.hasOwnProperty('cuenta')" >{{item.cuenta.trim()}}</span>
                                           </template>
                                            <template slot="item" slot-scope="item">
                                              {{ item.item.cuenta }}-{{
                                              item.item.banco.nombre
                                              }}
                                            </template>
                                            <template v-slot:prepend>
                                              <i style="font-size: 20px" class="fa fa-sticky-note-o"></i>
                                            </template>
                                          </v-combobox>
                                        </v-col>

                                        <v-col cols="12" md="6" sm="6">
                                          <!-- -----------------------------  fecha  -->
                                          <v-text-field dense outlined type="date" label="Fecha:"
                                            v-model="conciliacionBancaria.fecha" :rules="[$rules.required]"
                                            @blur="buscarDatosConciliacion">
                                            <template v-slot:prepend>
                                              <i style="font-size: 20px" class="fa fa-calendar"></i>
                                            </template>
                                          </v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-col>

                                    <v-col cols="12" md="6" sm="6">
                                      <v-container>
                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">
                                            <v-btn rounded v-on="on" dark @click.prevent="
                                              dialogNewNotaCredito = true
                                            " color="red lighten-2">
                                              <i style="font-size: 20px" class="fa fa-credit-card"></i>
                                              <!-- fab x-small dark  -->
                                              Nota Crédito
                                            </v-btn>
                                          </template>
                                          <span>Nota Crédito</span>
                                        </v-tooltip>

                                        <v-tooltip top>
                                          <template v-slot:activator="{ on }">
                                            <v-btn rounded v-on="on" dark @click.prevent="
                                              dialogNewNotaDebito = true
                                            " color="green lighten-2">
                                              <i style="font-size: 20px" class="fa fa-credit-card-alt"></i>
                                              <!-- fab x-small dark  -->
                                              Nota Débito
                                            </v-btn>
                                          </template>
                                          <span>Nota Débito</span>
                                        </v-tooltip>
                                      </v-container>
                                      <!-- fab x-small dark  
  <v-tooltip top >
<template v-slot:activator="{ on }">
<v-btn rounded v-on="on"   @click.prevent="dialog =true" color="light-blue lighten-4" >
<i style="font-size: 20px;" class="fa fa-share-square-o"></i>
Importar</v-btn>

 </template>
          <span>Importar</span>
  </v-tooltip>-->
                                    </v-col>

                                    <v-col cols="12" md="12" sm="12" style="margin-top: -45px">
                                      <v-row>
                                        <!-- columna 1--------------------------------------->
                                        <v-col cols="12" md="6" sm="6">
                                          <v-card color="grey lighten-3">
                                            <v-container>
                                              <center>
                                                <h3>Valores estado de Banco</h3>
                                              </center>
                                              
                                              <!-- ----------------------------- Balance Anterior -->
                                              <!-- v-model="conciliacionBancaria.balanceanterior"  -->
                                              
                                              <VueAutonumeric v-show="disabledBalanceAnterior==true" autocomplete="off" label="Balance Anterior:" dense
                                                outlined :rules="[$rules.required]" 
                                                :value="getTasaMonedaSelect(conciliacionBancaria.balanceanterior)"
                                                :disabled="disabledBalanceAnterior"
                                                id="formdisabledInput"
                                                >
                                                <template v-slot:prepend>
                                                  <i style="font-size: 20px" class="fa fa-money"></i>
                                                </template>
                                              </VueAutonumeric>

                                              <VueAutonumeric v-show="disabledBalanceAnterior==false" autocomplete="off" label="Balance Anterior:" dense
                                                outlined :rules="[$rules.required]" 
                                                v-model="conciliacionBancaria.balanceanterior"
                                                :disabled="disabledBalanceAnterior"
                                                @blur="BlurBalanceAnterior"
                                                id="formdisabledInput"
                                                >
                                                <template v-slot:prepend>
                                                  <i style="font-size: 20px" class="fa fa-money"></i>
                                                </template>
                                              </VueAutonumeric>



                                              <v-row>
                                                <v-col cols="12" md="6" sm="12">
                                                  <!-- ----------------------------- Cantidad -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(+)Depósitos Realizados(Cantidad)" dense outlined v-model="
                                                      conciliacionBancaria.cantidadDepositoBanaco
                                                    ">
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                                    </template>
                                                  </VueAutonumeric>

                                                  <!-- ----------------------------- valor -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(-)Cheques Realizados(Cantidad)" dense outlined v-model="
                                                      conciliacionBancaria.cantidadChequeBanco
                                                    ">
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                </v-col>
                                                <v-col cols="12" md="6" sm="12">
                                                  <!-- ----------------------------- ISR -->
                                                  <!-- v-model="conciliacionBancaria.totaldepositobanco" 
                                                     :value="getTasaMonedaSelect(conciliacionBancaria.totaldepositobanco)"
                                                  -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(+)Depósitos Realizados(valor)" dense outlined
                                                    @input="calcularTotalBanco" 
                                                    v-bind:value="getTasaMonedaSelect(conciliacionBancaria.totaldepositobanco)"
                                                    v-on:blur="conciliacionBancaria.totaldepositobanco=keyInput('totaldepositobanco',$event.target.value)"

                                                    >
                                                   
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-usd"></i>
                                                    </template>
                                                  </VueAutonumeric>

                                                  <!-- ----------------------------- ITBIS -->
                                                  <!--v-model="conciliacionBancaria.totalchequebanco"  -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(-)Cheques Realizados(valor)" dense outlined
                                                    @input="calcularTotalBanco" 
                                                    v-bind:value="getTasaMonedaSelect(conciliacionBancaria.totalchequebanco)"
                                                    v-on:blur="conciliacionBancaria.totalchequebanco=keyInput('totalchequebanco',$event.target.value)"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-usd"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                </v-col>
                                                        <!-- v-model.number="conciliacionBancaria.totalnotadebitobanco" -->
                                                <v-col cols="12" md="12" sm="12" style="margin-top: -24px">
                                                  <VueAutonumeric autocomplete="off" label="(-)Notas de Débito:" dense
                                                    outlined @input="calcularTotalBanco" 
                                                    v-bind:value="getTasaMonedaSelect(conciliacionBancaria.totalnotadebitobanco)"
                                                    v-on:blur="conciliacionBancaria.totalnotadebitobanco=keyInput('totalnotadebitobanco',$event.target.value)"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                <!--v-model.number="conciliacionBancaria.totalnotacreditobanco"  -->
                                                  <VueAutonumeric autocomplete="off" label="(+)Notas de Crédito:" dense
                                                    outlined @input="calcularTotalBanco" 
                                                    v-bind:value="getTasaMonedaSelect(conciliacionBancaria.totalnotacreditobanco)"
                                                    v-on:blur="conciliacionBancaria.totalnotacreditobanco=keyInput('totalnotacreditobanco',$event.target.value)"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>

                                                  <hr style="
                                                      border-bottom: 2px dashed
                                                        #212121;
                                                    " />
                                                  <br />
                                                  <!-- v-model.number="conciliacionBancaria.balancebanco" -->
                                                  <VueAutonumeric autocomplete="off" label="Balance según Banco:" dense
                                                    outlined disabled :value="getTasaMonedaSelect(conciliacionBancaria.balancebanco)"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                      <!-- v-model.number="conciliacionBancaria.balanceCorteMes" -->
                                                  <VueAutonumeric autocomplete="off" label="Balance al Corte Mes:" dense
                                                    outlined disabled :value="getTasaMonedaSelect(conciliacionBancaria.balanceCorteMes)"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                </v-col>
                                              </v-row>
                                            </v-container>
                                          </v-card>
                                        </v-col>
                                        <!-- fn columna 1----------------------------------->

                                        <!-- columna 2--------------------------------------->
                                        <v-col cols="12" md="6" sm="6">
                                          <v-card color="grey lighten-3">
                                            <v-container>
                                              <center>
                                                <h3>
                                                  Transacciones verificadas en
                                                  el sistema
                                                </h3>
                                              </center>

                                              <!-- ----------------------------- Balance Anterior -->
                                              <!-- v-model="conciliacionBancaria.balanceanterior"-->
                                              <VueAutonumeric autocomplete="off" label="Balance Anterior:" dense
                                                outlined disabled :value="getTasaMonedaSelect(conciliacionBancaria.balanceanterior)"
                                                id="formdisabledInput"
                                                >
                                                <template v-slot:prepend>
                                                  <i style="font-size: 20px" class="fa fa-money"></i>
                                                </template>
                                              </VueAutonumeric>
                                              <v-row>
                                                <v-col cols="12" md="6" sm="12">
                                                  <!------------------------------- Cantidad   -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(+)Depósitos Marcados(Cantidad)" dense outlined disabled
                                                    v-model="conciliacionBancaria.cantidadDepositoSistema"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                                    </template>
                                                  </VueAutonumeric>

                                                  <!------------------------------- valor -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(-)Cheques Marcados(Cantidad)" dense outlined disabled
                                                    v-model="conciliacionBancaria.cantidadChequeSistema"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-list-ol"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                </v-col>
                                                <v-col cols="12" md="6" sm="12">
                                                  <!-- ----------------------------- ISR -->
                                                  <!--  v-model="conciliacionBancaria.totaldeposito" -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(+)Depósitos Marcados(valor)" dense outlined disabled
                                                    :value="getTasaMonedaSelect(conciliacionBancaria.totaldeposito)"

                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-usd"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                  <!------------------------------- ITBIS -->
                                                  <!-- v-model="conciliacionBancaria.totalcheque" -->
                                                  <VueAutonumeric autocomplete="off" label="(-)Cheques Marcados(valor)"
                                                    dense outlined disabled :value="getTasaMonedaSelect(conciliacionBancaria.totalcheque)"
                                                    
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-usd"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                </v-col>

                                                <!-- v-model.number="conciliacionBancaria.totalnotadebito" -->
                                                <v-col cols="12" md="12" sm="12" style="margin-top: -24px">
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(-)Notas de Débito Registrada:" dense outlined disabled
                                                    :value="getTasaMonedaSelect(conciliacionBancaria.totalnotadebito)"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                  <!-- v-model.number="conciliacionBancaria.totalnotacredito" -->
                                                  <VueAutonumeric autocomplete="off"
                                                    label="(+)Notas de Crédito Registrada:" dense outlined disabled
                                                    :value="getTasaMonedaSelect(conciliacionBancaria.totalnotacredito)"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>

                                                  <hr style="border-bottom: 2px dashed#212121;" />
                                                  <br />
                                                  <!-- v-model.number="conciliacionBancaria.valfin" -->
                                                  <VueAutonumeric autocomplete="off" label="Total de datos verificados:"
                                                    dense outlined disabled :value="getTasaMonedaSelect(conciliacionBancaria.valfin)"
                                                    id="formdisabledInput"
                                                    >
                                                    <template v-slot:prepend>
                                                      <i style="font-size: 20px" class="fa fa-money"></i>
                                                    </template>
                                                  </VueAutonumeric>
                                                </v-col>
                                              </v-row>
                                            </v-container>
                                          </v-card>
                                        </v-col>
                                        <!-- fn columna 2----------------------------------->
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-tabs-items>
                              </v-card-text>
                            </v-card>
                          </v-tab-item>

                          <!-------------------------------------------- fn SubTab item 1 -->

                          <!-------------------------------------------- subTab item 2 -->
                          <v-tab-item :value="'tab-2'">
                            <v-card dense flat>
                              <v-card-text>
                                <v-tabs-items>
                                  <v-toolbar dense dark color="indigo">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <v-toolbar-title v-on="on">
                                          Cheque
                                        </v-toolbar-title>
                                      </template>
                                      <i style="font-size: 20px" dark dense class="fa fa-info-circle">
                                        <span> Cheque: </span></i>
                                    </v-tooltip>

                                    <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

                                    <v-spacer></v-spacer>

                                    <v-card-actions>
                                      <v-sheet id="scrolling-techniques-7" class="overflow-x-auto"
                                        :max-width="windowSize.width - 70" color="transparent">
                                        <v-btn-toggle>
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                              <v-btn id="boton-mini" @click="getAllcheque()" v-on="on" color="light-blue lighten-3" small dark>
                                                <i style="font-size: 20px" class="fa fa-refresh">
                                                </i>
                                                actualizar
                                              </v-btn>
                                            </template>
                                            <span>actualizar</span>
                                          </v-tooltip>
                                        </v-btn-toggle>
                                      </v-sheet>
                                    </v-card-actions>
                                  </v-toolbar>

                                  <!--------------------------------------------------- tb Lista cheque   chequesPK.secuencia -->
                                  <v-col cols="12" md="12" sm="12">
                                    <v-card>
                                      <v-data-table class="elevation-1 TbSize13 " dense show-select item-key="indx" 
                                      :footer-props="arrayPropiedases"
                                      @item-selected="obtenerDatoSelectCheque"
                                      @toggle-select-all="allSelcObtenerDatoSelectCheque"
                                      @pagination="cargarPaginacionAllCheque"
                                        @current-items="calcularTotalesCurrentItems" v-model="conciliacionBancaria.listaCheques" :headers="[
                                          {text: 'CÓDIGO',value: 'chequesPK.secuencia',width: 'auto',sortable: false ,class: ['no-gutters black--text'] },
                                          {text: 'FECHA',value: 'nuevaFecha',width: '110',sortable: false,class: ['no-gutters black--text']},
                                          {text: '#DOCUMENTO',value:'documentobancos.descripcion',width: '200',sortable: false,class: ['no-gutters black--text']},
                                          {text: 'BENEFICIARIO',value: 'beneficiario',width: '350',sortable: false,class: ['no-gutters black--text']},
                                          {text: 'VALOR',value: 'valor',width: 'auto',sortable: false,class: ['no-gutters black--text']},
                                          {text: 'CONCEPTO',value: 'concepto',width: 'auto',sortable: false,class: ['no-gutters black--text']},
                                        ]" :items="Listcheque" :items-per-page="15">
                                        <template v-slot:item.valor="props">
                                         <span style="font-size:13px; display:none;">{{ props.item.indx = Listcheque.indexOf(props.item)}}</span>
                                          <span>{{ currencyFormatter(getTasaMonedaSelect(props.item.valor))}}</span>
                                          <span style="font-size:13px; display:none;" v-if="!props.item.hasOwnProperty('marca') || props.item.marca==null">{{ props.item.marca = false}}</span>
                                        </template>
                                        <template v-slot:item.nuevaFecha="props">
                                          <span>{{
                                          FormatoFecha(props.item.nuevaFecha)
                                          }}</span>
                                        </template>

                                        <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom">
          <strong></strong>
        </td>

        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
        <td class="border-top-bottom">
          <strong></strong>
        </td>
        <td class="border-top-bottom">
          <strong></strong>
        </td>
        <td class="border-top-bottom">
          <strong></strong>
        </td>
        <td class="border-top-bottom">
          <strong class="d-flex justify-end" style="
                                                  font-size: 15px;
                                                  color: #00838f;
                                                ">Total:</strong>
        </td>
        <td class="border-total">
          <strong class="d-flex justify-start" style="
                                                  font-size: 15px;
                                                  color: #00838f;
                                                ">
            {{currencyFormatter(getTasaMonedaSelect(totales.totalCk))}}
          </strong>
        </td>
        <td class="border-top-bottom">
          <strong></strong>
        </td>
        <td class="border-top-bottom">
          <strong></strong>
        </td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%" class="white--text">
    <v-list-item-content>
      <span>VALOR TOTAL:{{currencyFormatter(getTasaMonedaSelect(totales.totalCk))}}</span>
    </v-list-item-content>
  </v-list-item>
</template>
</v-data-table>
</v-card>
</v-col>
<!--------------------------------------------------- fn tb lista cheque -->

<v-row>
  <!--  list cheque Col 1 -->
  <v-col cols="12" md="4" sm="4">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn rounded v-on="on" @click.prevent="
          dialogNewCheque = true
        " color="blue-grey lighten-4">
          <i style="font-size: 20px" class="fa fa-file-o"></i><!-- fab x-small dark  -->
          Crear un nuevo cheque
        </v-btn>
      </template>
      <span>Crear un nuevo cheque</span>
    </v-tooltip>
  </v-col>
  <!-- fn list cheque Col 1 -->

  <!--  list cheque Col 1 -->
  <v-col cols="12" md="8" sm="8">
    <v-row>
      <v-col cols="12" md="6" sm="6">
        <v-card color="grey lighten-3">
          <center>
            <b> Cheques Marcados</b>
          </center>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <!-- ----------------------------- cantidad -->
              <VueAutonumeric label="(Cantidad):" autocomplete="off" style="font-size: 13px" required outlined dense
                disabled v-model="conciliacionBancaria.cantidadChequeSistema"
                id="formdisabledInput"
                >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-list-ol"></i>
                </template>
              </VueAutonumeric>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <!-- ----------------------------- valor -->
              <!-- v-model="conciliacionBancaria.totalcheque" -->
              <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required outlined dense
                :value="getTasaMonedaSelect(conciliacionBancaria.totalcheque)" disabled

                id="formdisabledInput"
                >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-money"></i>
                </template>
              </VueAutonumeric>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6" sm="6">
        <v-card color="grey lighten-3">
          <center>
            <b> Cheques en Tránsito</b>
          </center>

          <v-row>
            <v-col cols="12" md="6" sm="6">
              <!-- ----------------------------- cantidad -->
              <VueAutonumeric label="(Cantidad):" autocomplete="off" style="font-size: 13px" required outlined dense
                v-model="totales.diferenciaCCk" disabled
                id="formdisabledInput"
                >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-list-ol"></i>
                </template>
              </VueAutonumeric>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <!-- ----------------------------- valor -->
              <!-- v-model="totales.diferenciaTCk" -->
              <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required outlined dense
                 :value="getTasaMonedaSelect(totales.diferenciaTCk)" disabled
                id="formdisabledInput"
                >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-money"></i>
                </template>
              </VueAutonumeric>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
  <!-- fn list cheque Col 1 -->
</v-row>
</v-tabs-items>
</v-card-text>
</v-card>
</v-tab-item>

<!-------------------------------------------- fn SubTab item 2 -->

<!-------------------------------------------- subTab item 3 -->
<v-tab-item :value="'tab-3'">
  <v-card dense flat>
    <v-card-text>
      <v-tabs-items>
        <v-toolbar dense dark color="indigo">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on">
                Depósito
              </v-toolbar-title>
            </template>
            <i style="font-size: 20px" dark dense class="fa fa-info-circle">
              <span> Depósito: </span></i>
          </v-tooltip>

          <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width - 70"
              color="transparent">
              <v-btn-toggle>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini"  @click="getAlldepositos()" v-on="on" color="light-blue lighten-3" small dark > 
                      <i style="font-size: 20px" class="fa fa-refresh">
                      </i>
                      actualizar
                    </v-btn>
                  </template>
                  <span>actualizar</span>
                </v-tooltip>
              </v-btn-toggle>
            </v-sheet>
          </v-card-actions>
        </v-toolbar>

        <!--------------------------------------------------- tb Lista deposito  depositosbancoPK.secuencia  -->
        <v-col cols="12" md="12" sm="12">
          <v-card>
            <v-data-table class="elevation-1" :footer-props="arrayPropiedases" 
            @item-selected="obtenerDatoSelectDeposito"
            @toggle-select-all="allSelcObtenerDatoSelectDeposito"
            @pagination="cargarPaginacionAllDeposito"

            v-model="conciliacionBancaria.listaDepositos" dense show-select @current-items="calcularTotalesCurrentItems" item-key="indx" :headers="[
              {text: 'CÓDIGO',value: 'depositosbancoPK.secuencia',width: 'auto',sortable: false, class: ['no-gutters black--text']},
              {text: 'FECHA',value: 'nuevaFecha',width: '110',sortable: false, class: ['no-gutters black--text']},
              {text: '#DOCUMENTO',value:'documentobancos.descripcion',width: 'auto',sortable: false, class: ['no-gutters black--text']},
              {text: 'VALOR',value: 'valor',width: 'auto',sortable: false, class: ['no-gutters black--text']},
              {text: 'CONCEPTO',value: 'concepto',width: 'auto',sortable: false, class: ['no-gutters black--text']},
            ]" :items="Listdeposito" :items-per-page="15">
              <template v-slot:item.valor="props">
                <span style="font-size:13px; display:none;">{{ props.item.indx = Listdeposito.indexOf(props.item)}}</span>
                <span>
                  {{currencyFormatter(getTasaMonedaSelect(props.item.valor))}}</span>
                <span style="font-size:13px; display:none;" v-if="!props.item.hasOwnProperty('marca') || props.item.marca==null">{{ props.item.marca = false}}</span>
              </template>

              <template v-slot:item.nuevaFecha="props">
                <span>{{
                FormatoFecha(props.item.nuevaFecha)
                }}</span>
              </template>

              <template v-slot:body.append v-if="windowSize.width > 600">
                <tr>
                  <td class="border-top-bottom">
                    <strong></strong>
                  </td>

                  <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
                  <td class="border-top-bottom">
                    <strong></strong>
                  </td>
                  <td class="border-top-bottom">
                    <strong></strong>
                  </td>
                  <td class="border-top-bottom">
                    <strong class="d-flex justify-end" style="
                                                  font-size: 15px;
                                                  color: #00838f;
                                                ">Total:</strong>
                  </td>
                  <td class="border-total">
                    <strong class="d-flex justify-start" style="
                                                  font-size: 15px;
                                                  color: #00838f;
                                                ">
                      {{currencyFormatter(getTasaMonedaSelect(totales.totalDp))}}
                    </strong>
                  </td>
                  <td class="border-top-bottom">
                    <strong></strong>
                  </td>
                  <td class="border-top-bottom">
                    <strong></strong>
                  </td>
                </tr>
              </template>

              <template v-slot:footer v-if="windowSize.width <= 600">
                <v-list-item style="font-size: 70%" class="white--text">
                  <v-list-item-content>
                    <span>VALOR TOTAL:{{currencyFormatter(getTasaMonedaSelect(totales.totalDp))}}</span>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <!--------------------------------------------------- fn tb lista deposito -->

        <v-row>
          <!--  list deposito Col 1 -->
          <v-col cols="12" md="4" sm="4">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn rounded v-on="on" @click.prevent="
                  dialogNewDepositos = true
                " color="blue-grey lighten-4">
                  <i style="font-size: 20px" class="fa fa-share-square-o"></i><!-- fab x-small dark  -->
                  Crear un nuevo Depósito
                </v-btn>
              </template>
              <span>Crear un nuevo Depósito</span>
            </v-tooltip>
          </v-col>
          <!-- fn list deposito Col 1 -->

          <!--  list deposito Col 2 -->
          <v-col cols="12" md="8" sm="8">
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <v-card color="grey lighten-3">
                  <center>
                    <b> Depósito Marcados</b>
                  </center>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <!-- ----------------------------- cantidad -->
                      <VueAutonumeric label="(Cantidad):" autocomplete="off" style="font-size: 13px" required outlined
                        dense disabled v-model="conciliacionBancaria.cantidadDepositoSistema"
                        id="formdisabledInput"
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-list-ol"></i>
                        </template>
                      </VueAutonumeric>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <!-- ----------------------------- valor -->
                      <!--v-model="conciliacionBancaria.totaldeposito"  -->
                      <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required outlined
                        dense disabled :value="getTasaMonedaSelect(conciliacionBancaria.totaldeposito)"

                        id="formdisabledInput"
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-money"></i>
                        </template>
                      </VueAutonumeric>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-card color="grey lighten-3">
                  <center>
                    <b> Depósito en Tránsito</b>
                  </center>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <!-- ----------------------------- cantidad -->
                      <VueAutonumeric label="(Cantidad):" autocomplete="off" style="font-size: 13px" required outlined
                        dense v-model="totales.diferenciaCDp" disabled
                        id="formdisabledInput"
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-list-ol"></i>
                        </template>
                      </VueAutonumeric>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <!-- ----------------------------- valor -->
                      <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px" required outlined
                        dense disabled :value="getTasaMonedaSelect(totales.diferenciaTDp)"
                        id="formdisabledInput"
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px" class="fa fa-money"></i>
                        </template>
                      </VueAutonumeric>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <!-- fn list deposito Col 2 -->
        </v-row>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</v-tab-item>

<!-------------------------------------------- fn SubTab item 3 -->
</v-tabs-items>
</v-card-text>
</v-card>
</v-tab-item>
<!-------------------------------------------- fn Tab item 1 -->

<!-------------------------------------------- Tab item 2 -->
<v-tab-item :value="'tab-2'">
  <v-card flat>
    <v-card-text>
      <v-card color="grey lighten-3">
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <input type="number" v-model="conciliacionBancaria.codigo" style="display: none" />
            <v-row>
              <!-- columna 1 ------------------------------>
              <v-col cols="12" md="4" sm="4">
                <!-- -----------------------------  Fecha Inicial:  -->
                <v-text-field dense outlined type="date" label="Fecha Inicial:" v-model="filtros.fecini"
                  @blur="buscarConciliaciones">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!-------------------------------------------------------- Cuenta Bancaria -->
                <v-combobox v-model="filtros.cuenta" label="Cuenta Bancaria:" required outlined dense autocomplete="off"
                  :items="RCuentaBanco" item-text="cuenta" @focus="cargarCuentaBanco" @change="buscarConciliaciones"
                  @blur="SelctCbCuentaBanco2" v-on:blur="buscarConciliaciones" >
                  <template slot="item" slot-scope="item">
                    {{ item.item.cuenta }}-{{
                    item.item.banco.nombre
                    }}
                  </template>
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>
              </v-col>
              <!-- fn columna 1 ------------------------------>

              <!-- columna 2 ------------------------------>
              <v-col cols="12" md="4" sm="4">
                <!-- -----------------------------  Fecha Inicial:  -->
                <v-text-field dense outlined type="date" label="Fecha Inicial:" v-model="filtros.fecfin"
                  @blur="buscarConciliaciones">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <!-------------------------------------------------------- Cuenta Bancaria -->
                <v-combobox v-model="filtros.banco" label="Banco:" required outlined dense autocomplete="off"
                  :items="RBanco" item-text="nombre" @focus="cargarBanco" @blur="SelctCbBanco"
                  @change="buscarConciliaciones">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-university"></i>
                  </template>
                </v-combobox>
              </v-col>
              <!-- fn columna 2 ------------------------------>

              <!--  columna 3 ------------------------------>

              <v-col cols="12" md="4" sm="4">
                <!-- -----------------------------  Secuencia  -->
                <v-text-field type="number" label="Secuencia " dense outlined v-model.number="filtros.secCon"
                  @blur="buscarConciliaciones">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-barcode"></i>
                  </template>
                </v-text-field>
              </v-col>
              <!-- fn columna 3 ------------------------------>
            </v-row>
          </v-form>
        </v-container>
      </v-card>

      <!--------------------------------------------------- Filtro Busqueda -->
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-data-table :footer-props="arrayPropiedases" class="elevation-1" dense :headers="[
            {text: 'Banco',value: 'banco.nombre',width: 'auto',class: ['no-gutters black--text']},
            {text: 'Cuentas Bancos',value: 'cuentaBanco.cuenta',width: 'auto',class: ['no-gutters black--text'] },
            {text: 'Fecha',value: 'nuevaFecha',width: 'auto',class: ['no-gutters black--text'] },
            {text: 'Balance Inicial',value: 'balanceanterior',width: 'auto',class: ['no-gutters black--text']},
            {text: 'Balance Según Banco',value: 'balancebanco',width: 'auto',class: ['no-gutters black--text'] },
            {text: 'Balance en Libros',value: 'valfin',width: 'auto',class: ['no-gutters black--text']},
            {text: 'Secuencia  ',value: 'secuencia',width: 'auto',class: ['no-gutters black--text']},
            {text: 'ACCIÓN',value: 'action',width: 'auto',class: ['no-gutters black--text']},
          ]" :items="RLisConciliacion" :items-per-page="-1">
            <template v-slot:item.action="{ item }">
              <!-- <v-btn fab @click.prevent="deleFact(item)" @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
              <v-btn fab x-small color="deep-purple accent-2" @click.prevent="imprimir(item)"><i style="font-size: 20px"
                  class="fa fa-print"></i></v-btn>
              <v-btn fab x-small color="red darken-3" @click.prevent="anular(item)"><i style="font-size: 20px"
                  class="fa fa-trash-o"></i></v-btn>
            </template>
            <template v-slot:item.balanceanterior="props">
              <span>{{
              currencyFormatter(props.item.balanceanterior)
              }}</span>
            </template>
            <template v-slot:item.balancebanco="props">
              <span>{{
              currencyFormatter(props.item.balancebanco)
              }}</span>
            </template>
            <template v-slot:item.valfin="props">
              <span>{{
              currencyFormatter(props.item.valfin)
              }}</span>
            </template>
            <template v-slot:item.nuevaFecha="props">
              <span>{{
              FormatoFecha(props.item.nuevaFecha)
              }}</span>
            </template>

            <template v-slot:body.append v-if="windowSize.width > 600">
              <tr>
                <td class="border-top-bottom">
                  <strong></strong>
                </td>

                <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
                <td class="border-top-bottom">
                  <strong></strong>
                </td>
                <td class="border-top-bottom">
                  <strong class="d-flex justify-end" style="font-size: 15px; color: #00838f">Total:</strong>
                </td>
                <td class="border-total">
                  <strong class="d-flex justify-center" style="font-size: 15px; color: #00838f">
                    {{ currencyFormatter(0) }}
                  </strong>
                </td>
                <td class="border-total">
                  <strong class="d-flex justify-center" style="font-size: 15px; color: #00838f">
                    {{ currencyFormatter(0) }}
                  </strong>
                </td>
                <td class="border-total">
                  <strong class="d-flex justify-center" style="font-size: 15px; color: #00838f">
                    {{ currencyFormatter(0) }}
                  </strong>
                </td>
                <td class="border-top-bottom">
                  <strong></strong>
                </td>
                <td class="border-top-bottom">
                  <strong></strong>
                </td>
              </tr>
            </template>

            <template v-slot:footer v-if="windowSize.width <= 600">
              <v-list-item style="font-size: 70%" class="white--text">
                <v-list-item-content>
                  <span>CANTIDAD PEDIDA:5</span>
                  <span>CANTIDAD EXISTENCIA:5</span>
                  <span>VENTAS:5</span>
                  <span>COSTO:5</span>
                  <span>IMPORTE:5</span>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!--------------------------------------------------- fn tb lista cheque -->
    </v-card-text>
  </v-card>
</v-tab-item>
<!-------------------------------------------- fn Tab item 2 -->
</v-tabs-items>
</v-card>
</v-col>
<!-------------------------------------------- fn Tab item 3 -->

<!-- fn Tab panellll ---------->
</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
  <div class="overflow-y-auto" v-bind:style="{
    'max-height': this.windowSize.height - 96 + 'px',
    'max-width': '325px',
    width: '325px',
  }">
    <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
  </div>
</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
  transition="fab-transition">
  <v-card>
    <v-toolbar flat color="#BDBDBD">
      <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
            <i style="font-size: 25px" class="fa fa-print"> </i>
            Listado
          </v-btn>
        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
        <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table :footer-props="arrayPropiedases" :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
          <template v-slot:item.porciento="props">
            <span>{{ currencyFormatter(props.item.porciento) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec == 'anularConciliacion'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px" class="fa fa-check"></i> Si
      </v-btn>

       <v-btn v-if="notif.Exec == 'imprimirConciliacion'" @click.prevent="imprimirConciliacion(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px" class="fa fa-print"></i> Si
      </v-btn>

      <v-btn @click.prevent="(notif.estado = !notif.estado),(conciliacionImprimir=null)" small>
        <i style="font-size: 20px" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif -->

<!------------otras funciones---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

<!-- ------------------------------------------------------ Modal nota de credito -->

<v-dialog v-model="dialogNewNotaCredito" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #3f51b5" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="dialogNewNotaCredito = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsNotaCredito />
    </v-list>
  </v-card>
</v-dialog>
<!-- fn Nota de credito ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal nota de debito -->

<v-dialog v-model="dialogNewNotaDebito" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #3f51b5" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="dialogNewNotaDebito = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsNotaDebito />
    </v-list>
  </v-card>
</v-dialog>
<!-- fn Nota de debito ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal Cheque -->

<v-dialog v-model="dialogNewCheque" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #3f51b5" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="dialogNewCheque = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsCheques />
    </v-list>
  </v-card>
</v-dialog>
<!-- fn Cheque ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal deposito -->

<v-dialog v-model="dialogNewDepositos" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card>
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #3f51b5" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="dialogNewDepositos = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsDepositos />
    </v-list>
  </v-card>
</v-dialog>
<!-- fn Deposito ----------------------------------------------------------------------------------------------->

<!-- fn Modales -------------------------------------------------------------------------------------------------->
</div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ComponentsCheques from "@/Bancos/Trans/Cheques.vue";
import ComponentsDepositos from "@/Bancos/Trans/depositos.vue";
import ComponentsNotaCredito from "@/Bancos/Trans/NotasCredito.vue";
import ComponentsNotaDebito from "@/Bancos/Trans/NotasDebito.vue";
import ContenidoBase from "../../components/ContenidoBase";
import { NumeroALetras } from "@/js/NumeroALetras.js";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ComponentsCheques,
    ComponentsDepositos,
    ComponentsNotaCredito,
    ComponentsNotaDebito,
    ContenidoBase,
  },
  created() {
    var json = {
      titulo: "Conciliación Bancaria",
      descripcion: "Conciliación Bancaria",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "BANConciliacionBancariaNew",
        "BANConciliacionBancariaSave",
        "BANConciliacionBancariaRemove",
        "BANConciliacionBancariaAnular",
        "BANConciliacionBancariaPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANConciliacionBancariaNew", this.new);
    // EventBus.$on("BANConciliacionBancariaSave", this.save);
    // EventBus.$on("BANConciliacionBancariaRemove", this.remove);
    // EventBus.$on("BANConciliacionBancariaAnular", this.remove);
    // EventBus.$on("BANConciliacionBancariaPrintList", this.imprimirList);
    EventBus.$emit("loading", false);
    EventBus.$on("emitBANConciliacionBancaria", this.datostabla);
   // EventBus.$on("onResize", this.onResize);
    this.cargarTodo();
    this.getMonedaBase()
  },

  data() {
    return {
      dialog: false,
      dialogNewCheque: false,
      dialogNewDepositos: false,
      dialogNewNotaCredito: false,
      dialogNewNotaDebito: false,
      RCuentaBanco: [],
      Rmoneda:[],
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      RBanco: [],
      totales: {
        totalCk: 0.0,
        totalDp: 0.0,
        cantidadCk: 0,
        cantidadDp: 0,
        diferenciaCDp: 0,
        diferenciaTDp: 0.0,
        diferenciaCCK: 0,
        diferenciaTCk: 0.0,
        totalCkSelec:0.0,
        totalDpSelec:0.0
      },
      ListaTiposClientes: [],
      RLisConciliacion: [],
      Listcheque: [],
      Listdeposito: [],
      windowSize: { width: "auto", height: "auto" },
      disabledBalanceAnterior:false,
      conciliacionImprimir:null,
      conciliacionBancaria: {
        secuencia: 0,
        activo: true,
        borrado: false,
        fecha: fechaNueva(),
        valini: 0.0,
        valfin: 0.0,
        totalnotadebito: 0.0,
        totalcheque: 0.0,
        totalnotacredito: 0.0,
        totaldeposito: 0.0,
        totalnotadebitobanco: 0.0,
        totalchequebanco: 0.0,
        totalnotacreditobanco: 0.0,
        totaldepositobanco: 0.0,
        balancebanco: 0.0,
        chequetransito: 0.0,
        depositotransito: 0.0,
        chequeEmitidoMes: 0.0,
        depositoEmitidoMes: 0.0,
        chequeTrasitoMes: 0.0,
        cantidadChequeBanco: 0.0,
        cantidadChequeSistema: 0.0,
        cantidadDepositoBanaco: 0.0,
        cantidadDepositoSistema: 0.0,
        cantidadChequesEmitidos: 0.0,
        cantidadDepositoEmitidos: 0.0,
        cantidadChequeTransito: 0.0,
        balanceanterior: 0.0,
        balanceCorteMes: 0.0,
        banco: null,
        cuentaBanco: null,
        conciliacionDetalleList: [],
        moneda:null,
        tasa:0
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false,
      },

      tab: "tab-2",
      Subtab: null,
      text: "",
      valid: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:",
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?",
      },
      filtros: {
        fecini: fechaNueva(),
        fecfin: fechaNueva(),
        secCon: 0,
        cuenta: null,
        banco: null,
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },

      aalert: {
        estado: false,
        color: null,
      },
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    NumeroALetras,
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
    onResize(e) {
      //console.log("imprii ");
      //console.log(e);
     // this.windowSize = e;
    },
    BlurBalanceAnterior(){
      if(this.conciliacionBancaria.balanceanterior>0){this.disabledBalanceAnterior=true}else{this.disabledBalanceAnterior=false}
    },
    async buscarDatosConciliacion() {
      this.totales = {
        totalCk: 0.0,
        totalDp: 0.0,
        cantidadCk: 0,
        cantidadDp: 0,
        iferenciaCDp: 0,
        diferenciaTDp: 0.0,
        diferenciaCCK: 0,
        diferenciaTCk: 0.0,
        totalCkSelec:0.0,
        totalDpSelec:0.0
      };
      if (this.conciliacionBancaria.fecha == null) {return;}
      if (this.conciliacionBancaria.cuentaBanco == null) {return;}
      if (!this.conciliacionBancaria.cuentaBanco.hasOwnProperty('codigo')) {return; }

      EventBus.$emit("loading", true);
      //EventBus.$emit("loading", true);
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/conciliacion-bancaria/findDatosConciliacion/" +
          this.conciliacionBancaria.fecha +
          "/" +
          this.conciliacionBancaria.cuentaBanco.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          var MONEDA=this.conciliacionBancaria.moneda
          var TASA=this.conciliacionBancaria.tasa
          this.conciliacionBancaria = res.data;
          this.conciliacionBancaria.moneda = MONEDA;
          this.conciliacionBancaria.tasa = TASA;
          // this.conciliacionBancaria.fecha = new Date(res.data.fecha).toJSON().split("T")[0];
          this.conciliacionBancaria.fecha = res.data.nuevaFecha;
          if(res.data.balanceanterior>0){this.disabledBalanceAnterior=true}else{this.disabledBalanceAnterior=false}
     
          var _this = this;
        setTimeout(function () {EventBus.$emit("loading", false);}, 500);
          
        });

      (async () => {
        const restrList = await this.getAllcheque();
        const restrList2 = await this.getAlldepositos();
        var _this = this;
        setTimeout(function () {
          _this.calcularTotales();
          EventBus.$emit("loading", false);
      }, 500);

      })() 
      /*this.totales.diferenciaCDp =this.totales.cantidadDp -this.conciliacionBancaria.cantidadDepositoSistema;
      this.totales.diferenciaCCk =this.totales.cantidadCk -this.conciliacionBancaria.cantidadChequeSistema;
      this.totales.diferenciaTDp =this.totales.totalDp - this.conciliacionBancaria.totaldeposito;
      this.totales.diferenciaTCk =this.totales.totalCk - this.conciliacionBancaria.totalcheque;*/
      
    },


    async getAllcheque(){

      return  new Promise((resolve, reject) => {
  (async () => {
  this.Listcheque = [];
  this.conciliacionBancaria.listaCheques=[]
  this.totales.totalCk= 0.0
  this.totales.cantidadCk=0  
  this.totales.diferenciaCCk=0
  this.$axios.get(this.$hostname +this.$hName +"/conciliacion-bancaria/findDatosConciliacion/allcheques/" +
          this.conciliacionBancaria.fecha +"/" +this.conciliacionBancaria.cuentaBanco.codigo,
          { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.totales.cantidadCk = res.data.length;
          this.totales.diferenciaCCk =this.totales.cantidadCk -this.conciliacionBancaria.cantidadChequeSistema;
          if(res.data.length>0){
            this.Listcheque=JSON.parse(JSON.stringify(res.data))
            var _this = this;
      setTimeout(function () {
        var ObjProRow = _this.Listcheque.filter((ee) => ee.marca == true );
          if (ObjProRow.length > 0) { _this.conciliacionBancaria.listaCheques=JSON.parse(JSON.stringify(ObjProRow))}
       }, 600);

          res.data.forEach(async(ck) => {
            // if (ck.marca) {this.conciliacionBancaria.listaCheques.push(ck); } else {this.Listcheque.push(ck);}
            this.totales.totalCk += ck.valor;
          });
          }  

          resolve(this.totales.totalCk)

        });
      })() 

    });

},


async getAlldepositos(){
  return  new Promise((resolve, reject) => {
  (async () => { 
  this.Listdeposito = [];
  this.conciliacionBancaria.listaDepositos=[]
  this.totales.cantidadDp = 0;
  this.totales.totalDp=0
 
  this.$axios.get(this.$hostname +this.$hName +"/conciliacion-bancaria/findDatosConciliacion/alldepositos/"
  +this.conciliacionBancaria.fecha +"/" +this.conciliacionBancaria.cuentaBanco.codigo,
          { headers: this.$store.getters.GetheadersAxios } )
        .then((res) => {
          this.totales.cantidadDp = res.data.length;
          if(res.data.length>0){ 
            this.Listdeposito=JSON.parse(JSON.stringify(res.data))

            var _this = this;
      setTimeout(function () {
        var ObjProRow = _this.Listdeposito.filter((ee) => ee.marca == true );
          if (ObjProRow.length > 0) { _this.conciliacionBancaria.listaDepositos=JSON.parse(JSON.stringify(ObjProRow))}
       }, 300);

          }
          
          res.data.forEach((dp) => {
            // if (dp.marca) {this.conciliacionBancaria.listaDepositos.push(dp);} else {this.Listdeposito.push(dp);}
            this.totales.totalDp += dp.valor;
          });
          resolve(this.totales.totalDp)
        });
      })() 

    });
},




    cargarCuentaBanco() {
      this.$axios
        .get(this.$hostname + this.$hName + "/cuentabanco/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCuentaBanco = res.data));
    },
    
    SelctCbCuentaBanco() {

      var _this = this;
      setTimeout(function () {
        if (_this.conciliacionBancaria.cuentaBanco == null) {
          _this.conciliacionBancaria.cuentaBanco = "";
        }

        if (!_this.conciliacionBancaria.cuentaBanco.hasOwnProperty('codigo')) {
          _this.conciliacionBancaria.cuentaBanco = "";
          _this.conciliacionBancaria.cuentaBanco = null;
          return;
        }
      }, 300);


    },
    SelctCbCuentaBanco2() {
      var _this = this;
      setTimeout(function () {
        if (_this.filtros.cuenta== null) {
          _this.filtros.cuenta= "";
        }

        if (!_this.filtros.cuenta.hasOwnProperty('codigo')) {
          _this.filtros.cuenta= "";
          _this.filtros.cuenta= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);

    },

    cargarBanco() {
      this.$axios
        .get(this.$hostname + this.$hName + "/bancos/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RBanco = res.data));
    },
    SelctCbBanco() {
      if (this.Bancos.banco == null) {
        this.Bancos.banco = "";
      }
      if (this.Bancos.banco.codigo == undefined) {
        this.alerta("Seleccione un Banco", "error");
        this.Bancos.banco = "";
        return;
      }
    },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
    async cargarTodo() { },
    datostabla(e) {
      this.conciliacionBancaria = e;
      this.acti_tb_data = false;
    },
    save() {
/*Cheques Marcados:conciliacionBancaria.totalcheque
Cheques en Tránsito:totales.diferenciaTCk
---
Depósito en Tránsito:totales.diferenciaCDp
Depósito Marcados:conciliacionBancaria.totaldeposito*/
      
      if (this.$refs.form.validate()) {
        EventBus.$emit("loading", true);
        this.conciliacionBancaria.chequetransito=this.totales.diferenciaTCk
        this.conciliacionBancaria.depositotransito=this.totales.diferenciaTDp
        var OBJDATA=JSON.parse(JSON.stringify(this.conciliacionBancaria))
        OBJDATA.valini=this.conciliacionBancaria.balanceanterior
        OBJDATA.depositoEmitidoMes=this.totales.totalDp
        OBJDATA.chequeEmitidoMes=this.totales.totalCk
        /*OBJDATA.valfin=this.conciliacionBancaria.balancebanco*/
        // this.conciliacionBancaria
        this.$axios
          .post(
            this.$hostname + this.$hName + "/conciliacion-bancaria/salvar",
            OBJDATA,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) =>{
            this.alerta("Dato guardado correctamente!", "bien")
            var _this = this;
        setTimeout(function () {
          _this.conciliacionImprimir=res.data;
          _this.NotifMessageImprimir()
        }, 500);

          })
          .catch((error) => this.alerta("Dato no guardado", "error"));
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },


async calcularTotalesCurrentItems() {
      
      (async () => {
//Subtab=='tab-2' ||  Subtab=='tab-3'
if(this.Subtab=='tab-2' ||  this.Subtab=='tab-3'){
      var totalCheque = 0,
        totalDeposito = 0;
      if (this.conciliacionBancaria != null) {
if(this.conciliacionBancaria.hasOwnProperty("listaCheques") && this.conciliacionBancaria.listaCheques.length>0){
  await Promise.all(this.conciliacionBancaria.listaCheques.map(async (ck) => {
          totalCheque += ck.valor;
        }));

        this.conciliacionBancaria.cantidadChequeSistema =this.conciliacionBancaria.listaCheques.length;
        this.conciliacionBancaria.cantidadChequeBanco = this.conciliacionBancaria.listaCheques.length;
}else{ 
  this.conciliacionBancaria.cantidadChequeSistema =0 
  this.conciliacionBancaria.cantidadChequeBanco = 0
}




if( this.conciliacionBancaria.hasOwnProperty("listaDepositos") && this.conciliacionBancaria.listaDepositos.length>0){
    
  await Promise.all(this.conciliacionBancaria.listaDepositos.map(async (d) => {
          totalDeposito += d.valor;
        }));

        this.conciliacionBancaria.cantidadDepositoSistema = this.conciliacionBancaria.listaDepositos.length;
        this.conciliacionBancaria.cantidadDepositoBanaco = this.conciliacionBancaria.listaDepositos.length;
      }else{
        this.conciliacionBancaria.cantidadDepositoSistema = 0
        this.conciliacionBancaria.cantidadDepositoBanaco =0
      }

        this.conciliacionBancaria.totalcheque = totalCheque;
        this.conciliacionBancaria.totaldeposito = totalDeposito;
        this.conciliacionBancaria.totaldepositobanco = totalDeposito;
        this.conciliacionBancaria.totalchequebanco = totalCheque;

        this.totales.diferenciaCDp = this.totales.cantidadDp -this.conciliacionBancaria.cantidadDepositoSistema;
        this.totales.diferenciaCCk =this.totales.cantidadCk - this.conciliacionBancaria.cantidadChequeSistema;
        this.totales.diferenciaTDp =this.totales.totalDp - this.conciliacionBancaria.totaldeposito;
        this.totales.diferenciaTCk =this.totales.totalCk - this.conciliacionBancaria.totalcheque;
        this.calcularTotalSistema();
      }

    }

    })()

   /* this.totales.totalCkSelec=0.0
    this.totales.totalDpSelec=0.0
    console.log('---Hola---')
    console.log(this.conciliacionBancaria.listaCheques)
    console.log(this.conciliacionBancaria.listaCheques)*/
    

    },

    

    async calcularTotales() {
      
      (async () => {
      var totalCheque = 0,
        totalDeposito = 0;
      if (this.conciliacionBancaria != null) {
if(this.conciliacionBancaria.hasOwnProperty("listaCheques") && this.conciliacionBancaria.listaCheques.length>0){
        this.conciliacionBancaria.listaCheques.forEach((ck) => {
          totalCheque += ck.valor;
        });

        this.conciliacionBancaria.cantidadChequeSistema =this.conciliacionBancaria.listaCheques.length;
        this.conciliacionBancaria.cantidadChequeBanco = this.conciliacionBancaria.listaCheques.length;
}else{ 
  this.conciliacionBancaria.cantidadChequeSistema =0 
  this.conciliacionBancaria.cantidadChequeBanco = 0
}




if( this.conciliacionBancaria.hasOwnProperty("listaDepositos") && this.conciliacionBancaria.listaDepositos.length>0){
        this.conciliacionBancaria.listaDepositos.forEach((d) => {
          totalDeposito += d.valor;
        });

        this.conciliacionBancaria.cantidadDepositoSistema = this.conciliacionBancaria.listaDepositos.length;
        this.conciliacionBancaria.cantidadDepositoBanaco = this.conciliacionBancaria.listaDepositos.length;
      }else{
        this.conciliacionBancaria.cantidadDepositoSistema = 0
        this.conciliacionBancaria.cantidadDepositoBanaco =0
      }

        this.conciliacionBancaria.totalcheque = totalCheque;
        this.conciliacionBancaria.totaldeposito = totalDeposito;
        this.conciliacionBancaria.totaldepositobanco = totalDeposito;
        this.conciliacionBancaria.totalchequebanco = totalCheque;

        this.totales.diferenciaCDp = this.totales.cantidadDp -this.conciliacionBancaria.cantidadDepositoSistema;
        this.totales.diferenciaCCk =this.totales.cantidadCk - this.conciliacionBancaria.cantidadChequeSistema;
        this.totales.diferenciaTDp =this.totales.totalDp - this.conciliacionBancaria.totaldeposito;
        this.totales.diferenciaTCk =this.totales.totalCk - this.conciliacionBancaria.totalcheque;
        this.calcularTotalSistema();
      }
    })()

   /* this.totales.totalCkSelec=0.0
    this.totales.totalDpSelec=0.0
    console.log('---Hola---')
    console.log(this.conciliacionBancaria.listaCheques)
    console.log(this.conciliacionBancaria.listaCheques)*/
    

    },


    calcularTotalSistema() {
      var td = 0,
        tc = 0,
        tnc = 0,
        tnd = 0,
        b = 0;
      b = this.conciliacionBancaria.balanceanterior;
      tc = this.conciliacionBancaria.totalcheque;
      td = this.conciliacionBancaria.totaldeposito;
      tnc = this.conciliacionBancaria.totalnotacredito;
      tnd = this.conciliacionBancaria.totalnotadebito;
       this.conciliacionBancaria.balancebanco = b + td - tc - tnd + tnc;
      this.conciliacionBancaria.valfin = b + td - tc - tnd + tnc;
      this.conciliacionBancaria.balanceCorteMes =
        this.conciliacionBancaria.balancebanco -
        this.conciliacionBancaria.valfin;
    },
    calcularTotalBanco() {
      var td = 0,
        tc = 0,
        tnc = 0,
        tnd = 0,
        b = 0;
      b = this.conciliacionBancaria.balanceanterior;
      tc = this.conciliacionBancaria.totalchequebanco;
      td = this.conciliacionBancaria.totaldepositobanco;
      tnc = this.conciliacionBancaria.totalnotacreditobanco;
      tnd = this.conciliacionBancaria.totalnotadebitobanco;
      this.conciliacionBancaria.balancebanco = b + td - tc - tnd + tnc;
      this.conciliacionBancaria.balanceCorteMes =
        this.conciliacionBancaria.balancebanco -
        this.conciliacionBancaria.valfin;
    },
    NotifMessage() {
      if (
        this.conciliacionBancaria.codigo != 0 &&
        this.conciliacionBancaria.codigo != null
      ) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "anularConciliacion",
        };

        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione un conciliacion para anular", "error");
      }
    },

    NotifMessageImprimir() {
  
        var notif = {
          estado: true,
          nombre: "¿ Desea Imprimir este registro?",
          Exec: "imprimirConciliacion",
        };
        this.Exec_notif(notif);
    },

    imprimirConciliacion(){
        this.imprimir(this.conciliacionImprimir)
        var _this = this;
        setTimeout(function () {
          _this.conciliacionImprimir=null;
        }, 500);
    },



    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e));

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },
    buscarConciliaciones() {
      var sec = 0,
        cuent = 0,
        ban = 0;
      if (this.filtros.fecini == null) {
        this.filtros.fecini = this.fechaNueva();
      }
      if (this.filtros.fecfin == null) {
        this.filtros.fecfin = this.fechaNueva();
      }
      if (this.filtros.secCon != null && this.filtros.secCon > 0) {
        sec = this.filtros.secCon;
      }
      if (this.filtros.cuenta != null && this.filtros.cuenta.hasOwnProperty('codigo')) {
        cuent = this.filtros.cuenta.codigo;
      }
      if (this.filtros.banco != null) {
        ban = this.filtros.banco.codigo;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/conciliacion-bancaria/findfiltros/" +
          this.filtros.fecini +
          "/" +
          this.filtros.fecfin +
          "/" +
          sec +
          "/" +
          cuent +
          "/" +
          ban +
          "",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RLisConciliacion = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    eliminar() {
     
    },
    anular(e) {
      if(e.secuencia==='undefined'){
        return
      }
    EventBus.$emit("loading", true);
var ban= e.banco.codigo
this.$axios.get(this.$hostname +this.$hName +"/conciliacion-bancaria/findUltimaConciliacionBanco/" +ban+"",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(e.secuencia>=res.data.secuencia){
              this.continuarAnulando(e)
          }else{
            this.alerta("No puede eliminar esta conciliación, ya que, existe una conciliación más reciente","error")
          }
        })
        .catch((error) => {
          console.error(error);
        });
      
    },

    continuarAnulando(e){
      this.$axios.post(this.$hostname + this.$hName + "/conciliacion-bancaria/borrar",
          e,{ headers: this.$store.getters.GetheadersAxios })
        .then((res) =>{ 
          this.alerta("Dato eliminado correctamente!", "bien");
          this.buscarConciliaciones()
      })
        .catch((error) =>
          this.alerta("Dato no eliminado","error")
        );
    },
    imprimirList() { },
    imprimir(object) {
      EventBus.$emit("loading", true);
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/conciliacion-bancaria/imprimir/" +
          object.secuencia + "/" + object.cuentaBanco.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          EventBus.$emit("loading", false);
          setTimeout(function () {
           /* let pdfWindow = window.open("Reporte de impresión");
            pdfWindow.document.write(
              "<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
              encodeURI(res.data) +
              "'></embed>"
            );*/
            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
            EventBus.$emit("openPDF",PDF ,'CONCILIACION BANCARIA');

          }, 1000);
        })
        .catch((error) => {
          EventBus.$emit("loading", false);
          console.error(error);
        });
    },
    newwnuevo() {
      this.disabledBalanceAnterior=false
      this.conciliacionBancaria = {
        secuencia: 0,
        activo: true,
        borrado: false,
        fecha: fechaNueva(),
        valini: 0.0,
        valfin: 0.0,
        totalnotadebito: 0.0,
        totalcheque: 0.0,
        totalnotacredito: 0.0,
        totaldeposito: 0.0,
        totalnotadebitobanco: 0.0,
        totalchequebanco: 0.0,
        totalnotacreditobanco: 0.0,
        totaldepositobanco: 0.0,
        balancebanco: 0.0,
        chequetransito: 0.0,
        depositotransito: 0.0,
        chequeEmitidoMes: 0.0,
        depositoEmitidoMes: 0.0,
        chequeTrasitoMes: 0.0,
        cantidadChequeBanco: 0.0,
        cantidadChequeSistema: 0.0,
        cantidadDepositoBanaco: 0.0,
        cantidadDepositoSistema: 0.0,
        cantidadChequesEmitidos: 0.0,
        cantidadDepositoEmitidos: 0.0,
        cantidadChequeTransito: 0.0,
        balanceanterior: 0.0,
        balanceCorteMes: 0.0,
        banco: null,
        cuentaBanco: null,
        conciliacionDetalleList: [],
        moneda:null,
        tasa:0
      };
      this.conciliacionImprimir=null
      this.getMonedaBase()
      EventBus.$emit("loading", false);
      // EventBus.$emit("actualizaBarraBusqueda2");
    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) {
        number = 0;
      }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      ///return this.formatNumber(params);

      //return params;
    },


    changeTabs(e){
       var _this = this;
      setTimeout(function () {
      if(_this.Subtab=="tab-2" || _this.Subtab=="tab-3" ){
        if(_this.conciliacionBancaria.cuentaBanco==null){
          _this.alerta("Debe seleccionar una cuenta bancaria","error")
          _this.Subtab="tab-1"
          return
        }
          if(_this.Subtab=="tab-2"){_this.getAllcheque()}
          if(_this.Subtab=="tab-3"){_this.getAlldepositos()}
      }
      
      }, 300);

    },

    obtenerDatoSelectCheque(e){
     /*e.item. chequesPK.documento
     e.item.chequesPK.secuencia
     e.value*/
this.Listcheque.filter((ee) => ee.chequesPK.secuencia==e.item.chequesPK.secuencia && ee.chequesPK.documento==e.item.chequesPK.documento)[0].marca=e.value
var LINK= `/conciliacion-bancaria/actualizarChequeMarca/${e.item.chequesPK.secuencia}/${e.item.chequesPK.documento}/${e.value}`
      this.$axios.get(this.$hostname + this.$hName +LINK, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          e.item.marca=e.value
          console.log('Actualizacion Marca cheque')
          return e.value ;
        } );

    },


async allSelcObtenerDatoSelectCheque(e){

if(e.value==false){
  EventBus.$emit("loading", true);
  var ObjProRow = this.conciliacionBancaria.listaCheques.filter((ee) => ee.marca == true );
  var barbar = new Promise((resolve, reject) => {
  if (ObjProRow.length > 0) {  
  var _this = this;
      setTimeout(function () { 
        var LISTADESPUES=_this.conciliacionBancaria.listaCheques
        ObjProRow.forEach(element => {

        var OBJETO = LISTADESPUES.filter((ee) => ee.indx==element.indx)
        if (OBJETO.length == 0) { 
          var VARIABLE={
         item:JSON.parse(JSON.stringify(element)),
         value:JSON.parse(JSON.stringify(e.value))
        }
        // const result =  await 
        _this.obtenerDatoSelectCheque(VARIABLE);
         }
        });
        resolve()
       }, 300); 
   }else{
    resolve()
   }
});

barbar.then(() => {
  console.log('All done allSelcObtenerDatoSelectCheque!');
   EventBus.$emit("loading", false);
});


}else{
  EventBus.$emit("loading", true);
  var _this = this;
      setTimeout(function () { 

      var ObjProRow = _this.conciliacionBancaria.listaCheques.filter((ee) => ee.marca == false );
      var bar = new Promise((resolve, reject) => {
      if(ObjProRow.length > 0) { 
      ObjProRow.forEach((element, index, array) => {
        var VARIABLE={
         item:JSON.parse(JSON.stringify(element)),
         value:JSON.parse(JSON.stringify(e.value))
        }
        // const result =  await 
        _this.obtenerDatoSelectCheque(VARIABLE);
         
    });
    resolve()
  }else{
    resolve()
  }
    
});

bar.then(() => {
  console.log('All done allSelcObtenerDatoSelectCheque!');
   EventBus.$emit("loading", false);
});


      }, 300);
}

      
    },


    cargarPaginacionAllCheque(e){
      // var ARRAYS=JSON.parse(JSON.stringify(this.conciliacionBancaria.listaCheques))

      this.conciliacionBancaria.listaCheques=[]
      var _this = this;
      setTimeout(function () {
        var ObjProRow = _this.Listcheque.filter((ee) => ee.marca == true );
          if (ObjProRow.length > 0) { _this.conciliacionBancaria.listaCheques=JSON.parse(JSON.stringify(ObjProRow))}
       }, 600);
    },
    

    obtenerDatoSelectDeposito(e){
     /*e.item.depositosbancoPK.documento
     e.item.depositosbancoPK.secuencia
     e.value*/
    this.Listdeposito.filter((ee) => ee.depositosbancoPK.secuencia==e.item.depositosbancoPK.secuencia && ee.depositosbancoPK.documento==e.item.depositosbancoPK.documento)[0].marca=e.value
  
var LINK= `/conciliacion-bancaria/actualizarDepositoMarca/${e.item.depositosbancoPK.secuencia}/${e.item.depositosbancoPK.documento}/${e.value}`
      this.$axios.get(this.$hostname + this.$hName +LINK, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          e.item.marca=e.value
          console.log('Actualizacion Marca deposito')
        } );

    },


    async allSelcObtenerDatoSelectDeposito(e){

if(e.value==false){
  EventBus.$emit("loading", true);
  var ObjProRow = this.conciliacionBancaria.listaDepositos.filter((ee) => ee.marca == true );
  var barbar = new Promise((resolve, reject) => {
  if (ObjProRow.length > 0) {  
  var _this = this;
      setTimeout(function () { 
        var LISTADESPUES=_this.conciliacionBancaria.listaDepositos
        ObjProRow.forEach(element => {

        var OBJETO = LISTADESPUES.filter((ee) => ee.indx==element.indx)
        if (OBJETO.length == 0) { 
          var VARIABLE={
         item:JSON.parse(JSON.stringify(element)),
         value:JSON.parse(JSON.stringify(e.value))
        }
        // const result =  await 
        _this.obtenerDatoSelectDeposito(VARIABLE);
         }
        });
        resolve()
       }, 300); 
   }else{
    resolve()
   }
});

barbar.then(() => {
  console.log('All done allSelcObtenerDatoSelectCheque!');
   EventBus.$emit("loading", false);
});


}else{
  EventBus.$emit("loading", true);
  var _this = this;
      setTimeout(function () { 

      var ObjProRow = _this.conciliacionBancaria.listaDepositos.filter((ee) => ee.marca == false );
      var bar = new Promise((resolve, reject) => {
      if(ObjProRow.length > 0) { 
      ObjProRow.forEach((element, index, array) => {
        var VARIABLE={
         item:JSON.parse(JSON.stringify(element)),
         value:JSON.parse(JSON.stringify(e.value))
        }
        // const result =  await 
        _this.obtenerDatoSelectDeposito(VARIABLE);
         
    });
    resolve()
  }else{
    resolve()
  }
    
});

bar.then(() => {
  console.log('All done allSelcObtenerDatoSelectCheque!');
   EventBus.$emit("loading", false);
});


      }, 300);
}

      
    },



    cargarPaginacionAllDeposito(e){
      // var ARRAYS=JSON.parse(JSON.stringify(this.conciliacionBancaria.listaCheques))
      this.conciliacionBancaria.listaDepositos=[]
      var _this = this;
      setTimeout(function () {
        var ObjProRow = _this.Listdeposito.filter((ee) => ee.marca == true );
          if (ObjProRow.length > 0) { _this.conciliacionBancaria.listaDepositos=JSON.parse(JSON.stringify(ObjProRow))}
       }, 300);
    },

    

    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.conciliacionBancaria.moneda= MONEDA
  this.conciliacionBancaria.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.conciliacionBancaria.moneda == null) {
          _this.conciliacionBancaria.moneda = "";
        }

        if (!_this.conciliacionBancaria.moneda.hasOwnProperty('codigo')) {
          _this.conciliacionBancaria.moneda = "";
          _this.conciliacionBancaria.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},
 
ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.conciliacionBancaria.tasa = JSON.parse(JSON.stringify(e.tasa))
      /*this.conciliacionBancaria.totaldepositobanco=JSON.parse(JSON.stringify((this.conciliacionBancaria.totaldepositobanco/e.tasa) ))
      this.conciliacionBancaria.totalchequebanco=JSON.parse(JSON.stringify((this.conciliacionBancaria.totalchequebanco/e.tasa)))
      this.conciliacionBancaria.totalnotadebitobanco=JSON.parse(JSON.stringify((this.conciliacionBancaria.totalnotadebitobanco/e.tasa)))
      this.conciliacionBancaria.totalnotacreditobanco=JSON.parse(JSON.stringify((this.conciliacionBancaria.totalnotacreditobanco/e.tasa)))*/

  }
},



getTasaMonedaSelect(valor){
  //this.conciliacionBancaria.moneda
  // this.conciliacionBancaria.tasa

 var BVALOR=0
   /*BVALOR=(valor/this.conciliacionBancaria.tasa)*/
  //convertidor de monedas
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  BVALOR=((MONEDA.tasa/this.conciliacionBancaria.tasa)*valor)
  // this.RedondeoValor(BVALOR)
return BVALOR;
},



keyInput(input,value1){
  //input,value
  //$event.target.value
  // :value="getTasaMonedaSelect(conciliacionBancaria.totaldepositobanco)"
  //v-on:input="keyInput"
  // this.conciliacionBancaria.totaldepositobanco=JSON.parse(JSON.stringify(e))
  // if (typeof value1 =! 'number') {} typeof e === 
  // var value=value1+"".replaceAll(',', '')
 var value=Number((value1+"").replace(/,/g,''))
if(value==null){value='0'}
if(value.length==0){value='0'}
return value;

 /* var _this = this;
  setTimeout(function () { 
    // _this.conciliacionBancaria[input]=JSON.parse(JSON.stringify(Number(value+''))) 
     _this.conciliacionBancaria[input]= value
  }, 300)*/

},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
      
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/conciliacion-bancaria/findall",
          this.conciliacionBancaria,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.list = res.data;
        });
    },
  },

  computed: {
    ActDescBtn() {
      if (this.conciliacionBancaria.secuencia > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
        };
      }

      if (this.conciliacionBancaria.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: false,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
        };
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    },
  },
};
</script>

<style>

</style>
