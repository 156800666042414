<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Historial Del Cliente'"
  :descripcion="'Historial Del Cliente'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitHistorialCliente'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
 <!-- -----------------------------  cliente  -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="cliente:"
            :items="RCliente"
            item-text="codnom"
            :suffix="infCliente.telefono"
            style="font-size: 13px;"
            v-model="HistorialCliente.cliente"
            @focus="cargarCliente"
            @keyup="CbFilcliente"
            @change="historialClieList"
            @blur="SelctCbCliente"
            no-filter
          >
          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('codigo')" ><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                    <span class="fontSize13 colorNombre" v-if="item!=null && !item.hasOwnProperty('codigo')" >{{item}}</span>
                  </template>

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">
   
<v-btn @click.prevent="openModHistorialVenta" v-on="on" color="blue lighten-1" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-history"></i>
</v-btn>

 </template>
          <span>Historial Ventas</span>
        </v-tooltip>
 </template> 


                    </v-combobox>

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="HistorialCliente.desde"
        @input="historialClieList"
        @blur="BlurDesde" 
        hide-details 
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<v-combobox
          v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda"
            autocomplete="off"
            dense
            outlined
            hide-details
            label="Moneda"
            :items="Rmoneda"
            item-text="nombrecorto"
            v-model="HistorialCliente.moneda"
            @blur="SelctCbMoneda"
            @focus="cargarMoneda"
            @change="historialClieList"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>
          </v-combobox>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="Balance:" dense outlined
 autocomplete="off"
  v-model.number="infCliente.balance"

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>


<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
        :rules="[$rules.required]" 
        label="Hasta:"
        v-model="HistorialCliente.hasta"
        hide-details
        @input="historialClieList"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
              
<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >




<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'Fecha', sortable: false,  value: 'fecha', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'No.Documento', sortable: false, value: 'ref', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'Descripción', sortable: false, value: 'descripcion', width:'auto', class: ['no-gutters black--text'] },
                 { text: '$ Factura(Débito)', sortable: false, value: 'debito', width:'auto', class: ['no-gutters black--text'] },
                 { text: '$ (Pagos)Crédito', sortable: false, value: 'credito', width:'auto', width:'auto', class: ['no-gutters black--text'] },
                 { text: '$ Balance', sortable: false, value: 'balance', width:'auto', class: ['no-gutters black--text'] }
                 ]"
              dense
              :items="listHiscliente"    
              :items-per-page="15"
              :footer-props="arrayPropiedases" 
            >

  <template v-slot:item.fecha="{ item }"> 
     <span >{{ FormatoFecha(item.fecha) }}</span>
  </template>

<template v-slot:item.balance="{ item }"> 
<span >{{ currencyFormatter(item.balance) }}</span>
</template>

<template v-slot:item.debito="{ item }"> 
<span >{{ currencyFormatter(item.debito) }}</span>
</template>

<template v-slot:item.credito="{ item }"> 
<span >{{ currencyFormatter(item.credito) }}</span>
</template>



         

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.debito)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.credito)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.BalanceTotal)}} </strong></td>
  </tr>
 </template>
 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Débito:{{currencyFormatter(totales.debito)}}</span>
        <span>Crédito:{{currencyFormatter(totales.credito)}}</span>
        <span>Balance:{{currencyFormatter(totales.BalanceTotal)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>


</v-col>

<v-col cols="12" md="12" sm="12">
<hr>
<center>
   <b> Valores a la Fecha Actual</b>
</center>
</v-col>

<v-col cols="12" md="3" sm="3">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="1 - 30 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorAfecha.a30dia"
  disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>

  </v-col>

<v-col cols="12" md="3" sm="3">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="31 - 60 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorAfecha.a60dia"
  disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

  </v-col>

<v-col cols="12" md="3" sm="3">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="31 - 90 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorAfecha.a90dia"
  disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

  </v-col>

  <v-col cols="12" md="3" sm="3">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="Mas de 90 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorAfecha.amasdia"
  disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

  </v-col>

            </v-row>

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :footer-props="arrayPropiedases" 
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


<!-- ------------------------------------------------------ Modal- nueva Factura-->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="ModHistorialVenta" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
 dense
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Historial de Ventas </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="ModHistorialVenta=!ModHistorialVenta"
        >
        <!-- cerrarModalHis() -->
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form3"
    v-model="valid"
    lazy-validation
  >
<v-row>
 

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
 <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="HistorialCliente.desde"
        hide-details
        disabled
        id="formdisabledInput"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">
<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="HistorialCliente.hasta"
        hide-details
        disabled
        id="formdisabledInput"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>


<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >

<v-data-table dense  item-key="indx"
class="elevation-1 TbSize13" 
                    :headers="[
                      { text: 'FECHA', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'PRODUCTO', value: 'producto', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'No.FACTURA', value: 'factura', sortable: false, class: ['no-gutters black--text'] },
                    ]
                    " :items="RHistorialCompracliente"
                    :footer-props="arrayPropiedases"  
                    :items-per-page="TbPaginatorHisCli.itemsPerPage"
                    @pagination="cargarPaginacionAllHisCli"
                    :server-items-length="TbTotalItemHisCli"
                    :page="TbPaginatorHisCli.page" 
                    :pageCount="TbPaginatorHisCli.pageCount"
                    >

                    <template v-slot:item.fecha="props">
                    <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                  </template>

                  <template v-slot:item.precio="props">
                    <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span>
                  </template>
                  </v-data-table>

 </div>

</v-col>






</v-row>
</v-form>
</v-card-text>

<hr/> 
 
 
</v-card>
</v-dialog>


<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarMoneda'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

   

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import VueAutonumeric from "../components/AutoNumericNuevo";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    VueAutonumeric

  },

  created() {
    var json = {
      titulo: "Historial Del Cliente",
      descripcion: "Historial Del Cliente",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "HistorialClienteNew",
        "HistorialClienteSave",
        "HistorialClienteRemove",
        "HistorialClienteAnular",
        "HistorialClientePrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("HistorialClienteNew", this.new);
    // EventBus.$on("HistorialClienteSave", this.save);
    // EventBus.$on("HistorialClienteRemove", this.remove);
    // EventBus.$on("HistorialClienteAnular", this.remove);
    // EventBus.$on("HistorialClientePrintList", this.imprimirList);
    EventBus.$on("emitHistorialCliente", this.datostabla);
    EventBus.$on("onResize", this.onResize);




var mond=this.$store.getters.GetConfiguracionGeneral.config.monedabase
this.HistorialCliente.moneda= {...mond}



  },

  data:()=>  ({
    arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

totales:{
BalanceTotal:0,
credito:0,
debito:0,
},

      listFactDetalle:[],
      RHistorialCompracliente:[],
      ModHistorialVenta:false,
      TbTotalItemHisCli: 0,
    TbPaginatorHisCli: {
      page: 1,
      itemsPerPage: 10,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
      Rmoneda:[],
      RCliente:[],
   
  infCliente:{
balance:0,
telefono:''
},

ValorAfecha:{
  a30dia:0,
  a60dia:0,
  a90dia:0,
  amasdia:0,
},

      listHiscliente:[],
      
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      HistorialCliente: {
        codigo: 0,
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        cliente:null,
        moneda:null
        //fechaCreacion: new,
       //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
    notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
 
    datostabla(e) {

      this.HistorialCliente = e;
      this.acti_tb_data=false
    },
    save() { },


NotifMessage() {

if(this.HistorialCliente.codigo!=0 && this.HistorialCliente.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarMoneda"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },

 Exec_notif(e) {
this.notif=JSON.parse(JSON.stringify(e))
//this.Elim_notif.estado = !this.Elim_notif.estado;
    },



    eliminar() { },
    anular() {},
    imprimirList() {},
    newwnuevo() {
      (this.HistorialCliente = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


SelctCbCliente(){
  if(this.HistorialCliente.cliente==null){

    this.HistorialCliente.cliente=''
  }

if(this.HistorialCliente.cliente.codigo== undefined){

this.alerta("Seleccione un cliente", "error");
this.HistorialCliente.cliente=''
return

}

},



cargarCliente(){

this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3/25/0',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RCliente=res.data))
},



CbFilcliente(e) {
     
         if (e.target.value.length >= 0) {
         
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/clientes/findtodo/" +
                (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
              "/3/25/0",
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.RCliente = res.data));
        }
      
      
    },


cargarMoneda(){

this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',
 {headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rmoneda=res.data))

},

SelctCbMoneda(){

 if(this.HistorialCliente.moneda==null){

    this.HistorialCliente.moneda=''
  }

if(this.HistorialCliente.moneda.codigo==undefined){

this.alerta("Seleccione una moneda", "error");
this.HistorialCliente.moneda=''
return

}

},

BlurDesde(){

if(this.HistorialCliente.desde.toString().length==0){
  this.HistorialCliente.desde=new Date().toISOString().substr(0,10)
}


 },


 BlurHasta(){

if(this.HistorialCliente.hasta.toString().length==0){
  this.HistorialCliente.hasta=new Date().toISOString().substr(0,10)
}

 },




historialClieList(){


  if (this.HistorialCliente.cliente == null){
      return 0;
   }


  var q2 = "";
 var tasa = 1.00;

     if (this.HistorialCliente.cliente != null){
            q2 = ` and clientecod = ${this.HistorialCliente.cliente.codigo} `;
        }
        if (this.HistorialCliente.moneda==null) {
            tasa = this.HistorialCliente.moneda.tasa
        }



 var f1=new Date(this.HistorialCliente.desde).toISOString().substr(0,10)
 var f2=new Date(this.HistorialCliente.hasta).toISOString().substr(0,10)

// var Obj={}

var link = `/ConsultaRapida/historialClieList/${this.HistorialCliente.cliente.codigo}/${f1}/${f2}/${q2}/${tasa}`
this.$axios
        .get(this.$hostname + this.$hName +link,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.CalcularBalance(res.data)
        })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});


},

async CalcularBalance(e){

//this.listFactDetalle=JSON.parse(JSON.stringify(e.listFacDetalle))


this.totales={
BalanceTotal:0,
credito:0,
debito:0,
}
/*this.listHiscliente=res.data
await obj.forEach(elent => {
this.totales.BalanceTotal+=(elent[3]-elent[4])
elent[9]=this.totales.BalanceTotal
this.totales.debito+=elent[3]
this.totales.credito+=elent[4]
});*/



var obj= JSON.parse(JSON.stringify(e.list))

await obj.forEach(elent => {

if(!elent.descripcion.includes("Contado")){
  this.totales.BalanceTotal+=(elent.debito-elent.credito)
}
elent.balance=this.totales.BalanceTotal
this.totales.debito+=elent.debito
this.totales.credito+=elent.credito
});

this.listHiscliente=JSON.parse(JSON.stringify(obj))

this.ValorAfecha.a30dia=e.vencidosValor[0]
this.ValorAfecha.a60dia=e.vencidosValor[1]
this.ValorAfecha.a90dia=e.vencidosValor[2]
this.ValorAfecha.amasdia=e.vencidosValor[3]

var listadoFac=[]

/*await e.listFacCl.forEach(elmnt => {

var Nfac =elmnt.documentoFactura.nombrecorto.trim()+"-"+elmnt.facturasventaPK.secuencia

elmnt.facturasventaDetalleList.forEach(ele => {

var Json={
  fecha:new Date(elmnt.fecha).toISOString().substr(0,10),
  nofactura:Nfac,
  cantidad:ele.cantidad,
  producto:ele.descripcion,
  precio:ele.precio,
  valor:(ele.cantidad*ele.precio),
  descuento:ele.descuento
}

listadoFac.push(Json)
  
  });
  
});*/

this.listFactDetalle=listadoFac


},


openModHistorialVenta(){

// this.ModHistorialVenta=!this.ModHistorialVenta

if (this.HistorialCliente.cliente == null){
  this.alerta("Seleccione un cliente para mostrar el historial", "error")
      return 0;
   }

this.ModHistorialVenta=true

this.TbTotalItemHisCli= 0
    this.TbPaginatorHisCli= {
      page: 1,
      itemsPerPage: 10,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    }

var _this=this;
setTimeout(function(){ 
  _this.CargarHistorialCliente() 
}, 500);


},

cargarPaginacionAllHisCli(e){
this.TbPaginatorHisCli=JSON.parse(JSON.stringify(e));
this.CargarHistorialCliente()
    },

  CargarHistorialCliente(){
          this.RHistorialCompracliente=[]
if(this.HistorialCliente.cliente ==null || this.HistorialCliente.cliente.codigo==0 ){
  return
}
          var obj =
        this.TbPaginatorHisCli.itemsPerPage +
        "/" +
        this.TbPaginatorHisCli.pageStart +
        "/" +
        this.HistorialCliente.cliente.codigo+
        "/" +
        this.HistorialCliente.desde+
        "/" +
        this.HistorialCliente.hasta;

          this.$axios
        .get(
          this.$hostname + this.$hName + "/ConsultaRapida/FindHistorialCliente/" + obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.RHistorialCompracliente = res.data.ListHistCliente),
            (this.TbTotalItemHisCli = res.data.TbTotalItem);
        });


        },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {},

  },

computed:{

  ActDescBtn(){

if( this.HistorialCliente.cliente!=null && this.HistorialCliente.cliente.hasOwnProperty('codigo')){
 // this.infCliente=JSON.parse(JSON.stringify(this.HistorialCliente.cliente))

 if(this.HistorialCliente.cliente.balancecliente!=null){
  this.infCliente.balance=JSON.parse(JSON.stringify(this.HistorialCliente.cliente.balancecliente.balance)) 
    }else{this.infCliente.balance=0}

  if(this.HistorialCliente.cliente.telefonosClientesList.length>0){
    this.infCliente.telefono= "Tel. "+JSON.parse(JSON.stringify(this.HistorialCliente.cliente.telefonosClientesList[0].telefonosClientesPK.telefono))
  }else{ this.infCliente.telefono=''}

}else{

this.infCliente={
balance:0,
telefono:''
}
}



this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}



};
</script>

<style></style>
