<template>
<div class="col-full" :class="$style.centered">
    <h1 :class="$style.headinglarge"> Esta Pagina no existe</h1>
    <p :class="$style.textlarge"> quiere que le redireccionemos
    <router-link :to="{ name:'VistaPrincipal' }">haga Click aqui</router-link></p>
    <img src="../assets/img/advertencia.jpg" />
</div>
</template>
<script>

export default{}
</script>
<style module>
.headinglarge {
font-size:50px;
}
.textlarge
{
font-size:50px;
font-weight:100;
}
.centered {
    text-align: center;
}
</style>