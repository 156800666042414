<template>
 <div>{{GetEjecucion}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Crear Conductores de vehiculos'"
  :descripcion="'Para Crear los Conductores de los Vehiculos de Transporte'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>

<BarraDinamicaNormal
              :url="$hostname + $hName + '/propietario/findallConductor/'"
              :nombre="'Conductor de Vehiculos'"
              :SizeNom="24"
              :emitnombre="'emitTranspConductoresVehiculos'"
              :SeachNombre="'Búsqueda general'"

      :headers="[
        { text: 'Código', value: 'id'},
        { text: 'Nombre', value: 'nombre'},
        { text: 'Cedula', value: 'cedula'},
        { text: 'Telefono', value: 'telefono'},
        { text: 'Ciudad', value: 'ciudad'},
        { text: 'Direccion', value: 'direccion'},
        { text: 'licencia', value: 'licencia'},
        { text: '#sindicato', value: 'numeroSindicato'},
           ]"
              :ContListSlot="[

              {Text:'', NomValue:'fotoPerfil', Ctsl:1, Type:3, State:true},
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Nombre', NomValue:'nombre', Ctsl:1, Type:0, State:true},
              {Text:'Cedula', NomValue:'cedula', Ctsl:1, Type:0, State:true},
              {Text:'Telefono', NomValue:'telefono', Ctsl:1, Type:0, State:true},
              {Text:'Ciudad', NomValue:'ciudad', Ctsl:1, Type:0, State:true},
              {Text:'Dirección', NomValue:'direccion', Ctsl:1, Type:0, State:true},
              {Text:'Licencia', NomValue:'licencia', Ctsl:1, Type:0, State:true},
              {Text:'#Sindicato', NomValue:'numeroSindicato', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ]"
            />
          </template>
        </v-tooltip>


      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container><!-- style="border: 1px solid #000000;" -->
    
<v-form ref="formConductores" @submit.prevent=""  v-model="valid" lazy-validation>
 <v-row>
<!-- columna 1 ----------------------------------------------->
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="Conductores.nombre"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Nombre y Apellido:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                </v-col>
<!-- fn columna 1 ----------------------------------------------->
</v-row>


<v-row style="margin-top:-23px;">
<!--  columna 2 ----------------------------------------------->
<v-col cols="12" md="6" sm="6" >

 <!-- ----------------------------------------- cedula :rules="[$rules.required, $rules.RulesCel]" -->
<v-text-field
            v-mask="maskCedula"
            label="Cédula:"
            @input="MaskChange"
            autocomplete="off"
            dense
            outlined
            maxlength="13"
            :rules="[$rules.RulesCel]"
            v-model="Conductores.cedula"
          >
          
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
              </template>
       
          </v-text-field>
</v-col>

<v-col cols="12" md="6" sm="6">

  <!-- -----------------------------  tb telefonos -->
<v-text-field
          
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
            maxlength="13"
            @input="MaskChange"
            v-mask="maskTelefono"
            :rules="[$rules.RulesTel]"
            v-model="Conductores.telefono"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>

          </v-text-field> 

</v-col> 
</v-row>

<v-row style="margin-top:-23px"> 
<v-col cols="12" md="4" sm="4">

  <v-text-field
                    v-model="Conductores.licencia"
                    autocomplete="off"
                    label="Licencia:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
</v-col>

<v-col cols="12" md="4" sm="4">
  <v-text-field
                    v-model="Conductores.numeroSindicato"
                    autocomplete="off"
                    label="Sindicato:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

</v-col>

<v-col cols="12" md="4" sm="4">
  <!-------------------------------------------------- fecha -->
   <v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Vencimiento"
        v-model="Conductores.vencimientoLicencia"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>

</v-col>

</v-row>


<v-row style="margin-top:-23px">
<v-col cols="12" md="6" sm="6">

<!-- ----------------------------- direccion -->

                  <v-textarea
                  autocomplete="off"
                    label="Direccion:"
                    rows="3"
                    dense
                    outlined
                    v-model="Conductores.direccion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>


                  <v-file-input       
        dense
        outlined  
        accept="image/png, image/jpeg, image/bmp"
        @change="ConvertirImg"
        ref="files"
        id="foto"
        show-size  
        counter           
        small-chips
        v-model="cargaImg"
        multiple
        prepend-icon="mdi-camera"
        label="Elegir Foto"
      >


<template v-slot:append-outer>

                  <v-tooltip top>
                <template v-slot:activator="{ on }"> 
                  
                <i style="font-size: 20px;" v-on="on" class="fa fa-eye"></i>
                </template>

<v-avatar  
                  size="230"
                  tile
                >
                  <v-img :src="Conductores.fotoPerfil"></v-img>
             </v-avatar> 

                          <!-- <span>Buscar Solicitud</span> getImgUrl() -->
                        </v-tooltip> 
                        
                  </template>
      </v-file-input>

</v-col>

<v-col cols="12" md="6" sm="6" >

<!-- ----------------------------- Ciudad -->

                  <v-textarea
                  autocomplete="off"
                    label="Ciudad:"
                    rows="3"
                    dense
                    outlined
                    v-model="Conductores.ciudad"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map"></i>
                    </template>
                  </v-textarea>

                  <v-switch
                    label="Activo"
                    v-model="Conductores.activo"
                  ></v-switch>

</v-col>
</v-row>         
            </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
<v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                 @click.prevent="imprimirList()"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>

          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'id', width:90},
        { text: 'Nombre', value: 'nombre', width:300},
        { text: 'Cedula', value: 'cedula', width:150},
        { text: 'Telefono', value: 'telefono', width:150},
        { text: 'Ciudad', value: 'ciudad', width:300},
        { text: 'Direccion', value: 'direccion', width:300},
        { text: 'licencia', value: 'licencia',width:300},
        { text: '#sindicato', value: 'numeroSindicato',width:300}, 
        { text: 'Vencimiento Licencia ', value: 'vencimientoLicencia', width: 150},    
        { text: 'Activo', value: 'activo'}
]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.despreciacionAcumulada="props">
                <span>{{ currencyFormatter(props.item.despreciacionAcumulada) }}</span>
              </template>
              <template v-slot:item.valorActual="props">
                <span>{{ currencyFormatter(props.item.valorActual) }}</span>
              </template>
              <template v-slot:item.valorCompra="props">
                <span>{{ currencyFormatter(props.item.valorCompra) }}</span>
              </template>
              <template v-slot:item.valorLibros="props">
                <span>{{ currencyFormatter(props.item.valorLibros) }}</span>
              </template>
              <template v-slot:item.vencimientoLicencia="props">
                <span>{{ FormatoFecha(props.item.vencimientoLicencia)}}</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   
<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarConductor'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    VueAutonumeric,
    ContenidoBase

  },
  created() {
    var json = { 
      titulo: "Crear Conductores de vehiculos",
      descripcion: "Para Crear los Conductores de los Vehiculos de Transporte",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [ 
        "TranspConductoresVehiculosNew",
        "TranspConductoresVehiculosSave",
        "TranspConductoresVehiculosRemove",
        "TranspConductoresVehiculosAnular",
        "TranspConductoresVehiculosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspConductoresVehiculosNew", this.new);
    // EventBus.$on("TranspConductoresVehiculosSave", this.save);
    // EventBus.$on("TranspConductoresVehiculosRemove", this.remove);
    // EventBus.$on("TranspConductoresVehiculosAnular", this.remove);
    // EventBus.$on("TranspConductoresVehiculosPrintList", this.imprimirList);
    EventBus.$on("emitTranspConductoresVehiculos", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  }, 

  data:()=> ({
    

      maskTelefono:'#############',
      maskCedula:'#############',
      Rpropietario:[], 
      Rconductor:[] ,
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

ejecucion:true,

cargaImg:null,
verImg:null,

Conductores: {
id: 0,
activo: true,
cedula: "",
ciudad: "",
direccion: null,
fecha: new Date().toISOString().substr(0,10),
foto:null,
fotoPerfil:null,
licencia: "",
nombre: null,
numeroSindicato: "",
propietario: false,
telefono: "",
vencimientoLicencia:new Date().toISOString().substr(0,10)

     },

     EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },

    
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

    
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      
    },
    datostabla(e) {

//console.log(e)

e.vencimientoLicencia=new Date(e.vencimientoLicencia).toISOString().substr(0,10)

console.log(e)




      this.Conductores = e;
      this.acti_tb_data=false

      this.maskTelefono='#############',
      this.maskCedula='#############',

      setTimeout(this.MaskChange,200);


    },
    save() {

if(this.$refs.formConductores.validate()) {

this.Conductores.foto=this.Conductores.fotoPerfil
//this.Conductores.numeroSindicato=this.Conductores.sindicato


if(this.ejecucion==true){

  this.ejecucion=false
 
 
var Objt=JSON.parse(JSON.stringify(this.Conductores)) 
this.$axios
        .post(this.$hostname + this.$hName + "/propietario/save/",Objt,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));


}      
}else{
              this.alerta("los campos en rojo son requeridos", "error");
       }
      


    },




NotifMessage() {

if(this.Conductores.id!=0 && this.Conductores.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarConductor"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un conductor para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },


   
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Conductores)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }


if (this.Conductores.id!=0) {


var Objt=JSON.parse(JSON.stringify(this.Conductores)) 
this.$axios
        .post(this.$hostname + this.$hName + "/propietario/borrado/",Objt,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

      }


    },
    anular() {


    },
    imprimirList() {

window.open(this.$hostname+this.$hName+'/services/conductor/printlistConductore/')

    },
    newwnuevo() {

this.Conductores={
id: 0,
activo: true,
cedula: "",
ciudad: "",
direccion: null,
fecha: new Date().toISOString().substr(0,10),
foto:null,
fotoPerfil:null,
licencia: "",
nombre: null,
numeroSindicato: "",
propietario: false,
telefono: "",
vencimientoLicencia:new Date().toISOString().substr(0,10)
 }
      
      this.cargaImg=null
     

        this.ejecucion = true;
        EventBus.$emit("actualizaBarraBusqueda2");
    },

getImgUrl() {


   //   var images = require("../assets/" + pet);
    var images
   if(this.Conductores.fotoPerfil ==null || this.Conductores.fotoPerfil.length==0 ){
      images = require("@/assets/img/Nofoto.png");

   }else{
     if(this.Conductores.fotoPerfil!=null || this.Conductores.fotoPerfil.length >0 ){

       images=this.Conductores.fotoPerfil

     }
   }
     //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
     // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },



ConvertirImg(e) {


      var _this = this;
      var filesSelected = e
     // var filesSelected = document.getElementById("foto").files;
      if (filesSelected.length > 0) {
        var fileToLoad = filesSelected[0];

        var fileReader = new FileReader();

        fileReader.onload = function(fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64

          var newImage = document.createElement("img");
          newImage.src = srcData;
          _this.Conductores.fotoPerfil = srcData;

      
          // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
          // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
          // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        };
        fileReader.readAsDataURL(fileToLoad);
      }else{

            this.Conductores.fotoPerfil=require("@/assets/img/Nofoto.png");

      }
    },



    MaskChange(){

// -------- maskTelefono ---------------
if(this.Conductores.telefono.length>0){

  this.maskTelefono='(###)###-####'
}else{

this.maskTelefono='#############'

}
// -------- maskCedula ---------------

// -------- maskTelefono ---------------

if(this.Conductores.cedula.length>0){

  this.maskCedula='###-#######-#'
}else{

this.maskCedula='#############'

}
// -------- maskCedula ---------------

    },


FormatoFecha(params0) {

//console.log('Fecha Fecha')
//console.log(params0)


var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

       this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },


async filtro() {
      var headers=this.$store.getters.GetheadersAxios
      
      await this.$axios.get(this.$hostname + this.$hName + "/propietario/findallConductor/", {headers:headers})
        .then(res => {this.list = res.data;});
    }

   
  },
  computed:{

    GetEjecucion(){

// -------- ActDescBtn -----------
if(this.Conductores.id>0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}


if(this.Conductores.id<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}
     
//EventBus.$emit("ActDesaBotones", objjct);
// -------- fn ActDescBtn -----------


// --------  Img -----------


   if(this.Conductores.fotoPerfil==null || this.Conductores.fotoPerfil.length==0 ){
     this.Conductores.fotoPerfil = require("@/assets/img/Nofoto.png");
   }
   
  //  else{
  //    if(this.Vehiculo.fotoVehiculo!=null || this.Vehiculo.fotoVehiculo.length >0 ){

  //      this.verImg= this.Vehiculo.fotoVehiculo

  //    }
  //  }
// -------- fn Img -----------


    }

  }
};
</script>

<style></style>
