<template>
 <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ActDescBtn}}
  <!--------------------- Creacion de Otros Ingresos ------------------------------->
        <!-- Modal Alerta -->
        <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
          :timeout="120000" top="top">
          {{ aalert.nombre }}

          <v-btn dark text @click="aalert.estado = false">Close</v-btn>
        </v-snackbar>

        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <div class="px-2">
              <v-card color="grey lighten-3">

                <div class="px-2">

                  <v-row style="border: 1px solid #000000;">

                    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-center" cols="12" md="3" sm="12">
                      <v-avatar v-ripple="{ class: `white--text` }" color="grey darken-2" class="ma-2" size="120">
                        <!-- <span 
                          v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo == null"
                          class="white--text headline">{{ getletra($store.getters.GetdatosInfUsu.usuario.nombre)
                          }}</span>
                        <img
                          v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo != null"
                          id="img-mini" alt="Usuario" style=" border: 2px solid #dd4b39;  border-radius: 10px;"
                          :src="$store.getters.GetConfiguracionGeneral.config.logo" />-->
                          <i style="font-size: 90px;" class="fa fa-address-book-o"></i>
                      </v-avatar>
                    </v-col>
                    <!-- fn Columna 1 -->
                    <!-- Columna 2 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="9" sm="12">

                      <v-form ref="formLogin" @submit.prevent="" v-model="valid" lazy-validation
                        autocomplete="new-password">
                        <v-row>
                          <!-- columna 1 -->
                          <v-col cols="12" md="12" sm="12">
                            <!-------------------------------------------------- nombe login -->
                            <v-text-field label="Usuario:" dense outlined :rules="[$rules.required]" autocomplete="off"
                              v-model="Login.username" :disabled="loading" @keyup.enter="inicioSesion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-user"></i>
                              </template>
                            </v-text-field>
                            <!-------------------------------------------------- Pass -->

                            <v-text-field label="Contraseña:" dense type="password" outlined :rules="[$rules.required]"
                              autocomplete="new-password" v-model="Login.password" :disabled="loading"
                              @keyup.enter="inicioSesion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                              </template>
                            </v-text-field>


                          </v-col>
                          <!-- fn columna 1 -->
                        </v-row>
                      </v-form>

                    </v-col>
                    <!-- fn Columna 2 -->
                    <!--  Columna 3 -->
                    <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pb-1 d-flex justify-center">

                      <!-- <v-btn-toggle > </v-btn-toggle> -->
                      <v-btn dense rounded class="px-1" color="green" :loading="loading" :disabled="loading"
                        @click.prevent="inicioSesion">
                        <i style="font-size: 28px; color:#FFFFFF; " class="fa fa-check "> </i>

                        <span style="color:#FFFFFF;">Aceptar </span>

                        <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>

                      </v-btn>

                      <!-- <v-btn-toggle dense rounded class="px-1"></v-btn-toggle> -->
                      <v-btn dense rounded class="px-1" color="red" :disabled="loading" @click.prevent="CancelarSesion">
                        <i style="font-size: 28px; color:#FFFFFF; " class="fa fa-ban"> </i>
                        <span style="color:#FFFFFF;">Cancelar </span>
                      </v-btn>



                    </v-col>
                    <!--  fn Columna 3 -->
                  </v-row>
                </div>

              </v-card>
            </div>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
    
  
</v-col>

</template>

<script>
import {EventBus} from "../event-bus";
import json from '../json.json'
import {readTextFile} from "@/js/Funciones.js";

export default {
  mounted() {
    //console.log(this.windowSize)
    //console.log(this.$store.getters.GetwindowSize)
    //console.log(this.readTextFile("C:\\fakepath\\json.txt"));
//how get local path a file in chrome
/*this.$axios
        .get("file:///C:/json.json")
        .then((res) => {
          console.log('Viendo Inform')
          console.log(res)
        })
        .catch((error) => {
          console.log(error);
        });*/
  },

  created() {


  },

  data: () => ({
    myJson: json,
    windowSize: {width: "auto", height: "auto"},
    valid: true,
    loading:false,
    Login: {
        username: null,
        password: null
      },
    aalert: {
      estado: false,
      color: null
    },

  }),

  props: {

  },

  methods: {
    readTextFile,
    inicioSesion() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true
        var ObjProRow = this.myJson.UsuarioAdmin.filter((ee) => ee.username == this.Login.username && ee.password == this.Login.password);
        if(ObjProRow.length==0){
          this.alerta("Usuario o clave incorrecta", "error");
          this.loading = false
          return
        }

     /*   if(_this.Login.username!="admin"){
          _this.alerta("Usuario o clave incorrecta", "error");
          _this.loading = false
          return
        }*/


        var _this = this;
        setTimeout(function () {
        _this.loading = false
        _this.$emit("inicioSession", true)
        _this.Login = {
        username: null,
        password: null
      }
        }, 2000);


        
        // EventBus.$emit("loading", true);
        // setTimeout(this.validarSesion, 2000);
      }else{
        this.alerta("Los campos rojos son requeridos", "error");
      }
    },



    CancelarSesion() {
     // this.loading = false
      //this.dialogSessionAdmin = false
     // this.FuncionEjecutar = null
     this.$emit("CloseSession", false)
      this.Login = {
        username: null,
        password: null
      }

    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        // this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        // this.ejecucion = true;
      }
    },

  },
  computed:{
    ActDescBtn(){
      this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
    }
  },
  

}
</script>

<style>

</style>