<template>
  <div id="ResponsiveCss">
    {{ ActDescBtn }}
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro Facturas de Proveedores'"
          :descripcion="'Esta función permite realizar Facturas a proveedores.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" @MethodGuardarTemporal="Saavetem" @MethodRestaurarTemporal="Restaurartem"
          @MethodImportarDato="importarDato" @MethodReimprimirEtiqueta="dialogImprimirEtiqueta = true" />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"><i style="font-size: 25px"
                        class="fa fa-eye"> </i>
                      ver</v-btn>
                    <v-btn color="teal darken-2" dark text small @click.prevent="
                      (dialogFiltros = true),
                      (ObjTemTbDinam = JSON.parse(
                        JSON.stringify(Modallfiltro)
                      ))
                    ">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>

                <!-- <v-navigation-drawer >  -->

                <BarraDinamicaLimitePlus :PropsNombre="'Facturas Proveedores'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por #Factura'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'facturasProveedoresPK.codigo' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  {
    Text: 'Código',
    NomValue: 'facturasProveedoresPK',
    Nv2: 'codigo',
    Ctsl: 2,
    Type: 0,
    State: true,
  },
  {
    Text: '#Factura',
    NomValue: 'referencia',
    Ctsl: 1,
    Type: 0,
    State: true,
  },
  {
    Text: 'proveedor',
    NomValue: 'proveedor',
    Nv2: 'nombre',
    Ctsl: 2,
    Type: 0,
    State: true,
  },
  {
    Text: 'fecha',
    NomValue: 'fecha',
    Ctsl: 1,
    Type: 1,
    State: true,
  },
  {
    Text: 'Valor $',
    NomValue: 'total',
    Ctsl: 1,
    Type: 2,
    State: true,
  },
  {
    Text: 'Estado',
    NomValue: 'activada',
    Ctsl: 1,
    Type: 0.2,
    State: true,
  },
]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="FacturaProveedor.codigo" style="display: none" />
                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <!-------------------------------------------------- proveedor -->

                        <v-combobox id="formdisabledInput" :disabled="InputDisabled" autocomplete="off" dense outlined
                          label="* Proveedor:" :items="Rproveedor" item-text="nombre" placeholder=" "
                          style="font-size: 13px" :rules="[$rules.required]" v-model="FacturaProveedor.proveedor"
                          @blur="SelctCbProveedor" @focus="cargarProveedor" @keyup="CbFilProveedor"
                          @change="BuscaOrdenCompra" v-on:blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>

                        <v-row style="margin-top: -10px">
                          <!-- sub columna 1 -->
                          <v-col cols="12" md="7" sm="7">
                            <!----------------------------------------------  Fecha entrada -->
                            <v-text-field dense outlined type="date" label="Fecha " @blur="verificarYearfecha()" :rules="[$rules.required]"
                              v-model="FacturaProveedor.fecha" placeholder=" " style="font-size: 13px">
                              <template v-slot:prepend>
                                <i style="font-size: 20px" class="fa fa-calendar"></i>
                              </template>
                            </v-text-field>
                          </v-col>
                          <!-- fn sub columna 1 -->

                          <!-- sub columna 2 -->
                          <v-col cols="12" md="5" sm="5">
                            <!-- Plazo -------------------------------------------------:disabled="InputDisabled" -->
                            <AutoNumeric id="formdisabledInput" label="Plazo:" dense outlined
                              autocomplete="off" v-model.number="FacturaProveedor.diascredito">
                              <template v-slot:prepend>
                                <i style="font-size: 20px" class="fa fa-clock-o"></i>
                              </template>
                            </AutoNumeric>
                          </v-col>
                          <!-- fn sub columna 2 -->
                        </v-row>

                        <!-------------------------------------------------- Comentario -->
                        <v-textarea id="formdisabledInput" :disabled="InputDisabled" style="margin-top: -14px"
                          autocomplete="off" label="Comentario" dense outlined v-model="FacturaProveedor.comentario"
                          rows="1">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>

                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="2" sm="2">
                        <!------------------------------------------ Documentos -->
                        <v-combobox id="formdisabledInput" :disabled="InputDisabled" autocomplete="off" dense outlined
                          label="* Documentos:" :items="RDocumento" item-text="descripcion" :rules="[rules.required]"
                          placeholder=" " style="font-size: 13px" v-model="FacturaProveedor.documentoproveedor"
                          @blur="SelctCbDocumento" v-on:blur="getCrearCont" @focus="cargarDocumento">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!---------------------------------------------- moneda-->
                        <v-combobox id="formdisabledInput" :disabled="InputDisabled" autocomplete="off" dense outlined
                          label="Moneda" :items="Rmoneda" item-text="nombrecorto" :rules="[rules.required]"
                          v-model="FacturaProveedor.moneda" @blur="SelctCbMoneda" @focus="cargarMoneda">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>

                        <!-- Tipo de gasto -----------------------:disabled="InputDisabled"---------------------------->
                        <v-combobox id="formdisabledInput"  v-model="tipogasto"
                          label="Tipo de Gasto:" required outlined dense autocomplete="off" :items="RTipoGasto"
                          item-text="descripcion" @focus="CargarRTipopago" @blur="SelctCbTipoGasto" placeholder=" "
                          style="font-size: 13px">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="2" sm="2">
                        <!-- Factura No --------------------------------------------------->
                        <v-text-field label="* Factura No:" dense outlined :rules="[rules.required]"
                          v-model="FacturaProveedor.referencia" @blur="repetidosNoFactura" v-on:blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
                          
                        <AutoNumericNuevoWithOptions :options="{ decimalPlaces: '4' }" id="formdisabledInput" :disabled="InputDisabled" label="tasa:" dense outlined
                          autocomplete="off" v-model.number="FacturaProveedor.tasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumericNuevoWithOptions>

                        <!-- ----------------------------- descuento descuentoIndividual-->
                        <AutoNumeric id="formdisabledInput" :disabled="InputDisabled" label="Desc Individual:" dense
                          outlined autocomplete="off" v-model.number="FacturaProveedor.descuentoIdividual"
                          @blur="calcularDescuentoIndividual"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>
                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="2" sm="2">
                        <!-- Código fijo NCF --------------------------------------------------->
                        <v-text-field label=" Código fijo NCF :" dense outlined placeholder=" " style="font-size: 13px"
                          @blur="repetidosNcf" v-on:blur="BlurCambioMayuscula" v-model="datosNCF.codigoFijo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>

                        <!------------------------------------------------ Imp. Selectivo -->
                        <AutoNumeric id="formdisabledInput" :disabled="InputDisabled" label="Imp. Selectivo:" dense
                          outlined autocomplete="off" v-model.number="FacturaProveedor.selectivo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>

                        <!------------------------------------------------ ITBIS incluido -->
                        
                        <!-- no-gutters -->
                        <!-- <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;" ></v-col> -->
                          <v-row  >
                            <v-col cols="6" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                                    <v-switch id="formdisabledInput" :disabled="InputDisabled" class="mt-0"
                                   v-model="FacturaProveedor.itbisincluido" label="ITBIS incluido" color="success" hide-detail
                                    @change="calcularImpuesto" hide-detail>
                                 <template v-slot:label>
                                  <strong style="color: #000000; font-size: 13px">ITBIS incluido</strong>
                                </template>
                              </v-switch>
                           </v-col>

                           <v-col cols="6" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                            <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-btn  id="boton-mini" color="red accent-2" v-on="on" dark small
                              @click.prevent="openDialogCargerImagen">
                              <i style="font-size: 18px" class="fa fa-file-image-o">
                              </i>
                              Cargar IMG
                            </v-btn>
                          </template>
                          <span>Cargar imagen </span>
                        </v-tooltip>
                           </v-col>

                          </v-row>

                          

                      </v-col>
                      <!-- columna 3 ------------------------------>

                      <!-- columna 4 ------------------------------>
                      <v-col cols="12" md="2" sm="2">
                        <!-- Secuencia NCF --------------------------------------------------->
                        <v-text-field label="Secuencia NCF:" dense outlined v-model="datosNCF.secuencia" placeholder=" "
                          style="font-size: 13px" @blur="repetidosNcf" v-on:blur="completarDigitos">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Valor -->
                        <AutoNumericNuevoWithOptions :options="{ decimalPlaces: '4' }" id="formdisabledInput" :disabled="InputDisabled" label="* Valor:" dense outlined
                          autocomplete="off" v-model.number="FacturaProveedor.total" :rules="[rules.required]"
                          v-on:blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumericNuevoWithOptions>

                        <v-tooltip top v-if="ProveedorOrdenCompra.length > 0">
                          <template v-slot:activator="{ on }">
                            <v-btn :disabled="InputDisabled" id="boton-mini" color="blue lighten-3" v-on="on" dark small
                              @click.prevent="OpenProveedorOrdenCompra">
                              <i style="font-size: 18px" class="fa fa-cart-arrow-down">
                              </i>
                              Ordenes de compra
                            </v-btn>
                          </template>
                          <span>Ordenes de compra </span>
                        </v-tooltip>
                      </v-col>
                      <!-- columna 4 ------------------------------>
                    </v-row>
                  </v-form>
                </div>
<!-- unidad por si acaso :   :PropsUnidadminima="false" -->

                <v-col cols="12" md="12" sm="12" style="margin-top: -35px">
                  <TablaProductoCxp ref="detalle" :ListProd="ListProd" :PropsFacturaProveedor="true" :PropsDecimalPlaces="4"
                    :PropsInputDisabled="InputDisabled" :EntradaAlmacen="almacen" :ProsActuAlmacen="ActualizaAlmacen"
                    @DataTotalList="DDataTotalList" @DataActAlmacen="ActualizaAlmacen = false"
                      :PropsUnidadminima="this.$store.getters.GetConfiguracionGeneral.configCxp.unidadminima"
                     />
                </v-col>
              </v-card>
            </div>
          </v-app>
        </td>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }"></div>
        </td>
      </tr>
    </table>
    <!-- Modales ----------------------------------------------------------------------------------------------->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Búsqueda por #Factura"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small @click.prevent="
                    (dialogFiltros = true),
                    (ObjTemTbDinam = JSON.parse(
                      JSON.stringify(Modallfiltro)
                    ))
                  ">
                    <i style="font-size: 25px" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              {
                text: 'Código',
                value: 'facturasProveedoresPK.codigo',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: '#Factura',
                value: 'referencia',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Fecha',
                value: 'fecha',
                width: 120,
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Proveedor',
                value: 'proveedor.nombre',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Valor',
                value: 'valor',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Documento',
                value: 'documentoproveedor.descripcion',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Moneda',
                value: 'moneda.descripcion',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Comentario',
                value: 'comentario',
                sortable: false,
                class: ['no-gutters black--text'],
              },
              {
                text: 'Estado',
                value: 'activada',
                sortable: false,
                class: ['no-gutters black--text'],
              },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == true">Activo</span>
                <span v-if="props.item.activada == false">Anulado</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'SaveContFactPro'" @click.prevent="saveReg(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn v-if="notif.Exec == 'AnularFactPro'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->
    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->
    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (dialogFiltros = false),
            (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
          ">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <br />
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="Proveedor:" style="font-size: 13px"
                :items="Rproveedor" item-text="nombre" v-model="Modallfiltro.proveedores"
                @blur="SelctCbProveedoresFiltro" @focus="cargarProveedor" @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-text-field label="* Factura No:" dense outlined :rules="[$rules.required]"
                v-model="Modallfiltro.factura">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-slack"></i>
                </template>
              </v-text-field>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">
              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->
            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal filtro -->

    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE LISTADO REGISTRO FACTURAS DE PROVEEDORES</b>
          </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

                <v-checkbox class="mt-0" color="teal" label="" v-model="datosImprecionAll.FecRegi" width="50">
                  <template v-slot:label>
                    <strong v-if="datosImprecionAll.FecRegi == true" style="color: #000000; font-size: 13px">Fecha de
                      Registro: Si</strong>
                    <strong v-if="datosImprecionAll.FecRegi == false" style="color: #000000; font-size: 13px">Fecha de
                      Registro: No</strong>
                  </template>
                </v-checkbox>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />
      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">
      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

    <!-- ------------------------------------------------------ Modal Provedor orden de compra -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogProveedorOrdenCompra" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>Ordenes de Compra</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogProveedorOrdenCompra = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-data-table class="RowSelectColor" :single-select="true" item-key="indx" :show-select="true"
                v-model="ProveedorOrdenCompraSelected" @item-selected="ProveedorOrdenCompraObtenerDatoSelect"
                @click:row="ProveedorOrdenCompraDatostabla" :headers="[
                  {
                    text: 'CODIGO',
                    sortable: false,
                    value: 'codigo',
                    width: 'auto',
                  },
                  {
                    text: 'FECHA',
                    sortable: false,
                    value: 'fecha',
                    width: 120,
                  },
                  {
                    text: 'VALOR',
                    sortable: false,
                    value: 'valor',
                    width: 'auto',
                    width: 'auto',
                  },
                  {
                    text: 'CONCEPTO',
                    sortable: false,
                    value: 'comentario',
                    width: 200,
                  },
                ]" dense :items="ProveedorOrdenCompra" :items-per-page="-1">
                <template v-slot:item.fecha="props">
                  <span style="font-size: 13px; display: none">{{
                      (props.item.indx = ProveedorOrdenCompra.indexOf(props.item))
                  }}</span>
                  <span style="font-size: 13px">{{
                      FormatoFecha(props.item.fecha)
                  }}</span>
                </template>

                <template v-slot:item.codigo="props">
                  <span style="font-size: 13px">{{ props.item.codigo }} </span>
                </template>

                <template v-slot:item.valor="props">
                  <span style="font-size: 13px">{{
                      currencyFormatter(props.item.valor)
                  }}</span>
                </template>

                <template v-slot:item.concepto="props">
                  <span style="font-size: 13px">{{ props.item.concepto }}
                  </span>
                </template>
              </v-data-table>
            </v-col>

            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal Provedor orden de compra -->

    <!-- ------------------------------------------------------ Modal- Restaurar temporal-->
    <v-dialog transition="fab-transition" label="Modal Restaurar temporal" v-model="dialogRestaurarTemporal" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>RESTAURAR TEMPORAL</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogRestaurarTemporal = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <!-- @click:row="RestaurarTempDatostabla"  -->
              <v-data-table class="RowSelectColor" item-key="indx" :headers="[
                {text:'CODIGO', sortable: false, value: 'codigo',width: 'auto',class: ['no-gutters black--texte'],},
                {text:'PROVEEDOR',sortable: false,value: 'proveedor.nombre',width: 'auto',class: ['no-gutters black--texte'],},
                {text:'FECHA',sortable: false,value: 'nuevaFecha',width: 120,class: ['no-gutters black--texte'],},
                {text:'ACCIÓN', value: 'action', width: '110', sortable: true}
              ]" dense :items="RestaurarTempList" :items-per-page="-1">
                <template v-slot:item.nuevaFecha="props">
                  <span style="font-size: 13px; display: none">{{(props.item.indx = RestaurarTempList.indexOf(props.item))}}</span>
                  <span style="font-size: 13px">{{
                      FormatoFecha(props.item.nuevaFecha)
                  }}</span>
                </template>

                <template v-slot:item.codigo="props">
                  <span style="font-size: 13px">{{ props.item.documentoproveedor.nombrecorto }}-{{
                      props.item.facturasProveedorestempPK.codigo
                  }}
                  </span>
                </template>

                <template v-slot:item.proveedor.nombre="props">
                  <span style="font-size: 13px">{{
                      props.item.proveedor.nombre
                  }}</span>
                </template>


                <template v-slot:item.action="{ item }">
                  <v-btn fab @click.prevent="RestaurarTempDatostabla(item)" x-small color="yellow accent-4"><i
                      style="font-size: 20px;" class="fa fa-eye"></i></v-btn>

                  <v-btn fab @click.prevent="ImprimirRestaurarFact(item)" x-small color="blue"><i
                      style="font-size: 20px;" class="fa fa-print"></i></v-btn>
                </template>


              </v-data-table>
            </v-col>

            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Restaurar temporal-->

    <!-- ------------------------------------------------------ Seleccionar impresora -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogImprimirEtiqueta" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>SELECCIÓN DE IMPRESORA </b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click.prevent="dialogImprimirEtiqueta = false">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="12" sm="12">
              <v-combobox autocomplete="off" dense outlined label="Impresora de código de barra:" @focus="buscarFormato"
                :items="RlistFormato" item-text="descripcion" v-model="ImprirEtiqueta.ImpesoraBarra">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-barcode"></i>
                </template>
              </v-combobox>

              <v-combobox v-if="false" autocomplete="off" dense outlined label="Seleccione Terminal:"
                v-model="ImprirEtiqueta.seleCtTerminar">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-trademark"></i>
                </template>
              </v-combobox>

              <v-combobox autocomplete="off" dense outlined label="Seleccione Impresora:" @focus="cargarImpresoras"
                :items="RlistImpresora" item-text="nombre" v-model="ImprirEtiqueta.selectImpresora">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-print"></i>
                </template>
              </v-combobox>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn-toggle rounded class="" dense>
            <v-btn color="blue" @click.prevent="ImprimirEtiqueta">
              <i style="font-size: 28px" class="fa fa-print"> </i>
              Imprimir
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-------------------------------------------------------- fn Seleccionar impresora -->
    <!-- hide-overlay max-width="95%" max-height="85%" -->
 <!-- ------------------------------------------------------ Modal- cargar imagen-->
 <v-dialog transition="dialog-bottom-transition" label="Modal cargar imagen" v-model="dialogCargerImagen" persistent fullscreen  >
   <v-card class="mx-auto" style="border: 1px solid #000000;" >
      <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
           <!--------------------- Creacion cargar imagen------------------------------->
      <div>
       
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Cargar Imagen
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Cargar Imagen: </span> Cargar Imagen</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarCargarImagen">
                 <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>

            

          </v-card-actions>
        </v-toolbar>
        <!-- <v-card> -->
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Cargar Imagen</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                 <v-form  ref="formInputFileImg" v-model="valid" lazy-validation>


                  <v-file-input dense outlined accept="image/png, image/jpeg, image/bmp" @change="ConvertirImg"
                  ref="files" id="imagenfooter" show-size counter small-chips v-model="objCargaImg.cargaImg" multiple
                  prepend-icon="mdi-camera" label="Cargar Imagen" :rules="[$rules.required]"
                  >
                  <template v-slot:append-outer>

                    <v-tooltip  top>
                    <template v-slot:activator="{ on }">

                      <v-btn @click.prevent="addArchivoList" v-on="on" color="green" fab x-small
                        dark>
                        <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                      </v-btn>

                    </template>
                    <span>Agregar a la lista</span>
                  </v-tooltip>
                  <!--  
                <v-tooltip top v-show="objCargaImg.codigo>0">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab @click.prevent="eliminarCargaImg()" x-small color="red darken-3">
                      <i style="font-size: 25px;" class="fa fa-trash-o"></i></v-btn>

                  </template>
                  <span>eliminar imagen</span>
                </v-tooltip>
                -->

                </template>
                </v-file-input>
                <!-- max-height="auto" -->
                  <div v-if="loadercargarFoto==true"> 
                    <center>
                <v-img v-if="objCargaImg.imgView!=null && objCargaImg.imgView.trim().length>0 " 
                  max-height="150"
                  max-width="250"
                  style="border: 2px solid #000000;" 
                  :src="objCargaImg.imgView"
                  :lazy-src="objCargaImg.imgView"></v-img>
                </center>
                </div>
                  </v-form>

                  <v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" v-for="(list, i) in listObjFoto" :key="i"> 
                    <span style="font-size:13px; display:none;">{{ list.indx =listObjFoto.indexOf(list)
                      }}</span>
                  <!-- Col 1 -->
                    <v-col style="border: 1px dashed  #616161; background-color:#3F51B5;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    
                      <v-row no-gutters>  
                      <span style="font-size: 25px;"  :class="`d-flex justify-start white--text`"><b>#{{i+1}}</b></span>
          <!-- v-show="objCargaImg.codigo>0" -->
          <v-spacer></v-spacer>
                      <div :class="`d-flex justify-end`">
                     <v-tooltip top   >
                   <template v-slot:activator="{ on }">
                    <v-btn  v-on="on" fab @click.prevent="eliminarCargaImg(list)" x-small color="red darken-3">
                      <i :class="`white--text`" style="font-size: 25px;" class="fa fa-trash-o"></i></v-btn>

                   </template>
                   <span :class="`white--text`">eliminar imagen</span>
                   </v-tooltip>
                   </div>
                   </v-row>


                    </v-col>
                  <!-- fin Col 1 -->
                   <!-- Col 2 -->
                    <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <v-img v-if="list.imagen!=null && list.imagen.trim().length>0 " 
                  max-height="auto"
                  style="border: 2px solid #000000;" 
                  :src="list.imagen"
                  :lazy-src="list.imagen"></v-img>
                    </v-col>
                <!-- fin Col 2 -->
                  </v-col>


                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->
                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Datos -->

        <!-- </v-card> -->
       
      </div>
      </v-col>
     </v-card>
  </v-dialog>
  <!-- ------------------------------------------------------ fn Modal- cargar imagen-->



<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>


</div>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import AutoNumeric from "../../components/AutoNumericNuevo";
import AutoNumericNuevoWithOptions from "../../components/AutoNumericNuevoWithOptions.vue";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import TablaProductoCxp from "../../CuentaXPagar/UtilidadComponents/TablaProductoCxP";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva,
  getParamFecha,
  RedondeoBasico,
  getFechaZone
} from "@/js/Funciones.js";
import CuentasContables from "../../components/CuentasContables";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    TablaProductoCxp,
    CuentasContables,
    BarraDinamicaLimitePlus,
    AutoNumericNuevoWithOptions,
    PvMenuOpcionBotones
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitFacturaProveedor", this.datostabla);
    // EventBus.$on("cxpFacturaProveedorNew", this.new);
    // EventBus.$on("cxpFacturaProveedorSave", this.save);
    // EventBus.$on("cxpFacturaProveedorRemove", this.remove);
    // EventBus.$on("cxpFacturaProveedorAnular", this.anular);
    // EventBus.$on("cxpFacturaProveedorPrintList", this.imprimirList);
    // EventBus.$on("cxpFacturaProveedorVercuenta", this.vercuenta);
    // EventBus.$on("cxpFacturaProveedorSaavetem", this.Saavetem);
    // EventBus.$on("cxpFacturaProveedorRestaurartem", this.Restaurartem);
    EventBus.$on("onResize", this.onResize);
    this.loadInfo();
console.log(".")
  },

  created() {
    var json = {
      titulo: "Registro Facturas de Proveedores",
      descripcion: "Esta función permite realizar Facturas a proveedores.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,
      stemporal: true,
      rtemporal: true,

      accion: [
        "cxpFacturaProveedorNew",
        "cxpFacturaProveedorSave",
        "cxpFacturaProveedorRemove",
        "cxpFacturaProveedorAnular",
        "cxpFacturaProveedorPrintList",
        "cxpFacturaProveedorVercuenta",
        "cxpFacturaProveedorSaavetem",
        "cxpFacturaProveedorRestaurartem",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
    this.cargarMoneda();
    this.findAlmacenPrincipal();
  },
  data() {
    return {
      objobj:null,
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      EjecutarPermisoPv:false,
      ObjEjecPermisoPv:null,
      dialogOrdenSugerida: false,
      dialogimprimirList: false,
      dialogImprimirEtiqueta: false,
      dialogCargerImagen:false,
      loadercargarFoto:false,
      listObjFoto:[],
      objCargaImg:{
        codigo:0,
        codigofactura:0,
        documentofactura:0,
        cargaImg: null,
        imgView:'',
      },
      
      InputDisabled: false,
      ImprirEtiqueta: {
        selectImpresora: null,
        seleCtTerminar: null,
        ImpesoraBarra: null,
        desde:' fechaNueva()',
      },
      datosImprecion: {
        nombre: "REGISTRO FACTURAS DE PROVEEDORES",
        UrlImprimir: "/facturasproveedor/imprimir",
        UrlExel: "/facturasproveedor/excel",
        UrlCorreo: "/facturasproveedor/correo",
      },
      configuraciones: null,
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: "LISTADO DE REGISTRO FACTURAS DE PROVEEDORES",
        UrlImprimir: "/facturasproveedor/ImprimirList",
        UrlExel: "/facturasproveedor/ExcelList",
        UrlCorreo: "/facturasproveedor/CorreoList",
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documentos: null,
        FecRegi: false,
      },
      ActualizaAlmacen: false,
      almacen: null,
      TotalProd: {
        cantidad: 0,
        cantExit: 0,
        costo: 0,
        impuesto: 0,
        descuento: 0,
        importe: 0,
      },
      ListProd: [],

      dialogFiltros: false,
      Modallfiltro: {
        proveedores: null,
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },

      Rmoneda: [],
      Rproveedor: [],
      RallProducto: [],
      Rdepartamento: [],
      RlistFormato: [],
      RlistImpresora: [],
      Ralmacen: [],
      RDocumento: [],
      RTipoGasto: [],
      acti_Modal_cuenta: false,
      objeTem: null,
      TotalCuenta: null,
      RLisCuenta: [],
      contabilidad: null,
      dialogRestaurarTemporal: false,
      RestaurarTempList: [],
      objRestaurarTemp:null,
      aalert: {
        estado: false,
        color: null,
      },
      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },
      search: "",
      ObjTemp: null,
      OrdenCompra: {
        desde: fechaNueva(),
        hasta: fechaNueva(),
        dia: 0,
        proveedor: null,
        almacen: null,
        opcion: 1,
      },
      datosNCF: {
        secuencia: "",
        codigoFijo: "",
      },
      dialogProveedorOrdenCompra: false,
      ProveedorOrdenCompraSelected: [],
      ProveedorOrdenCompra: [],
      FacturaProveedor: {
        facturasProveedoresPK: { codigo: 0, documento: 0 },
        empresa: null,
        fecha: fechaNueva(),
        referencia: "",
        ordencompra: null,
        diascredito: 0,
        tipogasto: 0,
        tiposuplidor: null,
        ncf: "",
        totalimpuesto: 0,
        totaldescuento: 0,
        total: 0.0,
        comentario: null,
        tasa: 0,
        pendiente: 0.0,
        activada: true,
        fechaCreacion: fechaNueva(),
        fechaModificacion: null,
        usuarioCreacion: "",
        usuarioModificacion: null,
        selectivo: 0.0,
        facturaGastos: false,
        itbisincluido: false,
        pagosuspendido: false,
        comentarioSuspencion: "",
        descuentoIdividual: 0,
        borrado: false,
        detalleJson: "",
        documentoproveedor: "",
        moneda: null,
        proveedor: null,
        facturasProveedoresDetalleList: [],
         totaldescuento:0,
         totalimpuesto:0,
         listFoto:[]
      },
      tipogasto: null,
      RLisCuenta: [],
      diasCredito: 0,
      TotalCuenta: {
        credito: 0,
        debito: 0,
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: true,
        guardartemporal: true,
        restaurartemporal: true,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        reimprimirEtiqueta: false
      },
      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0,
      },
      rules: {
        required: (value) => !!value || "Required.",
        edad: [
          (val) => val > 10 || `Edad debe ser mayor a 10`,
          (val) => val < 70 || `Edad debe ser menor a 120`,
        ],
        porciento: (value) => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: (value) =>
          (value && value.length <= 50) || "Max 20 characters",
        RTel: (value) => value != 10 || `telefono debe tener 10 digitos`,
      },
      headers: [{ text: "Tarjeta", value: "nombre" }],
      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
    };
  },
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    getParamFecha,
    RedondeoBasico,
    getFechaZone,
    onResize(e) {
      //this.windowSize=e
    },
    findAlmacenPrincipal() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/principal", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.almacen = res.data;
        });
    },
    Saavetem() {

      if (this.$refs.form.validate()) {
      EventBus.$emit("loading", true);
      this.llenarTemporal();
      //EventBus.$emit("loading", true);
      this.FacturaTemporal.ncf = this.datosNCF.codigoFijo.trim() + this.datosNCF.secuencia.trim();
      this.FacturaTemporal.fecha=this.getFechaZone(this.FacturaTemporal.fecha)
      if (this.tipogasto != null) { this.FacturaTemporal.tipogasto = this.tipogasto.codigo }
/*console.log('------- Informacion ----')
console.log(this.objobj)
EventBus.$emit("loading", false);
this.ejecucion = true;
return  this.FacturaTemporal       */
  this.$axios
        .post(
          this.$hostname + this.$hName + "/facturasproveedor/salvarTemporal",
          this.FacturaTemporal  ,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data) {
            // if (this.RestaurarTempList.length > 0) {
            if (this.objRestaurarTemp!=null) {
              this.borrarTemporar();
            } else {
              this.alerta("Guardado corretamente!!!", "bien");
            }

          }
        }).catch((error) => {
          EventBus.$emit("loading", false);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            
          } else if (error.request) {

            console.log(error.request);
          } else {
            console.log(error.message);
          }
          console.log(error.config);
        });

      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }



    },
    llenarTemporal() {
      this.FacturaTemporal = {
        facturasProveedorestempPK: {
          documento: 0,
          codigo: 0,
        },
        empresa: this.FacturaProveedor.empresa,
        fecha: this.FacturaProveedor.fecha,
        referencia: this.FacturaProveedor.referencia,
        ordencompra: this.FacturaProveedor.ordencompra,
        diascredito: this.FacturaProveedor.diascredito,
        tipogasto: this.FacturaProveedor.tipogasto,
        ncf: "",
        totalimpuesto: this.FacturaProveedor.totalimpuesto,
        totaldescuento: this.FacturaProveedor.totaldescuento,
        total: this.FacturaProveedor.total,
        comentario: this.FacturaProveedor.comentario,
        tasa: this.FacturaProveedor.tasa,
        pendiente: this.FacturaProveedor.pendiente,
        activada: true,
        selectivo: this.FacturaProveedor.selectivo,
        itbisincluido: this.FacturaProveedor.itbisincluido,
        borrado: false,
        pendienteHacer: true,
        facturasProveedoresDetalletempList: [],
        documentoproveedor: this.FacturaProveedor.documentoproveedor,
        moneda: this.FacturaProveedor.moneda,
        proveedor: this.FacturaProveedor.proveedor,
      };
      this.ListProd.forEach((element) => {
        this.llenarDetalleTemporal(element);
      });
    },
    buscarFormato() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/etiquetas/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RlistFormato = res.data;
        });
    },
    cargarImpresoras() {
      this.$axios
        .get("http://localhost:15999/impresora/inicial/listaImpresora",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RlistImpresora = res.data[0].impresoras;
        });
    },
    llenarDetalleTemporal(datos) {
      if (datos.producto != null) {
        var detalle = {
          facturasProveedoresDetalletempPK: {
            codigo: 0,
            documento: this.FacturaProveedor.documentoproveedor.codigo,
            almacen: datos.almacen.codigo,
            unidad: datos.unidad.codigo,
            producto: datos.producto.codigo,
            oferta: datos.ofertaSelect,
          },
          // fechavencimiento: datos.fechavencimiento,
          fechavencimiento: datos.fechavencimiento!= null ? datos.fechavencimiento+"T04:00" : null,
          cantidad: datos.cantidad,
          precio: datos.precio,
          descuento: datos.descuento,
          impuesto: datos.impuesto,
          valor: datos.valor,
          costo: datos.costo,
          precioFactura: datos.precio,
          descuentoPorciento: datos.descuentoPorciento,
          impuestoIncluido: this.FacturaProveedor.itbisincluido,
          borrado: false,
          almacenes: datos.almacen,
          productos: datos.producto,
          unidades: datos.unidad
          //,id:datos.indx
        };
        this.FacturaTemporal.facturasProveedoresDetalletempList.push(detalle);
      }
    },
    importarDato() { },

    RestaurarTempDatostabla(e){
      EventBus.$emit("loading", true); 
      this.newwnuevo()
      var _this = this;
      setTimeout(function () {
        _this.contRestaurarTempDatostabla(e)
      }, 600);
    },

    contRestaurarTempDatostabla(e) {
      this.tipogasto = null
      this.RLisCuenta = []
      var Obj = JSON.parse(JSON.stringify(e))
      Obj.fecha=e.nuevaFecha
      this.objRestaurarTemp=JSON.parse(JSON.stringify(Obj))
      delete Obj["facturasProveedorestempPK"]
      delete Obj["facturasProveedoresDetalletempList"]
      //Obj.documentoproveedor=e.documentoproveedor
      //Obj.referencia=e.referencia
      Obj.facturasProveedoresPK = {
        codigo: 0,
        documento: 0
      }
      Obj.facturasProveedoresDetalleList = []
      if (e.facturasProveedoresDetalletempList != null && e.facturasProveedoresDetalletempList.length > 0) {

        var FactPro=JSON.parse(JSON.stringify(e.facturasProveedoresDetalletempList))

        var bar = new Promise((resolve, reject) => {
  
          FactPro.sort(function (a, b) {
    if (a.id > b.id) {return 1;}
    if (a.id < b.id) {return -1;}
    // a must be equal to b
    return 0;
  });
  
  resolve('resolved');
  });
  bar.then(() => {console.log('All done!');});

          FactPro.forEach(element => {
          var varObj = JSON.parse(JSON.stringify(element))
          delete varObj["facturasProveedoresDetalletempPK"]
          varObj.facturasProveedoresDetallePK = JSON.parse(JSON.stringify(element.facturasProveedoresDetalletempPK))
          varObj.facturasProveedoresDetallePK.codigo = 0
          varObj.facturasProveedoresDetallePK.documento = 0
          Obj.facturasProveedoresDetalleList.push(varObj)
        });

      }
      var _this = this;
      setTimeout(function () {
        _this.calcularImpuesto()
        _this.datostabla(Obj);
        _this.dialogRestaurarTemporal = false;
      }, 500);

      if (e.tipogasto != null) {
        var ObjProRow = this.RTipoGasto.filter((ee) => ee.codigo == e.tipogasto);
        if (ObjProRow.length > 0) {
          this.tipogasto = JSON.parse(JSON.stringify(ObjProRow[0]))
        }
      }


      setTimeout(function () {
        _this.$refs.detalle.DisableCosto = false
      }, 1000);



      

      /*  this.FacturaProveedor = {
          facturasProveedoresPK: { codigo: 0, documento: 0 },
          empresa: null,
          fecha: fechaNueva(),
          referencia: e.referencia,
          ordencompra: e.ordencompra,
          diascredito: 0,
          tipogasto: e.tipogasto,
          tiposuplidor: null,
          ncf: e.ncf,
          totalimpuesto: e.totalimpuesto,
          totaldescuento: e.totaldescuento,
          total: e.total,
          comentario: e.comentario,
          tasa: e.tasa,
          pendiente: e.pendiente,
          activada: true,
          fechaCreacion: fechaNueva(),
          fechaModificacion: null,
          usuarioCreacion: "",
          usuarioModificacion: null,
          selectivo: e.selectivo,
          facturaGastos: false,
          itbisincluido: e.itbisincluido,
          pagosuspendido: false,
          comentarioSuspencion: "",
          descuentoIdividual: e.descuentoIdividual,
          borrado: false,
          detalleJson: "",
          documentoproveedor: e.documentoproveedor,
          moneda: e.moneda,
          proveedor: e.proveedor,
          facturasProveedoresDetalleList: [],
        };
        if (e.ncf != "") {
          // if (e.ncf.trim().length==19) {
          this.datosNCF.codigoFijo = e.ncf.substring(0, 3);
          this.datosNCF.secuencia = e.ncf.substring(3);
          //}
        }
        var detalle = [];
        this.$axios
          .get(
            this.$hostname +
              this.$hName +
              "/facturasproveedor/buscarTemporaldetalle/" +
              e.facturasProveedorestempPK.documento +
              "/" +
              e.facturasProveedorestempPK.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            res.data.forEach((element) => {
              this.$refs.detalle.addDetalleOrdenAFactura(element);
            });
          });*/
    },

    Restaurartem() {
      if (this.FacturaProveedor.proveedor == null) {
        this.alerta("Debe de seleccionar un proveedor.", "error");
        return;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/facturasproveedor/buscarTemporal/" +
          this.FacturaProveedor.proveedor.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RestaurarTempList = res.data;
          this.dialogRestaurarTemporal = true;
        });

    },
    repetidosNoFactura(e) {
      if (this.FacturaProveedor.proveedor == null) {
        return;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/facturasproveedor/facturaUsada/" +
          this.FacturaProveedor.proveedor.codigo +
          "/" +
          e.target.value +
          "/0",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data) {
            this.alerta(
              "Esta factura Esta siendo usada por este proveedor !!!",
              "error"
            );
          }
        });
    },
    completarDigitos(e) {
      if (e.target.value != null) {
        var resultado = ("000000000" + e.target.value).substring(
          ("000000000" + e.target.value).length - 8,
          ("000000000" + e.target.value).length
        );
        this.datosNCF.secuencia = resultado;
      }
    },
    repetidosNcf() {
      if (this.FacturaProveedor.proveedor == null) {
        return;
      }
      if (this.datosNCF.codigoFijo == null) {
        return;
      }
      if (this.datosNCF.secuencia == null) {
        return;
      }

      var ncf =
        this.datosNCF.codigoFijo.trim() + this.datosNCF.secuencia.trim();
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/facturasproveedor/facturaUsada/" +
          this.FacturaProveedor.proveedor.codigo +
          "/0/" +
          ncf,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data) {
            this.alerta(
              "Esta factura Esta siendo usada por este proveedor !!!",
              "error"
            );
          }
        });
    },
    vercuenta() {
      if (this.$refs.form.validate()) {
        //this.getCrearCont()
        if (this.RLisCuenta.length > 0) {
          this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
          this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta));
          this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
        } else {
          this.getCrearCont();
          this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
    getCrearCont() {
      if (!this.$refs.form.validate()) {
      } else {
        if (this.configuraciones.config.multimoneda) {
          if (this.FacturaProveedor.facturasProveedoresPK.codigo == 0) {
            this.$axios
              .get(
                this.$hostname +
                this.$hName +
                "/contabilidad/crear-contabilidad-cxp-multimoneda/" +
                this.FacturaProveedor.documentoproveedor.codigo +
                "/" +
                this.FacturaProveedor.total +
                "/" +
                this.TotalProd.impuesto +
                "/" +
                this.TotalProd.descuento +
                "/0/0/0/" +
                this.FacturaProveedor.moneda.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                this.llenarcuenta(res.data);
              });
          }
        } else {
          if (this.FacturaProveedor.facturasProveedoresPK.codigo == 0) {
            this.$axios
              .get(
                this.$hostname +
                this.$hName +
                "/contabilidad/crear-contabilidad-cxp/" +
                this.FacturaProveedor.documentoproveedor.codigo +
                "/" +
                this.FacturaProveedor.total +
                "/" +
                this.TotalProd.impuesto +
                "/" +
                this.TotalProd.descuento +
                "/0/0",
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                this.llenarcuenta(res.data);
              });
          }
        }
        if (this.FacturaProveedor.facturasProveedoresPK.codigo != 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/contabilidad/findContabilidad/" +
              this.FacturaProveedor.facturasProveedoresPK.documento +
              "/" +
              this.FacturaProveedor.facturasProveedoresPK.codigo +
              "/" +
              this.FacturaProveedor.documentoproveedor.operacion.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.llenarcuenta(res.data);
            });
        }
      }
    },
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.FacturaProveedor.fecha;
        this.contabilidad.documento =
          this.FacturaProveedor.documentoproveedor.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.RLisCuenta = this.RLisCuenta;
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1);
    },
    calcularTotalcuenta(e) {
      this.TotalCuenta.credito = 0;
      this.TotalCuenta.debito = 0;
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach((element) => {
          this.TotalCuenta.credito += element.credito;
          this.TotalCuenta.debito += element.debito;
        });
      }
    },
    CCuentasContables(e, t, b) {
      if (e != undefined) {
        if (e.length == 0) {
          this.getCrearCont();
        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t));
        }
      }

      this.acti_Modal_cuenta = b;
    },

    VerFacturaProveedor() { },

    CargarRTipopago() {
      this.$axios
        .get(this.$hostname + this.$hName + "/categoriagastos/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RTipoGasto = res.data;
        });
    },
    SelctCbTipoGasto() {
      if (this.tipogasto == null) {
        this.tipogasto = "";
      }
      if (this.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.tipogasto = "";
        return;
      }
      this.FacturaProveedor.tipogasto = this.tipogasto.codigo;
    },

    cargarDocumento() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentoscxp/findAllDocumentosFacturasProveedores",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.RDocumento = res.data));
    },

    SelctCbDocumento() {


      var _this = this;
      setTimeout(function () {
        if (_this.FacturaProveedor.documentoproveedor == null) {
          _this.FacturaProveedor.documentoproveedor = "";
        }

        if (!_this.FacturaProveedor.documentoproveedor.hasOwnProperty('codigo')) {
          _this.FacturaProveedor.documentoproveedor = "";
          _this.FacturaProveedor.documentoproveedor = null;
          return;
        }
      }, 300);


      /*FacturaProveedor.documentoproveedor
      if (this.FacturaProveedor.documentoproveedor == null) {
        this.FacturaProveedor.documentoproveedor = "";
      }
      if (this.FacturaProveedor.documentoproveedor.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.FacturaProveedor.documentoproveedor = "";
        return;
      }*/
    },
    loadInfo() {
      this.configuraciones = this.$store.getters.GetConfiguracionGeneral;
      this.FacturaProveedor.moneda = this.configuraciones.config.monedabase;
      this.FacturaProveedor.tasa = this.configuraciones.config.monedabase.tasa;
      if (this.RTipoGasto.length <= 0) {
        this.CargarRTipopago();
      }
    },
    cargarOrdenCompra() {
      if (this.FacturaProveedor.proveedor == null) {
        return
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ordenescompras/proveedor/" +
          this.FacturaProveedor.proveedor.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.ProveedorOrdenCompra = res.data;
        });
    },
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.Ralmacen = res.data;
        });
    },
    SelctCbAlmacen() {
      if (this.OrdenSugerida.almacen == null) {
        this.OrdenSugerida.almacen = "";
      }
      if (this.OrdenSugerida.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.OrdenSugerida.almacen = "";
        return;
      }
    },
    keyupDiaOrden() {
      if (e.key) {
        var mynumeral = require("numeral");
        this.OrdenSugerida.dia = mynumeral(this.OrdenSugerida.dia).value();
        if (this.OrdenSugerida.dia == null) {
          this.OrdenSugerida.dia = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    addClikProd() { },
    borrarTemporar() {

      // if (this.RestaurarTempList.length > 0) {

       if (this.objRestaurarTemp != null) { 
        this.$axios
        .post(this.$hostname + this.$hName + "/facturasproveedor/anularTemporal", this.objRestaurarTemp, {
          // .post(this.$hostname + this.$hName + "/facturasproveedor/anularTemporal", this.RestaurarTempList[0], {
            headers: this.$store.getters.GetheadersAxios,
          })
          .then((res) => {
            this.alerta("Guardado corretamente!!!", "bien");;
          });
      }
    },
    newCrearProducto() { },


save() { 

if (this.FacturaProveedor.facturasProveedoresPK.codigo > 0) {
  if (this.EjecutarPermisoPv == false) {
      this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(this.FacturaProveedor)) 
        this.funcionValidaPermisoPv('AccModificarRegFacPro')
        return;
      }
    }else{
      this.saveCont()
    }
},
    

    saveCont() { 
      if (this.$refs.form.validate()) { 

        if(this.FacturaProveedor.diascredito==null || this.FacturaProveedor.diascredito==0){
        if(this.FacturaProveedor.diascredito==null){ this.FacturaProveedor.diascredito=0}
          var notif = {
          estado: true,
          nombre: "¿Esta Factura tiene 0 días de crédito(PLAZO). Desea salvarla??",
          Exec: "SaveContFactPro",
        };
        this.Exec_notif(notif);

        }else{
          this.saveReg()
        }

      }else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },

    async saveReg() {

      //  this.getCrearCont();
      var tot = Math.round(this.FacturaProveedor.total * 100) / 100;
      var imp = Math.round(this.TotalProd.importe * 100) / 100;

    
      if (this.datosNCF.codigoFijo.trim().length > 0 && this.datosNCF.secuencia.trim().length <= 0) {
        this.alerta("Debe de completar el NCF ya que solo tiene seleccionado el prefijo", "error");
        return;
      }
      
var CONTROLAR_TOTAL=true
if(this.configuraciones.configCxp.hasOwnProperty('noControlarFactura') && this.configuraciones.configCxp.noControlarFactura==true){
  CONTROLAR_TOTAL=false
}
if(CONTROLAR_TOTAL==true){
  // if (tot != imp) 
      if ((tot - imp)>0.01 || (tot - imp)< -0.01 ) {
        this.alerta(
          "El valor de la factura debe ser igual al total de los productos",
          "error"
        );
        return;
      }
    }
      if (this.$refs.form.validate()) {

        if(this.FacturaProveedor.tasa==null|| this.FacturaProveedor.tasa==0){
          this.alerta(" La tasa de la moneda no puede estar vacio o ser cero",
          "error"
        );
          return
        }
        if (this.ejecucion == true) {
          EventBus.$emit("loading", true);
          this.ejecucion = false;
          var list_pro = this.ListProd.filter(d => d.producto != null);

          this.FacturaProveedor.facturasProveedoresDetalleList = [];
          var productosVenciemitno = list_pro.filter(det => det.fechavencimiento !== null).length;
          var productosParaVencieminto = list_pro.filter(d => d.producto.controlVencimiento == true).length;

          if (this.tipogasto != null) { this.FacturaProveedor.tipogasto = this.tipogasto.codigo }
          if (this.FacturaProveedor.facturasProveedoresPK.codigo == 0) {
            if (productosVenciemitno != productosParaVencieminto) {
              console.log('hola Mundo',list_pro.filter(d => d.producto.controlVencimiento == true && d.fechavencimiento==null || d.fechavencimiento!=null && d.fechavencimiento.trim().length==0))
              var OBJLIST=list_pro.filter(d => d.producto.controlVencimiento == true && d.fechavencimiento==null || d.fechavencimiento!=null && d.fechavencimiento.trim().length==0);
              var DESCPRO=""
              if(OBJLIST.length>0){DESCPRO=OBJLIST[0].propsDesProdUnid.trim()}

              this.alerta(`Debe verificar los productos ya que existen productos que debe llevar fecha de vencimiento!!! \n (${DESCPRO})`, "error")
              return
            }
          }

         /* list_pro.forEach((e) => {
            this.llenarDetalle(e);
          });*/

          await Promise.all(
            list_pro.map(async (e) => {
              this.llenarDetalle(e);
            })
            ); 

          /*  this.FacturaProveedor.contabilidad = this.contabilidad;
            this.FacturaProveedor.cont = this.contabilidad;*/
          this.FacturaProveedor.ncf = this.datosNCF.codigoFijo.trim() + this.datosNCF.secuencia.trim();

          if (this.ListProd.filter((e) => e.cantidad > 0 && e.producto != null).length <= 0) {
            this.alerta(
              "Debe seleccionar almeno un producto para registrar una factura",
              "error"
            );
            return;
          }

          if (list_pro.filter(det => det.fechavencimiento!=null &&  det.fechavencimiento.length  > 10).length>0) {
            this.alerta(
              "Fecha de vencimiento erroneas, revisar las fechas de vencimiento.",
              "error"
            );
            list_pro.filter(det => det.fechavencimiento!=null && det.fechavencimiento.length  > 10).forEach(element => {
              console.log(element.producto.descripcion+" - "+element.unidad.descripcion);
            });
            return;
          }


          if (this.RLisCuenta.length > 0) {
            this.contabilidad.contabilidadDetalleList = this.RLisCuenta
            this.FacturaProveedor.cont = this.contabilidad
          }
          
          /*var oobj=this.FacturaProveedor
          oobj.facturasProveedoresDetalleList=this.objobj
          console.log(oobj)*/
          this.FacturaProveedor.totaldescuento= this.RedondeoBasico(this.$refs.detalle.TotalProd.descuento)
          this.FacturaProveedor.totalimpuesto=  this.RedondeoBasico(this.$refs.detalle.TotalProd.impuesto)
          
          this.FacturaProveedor.listFoto=this.listObjFoto;

          this.$axios
            .post(
              this.$hostname + this.$hName + "/facturasproveedor/salvar",
             this.FacturaProveedor ,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {

              var cod= this.FacturaProveedor.facturasProveedoresPK.codigo

              // if (this.RestaurarTempList.length > 0) {
                if (this.objRestaurarTemp!=null) {
                this.borrarTemporar();
              } else {
                this.alerta("Dato guardado correctamente", "bien")
              }

              if(cod==0){
              var _this = this;
              setTimeout(function () {
                _this.datostabla(res.data)
                _this.dialogImprimirEtiqueta = true
              }, 500);
              }
            })
            .catch((error) => {
              if (error.response) {
                this.alerta(error.response.data, "error")
              } else if (error.request) {
                this.alerta(error.request, "error")
              } else {
                this.alerta(error.message, "error")
              }
            });
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    llenarDetalle(datos) {
      var detalle = {
        facturasProveedoresDetallePK: {
          codigo: 0,
          documento: this.FacturaProveedor.documentoproveedor.codigo,
          unidad: datos.unidad.codigo,
          producto: datos.producto.codigo,
          oferta: datos.ofertaSelect,
        },
        //fechavencimiento: datos.fechavencimiento,
        fechavencimiento: datos.fechavencimiento!= null ? datos.fechavencimiento+"T04:00" : null,
        cantidad: datos.cantidad,
        precio: datos.precio,
        descuento: datos.descuento,
        impuesto: datos.impuesto,
        valor: RedondeoBasico(datos.valor),
        costo: datos.costo,
        precioFactura: datos.precio,
        impuestoIncluido: false,
        descuentoPorciento: false,
        borrado: false,
        almacen: datos.almacen,
        productos: datos.producto,
        unidades: datos.unidad,
        descuentoPorciento:datos.descuentoPorciento
      
      };
      this.FacturaProveedor.facturasProveedoresDetalleList.push(detalle);
    },
    NotifMessage() {
      if (
        this.FacturaProveedor.facturasProveedoresPK.codigo != 0 &&
        this.FacturaProveedor.facturasProveedoresPK.codigo != null
      ) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularFactPro",
        };
        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione una factura proveedor para anular", "error");
      }
    },
    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e));
    },
    eliminar() { },
    anular() {

      if (
        this.FacturaProveedor.facturasProveedoresPK.codigo != 0 &&
        this.FacturaProveedor.facturasProveedoresPK.codigo != null
      ) {
        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          var linkverificar=`/${this.FacturaProveedor.facturasProveedoresPK.codigo}/${this.FacturaProveedor.facturasProveedoresPK.documento}/1`
          this.$axios.get(
          this.$hostname + this.$hName +"/pagoproveedores/VerificarMovimientoPago"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee pago", "error")
          }else{ 
            this.VerificarMovimNotDebito(linkverificar)

          }

        })
        .catch((error) => this.alerta(error, "error"));


       
          


        }
      }
    },

    VerificarMovimNotDebito(Links){
      this.$axios.get(
          this.$hostname + this.$hName +"/notadebitoproveedores/VerificarMovimientoNotaDebito"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee Nota de debito", "error")
          }else{ 
            this.VerificarMovimCheque(Links)
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },


    VerificarMovimCheque(Links){
      this.$axios.get(
          this.$hostname + this.$hName +"/cheques/VerificarMovimientoCheque"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee Cheque", "error")
          }else{ 
            this.ContAnular()
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },




    ContAnular(){
      this.$axios
            .post(
              this.$hostname + this.$hName + "/facturasproveedor/anular",
              this.FacturaProveedor,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.alerta("La factura fue anulada correctamente !!!", "bien");
            })
            .catch((error) =>{
              let msg = "";
              if (error.response) {
              msg = error.response.data
             } else if (error.request) {
               msg = error.request.data
               } else {
                msg = error.message;
               }
            this.alerta("Dato no anulado"+": "+msg, "error")

            }
            
             // EventBus.$emit("loading", false)
            );
    },

    imprimirList() {
      if (this.FacturaProveedor.facturasProveedoresPK.codigo > 0) {
        this.datosImprecion.codigo =
          this.FacturaProveedor.facturasProveedoresPK.codigo;
        this.datosImprecion.documento =
          this.FacturaProveedor.facturasProveedoresPK.documento;
        this.datosImprecion.orden = this.FacturaProveedor.ordencompra;
        this.dialogimprimirList = true;
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error");
      }
    },

    ImprimirEtiqueta() {
      this.ImprimirItemEtiquetaFActura(this.FacturaProveedor);
    },


    async ImprimirItemEtiquetaFActura(e) {
      EventBus.$emit("loading", true);
  
      var OBJDATA={
        config :this.$store.getters.GetConfiguracionGeneral,
        suplidor : e.proveedor,
        factura : e.referencia,
        formatoBarra : this.ImprirEtiqueta.ImpesoraBarra,
        Impresora : this.ImprirEtiqueta.selectImpresora,
        configInvUsarLote: this.$store.getters.GetConfiguracionGeneral.configInv.usarLote,
        lista:e.facturasProveedoresDetalleList.filter((ee) => ee.productos.imprimirEtiqueta==true)
     
      }
     

      this.$axios.post(this.$hostname + this.$hName + "/facturasproveedor/objectoEtiquetaLista",
              OBJDATA,{ headers: this.$store.getters.GetheadersAxios })
            .then((res) => {
             this.contImprimiendo(res.data)
            })
            .catch((error) => {
              EventBus.$emit("loading", false);
             /* this.loading(false)*/
              this.alerta("Dato no Impreso", "error")
            }
            );


    },

    async ImprimirItemEtiquetaFActura2(e) {
      if (e != null) {

  var LISTA_COMPR=[]

        await Promise.all(e.facturasProveedoresDetalleList.map(async (element) => {
          if (element.productos.imprimirEtiqueta) {
            this.$axios
              .get(
                this.$hostname + this.$hName + "/unidades-productos/find/" + element.productos.codigo + "/" + element.unidades.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                var UNI_MINI=null
                var bar = new Promise((resolve, reject) => {
                if(res.data.unidadminima==false){
                this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/findUnidadMinimaCompra/"+element.productos.codigo+"/true" ,
                { headers: this.$store.getters.GetheadersAxios })
              .then((ress) => {
                UNI_MINI=ress.data
                resolve()
              });

                }else{
                  UNI_MINI=res.data
                  resolve()
                }                     
                  });

 bar.then(() => {
  console.log('All done!');
  if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {

this.$axios
  .get(
    this.$hostname + this.$hName + "/unidades-productos/getLote/" + res.data.fechavencimiento,
    { headers: this.$store.getters.GetheadersAxios }
  )
  .then((lote) => {
    var resultado = {};
    resultado.config = this.$store.getters.GetConfiguracionGeneral;
    resultado.suplidor = e.proveedor;
    resultado.cantidad = element.cantidad;
    resultado.producto = res.data;
    resultado.producto.lote = lote.data;
    resultado.factura = e.referencia;
    resultado.unidad = element.unidades.descripcion;
    resultado.formatoBarra = this.ImprirEtiqueta.ImpesoraBarra;
    resultado.Impresora = this.ImprirEtiqueta.selectImpresora;
    resultado.productoOpuesto = UNI_MINI;
    resultado.orden=element.orden
    //this.imprimir(resultado, "etiqueta");
    LISTA_COMPR.push(resultado)
  });
} else {
var resultado = {};
resultado.config = this.$store.getters.GetConfiguracionGeneral;
resultado.suplidor = e.proveedor;
resultado.cantidad = element.cantidad;
resultado.producto = res.data;
resultado.factura = e.referencia;
resultado.unidad = element.unidades.descripcion;
resultado.formatoBarra = this.ImprirEtiqueta.ImpesoraBarra;
resultado.Impresora = this.ImprirEtiqueta.selectImpresora;
resultado.productoOpuesto = UNI_MINI;
resultado.orden=element.orden
if(element.fechavencimiento!=null){
  resultado.fecha=this.getParamFecha(element.fechavencimiento)
}
//this.imprimir(resultado, "etiqueta");
LISTA_COMPR.push(resultado)
}

 });

              });
          }

              
        }));


 var _this=this;
 setTimeout(function(){ 
  LISTA_COMPR.sort(function (a, b) {
            if (a.orden < b.orden) { return -1; }
            if (a.orden > b.orden) {return 1;}
            // a must be equal to b
            return 0;
          }); 
  _this.contImprimiendo(LISTA_COMPR)
 }, 1000);

      }


    },


async contImprimiendo(LISTA_COMPR){
//this.imprimir(resultado, "etiqueta");
// console.log('listaProducto-->',LISTA_COMPR)
 
//etiquetaList
var ListaEtiqueta={
  ListaEtiqueta:LISTA_COMPR
}
this.imprimir(ListaEtiqueta, "etiquetaList")

/*await Promise.all(LISTA_COMPR.map(async (element) => {
  var _this=this;
 setTimeout(function(){ 
  //etiqueta
                _this.imprimir(element, "etiqueta");
   }, 1000);

          }));*/


},


    imprimir(datos, operacion) {
      EventBus.$emit("loading", true);
      datos.operacion = operacion;
      this.$axios
        .post("http://localhost:15999/impresora/imprimir", datos)
        .then((res) => {
          EventBus.$emit("loading", false);
          //this.limpiarPuntoVentaCompleto();
        })
        .catch((error) => {
          EventBus.$emit("loading", false);
          console.error(error)
        });

    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true;
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);
    },

    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where =
        "facturas_proveedores.proveedor = proveedores.codigo AND facturas_proveedores.documento = documentoproveedor.codigo";
      this.datosImprecionAll.documento = "";
      if (this.datosImprecionAll.FecRegi == true) {
        where += ` and date_trunc('day',facturas_proveedores.fecha_creacion)>= ' ${new Date(
          this.datosImprecionAll.desde
        )
          .toISOString()
          .substr(
            0,
            10
          )}' and date_trunc('day',facturas_proveedores.fecha_creacion) <= ' ${new Date(
            this.datosImprecionAll.hasta
          )
            .toISOString()
            .substr(0, 10)} ' `;
      } else {
        where += ` and facturas_proveedores.fecha>= ' ${new Date(
          this.datosImprecionAll.desde
        )
          .toISOString()
          .substr(0, 10)}' and facturas_proveedores.fecha <= ' ${new Date(
            this.datosImprecionAll.hasta
          )
            .toISOString()
            .substr(0, 10)} ' `;
      }

      if (this.datosImprecionAll.documentos != null) {
        where += ` and facturas_proveedores.documento= ${this.datosImprecionAll.documentos.codigo}`;
        this.datosImprecionAll.documento =
          this.datosImprecionAll.documentos.descripcion;
      }
      this.datosImprecionAll.where = where;
    },

    calcularImpuesto() {
      this.$refs.detalle.setImpuestoIncluido(
        this.FacturaProveedor.itbisincluido
      );
    },
    calcularDescuentoIndividual() {
      this.$refs.detalle.setDescuentoIndividual(
        this.FacturaProveedor.descuentoIdividual
      );
    },

    newwnuevo() {
      
      this.findAlmacenPrincipal()

      this.contabilidad = null
        this.RLisCuenta = []
      this.tipogasto = null
      this.ListProd = []
      this.$refs.detalle.DisableCosto = false
      this.BarraTablaDinamicaAprir = false;
      this.FacturaTemporal = null;
      this.RestaurarTempList = []
      this.objRestaurarTemp=null
      this.FacturaProveedor = {
        facturasProveedoresPK: { codigo: 0, documento: 0 },
        empresa: null,
        fecha: fechaNueva(),
        referencia: "",
        ordencompra: null,
        diascredito: 0,
        tipogasto: 0,
        tiposuplidor: null,
        ncf: "",
        totalimpuesto: 0,
        totaldescuento: 0,
        total: 0.0,
        comentario: null,
        tasa: 0,
        pendiente: 0.0,
        activada: true,
        fechaCreacion: fechaNueva(),
        fechaModificacion: null,
        usuarioCreacion: "",
        usuarioModificacion: null,
        selectivo: 0.0,
        facturaGastos: false,
        itbisincluido: false,
        pagosuspendido: false,
        comentarioSuspencion: "",
        descuentoIdividual: 0,
        borrado: false,
        detalleJson: "",
        documentoproveedor: "",
        moneda: null,
        proveedor: null,
        facturasProveedoresDetalleList: [],
        totaldescuento:0,
        totalimpuesto:0,
        listFoto:[]
      };
      this.datosNCF = {
        codigoFijo: "",
        secuencia: "",
      };
      this.TotalProd = {
        cantidad: 0,
        cantExit: 0,
        costo: 0,
        impuesto: 0,
        descuento: 0,
        importe: 0,
      };


      this.newImagen()
      this.listObjFoto=[]
      this.ejecucion = true;
      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");

      var _this = this;
      setTimeout(function () {
        _this.ListProd = []
        _this.$refs.detalle.totalesNuevo()
      }, 300);

      this.loadInfo()

    },
    async datostabla(e) {
      
    this.getCargerImagen(e.facturasProveedoresPK.codigo,e.facturasProveedoresPK.documento)
      //this.ejecucion = true;
      /* if(this.FacturaProveedor.facturasProveedoresPK.codigo==e.facturasProveedoresPK.codigo){
         return
       }*/
      // this.FacturaProveedor = JSON.parse(JSON.stringify(e));
      this.contabilidad = null
      this.RLisCuenta = []
      this.ListProd = [];
      this.datosNCF.codigoFijo = ""
      this.datosNCF.secuencia = ""

      if (e.ncf != null) {
        if (e.ncf.trim().length == 11) {
          this.datosNCF.codigoFijo = e.ncf.trim().substr(0, 3);
          this.datosNCF.secuencia = e.ncf.trim().substr(3);
        }

        if (e.ncf.trim().length == 13) {
          this.datosNCF.codigoFijo = e.ncf.trim().substr(0, 5);
          this.datosNCF.secuencia = e.ncf.trim().substr(5);
        }
        if (e.ncf.trim().length >= 19) {
          this.datosNCF.codigoFijo = e.ncf.trim().substr(0, 11);
          this.datosNCF.secuencia = e.ncf.trim().substr(11);
        }
      }

      this.tipogasto = this.RTipoGasto.filter(t => t.codigo == e.tipogasto)[0]
      if (e.moneda == null) {
        this.loadInfo()
      }

      var obj=JSON.parse(JSON.stringify(e))
  
      if(!e.hasOwnProperty('descuentoIdividual')){obj.descuentoIdividual=0}
      if(e.hasOwnProperty('descuentoIdividual')&&e.descuentoIdividual==null){obj.descuentoIdividual=0}
      this.acti_tb_data = false;

      if(obj.facturasProveedoresDetalleList.length>0){

        obj.facturasProveedoresDetalleList.sort(function (a, b) {
            if (a.orden < b.orden) { return -1; }
            if (a.orden > b.orden) {return 1;}
            // a must be equal to b
            return 0;
          });


        if (obj.facturasProveedoresDetalleList[0].hasOwnProperty('almacen')) {
          this.almacen=JSON.parse(JSON.stringify(obj.facturasProveedoresDetalleList[0].almacen))
        }
        if (obj.facturasProveedoresDetalleList[0].hasOwnProperty('almacenes')) {
          this.almacen=JSON.parse(JSON.stringify(obj.facturasProveedoresDetalleList[0].almacenes))
        }

      }

      


      /*obj.facturasProveedoresDetalleList.forEach(element => {
        element.ofertaSelect=element.facturasProveedoresDetallePK.oferta  
      });*/
      await Promise.all(obj.facturasProveedoresDetalleList.map(async (element) => {
        element.ofertaSelect=element.facturasProveedoresDetallePK.oferta  
          }));



      var _this = this;
      setTimeout(function () {
        _this.ListProd = [];
        _this.$refs.detalle.totalesNuevo()
      }, 200);
      setTimeout(function () {
        _this.llenarObjeto(obj);
        
      }, 300);

      
    },
    async llenarObjeto(e) {
      this.FacturaProveedor = JSON.parse(JSON.stringify(e));
      this.FacturaProveedor.fecha = new Date(e.fecha).toISOString().substr(0, 10);

      if(e.facturasProveedoresDetalleList.length>0){

      e.facturasProveedoresDetalleList.forEach((el) => {
        var det = {
          facturasProveedoresDetallePK: {
            codigo: el.codigo,
            documento: el.documento,
            unidad: el.unidad,
            producto: el.producto,
            oferta: el.oferta,
          },
          ofertaSelect:el.ofertaSelect,
         // ofertaSelect:JSON.parse(JSON.stringify(el.oferta)),
          // fechavencimiento: el.fechavencimiento != null ? new Date(el.fechavencimiento).toISOString().substr(0, 10) : null,
            fechavencimiento:el.nuevaFechaVencimiento,
          //  fechavencimiento: el.fechavencimiento != null ? (new Date(el.fechavencimiento)).toJSON().split("T")[0] : null,
          cantidad: el.cantidad,
          precio: el.precio,
          descuento: el.descuento,
          impuesto: el.impuesto,
          valor: el.valor,
          costo: el.costo,
          precioFactura: el.precioFactura,
          impuestoIncluido: el.impuestoIncluido,
          descuentoPorciento: el.descuentoPorciento,
          borrado: false,
          almacen: el.almacen,
          producto: el.productos,
          unidad: el.unidades,
        };
        // this.$refs.detalle.addDetalleOrdenAFactura(det);
        this.$refs.detalle.addDetalledatostablaFacProveedor(det)
      });


      var _this = this;
      setTimeout(function () {
        _this.calcularDescuentoIndividual()
        EventBus.$emit("loading", false); 
       // _this.$refs.detalle.calcularTotales();
      }, 900);

    }

      this.$refs.detalle.DisableCosto = true
      return;
    },
    cargarProveedor() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/proveedores/findallbyDesc/25/0/a",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.Rproveedor = res.data));
    },

    SelctCbProveedorOrden() {
      if (this.OrdenSugerida.proveedor == null) {
        this.OrdenSugerida.proveedor = "";
      }
      if (this.OrdenSugerida.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.OrdenSugerida.proveedor = "";
        return;
      }
    },

    ProveedorOrdenCompraObtenerDatoSelect(e) {
      this.ProveedorOrdenCompraDatostabla(e.item);
    },
    async ProveedorOrdenCompraDatostabla(e) {
      var _this=this;
      var Limpiar = new Promise((resolve, reject) => {
        this.ListProd=[]
        this.ProveedorOrdenCompraSelected = [];
        this.FacturaProveedor.facturasProveedoresDetalleList=[]

        setTimeout(() => {
          EventBus.$emit("loading", true);
          resolve('resolved');
        }, 200);
      });


Limpiar.then(() => { 
   if(this.ejecucion==true){
    this.ListProd=[]
   this.ProveedorOrdenCompraSelected = [];
   this.FacturaProveedor.facturasProveedoresDetalleList=[]
   this.ProveedorOrdenCompraSelected.push(e);
    this.ejecucion=false
        var _this=this;
 setTimeout(function(){    
 _this.continProveedorOrdenCompraDatostabla(e)
}, 300);
}     
      });

    },

    async continProveedorOrdenCompraDatostabla(e){
  if (e != null) {
        this.FacturaProveedor.empresa = e.empresa;
        this.FacturaProveedor.fecha = new Date(e.fecha)
          .toISOString()
          .substr(0, 10);
        this.FacturaProveedor.ordencompra = e.codigo;
        this.FacturaProveedor.totalimpuesto = e.impuesto;
        this.FacturaProveedor.totaldescuento = 0;
        this.FacturaProveedor.total = e.valor;
        this.FacturaProveedor.comentario = e.comentario;
        this.FacturaProveedor.tasa = e.tasa;
        this.FacturaProveedor.moneda = e.moneda;
        var LisOrden= JSON.parse(JSON.stringify(e.ordencompraDetalleList))

        await Promise.all(
          LisOrden.sort(function (a, b) {
  if(a.descripcion > b.descripcion) {return 1;}
  if(a.descripcion < b.descripcion) {return -1;}
  return 0;})); 

        await Promise.all(
          LisOrden.map(async (el) => {
            var det = {
            facturasProveedoresDetallePK: {
              codigo: 0,
              documento: 0,
              unidad: 0,
              producto: 0,
              oferta: false,
            },
            fechavencimiento: null,
            cantidad: el.cantidad,
            precio: el.precio,
            descuento: el.descuento,
            impuesto: el.impuesto,
            valor: el.cantidad * el.precio,
            costo: el.precio,
            precioFactura: el.precio,
            impuestoIncluido: el.impuestoIncluido,
            descuentoPorciento: el.descuentoPorciento,
            borrado: false,
            almacen: el.almacen,
            productos: el.producto,
            unidades: el.unidad,
          };
          this.FacturaProveedor.facturasProveedoresDetalleList.push(det);
          this.$refs.detalle.addDetalleOrdenAFactura(det);
            })
            ); 

      /*  e.ordencompraDetalleList.forEach((el) => {
        });*/

      }
      this.dialogProveedorOrdenCompra = false;
      
      var _this = this;
      setTimeout(function () {
        EventBus.$emit("loading", false);
        _this.ejecucion=true
        _this.$refs.detalle.calcularTotales();
      }, 900);
      return;

},



    OpenProveedorOrdenCompra() {
      this.dialogProveedorOrdenCompra = true;
    },
    BuscaOrdenCompra(e) {
      this.cargarOrdenCompra();
    },
    SelctCbProveedor() {
      if (this.FacturaProveedor.proveedor == null) {
        this.FacturaProveedor.proveedor = "";
      }
      if (this.FacturaProveedor.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.FacturaProveedor.proveedor = "";
        return;
      }
      //this.datosNCF.codigoFijo = this.FacturaProveedor.proveedor.codigoFijo;

      if(this.FacturaProveedor.proveedor.hasOwnProperty('codigoFijo')&&this.FacturaProveedor.proveedor.codigoFijo!=null){
        this.datosNCF.codigoFijo = JSON.parse(JSON.stringify(this.FacturaProveedor.proveedor.codigoFijo.trim().toUpperCase()))
      }
      
      // this.FacturaProveedor.diascredito = this.FacturaProveedor.proveedor.diaCredito;
      this.tipogasto = this.FacturaProveedor.proveedor.gasto
      this.cargarOrdenCompra();
      if(this.FacturaProveedor.hasOwnProperty('diascredito')&&this.FacturaProveedor.diascredito!=null && this.FacturaProveedor.diascredito>0){
        this.FacturaProveedor.diascredito =   this.FacturaProveedor.proveedor.diaCredito;
      }else{
        this.FacturaProveedor.diascredito =30
      }


    },

    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" +
            (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.Rproveedor = res.data
          
          });
      }
    },

    SelctCbMoneda() {
      if (this.FacturaProveedor.moneda == null) {
        this.FacturaProveedor.moneda = "";
      }
      if (this.FacturaProveedor.moneda.codigo == undefined) {
        this.alerta("Seleccione una moneda", "error");
        this.FacturaProveedor.moneda = "";
        return;
      }
      this.cambiarTasa();
    },
    cambiarTasa() {
      this.FacturaProveedor.tasa = this.FacturaProveedor.moneda.tasa;
    },
    cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbProveedoresFiltro() {
      if (this.Modallfiltro.proveedores == null) {
        this.Modallfiltro.proveedores = "";
      }
      if (this.Modallfiltro.proveedores.codigo == undefined) {
        this.alerta("Seleccione un proveedores", "error");
        this.Modallfiltro.proveedores = "";
        return;
      }
    },
    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {
        this.Modallfiltro.documento = "";
      }
      if (this.Modallfiltro.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = "";
        return;
      }
    },

    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva();
      }
    },

    ReportBlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva();
      }
    },

    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = "";
      }

      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = "";
        return;
      }
    },

    DDataTotalList(e) {
      this.TotalProd = JSON.parse(JSON.stringify(e));
    },

    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro();
      }
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro();
    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        proveedores: null,
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null,
      };

      this.ListItems = [];
      this.TbTotalItem = 0;

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.factura != null &&
        this.Modallfiltro.factura.toString().length == 0
      ) {
        this.Modallfiltro.factura = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.factura);
    },

    CargarPaginacion(e, s) {
      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s));

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

    CargarListTb() {
      var prove = null;
      var doc = null;
      if (this.Modallfiltro.proveedores != null) {
        prove = this.Modallfiltro.proveedores.codigo;
      }
      if (this.Modallfiltro.documento != null) {
        doc = this.Modallfiltro.documento.codigo;
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        this.Modallfiltro.factura +
        "/" +
        prove +
        "/" +
        doc +
        "/" +
        this.Modallfiltro.Desde +
        "/" +
        this.Modallfiltro.Hasta;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/facturasproveedor/findListFactProveedorPlus/" +
          obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListItems = res.data.ListItems),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },




    BlurCambioMayuscula() {
      if (this.datosNCF.codigoFijo.length > 0) {
        this.datosNCF.codigoFijo = JSON.parse(JSON.stringify(this.datosNCF.codigoFijo.toUpperCase()))
      }
    },
    
    openDialogCargerImagen(){
      this.dialogCargerImagen=true
      this.GetloaderCargarFoto()
    },

getCargerImagen(codigofactura,documentofactura) {
  var LINK=`/facturasproveedor/findFacProFoto/${codigofactura}/${documentofactura}`
this.$axios.get(this.$hostname + this.$hName + LINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => {
    this.listObjFoto=res.data
  })
  .catch((error) => {
         this.newImagen()
          });

},

    cerrarCargarImagen(){
      

      if(this.objCargaImg.imgView!=null&& this.objCargaImg.imgView.trim().length>0 ){
        this.FacturaProveedor.imagenFactura=this.objCargaImg.imgView
      }
      this.dialogCargerImagen=false
      
    },

    eliminarCargaImg(item){

      const index = this.listObjFoto.indexOf(item);
      var eliminar= confirm("Desea eliminar esta imagen?") && this.listObjFoto.splice(index, 1);
      if(eliminar!=false){

      if(item.codigofactura>0 && item.documentofactura>0 && item.codigo>0){
        var obj = {
        cod: item.codigo,
        sec: item.codigofactura,
        doc: item.documentofactura,

          }

          this.$axios
        .post(
          this.$hostname +
          this.$hName + "/facturasproveedor/eliminarFacProFoto/", obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          if (res.data == true) {
            this.alerta("imagen eliminada corretamente", "bien2")
          } else {
            this.alerta("imagenn no eliminada", "error")
          }
         // this.newImagen()
          this.GetloaderCargarFoto() 
        })
        .catch(error =>{
          let msg = "";
              if (error.response) {
              msg = error.response.data
             } else if (error.request) {
               msg = error.request.data
               } else {
                msg = error.message;
               }
            this.alerta("Dato no anulado"+": "+msg, "error")
         } );

      }
     
      }

      
    },
    newImagen(){
    this.objCargaImg={
        codigo:0,
        codigofactura:0,
        documentofactura:0,
        cargaImg: null,
        imgView:'',
      }
    this.FacturaProveedor.imagenFactura=null

    //this.listObjFoto=[]

    },

    ConvertirImg(e) {

var _this = this;

// var filesSelected = document.getElementById("foto").files;
var filesSelected = e
if (filesSelected.length > 0) {
  var fileToLoad = filesSelected[0];

  var fileReader = new FileReader();

  fileReader.onload = function (fileLoadedEvent) {
    var srcData = fileLoadedEvent.target.result; // <--- data: base64

    var newImage = document.createElement("img");
    newImage.src = srcData;
    _this.objCargaImg.imgView = srcData;

    // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
    // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
    // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
  };
  fileReader.readAsDataURL(fileToLoad);
} else {

  this.objCargaImg.imgView = '';

}


     this.GetloaderCargarFoto() 

},

addArchivoList(){
  if (this.$refs.formInputFileImg.validate()) { 

  var newObj={
codigo: 0,
codigofactura: 0,
documentofactura: 0,
imagen: this.objCargaImg.imgView,
} 

this.listObjFoto.push(newObj);
this.newImagen()

  }else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }





},




// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccModificarRegFacPro') {
        this.EjecutarPermisoPv = true
        var _this = this;
        var OBJDATA=JSON.parse(JSON.stringify(this.ObjEjecPermisoPv)) 
        this.ObjEjecPermisoPv=null
        setTimeout(function () {
          _this.saveCont();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    } ,

    GetloaderCargarFoto(){
      var _this = this;

      _this.loadercargarFoto = false
      setTimeout(function () {
          _this.loadercargarFoto = true
        }, 1000);

    },

    verificarYearfecha(){
        var _this = this;
        setTimeout(function () {
        //  if(e!=null && e.length>10){e='2024-12-10' }
        if(_this.FacturaProveedor.fecha!=null && _this.FacturaProveedor.fecha.toString().length>10){
         var FCSPLIT=_this.FacturaProveedor.fecha.split("-")
         //var YEAR=FCSPLIT[0].substr(0,4)
         _this.FacturaProveedor.fecha=`${FCSPLIT[0].substr(0,4)}-${FCSPLIT[1]}-${FCSPLIT[2]}`
        }
        }, 500);

    },
    ImprimirRestaurarFact(object){
var OBJ={
  codigo: object.facturasProveedorestempPK.codigo,
  documento: object.facturasProveedorestempPK.documento
}
    /*object.facturasProveedorestempPK.codigo
    object.facturasProveedorestempPK.documento*/
  
      EventBus.$emit("loading", true);
      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/facturasproveedor/imprimirFactProTem",
          OBJ,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          EventBus.$emit("loading", false);
          setTimeout(function () {
            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
            EventBus.$emit("openPDF",PDF ,'Facturas de Proveedores Temporal');
          }, 1000);
        })
        .catch((error) => {
          EventBus.$emit("loading", false);
          console.error(error);
        });


    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

    async filtro() { },

    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {
    ActDescBtn() {
      if (this.FacturaProveedor.facturasProveedoresPK.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          reimprimirEtiqueta: true
        };
        if (!this.FacturaProveedor.activada) {
          this.EstadoBtn.vercuenta = false;
          this.EstadoBtn.anular = false;
          this.EstadoBtn.guardar = false;
          this.EstadoBtn.reimprimirEtiqueta = false;
        }
        this.InputDisabled = true

      }

      if (this.FacturaProveedor.facturasProveedoresPK.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: true,
          restaurartemporal: true,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          reimprimirEtiqueta: false
        };
        this.InputDisabled = false

      }

      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
    },
  },
};
</script>

<style>

</style>
