<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
           :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase
            :titulo="'Reporte Equipo de Alquiler'"
            :descripcion="'Imprime Lista de Productos de Alquiler'"
            :EstadoBtn="EstadoBtn"
            @abrirBarraTablaDinamica="abrirTabla"
            @MethodNuevo="newwnuevo"
            @MethodGuardar="save"
            @MethodEliminar="remove"
            @MethodAnular="anular"
            @MethodImprimir="imprimirList"
        />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                        small
                        color="yellow accent-4"
                        @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn
                    >
                    <v-btn
                        small
                        dark
                        color="deep-purple accent-2"
                        @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn
                    >
                  </v-btn-toggle>
                </center>
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
<!-- Formulario-Equipo de Alquiler -->
            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar
                    flat
                    color="#BDBDBD"
                    dense
                    style="border: 1px solid #000000;"
                >


                  <v-avatar tile size="45">
                    <img
                        src="@/assets/iconos_micromarket/custom-reports.png"
                        alt="Reporte"
                    >
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;"> Productos de Alquiler </span>
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="12" sm="12">


                        <!----------------------------------------------  Departamento -->
                        <v-combobox

                            autocomplete="off"
                            dense
                            outlined
                            label="Departamento:"
                            :items="Rdepartamento"
                            item-text="descripcion"
                            :rules="[$rules.required]"
                            v-model="EquipoAlquiler.departamento"
                            @focus="cargarDepartamento"
                            @blur="SelctCbDesc"
                        >
                          <template v-slot:prepend>
                            <i
                                style="font-size: 30px;"
                                class="fa fa-building"
                            ></i>
                          </template>
                        </v-combobox>


                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  Hasta  -->
                        <v-switch
                            class="mt-0"
                            color="teal"
                            inset
                            hide-details
                            label="Vencimiento:"
                            v-model="EquipoAlquiler.Vencimiento"
                        >
                          <template v-slot:label>
                            <strong v-if="EquipoAlquiler.Vencimiento==true" style="color:#000000;">Vencimiento:
                              Si</strong>
                            <strong v-if="EquipoAlquiler.Vencimiento==false" style="color:#000000;">Vencimiento:
                              No</strong>
                          </template>
                        </v-switch>
                      </v-col>
                      <!-- fn columna 2 -->

                      <!-- columna 3 -->

                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  Hasta  -->
                        <v-switch
                            class="mt-0"
                            color="teal"
                            inset
                            hide-details
                            label="En Alquiler:"
                            v-model="EquipoAlquiler.Alquiler"
                        >
                          <template v-slot:label>
                            <strong v-if="EquipoAlquiler.Alquiler==true" style="color:#000000;">En Alquiler: Si</strong>
                            <strong v-if="EquipoAlquiler.Alquiler==false" style="color:#000000;">En Alquiler:
                              No</strong>
                          </template>
                        </v-switch>
                      </v-col>
                      <!-- fn columna 3 -->
                    </v-row>


                  </v-form>
                </v-container>

                <hr>
                <ModalReporteFiltros
                    @DataReporteBasico="DDataReporteBasico"
                    :Datos="EquipoAlquiler"
                />
              </v-card>
            </v-container>
            <!-- fn Formulario-Equipo de Alquiler -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
        label="myAlert"
        v-model="acti_tb_data"
        persistent
        max-width="100%"
        max-height="100%"
        transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
              color="grey darken-4"
              fab
              small
              @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                  v-model="search"
                  @keyup="filtro"
                  label="Buscar"
                  single-line
                  hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
                :headers="slotHtabla"
                dense
                :items="list"
                :search="search"
                @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
        label="myAlert"
        v-model="Elim_notif.estado"
        persistent
        max-width="350"
        transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr/>

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr/>
        <v-card-actions>
          <v-btn
              @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
              small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
        v-model="aalert.estado"
        :color="aalert.color"
        style="margin-top: 20%;"
        :vertical="true"
        :timeout="120000"
        top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import {EventBus} from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {

    var json = {
      titulo: "Reporte Equipo de Alquiler",
      descripcion: "Imprime Lista de Productos de Alquiler",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ReportesEquipoAlquilerNew",
        "ReportesEquipoAlquilerSave",
        "ReportesEquipoAlquilerRemove",
        "ReportesEquipoAlquilerAnular",
        "ReportesEquipoAlquilerPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesEquipoAlquilerNew", this.new);
    // EventBus.$on("ReportesEquipoAlquilerSave", this.save);
    // EventBus.$on("ReportesEquipoAlquilerRemove", this.remove);
    // EventBus.$on("ReportesEquipoAlquilerAnular", this.remove);
    // EventBus.$on("ReportesEquipoAlquilerPrintList", this.imprimirList);
    EventBus.$on("emitReportesEquipoAlquiler", this.datostabla);
    this.cargarTodo();
    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({
    Rpropietario: [],
    RCaja: [],
    Rdepartamento: [],
    ListaTiposClientes: [],
    windowSize: {width: "auto", height: "auto"},
    EquipoAlquiler: {
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      Alquiler: true,
      Vencimiento: false,
      departamento: null,
      nombre: 'REPORTE ALQUILER EQUIPO',
      UrlImprimir: '/reportes-pv/ImprimirListEquiposAlquiler',
      UrlExel: '/reportes-pv/ExcelListEquiposAlquiler',
      UrlCorreo: '/reportes-pv/CorreoListEquiposAlquiler'
    },
    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{text: "Tipo Clientes", value: "descripcion"}],

    slotlist: [
      {dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:"},
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      {dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo"},
    ],

    slotHtabla: [
      {text: "Código", value: "codigo"},
      {text: "Descripcion", value: "descripcion"}
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },
  }),
  methods: {

    onResize(e) {
      this.windowSize = e
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
    },
    datostabla(e) {
      this.EquipoAlquiler = e;
      this.acti_tb_data = false
    },
    save() {
    },
    remove() {
    },
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
      (this.EquipoAlquiler = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
          EventBus.$emit("actualizaBarraBusqueda2");
    },


    // Llamar a la API y obtener los datos de la API y almacenarlos en la variable Rdepartamento.
    cargarDepartamento() {
      this.$axios.get(this.$hostname + this.$hName + "/departamento/findall").then(res => (this.Rdepartamento = res.data));
    },


  // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.EquipoAlquiler.departamento == null) {
        this.EquipoAlquiler.departamento = "";
      }

      if (this.EquipoAlquiler.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.EquipoAlquiler.departamento = "";
        return;
      }
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    },
    //retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico() {}
  }
};
</script>

<style></style>
