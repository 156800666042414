<template>
 <div> 
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Bancos'"
  :descripcion="'Bancos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">
         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver">
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/tipocliente/findalltipoclientes'"
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBancos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"/> -->
          </template>
        </v-tooltip>
      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha"
         :rules="[$rules.required]"
        v-model="Bancos.fecha"
        hide-details
        @blur="BlurFecha"
        id="formdisabledInput"
        disabled 
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>
</v-col>
<!-- fn columna 1 -->         
<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
<v-data-table
      :headers="[
                { text: 'Ver', sortable: false,  value: 'action', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'Cuenta', sortable: false, value: 'cuenta', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'Banco', sortable: false, value: 'banco.nombre', width:'auto', class: ['no-gutters black--text'] },
                 { text: '$ Balance', sortable: false, value: 'balance', width:'auto',align:'center', class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listBanco"
              :search="search"      
              :items-per-page="15"
              class="elevation-1 TbSize13 RowSelectColor"
            >

<template v-slot:item.balance="props"> 
  <span style="display:none;">{{props.item.indx = listBanco.indexOf(props.item)}}</span>
  <span >{{currencyFormatter(props.item.balance)}}</span>
</template>

 <template v-slot:item.action="{ item }"> 
    <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->
<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn v-on="on" fab @click.prevent="VerMovimientoBanco(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn> 
 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-eye"> 
          <span> Ver registro.</span>
</i>   
</v-tooltip>
<!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
 </template> 
<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" >
  <strong v-if="totales.balance>=0" class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.balance)}} </strong>
  <strong v-if="totales.balance<0" class="d-flex justify-center" style="font-size:15px; color: #00838F;"> ( {{(currencyFormatter(totales.balance))*(-1)}} ) </strong>

</td>
</tr>
 </template>
 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span v-if="totales.balance>=0">Total Balance:{{currencyFormatter(totales.balance)}}</span>
        <span v-if="totales.balance<0" >Total Balance:( {{(currencyFormatter(totales.balance))*(-1)}} )</span>

      </v-list-item-content>
</v-list-item>
  </template>
</v-data-table>
 </div>
</v-col>
              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >
 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">       
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>
      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>
            <v-data-table
              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' }
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>
      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />
        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>
        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="eliminar(), (Elim_notif.estado = !Elim_notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


<!-------------------------------------------------------- Modal- movimiento banco -->
<v-dialog  label="Movimientos Banco" v-model="dialogMovimientosBanco" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
<v-card>
<v-toolbar
        dense
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Movimientos Banco </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogMovimientosBanco=false, MovimientosBanco=null"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-col v-if="MovimientosBanco!=null"  class="pa-0 ma-0 py-1 my-0 px-2"   cols="12" md="12" sm="12" >
  <v-row>

<v-col  cols="12" md="4" sm="4" class="pa-0 ma-0 px-2 py-1" > 
  <v-text-field
        id="formdisabledInput"
        disabled
        dense
        outlined
        type="date"  
        label="FECHA:"
        v-model="Bancos.fecha"
        hide-details>
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>
</v-col>

<v-col  cols="12" md="4" sm="4" class="pa-0 ma-0 px-2 py-1" > 
  <v-text-field 
                 id="formdisabledInput"
                   disabled
                    label="CUENTA:"
                    autocomplete="off"
                    dense
                    outlined
                    :value="MovimientosBanco.cuenta"
                    hide-details
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                    </template>
                  </v-text-field>
</v-col>

<v-col  cols="12" md="4" sm="4" class="pa-0 ma-0 px-2 py-1" > 
<v-text-field 
                 id="formdisabledInput"
                   disabled
                    label="BANCO :"
                    autocomplete="off"
                    dense
                    outlined
                    :value="MovimientosBanco.banco.nombre"
                    hide-details
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-university"></i>
                    </template>
                  </v-text-field>
</v-col>

<v-col  cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 py-1" > 
<!-- -----------------------------  desde  -->
 <!-- v-on:blur="BuscarMovimiento"  @keyup.enter="BuscarMovimiento" -->
<v-text-field
        dense
        outlined
        type="date"  
        label="DESDE:"
         :rules="[$rules.required]"
        v-model="MovimientosBanco.desde"
        hide-details
        @blur="BlurDesde"
        v-on:blur="BuscarMovimiento"
       
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>     
<v-col  cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 py-1">            
<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="HASTA:"
        v-model="MovimientosBanco.hasta"
        hide-details
        @blur="BlurHasta"
        v-on:blur="BuscarMovimiento"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
    </v-col> 

<v-col  cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 py-1">  
  <!-- -----------------------------  Balance --> 
                    <VueAutonumeric 
                    id="formdisabledInput"
                    disabled
                      autocomplete="off"
                      label="Balance Inicial:"
                      dense
                      outlined
                      hide-details
                      v-model.number="MovimientosBanco.BalanceInicial"
                     
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>
</v-col> 
 </v-row>


<v-data-table
      :headers="[
             //   { text: 'Ver', sortable: false,  value: 'action', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'FECHA', sortable: false, value: 'Fecha', width:120, class: ['no-gutters black--text'] },
                 { text: 'DOCUMENTO', sortable: false, value: 'Documento', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'BENEFICIARIO', sortable: false, value: 'Beneficiario', width:'auto',align:'center', class: ['no-gutters black--text'] },
                 { text: 'DEPOSITO(Debito)', sortable: false, value: 'Deposito', width:'auto',align:'center', class: ['no-gutters black--text'] },
                 { text: 'CHEQUE(Credito)', sortable: false, value: 'Cheque', width:'auto',align:'center', class: ['no-gutters black--text'] },
                 { text: 'BALANCE', sortable: false, value: 'Balance', width:'auto',align:'center', class: ['no-gutters black--text'] },
                 { text: 'CONCEPTO', sortable: false, value: 'Concepto', width:280,align:'center', class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="ListMovimiento"
              :search="search"      
              :items-per-page="15"
              class="elevation-1 TbSize13"
            >
<!-- MovimientosBanco.ListMovimiento -->
<template v-slot:item.Balance="props"> 
  <!-- <span style="display:none;">{{props.item.indx = listBanco.indexOf(props.item)}}</span> -->
  <span v-if="props.item.Balance<0" > ( {{currencyFormatter(props.item.Balance*(-1))}} ) </span>
  <span v-if="props.item.Balance>=0" >{{currencyFormatter(props.item.Balance)}}</span>
</template>

 <template v-slot:item.Fecha="props">
    <span>{{FormatoFecha(props.item.Fecha) }}</span>
</template>

<template v-slot:item.Deposito="props"> 
  <span >{{currencyFormatter(props.item.Deposito)}}</span>
</template>

<template v-slot:item.Cheque="props"> 
  <span >{{currencyFormatter(props.item.Cheque)}}</span>
</template>

<template v-slot:body.append v-if="windowSize.width >600">
<tr >  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:13px; color: #00838F;"> {{currencyFormatter(TotalMovi.deposito)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:13px; color: #00838F;"> {{currencyFormatter(TotalMovi.cheque)}} </strong></td>
<td class="border-total" >
  <strong v-if="balanceAcomulado<0" class="d-flex justify-center" style="font-size:13px; color: #00838F;"> ( {{currencyFormatter(balanceAcomulado*(-1))}} ) </strong>
  <strong v-if="balanceAcomulado>=0"  class="d-flex justify-center" style="font-size:13px; color: #00838F;"> {{currencyFormatter(balanceAcomulado)}} </strong>

</td>
<td class="border-top-bottom"  ><strong></strong></td> 
</tr>
 </template>
 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span >Total deposito:{{currencyFormatter(totales.balance)}}</span>
        <span  >Total cheque: {{currencyFormatter(TotalMovi.cheque)}} )</span>
        <span v-if="balanceAcomulado <0">Total Balance: ( {{(currencyFormatter(balanceAcomulado))*(-1)}} )</span>
        <span v-if="balanceAcomulado>=0"  >Total Balance: {{currencyFormatter(balanceAcomulado)}} </span>
      </v-list-item-content>
</v-list-item>
  </template>
</v-data-table>

</v-col>

</v-card>
</v-dialog>
<!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
import VueAutonumeric from "../components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    VueAutonumeric
  },
  created() {},
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BancosNew", this.new);
    // EventBus.$on("BancosSave", this.save);
    // EventBus.$on("BancosRemove", this.remove);
    // EventBus.$on("BancosAnular", this.remove);
    // EventBus.$on("BancosPrintList", this.imprimirList);
    EventBus.$on("emitBancos", this.datostabla);
    EventBus.$on("onResize", this.onResize);

    this.BuscarLita()
  },
  data:()=>  ({
      Rpropietario:[],
      RSucursal:[],
      RCaja:[],
      listBanco:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      Bancos: {
        fecha: new Date().toISOString().substr(0,10),
      },

dialogMovimientosBanco:false,
MovimientosBanco:null,
ListMovimiento:[],
balanceAcomulado:0,
totales:{
  balance:0
},

TotalMovi:{
deposito:0,
cheque:0,
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      headers: [{ text: "Tipo Clientes", value: "descripcion" }],
     
 
      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    }),
  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
   
    datostabla(e) {
      this.Bancos = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() {},
    newwnuevo() {
      (this.Bancos = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


calculartotal(){

this.totales={
  balance:0
}

this.listBanco.forEach(ele => {
  if(ele.balance>0){this.totales.balance+= ele.balance }
  if(ele.balance<0){this.totales.balance= this.totales.balance-ele.balance}
});

},




BuscarLita(){

this.$axios.get(this.$hostname+this.$hName+`/cuentabanco/findallactivo`,{headers:this.$store.getters.GetheadersAxios}
  ).then(res=>( 
    this.listBanco=res.data,
    setTimeout(this.calculartotal,200)
  ))


},

BlurFecha(){
if(this.Bancos.fecha.toString().length==0){
  this.Bancos.fecha=new Date().toISOString().substr(0,10)
}

 },


BlurDesde(){
if(this.MovimientosBanco.desde.toString().length==0){
  this.MovimientosBanco.desde=new Date().toISOString().substr(0,10)
}
 },


 BlurHasta(){
if(this.MovimientosBanco.hasta.toString().length==0){
  this.MovimientosBanco.hasta=new Date().toISOString().substr(0,10)
}
 },


VerMovimientoBanco(e){

var obj = JSON.parse(JSON.stringify(e))

obj.desde=new Date().toISOString().substr(0,10)
obj.hasta=new Date().toISOString().substr(0,10)

this.MovimientosBanco=obj   
this.MovimientosBanco.ListMovimiento=[]
this.MovimientosBanco.BalanceInicial=0
this.dialogMovimientosBanco=true


this.BuscarMovimiento()

},


BuscarMovimiento(){

//var bancDetCuent = ` select * from (select fecha, secuencia, db.descripcion, beneficiario,valor  deposito,0  credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.cheques ch on ch.cuenta_banco = cb.codigo inner join ${schema}.documentobancos db on db.codigo = ch.documento and db.operacion!=34 where anulado = false union select fecha, secuencia, db.descripcion, '' beneficiario,0 deposito, valor credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.notadebitobancos ndb on ndb.cuenta = cb.codigo inner join ${schema}.documentobancos db on db.codigo = ndb.documento union select fecha, secuencia, db.descripcion, '' beneficiario,valor deposito, 0 credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.notacreditobanco ncb on ncb.cuenta = cb.codigo inner join ${schema}.documentobancos db on db.codigo = ncb.documento union select fecha, secuencia, db.descripcion, '' beneficiario,0 deposito,valor credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.depositosbanco dpb on dpb.cuenta = cb.codigo inner join ${schema}.documentobancos db on db.codigo = dpb.documento and db.operacion!=33) cb where fecha >= '  ${f1} ' and fecha <= '  ${f2}' and cuenta = ${this.MovimientosBanco.codigo} `

  var schema =this.$store.getters.GetheadersAxios["X-TENANT-ID"]
 var f1=new Date(this.MovimientosBanco.desde).toISOString().substr(0,10)
  var f2=new Date(this.MovimientosBanco.hasta).toISOString().substr(0,10)
  var bancDetCuent =`select * from ${schema}.bancoDetalleCuenta( ' ${f1} ',' ${f2} ', ${this.MovimientosBanco.codigo} )`
  var balccuen = ` select ${schema}.balance_almomento_banco( ' ${f1} ', ${this.MovimientosBanco.codigo} )`

//.replace(/[&\/\\#+$~%":?<>{}]/g, '')
var Obj={
  bancDetCuent:bancDetCuent,
  balccuen:balccuen,
  // data:this.MovimientosBanco
}

// console.log('Viendo Obj')
// console.log(Obj)

this.$axios
        .post(this.$hostname + this.$hName +'/ConsultaRapida/MovimientosBanco',Obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.MovimientosBanco.BalanceInicial=res.data.Balance
          // this.MovimientosBanco.BalanceInicial=res.data.Balance
          // this.MovimientosBanco.ListMovimiento=res.data.Lista
          // var obj=JSON.parse(JSON.stringify(this.MovimientosBanco))
          // this.MovimientosBanco=null
          // this.MovimientosBanco=obj
      
      this.getMoviObj(res.data)
        
        
        })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});
       
},


getMoviObj(e){


this.TotalMovi={
deposito:0,
cheque:0,
}

this.balanceAcomulado=0
this.ListMovimiento=[]
var list=[]

e.Lista.forEach(elmet => {

var obj={
Fecha:elmet[0],
Documento:elmet[2]+"-"+elmet[1],
Beneficiario:elmet[3],
Deposito:elmet[5],
Cheque:elmet[4],
Balance:this.balanceAcomulado+=(elmet[5]-elmet[4]),
Concepto:elmet[7]
}


this.TotalMovi.deposito+= elmet[5],
this.TotalMovi.cheque+=elmet[4],




list.push(obj)
  
});


this.ListMovimiento=list




},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },
    async filtro() { },

  }
};
</script>
<style></style>
