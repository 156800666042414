<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
           :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase
            :titulo="'REPORTE COMPARATIVO DE ORDENES PENDIENTES  VS ÓRDENES FINALIZADAS '"
            :descripcion="'IMPRIME REPORTE COMPARATIVO DE ORDENES PENDIENTES  VS ÓRDENES FINALIZADAS '"
            :EstadoBtn="EstadoBtn"
            @abrirBarraTablaDinamica="abrirTabla"
            @MethodNuevo="newwnuevo"
            @MethodGuardar="save"
            @MethodEliminar="remove"
            @MethodAnular="anular"
            @MethodImprimir="imprimirList"
        />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                        small
                        color="yellow accent-4"
                        @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn
                    >
                    <v-btn
                        small
                        dark
                        color="deep-purple accent-2"
                        @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn
                    >
                  </v-btn-toggle>
                </center>
                <!--                <BarraBusqueda2-->
             
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar
                    flat
                    color="#BDBDBD"
                    dense
                    style="border: 1px solid #000000;"
                >


                  <v-avatar tile size="45">
                    <img
                        src="@/assets/iconos_micromarket/custom-reports.png"
                        alt="Reporte"
                    >
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;">reporte comparativo de ordenes pendientes  vs órdenes finalizadas</span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="cerrarmodalcuenta"
                  >
                   <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="formRepor" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                      <v-select
                            v-model="ReporteComparativo.mesdesde"
                           :rules="[$rules.required]"
                            label="desde:"
                            outlined
                            dense
                            autocomplete="off"
                            :items="LabMes"
                            item-text="mes"
                            item-value="cod"
                        > <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-select>


 <v-text-field
          
            label="Año:"
            dense
            outlined
            autocomplete="off"
          @keyup="KeyUpCantidad"
            v-model.number="ReporteComparativo.year"
            @blur="ChangYear"
          >
          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
          </v-text-field>

                      </v-col>
                      <!-- fn columna 1 -->

 <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                      <v-select
                            v-model="ReporteComparativo.meshasta"
                            label="hasta:"
                            outlined
                            dense
                            autocomplete="off"
                            :items="LabMes"
                            item-text="mes"
                            item-value="cod"
                            :rules="[$rules.required]"
                        > <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-select>
                      </v-col>
                      <!-- fn columna 1 -->



                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">
                      </v-col>
                      <!-- fn columna 2 -->


                    </v-row>


                  </v-form>
                </v-container>

                <hr>

<v-toolbar
          flat
          color="#BDBDBD"
          dense
          style="border: 1px solid #000000;"
          class="d-flex justify-center"
      >

        <v-btn-toggle dense rounded>
         
          <v-btn
              color="light-blue accent-2"
              target="_blank"
              @click.prevent="ReporteImprimir"

          >
            <i style="font-size: 28px;" class="fa fa-print"> </i>

            <span style="color:#FFFFFF;">Printer </span>
          </v-btn>
       
        </v-btn-toggle>
      </v-toolbar>


<!-- 
                <ModalReporteFiltros
                    @DataReporteBasico="DDataReporteBasico"
                    :Datos="ReporteComparativo"
                /> -->

              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
        label="myAlert"
        v-model="acti_tb_data"
        persistent
        max-width="100%"
        max-height="100%"
        transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
              color="grey darken-4"
              fab
              small
              @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                  v-model="search"
                  @keyup="filtro"
                  label="Buscar"
                  single-line
                  hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
                :headers="slotHtabla"
                dense
                :items="list"
                :search="search"
                @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar
        v-model="aalert.estado"
        :color="aalert.color"
        style="margin-top: 20%;"
        :vertical="true"
        :timeout="120000"
        top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import {EventBus} from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {
 
  },
  mounted() {

    var fecha = new Date()
    this.ReporteComparativo.year= fecha.getFullYear();

  },

  data: () => ({
  

LabMes:[{cod:1,mes:'Enero'}, {cod:2,mes:'Febrero'}, {cod:3,mes:'Marzo'}, {cod:4,mes:'Abril'}, 
{cod:5,mes:'Mayo'}, {cod:6,mes:'Junio'}, {cod:7,mes:'Julio'},{cod:8,mes:'Agosto'},{cod:9,mes:'Septiembre'},
{cod:10,mes:'Octubre'},{cod:11,mes:'Noviembre'},{cod:12,mes:'Diciembre'}],     

    windowSize: {width: "auto", height: "auto"},

    ReporteComparativo: {
      mesdesde:null,
      meshasta:null,
      year:0,
      nombre: 'REPORTE VOUCHER TARJETAS DE CREDITO',
      UrlImprimir: '/reportes-pv/ImprimirListVoucherTarjetasCredito',
      UrlExel: '/reportes-pv/ExcelListVoucherTarjetasCredito',
      UrlCorreo: '/reportes-pv/CorreoListVoucherTarjetasCredito',
    },


    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,
    headers: [{text: "Tipo Clientes", value: "descripcion"}],
    slotlist: [
      {dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:"},
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      {dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo"},
    ],
    slotHtabla: [
      {text: "Código", value: "codigo"},
      {text: "Descripcion", value: "descripcion"}
    ],
    list: [],
    search: "",
    acti_tb_data: false,
  
    aalert: {
      estado: false,
      color: null
    },

  }),
  methods: {

    onResize(e) {
      this.windowSize = e
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    datostabla(e) {
    },
    save() {},
    remove() {},
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
      (this.ReporteComparativo = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
          EventBus.$emit("actualizaBarraBusqueda2");
    },


BlurDesde(){

if(this.ReporteComparativo.desde.toString().length==0){
  this.ReporteComparativo.desde=new Date().toISOString().substr(0,10)
}


 },


 BlurHasta(){

if(this.ReporteComparativo.hasta.toString().length==0){
  this.ReporteComparativo.hasta=new Date().toISOString().substr(0,10)
}

 },

 ChangYear(){


if(this.ReporteComparativo.year==0){
var fecha = new Date()
this.ReporteComparativo.year= fecha.getFullYear();
}


// var fecha = new Date(e)
    // var mes = fecha.getMonth();
 },

KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}

if(e.target.value.length==0){e.target.value=0}

  if(e.key){
     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     if(e.target.value==null){ e.target.value=0 }

    }
    else{e.key=""}

if(e.target.value.length==0){ e.target.value=0
        e.key=0
     }
  
},

ReporteImprimir(){
  EventBus.$emit("loading", true);
var lbls= ['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']

if(this.$refs.formRepor.validate()) {
var desde=`${this.ReporteComparativo.year}-${this.ReporteComparativo.mesdesde}-01`
var hasta=`${this.ReporteComparativo.year}-${this.ReporteComparativo.meshasta}-01`


var today = new Date(hasta);
var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);

var fechaInic =new Date(desde).toISOString().substr(0,10);
var fechafin=new Date(lastDayOfMonth).toISOString().substr(0,10);
var mesini=this.ReporteComparativo.mesdesde
var mesfin=this.ReporteComparativo.meshasta
var years=this.ReporteComparativo.year

var obj={
  where:` fechapautada >=' ${fechaInic} ' and fechapautada <= ' ${fechafin}' `,
  inform:` Desde: ${ lbls[mesini]}       Hasta: ${ lbls[mesfin]}      Año:${years}`
  }


this.$axios.post(this.$hostname + this.$hName +'/ordenes/imprimirReportComparativo', JSON.parse(JSON.stringify(obj)),{ headers: this.$store.getters.GetheadersAxios }).then(res => {
  EventBus.$emit("loading", false);
/*let pdfWindow = window.open("Reporte de imprecion")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>") */
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
    EventBus.$emit("openPDF",PDF ,'comparativo Ordenes');
         
      });


//fechapautada >=' 2022-01-01 ' and fechapautada <= ' 2022-12-31'
}




},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    },
    DDataReporteBasico(e) {
    },
  }
};
</script>

<style></style>
