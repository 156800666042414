<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Períodos Contables'" :descripcion="'Registro de Períodos Contables'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle> </v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>


                </center>
                <BarraDinamicaNormal ref="BarraDiNor" :url="
                  $hostname + $hName + '/periodofiscal/findall'
                " :nombre="'Periodo Fiscal'" :headers="[{ text: 'Periodo Fiscal', value: 'anoperiodo' }]"
                  :slotname="'item.descripcion'" :emitnombre="'emitCONTPeriodoFiscal'"
                  :SeachNombre="'Búsqueda por descripcion '" :ContListSlot="[
                    { Text: 'Código', NomValue: 'id', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Periodo Fiscal', NomValue: 'anoperiodo', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.1, State: true },
                  
                  ]" />
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">


                  <v-form ref="formPeriodoFiscal" v-model="valid" lazy-validation>
                    <v-row>

                      <!-- columna 1------------------------------------------------- -->
                      <v-col cols="12" md="6" sm="6">
                        <!------------------------------------------ Nombre -->
                        <v-text-field v-model="PeriodoFiscal.descripcion" :rules="[$rules.required]" autocomplete="off"
                          label="* Descripción:" required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>


                        <!-- ----------------------------- year-->
                        <v-text-field label="* Año:" dense outlined v-model="PeriodoFiscal.anoperiodo"
                          @keyup="KeyupYear" @blur="periodoByYear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar-o"></i>
                          </template>
                        </v-text-field>


                        <v-switch v-model="PeriodoFiscal.activado" label="Activo" color="success"
                          hide-details></v-switch>


                      </v-col>
                      <!-- fn columna 1-------------------------------------------------- -->

                      <!-- columna 2---------------------------------------->
                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  fecha-->
                        <v-text-field dense outlined type="date" label="Mes incial del período:"
                          :rules="[$rules.required]" @blur="validadFecha" v-model="PeriodoFiscal.fecha">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <!-------------------------------------------------- Concepto -->
                        <v-switch v-model="PeriodoFiscal.cerrado" label="Cerrado" color="success"
                          hide-details></v-switch>

                        <br>
                        <v-btn color="green lighten-1" dark small @click.prevent="generarPeriodo">Generar período
                          <i style="font-size: 18px;" dark right class="fa fa-retweet"></i>
                        </v-btn>


                      </v-col>
                      <!-- fn columna 2  ------------------------------------>

                    </v-row>

                  </v-form>
                </div>
              </v-card>

              <!--------------------------------------------------- tb_tabla -->
              <v-col cols="12" md="12" sm="12">
                <v-card>

                  <v-data-table class="elevation-1" dense :headers="[{ text: 'Número', value: 'numeroperiodo', width: 'auto' }, { text: 'Descripción', value: 'descripcion', width: 'auto' },
                  { text: 'Fecha inicial', value: 'nuevaFechaInicio', width: 'auto' }, { text: 'Fecha final', value: 'nuevaFechaFin', width: 'auto', align: 'center' },
                  { text: 'Protegido', value: 'cerrado', width: 'auto' }]" :items="ListPeriodo" :items-per-page="-1">


                    <template v-slot:item.descripcion="{ item }">
                    <span>{{ getDescripcionMeses(item.nuevaFechaInicio)}}</span>
                    </template>

                    <template v-slot:item.nuevaFechaInicio="{ item }">
                      <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                      <span>{{ FormatoFecha(item.nuevaFechaInicio) }}</span>
                    </template>

                    <template v-slot:item.nuevaFechaFin="{ item }">
                      <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                      <span>{{ FormatoFecha(item.nuevaFechaFin) }}</span>
                      <!-- <span>{{FormatoFecha(item.fechafin)}}</span> -->
                      <!-- <p></p>  -->
                      <!-- <span>{{item.fechafin}}</span>  -->

                    </template>

                    <template v-slot:item.cerrado="{ item }">
                      <v-switch class="" color="teal" label="" v-model="item.cerrado" dense>
                        <template v-slot:label>
                          <strong v-if="item.cerrado == true" style="color:#000000; font-size:13px;"> Si</strong>
                          <strong v-if="item.cerrado == false" style="color:#000000; font-size:13px;"> No</strong>
                        </template>
                      </v-switch>

                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
              <!--------------------------------------------------- fn tb_tabla  -->





            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'id', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripcion', value: 'descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Año Periodo', value: 'anoperiodo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cerrado', value: 'cerrado', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activado', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.cerrado="props">
                <span v-if="props.item.cerrado == true">Si</span>
                <span v-if="props.item.cerrado == false">No</span>
              </template>

              <template v-slot:item.activado="props">
                <span v-if="props.item.activado == true">Activo</span>
                <span v-if="props.item.activado == false">In-Activo</span>
              </template>



            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarPeriodo'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import numeral from 'numeral'
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,changeZone,getFechaZone } from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal
  },
  created() {
    var json = {
      titulo: "Períodos Contables",
      descripcion: "Registro de Períodos Contables",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CONTPeriodoFiscalNew",
        "CONTPeriodoFiscalSave",
        "CONTPeriodoFiscalRemove",
        "CONTPeriodoFiscalAnular",
        "CONTPeriodoFiscalPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {




    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("CONTPeriodoFiscalNew", this.new);
    EventBus.$on("CONTPeriodoFiscalSave", this.save);
    EventBus.$on("CONTPeriodoFiscalRemove", this.remove);
    EventBus.$on("CONTPeriodoFiscalAnular", this.remove);
    EventBus.$on("CONTPeriodoFiscalPrintList", this.imprimirList);
    EventBus.$on("emitCONTPeriodoFiscal", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      ListaTiposClientes: [],
      ListPeriodo: [],
      generarPeridoos: true,
      RDocumento: [],

      windowSize: { width: "auto", height: "auto" },
      PeriodoFiscal: {
        fecha: fechaNueva(),
        id: 0,
        descripcion: "",
        anoperiodo: 0,
        cerrado: false,
        activado: true,
        borrado: false,
        periodoscontablesDetalleList: []

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },


      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "codigo", value: "codigo" }, { text: "Periodo", value: "descripcion" }, { text: "Año", value: "ano" }, { text: "fecha Inicio Periodo", value: "fecha" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,


      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {
      //this.windowSize=e
    },
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,changeZone,getFechaZone,
    FormatoFecha1(params0) {
      //var params= new Date(params0).toISOString().substr(0,10);
      /*console.log('---params0---')
      console.log(params)*/
      //const esDate = new Intl.DateTimeFormat("es").format(params0);


      /*// El inglés estadounidense utiliza el orden mes-día-año
      console.log(date.toLocaleDateString('en-US'));
      // → "12/20/2012"
      // El inglés británico utiliza el orden día-mes-año
      console.log(date.toLocaleDateString('en-GB'));
      // → "20/12/2012"*/


      /*
      // solicitar un día de la semana junto con una fecha larga
      var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      console.log(date.toLocaleDateString('de-DE', options));
      // → "Donnerstag, 20. Dezember 2012"
      */

      // let date = new Date("2022-04-30".replace(/-/g, "/"));
      let date = new Date(params0);
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      //console.log(date.toLocaleDateString('ko-KR'));

      if (date != null) {
        if (date.toString().trim().length > 0) {
          return date.toLocaleDateString("en-GB")
        }
      }

      /*var gfg = Date.UTC('2020', '07', '03');
      console.log(gfg)
      console.log(new Date(gfg))
      return gfg;*/
      //return params;
      //console.log(esDate)

    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/periodofiscal/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {
      this.ListPeriodo = [];
      this.PeriodoFiscal = e;
      this.PeriodoFiscal.fecha = (new Date("01/01/" + e.anoperiodo)).toJSON().split("T")[0]
      this.ListPeriodo = e.periodoscontablesDetalleList;
      this.acti_tb_data = false
    },
    save() {

      if (this.$refs.formPeriodoFiscal.validate()) {
        if (this.ListPeriodo.length <= 0) {
          this.alerta("Debe generar los periodo para poder salvar ", "error");
          return;
        }
        if (this.PeriodoFiscal.periodoscontablesDetalleList.length <= 0) {
          this.PeriodoFiscal.periodoscontablesDetalleList = this.ListPeriodo
        }
        EventBus.$emit("loading", true);
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/periodofiscal/save",
            this.PeriodoFiscal,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>{
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )}
            
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },



    NotifMessage() {

      if (this.PeriodoFiscal.id != 0 && this.PeriodoFiscal.id != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarPeriodo"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un periodo para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.PeriodoFiscal)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.PeriodoFiscal.id != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/periodofiscal/borrar",
            this.PeriodoFiscal,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta(res.data.mensage, res.data.estado) })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }

    },
    anular() {
      if (this.Elim_notif.estado) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.PeriodoFiscal)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/periodofiscal/borrar",
            this.PeriodoFiscal,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() { },
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.PeriodoFiscal = {
        fecha: fechaNueva(),
        id: 0,
        descripcion: "",
        anoperiodo: 0,
        cerrado: false,
        activado: true,
        borrado: false,
        periodoscontablesDetalleList: []
      }
      this.ListPeriodo = []
      //  EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },



    KeyupYear(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.PeriodoFiscal.anoperiodo = mynumeral(this.PeriodoFiscal.anoperiodo).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.PeriodoFiscal.anoperiodo == null) {

          this.PeriodoFiscal.anoperiodo = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }

    },


    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      //return params;
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },


    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/periodofiscal/findall", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    },
    getDescripcionMeses(e) {
      var letraMes = "";
      // var fecha = new Date(e)
      var fecha = this.changeZone(e)
      var mes = fecha.getMonth();
      switch (mes) {
        case 0:
          letraMes = "Cierre Enero " + fecha.getFullYear();
          break;
        case 1:
          letraMes = "Cierre Febrero " + fecha.getFullYear();
          break;
        case 2:
          letraMes = "Cierre Marzo " + fecha.getFullYear();
          break;
        case 3:
          letraMes = "Cierre Abril " + fecha.getFullYear();
          break;
        case 4:
          letraMes = "Cierre Mayo " + fecha.getFullYear();
          break;
        case 5:
          letraMes = "Cierre Junio " + fecha.getFullYear();
          break;
        case 6:
          letraMes = "Cierre Julio " + fecha.getFullYear();
          break;
        case 7:
          letraMes = "Cierre Agosto " + fecha.getFullYear();
          break;
        case 8:
          letraMes = "Cierre Septiembre " + fecha.getFullYear();
          break;
        case 9:
          letraMes = "Cierre Octubre " + fecha.getFullYear();
          break;
        case 10:
          letraMes = "Cierre Noviembre " + fecha.getFullYear();
          break;
        case 11:
          letraMes = "Cierre Diciembre " + fecha.getFullYear();
          break;
        default:
          letraMes = "N/A";
      }

      return letraMes;
    },
    periodoByYear(e) {
      if (e != null) {
        this.$axios
          .get(this.$hostname + this.$hName + "/periodofiscal/existe/periodo/" + this.PeriodoFiscal.anoperiodo, { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data) {
              this.alerta("Este año ya posee un periodo fiscal Creado", "error")
              this.generarPeridoos = false;
            }
          });
      }
      this.generarPeridoos = true;
    },
    validadFecha() {
      /*if (this.PeriodoFiscal.fecha!=null) {
       var fecper = new Date(this.PeriodoFiscal.fecha+1);
       console.log(fecper)
       var ano = parseInt(fecper.toLocaleDateString().split("/")[2]);
       console.log(ano)
       if (this.PeriodoFiscal.anoperiodo!==ano){
            this.alerta("La fecha seleccionada no coincide con el año del periodo que esta digitando","error");
            return false;
       }
      }*/

      if (this.PeriodoFiscal.fecha != null) {
        var fec = "" + this.PeriodoFiscal.anoperiodo
        var fec2 = new Date(this.PeriodoFiscal.fecha).toISOString().substr(0, 4)
        if (fec !== fec2) {
          this.alerta("La fecha seleccionada no coincide con el año del periodo que esta digitando", "error");
          return false;
        }
      }


      return true;
    },
    async  generarPeriodo() {
      if (this.$refs.formPeriodoFiscal.validate()) {
        if (!this.validadFecha()) {
          return;
        }
        if (!this.generarPeridoos) {
          this.alerta("Este año ya posee un periodo fiscal Creado", "error");
          return;
        }
        EventBus.$emit("loading", true);
        this.$axios
          .get(this.$hostname + this.$hName + "/periodofiscal/generar/periodo/" + this.PeriodoFiscal.fecha, { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.generarPeriodoCont(res.data)

          });
      } else {
        this.alerta("Los campos en rojo son requeridos", "error")
      }
    },

    async generarPeriodoCont(lisObjPeriodo){
    if(lisObjPeriodo.length>0){
      var LIST_PER=[]
      await Promise.all(lisObjPeriodo.map(async (element) => {

var OBJ=JSON.parse(JSON.stringify(element))
OBJ.fechafin=this.getFechaZone(element.nuevaFechaFin)
OBJ.fechainicio=this.getFechaZone(element.nuevaFechaInicio) 
/*OBJ.nuevaFechaFin:"2031-01-31"
OBJ.nuevaFechaInicio:"2031-01-01"*/
LIST_PER.push(OBJ)
})); 

this.ListPeriodo = LIST_PER
EventBus.$emit("loading", false);
    }else{
      EventBus.$emit("loading", false);
    }
          
    },

  },

  computed: {

    ActDescBtn() {
      if (this.PeriodoFiscal.id > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      if (this.PeriodoFiscal.id <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }


};
</script>

<style lang="scss">

</style>
