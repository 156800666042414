<template>
  <div>
    {{ ActDescBtn }}
    <table
      style="position: absolute"
      width="100%"
      :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8"
    >
      <tr max-height="48px" height="48px">
        <ContenidoBase
          :titulo="'Precio Producto'"
          :descripcion="'Ver Precio de los Productos'"
          :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla"
          @MethodNuevo="newwnuevo"
          @MethodGuardar="save"
          @MethodEliminar="remove"
          @MethodAnular="anular"
          @MethodImprimir="imprimirList"
        />
      </tr>

      <tr>
        <!-- BarraPrimaria 96 --------------------------------------------------------------------------------->
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraTablaDinamicaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn
                    small
                    width="95%"
                    color="yellow accent-4"
                    @click.prevent="actualiza_tb_ver"
                  >
                    <i style="font-size: 25px" class="fa fa-eye"> </i>
                    ver</v-btn
                  >
                </center>
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app
            id="inspire"
            class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }"
          >
          <!-- Formulario-Ver precio producto -->
            <v-container>
              <div @click="ver">
                <!--style="display:none;"  -->
                <v-row>
                  <!-- fila 1 ------------------------------>
                  <v-col cols="12" md="12">
                    <v-row :align="'center'" :justify="'center'">
                      <!-- <i style="font-size: 20px;" class="fa fa-font">ok</i> -->

                      <img
                        @load="ver"
                        height="250"
                        width="250"
                        :src="logo"
                        alt="micromarket"
                      />
                    </v-row>
                  </v-col>
                  <!-- fn fila 1 ------------------------------>

                  <!-- fila 2 ------------------------------>
                  <v-col cols="12" md="12">
                    <v-row :align="'center'" :justify="'center'">
                      <b
                        ><i style="font-size: 35px">{{ usuario }}</i></b
                      >
                    </v-row>
                  </v-col>

                  <v-col  cols="12" md="12">
                    <v-row :align="'center'" :justify="'center'">
                      <b
                        ><i style="font-size: 50px">{{ descripcion }}</i></b
                      >
                    </v-row>
                  </v-col>
                  <!-- fn fila2 -------------------------->

                  <!-- fila 2 ------------------------------>
                  <v-col  cols="12" md="12">
                    <v-row :align="'center'" :justify="'center'">
                      <b style="font-size: 50px"
                        >RD$.{{ currencyFormatter(precio) }}</b
                      >
                    </v-row>
                  </v-col>
                  <!-- fn fila2        -------------------------->
                </v-row>

                <center>
                  <!-- <v-form ref="form" v-model="valid" lazy-validation> </v-form>  -->
                  <v-text-field
                    style="font-size: 0px"
                    :autofocus="true"
                    id="codigo"
                    color="white"
                    type="number"
                    v-model="producto.codigo"
                    @blur="ver"
                    @change="ejecutar"
                  >
                  </v-text-field>
                </center>
              </div>
            </v-container>
          <!-- Formulario-Ver precio producto -->

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraSecundariaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          ></div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
  </div>
</template>

<script>
//  var valor = '';
//             $(document).keydown(function (e) {
//                 valor += e.key;
//                 if (e.keyCode === 13) {
//                     valor.substring(0, valor.length - 5);
//                     leerPro(valor);
//                     valor = '';
//                 }
//             });
//             leerPro = function (valor) {
//                 console.log(valor.substring(0, valor.length - 5));
//                 leer_pro(valor.substring(0, valor.length - 5));
//             };
//             window.onload = function () {
//                 function foo() {
//                     alert("test");
//                 }
//                 setInterval(foo, 500);

import VueAutonumeric from "../../components/VueAutonumeric";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";

export default {
  components: {
    VueAutonumeric,
    ContenidoBase,
  },
  created() {
    var json = {
      titulo: "Precio Producto",
      descripcion: "Ver Precio de los Productos",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi: false,
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  //mounted-carga de inicio
  mounted() {
    this.cargarDatos();
  },
  //mounted-carga de inicio

  methods: {
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },

   // llama a una api y trae los datos unidad producto.
    ejecutar() {
      console.log(this.producto.codigo);
      var codigo = this.producto.codigo;

      this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/unidades-productos/" +
            this.producto.codigo +
            "/" +
            false +
            "/buscar"
        )
        .then(
          (res) => (
            (this.precio = res.data[0].precio),
            (this.descripcion = res.data[0].productos.descripcion)
          )
        );

      //alert(codigo) this.Rdata=res.data, console.log(this.Rdata) ,30000
      this.producto.codigo = "";

      setTimeout(this.limpiar, 8000);
    },

  // reestablece  los datos.
    limpiar() {
      this.precio = 0;
      this.descripcion = "";
    },

    ver() {
      //console.log('ok')
      document.getElementById("codigo").focus();
    },

    currencyFormatter(params) {
      //return this.formatNumber(params) + ".00";
      return this.formatNumber(params);
    },

    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    save() {},
    remove() {},
    anular() {},
    imprimir() {},
    nuevo() {},
    newwnuevo() {},
    imprimirList() {},
    cargarDatos() {
      this.configuraciones = this.$store.getters.GetConfiguracionGeneral;
      this.usuario = this.configuraciones.config.nombre;
      this.logo = this.configuraciones.config.logo;
    },
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      Rdata: [],
      usuario: "FARMACIA BIBBIA",
      precio: 0,
      descripcion: "",
      logo: "",
      configuraciones: null,
      producto: {
        codigo: "",
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: false,
        nuevo: false,
        guardar: false,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      rules: {
        required: (value) => !!value || "Required.",
        edad: [
          (val) => val > 10 || `Edad debe ser mayor a 10`,
          (val) => val < 70 || `Edad debe ser menor a 120`,
        ],
        counter: (value) =>
          (value && value.length <= 50) || "Max 20 characters",
        RTel: (value) => value != 10 || `telefono debe tener 10 digitos`,
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },
    };
  },
  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
    },
  },
};
</script>

<style></style>
