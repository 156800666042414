<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Solicitud de reposición'"
  :descripcion="'Registrar solicitud de reposición de caja chica'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  


 
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraDinamicaNormal
              ref="BarraDiNor"
              :url="
                $hostname + $hName + '/solicitud-reposicion/findall'
              "
              :nombre="'Solicitud de Reposición'"
              :headers="[ { text: 'Codigo', value: 'solicitudReposicionPK.codigo' },
                          { text: 'Caja Chica', value: 'cajaChica.descripcion' },
                          { text: 'Custodio', value: 'custodio.nombre'},
                          { text: 'fecha', value: 'fecha' },,
                          { text: 'estado', value: 'activado' }]"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCajaSolicitudReposicion'"
              :SeachNombre="'Búsqueda por descripcion '"
              :ContListSlot="[
              {Text:'Código', NomValue:'solicitudReposicionPK', Nv2:'codigo', Ctsl:2, Type:0, State:true},
              {Text:'Caja Chica', NomValue:'cajaChica',Nv2:'descripcion', Ctsl:2, Type:0, State:true},
              {Text:'Custodio', NomValue:'custodio', Nv2:'nombre' ,Ctsl:2, Type:0, State:true},
              {Text:'Fecha', NomValue:'fecha', Ctsl:1, Type:1, State:true},
              {Text:'Estado',NomValue:'activado', Ctsl:1, Type:0.1, State:true}
              ]"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2"> 
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="6" sm="6">
<!-------------------------------------------------------- No. Solicitud -->
                  <v-text-field
                    v-model.number="SolicitudReposicion.solicitudReposicionPK.codigo"
                    autocomplete="off"
                    label="No. Solicitud:"
                    disabled
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                    </template>
                  </v-text-field>

                  <!-------------------------------------------------------- Cuenta Bancaria -->
<v-combobox
                    v-model="SolicitudReposicion.cajaChica"
                    label="Caja Chica:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RCajaChica"
                    @focus="cargarCajaChica"
                    @blur="cargarDetalle"
                    @change="calcularTotal"
                    item-text="descripcion"
                  >  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-briefcase"
                            ></i>
                          </template>
</v-combobox>
      
                </v-col>
             
                <v-col cols="12" md="6" sm="6">

<!-- -----------------------------  fecha  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha :"
        v-model="SolicitudReposicion.fecha"
        @blur="cargarDetalle"
        @change="calcularTotal"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>

<!-- -----------------------------  Valor  -->
<VueAutonumeric
                      label="Valor:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      required
                      outlined
                      dense
                      v-model="SolicitudReposicion.valor"
                     :rules="[$rules.required]"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                </v-col>
              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>


<v-col cols="12" md="12" sm="12" >
<v-form ref="form2" @submit.prevent=""  v-model="valid" lazy-validation>
<v-data-table
      class="elevation-1"
      dense
  :headers="[{ text: 'Recibo', value: 'recibo.reciboPK.codigo',width:'auto', sortable:false}, { text: 'Concepto', value: 'recibo.concepto',width:'auto', sortable:false},
{ text: 'Valor', value: 'recibo.valor',width:'auto', sortable:false},{ text: 'Solicitar', value: 'aprobado',width:'auto', sortable:false},
]"
        :items="SolicitudReposicion.solicitudReposicionDetalleList"
        :items-per-page="-1"
      >
     <template v-slot:item.aprobado="{ item }"> 

       <v-switch
              class=""
              color="teal" 
              label=""
              v-model="item.aprobado"
              dense
              :disabled="SolicitudReposicion.solicitudReposicionPK.codigo>0"
              @change="calcularTotal"
            >
          <template v-slot:label>
         <strong v-if="item.aprobado==true" style="color:#000000; font-size:13px;">Solicitado: Si</strong>
         <strong v-if="item.aprobado==false" style="color:#000000; font-size:13px;">Solicitado: No</strong>
        </template>
         </v-switch> 
   </template>

<template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.valor)}}</span>
    </template>


 <template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                            
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(total)}} </strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
  </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>CANTIDAD PEDIDA:5</span>
        <span>CANTIDAD EXISTENCIA:5</span>
        <span>VENTAS:5</span>
       <span>COSTO:5</span>
       <span>IMPORTE:5</span>

      </v-list-item-content>
    </v-list-item>

  </template>
    </v-data-table>




</v-form>
</v-col>




        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
  fullscreen hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

<v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent="dialogimprimirListAll=true, datosImprecion.secuencia=0, datosImprecion.nombre='LISTADO SOLICITUD DE REPOSICIÓN' "
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>

          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'solicitudReposicionPK.codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Descripcion', value: 'cajaChica.descripcion', sortable:false, class: ['no-gutters black--text'] }
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :items-per-page="15"
            >
             
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarReposicion'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirListAll" persistent max-width="80%" max-height="50%">
<ModalReporteBasico
@DataReporteBasico="dialogimprimirListAll=false,datosImprecion.nombre='SOLICITUD DE REPOSICIÓN'"
:Datos="datosImprecion"
/>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- fn Modales ----------------------------------------------------------------------------------------------->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import {currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
    
  },
  created() {  
    var json = {
      titulo: "Solicitud de reposición",
      descripcion: "Registrar solicitud de reposición de caja chica",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CajaSolicitudReposicionNew",
        "CajaSolicitudReposicionSave",
        "CajaSolicitudReposicionRemove",
        "CajaSolicitudReposicionAnular",
        "CajaSolicitudReposicionPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CajaSolicitudReposicionNew", this.new);
    // EventBus.$on("CajaSolicitudReposicionSave", this.save);
    // EventBus.$on("CajaSolicitudReposicionRemove", this.remove);
    // EventBus.$on("CajaSolicitudReposicionAnular", this.remove);
    // EventBus.$on("CajaSolicitudReposicionPrintList", this.imprimirList);
    EventBus.$on("emitCajaSolicitudReposicion", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data:() =>({
   

dialogimprimirList:false,
dialogimprimirListAll:false,
datosImprecion:{
nombre:'SOLICITUD DE REPOSICIÓN',
 UrlImprimir: '/solicitud-reposicion/Imprimir', 
 UrlExel: '/solicitud-reposicion/Excel', 
 UrlCorreo: '/solicitud-reposicion/Correo', 
 secuencia: 0,
 caja:0
},


      RCajaChica:[],
      RCustodio:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      SolicitudReposicion: {
        solicitudReposicionPK: {
            codigo: 0,
            caja: 0
        },
        valor: 0,
        fecha: fechaNueva(),
        activado: true,
        borrado: false,
        cajaChica:null,
        custodio:null,
        solicitudReposicionDetalleList:[]
      },
    total:0.00,
     EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },


      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
   
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,
onResize(e){
//this.windowSize=e
},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }


    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/solicitud-reposicion/findall",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.list = res.data))
        .catch(error => console.log(error));
    },
    cargarCajaChica(){
       this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/cajachica/findalactivo",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.RCajaChica = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.SolicitudReposicion = e;
      this.SolicitudReposicion.fecha = (new Date(e.fecha)).toJSON().split("T")[0]
      this.acti_tb_data=false
    },
    cargarDetalle(){

      if(this.SolicitudReposicion.solicitudReposicionPK.codigo===0){
        console.log("/solicitud-reposicion/solicitar/"+this.SolicitudReposicion.fecha+"/"+this.SolicitudReposicion.cajaChica.codigo);
        this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/solicitud-reposicion/solicitar/"+this.SolicitudReposicion.fecha+"/"+this.SolicitudReposicion.cajaChica.codigo,
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.SolicitudReposicion.solicitudReposicionDetalleList = res.data))
        .catch(error => console.log(error));
          }
    },
    calcularTotal(){
      if (this.SolicitudReposicion.solicitudReposicionDetalleList.length>0) {
        this.total=0.00
        this.SolicitudReposicion.solicitudReposicionDetalleList.forEach(e => {
          if (e.aprobado===true) {
            this.total=this.total + e.valor
          }
          });
          }
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.SolicitudReposicion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/solicitud-reposicion/salvar",this.SolicitudReposicion,
         {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },



NotifMessage() {

if(this.SolicitudReposicion.codigo!=0 && this.SolicitudReposicion.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarReposicion"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una solicitud para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },


  

    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.SolicitudReposicion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.SolicitudReposicion.solicitudReposicionPK.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/solicitud-reposicion/anular",this.SolicitudReposicion,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => {
            if (res.data){
              this.alerta("Datos borrado existomente !!!","bien")  }})
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.SolicitudReposicion.solicitudReposicionPK.codigo!=0) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.SolicitudReposicion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/solicitud-reposicion/anular",this.SolicitudReposicion,
           {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato anulado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no anulado",
              "error"
            )
          );
      }
    },
    imprimirList() {

if(this.SolicitudReposicion.solicitudReposicionPK.codigo>0){
this.datosImprecion.secuencia=this.SolicitudReposicion.solicitudReposicionPK.codigo
this.datosImprecion.caja=this.SolicitudReposicion.cajaChica.codigo
this.dialogimprimirList=true
}else{
this.alerta("Seleccione una reposición de caja chica para poder imprimir","error")
}
    
    },

DDataReporteBasico(e){
this.dialogimprimirList=e
},

    newwnuevo() {
      
      this.BarraTablaDinamicaAprir = false

      this.SolicitudReposicion = {
        solicitudReposicionPK: {
            codigo: 0,
            caja: 0
        },
        valor: 0,
        fecha: fechaNueva(),
        activado: true,
        borrado: false,
        cajaChica:null,
        custodio:null,
        solicitudReposicionDetalleList:[]
      }
      this.total=0.00
      
     //   EventBus.$emit("actualizaBarraBusqueda2");
    },


currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/solicitud-reposicion/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    }
  },

computed:{

    ActDescBtn(){      
if(this.SolicitudReposicion.solicitudReposicionPK.codigo>0){
if (this.SolicitudReposicion.activado){
  this.EstadoBtn={ 
    BarraTablaDinamicaAprir:true,
          nuevo:true,
          guardar:true,
          eliminar:false,
          anular:true,
          imprimir:true,
          vercuenta:false,
          guardartemporal:false,
          restaurartemporal:false,
          importarDato:false,
          cargarProducto:false,
          ImprimirproductoInv:false,
          etiquetaXfactura:false
          }
}else{
  this.EstadoBtn={ 
    BarraTablaDinamicaAprir:true,
          nuevo:true,
          guardar:false,
          eliminar:false,
          anular:false,
          imprimir:true,
          vercuenta:false,
          guardartemporal:false,
          restaurartemporal:false,
          importarDato:false,
          cargarProducto:false,
          ImprimirproductoInv:false,
          etiquetaXfactura:false
          }
}
}
if(this.SolicitudReposicion.solicitudReposicionPK.codigo<=0){
  this.EstadoBtn={ 
    BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
    }



};
</script>

<style></style>
