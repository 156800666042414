<template>
  <div>{{ActDescBtn}}

    <v-card>

      <v-toolbar flat color="indigo" dense>

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> LISTA DE RECIBOS DE INGRESOS </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="cerrarModalAddData()">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-col cols="12" md="12" sm="12" class=" py-0 my-0">
          <v-row>
          <v-col cols="12" md="3" sm="3" class="py-0 my-0"  >
            <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="busquedaFiltro.secuencia" style="font-size: 12px;" hide-details @blur="blurSecuencia">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
        </v-col>

        <v-col cols="12" md="6" sm="6" class="py-0 my-0"  >
          <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" ref="RefCombClienteReImprimir" autocomplete="off" dense outlined hide-details
                        label="cliente:" :items="RCliente" item-text="codnom" style="font-size: 13px;"
                        v-model="busquedaFiltro.cliente" @focus="cargarClienteBusqueda" @keyup="CbFilcliente" @blur="SelctCbClienteBuscar" no-filter>

                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('codigo')" ><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                    <span class="fontSize13 colorNombre" v-if="item!=null && !item.hasOwnProperty('codigo')" >{{item}}</span>
                  </template>


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                        <template v-slot:append-outer>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">


                              <v-btn @click.prevent="CargarLista" v-on="on"  small color="teal"><i style="font-size: 20px;"
                                class="fa fa-search"></i></v-btn>

                          </template>
                          <span> buscado a por filtro el recibo de ingreso</span>
                        </v-tooltip>
                </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>
  <v-col cols="12" md="3" sm="3" class="py-0 my-0" v-if="RecIngGuardado.length>0"  >
    <v-checkbox hide-details class="mt-2"   color="teal" label="" v-model="busquedaFiltro.RecibGuardad" @change="loaderTablaCargar"
    
  width="50">
  <template v-slot:label>
    <strong v-if="busquedaFiltro.RecibGuardad == true"
      style="color:#000000; font-size:13px;">Mostrar recibos guardados: Si</strong>
    <strong v-if="busquedaFiltro.RecibGuardad == false"
      style="color:#000000; font-size:13px;">Mostrar recibos guardados: No</strong>
  </template>
</v-checkbox>

  </v-col>
      </v-row>

        </v-col>
       <!-- @click:row="addClikData" -->
        <div v-show="busquedaFiltro.RecibGuardad==false"> 
        <v-data-table v-model="RselectedItem" dense :footer-props="arrayPropiedases" :headers="[
         {text: 'RECIBO', value: 'recibosdeingresoPK.secuencia' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'FECHA', value: 'fecha' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'VALOR', value: 'valor' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'NOMBRE CLIENTE', value: 'cliente.nombre' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'TIPO DE PAGO', value: 'tipoPago' ,sortable: false, align:'center', class: ['no-gutters black--text']},     
        ]" :items="ListItems" item-key="indx" class="elevation-1 TbSize13" @pagination="cargarPaginacionAll"
          :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page" :pageCount="TbPaginator.pageCount"
           :server-items-length="TbTotalItem" :single-select="false" :show-select="true" @item-selected="addClikData"
           @toggle-select-all="addClikData"
           :loading="loaderTabla"
           >

          <!-- //  props.item.indx = ListItems.indexOf(props.item) -->

          <template v-slot:item.fecha="props">
            <span style="font-size:13px; display:none;">{{ props.item.indx = `${props.item.recibosdeingresoPK.secuencia}-${props.item.recibosdeingresoPK.documento}`  }}</span>
            <!-- <span style="display:none;">{{props.item.indx = "indx"+props.item.recibosdeingresoPK.secuencia+"-"+props.item.recibosdeingresoPK.documento}}</span> -->
            <span>{{ FormatoFecha(props.item.fecha) }}</span>
          </template>

          <template v-slot:item.valor="props">
            <span>{{ currencyFormatter(props.item.valor) }}</span>
          </template>
          <template v-slot:item.tipoPago="props">
            <span>{{ VerificarTipoPago(props.item) }}</span>
          </template>
          <template v-slot:body.append v-if="windowSize.width >600">
            <tr>  
            <td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>
            <td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valorSelec)}} </strong></td>  
            <td class="border-top-bottom"><strong></strong></td>     
            <td class="border-top-bottom"><strong></strong></td>                                                                    
            <!-- <td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valor)}} </strong></td> -->
            <td class="border-top-bottom"><strong></strong></td>
            <td class="border-top-bottom"><strong></strong></td>
              </tr>
          </template>

          <template v-slot:footer v-if="windowSize.width<=600">
            <v-list-item style="font-size: 70%;" class="white--text" > 
                  <v-list-item-content>
                    <span>Total Seleccionada:{{currencyFormatter(totales.valorSelec)}}</span>
                    <!-- <span>Total valor:{{currencyFormatter(totales.valor)}}</span> -->
                  </v-list-item-content>
            </v-list-item>
          </template>

        
        </v-data-table>
      </div>
      <div v-show="busquedaFiltro.RecibGuardad==true"> 
        
        <v-data-table v-model="RselectedItem"  dense :footer-props="arrayPropiedases" :headers="[
         {text: 'RECIBO', value: 'recibosdeingresoPK.secuencia' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'FECHA', value: 'fecha' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'VALOR', value: 'valor' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'NOMBRE CLIENTE', value: 'cliente.nombre' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'TIPO DE PAGO', value: 'tipoPago' ,sortable: false, align:'center', class: ['no-gutters black--text']},     
        ]" :items="RecIngGuardado" item-key="indx" class="elevation-1 TbSize13" :single-select="false" :show-select="true" 
        @item-selected="addClikData" @toggle-select-all="addClikData" :loading="loaderTabla"
           >

          <!-- //  props.item.indx = ListItems.indexOf(props.item) -->

          <template v-slot:item.fecha="props">
            <span style="font-size:13px; display:none;">{{ props.item.indx = `${props.item.recibosdeingresoPK.secuencia}-${props.item.recibosdeingresoPK.documento}`}}</span>
            <!-- <span style="display:none;">{{props.item.indx = "indx"+props.item.recibosdeingresoPK.secuencia+"-"+props.item.recibosdeingresoPK.documento}}</span> -->
            <span>{{ FormatoFecha(props.item.fecha) }}</span>
          </template>

          <template v-slot:item.valor="props">
            <span>{{ currencyFormatter(props.item.valor) }}</span>
          </template>
          <template v-slot:item.tipoPago="props">
            <span>{{ VerificarTipoPago(props.item) }}</span>
          </template>
          <template v-slot:body.append v-if="windowSize.width >600">
            <tr>  
            <td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>
            <td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valorSelec)}} </strong></td>  
            <td class="border-top-bottom"><strong></strong></td>     
            <td class="border-top-bottom"><strong></strong></td>                                                                    
            <!-- <td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valor)}} </strong></td> -->
            <td class="border-top-bottom"><strong></strong></td>
            <td class="border-top-bottom"><strong></strong></td>
              </tr>
          </template>

          <template v-slot:footer v-if="windowSize.width<=600">
            <v-list-item style="font-size: 70%;" class="white--text" > 
                  <v-list-item-content>
                    <span>Total Seleccionada:{{currencyFormatter(totales.valorSelec)}}</span>
                    <!-- <span>Total valor:{{currencyFormatter(totales.valor)}}</span> -->
                  </v-list-item-content>
            </v-list-item>
          </template>

        
        </v-data-table>



      </div>

      </v-card-text>
    </v-card>


    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}

      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



  </div>
</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";
export default {

  components: {
    AutoNumeric
  },
  mounted() {



    //  this.CargarLista()

  },

  created() {

  },


  data() {

    return {

      loaderTabla:false,
      search: '',
      ListItems: [],
      RselectedItem:[],
      RecIngGuardado:[],
      RCliente:[],
      aalert: {
        estado: false,
        color: null
      },
      windowSize:{width:"auto", height:"auto"},
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      busquedaFiltro:{
        secuencia:0,
        cliente:null,
        RecibGuardad:false
      },

      totales:{
        valor:0,
        valorSelec:0
      }


    }


  }, props: {
   /* RselectedItemsss: {
      required: false,
      type: Array,
    }*/
  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,

    CargarListTb() {

      var clie = null
      var doc = null
      var desde=null
      var hasta=null
      var fact=null

if(this.busquedaFiltro.secuencia!=null){
  fact=this.busquedaFiltro.secuencia
}

if (this.busquedaFiltro.cliente!=null&&this.busquedaFiltro.cliente.hasOwnProperty('codigo')) {
  clie=this.busquedaFiltro.cliente.codigo
}

      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + fact + "/" + clie + "/" + doc + "/" + desde + "/" + hasta
      this.$axios.get(this.$hostname + this.$hName + '/reciboingreos/findListReciboIngresoPlus2/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          // this.ItemSelect(res.data.ListItems), 
          this.ListItems=res.data.ListItems
          this.TbTotalItem = res.data.TbTotalItem 
          this.loaderTablaCargar()
        //console.log(res.data)
        })

    },

ItemSelect(e){
this.ListItems=[]
  var List=e
  List.forEach(element => {
    element.indx="indx"+element.recibosdeingresoPK.secuencia+"-"+element.recibosdeingresoPK.documento
  });

  this.ListItems=JSON.parse(JSON.stringify(List))
},

    cargarPaginacionAll(e) {
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },


    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }
      this.ListItems=[]
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()


    },



    CargarLista() {
      this.Limpiarfiltro()
    },


    addClikData(e) {
     var _this = this;
        setTimeout(function () {
          /*var Objt = {estado: "datos",datos: _this.RselectedItem}
         _this.$emit("EmitAgregarReciboIngreso", Objt);*/
         _this.calcularTotales()
        }, 800); /**/
     // 

    },


    cerrarModalAddData() {
      //this.CargarLista()
      // this.Limpiarfiltro()
      
      var Objt = { 
        estado: "cerrar",
        RselectedItem:this.RselectedItem,
        valorSelec:this.totales.valorSelec
       }
 
      this.$emit("EmitAgregarReciboIngreso", Objt);

      this.busquedaFiltro={
        secuencia:0,
        cliente:null,
        RecibGuardad:false
      },

      this.totales={
        valor:0,
        valorSelec:0
      }
      this.RecIngGuardado=[]

    },

    VerificarTipoPago(e){

if(e.efectivo>0){
  return 'efectivo'
}

if(e.cheque>0){
  return 'Cheque'
}

if(e.transferencia>0){
  return 'transferencia'
}

if(e.tarjeta>0){
  return 'tarjeta'
}

if(e.Anticipado!=null){
  return 'Pago Anticipado'
}
return'';

    },


    loaderTablaCargar(){
      this.loaderTabla=true
var _this=this;
setTimeout(function(){ _this.loaderTabla=false }, 900);
    },

// Llamar a una API y obtener los datos del cliente.
cargarClienteBusqueda() {
      var param=""
      var filtroTipoCliente=3
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + filtroTipoCliente + "/25/0"+param, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      if (e.target.value.length >= 0) {

        var param=""
        var filtroTipoCliente=3
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/" + filtroTipoCliente + "/25/0"+param,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },


    // Comprobando si el valor de la entrada es nulo o indefinido.
    SelctCbClienteBuscar() {
      var _this = this;
      setTimeout(function () {
        if (_this.busquedaFiltro.cliente== null) {
          _this.busquedaFiltro.cliente= "";
        }

        if (!_this.busquedaFiltro.cliente.hasOwnProperty('codigo')) {
          _this.busquedaFiltro.cliente= "";
          _this.busquedaFiltro.cliente= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);
    },

    blurSecuencia(){

      var _this = this;
      setTimeout(function () {
        if ( typeof _this.busquedaFiltro.secuencia === 'string' && _this.busquedaFiltro.secuencia != null) {
          _this.busquedaFiltro.secuencia=0
        }

       /* if (_this.busquedaFiltro.cliente== null) {
          _this.busquedaFiltro.cliente= "";
        }*/
      }, 300);
    },


calcularTotales(e){
 this.totales.valor=0;
 this.totales.valorSelec=0;
      
//  this.ListItems.forEach(element => {

//   });

  this.RselectedItem.forEach(element => {
        this.totales.valorSelec+=element.valor
  });

  //this.ListItems=JSON.parse(JSON.stringify(List))
},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        //  this.NewCliente();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);


      }
    },
  },
  computed:{

ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

}
}


}
</script>

<style>

</style>