<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Vendedores -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Vendedores'" :descripcion="'aqui se registran los vendedores de la empresa'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodImportarDato="importarDato" />
      </tr>
    <!-- fn ContenidoBase-Vendedores -->

      <tr>
        <!-- BarraPrimaria-Vendedores --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>


                    <v-btn color="teal darken-2" dark text small @click.prevent="dialogFiltros = !dialogFiltros">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>
                </center>
                <!--------------------- aqui va la otra tabla izquierda --------->



                <BarraDinamicaNormal :url="$hostname + $hName + '/vendedores/findAllFiltro/'" :nombre="'Vendedores'"
                  :emitnombre="'emitCXCVendedores'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Teléfono', value: 'telefono' },
                    { text: 'Cod.Empleado', value: 'codigo2' },
                    { text: 'Meta de Ventas', value: 'metadeventa' },
                    { text: 'Sucursal', value: 'sucursal.descripcion' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Nombre', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
  { Text: 'Teléfono', NomValue: 'telefono', Ctsl: 1, Type: 0, State: true },
  { Text: 'Cod.Empleado', NomValue: 'codigo2', Ctsl: 1, Type: 0, State: true },
  { Text: 'Meta de Ventas', NomValue: 'metadeventa', Ctsl: 1, Type: 2, State: true },
  { Text: 'Sucursal', NomValue: 'sucursal', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
]" />



                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/vendedores/findAllFiltro'
              "
              :nombre="'Vendedores'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitCXCVendedores'"
              :SeachNombre="'Búsqueda por Nombre '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>





          </div>
        </td>
        <!-- fn BarraPrimaria-Vendedores---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <!-- Formulario-Vendedores  -->
            <div class="px-2">
              <v-form ref="form" v-model="valid" lazy-validation>

                <v-card color="grey lighten-3">

                  <div class="px-2">
                    <v-col cols="9" lg="9" md="9" sm="9">
                      <v-text-field v-model="nuevosVendedores.nombre" autocomplete="off" :rules="[$rules.required]"
                        label="Nombre" required outlined dense>
                        <template v-slot:prepend>
                          <vue-fontawesome icon="male" size="2" color="black"></vue-fontawesome>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-row>
                      <v-col cols="6" lg="6" md="6" sm="6">
                        <v-text-field v-model="nuevosVendedores.telefono" autocomplete="off" :rules="[$rules.required]"
                          v-mask="['###-###-####']" label="Teléfono" required outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="phone" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-text-field>

                        <v-combobox v-model="nuevosVendedores.sucursal" label="Sucursal" :items="ListaSucursales"
                        @blur="SelctCbSucursal" @focus="CargarSucursal" item-text="descripcion" required outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="building" size="2" color="black"></vue-fontawesome>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected }">
                          <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.descripcion}}</span>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col cols="6" lg="6" md="6" sm="6">

                        <v-text-field v-model="nuevosVendedores.cedula" autocomplete="off" :rules="[$rules.required]"
                          label="Cédula" v-mask="['###-#######-#']" minlength="13" required outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="id-card" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-text-field>

                        <v-text-field v-model="nuevosVendedores.codigo2" label="Codigo Empleado:" required outlined
                          dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="hashtag" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-text-field>
                      </v-col>


                      <v-col cols="12" lg="6" md="6" sm="6">
                        <v-textarea v-model="nuevosVendedores.direccion" :rules="[$rules.required]" label="Dirección"
                          required outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="map" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-textarea>
                      </v-col>
                      <v-col cols="12" lg="6" md="6" sm="6">
                        <v-switch v-model="nuevosVendedores.activo" label="" outlined dense>
                          <template v-slot:label>
                            <strong v-if="nuevosVendedores.activo == true"
                              style="color:#000000; font-size:13px;">activo:
                              Si</strong>
                            <strong v-if="nuevosVendedores.activo == false"
                              style="color:#000000; font-size:13px;">activo:
                              No</strong>
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>

                  </div>

                </v-card>
                <hr>
                <v-card color="grey lighten-3">

                  <v-list-item>
                    <v-list-item-content>
                      <h4>COMISION POR VENTAS</h4>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row>
                    <v-col cols="6" lg="6" md="6" sm="6">
                      <v-text-field v-model="nuevosVendedores.comisionventa" label="% Ventas" required outlined dense>
                        <template v-slot:prepend>
                          <vue-fontawesome icon="percent" size="2" color="black"></vue-fontawesome>
                        </template>
                      </v-text-field>

                      <!-- <v-text-field
                    v-model="nuevosVendedores.comisionproducto"
                    label="% Productos"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <vue-fontawesome
                        icon="percent"
                        size="2"
                        color="black"
                      ></vue-fontawesome>
                    </template>
                  </v-text-field> -->

                    </v-col>


                    <v-col cols="6" lg="6" md="6" sm="6">
                      <!-- <v-text-field
                    v-model="nuevosVendedores.comisionservicio"
                    label="% Servicios"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <vue-fontawesome
                        icon="percent"
                        size="2"
                        color="black"
                      ></vue-fontawesome>
                    </template>
                  </v-text-field> -->

                      <v-text-field v-model="nuevosVendedores.metadeventa" label="Metas de Ventas" required outlined
                        dense>
                        <template v-slot:prepend>
                          <vue-fontawesome icon="money" size="2" color="black"></vue-fontawesome>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card>

                <hr>

                <v-card color="grey lighten-3">

                  <div class="px-2">

                    <v-list-item>
                      <v-list-item-content>
                        <h4>SUPERVISOR</h4>
                      </v-list-item-content>
                    </v-list-item>


                    <v-row>
                      <v-col cols="12" lg="10" md="8" sm="6">
                        <v-combobox v-model="nuevosVendedores.supervisor" label="Supervisor" :items="ListaSupervisores"
                          @blur="validarSelecion" item-text="nombre" outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="user" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>


                    <v-row>
                      <v-col cols="8" lg="6" md="8" sm="8">
                        <v-btn color="green" @click.prevent="abrirModal" dark>Agregar Comisión por Cobros</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" lg="12" md="8" sm="8">
                        <!-----aqui me quede agregar un tabla aqui -->

                        <v-data-table :headers="[
                          { text: 'Envejecimiento', value: 'vencimiento', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Porcentaje en Cobro', value: 'porcientocomision', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'Eliminar', value: 'eliminar', sortable: false, class: ['no-gutters black--text'] }
                        ]" :items="ListaComisiones" :rules="[$rules.required]" class="elevation-1" dense>
                          <template v-slot:item.vencimiento="props">
                            <v-combobox :items="ListaEnvejecimiento" item-text="nombre" v-model="props.item.vencimiento"
                              @focus="cargarEnvejecimientos">
                            </v-combobox>
                          </template>
                          <template v-slot:item.eliminar>
                            <v-btn fab @click.prevent="borrarFilaTablaComision" x-small color="red darken-3"><i
                                style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>

                  </div>
                </v-card>






              </v-form>

            </div>

<!-- fn Formulario-Vendedores  -->

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <!-- <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>  -->
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'codigo', class: ['no-gutters black--text'] },
              { text: 'Nombre', value: 'nombre', class: ['no-gutters black--text'] },
              { text: 'Teléfono', value: 'telefono', class: ['no-gutters black--text'] },
              { text: 'Cod.Empleado', value: 'codigo2', class: ['no-gutters black--text'] },
              { text: 'Meta de Ventas', value: 'metadeventa', class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.metadeventa="props">
                <span>{{
                    currencyFormatter(props.item.metadeventa)
                }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn tb-data todo general -->


    <!------------------------------------- Modal-agregar Comisiones -->
    <v-dialog label="myAlert" v-model="dialog" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <!-- <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i> -->

          <v-toolbar-title style="color:white;" class="font-weight-light"> Comisiones </v-toolbar-title>

          <!-- <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i> -->

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="abrirModal">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <!--  
          <v-card-title>
            <span class="headline">Comisiones</span>
          </v-card-title> -->

        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-combobox :items="ListaEnvejecimiento" item-text="nombre" label="Vencimiento"
                hint="Elige la cantidad de dias para que se venzan " persistent-hint required outlined dense
                v-model="vencimiento.vencimiento" @focus="cargarEnvejecimientos" @blur="SelctCbEnvejecimientos">
                <template v-slot:append-outer>
                  <v-btn @click.prevent="abrirModal2" color="green" fab x-small dark>
                    <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                  </v-btn>
                </template>



                <template v-slot:prepend>
                  <vue-fontawesome icon="calendar" size="2" color="black"></vue-fontawesome>
                </template>

              </v-combobox>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <Autonumeric outlined dense label="Porciento de cobro*" required v-model="vencimiento.porcientocomision">
                <template v-slot:prepend>
                  <vue-fontawesome icon="percent" size="2" color="black"></vue-fontawesome>
                </template>
              </Autonumeric>
            </v-col>
          </v-row>

        </v-container>
        <hr />
        <!--   d-flex justify-end -->
        <v-btn-toggle rounded class="d-flex justify-end">

          <v-btn dark color="green darken-1" @click.prevent="agregarTablaComision(vencimiento)">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

        </v-btn-toggle>
        <hr />
        <!-- <v-card-actions>        </v-card-actions> -->

      </v-card>
    </v-dialog>
    <!------------------------------------- Modal-agregar Comisiones -->
    <!------------------------------------- Modal-mantenimiento de rango de Vencimiento -->
    <v-dialog label="myAlert" v-model="dialog1" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <!-- <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i> -->
          <v-toolbar-title style="color:white;" class="font-weight-light"> Rango de Vencimiento </v-toolbar-title>

          <!-- <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i> -->

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="abrirModal2">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <!-- <v-card-title>
          <span class="headline">Rango de Vencimiento</span>
          </v-card-title> -->

        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field v-model="nuevoRangoVencimiento.nombre" :rules="[$rules.required]" label="Descripcion"
                required outlined dense>
                <template v-slot:prepend>
                  <vue-fontawesome icon="font" size="2" color="black"></vue-fontawesome>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="nuevoRangoVencimiento.inicio" :rules="[$rules.required]" label="Desde" required
                outlined dense>
                <template v-slot:prepend>
                  <vue-fontawesome icon="calendar" size="2" color="black"></vue-fontawesome>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field v-model="nuevoRangoVencimiento.fin" :rules="[$rules.required]" label="Hasta" required
                outlined dense>
                <template v-slot:prepend>
                  <vue-fontawesome icon="calendar" size="2" color="black"></vue-fontawesome>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-card-text>

            <hr />
            <!--   d-flex justify-end -->
            <v-btn-toggle rounded class="d-flex justify-end">

              <v-btn dark color="green darken-1" @click.prevent="saveRangoVencimiento">
                <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
                Salvar
              </v-btn>

            </v-btn-toggle>
            <hr />

          </v-card-text>

        </v-container>



      </v-card>
    </v-dialog>
    <!------------------------------------- fin Modal-mantenimiento de rango de Vencimiento -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->



    <!-- ------------------------------------------------------ Modal-notificacion general-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarVendedor'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->




    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="60%" max-height="50%">
      <v-card>

        <v-toolbar flat color="indigo">

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="CerrarFiltro(1)">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>




          <v-row>


            <v-col cols="12" md="12">


              <!-- ----------------------------- nombre -->


              <v-text-field label="Nombre" autocomplete="off" dense outlined v-model="Modallfiltro.nombre">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>



              <!-------------------------------------------------- Sucursal -->
              <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal" item-text="descripcion"
                v-model="Modallfiltro.sucursal" @blur="SelctCbSucursal" @focus="cargarSucursal">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                </template>
              </v-combobox>






            </v-col>

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="">


            <v-btn color="#90A4AE" @click.prevent="CerrarFiltro(2)">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- ------------------------------------------------------ Modal- Importar datos-->
    <v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Enviar Reporte
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Enviar Reporte: </span> Importar
              Vendedor</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
                  <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
                </v-btn>



              </template>
              <span>CANCELAR: Retornar y no importar Datos</span>
            </v-tooltip>



          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Vendedor</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                      <v-form ref="formInputFileVen" v-model="valid" lazy-validation>
                        <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoVendedores"
                          id="excelVendedores" dense outlined show-size counter multiple label="Archivo de Vendedores"
                          accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">

                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn @click.prevent="SubirArchivoVendedores" v-on="on" color="blue" fab x-small dark>
                                  <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
                                </v-btn>

                              </template>
                              <span>Subir Archivo</span>
                            </v-tooltip>
                          </template>
                        </v-file-input>
                      </v-form>



                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Facturas -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Importar Facturas-->



    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>


</template>

<script>
import numeral from "numeral";
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";

//import BarraBusqueda2 from "../../components/BarraBusquedaDinamAceptaValoresNull";
import { EventBus } from "../../event-bus.js";
export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico
  },

  created() {
    var json = {
      titulo: "Vendedores",
      descripcion: "aqui se registran los vendedores de la empresa",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CXCVendedoresNew",
        "CXCVendedoresSave",
        "CXCVendedoresRemove",
        "CXCVendedoresAnular",
        "CXCVendedoresPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
// mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXCVendedoresNew", this.new);
    // EventBus.$on("CXCVendedoresSave", this.save);
    // EventBus.$on("CXCVendedoresRemove", this.remove);
    // EventBus.$on("CXCVendedoresAnular", this.anular);
    // EventBus.$on("CXCVendedoresPrintList", this.imprimirList);
    EventBus.$on("emitCXCVendedores", this.datostabla);
    this.cargarTodo();

    // EventBus.$on("onResize", this.onResize);
  },
// mounted-carga de inicio
  data: () => ({
    ///modales
    dialog: false,
    dialog1: false,

    dialogImportarDatos: false,
    ArchivoVendedores: null,

    dialogimprimirList: false,
    datosImprecion: {
      nombre: 'VENDEDORES',
      UrlImprimir: '/vendedores/Imprimir',
      UrlExel: '/vendedores/Excel',
      UrlCorreo: '/vendedores/Correo',
      secuencia: 1,
      documento: 0
    },


    windowSize: { width: "auto", height: "auto" },

    dialogFiltros: false,

    Modallfiltro: {
      sucursal: null,
      nombre: null
    },
    //Listados
    ListaVendedores: [],
    ListaSupervisores: [],
    ListaSucursales: [],
    ListaEnvejecimiento: [],
    ListaComisiones: [],
    RSucursal: [],
    ////////////////////////////////////
    vencimiento: {
      borrado: false,
      codigo: 0,
      porcientocomision: 0,
      porcientoventa: 0,
      vencimiento: null,
      borrado: false
    },
    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: true,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },

    //////////////////////////////
    nuevoRangoVencimiento: {
      // codigo: 0,
      empresa: {
        balanceIngreso: false,
        borrado: false,
        empresa: 1
      },
      fin: 0,
      inicio: 0,
      nombre: "",
      borrado: false
    },

    /// el objeto
    nuevosVendedores: {
      activo: true,
      borrado: false,
      cedula: "",
      codigo: 0,
      codigo2: 0,
      comisionproducto: 0,
      comisionservicio: 0,
      comisionventa: 0,
      direccion: "",
      empresa: 1,
      fechaCreacion: fechaNueva(),
      fechaModificacion: fechaNueva(),
      metadeventa: 0,
      nombre: "",
      porcientoventa: 0,
      sucursal: null,
      supervisor: null,
      telefono: "",
      usuarioCreacion: "",
      usuarioModificacion: ""
    },

    files: [],
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,



    headers: [{ text: "Vendedores", value: "nombre" }],

    slotlist: [
      // { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },

      { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
      {
        dt0: "sucursal",
        dt1: "descripcion",
        dt2: null,
        dt3: 1,
        dt4: "Sucursal:"
      }
    ],


    value: false,
    list: [],
    search: "",
    acti_tb_data: false,




    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },


    aalert: {
      estado: false,
      color: null
    },


  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
    // Comprobando si el valor de la selección es mayor que 0. Si lo es, está comprobando si el valor
    // de la selección es igual al valor de la entrada. Si es así, está mostrando una alerta. Si no es
    // así, está configurando el valor de la selección en una cadena vacía.
    validarSelecion() {
      if (this.nuevosVendedores.codigo > 0) {
        if (this.nuevosVendedores.codigo == this.nuevosVendedores.supervisor.codigo) {
          this.alerta("No puede seleccionar este supervisor.El vendedor y el supervisor no pueden ser iguales.", "error")
          this.nuevosVendedores.supervisor = '';
          return
        }
      }
    },
    // Emisión de un evento al EventBus.
    BuscarFiltros() {

      //var e=null
      //EventBus.$emit('BarraDinamLimitadaFiltro', e);

      //por aqui

      var e = {
        opcion: 1,
        Modallfiltro: this.Modallfiltro,
        url: this.$hostname + this.$hName + '/vendedores/findAllFiltro/'
      }

      EventBus.$emit('BarraDinamFiltro', e);





      this.dialogFiltros = false



    },


    // Un método que se llama cuando el usuario hace clic en el botón "Cerrar" del modal.
    CerrarFiltro(e) {


      this.Modallfiltro = {
        sucursal: null,
        nombre: null
      }


      if (e == 1) {

        this.dialogFiltros = false

      }

      if (e == 2) {

        var e = {
          opcion: e,
          Modallfiltro: this.Modallfiltro,
          url: this.$hostname + this.$hName + '/vendedores/findAllFiltro/',
          urlcount: null
        }

        EventBus.$emit('BarraDinamFiltro', e);


      }


    },

    // Llamar a un método que está en la instancia de Vue.
    cargarSucursal() {

      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', {
        usuario: JSON.parse(JSON.stringify(this.$user))
      })
        .then(res => (this.RSucursal = res.data))

    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {

      if (this.Modallfiltro.sucursal == null) {

        this.Modallfiltro.sucursal = ''
      }

      if (this.Modallfiltro.sucursal.codigo == undefined) {

        this.alerta("Seleccione una sucursal", "error");
        this.Modallfiltro.sucursal = ''
        return

      }
    },

    saveRangoVencimiento() {
      console.log(this.nuevoRangoVencimiento);
      this.nuevoRangoVencimiento.fin = parseInt(this.nuevoRangoVencimiento.fin);
      this.nuevoRangoVencimiento.inicio = parseInt(
        this.nuevoRangoVencimiento.inicio
      );
      this.abrirModal2();
      // this.nuevoRangoVencimiento.codigo = this.nuevoRangoVencimiento.codigo


      var jsn = {
        datos: JSON.parse(JSON.stringify(this.nuevoRangoVencimiento)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/configuracioncxc/salvarEnvejecimiento", this.nuevoRangoVencimiento,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien2"))
        .catch(error =>
          this.alerta(
            "Dato no guardo correctamente",
            "error"
          )
        );
    },
    agregarTablaComision(e) {
      var json = {
        borrado: false,
        codigo: 0,
        porcientocomision: e.porcientocomision,
        porcientoventa: e.porcientoventa,
        vencimiento: e.vencimiento
      };
      this.ListaComisiones.push(json);
      this.dialog = false;
    },
   // Eliminando la fila de la tabla.
    borrarFilaTablaComision(item) {
      const index = this.ListaComisiones.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.ListaComisiones.splice(index, 1);
    },
    abrirModal() {
      this.dialog = !this.dialog;
    },
    abrirModal2() {
      this.dialog1 = !this.dialog1;
    },
   // Abriendo un modal con una tabla de datos.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {

        this.filtro()
        // EventBus.$emit("actualizaBarraDinaicaLim");


      }


    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/vendedores/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaVendedores = res.data))
        .catch(error => console.log(error));

      this.ListaSupervisores = this.ListaVendedores;

      
      this.CargarSucursal()
      this.cargarEnvejecimientos();

    },


    async CargarSucursal(){
      await this.$axios
        .get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.ListaSucursales = res.data))
        .catch(error => console.log(error));
    },


    // Llamar a una API y obtener una lista de objetos.
    cargarEnvejecimientos() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/configuracioncxc/findAllEnvejecimiento",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaEnvejecimiento = res.data))
        .catch(error => console.log(error));
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbEnvejecimientos() {

      if (this.vencimiento.vencimiento == null) {
        this.vencimiento.vencimiento = "";
      }

      if (this.vencimiento.vencimiento.codigo == undefined) {
        this.alerta("Seleccione un vencimiento", "error");
        this.vencimiento.vencimiento = "";
        return;
      }
    },
   // Obteniendo los datos de la API y luego asignándolos a la variable ListaComisiones.
    datostabla(e) {
      // this.nuevosVendedores = e;
      this.nuevosVendedores = e;
      if (e.supervisor != undefined && e.supervisor != null) {
        this.nuevosVendedores.supervisor = e.supervisor;
      }

      var jsn = {
        datos: JSON.parse(JSON.stringify(e)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      this.ListaComisiones = [];
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/vendedores/findAllListComisiones/" + this.nuevosVendedores.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaComisiones = res.data))
        .catch(error => console.log(error));

      this.acti_tb_data = false
    },
   // Envío de datos al servidor.
    save() {
      if (this.$refs.form.validate()) {
        this.nuevosVendedores.vendedoresComisionesList = this.ListaComisiones;
        console.log(this.nuevosVendedores);

        console.log('Datos DatosGe')
        console.log(this.nuevosVendedores)

        var form = {

          vendedor: this.nuevosVendedores,
          vendedoresComisionesList: this.ListaComisiones,
          usuario: JSON.parse(JSON.stringify(this.$user))

        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/vendedores/save", this.nuevosVendedores,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta("Dato guardado correctamente!", "bien"), console.log('Objt objet'), console.log(res.data) })
          // .then(res => this.alerta("se guardo correctamente!", "bien"))
          .catch(error => {
            this.alerta(" Dato no guardado correctamente", "error")
          }
          );


      }
    },
    saveListadoComision() {
      this.nuevosVendedores2.vendedoresComisionesList = this.ListaComisiones;
      //  this.nuevosVendedores.vendedoresComisionesList = this.ListaComisiones;
      console.log("este es e; nuevo vendedores 2 ===========>");
      console.log(this.nuevosVendedores2);

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.nuevosVendedores2)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }


      this.$axios
        .post(
          this.$hostname + this.$hName + "/vendedores/saveComisiones", this.nuevosVendedores2,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta(
            "Dato no guardado correctamente ",
            "error"
          )
        );
    },

   // Crear un objeto de notificación y luego llamar a la función Exec_notif().
    NotifMessage() {

      if (this.nuevosVendedores.codigo != 0 && this.nuevosVendedores.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarVendedor"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un vendedor para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))


    },


    eliminar() {
      //  if(this.nuevosVendedores.codigo!=0){
      // this.nuevosVendedores.vendedoresComisionesList = this.ListaComisiones;
      console.log(this.nuevosVendedores);

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.nuevosVendedores)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      this.$axios
        .post(
          this.$hostname + this.$hName + "/vendedores/borrar", this.nuevosVendedores,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
        // .then(res => this.alerta("se guardo correctamente!", "bien"))
        .catch(error =>
          this.alerta(
            "Dato no  eliminado correctamente ",
            "error"
          )
        );
      // }
    },
    anular() { },
   // Muestra un modal con las opciones de imprecion
    imprimirList() {
      this.dialogimprimirList = true
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },




    // Creando un nuevo objeto y asignándolo a la variable nuevosVendedores.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false
      this.nuevosVendedores = {
        activo: true,
        borrado: false,
        cedula: "",
        codigo: 0,
        codigo2: "",
        comisionproducto: 0,
        comisionservicio: 0,
        comisionventa: 0,
        direccion: "",
        empresa: 0,
        fechaCreacion: fechaNueva(),
        fechaModificacion: fechaNueva(),
        metadeventa: 0,
        nombre: "",
        porcientoventa: 0,
        sucursal: null,
        supervisor: null,
        telefono: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }
      this.ListaComisiones = [];
      EventBus.$emit("actualizaBarraBusqueda2");
    },



   // Un método que se llama cuando el usuario hace clic en el botón y muestra un modal
   // para importar los datos del una lista de vendadores.
    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos
    },


    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },



    SubirArchivoVendedores() {

      if (this.$refs.formInputFileVen.validate()) {

      } else {
        this.alerta("Seleccione un archivo ", "error");
      }
    },

    SelctCbSucursal() {
      var _this = this;
      setTimeout(function () {
        if (_this.nuevosVendedores.sucursal == null) {
          _this.nuevosVendedores.sucursal = "";
        }

        if (!_this.nuevosVendedores.sucursal.hasOwnProperty('codigo')) {
          _this.nuevosVendedores.sucursal = "";
          _this.nuevosVendedores.sucursal = null;
          return;
        }
      }, 300);
    },


    alerta(e, l) {

    

      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          this.ejecucion = false;

      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
   // Un método que se llama cuando el usuario hace clic en un botón y muestra un modal
   // con una tabla de datos.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    // Hacer una solicitud al servidor y devolver los datos a la variable de lista.
    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/vendedores/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    }
  },
  computed: {

    ActDescBtn() {



      if (this.nuevosVendedores.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }


        // var objjct = {
        //     save: true,
        //     eliminar: true,
        //     anular: false,
        //     nuevo: true,
        //     imprimir: true,
        //     vercuenta:false, 
        //   };

      }


      if (this.nuevosVendedores.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }




        // var objjct = {
        //       save: true,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false, 
        //     };


      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }







};
</script>

<style>

</style>
