<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
       <!-- ContenidoBase-Sucursales  -->
      <tr max-height="48px" height="48px">

        <ContenidoBase :titulo="'Sucursales'" :descripcion="'Creación de las distintas Sucursales'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
 <!-- fn ContenidoBase-Sucursales  -->

      <tr>
        <!-- BarraPrimaria-Sucursales   --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>

                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/sucursal/findallTodo/'"
                  :nombre="'Sucursales'" :emitnombre="'emitsucursal'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Nombre', value: 'descripcion' },
                    { text: 'Teléfono', value: 'telefono' },
                    { text: 'Meta de venta', value: 'metadeventas' },
                    { text: 'Ciudad', value: 'ciudad' },
                    { text: 'Dirección', value: 'direccion' },
                    { text: 'Estatus:', value: 'activada' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Nombre', NomValue: 'descripcion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Teléfono', NomValue: 'telefono', Ctsl: 1, Type: 0, State: true },
  { Text: 'Meta de venta', NomValue: 'metadeventas', Ctsl: 1, Type: 2, State: true },
  { Text: 'Ciudad', NomValue: 'ciudad', Ctsl: 1, Type: 0, State: true },
  { Text: 'Dirección', NomValue: 'direccion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.1, State: true }
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/sucursal/findall'"
              :nombre="'Sucursales'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitsucursal'"
              :SeachNombre="'Búsqueda por Nombre '"
              :slotlist="slotlist"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Sucursales   ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <div class="px-2">
               <!-- Formulario-Sucursales -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <input type="number" v-model="sucursal.codigo" style="display:none;" />
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="sucursal.descripcion" :rules="[$rules.required]" label="* Nombres:"
                      autocomplete="off" required outlined dense>
                      <template v-slot:prepend>
                        <vue-fontawesome icon="male" size="2" color="black"></vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field v-model="sucursal.codigo" :disabled="true" label="Codigo" required outlined dense>
                      <template v-slot:prepend>
                        <vue-fontawesome icon="list-ol" size="2" color="black"></vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field label="teléfono:" dense outlined autocomplete="off" maxlength="13"
                      v-mask="'(###)###-####'" :rules="[$rules.required, $rules.RulesTel]" v-model="sucursal.telefono">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-phone"></i>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <!-- hsdgfhg -->

                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="sucursal.dncd" autocomplete="off" label="DNCD" required outlined dense>
                      <template v-slot:prepend>
                        <vue-fontawesome icon="book" size="2" color="black"></vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <AutoNumeric v-model="sucursal.metadeventas" label="* Meta Ventas:" :rules="[$rules.required]"
                      required outlined dense>
                      <template v-slot:prepend>
                        <vue-fontawesome icon="arrow-up" size="2" color="black"></vue-fontawesome>
                      </template>
                    </AutoNumeric>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field v-model="sucursal.ciudad" label="Ciudad:" autocomplete="off"
                      :rules="[$rules.required]" outlined dense>
                      <template v-slot:prepend>
                        <vue-fontawesome icon=" building" size="2" color="black">
                        </vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-textarea v-model="sucursal.direccion" label="* Direccion:" :rules="[$rules.required]"
                      autocomplete="off" required outlined dense>
                      <template v-slot:prepend>
                        <vue-fontawesome icon="location-arrow" size="2" color="black"></vue-fontawesome>
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>

                <!-- fn hsdgfhg -->

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="almacenSelected" label="Almacén:" required outlined dense autocomplete="off"
                      :items="Ralmacen" item-text="descripcion" @blur="SelctCbAlmacen" @focus="CargarRalmacen">
                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/warehouse.png"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentofacturacion" label="Documento por defecto de Factura:" required
                      outlined dense autocomplete="off" :items="Rdocumentofactura" item-text="descripcion"
                      @blur="SelctCbDfact" @focus="CargarRdocumentofactura">
                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentofacturacredito" label="Documento por defecto de Factura a Credito:"
                      required outlined dense autocomplete="off" :items="RdocumentofacturaCredito"
                      item-text="descripcion" @blur="SelectCbDfactCred" @focus="CargarRdocumentofacturaCredito">
                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentorecibo" label="Documento por defecto de Recibo de Ingresos:" required
                      outlined dense autocomplete="off" :items="RdocumentoCxCrecibo" item-text="descripcion"
                      @blur="SelectCbDrecibo" @focus="CargarRdocumentoCxCrecibo">
                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentoOtrosIngreso" label="Documento por Defecto de Otros Ingresos:"
                      required outlined dense autocomplete="off" :items="RdocumentoCxCotrosIngresos"
                      item-text="descripcion" @blur="SelectCbOtroIng" @focus="CargarRdocumentoCxCotrosIngresos">

                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentoNotaCredito" label="Documento por defecto de Nota de Credito:"
                      required outlined dense autocomplete="off" :items="RdocumentoCxCcredito" item-text="descripcion"
                      @blur="SelectCbNotaC" @focus="CargarRdocumentoCxCcredito">
                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentoDevolucion" label="Documento por defecto por Devolucion:" required
                      outlined dense autocomplete="off" :items="RDocumentoDevoluc" item-text="descripcion"
                      @blur="SelectCbDocDevoluc" @focus="CargarRDocumentoDevoluc">



                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentoEntrada"
                      label="Documento por defecto de Entrada de Inventario Fisico:" required outlined dense
                      autocomplete="off" :items="RDocumentoEntrada" item-text="descripcion" @blur="SelctCbDocEntrada"
                      @focus="CargarRDocumentoEntrada">

                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentoSalida" label="Documento por Defecto de Salida de Inventario Fisico:"
                      required outlined dense autocomplete="off" :items="RDocumentoSalida" item-text="descripcion"
                      @blur="SelctCbDocSalida" @focus="CargarRDocumentoSalida">


                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="9">
                    <v-combobox v-model="documentoConduce" label="Documento para Coduce:" required outlined dense
                      autocomplete="off" :items="RDocumentoConduce" item-text="descripcion" @blur="SelctCbDocConduce"
                      @focus="CargarRDocumentoConduce">
                      <template v-slot:prepend>
                        <v-img height="30px" width="30px" src="../../assets/scroll.svg"></v-img>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4">
                    <v-switch v-model="sucursal.activada" label="Activo" />
                  </v-col>
                </v-row>
              </v-form>
              <!-- fn Formulario-Sucursales -->

            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <!--  <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip> -->
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', width: 130, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Nombre', value: 'descripcion', width: 200, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Teléfono', value: 'telefono', width: 130, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Meta de venta', value: 'metadeventas', width: 130, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Ciudad', value: 'ciudad', width: 180, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Dirección', value: 'direccion', width: 280, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Almacén', value: 'almacen.descripcion', width: 200, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento por Factura', value: 'documentofacturacion.descripcion', width: 150, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento por Factura a Crédito', value: 'documentofacturacredito.descripcion', width: 150, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento por Recibo de Ingreso', value: 'documentorecibo.descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento por Otros Ingresos', value: 'otrosIngresos.descripcion', width: 150, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento por Nota de Crédito', value: 'documentoNotaCredito.descripcion', width: 290, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento por Devolución', value: 'documentoDevolucion.descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento de Entrada de Inventario', value: 'documentoEntrada.descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento de Salida de Inventario', value: 'documentoSalida.descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Conduce', value: 'documentoConduce.descripcion', width: 150, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estatus:', value: 'activada', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">
              <template v-slot:item.metadeventas="props">
                <span>{{
                    currencyFormatter(props.item.metadeventas)
                }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == true">Activo</span>
                <span v-if="props.item.activada == false">Desactivo</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarSucursal'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general  -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase

  },

  directives: {
    mask
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitsucursal", this.datostabla);

    // EventBus.$on("sucursalNew", this.new);
    // EventBus.$on("sucursalSave", this.save);
    // EventBus.$on("sucursalRemove", this.remove);
    // EventBus.$on("sucursalAnular", this.anular);
    // EventBus.$on("sucursalPrintList", this.imprimirList);

    this.cargar();
    this.filtro();
  },
  created() {
    var json = {
      titulo: "Sucursales",
      descripcion: "Creacion de las distintas Sucursales",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "sucursalNew",
        "sucursalSave",
        "sucursalRemove",
        "sucursalAnular",
        "sucursalPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      Ralmacen: [],
      Rdocumentofactura: [],
      RdocumentoCxC: [],
      RDocumentoInv: [],
      RdocumentofacturaCredito: [],
      RdocumentoCxCrecibo: [],
      RdocumentoCxCotrosIngresos: [],
      RdocumentoCxCcredito: [],
      RDocumentoDevoluc: [],
      RDocumentoEntrada: [],
      RDocumentoSalida: [],
      RDocumentoConduce: [],

      Rrutas: [
        { codigo: 0, desc: "1" },
        { codigo: 1, desc: "2" },
        { codigo: 2, desc: "3" },
        { codigo: 3, desc: "4" },
        { codigo: 5, desc: "5" }
      ],
      valid: true,

      sucursal: {
        codigo: 0,
        descripcion: null,
        metadeventas: 0,
        telefono: '',
        direccion: null,
        Scodigo: "",
        dncd: "",
        ciudad: "",
        almacen: 0,
        documentofacturacion: 0,
        documentofacturacredito: 0,
        documentorecibo: 0,
        otrosIngresos: 0,
        documentoNotaCredito: 0,
        documentoDevolucion: 0,
        documentoEntrada: 0,
        documentoSalida: 0,
        documentoConduce: 0,
        activada: true,
        borrado: false
      },
      documentofacturacion: null,
      documentofacturacredito: null,
      documentorecibo: null,
      documentoOtrosIngreso: null,
      documentoNotaCredito: null,
      documentoDevolucion: null,
      documentoEntrada: null,
      documentoSalida: null,
      documentoConduce: null,
      almacenSelected: null,
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: true,
        anular: true,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      headers: [{ text: "Nombre", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "descripcion", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        { dt0: "telefono", dt1: null, dt2: null, dt3: 1, dt4: "Teléfono:" },
        {
          dt0: "metadeventas",
          dt1: null,
          dt2: null,
          dt3: 5,
          dt4: "Meta de venta:"
        },
        { dt0: "direccion", dt1: null, dt2: null, dt3: 1, dt4: "Dirección:" },
        { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },

      ],

      // "activada": false,
      // " dncd"
      // "ciudad": "Santiago",
      // "descripcion": "FARMACIA BIBBIA",
      // "direccion": "Santiago, Republica Dominicana",
      // "codigo": 1,
      // "metadeventas": 1500,
      // "telefono": "(809) 582-0000"

      // "almacen": {},

      //"documentoDevolucion": { },

      //"documentoEntrada": { },

      // "documentoNotaCredito": { },

      // "documentoSalida": { },

      // "documentofacturacion": { },

      //"documentofacturacredito": { },

      //  "documentorecibo": { },

      // "otrosIngresos": {},



      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocConduce() {
      if (this.documentoConduce == null) {
        this.documentoConduce = "";
      }
      if (this.documentoConduce.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentoConduce = "";
        return;
      }
    },

    // Comprobando si el documentoSalida es nulo o indefinido.
    SelctCbDocSalida() {
      if (this.documentoSalida == null) {
        this.documentoSalida = "";
      }
      if (this.documentoSalida.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentoSalida = "";
        return;
      }
    },
   // Comprobando si el documentoEntrada es nulo o indefinido.
    SelctCbDocEntrada() {
      if (this.documentoEntrada == null) {
        this.documentoEntrada = "";
      }
      if (this.documentoEntrada.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentoEntrada = "";
        return;
      }
    },

    // Comprobando si el documentoDevolucion es nulo o indefinido.
    SelectCbDocDevoluc() {
      if (this.documentoDevolucion == null) {
        this.documentoDevolucion = "";
      }
      if (this.documentoDevolucion.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentoDevolucion = "";
        return;
      }
    },
   // Comprobando si el valor de la selección no está definido.
    SelectCbNotaC() {
      if (this.documentoNotaCredito == null) {
        this.documentoNotaCredito = "";
      }

      if (this.documentoNotaCredito.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentoNotaCredito = "";
        return;
      }
      //
    },
    // Comprobando si el valor de la selección no está definido.
    SelectCbOtroIng() {
      if (this.otrosIngresos == null) {
        this.otrosIngresos = "";
      }

      if (this.otrosIngresos.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.otrosIngresos = "";
        return;
      }
      //
    },
    // Comprobando si el documentorecibo es nulo o indefinido.
    SelectCbDrecibo() {
      if (this.documentorecibo == null) {
        this.documentorecibo = "";
      }
      if (this.documentorecibo.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentorecibo = "";
        return;
      }
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelectCbDfactCred() {
      if (this.documentofacturacredito == null) {
        this.documentofacturacredito = "";
      }
      if (this.documentofacturacredito.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentofacturacredito = "";
        return;
      }
      //
    },

   // Comprobando si la documentofacturacion es nula o indefinida.
    SelctCbDfact() {
      if (this.documentofacturacion == null) {
        this.documentofacturacion = "";
      }
      if (this.documentofacturacion.codigo == undefined) {
        this.alerta("Seleccione un Documento", "error");
        this.documentofacturacion = "";
        return;
      }
      //
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbAlmacen() {
      if (this.almacen == null) {
        this.almacen = "";
      }
      if (this.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.almacen = "";
        return;
      }
    },
    // Realizar una solicitud GET al servidor y devolver los datos.
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },
    // Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
    CargarRdocumentofactura() {
      this.$axios.get(this.$hostname + this.$hName + "/documentoventas/documentoFacturaVentas/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rdocumentofactura = res.data))
        .catch(error => console.log(error));
    },
    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable
    // RdocumentofacturaCredito.
    CargarRdocumentofacturaCredito() {
      this.$axios
        .get(this.$hostname + this.$hName +
          "/documentoventas/documentoFacturaVentas",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.RdocumentofacturaCredito = res.data;
        });
    },
    //Llamar a una API y obtener los datos de la API y almacenarlos en la variable
    // RdocumentoCxCrecibo.
    CargarRdocumentoCxCrecibo() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentocxc/findallReciboIngreso",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.RdocumentoCxCrecibo = res.data;
        });
    },
    // Hacer una solicitud GET al servidor y devolver los datos de los documentos otros ingreso.
    CargarRdocumentoCxCotrosIngresos() {
      this.$axios.get(
        this.$hostname + this.$hName +
        "/documentocxc/findallOtrosIngreso",
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.RdocumentoCxCotrosIngresos = res.data;
        });
    },
   // Hacer una solicitud GET al servidor y devolver los datos de los documentos nota de credito.
   
    CargarRdocumentoCxCcredito() {
      this.$axios
        .get(this.$hostname + this.$hName +
          "/documentocxc/findallCXCNotaCredito",
          { headers: this.$store.getters.GetheadersAxios }
        ).then(res => {
          this.RdocumentoCxCcredito = res.data;
        });
    },
   // Hacer una solicitud al servidor y obtener los datos del servidor.
    CargarRDocumentoDevoluc() {
      this.$axios.get(
        this.$hostname + this.$hName +
        "/documento-inv/devolucion",
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.RDocumentoDevoluc = res.data;
        });
    },
   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    CargarRDocumentoEntrada() {
      this.$axios.get(
        this.$hostname + this.$hName +
        "/documento-inv/entradas",
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.RDocumentoEntrada = res.data;
        });
    },
   // Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
    CargarRDocumentoSalida() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documento-inv/salida",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.RDocumentoSalida = res.data;
        });
    },
    // Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
    CargarRDocumentoConduce() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documento-inv/conduce",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.RDocumentoConduce = res.data;
        });
    },
    cargar() {
      this.CargarRalmacen()
      this.CargarRdocumentofactura()
      this.CargarRdocumentoCxCrecibo()
      this.CargarRdocumentoCxCotrosIngresos()
      this.CargarRDocumentoDevoluc()
      this.CargarRDocumentoEntrada()
      this.CargarRDocumentoSalida()
      this.CargarRDocumentoConduce()
      this.CargarRdocumentoCxCotrosIngresos()
      this.CargarRdocumentofacturaCredito()
      this.CargarRdocumentoCxCcredito()
    },
    currencyFormatter(params) {
      return this.formatNumber(params);
    },
    formatNumber(number) {
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
   // Abrir una tabla , muestra y filtrar los datos.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }
    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
      }
    },


    // Crear un nuevo objeto y asignarle valores.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.sucursal = {
        codigo: 0,
        descripcion: null,
        metadeventas: 0,
        telefono: '',
        direccion: null,
        Scodigo: "",
        dncd: "",
        ciudad: "",
        almacen: 0,
        documentofacturacion: 0,
        documentofacturacredito: 0,
        documentorecibo: 0,
        otrosIngresos: 0,
        documentoNotaCredito: 0,
        documentoDevolucion: 0,
        documentoEntrada: 0,
        documentoSalida: 0,
        documentoConduce: 0,
        activada: true,
        borrado: false
      },
        this.documentofacturacion = null
      this.documentofacturacredito = null
      this.documentorecibo = null
      this.documentoOtrosIngreso = null
      this.documentoNotaCredito = null
      this.documentoDevolucion = null
      this.documentoEntrada = null
      this.documentoSalida = null
      this.documentoConduce = null
      this.almacenSelected = null
      this.ejecucion = true
      EventBus.$emit("actualizaBarraBusqueda2")
    },

    // Guardando los datos en la base de datos.
    save() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;

          if (this.documentofacturacredito != null) { this.sucursal.documentofacturacredito = this.documentofacturacredito.codigo }
          if (this.documentofacturacion != null) { this.sucursal.documentofacturacion = this.documentofacturacion.codigo }
          if (this.documentorecibo != null) { this.sucursal.documentorecibo = this.documentorecibo.codigo }
          if (this.documentoOtrosIngreso != null) { this.sucursal.otrosIngresos = this.documentoOtrosIngreso.codigo }
          if (this.documentoNotaCredito != null) { this.sucursal.documentoNotaCredito = this.documentoNotaCredito.codigo }
          if (this.documentoDevolucion != null) { this.sucursal.documentoDevolucion = this.documentoDevolucion.codigo }
          if (this.documentoEntrada != null) { this.sucursal.documentoEntrada = this.documentoEntrada.codigo }
          if (this.documentoSalida != null) { this.sucursal.documentoSalida = this.documentoSalida.codigo }
          if (this.documentoConduce != null) { this.sucursal.documentoConduce = this.documentoConduce.codigo }
          if (this.almacenSelected != null) { this.sucursal.almacen = this.almacenSelected.codigo }

          this.$axios.post(
            this.$hostname + this.$hName + "/sucursal/save", this.sucursal,
            { headers: this.$store.getters.GetheadersAxios }
          ).then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => {
              if (error.response) {
                this.alerta(error.response.request.responseText, "error")
              } else {
                this.alerta(error, "error")
              }
            });

        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },




    // Crear un objeto de notificación y luego llamar a la función Exec_notif().
    NotifMessage() {

      if (this.sucursal.codigo != 0 && this.sucursal.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarSucursal"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una Sucursal para eliminar", "error") }


    },

    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))
    },
   // Hacer una solicitud al servidor para borrar la sucursal seleccionada.
    eliminar() {
      if (this.sucursal.codigo != 0 && this.sucursal.codigo != null) {
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/sucursal/borrado", this.sucursal,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato eliminado correctamente", "bien"))
            .catch(error => {
              if (error.response) {
                this.alerta(error.response.request.responseText, "error")
              } else {
                this.alerta(error, "error")
              }
            });
        }
      }
    },

    anular() {


    },
    // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/sucursal/imprimir/", { headers: headers }).then(res => {
       /* let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/
          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'sucursal');

      });


    },
    // Tomando los datos de la tabla y poniéndolos en el formulario.
    datostabla(e) {

      console.log(e)
      var sucursal = JSON.parse(JSON.stringify(e))

      if (e.telefono!=null) {
        sucursal.telefono = JSON.parse(JSON.stringify(e.telefono.trim()))
      }
      
      this.sucursal = sucursal;
      if (e.documentofacturacion != null) {
        this.documentofacturacion = this.Rdocumentofactura.filter(r => r.codigo == e.documentofacturacion)[0]
      }
      if (e.documentofacturacredito != null) {
        this.documentofacturacredito = this.RdocumentofacturaCredito.filter(r => r.codigo == e.documentofacturacredito)[0]
      }
      if (e.documentorecibo != null) {
        this.documentorecibo = this.RdocumentoCxCrecibo.filter(r => r.codigo == e.documentorecibo)[0]
      }
      if (e.otrosIngresos != null) {
        this.documentoOtrosIngreso = this.RdocumentoCxCotrosIngresos.filter(r => r.codigo == e.otrosIngresos)[0]
      }
      if (e.documentoNotaCredito != null) {
        this.documentoNotaCredito = this.RdocumentoCxCcredito.filter(r => r.codigo == e.documentoNotaCredito)[0]
      }
      if (e.documentoDevolucion != null) {
        this.documentoDevolucion = this.RDocumentoDevoluc.filter(r => r.codigo == e.documentoDevolucion)[0]
      }
      if (e.documentoEntrada != null) {
        this.documentoEntrada = this.RDocumentoEntrada.filter(r => r.codigo == e.documentoEntrada)[0]
      }
      if (e.documentoSalida != null) {
        this.documentoSalida = this.RDocumentoSalida.filter(r => r.codigo == e.documentoSalida)[0]
      }
      if (e.documentoConduce != null) {
        this.documentoConduce = this.RDocumentoConduce.filter(r => r.codigo == e.documentoConduce)[0]
      }
      this.almacenSelected = this.Ralmacen.filter(r => r.codigo == e.almacen)[0]
      this.acti_tb_data = false;
    },
   // Un método que se llama cuando el usuario hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },
  // Hacer una solicitud al servidor y devolver los datos a la variable de lista.
    async filtro() {
      var headers = this.$store.getters.GetheadersAxios
      await this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallTodo/", { headers: headers })
        .then(res => { this.list = res.data; });
    }
  },


  computed: {

    ActDescBtn() {


      if (this.sucursal.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }


        var objjct = {
          save: true,
          eliminar: true,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };

      }


      if (this.sucursal.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        var objjct = {
          save: true,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };


      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }


  }



};
</script>

<style>
</style>
