<template>

<div>
<table  style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
<tr max-height="48px" height="48px"  >
<ContenidoBase 
  :titulo="'Abordaje de Bus'"
  :descripcion="'se leen los ticket para abordar el bus'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
    
    <tr>
<!-- BarraPrimaria 96 --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                 
            </center>
<!-- <BarraDinamicaNormal
              :url="$hostname + $hName + '/services/tipocliente/findalltipoclientes'"
              :nombre="'Tipo de Clientes'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            /> -->

          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->

<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
<v-app id="inspire" >
  <div  @click="ver"> 
<!--style="display:none;"  -->       
      <v-row>
        <!-- fila 1 ------------------------------>
        <v-col cols="12" md="12">
          <v-row :align="'center'" :justify="'center'">
            <!-- <i style="font-size: 20px;" class="fa fa-font">ok</i> -->
          
              <img @load="ver" height="100%"
                src="../../assets/iconos_micromarket/imagenMM.gif"
                alt="micromarket"
              />
         
            
          </v-row>
          
        </v-col>
        <!-- fn fila 1 ------------------------------>

        <!-- fila 2 ------------------------------>
<v-col cols="12" md="12">
          <v-row :align="'center'" :justify="'center'">
            <b><i style=" font-size: 35px;">{{usuario}}</i></b>
          </v-row>
        </v-col>

        <v-col style="margin-top: -3.5%; " cols="12" md="12">
          <v-row :align="'center'" :justify="'center'">
            <b><i style="font-size: 50px;">{{descripcion}}</i></b>
          </v-row>
        </v-col> 
        <!-- fn fila2 -------------------------->

        <!-- fila 2 ------------------------------>
        <v-col style="margin-top: -3.5%; " cols="12" md="12">
          <v-row :align="'center'" :justify="'center'">
            <b style="font-size: 50px;">RD$.{{ currencyFormatter(precio) }}</b>
          </v-row>
        </v-col>
        <!-- fn fila2        -------------------------->
      </v-row>

      <center>
        <!-- <v-form ref="form" v-model="valid" lazy-validation> </v-form>  -->
          <v-text-field
          style="font-size: 0px;" 
          :autofocus="true"
            id="codigo"
            color="white"
                  type="number"
                  v-model="producto.codigo"
                  @blur="ver"
                  @change="ejecutar"
              >
              </v-text-field>
              
                </center>
    
  </div>
</v-app>
  </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


 </tr>
  </table>

  </div>
</template>

<script>
//  var valor = '';
//             $(document).keydown(function (e) {
//                 valor += e.key;
//                 if (e.keyCode === 13) {
//                     valor.substring(0, valor.length - 5);
//                     leerPro(valor);
//                     valor = '';
//                 }
//             });
//             leerPro = function (valor) {
//                 console.log(valor.substring(0, valor.length - 5));
//                 leer_pro(valor.substring(0, valor.length - 5));
//             };
//             window.onload = function () {
//                 function foo() {
//                     alert("test");
//                 }
//                 setInterval(foo, 500);

import VueAutonumeric from "../../components/VueAutonumeric";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    VueAutonumeric,
    ContenidoBase
    
  },
  created() {
    var json = {
      titulo: "Abordaje de Bus",
      descripcion: "se leen los ticket para abordar el bus",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  mounted() {
     EventBus.$on("onResize", this.onResize);
  },

 
data() {
    return {
    


      valid: true,
windowSize:{width:"auto", height:"auto"},
BarraTablaDinamicaAprir:false,
BarraSecundariaAprir:false,

      Rdata:[],
      usuario:'FARMACIA BIBBIA',
      precio:0,
      descripcion:'',

      producto:{ 

        codigo:'',
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      }
    };
  },

  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},


ejecutar(){



 console.log(this.producto.codigo)
var codigo=this.producto.codigo


this.$axios.get(this.$hostname+this.$hName+'/services/producto/'+this.producto.codigo+"/"+false+"/buscar")
.then(res=>(this.precio=res.data[0].precio, this.descripcion=res.data[0].productos.descripcion))


//alert(codigo) this.Rdata=res.data, console.log(this.Rdata) ,30000
this.producto.codigo="";


setTimeout(this.limpiar, 8000); 


},


limpiar(){

      this.precio=0
      this.descripcion=''
  
},

abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
ver(){

//console.log('ok')
document.getElementById("codigo").focus();

},

   currencyFormatter(params) {
      //return this.formatNumber(params) + ".00";
      return this.formatNumber(params);
    },

    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


return number.toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    },

    save() {},
    remove() {},
    anular() {},
    imprimirList() {},
    newwnuevo() {}
  }
 
};
</script>

<style></style>
