<template>
 <div >{{ActDescBtn}}
 <table border="1" style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'FormularioBase'"
  :descripcion="'aqui se registran los diferentes Tipos de Clientes'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="remove"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                 
            </center>





      

<BarraDinamicaNormal
              :url="$hostname + $hName + '/services/tipocliente/findalltipoclientes'"
              :nombre="'Tipo de Clientes'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            />





            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app  id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <v-container>
   
 <v-card
 color="grey lighten-3"
  >
  
  <v-container>
    
  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" lg="12" md="8" sm="6">
                  <v-text-field
                    v-model="nuevoTiposClientes.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Descripcion"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <vue-fontawesome
                        icon="edit"
                        size="2"
                        color="black"
                      ></vue-fontawesome>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-switch
                    label="Activo"
                    v-model="nuevoTiposClientes.activo"
                  ></v-switch>
                </v-col>
              </v-row>

              <!-- <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  Archivo de Tipo de Clientes
               </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <v-file-input
                    v-model="files"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row> -->
            </v-form>      
  </v-container>
  </v-card>   
       </v-container>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>
<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../components/BarraDinamicaNormal";
import ContenidoBase from "../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Tipos de Clientes",
      descripcion: "aqui se registran los diferentes Tipos de Clientes",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CXCTiposClientesNew",
        "CXCTiposClientesSave",
        "CXCTiposClientesRemove",
        "CXCTiposClientesAnular",
        "CXCTiposClientesPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("CXCTiposClientesNew", this.new);
    EventBus.$on("CXCTiposClientesSave", this.save);
    EventBus.$on("CXCTiposClientesRemove", this.remove);
    EventBus.$on("CXCTiposClientesAnular", this.remove);
    EventBus.$on("CXCTiposClientesPrintList", this.imprimirList);
    EventBus.$on("emitCXCTiposClientes", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

 data:()=> ({
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},


      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


      nuevoTiposClientes: {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        //fechaCreacion: new,
      //  fechaModificacion: "",
        usuarioCreacion: "Admin",
        usuarioModificacion: "",
        empresa:1
      },

      files: [],
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
 

veer(e){


console.log('imprimiendo la pamara')
console.log(e)


},


onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.nuevoTiposClientes = e;
      this.acti_tb_data=false
    },
    save() {

console.log('Save')


if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.nuevoTiposClientes)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}
    },
    remove() {

     console.log('newRemove') 

if(this.nuevoTiposClientes.codigo!=0 && this.nuevoTiposClientes.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.nuevoTiposClientes)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.nuevoTiposClientes.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }




      // if (this.Elim_notif.estado) {
      // this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/tipocliente/removeTipoCliente",
      //     this.nuevoTiposClientes
      //   )
      //   .then(res => this.alerta("se hizo correctamente!", "bien"))
      //   .catch(error =>
      //     this.alerta(
      //       "hubo un error , por favor verifique el campo descripcion!",
      //       "error"
      //     )
      //   );
      // }
    },
    anular() {

      console.log('newanular')
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.nuevoTiposClientes)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {
console.log('newimprimirList')


    },


    newnuevo() {

console.log('newnuevo')
  
      this.nuevoTiposClientes = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }

        EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },


computed:{

    ActDescBtn(){


if(this.nuevoTiposClientes.codigo>0){
 this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}


if(this.nuevoTiposClientes.codigo<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}
     
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }


};
</script>

<style></style>
