<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Registro de Faltas'"
  :descripcion="'Para realizar los registros de faltas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
               
              
            </center>

            <BarraDinamicaNormal
              :url="$hostname + $hName + '/reporteschoferes/findall/'"
              :nombre="'Faltas'"
              :emitnombre="'emitTranspRegistroFaltas'"
              :SeachNombre="'Búsqueda general'"


              :headers="[
        { text: 'Código', value: 'id'},
        { text: 'Descripción', value: 'descripcion'},
        { text: 'Categoría', value: 'idCategoria.nombre'},
        { text: 'Usuario_Registro', value: 'idusuarioRegistra'},
    
       
           ]"
              :ContListSlot="[

              {Text:'', NomValue:'fotoVehiculo', Ctsl:1, Type:3, State:true},
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Categoría', NomValue:'idCategoria', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Usuario_Registro', NomValue:'idusuarioRegistra', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ]"
            />


            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitTranspRegistroFaltas'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
 <!-- <v-container> -->

  
  <v-container>

<v-form ref="formRegistroFaltas" @submit.prevent=""  v-model="valid" lazy-validation>

<v-card color="grey lighten-3">
<v-container><!-- style="border: 1px solid #000000;" -->
    
           
                <v-col cols="12" md="12" sm="12">


  <v-radio-group v-model="RegistroFaltas.idCategoria.id" row @change="ChangeIdCategoria">
<!-- radio 1 -->
 <v-col cols="12" md="12" sm="12" >
   <v-row>

<!--  opcion 1 -->
    <v-col cols="12" md="4" sm="4"> 

        <v-radio label="ACCIDENTES"  color="red darken-3" :value="1">
 <template v-slot:label>
            <strong id="radio-label" style="color:#000000;">ACCIDENTES</strong>
            </template> 
          
        </v-radio>

    <v-avatar size="45">
      <v-img 
      src="@/assets/faltas-iconos/accidente.png" 
      aspect-ratio="1"
      class="grey lighten-2"
      max-width="300"
      max-height="300"
    ></v-img>
</v-avatar>


</v-col>

 
<!--  opcion 2 -->
<v-col cols="12" md="4" sm="4">
 

        <v-radio label="CONDUCCION"  color="red darken-3" :value="2">
 <template v-slot:label>
            <strong id="radio-label" style="color:#000000;">CONDUCCION</strong>
            </template> 


        </v-radio>

        <v-avatar size="45">
    
      <v-img 
     src="@/assets/faltas-iconos/conduccion.png" 
      aspect-ratio="1"
      class="grey lighten-2"
      max-width="300"
      max-height="300"
    ></v-img>
</v-avatar>

</v-col >

<!--  opcion 3 -->
<v-col cols="12" md="4" sm="4">
 

        <v-radio label="VESTIMENTA / UNIFORME"  color="red darken-3" :value="3">
        <template v-slot:label>
            <strong  id="radio-label" style="color:#000000;">VESTIMENTA / UNIFORME</strong>
            </template> 
        
        </v-radio>

        <v-avatar size="45">

      <v-img 
      src="@/assets/faltas-iconos/uniforme.png" 
      aspect-ratio="1"
      class="grey lighten-2"
      max-width="300"
      max-height="300"
    ></v-img>
</v-avatar>


</v-col>
<!--  opcion 4 -->
<v-col cols="12" md="4" sm="4">    


       <v-radio label="CONFLICTOS CON OTROS EMPLEADOS"  color="red darken-3" :value="4">
<template v-slot:label>
            <strong id="radio-label" style="color:#000000;">CONFLICTOS CON OTROS EMPLEADOS</strong>
            </template> 

       </v-radio>

        <v-avatar size="45">
    
      <v-img 
     src="@/assets/faltas-iconos/otroempleado.png" 
      aspect-ratio="1"
      class="grey lighten-2"
      max-width="300"
      max-height="300"
    ></v-img>
</v-avatar>


</v-col>

<!--  opcion 5 -->
<v-col cols="12" md="4" sm="4">
       <v-radio label="CONFLICTOS CON PASAJEROS"  color="red darken-3" :value="5">
          <template v-slot:label>
            <strong id="radio-label" style="color:#000000;">CONFLICTOS CON PASAJEROS</strong>
            </template> 
       </v-radio>

        <v-avatar size="45">
    
      <v-img 
      src="@/assets/faltas-iconos/pasajero.png" 
      aspect-ratio="1"
      class="grey lighten-2"
      max-width="300"
      max-height="300"
    ></v-img>
</v-avatar>

</v-col>

<!--  opcion 6 -->
<v-col cols="12" md="4" sm="4">

       <v-radio label="OTROS"  color="red darken-3" :value="6">
            <template v-slot:label>
            <strong id="radio-label" style="color:#000000;">OTROS</strong>
            </template> 
       </v-radio>

        <v-avatar size="45">
    
      <v-img 
     src="@/assets/faltas-iconos/otros.png" 
      aspect-ratio="1"
      class="grey lighten-2"
      max-width="300"
      max-height="300"
    ></v-img>
</v-avatar>

</v-col>

</v-row>
</v-col>
<!--  fin radio 1 -->
 </v-radio-group>
  </v-col>
                        
          
</v-container>
</v-card>
<hr>
<v-card color="grey lighten-3">
<v-container><!-- style="border: 1px solid #000000;" -->
<v-row>
 <v-col cols="12" md="12" sm="12">
    <!-- ----------------------------- Descripción -->
                  <v-textarea
                  autocomplete="off"
                    label="Descripción:"
                    dense
                    outlined
                    v-model="RegistroFaltas.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-textarea>



                  <!-- <v-switch
                    label="Activo"
                    v-model="RegistroFaltas.activo"
                 ></v-switch> -->
  </v-col>

<v-col cols="12" md="6" sm="6">
  <!-------------------------------  fecha -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha:"
        v-model="RegistroFaltas.fecha"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>



</v-col>
<v-col cols="12" md="6" sm="6">

 <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Conductor:"
            :items="Rconductor"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="Cbchofer" 
            @focus="cargarConductor" 
            @blur="SelctCbConductor" 
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-car"></i>
            </template>
          </v-combobox>


</v-col>



</v-row>
</v-container>
</v-card>




            </v-form>
       </v-container>
  
 <!-- </v-container> -->

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

<v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>




          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    
<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarFalta'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase
    
  },
  created() {

    
    var json = {
      titulo: "Registro de Faltas",
      descripcion: "Para realizar los registros de faltas",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "TranspRegistroFaltasNew",
        "TranspRegistroFaltasSave",
        "TranspRegistroFaltasRemove",
        "TranspRegistroFaltasAnular",
        "TranspRegistroFaltasPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspRegistroFaltasNew", this.new);
    // EventBus.$on("TranspRegistroFaltasSave", this.save);
    // EventBus.$on("TranspRegistroFaltasRemove", this.remove);
    // EventBus.$on("TranspRegistroFaltasAnular", this.remove);
    // EventBus.$on("TranspRegistroFaltasPrintList", this.imprimirList);
    EventBus.$on("emitTranspRegistroFaltas", this.datostabla);
 this.cargarConductor()

    EventBus.$on("onResize", this.onResize);
  },

  data:()=> ({
   
      ejecucion: true,
      Rconductor:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},


      Cbchofer:null,
      RegistroFaltas: {
        id: 0,
        activo: true,
        descripcion: "",


    idCategoria: {
      id: 1
    },

        fecha: new Date().toISOString().substr(0,10),
        fechaRegistro:new Date().toISOString().substr(0,10),
        idchofer:null,
        idusuarioRegistra:''

      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },




     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,


      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
   
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },

ChangeIdCategoria(e){
if(e==1){this.RegistroFaltas.idCategoria.nombre="ACCIDENTES"}
if(e==2){this.RegistroFaltas.idCategoria.nombre="CONDUCCION"}
if(e==3){this.RegistroFaltas.idCategoria.nombre="VESTIMENTA / UNIFORME"}
if(e==4){this.RegistroFaltas.idCategoria.nombre="CONFLICTOS CON OTROS EMPLEADOS"}
if(e==5){this.RegistroFaltas.idCategoria.nombre="CONFLICTOS CON PASAJEROS"}
if(e==6){this.RegistroFaltas.idCategoria.nombre="OTROS"}

}, 


   
    datostabla(e) {

      this.Cbchofer=null
      var jsn =  JSON.parse(JSON.stringify(e))
      jsn.fecha= new Date(e.fecha).toISOString().substr(0,10);
      this.RegistroFaltas = JSON.parse(JSON.stringify(jsn));


      this.acti_tb_data=false
    //  this.Cbchofer.id,this.RegistroFaltas.conductor


this.Rconductor.forEach(el => {
if(el.id==e.idchofer){ this.Cbchofer=JSON.parse(JSON.stringify(el))  }
});



// activo: true
// borrado: false
// descripcion: "Reporte Negativo 1"
// fecha: 1635393600000
// fechaRegistro: 1635435834000
// id: 1
// idCategoria: null
// idchofer: 1
// idusuarioRegistra: "Jc"




    },
    save() {

if (this.$refs.formRegistroFaltas.validate()) {

this.RegistroFaltas.idchofer= JSON.parse(JSON.stringify(this.Cbchofer.id))
 
if(this.ejecucion==true){

  this.ejecucion=false

  var Json=JSON.parse(JSON.stringify(this.RegistroFaltas)) 

this.$axios
        .post(this.$hostname + this.$hName + "/reporteschoferes/save/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));
  
  
  }

}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },


NotifMessage() {

if(this.RegistroFaltas.id!=0 && this.RegistroFaltas.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar esta falta?",
        Exec:"eliminarFalta"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una falta para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    
    eliminar() {

if (this.RegistroFaltas.codigo!=0) {
    
var Json=JSON.parse(JSON.stringify(this.RegistroFaltas)) 

this.$axios
        .post(this.$hostname + this.$hName + "/reporteschoferes/borrado/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"))

      
      }

    },
    anular() {
     // if (this.Elim_notif.estado) { }

var jsn={
   datos:JSON.parse(JSON.stringify(this.RegistroFaltas)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
     
    },
    imprimirList() {},
    newwnuevo() {

 this.Cbchofer=null
 this.RegistroFaltas={

        id: 0,
        activo: true,
        descripcion: "",


    idCategoria: {
      id: 1
    },

        fecha: new Date().toISOString().substr(0,10),
        fechaRegistro:new Date().toISOString().substr(0,10),
        idchofer:null,
        idusuarioRegistra:''

      }




        this.ejecucion = true;
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarConductor(){

var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/propietario/findallConductorActivos/",{headers:headers})
.then(res => {this.Rconductor = res.data})
.catch(error => console.log(error));

},

 SelctCbConductor(){

   if(this.Cbchofer==null){

    this.Cbchofer=''
  }

if(this.Cbchofer.id==undefined){

this.alerta("Seleccione una Llegada", "error");
this.Cbchofer=''
return

}

 },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {

      var headers=this.$store.getters.GetheadersAxios 
      await this.$axios.get(this.$hostname + this.$hName + "/reporteschoferes/findall/", {headers:headers})
        .then(res => {this.list = res.data;});


    }
  },




computed:{




    ActDescBtn(){      
if(this.RegistroFaltas.id>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.RegistroFaltas.id<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

    }
    }




};
</script>

<style></style>
