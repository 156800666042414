<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 <tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Formulario de Temperatura de Medicamentos'"
  :descripcion="'Crear, Modificar y Desactivar Temperatura'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
 
 
 
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitSaludFormularioTemperatura'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="12" sm="12">

                 <!-------------------------------------------------- Sucursal -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Sucursal:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="Temperatura.sucursal"
            @focus="cargarSucursal"
            @blur="SelctCbSucursalDesp"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
 </v-combobox>
                </v-col>
              </v-row>
            </v-form>
       </v-container>

<hr>
              <v-card-actions style="background-color: #BDBDBD; border: 1px solid #000000;" class="d-flex justify-center" >
               <v-btn 
                  color="blue"
                  dark
                  @click="imprimirFormularioTemp()"
                  >
               <i style="font-size: 20px;" class="fa fa-print" > </i>
                  Imprimir
                  <!-- <v-icon dark right>mdi-printer-check</v-icon> -->
                </v-btn> 
                </v-card-actions>




  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase
    
  },
  created() {
    var json = {
      titulo: "Formulario de Temperatura de Medicamentos",
      descripcion: "Crear, Modificar y Desactivar Temperatura",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [
        "SaludFormularioTemperaturaNew",
        "SaludFormularioTemperaturaSave",
        "SaludFormularioTemperaturaRemove",
        "SaludFormularioTemperaturaAnular",
        "SaludFormularioTemperaturaPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("SaludFormularioTemperaturaNew", this.new);
    // EventBus.$on("SaludFormularioTemperaturaSave", this.save);
    // EventBus.$on("SaludFormularioTemperaturaRemove", this.remove);
    // EventBus.$on("SaludFormularioTemperaturaAnular", this.remove);
    // EventBus.$on("SaludFormularioTemperaturaPrintList", this.imprimirList);
    EventBus.$on("emitSaludFormularioTemperatura", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {

      RSucursal:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      Temperatura: {
        activo: true,
        codigo: 0,
        descripcion: "",
        sucursal:null
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

     EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.Temperatura = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.Temperatura)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },
    remove() {

if(this.Temperatura.codigo!=0 && this.Temperatura.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Temperatura)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.Temperatura.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.Temperatura)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {},
    newwnuevo() {
      (this.Temperatura = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarSucursal(){

  let headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', {headers})
          .then(res => (this.RSucursal = res.data))
},

imprimirFormularioTemp() {
      EventBus.$emit("loading", true);

var OBJ={
  where:'where c.empresa>=0 ',
  condicion:'',
  sucursal:''
}

if(this.Temperatura.sucursal!=null && this.Temperatura.sucursal.hasOwnProperty('codigo') ){
  OBJ.where+= ` and s.codigo=${this.Temperatura.sucursal.codigo}`;  
  OBJ.condicion+=` SUCURSAL: ${this.Temperatura.sucursal.descripcion} `
  OBJ.sucursal+=`${this.Temperatura.sucursal.descripcion} `
}else{
  OBJ.condicion+=`TODAS  `
  OBJ.sucursal+=`TODDAS `
}

      this.$axios
        .post(
          this.$hostname +this.$hName +"/reportesSALUD/formularioTemperatura", OBJ,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          EventBus.$emit("loading", false);
          setTimeout(function () {
           /* let pdfWindow = window.open("Reporte de impresión");
            pdfWindow.document.write(
              "<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
              encodeURI(res.data) +
              "'></embed>"
            );*/
            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
            EventBus.$emit("openPDF",PDF ,'FORMULARIO DE TEMPERATURA');

          }, 1000);
        })
        .catch((error) => {
          EventBus.$emit("loading", false);
          console.error(error);
        });
    },


SelctCbSucursalDesp() {
var _this = this;
      setTimeout(function () {
        if (_this.Temperatura.sucursal == null) {
          _this.Temperatura.sucursal = "";
        }

        if (!_this.Temperatura.sucursal.hasOwnProperty('codigo')) {
          _this.Temperatura.sucursal = "";
          _this.Temperatura.sucursal = null;
          return;
        }
      }, 300);
},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },





    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
