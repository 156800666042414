<template @click="buscando">
  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <v-card>
      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12">
        <span style="font-size:28px;" :class="`d-flex justify-center black--text`"><b> {{ PropsNombre }}</b></span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-text-field class="pa-0 ma-0 py-0 my-0 " v-if="PropsSearch == true" autocomplete="off" v-on="on"
              v-model="PropsBusqueda" @keyup.enter="keyupPropsBusqueda" :label="PropsSeachNombre" single-line
              hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>

            <v-text-field class="pa-0 ma-0 py-0 my-0 " v-if="PropsSearchNumber == true" autocomplete="off" v-on="on"
              v-model="PropsBusqueda" @keyup.enter="keyupPropsBusqueda" type="number" :label="PropsSeachNombre"
              single-line hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>




            <v-text-field v-if="PropsSearchFecha == true" dense rounded outlined type="date" @input="keyupPropsBusqueda"
              :label="PropsSeachNombre" v-model="PropsBusqueda">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

              <template v-slot:append-outer>

                <v-btn @click.prevent="FechNew" color="grey lighten-1" fab x-small dark>
                  <i style="font-size: 20px;" class="fa fa-file-o"></i>
                </v-btn>
              </template>
            </v-text-field>


          </template>
          <span>{{ PropsSeachNombre }}</span>
        </v-tooltip>
      </v-col>

      <v-data-table @click:row="obtenerDato" :items-per-page="TbPaginator.itemsPerPage" @pagination="CargarPaginacion"
        :headers="headers" :items="PropsList" :search="PropsBusqueda" :server-items-length="TbTotalItem"
        hide-default-header v-if="TbActi" :footer-props="arrayPropiedases">
        <template v-slot:item="props">
          <!-- Notas:
  {Text: Se refiere al nombre descriptivo,
   NomValue: se refiere al primer nombre del objecto,
   Nv2: se refiere al seg nombre objecto, Nv3: al tercero, 
   Ctsl: cantidad de slot de objecto que pose,  
   Type: se refiere al tipo:(texto=0,fecha=1, numerico=2), 
   State: 
   }

   ContListSlot:[
{Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
{Text:'Banco',NomValue:'banco', Ctsl:1, Type:0, State:true},
{Text:'Beneficio',NomValue:'beneficio', Ctsl:1, Type:0, State:true},
{Text:'Fecha',NomValue:'fecha', Ctsl:1, Type:1, State:true},
{Text:'Valor',NomValue:'valor', Ctsl:1, Type:2, State:true},
{Text:'Sucursal',NomValue:'sucursal', Nv2:'nombre', Ctsl:2, Type:0, State:true},
{Text:'Documento',NomValue:'sucursal', Nv2:'documento', Nv3:'desc' ,Ctsl:3, Type:0, State:true}
],

   -->
          <tr>
            <td @click.prevent="obtenerDato(props.item)" v-ripple="{ class: `indigo--text` }">

              <v-list-item-content>
                <span v-for="(sl, i) in ContListSlot" :key="i">
                  <span
                    v-if="props.item.hasOwnProperty(sl.NomValue) && props.item[sl.NomValue] != null && props.item[sl.NomValue].toString().length > 0">

                    <span v-if="sl.Ctsl == 1">
                      <span v-if="sl.Type == 0"><b>{{ sl.Text }}:</b>{{ props.item[sl.NomValue] }}.</span>
                      <span v-if="sl.Type == 1"><b>{{ sl.Text }}:</b>{{ FormatoFecha(props.item[sl.NomValue]) }}.</span>
                      <span v-if="sl.Type == 2"><b>{{ sl.Text }}:</b>{{ currencyFormatter(props.item[sl.NomValue])
                      }}.</span>

                      <span v-if="sl.Type == 0.1 & props.item[sl.NomValue] == false"><b>{{ sl.Text }}:</b><span
                          class="red--text">Inactivo.</span></span>
                      <span v-if="sl.Type == 0.1 & props.item[sl.NomValue] == true"><b>{{ sl.Text }}:</b><span
                          class="green--text">Activo. </span></span>
                      <span v-if="sl.Type == 0.2 & props.item[sl.NomValue] == true"> <b>{{ sl.Text }}:</b><span
                          class="green--text"> Activo.</span></span>
                      <span v-if="sl.Type == 0.2 & props.item[sl.NomValue] == false"> <b>{{ sl.Text }}:</b><span
                          class="red--text"> Anulado.</span></span>
                      <span v-if="sl.Type == 0.3 & props.item[sl.NomValue] != null"><span class="green--text">{{ sl.Text
                      }}</span></span>
                      <span v-if="sl.Type == 0.4 & props.item[sl.NomValue] == true"><span class="green--text">{{ sl.Text
                      }}</span></span>
                      <span v-if="sl.Type == 0.4 & props.item[sl.NomValue] == false"><span class="red--text">{{ sl.Text2
                      }}</span></span>

                      <span v-if="sl.Type == 3">

                        <v-avatar size="50" tile>
                          <v-img :src="props.item[sl.NomValue]"></v-img>
                        </v-avatar>
                      </span>

                    </span>

                    <span v-if="sl.Ctsl == 2 && props.item[sl.NomValue].hasOwnProperty(sl.Nv2)">
                      <span v-if="sl.Type == 0"><b>{{ sl.Text }}: </b>{{ props.item[sl.NomValue][sl.Nv2] }}.</span>
                      <span v-if="sl.Type == 1"><b>{{ sl.Text }}:
                        </b>{{ FormatoFecha(props.item[sl.NomValue][sl.Nv2]) }}.</span>
                      <span v-if="sl.Type == 2"><b>{{ sl.Text }}:
                        </b>{{ currencyFormatter(props.item[sl.NomValue][sl.Nv2]) }}.</span>

                      <span v-if="sl.Type == 0.3">
                        <span v-if="props.item[sl.PrgValue] == sl.PrgState"><b>{{ sl.Text }}:
                          </b>{{ props.item[sl.NomValue][sl.Nv2] }}.</span>
                      </span>
                      <span v-if="sl.Type == 0.1 & props.item[sl.NomValue][sl.Nv2] == false"><b>{{ sl.Text }}:</b><span
                          class="red--text">Inactivo.</span></span>
                      <span v-if="sl.Type == 0.1 & props.item[sl.NomValue][sl.Nv2] == true"><b>{{ sl.Text }}:</b><span
                          class="green--text">Activo. </span></span>
                      <span v-if="sl.Type == 0.2 & props.item[sl.NomValue][sl.Nv2] == false"> <b>{{ sl.Text }}:</b><span
                          class="red--text"> Anulado.</span></span>

                      <span v-if="sl.Type == 3">

                        <v-avatar size="50" tile>
                          <v-img :src="props.item[sl.NomValue][sl.Nv2]"></v-img>
                        </v-avatar>
                      </span>

                    </span>

                    <span v-if="sl.Ctsl == 3 & props.item[sl.NomValue].hasOwnProperty(sl.Nv2)">
                      <span v-if="props.item[sl.NomValue][sl.Nv2].hasOwnProperty(sl.Nv3)"><b>{{ sl.Text }}: </b>
                        <span v-if="sl.Type == 0">{{ props.item[sl.NomValue][sl.Nv2][sl.Nv3] }}.</span>
                        <span v-if="sl.Type == 1">{{ FormatoFecha(props.item[sl.NomValue][sl.Nv2][sl.Nv3]) }}.</span>
                        <span v-if="sl.Type == 2">{{ currencyFormatter(props.item[sl.NomValue][sl.Nv2][sl.Nv3])
                        }}.</span>
                      </span>
                    </span>


                  </span>
                </span>
              </v-list-item-content>

            </td>
          </tr>
          <hr>

          <!-- <span>{{props.item}}
  </span> -->
        </template>

      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
// import axios from "axios";
import cuerpo from "../../src/components/cuerpo";
import { EventBus } from "@/event-bus.js";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";

export default {
  components: {
    cuerpo
  },
  data() {
    return {

      TbActi: true,
      ObjTemTabla: null,
      indexTabla: null,
      objFiltro: null,
      PropsBusqueda: '',
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },


    };
  },
  props: {
    headers: {
      required: true,
      type: Array
    },


    ContListSlot: {
      required: false,
      type: Array
    },


    PropsList: {
      required: false,
      type: Array
    },

    PropsNombre: {
      required: true,
      type: String,
      default: ''
    },


    PropsSeachNombre: {
      required: true,
      type: String,
      default: ''
    },


    PropsSearchFecha: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsSearch: {
      required: false,
      type: Boolean,
      default: false
    },
    PropsSearchNumber: {
      required: false,
      type: Boolean,
      default: false
    },
    TbPaginator: {
      required: false,
      type: Object
    },

    TbTotalItem: {
      required: false,
      type: Number
    },


  },

  created() { },
  mounted() {
    // EventBus.$on("actualizaBarraDinaicaLim", this.filtro);

    EventBus.$on("BarraDinamLimitadaFiltro", this.Modalfiltro);
  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,

    CargarPaginacion(e) {

      var tgt = this.PropsBusqueda
      if (this.PropsBusqueda.toString().length == 0) { tgt = null }

      this.$emit("CargarPaginacion", e, tgt)
    },

    keyupPropsBusqueda(e) {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }
      var tgt = e.target.value
      if (e.target.value.length == 0) { tgt = null }

      this.$emit("CargarPaginacion", Pg, tgt)
    },



    cargarMasdatos(e) {

      this.paginator = e

      if (this.paginator.itemsPerPage == -1) {

        this.paginator.itemsPerPage = 15

      }


      this.filtro()


      // this.json = {
      //   descripcion: this.search,
      //   ultimoRegistro: e.pageStop,
      //   paginacion: e.page,
      //   limite: this.paginator.itemsPerPage
      // };
      // this.paginator.pageStop = e.pageStop;
      // this.$axios.post(this.url, this.json).then(res => (this.list = res.data));
    },

    FechNew() {

      this.search = ""


    },


    TelFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            "(" +
            params.substring(0, 3) +
            ")" +
            params.substring(3, 6) +
            "-" +
            params.substring(6, 10)
          );
        }
        return params;
      }
    },

    cedFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(0, 3) +
            "-" +
            params.substring(3, 7) +
            "-" +
            params.substring(7, 11)
          );
        }
      }
    },



    obtenerDato(e) {
      this.$emit("EmitDAta", JSON.parse(JSON.stringify(e)))
      // EventBus.$emit(this.emitnombre, e);
      // EventBus.$emit("EmitDAta", e);
    },


    ObtenerTemp(e) {

      // const index =  this.nuevoClientes.ListFact.indexOf(item)
      // this.nuevoClientes.ListFact.splice(index, 1)
      // this.nuevoClientes.ListFactDele.push(item);

      if (this.ObjTemTabla != null) {

        var pg = JSON.parse(JSON.stringify(this.paginator))

        //this.list[this.indexTabla]=JSON.parse(JSON.stringify(this.ObjTemTabla))
        console.log('Ver el index: ' + this.indexTabla)
        console.log(this.list[this.indexTabla])

        this.list[this.indexTabla] = JSON.parse(JSON.stringify(this.ObjTemTabla))

        this.TbActi = false

        var _this = this;

        setTimeout(function () { _this.TbActi = true }, 5);
        //setTimeout(function(){ _this.cargarMasdatos(pg) }, 8);
        //setTimeout(this.TbActi=true,8000);
        // var jsn =JSON.parse(JSON.stringify(this.list))
        // this.list=[]
        // this.list=JSON.parse(JSON.stringify(jsn))

      }
      this.indexTabla = JSON.parse(JSON.stringify(this.list.indexOf(e)))
      this.ObjTemTabla = JSON.parse(JSON.stringify(e))

    }


  }



};
</script>

<style>

</style>

