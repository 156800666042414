<template @click="buscando">
  <aside v-show="isVisible" class="barra-busqueda">
    <v-card>
      <v-card-title>
        <center >
    <v-card-text style="font-size: 25px;"  class="font-weight-bold" >
         {{ nombre }}
    </v-card-text>
         
<!-- class="justify-center headline font-weight-bold" -->

        <!-- <h5>{{ nombre }}</h5> -->
        </center> 
        <v-spacer></v-spacer>

        <v-tooltip right>
          <template v-slot:activator="{ on }">

            <v-text-field
            v-if="Campofecha==false"
              autocomplete="off"
              v-on="on"
              v-model="search"
              @keyup="filtro"
              :label="SeachNombre"
              single-line
              hide-details
            >
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>


<v-text-field
v-if="Campofecha==true"
        dense
         rounded
        outlined
        type="date" 
        @input="filtro" 
        :label="SeachNombre"
        v-model="search"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

<template v-slot:append-outer>

<v-btn @click.prevent="FechNew" color="grey lighten-1" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-file-o"></i>
</v-btn>
                  
 </template>

                
  
</v-text-field>


     
 
          </template>
          <span>{{SeachNombre}}</span>
        </v-tooltip>
      </v-card-title>

      <v-data-table
        @click:row="obtenerDato"
        :items-per-page="10"
        :headers="headers"
        :items="list"
        :search="search"
        
      >
        <template v-slot:[slotname]="props">
          <div style="border-top:2px solid gray;">
            <v-list-item-title v-for="(sl, i) in slotlist" :key="i">
              
                <div v-if="sl.dt0=='sumatoria' && sl.dt3==1 && sl.dt4=='Valor:'">
                  <b>{{ sl.dt4 }}</b>{{ currencyFormatter(props.item.efectivo + props.item.cheques + props.item.tarjeta + props.item.recibos)}}
                </div>

          <p v-if="sl.dt3==0.1">
          <b class="red--text" v-if="props.item[sl.dt0]==false">{{sl.dt4}}</b>
          </p>

              <div v-if="sl.dt3 == 1">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null) && sl.dt0!='sumatoria'">
                  <b>{{ sl.dt4 }} </b>{{ props.item[sl.dt0] }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null) & props.item.hasOwnProperty(''+sl.dt0)">

                <!-- & props.item[sl.dt0].hasOwnProperty(''+sl.dt1) -->

                  
                  <b>{{ sl.dt4 }}</b>
                  {{ props.item[sl.dt0][sl.dt1] }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ props.item[sl.dt0][sl.dt1][sl.dt2] }}
                </div>
              </div>

              <div v-if="sl.dt3 == 2">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ fecFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ fecFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ fecFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 3">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ TelFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ TelFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ TelFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 4">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ cedFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ cedFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ cedFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 5">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ currencyFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ currencyFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ currencyFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

                 <!-- <div v-if="sl.dt3 == 6">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ sumatoria(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ sumatoria(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }} </b
                  >{{ sumatoria(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div> -->
            </v-list-item-title>
          </div>
          <div></div>


        </template>
      </v-data-table>
    </v-card>
  </aside>
</template>

<script>
// import axios from "axios";
import cuerpo from "../../src/components/cuerpo";
import { EventBus } from "@/event-bus.js";
export default {
  components: {
    cuerpo
  },
  data() {
    return {
      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" }
      ],
      json:{},
      paginator: {
        itemsPerPage: 5,
        pageStart: 10,
        pageStop: 15,
        pageCount: 100,
        itemsLength: 5
      },
      isVisible: true,
      list: [],
      search: ""
    };
  },
  props: {
    headers: {
      required: true,
      type: Array
    },

    slotname: {
      required: false,
      type: String
    },

    url: {
      required: true,
      type: String
    },
    nombre: {
      required: true,
      type: String
    },

    SeachNombre: {
      required: true,
      type: String
    },

    slotlist: {
      required: false,
      type: Array
    },

    emitnombre: {
      required: true,
      type: String
    },

Campofecha: {
      required: false,
      type: Boolean
    },


  },

  created() {

    /////////
   // this.$axios.post(this.url, {usuario:this.$CodeUser}).then(res => (this.list = res.data));

this.filtro()
    //this.list.push(this.slotlist)
    //this.list.push(this.slotlist)
  },
  mounted: function() {
    EventBus.$on("actualizaBarraBusqueda2", this.filtro);
    EventBus.$on("BarraDinamFiltro", this.Modalfiltro);

  },
  methods: {

FechNew(){

this.search=""


},


ver(e){
  console.log('Provando provando')
console.log(e)
},

sumatoria(e){
  
 

},
    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
     // return this.formatNumber(params);

if(number==null){number=0}

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


    },

//     formatNumber(number) {

// if(number==null){number=0}

//       // return Math.floor(number)
//       //   .toString()
//       //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

//       return number
//         .toFixed(2)
//         .toString()
//         .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
//     },

    fecFormatter(params0) {
var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    TelFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            "(" +
            params.substring(0, 3) +
            ")" +
            params.substring(3, 6) +
            "-" +
            params.substring(6, 10)
          );
        }
        return params;
      }
    },

    cedFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(0, 3) +
            "-" +
            params.substring(3, 7) +
            "-" +
            params.substring(7, 11)
          );
        }
      }
    },

    async filtro() {
      await this.$axios.get(this.url, {headers:this.$store.getters.GetheadersAxios}).then(res => {
        this.list =  JSON.parse(JSON.stringify(res.data)) ;
      });
    },


async Modalfiltro(e) {


console.log('ModalDinamicoFiltro')
console.log(e)


  if(e.opcion==1){

    console.log('Entrando al modalFiltro filt==>'+e.url)
 this.objFiltro=JSON.parse(JSON.stringify(e)) 


 await this.$axios
        .get(e.url, {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list =JSON.parse(JSON.stringify(res.data));
        });


  }else{

    console.log('Entrando al Filtro')

    
    this.objFiltro=null


this.list=[]





 this.filtro()

var _this=this;
  //setTimeout(function(){ _this.cargarFiltro(e) }, 300);
  
   //setTimeout(this.cargarFiltro,200);
  
  
  }

    },














    obtenerDato(e) {
      EventBus.$emit(this.emitnombre, e);
      //this.Rdata=e
      //this.RdataDetalle=e[this.slotlist]
    }
  }
};
</script>
<style></style>
