<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px">
<ContenidoBase 
  :titulo="'CREAR ORDENES'"
  :descripcion="'Crear ordenes'"
   :EstadoBtn="EstadoBtn"
   @MethodNuevo="newwCrearOrdenes"
   @MethodGuardar="saveCrearOrdenes"
   @MethodAnular="NotifMessage" 
   @MethodImprimir="imprimirList"  
/> <!-- @MethodEliminar="NotifMessage"  -->
  </tr>
    <tr>
  <!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir"   >

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
      <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
  <!-- <v-btn-toggle  ></v-btn-toggle> -->
        <v-btn small width="95%" color="yellow accent-4"    @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;" class="fa fa-eye"> </i>  ver</v-btn>
       
  </center>

<!-- BarraDinamicaNormal -->
   
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-76"   >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'height':this.windowSize.height-76+'px' ,'max-height':this.windowSize.height-76+ 'px'  }">
 <div class="px-2">
 <center>
<!-- Formulario -->
 <v-col cols="12" md="12" sm="12"> 
<v-card color="grey lighten-3" >
  <!-- <h3 v-if="this.CrearOrdenes.codigo==0">NUEVO REGISTRO</h3>
  <h3 v-if="this.CrearOrdenes.codigo>0">EDITANDO REGISTRO</h3> -->
<div class="px-2">

  <v-form ref="formMant" v-model="valid" lazy-validation>
    <v-row>

<v-col v-if="false" cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-1" >
  <v-combobox
                            v-model="CrearOrdenes.mantenimiento"
                            label="# Mantenimiento:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RMantenimiento"
                            item-text="nombre"
                            @blur="SelctCbMantenimiento"
                            @focus="cargarManteniemiento"
                            style="font-size:13px;"
                           
                        >

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-slack"
                            ></i>
                          </template>

<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO:</b> {{item.item.id}}</p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CLIENTE: </b>{{item.item.cliente.nombre}}</p>
</v-col>
 </template>

                        </v-combobox>
</v-col>
     
  <v-col cols="12" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 px-1" >

<v-combobox
                            v-model="CrearOrdenes.cliente"
                            label="Cliente:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="Rcliente"
                            item-text="nombre"
                            @blur="SelctCbCliente"
                            @focus="cargarCliente"
                            style="font-size:13px;"
                            @keyup="CbFilClient"  
                        >

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-user-circle-o"
                            ></i>
                          </template>

<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO:</b> {{item.item.codigo}}</p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE: </b>{{item.item.nombre}}</p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="px-2" style="border: 1px dashed  #616161;"><b>TEL.: </b> {{selectTelefonoCliente(item.item)}}</span>
</span>
</v-col>
 </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="dialogCrearcliente=true" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Crear Cliente</span>
        </v-tooltip> 
        
 </template>

                        </v-combobox>

</v-col>

 <v-col cols="12" md="4" sm="4" class="pa-0 ma-0 py-0 my-0 px-1">
<v-textarea
                  autocomplete="off"
                    label="* Direccion:"
                    dense
                    outlined
                    rows="1"
                    v-model="infCliente.direccion"
                    disabled
                    id="formdisabledInput"
                    style="font-size:13px;"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>
 </v-col>

<v-col cols="12" md="4" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
   <!-- ----------------------------- Referencia -->

   <v-text-field label="Referencia:" autocomplete="off" dense outlined v-model="CrearOrdenes.referencia" maxlength="29">
                        <template v-slot:prepend >
                          <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                        </template>
                      </v-text-field>
 </v-col>


 
 

 <v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<!----------------------------------- Telefono  -->
<v-text-field
         
            id="formdisabledInput"
            label="Télefono :"
            dense
            outlined
            autocomplete="off"
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="infCliente.telefono"
            style="font-size:13px"
            disabled
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>
          </v-text-field>
  </v-col>

 <v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
 <v-text-field label="E-mail"
                      id="formdisabledInput"
                     dense outlined
                     v-model="infCliente.email"
                     disabled
                     style="font-size:13px"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>
 </v-col>


 <v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Estimada "
        v-model="CrearOrdenes.fechaestimada"
        :rules="[$rules.required]"
        style="font-size:13px;"
        :disabled="CrearOrdenes.id>0"
        id="formdisabledInput"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>

 </v-col>


<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Pautada "
        v-model="CrearOrdenes.fechapautada"
        :rules="[$rules.required]"
        style="font-size:13px;"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>

</v-col>


<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field label="Hora Estimada"
                    style="font-size:13px;"
                    :rules="[$rules.required]"
                     dense outlined
                     v-model="CrearOrdenes.hora"
                     type="time"
                     
                     id="formdisabledInput"
                     
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>

</v-col>

<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
   <!-- ----------------------------- Referencia -->

   <v-text-field label="brigada:" autocomplete="off" dense outlined v-model="CrearOrdenes.brigada" maxlength="29">
                        <template v-slot:prepend >
                          <i style="font-size: 20px;" class="fa fa-slack"></i>
                        </template>
                      </v-text-field>
 </v-col>

<v-col cols="12" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 px-1">
<v-combobox
                            v-model="CrearOrdenes.tecnico"
                            label="Tecnico:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RTecnico"
                            item-text="nombre"
                            @blur="SelctCbTecnico"
                            @focus="cargarTecnico"
                            @keyup="CbFilTecnico"
                            style="font-size:13px;"
                        >

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-wrench"
                            ></i>
                          </template>

<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO:</b> {{item.item.id}}</p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE: </b>{{item.item.nombre}}</p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="px-2" style="border: 1px dashed  #616161;"><b>TEL.: </b> {{item.item.telefono}}</span>
  <span class="px-2" style="border: 1px dashed  #616161;"><b>Especialidad.: </b> {{item.item.especialidad.descripcion}}</span>
</span>
</v-col>
 </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="dialogCrearTecnico=true" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Crear Tecnico</span>
        </v-tooltip> 
        
 </template>

                        </v-combobox>
</v-col>

<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-textarea
                  autocomplete="off"
                    label="Materiales a utilizar:"
                    dense
                    outlined
                    rows="1"
                    v-model="CrearOrdenes.materiales"
                    id="formdisabledInput"
                    style="font-size:13px;"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-suitcase"></i>
                    </template>
                  </v-textarea>

</v-col>
<v-col cols="12" md="5" sm="5" class="pa-0 ma-0 py-0 my-0 px-1" v-if="CrearOrdenes.id>0">


<v-radio-group class="py-0" v-model="CrearOrdenes.pendiente" row>                 
                  <v-radio label="PENDIENTE" color="grey" :value="true"></v-radio>                                       
                  <v-radio label="REALIZADO" color="green" :value="false"></v-radio>                 
</v-radio-group>


</v-col>


</v-row>
  </v-form>
 
</div>
 </v-card>
</v-col>
<!-- fn Formulario -->
<!-- ORDEN DETALLE -->
<v-col cols="12" md="7" sm="9" class="py-0" > 
<v-row>

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-1">

 <v-card>
<v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12">
  <span :class="`d-flex justify-star white--text px-1`"><b>ORDEN DETALLE</b></span>
</v-col>

<div  class="py-2 px-2">
<v-form ref="formEquipo" v-model="valid" lazy-validation v-if="CrearOrdenes.id==0" > 
<v-row > 

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-1">

 <v-combobox
                            v-model="equipo"
                            label="Seleccionar equipo:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="Requipos"
                            item-text="descripcion"
                            @blur="SelctCbEquipo"
                            @focus="cargarEquipo"
                            style="font-size:13px;"
                            @keyup="CbFilEquipo"
                            hide-details
                        >

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-bolt"></i>
                          </template>

                        </v-combobox>
</v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-1">

 <v-combobox
                            v-model="actividad"
                            label="Seleccionar Actividad:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RActividad"
                            item-text="descripcion"
                            @blur="SelctCbActividad"
                            @focus="cargarActividad"
                            @keyup="CbFilRegAct"
                            style="font-size:13px;"
                            hide-details
                        >

      <template v-slot:prepend>
      <i @mouseover="btnCrearActivida=true" v-if="btnCrearActivida==false"  style="font-size: 20px;" class="fa fa-ils"></i>

<v-tooltip top   v-if="btnCrearActivida==true"  >
<template v-slot:activator="{ on }">
   
<v-btn @mouseout="btnCrearActivida=false" @click.prevent="dialogCrearActivida=true" v-on="on" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>

 </template>
    <span>Crear Nueva Actividad</span>
 </v-tooltip>
  </template>


<template v-slot:append-outer>
  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="AgregarOrdenDetalle" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Agrerar Orden</span>
        </v-tooltip> 
        
 </template> 
                        </v-combobox>


</v-col>

  </v-row>
</v-form>

  <v-form ref="formTbEquipo" v-model="valid" lazy-validation > 
  <v-data-table
       id="TbBord"
      v-if="CrearOrdenes.ordenesMantDetalleList.length>0"
      class="elevation-1 RowSelectColor TbSize13"
       item-key="indx"
      :headers="[{text: 'CÓDIGO', sortable: false,  value: 'producto.codigo',width:100, class: ['no-gutters black--text']},
                { text: 'EQUIPO', sortable: false, value: 'descripcion', class: ['no-gutters black--text'] },
                { text: 'ACTIVIDAD', sortable: false, value: 'actividad', width:300,class: ['no-gutters black--text'] },
                { text: 'ACCIÓN', sortable: false, value: 'action',width:50, class: ['no-gutters black--text'] }]"
              dense
              :items="CrearOrdenes.ordenesMantDetalleList"     
              :items-per-page="15"
              
            >
  <template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<v-btn :disabled="CrearOrdenes.id>0"  fab @click.prevent="deleEquipo(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                 
</template>


<template v-slot:item.descripcion="props">
              <v-text-field label="" dense outlined autocomplete="off" hide-details
                          id="formdisabledInput" style="font-size:13px;" v-model="props.item.descripcion"
                          :disabled="CrearOrdenes.id>0 || props.item.producto.servicio==false" maxlength="50">
                        </v-text-field>
              </template>

              <template v-slot:item.producto.codigo="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = CrearOrdenes.ordenesMantDetalleList.indexOf(props.item)}}</span>
                <!-- <span style="font-size:13px;">{{props.item.producto.codigo}}  </span> -->
                <v-text-field label="" dense outlined autocomplete="off" hide-details
                          id="formdisabledInput" style="font-size:13px;" :value="props.item.producto.codigo"
                          disabled>
                        </v-text-field>
              </template>



              <template v-slot:item.actividad="props">
                <v-combobox
                           id="formdisabledInput"
                            v-model="props.item.actividad"
                            label=""
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RActividad"
                            item-text="descripcion"
                            @blur="SelctCbActividadTb(props.item.actividad)"
                            @focus="cargarActividad"
                            @keyup="CbFilRegAct"
                            style="font-size:13px;"
                            hide-details
                            :disabled="CrearOrdenes.id>0"
                        >
                 </v-combobox>
              </template>
</v-data-table>
</v-form>

</div>

 </v-card>


</v-col>
</v-row>
</v-col>
<!-- fn ORDEN DETALLE -->
 </center>
</div>  

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
         
        <!-- BarraBusqueda2 -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarCrearOrdenes'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->



</v-dialog>

<!-- ------------------------------------------------------ Modal- Cliente-->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogCrearcliente" persistent fullscreen hide-overlay>

<v-card>
 <div>

<v-toolbar dark color="indigo darken-4" dense>
  
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogCrearcliente=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
 </v-card-actions>
    </v-toolbar> 

<v-col cols="12" md="12" sm="12">

 <v-card flat>
<ClientesBasico/>
 </v-card>

  
</v-col>

</div>
</v-card>


</v-dialog>


<!-- ------------------------------------------------------ Modal- CrearTecnico-->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogCrearTecnico" persistent fullscreen hide-overlay>

<v-card>
 <div>

<v-toolbar dark color="indigo darken-4" dense>
  
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogCrearTecnico=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
 </v-card-actions>
    </v-toolbar> 

<v-col cols="12" md="12" sm="12" >
<v-card flat >
<RegistroTecnicos/>
</v-card > 

</v-col>

</div>
</v-card>


</v-dialog>

<!-- ------------------------------------------------------ Modal- CrearActivida-->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogCrearActivida" persistent fullscreen hide-overlay>

<v-card>
 <div>

<v-toolbar dark color="indigo darken-4" dense>
  
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogCrearActivida=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
 </v-card-actions>
    </v-toolbar> 

<v-col cols="12" md="12" sm="12" >
<v-card flat>
<RegistroActividades/>
</v-card > 
</v-col>

</div>
</v-card>


</v-dialog>


<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>



</template>

<script>
import VueAutonumeric from "../../components/AutoNumericNuevo";

import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,changeZone,getParamFecha} from "@/js/Funciones.js";
import RegistroEspecialidades from "@/EventoMantenimiento/Mant/RegistroEspecialidades.vue";
import RegistroTecnicos from "@/EventoMantenimiento/Mant/RegistroTecnicos.vue";
import RegistroActividades from "@/EventoMantenimiento/Mant/RegistroActividades.vue";
import ClientesBasico from "@/CuentaXCobrar/Mant/ClientesBasico.vue";

export default {

   components: {
    VueAutonumeric,
    ContenidoBase,
    RegistroEspecialidades,
    RegistroTecnicos,
    RegistroActividades,
    ClientesBasico

  },

mounted() {


  },

   created(){
  },
  // destroyed(){
  //   location.reload()
  // },

  data:()=> ({


  windowSize:{width:"auto", height:"auto"},
  RMantenimiento:[
    {
      id:1,
      cliente:{id:1,nombre:'jc'},
      frecha:new Date().toISOString().substr(0,10),
    }
  ],


dialogCrearTecnico:false,
RTecnico:[],

Rcliente:[],


valid:true,
dialogCrearcliente:false,
dialogCrearActivida:false,



Requipos:[],
equipo:null,
btnCrearActivida:false,
RActividad:[],
actividad:null,

infCliente:{
direccion:'',
telefono:'',
email:''
},

CrearOrdenes:{
id: 0,
activa: true,
cliente:null,
fecha: fechaNueva(),
fechaestimada: fechaNueva(),
fechapautada: fechaNueva(),
hora: null,
mantenimiento: null,
ordenesMantDetalleList: [],
pendiente: true,
tecnico:null,
materiales:'',
referencia:'',
brigada:''
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },




  ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

       notif: {
        estado: false,
        nombre: "",
        Exec:""
      },



    BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false

}),

 methods: {
   currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,changeZone,getParamFecha,
newwCrearOrdenes() {

this.BarraTablaDinamicaAprir = false

this.actividad=null
this.equipo=null

this.CrearOrdenes={
id: 0,
activa: true,
cliente:null,
fecha: fechaNueva(),
fechaestimada: fechaNueva(),
fechapautada: fechaNueva(),
hora: null,
mantenimiento: null,
ordenesMantDetalleList: [],
pendiente: true,
tecnico:null,
materiales:'',
referencia:'',
brigada:''
},
this.ejecucion = true



var _this=this;
 setTimeout(function(){ _this.$emit("EmitOrdenNuevo") }, 800);


EventBus.$emit("loading", false);

    },

async saveCrearOrdenes() {

 if(this.CrearOrdenes.ordenesMantDetalleList.length==0){ 
   this.alerta(" Agrege al menos un detalle orden", "error"); 
   return 0; 
   }

if(this.$refs.formMant.validate()) {

if(this.ejecucion==true){

  this.ejecucion=false
 

if(this.$refs.formTbEquipo.validate()) {
if(this.CrearOrdenes.pendiente==false && this.CrearOrdenes.hasOwnProperty('mantenimiento') && this.CrearOrdenes.mantenimiento!=null ){
await this.GetMantRealizado()
}

await this.saveRegistroOrden()


}else{
  this.ejecucion=true
  this.alerta("Seleccione los campos en rojo de orden detalle", "error");
 }




}      
}else{

              this.alerta("los campos en rojo son requeridos", "error");

       }

},


async GetMantRealizado(){
var obj=`/ordenes/OrdenByMantenimiento/${this.CrearOrdenes.mantenimiento.id}`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.ActualizarEstadoMant(res.data[0])
  })



},

ActualizarEstadoMant(e){



//verificando si es la ultima orden relacionado al mantenimiento para finalizar el mantenimiento
if(e.id==this.CrearOrdenes.id){
if(this.CrearOrdenes.mantenimiento!=null){
  var datos= JSON.parse(JSON.stringify(this.CrearOrdenes.mantenimiento))
/*var obj={
   datos: JSON.parse(JSON.stringify(this.CrearOrdenes.mantenimiento)),
 }*/

 this.$axios
        .post(this.$hostname + this.$hName + "/mantenimientos/Realizado", JSON.parse(JSON.stringify(datos)),{ headers: this.$store.getters.GetheadersAxios })
        .then(res =>{console.log(res.data)})
}
}

},



async saveRegistroOrden(){ 

  EventBus.$emit("loading", true);
  var datos= JSON.parse(JSON.stringify(this.CrearOrdenes))
  datos.fecha= this.CrearOrdenes.fecha+"T04:00"
  datos.fechaestimada= this.CrearOrdenes.fechaestimada+"T04:00"
  datos.fechapautada=this.CrearOrdenes.fechapautada+"T04:00"

  this.$axios
            .post(this.$hostname + this.$hName + "/ordenes/save",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bienLimpio")
            })
            .catch(error => { this.alerta("Dato no guardado ", "error") });


/*var obj={
   datos: JSON.parse(JSON.stringify(this.CrearOrdenes)),
   usuario:this.$store.getters.GetdatosInfUsu.userid,
 }

 this.$axios
        .post(this.$hostname + this.$hName + "/services/OrdenesMantenimiento/save", JSON.parse(JSON.stringify(obj)))
        .then(res =>{this.alerta("Dato guardado correctamente!", "bienLimpio")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));*/

},





NotifMessage() {

if(this.CrearOrdenes.id!=0 && this.CrearOrdenes.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarCrearOrdenes"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una Orden para eliminar", "error")}


    },

 Exec_notif(e) {

this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

 
eliminar() {
  EventBus.$emit("loading", true);
  var datos= JSON.parse(JSON.stringify(this.CrearOrdenes))
  datos.fecha= this.CrearOrdenes.fecha+"T04:00"
  datos.fechaestimada= this.CrearOrdenes.fechaestimada+"T04:00"
  datos.fechapautada=this.CrearOrdenes.fechapautada+"T04:00"

  this.$axios
            .post(this.$hostname + this.$hName + "/ordenes/Anular",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage, res.data.estado)
            })
            .catch(error => { this.alerta("Dato no anulado ", "error") });
/*var obj={
   datos: JSON.parse(JSON.stringify(this.CrearOrdenes)),
   usuario:this.$store.getters.GetdatosInfUsu.userid,
 }

 this.$axios
        .post(this.$hostname + this.$hName + "/services/OrdenesMantenimiento/Anular", JSON.parse(JSON.stringify(obj)))
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));*/
},

anular(){
},

imprimirList() {
  EventBus.$emit("loading", true);
var obj={
   idcod:this.CrearOrdenes.id
 }

this.$axios.post(this.$hostname + this.$hName +'/ordenes/imprimirOrden', JSON.parse(JSON.stringify(obj)),{ headers: this.$store.getters.GetheadersAxios }).then(res => {
  EventBus.$emit("loading", false);
/*let pdfWindow = window.open("Reporte de imprecion")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>") */
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'Ordenes');
         
      });


    },


cargarManteniemiento(){
  
//  var headers = this.$store.getters.GetheadersAxios
//       this.$axios.get(this.$hostname + this.$hName + '//', {headers})
//           .then(res => (this.RMantenimiento = res.data))


},
SelctCbMantenimiento(){
   if (this.CrearOrdenes.mantenimiento == null) {

        this.CrearOrdenes.mantenimiento = ''
      }

      if (this.CrearOrdenes.mantenimiento.id == undefined) {

        this.alerta("Seleccione un mantenimiento", "error");
        this.CrearOrdenes.mantenimiento = ''
        return

      }

},


CbFilClient(e){
  if (e.target.value.length >= 2) {    
    this.$axios
           .get(
             this.$hostname +
               this.$hName +
               "/clientes/findtodo/" +
             (e.target.value === "" ? "A" : e.target.value) +
             "/3/25/0",
             {headers:this.$store.getters.GetheadersAxios}
           )
           .then(res => (this.Rcliente = res.data));
        }

},


cargarCliente(){

  this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3/25/0',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rcliente=res.data))
},
SelctCbCliente(){

 if (this.CrearOrdenes.cliente == null) {

        this.CrearOrdenes.cliente = ''
      }

      if (this.CrearOrdenes.cliente.codigo == undefined) {

        this.alerta("Seleccione un Cliente", "error");
        this.CrearOrdenes.cliente = ''
        return

      }
},


selectTelefonoCliente(e) {
      if (typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return ' ' }
    },

CbFilTecnico(e){
  if (e.target.value.length >= 2) {    
var obj=`/tecnicos/findAllActivo/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj,{ headers: this.$store.getters.GetheadersAxios })
            .then(res => (this.RTecnico = res.data));
        }

},

cargarTecnico(){

var obj=`/tecnicos/findAllActivo/null`
this.$axios.get(this.$hostname+this.$hName+obj,{ headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.RTecnico=res.data
  })
},
SelctCbTecnico(){

   if (this.CrearOrdenes.tecnico == null) {

        this.CrearOrdenes.tecnico = ''
      }

      if (this.CrearOrdenes.tecnico.id == undefined) {

        this.alerta("Seleccione un tecnico", "error");
        this.CrearOrdenes.tecnico = ''
        return

      }

},


CbFilEquipo(e){
  if (e.target.value.length >= 2) {    
    var obj=`/mantenimientos/findAllProductoCombo/25/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj, { headers: this.$store.getters.GetheadersAxios })
            .then(res => (this.Requipos = res.data));
        }

},


cargarEquipo(){
  var obj=`/mantenimientos/findAllProductoCombo/25/null`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.Requipos=res.data
  })
},
SelctCbEquipo(){

   if (this.equipo == null) {

        this.equipo = ''
      }

      if (this.equipo.codigo == undefined) {

       // this.alerta("Seleccione una equipo", "error");
        this.equipo = ''
        return

      }


},

cargarActividad(){
  var obj=`/actividades/findAllActivo/null`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{this.RActividad=res.data})
},

CbFilRegAct(e){
  if (e.target.value.length >= 2) {    
    var obj=`/actividades/findAllActivo/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj,{ headers: this.$store.getters.GetheadersAxios })
            .then(res => (this.RActividad = res.data));
        }

},


SelctCbActividad(){

   if (this.actividad == null) {

        this.actividad = ''
      }

      if (this.actividad.id == undefined) {

       // this.alerta("Seleccione una actividad", "error");
        this.actividad = ''
        return

      }

},



KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}

if(e.target.value.length==0){e.target.value=0}

  if(e.key){
     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     if(e.target.value==null){ e.target.value=0 }

    }
    else{e.key=""}

if(e.target.value.length==0){ e.target.value=0
        e.key=0
     }
  
},




AgregarOrdenDetalle(){

if(this.$refs.formEquipo.validate()) { 
var jsn={
  id:0,
  producto:JSON.parse(JSON.stringify(this.equipo)),
  actividad: JSON.parse(JSON.stringify(this.actividad)),
  descripcion: this.equipo.descripcion
}

this.CrearOrdenes.ordenesMantDetalleList.push(jsn)


}




},

deleEquipo(item){

const index =  this.CrearOrdenes.ordenesMantDetalleList.indexOf(item)
confirm('Desea eliminar este detalle ?') && this.CrearOrdenes.ordenesMantDetalleList.splice(index, 1)

},

SelctCbActividadTb(e){ 
if( e!= null && !e.hasOwnProperty('id')){e=null}
},


SelectOrdeComponent(e){
console.log('Entrando al ManteOrden')
console.log(e)
},


alerta(e, l) {


 if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);

      }
  
      if (l == "bienLimpio") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwCrearOrdenes();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

  },


computed:{

    ActDescBtn(){


if( this.CrearOrdenes.cliente!=null && this.CrearOrdenes.cliente.hasOwnProperty('codigo')){
  this.infCliente=JSON.parse(JSON.stringify(this.CrearOrdenes.cliente))


if(this.CrearOrdenes.cliente.hasOwnProperty('telefonosClientesList') && this.CrearOrdenes.cliente.telefonosClientesList.length >0 ){
  this.infCliente.telefono= JSON.parse(JSON.stringify(this.CrearOrdenes.cliente.telefonosClientesList[0].telefonosClientesPK.telefono))
}else{ this.infCliente.telefono=""}

}else{
  this.infCliente={
direccion:'',
telefono:'',
email:''
}
}


if(this.CrearOrdenes.id>0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}


if(this.CrearOrdenes.id<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


}
     

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>