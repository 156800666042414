<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
  <!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ver Conduce
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>ver Conduce: </span> Ver Conduce</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>

    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click="cancelarConduceAfactura()" color="orange"
        small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

</v-card-actions>
</v-toolbar>
<!-- fn v-toolbar accion  -->
<v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="ConduceBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="ConduceBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="ConduceBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta"  -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" autocomplete="off" dense outlined label="cliente:" :items="RCliente"
                        item-text="codnom" style="font-size: 13px;" v-model="ConduceBusqueda.cliente"
                        @blur="SelctCbConduceBuscar" @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter>
                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getConducelistFactura" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0">
              <v-data-table class="RowSelectColor" item-key="indx" @click:row="VerConduceDatostabla" :headers="[
                { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                { text: '# Secuencia', sortable: false, value: 'conduceInventarioPK.secuencia', width: 129 },
                { text: 'Cliente', sortable: false, value: 'cliente', width: 129 },
                { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
              ]" dense :items="VerConducelistFactCliente">
                <template v-slot:item.fecha="props">
                  <span style="font-size:13px; display:none;">{{ props.item.indx =
                  VerConducelistFactCliente.indexOf(props.item)
                  }}</span>
                  <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                </template>

                <template v-slot:item.conduceInventarioPK.secuencia="props">
                  <span style="font-size:13px;">{{ props.item.documentoinventario.nombrecorto }} - {{
                    props.item.conduceInventarioPK.secuencia }} </span>
                </template>

                <template v-slot:item.cliente="props">
                  <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                  </span>
                </template>

                <template v-slot:item.valor="props">
                  <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                </template>
              </v-data-table>
            </v-col>

          </v-card>


</v-col>

<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    dialogBucarConduce: false,

    VerConducelistFactCliente: [],
    RCliente:[],
    ConduceBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },
    TotalRegistros: 1,
    resertPagi: true,
    ReimprecionPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,
  
// Hacer una llamada API al servidor y devolver los datos de la cotizacion.
getConducelistFactura() {
  this.VerCotizacionlistFactCliente = [];
      var sec,
        fecini,
        fecfin,
        cliente = null;
      if (this.ConduceBusqueda.secuencia != null) {
        sec = this.ConduceBusqueda.secuencia;
      }
      if (this.ConduceBusqueda.fechaInicial != null) {
        fecini = this.ConduceBusqueda.fechaInicial;
      }
      if (this.ConduceBusqueda.fechaFinal != null) {
        fecfin = this.ConduceBusqueda.fechaFinal;
      }
      if (this.ConduceBusqueda.cliente != null) {
        cliente = this.ConduceBusqueda.cliente;
      }

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reimpresion/conduce/" +
          sec +
          "/" +
          fecini +
          "/" +
          fecfin +
          "/" +
          cliente +
          "/" +
          this.ReimprecionPaginator.itemsPerPage +
          "/" +
          this.ReimprecionPaginator.pageStart,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          //*this.TotalRegistros = res.data.cantidad,
          this.VerConducelistFactCliente = res.data.listas;
        });
},

// Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
NewBucarConduce() {
      this.CotizacionBusqueda = {
        secuencia: 0,
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
        cliente: null,
      };
    },


    cancelarConduceAfactura() {
      this.dialogBucarConduce = false;
      this.NewBucarConduce();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarConduceAfactura", this.dialogBucarConduce)
     }, 300);
    },


     // Llamar a una API y obtener los datos del cliente.
    cargarClienteBusqueda() {
      var filtroTipoCliente=3
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + filtroTipoCliente + "/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      var filtroTipoCliente=3
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/" + filtroTipoCliente + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },
    SelctCbConduceBuscar() {
      if (this.ConduceBusqueda.cliente == null) {
        this.ConduceBusqueda.cliente = "";
      }
      if (this.ConduceBusqueda.cliente.codigo == undefined) {
        this.ConduceBusqueda.cliente = "";
        this.alerta("Seleccione un cliente", "error");
        return;
      }
    },

    async VerConduceDatostabla(e){
    
      var OBJ = {
    headerFactura:{
      // fecha:getParamFecha(e.fecha).split("T")[0],
      fecha:fechaNueva(),
      listaPrecio:null,
      tipocomprobante:null,
      documento:null,
      cliente:null,
      sucursal:null,
      moneda:null,
      vendedor:null,
      diascredito:0,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false
    },
    }
    OBJ.conduceSelect=e.conduceInventarioPK;
    if (e.hasOwnProperty('cliente')&&e.cliente != null) {
        OBJ.headerFactura.cliente = JSON.parse(JSON.stringify(e.cliente))
        }
        OBJ.headerFactura.tipocomprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
        OBJ.headerFactura.moneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase

        if(e.conduceInventarioDetalleList.length > 0){
          var listCondDetalle = JSON.parse(JSON.stringify(e.conduceInventarioDetalleList))
          listCondDetalle.sort(function (a, b) {
            if (a.id > b.id) { return 1; }
            if (a.id < b.id) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          (async () => {  
            const restrList =  await this.ContConduceDatostabla(listCondDetalle,OBJ);

          })()



        }
    },


    async ContConduceDatostabla(listCondDetalle,data){  
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var bar = new Promise((resolve, reject) => {
        listCondDetalle.forEach(async (element) => {
           (async () => { 
            const resUniPro =  await this.findUnidaProducto(element.producto.codigo,element.unidad.codigo);
            if(resUniPro!=null){
                var Obj =JSON.parse(JSON.stringify(resUniPro))
                Obj.descripcion=`${element.producto.descripcion.trim()} ${element.unidad.descripcion.trim()}` 
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false
                // Obj.precio = element.precio
                /*Obj.precio = resUniPro.precio
                Obj.precioOrig = resUniPro.precio
                Obj.precioDig = resUniPro.precio*/
                Obj.precio = element.precio
                Obj.precioOrig = element.precio
                Obj.precioDig = element.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                ListDetalleTemp.push(Obj)
            } 
            })()
            }); 
            resolve()
      });

      bar.then(() => {
        OBJ.listConduceDetalle=ListDetalleTemp
        this.cancelarConduceAfactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornConduceAfactura", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },

    async findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},

alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },


    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>