<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<tr max-height="48px" height="48px"  >     
<ContenidoBase 
  :titulo="'Reporte Comisiones de Vendedores por Cobro'"
  :descripcion="'Imprime las Comisiones a Pagar a los Vendedores.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
</tr>
  
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReporteComisionesVendedores'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
<!-- Formulario-Comisiones de Vendedores por Cobro -->
 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Reporte Comisiones de Vendedores por Cobro </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="ComisionesVendedores.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<!-- -----------------------------  Cliente  -->
<v-combobox 
                        autocomplete="off"
                        dense
                        outlined
                        label="Cliente:"
                        :items="Rcliente"
                        item-text="codnom"
                        @keyup="CbFilcliente"
                        v-model="ComisionesVendedores.cliente"
                        @blur="SelctCbClient"
                        @focus="cargarCliente"
                        no-filter
                      >
                      <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i
                            style="font-size: 30px;"
                            class="fa fa-user-circle-o"
                          ></i>
                        </template>
                      </v-combobox>


<!-- ----------------------------- vendedores -->
<v-combobox
                    v-model="ComisionesVendedores.vendedor"
                    label=" Vendedor:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="RVendedores"
                    item-text="nombre"
                    @blur="SelctCbVendedores"
                    @focus="cargarVendedores"
                    
                    style="font-size: 13px;"


                  >            
<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Descripción: {{item.item.nombre}}</b></p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Cedula.: </b> {{item.item.cedula }} </span>
   <span class="pe-1" style="border: 1px dashed  #616161;"><b>Telefono: </b> {{item.item.telefono }} </span>
 <!-- <span class="pe-1" style="border: 1px dashed  #616161;"><b>Placa: </b> {{item.item.placa }} </span> -->
</span>
</v-col>
 
</template>

  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-universal-access"></i> 
 </template>   

                        </v-combobox>
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="ComisionesVendedores.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<!-- ----------------------------- Tipo de Cliente -->
<v-combobox 
            autocomplete="off"
            dense
            outlined
            label="Tipo de Cliente:"
            :items="RtipoCliente"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="ComisionesVendedores.tipocliente"
            @blur="SelctCbTipoCliente"
            @focus="cargarTipoCliente"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-users"></i>
            </template>
          </v-combobox>


</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>


<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="ComisionesVendedores"
/>


  </v-card>
 </v-container>
<!-- Formulario-Comisiones de Vendedores por Cobro -->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
 
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
    

  },
  created() { 
    var json = { 
      titulo: "Reporte Comisiones de Vendedores por Cobro",
      descripcion: "Imprime las Comisiones a Pagar a los Vendedores.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [ 

        "ReporteComisionesVendedoresNew",
        "ReporteComisionesVendedoresSave",
        "ReporteComisionesVendedoresRemove",
        "ReporteComisionesVendedoresAnular",
        "ReporteComisionesVendedoresPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() { 
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReporteComisionesVendedoresNew", this.new);
    EventBus.$on("ReporteComisionesVendedoresSave", this.save);
    EventBus.$on("ReporteComisionesVendedoresRemove", this.remove);
    EventBus.$on("ReporteComisionesVendedoresAnular", this.remove);
    EventBus.$on("ReporteComisionesVendedoresPrintList", this.imprimirList);
    EventBus.$on("emitReporteComisionesVendedores", this.datostabla);
    
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({


      Rpropietario:[],
      Rcliente:[],
      RCaja:[],
      RtipoCliente:[],
      RVendedores:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      ComisionesVendedores: {
        nombre:'Reporte Comisiones de Vendedores por Cobro',
        UrlImprimir: '/reportescxc/ImprimirComisionesVendedores', 
        UrlExel: '/reportescxc/ExcelComisionesVendedores', 
        UrlCorreo: '/reportescxc/CorreoComisionesVendedores', 
        desde: fechaNueva(),
        hasta: fechaNueva(),
        cliente:null,
        vendedor:null,
        tipocliente:null
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },
EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
    

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.ComisionesVendedores = e;
      this.acti_tb_data=false
    },
    save() {},
    remove() {},
    eliminar() {},
    anular() { },
    imprimirList() {},
    //Retorna los objectos del componente ModalReporteFiltros
    DDataReporteBasico(){},
    newwnuevo() {
      (this.ComisionesVendedores = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },




// Llamar a una API y obtener los datos.
cargarCliente(){
   
this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3/25/0',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rcliente=res.data))

},


// Comprobando si el cliente es nulo o indefinido.
SelctCbClient() {
      if (this.ComisionesVendedores.cliente == null) {
        this.ComisionesVendedores.cliente = "";
      }

      if (this.ComisionesVendedores.cliente.codigo == undefined) {
      //  this.alerta("Seleccione un cliente", "error");
        this.ComisionesVendedores.cliente = "";
        return;
      }

    },

CbFilcliente(e) {
      //console.log(e.target.value)

if (e.target.value.length >= 0) {
         
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/clientes/findtodo/" +
                (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
              "/3/25/0",
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rcliente = res.data));
        }


},




// Llamar a una API y obtener los datos de la API y almacenarlos en la variable RtipoCliente.
cargarTipoCliente(){

this.$axios.get(this.$hostname+this.$hName+'/tipoclientes/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RtipoCliente=res.data))

},


// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbTipoCliente(){

 if(this.ComisionesVendedores.tipocliente==null){

    this.ComisionesVendedores.tipocliente=''
  }

if(this.ComisionesVendedores.tipocliente.codigo==undefined){

//this.alerta("Seleccione un tipo de cliente", "error");
this.ComisionesVendedores.tipocliente=''
return

}

},



// Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
cargarVendedores(){

 this.$axios.get(this.$hostname + this.$hName + '/vendedores/findallactiv', {headers:this.$store.getters.GetheadersAxios}
   ).then(res => {this.RVendedores = res.data;});

},

// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbVendedores(){

 if(this.ComisionesVendedores.vendedor==null){

    this.ComisionesVendedores.vendedor=''
  }

if(this.ComisionesVendedores.vendedor.codigo==undefined){

//this.alerta("Seleccione un vendedor", "error");
this.ComisionesVendedores.vendedor=''
return

}

},


// Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
BlurDesde() {

if (this.ComisionesVendedores.desde.toString().length == 0) {
  this.ComisionesVendedores.desde = this.fechaNueva()
}


},


// Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
BlurHasta() {

if (this.ComisionesVendedores.hasta.toString().length == 0) {
  this.ComisionesVendedores.hasta = this.fechaNueva()
}

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
