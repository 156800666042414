<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Consulta Facturas'"
  :descripcion="'Consulta Facturas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBancos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
             
<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2" > 
<v-row class="pa-0 ma-0 py-0 my-0" >

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2 py-1">       
<v-combobox

            v-model="ConsultaFacturas.documento"
            autocomplete="off"
            dense
            outlined
            label="Documentos:"
            :items="RDocumento"
            item-text="descripcion"
            @blur="SelctCbDocumento"
            @focus="cargarDocumento"
            style="font-size: 13px;"
            hide-details
            @change="BuscarFactura"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-book"></i>
            </template>
          </v-combobox>
</v-col>

<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 px-2 py-1">   
<v-text-field
                  
                        label="No.Factura:"
                        autocomplete="off"
                        dense
                        outlined
                        v-model.number="ConsultaFacturas.factura"
                        @keyup="KeyUpCantidad"
                        placeholder=" "
                        style="font-size: 12px;"
                         hide-details
                         @blur="BuscarFactura"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-slack"></i>
                        </template>
                      </v-text-field>
</v-col>


<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 px-2 py-1">   
<v-textarea
                  autocomplete="off"
                    label="Concepto:"
                    rows="1"
                    dense
                    outlined
                    v-model="ConsultaFacturas.concepto"
                    style="font-size: 12px;"
                    hide-details
                    @blur="BuscarFactura"
                    
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-commenting"></i>
                    </template>
                  </v-textarea>
</v-col>
<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 px-2 py-1">  
 <v-text-field
                label="NCF"
                autocomplete="off"
                dense
                outlined
                v-model="ConsultaFacturas.ncf "
                 style="font-size: 12px;"
                 hide-details
                 @blur="BuscarFactura"
             
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>

</v-col>
<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2 py-1">  

  <v-combobox
            autocomplete="off"
            dense
            outlined
           
            label="Succursal:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="ConsultaFacturas.sucursal"
            @blur="SelctCbSucursal"
            @focus="cargarSucursal"
            hide-details
            @change="BuscarFactura"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>
</v-col>

<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 px-2 py-1">
  <v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Desde"
         :rules="[$rules.required]"
        v-model="ConsultaFacturas.desde"
        hide-details
        style="font-size: 12px;"
        @blur="BlurDesde"
        @input="BuscarFactura"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field> 
</v-col>

<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 px-2 py-1">
  <v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Hasta"
         :rules="[$rules.required]"
        v-model="ConsultaFacturas.hasta"
        hide-details
        style="font-size: 12px;"
        @blur="BlurHasta"
        @input="BuscarFactura"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 px-2 py-1">
  <v-btn
              color="light-blue accent-2"
              target="_blank"
              @click.prevent="ReporteImprimir"
              :disabled="texAria.length<=0"
          >
            <i style="font-size: 28px;" class="fa fa-print"> </i>

            <span style="color:#FFFFFF;">Printer </span>
          </v-btn
          >
</v-col>
</v-row>            
</v-col>   


<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" > 

<v-row class="pa-0 ma-0 py-0 my-0" >

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2 py-1">

  <div style=" max-height:420px" class="overflow-x-auto"  >
  <v-textarea disabled  id="formdisabledInput" style="font-size: 12px;"  solo :value="texAria" :rows="rowDesTab"></v-textarea>
</div>


<!-- <v-card style=" min-height:400px" >
  <v-card-text class="black--text"  >
    {{texAria}}
  </v-card-text>
</v-card> -->

</v-col>   
<v-col cols="12" md="8" sm="8" class="pa-0 ma-0 px-2 py-1">
<div style="border: 1px solid #000000;" >
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      v-model="selectedListFact"
      :single-select="true"
      :show-select="true"
      @item-selected="obtenerDatoSelectFact"
      @click:row="obtenerDatoSelectFactClickRow"
      item-key="indx"

      :headers="[
                { text: 'Factura', sortable: false,  value: 'fact', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'Fecha', sortable: false, value: 'fecha', width:120, class: ['no-gutters black--text'] },
                 { text: 'Valor', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text'] },
                 { text: 'Comentario', sortable: false, value: 'comentario', width:250, class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listDatos"
              :search="search"      
              :items-per-page="15"
            >


<template v-slot:item.fecha="props">
  <span style="display:none;">{{props.item.indx = listDatos.indexOf(props.item)}}</span>
    <span>{{FormatoFecha(props.item.fecha) }}</span>
</template>

<template v-slot:item.fact="props">
<span>{{ props.item.documentoFactura.nombrecorto+"-"+props.item.facturasventaPK.secuencia }}</span>
</template>

<template v-slot:item.valor="props">
  <span  >{{currencyFormatter(props.item.valor )}}</span>
 </template>

</v-data-table>

 </div>
</v-col> 

</v-row>
</v-col>   







              

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


     <!-- ------------------------------------------------------  Modal Loader -->

    <v-dialog
        v-model="Reparaloading"

        persistent
        width="300"

    >
      <v-card
          color="grey darken-2"
          dark
      >
        <v-card-text>
          <br></br>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import numeral from 'numeral'
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter,getParamFecha} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
    var json = {
      titulo: "Consulta Facturas",
      descripcion: "Consulta Facturas",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "ConsultaFacturasNew",
        "ConsultaFacturasSave",
        "ConsultaFacturasRemove",
        "ConsultaFacturasAnular",
        "ConsultaFacturasPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ConsultaFacturasNew", this.new);
    // EventBus.$on("ConsultaFacturasSave", this.save);
    // EventBus.$on("ConsultaFacturasRemove", this.remove);
    // EventBus.$on("ConsultaFacturasAnular", this.remove);
    // EventBus.$on("ConsultaFacturasPrintList", this.imprimirList);
    EventBus.$on("emitConsultaFacturas", this.datostabla);
    EventBus.$on("onResize", this.onResize);

this.BuscarFactura()

  },

  data:()=>  ({


     
      listDatos:[],
      selectedListFact:[],
      RDocumento:[],
      RSucursal:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      texAria:"",
      rowDesTab:25,
      Reparaloading:false,
      ConsultaFacturas: {
        documento:null,
        factura:null,
        ncf:null,
        sucursal:null,
        concepto:null,
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,getParamFecha,
onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    
    datostabla(e) {

      this.ConsultaFacturas = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() {},
    anular() { },
    imprimirList() {},
    newwnuevo() {
      (this.ConsultaFacturas = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarDocumento(){

// this.$axios.post(this.$hostname+this.$hName+'/services/utilitarioFacturaVentas/DocumentoPvFindall',{
// usuario:JSON.parse(JSON.stringify(this.$user))
  
// })
// .then(res=>( this.RDocumento=res.data))


this.$axios.get(this.$hostname + this.$hName + "/documentoventas/documentoFacturaVentas/",{headers:this.$store.getters.GetheadersAxios})
    .then(res => (this.RDocumento = res.data))
    .catch(error => console.log(error));

},

SelctCbDocumento(){
  if(this.ConsultaFacturas.documento==null){

    this.ConsultaFacturas.documento=''
  }

if(this.ConsultaFacturas.documento.codigo== undefined){

this.alerta("Seleccione un documento", "error");
this.ConsultaFacturas.documento=''
return

}

},

cargarSucursal(){

this.$axios.get(this.$hostname+this.$hName+'/sucursal/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RSucursal=res.data))

},

SelctCbSucursal(){

 if(this.ConsultaFacturas.sucursal==null){

    this.ConsultaFacturas.sucursal=''
  }

if(this.ConsultaFacturas.sucursal.codigo==undefined){

this.alerta("Seleccione un sucursal", "error");
this.ConsultaFacturas.sucursal=''
return

}
},

BlurDesde(){
if(this.ConsultaFacturas.desde.toString().length==0){
  this.ConsultaFacturas.desde=new Date().toISOString().substr(0,10)
}

 },

 BlurHasta(){

if(this.ConsultaFacturas.hasta.toString().length==0){
  this.ConsultaFacturas.hasta=new Date().toISOString().substr(0,10)
}

 },


BuscarFactura(){


if(this.ConsultaFacturas.factura=="" || this.ConsultaFacturas.factura==0){this.ConsultaFacturas.factura=null}



var obj= JSON.parse(JSON.stringify(this.ConsultaFacturas))

if(this.ConsultaFacturas.documento!=null){  obj.documento=this.ConsultaFacturas.documento.codigo}
if(this.ConsultaFacturas.sucursal!=null){  obj.sucursal=this.ConsultaFacturas.sucursal.codigo}

this.$axios
        .post(this.$hostname + this.$hName +"/ConsultaRapida/ConsultaFacturas",obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ this.GetDataFact(res.data) })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});


},


GetDataFact(e){

this.listDatos=[]
this.listDatos=e

},


obtenerDatoSelectFact(e){

this.obtenerDatoSelectFactClickRow(e.item)
},
obtenerDatoSelectFactClickRow(e){
var obj=JSON.parse(JSON.stringify(e))
obj.horaFactura=new Date(e.horaFactura);
this.texAria=""

if(e.fecha!=null){
          var fecnac=this.getParamFecha(e.fecha)
          obj.fecha=fecnac.split("T")[0]
        }

this.selectedListFact=[]
this.selectedListFact.push(e)
console.log(obj)

this.$axios
        .post(this.$hostname + this.$hName +"/ConsultaRapida/GetFacturas",obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
           this.texAria=res.data,this.ver(res.data)
        })
        .catch(error => {});



},


ver(e){

// this.au=

var au =e.split("\n").length
// this.rowDesTab=1+parseInt(e.length/104)+au
this.rowDesTab=(au+2)

},


ReporteImprimir(){

if(this.selectedListFact.length==1){

// this.Reparaloading=true
EventBus.$emit("loading", true);
var obj=JSON.parse(JSON.stringify(this.selectedListFact[0]))
//obj.horaFactura=new Date(this.selectedListFact[0].horaFactura);
      this.$axios
      .post(this.$hostname + this.$hName +"/ConsultaRapida/ImprimirFactura",obj,{headers:this.$store.getters.GetheadersAxios})
      .then(res =>{ 
        // this.Reparaloading=false
        EventBus.$emit("loading", false);
      setTimeout(function () {
            
              /*let pdfWindow = window.open("Reporte de imprecion")
              pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
                  encodeURI(res.data) + "'></embed>")*/
         
                  var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
                  EventBus.$emit("openPDF",PDF ,'Imprimir factura');

                }, 1000);
      
      })
      .catch(error => {
        // this.Reparaloading=false
        EventBus.$emit("loading", false);
      
      });
}


},






alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {},




KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}

if(e.target.value.length==0){

e.target.value=0

}


  if(e.key){

     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(e.target.value==null){

        e.target.value=0
     }

    }
    else{

      e.key=""

     //console.log("no se puede poner letras o signos")
    }


if(e.target.value.length==0){

        e.target.value=0
        e.key=0
     }
    

    
},

  },

computed:{

  ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}

};
</script>

<style></style>
