<template>
  <v-toolbar dense width="100%" absolute color="indigo darken-4" dark v-resize="ScrUpdate">
    <v-app-bar-nav-icon v-if="EstadoBtn.BarraTablaDinamicaAprir == true" @click.stop="AprirBarraTablaDinamica">
    </v-app-bar-nav-icon>

    <v-menu>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-toolbar-title dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">{{ titulo }}</v-toolbar-title>

          </template>

          <i style="font-size: 20px;" v-if="windowSize.width > 600" dark dense class="fa fa-info-circle">
            <span> {{ descripcion }} </span></i>

          <i style="font-size: 20px;" v-if="windowSize.width <= 600" dark dense class="fa fa-info-circle">
            <span> {{ titulo }}: </span> {{ descripcion }}</i>

        </v-tooltip>
      </template>
    </v-menu>

    <!-- <v-tooltip bottom v-if="windowSize.width<=600" >
  
<template v-slot:activator="{ on }">

  <v-toolbar-title  v-on="on">
            {{ titulo }}
  </v-toolbar-title>

 </template >
 <i style="font-size: 20px;"  dark dense class="fa fa-info-circle"> 
 <span > {{titulo}}: </span>  {{ descripcion }}</i>

  </v-tooltip>

<v-tooltip bottom v-if="windowSize.width>600" >
  
<template v-slot:activator="{ on }">


  <v-toolbar-title  v-on="on"> 
            {{ titulo }}
  </v-toolbar-title>

 </template  >
 <i style="font-size: 20px;"  dark dense class="fa fa-info-circle"> 
  <span > {{descripcion}} </span></i>

  </v-tooltip> -->


    <v-spacer></v-spacer>

    <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" max-width="900px" color="indigo darken-4">
      <v-btn-toggle v-model="toggle_exclusive" color="indigo" style="margin-right:50px;">
        <v-btn id="boton-mini" color="#90A4AE" dark small v-if="EstadoBtn.hasOwnProperty('nuevo') && EstadoBtn.nuevo"
          @click.prevent="MethodNuevo">Nuevo
          <v-icon dark right>mdi-file</v-icon>
        </v-btn>

        <v-btn id="boton-mini" color="success" dark small
          v-if="EstadoBtn.hasOwnProperty('guardar') && EstadoBtn.guardar" @click.prevent="MethodGuardar">Salvar
          <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
        </v-btn>

        <v-btn id="boton-mini" color="red" dark small v-if="EstadoBtn.hasOwnProperty('eliminar') && EstadoBtn.eliminar"
          @click.prevent="MethodEliminar">Eliminar
          <i style="font-size: 18px;" dark right class="fa fa-trash"></i>
        </v-btn>

        <v-btn id="boton-mini" color="orange" dark small v-if="EstadoBtn.hasOwnProperty('anular') && EstadoBtn.anular"
          @click.prevent="MethodAnular">Anular
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>

        <v-btn id="boton-mini" color="blue" dark small v-if="EstadoBtn.hasOwnProperty('imprimir') && EstadoBtn.imprimir"
          @click.prevent="MethodImprimir">Imprimir
          <v-icon dark right>mdi-printer-check</v-icon>
        </v-btn>

        <v-btn id="boton-mini" color=" blue lighten-3" dark small
          v-if="EstadoBtn.hasOwnProperty('imprimircuadre') && EstadoBtn.imprimircuadre"
          @click.prevent="MethodImprimircuadre">
          Imprimir Cuadre
          <v-icon dark right>mdi-printer-check</v-icon>
        </v-btn>
        <v-btn id="boton-mini" color=" blue lighten-3" dark small
          v-if="EstadoBtn.hasOwnProperty('imprimircierre') && EstadoBtn.imprimircierre"
          @click.prevent="MethodImprimircierre">
          Imprimir Cierre
          <v-icon dark right>mdi-printer-check</v-icon>
        </v-btn>

        <v-btn id="boton-mini" color=" blue lighten-3" dark small
          v-if="EstadoBtn.hasOwnProperty('factura') && EstadoBtn.factura"
          @click.prevent="MethodFactura">
          Factura
          <i style="font-size: 18px;" class="fa fa-wpforms"> </i>
        </v-btn>

        <v-btn id="boton-mini" color="teal darken-2" dark small
          v-if="EstadoBtn.hasOwnProperty('vercuenta') && EstadoBtn.vercuenta" @click.prevent="MethodVeercuenta">Ver
          Cuenta

          <i style="font-size: 18px;" class="fa fa-eye"> </i>

        </v-btn>

        <v-btn id="boton-mini" color="green lighten-1" dark small
          v-if="EstadoBtn.hasOwnProperty('guardartemporal') && EstadoBtn.guardartemporal"
          @click.prevent="MethodGuardarTemporal">Salvar temporal
          <i style="font-size: 18px;" class="fa fa-hourglass-half"> </i>

        </v-btn>


        <v-btn id="boton-mini" color="teal lighten-2" dark small
          v-if="EstadoBtn.hasOwnProperty('restaurartemporal') && EstadoBtn.restaurartemporal"
          @click.prevent="MethodRestaurarTemporal">Restaurar Temporal
          <i style="font-size: 18px;" class="fa fa-history"> </i>

        </v-btn>

        <v-btn id="boton-mini" color="grey lighten-1" dark small
          v-if="EstadoBtn.hasOwnProperty('importarDato') && EstadoBtn.importarDato" @click.prevent="MethodImportarDato">
          Importar Datos
          <i style="font-size: 18px;" class="fa fa-cloud-upload"> </i>
        </v-btn>

        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="blue lighten-1" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('cargarProducto') && EstadoBtn.cargarProducto"
              @click.prevent="MethodCargarProducto">Cargar Productos
              <i style="font-size: 18px;" class="fa fa-shopping-cart"> </i>
            </v-btn>

          </template>
          <span>Cargar Productos para Inventario físico</span>
        </v-tooltip>


        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" v-on="on" color="blue lighten-3" dark small
              v-show="EstadoBtn.hasOwnProperty('ImprimirproductoInv') && EstadoBtn.ImprimirproductoInv"
              @click.prevent="MethodImprimirproductoInv"> Imprimir Productos
              <i style="font-size: 18px;" class="fa fa-print"> </i>
            </v-btn>
          </template>
          <span>Imprimir Productos para inventario físico</span>
        </v-tooltip>



        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="orange lighten-2" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('etiquetaXfactura') && EstadoBtn.etiquetaXfactura"
              @click.prevent="MethodEtiquetaXFactura">Etiqueta por factura
              <i style="font-size: 18px;" class="fa fa-tags"> </i>
            </v-btn>

          </template>
          <span>Etiqueta por factura</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="orange lighten-2" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('reimprimirEtiqueta') && EstadoBtn.reimprimirEtiqueta"
              @click.prevent="MethodReimprimirEtiqueta">Reimprimir Etiqueta
              <i style="font-size: 18px;" class="fa fa-tags"> </i>
            </v-btn>

          </template>
          <span>Etiqueta por factura</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="blue lighten-2" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('AplicarProducion') && EstadoBtn.AplicarProducion"
              @click.prevent="MethodAplicarProducion">Aplicar Produción
              <i style="font-size: 18px;" class="fa fa-shopping-cart"> </i>
            </v-btn>

          </template>
          <span>Aplicar Produción</span>
        </v-tooltip>



        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="blue lighten-3" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('CompraSugeridas') && EstadoBtn.CompraSugeridas"
              @click.prevent="MethodCompraSugeridas">Compra sugeridas
              <i style="font-size: 18px;" class="fa fa-cart-arrow-down"> </i>
            </v-btn>

          </template>
          <span>Ordenes de compra sugeridas</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="blue lighten-1" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('ProductoProveedores') && EstadoBtn.ProductoProveedores"
              @click.prevent="MethodProductoProveedores">Producto por Proveedores
              <i style="font-size: 18px;" class="fa fa-shopping-basket"> </i>
            </v-btn>

          </template>
          <span>Producto por Proveedores</span>
        </v-tooltip>


        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn id="boton-mini" color="blue lighten-1" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('Buscar') && EstadoBtn.Buscar"
              @click.prevent="MethodBuscar">Buscar
              <i style="font-size: 18px;" class="fa fa-search"> </i>
            </v-btn>

          </template>
          <span>Buscar</span>
        </v-tooltip>

        <!--  -->


        <v-tooltip top>
          <template v-slot:activator="{ on }">

            <v-btn id="boton-mini" color="light-green lighten-1" v-on="on" dark small
              v-show="EstadoBtn.hasOwnProperty('listaRecibo') && EstadoBtn.listaRecibo"
              @click.prevent="MethodListaReciboIngreso">Lista Recibos
              <i style="font-size: 18px;" class="fa fa-list-alt"> </i>
            </v-btn>

          </template>
          <span>Lista de recibos de ingresos</span>
        </v-tooltip>


        <!-- <v-btn color="blue" dark small @click="abrirBarraderecha">
                    <v-icon dark left>mdi-dots-vertical</v-icon>
                  </v-btn> -->
      </v-btn-toggle>
    </v-sheet>
    <!-- <v-btn icon >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->



  </v-toolbar>

</template>

<script>
import { EventBus } from "../event-bus.js";
export default {


  mounted() {


    EventBus.$on("onResize", this.onResize);
    this.ScrUpdate()

    //this.titulo= JSON.parse(JSON.stringify(this.titulo))
    //this.descripcion= JSON.parse(JSON.stringify(this.descripcion))

    //console.log('Ohhhhhh La pampara ContenidoBase')



  },

  created() {
    //console.log("Veeeeerrr")
  },


  data: () => ({

    windowSize: { width: "auto", height: "auto" },
    toggle_exclusive: false,
    nuevoTiposClientes: {
      activo: true,
      borrado: false,
      codigo: 0,
      descripcion: "",
      descripcionOriginal: "",
      //fechaCreacion: new,
      //  fechaModificacion: "",
      usuarioCreacion: "Admin",
      usuarioModificacion: "",
      empresa: 1
    },



    files: [],
    text: "",
    valid: true,
    BarraPrimariaAbrir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),
  methods: {


    ScrUpdate() {

      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 }
      this.onResize(jsn)
      //EventBus.$emit("onResize", jsn);

    },


    onResize(e) {

      this.windowSize = e
      //this.windowSize={width:e.width, height:e.height}

    },

    AprirBarraTablaDinamica() {
      this.$emit("abrirBarraTablaDinamica")
    },
    MethodNuevo() {
      this.$emit("MethodNuevo")
    },
    MethodGuardar() {
      this.$emit("MethodGuardar")
    },
    MethodEliminar() {
      this.$emit("MethodEliminar")
    },
    MethodAnular() {
      this.$emit("MethodAnular")
    },
    MethodImprimir() {
      this.$emit("MethodImprimir")
    },

    MethodImprimircuadre() {
      this.$emit("MethodImprimircuadre")
    },
    MethodImprimircierre() {
      this.$emit("MethodImprimircierre")
    },

    MethodFactura(){
      this.$emit("MethodFactura")
    },
    MethodVeercuenta() {
      this.$emit("MethodVeercuenta")
    },
    MethodGuardarTemporal() {
      this.$emit("MethodGuardarTemporal")
    },
    MethodRestaurarTemporal() {
      this.$emit("MethodRestaurarTemporal")
    },
    MethodImportarDato() {
      this.$emit("MethodImportarDato")
    },
    MethodCargarProducto() {
      this.$emit("MethodCargarProducto")
    },
    MethodImprimirproductoInv() {
      this.$emit("MethodImprimirproductoInv")
    },
    
    MethodReimprimirEtiqueta() {
      this.$emit("MethodReimprimirEtiqueta")
    },

    MethodEtiquetaXFactura() {
      this.$emit("MethodEtiquetaXFactura")
    },

    MethodAplicarProducion() {
      this.$emit("MethodAplicarProducion")
    },

    MethodCompraSugeridas() {
      this.$emit("MethodCompraSugeridas")
    },

    MethodListaReciboIngreso() {
      this.$emit("MethodListaReciboIngreso")
    },
    MethodProductoProveedores(){
      this.$emit("MethodProductoProveedores")
    },
    MethodBuscar(){
      this.$emit("MethodBuscar")
    }

  },

  props: {


    titulo: {
      required: false,
      type: String
    },

    descripcion: {
      required: false,
      type: String
    },
    EstadoBtn: {
      required: false,
      type: Object
    },


  },

}
</script>

<style scoped>
@media screen and (max-width: 800px) {

  #boton-mini {
    font-size: 0.45px;
  }



}
</style>