<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-96"  :max-height="this.windowSize.height-96" >
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraPrimariaAbrir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReporteServicios'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
      <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <v-card color="grey lighten-3">
<div style="border: 1px solid #000000;">
<v-row>

<v-col cols="12" md="4" sm="4">
<v-img style="height: 60px; width:140px; border-radius: 2px;" :src="getImgUrl()"></v-img>
</v-col>
<v-col cols="12" md="4" sm="4" >
 <center>
  <h3><span>REPORTE DE SERVICIOS</span></h3>
  <span>Tel.:(809)241-4811</span>
  </center>
</v-col>
<v-col cols="12" md="4" sm="4" >
 <span class="d-flex justify-end"><b>No.</b> CIDC</span>

</v-col>

</v-row>  
</div>
 </v-card>


 <v-container>

 <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
<!-- <v-card-actions style="border: 1px solid #000000;" class="d-flex justify-center" >
      INDENTIFICACION
</v-card-actions> -->

  <v-card color="grey lighten-3">
  <v-container style="border: 1px solid #000000;" >

<v-row>

<v-col cols="12" md="9" sm="9">
<!-- ----------------------------- Nombre -->
                  <v-text-field
                  id="formTecniInput"
                    v-model="ReporteServicios.NombreTecnico"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    
                    label="Nombre del Técnico:"
                    required
                    outlined
                    disabled
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-user-o"></i>
                    </template>

                  </v-text-field>

</v-col>

<v-col cols="12" md="3" sm="3">
  <!-------------------------------------------------- fecha -->
   <v-text-field
        dense
        outlined
        type="date"  
        label="Fecha"
        v-model="ReporteServicios.fecha"
      
        :rules="[$rules.required]"
        
        placeholder=" "
        style="font-size: 12px;"
        
       
        >

                <template v-slot:prepend>
                  <i style="font-size: 18px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>
</v-col>
</v-row>


<v-row style="margin-top:-25px">
<v-col cols="12" md="9" sm="9">
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Nombre del Cliente:"
            :items="RCliente"
            item-text="nombre"
            :rules="[$rules.required]"
            :suffix="clientTel"
            style="font-size: 13px;"
           
            v-model="ReporteServicios.cliente"
            @blur="SelctCbCliente"
            @change="ClienteSelect"
            @focus="cargarCliente"
            @keyup="CbFilcliente"
            >
                      <template v-slot:prepend>
                        <i style="font-size: 18px;" class="fa fa-user-circle"></i>
                      </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }"> 
   
<v-btn @click.prevent="" v-on="on" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>

 </template>
          <span>Nuevo Cliente</span>
        </v-tooltip>

        
 </template> 





</v-combobox>
</v-col>

<v-col cols="12" md="3" sm="3">
                  <!-- ----------------------------- teléfono -->
            <v-text-field
            style="font-size: 12px;"
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
            :rules="[$rules.required,$rules.RulesTel]"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="ReporteServicios.telefono"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template> 
          </v-text-field>
</v-col>
</v-row>

<v-row style="margin-top:-25px;" >
<v-col cols="12" md="12" sm="12">
<!-- ----------------------------- Nombre -->
                  <v-text-field
                  
                    v-model="ReporteServicios.NombreTecnico"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Nombre del Solicitante:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-user-circle-o"></i>
                    </template>

                  

                  </v-text-field>

</v-col>
</v-row>

<v-row style="margin-top:-25px;">
  
<v-col cols="12" md="2" sm="2"><b>Tipos  Solución:</b> </v-col>

<v-col cols="12" md="10" sm="10" >
<v-row>
<div v-for="(ltso, i) in ListTipoSolucion" :key="i">

<v-checkbox
              class="mt-0 ma-3"
              v-model="ltso.Estado"
              :label="ltso.Descripcion"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">{{ltso.Descripcion}}</strong>
         </template>
            
</v-checkbox>
</div>
</v-row>
</v-col>
</v-row>


<v-row style="margin-top:-25px;">
  
<v-col cols="12" md="2" sm="2"><b>Tipo Servicios:</b> </v-col>

<v-col cols="12" md="10" sm="10" >
<v-row>
<div v-for="(ltse, i) in ListTipoServicios" :key="i">

<v-checkbox
              class="mt-0 ma-3"
              v-model="ltse.Estado"
              :label="ltse.Descripcion"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">{{ltse.Descripcion}}</strong>
         </template>
            
</v-checkbox>
</div>
</v-row>
</v-col>
</v-row>





<v-row style="margin-top:-25px;">
  
<v-col cols="12" md="2" sm="2"><b>Tipo Trabajo:</b> </v-col>

<v-col cols="12" md="10" sm="10" >
<v-row>
<div v-for="(lt, i) in ListTipoTrabajo" :key="i">

<v-checkbox
              class="mt-0 ma-3"
              v-model="lt.Estado"
              :label="lt.Descripcion"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">{{lt.Descripcion}}</strong>
         </template>
            
</v-checkbox>
</div>
</v-row>
</v-col>
</v-row>




<!-- <v-row style="margin-top:-25px">
<v-col cols="12" md="2" sm="2"><b>Tipo Trabajo:</b> </v-col>

<v-col cols="12" md="2" sm="2" v-for="(lt, i) in ListTipoTrabajo" :key="i">
<v-checkbox
              class="mt-0"
              v-model="lt.Estado"
              :label="lt.Descripcion"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">{{lt.Descripcion}}</strong>
         </template>
            
            </v-checkbox>
</v-col>
</v-row> -->


<v-col cols="12" md="12" sm="12">
<!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                    label="Descripción del Trabajo:"
                    dense
                    outlined
                    v-model="ReporteServicios.DescipcionTrabajo"
                    :rules="[$rules.required]"
                    rows="1"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-textarea>


<!--------------------------------------------------- tb Equipos -->
<v-card>

<v-toolbar
        flat
        dense
        color="#BDBDBD"
      >
<v-tooltip top>
<template v-slot:activator="{ on }">
   
<v-btn v-on="on" @click.prevent="AddEquipo" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>


 </template>
          <span>Agregar Equipos</span>
        </v-tooltip>


<v-toolbar-title class="font-weight-light"><v-card-title>Equipos Instalados o Trabajados</v-card-title></v-toolbar-title>
    <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="[{ text: 'Cantidad', value: 'cantidad',width:'100'},{ text: 'Descripción', value: 'descripcion', width:'auto'},
        ,{ text: 'Marca', value: 'marca', width:'auto' },{ text: 'Serial', value: 'serial', width:'auto' },
         { text: 'Observación', value: 'observacion', width:'auto' },{ text: 'acción', value: 'action', width:90 }]"
        :items="RLisEquipo"
        dense
      >

<template v-slot:item.cantidad="props">
<!-- ----------------------------- cantidad -->
<input 
  style="border: 2px solid #607D8B; font-size: 15px; "
  autocomplete="off"
  size="8"
    dense outlined
    v-model.number="props.item.cantidad"
     @keyup="KeyUpCantidad"                
 />
</template>

<template v-slot:item.descripcion="props">
<!-- ----------------------------- cantidad -->
<input 
  style="border: 2px solid #607D8B; font-size: 15px; "
  autocomplete="off"
  size="50"
    dense outlined
    v-model.number="props.item.descripcion"                
 />

</template>

<template v-slot:item.marca="props">
<!-- ----------------------------- cantidad -->
<input 
  style="border: 2px solid #607D8B; font-size: 15px; "
  autocomplete="off"
  size="15"
    dense outlined
    v-model.number="props.item.marca"                
 />

</template>

<template v-slot:item.serial="props">
<!-- ----------------------------- cantidad -->
<input 
  style="border: 2px solid #607D8B; font-size: 15px; "
  autocomplete="off"
  size="15"
    dense outlined
    v-model.number="props.item.serial"                
 />

</template>

<template v-slot:item.observacion="props">
<!-- ----------------------------- cantidad -->
<input 
  style="border: 2px solid #607D8B; font-size: 15px; "
  autocomplete="off"
  size="15"
    dense outlined
    v-model.number="props.item.observacion"                
 />

</template>

  
     <template v-slot:item.action="{ item }">
<!-- <v-btn fab @click.prevent="editMon(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
<v-btn   fab @click.prevent="deleEquipo(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
               
                    </template>
    </v-data-table>
</v-card>
<!--------------------------------------------------- fn tb  Equipos -->
</v-col>

<br>
<v-row style="margin-top:-25px;">
  
<v-col cols="12" md="2" sm="2"><b>Estatus del Trabajo:</b> </v-col>

<v-col cols="12" md="10" sm="10" >
<v-row>

<v-checkbox
              class="mt-0 ma-3"
              v-model="ReporteServicios.finalizado"
              label="Finalizado"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">Finalizado</strong>
         </template>
            
</v-checkbox>

<v-checkbox
              class="mt-0 ma-3"
              v-model="ReporteServicios.pendiente"
              label="Pendiente"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">Pendiente</strong>
         </template>
            
</v-checkbox>

<v-checkbox
              class="mt-0 ma-3"
              v-model="ReporteServicios.otros"
              label="Otros"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">Otros</strong>
         </template>
            
</v-checkbox>





<!-- <div v-for="(Let, i) in ListEstatusTrab" :key="i">
<v-checkbox
              class="mt-0 ma-3"
              v-model="Let.Estado"
              :label="Let.Descripcion"
              color="teal "
              hide-details
            >
            <template v-slot:label>
         <strong  style="color:#000000; font-size:14px;">{{Let.Descripcion}}</strong>
         </template>        
</v-checkbox>
</div> -->

</v-row>
</v-col>
</v-row>


<v-row>

  <v-col cols="12" md="3"  sm="3" >
  <v-text-field label="Hora llegada:"
                      dense outlined
                      v-model="ReporteServicios.horaLlegada"
                      autocomplete="off"
                      type="time"
              
                      :rules="[$rules.required]"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                        </template>
                      </v-text-field>
    </v-col>


<v-col cols="12" md="3"  sm="3" >
    <v-text-field label="Hora Salida:"
                      dense outlined
                      v-model="ReporteServicios.horaSalida"
                      autocomplete="off"
                      type="time"
              
                      :rules="[$rules.required]"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                        </template>
                      </v-text-field>
  </v-col>



<v-col cols="12" md="3"  sm="3" >
  <v-text-field label="Total Horas:"
                      dense outlined
                      v-model="ReporteServicios.TotalHoras"
                      autocomplete="off"
                      :rules="[$rules.required]"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                        </template>
                      </v-text-field>
    </v-col>


<v-col cols="12" md="3"  sm="3" >
  <v-text-field label="Total Horas:"
                      dense outlined
                      v-model="ReporteServicios.HorasViaje"
                      autocomplete="off"
                      :rules="[$rules.required]"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                        </template>
                      </v-text-field>
</v-col>


<v-col style="margin-top:-25px;" cols="12" md="12" sm="12">
<!-- ----------------------------- Observaciones -->
                  <v-textarea
                  autocomplete="off"
                    label="Observaciones"
                    dense
                    outlined
                    v-model="ReporteServicios.Observaciones"
                    :rules="[$rules.required]"
                    rows="1"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-textarea>
</v-col>
</v-row>

<br><br>

<v-row>
  
<v-col cols="12" md="6" sm="6">
<hr style="border-bottom: 2px dashed #212121;"> <br>
<center style="margin-top:-25px" ><b>Firma del Técnico </b></center>
</v-col>


<v-col cols="12" md="6" sm="6">
<hr style="border-bottom: 2px dashed #212121;"> <br>
<center style="margin-top:-25px" ><b>Firma del cliente </b></center>
</v-col>

<span>
   <b>Nota:</b><span style="color: #C62828;">A la firma de este documento o al recibir un email de este reporte, el cliente asume recibido conforme el trabajo y las facturas que se generen, de lo contrario si no está de acuerdo con este informe debe de reportar este documento o  email.</span>
</span>
 
</v-row> 


       </v-container>
  </v-card>

  </v-form>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>




          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import VueAutonumeric from "../components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric
  },
  created() {
    var json = {
      titulo: "Reporte de Servicios",
      descripcion: "",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [ 
        "ReporteServiciosInscNew",
        "ReporteServiciosInscSave",
        "ReporteServiciosInscRemove",
        "ReporteServiciosInscAnular",
        "ReporteServiciosInscPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReporteServiciosInscNew", this.new);
    EventBus.$on("ReporteServiciosInscSave", this.save);
    EventBus.$on("ReporteServiciosInscRemove", this.remove);
    EventBus.$on("ReporteServiciosInscAnular", this.remove);
    EventBus.$on("ReporteServiciosInscPrintList", this.imprimirList);
    EventBus.$on("emitReporteServiciosInsc", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },


  data:()=>({
    

        ListTipoSolucion:[
        {
          Descripcion:'Telefónica',
          Estado:false,
        },
        {
          Descripcion:'Internet',
          Estado:false,
        },
        {
          Descripcion:'Personal',
          Estado:false,
        },
        {
          Descripcion:'Programación',
          Estado:false,
        },
        {
          Descripcion:'Otros',
          Estado:false,
        },
       
      
        ],

ListTipoServicios:[

      {
    Descripcion:'Facturable',
          Estado:false,
        },
        {
          Descripcion:'Por Contrato',
          Estado:false,
        },
        {
          Descripcion:'Entrenamiento',
          Estado:false,
        },
        {
          Descripcion:'Garantía',
          Estado:false,
        },
        {
          Descripcion:'Otros',
          Estado:false,
        }      
],




ListTipoTrabajo:[

        {
    Descripcion:'Sistema',
          Estado:false,
        },
        {
          Descripcion:'Equipos',
          Estado:false,
        },
        {
          Descripcion:'Redes',
          Estado:false,
        },
        {
          Descripcion:'Sistema Operativo',
          Estado:false,
        },
        {
          Descripcion:'Otros',
          Estado:false,
        }
],


      RLisEquipo:[],
      ListaTiposClientes: [],
      RCliente:[],
      clientTel:'',

      windowSize:{width:"auto", height:"auto"},
      ReporteServicios: {
        NombreTecnico:'Jean Carlos',
        NombreCliente:null,
        telefono:'',
        NombreSolicitante:null,
        DescipcionTrabajo:'',
        fecha:new Date().toISOString().substr(0,10),

        horaLlegada:null,
        horaSalida:null,
        TotalHoras:0,
        HorasViaje:0,
        Observaciones:'',
        finalizado:false,
        pendiente:false,
        otros:false
      },

     
      text: "",
      valid: true,
     BarraPrimariaAbrir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraPrimariaAbrir = !this.BarraPrimariaAbrir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.ReporteServicios = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.ReporteServicios)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}


    },
    remove() {

if(this.ReporteServicios.codigo!=0 && this.ReporteServicios.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.ReporteServicios)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.ReporteServicios.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.ReporteServicios)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {},
    new() {
      (this.ReporteServicios = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarCliente(){

this.$axios.post(this.$hostname+this.$hName+'/services/clienteservices/findAllClientesCombo',{
 descripcion: "", limite:25
,usuario:JSON.parse(JSON.stringify(this.$user)) 
})
.then(res=>( this.RCliente=res.data))

},


ClienteSelect(e){



 if(e!=null){
if(e.hasOwnProperty('codigo')){

this.ObjCliente=e
this.clientTel='Tel.'+e.cedrnc

}else{

this.clientTel=''

}
 
  }

},


SelctCbCliente(){
  if(this.ReporteServicios.cliente==null){

    this.ReporteServicios.cliente=''
  }

if(this.ReporteServicios.cliente.codigo== undefined){

this.alerta("Seleccione un cliente", "error");
this.ReporteServicios.cliente=''
return

}

},

CbFilcliente(e) {
     
        if (e.target.value.length >= 2) {
         
          this.$axios
            .post(
              this.$hostname +
                this.$hName +
                "/services/clienteservices/findAllClientesCombo",
              { descripcion: e.target.value, limite:25
                ,usuario:JSON.parse(JSON.stringify(this.$user)) 
              }
            )
            .then(res => (this.RCliente = res.data));
        }
      
    },



AddEquipo(){

var jsn={
  codigo:0,
  cantidad:0,
  descripcion:'',
  marca:'',
  serial:'',
  observacion:''
}

  this.RLisEquipo.push(jsn)
},

deleEquipo(item){


const index =  this.RLisEquipo.indexOf(item)
  confirm('Desea eliminar este Equipo?') && this.RLisEquipo.splice(index, 1)

},



KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}



if(e.target.value.length==0){

e.target.value=0

}


  if(e.key){

     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(e.target.value==null){

        e.target.value=0
     }

    }
    else{

      e.key=""

     console.log("no se puede poner letras o signos")
    }


if(e.target.value.length==0){

        e.target.value=0
        e.key=0
     }
    

    
},




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.new();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },

 currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },


getImgUrl() {

    var images 
    images = require("@/assets/log.png");
  
      return images;
    },



    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style>

#formTecniInput {
  color:black;
}


</style>
