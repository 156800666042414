<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Configuracion recurrente -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Configuración recurrente'"
          :descripcion="'Crear, Modificar y Desactivar Configuración recurrente'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage('ElimiarCofRec')" @MethodAnular="NotifMessage('AnularCofRec')"
          @MethodImprimir="imprimirList" />
      </tr>
      <!-- fn ContenidoBase-Configuracion recurrente -->

      <tr>
        <!-- BarraPrimaria-Configuracion recurrente  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle>
                    <v-btn v-if="false" small dark color="deep-purple accent-2" @click.prevent="ver()">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn> </v-btn-toggle> -->

                  <v-btn color="teal darken-2" dark text small width="95%"
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtrar
                  </v-btn>


                </center>
                <BarraDinamicaLimitePlus :PropsNombre="'Configuración recurrente'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por Código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'id' },
                    { text: '#Contrato', value: 'contrato' },
                    { text: 'Nombre', value: 'cliente.nombre' },
                    { text: 'Desde', value: 'desde' },
                    { text: 'Hasta', value: 'hasta' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'id', Ctsl: 1, Type: 0, State: true },
  { Text: '#Contrato', NomValue: 'contrato', Ctsl: 1, Type: 0, State: true },
  { Text: 'Nombre', NomValue: 'cliente', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
  { Text: 'Desde', NomValue: 'desde', Ctsl: 1, Type: 1, State: true },
  { Text: 'Hasta', NomValue: 'hasta', Ctsl: 1, Type: 1, State: true },
  { Text: 'Estado', NomValue: 'activa', Ctsl: 1, Type: 0.2, State: true }
]" />


              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria-Configuracion recurrente ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

      <!-- Formulario-Configuracion recurrente  -->
            <div class="px-2">
              <v-card color="grey lighten-3">
                <b> DATOS BÁSICOS</b>
                <!--<center></center> -->
                
                <v-form v-model="valid" ref="form" lazy-validation>

                  <input type="number" v-model="form.datosBasicos.id" style="display:none;" />


                  <!-- row 1 -------------------------->
                  <v-row>
                    <v-col cols="12" md="2" sm="2">
                      <!-------------------------------------------------- numero contraro -->
                      <v-text-field v-model="form.datosBasicos.contrato" autocomplete="off" placeholder=" "
                        style="font-size:13px;" label="No.Contrato" outlined dense>

                        <template v-slot:prepend>

                          <i style="font-size: 30px;" class="fa fa-file-text-o"></i>

                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="3" sm="3">
                      <!-------------------------------------------------- Desde -->
                      <v-text-field dense style="font-size:12px;" outlined type="date" label="Desde"
                        v-model="form.datosBasicos.desde" :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="3" sm="3">
                      <!-------------------------------------------------- Hasta -->
                      <v-text-field style="font-size:12px;" dense outlined type="date" label="Hasta"
                        v-model="form.datosBasicos.hasta" :rules="[$rules.required]">

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="2" sm="2">

                      <!-- ----------------------------- Repetir cada   @keyup="CantidadRepet"    -->
                      <v-text-field label="Repetir cada" style="font-size:13px;" :rules="[$rules.required]" dense
                        outlined v-model.number="form.datosBasicos.frecuencia" @keyup="KeyUpCantidad">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-repeat"></i>
                        </template>
                      </v-text-field>

                    </v-col>

                    <v-col cols="12" md="2" sm="2">

                      <!-- ----------------------------- Frecuencia-->
                      <v-combobox style="font-size:13px;" autocomplete="off" placeholder=" " dense outlined
                        label="Frecuencia:" :items="Rfrecuencia" item-text="descripcion" :rules="[$rules.required]"
                        v-model="form.datosBasicos.fe" @blur="SelctCbFrecuencia" @change="changeFrecuencia">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-google-wallet"></i>
                        </template>
                      </v-combobox>

                    </v-col>
                  </v-row>

                  <!-- row 2 -------------------------->
                  <v-row style="margin-top:-27px;">
                    <v-col cols="12" md="6" sm="6">
                      <!-------------------------------------------------- cliente -->
                      <v-combobox autocomplete="off" style="font-size:13px;" dense outlined label="cliente:"
                        :items="RCliente" item-text="codnom" :rules="[$rules.required]" :suffix="BasicclientTel"
                        v-model="form.datosBasicos.cliente" @blur="SelctCbCliente" @focus="cargarCliente"
                        @keyup="CbFilcliente" @change="ClienteSelectTel" no-filter>

                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12" md="2" sm="2">
                      <!-- ----------------------------- moneda -->
                      <v-combobox autocomplete="off" dense outlined style="font-size:13px;" placeholder=" "
                        label="Moneda" :items="Rmoneda" item-text="nombrecorto" :rules="[$rules.required]"
                        v-model="form.datosBasicos.moneda" @blur="SelctCbMoneda" @focus="cargarMoneda">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </v-combobox>
                    </v-col>

                    <v-col cols="12" md="4" sm="4">
                      <!-- ----------------------------- Caja -->
                      <v-combobox v-model="form.datosBasicos.caja" label="caja" style="font-size:13px;"
                        :rules="[$rules.required]" outlined dense autocomplete="off" :items="RCaja"
                        item-text="descripcion" @blur="SelctCbCaja" @focus="cargarCaja">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-fax"></i>
                        </template>
                      </v-combobox>

                    </v-col>
                  </v-row>

                  <!-- row 2 -------------------------->
                  <v-row style="margin-top:-27px;">
                    <v-col cols="12" md="3" sm="3">
                      <!-- ----------------------------- Documento -->
                      <v-combobox autocomplete="off" style="font-size:13px;" dense outlined :rules="[$rules.required]"
                        label="Documentos:" :items="RDocumento" item-text="descripcion"
                        v-model="form.datosBasicos.documentoventaGenerar" @blur="SelctCbDocumento"
                        @focus="cargarDocumento">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-book"></i>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <!-------------------------------------------------- Proyecto -->
                      <v-combobox autocomplete="off" dense outlined style="font-size:13px;" label="Proyecto"
                        :items="Rproyecto" item-text="nombre" :rules="[$rules.required]"
                        v-model="form.datosBasicos.proyecto" @blur="SelctCbProyecto" @focus="cargarProyecto">

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-area-chart"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="AbrirdialogProyect" v-on="on" color="green" fab x-small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Nuevo Proyecto</span>
                          </v-tooltip>


                        </template>

                      </v-combobox>
                    </v-col>

                    <v-col cols="12" md="3" sm="3">
                      <!-------------------------------------------------- Opciones factura/Pre-fa -->
                      <v-radio-group v-model="form.datosBasicos.esTipoFactura" row>
                        <v-radio label="Factura" color="teal lighten-2" :value="true"></v-radio>
                        <v-radio label="Pre-factura" color="teal lighten-2" :value="false"></v-radio>
                      </v-radio-group>

                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <!-- ----------------------------- posponer-->
                      <v-switch color="teal" label="" v-model="form.datosBasicos.posponer">
                        <template v-slot:label>
                          <strong v-if="form.datosBasicos.posponer == true"
                            style="color:#000000; font-size:13px;">Posponer: Si</strong>
                          <strong v-if="form.datosBasicos.posponer == false"
                            style="color:#000000; font-size:13px;">Posponer: No</strong>
                        </template>
                      </v-switch>
                      <!-- <v-checkbox input-value="true" color="teal lighten-2" v-model="form.datosBasicos.posponer" label="Posponer" ></v-checkbox> -->
                    </v-col>
                  </v-row>

                  <v-row style="margin-top:-27px;">
                    <v-col cols="12" md="12" sm="12">
                      <!-- ----------------------------- Comentario-->
                      <v-textarea v-model="form.datosBasicos.comentario" label="Comentario " style="font-size:13px;"
                        rows="1" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-comments-o"></i>
                        </template>
                      </v-textarea>
                    </v-col>
                  </v-row>

                </v-form>
              </v-card>
              <hr>
              <v-card color="grey lighten-3">
                <b> DATOS SERVICIOS</b>
                <!--<center></center> -->
                <v-form v-model="valid" ref="formProducto" lazy-validation>

                  <v-row>

                    <!-------------------------------------------------- producto  -->
                    <v-col cols="7" md="7">
                      <v-combobox autocomplete="off" style="font-size:13px;" dense outlined label="Productos:"
                        :items="Rproducto" item-text="productos.descripcion" :rules="[$rules.required]"
                        @keyup="CbFilProducto" @focus="CargarProducto" v-model="form.datosServicio.producto"
                        @blur="SelctCbProduc" @change="ProductoChange">
                        <template slot="item" slot-scope="item" margin-top="50px">
                          <v-row>
                            <v-col cols="7" md="7"> <span style="font-size: 13px;">{{
                                item.item.productos.descripcion
                            }}</span> </v-col>
                            <v-col cols="3" md="3"> <span style="font-size: 13px;"> {{ item.item.unidades.descripcion
                            }}</span> </v-col>
                            <v-col cols="2" md="2"> <span style="font-size: 13px;">{{
                                currencyFormatter(item.item.precio)
                            }}</span> </v-col>

                          </v-row>
                        </template>

                        <template v-slot:prepend-item>
                          <v-row>
                            <v-col cols="7" md="7"> <b style="font-size: 16px;">Producto: </b> </v-col>
                            <v-col cols="3" md="3"> <b style="font-size: 16px;"> Unidad: </b> </v-col>
                            <v-col cols="2" md="2"> <b style="font-size: 16px;">Precio:</b> </v-col>
                          </v-row>
                        </template>

                        <!-- <template slot="item" slot-scope="item" margin-top="50px">



                        <v-simple-table style="width:100%;">
<template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="color: #4CAF50">Producto:</th>
                              <th class="text-left" style="color: #4CAF50">Unidad:</th>
                              <th class="text-left" style="color: #4CAF50">Precio:</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ item.item.productos.descripcion }}</td>
                              <td>
                                  {{ item.item.unidades.descripcion }}
                              </td>
                              <td>{{ currencyFormatter(item.item.precio)}}</td>
                            </tr>
                          </tbody>
 </template>
                        </v-simple-table>
                      </template> -->


                        <template v-slot:prepend>
                          <i style="font-size: 30px;" class="fa fa-shopping-cart"></i>
                        </template>


                      </v-combobox>
                    </v-col>

                    <!-- ----------------------------- Precio  -->
                    <v-col cols="2" md="2">

                      <VueAutonumeric label="Precio" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="form.datosServicio.precio" :disabled="disabPrecio" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>

                      </VueAutonumeric>


                      <!-- <v-text-field label="Precio"  id="precio" dense outlined 
 autocomplete="off"
 :rules="[rules.required]"
  v-model.number="form.datosServicio.precio"
  type="number"
  @focus="AutoNumericFormat(form.datosServicio.precio,1,'precio','precio')"
  @blur="AutoNumericFormat(form.datosServicio.precio,2,'precio','precio')"
  :prefix="AutoFormato.precio"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>

  </v-text-field> -->

                    </v-col>

                    <!-- ----------------------------- Cantidad-->
                    <v-col cols="3" md="3">
                      <v-text-field label="Cantidad" style="font-size:13px;" autocomplete="off" dense outlined
                        :rules="[$rules.required]" v-model.number="form.datosServicio.cantidad" @keyup="KeyUpCantidad">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="AddProducto" v-on="on" color="green" small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Agregar producto</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>


                    <!-- ----------------------------- descuento-->
                    <v-col cols="4" md="4">


                      <!-- ----------------------------- descuento-->
                      <VueAutonumeric v-show="form.datosServicio.RadioDescValor == false" label="Desc:"
                        autocomplete="off" style="font-size: 13px;" required outlined dense
                        v-model.number="form.datosServicio.descuentoPorciento">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-money"></i>
                        </template>
                        <template v-slot:append-outer>

                          <v-radio-group @change="ChangeRadiodesc" style="margin-top: -18%;"
                            v-model="form.datosServicio.RadioDescValor" column>
                            <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                            <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                          </v-radio-group>

                        </template>
                      </VueAutonumeric>

                      <!-- <v-text-field id="descuento" v-show="form.datosServicio.RadioDescValor==false" autocomplete="off"
                        v-model.number="form.datosServicio.descuentoPorciento"
                        :rules="[rules.porciento]"
                        label="Desc"
                        type="number"
                        outlined
                        dense
                        @focus="AutoNumericFormat(form.datosServicio.descuentoPorciento,1,'descuento','descuento')"
                        @blur="AutoNumericFormat(form.datosServicio.descuentoPorciento,2,'descuento','descuento')"
                        @keyup="DSkeyupdescPorc"
                        :prefix="AutoFormato.descuento"
                      >

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>

<template v-slot:append-outer>

<v-radio-group @change="ChangeRadiodesc" style="margin-top: -18%;"   v-model="form.datosServicio.RadioDescValor" column>     
                  <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                  <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>                                       
</v-radio-group>
  
 </template>
</v-text-field> -->


                      <!-- ----------------------------- descuento-->
                      <VueAutonumeric v-show="form.datosServicio.RadioDescValor == true" label="Desc:"
                        autocomplete="off" style="font-size: 13px;" required outlined dense
                        v-model.number="form.datosServicio.descuentoValor">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        <template v-slot:append-outer>

                          <v-radio-group @change="ChangeRadiodesc" style="margin-top: -18%;"
                            v-model="form.datosServicio.RadioDescValor" column>
                            <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                            <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                          </v-radio-group>

                        </template>
                      </VueAutonumeric>






                      <!-- <v-text-field id="descuentoValor" v-show="form.datosServicio.RadioDescValor==true" label="Desc" dense outlined
 autocomplete="off"
v-model.number="form.datosServicio.descuentoValor"
  type="number"
 @focus="AutoNumericFormat(form.datosServicio.descuentoValor,1,'descuentoValor','descuentoValor')"
 @blur="AutoNumericFormat(form.datosServicio.descuentoValor,2,'descuentoValor','descuentoValor')"
 @keyup="DSkeyupdesc"
:prefix="AutoFormato.descuentoValor"


  
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>

    <template v-slot:append-outer>

<v-radio-group @change="ChangeRadiodesc"  style="margin-top: -18%;"   v-model="form.datosServicio.RadioDescValor" column>     
                  <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                  <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>                                       
</v-radio-group>
  
 </template>

  </v-text-field> -->

                    </v-col>


                    <!-- ----------------------------- recargo-->
                    <v-col cols="4" md="4">


                      <!-- ----------------------------- Recargo-->
                      <VueAutonumeric v-show="form.datosServicio.RadioRecargoValor == false" label="Recargo:"
                        autocomplete="off" style="font-size: 13px;" required outlined dense
                        v-model.number="form.datosServicio.recargoPorciento">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-radio-group @change="ChangeRadioRecargo" style="margin-top: -18%;"
                            v-model="form.datosServicio.RadioRecargoValor" column>
                            <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                            <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                          </v-radio-group>


                        </template>
                      </VueAutonumeric>



                      <!-- <v-text-field id="recargoPorciento" v-show="form.datosServicio.RadioRecargoValor==false"
                        v-model.number="form.datosServicio.recargoPorciento"
                        type="number"
                        autocomplete="off"
                        :rules="[rules.porciento]"
                        @focus="AutoNumericFormat(form.datosServicio.recargoPorciento,1,'recargoPorciento','recargoPorciento')"
                        @blur="AutoNumericFormat(form.datosServicio.recargoPorciento,2,'recargoPorciento','recargoPorciento')"
                        @keyup="DSkeyuprecPorc"
                       :prefix="AutoFormato.recargoPorciento"
                        label="Recargo"
                        outlined
                        dense
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>

<template v-slot:append-outer>

<v-radio-group @change="ChangeRadioRecargo" style="margin-top: -18%;"  v-model="form.datosServicio.RadioRecargoValor" column>     
                  <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                  <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>                                       
</v-radio-group>


 </template>
                      </v-text-field> -->


                      <!-- ----------------------------- Recargo-->
                      <VueAutonumeric v-show="form.datosServicio.RadioRecargoValor == true" label="Recargo:"
                        autocomplete="off" style="font-size: 13px;" required outlined dense
                        v-model.number="form.datosServicio.recargoValor">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-radio-group @change="ChangeRadioRecargo" style="margin-top: -18%;"
                            v-model="form.datosServicio.RadioRecargoValor" column>
                            <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                            <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                          </v-radio-group>

                        </template>
                      </VueAutonumeric>

                      <!-- <v-text-field id="recargoValor" v-show="form.datosServicio.RadioRecargoValor==true"
 label="Recargo" dense outlined
 @focus="AutoNumericFormat(form.datosServicio.recargoValor,1,'recargoValor','recargoValor')"
  @blur="AutoNumericFormat(form.datosServicio.recargoValor,2,'recargoValor','recargoValor')"
  @keyup="DSkeyuprec"
  autocomplete="off"
 type="number"
  v-model.number="form.datosServicio.recargoValor"
  :prefix="AutoFormato.recargoValor"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>

    <template v-slot:append-outer>

<v-radio-group @change="ChangeRadioRecargo" style="margin-top: -18%;"   v-model="form.datosServicio.RadioRecargoValor" column>     
                  <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                  <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>                                       
</v-radio-group>
  
 </template>

  </v-text-field> -->

                    </v-col>


                    <!-- ----------------------------- Retencion-->
                    <v-col cols="4" md="4">


                      <!-- ----------------------------- Recargo-->
                      <VueAutonumeric style="display:none; font-size: 13px;" label="Retención" autocomplete="off"
                        required outlined dense v-model.number="form.datosServicio.retencion">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" v-if="form.datosServicio.RadioRestValor == true"
                            class="fa fa-usd"></i>
                          <i style="font-size: 20px;" v-if="form.datosServicio.RadioRestValor == false"
                            class="fa fa-percent"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-radio-group style="margin-top: -18%;" v-model="form.datosServicio.RadioRestValor" column>
                            <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                            <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                          </v-radio-group>

                        </template>
                      </VueAutonumeric>


                      <!-- <v-text-field style="display:none;" id="retencion" label="Retención" dense outlined
 autocomplete="off"
 @focus="AutoNumericFormat(form.datosServicio.retencion,1,'retencion','retencion')"
  @blur="AutoNumericFormat(form.datosServicio.retencion,2,'retencion','retencion')"
  v-model.number="form.datosServicio.retencion"
  type="number"
  :prefix="AutoFormato.retencion"
  >
    <template v-slot:prepend>

<i style="font-size: 20px;" v-if="form.datosServicio.RadioRestValor==true" class="fa fa-usd"></i>
<i style="font-size: 20px;" v-if="form.datosServicio.RadioRestValor==false" class="fa fa-percent"></i>
  
    </template>

    <template v-slot:append-outer>

<v-radio-group   style="margin-top: -18%;"   v-model="form.datosServicio.RadioRestValor" column>     
                  <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                  <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>                                       
</v-radio-group>
  
 </template>

  </v-text-field> -->
                    </v-col>

                  </v-row>
                </v-form>


                <v-data-table dense :items-per-page="-1" :headers="[
                  { text: 'CÓDIGO', value: 'producto.productos.codigo', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'NOMBRE', value: 'producto.productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'PRECIO', value: 'precio', sortable: false, align: 'center', class: ['no-gutters black--text'] },
                  { text: 'CANT', value: 'cantidad', sortable: false, align: 'center', class: ['no-gutters black--text '] },
                  { text: 'DESC', value: 'descuento', sortable: false, align: 'center', class: ['no-gutters black--text'] },
                  { text: 'RECARGO', value: 'recargo', sortable: false, align: 'center', class: ['no-gutters black--text'] },
                
                  { text: 'TOTAL', value: 'total', sortable: false, align: 'center', class: ['no-gutters black--text'] },
                  { text: 'ACCIÓN', value: 'action', sortable: false, class: ['no-gutters black--text'] },
                
                ]" :items="form.ProductoList" item-key="codigo" class="elevation-1">
                  <!-- {text: 'RETENCIÓN', value: 'retencion' ,sortable: false, class: ['no-gutters']},  -->
                  <template v-slot:item.action="{ item }">
                    <v-btn fab @click.prevent="editProducto(item)" x-small color="grey "><i style="font-size: 20px;"
                        class="fa fa-pencil"></i></v-btn>
                    <v-btn fab @click.prevent="delProducto(item)" x-small color="red darken-3"><i
                        style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                  </template>

                  <template v-slot:item.precio="props">
                    <span> {{ currencyFormatter(props.item.precio) }}</span>
                  </template>


                  <!-- <template v-slot:item.retencion="props">
                <span > {{currencyFormatter(props.item.retencion)}}</span>
      </template> -->


                  <template v-slot:item.descuento="props">
                    <span v-if="props.item.RadioDescValor == true">
                      {{ currencyFormatter(props.item.descuentoValor) }}</span>
                    <span v-if="props.item.RadioDescValor == false">
                      <span v-if="props.item.descuentoPorciento == 0">{{
                          currencyFormatter(props.item.descuentoPorciento)
                      }}
                      </span>
                      <span v-if="props.item.descuentoPorciento != 0">{{ currencyFormatter((props.item.precio *
                          props.item.cantidad) * (props.item.descuentoPorciento / 100))
                      }}</span>
                    </span>
                  </template>

                  <template v-slot:item.recargo="props">

                    <span v-if="props.item.RadioRecargoValor == true">
                      {{ currencyFormatter(props.item.recargoValor) }}</span>
                    <span v-if="props.item.RadioRecargoValor == false">
                      <span v-if="props.item.recargoPorciento == 0">{{ currencyFormatter(props.item.recargoPorciento) }}
                      </span>
                      <span v-if="props.item.recargoPorciento != 0">{{ currencyFormatter((props.item.precio *
                          props.item.cantidad) * (props.item.recargoPorciento / 100))
                      }}</span>
                    </span>

                  </template>


                  <!--<template v-slot:item.retencion="props">
<span v-if="props.item.RadioRestValor==true"> {{currencyFormatter(props.item.retencion)}}</span>
<span v-if="props.item.RadioRestValor==false"> 
<span v-if="props.item.retencion==0">{{currencyFormatter(props.item.recargoPorciento)}} </span>
<span v-if="props.item.retencion!=0">{{currencyFormatter( (props.item.precio * props.item.cantidad)  * (props.item.retencion/100))}}</span>
</span>
</template>

-->



                  <template v-slot:item.total="props">
                    <span> {{ currencyFormatter(props.item.total) }}</span>
                  </template>

                  <template v-slot:body.append>
      <tr>

        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end"
            style="font-size:15px; color: #00838F;">Total:</strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(form.Total.precio) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(form.Total.cantida) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(form.Total.descuento) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(form.Total.recargo) }} </strong></td>
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(form.Total.Total) }} </strong></td>
        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
        <td class="border-top-bottom"><strong></strong></td>

      </tr>
</template>

<template v-slot:footer>


  <!-- ----------------------------- descuento-->
  <v-col cols="4" md="4">

    <!-- ----------------------------- Cheques Emitidos -->
    <VueAutonumeric label="Descuento:" autocomplete="off" style="font-size: 13px;" required outlined dense
      v-model.number="form.DescuentoProducto">
      <template v-slot:prepend>
        <i style="font-size: 30px;" class="fa fa-percent"></i>
      </template>
    </VueAutonumeric>


    <!-- <v-text-field 
                        v-model.number="form.DescuentoProducto"
                        type="number"
                        :rules="[$rules.porciento]"
                        @keyup="DSkeyuDescProd"
                        label="Descuento"
                        outlined
                        dense
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 30px;" class="fa fa-percent"></i>
                        </template>
                      </v-text-field > -->
  </v-col>

</template>

</v-data-table>

<!-- eoo eoo -->
</v-card>
<hr>
<v-card color="grey lighten-3">
  <b> RESTRICCIONES</b>
  <!--<center></center> -->

  <v-col cols="6" md="6">
    <v-btn @click.prevent="AbrirModRestriccion" color="green" large dark>
      <i style="font-size: 20px;" class="fa fa-plus-square"> </i> AGREGAR RESTRICCIONES
    </v-btn>
  </v-col>
</v-card>


<v-data-table dense :items-per-page="-1" hide-default-header :headers="[
  { text: 'RESTRICCIONES', value: 'restrincciones', sortable: false, class: ['no-gutters black--text'] },

  { text: 'ACCIÓN', value: 'action', sortable: false, class: ['no-gutters black--text'] },
]" :items="form.RetriccionList" item-key="codigo" class="elevation-1">



  <template v-slot:item.restrincciones="props">


    <v-list-item>
      <v-list-item-content>
        <h4>RESTRICCIÓN:</h4>
        <span><b>Desde:</b>{{ FormatoFecha(props.item.desde) }} --> <span>
            <b>Hasta:</b>{{ FormatoFecha(props.item.hasta) }}</span>

          <span v-if="props.item.aumento == true">
            <span v-if="props.item.precio != 0"> <b> Aumentar: </b> {{ currencyFormatter(props.item.precio) }} $</span>
            <span v-if="props.item.porciento != 0"><b> Aumentar: </b> {{ currencyFormatter(props.item.porciento) }}
              %</span>
          </span>
          <span v-if="props.item.aumento == false">
            <span v-if="props.item.precio != 0"> <b> Disminuir: </b> {{ currencyFormatter(props.item.precio) }} $</span>
            <span v-if="props.item.porciento != 0"><b> Disminuir: </b> {{ currencyFormatter(props.item.porciento) }}
              %</span>
          </span>

          <span><b> Descuento: </b> {{ currencyFormatter(props.item.DescuentoProducto) }} </span>

        </span>

        <v-data-table dense :items-per-page="-1" :headers="[
          { text: 'CÓDIGO', value: 'producto.productos.codigo', sortable: false, class: ['no-gutters black--text'] },
          { text: 'NOMBRE', value: 'producto.productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
          { text: 'PRECIO', value: 'precio', sortable: false, align: 'center', class: ['no-gutters black--text'] },
          { text: 'CANT', value: 'cantidad', sortable: false, align: 'center', class: ['no-gutters black--text'] },
          { text: 'DESC', value: 'descuento', sortable: false, align: 'center', class: ['no-gutters black--text'] },
          { text: 'RECARGO', value: 'recargo', sortable: false, align: 'center', class: ['no-gutters black--text'] },
        
          { text: 'TOTAL', value: 'total', align: 'center', sortable: false, class: ['no-gutters black--text'] },
        ]" :items="props.item.ListProd" item-key="codigo" class="elevation-1">

          <!-- {text: 'RETENCIÓN', value: 'retencion' ,sortable: false, class: ['no-gutters']},  -->

          <template v-slot:item.precio="props">
            <span> {{ currencyFormatter(props.item.precio) }}</span>
          </template>


          <template v-slot:item.total="props">
            <span> {{ currencyFormatter(props.item.total) }}</span>
          </template>


          <template v-slot:item.descuento="props">
            <span> {{ currencyFormatter(props.item.descuentoValor) }}</span>
          </template>

          <template v-slot:item.recargo="props">
            <span> {{ currencyFormatter(props.item.recargoValor) }}</span>
          </template>


          <!-- <template v-slot:item.retencion="props">
                <span > {{ currencyFormatter(props.item.retencion)}}</span>
      </template> -->




          <template v-slot:body.append>
            <tr>

              <td class="border-top-bottom"><strong></strong></td>
              <td class="border-top-bottom"><strong class="d-flex justify-end"
                  style="font-size:15px; color: #00838F;">Total:</strong></td>
              <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

              <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                  {{ currencyFormatter(props.item.TotalModRet.precio) }} </strong></td>
              <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                  {{ currencyFormatter(props.item.TotalModRet.cantida) }} </strong></td>
              <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                  {{ currencyFormatter(props.item.TotalModRet.descuento) }} </strong></td>
              <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                  {{ currencyFormatter(props.item.TotalModRet.recargo) }} </strong></td>
              <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(props.item.TotalModRet.retencion)}} </strong></td> -->
              <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                  {{ currencyFormatter(props.item.TotalModRet.Total) }} </strong></td>


            </tr>
          </template>

        </v-data-table>
      </v-list-item-content>
    </v-list-item>

    <!-- <span > {{ currencyFormatter(props.item.precio)}}</span> -->

  </template>

  <template v-slot:item.action="{ item }">
    <v-btn fab @click.prevent="editRestrincion(item)" small color="grey "><i style="font-size: 18px;"
        class="fa fa-pencil"></i></v-btn>
    <!-- <v-btn fab @click.prevent="delRestrincion(item)" small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
 -->
  </template>

</v-data-table>








</div>
 <!-- fn Formulario-Configuracion recurrente  -->
<!-- <v-form ref="formCierre" @submit.prevent=""  v-model="valid" lazy-validation>  -->







</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
  transition="fab-transition">
  <v-card>
    <v-toolbar flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" @keyup="CargarListTb" label="Buscar" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
          <template v-slot:item.porciento="props">
            <span>{{ currencyFormatter(props.item.porciento) }}</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ Modal-notificacion general -->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec == 'AnularCofRec'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>



      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Modal-notificacion general -->


<!------------ Alerta ---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!------------ fn Alerta ---------------->

<!-- ------------------------------------------------------ Modal-Imprimir listado de configuraciones -->
<v-dialog label="Modal agregar factura" v-model="dialogImprimir" persistent max-width="90%" max-height="80%">
  <v-form ref="formImprimir" v-model="valid" lazy-validation>

    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> <b>Imprimir listado de configuraciones</b>
        </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogImprimir = !dialogImprimir">

          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>


        <v-row>

          <!-- ----------------------------- columna-1 -->
          <v-col cols="6" md="6">
            <!-------------------------------------------------- Desde -->
            <v-text-field dense outlined type="date" label="Desde" v-model="Imprimir.fechaDesde"
              :rules="[$rules.required]" @blur="BlurDesde">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>

            <!-------------------------------------------------- Proyecto -->
            <v-combobox autocomplete="off" dense outlined label="Proyecto" :items="Rproyecto" item-text="nombre"
              :rules="[$rules.required]" v-model="Imprimir.proyecto" @blur="SelctCbProyectoImprime"
              @focus="cargarProyecto">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-area-chart"></i>
              </template>

            </v-combobox>






            <!-- viendo -->

          </v-col>
          <!-- ----------------------------- fn columna-1 -->


          <!-- ----------------------------- columna-2 -->
          <v-col cols="6" md="6">
            <!-------------------------------------------------- Hasta -->

            <v-text-field dense outlined type="date" label="Hasta" v-model="Imprimir.fechaHasta"
              :rules="[$rules.required]" @blur="BlurHasta">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>


            <!-------------------------------------------------- cliente -->
            <v-combobox autocomplete="off" dense outlined label="cliente:" :items="RCliente" item-text="codnom"
              :rules="[$rules.required]" style="font-size:13px;" v-model="Imprimir.cliente"
              @blur="SelctCbClienteImprime" @focus="cargarCliente" @keyup="CbFilcliente" :suffix="ImpriclientTel"
              @change="ImpriClienteSelectTel" no-filter>

              <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
              </template>
            </v-combobox>



          </v-col>
          <!-- ----------------------------- fn columna-2 -->

          <v-col cols="12" md="12">

            <!-------------------------------------------------- Opciones 1 -->
            <v-radio-group v-model="Imprimir.estatus" row>
              <v-radio label="Solo pospuestas" color="teal lighten-2" :value="'soloPospuestas'"></v-radio>
              <v-radio label="Solo activas" color="teal lighten-2" :value="'soloActivas'"></v-radio>
              <v-radio label="Todas" color="teal lighten-2" :value="'todas'"></v-radio>
            </v-radio-group>


            <!-------------------------------------------------- Opciones 2-->
            <v-radio-group v-model="Imprimir.formatoResporte" row>
              <v-radio label="Resumudo" color="teal lighten-2" :value="'resumidos'"></v-radio>
              <v-radio label="completo" color="teal lighten-2" :value="'completo'"></v-radio>

            </v-radio-group>


          </v-col>



        </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">



          <v-btn color="#90A4AE" @click.prevent="LimpiarImprimirConf">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Limpiar
          </v-btn>

          <v-btn color="blue" @click.prevent="ImprimirConfiguracion">
            <i style="font-size: 28px;" class="fa fa-print"> </i>
            Imprimir
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>

<!-------------------------------------------------------- fn  Modal-Imprimir listado de configuraciones -->


<!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo">

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>

      <v-row>

        <!-- ----------------------------- columna 1 -->
        <v-col cols="12" md="6" sm="6">


          <!----------------------------------------------  contraro -->
          <v-text-field v-model="Modallfiltro.contraro" label="No. Contrato" outlined dense>

            <template v-slot:prepend>

              <i style="font-size: 30px;" class="fa fa-file-text-o"></i>

            </template>
          </v-text-field>


          <v-combobox autocomplete="off" dense outlined label="cliente:" :items="RCliente" item-text="nombre"
            :suffix="clientTel" style="font-size: 13px;" v-model="Modallfiltro.cliente" @blur="SelctCbFiltroCliente"
            @change="ClienteSelect" @focus="cargarCliente" @keyup="CbFilcliente">
            <template v-slot:prepend>
              <i style="font-size: 18px;" class="fa fa-user-circle"></i>
            </template>
          </v-combobox>



          <!-- anja anja -->




        </v-col>
        <!------------------------------- fn  columna 1 -->

        <!-------------------------------  columna 2 -->
        <v-col cols="12" md="6" sm="6">


          <!-------------------------------------------------- Proyecto -->
          <v-combobox autocomplete="off" dense outlined label="Proyecto" :items="Rproyecto" item-text="nombre"
            :rules="[$rules.required]" v-model="Modallfiltro.proyecto" @blur="SelctCbProyectoFiltro"
            @focus="cargarProyecto">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-area-chart"></i>
            </template>
          </v-combobox>

        </v-col>
        <!-- ----------------------------- fn columna-2 -->





      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle rounded class="">


        <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal-Filtro tb-datos-->


<!-- ------------------------------------------------------ Modal-Nuevo Proyecto -->
<v-dialog label="Modal agregar factura" v-model="dialogProyect" persistent max-width="90%" max-height="80%">
  <v-form ref="formNewProyecto" v-model="valid" lazy-validation>




    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> <b>Nuevo proyecto</b></v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogProyect = !dialogProyect">

          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>


        <v-row>


          <input type="number" v-model="form.NewProyecto.id" style="display:none;" />



          <!-- -----------------------------  columna-1 -->
          <v-col cols="12" md="12">


            <!-------------------------------------------------- nombre -->
            <v-text-field label="Descripción:" autocomplete="off" dense outlined :rules="[$rules.required]"
              v-model="form.NewProyecto.nombre">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-font"></i>
              </template>
            </v-text-field>

          </v-col>
          <!-- ----------------------------- fn columna-1 -->

        </v-row>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">


          <v-btn dark color="#90A4AE" @click.prevent="form.NewProyecto.id = 0,
            form.NewProyecto.nombre = null, form.NewProyecto.activo = true, form.NewProyecto.borrado = false
          ">
            <i style="font-size: 28px;" class="fa fa-file-o"> </i>
            Nuevo
          </v-btn>

          <v-btn dark color="green" @click.prevent="SaveProyect">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Guardar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal-Nuevo Proyecto -->

<!-- ------------------------------------------------------ Modal-Retrinccion  -->
<v-dialog label="Modal" v-model="dialogRetrinccion" persistent max-width="100%" max-height="100%">
  <v-form ref="formRetrinccion" v-model="valid" lazy-validation>

    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> <b>Restricción</b></v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="CerrarModRestriccion">
          <!--  CerrarFiltro() -->
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>

        <input type="number" v-model="form.datosBasicos.id" style="display:none;" />



        <v-row>


          <!-------------------------------------------------- Cols 1 -->
          <v-col cols="6" md="6">

            <!-------------------------------------------------- Desde -->
            <v-text-field dense outlined type="date" label="Desde" v-model="form.datosRetriccion.desde"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>

            <v-row>

              <!-- ----------------------------- Precio   -->
              <v-col cols="6" md="6">

                <!-- ----------------------------- Precio-->
                <VueAutonumeric label="Precio:" autocomplete="off" style="font-size: 13px;" required outlined
                  @input="PrecioPorciento(1)" dense v-model.number="form.datosRetriccion.precio">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>

                <!-- <v-text-field id="RestmodPrecio" label="Precio" dense outlined
 autocomplete="off"
 @keyup="RESTkeyuprecio"
 type="number"
 v-model.number="form.datosRetriccion.precio"
 @focus="AutoNumericFormat(form.datosRetriccion.precio,1,'RestmodPrecio','RestmodPrecio')"
@blur="AutoNumericFormat(form.datosRetriccion.precio,2,'RestmodPrecio','RestmodPrecio')"
 @input="PrecioPorciento(1)"
 :prefix="AutoFormato.RestmodPrecio"

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </v-text-field> -->


              </v-col>

              <!-- ----------------------------- porciento-->
              <v-col cols="6" md="6">

                <!-- ----------------------------- Precio-->
                <VueAutonumeric label="%" autocomplete="off" style="font-size: 13px;" required outlined
                  @input="PrecioPorciento(2)" dense v-model.number="form.datosRetriccion.porciento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>
                </VueAutonumeric>

                <!-- <v-text-field id="RestmodPorciento"
                        v-model.number="form.datosRetriccion.porciento"
                        type="number"
                       @focus="AutoNumericFormat(form.datosRetriccion.porciento,1,'RestmodPorciento','RestmodPorciento')"
                       @blur="AutoNumericFormat(form.datosRetriccion.porciento,2,'RestmodPorciento','RestmodPorciento')"
                       @keyup="RESTkeyupoorciento"
                        :prefix="AutoFormato.RestmodPorciento"
                        :rules="[$rules.porciento]"
                        @input="PrecioPorciento(2)"
                        label="%"
                        required
                        outlined
                        dense
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 30px;" class="fa fa-percent"></i>
                        </template>
                      </v-text-field> -->

              </v-col>

            </v-row>


            <!-- ----------------------------- Reteción-->
            <VueAutonumeric style="display:none; font-size: 13px;" label="Reteción" autocomplete="off" required outlined
              dense v-model.number="form.datosRetriccion.retencion">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-percent"></i>
              </template>
            </VueAutonumeric>


            <!-- <v-text-field style="display:none;" id="RestmodRetencion" label="Reteción" dense outlined
 autocomplete="off"
  v-model.number="form.datosRetriccion.retencion"
   type="number"
  @focus="AutoNumericFormat(form.datosRetriccion.retencion,1,'RestmodRetencion','RestmodRetencion')"
  @blur="AutoNumericFormat(form.datosRetriccion.retencion,2,'RestmodRetencion','RestmodRetencion')"
  :prefix="AutoFormato.RestmodRetencion"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>

  </v-text-field> -->

          </v-col>


          <!-------------------------------------------------- cols 2 -->
          <v-col cols="6" md="6">

            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta" v-model="form.datosRetriccion.hasta"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>


            <!-- ----------------------------- Aumento/Disminuir-->


            <v-radio-group v-model="form.datosRetriccion.aumento" row>
              <v-radio label="Aumentar" color="teal lighten-2" :value="true"></v-radio>
              <v-radio label="Disminuir" color="teal lighten-2" :value="false"></v-radio>
            </v-radio-group>


          </v-col>
        </v-row>
        <!-- ----------------------------- Btn aplicar-->
        <v-col cols="6" md="6">
          <v-btn @click.prevent="ApplModRestrincion" color="teal lighten-2" large dark>
            <i style="font-size: 20px;" class="fa fa-check-square-o"> </i> Aplicar
          </v-btn>
        </v-col>

        <hr>

      </v-card-text>
      <!-- ----------------------------- Tb-Modal restrinccion-->
      <v-data-table dense :items-per-page="-1" :headers="[
        { text: 'CÓDIGO', value: 'producto.productos.codigo', sortable: false, class: ['no-gutters black--text'] },
        { text: 'NOMBRE', value: 'producto.productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
        { text: 'PRECIO', value: 'precio', sortable: false, align: 'center', class: ['no-gutters black--text'] },
        { text: 'CANT', value: 'cantidad', sortable: false, class: ['no-gutters black--text'] },
        { text: 'DESC', value: 'descuento', sortable: false, class: ['no-gutters black--text'] },
        { text: 'RECARGO', value: 'recargo', sortable: false, class: ['no-gutters black--text'] },
      
        { text: 'TOTAL', value: 'total', sortable: false, class: ['no-gutters black--text'] },
      ]
      " :items="form.datosRetriccion.ListProd" item-key="codigo" class="elevation-1">

        <!-- {text: 'RETENCIÓN', value: 'retencion' ,sortable: false, class: ['no-gutters']},  -->
        <!-- <template v-slot:item.action="{ item }">
  {text: 'ACCIÓN', value: 'action' ,sortable: false, class: ['no-gutters']},
<v-btn fab @click.prevent="editModRetrincion(item)" small color="grey "><i style="font-size: 18px;" class="fa fa-pencil"></i></v-btn> 
 <v-btn fab @click.prevent="delModRetrincion(item)" small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
      
 </template> -->

        <!-- Poor aquii -->

        <template v-slot:footer>

          <!-- @keyup="RESTkeyuDescProd"  okeo    -->
          <!-- ----------------------------- descuento-->
          <v-col cols="4" md="4">

            <VueAutonumeric style="font-size: 13px;" label="Descuento" :rules="[$rules.porciento]" autocomplete="off"
              required outlined dense v-model.number="form.datosRetriccion.DescuentoProducto">
              <template v-slot:prepend>
                <i style="font-size: 30px;" class="fa fa-percent"></i>
              </template>
            </VueAutonumeric>

          </v-col>

        </template>




        <template v-slot:item.precio="props">

          <v-text-field label="Precio" dense outlined autocomplete="off" :rules="[$rules.required]"
            v-model.number="props.item.precio" @input="ActuModRestrincion" hide-details>
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>

          </v-text-field>
        </template>

        <template v-slot:item.total="props">
          <span> {{ currencyFormatter(props.item.total) }}</span>
        </template>




        <template v-slot:item.descuento="props">
          <v-text-field label="Descuento" dense outlined autocomplete="off" v-model.number="props.item.descuentoValor"
            hide-details @input="ActuModRestrincion">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>

          </v-text-field>
        </template>


        <template v-slot:item.recargo="props">
          <v-text-field label="Recargo" dense outlined autocomplete="off" v-model.number="props.item.recargoValor"
            hide-details @input="ActuModRestrincion">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>

          </v-text-field>
        </template>


        <!-- <template v-slot:item.retencion="props">

    <v-text-field label="Reteción" dense outlined
 autocomplete="off"
  v-model.number="props.item.retencion"
  @input="ActuModRestrincion"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>

  </v-text-field>
                
      </template> -->




        <!-- por aqui -->

        <template v-slot:body.append>
          <tr>

            <td class="border-top-bottom"><strong></strong></td>
            <td class="border-top-bottom"><strong class="d-flex justify-end"
                style="font-size:15px; color: #00838F;">Total:</strong></td>

            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                {{ currencyFormatter(form.datosRetriccion.TotalModRet.precio) }} </strong></td>
            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                {{ currencyFormatter(form.datosRetriccion.TotalModRet.cantida) }} </strong></td>
            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                {{ currencyFormatter(form.datosRetriccion.TotalModRet.descuento) }} </strong></td>
            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                {{ currencyFormatter(form.datosRetriccion.TotalModRet.recargo) }} </strong></td>
            <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.datosRetriccion.TotalModRet.retencion)}} </strong></td> -->
            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
                {{ currencyFormatter(form.datosRetriccion.TotalModRet.Total) }} </strong></td>

          </tr>
        </template>



      </v-data-table>



      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn-toggle rounded class="">


          <!-- <v-btn color="red" @click.prevent="form.NewProyecto.codigo=0,
form.NewProyecto.descripcion=null
  ">
              <i style="font-size: 28px;" class="fa fa-ban"> </i>
              Cancelar</v-btn> -->

          <v-btn dark color="green" @click.prevent="AddModRestriccion">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Agregar
          </v-btn>

        </v-btn-toggle>

      </v-card-actions>

    </v-card>
  </v-form>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal-Retrinccion -->

<!-- ------------------------------------------------------ Cerrar_notif-->
<v-dialog label="myAlert" v-model="Cerrar_notif.estado" persistent max-width="350">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>

      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>

    </v-card-title>
    <hr />

    <v-card-text>
      <center>
        <h4>{{ Cerrar_notif.nombre }}</h4>
      </center>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn @click.prevent="
        ConfModRestriccion(), (Cerrar_notif.estado = !Cerrar_notif.estado)
      " small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>

      <v-btn @click.prevent="Cerrar_notif.estado = !Cerrar_notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Cerrar_notif -->







<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>
</template>

<script>
import { EventBus } from "../../event-bus";
import numeral from 'numeral'
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import BasicAutonumeric from "../../components/VueAutonumeric";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { json } from "body-parser";
export default {
  components: {


    BarraDinamicaLimitePlus,
    BarraDinamicaNormal,
    BasicAutonumeric,
    VueAutonumeric,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Configuración recurrente",
      descripcion: "Crear, Modificar y Desactivar Configuración recurrente",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "ConfiguracionRecurrenteNew",
        "ConfiguracionRecurrenteSave",
        "ConfiguracionRecurrenteRemove",
        "ConfiguracionRecurrenteAnular",
        "ConfiguracionRecurrentePrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ConfiguracionRecurrenteNew", this.new);
    // EventBus.$on("ConfiguracionRecurrenteSave", this.save);
    // EventBus.$on("ConfiguracionRecurrenteRemove", this.remove);
    // EventBus.$on("ConfiguracionRecurrenteAnular", this.PreguntaAnular);
    // EventBus.$on("ConfiguracionRecurrentePrintList", this.imprimirList);
    EventBus.$on("emitConfiguracionRecurrente", this.datostabla);


    this.cargarCaja()
    this.CargarTablaGeneral()



  },

  data: () => ({


    RSucursal: [],
    clientTel: '',
    BasicclientTel: '',
    ImpriclientTel: '',

    ejecucion: true,
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,


    hostname: '',
    hName: '',
    valid: true,
    Rdatos: [],
    drawerLeft: null,

    dialogImprimir: false,
    dialogFiltros: false,


    dialogFiltros: false,
    ObjTemTbDinam: null,


    Modallfiltro: {
      secuencia: null,
      cliente: null,
      proyecto: null,
      contraro: null
    },

    ObjTemTbDinam: null,
    ListItems: [],
    TbTotalItem: 0,
    TbPaginator: {
      page: 1,
      itemsPerPage: 5,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5
    },


    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    AutoFomatLabel: 0,
    AutoFormDisplc: false,
    objTem: [],

    Idcont: 0,
    disabPrecio: true,
    dialogProyect: false,
    dialogRetrinccion: false,
    RCliente: [],
    Rproyecto: [],
    RCaja: [],
    Rmoneda: [],
    RDocumento: [],
    Rproducto: [],

    Rfrecuencia: [
      { codigo: 1, des: 'dia', descripcion: "DÍA" },
      { codigo: 2, des: 'mes', descripcion: "MES" },
      { codigo: 3, des: 'ano', descripcion: "AÑO" }
    ],


    Imprimir: {
      fechaDesde: new Date().toISOString().substr(0, 10),
      fechaHasta: new Date().toISOString().substr(0, 10),
      estatus: "todas",
      formatoResporte: "completo",
      proyecto: null,
      cliente: null
    },

    form: {
      datosBasicos: {
        id: 0,
        cliente: null,
        proyecto: null,
        desde: new Date().toISOString().substr(0, 10),
        hasta: new Date().toISOString().substr(0, 10),
        frecuencia: 0,
        fe: null,
        tipoTiempo: null,
        ultimaFechaGenerada: null,
        porcientoRetencion: 0,
        valorRetencion: 0,
        porcientoDescuento: 0,
        valorDescuento: 0,
        otrosCargos: 0,
        posponer: false,
        comentario: null,
        esTipoFactura: true,
        contrato: '',
        borrado: false,
        caja: null,
        usuarioCancela: null,
        fechaCancela: null,
        documentoventaGenerar: null,
        moneda: null,
      },

      NewProyecto: {
        id: 0,
        nombre: '',
        activo: true,
        borrado: false
      },

      ProductoList: [],
      RetriccionList: [],
      DescuentoProducto: 0,
      LisRetriccion: [],


      datosRetriccion: {
        desde: null,
        hasta: null,
        precio: 0,
        porciento: 0,
        aumento: true,
        opcion: 1,
        retencion: 0,
        ListProd: [],
        DescuentoProducto: 0,

        TotalModRet: {
          Total: 0,
          precio: 0,
          cantida: 0,
          descuento: 0,
          recargo: 0,
          retencion: 0

        },

      },

      datosServicio: {
        Idcont: 0,
        producto: null,
        precio: 0,
        cantidad: 0,
        descuentoValor: 0,
        recargoValor: 0,
        descuentoPorciento: 0,
        recargoPorciento: 0,
        RadioDescValor: true,
        RadioRecargoValor: true,
        RadioRestValor: true,
        retencion: 0
      },


      Total: {
        Total: 0,
        precio: 0,
        cantida: 0,
        descuento: 0,
        recargo: 0,
        retencion: 0
      }

    },

    Cerrar_notif: {
      estado: false,
      nombre: "¿Desea cerrar sin  agregar?"
    },



    totales: {

      totalDenomiacionvalor: 0,
      totalTotal: 0

    },


    list: [],
    search: "",
    acti_tb_data: false,


    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },

    aalert: {
      estado: false,
      color: null
    },


    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],




  }),






  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    ver() {

      this.items.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });



    },



   // abre la BarraPrimaria una tabla con los datos de la configuracion recurrente.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }
    },

  // Tomando los datos de la tabla y poniéndolos en el formulario.
    datostabla(e) {

      this.form.RetriccionList = []
      this.form.ProductoList = []

      var obj = JSON.parse(JSON.stringify(e))

      obj.desde = new Date(e.desde).toISOString().substr(0, 10)
      obj.hasta = new Date(e.hasta).toISOString().substr(0, 10)

      delete obj["configuracionDocumentosRecurrentesDetalleList"]

      this.form.datosBasicos = obj


      // Rfrecuencia, form.datosBasicos.fe

      var ObjRow = this.Rfrecuencia.filter((ee) => ee.des == e.tipoTiempo);
      if (ObjRow.length > 0) {
        this.form.datosBasicos.fe = ObjRow[0]
      }

      if (e.configuracionDocumentosRecurrentesDetalleList.length == 0) {
        return;
      }

      var configDetall = JSON.parse(JSON.stringify(e.configuracionDocumentosRecurrentesDetalleList))


      configDetall.forEach(element => {


        if (element.hasOwnProperty('desde') && element.desde != null && element.hasOwnProperty('hasta') && element.hasta != null) {

        } else {
          element.Idcont = element.id
          element.retencion = 0
          element.producto = element.unidadesProductos

          if (element.valorDescuento != 0) {
            element.descuentoPorciento = 0
            element.descuentoValor = element.valorDescuento
            element.RadioDescValor = true
          } else {
            element.descuentoValor = 0
            element.descuentoPorciento = element.porcientoDescuento
            element.RadioDescValor = false

          }

          if (element.valorRecargo != 0) {
            element.recargoPorciento = 0
            element.recargoValor = element.valorRecargo
            element.RadioRecargoValor = true
          } else {
            element.recargoValor = 0
            element.recargoPorciento = element.porcientoRecargo
            element.RadioRecargoValor = false

          }

          this.form.ProductoList.push(element)
        }

      });


      this.confDetall = JSON.parse(JSON.stringify(e.configuracionDocumentosRecurrentesDetalleList))
      setTimeout(this.DataRest, 200), setTimeout(this.CalcularProducto, 200)

      // this.ReciboIngreso.FactList = JSON.parse(JSON.stringify(ObjRow));

    },

   // Guardando los datos en la base de datos.
    save() {


      if (this.$refs.form.validate()) {


        /*   this.form.RetriccionList.forEach(element => {
   
             var list = element.ListProd.filter(function (ell) {
               return ell != null;
   
             })
   
   
             element.ListProd = JSON.parse(JSON.stringify(list))
   
           });*/




        /*    var Obj = {
              usuario: JSON.parse(JSON.stringify(this.$user)),
              datosBasicos: this.form.datosBasicos,
              ProductoList: this.form.ProductoList.filter(function (el) {
                return el != null;
              }),
              DescuentoProducto: this.form.DescuentoProducto,
              RetriccionList: this.form.RetriccionList.filter(function (el1) {
                return el1 != null;
              }),
    
            }*/

        if (this.ejecucion == true) {

          this.ejecucion = false
          EventBus.$emit("loading", true);

          var obj = JSON.parse(JSON.stringify(this.form.datosBasicos))
          obj.id = this.form.datosBasicos.id
          //obj.configuracionDocumentosRecurrentesDetalleList = this.form.ProductoList.concat(this.form.RetriccionList)
          var listConfRec = JSON.parse(JSON.stringify(this.form.ProductoList))


          this.form.RetriccionList.forEach(element => {

            element.ListProd.forEach(el => {
              el.desde = element.desde
              el.hasta = element.hasta
            });

            listConfRec = listConfRec.concat(element.ListProd);
          });


          var listlist = []
          listConfRec.forEach(element => {
            var obj = {
              cantidad: element.cantidad,
              precio: element.precio,
              total: element.total,
              desde: element.desde,
              hasta: element.hasta,
              unidadesProductos: element.producto,
              unidad: element.producto.unidades,
              porcientoDescuento: element.descuentoPorciento,
              valorDescuento: element.descuentoValor,
              porcientoRecargo: element.recargoPorciento,
              valorRecargo: element.recargoValor,
              impuestos: (element.hasOwnProperty('impuestos') ? element.impuestos : 0)
            }
            listlist.push(obj);
          });

          obj.configuracionDocumentosRecurrentesDetalleList = listlist
          //   obj.configuracionDocumentosRecurrentesDetalleList = listConfRec
          this.$axios.post(this.$hostname + this.$hName + '/configuracion-recurrente/save', obj,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente", "bien"), setTimeout(this.CargarTablaGeneral, 500) })
            .catch(error => { this.alerta(error, 'error'), EventBus.$emit("loading", false); })

        }



      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }




    },




   // Una función que se llama cuando se hace clic en un botón.
    NotifMessage(e) {

      if (this.form.datosBasicos.id != 0 && this.form.datosBasicos.id != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: e
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una caja para eliminar", "error") }


    },

    Exec_notif(e) {

      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.ConfiguracionRecurrente)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.form.datosBasicos.id != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/borradoTipoCliente",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.alerta(res.data.mensage, res.data.estado) })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
   // Hacer una solicitud al servidor para anular los datos.
     anular() {
      /*   var Obj = {
           codigo: this.form.datosBasicos.id,
           usuario: JSON.parse(JSON.stringify(this.$user))
         }
   
   
   
         this.$axios.post(this.$hostname + this.$hName + '/services/documentosrecurrentes/Anular', { headers: this.$store.getters.GetheadersAxios })
           .then(res => { this.alerta(res.data.mensage, res.data.estado) })
           .catch(error => this.alerta(error, 'error'))*/


      // this.form.datosBasicos.usuario = this.$store.getters.GetdatosInfUsu.usuario.username

      if (this.form.datosBasicos.id !== 0) {

        EventBus.$emit("loading", true);
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/configuracion-recurrente/anular/" + this.form.datosBasicos.id,
            {
              headers: this.$store.getters.GetheadersAxios
            }
          )
          .then(res => {
            if (res.data.activa == false) {

              this.alerta("Dato anulado corretamente", "bien")
            } else {
              this.alerta("Dato no anulado", "error")
            }
          })
          .catch(error =>
            this.alerta("Dato no anulado", "error")
          );

      }




    },
   // Crear un nuevo objeto y asignarlo a la variable de formulario.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false

      this.AutoFormato = {
        precio: "",
        descuento: "",
        descuentoValor: "",
        recargoValor: "",
        retencion: "",
        recargoPorciento: "",

        RestmodPrecio: "",
        RestmodPorciento: "",
        RestmodRetencion: ""
      }

      this.objTem = [],
        this.Idcont = 0,

        this.form = {

          datosBasicos: {
            id: 0,
            cliente: null,
            proyecto: null,
            desde: new Date().toISOString().substr(0, 10),
            hasta: new Date().toISOString().substr(0, 10),
            frecuencia: 0,
            fe: null,
            tipoTiempo: null,
            ultimaFechaGenerada: null,
            porcientoRetencion: 0,
            valorRetencion: 0,
            porcientoDescuento: 0,
            valorDescuento: 0,
            otrosCargos: 0,
            posponer: false,
            comentario: null,
            esTipoFactura: true,
            contrato: '',
            borrado: false,
            caja: null,
            usuarioCancela: null,
            fechaCancela: null,
            documentoventaGenerar: null,
            moneda: null,
          },

          NewProyecto: {
            id: 0,
            nombre: '',
            activo: true,
            borrado: false
          },

          ProductoList: [],
          RetriccionList: [],
          DescuentoProducto: 0,
          LisRetriccion: [],


          datosRetriccion: {
            desde: null,
            hasta: null,
            precio: 0,
            porciento: 0,
            aumento: true,
            opcion: 1,
            retencion: 0,
            ListProd: [],
            DescuentoProducto: 0,

            TotalModRet: {
              Total: 0,
              precio: 0,
              cantida: 0,
              descuento: 0,
              recargo: 0,
              retencion: 0

            },

          },

          datosServicio: {
            Idcont: 0,
            producto: null,
            precio: 0,
            cantidad: 0,
            descuentoValor: 0,
            recargoValor: 0,
            descuentoPorciento: 0,
            recargoPorciento: 0,
            RadioDescValor: true,
            RadioRecargoValor: true,
            RadioRestValor: true,
            retencion: 0
          },


          Total: {
            Total: 0,
            precio: 0,
            cantida: 0,
            descuento: 0,
            recargo: 0,
            retencion: 0
          }

        }

      this.ejecucion = true
      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
    },

   // Llamar a la API y obtener los datos de la API.
    cargarMoneda() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/monedas/findallActivos/", { headers: headers })
        .then(res => (this.Rmoneda = res.data))
        .catch(error => console.log(error));
    },






    SelctCbCaja() {

      if (this.Modallfiltro.caja == null) {

        this.Modallfiltro.caja = ''
      }

      if (this.Modallfiltro.caja.codigo == undefined) {

        this.alerta("Seleccione una caja", "error");
        this.Modallfiltro.caja = ''
        return

      }
    },



    KeyUpDiaAc(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.Cuadres.dias = mynumeral(this.Cuadres.dias).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.Cuadres.dias == null) {

          this.Cuadres.dias = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }



    },


    // Comprobando si la entrada es un número o no.
    KeyUpCantidad(e) {

      //  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}



      if (e.target.value.length == 0) {

        e.target.value = 0

      }


      if (e.key) {

        var mynumeral = require('numeral')
        e.target.value = mynumeral(e.target.value).value()

        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (e.target.value == null) {

          e.target.value = 0
        }

      }
      else {

        e.key = ""

        console.log("no se puede poner letras o signos")
      }


      if (e.target.value.length == 0) {

        e.target.value = 0
        e.key = 0
      }

    },

    // ---------------------------------------

    CargarTablaGeneral() {

      //          this.$axios.post(this.hostname+this.hName+'/configuracion-recurrente/findallDocRecurrente',{
      //            objFiltro:null,
      //            usuario:JSON.parse(JSON.stringify(this.$user))
      //          })
      // .then(res=>( this.Rdatos=res.data))  
    },



    ChangeRadioRecargo(e) {
      if (e == true) {
        this.form.datosServicio.recargoPorciento = 0
      } else { this.form.datosServicio.recargoValor = 0 }

      setTimeout(this.AutoNumericCambio, 300);
    },

    ChangeRadiodesc(e) {
      if (e == true) {
        this.form.datosServicio.descuentoPorciento = 0
      } else { this.form.datosServicio.descuentoValor = 0 }

      setTimeout(this.AutoNumericCambio, 300);
    },

   // Apertura de un cuadro de diálogo.
    AbrirdialogProyect() {
      if (this.form.datosBasicos.proyecto != null) {
        this.form.NewProyecto = JSON.parse(JSON.stringify(this.form.datosBasicos.proyecto))
      }

      if (this.form.datosBasicos.proyecto == "") {

        this.form.NewProyecto = {
          id: 0,
          nombre: "",
          activo: true,
          borrado: false
        }

      }
      this.dialogProyect = !this.dialogProyect
    },

   // Comprobando si el usuario ha seleccionado un valor del menú desplegable. Si no, está mostrando un
   // mensaje de error.
    SelctCbCaja() {
      if (this.form.datosBasicos.caja == null) {

        this.form.datosBasicos.caja = ''
      }

      if (this.form.datosBasicos.caja.codigo == undefined) {

        this.alerta("Seleccione una caja", "error");
        this.form.datosBasicos.caja = ''
        return

      }

    },

  // Llamar a una API y obtener los datos de la API.
    cargarCaja() {


      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/cajas/findAllActivos/", { headers: headers })
        .then(res => { this.RCaja = res.data })
        .catch(error => console.log(error));


    },

    // Agregar un nuevo objeto a la matriz.
    AddModRestriccion() {

      if (this.form.datosRetriccion.ListProd == 0) {

        this.alerta("Debe de agregar al menos una restriccion", "error");

      } else {


        if (this.form.datosRetriccion.hasOwnProperty('edit')) {

          delete this.form.datosRetriccion["edit"]

        }


        //this.dialogRetrinccion=!this.dialogRetrinccion

        this.form.RetriccionList.unshift(JSON.parse(JSON.stringify(this.form.datosRetriccion)))

        this.form.datosRetriccion = {
          desde: null,
          hasta: null,
          precio: 0,
          porciento: 0,
          aumento: true,
          opcion: 1,
          retencion: 0,
          ListProd: [],
          DescuentoProducto: 0,

          TotalModRet: {
            Total: 0,
            precio: 0,
            cantida: 0,
            descuento: 0,
            recargo: 0,
            retencion: 0
          },

        }


        this.dialogRetrinccion = !this.dialogRetrinccion
      }
    },

    CerrarModRestriccion() {
      if (this.form.datosRetriccion.ListProd.length != 0) {
        this.Cerrar_notif.estado = !this.Cerrar_notif.estado;
      } else {
        this.ConfModRestriccion()
      }



    },

    ConfModRestriccion() {
      if (this.form.datosRetriccion.hasOwnProperty('edit')) {

        delete this.form.datosRetriccion["edit"]


        this.form.RetriccionList.unshift(JSON.parse(JSON.stringify(this.objTem)))
        this.objTem = []
        this.form.datosRetriccion = {
          desde: null,
          hasta: null,
          precio: 0,
          porciento: 0,
          aumento: true,
          opcion: 1,
          retencion: 0,
          ListProd: [],
          DescuentoProducto: 0,

          TotalModRet: {
            Total: 0,
            precio: 0,
            cantida: 0,
            descuento: 0,
            recargo: 0,
            retencion: 0
          },

        }
      }

      this.dialogRetrinccion = !this.dialogRetrinccion
      this.form.datosRetriccion = {
        desde: null,
        hasta: null,
        precio: 0,
        porciento: 0,
        aumento: true,
        opcion: 1,
        retencion: 0,
        ListProd: [],
        DescuentoProducto: 0,

        TotalModRet: {
          Total: 0,
          precio: 0,
          cantida: 0,
          descuento: 0,
          recargo: 0,
          retencion: 0
        },

      }
    },

    // Apertura de un cuadro de diálogo.
    AbrirModRestriccion() {

      if (this.form.ProductoList.length == 0) {

        this.alerta("Debe de agregar al menos un producto", "error");

      } else {
        this.dialogRetrinccion = !this.dialogRetrinccion

      }
    },
// Comprobando si el objeto tiene una propiedad llamada 'des' y si la tiene, está configurando el valor
// del objeto al valor de la propiedad 'des'.

    changeFrecuencia(e) {

      if (e != null) {
        if (e.hasOwnProperty('des')) {
          this.form.datosBasicos.tipoTiempo = JSON.parse(JSON.stringify(e.des))
        } else {
          this.form.datosBasicos.tipoTiempo = null
        }

      }
    },


   // Un método que se llama cuando el usuario selecciona un producto de un menú desplegable.
    ProductoChange(e) {

      if (e != null) {

        if (e.hasOwnProperty('productos')) {

          if (e.productos.calcularprecio == true) {

            this.disabPrecio = false

          } else { this.disabPrecio = true }

          this.form.datosServicio.precio = JSON.parse(JSON.stringify(e.precio))

          //this.FormatoNumero(,1,'precio')


        }

      } else {
        this.form.datosServicio.precio = 0
      }

    },

   // Comprobando si las fechas están dentro del rango de las fechas básicas  y aplica la retrincion en la tabla.
    ApplModRestrincion() {

      if (this.form.datosRetriccion.desde != null && this.form.datosRetriccion.hasta != null) {

        if (this.form.datosRetriccion.desde < this.form.datosBasicos.desde || this.form.datosRetriccion.desde > this.form.datosBasicos.hasta) {

          this.alerta("Las 'FECHAS RESTRICCIÓN' están fuera del rango de las 'FECHAS BÁSICOS' ", "error");

          return

        }


        if (this.form.datosRetriccion.hasta < this.form.datosBasicos.desde || this.form.datosRetriccion.hasta > this.form.datosBasicos.hasta) {

          this.alerta("Las 'FECHAS RESTRICCIÓN:(Desde y Hasta)' están fuera del rango de las 'FECHAS BÁSICOS' ", "error");

          return

        }


        if (this.form.datosRetriccion.hasta < this.form.datosRetriccion.desde) {

          this.alerta("La 'FECHA FINAL' es menor a la 'FECHA INICIAL'", "error");

          return

        }


      }


      if (this.form.datosRetriccion.porciento == 0 && this.form.datosRetriccion.precio == 0) {

        this.alerta("Debe de asignar un precio o un porciento", "error");

        return

      }

      if (this.form.RetriccionList.length > 0) {

        var cont = true

        this.form.RetriccionList.forEach(element => {


          //   console.log('entro forech 1')
          // console.log( element.Desde+'--'+this.form.datosRetriccion.Desde)


          if (this.form.datosRetriccion.desde >= element.desde && this.form.datosRetriccion.desde <= element.hasta) {

            cont = false
            this.alerta("Empalme de  fechas  en una ?RESTRICCIÓN? existente", "error");
            return
          }

          if (this.form.datosRetriccion.Hasta >= element.desde && this.form.datosRetriccion.hasta <= element.hasta) {

            cont = false
            this.alerta("Empalme de  fechas  en una ?RESTRICCIÓN? existente", "error");
            return
          }


        });


        if (cont == false) {

          this.alerta("Empalme de  fechas  en una ?RESTRICCIÓN? existente", "error");

          return

        }

      }



      if (this.$refs.formRetrinccion.validate()) {

        this.form.datosRetriccion.ListProd = []


        this.form.datosRetriccion.TotalModRet = {
          Total: 0,
          precio: 0,
          cantida: 0,
          descuento: 0,
          recargo: 0,
          retencion: 0
        }




        var jsn = JSON.parse(JSON.stringify(this.form.ProductoList))

        jsn.forEach(element => {

          element.retencion = JSON.parse(JSON.stringify(this.form.datosRetriccion.retencion))

          if (this.form.datosRetriccion.aumento == true) {

            if (this.form.datosRetriccion.precio != 0) {
              element.precio = (element.precio) + (this.form.datosRetriccion.precio)

            } else {
              element.precio = (element.precio) * (1 + (this.form.datosRetriccion.porciento / 100))
            }

          } else {


            if (this.form.datosRetriccion.precio != 0) {
              element.precio = (element.precio) - (this.form.datosRetriccion.precio)
            } else {
              element.precio = (element.precio) - (element.precio * (this.form.datosRetriccion.porciento / 100))

            }

          }


          var descc = 0, recc = 0, rett = element.retencion

          if (element.RadioDescValor == true) { this.form.datosRetriccion.TotalModRet.descuento += element.descuentoValor; descc = element.descuentoValor }
          else {
            if (element.descuentoPorciento == 0) { this.form.datosRetriccion.TotalModRet.descuento += element.descuentoPorciento; descc = element.descuentoPorciento }
            else { this.form.datosRetriccion.TotalModRet.descuento += (descc = ((element.precio * element.cantidad) * (element.descuentoPorciento / 100))) }
          }



          if (element.RadioRecargoValor == true) { this.form.datosRetriccion.TotalModRet.recargo += element.recargoValor; recc = element.recargoValor }
          else {
            if (element.recargoPorciento == 0) { this.form.datosRetriccion.TotalModRet.recargo += element.recargoPorciento; recc = element.recargoPorciento }
            else { this.form.datosRetriccion.TotalModRet.recargo += (recc = ((element.precio * element.cantidad) * (element.recargoPorciento / 100))) }
          }

          this.form.datosRetriccion.TotalModRet.retencion += element.retencion
          // if(element.RadioRestValor==true){ this.form.datosRetriccion.TotalModRet.retencion+=element.retencion;  rett=element.retencion }
          // else{ 
          // if(element.retencion==0){this.form.datosRetriccion.TotalModRet.retencion+=element.retencion;  rett=element.retencion  }
          // else{ this.form.datosRetriccion.TotalModRet.retencion+=( rett= ((element.precio * element.cantidad)  * (element.retencion/100))) }}

          // element.retencion=JSON.parse(JSON.stringify(rett))


          element.descuentoValor = JSON.parse(JSON.stringify(descc))
          element.recargoValor = JSON.parse(JSON.stringify(recc))


          element.RadioDescValor = true
          element.RadioRecargoValor = true
          element.RadioRestValor = true

          element.descuentoPorciento = 0
          element.recargoPorciento = 0


          element.total = ((element.precio * element.cantidad) + (recc + rett) - (descc))

          this.form.datosRetriccion.TotalModRet.Total += element.total
          this.form.datosRetriccion.TotalModRet.precio += element.precio
          this.form.datosRetriccion.TotalModRet.cantida += element.cantidad
          //this.form.Total.descuento+=element.descuento
          //this.form.Total.recargo+=element.recargo
          //this.form.datosRetriccion.TotalModRet.retencion+=element.retencion

          //por aquii

          //jsn.ListProd.push(element)
          this.form.datosRetriccion.ListProd.push(element)

        });

        //this.form.datosRetriccion.ListProd.push(jsn)
      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }

    },

    // Cálculo del total de los productos de la lista.
    ActuModRestrincion() {

      this.form.datosRetriccion.TotalModRet = {
        Total: 0,
        precio: 0,
        cantida: 0,
        descuento: 0,
        recargo: 0,
        retencion: 0
      }

      this.form.datosRetriccion.ListProd.forEach(element => {
        element.total = 0

        element.total = ((element.precio * element.cantidad) + (element.recargoValor + element.retencion) - (element.descuentoValor))

        this.form.datosRetriccion.TotalModRet.Total += element.total
        this.form.datosRetriccion.TotalModRet.precio += element.precio
        this.form.datosRetriccion.TotalModRet.cantida += element.cantidad

        this.form.datosRetriccion.TotalModRet.recargo += element.recargoValor
        this.form.datosRetriccion.TotalModRet.descuento += element.descuentoValor


        this.form.datosRetriccion.TotalModRet.retencion += element.retencion


      });

    },

   // Creando un nuevo objeto en la matriz.
    NewProducto() {

      this.form.datosServicio = {

        Idcont: 0,
        producto: null,
        precio: 0,
        cantidad: 0,
        descuentoValor: 0,
        recargoValor: 0,
        descuentoPorciento: 0,
        recargoPorciento: 0,
        RadioDescValor: true,
        RadioRecargoValor: true,
        RadioRestValor: true,
        retencion: 0

      }
      this.disabPrecio = true
    },


   // Adición de un producto a la lista de productos.
    AddProducto() {
      this.form.Total = {
        Total: 0,
        precio: 0,
        cantida: 0,
        descuento: 0,
        recargo: 0,
        retencion: 0
      }



      if (this.$refs.formProducto.validate()) {


        if (!this.form.datosServicio.hasOwnProperty('editar')) {
          this.Idcont += 1
          this.form.datosServicio.Idcont = JSON.parse(JSON.stringify(this.Idcont))
          this.form.ProductoList.unshift(this.form.datosServicio)


          if (this.form.RetriccionList.length > 0) {
            this.AgrProdRestExit(this.form.datosServicio)
          }


          this.NewProducto()

        } else {

          //this.form.datosServicio=JSON.parse(JSON.stringify(e)) 
          // this.objTem=JSON.parse(JSON.stringify(e)) 
          // this.objEdit=JSON.parse(JSON.stringify(e))

          this.form.ProductoList.forEach(element => {

            if (element.Idcont == this.form.datosServicio.Idcont) {

              if (this.form.RetriccionList.length > 0) {
                this.ActProdRestExit(this.form.datosServicio)
              }


              delete this.form.ProductoList[this.form.ProductoList.indexOf(element)]




            }


          });


          this.form.ProductoList.unshift(this.form.datosServicio)
          this.NewProducto()

        }
      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }

      setTimeout(this.CalcularProducto, 300);


    },




    // Recorriendo la matriz y actualizando los valores.
    ActProdRestExit(a) {

      this.form.RetriccionList.forEach(element => {

        var Jsn = JSON.parse(JSON.stringify(a))

        if (element.aumento == true) {
          if (element.precio != 0) {
            Jsn.precio = (element.precio) + (Jsn.precio)
          }
        }
        else {

          if (element.precio != 0) {
            Jsn.precio = (Jsn.precio) - (element.precio)
          }
        }


        element.ListProd.forEach(elt1 => {


          if (elt1.Idcont == Jsn.Idcont) {

            delete element.ListProd[element.ListProd.indexOf(elt1)]

            element.ListProd.unshift(Jsn)
          }
        });


        element.TotalModRet = {
          Total: 0,
          cantida: 0,
          descuento: 0,
          precio: 0,
          recargo: 0,
          retencion: 0,
        }


        element.ListProd.forEach(element1 => {

          if (element1.RadioDescValor == false) {
            element1.recargoValor = ((element1.precio * element1.cantidad) * (element1.recargoPorciento / 100))
            element1.recargoPorciento = 0
            element1.RadioRecargoValor = true
          }

          if (element1.RadioDescValor == false) {
            element1.descuentoValor = ((element1.precio * element1.cantidad) * (element1.descuentoPorciento / 100))
            element1.descuentoPorciento = 0
            element1.RadioDescValor = true
          }

          if (element1.RadioRestValor == false) {
            element1.retencion = ((element1.precio * element1.cantidad) * (element1.retencion / 100))
            element1.RadioRestValor = true
          }


          element1.total = ((element1.precio * element1.cantidad) + (element1.recargoValor + element1.retencion) - (element1.descuentoValor))

          element.TotalModRet.Total += element1.total
          element.TotalModRet.precio += element1.precio
          element.TotalModRet.cantida += element1.cantidad

          element.TotalModRet.recargo += element1.recargoValor
          element.TotalModRet.descuento += element1.descuentoValor

          element.TotalModRet.retencion += element1.retencion


        });



      });

    },


    // Adición de un producto a una lista de productos.
    AgrProdRestExit(e) {

      this.form.RetriccionList.forEach(element => {

        var Jsn = JSON.parse(JSON.stringify(e))

        if (element.aumento == true) {
          if (element.precio != 0) {
            Jsn.precio = (element.precio) + (Jsn.precio)
          }
        }
        else {

          if (element.precio != 0) {
            Jsn.precio = (Jsn.precio) - (element.precio)
          }
        }


        element.ListProd.unshift(Jsn)

        element.TotalModRet = {
          Total: 0,
          cantida: 0,
          descuento: 0,
          precio: 0,
          recargo: 0,
          retencion: 0,
        }


        element.ListProd.forEach(element1 => {


          if (element1.RadioDescValor == false) {
            element1.recargoValor = ((element1.precio * element1.cantidad) * (element1.recargoPorciento / 100))
            element1.recargoPorciento = 0
            element1.RadioRecargoValor = true
          }

          if (element1.RadioDescValor == false) {
            element1.descuentoValor = ((element1.precio * element1.cantidad) * (element1.descuentoPorciento / 100))
            element1.descuentoPorciento = 0
            element1.RadioDescValor = true
          }

          if (element1.RadioRestValor == false) {
            element1.retencion = ((element1.precio * element1.cantidad) * (element1.retencion / 100))
            element1.RadioRestValor = true
          }


          element1.total = ((element1.precio * element1.cantidad) + (element1.recargoValor + element1.retencion) - (element1.descuentoValor))

          element.TotalModRet.Total += element1.total
          element.TotalModRet.precio += element1.precio
          element.TotalModRet.cantida += element1.cantidad

          element.TotalModRet.recargo += element1.recargoValor
          element.TotalModRet.descuento += element1.descuentoValor


          element.TotalModRet.retencion += element1.retencion


        });



      });

    },

   // Cálculo del total de la lista de productos.
    CalcularProducto() {

      this.form.Total = {
        Total: 0,
        precio: 0,
        cantida: 0,
        descuento: 0,
        recargo: 0,
        retencion: 0
      }


      this.form.ProductoList.forEach(element => {
        var descc = 0, recc = 0, rett = 0

        if (element.RadioDescValor == true) { this.form.Total.descuento += element.descuentoValor; descc = element.descuentoValor }
        else {
          if (element.descuentoPorciento == 0) { this.form.Total.descuento += element.descuentoPorciento; descc = element.descuentoPorciento }
          else { this.form.Total.descuento += (descc = ((element.precio * element.cantidad) * (element.descuentoPorciento / 100))) }
        }



        if (element.RadioRecargoValor == true) { this.form.Total.recargo += element.recargoValor; recc = element.recargoValor }
        else {
          if (element.recargoPorciento == 0) { this.form.Total.recargo += element.recargoPorciento; recc = element.recargoPorciento }
          else { this.form.Total.recargo += (recc = ((element.precio * element.cantidad) * (element.recargoPorciento / 100))) }
        }


        if (element.RadioRestValor == true) { this.form.Total.retencion += element.retencion; rett = element.retencion }
        else {
          if (element.retencion == 0) { this.form.Total.recargo += element.retencion; rett = element.retencion }
          else { this.form.Total.retencion += (rett = ((element.precio * element.cantidad) * (element.retencion / 100))) }
        }




        element.total = ((element.precio * element.cantidad) + (recc + rett) - (descc))

        this.form.Total.Total += element.total
        this.form.Total.precio += element.precio
        this.form.Total.cantida += element.cantidad


      });


      //eoo aquii


    },

    delModRetrincion(item) {

      const index = this.form.datosRetriccion.List.indexOf(item)
      confirm('¿Desea eliminar esta restricción?') && this.form.datosRetriccion.List.splice(index, 1)

    },

// Tomando el objeto de la matriz y colocándolo en un nuevo objeto.

    editRestrincion(e) {

      this.objTem = JSON.parse(JSON.stringify(e))
      this.form.datosRetriccion = JSON.parse(JSON.stringify(e))
      this.form.datosRetriccion.edit = true

      const index = this.form.RetriccionList.indexOf(e)
      this.form.RetriccionList.splice(index, 1)

      this.dialogRetrinccion = !this.dialogRetrinccion



    },


   // Eliminación de un producto de la lista de productos.
    delProducto(item) {

      const index = this.form.ProductoList.indexOf(item)
      var c = confirm('¿Desea eliminar este producto?')



      if (c == true) {

        if (this.form.RetriccionList.length > 0) {


          this.form.RetriccionList.forEach(element => {

            element.ListProd.forEach(element1 => {
              if (element1.Idcont == item.Idcont) {
                delete element.ListProd[element.ListProd.indexOf(element1)]
              }

            });

            element.TotalModRet = {
              Total: 0,
              cantida: 0,
              descuento: 0,
              precio: 0,
              recargo: 0,
              retencion: 0,
            }


            element.ListProd.forEach(element2 => {

              element2.total = ((element2.precio * element2.cantidad) + (element2.recargoValor + element2.retencion) - (element2.descuentoValor))

              element.TotalModRet.Total += element2.total
              element.TotalModRet.precio += element2.precio
              element.TotalModRet.cantida += element2.cantidad

              element.TotalModRet.recargo += element2.recargoValor
              element.TotalModRet.descuento += element2.descuentoValor

              element.TotalModRet.retencion += element2.retencion


            });


          });

        }

        this.form.ProductoList.splice(index, 1)

      }

      setTimeout(this.AutoNumericCambio, 300);
    },


   // Establecer el valor del campo de entrada al valor del objeto.
    editProducto(e) {

      e.editar = true
      this.form.datosServicio = JSON.parse(JSON.stringify(e))
      this.ProductoChange(e.producto)


      setTimeout(this.AutoNumericCambio, 300);
    },

    SelctCbProduc() {

      if (this.form.datosServicio.producto == null) {
        this.form.datosServicio.producto = "";
      }

      if (this.form.datosServicio.producto.unidadesProductosPK == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.form.datosServicio.producto = "";
        return;
      }


    },

   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    CargarProducto() {


      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/unidades-productos/*/false/buscar/true",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.Rproducto = res.data));

      //buscarservicios= en buscar
    },


   // Un método que se llama cuando el usuario ingresa un valor en la entrada, si el valor es mayor o
   // igual a 3, realizará una solicitud al servidor y devolverá los datos.
    CbFilProducto(e) {

      if (e.target.value.length == 0) {

        this.CargarProducto()

      }

      if (e.target.value.length >= 3) {


        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/unidades-productos/" + e.target.value + "/false/buscar/true",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproducto = res.data));

      }

    },

    // Una función que se llama cuando se hace clic en un botón y guarda el proyecto en la base de dato.
    SaveProyect() {

      //if(this.form.ProductoList.length==0){
      //
      //this.alerta("Debe de agregar al menos un producto", "error");
      //
      //return
      //}

      if (this.$refs.formNewProyecto.validate()) {

        this.$axios
          .post(this.$hostname + this.$hName + "/Proyecto/save/", this.form.NewProyecto, { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.alerta("Dato guardado correctamente!", "bien1"), this.form.datosBasicos.proyecto = null, this.dialogProyect = !this.dialogProyect })
          .catch(error => this.alerta("Dato no guardado correctamente", "error"));

      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }

    },

   // Hacer una solicitud HTTP al servidor y obtener una respuesta.
    cargarDocumento() {


      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/documentoventas/findallActivos/", { headers: headers })
        .then(res => (this.RDocumento = res.data))
        .catch(error => console.log(error));


      // this.$axios.post(this.$hostname + this.$hName+'/services/FacturaControlada/GetfindallDocumento',{
      // usuario:JSON.parse(JSON.stringify(this.$user)) 
      // })
      // .then(res=>( this.RDocumento=res.data))


    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {


      if (this.form.datosBasicos.documentoventaGenerar == null) {

        this.form.datosBasicos.documentoventaGenerar = ''
      }

      if (this.form.datosBasicos.documentoventaGenerar.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.form.datosBasicos.documentoventaGenerar = ''
        return

      }

    },



   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbMoneda() {

      if (this.form.datosBasicos.moneda == null) {

        this.form.datosBasicos.moneda = ''
      }

      if (this.form.datosBasicos.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.form.datosBasicos.moneda = ''
        return

      }

    },

 // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbFrecuencia() {


      if (this.form.datosBasicos.fe == null) {

        this.form.datosBasicos.fe = ''
      }

      if (this.form.datosBasicos.fe.codigo == undefined) {

        this.alerta("Seleccione una Frecuencia", "error");
        this.form.datosBasicos.fe = ''
        return

      }

    },



    // Comprobando si la entrada es un número o no.
    KeyUpCantidad(e) {

      //  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}



      if (e.target.value.length == 0) {

        e.target.value = 0

      }


      if (e.key) {

        var mynumeral = require('numeral')
        e.target.value = mynumeral(e.target.value).value()

        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (e.target.value == null) {

          e.target.value = 0
        }

      }
      else {

        e.key = ""

        console.log("no se puede poner letras o signos")
      }


      if (e.target.value.length == 0) {

        e.target.value = 0
        e.key = 0
      }



    },


    CantidadServ(e) {

      if (e.key) {
        //var mynumeral = require('numeral')
        this.form.datosServicio.cantidad = numeral(this.form.datosServicio.cantidad).value()

        if (this.form.datosServicio.cantidad == null) {

          this.form.datosServicio.cantidad = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },


    CantidadRepet(e) {






      if (e.key) {
        //var mynumeral = require('numeral')
        this.form.datosBasicos.cantidad = numeral(this.form.datosBasicos.cantidad).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.form.datosBasicos.cantidad == null) {

          this.form.datosBasicos.cantidad = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },

   // Llamar a la API y obtener los datos de la API.
    cargarProyecto() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/Proyecto/findallActivos/", { headers: headers })
        .then(res => (this.Rproyecto = res.data))
        .catch(error => console.log(error));

    },

   // Comprobando si el usuario ha seleccionado un proyecto de la lista desplegable. Si el usuario no
   // ha seleccionado un proyecto, el código mostrará un mensaje de error.
    SelctCbProyectoImprime() {

      if (this.Imprimir.proyecto === "") {

        this.Imprimir.proyecto = null
      }

      if (this.Imprimir.proyecto.id === undefined) {

        this.alerta("Seleccione un proyecto", "error");
        this.Imprimir.proyecto = null
        return

      }

    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbProyectoFiltro() {

      if (this.Modallfiltro.proyecto == null) {

        this.Modallfiltro.proyecto = ''
      }

      if (this.Modallfiltro.proyecto.id == undefined) {

        this.alerta("Seleccione un proyecto", "error");
        this.Modallfiltro.proyecto = ''
        return

      }

    },
// Comprobando si el usuario ha seleccionado un proyecto de la lista desplegable. Si el usuario no ha
// seleccionado un proyecto, se mostrará un mensaje de error.

    SelctCbProyecto() {

      if (this.form.datosBasicos.proyecto == null) {

        this.form.datosBasicos.proyecto = ''
      }

      if (this.form.datosBasicos.proyecto.id == undefined) {

        this.alerta("Seleccione un proyecto", "error");
        this.form.datosBasicos.proyecto = ''
        return

      }

    },

// Realizar una solicitud GET al servidor y devolver los datos al de una lista de cliente.

    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCliente = res.data))

    },


   // Un método que se llama cuando el usuario selecciona un cliente del menú desplegable.
    ClienteSelect(e) {

      if (e != null) {
        if (e.hasOwnProperty('codigo')) {

          //this.ObjCliente=e
          this.clientTel = 'Tel.' + e.cedrnc


        } else {
          this.clientTel = ''
        }

      }

    },

 // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {

      if (e.target.value.length >= 0) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            '/clientes/findtodo/' + (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  + '/3/25/0',
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.RCliente = res.data));
      }

    },



    // Comprobando si el cliente es nulo o indefinido. Si es nulo o indefinido, establecerá el cliente
    // en una cadena vacía.
    SelctCbFiltroCliente() {

      if (this.form.datosBasicos.cliente == null) {

        this.form.datosBasicos.cliente = ''
      }

      if (this.form.datosBasicos.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.form.datosBasicos.cliente = ''
        return

      }


    },
// Un método que se llama cuando un usuario selecciona un cliente de una lista desplegable.

    ClienteSelectTel(e) {

      if (e != null) {
        if (e.hasOwnProperty('codigo')) {

          //this.ObjCliente=e
          this.BasicclientTel = 'Tel.' + e.cedrnc


        } else {
          this.BasicclientTel = ''
        }

      }

    },


    // Comprobando si el objeto tiene una propiedad llamada código.
    ImpriClienteSelectTel(e) {

      if (e != null) {
        if (e.hasOwnProperty('codigo')) {

          //this.ObjCliente=e
          this.ImpriclientTel = 'Tel.' + e.cedrnc


        } else {
          this.ImpriclientTel = ''
        }

      }

    },





   // Comprobando si el cliente está seleccionado, si no, muestra un mensaje de error.
    SelctCbClienteImprime() {

      if (this.Imprimir.cliente == null) {

        this.Imprimir.cliente = ''
      }

      if (this.Imprimir.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Imprimir.cliente = ''
        return

      }

    },



   // Comprobando si el cliente es nulo o indefinido.
    SelctCbCliente() {

      if (this.form.datosBasicos.cliente == null) {

        this.form.datosBasicos.cliente = ''
      }

      if (this.form.datosBasicos.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.form.datosBasicos.cliente = ''
        return

      }


    },

// Un método que se llama cuando el usuario hace clic en el botón y abre el modal de impresion.

    imprimirList() {
      this.dialogImprimir = !this.dialogImprimir;
    },

    // Abriendo una nueva ventana y escribiendo el pdf en ella.
    ImprimirConfiguracion() {
      // window.open(this.$hostname + this.$hName + '/services/documentosrecurrentes/getConfiguracionesList/' + JSON.stringify(this.Imprimir))
      // axios.get('http://10.0.0.89:8080/micromarket/services/documentosrecurrentes/getConfiguracionesList/'+JSON.stringify(this.Imprimir))
      // .then(res=>( this.Rproyecto=res.data))

      // new Date().toISOString().substr(0, 10),
      if (this.Imprimir.proyecto === "") {
        this.Imprimir.proyecto = null;
      }
      EventBus.$emit("loading", true);
      this.$axios
        .post(this.$hostname + this.$hName + "/configuracion-recurrente/imprimirListRecurente", this.Imprimir, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          /*let pdfWindow = window.open("Reporte de imprecion")
          pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
            encodeURI(res.data) + "'></embed>")*/
            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
            EventBus.$emit("openPDF",PDF ,'Conf recurrente');
          EventBus.$emit("loading", false);
        })
        .catch(error => this.alerta("Dato no guardado correctamente", "error"));



    },

    // Creando un nuevo objeto llamado Imprimir y asignándolo al objeto de datos.
    LimpiarImprimirConf() {
      this.Imprimir = {
        fechaDesde: new Date().toISOString().substr(0, 10),
        fechaHasta: new Date().toISOString().substr(0, 10),
        estatus: "todas",
        formatoResporte: "completo",
        proyecto: null,
        cliente: null
      }

    },

    // Establecer la fecha a la fecha de hoy si el usuario no ingresa una fecha.
    BlurDesde() {

      if (this.Imprimir.fechaDesde.toString().length == 0) {
        this.Imprimir.fechaDesde = new Date().toISOString().substr(0, 10)
      }

    },


    // Establecer la fecha a la fecha de hoy si el usuario no ingresa una fecha.
    BlurHasta() {

      if (this.Imprimir.fechaHasta.toString().length == 0) {
        this.Imprimir.fechaHasta = new Date().toISOString().substr(0, 10)
      }

    },





    newRecurente() {
      // console.log('La pampara esta, entro')

      this.objTem = [],
        this.Idcont = 0,

        this.form = {

          datosBasicos: {
            id: 0,
            cliente: null,
            proyecto: null,
            desde: new Date().toISOString().substr(0, 10),
            hasta: new Date().toISOString().substr(0, 10),
            frecuencia: 0,
            fe: null,
            tipoTiempo: null,
            ultimaFechaGenerada: null,
            porcientoRetencion: 0,
            valorRetencion: 0,
            porcientoDescuento: 0,
            valorDescuento: 0,
            otrosCargos: 0,
            posponer: false,
            comentario: null,
            esTipoFactura: true,
            contrato: '',
            borrado: false,
            caja: null,
            usuarioCancela: null,
            fechaCancela: null,
            documentoventaGenerar: null,
            moneda: null,
          },

          NewProyecto: {
            id: 0,
            nombre: '',
            activo: true,
            borrado: false
          },

          ProductoList: [],
          RetriccionList: [],
          DescuentoProducto: 0,
          LisRetriccion: [],


          datosRetriccion: {
            desde: null,
            hasta: null,
            precio: 0,
            porciento: 0,
            aumento: true,
            opcion: 1,
            retencion: 0,
            ListProd: [],
            DescuentoProducto: 0,

            TotalModRet: {
              Total: 0,
              precio: 0,
              cantida: 0,
              descuento: 0,
              recargo: 0,
              retencion: 0

            },

          },

          datosServicio: {
            Idcont: 0,
            producto: null,
            precio: 0,
            cantidad: 0,
            descuentoValor: 0,
            recargoValor: 0,
            descuentoPorciento: 0,
            recargoPorciento: 0,
            RadioDescValor: true,
            RadioRecargoValor: true,
            RadioRestValor: true,
            retencion: 0
          },


          Total: {
            Total: 0,
            precio: 0,
            cantida: 0,
            descuento: 0,
            recargo: 0,
            retencion: 0
          }

        }

      if (this.drawerLeft == true) {

        this.CargarTablaGeneral()

      }

    },


   // Tomando los datos de la matriz confDetall y empujándolos a la matriz form.RetriccionList.
    DataRest() {


      var obj3 = this.confDetall.filter(e => e.desde != null && e.hasta != null)

      obj3.forEach(obj3ele => {

        var Fechdes = new Date(obj3ele.desde).toISOString().substr(0, 10)
        var Fechhas = new Date(obj3ele.hasta).toISOString().substr(0, 10)
        obj3ele.Idcont = obj3ele.id
        obj3ele.retencion = 0
        obj3ele.producto = obj3ele.unidadesProductos

        if (obj3ele.valorDescuento != 0) {
          obj3ele.descuentoValor = obj3ele.valorDescuento
          obj3ele.RadioDescValor = true
        } else {
          obj3ele.descuentoValor = 0
          obj3ele.RadioDescValor = true
        }


        if (obj3ele.valorRecargo != 0) {

          obj3ele.recargoValor = obj3ele.valorRecargo
          obj3ele.RadioRecargoValor = true
        } else {
          obj3ele.recargoValor = 0
          obj3ele.RadioRecargoValor = true
        }

        var jsn = {
          DescuentoProducto: 0,
          desde: Fechdes,   //element.desde.split("T")[0],
          hasta: Fechhas,  //element.hasta.split("T")[0],
          ListProd: [],
          TotalModRet: { Total: 0, precio: 0, cantida: 0, descuento: 0, recargo: 0, retencion: 0 },
          aumento: true,
          opcion: 1,
          porciento: 0,
          precio: 10,
          retencion: 0,
        }

        if (this.form.RetriccionList.length == 0) {
          var objPro = JSON.parse(JSON.stringify(obj3ele))
          var objjj = this.form.ProductoList.filter(e =>
            e.unidadesProductos.unidadesProductosPK.unidad == objPro.unidadesProductos.unidadesProductosPK.unidad &&
            e.unidadesProductos.unidadesProductosPK.producto == objPro.unidadesProductos.unidadesProductosPK.producto
          )

          if (obj3ele.precio > objjj[0].precio) {
            jsn.aumento = true
            jsn.precio = obj3ele.precio - objjj[0].precio
          } else {
            jsn.aumento = false
            jsn.precio = objjj[0].precio - obj3ele.precio
          }
          objPro.desde = Fechdes
          objPro.hasta = Fechhas
          jsn.ListProd.push(objPro)
          this.form.RetriccionList.push(jsn)
        } else {

          var obj11 = this.form.RetriccionList.filter(e => e.desde == Fechdes && e.hasta == Fechhas)

          if (obj11.length == 0) {
            var objPro = JSON.parse(JSON.stringify(obj3ele))
            var objjj = this.form.ProductoList.filter(e =>
              e.unidadesProductos.unidadesProductosPK.unidad == objPro.unidadesProductos.unidadesProductosPK.unidad &&
              e.unidadesProductos.unidadesProductosPK.producto == objPro.unidadesProductos.unidadesProductosPK.producto
            )

            if (obj3ele.precio > objjj[0].precio) {
              jsn.aumento = true
              jsn.precio = obj3ele.precio - objjj[0].precio
            } else {
              jsn.aumento = false
              jsn.precio = objjj[0].precio - obj3ele.precio
            }
            objPro.desde = Fechdes
            objPro.hasta = Fechhas
            jsn.ListProd.push(objPro)
            this.form.RetriccionList.push(jsn)
          } else {

            const index = this.form.RetriccionList.indexOf(obj11[0]);
            obj3ele.desde = new Date(obj3ele.desde).toISOString().substr(0, 10)
            obj3ele.hasta = new Date(obj3ele.hasta).toISOString().substr(0, 10)
            this.form.RetriccionList[index].ListProd.push(obj3ele)
          }
        }
      });

      setTimeout(this.CalcularTotalRetriccion, 200)
      //-- calcular totales


    },

   // Cálculo del total de los productos de la lista.
    CalcularTotalRetriccion() {

      this.form.RetriccionList.forEach(element => {
        element.TotalModRet = {
          Total: 0,
          cantida: 0,
          descuento: 0,
          precio: 0,
          recargo: 0,
          retencion: 0,
        }
        element.ListProd.forEach(element1 => {
          element.TotalModRet.Total += element1.total
          element.TotalModRet.precio += element1.precio
          element.TotalModRet.cantida += element1.cantidad

          element.TotalModRet.recargo += element1.recargoValor
          element.TotalModRet.descuento += element1.descuentoValor


          element.TotalModRet.retencion += element1.retencion
        });

      });


    },

    DataRest1() {

      this.confDetall.forEach(element => {

        if (element.hasOwnProperty('desde') && element.desde != null && element.hasOwnProperty('hasta') && element.hasta != null) {



          element.Idcont = element.id
          element.retencion = 0
          //element.
          if (element.valorDescuento != 0) {

            element.descuentoValor = element.valorDescuento
            element.RadioDescValor = true
          } else {

            element.descuentoValor = 0
            element.RadioDescValor = true

          }


          if (element.valorRecargo != 0) {

            element.recargoValor = element.valorRecargo
            element.RadioRecargoValor = true
          } else {

            element.recargoValor = 0
            element.RadioRecargoValor = true

          }


          var pro = {
            precio: element.precio,
            productos: element.producto,
            unidades: element.unidad

          }

          element.producto = pro


          //  element.producto.precio=element.precio
          // element.producto.productos=element.producto
          //      element.producto.unidades=element.unidad

          //this.form.ProductoList.push(element)

          // wowaqui


          var jsn = {

            DescuentoProducto: 0,
            desde: new Date(element.desde).toISOString().substr(0, 10),   //element.desde.split("T")[0],
            hasta: new Date(element.hasta).toISOString().substr(0, 10),  //element.hasta.split("T")[0],
            ListProd: [],
            TotalModRet: { Total: 0, precio: 0, cantida: 0, descuento: 0, recargo: 0, retencion: 0 },
            aumento: true,
            opcion: 1,
            porciento: 0,
            precio: 10,
            retencion: 0,

          }




          if (this.form.RetriccionList.length > 0) {

            this.form.RetriccionList.forEach(element1 => {


              if (jsn.desde == new Date(element1.desde).toISOString().substr(0, 10) && jsn.hasta == new Date(element1.hasta).toISOString().substr(0, 10)) {

                element1.ListProd.push(element)

              } else {
                this.form.ProductoList.forEach(element2 => {

                  if (element2.producto.productos.codigo == element.producto.productos.codigo && element2.producto.unidades.codigo == element.producto.unidades.codigo) {
                    element.Idcont = element2.Idcont
                    this.Idcont = element2.Idcont
                    if (element.precio > element2.precio) {
                      jsn.aumento = true
                      jsn.precio = element.precio - element2.precio
                    } else {
                      jsn.aumento = false
                      jsn.precio = element2.precio - element.precio

                    }



                    jsn.ListProd.push(element)

                  }

                });


                this.form.RetriccionList.push(jsn)

              }

            });


          } else {

            this.form.ProductoList.forEach(element3 => {

              if (element3.producto.productos.codigo == element.producto.productos.codigo && element3.producto.unidades.codigo == element.producto.unidades.codigo) {

                element.Idcont = element3.Idcont
                this.Idcont = element3.Idcont
                if (element.precio > element3.precio) {
                  jsn.aumento = true
                  jsn.precio = element.precio - element3.precio
                } else {
                  jsn.aumento = false
                  jsn.precio = element3.precio - element.precio

                }



                jsn.ListProd.push(element)

              }

            });


            this.form.RetriccionList.push(jsn)


          }
        }
      });





      this.form.RetriccionList.forEach(ele => {

        ele.ListProd.forEach(ele1 => {

          var descc = 0, recc = 0, rett = ele1.retencion

          if (ele1.RadioDescValor == true) { ele.TotalModRet.descuento += ele1.descuentoValor; descc = ele1.descuentoValor }
          else {
            if (ele1.descuentoPorciento == 0) { ele.TotalModRet.descuento += ele1.descuentoPorciento; descc = ele1.descuentoPorciento }
            else { ele.TotalModRet.descuento += (descc = ((ele1.precio * ele1.cantidad) * (ele1.descuentoPorciento / 100))) }
          }



          if (ele1.RadioRecargoValor == true) { ele.TotalModRet.recargo += ele1.recargoValor; recc = ele1.recargoValor }
          else {
            if (ele1.recargoPorciento == 0) { ele.TotalModRet.recargo += ele1.recargoPorciento; recc = ele1.recargoPorciento }
            else { ele.TotalModRet.recargo += (recc = ((ele1.precio * ele1.cantidad) * (ele1.recargoPorciento / 100))) }
          }

          ele.TotalModRet.retencion += ele1.retencion
          // if(ele1.RadioRestValor==true){ ele.TotalModRet.retencion+=ele1.retencion;  rett=ele1.retencion }
          // else{ 
          // if(ele1.retencion==0){ele.TotalModRet.retencion+=ele1.retencion;  rett=ele1.retencion  }
          // else{ ele.TotalModRet.retencion+=( rett= ((ele1.precio * ele1.cantidad)  * (ele1.retencion/100))) }}

          // ele1.retencion=JSON.parse(JSON.stringify(rett))


          ele1.descuentoValor = JSON.parse(JSON.stringify(descc))
          ele1.recargoValor = JSON.parse(JSON.stringify(recc))


          ele1.RadioDescValor = true
          ele1.RadioRecargoValor = true
          ele1.RadioRestValor = true

          ele1.descuentoPorciento = 0
          ele1.recargoPorciento = 0


          ele1.total = ((ele1.precio * ele1.cantidad) + (recc + rett) - (descc))

          ele.TotalModRet.Total += ele1.total
          ele.TotalModRet.precio += ele1.precio
          ele.TotalModRet.cantida += ele1.cantidad


        });


        if (ele.ListProd.length < this.form.ProductoList.length) {

          const index = this.form.RetriccionList.indexOf(ele)

          //this.form.RetriccionList.splice(index, 1)

          delete this.form.RetriccionList[index]

        }



      });


    },


    // Comprobando si el valor de e es 1 o 2 y luego poniendo el valor de
    // form.datosRetriccion.porciento o form.datosRetriccion.precio a 0.
    PrecioPorciento(e) {

      if (e == 1) {

        this.form.datosRetriccion.porciento = 0
        //this.AutoFormato.RestmodPorciento='0.00'
      }

      if (e == 2) {
        this.form.datosRetriccion.precio = 0
        //this.AutoFormato.RestmodPrecio='0.00'

      }

    },



    //---------------------------------------- aaqui


    // Limpieza del filtro y recarga de los datos.
    Limpiarfiltro() {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        secuencia: null,
        cliente: null,
        proyecto: null,
        contraro: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.secuencia != null && this.Modallfiltro.secuencia.toString().length == 0) { this.Modallfiltro.secuencia = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.secuencia)
    },

  // Un método que se llama cuando el usuario cambia la página o el número de elementos por página.
    CargarPaginacion(e, s) {

      this.Modallfiltro.secuencia = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    // Llamar a una API y devolver una lista de elementos.
    CargarListTb() {

      var cliente = null
      var proyecto = null
      if (this.Modallfiltro.cliente != null) { cliente = this.Modallfiltro.cliente.codigo }
      if (this.Modallfiltro.proyecto != null) { proyecto = this.Modallfiltro.proyecto.id }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.secuencia + "/" + cliente + "/" + proyecto + "/" + this.Modallfiltro.contraro
      this.$axios.get(this.$hostname + this.$hName + '/configuracion-recurrente/findAllDocumentoRecurrentePlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })
    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "bien1") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }


      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }

    },

    FormatoFecha(params0) {
      var params = new Date(params0).toISOString().substr(0, 10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },





    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()
    },


  }

  ,
  computed: {

    ActDescBtn() {


      if (this.form.datosBasicos.id > 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if (this.form.datosBasicos.activa == false) {
          this.EstadoBtn.anular = false
          this.EstadoBtn.guardar = false

        }



        // var objjct = {
        //     save: true,
        //     eliminar: false,
        //     anular: true,
        //     nuevo: true,
        //     imprimir: true,
        //     vercuenta:false, 
        //   };

      }


      if (this.form.datosBasicos.id <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }



        // var objjct = {
        //       save: true,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false, 
        //     };


      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }





  }


};
</script>

<style>

</style>
