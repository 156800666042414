<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Verificación Cuadre de Caja'"
  :descripcion="'Verificación Cuadre de Caja'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitVerifCuadreCaja'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="verifCuadreCaja.desde"
        hide-details
        @blur="BlurDesde"
        v-on:blur="BuscarCuadreCaja"
        @keyup.enter="BuscarCuadreCaja"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="verifCuadreCaja.hasta"
        hide-details
        @blur="BlurHasta"
        v-on:blur="BuscarCuadreCaja"
         @keyup.enter="BuscarCuadreCaja"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
      
<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
  <!-- #Cierre,Caja/Usuario, Entregado,Ingreso, Diferencia   
   { text: '#Cierre', sortable: false,  value: 'nombre', width:'auto',sortable:false, class: ['no-gutters black--text'] },
  -->
 <!-- /*caja, String cajero,Double entregado,
            Double efectivo,Double cxc,Double tarjeta,
            Double cheque,Double transferencia,
            Double puntos,Double cupones, Double vales*/ -->
<v-data-table

      :headers="[
                 { text: 'Caja/Usuario', sortable: false, value: 'cajaUsuario', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: '$ Entregado', sortable: false, value: 'entregado', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: '$ Ingreso', sortable: false, value: 'ingreso', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: 'Diferencia', sortable: false, align:'center', value: 'diferencia', width:'auto', width:'auto', sortable:false, class: ['no-gutters black--text'] }
                 ]"
              dense
              :items="listCuadreCaja"
              :search="search"      
              :items-per-page="15"
              class="elevation-1 TbSize13"
            >

<template v-slot:item.cajaUsuario="props"> 
  <span >{{props.item.caja}} / {{props.item.cajero }} </span>
</template>

<template v-slot:item.entregado="props"> 
  <span style="display:none;">{{props.item.indx = listCuadreCaja.indexOf(props.item)}}</span>
  <span >{{currencyFormatter(props.item.entregado)}}</span>
</template>

<template v-slot:item.ingreso="props"> 
  <span >{{currencyFormatter((props.item.efectivo - props.item.vales) )    }}</span>
</template>

<template v-slot:item.diferencia="props"> 
 <span >{{currencyFormatter( (props.item.entregado)-(props.item.efectivo - props.item.vales))}}</span>
</template>

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.entregado)}} </strong></td>                                                                         
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.ingreso)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.diferencia)}} </strong></td>
  </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Entregado:{{currencyFormatter(totales.entregado)}}</span>
        <span>Total Ingreso:{{currencyFormatter(totales.ingreso)}}</span>
        <span>Total diferencia:{{currencyFormatter(totales.diferencia)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>

</v-data-table>

 </div>
<!-- <span>Ventas totales en este periodo:0.00</span> -->
</v-col>
<!-- EFECTIVO  -  CHEQUES  -  TARJETAS  -  TOTAL  -  DEPOSITO -->
<v-col cols="12" md="12" sm="12" >
  <div style="border: 1px solid #000000; background-color:#FFFFFF;" >
    <v-row>

      <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="2" sm="2">
                <h3 :class="`d-flex justify-center black--text`"><b>VALES</b></h3>
                <h3 :class="`d-flex justify-center black--text`"><b>{{currencyFormatter(totales.vales)}}</b></h3>
    </v-col>

      <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="2" sm="2">
                <h3 :class="`d-flex justify-center black--text`"><b>EFECTIVO</b></h3>
                <h3 :class="`d-flex justify-center black--text`"><b>{{currencyFormatter(totales.efectivo)}}</b></h3>
    </v-col>

    <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="2" sm="2">
                <h3 :class="`d-flex justify-center black--text`"><b>CHEQUES</b></h3>
                <h3 :class="`d-flex justify-center black--text`"><b>{{currencyFormatter(totales.cheques)}}</b></h3>
    </v-col>

    <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="2" sm="2">
                <h3 :class="`d-flex justify-center black--text`"><b>TARJETAS</b></h3>
                <h3 :class="`d-flex justify-center black--text`"><b>{{currencyFormatter(totales.tarjetas)}}</b></h3>
    </v-col>

    <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="2" sm="2">
                <h3 :class="`d-flex justify-center black--text`"><b>TOTAL</b></h3>
                <h3 :class="`d-flex justify-center black--text`"><b>{{currencyFormatter(totales.efectivo+totales.cheques+totales.tarjetas)}}</b></h3>
    </v-col>

    <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="2" sm="2">
                <h3 :class="`d-flex justify-center black--text`"><b>DEPOSITO</b></h3>
                <h3 :class="`d-flex justify-center black--text`"><b>{{currencyFormatter(totales.deposito)}}</b></h3>
    </v-col>


    </v-row>
  </div>
  </v-col>


              </v-row>

            </v-form>
       </div>

  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("verifCuadreCajaNew", this.new);
    // EventBus.$on("verifCuadreCajaSave", this.save);
    // EventBus.$on("verifCuadreCajaRemove", this.remove);
    // EventBus.$on("verifCuadreCajaAnular", this.remove);
    // EventBus.$on("verifCuadreCajaPrintList", this.imprimirList);
    EventBus.$on("emitVerifCuadreCaja", this.datostabla);
    EventBus.$on("onResize", this.onResize);

    this.BuscarCuadreCaja()
  },

  data:()=>  ({


      Rpropietario:[],
      RSucursal:[],
      RCaja:[],
      
      listCuadreCaja:[],
      
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      totalDeposito:0,
totales:{
vales:0,
efectivo:0,
cheques:0,
tarjetas:0,
deposito:0,
entregado:0,
ingreso:0,
diferencia:0
},

verifCuadreCaja: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    
    datostabla(e) {

      this.verifCuadreCaja = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() {},
    anular() { },
    imprimirList() {},
    newwnuevo() {
      (this.verifCuadreCaja = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },



calculartotal(){

this.totales={
vales:0,
efectivo:0,
cheques:0,
tarjetas:0,
deposito:0,
entregado:0,
ingreso:0,
diferencia:0
}

this.totales.deposito=JSON.parse(JSON.stringify(this.totalDeposito)) 
this.listCuadreCaja.forEach(ele => {
this.totales.vales+=ele.vales
this.totales.efectivo+=ele.efectivo
this.totales.cheques+=ele.cheque
this.totales.tarjetas+=ele.tarjeta
this.totales.entregado+=ele.entregado
this.totales.ingreso+=(ele.efectivo-ele.vales)
this.totales.diferencia+= (ele.entregado)-(ele.efectivo - ele.vales)


});

},


BuscarCuadreCaja(){

var WHERE=` cj.fecha >= '${this.verifCuadreCaja.desde}' and cj.fecha <= '${this.verifCuadreCaja.hasta}'`
//var CONDUCION=`Desde: ${this.FormatoFecha(this.verifCuadreCaja.desde)} \t Hasta: ${this.FormatoFecha(this.verifCuadreCaja.hasta)} \n`
var DATAOBJ=JSON.parse(JSON.stringify(this.verifCuadreCaja))
DATAOBJ.where=WHERE
var Link=`/ConsultaRapida/verificacionCuadreCaja`
this.$axios.post(this.$hostname+this.$hName+Link,DATAOBJ,
{headers:this.$store.getters.GetheadersAxios}
  ).then(res=>{
    this.listCuadreCaja=res.data.listaCuadre
    this.totalDeposito=res.data.totalDepositoBanco
   // setTimeout(this.calculartotal,200)
   var _this = this;
   setTimeout(function () { _this.calculartotal() }, 300)

  });

},



BlurDesde(){

if(this.verifCuadreCaja.desde.toString().length==0){
  this.verifCuadreCaja.desde=new Date().toISOString().substr(0,10)
}


 },


 BlurHasta(){

if(this.verifCuadreCaja.hasta.toString().length==0){
  this.verifCuadreCaja.hasta=new Date().toISOString().substr(0,10)
}

 },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { },


  },



computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
  }



};
</script>

<style></style>
