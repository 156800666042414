<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px">
<ContenidoBase 
  :titulo="'REGISTRO TÉCNICOS'"
  :descripcion="'Crear, Modificar y Desactivar Registro técnicos'"
   :EstadoBtn="EstadoBtn"
   @MethodNuevo="newwRegistroTecnicos"
   @MethodGuardar="saveRegistroTecnicos"
   @MethodEliminar="NotifMessage" 
   @MethodImprimir="imprimirList"  
/>
  </tr>
  
    <tr>
  <!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir"   >

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
      <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
  <!-- <v-btn-toggle  ></v-btn-toggle> -->
        <v-btn small width="95%" color="yellow accent-4"    @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;" class="fa fa-eye"> </i>  ver</v-btn>
       
  </center>

<!-- BarraDinamicaNormal -->
   
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-76"   >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'height':this.windowSize.height-76+'px' ,'max-height':this.windowSize.height-76+ 'px'  }">
 <div class="px-2">
 <center>

 <v-col cols="12" md="6" sm="6"> 
<v-card color="grey lighten-3" >
  <h3 v-if="this.RegistroTecnicos.id==0">NUEVO REGISTRO</h3>
  <h3 v-if="this.RegistroTecnicos.id>0">EDITANDO REGISTRO</h3>
<div class="px-2">

  <v-form ref="formTec" v-model="valid" lazy-validation>
    <v-row>
     
  <v-col cols="12" md="12" sm="12">
 <v-combobox
 ref="RefCombTec"
                            v-model="RegNombre"
                            label="Nombre:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RTecnico"
                            item-text="nombre"
                            @blur="SelctCbRegNombre"
                            @focus="cargarRegNombre"
                            @change="changeRegNombre"
                            @keyup="CbFilRegNom"
                        >

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-font"
                            ></i>
                          </template>

<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO:</b> {{item.item.id}}</p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE: </b>{{item.item.nombre}}</p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="px-2" style="border: 1px dashed  #616161;"><b>TEL.: </b> {{item.item.telefono}}</span>
  <span class="px-2" style="border: 1px dashed  #616161;"><b>ESPECIALIDAD:  </b> {{item.item.especialidad.descripcion}}</span>
</span>
<p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="item.item.activo==true"><b>ESTADO:</b> <span class="green--text">Activo</span></p>
<p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="item.item.activo==false"><b >ESTADO:</b><span class="red--text" >In-Activo</span></p>
  
</v-col>
 

 </template>

                        </v-combobox>

<v-textarea
                  autocomplete="off"
                    label="* Direccion:"
                    dense
                    outlined
                    rows="2"
                    v-model="RegistroTecnicos.direccion"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>


<!----------------------------------- Telefono  -->

<v-text-field
          
            label="Télefono :"
            dense
            outlined
            autocomplete="off"
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="RegistroTecnicos.telefono"
            :rules="[$rules.required,$rules.RulesTel]"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>
          </v-text-field>


<!-- -----------------------------  email -->
             <v-text-field label="E-mail"
                     dense outlined
                     v-model="RegistroTecnicos.email"
                     :rules="[$rules.email]"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>



 <v-combobox
                            v-model="RegistroTecnicos.especialidad"
                            label="Especialidad:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="REspecialidad"
                            item-text="descripcion"
                            @blur="SelctCbEspecialidad"
                            @focus="cargarEspecialidad"
                            @keyup="CbFilRegEspec"
                        >

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-star-o"></i>
                          </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="OpendialogEspecialidades" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Crear Especialidad</span>
        </v-tooltip> 
        
 </template> 

                        </v-combobox>


<v-switch
              class="mt-0 d-flex justify-center"
              color="teal" 
              label=""
              v-model="RegistroTecnicos.activo"
            >
          <template v-slot:label>
         <strong v-if="RegistroTecnicos.activo==true" style="color:#000000; font-size:13px;">Activar</strong>
         <strong v-if="RegistroTecnicos.activo==false" style="color:#000000; font-size:13px;">In-Activar</strong>
        </template>
         </v-switch>  


  </v-col>

</v-row>
  </v-form>
 
</div>
 </v-card>

</v-col>
 </center>
</div>  

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
         
        <!-- BarraBusqueda2 -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarRegistroTecnicos'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->


<!-- ------------------------------------------------------ Modal- Especialidades-->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogEspecialidades" persistent fullscreen hide-overlay>
 <v-card>
 <div>

<v-toolbar dark color="indigo darken-4" dense>

      <!-- <v-tooltip bottom >
<template v-slot:activator="{ on }">

  <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Recibos de Otros Ingresos</v-toolbar-title>

 </template  >
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span >  Recibos de Otros Ingresos: </span> Creación de Recibos de Otros Ingresos</i>

  </v-tooltip> -->
     
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogEspecialidades=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>

 </v-card-actions>

    </v-toolbar> 

<v-col cols="12" md="12" sm="12">
  <v-card flat >
<RegistroEspecialidades
  ref="RefComponEsp"
 />
</v-card > 
</v-col>

</div>
</v-card>
</v-dialog>



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>



</template>

<script>
import VueAutonumeric from "../../components/AutoNumericNuevo";

import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
import RegistroEspecialidades from "@/EventoMantenimiento/Mant/RegistroEspecialidades.vue";

export default {

   components: {
    VueAutonumeric,
    ContenidoBase,
    RegistroEspecialidades

  },

mounted() {


  },

   created(){
  },
  // destroyed(){
  //   location.reload()
  // },

  data:()=> ({

  windowSize:{width:"auto", height:"auto"},

// <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO: {{item.item.codigo}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DESCRIPCIÓN: {{item.item.descripcion}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>ESPECIALIDAD: {{item.item.especialidad.descripcion}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 red--text" v-if="item.item.activo==true"><b>Activo</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 green--text" v-if="item.item.activo==false"><b>In-Activo</b></p>

RTecnico:[],
REspecialidad:[],

valid:true,
dialogEspecialidades:false,


RegNombre:null,
RegistroTecnicos:{
id:0,
nombre:null,
direccion:null,
telefono:"",
email:"",
especialidad:null,
activo:true,
borrado:false
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },




  ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

       notif: {
        estado: false,
        nombre: "",
        Exec:""
      },



    BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false

}),

 methods: {
   currencyFormatter,FormatoFecha,HoraFormatter,

  
 

newwRegistroTecnicos() {

this.BarraTablaDinamicaAprir = false
this.RegNombre=null

this.RegistroTecnicos={
id:0,
nombre:null,
direccion:null,
telefono:"",
email:"",
especialidad:null,
activo:true,
borrado:false
},

this.ejecucion = true
EventBus.$emit("loading", false);

    },

saveRegistroTecnicos() {

 
  if(this.$refs.formTec.validate()) {


if(this.ejecucion==true){

  this.ejecucion=false
 

this.RegistroTecnicos.nombre= JSON.parse(JSON.stringify(this.$refs.RefCombTec.internalSearch)) 
var datos= JSON.parse(JSON.stringify(this.RegistroTecnicos))

this.$axios
            .post(this.$hostname + this.$hName + "/tecnicos/save",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bienLimpio")
            })
            .catch(error => { this.alerta("Dato no guardado ", "error") });

/*
 var obj={
   datos:this.RegistroTecnicos,
 }

// console.log(this.$refs.RefCombEsp.lazySearch)internalSearch
this.$axios
        .post(this.$hostname + this.$hName + "/services/Tecnico/save", JSON.parse(JSON.stringify(obj)) )
        .then(res =>{this.alerta("Dato guardado correctamente!", "bienLimpio")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));*/

}      
}else{

              this.alerta("los campos en rojo son requeridos", "error");

       }

},


NotifMessage() {

if(this.RegistroTecnicos.id!=0 && this.RegistroTecnicos.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarRegistroTecnicos"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un Registro Tecnicos para eliminar", "error")}


    },

 Exec_notif(e) {
//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

 
eliminar() {

if(this.$refs.formTec.validate()) {

  EventBus.$emit("loading", true);
  var datos= JSON.parse(JSON.stringify(this.RegistroTecnicos))
this.$axios
            .post(this.$hostname + this.$hName + "/tecnicos/borrado",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage,res.data.estado )})
            .catch(error => { this.alerta("Dato no guardado ", "error") });



 /*var obj={
   datos:this.RegistroTecnicos,
   usuario:this.$store.getters.GetdatosInfUsu.userid
 }
// console.log(this.$refs.RefCombEsp.lazySearch)internalSearch
this.$axios
        .post(this.$hostname + this.$hName + "/services/Tecnico/borrado", JSON.parse(JSON.stringify(obj)) )
        .then(res =>{this.alerta(res.data.mensage,res.data.estado )})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));*/
  }else{
              this.alerta("Seleccione un Registro Especialidades para eliminar", "error");
       }

},

anular(){
},

imprimirList() {
  EventBus.$emit("loading", true);
var obj=`/tecnicos/imprimir/`
this.$axios.get(this.$hostname + this.$hName +obj,{headers:this.$store.getters.GetheadersAxios}).then(res => {
  EventBus.$emit("loading", false);
/*let pdfWindow = window.open("Reporte de imprecion")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>") */
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'registro tecnicos');
         
      });
    },





CbFilRegNom(e){
  if (e.target.value.length >= 2) {    
var obj=`/tecnicos/findall/${this.$store.getters.GetdatosInfUsu.userid}/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj,{headers:this.$store.getters.GetheadersAxios})
            .then(res => (this.RTecnico = res.data));
        }

},



cargarRegNombre(){
var obj=`/tecnicos/findall/null`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res=>{
  this.RTecnico=res.data
  })

},
SelctCbRegNombre(){

  //  if (this.RegNombre == null) {

  //       this.RegNombre = ''
  //     }

  //     if (this.RegNombre.codigo == undefined) {

  //       this.alerta("Seleccione un registro actividades", "error");
  //       this.RegNombre = ''
  //       return

  //     }

},


cargarEspecialidad(){
  var obj=`/especialidades/findAllActivo/null`
this.$axios.get(this.$hostname+this.$hName+obj,{ headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.REspecialidad=res.data
  })
},

CbFilRegEspec(e) {
  if (e.target.value.length >= 2) {    
var obj=`/especialidades/findAllActivo/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj,{headers:this.$store.getters.GetheadersAxios})
            .then(res => (this.REspecialidad = res.data));
        }
},



SelctCbEspecialidad(){

   if (this.RegistroTecnicos.especialidad == null) {

        this.RegistroTecnicos.especialidad = ''
      }

      if (this.RegistroTecnicos.especialidad.id == undefined) {

        this.alerta("Seleccione una Especialidad", "error");
        this.RegistroTecnicos.especialidad = ''
        return

      }

},


OpendialogEspecialidades(){
  
 this.dialogEspecialidades=true
var _this=this;
setTimeout(function(){ 
if(_this.RegistroTecnicos.especialidad!=null){
  _this.$refs.RefComponEsp.SelectRefEspecial(_this.RegistroTecnicos.especialidad)
  }else{
_this.$refs.RefComponEsp.SelectRefEspecial(null)
  }
}, 500);

  //this.$refs.RegEsp.obtenerEspecial(this.RegistroActividades.especialidad)

 },
 changeRegNombre(e){

   if(e.hasOwnProperty('id')){

        this.RegistroTecnicos=JSON.parse(JSON.stringify(e))
   }else{

//this.RegistroTecnicos.codigo=0
     
   }



 },



alerta(e, l) {


 if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
      }
  
      if (l == "bienLimpio") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwRegistroTecnicos();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

  },


computed:{

    ActDescBtn(){

if(this.RegistroTecnicos.id>0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}


if(this.RegistroTecnicos.id<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


}
     

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>