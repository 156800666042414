<template>
  <div>{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
       <!-- ContenidoBase-Depositos Bancarios -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Depósitos Bancarios'" :descripcion="'Crear y Modificar Depósitos Bancarios'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" @MethodListaReciboIngreso="ListaReciboIngreso" />
      </tr>
       <!-- fn ContenidoBase-Depositos Bancarios -->

      <tr>
        <!-- BarraPrimaria-Depositos Bancarios --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>


                <BarraDinamicaLimitePlus :PropsNombre="'Depósitos Bancarios'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'depositosbancoPK.secuencia' },
                 /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{Text:'Secuencia',NomValue:'depositosbancoPK',Nv2:'secuencia', Ctsl:2, Type:0, State:true},
              {Text:'Cuenta',NomValue:'cuenta', Nv2:'cuenta', Ctsl:2, Type:0, State:true},
              {Text:'Banco',NomValue:'cuenta', Nv2:'banco',Nv3:'nombre', Ctsl:3, Type:0, State:true},
              {Text:'Documento',NomValue:'documentobancos', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
              {Text:'# Refernecia',NomValue:'referencia', Ctsl:1, Type:0, State:true},
              {Text:'concepto',NomValue:'concepto', Ctsl:1, Type:0, State:true},
              {Text:'Fecha',NomValue:'nuevaFecha', Ctsl:1, Type:1, State:true},
              {Text:'Valor', NomValue:'valor', Ctsl:1, Type:2, State:true},
              { Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.2, State: true }
              ]" />



                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBANDepositos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Depositos Bancarios ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
<!-- Formulario-Depositos Bancarios  -->

            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">


                  <v-form ref="formDeposito" v-model="valid" lazy-validation>
                    <v-row>



                      <!-- columna 1 ----------------------------------------------->
                      <v-col cols="12" md="4" sm="4">
                        <v-form ref="formDeposito2" v-model="valid" lazy-validation> 
                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="Deposito.documentobancos"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>
                      
                        <!-------------------------------------------------------- Referencia -->
                        <!--@blur="SelctCbOperacion" @focus="CargarROperacion"  -->

                        <v-combobox v-model="Deposito.cuenta" label="Cuenta Bancaria:" required outlined dense disabled
                          :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                          @blur="SelctCbCuentaBanco" @focus="cargarCuentaBanco" @change="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                          </template>
                        </v-combobox>


                        <!-------------------------------------------------- Valor-->
                        <VueAutonumeric label="Valor:" dense outlined autocomplete="off" v-model.number="Deposito.valor"
                          :rules="[$rules.required]" @blur="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>



                        <VueAutonumeric label="Prima:" dense outlined autocomplete="off" v-model.number="Deposito.prima">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-square"></i>
                          </template>
                        </VueAutonumeric>

                      </v-form>
                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!-- columna 2  --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!-------------------------------------------------------- Referencia -->

                        <v-text-field label="Referencia:" dense outlined v-model="Deposito.referencia"
                          autocomplete="off" @blur="convertirMayusculaReferencia" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  fecha  -->
                        <v-text-field dense outlined type="date" label="Fecha:" v-model="Deposito.fecha"
                          @blur="buscarPeriodo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <!-- -----------------------------------------Moneda-->
                        <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                          item-text="nombrecorto" :rules="[$rules.required]" v-model="Deposito.moneda"
                          @blur="SelctCbMoneda" @focus="cargarMoneda" @change="ChangeMonedaTasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn columna 2  ------------------------------------------>

                      <!-- columna 3 ----------------------------------------------->
                      <v-col cols="12" md="4" sm="4">



                        <!-- -----------------------------------------taza -->
                        <VueAutonumeric label="tasa:" dense outlined autocomplete="off" v-model.number="Deposito.tasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- comentario -->

                        <v-textarea autocomplete="off" label="comentario:" dense outlined v-model="Deposito.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>


                      </v-col>
                      <!-- fn columna 3  --------------------------------->

                    </v-row>
                  </v-form>

                </div>
              </v-card>

              <v-row>
                <v-col cols="12" md="12" sm="12">


                  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta"
                    :Total="TotalCuenta" />


                </v-col>
              </v-row>


            </div>
<!-- fn Formulario-Depositos Bancarios  -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->



    <!-------------------------------------------------------- tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpenImprimirList()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>
            <v-data-table class="TbSize13" :headers="[
                    { text: 'Secuencia', value: 'depositosbancoPK.secuencia',sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Cuenta', value: 'cuenta.cuenta', sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Banco', value: 'cuenta.banco.nombre', sortable:false, class: ['no-gutters black--text']  },
                    { text: 'Documento', value: 'documentobancos.descripcion', sortable:false, class: ['no-gutters black--text']  },
                    { text: '# Refernecia', value: 'referencia', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'moneda', value: 'moneda.descripcion', sortable:false, class: ['no-gutters black--text'] },
                     { text: 'Fecha', value: 'nuevaFecha', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'Valor', value: 'valor', sortable:false, class: ['no-gutters black--text'] },
                    { text: 'Concepto', value: 'concepto', sortable:false, class: ['no-gutters black--text'] },
            ] " dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">


              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec='anularDepositos'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-------------------------------------------------------- Modal- agregar cuenta-->
    <!-- <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
<CuentasContables
@CuentasContables="CCuentasContables"
:RLisCuenta="RLisCuenta"
:objeTem="objeTem"
:Total="TotalCuenta"
/>
</v-dialog> -->
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->




    <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico -->


    <!-- ------------------------------------------------------ Modal-Reportes-ModalReporteFiltros -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE DEPÓSITOS BANCARIOS </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>
        
        <v-container style="border: 1px solid #000000;">
          <v-form ref="formImpList" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento :rules="[$rules.required]"-->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion"  v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>

              </v-col>

              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>


              </v-col>
              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-center">
<v-radio-group  hide-details class="py-0" v-model="datosImprecionAll.opcion" row>     
                  <!-- <v-radio label="RUTINARIOS" color="green" :value="1"></v-radio>
                  <v-radio label="NO RUTINARIOS" color="green" :value="2"></v-radio>-->
                  <v-radio label="TODOS" color="green" :value="1"></v-radio>
                  <v-radio label="ACTIVO" color="green" :value="2"></v-radio>                                       
                  <v-radio label="ANULO" color="green" :value="3"></v-radio>  
                  <!-- <v-radio label="CANCELADO" color="green" :value="5"></v-radio> -->
                  
</v-radio-group>
</v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" ref="ModRepFilt" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reportes-ModalReporteFiltros -->


    <!-- ------------------------------------------------------ Modal-Filtro tb-datos  -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar dense flat color="indigo">
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <v-col cols="12" md="12">

              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">
              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>

              <v-text-field label="NO.Depósito :" dense outlined v-model.number="Modallfiltro.codigo" autocomplete="off"
                type="number" :rules="[$rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-slack"></i>
                </template>
              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle dense rounded class="">
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos  -->

<!-- ------------------------------------------------------ Modal- Add Recibo Ingreso-->
<v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogAgregarReciboIngreso" persistent
      max-width="90%" max-height="80%">

      <AgregarReciboIngreso ref="RefAgregarReciboIngreso" @EmitAgregarReciboIngreso="RecAgregarReciboIngreso"
         />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-  Add Recibo Ingreso-->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import AgregarReciboIngreso from "@/Bancos/UtilidadComponents/AgregarReciboIngreso";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteFiltros,
    BarraDinamicaLimitePlus,
    AgregarReciboIngreso

  },
  created() {
    var json = {
      titulo: "Depósitos Bancarios",
      descripcion: "Crear y Modificar Depósitos Bancarios",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: false,
      accion: [
        "BANDepositosNew",
        "BANDepositosSave",
        "BANDepositosRemove",
        "BANDepositosAnular",
        "BANDepositosPrintList",
        "BANDepositosVercuenta"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANDepositosNew", this.new);
    // EventBus.$on("BANDepositosSave", this.save);
    // EventBus.$on("BANDepositosRemove", this.remove);
    // EventBus.$on("BANDepositosAnular", this.remove);
    // EventBus.$on("BANDepositosPrintList", this.imprimirList);
    // 
    //  EventBus.$on("BANDepositosVercuenta", this.vercuenta);
    EventBus.$on("emitBANDepositos", this.datostabla);

    this.cargarTodo();
    this.cargarCuentaBanco();
    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },


      dialogAgregarReciboIngreso:false,
      RselectedItemReciboIngreso:[],
      RecIngGuardado:[],
      valorSelectRecibo:0,
      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'DEPÓSITOS BANCARIOS',
        UrlImprimir: '/depositios/ImprimirList',
        UrlExel: '/depositios/ExcelList',
        UrlCorreo: '/depositios/CorreoList',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documentos: null,
        opcion:1
      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'DEPÓSITOS BANCARIOS',
        UrlImprimir: '/depositios/Imprimir',
        UrlExel: '/depositios/Excel',
        UrlCorreo: '/depositios/Correo',
        codigo: 0,
        documento: 0
      },


      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null
      },


      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      objeTem: null,


      RDocumento: [],
      RCuentaBanco: [],
      Rmoneda: [],


      ListaTiposClientes: [],
      RLisCuenta: [],


      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      contabilidad: null,
      Deposito: {
        depositosbancoPK: { secuencia: 0, documento: 0 },
        activo: true,
        borrado: false,
        fecha: fechaNueva(),
        referencia: "",
        valor: 0.00,
        tasa: 1,
        concepto: "",
        periodocontable: null,
        marca: false,
        conciliacion: 0,
        banco: null,
        cuenta: null,
        documentobancos: null,
        moneda: null,
        contabilidad: null,
        prima:0
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false,
        ListaReciboIngreso:true

      },

      acti_Modal_cuenta: false,

      text: "",
      valid: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],


      list: [],
      search: "",
      acti_tb_data: false,


      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    onResize(e) {
      //this.windowSize=e
    },


    async cargarTodo() {
      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/depositios/findall/10/0",
      //     {headers:this.$store.getters.GetheadersAxios}
      //   )
      //   .then(res => (this.ListaTiposClientes = res.data))
      //   .catch(error => console.log(error));
    },
    // Establecer el valor de una variable en un componente secundario.
    async datostabla(e) {
      this.valorSelectRecibo=0
      var obj = JSON.parse(JSON.stringify(e))
      // obj.fecha = new Date(e.fecha).toISOString().substr(0, 10);
      obj.fecha =e.nuevaFecha
      this.Deposito = obj;
      this.acti_tb_data = false

      this.RLisCuenta=[]
      var _this = this;
      setTimeout(function () {
        _this.getCrear()
       }, 500);




  
       // this.dialogAgregarReciboIngreso=true


       if(e.recibosdeingresoList!=null&&e.recibosdeingresoList.length>0){
       
        var _this = this;
        setTimeout(function () { _this.EjecutarPermisoPv = false}, 500);
        var LIST_REC=JSON.parse(JSON.stringify(e.recibosdeingresoList))
      
         await Promise.all(LIST_REC.map(async (element) => { 
         this.valorSelectRecibo+=element.valor
          element.indx=`${element.recibosdeingresoPK.secuencia}-${element.recibosdeingresoPK.documento}`
         }));

         this.RselectedItemReciboIngreso=JSON.parse(JSON.stringify(LIST_REC))
         this.RecIngGuardado=JSON.parse(JSON.stringify(LIST_REC))

      }else{
       this.RselectedItemReciboIngreso=[]
       this.RecIngGuardado=[]
      }
    },
    buscarPeriodo(e) {
      if (this.Deposito.fecha != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + this.Deposito.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
      this.getCrear()
    },
   // Guardando los datos en la base de datos.
    save() {
      if(this.Deposito.conciliacion!==null && this.Deposito.conciliacion>0){
        this.alerta(
          "Este Deposito esta conciliado no puede ser modificado",
          "error"
        );
        return;
      }
      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.alerta("El Total de Débito y Crédito no Coincide en el Asiento Contable", "error");
        return
      }
      if (this.$refs.formDeposito.validate()) {

        EventBus.$emit("loading", true);
      if(this.RselectedItemReciboIngreso.length>0){
        this.Deposito.recibosdeingresoList=this.RselectedItemReciboIngreso
        if(this.valorSelectRecibo!= this.Deposito.valor){
          this.alerta(`El total de los recibos de ingreso seleccionado (${currencyFormatter(this.valorSelectRecibo)}) es diferente al valor del deposito (${currencyFormatter(this.Deposito.valor)}) `, "error")
          return
        }
        
      }
      
      if(this.RLisCuenta.length>0){
        this.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }
       this.Deposito.contabilidad = this.contabilidad;

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/depositios/salvar", this.Deposito,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>{ 
            EventBus.$emit("loading", false);
            this.alerta("Dato no guardado", "error")}
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },



   // Creando un nuevo objeto llamado notif y asignándolo a la variable notif.
    NotifMessage() {

      if (this.Deposito.depositosbancoPK.secuencia!= 0 && this.Deposito.depositosbancoPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "anularDepositos"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un deposito para anular", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {

    },


    verificarMovimientoDepositos(){
var bool=false;
//False: No tiene movimiento/True:contieneMovimiento
return  new Promise((resolve, reject) => {
var linkverificar=`/${this.Deposito.depositosbancoPK.secuencia}/${this.Deposito.depositosbancoPK.documento}`
      this.$axios.get(
        this.$hostname + this.$hName +"/depositios/VerificarMovimientoConciliacion"+linkverificar,
        { headers: this.$store.getters.GetheadersAxios }
      )
      .then((res) => {
        // if(res.data==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")}
       // else{this.VerificarMovimNotDebito(linkverificar)}
        resolve(res.data)
      })
      .catch((error) => {
        resolve(false)
        this.alerta(error, "error")}); 
});

// return bar.then(() => {return bool});

},

async anular() {

    EventBus.$emit("loading", true);
    const result =  await this.verificarMovimientoDepositos();
    console.log(result)
    if(result==true){this.alerta("Dato no anulado correctamente, deposito conciliado", "error")
      return ;
    }
      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/depositios/anular", this.Deposito,
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => this.alerta("Dato anulado correctamente!", "bien"))
        .catch(error =>
          this.alerta(
            "Dato no anulado",
            "error"
          )
        );

    },
    imprimirList() {

      this.dialogimprimirList = true
      this.datosImprecion.codigo = this.Deposito.depositosbancoPK.secuencia
      this.datosImprecion.documento = this.Deposito.depositosbancoPK.documento
    },
    DDataReporteBasico(e) { this.dialogimprimirList = e },
    // Crear un nuevo objeto y asignarlo a la propiedad de datos de la instancia de Vue.
    newwnuevo() {
      this.BarraTablaDinamicaAprir = false

      this.RLisCuenta = []

      this.TotalCuenta = {
        credito: 0,
        debito: 0
      }
      this.contabilidad = null;
      this.Deposito = {
        depositosbancoPK: { secuencia: 0, documento: 0 },
        activo: true,
        borrado: false,
        fecha: fechaNueva(),
        referencia: "",
        valor: 0.00,
        tasa: 1,
        concepto: "",
        periodocontable: null,
        marca: false,
        conciliacion: 0,
        banco: null,
        cuenta: null,
        documentobancos: null,
        moneda: null,
        contabilidad: null,
        prima:0
      }


      var refRe= this.$refs.RefAgregarReciboIngreso
      if(refRe!=undefined){
        this.$refs.RefAgregarReciboIngreso.RselectedItem=[]
      }

      this.RselectedItemReciboIngreso=[]
      this.RecIngGuardado=[]
      this.valorSelectRecibo=0

      EventBus.$emit("loading", false);
    },





   // Llamar a una API y obtener los datos de la API.
    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentobanco/documentodeposito',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },



   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.Deposito.documentobancos == null) {

        this.Deposito.documentobancos = ''
      }

      if (this.Deposito.documentobancos.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Deposito.documentobancos = ''
        return

      }
      this.buscarPeriodo();
      this.Deposito.cuenta = this.Deposito.documentobancos.cuenta
    },
    cargarCuentaBanco() {

      this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/findallactivo',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },



   // Comprobando si el valor de la selección es nulo o indefinido. Si es nulo o indefinido,
   // establecerá el valor de la selección en una cadena vacía.
    SelctCbCuentaBanco() {
      if (this.Deposito.cuenta == null) {

        this.Deposito.cuenta = ''
      }

      if (this.Deposito.cuenta.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Deposito.cuenta = ''
        return
      }
      this.Deposito.banco = this.Deposito.cuenta.banco;
    },
    // Llamando a un método llamado llenarcuenta()
    getCrear() {

      if (this.$refs.formDeposito2.validate()) {
        if (this.Deposito.depositosbancoPK.secuencia === 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-ban/' + this.Deposito.documentobancos.codigo + '/' + this.Deposito.valor + '/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }

        if (this.Deposito.depositosbancoPK.secuencia !== 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.Deposito.depositosbancoPK.documento + '/' + this.Deposito.depositosbancoPK.secuencia + '/' + this.Deposito.documentobancos.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              if (res.data.toString().length > 0) {
                this.llenarcuenta(res.data)
              }
            });
        }
      } else {

      }

    },
    // Tomando los datos del componente secundario y colocándolos en el componente principal.
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.Deposito.fecha;
        this.contabilidad.documento = this.Deposito.documentobancos.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
     // this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },
    calcularTotalcuenta(e) {

      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }

    },
    // Llamar a una API y obtener una lista de monedas.
    cargarMoneda() {
      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbMoneda() {
      if (this.Deposito.moneda == null) {
        this.Deposito.moneda = ''
      }
      if (this.Deposito.moneda.codigo == undefined) {
        this.alerta("Seleccione una moneda", "error");
        this.Deposito.moneda = ''
        return
      }

    },

   // Tomando el valor de la opción seleccionada y asignándolo a la variable tasa.
    ChangeMonedaTasa(e) {

      if (e != null) {
        this.Deposito.tasa = JSON.parse(JSON.stringify(e.tasa))
      }
    },

    vercuenta() {


      // if(!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
      //   this.alerta("Los campos en rojo son requeridos", "error");
      //   return
      // }

      // if(this.$refs.formDeposito.validate()) {

      // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));
      // this.TotalCuenta=JSON.parse(JSON.stringify(this.TotalCuenta))

      // this.acti_Modal_cuenta=!this.acti_Modal_cuenta

      // }else{

      //               this.alerta("Los campos en rojo son requeridos", "error");
      //        }




      // // this.acti_Modal_cuenta=!this.acti_Modal_cuenta
      // // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));


    },





    // Convertir el valor de la entrada a mayúsculas.
    convertirMayusculaReferencia(e) {
      if (e.target.value.length > 0) {
        this.Deposito.referencia = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))
        console.log(e.target.value.toString().toUpperCase())

      }

    },




   // Creando un nuevo objeto llamado RLisCuenta y asignándolo a la propiedad
   // contabilidad.contabilidadDetalleList.
    CCuentasContables(e, t) {

      //this.acti_Modal_cuenta=b

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.contabilidad.contabilidadDetalleList = this.RLisCuenta;
      //this.form.RLisCuenta= this.RLisCuenta

      this.TotalCuenta = JSON.parse(JSON.stringify(t))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbReportDocumento() {

      var _this = this;
      setTimeout(function () {
        if (_this.datosImprecionAll.documentos == null) {
          _this.datosImprecionAll.documentos = "";
        }

        if (!_this.datosImprecionAll.documentos.hasOwnProperty('codigo')) {
          _this.datosImprecionAll.documentos = "";
          _this.datosImprecionAll.documentos = null;
          return;
        }
      }, 300);

    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {
        this.Modallfiltro.documento = "";
      }

      if (this.Modallfiltro.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = "";
        return;
      }
    },





    // Al verificar si la fecha está vacía, si está vacía, está configurando la fecha a la fecha de
    // hoy.
    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },


    // Al verificar si la fecha está vacía, si lo está, está configurando la fecha a la fecha de hoy.
    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

   // muestra una tabla de datos
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

   // Un método que se llama cuando se hace clic en un botón
   // muestra un modal con una tabla de datos.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

   // Un método que se llama cuando el usuario hace clic en el botón 
   // restablece la paginacion de la tabla y los datos.
    Limpiarfiltro() {
      console.log(this.acti_tb_data)

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


   // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    // Una función que se llama cuando el usuario cambia el número de elementos por página.
    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

   // Llamar a una API y obtener los datos.
    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }


      var doc = null
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/depositios/findListDepositoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    ListaReciboIngreso(){

      this.dialogAgregarReciboIngreso = true
      var _this = this;
        setTimeout(function () {
     
             if(_this.Deposito.depositosbancoPK.secuencia>0 && _this.RecIngGuardado.length>0){
              _this.$refs.RefAgregarReciboIngreso.RecIngGuardado=JSON.parse(JSON.stringify(_this.RecIngGuardado)) 
             }

             _this.$refs.RefAgregarReciboIngreso.RselectedItem=JSON.parse(JSON.stringify(_this.RselectedItemReciboIngreso))
            _this.$refs.RefAgregarReciboIngreso.CargarLista();
           _this.$refs.RefAgregarReciboIngreso.calcularTotales();
        }, 500);

    },

    RecAgregarReciboIngreso(e){

      if (e.estado == 'cerrar') {
        this.dialogAgregarReciboIngreso = false
        this.RselectedItemReciboIngreso=e.RselectedItem
        this.valorSelectRecibo=e.valorSelec 
      }
      if (e.estado == 'datos') {
        this.RselectedItemReciboIngreso=e.datos
      }

    },

    OpenImprimirList(){
      this.dialogimprimirListFilt=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.ModRepFilt.CrearObjecto = _this.validarObjecto;
      }, 300);
    },


    validarObjecto() {

if (!this.$refs.formImpList.validate()) {
  this.$refs.ModRepFilt.detener = true;
  return;
} else {
  this.$refs.ModRepFilt.detener = false;
}

//d.secuencia > 0 and d.fecha >= $P{f1} and d.fecha <=  $P{f2} and case when $P{documento} = 0 then true else d.documento=$P{documento} end
var WHERE=` d.secuencia > 0 and d.fecha >= '${this.datosImprecionAll.desde}' and d.fecha <= '${this.datosImprecionAll.hasta}' `
var CONDUCION=`Desde: ${this.FormatoFecha(this.datosImprecionAll.desde)} \t Hasta: ${this.FormatoFecha(this.datosImprecionAll.hasta)} \n`

if(this.datosImprecionAll.documentos!=null){
  WHERE+=` and d.documento=${this.datosImprecionAll.documentos.codigo}`
  CONDUCION+=`Documento: ${this.datosImprecionAll.documentos.descripcion} \t`
}

if(this.datosImprecionAll.opcion==1){CONDUCION+=`Opcion: Todos ` }
if(this.datosImprecionAll.opcion==2){CONDUCION+=`Opcion: Activos`
WHERE+=` and d.activado=true`
}
if(this.datosImprecionAll.opcion==3){CONDUCION+=`Opcion: Anulados`
  WHERE+=` and d.activado=false`
 }

this.datosImprecionAll.where=WHERE
this.datosImprecionAll.condicion=CONDUCION

},
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/depositios/findall/10/0", {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },

  computed: {



    ActDescBtn() {
      if (this.Deposito.depositosbancoPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
          listaRecibo:true
        }

        if(this.Deposito.activado==false){
          this.EstadoBtn.anular=false
        }


      }


      if (this.Deposito.depositosbancoPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false,
          listaRecibo:true
        }
      }



      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }






};
</script>

<style>

</style>
