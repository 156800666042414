import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { mask } from "vue-the-mask";
import "font-awesome/css/font-awesome.min.css";
import VueTheMask from "vue-the-mask";

Vue.use(VueTheMask);
import axios from "axios";
import VueResource from 'vue-resource'

import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'

Vue.use(Chartkick.use(Chart))
Vue.use(VueResource)
//defined as global component
Vue.component(
    "VueFontawesome",
    require("vue-fontawesome-icon/src/components/VueFontawesome.vue").default
);
Vue.config.productionTip = false;
Vue.prototype.$bbtnNuevo;

Vue.prototype.$nombreEmpresa = "DecaSystems";
Vue.prototype.$imagen = "../src/assets/log.png";
Vue.prototype.$user = {
    userid: "bibbia", passwd: "", jdbcJDNI: "", sudo: true,
    dashboard: true, micromarket: false, transportnet: false, gubernamental: false, verVentasCaja: false
};

Vue.prototype.$ImgName = "./assets/log.png";
Vue.prototype.$IdUser = "1";
Vue.prototype.$UseName = "Admin";
Vue.prototype.$Version = "Decasystems 1.25.0";
Vue.prototype.$hostname = process.env.VUE_APP_API_URL;
Vue.prototype.$hName = process.env.VUE_APP_API_CONTEXT;
Vue.prototype.$axios = axios;
Vue.prototype.$Bearer = "Bearer ";

Vue.prototype.$User = "administrador";
Vue.prototype.$CodeUser = "bibbia"; 

Vue.prototype.$rules = {
    required: value => !!value || "Requerido.",
    edad: [
        val => val > 10 || `Edad debe ser mayor a 10`,
        val => val < 70 || `Edad debe ser menor a 120`
    ],
    counter: value => (value && value.length <= 50) || "Max 50 characters",
    RulesTel: value => (value.length == 0 || value.length == 13) || `telefono debe tener 10 digitos`,
    //RulesTel: value => (value && value.length == 13) || `telefono debe tener 10 digitos`,
    //RulesCel: value => (value && value.length  ==13) || `Cedula debe tener 11 digitos`,
    RulesCel: value => (value.length == 0 || value.length == 13) || `Cedula debe tener 11 digitos`,
    RulesRnc: value => (value && value.length == 11) || `RNC debe tener 9 digitos`,
    RulesCelRnc: value => (value && value.length == 9 || value.length == 11 || value.length == 13) || `Ingrese una Cedula o RNC válido`,
    RulesCelRncValidar: value => (value.length == 0 || value.length == 9 || value.length == 11 || value.length == 13) || `Ingrese una Cedula o RNC válido`,
    RTel: value => value != 10 || `telefono debe tener 10 digitos`,
    porciento: value => value <= 100 || `Porciento debe ser  menor o igual a 100`,
    porciento2: value => value <= 100 && value > 0 || `Porciento debe ser  menor o igual a 100`,
    Numericc: value => (value >= 0) || "Requerido",
    NumNocero: value => (value > 0) || "Requerido",
    email2: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
    },
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || value.length == 0 || "Requerido"
    },

    validando: value => {
        console.log('--validando--')
        console.log(value)
       return  value==true || "Campo invalido "
    }


}


//tipo de inventario producto
Vue.prototype.$RTipoInventario = [
    { codigo: 1, value: 'T', descripcion: 'Productos Terminados' },
    { codigo: 2, value: 'M', descripcion: 'Materia Prima' },
    { codigo: 3, value: 'G', descripcion: 'Material Gastable' },
],


    // Vue.directive('FormatoFecha', function (params0) {

    //   var params= new Date(params0).toISOString().substr(0,10);
    //   if (params != null) {
    //     if (params.length > 0) {
    //       return (
    //         params.substring(8, 10) +
    //         "-" +
    //         params.substring(5, 7) +
    //         "-" +
    //         params.substring(0, 4)
    //       );
    //     }
    //   }

    //   console.log(params0) // Object {color: 'white', text: 'hello!'}
    // })


    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount("#app");
