<template @click="buscando">
  <aside v-show="isVisible" class="barra-busqueda">
    <v-card>
      <v-card-title>
        <!-- :class="`d-flex justify-center mb-6`" -->
        <center >
    <v-card-text style="font-size: 25px;"  class="font-weight-bold" >
         {{ nombre }}
    </v-card-text>
        <!-- <h5>{{ nombre }}</h5> -->
        </center> <br>
        <v-spacer></v-spacer>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-text-field
            v-if="Campofecha==false"
              autocomplete="off"
              v-on="on"
              v-model="search"
              @keyup="filtro"
              :label="SeachNombre"
              single-line
              hide-details
            >
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>

            <v-text-field
v-if="Campofecha==true"
        dense
         rounded
        outlined
        type="date" 
        @input="filtro" 
        :label="SeachNombre"
        v-model="search"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

<template v-slot:append-outer>

<v-btn @click.prevent="FechNew" color="grey lighten-1" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-file-o"></i>
</v-btn>             
 </template>
</v-text-field>


          </template>
          <span>{{SeachNombre}}</span>
        </v-tooltip>
      </v-card-title>

      <v-data-table
        @click:row="obtenerDato"
        :items-per-page="paginator.itemsPerPage"
        @pagination="cargarPaginacion"
        :headers="headers"
        :items="list"
        :search="search"
        :server-items-length="limiteTotalRegistros"
        hide-default-header
         v-if="TbActi"
      >
      <template v-slot:item="props"> 
<!-- Notas:
  {Text: Se refiere al nombre descriptivo,
   NomValue: se refiere al primer nombre del objecto,
   Nv2: se refiere al seg nombre objecto, Nv3: al tercero, 
   Ctsl: cantidad de slot de objecto que pose,  
   Type: se refiere al tipo:(texto=0,fecha=1, numerico=2), 
   State: 
   }

   ContListSlot:[
{Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
{Text:'Banco',NomValue:'banco', Ctsl:1, Type:0, State:true},
{Text:'Beneficio',NomValue:'beneficio', Ctsl:1, Type:0, State:true},
{Text:'Fecha',NomValue:'fecha', Ctsl:1, Type:1, State:true},
{Text:'Valor',NomValue:'valor', Ctsl:1, Type:2, State:true},
{Text:'Sucursal',NomValue:'sucursal', Nv2:'nombre', Ctsl:2, Type:0, State:true},
{Text:'Documento',NomValue:'sucursal', Nv2:'documento', Nv3:'desc' ,Ctsl:3, Type:0, State:true}
],

   -->
        <tr>
          <td @click.prevent="obtenerDato(props.item)"  v-ripple="{ class: `indigo--text` }" >
  
      <v-list-item-content>
<span v-for="(sl, i) in ContListSlot" :key="i">
  
      <span v-if="props.item.hasOwnProperty(sl.NomValue) && props.item[sl.NomValue].toString().length > 0 ">

       <span v-if="sl.Ctsl==1">
        <span v-if="sl.Type==0"><b>{{sl.Text}}:</b>{{props.item[sl.NomValue]}}.</span>
        <span v-if="sl.Type==1"><b>{{sl.Text}}:</b>{{FormatoFecha(props.item[sl.NomValue])}}.</span>
        <span v-if="sl.Type==2"><b>{{sl.Text}}:</b>{{currencyFormatter(props.item[sl.NomValue])}}.</span>

         <span v-if="sl.Type==0.1 & props.item[sl.NomValue]==false" ><b>{{sl.Text}}:</b><span class="red--text">Inactivo.</span></span>
        <span v-if="sl.Type==0.1 & props.item[sl.NomValue]==true" ><b>{{sl.Text}}:</b><span class="green--text">Activo. </span></span>
        <span v-if="sl.Type==0.2 & props.item[sl.NomValue]==false" > <b>{{sl.Text}}:</b><span class="red--text" > Anulado.</span></span>
        <span v-if="sl.Type==3">
          
          <v-avatar  
                  size="50"
                  tile
                >
                  <v-img :src="props.item[sl.NomValue]"></v-img>
             </v-avatar> 
        </span>

    </span>

    <span v-if="sl.Ctsl==2 & props.item[sl.NomValue].hasOwnProperty(sl.Nv2)">
        <span v-if="sl.Type==0"><b>{{sl.Text}}: </b>{{props.item[sl.NomValue][sl.Nv2]}}.</span>
        <span v-if="sl.Type==1"><b>{{sl.Text}}: </b>{{FormatoFecha(props.item[sl.NomValue][sl.Nv2])}}.</span>
        <span v-if="sl.Type==2"><b>{{sl.Text}}: </b>{{currencyFormatter(props.item[sl.NomValue][sl.Nv2])}}.</span>

    <span v-if="sl.Type==0.3"> 
      <span v-if="props.item[sl.PrgValue]==sl.PrgState"><b>{{sl.Text}}: </b>{{props.item[sl.NomValue][sl.Nv2]}}.</span>
    </span>
    <span v-if="sl.Type==0.1 & props.item[sl.NomValue][sl.Nv2]==false" ><b>{{sl.Text}}:</b><span class="red--text">Inactivo.</span></span>
        <span v-if="sl.Type==0.1 & props.item[sl.NomValue][sl.Nv2]==true" ><b>{{sl.Text}}:</b><span class="green--text">Activo. </span></span>
        <span v-if="sl.Type==0.2 & props.item[sl.NomValue][sl.Nv2]==false" > <b>{{sl.Text}}:</b><span class="red--text" > Anulado.</span></span>
        
    <span v-if="sl.Type==3">
          
          <v-avatar  
                  size="50"
                  tile
                >
                  <v-img :src="props.item[sl.NomValue][sl.Nv2]"></v-img>
             </v-avatar> 
        </span>

    </span>

    <span v-if="sl.Ctsl==3 & props.item[sl.NomValue].hasOwnProperty(sl.Nv2)">
      <span v-if="props.item[sl.NomValue][sl.Nv2].hasOwnProperty(sl.Nv3)"><b>{{sl.Text}}: </b>
        <span v-if="sl.Type==0">{{props.item[sl.NomValue][sl.Nv2][sl.Nv3]}}.</span>
        <span v-if="sl.Type==1">{{FormatoFecha(props.item[sl.NomValue][sl.Nv2][sl.Nv3])}}.</span>
        <span v-if="sl.Type==2">{{currencyFormatter(props.item[sl.NomValue][sl.Nv2][sl.Nv3])}}.</span>
    </span>
    </span>


  </span> 
</span>  
      </v-list-item-content>
     
            </td>
        </tr>
        <hr>

<!-- <span>{{props.item}}
  </span> -->
 </template> 
        
      </v-data-table>
    </v-card>
  </aside>
</template>

<script>
// import axios from "axios";
import cuerpo from "../../src/components/cuerpo";
import { EventBus } from "@/event-bus.js";
export default {
  components: {
    cuerpo
  },
  data() {
    return {

      TbActi:true,
      ObjTemTabla:null,
      indexTabla:null,
      
      objFiltro:null,
      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" }
      ],
      limiteTotalRegistros: 0,
      json: {},
      paginator: {
        page:1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      isVisible: true,
      list: [],
      search: ""
    };
  },
  props: {
    headers: {
      required: true,
      type: Array
    },

    slotname: {
      required: false,
      type: String
    },

    url: {
      required: true,
      type: String
    },
    nombre: {
      required: true,
      type: String
    },

    SeachNombre: {
      required: true,
      type: String
    },

    slotlist: {
      required: false,
      type: Array
    },

    ContListSlot: {
      required: false,
      type: Array
    },

    emitnombre: {
      required: true,
      type: String
    },

    urlcount: {
      required: false,
      type: String
    },

    Campofecha: {
      required: false,
      type: Boolean
    },

  },

  created() {
 //   this.filtro()
  },
  mounted: function() {
   // EventBus.$on("actualizaBarraDinaicaLim", this.filtro);
    EventBus.$on("actualizaBarraBusqueda2", this.ver);
    EventBus.$on("BarraDinamLimitadaFiltro", this.Modalfiltro);
  },
  methods: {


cargarPaginacion(e){

if(e.itemsPerPage==-1){

e.itemsPerPage=15

}

this.paginator= JSON.parse(JSON.stringify(e))

this.filtro()

},


   cargarMasdatos(e) {

this.paginator=e

if(this.paginator.itemsPerPage==-1){

this.paginator.itemsPerPage=15

}


this.filtro()


      // this.json = {
      //   descripcion: this.search,
      //   ultimoRegistro: e.pageStop,
      //   paginacion: e.page,
      //   limite: this.paginator.itemsPerPage
      // };
      // this.paginator.pageStop = e.pageStop;
      // this.$axios.post(this.url, this.json).then(res => (this.list = res.data));
    },

FechNew(){

this.search=""


},


    ver(e) {
     // console.log(e);
    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },

    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

     FormatoFecha(params0) {
var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    TelFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            "(" +
            params.substring(0, 3) +
            ")" +
            params.substring(3, 6) +
            "-" +
            params.substring(6, 10)
          );
        }
        return params;
      }
    },

    cedFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(0, 3) +
            "-" +
            params.substring(3, 7) +
            "-" +
            params.substring(7, 11)
          );
        }
      }
    },

  async filtro() {


this.ObjTemTabla=null
this.indexTabla=null
this.list=[]

var obj={
        datos:{},
        descripcion: this.search,
          
          paginacion: this.paginator.page,
          limite: this.paginator.itemsPerPage,
          usuarios:this.$CodeUser,
          
          usuario:JSON.parse(JSON.stringify(this.$user)) 
      }


// console.log('EEeeeeoooo')
// console.log("ElsiZe==>"+ this.search.length==0 ? null : this.search.length)

var busc=null

if(this.search.length==0){ busc=null }else{ busc=JSON.parse(JSON.stringify(this.search))}
var obj1={
    BuscDesc: busc,
    limite: this.paginator.itemsPerPage,
    ultimo: this.paginator.pageStart,
    objFiltro:JSON.parse(JSON.stringify(this.objFiltro)),
}

      // await this.$axios.post(this.url, JSON.parse(JSON.stringify(obj)))
      // .then(res => {this.list = res.data;});


// var obj1="/0/0/"+this.paginator.itemsPerPage+"/"+this.paginator.pageStart
// console.log("ElLink==>"+this.url+obj1)

// var headers=this.$store.getters.GetheadersAxios
//  await this.$axios.get(this.url+obj1,{headers:headers}).then(res => {
// console.log('Ver Datos'), console.log(res.data)
//        // this.list =  JSON.parse(JSON.stringify(res.data)) ;
//       });


this.$axios
        .post(this.url,obj1,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.list=(res.dat===null?[]:res.data)})
        .catch(error => console.log("ErrorBarraLImite==>"+error)
        // {this.alerta("Dato no guardado correctamente", "error")}
        );

//this.limiteTotalRegistros = res.data.totalRegistros

    },

 
async Modalfiltro(e) {

  

  if(e.opcion==1){

 this.objFiltro=JSON.parse(JSON.stringify(e)) 
    var obj={
        datos:{},
          descripcion: '',
          ultimoRegistro: this.paginator.pageStart,
          paginacion: this.paginator.page,
          limite: this.paginator.itemsPerPage,
          usuarios:this.$CodeUser,
          objFiltro:JSON.parse(JSON.stringify(e)) ,

        usuario:JSON.parse(JSON.stringify(this.$user)) 
      }


 await this.$axios
        .post(e.url, JSON.parse(JSON.stringify(obj)),{headers:this.$store.getters.GetheadersAxios})
        .then(res => {
        console.log(res.data)
          this.list = (res.data===null?[]:res.data);
        });


  }else{

    

    
    this.objFiltro=null
this.search=''

this.list=[]

var pagi= {
itemsLength:0,
itemsPerPage:5,
page:1,
pageCount:0,
pageStart:0,
pageStop:0,




        // page:1,
        // itemsPerPage: 5,
        // pageStart: 1,
        // pageStop: 15,
        // pageCount: 10,
        // itemsLength: 5
      }


this.cargarMasdatos(pagi)


 var _this=this;

  // this.filtro() , cargar

  setTimeout(function(){ _this.cargarFiltro(e) }, 300);
  
   //setTimeout(this.cargarFiltro,200);
  
  
  }

    },


async cargarFiltro(e) {
      if (e.urlcount != null) {

var obj={
        datos:{},
        usuario:JSON.parse(JSON.stringify(this.$user)) 
      }
      
         await this.$axios
          .post(e.urlcount,obj ,
            {headers:this.$store.getters.GetheadersAxios}
           )
           .then(res => {
             console.log("cantidad record")
             console.log(res)
             this.limiteTotalRegistros = res.data
             this.filtro()} );
      }
},



    obtenerDato(e) {
      
      EventBus.$emit(this.emitnombre, e);

    //  this.ObtenerTemp(e)
      //this.Rdata=e
      //this.RdataDetalle=e[this.slotlist]
    },


ObtenerTemp(e){

// const index =  this.nuevoClientes.ListFact.indexOf(item)
// this.nuevoClientes.ListFact.splice(index, 1)
// this.nuevoClientes.ListFactDele.push(item);


if(this.ObjTemTabla!=null){

var pg=JSON.parse(JSON.stringify(this.paginator))

  
  //this.list[this.indexTabla]=JSON.parse(JSON.stringify(this.ObjTemTabla))
  console.log('Ver el index: '+this.indexTabla)
  console.log(this.list[this.indexTabla])

  this.list[this.indexTabla]=JSON.parse(JSON.stringify(this.ObjTemTabla))

this.TbActi=false



var _this=this;

setTimeout(function(){ _this.TbActi=true}, 5);
//setTimeout(function(){ _this.cargarMasdatos(pg) }, 8);


//setTimeout(this.TbActi=true,8000);

  // var jsn =JSON.parse(JSON.stringify(this.list))
  // this.list=[]
  // this.list=JSON.parse(JSON.stringify(jsn))

}
this.indexTabla =JSON.parse(JSON.stringify(this.list.indexOf(e)))
this.ObjTemTabla=JSON.parse(JSON.stringify(e))

    }









  }



};
</script>

<style></style>

