<template>
 <div >{{ActDescBtn}}
 <table border="1" style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Registro de Formulas'"
  :descripcion="'aqui se registran los diferentes formulas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                dark
                  small
                  width="95%"
                  color="deep-purple accent-2"
                  @click.prevent="imprimirListCompleto"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i> listado</v-btn
                > 
            </center>


<BarraDinamicaNormal
              :nombre="'Registro de formulas'"
              @EmitDAta="datostabla"
              @EmitBuscar="filtro"
              :SeachNombre="'Búsqueda general'"
              :list="list"

              :headers="[
        { text: 'Código', value: 'id' },
        { text: 'Descripcion', value: 'unidadesProductos.productos.descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'unidadesProductos',Nv2:'productos', Nv3:'descripcion', Ctsl:3, Type:0, State:true},
              {Text:'Fecha',NomValue:'fecha', Ctsl:1, Type:1, State:true},
              ]"
            />

            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app  id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <v-container>
   
 <v-card
 color="grey lighten-3"
  >
  
  <v-container>
    
  <v-form ref="formRegistroFormulas" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                   <!-------------------------------------------------- Producto -->
                   <v-combobox id="formdisabledInput" autocomplete="off" dense outlined label="Descripción:" :items="Rproducto" style="font-size: 13px;"
                          item-text="codProDesc" @keyup="CbFilProducto" v-model="RegistroFormulas.unidadesProductos" :disabled="RegistroFormulas.id!=0"
                          @blur="SelctCbProduc" @focus="CargarRproducto" :rules="[$rules.required]" no-filter  >
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected}">  
                         <span v-if="item.hasOwnProperty('productos') && item.hasOwnProperty('unidades')"  >
                          <span class="black--text" >{{item.productos.descripcion}} - <b> {{item.unidades.descripcion}}</b></span>
                        </span> 
                        </template>

                          <template slot="item" slot-scope="item">
                            <span >{{item.item.productos.descripcion}} - <b> {{item.item.unidades.descripcion}}</b></span>
                          </template>
                          
                        </v-combobox>
                </v-col>
              </v-row>
            </v-form>  
            
       <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-1">
        <v-form ref="formAddPro" @submit.prevent="" v-model="valid" lazy-validation>  
          <v-combobox autocomplete="off" dense outlined label="PRODUCTO:" :items="Rproducto" hide-details 
          style="font-size: 13px;" item-text="codProDesc" @keyup="CbFilProductoAgregar" v-model="AddProducto"
                          @blur="SelctCbProducSelect" @focus="CargarRproductoAgregar" :rules="[$rules.required]" no-filter>
                        
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                          <span v-if="item.hasOwnProperty('productos') && item.hasOwnProperty('unidades')"  >
                          <span >{{item.productos.descripcion}} - <b> {{item.unidades.descripcion}}</b></span>
                         </span>
                          </template>

                          <template slot="item" slot-scope="item">
                            <span >{{item.item.productos.descripcion}} - <b> {{item.item.unidades.descripcion}}</b></span>
                          </template>
                          <template v-slot:append-outer>

<v-tooltip top>
<template v-slot:activator="{ on }">  
 
<v-btn class="pa-0 ma-0" @click.prevent="AgregarProducto" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
</template>
        <span>Agrerar Producto</span>
      </v-tooltip> 
      
</template> 
                          
                        </v-combobox>
        </v-form>    
 <v-card>
<v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12">
  <span :class="`d-flex justify-star white--text px-1`"><b>FORMULACIÓN</b></span>
</v-col>
<v-data-table  id="TbBord"
      class="RowSelectColor TbSize13 elevation-1" 
      item-key="indx"
      :headers="[
                {text: 'CÓDIGO', sortable: false,width:130,  value: 'unidadesProductos.unidadesProductosPK', class: ['no-gutters black--text']},
                { text: 'NOMBRE', sortable: false, value: 'unidadesProductos.productos', class: ['no-gutters black--text'] },
                { text: 'UNIDAD', sortable: false, width:180, value: 'unidadesProductos.unidades', class: ['no-gutters black--text'] },
                { text: 'CANTIDAD', sortable: false,width:110, value: 'cantidad', class: ['no-gutters black--text'] },
                { text: 'ACCIÓN', sortable: false,width:50, value: 'action', class: ['no-gutters black--text'] },       
                ]"
              dense
              :items="ListProduct" 

              :items-per-page="15"
            >
  <template v-slot:item.action="{ item }"> 
    

<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<!-- icon -->
<v-btn fab @click.prevent="BtnAccionDelete(item)" x-small  color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>            
  </template>

            <template v-slot:item.cantidad="props">

              <!-- <span style="font-size:13px; display:none;">{{props.item.indx = ListProduct.indexOf(props.item)}}</span> -->
              <span v-if="props.item.cantidad==null" style="display:none;">{{props.item.cantidad =0 }}</span>
                <VueAutonumeric label="" dense outlined autocomplete="off"
                        v-model.number="props.item.cantidad" 
                        :rules="[$rules.Numericc]" style="font-size: 13px;"  hide-details
                        >
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>
             </template>
             <!-- //  unidadesProductosPK.producto,productos.descripcion,unidades.descripcion   
            unidadesProductosPK-->

             <template v-slot:item.unidadesProductos.unidadesProductosPK="props">
                    <v-text-field style="font-size: 13px;" disabled background-color="white" color="grey darken-4"
                     :value="props.item.unidadesProductos.unidadesProductosPK.producto"
                     id="formdisabledInput"
                    @focus="CargarRproducto" label=""  hide-details outlined dense>
                  </v-text-field> 
              </template>

              <template v-slot:item.unidadesProductos.productos="props">
                    <v-text-field disabled background-color="white" color="grey darken-4"
                     :value="props.item.unidadesProductos.productos.descripcion" style="font-size: 13px;"
                     id="formdisabledInput"
                    @focus="CargarRproducto" label=""  hide-details outlined dense>
                  </v-text-field> 
              </template>
              
              <template v-slot:item.unidadesProductos.unidades="props">
                    <v-text-field disabled background-color="white" color="grey darken-4"
                     :value="props.item.unidadesProductos.unidades.descripcion" style="font-size: 13px;"
                     id="formdisabledInput"
                    @focus="CargarRproducto" label=""  hide-details outlined dense>
                  </v-text-field> 
              </template>

</v-data-table>


 </v-card>
</v-col>    

  </v-container>
  </v-card>   
       </v-container>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarRegistro'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>
<script>
import { EventBus } from "@/event-bus";
import BarraBusqueda2 from "@/components/BarraBusquedaDinam";
import BarraDinamicaNormal from "@/components/BarraDinamicaNormalPlus.vue";
import ContenidoBase from "@/components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,RedondeoValor,RedondeoBasico,RedondeoValorCantRed} from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase,
    VueAutonumeric
  },
  created() {
    var json = {
      titulo: "Registro de Formulas",
      descripcion: "aqui se registran los diferentes formulas",
    };

    //EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
   // EventBus.$on("emitCXCTiposClientes", this.datostabla);
   // EventBus.$on("onResize", this.onResize);
  },

 data:()=> ({
  
      windowSize:{width:"auto", height:"auto"},
      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:true,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

        AddProducto:null,
        ListProduct: [],
        Rproducto:[],
        RegistroFormulas: {

          id:0,
          fecha: fechaNueva(),
          unidadesProductos:null,
          formulaDetalleList:[],
          borrado : false
      },

      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,



      
      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,RedondeoValor,RedondeoBasico,RedondeoValorCantRed,
onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if(this.BarraTablaDinamicaAprir==true){this.filtro()}

    },
    datostabla(e) {
      this.RegistroFormulas = JSON.parse(JSON.stringify(e));
      this.ListProduct=JSON.parse(JSON.stringify(e.formulaDetalleList))
      this.acti_tb_data=false
    },
    save() {
//this.ListProduct

if (this.$refs.formRegistroFormulas.validate()) {

  if(this.ListProduct.length==0){
    this.alerta("agregue almeno un producto a la lista de formulación", "error");
    return;
  }


  EventBus.$emit("loading", true);
  if(this.RegistroFormulas.id!=0){this.RegistroFormulas.fechaModificacion=this.fechaNueva()}
  var datos= JSON.parse(JSON.stringify(this.RegistroFormulas))
  datos.formulaDetalleList=JSON.parse(JSON.stringify(this.ListProduct))
  this.$axios
            .post(this.$hostname + this.$hName + "/formulas/save",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bien")
            })
            .catch(error => { this.alerta("Dato no guardado ", "error") });


  /*var obj={
   var datos: JSON.parse(JSON.stringify(this.RegistroFormulas))
   usuario:this.$store.getters.GetdatosInfUsu.userid,
   detalle:this.ListProduct
 }
 this.$axios
        .post(this.$hostname + this.$hName + "/services/formulas/save", JSON.parse(JSON.stringify(obj)))
        .then(res =>{this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>{this.alerta("Dato no guardado correctamente!", "error")})*/


}else{
this.alerta("Los campos en rojo son requeridos", "error");
}

    },


    NotifMessage() {

if(this.RegistroFormulas.id!=0 && this.RegistroFormulas.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarRegistro"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un formula para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

    },


  
    eliminar() {

      
if (this.RegistroFormulas.id!=0) {
  EventBus.$emit("loading", true); 

 /* var obj=`/services/formulas/remove/${this.RegistroFormulas.id}/${this.$store.getters.GetdatosInfUsu.userid}`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res =>{this.alerta("Dato eliminado correctamente!", "bien")})
.catch(error =>{
  this.alerta("Esta Formula Esta Siendo Usada", "error")
})*/
var obj=`/formulas/remove/${this.RegistroFormulas.id}/`
this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.alerta("Dato eliminado correctamente!", "bien") })
     .catch(error =>{
      let msg = "";
          if (error.response) {
            msg = error.response.data
          } else if (error.request) {
            msg = error.request.data
          } else {
            msg = error.message;
          }
          this.alerta(msg, "error")
    //  this.alerta("Esta Formula Esta Siendo Usada", "error")
      })
}


      

 /* var jsn={
   datos:JSON.parse(JSON.stringify(this.RegistroFormulas)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.RegistroFormulas.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }*/

    },
    anular() {
    },
    imprimirList() {

var titulo="Registro de Formulas"
var where=`f.id= ${this.RegistroFormulas.id} and f.borrado=false`
EventBus.$emit("loading", true);
var obj=`/formulas/imprimir/${where}/${titulo}`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res =>{
  EventBus.$emit("loading", false);
  //this.alerta("Dato eliminado correctamente!", "bien")
var _this = this;
setTimeout(function () {
  let pdfWindow = window.open("Reporte de imprecion")
  pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
      encodeURI(res.data) + "'></embed>")
}, 2000);


})
.catch(error =>{
  this.alerta("Reporte no Impreso!", "error")
  EventBus.$emit("loading", false);
})


    },


    imprimirListCompleto() {



      var titulo="Listado de Registro de Formulas"
      var where=`f.id> 0 and f.borrado=false`
EventBus.$emit("loading", true);
var obj=`/formulas/imprimir/${where}/${titulo}`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res =>{
  EventBus.$emit("loading", false);
  //this.alerta("Dato eliminado correctamente!", "bien")
var _this = this;
setTimeout(function () {
 /* let pdfWindow = window.open("Reporte de imprecion")
  pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
      encodeURI(res.data) + "'></embed>")*/
      var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'Registro formulario');

}, 1000);


})
.catch(error =>{
  this.alerta("Reporte no Impreso!", "error")
  EventBus.$emit("loading", false);
})

    },



    newnuevo() {

      this.AddProducto=null,
        this.ListProduct=[],
        this.Rproducto=[],
      this.RegistroFormulas = {
        id:0,
          fecha: fechaNueva(),
          unidadesProductos:null,
          formulaDetalleList:[],
          borrado : false

      }

       EventBus.$emit("loading", false);
       this.filtro()
      //  EventBus.$emit("actualizaBarraBusqueda2");
    },



    AgregarProducto(){

if (this.$refs.formAddPro.validate()) {

var obj={
  id: 0,
  cantidad: 1,
  unidadesProductos:this.AddProducto,
  borrado : false

}
  this.ListProduct.unshift(obj)
  this.AddProducto=null
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}
     
    },


    BtnAccionDelete(item) {
      const index = this.ListProduct.indexOf(item);
      confirm("¿ Desea eliminar este producto de la lista ?") &&
        this.ListProduct.splice(index, 1);
    },

    

    CargarRproductoAgregar() {
// /producto/{qrt}/{bandera}/buscar

var obj=`/unidades-productos/a/materiales`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });


/*var obj=`/services/producto/a/materiales`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
this.Rproducto=res.data
})*/

},




CbFilProductoAgregar(e) {
if (e.target.value.length >= 0) {
//var obj=`/services/unidades-productos/${e.target.value}/false/buscar/${this.$store.getters.GetdatosInfUsu.userid}/`

var obj=`/unidades-productos/${(e.target.value === "" ? "A" : e.target.value)}/materiales`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });

/*var obj=`/services/producto/${e.target.value}/materiales`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{this.Rproducto = res.data})*/



}

},






    CargarRproducto() {
    
      var obj=`/unidades-productos/a/false/buscar`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });

      // /producto/{qrt}/{bandera}/buscar
/*      var obj=`/services/producto/a/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
  this.Rproducto=res.data
  })*/
//${this.$store.getters.GetdatosInfUsu.userid}
/*ar obj=`/services/producto/a/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{this.Rproducto = res.data}) */

},




CbFilProducto(e) {
if (e.target.value.length >= 0) {

  var obj=`/unidades-productos/${(e.target.value === "" ? "A" : e.target.value)}/false/buscar`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });

  //var obj=`/services/unidades-productos/${e.target.value}/false/buscar/${this.$store.getters.GetdatosInfUsu.userid}/`
  
 /* var obj=`/services/producto/${e.target.value}/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{this.Rproducto = res.data})*/
}

},

SelctCbProduc() {

  
  var _this = this;
      setTimeout(function () {
        if (_this.RegistroFormulas.unidadesProductos == null) {
          _this.RegistroFormulas.unidadesProductos= "";
        }

        if (!_this.RegistroFormulas.unidadesProductos.hasOwnProperty('productos')) {
          _this.RegistroFormulas.unidadesProductos = "";
          _this.RegistroFormulas.unidadesProductos = null;
          return;
        }
      }, 300);
},


SelctCbProducSelect() {

  
var _this = this;
    setTimeout(function () {
      if (_this.AddProducto == null) {
        _this.AddProducto= "";
      }

      if (!_this.AddProducto.hasOwnProperty('productos')) {
        _this.AddProducto = "";
        _this.AddProducto = null;
        return;
      }
    }, 300);
},




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {


      this.$axios.get(this.$hostname + this.$hName + "/formulas/findall/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.list = JSON.parse(JSON.stringify(res.data));
      });

    // console.log(this.$store.getters.GetdatosInfUsu.userid) 

  /*    var obj=`/services/formulas/findall/${this.$store.getters.GetdatosInfUsu.userid}`
      this.$axios.get(this.$hostname+this.$hName+obj)
      .then(res=>{
        this.list = res.data
      })*/

    
     /* await this.$axios
        .post(this.$hostname + this.$hName + "/services/formulas/findall", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });*/
    }
  },


computed:{

    ActDescBtn(){


if(this.RegistroFormulas.id>0){
 this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}


if(this.RegistroFormulas.id<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}
     
this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }


};
</script>

<style></style>
