<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
  <v-app id="inspire" class="overflow-y-auto "v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
 hola mundo
  </v-app>
</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    objTemp:null,
    Retencionfactura: {
      ValorPorc: true,
      Retencion: 0,
    },

    dialog:false
    

  }),

  methods: { 

    openViewer(e) {
 let pdfWindow = window.open("")
// pdfWindow.document.write('<input type="button" id="bt" onclick="{console.log("carga el contenido nuevo");}" value="Print PDF" />');
// pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf' src='" + SRC + "' ></embed>")
//span
pdfWindow.document.write(`
<b style="font-size: 42px;"><center>
<pre id="preId">${e}
         </pre>
         </center><b/>`
         
         )
         pdfWindow.print()
      
        /* var _this=this;
         setTimeout(function(){ 
         var req = new XMLHttpRequest();
         var fileName = "prueba.pdf";
         var url = _this.$hostname + _this.$hName + '/configuraciones/imprimir/';
          req.open("GET", url, true);
          // req.setRequestHeader('AutUser', this.$store.getters.GetheadersAxios.AutUser);
          req.setRequestHeader('Authorization', _this.$store.getters.GetheadersAxios.Authorization);
          req.setRequestHeader('X-TENANT-ID', _this.$store.getters.GetheadersAxios["X-TENANT-ID"]);
          req.setRequestHeader('Content-Type', _this.$store.getters.GetheadersAxios["Content-Type"]);
          req.setRequestHeader('ruta', `\Users\DESARR~1\AppData\Local\Temp\temp2335827343501907798.pdf`);
          req.responseType = "blob";
          var blob = new Blob([req.response], { type: "application/pdf" });

          req.onload = function () {

            var url = URL || webkitURL;
                var link = url.createObjectURL(blob);
                let pdfWindow = window.open("d.pdf")
                pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf' src='" + link + "' ></embed>")
          };
          req.send();
        }, 300);*/
},


GetPrintFacturaVenta(e) {
  this.dialog=true
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/AnularFactura/FacturaText/" + e.facturasventaPK.secuencia + "/" + e.facturasventaPK.documento,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.openViewer(res.data)
        });
    },





    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>