var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(_vm.ActDescBtn)+" "),_c('table',{staticStyle:{"position":"absolute"},attrs:{"width":"100%","height":this.windowSize.height-8,"max-height":this.windowSize.height-8}},[_c('tr',{attrs:{"max-height":"48px","height":"48px"}},[_c('ContenidoBase',{attrs:{"titulo":'Utilitario Productos',"descripcion":'Utilitario Productos.',"EstadoBtn":_vm.EstadoBtn},on:{"abrirBarraTablaDinamica":_vm.abrirTabla,"MethodNuevo":_vm.newwnuevo,"MethodGuardar":_vm.save,"MethodEliminar":_vm.remove,"MethodAnular":_vm.anular,"MethodImprimir":_vm.imprimirList}})],1),_c('tr',[_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.BarraTablaDinamicaAprir),expression:"BarraTablaDinamicaAprir"}],attrs:{"width":"109","max-height":this.windowSize.height-96}},[_c('div',{staticClass:"overflow-y-auto ",style:({  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  })},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('center',[_c('v-btn-toggle',[_c('v-btn',{attrs:{"small":"","color":"yellow accent-4"},on:{"click":function($event){$event.preventDefault();return _vm.actualiza_tb_ver($event)}}},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"font-size":"25px"}}),_vm._v(" ver")]),_c('v-btn',{attrs:{"small":"","dark":"","color":"deep-purple accent-2"},on:{"click":function($event){$event.preventDefault();return _vm.notifi('Seguro que desea nuevo', 'n')}}},[_c('i',{staticClass:"fa fa-print",staticStyle:{"font-size":"25px"}}),_vm._v(" Listado")])],1)],1)]}}])})],1)]),_c('td',{attrs:{"max-height":this.windowSize.height-96}},[_c('v-app',{staticClass:"overflow-y-auto ",style:({  'max-height':this.windowSize.height-96+ 'px' }),attrs:{"id":"inspire"}},[_c('div',{staticClass:"px-2"},[_c('v-card',{attrs:{"color":"grey lighten-3"}},[_c('div',{staticClass:"px-2",staticStyle:{"border":"1px solid #000000"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-col',{staticClass:"pa-0 ma-0 py-0 my-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',{staticClass:"pa-0 ma-0 py-0 my-0"},[_c('v-col',{staticClass:"pa-0 ma-0 px-2 pb-2 ",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('h4')]),_c('v-col',{staticClass:"pa-0 ma-0 px-2 pb-2 ",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-combobox',_vm._g({attrs:{"label":"Almacén","required":"","outlined":"","dense":"","autocomplete":"off","items":_vm.Ralmacen,"item-text":"descripcion","hide-details":"","disabled":_vm.Reparaloading},on:{"blur":_vm.SelctCbAlmacen,"focus":_vm.CargarRalmacen,"change":[_vm.resertPaginacion,_vm.getProducto]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-img',{attrs:{"height":"20px","width":"20px","src":require("../assets/warehouse.png")}})]},proxy:true}],null,true),model:{value:(_vm.UtilitarioProductos.almacen),callback:function ($$v) {_vm.$set(_vm.UtilitarioProductos, "almacen", $$v)},expression:"UtilitarioProductos.almacen"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Se registra el almacén que deseamos filtrar en la búsqueda.")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Letra Incial:","dense":"","maxlength":"1","autocomplete":"off","outlined":"","rules":[_vm.$rules.required],"disabled":_vm.Reparaloading},on:{"input":[_vm.getProducto,_vm.resertPaginacion]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-sort-alpha-desc",staticStyle:{"font-size":"20px"}})]},proxy:true}],null,true),model:{value:(_vm.UtilitarioProductos.desdeletra),callback:function ($$v) {_vm.$set(_vm.UtilitarioProductos, "desdeletra", $$v)},expression:"UtilitarioProductos.desdeletra"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Se registra la letra incial que deseamos filtrar en la búsqueda.")])])])],1),_c('v-col',{staticClass:"pa-0 ma-0 px-2 pb-2",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-combobox',_vm._g({attrs:{"autocomplete":"off","dense":"","outlined":"","label":"Productos:","items":_vm.Rproducto,"item-text":"codProDesc","hide-details":"","disabled":_vm.Reparaloading,"no-filter":""},on:{"keyup":_vm.CbFilProducto,"blur":_vm.SelctCbProduc,"focus":_vm.CargarRproducto,"change":[_vm.getProducto,_vm.resertPaginacion]},scopedSlots:_vm._u([{key:"item",fn:function(item){return [_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(item.item.codProDesc = (item.item.productos.codigo) + " - " + (item.item.productos.descripcion.trim()) + " " + (item.item.unidades.descripcion.trim()) + " "))]),_c('span',{staticClass:"fontSize13"},[_c('b',[_vm._v(_vm._s(item.item.productos.codigo))]),_vm._v(" - "+_vm._s(item.item.productos.descripcion.trim())+" "),_c('b',[_vm._v(_vm._s(item.item.unidades.descripcion.trim()))])])]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item.hasOwnProperty('productos'))?_c('span',{staticClass:"fontSize13 colorNombre"},[_c('b',[_vm._v(_vm._s(item.productos.codigo))]),_vm._v(" - "+_vm._s(item.productos.descripcion)+" "+_vm._s(item.unidades.descripcion)+" ")]):_vm._e()]}},{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-shopping-cart",staticStyle:{"font-size":"20px"},on:{"click":_vm.productoClick}})]},proxy:true}],null,true),model:{value:(_vm.UtilitarioProductos.producto),callback:function ($$v) {_vm.$set(_vm.UtilitarioProductos, "producto", $$v)},expression:"UtilitarioProductos.producto"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Se registra el producto que deseamos filtrar en la búsqueda.")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Letra Final:","maxlength":"1","dense":"","autocomplete":"off","outlined":"","rules":[_vm.$rules.required],"disabled":_vm.Reparaloading},on:{"input":[_vm.getProducto,_vm.resertPaginacion]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-sort-alpha-asc",staticStyle:{"font-size":"20px"}})]},proxy:true}],null,true),model:{value:(_vm.UtilitarioProductos.hastaletra),callback:function ($$v) {_vm.$set(_vm.UtilitarioProductos, "hastaletra", $$v)},expression:"UtilitarioProductos.hastaletra"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Se registra la letra final que deseamos filtrar en la búsqueda.")])])])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0 ma-0 py-0 my-0 pt-2",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('div',{staticClass:"px-2"},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"indigo","dense":""}},[_c('v-card-actions',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","disabled":_vm.Reparaloading,"rounded":"","color":"blue"},on:{"click":function($event){$event.preventDefault();return _vm.recalcularBalance($event)}}},on),[_c('i',{staticClass:"fa fa-retweet",staticStyle:{"font-size":"20px"}}),_vm._v(" recalcular")])]}}])},[_c('span',[_vm._v("Recalcular Balance")])]),(false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","disabled":_vm.Reparaloading,"rounded":"","color":"blue lighten-3"},on:{"click":function($event){$event.preventDefault();return _vm.repararproducto($event)}}},on),[_c('i',{staticClass:"fa fa-wrench",staticStyle:{"font-size":"20px"}}),_vm._v(" Restaurar")])]}}],null,false,2464556840)},[_c('span',[_vm._v("Restaurar Producto")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","disabled":_vm.Reparaloading,"rounded":"","color":"cyan lighten-3"},on:{"click":function($event){$event.preventDefault();return _vm.apostrofeproducto($event)}}},on),[_c('i',{staticClass:"fa fa-opencart",staticStyle:{"font-size":"20px"}}),_vm._v(" Apóstrofe")])]}}])},[_c('span',[_vm._v("Quita los Apóstrofes del producto")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","disabled":_vm.Reparaloading,"rounded":"","color":"teal lighten-4"},on:{"click":function($event){$event.preventDefault();return _vm.mayusculaproducto($event)}}},on),[_c('v-avatar',{attrs:{"color":"grey darken-4","size":"20"}},[_c('span',{staticClass:"white--text text-h5",staticStyle:{"font-size":"15px"}},[_vm._v("M")])]),_vm._v(" mayúscula")],1)]}}])},[_c('span',[_vm._v("coloca mayúscula a la descripción del producto")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","disabled":_vm.Reparaloading,"rounded":"","color":"cyan lighten-4"},on:{"click":function($event){$event.preventDefault();return _vm.minusculaproducto($event)}}},on),[_c('v-avatar',{attrs:{"color":"grey darken-4","size":"20"}},[_c('i',{staticClass:"white--text text-h5 fa fa-maxcdn",staticStyle:{"font-size":"15px"}})]),_vm._v(" Minúscula")],1)]}}])},[_c('span',[_vm._v("coloca minúscula a la descripción del producto")])])],1),_c('v-spacer')],1),_c('div',{staticStyle:{"border":"1px solid #000000"}},[(_vm.resertPagi==true)?_c('v-data-table',{staticClass:"elevation-1 RowSelectColor TbSize13",attrs:{"single-select":true,"item-key":"indx","show-select":true,"headers":[{ text: 'Código', sortable: true,  value: 'codigo', width:90, class: ['no-gutters black--text'] },
                  { text: 'Producto', sortable: true, value: 'producto', width:350, class: ['no-gutters black--text']},
                  { text: 'Unidad', sortable: false, value: 'unidad', width:100, class: ['no-gutters black--text'] },
                  { text: 'Almacen', sortable: false, value: 'almacen', width:230, class: ['no-gutters black--text'] },
                  { text: 'Ubicación', sortable: true, value: 'ubicacion', width:150, class: ['no-gutters black--text'] },
                  { text: 'Exit', sortable: false, value: 'existencia', width:110, class: ['no-gutters black--text'] },
                  { text: 'Exit.Historico', sortable: false, value: 'existenciaHistorico', width:110, class: ['no-gutters black--text'] },
                  { text: 'Precio', sortable: false, value: 'precio', width:129, class: ['no-gutters black--text'] },
                  { text: 'Costo', sortable: false, value: 'costo', width:129, class: ['no-gutters black--text'] },
                  { text: 'Factor', sortable: false, value: 'factor', width:110, class: ['no-gutters black--text'] },
                  { text: 'beneficio', sortable: false, value: 'beneficio', width:110, class: ['no-gutters black--text'] } ],"dense":"","items":_vm.listRproduct,"items-per-page":_vm.paginator.itemsPerPage,"server-items-length":_vm.TotalRegistros},on:{"click:row":_vm.datosRowSelect,"item-selected":_vm.obtenerDatoDetFact,"pagination":_vm.cargarPaginacion},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","x-small":"","color":"yellow accent-4"},on:{"click":function($event){$event.preventDefault();return _vm.VerReporte(item)}}},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"font-size":"20px"}})])]}},{key:"item.producto",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px","display":"none"}},[_vm._v(_vm._s(props.item.indx = _vm.listRproduct.indexOf(props.item)))]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(props.item.producto))])]}},{key:"item.almacen",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(props.item.almacen))])]}},{key:"item.ubicacion",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(props.item.ubicacion))])]}},{key:"item.precio",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.currencyFormatter(props.item.precio)))])]}},{key:"item.costo",fn:function(props){return [_c('span',[_vm._v(_vm._s(_vm.currencyFormatter(props.item.costo)))])]}}],null,false,46963854),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1)],1)],1)])],1)],1)],1)])],1)])],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.BarraSecundariaAprir),expression:"BarraSecundariaAprir"}],attrs:{"width":"109","max-height":this.windowSize.height-96}},[_c('div',{staticClass:"overflow-y-auto ",style:({  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' })})])])]),_c('v-dialog',{attrs:{"label":"myAlert","persistent":"","max-width":"350","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return undefined}}]),model:{value:(_vm.Elim_notif.estado),callback:function ($$v) {_vm.$set(_vm.Elim_notif, "estado", $$v)},expression:"Elim_notif.estado"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_c('i',{staticClass:"fa fa-warning",staticStyle:{"font-size":"28px"}}),_vm._v(" CONFIRMACION "),_c('i',{staticClass:"fa fa-warning",staticStyle:{"font-size":"28px"}})])]),_c('hr'),_c('v-card-text',[_c('h5',[_vm._v(_vm._s(_vm.Elim_notif.nombre))])]),_c('hr'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();_vm.eliminar(), (_vm.Elim_notif.estado = !_vm.Elim_notif.estado)}}},[_c('i',{staticClass:"fa fa-check",staticStyle:{"font-size":"20px"}}),_vm._v(" Si ")]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){$event.preventDefault();_vm.Elim_notif.estado = !_vm.Elim_notif.estado}}},[_c('i',{staticClass:"fa fa-ban",staticStyle:{"font-size":"20px"}}),_vm._v(" No ")])],1)],1)],1),_c('v-snackbar',{staticStyle:{"margin-top":"20%"},attrs:{"color":_vm.aalert.color,"vertical":true,"timeout":120000,"top":"top"},model:{value:(_vm.aalert.estado),callback:function ($$v) {_vm.$set(_vm.aalert, "estado", $$v)},expression:"aalert.estado"}},[_vm._v(" "+_vm._s(_vm.aalert.nombre)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.aalert.estado = false}}},[_vm._v("Close")])],1),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.Reparaloading),callback:function ($$v) {_vm.Reparaloading=$$v},expression:"Reparaloading"}},[_c('v-card',{attrs:{"color":"grey darken-2","dark":""}},[_c('v-card-text',[_vm._v(" Espere... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }