<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Proveedores'" :descripcion="'aqui se registran los clientes de la empresa'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList"
          @MethodImportarDato="importarDato" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"></i> ver
                  </v-btn>
                  <!-- <v-btn 
                  color="teal darken-2"
                  dark
                  text 
                  small
                  @click.prevent="dialogFiltros = !dialogFiltros, CerrarFiltro(2)"
                  >
                  <i style="font-size: 25px;" class="fa fa-search"> </i>
                Filtrar
      </v-btn> -->

                </center>
                <BarraDinamicaLimitePlus :PropsNombre="'Proveedores'" PropsSearch :PropsSeachNombre="'Búsqueda nombre'"
                  :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem" :PropsList="ListItems"
                  @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'codigo' }
                    /*{ text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
                  { Text: 'Nombre', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
                  { Text: 'Balance $', NomValue: 'balanceproveedor', Nv2: 'balance', Ctsl: 2, Type: 2, State: true },
                  { Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.1, State: true },
                  ]" />

                <!-- <v-navigation-drawer >  -->

                <!-- 
            <BarraBusqueda2
              :url="$hostname + $hName +'/services/proveedor/findAllProveedorFiltro'"
              :nombre="'Proveedores'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitCXPProveedores'"
              :SeachNombre="'Búsqueda por el nombre del Proveedor'"
              :slotlist="slotlist"
              :urlcount="'/services/proveedor/totalProveedor'"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">

              <v-form v-model="valid" ref="form" lazy-validation>

                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <!-- -----------------------------  col 1 -->
                      <v-col cols="12" md="6" sm="6">

                        <v-col class="px-2  pa-0 ma-0 py-0 my-0" cols="12" lg="12" md="12" sm="12"> 
                            <v-row >
                            <v-col class=" pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                            <!-- -----------------------------  Codigo -->
                        <v-text-field label="Codigo:" id="formdisabledInput" dense outlined v-model="form.provedores.codigo" :disabled="true"
                          style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>
                           </v-col>
                      <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                        <!-- -----------------------------   referencia -->
                        <v-text-field label="Codigo Anterior:" id="formdisabledInput" dense outlined v-model="form.provedores.referencia"
                          style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      </v-row>
                          </v-col>

                        <!-- ----------------------------- nombre -->

                        <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="form.provedores.nombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>



                        <!--  @blur="MakaraCel"  @focus="MakarNo"   ----------------------------- cedula/rnc -->

                        <v-text-field autocomplete="off" v-mask="MakCelRnc" label="RNC o Cédula:" dense outlined
                          maxlength="13" :rules="[$rules.RulesCelRnc]" v-model="form.provedores.rnc" @blur="MakaraCelPro"
                          @focus="MakarNo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- Tipo de Proveedor -->
                        <v-combobox autocomplete="off" dense outlined label="* Tipo de Proveedor:" :items="RTipoProv"
                          item-text="descripcion" :rules="[$rules.required]" v-model="form.provedores.tipo"
                          @blur="SelctCbTipo" @focus="cargarTipo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                          </template>
                        </v-combobox>


                        <!-- ----------------------------- pais -->
                        <v-combobox autocomplete="off" dense outlined label="*País:" :items="Rpais" item-text="nombre"
                          :rules="[$rules.required]" v-model="form.provedores.pais" @blur="SelctCbPais"
                          @focus="cargarPais">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-globe"></i>
                          </template>
                        </v-combobox>


                        <!-- ----------------------------- Ciuddad -->

                        <v-text-field label="*Ciudad:" dense outlined autocomplete="off" v-model="form.provedores.ciudad"
                          :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map"></i>
                          </template>
                        </v-text-field>



                        <!-- ----------------------------- direccion -->
                        <v-textarea autocomplete="off" label="* Dirección:" dense outlined
                          v-model="form.provedores.direccion" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>

                        <!-- ----------------------------- tipo gasto -->

                        <v-combobox v-model="form.provedores.gasto" label="Tipo de Gasto:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                          @focus="CargarRTipopago" @blur="SelctCbTipoGasto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>




                        <!-- ----------------------------- Add telefono -->
                        <!-- -----------------------------  tb telefonos -->
                        <v-text-field label="Teléfono:" dense outlined autocomplete="off" maxlength="13"
                          v-mask="'(###)###-####'" v-model="telefonos">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn @click.prevent="addTel" v-on="on" color="green" fab x-small dark>
                                  <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                </v-btn>

                              </template>
                              <span>Agregar Teléfono</span>
                            </v-tooltip>


                          </template>

                        </v-text-field>
                        <v-data-table
                          :headers="[{ text: 'teléfonos', value: 'proveedoresTelefonosPK.telefono' }, { text: 'actions', value: 'action' }]"
                          :items="form.ListTel" :items-per-page="5" class="elevation-1">
                          <template v-slot:item.action="{ item }">

                            <v-btn fab @click.prevent="editTel(item)" x-small color="grey "><i style="font-size: 20px;"
                                class="fa fa-pencil"></i></v-btn>
                            <v-btn fab @click.prevent="deleTel(item)" x-small color="red darken-3"><i
                                style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>


                          </template>

                        </v-data-table>

                        <br>



                      </v-col>
                      <!-- ----------------------------- fn col 1 -->


                      <!-- -----------------------------  col 2 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  email -->
                        <v-text-field label="E-mail:" dense outlined v-model="form.provedores.email">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>



                        <!-- ----------------------------- COntacro -->


                        <v-text-field label="Contacto:" autocomplete="off" dense outlined
                          v-model="form.provedores.contacto">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-location-arrow"></i>
                          </template>
                        </v-text-field>

                        <!----------------------------------- Telefono contocto -->

                        <v-text-field label="Teléfono del Contacto:" dense outlined autocomplete="off" maxlength="13"
                          v-mask="'(###)###-####'" v-model="form.provedores.telefonocontacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  Email Contacto -->
                        <v-text-field label="Email Contacto:" dense outlined v-model="form.provedores.emailcontacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>


                        <!-- ----------------------------- Dia de credito -->
                        <v-text-field label="Días de Crédito:" dense outlined v-model="form.provedores.diaCredito"
                          @keyup="KeyUpEntero">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>


                        <!-- ----------------------------- limite -->
                        <VueAutonumeric label="Límite de Crédito:" dense outlined autocomplete="off"
                          v-model.number="form.provedores.limiteCredito">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- Pociento -->
                        <VueAutonumeric autocomplete="off" label="Porcentaje de Retención ITBIS:" dense outlined
                          :rules="[$rules.porciento]" v-model="form.provedores.retencionItbis">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </VueAutonumeric>


                        <!-- ----------------------------- Pociento -->
                        <VueAutonumeric autocomplete="off" label="Porcentaje de Retención ISR:" dense outlined
                          :rules="[$rules.porciento]" v-model="form.provedores.isr">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </VueAutonumeric>

                        <!-- ----------------------------- descuento -->
                        <VueAutonumeric label="Descuento Pronto Pago:" required outlined dense
                          v-model="form.provedores.descuentoPp">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-percent"></i>
                          </template>
                        </VueAutonumeric>


                        <!-- ----------------------------- Prefijo -->


                        <v-text-field label="* Prefijo del comprobante:" autocomplete="off" dense outlined
                          v-model="form.provedores.codigoFijo">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>

                        <!------------------------------------------------ Activado -->
                        <v-switch v-model="form.provedores.activo" label="Activo:" color="success" hide-detail>
                        </v-switch>

                        <!------------------------------------------------ Activado -->
                        <v-switch v-model="form.provedores.pagoSuspendido" label="Pagos Suspendidos:" color="success"
                          hide-detail></v-switch>



                      </v-col>
                    </v-row>
                  </div>
                </v-card>

                <hr>

                <v-card color="grey lighten-3">
                  <div class="px-2">


                    <v-col cols="12" md="12" sm="12">

                      <!--------------------------------------------------- tb PASAPORTE -->
                      <v-card>

                        <v-toolbar flat dense color="#BDBDBD">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn v-on="on" @click.prevent="acti_Nueva_politica = !acti_Nueva_politica" color="green"
                                fab x-small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>


                            </template>
                            <span>Agregar Politica de descuento</span>
                          </v-tooltip>


                          <v-toolbar-title class="font-weight-light">
                            <v-card-title>Politica de descuento</v-card-title>
                          </v-toolbar-title>


                        </v-toolbar>
                        <v-data-table
                          :headers="[{ text: 'Días', value: 'hasta', class: ['no-gutters black--text'] }, { text: 'Porciento de Descuento', value: 'porcentaje', width: 200, class: ['no-gutters black--text'] }, { text: 'actions', value: 'action', class: ['no-gutters black--text'] }]"
                          :items="form.ListPol">

                          <template v-slot:item.action="{ item }">

                            <v-btn fab @click.prevent="editPol(item)" x-small color="grey "><i style="font-size: 20px;"
                                class="fa fa-pencil"></i></v-btn>
                            <v-btn fab @click.prevent="delePol(item)" x-small color="red darken-3"><i
                                style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                          </template>


                          <template v-slot:item.porcentaje="props">
                            <span>{{ currencyFormatter(props.item.porcentaje) }}%</span>
                            <!-- // new Date(e.tipo.fechaCreacion).toISOString().substr(0,10)   -->

                            <span style="display:none;"
                              v-if="props.item.proveedorVencimientoPK.hasOwnProperty('proveedor')">
                              {{ delete props.item['proveedores'] }}
                            </span>

                          </template>

                          <template v-slot:item.hasta="props">
                            <span> Hasta {{ props.item.proveedorVencimientoPK.hasta }} Días </span>
                          </template>


                        </v-data-table>
                      </v-card>



                      <!--------------------------------------------------- tb Factura inicial -->
                     <!--13/08-24:MIC-2411: https://decasystem.atlassian.net/browse/MIC-2411 -->
                      <v-card v-if="false">

                        <v-toolbar flat dense color="#BDBDBD">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn v-on="on"
                                @click.prevent="acti_Nueva_facturaInicial = !acti_Nueva_facturaInicial, disableDocu = false"
                                color="green" fab x-small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>


                            </template>
                            <span>Agregar Facturas Iniciales</span>
                          </v-tooltip>


                          <v-toolbar-title class="font-weight-light">
                            <v-card-title>FACTURAS INICIALES</v-card-title>
                          </v-toolbar-title>

                          <!-- addFact -->

                        </v-toolbar>
                        <v-data-table
                          :headers="[{ text: 'DOCUMENTO', value: 'documentoproveedor.descripcion', width: 200, sortable: false, class: ['no-gutters black--text'] }, { text: 'REFERENCIA*', value: 'referencia', sortable: false, class: ['no-gutters black--text'] }, { text: 'NCF', value: 'ncf', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'FECHA', value: 'fecha', width: 150, sortable: false, class: ['no-gutters black--text'] }, { text: 'DÍAS DE CRÉDITO', value: 'diascredito', sortable: false, class: ['no-gutters black--text'] }, { text: 'ITBIS', value: 'totalimpuesto', sortable: false, class: ['no-gutters black--text'] },
                          { text: 'VALOR', value: 'total', sortable: false, class: ['no-gutters black--text'] }, { text: 'actions', value: 'action', width: 120, sortable: false, class: ['no-gutters black--text'] }]"
                          :items="form.ListFact">

                          <template v-slot:item.action="{ item }">

                            <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;"
                                class="fa fa-pencil"></i></v-btn>
                            <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i
                                style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                          </template>

                          <template v-slot:item.total="props">
                            <span> <span v-if="props.item.moneda!=null">{{ props.item.moneda.nombrecorto }}</span> {{ currencyFormatter(props.item.total) }}</span>
                          </template>


                          <template v-slot:item.totalimpuesto="props">
                            <span><span v-if="props.item.moneda!=null">{{ props.item.moneda.nombrecorto }}</span>{{ currencyFormatter(props.item.totalimpuesto)
                            }}</span>
                          </template>

                          <template v-slot:item.diascredito="props">
                            <span>{{ currencyFormatter(props.item.diascredito) }}</span>
                          </template>



                        </v-data-table>
                      </v-card>
                      <!--------------------------------------------------- fn tb Factura inicial -->
                    </v-col>

                  </div>
                </v-card>



              </v-form>
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->


    <!-- ------------------------------------------------------ Modal- nueva politica-->
    <v-dialog transition="fab-transition" label="Modal agregar politica" v-model="acti_Nueva_politica" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar dense flat color="indigo">

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> Crear politica Nueva </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_Nueva_politica = !acti_Nueva_politica, cerrarModalPol()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row>
              <!-- pasaporte.numeropasaporte


por aqui
 pasaporte.numeropasaporte
dia,porciento
politica -->
              <!--------------------------------------------------------- Politica col1 -->
              <v-col cols="12" md="6" sm="6">
                <v-text-field label="* Días:" dense outlined v-model="politica.proveedorVencimientoPK.hasta"
                  @keyup="DiaKeyup" autocomplete="off" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                  </template>
                </v-text-field>
              </v-col>


              <!--------------------------------------------------- politica con2 -->

              <v-col cols="12" md="6" sm="6">

                <VueAutonumeric autocomplete="off" label="* Porciento de descuento:" dense outlined
                  :rules="[$rules.required, $rules.porciento]" v-model="politica.porcentaje">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                  </template>
                </VueAutonumeric>




              </v-col>




            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <!--   -->
        <v-btn-toggle rounded class="d-flex justify-end ">

          <v-btn dark color="green darken-1" @click.prevent="addPol">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- nueva politica-->


    <!-- ------------------------------------------------------ Modal- nueva Factura-->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="acti_Nueva_facturaInicial" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo">

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> Crear Factura inicial </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="acti_Nueva_facturaInicial = !acti_Nueva_facturaInicial, cerrarModalfac()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>
          <v-form ref="form3" v-model="valid" lazy-validation>
            <v-row>

              <!------------------------------------------ Documentos -->
              <v-col cols="12" md="6" sm="6">
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" :disabled="disableDocu"
                  v-model="facturaInicial.documentoproveedor" @blur="SelctCbDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 30px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>

              <!------------------------------------------------------ NCF -->
              <v-col cols="12" md="6" sm="6">

                <v-text-field label="NCF" autocomplete="off" dense outlined v-model="facturaInicial.ncf">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-barcode"></i>
                  </template>
                </v-text-field>

              </v-col>

              <!-------------------------------------------------------- Referencia -->
              <v-col cols="12" md="12" sm="12">

                <v-text-field label="* Referencia:" dense outlined v-model="facturaInicial.referencia"
                  :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                  </template>
                </v-text-field>
              </v-col>

              <!--------------------------------------------------------- fecha -->
              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="* Fecha:" v-model="facturaInicial.fecha"
                  :rules="[$rules.required]">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>
              </v-col>


              <!--------------------------------------------------- Dia de credito -->

              <v-col cols="12" md="6" sm="6">

                <v-text-field label="* Días de Crédito:" dense outlined v-model="facturaInicial.diascredito"
                  @keyup="DiaCreFact">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                  </template>
                </v-text-field>


              </v-col>

              <!----------------------------------------------------- ITBIS -->
              <v-col cols="12" md="6" sm="6">

                <VueAutonumeric label="ITBIS:" dense outlined autocomplete="off"
                  v-model.number="facturaInicial.totalimpuesto">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>

              </v-col>

              <!-- -----------------------------------------lugar de nacimiento -->
              <v-col cols="12" md="6" sm="6">
                <VueAutonumeric label="* Valor:" dense outlined autocomplete="off" :rules="[$rules.required]"
                  v-model.number="facturaInicial.total">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>
              </v-col>
              <!-- -----------------------------------------Moneda-->
              <v-col cols="12" md="6" sm="6">
                <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda" item-text="nombrecorto"
                  :rules="[$rules.required]" v-model="facturaInicial.moneda" @blur="SelctCbMoneda" @focus="cargarMoneda"
                  @change="ChangeMonedaTasa">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-combobox>
              </v-col>


              <!-- -----------------------------------------taza -->
              <v-col cols="12" md="6" sm="6">
                <VueAutonumeric label="* tasa:" dense outlined autocomplete="off" v-model.number="facturaInicial.tasa">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </VueAutonumeric>
              </v-col>


            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <!--    -->
        <v-btn-toggle rounded class="d-flex justify-end">

          <v-btn dark color="green darken-1" @click.prevent="addFact">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>



    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup.enter="CargarListTb" label="Search" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[{ text: 'Código', value: 'codigo', width: 150, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Nombre', value: 'nombre', width: 250, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Rnc', value: 'rnc', width: 150, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Email', value: 'email', width: 180, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Ciudad', value: 'ciudad', width: 220, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Dirección', value: 'direccion', width: 250, sortable: false, class: ['no-gutters black--text'] },
            { text: 'país', value: 'pais.nombre', width: 200, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Referencia', value: 'referencia', width: 250, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Balance $', value: 'balanceproveedor.balance', width: 150, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Día de Crédito', value: 'diaCredito', width: 120, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Límite de Crédito', value: 'limiteCredito', width: 120, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Tipo de Proveedor', value: 'tipo.descripcion', width: 200, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Retención Itbis', value: 'retencionItbis', width: 120, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Retención Isr', value: 'isr', width: 120, sortable: false, class: ['no-gutters black--text'] },
            { text: 'Tipo de Gasto', value: 'gasto.descripcion', width: 230, sortable: false, class: ['no-gutters black--text'] },
            { text: 'activo', value: 'activo', width: 120, sortable: false, class: ['no-gutters black--text'] }
            ]" @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
              :pageCount="TbPaginator.pageCount" :items="ListItems" :search="search" @click:row="datostabla"
              :server-items-length="TbTotalItem">
              <template v-slot:item.balanceproveedor.balance="props">
                <span>{{ currencyFormatter(props.item.balanceproveedor.balance) }}</span>
              </template>

              <template v-slot:item.retencionItbis="props">
                <span>{{ currencyFormatter(props.item.retencionItbis) }}</span>
              </template>

              <template v-slot:item.isr="props">
                <span>{{ currencyFormatter(props.item.isr) }}</span>
              </template>


              <template v-slot:item.activo="props">
                <span v-if="props.item.activo == true">Activo</span>
                <span v-if="props.item.activo == false">In-Activo</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog transition="fab-transition" label="myAlert" v-model="Elim_notif.estado" persistent max-width="350">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->



    <!-- ------------------------------------------------------ Modal- Importar datos-->
    <v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Importar Facturas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Importar Facturas: </span> Importar Facturas</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

                 

                </template>
                <span>CANCELAR: Retornar y no importar Datos</span>
              </v-tooltip>

            

          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Facturas</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                 <v-form  ref="formInputFilePro" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoProveedores" id="excelProveedores" dense
                  outlined show-size counter multiple label="Archivo de Proveedores"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                  
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoProve" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>
                  
              <v-form  ref="formInputFileFactIncPro" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoFactIncPro" id="excelFactIncPro" dense
                  outlined show-size counter multiple label="Archivo de Facturas Iniciales Proveedores"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">           
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoFactInc" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>

                  
                  <v-form  ref="formInputFileFactIncProSelec" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoFactIncProSelec" id="excelFactIncProSele" dense
                  outlined show-size counter multiple label="Archivo de Facturas Iniciales Proveedor Seleccionado"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                  
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoFactIncProveSelec" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>
              
            


                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Facturas -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Importar Facturas-->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico2 @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>


</template>
<script>
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteBasico2 from "../../components/ModalReporteBasico2";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteBasico2
  },

  directives: {
    mask,
  },

  mounted() {

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitCXPProveedores", this.datostabla);
    // EventBus.$on("CXPProveedoresNew", this.new);
    // EventBus.$on("CXPProveedoresSave", this.save);
    // EventBus.$on("CXPProveedoresRemove", this.remove);
    // EventBus.$on("CXPProveedoresAnular", this.anular);
    // EventBus.$on("CXPProveedoresPrintList", this.imprimirList);
    // EventBus.$on("CXPProveedoresImportarDato", this.importarDato);
    //EventBus.$on("onResize", this.onResize);

    // this.$refs.ModRepBasic.CrearObjecto=this.validarObjecto;

  },
  created() {
    var json = {
      titulo: "Proveedores",
      descripcion: "aqui se registran los clientes de la empresa",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      importarDato: true,
      accion: [
        "CXPProveedoresNew",
        "CXPProveedoresSave",
        "CXPProveedoresRemove",
        "CXPProveedoresAnular",
        "CXPProveedoresPrintList",
        "CXPProveedoresVercuenta",
        "CXPProveedoresSaavetem",
        "CXPProveedoresRestaurartem",
        "CXPProveedoresImportarDato",
        "CXPProveedoresCargarProducto",
        "CXPProveedoresProductoInv"


      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },


      dialogImportarDatos: false,



      dialogFiltros: false,

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'PROVEEDORES',
        UrlImprimir: '/proveedores/imprimir',
        UrlExel: '/proveedores/excel',
        UrlCorreo: '/proveedores/correo',
      },



      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE PROVEEDORES',
        UrlImprimir: '/proveedores/imprimirListado',
        UrlExel: '/proveedores/excelListado',
        UrlCorreo: '/proveedores/correoListado'
      },





      RCliente: [],
      RTipoProv: [],

      MakCelRnc: '#############',

      RtipoCliente: [],
      RVendedores: [],
      RTipoGasto: [],
      RStatus: [
        { codigo: 1, descripcion: "Activo" },
        { codigo: 2, descripcion: "Suspendido" },
        { codigo: 3, descripcion: "Cancelado" },
        { codigo: 4, descripcion: "Incobrable" },
        { codigo: 5, descripcion: "Mano de Abogado" },
      ],

      RTipo: [
        { codigo: 1, descripcion: "DISTRIBUIDOR" },
        { codigo: 2, descripcion: "MAYORISTA" },
        { codigo: 3, descripcion: "DETALLISTA" }
      ],

      Rpais: [],
      RtipoComprobante: [],
      RDocumento: [],
      Rmoneda: [],

      telefonos: "",
      Colss: {
        p1: {
          cols: 12,
          lg: 12,
          sm: 12
        }
      },

      ObjTemp: null,

      politica: {
        proveedorVencimientoPK: { hasta: 0, proveedor: 0 },
        codigo: 0,
        porcentaje: 0,
        borrado: false
      },



      disableDocu: false,

      Archivo: null,
      ArchivoProveedores:null,
      ArchivoFactIncPro:null,
      ArchivoFactIncProSelec:null,
      formData:null,

      facturaInicial: {
        facturasProveedoresPK: { codigo: 0, documento: 0 },
        documentoproveedor: null,
        ncf: "",
        referencia: null,
        fecha: null,
        diascredito: 0,
        totalimpuesto: 0,
        moneda: null,
        tasa: 0,
        pendiente: 0,
        total: 0

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: true,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      form: {

        ListFactDele: [],
        ListFact: [],
        ListPol: [],
        ListTel: [],



        provedores: {

          codigo: 0,
          nombre: '',
          rnc: '',
          tipo: null,
          pais: null,
          ciudad: "",
          direccion: "",
          gasto: null,
          email: "",
          contacto: '',
          telefonocontacto: '',
          emailcontacto: "",
          diaCredito: 0,
          limiteCredito: 0,
          retencionItbis: 0,
          isr: 0,
          descuentoPp: 0,
          codigoFijo: "",
          activo: true,
          borrado: false,
          pagoSuspendido: false,



          rncOriginal: '',

          cedrnc: "",
          comentario: '',
          empresa: 1,
          //fechaCreacion: new Date().toISOString().substr(0,10),
          //fechaModificacion: new Date().toISOString().substr(0,10),

          informales: false,
          porcientoRetencion: null,
          referencia: null,

          usuarioCreacion: this.$UseName,
          usuarioModificacion: ''

        },


      },



      valid: true,

      headers: [{ text: "Nombre", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        {
          dt0: "balanceproveedor",
          dt1: "balance",
          dt2: null,
          dt3: 5,
          dt4: "Balance:$"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: null,
      acti_Nueva_politica: false,
      acti_Nueva_facturaInicial: false,
      acti_tb_data: false,

      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 5,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {

      // console.log("imprii ")
      // console.log(e)
      // this.windowSize=e


    },



    cargarClienteLimt() {

      this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/findAllClientesCombo', { descripcion: "", limite: 25 })
        .then(res => (this.RCliente = res.data))

    },

    CargarRTipopago() {
      this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findallactivo', { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.RTipoGasto = res.data;
        });
    },


    SelctCbTipoGasto() {


      if (this.form.provedores.gasto == null) {
        this.form.form.provedores.gasto = "";
      }

      if (this.form.provedores.gasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.form.form.provedores.gasto = "";
        return;
      }

    },



    CbFilcliente(e) {

      if (e.target.value.length >= 0) {

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/clienteservices/findAllClientesCombo",
            { descripcion: e.target.value, limite: 25 }
          )
          .then(res => (this.RCliente = res.data));
      }

    },

    SelctCbCliente() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = ''


        //qlq2

        return

      }

    },

    SelctCbMoneda() {

      if (this.facturaInicial.moneda == null) {

        this.facturaInicial.moneda = ''
      }

      if (this.facturaInicial.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.facturaInicial.moneda = ''
        return

      }

    },

    ChangeMonedaTasa(e) {
      this.facturaInicial.tasa = JSON.parse(JSON.stringify(e.tasa))

    },




    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))

    },


    MakarNo() {

      this.MakCelRnc = '#############'

    },

    MakaraCel() {

      if (this.form.politica.cedrnc.toString().trim().length == 9) {

        this.MakCelRnc = '###-#####-#'
      }


      if (this.form.politica.cedrnc.toString().trim().length >= 11) {

        this.MakCelRnc = '###-#######-#'
      }

      // RulesCelRnc
      // this.MakCelRnc='###-#####-#',
      // MakCelRnc:'###-#######-#',
      //console.log(this.form.politica.cedrnc.toString().length)

    },

    MakaraCelPro() {

if (this.form.provedores.rnc.toString().trim().length == 9) {

  this.MakCelRnc = '###-#####-#'
}


if (this.form.provedores.rnc.toString().trim().length >= 11) {

  this.MakCelRnc = '###-#######-#'
}

// RulesCelRnc
// this.MakCelRnc='###-#####-#',
// MakCelRnc:'###-#######-#',
//console.log(this.form.politica.cedrnc.toString().length)

},


    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentoscxp/findAllDocumentosFacturasIniciales', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    SelctCbDocumento() {
      if (this.facturaInicial.documentoproveedor == null) {

        this.facturaInicial.documentoproveedor = ''
      }

      if (this.facturaInicial.documentoproveedor.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.facturaInicial.documentoproveedor = ''
        return

      }

    },





    cargarPais() {

      this.$axios.get(this.$hostname + this.$hName + '/paises/findall', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rpais = res.data))

    },

    SelctCbPais() {

      if (this.form.provedores.pais == null) {

        this.form.provedores.pais = ''
      }

      if (this.form.provedores.pais.id == undefined) {

        this.alerta("Seleccione un pais", "error");
        this.form.provedores.pais = ''
        return

      }



    },

    cargarTipo() {



      this.$axios.get(this.$hostname + this.$hName + '/tipoproveedor/findallactivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RTipoProv = res.data))

    },

    SelctCbTipo() {

      if (this.form.provedores.tipo == null) {

        this.form.provedores.tipo = ''
      }

      if (this.form.provedores.tipo.codigo == undefined) {

        this.alerta("Seleccione un tipo", "error");
        this.form.provedores.tipo = ''
        return

      }

    },







    cargarStatus() {

      // this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{})
      // .then(res=>( this.RStatus=res.data))

    },

    SelctCbStatus() {

      if (this.form.politica.statuscliente == null) {

        this.form.politica.statuscliente = ''
      }

      if (this.form.politica.statuscliente.codigo == undefined) {

        this.alerta("Seleccione un Estatus", "error");
        this.form.politica.statuscliente = ''
        return

      }

    },


    cargarVendedores() {

      this.$axios.post(this.$hostname + this.$hName + '/services/vendedores/findAllVendedores', { "usuario": this.$CodeUser })
        .then(res => (this.RVendedores = res.data))

    },

    SelctCbVendedores() {

      if (this.form.politica.vendedores == null) {

        this.form.politica.vendedores = ''
      }

      if (this.form.politica.vendedores.codigo == undefined) {

        this.alerta("Seleccione un vendedor", "error");
        this.form.politica.vendedores = ''
        return

      }

    },



    cargarCliente() {

      this.$axios.post(this.$hostname + this.$hName + '/services/tipocliente/findalltipoclientes', {})
        .then(res => (this.RtipoCliente = res.data))

    },

    SelctCbTipoClienteLimt() {

      if (this.Modallfiltro.tipocliente == null) {

        this.Modallfiltro.tipocliente = ''
      }

      if (this.Modallfiltro.tipocliente.codigo == undefined) {

        this.alerta("Seleccione un tipo de cliente", "error");
        this.Modallfiltro.tipocliente = ''
        return

      }

    },


    SelctCbTipoCliente() {

      if (this.form.politica.tipocliente == null) {

        this.form.politica.tipocliente = ''
      }

      if (this.form.politica.tipocliente.codigo == undefined) {

        this.alerta("Seleccione un tipo de cliente", "error");
        this.form.politica.tipocliente = ''
        return

      }

    },



    DiaKeyup(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.politica.hasta = mynumeral(this.politica.hasta).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.politica.hasta == null) {

          this.politica.hasta = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },





    DiaCreFact(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.facturaInicial.diascredito = mynumeral(this.facturaInicial.diascredito).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.facturaInicial.diascredito == null) {

          this.facturaInicial.diascredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },


    DiaCre(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.politica.diascredito = mynumeral(this.form.politica.diascredito).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.form.politica.diascredito == null) {

          this.form.politica.diascredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },

    KeyUpEntero(e) {

      //  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}



      if (e.target.value.length == 0) {

        e.target.value = 0

      }


      if (e.key) {

        var mynumeral = require('numeral')
        e.target.value = mynumeral(e.target.value).value()

        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (e.target.value == null) {

          e.target.value = 0
        }

      }
      else {

        e.key = ""


      }


      if (e.target.value.length == 0) {

        e.target.value = 0
        e.key = 0
      }



    },




    delePol(item) {




      const index = this.form.ListPol.indexOf(item)
      confirm('Desea eliminar este politica?') && this.form.ListPol.splice(index, 1)

      // this.form.ListPol=this.ListPol

    },

    editPol(item) {

      this.politica = Object.assign({}, item);
      this.ObjTemp = Object.assign({}, item);


      // this.pasaporte=item
      // this.ObjTempasaporte=item;

      const index = this.form.ListPol.indexOf(item)
      this.form.ListPol.splice(index, 1)




      this.acti_Nueva_politica = true

      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true
    },


    newPolitica() {

      this.politica = {
        proveedorVencimientoPK: { hasta: 0, proveedor: 0 },
        codigo: 0,
        porcentaje: 0,
        borrado: false

      },

        this.ObjTemp = null

    },

    newFactura() {

      this.facturaInicial = {
        facturasProveedoresPK: { codigo: 0, documento: 0 },
        documentoproveedor: null,
        ncf: "",
        referencia: null,
        fecha: null,
        diascredito: 0,
        totalimpuesto: 0,
        moneda: null,
        tasa: 0,
        pendiente: 0,
        total: 0

      }

      this.ObjTemp = null;
    },

    cerrarModalPol() {

      if (this.ObjTemp != null) {

        this.form.ListPol.push(this.ObjTemp)


      }

      this.newPolitica()
    },


    cerrarModalfac() {

      if (this.ObjTemp != null) {

        this.form.ListFact.push(this.ObjTemp)

      }

      this.newFactura()
    },




    addFact() {

      if (this.$refs.form3.validate()) {

        this.form.ListFact.push(this.facturaInicial)
        this.acti_Nueva_facturaInicial = false


        //this.form.ListFact=this.ListFact

        this.newFactura()

      } else {

        this.alerta("Los campos marcados en rojo son  obligatorios", "error");

      }

      //{ text: '#PASAPORTE', value: 'numeropasaporte' },{ text: 'NOMBRE', value: 'nombre' },{ text: 'PARENTESCO', value: 'parentesco' }


    },



    deleFact(item) {


      if (item.facturasventaPK == null) {
        const index = this.form.ListFact.indexOf(item)
        confirm('Desea eliminar este Factura?') && this.form.ListFact.splice(index, 1)
        //this.form.ListFact= this.ListFact


      } else {


        this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/FacturaInicialMovimiento',
          JSON.parse(JSON.stringify(item)))
          .then(res => { this.deleFact2(item, res.data) });



        //this.disableDocu=true;
      }

    },

    deleFact2(item, bool) {

      if (bool.boobean == true) {
        this.alerta(bool.mensage, bool.estado);
      } else {

        const index = this.form.ListFact.indexOf(item)
        var c = confirm('Desea eliminar este Factura?') && this.form.ListFact.splice(index, 1)

        if (c != false) {

          //this.form.ListFact= this.ListFact
          this.form.ListFactDele.push(item);

        }


      }

    },



    editFact(item) {


      if (item.facturasventaPK == null) {
        this.disableDocu = false;

      } else {
        this.disableDocu = true;
      }



      this.facturaInicial = Object.assign({}, item);
      this.ObjTemp = Object.assign({}, item);

      const index = this.form.ListFact.indexOf(item)
      this.form.ListFact.splice(index, 1)


      this.acti_Nueva_facturaInicial = true

      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true
    },



    addPol() {

      if (this.$refs.form2.validate()) {

        this.form.ListPol.push(this.politica)
        this.acti_Nueva_politica = false

        //this.form.ListPol=this.ListPol

        this.newPolitica()

      } else {

        this.alerta("Los campos marcados en rojo son  obligatorios", "error");

      }

      //{ text: '#PASAPORTE', value: 'numeropasaporte' },{ text: 'NOMBRE', value: 'nombre' },{ text: 'PARENTESCO', value: 'parentesco' }


    },


    deleTel(item) {

      const index = this.form.ListTel.indexOf(item)
      confirm('Desea eliminar este teléfono?') && this.form.ListTel.splice(index, 1)


      //this.form.ListTel=this.ListTel


    },


    editTel(item) {

      this.telefonos = item.telefono
      const index = this.form.ListTel.indexOf(item)
      this.form.ListTel.splice(index, 1)
      //this.form.ListTel=this.ListTel
      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialog = true


    },


    addTel() {

      //console.log(this.telefonos.toString().length)

      if (this.telefonos.toString().length == 13) {
        var tempTel = {
          borrado: false,
          activo: true,
          proveedoresTelefonosPK: { telefono: this.telefonos, proveedor: 0 },
          empresa: 1
        }

        this.form.ListTel.push(tempTel)

        this.telefonos = ""

        //this.form.ListTel=this.ListTel

      } else {

        this.alerta("Teléfono invalido", "error");


      }

      //addTel,[{ telefono: '809-555-5555' ,eliminar:''}],ListTel

    },


    cargarPaginacion(e) {
      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      if (number == null) { number = 0 }

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;

    },


    SubirArchivoProve(){

      if (this.$refs.formInputFilePro.validate()) {
        EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoProveedores[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/suplidor", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );



      }else {
        this.alerta("Seleccione un archivo ", "error");
      }

  

    },

    SubirArchivoFactInc(){

if(this.$refs.formInputFileFactIncPro.validate()) {
  EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoFactIncPro[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/facutrasInicialesProveedor", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );

}else {
this.alerta("Seleccione un archivo ", "error");
}
     
   },

    SubirArchivoFactIncProveSelec(){

    if(this.$refs.formInputFileFactIncProSelec.validate()) {

      EventBus.$emit("loading", true);
      var head = this.$store.getters.GetheadersAxios
      head["Content-Type"] = "multipart/form-data"
      head.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.formData = new FormData();
      this.formData.append('file', this.ArchivoFactIncProSelec[0]);
      this.$axios
        .post(this.$hostname + this.$hName + "/importData/facutrasInicialesProveedor2", this.formData, { headers: head })
        .then(res => {
          this.alerta("Dato importado correctamente", "bien")
          EventBus.$emit("loading", false);
        })
        .catch((error) => {
              this.alerta("Dato no importado correctamente", "error")
            }
            );

    }else {
  this.alerta("Seleccione un archivo ", "error");
    }

      
    },  
          

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.form.ListTel = [],
        this.form.ListPol = [],
        this.form.ListFact = [],

        this.form.provedores = {

          codigo: 0,
          nombre: '',
          rnc: '',
          tipo: null,
          pais: null,
          ciudad: "",
          direccion: "",
          gasto: null,
          email: "",
          contacto: '',
          telefonocontacto: '',
          emailcontacto: "",
          diaCredito: 0,
          limiteCredito: 0,
          retencionItbis: 0,
          isr: 0,
          descuentoPp: 0,
          codigoFijo: "",
          activo: true,
          borrado: false,
          pagoSuspendido: false,



          rncOriginal: '',

          cedrnc: "",
          comentario: '',
          empresa: 1,
          //fechaCreacion: new Date().toISOString().substr(0,10),
          //fechaModificacion: new Date().toISOString().substr(0,10),

          informales: false,
          porcientoRetencion: null,
          referencia: null,

          usuarioCreacion: this.$UseName,
          usuarioModificacion: ''

        },

        (this.ejecucion = true);
      this.ArchivoProveedores=null
      this.ArchivoFactIncPro=null
      this.ArchivoFactIncProSelec=null
      this.formData=null
      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");

    },


    save() {



      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);
          //this.form.provedores.facTemp1 = this.form.ListFact
          this.form.provedores.facTemp1 =[]
          this.form.provedores.proveedoresTelefonosList = this.form.ListTel
          this.form.provedores.ProveedorVencimientoList = this.form.ListPol
          this.$axios
            .post(
              this.$hostname + this.$hName + "/proveedores/save", this.form.provedores,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => {
              this.alerta("Datos salvados correctamente !!!", "bien")
            })
            .catch(error => {
              EventBus.$emit("loading", false);
              this.alerta('Dato no guardado correctamente', "error")
            });
        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },

    remove() {

      if (this.form.provedores.codigo != 0 && this.form.provedores.codigo != null) {
        this.Elim_notif.estado = !this.Elim_notif.estado;

      } else {
        this.alerta("Seleccione un proveedor para  eliminar", "error")
      }
    },

    eliminar() {




      if (this.form.provedores.codigo != 0 && this.form.provedores.codigo != null) {
        if (this.$refs.form.validate()) {


          this.form.usuarios = this.$CodeUser

          this.$axios
            .post(
              this.$hostname + this.$hName + "/proveedores/borrar", this.form.provedores,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => {
              if (res.data) {
                this.alerta("Datos eliminado correctamente!!!", "bien")
              }
            })
            .catch(error => this.alerta('Dato no eliminado correctamente', "error"));
        }
      }



    },

    datostabla(e) {
      var obj = JSON.parse(JSON.stringify(e))
      if (e.email != null) { obj.email = obj.email.trim() }
      if (e.contacto != null) { obj.contacto = obj.contacto.trim() }
      if (e.telefonocontacto != null) { obj.telefonocontacto = obj.telefonocontacto.trim() }
      if (e.emailcontacto != null) { obj.emailcontacto = obj.emailcontacto.trim() }

      if (e.hasOwnProperty('rnc') && e.rnc != null) { obj.rnc  = obj.rnc.trim() }else{ obj.rnc='' }

      // form.provedores.email, form.provedores.contacto, form.provedores.telefonocontacto, form.provedores.emailcontacto

      // if(!e.hasOwnProperty('cedrnc')){
      // e.cedrnc=""
      // }

      if (e.hasOwnProperty('tipo')) {

        if (e.tipo.hasOwnProperty('fechaCreacion')) {
          obj.tipo.fechaCreacion = new Date(e.tipo.fechaCreacion).toISOString().substr(0, 10)
          //delete e.tipo["fechaCreacion"]
        }

        if (e.tipo.hasOwnProperty('fechaModificacion') && e.tipo.fechaModificacion != null) {

          obj.tipo.fechaModificacion = (new Date(e.tipo.fechaModificacion)).toISOString().substr(0, 10)

          //delete e.tipo["fechaModificacion"]


        }

      }


      if (e.hasOwnProperty('fechaCreacion')) {
        obj.fechaCreacion = new Date(e.fechaCreacion).toISOString().substr(0, 10)
      }

      if (e.hasOwnProperty('fechaModificacion') && e.fechaModificacion != null) {
        obj.fechaModificacion = new Date(e.fechaModificacion).toISOString().substr(0, 10)
      }

      this.form.ListTel = []
      this.form.ListPol = []
      this.form.ListFact = []


      this.form.provedores = obj;
      this.form.ListTel = obj.proveedoresTelefonosList;
      this.form.ListPol = obj.proveedorVencimientoList;
      this.form.ListFactDele = []



      //var obj= JSON.parse(JSON.stringify(e)) 
      //delete obj["tipo"]


     /* <!--13/08-24:MIC-2411: https://decasystem.atlassian.net/browse/MIC-2411 -->
     this.$axios.get(this.$hostname + this.$hName + '/proveedores/facturaInicialesPendientes/' + this.form.provedores.codigo, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.form.ListFact = JSON.parse(JSON.stringify(res.data)),
            setTimeout(this.LisFactFechaEntero, 200);
        });*/

        this.MakarNo()
  var _this=this;
setTimeout(function(){ 
_this.MakaraCelPro()
}, 800);

      //  this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/ClienteAllData', {"codigo":+e.codigo}) JSON.parse(JSON.stringify(e.codigo))
      // .then(res =>  {  

      //   this.tb_clienteTel(res.data.ListTel.entity),this.tb_clienteFac(res.data.ListFact.entity),this.tb_clientePas(res.data.ListPol.entity) 

      // });

      //nota: mequede revisando el problema tostrin pare ce en cedula
      // this.acti_tb_data=false;
    },

    anular() {

    },

    imprimirList() {
      if (this.form.provedores.codigo > 0) {
        //this.datosImprecion.secuencia=this.form.provedores.codigo 

        this.datosImprecion.moneda = this.moneda = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()))
        this.datosImprecion.suplidor = this.form.provedores.codigo
        this.datosImprecion.schema = this.$store.getters.GetheadersAxios["X-TENANT-ID"]

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
      this.dialogimprimirListFilt = false
    },


    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos
    },


    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },





    LisFactFechaEntero() {



      this.form.ListFact.forEach(element => {



        if (element.hasOwnProperty('fechaCreacion')) {
          element.fechaCreacion = new Date(element.fechaCreacion).toISOString().substr(0, 10)
        }

        if (element.hasOwnProperty('fechaModificacion') && element.fechaModificacion != null) {
          element.fechaModificacion = new Date(element.fechaModificacion).toISOString().substr(0, 10)
        }

        if (element.hasOwnProperty('fecha') && element.fecha != null) {
          element.fecha = new Date(element.fecha).toISOString().substr(0, 10)
        }





        if (element.hasOwnProperty('documentoproveedor')) {

          if (element.documentoproveedor.hasOwnProperty('fechaCreacion')) {
            element.documentoproveedor.fechaCreacion = new Date(element.documentoproveedor.fechaCreacion).toISOString().substr(0, 10)
          }

          if (element.documentoproveedor.hasOwnProperty('fechaModificacion') && element.documentoproveedor.fechaModificacion != null) {
            element.documentoproveedor.fechaModificacion = new Date(element.documentoproveedor.fechaModificacion).toISOString().substr(0, 10)
          }

        }



        if (element.hasOwnProperty('proveedor')) {

          if (element.proveedor.hasOwnProperty('fechaCreacion')) {
            element.proveedor.fechaCreacion = new Date(element.proveedor.fechaCreacion).toISOString().substr(0, 10)
          }

          if (element.proveedor.hasOwnProperty('fechaModificacion') && element.proveedor.fechaModificacion != null) {
            element.proveedor.fechaModificacion = new Date(element.proveedor.fechaModificacion).toISOString().substr(0, 10)
          }


          if (element.proveedor.hasOwnProperty('tipo')) {


            if (element.proveedor.tipo.hasOwnProperty('fechaCreacion')) {
              element.proveedor.tipo.fechaCreacion = new Date(element.proveedor.tipo.fechaCreacion).toISOString().substr(0, 10)
            }

            if (element.proveedor.tipo.hasOwnProperty('fechaModificacion') && element.proveedor.fechaModificacion != null) {
              element.proveedor.tipo.fechaModificacion = new Date(element.proveedor.tipo.fechaModificacion).toISOString().substr(0, 10)
            }
          }

        }

      });

    },





    tb_clientePas(e) {

      //{ text: '#PASAPORTE', value: 'numeropasaporte' },{ text: 'NOMBRE', value: 'nombre', width:200 },
      //{ text: 'PARENTESCO', value: 'parentesco' },{ text: 'actions', value: 'action' }

      e.forEach(element => {

        if (element.fechaemision != null) { element.fechaemision = new Date(element.fechaemision).toISOString().substr(0, 10) } else { element.fechaemision = null }
        if (element.fechavencimiento != null) { element.fechavencimiento = new Date(element.fechavencimiento).toISOString().substr(0, 10) } else { element.fechavencimiento = null }


        this.form.ListPol.push(element)
      });

      //this.form.ListPol=this.ListPol



      //ListPol
      // ListFact:[],
      //         ListPol:[],
      //         ListTel:[],
      //this.form.politica.ListFact=this.ListFact

    },


    tb_clienteFac(e) {
      e.forEach(element => {


        // var jsn={
        // documento:element.documentoFactura,
        // ncf:element.ncf,
        // //fecha:element.fecha.split("T")[0],this.fecFormatter() 
        // fecha: new Date().toISOString(element.fecha).substr(0, 10),
        // diaCredito:element.diascredito,
        // itbis:element.valorimpuesto,
        // valor:element.valor,
        // referencia: element.referencia

        // }

        // this.ListFact.push(jsn)

        element.documento = element.documentoproveedor;
        element.fecha = new Date(element.fecha).toISOString().substr(0, 10)
        element.itbis = element.valorimpuesto
        //element.diaCredito=element.diascredito


        this.form.ListFact.push(element)

      });

      //this.form.ListFact=this.ListFact

    },

    tb_ProvedorTel(e) {

      e.forEach(element => {
        this.form.ListTel.push(element)
      });

      //this.form.ListTel=this.ListTel

    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        search: null,
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.search != null && this.search.toString().length == 0) { this.search = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.search)
    },

    CargarPaginacion(e, s) {

      this.search = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {
      if (this.search != null && this.search.toString().length == 0) { this.search = null }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.search
      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findListProveedorPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/proveedores/findallbyDesc/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart + "/" + (this.search === '' ? "*" : this.search),
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {


    ActDescBtn() {

      if (this.form.provedores.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }



      }


      if (this.form.provedores.codigo <= 0) {



        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: true,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }











};
</script>

<style scoped>

</style>
