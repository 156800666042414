<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Inventario Físico'"
  :descripcion="'Inventario Físico'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBancos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Desde"
         :rules="[$rules.required]"
        v-model="InventarioFisico.desde"
        hide-details
        @blur="BlurDesde"
        @input="inventarioFisicoList"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 1 -->
<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Hasta"
         :rules="[$rules.required]"
        v-model="InventarioFisico.hasta"
        hide-details
        @blur="BlurHasta"
        @input="inventarioFisicoList"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 2 -->



<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'ver', sortable: false,  value: 'action', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Fecha', sortable: false, value: 'fecha', width:120, class: ['no-gutters black--text']  },
                 { text: 'Almacén', sortable: false, value: 'almacen.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: '# Inventario', sortable: false, value: 'secuencia', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Libro (Valor)', sortable: false, value: 'valorlibro', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Físico (Valor)', sortable: false, value: 'valorfisico', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Concepto', sortable: false, value: 'comentario', width:300, class: ['no-gutters black--text'] },
                 { text: 'Estado', sortable: false, value: 'activo', width:'auto', class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listDatos"  
              :items-per-page="15"
            >
 <template v-slot:item.action="{ item }"> 
    <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn v-on="on" fab @click.prevent="VerDetalleinventario(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn> 

 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-eye"> 
          <span> Ver registro.</span>
    </i>   
</v-tooltip>
<!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
  
 </template> 

<template v-slot:item.valorlibro="props">
    <span>{{currencyFormatter(props.item.valorlibro )}}</span>
</template>

<template v-slot:item.valorfisico="props">
    <span>{{currencyFormatter(props.item.valorfisico )}}</span>
</template>

<template v-slot:item.fecha="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = listDatos.indexOf(props.item)}}</span>
                <span style="font-size:13px;" >{{ FormatoFecha(props.item.fecha) }}</span>
 </template>


 <template v-slot:item.activo="props">
  <span class="red--text" v-if="props.item.activo==false" >Cerrado</span>
  <span class="green--text" v-if="props.item.activo==true"  >Activo</span>
 </template>


<!-- <template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
 
 </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Meta de Ventas:0.00</span>
        <span>Total Ventas:0.00</span>
        <span>Total % Comisión:0.00</span>
       <span>Valor Comisiones:0.00</span>
      </v-list-item-content>
</v-list-item>

  </template> -->
</v-data-table>
 </div>
</v-col>



              </v-row>

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


<!-- ------------------------------------------------------ Modal- Detalle inventario-->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="DialogDetalleInv" persistent max-width="95%" max-height="95%">
 <v-card>

 <v-toolbar
 dense
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Detalle Inventario </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="DialogDetalleInv=false"
        >
        <!-- cerrarModalHis() -->
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form3"
    v-model="valid"
    lazy-validation
  >
<v-row >
 

<!-- columna 2 -->
<v-row class="d-flex justify-center">
<v-col cols="12" md="9" sm="9" >
<!-- -----------------------------  Hasta  -->
<v-text-field
                autocomplete="off"
                v-model="search"
                label="Buscar producto del inventario seleccionado"
                single-line
                hide-details
                rounded
                dense
                outlined
            >
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>
</v-col>
</v-row >

<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >

<v-data-table
      class=" elevation-1 TbSize13"
      :headers="[
                { text: 'Código', sortable: false,  value: 'producto.codigo', width:120, class: ['no-gutters black--text']  },
                 { text: 'Nombre', sortable: false, value: 'producto.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Costo', sortable: false, value: 'costo', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Cantidad (Contado)', sortable: false, value: 'cantidad', width:150, class: ['no-gutters black--text']  },
                 { text: 'Valor', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text']  },
                 ]"
              dense
              :items="invfisDetalleList"
              :search="search"      
              :items-per-page="15"
            >
  

      <template v-slot:item.costo="props">
                <span >{{currencyFormatter(props.item.costo) }}</span>
      </template>

      <template v-slot:item.valor="props">
                <span >{{currencyFormatter(props.item.valor) }}</span>
      </template>



<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                           
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.costo)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.cantidad)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valor)}} </strong></td>
 </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total costo: {{currencyFormatter(totales.costo)}}</span>
        <span>Total cantidad: {{currencyFormatter(totales.cantidad)}}</span>
       <span>Valor valor: {{currencyFormatter(totales.valor)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>


<template v-slot:no-data>
<v-card-title class="d-flex justify-center" >Este inventario no posee detalle</v-card-title>
 </template>


</v-data-table>

 </div>

</v-col>

</v-row>
</v-form>
</v-card-text>

<hr/> 
 
 
</v-card>
</v-dialog>





<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
    var json = {
      titulo: "Inventario Físico",
      descripcion: "Inventario Físico",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "InventarioFisicoNew",
        "InventarioFisicoSave",
        "InventarioFisicoRemove",
        "InventarioFisicoAnular",
        "InventarioFisicoPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("InventarioFisicoNew", this.new);
    // EventBus.$on("InventarioFisicoSave", this.save);
    // EventBus.$on("InventarioFisicoRemove", this.remove);
    // EventBus.$on("InventarioFisicoAnular", this.remove);
    // EventBus.$on("InventarioFisicoPrintList", this.imprimirList);
    EventBus.$on("emitInventarioFisico", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({


   
      listDatos:[],
      DialogDetalleInv:false,
      invfisDetalleList:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      InventarioFisico: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

totales:{
  costo:0,
  cantidad:0,
  valor:0
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
// this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    
    datostabla(e) {

      this.InventarioFisico = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() {},
    newwnuevo() {
      (this.InventarioFisico = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


BlurDesde(){
if(this.InventarioFisico.desde.toString().length==0){
  this.InventarioFisico.desde=new Date().toISOString().substr(0,10)
}

 },


 BlurHasta(){

if(this.InventarioFisico.hasta.toString().length==0){
  this.InventarioFisico.hasta=new Date().toISOString().substr(0,10)
}

 },


inventarioFisicoList(){

 var f1=new Date(this.InventarioFisico.desde).toISOString().substr(0,10)
 var f2=new Date(this.InventarioFisico.hasta).toISOString().substr(0,10)

// var Obj={}

var link = `/ConsultaRapida/InventarioFísico/${f1}/${f2}`
this.$axios
        .get(this.$hostname + this.$hName +link,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ this.listDatos=res.data})
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});
},


async VerDetalleinventario(e){

this.totales={
  costo:0,
  cantidad:0,
  valor:0

}


this.invfisDetalleList=[]




if(e.hasOwnProperty('inventariofisicoDetalleList') && e.inventariofisicoDetalleList!=null){
// this.invfisDetalleList=JSON.parse(JSON.stringify(e.inventariofisicoDetalleList))

var obj=JSON.parse(JSON.stringify(e.inventariofisicoDetalleList))

await obj.forEach(elent => {

  elent.valor=(elent.costo*elent.cantidad)
  this.totales.costo+=elent.costo
  this.totales.cantidad+=elent.cantidad
  this.totales.valor+=elent.valor

});

this.invfisDetalleList=obj

}

this.DialogDetalleInv=true


// almacen:Object
// balance:null
// borrado:false
// cantidad:1
// costo:338.13288815723377
// fechaVencimiento:null
// id:2183
// lote:null
// producto:Object
// secuencia:109
// ubicacion:null
// unidad:Object




},


alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {},




  },

computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
  }

};
</script>

<style></style>
