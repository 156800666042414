<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
           :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase
            :titulo="'REPORTE ÓRDENES'"
            :descripcion="'IMPRIME REPORTE ÓRDENES'"
            :EstadoBtn="EstadoBtn"
            @abrirBarraTablaDinamica="abrirTabla"
            @MethodNuevo="newwnuevo"
            @MethodGuardar="save"
            @MethodEliminar="remove"
            @MethodAnular="anular"
            @MethodImprimir="imprimirList"
        />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                        small
                        color="yellow accent-4"
                        @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn
                    >
                    <v-btn
                        small
                        dark
                        color="deep-purple accent-2"
                        @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn
                    >
                  </v-btn-toggle>
                </center>
                <!--                <BarraBusqueda2-->
             
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar
                    flat
                    color="#BDBDBD"
                    dense
                    style="border: 1px solid #000000;"
                >


                  <v-avatar tile size="45">
                    <img
                        src="@/assets/iconos_micromarket/custom-reports.png"
                        alt="Reporte"
                    >
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;">Reporte Órdenes</span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="cerrarmodalcuenta"
                  >
                   <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  desde  -->
                        <v-text-field
                            dense
                            outlined
                            type="date"
                            label="Desde:"
                            :rules="[$rules.required]"
                            v-model="ReporteOrdenes.desde"
                            @blur="BlurDesde"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

 <v-radio-group hide-details class="ma-1 pa-1"  v-model="ReporteOrdenes.pendiente" row>     
                  <v-radio label="pendientes" color="green" :value="true"></v-radio>
                  <v-radio label="Finalizados" color="green" :value="false"></v-radio>                                       
</v-radio-group>
                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col  cols="12" md="6" sm="6">

                        <!-- -----------------------------  Hasta  -->
                        <v-text-field
                            dense
                            outlined
                            type="date"
                            :rules="[$rules.required]"
                            label="Hasta:"
                            v-model="ReporteOrdenes.hasta"
                            @blur="BlurHasta"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                <v-radio-group v-if="false" hide-details class="ma-1 pa-1"  v-model="ReporteOrdenes.preventivas " row>     
                  <v-radio label="Preventivas" color="green" :value="true"></v-radio>
                  <v-radio label="No-Preventivos" color="green" :value="false"></v-radio>                                       
                </v-radio-group>


                      </v-col>
                      <!-- fn columna 2 -->


                    </v-row>


                  </v-form>
                </v-container>

                <hr>

<v-toolbar
          flat
          color="#BDBDBD"
          dense
          style="border: 1px solid #000000;"
          class="d-flex justify-center"
      >

        <v-btn-toggle dense rounded>
         
          <v-btn
              color="light-blue accent-2"
              target="_blank"
              @click.prevent="ReporteImprimir"

          >
            <i style="font-size: 28px;" class="fa fa-print"> </i>

            <span style="color:#FFFFFF;">Printer </span>
          </v-btn>
       
        </v-btn-toggle>
      </v-toolbar>
                <!-- <ModalReporteFiltros
                    @DataReporteBasico="DDataReporteBasico"
                    :Datos="ReporteOrdenes"
                /> -->

              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
        label="myAlert"
        v-model="acti_tb_data"
        persistent
        max-width="100%"
        max-height="100%"
        transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
              color="grey darken-4"
              fab
              small
              @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                  v-model="search"
                  @keyup="filtro"
                  label="Buscar"
                  single-line
                  hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
                :headers="slotHtabla"
                dense
                :items="list"
                :search="search"
                @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar
        v-model="aalert.estado"
        :color="aalert.color"
        style="margin-top: 20%;"
        :vertical="true"
        :timeout="120000"
        top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import {EventBus} from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import {currencyFormatter,FormatoFecha,HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {
 
  },
  mounted() {

  },

  data: () => ({
    Rpropietario: [],
    RSucursal: [],
    RCaja: [],
    ListaTiposClientes: [],
    windowSize: {width: "auto", height: "auto"},

    ReporteOrdenes: {
      desde: fechaNueva(),
      hasta: fechaNueva(),
      pendiente:true,
      preventivas:true,
      // nombre: 'REPORTE VOUCHER TARJETAS DE CREDITO',
      // UrlImprimir: '/reportes-pv/ImprimirListVoucherTarjetasCredito',
      // UrlExel: '/reportes-pv/ExcelListVoucherTarjetasCredito',
      // UrlCorreo: '/reportes-pv/CorreoListVoucherTarjetasCredito',
    },


    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,
    headers: [{text: "Tipo Clientes", value: "descripcion"}],
    slotlist: [
      {dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:"},
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      {dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo"},
    ],
    slotHtabla: [
      {text: "Código", value: "codigo"},
      {text: "Descripcion", value: "descripcion"}
    ],
    list: [],
    search: "",
    acti_tb_data: false,
  
    aalert: {
      estado: false,
      color: null
    },

  }),
  methods: {
    currencyFormatter,FormatoFecha,HoraFormatter,
    HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    datostabla(e) {
    },
    save() {},
    remove() {},
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
      (this.ReporteOrdenes = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
          EventBus.$emit("actualizaBarraBusqueda2");
    },


BlurDesde(){

if(this.ReporteOrdenes.desde.toString().length==0){
  this.ReporteOrdenes.desde=this.fechaNueva()
}


 },


 BlurHasta(){

if(this.ReporteOrdenes.hasta.toString().length==0){
  this.ReporteOrdenes.hasta=this.fechaNueva()
}

 },

ReporteImprimir(){
  EventBus.$emit("loading", true);
// ReporteOrdenes: {
//       desde: new Date().toISOString().substr(0, 10),
//       hasta: new Date().toISOString().substr(0, 10),
//       pendiente:true,
//       preventivas:true,
//       // nombre: 'REPORTE VOUCHER TARJETAS DE CREDITO',
//       // UrlImprimir: '/reportes-pv/ImprimirListVoucherTarjetasCredito',
//       // UrlExel: '/reportes-pv/ExcelListVoucherTarjetasCredito',
//       // UrlCorreo: '/reportes-pv/CorreoListVoucherTarjetasCredito',
//     },

var desde=this.ReporteOrdenes.desde
var hasta=this.ReporteOrdenes.hasta

var inform=`DESDE: ${this.FormatoFecha(desde)}      HASTA:  ${this.FormatoFecha(hasta)}      `

var where=` om.pendiente= ${this.ReporteOrdenes.pendiente}  and om.fechapautada >=' ${desde} ' and om.fechapautada <= ' ${hasta}'`
    

if(this.ReporteOrdenes.pendiente==false){ inform+=`    Ordenes: Realizada`}else{inform+=`    Ordenes: pendiente`}


var obj={
   where:where,
   inform:inform
 }

this.$axios.post(this.$hostname + this.$hName +'/ordenes/imprimirReport', JSON.parse(JSON.stringify(obj)),{ headers: this.$store.getters.GetheadersAxios }).then(res => {
  EventBus.$emit("loading", false);
  /*let pdfWindow = window.open("Reporte de imprecion")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>") */ 
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'reporte ordenes');
    
      });



},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    },
    DDataReporteBasico(e) {
    },
  }
};
</script>

<style></style>
