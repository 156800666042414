<template>
  <div>{{ InfoPagina }}
    <v-card class="overflow-hidden" v-if="$store.getters.GetdatosSistem.ActivarBarraAccion == true">
      <v-app-bar height="63px" width="100%" absolute color="indigo darken-4" dark dense elevate-on-scroll
        scroll-target="#scrolling-techniques-7">
        <v-app-bar-nav-icon v-if="btnBarraDi" @click.stop="abrirTablaSecundaria">
        </v-app-bar-nav-icon>

        <v-tooltip bottom v-if="windowSize.width <= 600">
          <template v-slot:activator="{ on }">

            <v-toolbar-title v-on="on">
              {{ titulo }}
            </v-toolbar-title>

          </template>
          <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
            <span> {{ titulo }}: </span> {{ descripcion }}</i>

        </v-tooltip>


        <v-menu open-on-hover bottom offset-y v-if="windowSize.width > 600">
          <template v-slot:activator="{ on }">
            <v-toolbar-title v-on="on">
              {{ titulo }}
            </v-toolbar-title>
          </template>
          <v-alert color="lightgray" dark icon="mdi-information" dense>
            {{ descripcion }}
          </v-alert>
        </v-menu>

        <v-spacer></v-spacer>
        <v-card flat color="indigo darken-4">
          <v-card-text>
            <v-row align="center" justify="center">
              <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" max-width="900px" color="indigo darken-4">
                <!-- <v-container > -->
                <v-btn-toggle v-model="toggle_exclusive" color="indigo" style="margin-right:50px;">
                  <v-btn id="boton-mini" color="#90A4AE" dark small v-if="nuevo" @click.prevent="neww">Nuevo
                    <v-icon dark right>mdi-file</v-icon>
                  </v-btn>

                  <v-btn id="boton-mini" color="success" dark small v-if="save" @click.prevent="savee">Salvar
                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                  </v-btn>

                  <v-btn id="boton-mini" color="red" dark small v-if="eliminar" @click.prevent="removee">Eliminar
                    <i style="font-size: 18px;" dark right class="fa fa-trash"></i>
                  </v-btn>

                  <v-btn id="boton-mini" color="orange" dark small v-if="anular" @click.prevent="anularr">Anular
                    <v-icon dark right>mdi-cancel</v-icon>
                  </v-btn>

                  <v-btn id="boton-mini" color="blue" dark small v-if="imprimir" @click.prevent="printlist">Imprimir
                    <v-icon dark right>mdi-printer-check</v-icon>
                  </v-btn>

                  <v-btn id="boton-mini" color="teal darken-2" dark small v-if="vercuenta" @click.prevent="veercuentaa">
                    Ver Cuenta

                    <i style="font-size: 18px;" class="fa fa-eye"> </i>

                  </v-btn>

                  <v-btn id="boton-mini" color="green lighten-1" dark small v-if="stemporal" @click.prevent="saavetem">
                    Salvar temporal
                    <i style="font-size: 18px;" class="fa fa-hourglass-half"> </i>

                  </v-btn>


                  <v-btn id="boton-mini" color="teal lighten-2" dark small v-if="rtemporal"
                    @click.prevent="restaurartem">Restaurar Temporal
                    <i style="font-size: 18px;" class="fa fa-history"> </i>

                  </v-btn>

                  <v-btn id="boton-mini" color="grey lighten-1" dark small v-if="importarDato"
                    @click.prevent="importarDatoos">Importar Datos
                    <i style="font-size: 18px;" class="fa fa-cloud-upload"> </i>
                  </v-btn>


                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-btn id="boton-mini" color="blue lighten-3" v-on="on" dark small v-show="cargarProducto"
                        @click.prevent="cargarProductoo">Cargar Productos
                        <i style="font-size: 18px;" class="fa fa-shopping-cart"> </i>
                      </v-btn>

                    </template>
                    <span>Cargar Productos para Inventario físico</span>
                  </v-tooltip>


                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-btn id="boton-mini" v-on="on" color="blue lighten-3" dark small v-show="productoInv"
                        @click.prevent="productoInvv"> Imprimir Productos
                        <i style="font-size: 18px;" class="fa fa-print"> </i>
                      </v-btn>
                    </template>
                    <span>Imprimir Productos para inventario físico</span>
                  </v-tooltip>

                  <!-- <v-btn color="blue" dark small @click="abrirBarraderecha">
                    <v-icon dark left>mdi-dots-vertical</v-icon>
                  </v-btn> -->
                </v-btn-toggle>

                <!-- </v-container> -->
              </v-sheet>

            </v-row>
          </v-card-text>
        </v-card>
      </v-app-bar>
      <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="100rem">
        <div v-bind:style="{ 'width': '100%', 'height': this.windowSize.height - 21 + 'px' }">
          <!-- <div style="margin-top:90px;"></div> -->
          <br><br><br>
          <!-----------------cuerpo del 650  componente style="height:780px; width:100%; max-height"-------------->
          <router-view></router-view>
          <!--------- fin cuerpo ----------------->
        </div>

      </v-sheet>
    </v-card>

    <div v-if="$store.getters.GetdatosSistem.ActivarBarraAccion == false"
      v-bind:style="{ 'background-color': '#dd4b39', 'position': 'absolute', 'border': '2px solid #F44336', 'width': '100%', 'height': this.windowSize.height - 10 + 'px' }">
      <router-view></router-view>
    </div>
  </div>


</template>

<script>
import { EventBus } from "../event-bus.js";
import BarraNavegacion from "@/components/BarraNavegacion";
export default {
  components: {
    BarraNavegacion
  },

  mounted() {
    EventBus.$on("ActDesaBotones", this.ActivarDesactivarBotones);
    EventBus.$on("anularBotonesAccion", this.btnBarradim);
    EventBus.$on("onResize", this.onResize);

  },
  data() {
    return {
      titulo: "",
      descripcion: "",
      drawer: true,
      toggle_exclusive: false,
      openOnHover: false,
      nuevo: true,
      save: true,
      eliminar: false,
      anular: true,
      imprimir: true,
      accion: null,
      vercuenta: false,
      stemporal: false,
      rtemporal: false,
      importarDato: false,

      cargarProducto: false,
      productoInv: false,


      btnBarraDi: true,
      pv: false,

      windowSize: { width: "auto", height: "auto" },


    };
  },
  methods: {

    btnBarradim(e) {

      sessionStorage.setItem("BotonesAccion", JSON.stringify(e));


      console.log('Imprimio la pamparaaa')
      console.log(e)


      if (e.titulo == "Login") {

        this.$store.commit('MutationActivarBarraAccion', false);
        this.$store.commit('MutationbtnAppBar', false);
      } else {
        this.$store.commit('MutationActivarBarraAccion', true);
        this.$store.commit('MutationbtnAppBar', true);

      }



      if (e.titulo == "Punto de ventas") {

        this.$store.commit('MutationActivarBarraAccion', false);

      } else {
        this.$store.commit('MutationActivarBarraAccion', true);

      }

      //|| e.titulo=="Depósitos Bancarios" || e.titulo=="Cheques" || e.titulo=="Notas de Crédito" || e.titulo=="Notas de Débito" 

      if (e.titulo == "Conciliación Bancaria") {

        location.reload();
        //console.log('Imprimio la pamparaaa')
        //  console.log(e)

      }

      if (e.hasOwnProperty('accion')) { this.accion = JSON.parse(JSON.stringify(e.accion)) }
      if (e.hasOwnProperty('titulo')) { this.titulo = JSON.parse(JSON.stringify(e.titulo)) }
      if (e.hasOwnProperty('descripcion')) { this.descripcion = JSON.parse(JSON.stringify(e.descripcion)) }
      if (e.hasOwnProperty('nuevo')) { this.nuevo = JSON.parse(JSON.stringify(e.nuevo)) }
      if (e.hasOwnProperty('save')) { this.save = JSON.parse(JSON.stringify(e.save)) }
      if (e.hasOwnProperty('eliminar')) { this.eliminar = JSON.parse(JSON.stringify(e.eliminar)) }
      if (e.hasOwnProperty('anular')) { this.anular = JSON.parse(JSON.stringify(e.anular)) }
      if (e.hasOwnProperty('imprimir')) { this.imprimir = JSON.parse(JSON.stringify(e.imprimir)) }
      if (e.hasOwnProperty('vercuenta')) { this.vercuenta = JSON.parse(JSON.stringify(e.vercuenta)) } else { this.vercuenta = false }

      if (e.hasOwnProperty('stemporal')) { this.stemporal = JSON.parse(JSON.stringify(e.stemporal)) } else { this.stemporal = false }
      if (e.hasOwnProperty('rtemporal')) { this.rtemporal = JSON.parse(JSON.stringify(e.rtemporal)) } else { this.rtemporal = false }
      if (e.hasOwnProperty('importarDato')) { this.importarDato = JSON.parse(JSON.stringify(e.importarDato)) } else { this.importarDato = false }

      if (e.hasOwnProperty('cargarProducto')) {

        console.log('veeer cargarProducto')
        console.log(e.cargarProducto)

        this.cargarProducto = JSON.parse(JSON.stringify(e.cargarProducto))

      } else { this.cargarProducto = false }
      if (e.hasOwnProperty('productoInv')) { this.productoInv = JSON.parse(JSON.stringify(e.productoInv)) } else { this.productoInv = false }


      if (e.hasOwnProperty('btnBarraDi')) {

        this.btnBarraDi = false

      } else { this.btnBarraDi = true }



      EventBus.$emit("cerrarBarraPrincipal");

    },


    onResize(e) {


      this.windowSize = e
      this.windowSize = { width: e.width, height: e.height }



      // if(e.width>600){

      // this.windowSize={width:300, height:e.height}

      // this.btmin=false


      // }else{

      // this.windowSize={width:e.width+100, height:e.height}


      // this.btmin=true

      // }



    },

    ActivarDesactivarBotones(e) {

      this.nuevo = e.nuevo,
        this.save = e.save,
        this.eliminar = e.eliminar,
        this.anular = e.anular,
        this.imprimir = e.imprimir,
        this.vercuenta = e.vercuenta,
        this.stemporal = e.stemporal,
        this.rtemporal = e.rtemporal,
        this.importarDato = e.importarDato,
        this.cargarProducto = e.cargarProducto,
        this.productoInv = e.productoInv


    },

    anularBotones(e) {
      var json = {
        save: e.save,
        eliminar: e.eliminar,
        anular: e.anular,
        nuevo: e.nuevo,
        imprimir: e.imprimir,
        vercuenta: e.vercuenta,
        stemporal: e.stemporal,
        rtemporal: e.rtemporal,
        importarDato: e.importarDato,
        cargarProducto: e.cargarProducto,
        productoInv: e.productoInv
      };
    },

    acciones(e) {
      this.accion = e.accion;
    },

    abrirBarraderecha() {
      EventBus.$emit("abrirMenuDerecho");
    },
    abrirTablaSecundaria() {
      EventBus.$emit("abrirMenuizquierdo");
      EventBus.$emit("abrircerrarBarraPrincipal");
    },
    savee() {
      EventBus.$emit(this.accion[1]);
    },
    removee() {
      EventBus.$emit(this.accion[2]);
    },
    anularr() {
      EventBus.$emit(this.accion[3]);
    },
    neww() {
      EventBus.$emit(this.accion[0]);
    },
    printlist() {
      EventBus.$emit(this.accion[4]);
    },

    veercuentaa() {
      EventBus.$emit(this.accion[5]);
    },


    saavetem() {
      EventBus.$emit(this.accion[6]);
    },

    restaurartem() {
      EventBus.$emit(this.accion[7]);
    },

    importarDatoos() {
      EventBus.$emit(this.accion[8]);
    },


    cargarProductoo() {

      EventBus.$emit(this.accion[9]);

    },

    productoInvv() {

      EventBus.$emit(this.accion[10]);

    },



  },
  computed: {

    InfoPagina() {

      // console.log(this.titulo)

      var e = JSON.parse(sessionStorage.getItem("BotonesAccion"));

      console.log('computed computed')
      console.log(e)


      if (e != null) {

        if (e.hasOwnProperty('accion')) { this.accion = JSON.parse(JSON.stringify(e.accion)) }
        if (e.hasOwnProperty('titulo')) { this.titulo = JSON.parse(JSON.stringify(e.titulo)) }
        if (e.hasOwnProperty('descripcion')) { this.descripcion = JSON.parse(JSON.stringify(e.descripcion)) }
        if (e.hasOwnProperty('nuevo')) { this.nuevo = JSON.parse(JSON.stringify(e.nuevo)) }
        if (e.hasOwnProperty('save')) { this.save = JSON.parse(JSON.stringify(e.save)) }
        if (e.hasOwnProperty('eliminar')) { this.eliminar = JSON.parse(JSON.stringify(e.eliminar)) }
        if (e.hasOwnProperty('anular')) { this.anular = JSON.parse(JSON.stringify(e.anular)) }
        if (e.hasOwnProperty('imprimir')) { this.imprimir = JSON.parse(JSON.stringify(e.imprimir)) }
        if (e.hasOwnProperty('vercuenta')) { this.vercuenta = JSON.parse(JSON.stringify(e.vercuenta)) }
        if (e.hasOwnProperty('stemporal')) { this.stemporal = JSON.parse(JSON.stringify(e.stemporal)) }
        if (e.hasOwnProperty('rtemporal')) { this.rtemporal = JSON.parse(JSON.stringify(e.rtemporal)) }
        if (e.hasOwnProperty('importarDato')) { this.importarDato = JSON.parse(JSON.stringify(e.importarDato)) } else { this.importarDato = false }


        if (e.hasOwnProperty('cargarProducto')) { this.cargarProducto = JSON.parse(JSON.stringify(e.cargarProducto)) } else { this.cargarProducto = false }
        if (e.hasOwnProperty('productoInv')) { this.productoInv = JSON.parse(JSON.stringify(e.productoInv)) } else { this.productoInv = false }

        if (e.hasOwnProperty('btnBarraDi')) {

          this.btnBarraDi = false

        } else { this.btnBarraDi = true }


        if (e.titulo == "Punto de ventas") {

          this.$store.commit('MutationActivarBarraAccion', false);

        } else {
          this.$store.commit('MutationActivarBarraAccion', true);

        }

        if (e.titulo == "Login") {

          this.$store.commit('MutationActivarBarraAccion', false);
          this.$store.commit('MutationbtnAppBar', false);
        } else {
          this.$store.commit('MutationActivarBarraAccion', true);
          this.$store.commit('MutationbtnAppBar', true);
        }



      }





    }



  }
};
</script>

<style scoped>
@media screen and (max-width: 800px) {

  #boton-mini {
    font-size: 0.45px;
  }



}
</style>