<template>
  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;" >{{ GetEjecucion }}
      <v-row  class="pa-0 ma-0 py-0 my-0" no-gutters > 
    <!-- col header 1   -->
    <v-col class="pa-0 ma-0 py-0 my-0 "  cols="12" md="4" sm="4" >
      <!------------------------------------------ Documentos descripcion-->
          <v-combobox class="pt-2 fontSize13" hide-details autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
            item-text="desop" :rules="[$rules.required]"
            v-model="headerFactura.documento" @blur="SelctCbDocumento" @focus="cargarDocumento"  
            v-on:blur="DataRetornInfHeaderFactura"  @change="changeDocumentoInfFact(headerFactura.documento)"
            id="formdisabledInput" :disabled="editando"
          >
                  <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.desop = `${item.item.operacion.modulo.trim()} - ${item.item.descripcion.trim()} ${item.item.codigo} ` }}</span>
                  <span class="fontSize13"> <b>{{ item.item.operacion.modulo.trim()}}</b> - {{item.item.descripcion.trim()}}</span>
                  </template>
                  
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"> <b>{{ item.operacion.modulo.trim()}}</b> - {{item.descripcion.trim()}}</span>
                    

                  </template>


            <template v-slot:prepend>
              <i style="font-size: 20px" class="fa fa-book"></i>
            </template>
          </v-combobox>
          <!------------------------------------------ fn Documentos -->
          <!------------------------------------------ Cliente -->
          <v-combobox ref="RefTextFieldBuscaCliente"  autocomplete="off" background-color="white" class="py-2 fontSize13" hide-details
                        color="grey darken-4" dense outlined label="Cliente:" :items="RCliente" item-text="codnom"
                        style="font-size: 13px;" v-model="headerFactura.cliente" @blur="SelctCbClienteBuscar"
                        @change="getChangeClienteBusqueda(headerFactura.cliente)" 
                        v-on:change="DataRetornInfHeaderFacturaChange('changeCliente')"
                        v-on:blur="DataRetornInfHeaderFactura"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" :rules="[$rules.required]"
                        id="formdisabledInput" :disabled="editando" no-filter>
                        <template v-slot:prepend>
                                <i style="font-size: 20px" class="fa fa-user-circle"></i>
                              </template>
                        <!-- margin-top="50px"style="font-size: 14px;" -->
                        <template slot="item" slot-scope="item">
                          <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                          <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>
                            style="border: 1px dashed  #616161;"
                              -->
                          <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0"
                            cols="12" md="12" sm="12">
                            <p class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                                {{ item.item.nombre }}</b></p>

                            <span class="pa-0 ma-0 py-0 my-0">
                              <span><b>TEL.: </b>
                                {{ selectTelefonoCliente(item.item) }}</span>
                              <span><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                            </span>
                            <p class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                              {{ item.item.direccion }} </p>
                            <span v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                                :{{ item.item.ars.nombre }}</b></span>
                            <span v-if="item.item.diascredito > 0"><b> TIPO:
                                Cliente
                                Credito</b></span>
                            <span v-if="item.item.diascredito == 0"><b>TIPO:
                                Cliente
                                Contado</b></span>
                          </v-col>


                        </template>
          </v-combobox> 
          <!------------------------------------------ fn Cliente --> 
          <p v-if="headerFactura.cliente==null"  style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Límite de Crédito.:</b>
            {{currencyFormatter(0)}}. <b> Plazo: </b>0 días. 
          </p>
          <p v-if="headerFactura.cliente!=null"  style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Límite de Crédito.:</b>
            {{currencyFormatter(headerFactura.cliente.limitecredito)}}. <b> Plazo: </b>{{headerFactura.cliente.diascredito}} días. 
          </p>

          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Cedula/RNC.........:</b> <span v-if="headerFactura.cliente!=null && headerFactura.cliente.hasOwnProperty('cedrnc')">{{headerFactura.cliente.cedrnc.trim()}}</span></p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Ciudad.................:</b> <span v-if="headerFactura.cliente!=null">{{headerFactura.cliente.ciudad}}</span></p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Teléfono..............:</b> {{selectTelefonoCliente(headerFactura.cliente) }}</p>

    </v-col>
    <!-- fn col header 1   --> 
   <!-- col header 2   --> 
    <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="4" sm="4" >
      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12"  >
        <v-row no-gutters>
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
            <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 px-2"><b> {{infFact.nombre}} </b></p>
            <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 px-2"><b> No: </b>{{infFact.secuencia}}</p>
          </v-col>

          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
            <v-combobox class="pt-2 fontSize13" autocomplete="off" dense outlined label="Centro de costo:" hide-details
                          :items="RtipoComprobante" :rules="[$rules.required]" item-text="descripcion"
                          v-model="headerFactura.centroCosto" @blur=""
                          @focus=""
                          v-on:blur="DataRetornInfHeaderFactura">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-contao"></i>
                          </template>
                        </v-combobox>
          </v-col>
        </v-row>
      </v-col>
        <!-- -----------------------------  Fecha Factura -->
        <!-- <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12" style="border: 1px solid #000000;"> </v-col> -->
                <v-text-field class="pt-2 fontSize13" dense outlined type="date" label="Fecha Factura:" hide-details
                          v-model="headerFactura.fecha"
                          v-on:blur="DataRetornInfHeaderFactura"
                          id="formdisabledInput" :disabled="!modificando">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                     
      <!-- ----------------------------- fn Fecha Factura -->

      <!-- ----------------------------- tipocomprobante v-on:blur="DataRetornInfHeaderFactura"-->
      <v-combobox class="pt-2 fontSize13" autocomplete="off" dense outlined label=" Tipos de Comprobante:" hide-details
                          :items="RtipoComprobante" :rules="[$rules.required]" item-text="descripcion"
                          v-model="headerFactura.tipocomprobante" @blur="SelctCbTipoComprobante"
                          v-on:change="DataRetornInfHeaderFacturaChange('changeComprobante')"
                          @focus="cargarTipoComprobante"
                          id="formdisabledInput" :disabled="editando">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>
        <!-- ----------------------------- fn tipocomprobante -->

<!-- ----------------------------- Lista de Precios -->
<v-combobox v-if="this.headerFactura.activarListasPrecios"  id="formdisabledInput" disabled autocomplete="off" dense outlined label="Lista de Precios:" class="py-2 fontSize13"  :items="Rlista" hide-details
                          item-text="descripcion" v-model="headerFactura.listaPrecio" @blur="SelctCblista" 
                          @focus="cargarlista"
                          v-on:blur="DataRetornInfHeaderFactura">
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                                <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                              </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-list-ol"></i>
                          </template>
                        </v-combobox>
<!-- ----------------------------- fn Lista de Precios -->


    </v-col>
    <!-- fn col header 2   -->

    <!-- col header 3   -->
    <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="4" sm="4">
      
      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12">
        <v-row no-gutters>
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
<!------------------------------- Sucursal -->
<v-combobox class="pt-2 fontSize13" hide-details   autocomplete="off" dense outlined
                        id="formdisabledInput" disabled
                        label="Sucursal:" :items="RSucursal" item-text="descripcion" :rules="[$rules.required]"
                        v-model="headerFactura.sucursal" @blur="SelctCbSucursal" @focus="CargarRSucursal"
                        v-on:blur="DataRetornInfHeaderFactura">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                        </template>
      </v-combobox>
      <!------------------------------- fn Sucursal -->
          </v-col>
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
            <v-text-field class="pt-2 fontSize13" hide-details  label="NCF" autocomplete="off" dense outlined v-model="headerFactura.ncf"
                        @blur="BlurCambioMayuscula"
                          style="font-size: 13px;"
                          id="formdisabledInput" :disabled="editando">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-barcode"></i>
                          </template>
            </v-text-field>
          </v-col>

        </v-row>
      </v-col>

      

      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12">
        <v-row no-gutters>
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
           <!------------------------------- Moneda -->
      <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion" class="pt-2 fontSize13" hide-details 
                  :rules="[$rules.required]" v-model="headerFactura.moneda"   @focus="cargarMoneda"
                  @blur="SelctCbMoneda"
                  v-on:blur="DataRetornInfHeaderFactura"
                  v-on:change="DataRetornInfHeaderFacturaChange('changeMoneda')"
                  id="formdisabledInput" :disabled="!modificando">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <!--{{ item.descripcion }} (  )-->
                    <span id="ImputColorDisable">{{ item.nombrecorto }}</span>
                  </template>
                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
      </v-combobox>
      <!------------------------------- fn Moneda -->
          </v-col>

          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
            <VueAutonumeric label="Descuento cliente(Info.)"  dense outlined hide-details
                          id="formdisabledInput" disabled
                          autocomplete="off" :rules="[$rules.Numericc]"
                          v-model.number="headerFactura.descuento" @blur=""
                          class="pt-2 fontSize13 " @focus=""
                          v-on:blur="DataRetornInfHeaderFactura">
                          <template v-slot:prepend>
                            <i v-if="headerFactura.ValorPorc == true" style="font-size: 15px;"
                              class="fa fa-usd"></i>
                            <i v-if="headerFactura.ValorPorc == false" style="font-size: 15px;"
                              class="fa fa-percent"></i>
                          </template>
             </VueAutonumeric>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12">
        <v-row no-gutters>
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
                <!-- -----------------------------  Tasa Compra -->
                <v-text-field hide-details class="pt-2 fontSize13" v-if="headerFactura.moneda != null" id="formdisabledInput" label="Tasa Compra:" dense outlined
                  v-model.number="headerFactura.moneda.tasa"
                  :disabled="!modificando"
                  v-on:blur="DataRetornInfHeaderFacturaChange('changeMoneda')">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
          <!------------------------------- vendedor -->
          <v-combobox class="pt-2 fontSize13" ref="RefTextFieldBuscaVendedor" autocomplete="off" background-color="white" hide-details
                      color="grey darken-4" dense outlined label="Vendedor:" :items="RVendedor" item-text="nombre"
                      style="font-size: 13px;" v-model="headerFactura.vendedor" @blur="SelctCbVendedorFactura"
                       @focus="cargarVendedorFactura" 
                      :rules="[$rules.required]"
                      v-on:blur="DataRetornInfHeaderFactura"
                      id="formdisabledInput" :disabled="!modificando">
                      <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-universal-access"></i> 
                      </template> 
                      <!-- margin-top="50px"style="font-size: 14px;" -->
                      <template slot="item" slot-scope="item">
                        <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                        <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0" cols="12"
                          md="12" sm="12">
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                              {{ item.item.nombre }}</b></p>

                          <span class="pa-0 ma-0 py-0 my-0">
                            <span style="border: 1px dashed  #616161;"><b>TEL.: </b>{{ item.item.telefono }} </span>
                            <span style="border: 1px dashed  #616161;"><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                          </span>
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                            {{ item.item.direccion }} </p>
                          <span style="border: 1px dashed  #616161;"
                            v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                              :{{ item.item.ars.nombre }}</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito > 0"><b> TIPO: Cliente
                              Credito</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito == 0"><b>TIPO: Cliente
                              Contado</b></span>
                        </v-col>

                      </template>

           </v-combobox>
          <!------------------------------- fn vendedor -->
         </v-col>
        </v-row>
      </v-col>

      <!-- ----------------------------- Dia de credito -->
          <v-text-field  label="Condición de pago" dense outlined class="pt-2 fontSize13" hide-details 
                          v-model="headerFactura.diascredito" @keyup="DiaCre"
                          v-on:blur="DataRetornInfHeaderFactura"
                          id="formdisabledInput" :disabled="!modificando">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>
      <!-- ----------------------------- fn Dia de credito -->

    </v-col>
    <!-- fn col header 3   -->
     </v-row>

      <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    </v-col> 
</template>
<script>
import numeral from "numeral";
import { EventBus } from "../../event-bus";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 
 
  },
  mounted() {
      this.cargaInicial()
  },
  beforeDestroy() {

  },

  data: () => ({ 
    Rlista:[],
    RtipoComprobante:[],
    RDocumento:[],
    RCliente:[],
    RSucursal:[],
    Rmoneda:[],
    RVendedor:[],
    windowSize: { width: "auto", height: "auto" },
    headerFactura:{
      fecha:fechaNueva(),
      listaPrecio:null,
      tipocomprobante:null,
      documento:null,
      cliente:null,
      sucursal:null,
      moneda:null,
      vendedor:null,
      diascredito:0,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false,
      ncf:''
    },
    
    infFact:{
      nombre:'**********',
      secuencia:0
    },
    aalert: {
      estado: false,
      color: null,
    },
   editando:false,
   modificando:false

  }),

  props: { 
cajaSelect: {
  required: false,
  type: Object,
  default: null
},
// salvarNuevo,editar,modificar:
modofactura: {
  required: false,
  type: String,
  default: "salvarNuevo"
}

},


  methods: { 
    fechaNueva,currencyFormatter,
    
  cargaInicial(){
    if(this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios!=null && this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios==true){
      this.headerFactura.activarListasPrecios=this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios
      var CONFIGPV=this.$store.getters.GetConfiguracionGeneral.configPv
      if(CONFIGPV.hasOwnProperty('listaPrecio') && CONFIGPV.listaPrecio!=null){
       this.headerFactura.listaPrecio = CONFIGPV.listaPrecio
      }
    }
  this.headerFactura. fecha=this.fechaNueva()
   this.headerFactura.diascredito=0
   this.headerFactura.cliente=null 
   this.headerFactura.vendedor=null 
   this.headerFactura.moneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase
   this.headerFactura.tipocomprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
   this.DataRetornInfHeaderFactura()
    } ,
  cargarMonedaXdefecto(){
    this.headerFactura.moneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase
    var OtrasConfPv = this.$store.getters.GetOtrasConfigPv;
      OtrasConfPv.moneda = this.headerFactura.moneda.descripcion.trim();
    this.DataRetornInfHeaderFactura()
    },

     cargarComprobanteXdefecto(){
    this.headerFactura.tipocomprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
    this.DataRetornInfHeaderFactura()
    },
  cargarlista() {
    this.$axios.get(this.$hostname + this.$hName + "/listasprecios/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,})
        .then((res) => (this.Rlista = res.data));
  },

  SelctCblista() {
    var _this = this;
    setTimeout(function () {
  if (_this.headerFactura.listaPrecio == null) {
    _this.headerFactura.listaPrecio = "";
  }

  if (!_this.headerFactura.listaPrecio.hasOwnProperty('id')) {
    _this.headerFactura.listaPrecio = "";
    _this.headerFactura.listaPrecio = null;
    _this.cargarSelectlistaPrecioXdefecto()
    _this.alerta("Seleccione una lista de precio", "error");
    return;
  }
}, 300);

  },

  cargarSelectlistaPrecioXdefecto(){
if(this.headerFactura.activarListasPrecios==true){
    if(this.headerFactura.cliente!=null && this.headerFactura.cliente.listaPrecio!=null){
      this.headerFactura.listaPrecio=JSON.parse(JSON.stringify(this.headerFactura.cliente.listaPrecio))
    }else{
      var CONFIGPV=this.$store.getters.GetConfiguracionGeneral.configPv
      if(CONFIGPV.hasOwnProperty('listaPrecio') && CONFIGPV.listaPrecio!=null){
       this.headerFactura.listaPrecio = CONFIGPV.listaPrecio
      }
    }
  }

  },
    
  cargarTipoComprobante() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tiponcf/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RtipoComprobante = res.data));
  },


  SelctCbTipoComprobante() {
      var _this = this;
    setTimeout(function () {
  if (_this.headerFactura.tipocomprobante == null) {
    _this.headerFactura.tipocomprobante = "";
  }

  if (!_this.headerFactura.tipocomprobante.hasOwnProperty('codigo')) {
    _this.headerFactura.tipocomprobante = "";
    _this.headerFactura.tipocomprobante = null;
    _this.cargarComprobanteXdefecto()
    // _this.alerta("Seleccione un tipo de comprobante", "error");
  }
  _this.DataRetornInfHeaderFactura()
  //  _this.DataRetornInfHeaderFacturaChange('changeComprobante')
  return;
}, 300);
  },

 cargarDocumento(){
var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/documentoventas/findallActivos/",{headers:headers})
        .then(res => {
          //this.RDocumento = res.data
        var LISTDO=res.data.filter((ee) => ee.operacion.codigo==27 || ee.operacion.codigo==37);
     this.$axios.get(this.$hostname +this.$hName +"/documento-inv/conduce",
      { headers: this.$store.getters.GetheadersAxios })
        .then(res2 => {
          this.RDocumento = LISTDO.concat(res2.data)
        });   
        })
        .catch(error => console.log(error));
      },



SelctCbDocumento(){

  var _this = this;
    setTimeout(function () {
  if (_this.headerFactura.documento == null) {
    _this.headerFactura.documento = "";
  }

  if (!_this.headerFactura.documento.hasOwnProperty('codigo')) {
    _this.headerFactura.documento = "";
    _this.headerFactura.documento = null;
    _this.alerta("Seleccione un documento", "error");
    return;
  }
}, 300);

},


 // Un método que recibe un cliente y verifica en una lista si tiene telefono .
selectTelefonoCliente(e) {

      if ( e!=null && typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return ' ' }

},

async getChangeClienteBusqueda(e) {
if ( typeof e === 'object' && e != null) {

  if (e.statuscliente != null) {
    if (e.statuscliente == 2 || e.statuscliente == 3) {
      this.headerFactura.cliente = null
      this.alerta("Este cliente esta suspendido o cancelado, no puede Seleccinarlo", "error");
      return
    }
  }

  var obj = JSON.parse(JSON.stringify(e))
  obj.telefono = this.selectTelefonoCliente(e)
  if (e.cedrnc != null) { obj.cedrnc = e.cedrnc.trim() } else { obj.cedrnc = '' }

  if (e.diascredito != null && e.diascredito >0 ) { this.headerFactura.diascredito = e.diascredito} else { this.headerFactura.diascredito = 0 }
  if (e.vendedor != null) { 
    this.headerFactura.vendedor =  await this.findIdvendedor(e.vendedor );
  } else {this.headerFactura.vendedor =null }



  if (e.tipocomprobante != null) { this.headerFactura.tipocomprobante = e.tipocomprobante } else {this.headerFactura.tipocomprobante =null }
 
  var DESCUENTOVALOR=0
   var DESCUENTO=0
if(e.descuentovalor != null){DESCUENTOVALOR=e.descuentovalor}
if(e.descuento != null){DESCUENTO=e.descuento}

  if (DESCUENTOVALOR>0) { 
    this.headerFactura.descuento = DESCUENTOVALOR
    this.headerFactura.ValorPorc = true
  } 

  if (DESCUENTO>0) { 
    this.headerFactura.descuento = DESCUENTO
    this.headerFactura.ValorPorc = false
  } 


  if(DESCUENTOVALOR==0 && DESCUENTO==0){
    this.headerFactura.descuento = 0
    this.headerFactura.ValorPorc = false
  }
  // else{
  //   this.DataRetornInfHeaderFacturaChange('changeCliente')
  // }
  
  

}else{
  this.headerFactura.diascredito = 0
  this.headerFactura.vendedor =null
  this.headerFactura.tipocomprobante=null
  this.headerFactura.descuento = 0
    this.headerFactura.ValorPorc = false
}
},


cargarClienteBusqueda() {
  var filtroTipoCliente=3
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + filtroTipoCliente + "/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
},

CbFilcliente(e) {
      var filtroTipoCliente=3
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/" + filtroTipoCliente + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
},

SelctCbClienteBuscar() {
      var _this = this;
      setTimeout(function () {
        if (_this.headerFactura.cliente== null) {
          _this.headerFactura.cliente= "";
        }

        if (!_this.headerFactura.cliente.hasOwnProperty('codigo')) {
          _this.headerFactura.cliente= "";
          _this.headerFactura.cliente= null;
          //  _this.alerta("Seleccione un cliente", "error");
        }
        _this.cargarSelectlistaPrecioXdefecto()
        // _this.DataRetornInfHeaderFacturaChange('changeCliente')
        return;

      }, 300);
},

DataRetornInfHeaderFacturaChange(nombreChange){
  var _this=this;
  setTimeout(function(){ 

    if(_this.headerFactura.moneda==null){
      _this.headerFactura.moneda = _this.$store.getters.GetConfiguracionGeneral.config.monedabase
    }

    var OBJ={
    nombreChange:nombreChange,
    headerFactura:_this.headerFactura
  }
   _this.DataRetornInfHeaderFactura()
  _this.$emit("DataRetornInfHeaderFacturaChange", OBJ)
  }, 300);

},

CargarRSucursal() {

this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
  this.RSucursal = JSON.parse(JSON.stringify(res.data));
});

},
SelctCbSucursal() {
var _this = this;
setTimeout(function () {
  if (_this.headerFactura.sucursal == null) {
    _this.headerFactura.sucursal = "";
  }

  if (!_this.headerFactura.sucursal.hasOwnProperty('codigo')) {
    _this.headerFactura.sucursal = "";
    _this.headerFactura.sucursal = null;
    return;
  }
}, 300);
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
},
   
SelctCbMoneda() {
var _this = this;
setTimeout(function () {
  if (_this.headerFactura.moneda == null) {
    _this.headerFactura.moneda = "";
  }

  if (!_this.headerFactura.moneda.hasOwnProperty('codigo')) {
    _this.headerFactura.moneda = "";
    _this.headerFactura.moneda = null;
    _this.cargarMonedaXdefecto()
    return;
  }
}, 300);
},

cargarVendedorFactura() {
      this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findallactiv", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RVendedor = res.data));
},

SelctCbVendedorFactura() {
var _this = this;
setTimeout(function () {
  if (_this.headerFactura.vendedor == null) {
    _this.headerFactura.vendedor = "";
  }

  if (!_this.headerFactura.vendedor.hasOwnProperty('codigo')) {
    _this.headerFactura.vendedor = "";
    _this.headerFactura.vendedor = null;
    return;
  }
}, 300);

},

DiaCre(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.headerFactura.diascredito = mynumeral(
          this.headerFactura.diascredito
        ).value();
        if (this.headerFactura.diascredito == null) {
          this.headerFactura.diascredito = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
},


async findIdvendedor(codigo){
      return  new Promise((resolve, reject) => {
      var VARLINK=`vendedores/find/${codigo}`
        this.$axios.get(this.$hostname + this.$hName + '/' + VARLINK, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          if(res.data.hasOwnProperty('codigo')){
            resolve(res.data)
          } else{
            resolve(null)
          }  
        })
        .catch(error => {
            resolve(null)
          });  

        });
},

DataRetornInfHeaderFactura(){
  var _this=this;
  setTimeout(function(){ 
    if(_this.headerFactura.moneda==null){
      _this.headerFactura.moneda = _this.$store.getters.GetConfiguracionGeneral.config.monedabase
    }
    _this.$emit("DataRetornInfHeaderFactura", _this.headerFactura)
  }, 300);

},

 // Convirtiendo la cadena a mayúsculas.
 BlurCambioMayuscula(){

if(this.headerFactura.ncf.length>0){
  this.headerFactura.ncf=JSON.parse(JSON.stringify(this.headerFactura.ncf.toUpperCase()))  
}

},

getInformacionFactura(e){
// e.documento.
this.infFact.secuencia=e.secuencia

if(e.documento!=null && e.documento.operacion.codigo==27){
  this.infFact.nombre='FACTURA'
  }
if(e.documento!=null && e.documento.operacion.codigo==35){
  this.infFact.nombre='CONDUCE'
  }

  if(e.documento!=null && e.documento.operacion.codigo==37){
  this.infFact.nombre='PEDIDO'
  }

  /*this.infFact={
      nombre:'',
      secuencia:0
    },*/

},

changeDocumentoInfFact(e){
  var _this=this;
  setTimeout(function(){
  if(e!=null && e.hasOwnProperty('codigo')){
    var OBJ={
        documento:e.codigo,
        operacion:e.operacion.codigo
      }
    _this.GetUltimaSecuenciaFactura(OBJ)
  if(e.operacion.codigo==27){
  _this.infFact.nombre='FACTURA'
  }
if(e.operacion.codigo==35){
  _this.infFact.nombre='CONDUCE'
  }
  if(e.operacion.codigo==37){
  _this.infFact.nombre='PEDIDO'
  }
  }else{
    _this.infFact. nombre='**********'
    _this.infFact.secuencia=0
  }
  
  }, 300);

},


  // Usca el ultimi secuencia factura
  async GetUltimaSecuenciaFactura(e){
    // var _this = this;
if(e.operacion==27){
      this.$axios.get(this.$hostname +this.$hName +"/pv/ultimoSecuenciaFactura/"+
          e.operacion +"/"+e.documento,
          { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.infFact.secuencia=res.data
          /*console.log('---GetUltimaSecuenciaFactura---')
          console.log(res.data)*/
         /* this.CierresCajas.cierrescajasPK.secuencia = res.data;
          this.CierresCajas.cierrescajasPK.caja= this.cajaSelect.codigo
          setTimeout(function(){ 
            _this.updateConfiguracion()
            _this.$emit("DataRetornCierre", _this.CierresCajas)
            _this.cargarDenominacion(res.data)
          }, 300);*/
        });
}

if(e.operacion==35){ 
  this.$axios.get(this.$hostname +this.$hName +"/conduce/ultimoSecuenciaConduce/"+
          e.operacion +"/"+e.documento,
          { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.infFact.secuencia=res.data
        });
}

if(e.operacion==37){
      this.$axios.get(this.$hostname +this.$hName +"/pedidosfacturacion/ultimoSecuenciaPedido/",
          { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.infFact.secuencia=res.data
        });
}



      
  },

alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        // this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
},


  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      if(this.cajaSelect!=null && this.cajaSelect.hasOwnProperty('codigo')){
        this.headerFactura.sucursal= this.cajaSelect.sucursal
      }

      if(this.headerFactura.tipocomprobante==null){
        this.cargarComprobanteXdefecto()
        // this.headerFactura.tipocomprobante
      }

    var CLIENTE=this.headerFactura.cliente
var _this=this;
setTimeout(function(){ 
  if(_this.headerFactura.cliente!=null && _this.headerFactura.cliente.hasOwnProperty('codigo') && !_this.headerFactura.cliente.hasOwnProperty('codnom') ){
    CLIENTE.codnom=`${_this.headerFactura.cliente.codigo} - ${_this.headerFactura.cliente.nombre.trim()}` 
    _this.headerFactura.cliente=JSON.parse(JSON.stringify(CLIENTE))
  }
}, 300);

      // salvarNuevo,editar,modificar:
   
  if(this.modofactura==='salvarNuevo'){
    this.editando=false
    this.modificando=true
   }
   if(this.modofactura==='editar'){
    this.editando=true
    this.modificando=false
   }

   if(this.modofactura==='modificar'){
    this.editando=true
    this.modificando=true
   }



     },

},
};
</script>
<style lang="scss">
.colorNombre {
  color: #000000;
}
@media screen and (max-width: 850px) {

  #boton-mini2 {
    font-size: 0.45px;
  }

}

@media screen and (max-width: 900px) {

  #boton-mini3 {
    font-size: 0.45px;
  }

}


</style>