<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
 <!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ver Pedido
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>ver Pedido: </span> Ver
    Pedido</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>
    
    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click=" cancelarPedidoAfactura()"
        color="orange" small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

</v-card-actions>
</v-toolbar>
<!-- v-toolbar  accion -->
<v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="PedidoAfacturaBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="PedidoAfacturaBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="PedidoAfacturaBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta"  @blur="SelctCbPedidoAfacturaBuscar"-------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" autocomplete="off" ref="RefCombClienteCosFac" dense outlined label="cliente:"
                        :items="RCliente" item-text="codnom" style="font-size: 13px;" v-model="PedidoAfacturaBusqueda.cliente"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter>

                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getPedidoAfacturalistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>
<!-- @click:row="VerPedidoAfacturaDatostabla" -->
            <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0">
              <v-data-table class="RowSelectColor" item-key="indx"  :headers="[
                { text: 'Fecha', sortable: false, value: 'nuevaFecha', width: 120, class: ['no-gutters black--text']  },
                { text: '# Secuencia', sortable: false, value: 'idpedido', width: 129, class: ['no-gutters black--text']  },
                { text: 'Cliente', sortable: false, value: 'cliente', width: 129, class: ['no-gutters black--text']  },
                { text: 'Valor', sortable: false, value: 'valorpedido', width: 'auto', width: 105, class: ['no-gutters black--text']  },
                { text: 'Acción',align:'center' ,sortable: false, value: 'action', width: '110', class: ['no-gutters black--text']  },
              ]" dense :items="VerPedidoAfacturalistFactCliente" :footer-props="arrayPropiedases"
                @pagination="PedidoAfacturaCargarPaginacion" :items-per-page="PedidoAfacturaPaginator.itemsPerPage"
                :server-items-length="TotalRegistros">
                <template v-slot:item.nuevaFecha="props">

                  <span style="font-size:13px; display:none;">{{ props.item.indx =
                  VerPedidoAfacturalistFactCliente.indexOf(props.item)
                  }}</span>
                  <span style="font-size:13px;">{{ FormatoFecha(props.item.nuevaFecha) }}</span>
                </template>

                <template v-slot:item.idpedido="props">
                  <span style="font-size:13px;">{{ props.item.idpedido }} </span>
                </template>

                <template v-slot:item.cliente="props">
                  <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre}} </span>
                </template>

                <template v-slot:item.valorpedido="props">
                  <span style="font-size:13px;">{{ currencyFormatter(props.item.valorpedido) }}</span>
                </template>
                <template v-slot:item.action="{ item }">
    <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->
<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn  v-on="on" fab @click.prevent="VerPedidoAfacturaDatostabla(item)" x-small color="green"><i style="font-size: 20px;" class="fa fa-check"></i></v-btn> 

</template>
<i style="font-size: 20px;" dark dense class="fa fa-eye"><span> Pedido a Factura.</span>
</i>   
</v-tooltip>

<v-tooltip top >
<template v-slot:activator="{ on }">
<v-btn  v-on="on" fab @click.prevent="editarPedidoFactura(item)" x-small color="orange"><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 

</template>
<i style="font-size: 20px;" dark dense class="fa fa-pencil"><span> Editar registro.</span>
</i>   
</v-tooltip>

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn  v-on="on" fab @click.prevent="ImprimirPedidoFactura(item)" x-small color="blue"><i style="font-size: 20px;" class="fa fa-print"></i></v-btn> 

</template>
<i style="font-size: 20px;" dark dense class="fa fa-trash-o"><span> Imprimir registro.</span>
</i>   
</v-tooltip>

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn  v-on="on" fab @click.prevent="cerrarPedidoAfactura(item)" x-small color="red lighten-1"><i style="font-size: 20px;" class="fa fa-window-close-o"></i></v-btn> 

</template>
<i style="font-size: 20px;" dark dense class="fa fa-eye"><span> Cerrar pedido.</span>
</i>   
</v-tooltip>

<!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
  
</template> 
              </v-data-table>
            </v-col>

          </v-card>


        </v-col>
<!-- Modal Alerta -->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
          :timeout="120000" top="top">
          {{ aalert.nombre }}

          <v-btn dark text @click="aalert.estado = false">Close</v-btn>
        </v-snackbar>
</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    filtroTipoCliente: 3,
    VerPedidoAfacturalistFactCliente: [],
    RCliente:[],
    PedidoAfacturaBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },
    TotalRegistros: 1,
    PedidoAfacturaPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    aalert: {
        estado: false,
        color: null
      },

  }),
  props: {
    cajaSelect: {
      required: false,
      type: Object,
      default: null
    },

    configuraciones: {
      required: false,
      type: Object,
      default: null
    },
    
  },
  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha,
     
    // Hacer una llamada API al servidor y devolver los datos de la cotizacion.
    getPedidoAfacturalistFactCliente() {
      /*console.log('Viendo Informacion klk ')
      console.log(this.$refs.RefCombClienteCosFac.internalSearch)*/
      //  this.RegistroActividades.descripcion= JSON.parse(JSON.stringify(this.$refs.RefCombAct.internalSearch)) 
      var _this = this;
      setTimeout(function () {
        _this.VerPedidoAfacturalistFactCliente = [];
        var sec,
          fecini,
          fecfin,
          cliente = null;
          var sucursal=_this.cajaSelect.sucursal.codigo;
        if (_this.PedidoAfacturaBusqueda.secuencia != null) {
          sec = _this.PedidoAfacturaBusqueda.secuencia;
        }
        if (_this.PedidoAfacturaBusqueda.fechaInicial != null) {
          fecini = _this.PedidoAfacturaBusqueda.fechaInicial;
        }
        if (_this.PedidoAfacturaBusqueda.fechaFinal != null) {
          fecfin = _this.PedidoAfacturaBusqueda.fechaFinal;
        }
        if (_this.PedidoAfacturaBusqueda.cliente != null) {
          cliente = _this.$refs.RefCombClienteCosFac.internalSearch;
          // cliente = this.PedidoAfacturaBusqueda.cliente;
        }

        _this.$axios
          .get(
            _this.$hostname +
            _this.$hName +
            "/pedidosfacturacion/findPedido/" +
            sec +
            "/" +
            fecini +
            "/" +
            fecfin +
            "/" +
            cliente +
            "/false" +
            "/" +
            sucursal+
            "/" +
            _this.PedidoAfacturaPaginator.itemsPerPage +
            "/" +
            (_this.PedidoAfacturaPaginator.pageStart),
            { headers: _this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            //*this.TotalRegistros = res.data.cantidad,
            _this.VerPedidoAfacturalistFactCliente = res.data.listas;
            _this.TotalRegistros = res.data.cantidad
          });

      }, 500);

    },

    // Establecer el valor predeterminado del paginador en 15.
    PedidoAfacturaCargarPaginacion(e) {
      this.PedidoAfacturaPaginator = e;
      if (this.PedidoAfacturaPaginator.itemsPerPage == 1) {
        this.PedidoAfacturaPaginator.itemsPerPage = 15;
      }
      this.getPedidoAfacturalistFactCliente();
    },

    
    // Creando un nuevo objeto y asignándolo a la variable PedidoAfacturaBusqueda.
     NewBucarPedidoAfactura() {
      this.PedidoAfacturaBusqueda = {
        secuencia: 0,
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
        cliente: null,
      };
    },

    cancelarPedidoAfactura() {
      // this.dialogPedidoAfactura = false;
      this.NewBucarPedidoAfactura();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarPedidoAfactura", false)
     }, 300);
    },

    // Llamar a una API y obtener los datos del cliente.
    cargarClienteBusqueda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + this.filtroTipoCliente + "/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/" + this.filtroTipoCliente + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },

    async findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},

     
    // Obtener los datos del servidor y agregarlos a la tabla.
  async VerPedidoAfacturaDatostabla(e) {
      var OBJ = {
    headerFactura:{
      // fecha:getParamFecha(e.fecha).split("T")[0],
      // fecha:fechaNueva(),
      fecha:e.nuevaFecha,
      listaPrecio:null,
      tipocomprobante:null,
      documento:null,
      cliente:null,
      sucursal:null,
      moneda:null,
      vendedor:null,
      diascredito:0,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false
    },
  }

         if (e.hasOwnProperty('cliente')&&e.cliente != null) {
        OBJ.headerFactura.cliente = JSON.parse(JSON.stringify(e.cliente))
        OBJ.headerFactura.cliente.codnom=`${e.cliente.codigo} - ${e.cliente.nombre.trim()}` 
        }

        if (e.hasOwnProperty('tipocomprobante')&&e.tipocomprobante != null) {
        OBJ.headerFactura.tipocomprobante = JSON.parse(JSON.stringify(e.tipocomprobante))
        }else{
          OBJ.headerFactura.tipocomprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
        }

        OBJ.headerFactura.moneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase
        OBJ.pedidoFactura=JSON.parse(JSON.stringify(e))
        if(!e.hasOwnProperty('comentario')){OBJ.pedidoFactura.comentario=''}
        if(e.pedidosfacturacionDetalleList.length > 0){
          var listDetalle = JSON.parse(JSON.stringify(e.pedidosfacturacionDetalleList))
          listDetalle.sort(function (a, b) {
            if (a.id > b.id) { return 1; }
            if (a.id < b.id) {return -1; }
            // a must be equal to b
            return 0;
          });

          (async () => {  
            const restrList =  await this.ContPedidoAfacturaDatostabla(listDetalle,OBJ);

          })()



        }
 
      
    },

    async ContPedidoAfacturaDatostabla(listDetalle,data){  
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var ObjProRow = listDetalle.filter((ee) => (ee.cantpedida-ee.cantfacturada) >0);

      OBJ.pedidoFactura.pedidosfacturacionDetalleList=JSON.parse(JSON.stringify(ObjProRow))
      var bar = new Promise((resolve, reject) => {
        listDetalle.filter(
        (ee) => (ee.cantpedida-ee.cantfacturada) >0).forEach(async (element) => {
           (async () => { 
            /*producto:
            unidad:*/
            // const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            // if(resUniPro!=null){}
                //  var Obj =JSON.parse(JSON.stringify(resUniPro))
                var Obj ={}
                Obj.unidadesProductosPK={
                  producto:element.producto.codigo,
                  unidad:element.unidad.codigo
                }
                Obj.descripcion=`${element.producto.descripcion.trim()} ${element.unidad.descripcion.trim()}` 
                Obj.cantidad = (element.cantpedida-element.cantfacturada)
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.pordescuento
                Obj.descuento = element.pordescuento
                Obj.ValorPorc = false
                // Obj.precio = element.precio
                Obj.precio = element.precio
                Obj.precioOrig = element.precio
                Obj.precioDig = element.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                Obj.productos=JSON.parse(JSON.stringify(element.producto))
                Obj.unidades=JSON.parse(JSON.stringify(element.unidad))
                ListDetalleTemp.push(Obj)
             
            })()
            }); 
            resolve()
      });

      bar.then(() => {
        OBJ.listDetalle=ListDetalleTemp
        this.cancelarPedidoAfactura()
        OBJ.opcion='convertirPedidoAfactura'
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornPedidoAfactura", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },
 GetCodigoProdUni(unidaproducto) {
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
        if(unidaproducto.lote!==""){
          // console.log(unidaproducto.lote.substring(10))
          this.lote = parseInt(unidaproducto.lote.substring(10)); 
        }
        return unidaproducto.lote;
      } else {
        var pk = unidaproducto.unidadesProductosPK;
        var codigoP = ('000000' + pk.producto).substring(('000000' + pk.producto).length - 6, ('000000' + pk.producto).length)
        var codigoU = ('0000' + pk.unidad).substring(('0000' + pk.unidad).length - 4, ('0000' + pk.unidad).length)
        return codigoP + codigoU;
      }
    },
  

      // Obtener los datos del servidor y agregarlos a la tabla.
  async editarPedidoFactura(e) {
      var OBJ = {
    headerFactura:{
      //  fecha:getParamFecha(e.fecha).split("T")[0],
       fecha:e.nuevaFecha,
      listaPrecio:null,
      tipocomprobante:null,
      documento:e.documento,
      cliente:null,
      sucursal:null,
      moneda:null,
      vendedor:null,
      diascredito:0,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false
    },
  }

         if (e.hasOwnProperty('cliente')&&e.cliente != null) {
        OBJ.headerFactura.cliente = JSON.parse(JSON.stringify(e.cliente))
        OBJ.headerFactura.cliente.codnom=`${e.cliente.codigo} - ${e.cliente.nombre.trim()}` 
        }

        if (e.hasOwnProperty('tipocomprobante')&&e.tipocomprobante != null) {
        OBJ.headerFactura.tipocomprobante = JSON.parse(JSON.stringify(e.tipocomprobante))
        }else{
          OBJ.headerFactura.tipocomprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
        }

        OBJ.headerFactura.moneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase
        OBJ.pedidoFactura=JSON.parse(JSON.stringify(e))
        if(!e.hasOwnProperty('comentario')){OBJ.pedidoFactura.comentario=''}
        if(e.pedidosfacturacionDetalleList.length > 0){
          var listDetalle = JSON.parse(JSON.stringify(e.pedidosfacturacionDetalleList))
          listDetalle.sort(function (a, b) {
            if (a.id > b.id) { return 1; }
            if (a.id < b.id) {return -1; }
            // a must be equal to b
            return 0;
          });

          (async () => {  
            const restrList =  await this.ContEditarPedidoFacturaDatostabla(listDetalle,OBJ);

          })()



        }
 
      
    },

    async ContEditarPedidoFacturaDatostabla(listDetalle,data){  
     return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      // var ObjProRow = listDetalle.filter((ee) => (ee.cantpedida-ee.cantfacturada) >0);
      var ObjProRow = listDetalle.filter((ee) => (ee.cantfacturada) ==0);
      OBJ.pedidoFactura.pedidosfacturacionDetalleList=JSON.parse(JSON.stringify(ObjProRow))
      var bar = new Promise((resolve, reject) => {
        listDetalle.filter(
        (ee) => (ee.cantfacturada)==0).forEach(async (element) => {
           (async () => { 
            /*producto:
            unidad:*/
            // const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            // if(resUniPro!=null){}
                //  var Obj =JSON.parse(JSON.stringify(resUniPro))
                var Obj ={}
                Obj.unidadesProductosPK={
                  producto:element.producto.codigo,
                  unidad:element.unidad.codigo
                }
                Obj.descripcion=`${element.producto.descripcion.trim()} ${element.unidad.descripcion.trim()}` 
                Obj.cantidad = (element.cantpedida-element.cantfacturada)
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.pordescuento
                Obj.descuento = element.pordescuento
                Obj.ValorPorc = false
                // Obj.precio = element.precio
                Obj.precio = element.precio
                Obj.precioOrig = element.precio
                Obj.precioDig = element.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                Obj.productos=JSON.parse(JSON.stringify(element.producto))
                Obj.unidades=JSON.parse(JSON.stringify(element.unidad))
                ListDetalleTemp.push(Obj)
             
            })()
            }); 
            resolve()
      });

      bar.then(() => {
        OBJ.listDetalle=ListDetalleTemp
        this.cancelarPedidoAfactura()
        OBJ.opcion='EditarPedidoAfactura'
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornPedidoAfactura", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },


    async ImprimirPedidoFactura(e) {

      if(this.cajaSelect.formatoimpresioncontado.trim().includes('medPag')){
        if (localStorage.printerSelect != null) {
                      var msg = {
                        accion: "print",
                        formato: this.cajaSelect.formatoimpresioncontado.trim(),
                        impresora: JSON.parse(localStorage.printerSelect).nombre,
                        data: this.configuraciones,
                        obj: e,
                        caja: this.cajaSelect,
                      };
                      this.imprimir(msg, "pedidofactura");
                    }
      }else{

        EventBus.$emit("loading", true);
      var headers = this.$store.getters.GetheadersAxios

this.$axios.get(this.$hostname + this.$hName + "/pedidosfacturacion/imprimir/"+e.idpedido, { headers: headers }).then(res => {
  EventBus.$emit("loading", false)
  /*let pdfWindow = window.open("Reporte de imprecion")
  pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>")*/
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
    EventBus.$emit("openPDF",PDF ,'pedidos facturacion');
})
.catch((error) => {
          this.alerta("Reporte no generado", "error");
          console.log(error)
          EventBus.$emit("loading", false);
        });

      }
      
    },




   async cerrarPedidoAfactura(e) {
EventBus.$emit("loading", true);
    this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/pedidosfacturacion/cerrar/" + e.idpedido +"/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.alerta(res.data.mensage, res.data.estado);
        })
        .catch((error) => {
          this.alerta(error, "error");
          EventBus.$emit("loading", false);
        });
    },


    imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
          })
          .catch((error) => console.error(error));
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            // this.limpiarTodoCompleto();
          })
          .catch((error) => console.error(error));
      }
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
          this.getPedidoAfacturalistFactCliente()
        //  this.NewCliente();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);


      }
    },





    async ContPedidoAfacturaDatostabla2(listCotizDetalle,data){  
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var bar = new Promise((resolve, reject) => {
        listCotizDetalle.forEach(async (element) => {
           (async () => { 
            const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            if(resUniPro!=null){
                var Obj =JSON.parse(JSON.stringify(resUniPro))
                Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}` 
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false
                // Obj.precio = element.precio
                Obj.precio = resUniPro.precio
                Obj.precioOrig = resUniPro.precio
                Obj.precioDig = resUniPro.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                ListDetalleTemp.push(Obj)
            } 
            })()
            }); 
            resolve()
      });

      bar.then(() => {
        OBJ.listCotizDetalle=ListDetalleTemp
        this.cancelarPedidoAfactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornPedidoAfactura", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },


   
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>