<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
 <!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ver Cotización
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>ver Cotización: </span> Ver
    Cotización</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>

    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click=" cancelarCotizacionAfactura()"
        color="orange" small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

</v-card-actions>
</v-toolbar>
<!-- v-toolbar  accion -->
<v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="CotizacionBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="CotizacionBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="CotizacionBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta"  @blur="SelctCbCotizacionBuscar"-------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" autocomplete="off" ref="RefCombClienteCosFac" dense outlined label="cliente:"
                        :items="RCliente" item-text="codnom" style="font-size: 13px;" v-model="CotizacionBusqueda.cliente"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter>
                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getCotizacionlistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0">
              <v-data-table class="RowSelectColor" item-key="indx" @click:row="VerCotizacionDatostabla" :headers="[
                { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                { text: '# Secuencia', sortable: false, value: 'secuencia', width: 129 },
                { text: 'Cliente', sortable: false, value: 'nombrecliente', width: 129 },
                { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
              ]" dense :items="VerCotizacionlistFactCliente" :footer-props="arrayPropiedases"
                @pagination="CotizacionCargarPaginacion" :items-per-page="CotizacionPaginator.itemsPerPage"
                :server-items-length="TotalRegistros">
                <template v-slot:item.fecha="props">

                  <span style="font-size:13px; display:none;">{{ props.item.indx =
                  VerCotizacionlistFactCliente.indexOf(props.item)
                  }}</span>
                  <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                </template>

                <template v-slot:item.secuencia="props">
                  <span style="font-size:13px;">{{ props.item.secuencia }} </span>
                </template>

                <template v-slot:item.clinica.nombre="props">
                  <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.nombrecliente }} </span>
                </template>

                <template v-slot:item.valor="props">
                  <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                </template>
              </v-data-table>
            </v-col>

          </v-card>


        </v-col>

</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    filtroTipoCliente: 3,
    VerCotizacionlistFactCliente: [],
    RCliente:[],
    CotizacionBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },
    TotalRegistros: 1,
    CotizacionPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },

  }),

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha,
     
    // Hacer una llamada API al servidor y devolver los datos de la cotizacion.
    getCotizacionlistFactCliente() {
      /*console.log('Viendo Informacion klk ')
      console.log(this.$refs.RefCombClienteCosFac.internalSearch)*/
      //  this.RegistroActividades.descripcion= JSON.parse(JSON.stringify(this.$refs.RefCombAct.internalSearch)) 
      var _this = this;
      setTimeout(function () {
        _this.VerCotizacionlistFactCliente = [];
        var sec,
          fecini,
          fecfin,
          cliente = null;
        if (_this.CotizacionBusqueda.secuencia != null) {
          sec = _this.CotizacionBusqueda.secuencia;
        }
        if (_this.CotizacionBusqueda.fechaInicial != null) {
          fecini = _this.CotizacionBusqueda.fechaInicial;
        }
        if (_this.CotizacionBusqueda.fechaFinal != null) {
          fecfin = _this.CotizacionBusqueda.fechaFinal;
        }
        if (_this.CotizacionBusqueda.cliente != null) {
          cliente = _this.$refs.RefCombClienteCosFac.internalSearch;
          // cliente = this.CotizacionBusqueda.cliente;
        }

        _this.$axios
          .get(
            _this.$hostname +
            _this.$hName +
            "/reimpresion/findCotizacionpv/" +
            sec +
            "/" +
            fecini +
            "/" +
            fecfin +
            "/" +
            cliente +
            "/false" +
            "/" +
            _this.CotizacionPaginator.itemsPerPage +
            "/" +
            (_this.CotizacionPaginator.pageStart),
            { headers: _this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            //*this.TotalRegistros = res.data.cantidad,
            _this.VerCotizacionlistFactCliente = res.data.listas;
            _this.TotalRegistros = res.data.cantidad
          });

      }, 500);

    },

    // Establecer el valor predeterminado del paginador en 15.
    CotizacionCargarPaginacion(e) {
      this.CotizacionPaginator = e;
      if (this.CotizacionPaginator.itemsPerPage == 1) {
        this.CotizacionPaginator.itemsPerPage = 15;
      }
      this.getCotizacionlistFactCliente();
    },

    
    // Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
     NewBucarCotizacion() {
      this.CotizacionBusqueda = {
        secuencia: 0,
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
        cliente: null,
      };
    },

    cancelarCotizacionAfactura() {
      this.dialogCierreCaja = false;
      this.NewBucarCotizacion();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarCotizacionAfactura", this.dialogCierreCaja)
     }, 300);
    },

    // Llamar a una API y obtener los datos del cliente.
    cargarClienteBusqueda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + this.filtroTipoCliente + "/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/" + this.filtroTipoCliente + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },

    async findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},

     
    // Obtener los datos del servidor y agregarlos a la tabla.
  async VerCotizacionDatostabla(e) {
      var OBJ = {
    headerFactura:{
      // fecha:getParamFecha(e.fecha).split("T")[0],
      fecha:fechaNueva(),
      listaPrecio:null,
      tipocomprobante:null,
      documento:null,
      cliente:null,
      sucursal:null,
      moneda:null,
      vendedor:null,
      diascredito:0,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false
    },
  }

         if (e.hasOwnProperty('cliente')&&e.cliente != null) {
        OBJ.headerFactura.cliente = JSON.parse(JSON.stringify(e.cliente))
        }

        if (e.hasOwnProperty('tipocomprobante')&&e.tipocomprobante != null) {
        OBJ.headerFactura.tipocomprobante = JSON.parse(JSON.stringify(e.tipocomprobante))
        }else{
          OBJ.headerFactura.tipocomprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
        }

        OBJ.headerFactura.moneda = this.$store.getters.GetConfiguracionGeneral.config.monedabase

        if(e.cotizacionDetalleList.length > 0){
          var listCotizDetalle = JSON.parse(JSON.stringify(e.cotizacionDetalleList))
          listCotizDetalle.sort(function (a, b) {
            if (a.cotizacionDetallePK.id > b.cotizacionDetallePK.id) { return 1; }
            if (a.cotizacionDetallePK.id < b.cotizacionDetallePK.id) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          (async () => {  
            const restrList =  await this.ContCotizacionDatostabla(listCotizDetalle,OBJ);

          })()



        }
 
      
    },

    async ContCotizacionDatostabla(listCotizDetalle,data){  
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var bar = new Promise((resolve, reject) => {
        listCotizDetalle.forEach(async (element) => {
           (async () => { 
            const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            if(resUniPro!=null){
                var Obj =JSON.parse(JSON.stringify(resUniPro))
                Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}` 
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false

            var impuestoPrecio = element.productos.incluirimpuestoenprecio;
var PRECIO=JSON.parse(JSON.stringify(element.precio))
if(impuestoPrecio){
  PRECIO=(element.precio+(element.valorimpuesto!=null?element.valorimpuesto:0))
}else{}

                // Obj.precio = element.precio
                Obj.precio = PRECIO
                Obj.precioOrig = PRECIO
                Obj.precioDig = PRECIO
               /* Obj.precio = resUniPro.precio
                Obj.precioOrig = resUniPro.precio
                Obj.precioDig = resUniPro.precio*/
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                ListDetalleTemp.push(Obj)
            } 
            })()
            }); 
            resolve()
      });

      bar.then(() => {
        OBJ.listCotizDetalle=ListDetalleTemp
        this.cancelarCotizacionAfactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCotizacionAfactura", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },
  
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>