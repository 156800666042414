<template>

  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

    <v-card>

      <v-toolbar flat color="indigo" dense>

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> Reporte</v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="CerrarImpriEtiqueta">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>
        <v-form ref="formEtiqueta" v-model="valid" lazy-validation>
          <v-row>


            <v-col cols="12" md="12" sm="12">


              <!-------------------------------------------------- Producto -->
              <v-combobox autocomplete="off" dense outlined label="Productos:" :items="Rproducto"
                item-text="descripcion" @keyup="CbFilProducto" v-model="imprimirEtiqueta.producto" @blur="SelctCbProduc"
                @focus="CargarRproducto">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                </template>
              </v-combobox>





              <!-------------------------------------------------- terminal -->
              <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
              <v-combobox autocomplete="off" dense outlined label="* Impresora de código de barra:" item-text="nombre"
                v-model="imprimirEtiqueta.impresoraBarra">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-combobox>




              <!-- ----------------------------------------terminal -->
              <v-combobox autocomplete="off" dense outlined label="* Seleccione Terminal:" item-text="nombre"
                v-model="imprimirEtiqueta.terminal">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-trademark"></i>
                </template>
              </v-combobox>


              <!-------------------------------------------------- terminal -->
              <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
              <v-combobox autocomplete="off" dense outlined label="* Seleccione Impresora:" item-text="nombre"
                v-model="imprimirEtiqueta.impresora">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-print"></i>
                </template>
              </v-combobox>


              <!-- ----------------------------- Cantidad -->
              <v-text-field type="number" label="Cantidad:" dense outlined :rules="[$rules.required]"
                v-model.number="imprimirEtiqueta.cantiada">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sort-amount-asc"></i>
                </template>
              </v-text-field>

            </v-col>
          </v-row>
        </v-form>

      </v-card-text>


      <hr />
      <v-btn-toggle rounded class="d-flex justify-end">

        <v-btn dark color="blue" @click.prevent="ImprimirModalEtiqueta">
          <i style="font-size: 28px;" class="fa fa-print"> </i>
          Imprimir
        </v-btn>
      </v-btn-toggle>
      <hr />


    </v-card>
  </v-col>
</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
export default {
  components: {
    AutoNumeric
  },

  mounted() {

  },

  created() {

  },

  data: () => ({

    valid: true,
    Rproducto: [],
    imprimirEtiqueta: {

      impresoraBarra: null,
      letra: null,
      terminal: null,
      impresora: null,
      cantiada: 0,
      producto: null
    },




  }),
  methods: {

    ImprimirModalEtiqueta() {

    },



    CerrarImpriEtiqueta() {

      //dialogEtiqueta = false

      this.$emit("EmitCerrarImpriEtiqueta", false);
    },


    CbFilProducto(e) {
      //console.log(e.target.value)
      // if (e.target.value.length >= 3) {
      //   //console.log("ok1")
      //   this.$axios
      //     .post(
      //       this.$hostname +
      //         this.$hName +
      //         "/services/Especiales/getAllProduct2",
      //       { descripcion: e.target.value,
      //          usuario:JSON.parse(JSON.stringify(this.$user)) 
      //       }
      //     )
      //     .then(res => (this.Rproducto = res.data));
      // }
    },


    CargarRproducto() {

      //   this.$axios
      //             .post(
      //               this.$hostname +
      //                 this.$hName +
      //                 "/services/Especiales/getAllProduct2",
      //               { descripcion: 'a',
      //                  usuario:JSON.parse(JSON.stringify(this.$user)) 
      //               }
      //             )
      //             .then(res => (this.Rproducto = res.data));
    },


    SelctCbProduc() {


      if (this.imprimirEtiqueta.producto == null) {
        this.imprimirEtiqueta.producto = "";
      }

      if (this.imprimirEtiqueta.producto.codigo == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.imprimirEtiqueta.producto = "";
        return;
      }
    },



  }




}
</script>

<style>

</style>