<template>
  <div>

    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> Crear Cuenta Nueva </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="cerrarModalAddCuenta()">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <br>
        <v-form ref="form3" v-model="valid" lazy-validation>
          <v-row>

            <input type="number" v-model="CuentaNueva.codigo" style="display:none;" />

            <v-col cols="12" md="6" sm="6">
              <v-text-field label="* Cuenta:" autocomplete="off" dense outlined :rules="[$rules.required]"
                v-model="CuentaNueva.cuenta">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                </template>
              </v-text-field>
            </v-col>


            <v-col cols="12" md="6" sm="6">


              <v-combobox v-model="CuentaNueva.descargo" label="Cuenta Descargo:" required outlined dense
                autocomplete="off" :items="Rcuenta" @keyup="CbFilCuenta" :item-text="RfilCuenta"
                @blur="SelctCbcuentaNuevaDesc" @focus="CargarRcuenta" @change="SelctCbCuentaNuevaDescValidControl">

                <template slot="item" slot-scope="item" margin-top="50px">
                  <v-row>
                    <v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
                    <v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
                  </v-row>
                </template>

                <template v-slot:prepend-item>
                  <v-row>
                    <v-col cols="6" md="6"> <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                    <v-col cols="6" md="6"> <b style="font-size: 18px;">Descripción: </b> </v-col>
                  </v-row>
                </template>


                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                </template>
              </v-combobox>

            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-text-field label="Descripción*:" dense autocomplete="off" outlined :rules="[$rules.required]"
                v-model="CuentaNueva.descripcion">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>
            </v-col>


            <v-col cols="12" md="6" sm="6">
              <v-combobox v-model="CuentaNueva.origen" label="Origen:" required outlined dense
                :rules="[$rules.required]" autocomplete="off" :items="ROrigen" item-text="descripcion"
                @blur="SelctCbcuentaorigen">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-combobox v-model="CuentaNueva.tipo" label="Tipo:" required outlined dense :rules="[$rules.required]"
                autocomplete="off" :items="RTipo" item-text="descripcion" @blur="SelctCbCuentatipo">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-tag"></i>
                </template>
              </v-combobox>
            </v-col>


            <v-col cols="12" md="6" sm="6">
              <v-combobox v-model="CuentaNueva.tipogasto" label="Tipo de Gasto:" required outlined dense
                :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                @focus="CargarRTipopago" @blur="SelctCbTipoGasto">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-tag"></i>
                </template>
              </v-combobox>
            </v-col>


            <v-col cols="12" md="6" sm="6">
              <v-combobox v-model="CuentaNueva.grupo" label="Grupo:" required outlined dense :rules="[$rules.required]"
                autocomplete="off" :items="RGrupo" item-text="descripcion" @blur="SelctCbGrupo">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-cubes"></i>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <hr />
      <!--   -->
      <v-btn-toggle rounded class="d-flex justify-end ">

        <v-btn dark color="green darken-1" @click.prevent="saveCuentaNueva">
          <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
          Salvar
        </v-btn>


      </v-btn-toggle>
      <hr />
    </v-card>

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


  </div>
</template>
<script>
import AutoNumeric from "../components/AutoNumericNuevo";
import { EventBus } from "@/event-bus.js";
export default {

  components: {
    AutoNumeric
  },
  mounted() {

  },

  created() {

  },


  data() {

    return {

      valid: true,
      search: '',

      Rcuenta: [],
      RTipoGasto: [],

      RfilCuenta: "cuenta",

      CuentaNueva: {

        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true,
      },

      aalert: {
        estado: false,
        color: null
      },

      ROrigen: [
        { codigo: 1, origen: "D", descripcion: "Crédito" },
        { codigo: 2, origen: "C", descripcion: "Debito" },
      ],


      RTipo: [
        { codigo: 1, tipo: "CO", descripcion: "Control" },
        { codigo: 2, tipo: "DE", descripcion: "Detalle" },
      ],

      RGrupo: [

        { codigo: "AC", descripcion: "Activo" },
        { codigo: "PA", descripcion: "Pasivo" },
        { codigo: "CA", descripcion: "Capital" },
        { codigo: "IN", descripcion: "Ingreso" },
        { codigo: "CO", descripcion: "Costo" },
        { codigo: "GA", descripcion: "Gasto" }

      ]


    }


  },
  methods: {


    newcuenta() {
      this.CuentaNueva = {

        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true,
      }

      //  this.modalCuenta={
      //     codigoDocumento:0,
      //     cuenta:null,
      //     tipo:null,
      //     origen:null
      //   },

      // this.acti_Nueva_cuenta=false

    },


    saveCuentaNueva() {
      if (this.$refs.form3.validate()) {


        var jsn = {
          CuentaNueva: JSON.parse(JSON.stringify(this.CuentaNueva))
          , usuario: JSON.parse(JSON.stringify(this.$user))
        }

        var CtaNueva=JSON.parse(JSON.stringify(this.CuentaNueva))
        CtaNueva.origen= this.CuentaNueva.origen.origen
        CtaNueva.tipo=  this.CuentaNueva.tipo.tipo
        CtaNueva.grupo=  this.CuentaNueva.grupo.codigo 
        CtaNueva.gasto= this.CuentaNueva.tipogasto
        CtaNueva.tipogasto=""
        CtaNueva.activo= true
        CtaNueva.borrado = false
        this.$axios.post(this.$hostname + this.$hName + '/catalogo/save',
        CtaNueva,{headers:this.$store.getters.GetheadersAxios})
             .then(res => this.alertaModal("Dato guardado correctamente!","bien"))
             .catch(error => this.alertaModal('Dato no guardado correctamente','error'))

        /*this.$axios.post(this.$hostname + this.$hName + '/services/CuentaCatalogo/save',
          JSON.parse(JSON.stringify(jsn)))
          .then(res => this.alertaModal(res.data.mensage, res.data.estado))
          .catch(error => this.alertaModal('Dato no guardado correctamente', 'error'))*/
      } else {

        this.alertaModal("los campos en rojo son requeridos", "error");

      }

    },



    addClikProd(e) {
      var Objt = {
        estado: "datos",
        datos: e
      }

      this.$emit("EmitCrearCuenta", Objt);

    },


    cerrarModalAddCuenta() {

      this.newcuenta()

      var Objt = { estado: "cerrar" }
      this.$emit("EmitCrearCuenta", Objt);

    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newcuenta();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    alertaModal(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newcuenta();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }
    },



    SelctCbcuentaNuevaDesc() {


      if (this.CuentaNueva.descargo == null) {
        this.CuentaNueva.descargo = "";
      }

      if (this.CuentaNueva.descargo.cuenta == undefined) {
        this.alerta("Seleccione un tipo cuenta", "error");
        this.CuentaNueva.descargo = "";
        return;
      }

    },

    CargarRcuenta() {
      this.$axios.post(this.$hostname + this.$hName + '/services/CuentaCatalogo/findall', {
        usuario: JSON.parse(JSON.stringify(this.$user))
      }).then(res => {
        this.Rcuenta = res.data;
      });
    },


    SelctCbCuentaNuevaDescValidControl() {

      if (this.CuentaNueva.descargo != null) {

        if (this.CuentaNueva.descargo.tipo == 'DE') {
          this.alerta("No puede seleccionar una cuenta de detalle", "error");
          this.CuentaNueva.descargo = null;
        }

      }

    },

    CbFilCuenta(e) {

      if (e.target.value.length == 1) {

        if (e.target.value.charCodeAt(0) >= 97 & e.target.value.charCodeAt(0) <= 122) {

          //console.log("ok letra")

          this.RfilCuenta = "descripcion"


          this.CargarRcuenta()

        } else { this.RfilCuenta = "cuenta" }



        if (e.target.value.charCodeAt(0) >= 48 & e.target.value.charCodeAt(0) <= 57) {
          this.CargarRcuenta()
          //console.log("ok numero")
          this.RfilCuenta = "cuenta"

        } else { this.RfilCuenta = "descripcion" }

        //numero 48 a 57
        //letras 97 a 122 

      }


    },

    SelctCbcuentaorigen() {

      if (this.CuentaNueva.origen == null) {
        this.CuentaNueva.origen = "";
      }

      if (this.CuentaNueva.origen.codigo == undefined) {
        this.alerta("Seleccione un origen", "error");
        this.CuentaNueva.origen = "";
        return;
      }

    },

    SelctCbCuentatipo() {

      if (this.CuentaNueva.tipo == null) {
        this.CuentaNueva.tipo = "";
      }

      if (this.CuentaNueva.tipo.codigo == undefined) {
        this.alerta("Seleccione un tipo", "error");
        this.CuentaNueva.tipo = "";
        return;
      }

    },

    CargarRTipopago() {
      this.$axios.post(this.$hostname + this.$hName + '/services/CategoriaGasto/findall', {
        usuario: JSON.parse(JSON.stringify(this.$user))
      }).then(res => {
        this.RTipoGasto = res.data;
      });
    },

    SelctCbTipoGasto() {


      if (this.CuentaNueva.tipogasto == null) {
        this.CuentaNueva.tipogasto = "";
      }

      if (this.CuentaNueva.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.CuentaNueva.tipogasto = "";
        return;
      }

    },

    SelctCbGrupo() {
      if (this.CuentaNueva.grupo == null) {
        this.CuentaNueva.grupo = "";
      }

      if (this.CuentaNueva.grupo.codigo == undefined) {
        this.alerta("Seleccione un grupo", "error");
        this.CuentaNueva.grupo = "";
        return;
      }

    },



  }


}

</script>