<template>
<div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >

<tr max-height="48px" height="48px"  > 
<ContenidoBase 
  :titulo="'Centros de Salud'"
  :descripcion="'Crear, Modificar y Desactivar Centros de Salud'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>




    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        

      <v-tooltip left>
          <template v-slot:activator="{ on }">
            <!--------------------- aqui va la otra tabla izquierda --------->
            <!-- <v-navigation-drawer >  -->
            <center>
              <!-- <v-btn-toggle> </v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
             
            </center>
            <!-- <Barra busqueda -->

<BarraDinamicaNormal
              ref="BarraDiNor"
              :url="$hostname + $hName + '/centrosalud/findall/'"
              :nombre="'Centros de Salud'"
              :emitnombre="'emiCentroSalud'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'RNC', value: 'rnc'},
        { text: 'Telefono', value: 'telefono'},
        { text: 'Ciudad', value: 'ciudad' },
        { text: 'Direccion', value: 'direccion'},
        { text: 'Fecha de Creacion', value: 'fechaCreacion'},
        { text: 'Usuario Creacion', value: 'usuarioCreacion' }
         ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Nombre', NomValue:'nombre', Ctsl:1, Type:0, State:true},
              {Text:'RNC', NomValue:'rnc', Ctsl:1, Type:0, State:true},
              {Text:'Telefono', NomValue:'telefono', Ctsl:1, Type:0, State:true},
              {Text:'Ciudad', NomValue:'ciudad', Ctsl:1, Type:0, State:true},
              {Text:'Direccion', NomValue:'direccion', Ctsl:1, Type:0, State:true},
              {Text:'Fecha de Creacion', NomValue:'fechaCreacion', Ctsl:1, Type:1, State:true},
              {Text:'Usuario Creacion', NomValue:'usuarioCreacion', Ctsl:1, Type:0, State:true}
              ]"
            />


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/centrosalud/findAll'"
              :nombre="'Centros de Salud'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emiCentroSalud'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por Nombre'"
            /> -->
         
            <!-- Barra busqueda -->
          </template>
        </v-tooltip>


      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
          
 <div class="px-2">


<v-card
 color="grey lighten-3"
  >
  <div class="px-2">
    
  


  <v-form ref="form" v-model="valid" lazy-validation>
<v-row>
<input
                  type="number"
                  v-model="form.centroSalud.codigo"
                  style="display:none;"
                />


<!-- -----------------------------  columna-1 -->
<v-col cols="6" md="6">

<!-------------------------------------------------- Nombre -->
<v-text-field label="* Nombre:"
                     dense outlined
                     autocomplete="off"
                     v-model="form.centroSalud.nombre"
                    :rules="[$rules.required]" 
                     
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i> 
                      </template>
                    </v-text-field>



<!-------------------------------------------------- telefono :rules="[rules.required, rules.RulesTel]" -->
<v-text-field
          
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            
            v-model="form.centroSalud.telefono"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>
          </v-text-field>



<!-- ----------------------------- Ciuddad -->
                  
                  <v-text-field label="* Ciudad:" dense outlined
                  autocomplete="off"
                    v-model="form.centroSalud.ciudad"
                    :rules="[$rules.required]"
                    >
                  <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map"></i> 
                  </template>
                    </v-text-field>


</v-col>
<!-- ----------------------------- fn columna-1 -->

<!-- -----------------------------  columna-2 -->
<v-col cols="6" md="6">

<!-- -----------------------------rnc --> 
                
                  <v-text-field 
                  autocomplete="off"
                    v-mask="'###-#####-#'"
                    label="RNC:"
                    dense
                    outlined
                    maxlength="11"
                    :rules="[$rules.RulesRnc]"
                    v-model="form.centroSalud.rnc"
                   
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                    </template>
                  </v-text-field>

        <!-- -----------------------------  email -->
             <v-text-field label="E-mail:"
             autocomplete="off"
                     dense outlined
                     v-model="form.centroSalud.email"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>  


                  <!-- ----------------------------- direccion -->

                  
                  <v-textarea
                  autocomplete="off"
                    label="* Direccion:"
                    dense
                    outlined
                    v-model="form.centroSalud.direccion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>


</v-col>
<!-- ----------------------------- fn columna-2 -->


</v-row>
  </v-form>
</div>
  </v-card>


       </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay 
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                  style="display:none;"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              class="TbSize13"
              :headers="[
        { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Nombre', value: 'nombre', sortable:false, class: ['no-gutters black--text'] },
        { text: 'RNC', value: 'rnc', width:130, sortable:false, class: ['no-gutters black--text'] },
        { text: 'Telefono', value: 'telefono', width:130, sortable:false, class: ['no-gutters black--text'] },
        { text: 'Ciudad', value: 'ciudad', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Direccion', value: 'direccion', width:230, sortable:false, class: ['no-gutters black--text']  },
        { text: 'Fecha de Creacion:', value: 'fechaCreacion', width:130, sortable:false, class: ['no-gutters black--text'] },
        { text: 'Usuario Creacion', value: 'usuarioCreacion', sortable:false, class: ['no-gutters black--text'] }


      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.fechaCreacion="props">
                <span>{{ FormatoFecha(props.item.fechaCreacion) }}</span>
              </template>



            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title :class="`d-flex justify-center`" >
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions :class="`d-flex justify-center`" >
          <v-btn
            v-if="notif.Exec=='eliminarCentroSalud'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>
<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import { isNullOrUndefined } from "util";
import { parse } from 'path';
import ModalReporteBasico from "../../components/ModalReporteBasico";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    AutoNumeric,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico
    
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
     EventBus.$on("emiCentroSalud", this.datostabla);
    // EventBus.$on("CXCCentrosSaludNew", this.new);
    // EventBus.$on("CXCCentrosSaludSave", this.save);
    // EventBus.$on("CXCCentrosSaludRemove", this.remove);
    // EventBus.$on("CXCCentrosSaludAnular", this.anular);
    // EventBus.$on("CXCCentrosSaludPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    this.cargarTodo(); 
  },
  created() {
    var json = {
      titulo: "Centros de Salud",
      descripcion: "Crear, Modificar y Desactivar Centros de Salud",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      accion: [
        "CXCCentrosSaludNew",
        "CXCCentrosSaludSave",
        "CXCCentrosSaludRemove",
        "CXCCentrosSaludAnular",
        "CXCCentrosSaludPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

      windowSize:{width:"auto", height:"auto"},
      valid: true,
      ejecucion: true,

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



dialogimprimirList:false,
datosImprecion:{
nombre:'CENTROS DE SALUD',
 UrlImprimir: '/centrosalud/ImprimirCentrosSalud', 
 UrlExel: '/centrosalud/ExcelCentrosSalud', 
 UrlCorreo: '/centrosalud/CorreoCentrosSalud', 
},


      form: {

centroSalud:{
codigo: 0,
nombre: null,
ciudad: null,
direccion: null,
fechaCreacion: new Date().toISOString().substr(0,10),
rnc: "",
telefono: "",
usuarioCreacion: "admin",
usuarioModificacion: ""
//"rncOriginal": "102000298",
// "nombreCortoOriginal": "CLINICA COROMINAS",

},





        documento: null,
        factura: null,
        ncf: null,
        tipoAnulacion: null
      },

     
      headers: [{ text: "Nombre", value: "nombre" }],

      slotlist: [
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "" }
      ],
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      

      list: [],
      search: "",
      acti_tb_data: false,

notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

    
      aalert: {
        estado: false,
        color: null
      }
    };
  },
  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){

// console.log("imprii ")
// console.log(e)
// this.windowSize=e

},

    abrirTabla() {

 this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;


if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }

  
    },
    async cargarTodo() {
      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/sucursal/findall", {})
      //   .then(res => (this.ListaSucursales = res.data))
      //   .catch(error => console.log(error));
    },

fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {

var params2 =new Date(params).toISOString().substr(0,10)

          return (
            params2.substring(8, 10) +
            "-" +
            params2.substring(5, 7) +
            "-" +
            params2.substring(0, 4)
          );



        }
      }
    },


    datostabla(e) {

this.acti_tb_data = false;

this.form.centroSalud=JSON.parse(JSON.stringify(e))
console.log('Datos')
console.log(e)

    },
    save() { 
    
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;

if(this.form.centroSalud.codigo !=0){this.form.centroSalud.usuarioModificacion="aadmin"}

console.log('Guardando...')
          this.$axios
            .post(this.$hostname + this.$hName + "/centrosalud/save/",this.form.centroSalud,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));


        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    
    
    
    
    },


NotifMessage() {

if(this.form.centroSalud.codigo!=0 && this.form.centroSalud.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarCentroSalud"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un custodio para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))


    },

  
    eliminar() { 
      
if(this.form.centroSalud.codigo!=0){



      if (this.$refs.form.validate()) {
      
          this.$axios
            .post(this.$hostname + this.$hName + "/centrosalud/eliminar/",this.form.centroSalud,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));


        
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


}
      
      
    
    
    
    
    
    },
    anular() {},
    imprimirList() {
        this.dialogimprimirList=true
    },

DDataReporteBasico(e){
this.dialogimprimirList=e
},
    newwnuevo() {

this.BarraTablaDinamicaAprir = false

this.form.centroSalud={
codigo: 0,
nombre: null,
ciudad: null,
direccion: null,
fechaCreacion: new Date().toISOString().substr(0,10),
rnc: "",
telefono: "",
usuarioCreacion: "admin",
usuarioModificacion: ""
//"rncOriginal": "102000298",
// "nombreCortoOriginal": "CLINICA COROMINAS",

}


        this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/centrosalud/findall/", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    }
  },
  computed:{

    ActDescBtn(){

if(this.form.centroSalud.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


  // var objjct = {
  //     save: true,
  //     eliminar: true,
  //     anular: false,
  //     nuevo: true,
  //     imprimir: true,
  //     vercuenta:false, 
  //   };

}


if(this.form.centroSalud.codigo<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


// var objjct = {
//       save: true,
//       eliminar: false,
//       anular: false,
//       nuevo: true,
//       imprimir: true,
//       vercuenta:false, 
//     };


}
    this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))  
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }

};
</script>
