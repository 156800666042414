<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Formulario de Solicitud/Modificación/Actualización'"
  :descripcion="'Formulario de Solicitud/Modificación/Actualización Crear, Modificar y Desactivar Fabricantes'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitSaludFormSolicitud'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

 <v-container >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>


<v-card color="grey lighten-3">
  <v-container>
              <v-row>

              <!-- columna 1------------------------------->
                <v-col cols="12" md="4" sm="4">

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Solicitud medida de distancia"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Solicitud apertura"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Traslado establecimiento"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Solicitud renovación registro"
              color="teal "
              hide-details
            ></v-checkbox>

                </v-col>
                <!-- fn columna 1----------------------------->

                <!-- columna 2 ------------------------------->
                <v-col cols="12" md="4" sm="4">

                  <v-checkbox
              v-model="FormSolicitud.activo"
              label="Solicitud cambio del nombre"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Cambio propietario"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Solicitud nueva categoría"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Solicitud de razón social"
              color="teal "
              hide-details
            ></v-checkbox>

                </v-col>
                <!-- fn columna 2 ---------------------------->

                <!-- columna 3 ------------------------------->
                <v-col cols="12" md="4" sm="4">


<!---------------------- No. Receta-->
          <v-text-field
            v-model="FormSolicitud.descripcion"
            :rules="[$rules.required]"
            label="Solicitud  No. "
            required
            outlined
            dense
          >

            <template v-slot:prepend>

<i style="font-size: 20px;" class="fa fa-hashtag"></i>

            </template>
          </v-text-field>

                  <!---------------------- No. Receta-->
          <v-text-field
            v-model="FormSolicitud.descripcion"
            :rules="[$rules.required]"
            label="Receta No. "
            required
            outlined
            dense
          >

            <template v-slot:prepend>

<i style="font-size: 20px;" class="fa fa-hashtag"></i>

            </template>
          </v-text-field>

                </v-col>
                <!-- fn columna 3 ---------------------------->



              </v-row>
  </v-container>
</v-card>
<hr>
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Datos del Propietario</b></center>
              <v-row>
                <!-- columna 1 -------------------------------->
                <v-col cols="12" md="6" sm="6" >

                <!------------------------------------------- nombre -->
                <v-text-field
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Nombre o razón social:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

                  <!-- ----------------------------- cedula/rnc --> 
                
                  <v-text-field 
                  autocomplete="off"
                    v-mask="MakCelRnc"
                    label="* Cedula/RNC:"
                    dense
                    outlined
                    maxlength="13"
                    :rules="[$rules.RulesCelRnc]"
                    v-model="FormSolicitud.cedrnc"
                    @blur="MakaraCel"
                    @focus="MakarNo"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                    </template>
                  </v-text-field>

                  <!-- -----------------------------  email -->
             <v-text-field label="Correo electrónico:"
                     dense outlined
                     v-model="FormSolicitud.descripcion"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>

                </v-col>
                <!-- fn columna 1 -->

                <!-- columna 2 -------------------------------->
                <v-col cols="12" md="6" sm="6" >

                <!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                    label="Direccion:"
                    rows="2"
                    dense
                    outlined
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>

                  <!-- ----------------------------- teléfono -->
                    <v-text-field
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="FormSolicitud.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template> 
          </v-text-field>

          <!-------------------------------  email -->
             <v-text-field label="Fax:"
                     dense outlined
                     v-model="FormSolicitud.descripcion"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-fax"></i> 
                      </template>
             </v-text-field>
                  



                </v-col>
                <!-- fn columna 2 -->



              </v-row>
  </v-container>
</v-card>
<hr>
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Datos generales del Direcctor Técnico</b></center>
              <v-row>

                <!-- columna 1  ----------------------------------->
                <v-col cols="12" md="6" sm="6">

                  <!------------------------------------------- nombre -->
                <v-text-field
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Nombre:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

                  <!-- ----------------------------------------- cedula  -->
<v-text-field
            v-mask="'###-#######-#'"
            label="Cédula:"
            autocomplete="off"
            dense
            outlined
            maxlength="13"
            :rules="[$rules.required, $rules.RulesCel]"
            v-model="FormSolicitud.descripcion"
          >
          
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
              </template>
       
          </v-text-field>

          <!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                    label="Direccion:"
                    rows="2"
                    dense
                    outlined
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>

          <!-- ----------------------------- teléfono -->
                    <v-text-field
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="FormSolicitud.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template> 
          </v-text-field>


          <!------------------------------------------- Profesión -->
                <v-text-field
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Profesión:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-graduation-cap"></i>
                    </template>
                  </v-text-field>


                </v-col>

                <!-- fn columna 1 --------------------------------->

                <!-- columna 2  ----------------------------------->
                <v-col cols="12" md="6" sm="6" >

                  <!------------------------------------------- nombre -->
                <v-text-field
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Apellido:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

                  <!-- -----------------------------  email -->
             <v-text-field label="Correo electrónico:"
                     dense outlined
                     v-model="FormSolicitud.descripcion"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>

                    <!-------------------------------  email -->
             <v-text-field label="Fax:"
                     dense outlined
                     v-model="FormSolicitud.descripcion"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-fax"></i> 
                      </template>
                    </v-text-field>

                    <!-- ------------------------------- exequatur -->
                    <v-text-field
                      autocomplete="off"
                      label="exequátur"
                      v-model="FormSolicitud.descripcion"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="id-badge"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>

                    <!-- -----------------------------  fecha  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha:"
        v-model="FormSolicitud.fecha"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>

                </v-col>
                
                <!-- fn columna 2 -->




              </v-row>
  </v-container>
</v-card>
<hr>
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Datos del establecimiento</b></center>
    <span>Tipo de establecimiento</span>
<v-row>
<v-col cols="12" sm="6" md="6" >
<v-radio-group v-model="FormSolicitud.opcion" column > 
<v-radio label="Laboratorio Fabricante" color="green" :value="1"></v-radio>
<v-radio label="Laboratorio Acondicionadores/Envasadores" color="green" :value="2"></v-radio>
<v-radio label="Farmacia" color="green" :value="3"></v-radio>
</v-radio-group>
</v-col>

<v-col cols="12" sm="6" md="6" >
<v-radio-group v-model="FormSolicitud.opcion" column > 
<v-radio label="Laboratorio farmoquímico" color="green" :value="4"></v-radio>
<v-radio label="Distribuidora" color="green" :value="5"></v-radio>
<v-radio label="Maquiladores a terceros" color="green" :value="6"></v-radio>
</v-radio-group>
</v-col>
</v-row>
<span>Tipo de actividad</span>

<v-col cols="12" md="12" sm="12">
<v-row>

              <!-- columna 1------------------------------->
                <v-col cols="12" md="4" sm="4">

                  <v-checkbox
              v-model="FormSolicitud.activo"
              label="Medicamentos"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Hospitalaria"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Productos similares"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Venta de producto terminado"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Otros"
              color="teal "
              hide-details
            ></v-checkbox>

                </v-col>
                <!-- fn columna 1----------------------------->

                <!-- columna 2 ------------------------------->
                <v-col cols="12" md="4" sm="4">

                  <v-checkbox
              v-model="FormSolicitud.activo"
              label="Naturales"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Cosméticos"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Importadora"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Venta de materia prima"
              color="teal "
              hide-details
            ></v-checkbox>

                </v-col>
                <!-- fn columna 2 ---------------------------->

<!-- columna 3 ------------------------------->
                <v-col cols="12" md="4" sm="4">

                  <v-checkbox
              v-model="FormSolicitud.activo"
              label="Dispositivos médicos"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Higiene del Hogar"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Ambulatorio pública"
              color="teal "
              hide-details
            ></v-checkbox>

            <v-checkbox
              v-model="FormSolicitud.activo"
              label="Ambulatoria privada"
              color="teal "
              hide-details
            ></v-checkbox>

                </v-col>
                <!-- fn columna 3 ---------------------------->
</v-row>
</v-col>

<span>Tipo de actividad</span>
<v-col cols="12" md="12" sm="12">
<v-radio-group v-model="FormSolicitud.opcionTActiv" row > 
<v-radio label="Líquidos" color="green" :value="1"></v-radio>
<v-radio label="Sólidos" color="green" :value="2"></v-radio>
<v-radio label="Semi-Sólidos" color="green" :value="3"></v-radio>
<v-radio label="Otros" color="green" :value="4"></v-radio>
</v-radio-group>
</v-col>

<span>Clasificación del laboratorio</span>
<v-col cols="12" md="12" sm="12">
<v-radio-group v-model="FormSolicitud.opcionTActiv" row > 
<v-radio label="Clase A" color="green" :value="1"></v-radio>
<v-radio label="Clase B" color="green" :value="2"></v-radio>
<v-radio label="Clase C" color="green" :value="3"></v-radio>
<v-radio label="Clase D" color="green" :value="4"></v-radio>
<v-radio label="Clase E" color="green" :value="5"></v-radio>
</v-radio-group>
</v-col>

	
	<v-col cols="12" md="12" sm="12">


<!------------------------------------------- caso -->
                <v-text-field
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" En caso de renovación No. registro sanitario anterior:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-file-text-o"></i>
                    </template>
                  </v-text-field>



    <!-- ----------------------------- Notas -->
                  <v-textarea
                  autocomplete="off"
                    label="Notas:"
                    rows="2"
                    dense
                    outlined
                    v-model="FormSolicitud.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                    </template>
                  </v-textarea>


	</v-col>
	
	





  </v-container>
</v-card>

         


            </v-form>
     
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>



          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  
  <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarSolicitud'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase
    
  },
  
  created() {
    var json = {
      titulo: "Formulario de Solicitud/Modificación/Actualización",
      descripcion: "Crear, Modificar y Desactivar Fabricantes",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [ 
        "SaludFormSolicitudNew",
        "SaludFormSolicitudSave",
        "SaludFormSolicitudRemove",
        "SaludFormSolicitudAnular",
        "SaludFormSolicitudPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("SaludFormSolicitudNew", this.new);
    // EventBus.$on("SaludFormSolicitudSave", this.save);
    // EventBus.$on("SaludFormSolicitudRemove", this.remove);
    // EventBus.$on("SaludFormSolicitudAnular", this.remove);
    // EventBus.$on("SaludFormSolicitudPrintList", this.imprimirList);
    EventBus.$on("emitSaludFormSolicitud", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {

      MakCelRnc:'#############',


      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      FormSolicitud: {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        cedrnc:"",
        fecha:new Date().toISOString().substr(0,10),
        opcion: 1,
        opcionTActiv:1
       
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },

    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

this.MakCelRnc='#############'
if(!e.hasOwnProperty('cedrnc')){
e.cedrnc=""
}

if(e.hasOwnProperty('cedrnc')){
setTimeout(this.MakaraCel,900);
//this.MakaraCel() 
}


      this.FormSolicitud = e;
      this.acti_tb_data=false




    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.FormSolicitud)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },



NotifMessage() {

if(this.FormSolicitud.codigo!=0 && this.FormSolicitud.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarSolicitud"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un formulario solicitud para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    remove() {
if(this.FormSolicitud.codigo!=0 && this.FormSolicitud.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}
    },


    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.FormSolicitud)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.FormSolicitud.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      // if (this.Elim_notif.estado) {}

var jsn={
   datos:JSON.parse(JSON.stringify(this.FormSolicitud)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      
    },
    imprimirList() {},
    newwnuevo() {
      (this.FormSolicitud = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


MakarNo(){

this.MakCelRnc='#############'

},



MakaraCel(){


if(this.FormSolicitud.cedrnc.toString().length<11){

this.MakCelRnc='###-#####-#'
}


if(this.FormSolicitud.cedrnc.toString().length >=11){

this.MakCelRnc='###-#######-#'
}


},





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },

computed:{

    ActDescBtn(){      
if(this.FormSolicitud.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.FormSolicitud.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

    }
    }




};
</script>

<style></style>
