<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   
    
<ContenidoBase 
  :titulo="'Configuración de Mensajeria'"
  :descripcion="'Configuración de Mensajeria'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBancos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
    <v-col cols="12" md="12" sm="12" class="px-2"> 
      <v-row>

        <!---------------------------------------------------  Lista de telefono  -->
    <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

<v-card>
  <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span><b>Lista de telefono autorizados</b></span>
  </v-col>
  <!-- cuerpo formulario de lista de telefono -->
  <v-col cols="12" md="12" sm="12">
    <v-row>
      <!-- Columan 1 -->
      <v-col cols="12" md="6" sm="6" class=" py-0 my-0">
        <v-form v-model="valid" ref="formListTelefono" @submit.prevent="" lazy-validation> 

        <v-row>
                           <v-col class="pa-0 ma-0 py-0 my-0" cols="7" md="7" sm="7">
                            <v-text-field label="Nombre:" @keyup.enter="addTel" style="font-size:13px;" dense outlined autocomplete="off" v-model="tel.nombre" hide-details :rules="[$rules.required]">
                              <template v-slot:prepend>
                                <i style="font-size: 20px" class="fa fa-font"></i>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="5" md="5" sm="5">
                            <v-text-field label="teléfono:" style="font-size:13px;" dense outlined autocomplete="off" maxlength="13"
                              v-mask="'(###)###-####'" v-model="tel.telefono" hide-details :rules="[$rules.required,$rules.RulesTel]"
                              @keyup.enter="addTel">
                              <template v-slot:append-outer>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn @click.prevent="addTel" v-on="on" color="green" fab x-small dark>
                                      <i style="font-size: 20px" class="fa fa-plus-square"></i>
                                    </v-btn>
                                  </template>
                                  <span>Agregar Teléfono</span>
                                </v-tooltip>
                              </template>
                            </v-text-field>
                          </v-col>
</v-row>
</v-form>

                          <v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
<v-data-table

      :headers="[
                { text: 'Nombre', sortable: false,  value: 'nombre', width:'auto',sortable:false, class: ['no-gutters black--text'] },
                 { text: 'Telefono', sortable: false, value: 'telefono', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: 'Acciones', sortable: false, value: 'action', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listTelefono"     
              :items-per-page="15"
              class="elevation-1 TbSize13"
            >

<template v-slot:item.metadeventa="props"> 
  <span style="display:none;">{{props.item.indx = listVentas.indexOf(props.item)}}</span>
  <span >{{currencyFormatter(props.item.metadeventa)}}</span>
</template>

<template v-slot:item.action="{ item }">
                            <v-btn fab @click.prevent="editTel(item)" x-small color="grey "><i style="font-size: 20px"
                                class="fa fa-pencil"></i></v-btn>
                            <v-btn fab @click.prevent="deleTel(item)" x-small color="red darken-3"><i
                                style="font-size: 20px" class="fa fa-trash-o"></i></v-btn>
                          </template>
</v-data-table>

 </div>

</v-col>
                          
          


      </v-col>
      <!-- Columan 2 -->


      <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

      </v-col>

    </v-row>

  </v-col>
  <!-- fn cuerpo formulario de Lista de telefono -->
</v-card>
</v-col>
<!--------------------------------------------------- fn Lista de telefono   -->


<!---------------------------------------------------  Cuadre de caja  -->
<v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

<v-card>
  <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span><b>1- Cuadre de caja</b></span>
  </v-col>
  <!-- cuerpo formulario de Cuadre de caja-->
  <v-col cols="12" md="12" sm="12">
    <v-form v-model="valid" ref="formCuadreCaja" @submit.prevent="" lazy-validation> 
    <!-- style="border: 1px solid #000000;" -->
    <v-row>
      <!-- Columan 1 -->
      <v-col cols="12" md="5" sm="5" class=" py-0 my-0" >
        <v-textarea  :rules="[$rules.required]" autocomplete="off" label="Diseño de mensaje:" rows="2" dense outlined v-model="mensajeriaCuadreCaja.mensaje">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-commenting"></i>
                      </template>
                    </v-textarea>       
      </v-col>
      <!-- Columan 2 -->
      <v-col cols="12" md="5" sm="5" class=" py-0 my-0" >
<!-- style="background-color:#BDBDBD;" -->
        <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
          <!-- <center><span style="font-size: 13px;"><b>Campos Disponible</b></span> -->
            <center>
          <v-row style="font-size: 13px;">
            <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Campo </b></p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">idcuadre </p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">nomcaja</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">fecha</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">venta</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">devolucion</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">depositar</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">diferencia</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">diferenciaValor</p>
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Definición </b></p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Número de cuadre.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Nombre de la caja.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Fecha del cuadre.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Venta total del cuadre.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Devoluciones totales del cuadre.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Valor total a depositar</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Texto describe faltante o sobrante.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Valor faltante o sobrante</p>
            </v-col>
          </v-row>
        </center>
  </v-col>
      </v-col>
      <!-- Columan 3 -->
      <v-col cols="12" md="2" sm="2" class=" py-0 my-0 ">
        <v-text-field label="Hora"
                    style="font-size:13px;"
                     dense outlined
                     v-model="mensajeriaCuadreCaja.hora"
                     type="time"
                     hide-details
                     id="formdisabledInput"
                     :disabled="mensajeriaCuadreCaja.automatico==false"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>
                  <div :class="`ma-1 pl-3 ml-3`" >
                    <v-checkbox  :class="`ma-1`" color="teal" label="" v-model="mensajeriaCuadreCaja.activada" hide-details
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="mensajeriaCuadreCaja.activada == true"
                                      style="color:#000000; font-size:13px;">Activada: Si</strong>
                                    <strong v-if="mensajeriaCuadreCaja.activada == false"
                                      style="color:#000000; font-size:13px;">Activada: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox :class="`ma-1`"  color="teal" label="" v-model="mensajeriaCuadreCaja.automatico" hide-details
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="mensajeriaCuadreCaja.automatico == true"
                                      style="color:#000000; font-size:13px;">De proceso: Si</strong>
                                    <strong v-if="mensajeriaCuadreCaja.automatico == false"
                                      style="color:#000000; font-size:13px;">De proceso: No</strong>
                                  </template>
                                </v-checkbox>
                              </div>   
      </v-col>
    </v-row>
  </v-form> 
  </v-col>
  <!-- fn cuerpo formulario de Cuadre de caja -->
</v-card>
</v-col>
<!--------------------------------------------------- fn cuadre de caja  -->

<!---------------------------------------------------  Beneficio diario / del dia  -->
<v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

<v-card>
  <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span><b>2- Beneficio diario / del día</b></span>
  </v-col>
  <!-- cuerpo formulario de Beneficio diario / del dia--><!-- style="border: 1px solid #000000;" -->
  <v-col cols="12" md="12" sm="12">
    <v-form v-model="valid" ref="formBeneficioDiario" @submit.prevent="" lazy-validation> 
    
    <v-row>
      <!-- Columan 1 -->
      <v-col cols="12" md="5" sm="5" class=" py-0 my-0" >
        <v-textarea  :rules="[$rules.required]" autocomplete="off" label="Diseño de mensaje:" rows="2" dense outlined v-model="mensajeriaBeneficioDiario.mensaje">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-commenting"></i>
                      </template>
                    </v-textarea>       
      </v-col>
      <!-- Columan 2 -->
      <v-col cols="12" md="5" sm="5" class=" py-0 my-0" >
<!-- style="background-color:#BDBDBD;" -->
        <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
          <!-- <center><span style="font-size: 13px;"><b>Campos Disponible</b></span> -->
            <center>
              <v-row style="font-size: 13px;">
            <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Campo </b></p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">venta </p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">costo</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">beneficioBruto</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">beneficioMes</p>
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Definición </b></p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Total de venta del día.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Total de costo del día.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Total de beneficio bruto del día.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Total de beneficio acumulado del mes.</p>
            </v-col>
          </v-row>
        </center>
  </v-col>
      </v-col>
      <!-- Columan 3 -->
      <v-col cols="12" md="2" sm="2" class=" py-0 my-0 ">
        <v-text-field label="Hora"
                    style="font-size:13px;"
                     dense outlined
                     v-model="mensajeriaBeneficioDiario.hora"
                     type="time"
                     hide-details
                     id="formdisabledInput"
                     :disabled="mensajeriaBeneficioDiario.automatico==false"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>
                  <div :class="`ma-1 pl-3 ml-3`" >
                    <v-checkbox  :class="`ma-1`" color="teal" label="" v-model="mensajeriaBeneficioDiario.activada" hide-details
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="mensajeriaBeneficioDiario.activada == true"
                                      style="color:#000000; font-size:13px;">Activada: Si</strong>
                                    <strong v-if="mensajeriaBeneficioDiario.activada == false"
                                      style="color:#000000; font-size:13px;">Activada: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox :class="`ma-1`"  color="teal" label="" v-model="mensajeriaBeneficioDiario.automatico" hide-details
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="mensajeriaBeneficioDiario.automatico == true"
                                      style="color:#000000; font-size:13px;">De proceso: Si</strong>
                                    <strong v-if="mensajeriaBeneficioDiario.automatico == false"
                                      style="color:#000000; font-size:13px;">De proceso: No</strong>
                                  </template>
                                </v-checkbox>
                              </div>   
      </v-col>
    </v-row>
  </v-form> 
  </v-col>
  <!-- fn cuerpo formulario de Beneficio diario / del dia -->
</v-card>
</v-col>
<!--------------------------------------------------- fn Beneficio diario / del dia -->

<!---------------------------------------------------  Facturas anuladas del día -->
<v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

<v-card>
  <v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span><b>3- Facturas anuladas del día</b></span>
  </v-col>
  <!-- cuerpo formulario de Facturas anuladas del día--><!-- style="border: 1px solid #000000;" -->
  <v-col cols="12" md="12" sm="12">
    <v-form v-model="valid" ref="formFacturasAnulada" @submit.prevent="" lazy-validation> 
    
    <v-row>
      <!-- Columan 1 -->
      <v-col cols="12" md="5" sm="5" class=" py-0 my-0" >
        <v-textarea :rules="[$rules.required]" autocomplete="off" label="Diseño de mensaje:" rows="2" dense outlined v-model="mensajeriaFacturasAnuladas.mensaje">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-commenting"></i>
                      </template>
                    </v-textarea>       
      </v-col>
      <!-- Columan 2 -->
      <v-col cols="12" md="5" sm="5" class=" py-0 my-0" >
<!-- style="background-color:#BDBDBD;" -->
        <v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
          <!-- <center><span style="font-size: 13px;"><b>Campos Disponible</b></span> -->
            <center>
              <v-row style="font-size: 13px;">
            <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Campo </b></p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Fecha </p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">cantidadAnulada</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">valorAnulada</p>
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> Definición </b></p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Fecha del día.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Cantidad de facturas anulada.</p>
              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">Valor total de facturas anulada.</p>
            </v-col>
          </v-row>
        </center>
  </v-col>
      </v-col>
      <!-- Columan 3 -->
      <v-col cols="12" md="2" sm="2" class=" py-0 my-0 ">
        <v-text-field label="Hora"
                    style="font-size:13px;"
                     dense outlined
                     v-model="mensajeriaFacturasAnuladas.hora"
                     type="time"
                     hide-details
                     id="formdisabledInput"
                     :disabled="mensajeriaFacturasAnuladas.automatico==false"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>
                  <div :class="`ma-1 pl-3 ml-3`" >
                    <v-checkbox  :class="`ma-1`" color="teal" label="" v-model="mensajeriaFacturasAnuladas.activada" hide-details
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="mensajeriaFacturasAnuladas.activada == true"
                                      style="color:#000000; font-size:13px;">Activada: Si</strong>
                                    <strong v-if="mensajeriaFacturasAnuladas.activada == false"
                                      style="color:#000000; font-size:13px;">Activada: No</strong>
                                  </template>
                                </v-checkbox>

                                <v-checkbox :class="`ma-1`"  color="teal" label="" v-model="mensajeriaFacturasAnuladas.automatico" hide-details
                                  width="50">
                                  <template v-slot:label>
                                    <strong v-if="mensajeriaFacturasAnuladas.automatico == true"
                                      style="color:#000000; font-size:13px;">De proceso: Si</strong>
                                    <strong v-if="mensajeriaFacturasAnuladas.automatico == false"
                                      style="color:#000000; font-size:13px;">De proceso: No</strong>
                                  </template>
                                </v-checkbox>
                              </div>   
      </v-col>
    </v-row>
  </v-form> 
  </v-col>
  <!-- fn cuerpo formulario de Facturas anuladas del día -->
</v-card>
</v-col>
<!--------------------------------------------------- fn Factura anuladas del dia -->




     </v-row>
    </v-col>
  </v-form>

 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


 <!-- ------------------------------------------------------ notif-->
 <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarConf'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
  
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    EventBus.$on("onResize", this.onResize);

    this.cargarDatosGeneral()
  },

  data:()=>  ({

  windowSize:{width:"auto", height:"auto"},

  listTelefono: [],
  tel:{
  nombre:'',
  telefono:''
},

mensajeriaCuadreCaja:{
id:0,
mensaje:'',
hora:null,
activada:true,
automatico:false,
titulo:'Cuadre de caja'
},

mensajeriaBeneficioDiario:{
id:0,
mensaje:'',
hora:null,
activada:true,
automatico:false,
titulo:'Beneficio diario'
},

mensajeriaFacturasAnuladas:{
id:0,
mensaje:'',
hora:null,
activada:true,
automatico:false,
titulo:'Facturas anuladas del día'
},



EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {
      this.acti_tb_data=false
    },
    save() { 
//formCuadreCaja,formBeneficioDiario,formFacturasAnulada
  var OBJ_LIST_MENSAJERIA=[]
 
 
if(this.listTelefono.length==0){
  this.alerta("Debe de agregar al menos un teléfono a la lista", "error");
  return
}


  if(!this.$refs.formCuadreCaja.validate()) {
    this.alerta("debe de digitar el diseño de mensaje del cuadre de caja", "error");
    return
  }
  OBJ_LIST_MENSAJERIA.push(this.mensajeriaCuadreCaja)


  if(!this.$refs.formBeneficioDiario.validate()) {
    this.alerta("debe de digitar el diseño de mensaje del Beneficio diario", "error");
    return
  }
  OBJ_LIST_MENSAJERIA.push(this.mensajeriaBeneficioDiario)


  if(!this.$refs.formFacturasAnulada.validate()) {
    this.alerta("debe de digitar el diseño de mensaje del Facturas anuladas", "error");
    return
  }
  OBJ_LIST_MENSAJERIA.push(this.mensajeriaFacturasAnuladas)

this.$axios
.post(this.$hostname + this.$hName + "/mensajeria/saveTalefono/", this.listTelefono, { headers: this.$store.getters.GetheadersAxios })
.then(res => { 
this.alerta("Dato guardado correctamente!", "bien")
var _this=this
setTimeout(function(){ _this.cargarListaTelefono(res.data) }, 300);

})
.catch(error => this.alerta("Dato no guardado correctamente", "error"));


this.$axios
.post(this.$hostname + this.$hName + "/mensajeria/saveMensajeria/", OBJ_LIST_MENSAJERIA, { headers: this.$store.getters.GetheadersAxios })
.then(res => { 
this.alerta("Dato guardado correctamente!", "bien") 
var _this=this
setTimeout(function(){ _this.cargarListaMensajeria(res.data) }, 300);
})
.catch(error => this.alerta("Dato no guardado correctamente", "error"));


    },


    remove() { },
    eliminar() { },
    anular() {},
    imprimirList() {},
    newwnuevo() {


this.tel={
  nombre:'',
  telefono:''
}
this.listTelefono=[]
this.mensajeriaCuadreCaja={
id:0,
mensaje:'',
hora:null,
activada:true,
automatico:false,
titulo:'Cuadre de caja'
}

this.mensajeriaBeneficioDiario={
id:0,
mensaje:'',
hora:null,
activada:true,
automatico:false,
titulo:'Beneficio diario'
}

this.mensajeriaFacturasAnuladas={
id:0,
mensaje:'',
hora:null,
activada:true,
automatico:false,
titulo:'Facturas anuladas del día'
}

},

NotifMessage() {

if (this.obj.codigo != 0 && this.obj.codigo != null) {

  var notif = {
    estado: true,
    nombre: "¿Seguro que desea eliminar este registro?",
    Exec: "eliminarConf"
  }

  this.Exec_notif(notif)
} else { this.alerta("Seleccione un Mensajero para eliminar", "error") }


},

Exec_notif(e) {

//console.log(e)
this.notif = JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
},



// Agregar un nuevo objeto a la matriz y resetea los campo del agregar a lista de telefono.
addTel() {


  if(this.$refs.formListTelefono.validate()) {
    this.listTelefono.push(this.tel);

  this.tel={
  nombre:'',
  telefono:''
}
  }else {
    this.alerta("los campos en rojo son requeridos", "error");
      }


      //addTel,[{ telefono: '809-555-5555' ,eliminar:''}],ListTel
    },

    // Eliminando el elemento de la matriz listTelefono.
    deleTel(item) {
      const index = this.listTelefono.indexOf(item);
      confirm("Desea eliminar este teléfono?") && this.listTelefono.splice(index, 1);
    },

    // Eliminando el elemento de la matriz listTelefono y lo pasa a los campos de telefono para editarlo.
    editTel(item) {
      this.tel=JSON.parse(JSON.stringify(item))
      const index = this.listTelefono.indexOf(item);
      this.listTelefono.splice(index, 1);
    },

// Haciendo dos llamadas a la misma API, una para la lista de teléfonos y otra para la lista de
// mensajes.

    cargarDatosGeneral() {
      this.$axios
        .get(this.$hostname + this.$hName + "/mensajeria/findallTelefono/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {this.cargarListaTelefono(res.data)});


        this.$axios
        .get(this.$hostname + this.$hName + "/mensajeria/findallMensajeria/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {this.cargarListaMensajeria(res.data)} );


    },

  // Tomando los datos de la entrada y colocándolos en la matriz listTelefono.
    cargarListaTelefono(e){
      this.listTelefono=JSON.parse(JSON.stringify(e))
    },

    cargarListaMensajeria(e){
      e.forEach(element => {
        if(element.titulo=='Cuadre de caja'){
          var OBJ_CUADRE = JSON.parse(JSON.stringify(element))
          OBJ_CUADRE.id=0
          this.mensajeriaCuadreCaja=OBJ_CUADRE
        }
        if(element.titulo=='Beneficio diario'){
          var OBJ_BENEFICIO = JSON.parse(JSON.stringify(element))
          OBJ_BENEFICIO.id=0
          this.mensajeriaBeneficioDiario=OBJ_BENEFICIO
        }
        
        if(element.titulo=='Facturas anuladas del día'){
          var OBJ_FACTURAS_ANULADAS = JSON.parse(JSON.stringify(element))
          OBJ_FACTURAS_ANULADAS.id=0
          this.mensajeriaFacturasAnuladas=OBJ_FACTURAS_ANULADAS
        }
      });

    },


alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    },

  },

computed:{

  ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}


};
</script>

<style></style>
