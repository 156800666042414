<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Reporte Diario General'"
  :descripcion="'Imprime Diario General'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesDiarioGeneral'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Reporte Diario General </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
   <v-text-field label="* Fecha inicial:"
        dense
        outlined
        type="date"  
         :rules="[$rules.required]"
        v-model="DiarioGeneral.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

    </v-text-field>

<v-combobox
                    v-model="DiarioGeneral.cuentaIni"
                    label="Cuenta inicial:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbReportcuentaInicio"
                    @focus="CargarRcuenta"
                    
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
                        </v-combobox>


<!-- -----------------------------  Mes actual  @blur="SelctCbClient"  @focus="cargarCliente"-->
<v-combobox
                        autocomplete="off"
                        dense
                        outlined
                        label="Libro auxiliar:"
                        :items="Rlibro"
                        item-text="nombre"
                        v-model="DiarioGeneral.Libro"
                        @blur="SelctCbReportLibroAux"
                              
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-leanpub"
                          ></i>
                        </template>
                      </v-combobox>

<v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Resumido:"
              v-model="DiarioGeneral.Resumen"
              @change="ChabgeResumido"
            >
          <template v-slot:label>
         <strong v-if="DiarioGeneral.Resumen==true" style="color:#000000;">Resumido: Si</strong>
         <strong v-if="DiarioGeneral.Resumen==false" style="color:#000000;">Resumido: No</strong>
        </template>
</v-switch>


<v-switch
v-if="$store.getters.GetdatosInfUsu.usuario.dsc"
              color="teal"
              inset 
              hide-details
              label="DSC:"
              v-model="DiarioGeneral.dsc"
            >
          <template v-slot:label>
         <strong v-if="DiarioGeneral.dsc==true" style="color:#000000;">DSC: Si</strong>
         <strong v-if="DiarioGeneral.dsc==false" style="color:#000000;">DSC: No</strong>
        </template>
 </v-switch>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="* Fecha final:"
        v-model="DiarioGeneral.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>




<v-combobox
                    v-model="DiarioGeneral.cuentaFin"
                    label="Cuenta Final:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbReportcuentaFinal"
                    @focus="CargarRcuenta"
                    
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
                        </v-combobox>


<!------------------------------------------ Documentos -->
     <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Documentos:"
            :items="RDocumento"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="DiarioGeneral.documento"
            @blur="SelctCbDocumento"
            @focus="cargarDocumento"   
          >
            <template v-slot:prepend>
              <i style="font-size: 30px;" class="fa fa-book"></i>
            </template>
          </v-combobox>

<v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Solo Descuadradas:"
              v-model="DiarioGeneral.descuadre"
              @change="ChabgeDescuadre"
            >
          <template v-slot:label>
         <strong v-if="DiarioGeneral.descuadre==true" style="color:#000000;">Solo Descuadradas: Si</strong>
         <strong v-if="DiarioGeneral.descuadre==false" style="color:#000000;">Solo Descuadradas: No</strong>
        </template>
</v-switch>




</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="DiarioGeneral"
/>

  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
// import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() { 
    var json = {  
      titulo: "Reporte Diario General",
      descripcion: "Imprime Diario General",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [ 
        "ReportesDiarioGeneralNew",
        "ReportesDiarioGeneralSave",
        "ReportesDiarioGeneralRemove",
        "ReportesDiarioGeneralAnular",
        "ReportesDiarioGeneralPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesDiarioGeneralNew", this.new);
    // EventBus.$on("ReportesDiarioGeneralSave", this.save);
    // EventBus.$on("ReportesDiarioGeneralRemove", this.remove);
    // EventBus.$on("ReportesDiarioGeneralAnular", this.remove);
    // EventBus.$on("ReportesDiarioGeneralPrintList", this.imprimirList);
    EventBus.$on("emitReportesDiarioGeneral", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({


      Rlibro:[
        {
          codigo:0,
          sigla:"PV",
          nombre:"Puntos de Venta"
        },

         {
          codigo:1,
          sigla:"CXC",
          nombre:"Cuentas Por Cobrar"
        },
     
        {
          codigo:2,
          sigla:"CXP",
          nombre:"Cuentas Por Pagar"
        },
     
       {
          codigo:3,
          sigla:"INV",
          nombre:"Invetario"
        },

        {
          codigo:4,
          sigla:"CONT",
          nombre:"Gastos"
        },

        {
          codigo:5,
          sigla:"BAN",
          nombre:"Bancos"
        },

        {
          codigo:6,
          sigla:"ENT",
          nombre:"Contabilidad"
        }
        
      ],
      RDocumento:[],
      Rcuenta:[],
      RfilCuenta:"cuenta",

      windowSize:{width:"auto", height:"auto"},
      DiarioGeneral: {
        nombre:'REPORTE DIARIO GENERAL',
        UrlImprimir: '/reportescont/ImprimirDiarioGeneral', 
        UrlExel: '/reportescont/ExcelDiarioGeneral', 
        UrlCorreo: '/reportescont/CorreoDiarioGeneral', 

        desde: fechaNueva(),
        hasta: fechaNueva(),
        Resumen:true,
        cuentaIni:null,
        cuentaFin:null,
        Libro:null,
        documento:null,
        descuadre:false,
        dsc:false

        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },








      EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
    
      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.DiarioGeneral = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() {},
    imprimirList() {},
    DDataReporteBasico(){},
    newwnuevo() {
      (this.DiarioGeneral = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },





cargarDocumento(){

if(this.DiarioGeneral.Libro!=null){ 

this.$axios.get(this.$hostname+this.$hName+'/entradadiario/getDocumentoXmodulo/'+this.DiarioGeneral.Libro.sigla,{headers:this.$store.getters.GetheadersAxios})
.then(res=>{ console.log('InforMInforma'),console.log(res.data),this.RDocumento=res.data}  )

}else{

this.RDocumento=[]
this.DiarioGeneral.documento=null
this.alerta("Seleccione un libro auxiliar para cargar un documento", "error");

}

},


SelctCbDocumento(){
  if(this.DiarioGeneral.documento==null){

    this.DiarioGeneral.documento=''
  }

if(this.DiarioGeneral.documento.codigo== undefined){

//this.alerta("Seleccione un documento", "error");
this.DiarioGeneral.documento=''
return

}

},


CargarRcuenta() {
   this.$axios.get(this.$hostname + this.$hName + '/catalogo/findall', {headers:this.$store.getters.GetheadersAxios})
   .then(res => {
        this.Rcuenta = res.data;
      });
    },



SelctCbReportcuentaInicio() {
if (this.DiarioGeneral.cuentaIni == null) {
        this.DiarioGeneral.cuentaIni = "";
      }

      if (this.DiarioGeneral.cuentaIni.cuenta == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.DiarioGeneral.cuentaIni = "";
        return;
      }
}, 

SelctCbReportcuentaFinal() {
if (this.DiarioGeneral.cuentaFin == null) {
        this.DiarioGeneral.cuentaFin = "";
      }

      if (this.DiarioGeneral.cuentaFin.cuenta == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.DiarioGeneral.cuentaFin = "";
        return;
      }
}, 



SelctCbReportLibroAux() {
if (this.DiarioGeneral.Libro == null) {
        this.DiarioGeneral.Libro = "";
        this.DiarioGeneral.documento= "";
      }

      if (this.DiarioGeneral.Libro.codigo == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.DiarioGeneral.Libro = "";
        this.DiarioGeneral.documento = "";
        return;
      }
}, 







CbFilCuenta(e){

if(e.target.value.length==1){

if(e.target.value.charCodeAt(0)>=97 & e.target.value.charCodeAt(0)<=122 ){

//console.log("ok letra")

this.RfilCuenta="descripcion"


this.CargarRcuenta()

}else{this.RfilCuenta="cuenta"}



if(e.target.value.charCodeAt(0)>=48 & e.target.value.charCodeAt(0)<=57 ){
this.CargarRcuenta()
//console.log("ok numero")
this.RfilCuenta="cuenta"

}else{this.RfilCuenta="descripcion"}

  //numero 48 a 57
  //letras 97 a 122 

}


},


ChabgeResumido(e){
  if(e==true){
  this.DiarioGeneral.descuadre=!e
}
},

ChabgeDescuadre(e){
  if(e==true){
    this.DiarioGeneral.Resumen=!e
  }
  
},

BlurDesde(){

if(this.DiarioGeneral.desde.toString().length==0){
  this.DiarioGeneral.desde=this.fechaNueva()
}


 },


 BlurHasta(){

if(this.DiarioGeneral.hasta.toString().length==0){
  this.DiarioGeneral.hasta=this.fechaNueva()
}

 },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    
    }
  }
};
</script>

<style></style>
