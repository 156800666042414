<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
<!-- ContenidoBase-Bancos -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Bancos'" :descripcion="'Crear y Modificar Bancos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>
<!-- fn ContenidoBase-Bancos-->

      <tr>
        <!-- BarraPrimaria-Bancos --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>
                <BarraDinamicaNormal ref="BarraDiNor" :url="
                  $hostname + $hName + '/bancos/findall'
                " :nombre="'Banco'" :headers="headers" :slotname="'item.nombre'" :emitnombre="'emitBANBancos'"
                  :SeachNombre="'Búsqueda general'" :ContListSlot="[
                    { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Nombre', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
                    { Text: 'RNC', NomValue: 'rnc', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.1, State: true },
                  ]" />
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Bancos  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <!-- Formulario-Bancos -->
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">


                  <v-form ref="formBanco" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 ---------------------------------------->
                      <v-col cols="12" md="4" sm="4">
                        <!-- ----------------------------- nombre -->
                        <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="Bancos.nombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- direccion -->
                        <v-textarea autocomplete="off" label="* Dirección:" dense outlined v-model="Bancos.direccion"
                          :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- fn columna 1---------------------------------------->


                      <!-- columna 2 ---------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!-- ----------------------------- Ciudad -->

                        <v-text-field label="* Ciudad:" dense outlined autocomplete="off" v-model="Bancos.ciudad"
                          :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- telefonos -->
                        <v-text-field label="teléfono:" dense outlined autocomplete="off" maxlength="13"
                          v-mask="'(###)###-####'" v-model="Bancos.telefono">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>
                        <!-- ----------------------------- Contacro -->
                        <v-text-field label="Contacto" autocomplete="off" dense outlined v-model="Bancos.contacto">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-location-arrow"></i>
                          </template>
                        </v-text-field>

                        <div cols="12" md="12" sm="12" v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true" >
                        <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                  :rules="[$rules.required]" v-model="Bancos.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda" 
                  @change="ChangeMonedaTasa">
                  
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                      {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                  </template>


                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
                        </v-combobox>
                       </div>



                      </v-col>
                      <!-- fn columna 2 ---------------------------------------->

                      <!--  columna 3 ---------------------------------------->

                      <v-col cols="12" md="4" sm="4">

                        <!-- -----------------------------  email -->
                        <v-text-field label="E-mail Contacto:" dense outlined v-model="Bancos.correoContacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- telefonos -->
                        <v-text-field label="teléfono de Contacto:" dense outlined autocomplete="off" maxlength="13"
                          v-mask="'(###)###-####'" v-model="Bancos.telefonocontacto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------rnc -->

                        <v-text-field autocomplete="off" v-mask="'###-#####-#'" label="RNC:" dense outlined
                          maxlength="11" :rules="[$rules.RulesRnc]" v-model="Bancos.rnc">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                          </template>
                        </v-text-field>


                      <div cols="12" md="4" sm="4" v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true" >
                        <v-text-field v-if="Bancos.moneda != null" id="formdisabledInput" label="Tasa Compra:" dense outlined
                        v-model.number="Bancos.tasa">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                        </v-text-field>
                      </div>


                        <!-- <v-switch
                    label="Activo"
                    v-model="Bancos.activo"
                  ></v-switch> -->


                      </v-col>
                      <!-- fn columna 3 ---------------------------------------->


                    </v-row>
                  </v-form>
                </div>
              </v-card>
            </div>
          <!-- fn Formulario-Bancos -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Codigo', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Nombre', value: 'nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Dirección', value: 'direccion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Teléfono', value: 'telefono', sortable: false, class: ['no-gutters black--text'] },
              { text: 'RNC', value: 'rnc', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Ciudad', value: 'ciudad', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ tb-data todo general -->
    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarBancos'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">

      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
  },
  created() {
    var json = {
      titulo: "Bancos",
      descripcion: "Crear y Modificar Bancos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "BANBancosNew",
        "BANBancosSave",
        "BANBancosRemove",
        "BANBancosAnular",
        "BANBancosPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  //mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANBancosNew", this.new);
    // EventBus.$on("BANBancosSave", this.save);
    // EventBus.$on("BANBancosRemove", this.remove);
    // EventBus.$on("BANBancosAnular", this.remove);
    // EventBus.$on("BANBancosPrintList", this.imprimirList);
    EventBus.$on("emitBANBancos", this.datostabla);
    this.cargarTodo();
    this.getMonedaBase()
    EventBus.$on("onResize", this.onResize);
  },
// fn mounted-carga de inicio
  data() {
    return {
      ListaTiposClientes: [],
      Rmoneda:[],
      windowSize: { width: "auto", height: "auto" },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: ' BANCOS',
        UrlImprimir: '/bancos/ImprimirListBanco',
        UrlExel: '/bancos/ExcelListBanco',
        UrlCorreo: '/bancos/CorreoListBanco',



      },


      Bancos: {
        codigo: 0,
        nombre: "",
        telefonocontacto: "",
        correoContacto: "",
        direccion: "",
        ciudad: "",
        telefono: "",
        contacto: "",
        activo: true,
        rnc: null,
        activado: true,
        moneda:null,
        tasa:null

      },


      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },


      text: "",
      valid: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Codigo", value: "codigo" },
      { text: "Nombre", value: "nombre" },
      { text: "RNC", value: "rnc" },
      { text: "Ciudad", value: "ciudad" },
      ],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {
      //this.windowSize=e
    },





    // Abriendo una tabla y luego filtrando la tabla.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }

    },
   // Hacer una llamada axios al backend y devolver los datos al frontend.
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/bancos/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    // Tomando los datos de la base de datos y poniéndolos en una tabla.
    datostabla(e) {
      var obj = JSON.parse(JSON.stringify(e))
      obj.rnc = e.rnc.trim()
      if (e.telefono != null) { obj.telefono = e.telefono.trim() }
      if (e.telefonocontacto != null) { e.telefonocontacto.trim() }
      this.Bancos = obj;
      this.acti_tb_data = false
    },
    // Envío de una solicitud POST al servidor.
    save() {
      if (this.$refs.formBanco.validate()) {
        this.$axios
          .post(
            this.$hostname + this.$hName +
            "/bancos/salvar", this.Bancos,
            { headers: this.$store.getters.GetheadersAxios })
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error => this.alerta("Dato no guardado", "error"));
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },



    // Crear un objeto de notificación y luego llamar al método Exec_notif().
    NotifMessage() {
      if (this.Bancos.codigo != 0 && this.Bancos.codigo != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar banco?",
          Exec: "eliminarBancos"
        }
        this.Exec_notif(notif)
      } else { this.alerta("Seleccione un bancos para eliminar", "error") }
    },
    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))
    },
    eliminar() {
      if (this.Bancos.codigo != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/bancos/borrar", this.Bancos,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { if (res.data) { this.alerta("Datos eliminados correctamente", "bien") } })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    anular() {


      this.$axios
        .post(
          this.$hostname +
          this.$hName +
          "/bancos/borrar", this.Bancos,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
        .catch(error =>
          this.alerta(
            // "hubo un error , por favor verifique el campo descripcion!"
            "Dato no eliminado",
            "error"
          )
        );

    },
    //Muestra un modal con las opciones de la imprecion
    imprimirList() {

      this.dialogimprimirList = true
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

   // Creando un nuevo objeto y asignándolo a la variable Bancos.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.Bancos = {
        codigo: 0,
        nombre: "",
        telefonocontacto: "",
        correoContacto: "",
        direccion: "",
        ciudad: "",
        telefono: "",
        contacto: "",
        activo: true,
        rnc: null,
        activado: true,
        moneda:null,
        tasa:null

      }
      this.getMonedaBase()
      // EventBus.$emit("actualizaBarraBusqueda2");
    },


    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.Bancos.moneda= MONEDA
  this.Bancos.tasa=MONEDA.tasa
},

   cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.Bancos.moneda == null) {
          _this.Bancos.moneda = "";
        }

        if (!_this.Bancos.moneda.hasOwnProperty('codigo')) {
          _this.Bancos.moneda = "";
          _this.Bancos.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},


ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.Bancos.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
   // Un método que se llama cuando el usuario hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

   // Llamar a la API y obtener los datos.
    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/bancos/findall", { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },



  computed: {

    ActDescBtn() {
      if (this.Bancos.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      if (this.Bancos.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }




};
</script>

<style>
</style>
