<template>


<div>
  <v-card
              color="grey darken-3"
              dark
              raised
              shaped
             
            >
             
          <v-list-item>
      <v-list-item-content>

<span class="py-0 fa fa-id-card-o" v-if="Obje.hasOwnProperty('cedrnc')  &&  Obje.cedrnc!=null     "> {{Obje.cedrnc}}</span>
<span class="py-0 fa fa-money" v-if="Obje.hasOwnProperty('balancecliente')  &&  Obje.balancecliente.balance!=null"  > Balance: ${{currencyFormatter(Obje.balancecliente.balance)}}</span>
<span class="py-0 fa fa-envelope-o" v-if="Obje.email!=null && Obje.email.length>0"> {{Obje.email}}</span>
<span class="py-0 fa fa-map-marker" v-if="Obje.ciudad!=null" > {{Obje.ciudad}}, {{Obje.direccion}}</span>
<br>
<span class="py-0 fa fa-phone" v-if="Obje.hasOwnProperty('telefonosClientesList') && Obje.telefonosClientesList.length>0" > teleforno:</span >  
<span class="py-0" v-for="(tl, i) in Obje.telefonosClientesList" :key="i">{{i+1}}) {{tl.telefonosClientesPK.telefono}}</span > 



        <!-- <h2>{{form.lote.ars.nombre}}</h2>
        <span v-if="form.lote.hasOwnProperty('sucursal')"><span v-if="form.lote.sucursal!=null"> {{form.lote.sucursal.telefono}}</span></span>
       <span v-if="form.lote.hasOwnProperty('sucursal')"><span v-if="form.lote.sucursal!=null">{{form.lote.sucursal.direccion}}</span></span>
        <span>$ {{currencyFormatter(form.lote.valor)}}</span>
        <span v-if="form.lote.enviado==true">Enviado</span>
        <span v-if="form.lote.enviado==false"> sin Enviar</span> -->
      </v-list-item-content>
    </v-list-item>   

    <!-- <v-row > max-width="180"     -->


<!-- -----------------------------  col 1 -->
<!-- <v-col cols="6" md="6"  >
 <p style="display:none;">{{cargarTelefono()}}</p>
<v-card-text class="py-0 fa fa-id-card-o" v-if="Obje.cedrnc!=null"  > {{Obje.cedrnc}}</v-card-text>
<v-card-text class="py-0 fa fa-money" v-if="Obje.balancecliente.balance!=null"  > Balance: ${{currencyFormatter(Obje.balancecliente.balance)}}</v-card-text>
<v-card-text class="py-0 fa fa-envelope-o" v-if="Obje.email!=null && Obje.email.length>0"> {{Obje.email}}</v-card-text>
<v-card-text class="py-0 fa fa-map-marker" v-if="Obje.ciudad!=null" > {{Obje.ciudad}}, {{Obje.direccion}}</v-card-text>

</v-col> -->
 <!-- ----------------------------- fn  col 1 -->

<!-- -----------------------------  col 2 -->

 <!-- <v-col cols="6" md="6"> 
<v-card-text class="py-0 fa fa-phone" v-if="telefono.length>0" > teleforno:</v-card-text>  
<v-card-text class="py-0" v-for="(tl, i) in telefono" :key="i">{{i+1}}) {{tl.telefonosClientesPK.telefono}}</v-card-text> 

</v-col> -->

<!-- ----------------------------- fn col 2 -->

 <!--  este..* <v-card-text>

<v-card-text class="py-0 fa fa-id-card-o" v-if="Obje.cedrnc!=null"> {{Obje.cedrnc}}</v-card-text>
<v-card-text class="py-0 fa fa-money" v-if="Obje.balancecliente.balance!=null"  > Balance: ${{currencyFormatter(Obje.balancecliente.balance)}}</v-card-text>
<v-card-text class="py-0 fa fa-envelope-o" v-if="Obje.email!=null && Obje.email.length>0"> {{Obje.email}}</v-card-text>
<v-card-text class="py-0 fa fa-map-marker" v-if="Obje.ciudad!=null" > {{Obje.ciudad}}, {{Obje.direccion}}</v-card-text>
<br>
<v-card-text class="py-0 fa fa-phone" v-if="Obje.telefonosClientesList.length>0" > teleforno:</v-card-text >  
<v-card-text class="py-0" v-for="(tl, i) in Obje.telefonosClientesList" :key="i">{{i+1}}) {{tl.telefonosClientesPK.telefono}}</v-card-text > 


</v-card-text>


  </v-row>  -->
</v-card>

</div>

</template>

<script>
export default {

created(){

},
data() {
    return {

telefono:[]

    }
    },



    props:{

Obje: {
      required: true,
      type: Object 
    },





    },

    methods:{

// cargarTelefono(){

// this.$axios.post(this.$hostname + this.$hName + '/services/clienteservices/ClienteTelefono', {"codigo":+this.Obje.codigo})
// .then(res =>  {this.telefono=res.data });


// },


currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },


    }

}
</script>

<style>

#Telefono {
	position: absolute;
	width: 344px;
	height: 346px;
	z-index: 1;
	left: 280px;
	top: 27px;
}



</style>