<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"  >  
<ContenidoBase 
  :titulo="'Reporte Vencimiento de Producto'"
  :descripcion="'Imprime Lista de Vencimiento de Producto.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesListaReciboIngreso'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Lista de Vencimiento de Producto </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha de Corte:"
         :rules="[$rules.required]"
        v-model="VencimientoProducto.fecha"
        @blur="Blurfecha"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


<!-------------------------------------------------- suplidor -->
 <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Proveedor:"
            :items="Rproveedor"
            item-text="nombre" 
            v-model="VencimientoProducto.proveedor"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            
            
  
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
                      </template>             
</v-combobox>


<!-------------------------------------------------- Valor-->
<VueAutonumeric label="Días de Entrega Anticipada:" 
  dense 
  outlined
 autocomplete="off"
  v-model.number="VencimientoProducto.diaentrega"
  :rules="[$rules.required]" 
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!------------------------------------------ Fabricante -->
<!--@keyup="CbFil", @blur="SelctCbDesc", @focus="", @change="VerifEspecial"  -->
                      <v-combobox                 
                        autocomplete="off"
                        dense
                        outlined
                        label="Laboratorio:"
                        :items="RFabricante"
                        item-text="nombre"
                        :rules="[$rules.required]"
                        v-model="VencimientoProducto.fabricante" 
                        @focus="CargarFabricante"
                        @blur="SelctCbFabricante"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-flask"
                          ></i>
                        </template>
                      </v-combobox>              
<v-combobox
                    v-model="VencimientoProducto.almacen"
                    label="Almacén"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="Ralmacen"
                    item-text="descripcion"
                    @blur="SelctCbAlmacen"
                    @focus="CargarRalmacen"
                  >
                    <template v-slot:prepend>
                      <v-img
                        height="20px"
                        width="20px"
                        src="../../assets/warehouse.png"
                      ></v-img>
                    </template>
                  </v-combobox>

</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>
<ModalReporteFiltros
 ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="VencimientoProductoLink"
/>


  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {  
      titulo: "Reporte Vencimiento de Producto",
      descripcion: "Imprime Lista de Vencimiento de Producto.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,

      accion: [ 

        "ReportesVencimientoProductoNew",
        "ReportesVencimientoProductoSave",
        "ReportesVencimientoProductoRemove",
        "ReportesVencimientoProductoAnular",
        "ReportesVencimientoProductoPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReportesVencimientoProductoNew", this.new);
    EventBus.$on("ReportesVencimientoProductoSave", this.save);
    EventBus.$on("ReportesVencimientoProductoRemove", this.remove);
    EventBus.$on("ReportesVencimientoProductoAnular", this.remove);
    EventBus.$on("ReportesVencimientoProductoPrintList", this.imprimirList);
    EventBus.$on("emitReportesVencimientoProducto", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto=this.validarObjecto;
  },

  data:()=>  ({

      Rpropietario:[],
      RFabricante:[],
      Rproveedor:[], 
      Ralmacen:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

    VencimientoProductoLink:{

      nombre:'REPORTE VENCIMIENTO DE PRODUCTO',
        UrlImprimir: '/reportesInv/productos-proximo-vencer', 
        UrlExel: '/reportesInv/Exelproductos-proximo-vencer', 
        UrlCorreo: '/reportesInv/correo-productos-proximo-vencer',

    },

      VencimientoProducto: {
        fecha: fechaNueva(),
        proveedor:null,
        fabricante:null,
        diaentrega:0,
        almacen:null
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {

      this.VencimientoProducto = e;
      this.acti_tb_data=false
    },
    save() {},
    remove() {},
    eliminar() { },
    anular() {},
    imprimirList() {},
    DDataReporteBasico(){},
    validarObjecto(){

var where=""
var where2=""
  //where+=` and fv.fecha >= ' ${new Date(this.VencimientoProducto.desde).toISOString().substr(0,10)} ' and fv.fecha  <=' ${new Date(this.VencimientoProducto.hasta).toISOString().substr(0,10)} '`

this.VencimientoProductoLink.laboratorio_nom="Todos"
this.VencimientoProductoLink.suplidor_nom="Todos"
this.VencimientoProductoLink.almacenNombre=""

      if (this.VencimientoProducto.fabricante != null) {
        this.VencimientoProductoLink.laboratorio_nom=JSON.parse(JSON.stringify(this.VencimientoProducto.fabricante.nombre))
        where+=` and p.fabricante = ${this.VencimientoProducto.fabricante.codigo} `
        }

    if (this.VencimientoProducto.proveedor != null) {
        this.VencimientoProductoLink.suplidor_nom=JSON.parse(JSON.stringify(this.VencimientoProducto.proveedor.nombre))
        where+=` and pr.codigo = ${this.VencimientoProducto.proveedor.codigo} `
        }

    if(this.VencimientoProducto.almacen != null) {
          where+=` and a.codigo = ${this.VencimientoProducto.almacen.codigo}`
           this.VencimientoProductoLink.almacenNombre=JSON.parse(JSON.stringify(this.VencimientoProducto.almacen.descripcion))
        }

this.VencimientoProductoLink.where=where
this.VencimientoProductoLink.where2=where2

this.VencimientoProductoLink.f1=this.VencimientoProducto.fecha
this.VencimientoProductoLink.f2=this.fechaNueva()
this.VencimientoProductoLink.dias=JSON.parse(JSON.stringify(this.VencimientoProducto.diaentrega))


},


    newwnuevo() {
      (this.VencimientoProducto = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarProveedor(){

this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))

},

SelctCbProveedor(){
  if(this.VencimientoProducto.proveedor==null){

    this.VencimientoProducto.proveedor=''
  }

if(this.VencimientoProducto.proveedor.codigo== undefined){

this.alerta("Seleccione un proveedor", "error");
this.VencimientoProducto.proveedor=''

//qlq2

return

}

}, 

CbFilProveedor(e) {
     
        if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"A":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
      
    },


CargarRalmacen() {
     this.$axios
    .get(this.$hostname + this.$hName + "/almacen/activos", 
    {headers:this.$store.getters.GetheadersAxios} 
    )
    .then(res => {
      this.Ralmacen = res.data;
    });

    },

 SelctCbAlmacen() {
      if (this.VencimientoProducto.almacen== null) {
        this.VencimientoProducto.almacen = "";
      }

      if (this.VencimientoProducto.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.VencimientoProducto.almacen = "";
        return;
      }
    },


Blurfecha(){

if(this.VencimientoProducto.fecha.toString().length==0){
  this.VencimientoProducto.fecha=this.fechaNueva()
}

 },


CargarFabricante(){
this.$axios.get(this.$hostname + this.$hName + "/fabricante/activo",
              {headers: this.$store.getters.GetheadersAxios})
              .then(res => this.RFabricante = res.data)
              .catch()
},




SelctCbFabricante(){
 if(this.VencimientoProducto.fabricante==null){

    this.VencimientoProducto.fabricante=''
  }

if(this.VencimientoProducto.fabricante.codigo==undefined){

this.alerta("Seleccione un laboratorio", "error");
this.VencimientoProducto.fabricante=''
return
}

},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {}
  }
};
</script>

<style></style>
