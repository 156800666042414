<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px">
<ContenidoBase 
  :titulo="'CONSULTA DE MANTENIMIENTOS  '"
  :descripcion="'Consulta de mantenimientos'"
   :EstadoBtn="EstadoBtn"
   @MethodNuevo="newwBusquedaConsulta"
   @MethodImprimir="imprimirList"  
/>
  </tr>
    <tr>
  <!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir"   >

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
      <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
  <!-- <v-btn-toggle  ></v-btn-toggle> -->
        <v-btn small width="95%" color="yellow accent-4"    @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;" class="fa fa-eye"> </i>  ver</v-btn>
       
  </center>

<!-- BarraDinamicaNormal -->
   
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-76"   >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'height':this.windowSize.height-76+'px' ,'max-height':this.windowSize.height-76+ 'px'  }">
 <div class="px-2">
 <center>

 <v-col cols="12" md="6" sm="6"> 
<v-card color="grey lighten-3">
<div class="px-2">

  <v-form ref="formConsulta" v-model="valid" lazy-validation>
    <v-row>
     <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-2" >
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
        v-model="BusquedaConsulta.desde"
        @blur="BlurDesde"
         style="font-size:13px;"
         @input="CargarListMantenimiento"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
    </v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-2">
<v-text-field
        dense
        outlined
        type="date" 
        label="Hasta:"
        v-model="BusquedaConsulta.hasta"
        @blur="BlurHasta"
        style="font-size:13px;"
        @input="CargarListMantenimiento"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2">
<v-combobox
                            v-model="BusquedaConsulta.cliente"
                            label="Cliente:"
                            required
                            outlined
                            dense
                            autocomplete="off"
                            :items="Rcliente"
                            item-text="nombre"
                            @blur="SelctCbCliente"
                            @focus="cargarCliente"
                            @keyup="CbFilClient"
                            style="font-size:13px;"
                            @change="CargarListMantenimiento"
                           hide-details
                           
                        >
                        <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('nombre')" >{{item.nombre.trim()}}</span>
                       </template>

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-user-circle-o"
                            ></i>
                          </template>

<!-- <template slot="item" slot-scope="item">
<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>--><!--
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO:</b> {{item.item.codigo}}</p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE: </b>{{item.item.nombre}}</p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="px-2" style="border: 1px dashed  #616161;"><b>TEL.: </b> {{item.item.telefono}}</span>
</span>
</v-col>
 </template> -->

 </v-combobox>
</v-col>

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2">
<v-radio-group class="py-0" @change="CargarListMantenimiento" v-model="BusquedaConsulta.opcion" row>     
                  <!-- <v-radio label="RUTINARIOS" color="green" :value="1"></v-radio>
                  <v-radio label="NO RUTINARIOS" color="green" :value="2"></v-radio>-->
                  <v-radio label="TODOS" color="green" :value="1"></v-radio>
                  <v-radio label="PENDIENTE" color="green" :value="2"></v-radio>                                       
                  <v-radio label="REALIZADO" color="green" :value="3"></v-radio>  
                  <!-- <v-radio label="CANCELADO" color="green" :value="5"></v-radio> -->
                  

</v-radio-group>
</v-col>

   </v-row>
  </v-form>
 
</div>
 </v-card>
</v-col>

 <v-col cols="12" md="12" sm="12"> 
   <div style="border: 1px solid #000000;" >
<!-- <v-data-table
      class="RowSelectColor TbSize13"
       item-key="indx"
      :headers="[{text: 'FECHA', sortable: false,  value: 'fecha', class: ['no-gutters black--text']},
                { text: 'CLIENTE', sortable: false, value: 'cliente', class: ['no-gutters black--text'] },
                { text: 'EQUIPO', sortable: false, value: 'equipo', class: ['no-gutters black--text'] },
                { text: 'TIPO', sortable: false, value: 'tipo', class: ['no-gutters black--text'] },
                { text: 'STATUS', sortable: false, value: 'status', class: ['no-gutters black--text'] }]"
              dense
              :items="listaMante"     
              :items-per-page="15"
              
            >

            <template v-slot:item.fecha="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = listaMante.indexOf(props.item)}}</span>
                <span >{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

</v-data-table> -->


<v-data-table
      class="RowSelectColor TbSize13" 
      item-key="indx"
      :headers="[
               { text: '', value: 'data-table-expand', class: ['no-gutters black--text'] },
               {text: '#MANT', sortable: false,  value: 'id', class: ['no-gutters black--text']},
               {text: 'FECHA', sortable: false,  value: 'nuevaFecha', class: ['no-gutters black--text']},
               { text: 'CLIENTE', sortable: false, value: 'cliente.nombre', class: ['no-gutters black--text'] },
               { text: 'STATUS', sortable: false, value: 'activo', class: ['no-gutters black--text'] },
               { text: 'ESTADO', sortable: false, value: 'anulado', class: ['no-gutters black--text'] },
               ]"
              dense
              :items="listaMante" 
              show-expand  
              :single-expand="true" 
              :expanded.sync="expanded" 
              :items-per-page="15"
            >
              <template v-slot:item.nuevaFecha="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = listaMante.indexOf(props.item)}}</span>
                <span >{{ FormatoFecha(props.item.nuevaFecha) }}</span>
              </template>

              <template v-slot:item.activo="props">
                <span v-if="props.item.activo==true" class="red--text"> Pendiente </span>
                <span v-if="props.item.activo==false" class="green--text"> Realizado </span>
              </template>

               <template v-slot:item.anulado="props">
                <span v-if="props.item.anulado==true" class="red--text"> Anulado </span>
                <span v-if="props.item.anulado==false" class="green--text"> Activo </span>
              </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pa-0 ma-0 px-1 py-1 ">
       <v-data-table
       
      hide-default-footer
      class="RowSelectColor TbSize13" 
      item-key="indx"
      :headers="[{ text: 'EQUIPOS', sortable: false, value: 'refcodigo', class: ['no-gutters black--text'] }]"
              dense
              :items="item.mantenimientoDetalleList"   
              :items-per-page="-1"
            >

                <template v-slot:item.refcodigo="props">
          <span style="font-size:13px; display:none;">{{props.item.indx = item.mantenimientoDetalleList.indexOf(props.item)}}</span>
        <v-col  class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12"> 
          <!-- <span>{{props.item.cantidad}} - {{props.item.unidades.descripcion}} / {{props.item.productos.descripcion}}</span> -->
          <span> {{props.item.descripcion}}</span>
        </v-col>
              </template>

      <template v-slot:no-data>
      <span class=" black--text" >Mantenimiento sin equipos</span>
      </template>
</v-data-table> 
       
      </td>
    </template>

</v-data-table>



</div>
</v-col>
 </center>
</div>  

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
         
        <!-- BarraBusqueda2 -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarBusquedaConsulta'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>



</template>

<script>
import VueAutonumeric from "../../components/AutoNumericNuevo";

import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";


export default {

   components: {
    VueAutonumeric,
    ContenidoBase

  },

mounted() {
this.CargarListMantenimiento()
  },

   created(){
  },
  // destroyed(){
  //   location.reload()
  // },

  data:()=> ({

  windowSize:{width:"auto", height:"auto"},

// <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO: {{item.item.codigo}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DESCRIPCIÓN: {{item.item.descripcion}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>ESPECIALIDAD: {{item.item.especialidad.descripcion}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 red--text" v-if="item.item.activo==true"><b>Activo</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 green--text" v-if="item.item.activo==false"><b>In-Activo</b></p>

expanded:[],

listaMante:[],
Rcliente:[],

RActividad:[],


valid:true,

BusquedaConsulta:{
desde:new Date().toISOString().substr(0,10),
hasta:new Date().toISOString().substr(0,10),
cliente:null,
opcion:1
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },




  ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

       notif: {
        estado: false,
        nombre: "",
        Exec:""
      },



    BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false

}),

 methods: {
   currencyFormatter,FormatoFecha,HoraFormatter,

  
 

newwBusquedaConsulta() {

this.BusquedaConsulta={
codigo:0,
descripcion:null,
especialidad:null,
activo:true
},

this.ejecucion = true

    },

saveBusquedaConsulta() {

 
  if(this.$refs.form.validate()) {


if(this.ejecucion==true){

  this.ejecucion=false
 

this.$axios
        .post(this.$hostname + this.$hName + "//save/",this.BusquedaConsulta,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta("Dato guardado correctamente!", "bienLimpio")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

}      
}else{

              this.alerta("los campos en rojo son requeridos", "error");

       }

},


NotifMessage() {

if(this.BusquedaConsulta.codigo!=0 && this.BusquedaConsulta.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarBusquedaConsulta"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una Registro Especialidades para eliminar", "error")}


    },

 Exec_notif(e) {

this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

 
eliminar() {

alert('Eliminando...')

},

anular(){
},

imprimirList() {

  EventBus.$emit("loading", true); 
var cli=null
var desde=new Date(this.BusquedaConsulta.desde).toISOString().substr(0,10)
var hasta=new Date(this.BusquedaConsulta.hasta).toISOString().substr(0,10)

var inform=`DESDE: ${this.FormatoFecha(desde)}      HASTA:  ${this.FormatoFecha(hasta)}      `

var where=` ma.fecha >=' ${desde} ' and ma.fecha <= ' ${hasta}'`
    
if(this.BusquedaConsulta.cliente!=null){
  cli=this.BusquedaConsulta.cliente.nombre
  where+=` and cl.codigo= ${this.BusquedaConsulta.cliente.codigo} `
  inform+=`    CLIENTE: ${cli}     `
  }

if (this.BusquedaConsulta.opcion==2) { 
  where+=` and ma.activo= true` 
  inform+=`    Mantenimiento: PENDIENTES  `
  }
  if (this.BusquedaConsulta.opcion==3) { 
    where+=` and ma.activo= false`
    inform+=`    Mantenimiento: REALIZADOS  `

  }


var obj={
   where:where,
   inform:inform
 }

this.$axios.post(this.$hostname + this.$hName +'/mantenimientos/imprimirConsultaMant', JSON.parse(JSON.stringify(obj)),
        { headers: this.$store.getters.GetheadersAxios }).then(res => {
          EventBus.$emit("loading", false); 

/*let pdfWindow = window.open("Reporte de imprecion")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>")   */
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'Consulta mantenimiento');

      })

      .catch(error => {
        EventBus.$emit("loading", false); 
        });
      


    },



BlurDesde(){
if(this.BusquedaConsulta.desde.toString().length==0){
  this.BusquedaConsulta.desde=new Date().toISOString().substr(0,10)
}
 },

 BlurHasta(){
if(this.BusquedaConsulta.hasta.toString().length==0){
  this.BusquedaConsulta.hasta=new Date().toISOString().substr(0,10)
}
 },


CbFilClient(e){
  if (e.target.value.length >= 2) {   
    
    this.$axios
           .get(
             this.$hostname +
               this.$hName +
               "/clientes/findtodo/" +
             (e.target.value === "" ? "A" : e.target.value) +
             "/3/25/0",
             {headers:this.$store.getters.GetheadersAxios}
           )
           .then(res => (this.Rcliente = res.data));
    
/*var obj=`/services/clienteservices/findAllClientesCombo/${this.$store.getters.GetdatosInfUsu.userid}/25/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj)
            .then(res => (this.Rcliente = res.data));*/




        }

},

cargarCliente(){

  this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3/25/0',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rcliente=res.data))
 /* var obj=`/services/clienteservices/findAllClientesCombo/${this.$store.getters.GetdatosInfUsu.userid}/25/null`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
  this.Rcliente=res.data
  })*/


},


SelctCbCliente(){

   if (this.BusquedaConsulta.cliente == null) {

        this.BusquedaConsulta.cliente = ''
      }

      if (this.BusquedaConsulta.cliente.codigo == undefined) {

       // this.alerta("Seleccione un registro actividades", "error");
        this.BusquedaConsulta.cliente = ''
        return

      }

},


CargarListMantenimiento(){

 // /findConsultaMant/{usuario}/{desde}/{hasta}/{cliente}

var cli=0
if(this.BusquedaConsulta.cliente!=null){
  cli=this.BusquedaConsulta.cliente.codigo
}


var obj=`/mantenimientos/findConsultaMant/${this.BusquedaConsulta.desde}/${this.BusquedaConsulta.hasta}/${cli}/${this.BusquedaConsulta.opcion}`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res=>{
  this.listaMante=res.data
  })

},


alerta(e, l) {
 if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

      }
  
      if (l == "bienLimpio") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwBusquedaConsultas();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

  },


computed:{

    ActDescBtn(){

if(this.BusquedaConsulta.codigo>0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}


if(this.BusquedaConsulta.codigo<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


}
     

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>