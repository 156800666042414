<template>
<div v-resize="onResize">

<!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->


<!-- ------------------------------------------------------ Modal- nueva cuenta-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Nueva_cuenta" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Crear Cuenta Nueva </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="acti_Nueva_cuenta=!acti_Nueva_cuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>

<NuevaCuenta
@NuevaCuenta="NnuevaCuenta"

/>


</v-card-text>
</v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal nueva cuenta-->




<!----------------------------------------------------------------- Cuenta Contable -->
<v-card>
 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Cuentas Contables </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form  
    ref="formCuenta"
    v-model="valid"
    lazy-validation
  >
<v-row>
   


      <v-col cols="12" md="12">

 <v-combobox
                    v-model="modalCuenta.cuenta"
                    label="* Cuenta:"
                    required
                    outlined
                    dense
                    :rules="[rules.required]"
                    autocomplete="off"
                    :items="Rcuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbcuenta"
                    @keyup="CbFilCuenta"
                    @change="SelctCbCuentaValidControl"
                    @focus="CargarRcuenta"
                  > 
              <template slot="item" slot-scope="item" margin-top="50px">
                        <v-row>
<v-col cols="4" md="4"> <span style="font-size: 14px;"> {{item.item.cuenta }}</span> </v-col>
<v-col cols="7" md="7"> <span style="font-size: 14px;">{{ item.item.descripcion}}</span> </v-col>
<v-col cols="1" md="1"> <span style="font-size: 14px;">({{item.item.tipo }})</span> </v-col>

</v-row>
              </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="4" md="4" > <b style="font-size: 16px;"> Cuenta: </b> </v-col>
                  <v-col cols="7" md="7" > <b style="font-size: 16px;">Descripción: </b> </v-col>
                  <v-col cols="1" md="1" > <b style="font-size: 16px;">Tipo:</b> </v-col>
                 </v-row>
          </template>





  <template v-slot:prepend>



  <i @mouseover="btnAgCuenta=true"  v-if="btnAgCuenta==false"  style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 


<v-tooltip top   v-if="btnAgCuenta==true"  >
<template v-slot:activator="{ on }">
   
<v-btn @mouseout="btnAgCuenta=false" @click.prevent="acti_Nueva_cuenta= !acti_Nueva_cuenta" v-on="on" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>

</template>
    <span>Crear Nueva Cuenta</span>
 </v-tooltip>


 </template>   

 <template v-slot:append-outer>

<v-tooltip top>
<template v-slot:activator="{ on }">

    <v-btn @click.prevent="AddCuenta" v-on="on"  color="secondary" fab x-small dark>
    <i style="font-size: 20px;" class="fa fa-plus-square"></i>
    </v-btn>
 </template>
<span>Agregar Cuenta</span>
</v-tooltip>

  </template>   


                        </v-combobox>
</v-col>
</v-row>
</v-form>

<!-------------------------------------------------------------------------------- tb-agrega cuenta -->
<v-data-table  
                      :headers="[{ text: 'Cuenta', value: 'cuenta.cuenta' },
                      { text: 'Nombre', value: 'cuenta.descripcion' },
                      { text: 'Debito', value: 'debito', width:180 },
                      { text: 'Credito', value: 'credito', width:180},
                      { text: 'Acción', value: 'action' }]"
                      :items="RLisCuenta"
                      :items-per-page="-1"
                      class="elevation-1"
                      dense
                    > 



<template v-slot:item.debito="props">
  <!-------------------------------------------------- debito -->
<VueAutonumeric  dense outlined
 autocomplete="off"
  v-model.number="props.item.debito"
  @input="calcularTotal(props.item.debito)"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-dollar"></i>
    </template>
  </VueAutonumeric>
  <!-------------------------------------------------- debito -->   
   </template>


<template v-slot:item.credito="props">
  <!-------------------------------------------------- debito -->
<VueAutonumeric  dense outlined
 autocomplete="off"
  v-model.number="props.item.credito"
  @input="calcularTotal(props.item.credito)"
  
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-dollar"></i>
    </template>
  </VueAutonumeric>
  <!-------------------------------------------------- debito -->                  
  </template>

 <template v-slot:item.action="{ item }">
<!-- <v-btn fab @click.prevent="editTel(item)" small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
<v-btn fab @click.prevent="deleCuenta(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>              
</template>

<template v-slot:body.append v-if="windowSize.width >600" >
<tr> 
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end">Totales:</strong></td>
<td class="border-total"  ><strong class="d-flex justify-center"> {{ currencyFormatter(Total.debito)}}</strong></td>
<td class="border-total" ><strong class="d-flex justify-center">  {{ currencyFormatter(Total.credito)}}</strong></td>
<td class="border-top-bottom"  ><strong></strong></td>        
  </tr>
 </template>

<template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>TOTAL DEBITO:{{ currencyFormatter(Total.debito)}}</span>
        <span>TOTAL CREDITO:{{ currencyFormatter(Total.credito)}}</span>
      </v-list-item-content>
    </v-list-item>

  </template>




                    </v-data-table>


<!-------------------------------------------------------------------------------- tb agrega cuenta -->


</v-card-text>


     <hr/>   
          <v-btn-toggle rounded class="d-flex justify-end" >

            <v-btn color="green darken-1" @click.prevent="saveCuenta">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar</v-btn>

              <!-- <v-btn v-if="modalCuenta.codigoDocumento!=0"  color="red" @click.prevent="removeCuenta">
              <i style="font-size: 28px;" class="fa fa-trash"> </i>
              Eliminar</v-btn> -->

          </v-btn-toggle>
      <hr/>  
</v-card>
<!-------------------------------------------------------------------------------- Fn cuenta Contable -->

<!-- aquiii -->


</div>
</template>

<script>

import numeral from 'numeral'
import VueAutonumeric from "../components/AutoNumericNuevo";
import NuevaCuenta from "../components/NuevaCuenta";
import { EventBus } from "../event-bus";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc
} from "@/js/Funciones.js";
export default {

    components:{
 
    VueAutonumeric,
    NuevaCuenta
    

    },

    mounted() { 
    EventBus.$on("onResize", this.onResize);
  
  },

    created(){

    },
    data(){
     return{

        windowSize:{width:"auto", height:"auto"},
        //  RLisCuenta:[],
         valid: true,
         btnAgCuenta:false, 
         acti_Modal_cuenta:false,
         acti_Nueva_cuenta:false,

RfilCuenta:"cuenta",
Rcuenta:[],
modalCuenta:{
    cuenta:null ,

 },

aalert: {
        estado: false,
        color: null
      },



    rules: {
        required: value => !!value || "Requerido.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

     }

    },



    props:{
RLisCuenta: {
      required: true,
      type: Array 
    },

    objeTem: {
      required: true,
      type: Array 
    },


    Total:{
        required: true,
      type: Object 

    }

    },


    methods:{
      currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,

onResize(e){

var jsn = {width:window.innerWidth, height:window.innerHeight-112 }
this.windowSize=jsn

// console.log("imprii ")
// console.log(e)
// this.windowSize=e


},

currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

saveCuenta(){

if(this.Total.credito!=this.Total.debito){  
this.alerta("El Total de Debito y Credito no Coincide en el Asiento Contable", "error");
return
}
 

this.acti_Modal_cuenta=!this.acti_Modal_cuenta
//this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));

var TOTAL={
  debito:this.RedondeoBasico(this.Total.debito),
  credito:this.RedondeoBasico(this.Total.credito),
}
//this.Total
this.$emit("CuentasContables", this.RLisCuenta,TOTAL,false)
this.modalCuenta.cuenta=null 
  
},


deleCuenta(item){
var eliminar
const index =  this.RLisCuenta.indexOf(item)
eliminar= confirm('Desea eliminar este Cuenta?') && this.RLisCuenta.splice(index, 1)

if(eliminar!=false){

this.calcularTotal(1)

}

//this.ObjTemp= Object.assign({}, item); 
//this.nuevoClientes.ListTel=this.RLisTel

},

alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.new();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },



AddCuenta(){
if (this.$refs.formCuenta.validate()) {

  if(this.modalCuenta.cuenta.tipo.trim()==="CO"){
    this.alerta("Esta es una cuenta control. Por lo tanto no la puede asignar", "error");
  return;
}

var json={
cuenta:{
  cuenta:this.modalCuenta.cuenta.cuenta,
  descripcion:this.modalCuenta.cuenta.descripcion,
  },
debito:0,
credito:0,
}
this.RLisCuenta.push(json)


//this.NotaDebito.RLisCuenta=this.RLisCuenta
this.modalCuenta.cuenta=null 



}else{

this.alerta("Los campos marcados en rojo son  obligatorios", "error");

}


},


NnuevaCuenta(e){

this.acti_Nueva_cuenta=e

},


CargarRcuenta() {
   this.$axios.get(this.$hostname + this.$hName + '/catalogo/findall', {headers:this.$store.getters.GetheadersAxios}).
   then(res => {
        this.Rcuenta = res.data;
      });
    },

SelctCbCuentaValidControl(){
if(this.modalCuenta.cuenta != null){


if(this.modalCuenta.cuenta.tipo == 'CO'){
 this.alerta("No puede seleccionar una cuenta control", "error");
 this.modalCuenta.cuenta = null;
}


}


},



CbFilCuenta(e) {

if(e.target.value.length==1){

if(e.target.value.charCodeAt(0)>=97 & e.target.value.charCodeAt(0)<=122 ){

this.RfilCuenta="descripcion"


this.CargarRcuenta()

}else{this.RfilCuenta="cuenta"}



if(e.target.value.charCodeAt(0)>=48 & e.target.value.charCodeAt(0)<=57 ){
this.CargarRcuenta()
//console.log("ok numero")
this.RfilCuenta="cuenta"

}else{this.RfilCuenta="descripcion"}

  //numero 48 a 57
  //letras 97 a 122 

}

      },

SelctCbcuenta() {

      if (this.modalCuenta.cuenta == null) {
        this.modalCuenta.cuenta = "";
      }

      if (this.modalCuenta.cuenta.cuenta == undefined) {
        this.alerta("Seleccione una Cuenta", "error");
        this.modalCuenta.cuenta = "";
        return;
      }

    },


cerrarmodalcuenta(){

 var RLis= JSON.parse(JSON.stringify(this.objeTem));
 //this.RLisCuenta=JSON.parse(JSON.stringify(this.objeTem));

this.calcularTotalCerrar(RLis)




// if(this.RLisCuenta.length>0){
//  var RLis= JSON.parse(JSON.stringify(this.objeTem));
// this.calcularTotalCerrar(RLis)
// }

var TOTAL={
  debito:this.RedondeoBasico(this.Total.debito),
  credito:this.RedondeoBasico(this.Total.credito),
}
//this.Total
this.$emit("CuentasContables", RLis,TOTAL,false)



},




async calcularTotal(e){
  /*this.RedondeoBasico()
  this.RedondeoValor(e.precio)*/
  this.Total.credito=0
  this.Total.debito=0
//   Cuentas Contables
 if(e.toString().length>0){
/*this.RLisCuenta.forEach(element => {

var TOTALCREDITO=0
var TOTALDEBITO=0

//2this.Total.credito += this.RedondeoValor(element.credito)
this.Total.debito += this.RedondeoValor(element.debito) 2//
// this.RedondeoValorCantRed(pre,2)
//2this.Total.credito=(this.RedondeoValorCantRed(this.Total.credito,2)+this.RedondeoValorCantRed(element.credito,2))
this.Total.debito=(this.RedondeoValorCantRed(this.Total.debito,2)+this.RedondeoValorCantRed(element.debito,2))2//
TOTALCREDITO = (this.Total.credito+element.credito)
TOTALDEBITO = (this.Total.debito+element.debito)
this.Total.credito=this.RedondeoValor(TOTALCREDITO)
this.Total.debito=this.RedondeoValor(TOTALDEBITO)

});*/

await Promise.all(this.RLisCuenta.map(async (element) => {
  var TOTALCREDITO=0
var TOTALDEBITO=0
TOTALCREDITO = (this.Total.credito+element.credito)
TOTALDEBITO = (this.Total.debito+element.debito)
this.Total.credito=this.RedondeoValor(TOTALCREDITO)
this.Total.debito=this.RedondeoValor(TOTALDEBITO)
          }));

}

},

async calcularTotalCerrar(e){

  this.Total.credito=0
  this.Total.debito=0

/*e.forEach(element => {
var TOTALCREDITO=0
var TOTALDEBITO=0
TOTALCREDITO = (this.Total.credito+element.credito)
TOTALDEBITO = (this.Total.debito+element.debito)
this.Total.credito=this.RedondeoValor(TOTALCREDITO)
this.Total.debito=this.RedondeoValor(TOTALDEBITO)

//2this.Total.credito += this.RedondeoValor(element.credito)
this.Total.debito += this.RedondeoValor(element.debito) 2// 

});*/

await Promise.all(this.RLisCuenta.map(async (element) => {
  var TOTALCREDITO=0
var TOTALDEBITO=0
TOTALCREDITO = (this.Total.credito+element.credito)
TOTALDEBITO = (this.Total.debito+element.debito)
this.Total.credito=this.RedondeoValor(TOTALCREDITO)
this.Total.debito=this.RedondeoValor(TOTALDEBITO)
          }));
}


}
};
</script>

<style lang="scss" scoped>

.border-total{ 

border: 2px solid #757575;
border-bottom: 6px double #757575;
  
}


.border-top-bottom{ 

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;  
}


</style>

