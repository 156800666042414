<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Catálogo de Cuentas'"
  :descripcion="'Crear, Modificar y Desactivar Cuentas Contables'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

        <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraDinamicaNormal
              ref="BarraDiNor"
              :url="
                $hostname + $hName + '/catalogo/findall'
              "
              :nombre="'Catálogo Cuenta'"
              :headers="[
        { text: 'Cuenta', value: 'cuenta' },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Estado', value: 'activo' }
       
        ]"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCONTCuentasContables'"
              :SeachNombre="'Búsqueda por descripcion '"
              :ContListSlot="[
              {Text:'Cuenta', NomValue:'cuenta', Ctsl:1, Type:0, State:true},
              {Text:'Descripcón', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ]"
            />
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2"> 
  <v-form ref="formCatalogo" v-model="valid" lazy-validation>
              <v-row>
                <input
                  type="number"
                  v-model="CuentaNueva.cuenta"
                  style="display:none;"
                />

<v-col cols="12" md="6" sm="6">
      <v-text-field
            label="* Cuenta:"
            autocomplete="off"
            dense
            outlined
            :disabled="editando"
            :rules="[$rules.required]"
            v-model="CuentaNueva.cuenta"
            @blur="validarCuenta"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
            </template>
          </v-text-field>
  </v-col>


<v-col cols="12" md="6" sm="6">


 <v-combobox
                    v-model="CuentaNueva.descargo"
                    label="Cuenta Descargo:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbcuentaNuevaDesc"
                    @focus="CargarRcuenta"
                    @change="SelctCbCuentaNuevaDescValidControl"
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
                        </v-combobox>

  </v-col>

<v-col cols="12" md="12" sm="12">
      <v-text-field
            label="Descripción*:"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="CuentaNueva.descripcion"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-font"></i>
            </template>
          </v-text-field>
  </v-col>


<v-col cols="12" md="6" sm="6">
  <v-combobox 
                    v-model="parametros.Origen"
                    label="Origen:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="ROrigen"
                    item-text="descripcion"
                    @blur="SelctCbcuentaorigen"
                   
                  > 
                  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-bandcamp"
                            ></i>
                          </template>
                        </v-combobox>


 <v-combobox
                    v-model="CuentaNueva.gasto"
                    label="Tipo de Gasto:"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="RTipoGasto"
                    item-text="descripcion"
                    @focus="CargarRTipopago" 
                    @blur="SelctCbTipoGasto"
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>

                        <v-switch
                        v-model="CuentaNueva.activo"
                        label="Activo"
                        color="success"
                        hide-details
                      ></v-switch>

  </v-col>

<v-col cols="12" md="6" sm="6">
  <v-combobox
                    v-model="parametros.Tipo"
                    label="Tipo:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RTipo"
                    item-text="descripcion"
                    @blur="SelctCbCuentatipo"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>
                        
                        
                        
                        <v-combobox
                    v-model="parametros.Grupo"
                    label="Grupo:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RGrupo"
                    item-text="descripcion"
                    @blur="SelctCbGrupo"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-cubes"
                            ></i>
                          </template>
                        </v-combobox>
</v-col>          
              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->

    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay
    
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
         
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Cuenta', value: 'cuenta', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Descripcion', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Tipo', value: 'tipo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Grupo', value: 'grupo', sortable:false, class: ['no-gutters black--text'] },
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :items-per-page="15"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarCatalogo'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------ otras funciones ---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>




<!-- ------------------------------------------------------ Modal- Reportes-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
<!----------------------------------------------------------------- Cuerpo Reporte -->
 <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
      
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;" > <b> CATÁLOGO DE CUENTAS </b> </span >

        <v-spacer></v-spacer>
        <v-btn
          color="red"
          fab
          small
          @click.prevent="dialogimprimirList=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row class="pa-0 ma-0">


<v-col cols="12" md="6" sm="6">


<v-combobox
                    v-model="datosImprecion.CuentaInicio"
                    label="Cuenta inicial:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbReportcuentaInicio"
                    @focus="CargarRcuenta"
                    
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
                        </v-combobox>


</v-col>

<v-col cols="12" md="6" sm="6">




<v-combobox
                    v-model="datosImprecion.CuentaFinal"
                    label="Cuenta inicial:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbReportcuentaFinal"
                    @focus="CargarRcuenta"
                    
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
                        </v-combobox>





</v-col>

              </v-row>
            </v-form>
 </v-container>

<hr>

<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

  </v-card>
<!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
</v-dialog>
<!-- ------------------------------------------------------ fn Modal reportes -->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteFiltros 
  },
  
  created() {
    var json = {
      titulo: "Catálogo de Cuentas",
      descripcion: "Crear, Modificar y Desactivar Cuentas Contables",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CONTCuentasContablesNew",
        "CONTCuentasContablesSave",
        "CONTCuentasContablesRemove",
        "CONTCuentasContablesAnular",
        "CONTCuentasContablesPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CONTCuentasContablesNew", this.new);
    // EventBus.$on("CONTCuentasContablesSave", this.save);
    // EventBus.$on("CONTCuentasContablesRemove", this.remove);
    // EventBus.$on("CONTCuentasContablesAnular", this.remove);
    // EventBus.$on("CONTCuentasContablesPrintList", this.imprimirList);
    EventBus.$on("emitCONTCuentasContables", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
dialogimprimirList:false,
datosImprecion:{
nombre:'CATÁLOGO DE CUENTAS',
 UrlImprimir: '/catalogo/ImprimirCatalogoCuenta', 
 UrlExel: '/catalogo/ExcelCatalogoCuenta', 
 UrlCorreo: '/catalogo/CorreoCatalogoCuenta', 
 CuentaInicio: null, 
 CuentaFinal: null 
},
permitirSalvar:true,
editando:false,
     Rcuenta:[],
     RfilCuenta:"cuenta",


ROrigen:[
{codigo:"C", descripcion:"Crédito"},
{codigo:"D", descripcion:"Débito"},
],

RTipoGasto:[],
RTipo:[{codigo:"CO ", descripcion:"Cuenta Control"},
{codigo:"DE ", descripcion:"Cuenta Detalle"}],
RGrupo:[
{codigo:"AC ", descripcion:"Activo"},
{codigo:"PA ", descripcion:"Pasivo"},
{codigo:"CA ", descripcion:"Capital"},
{codigo:"IN ", descripcion:"Ingresos"},
{codigo:"CO ", descripcion:"Costo"},
{codigo:"GA ", descripcion:"Gastos"}],
parametros:{
  Tipo:null,
  Origen:null,
  Grupo:null
},
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      CuentaNueva: {
        activo: true,
        borrado: false,
        cuenta: "",
        descripcion: "",
        origen: "",      
        tipo: "",      
        tipogasto: "",      
        grupo: "",      
        departamento:null,      
        cuentaGubernamental: "",      
        gasto:null,      
        empresa:1,
        descargo:null
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },

      
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],
      list: [],
      search: "",
      acti_tb_data: false,
     

notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }



    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/catalogo/findall",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.CuentaNueva = e;
      if (e.tipo!=null) {
        this.parametros.Tipo = this.RTipo.filter(tr=>tr.codigo.trim()===e.tipo.trim())[0]
      }
      if (e.origen!=null) {
        this.parametros.Origen = this.ROrigen.filter(tr=>tr.codigo.trim()===e.origen.trim())[0]
      }
      if (e.grupo!=null) {
        this.parametros.Grupo = this.RGrupo.filter(tr=>tr.codigo.trim()===e.grupo.trim())[0]
      }
      this.acti_tb_data=false
      this.editando=true;
    },
    save() {

if (this.$refs.formCatalogo.validate()) {

      if(!this.editando && !this.permitirSalvar){
        this.alerta("Esta cuenta ya existe.","error");
        return;
      }
     this.CuentaNueva.tipo=this.parametros.Tipo.codigo
     this.CuentaNueva.grupo=this.parametros.Grupo.codigo
     this.CuentaNueva.origen=this.parametros.Origen.codigo
     console.log(this.CuentaNueva);
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/catalogo/save",
          this.CuentaNueva,
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res =>this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}
    },





NotifMessage() {

if(this.CuentaNueva.cuenta!="" && this.CuentaNueva.cuenta!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarCatalogo"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un Catálogo para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },
    validarCuenta(e){
      if (e.target!=null) {
        this.$axios
        .get(this.$hostname + this.$hName + "/catalogo/findbyid/"+e.target.value, {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          if(res.data){
            this.alerta("Esta cuenta ya existe.","error")
            this.permitirSalvar= false;
          }
        });
          }
          this.permitirSalvar= true;
    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.CuentaNueva)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.CuentaNueva.cuenta!="") {

  this.CuentaNueva.tipo=this.parametros.Tipo.codigo
  this.CuentaNueva.grupo=this.parametros.Grupo.codigo
  this.CuentaNueva.origen=this.parametros.Origen.codigo
  console.log(this.CuentaNueva);
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/catalogo/delete",
            this.CuentaNueva,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => {
            if (res.data) {
              this.alerta("Dato eliminado correctamente !", "bien")  
            }
              })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

  this.CuentaNueva.tipo=this.parametros.Tipo.codigo
  this.CuentaNueva.grupo=this.parametros.Grupo.codigo
  this.CuentaNueva.origen=this.parametros.Origen.codigo
console.log(this.CuentaNueva);
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/catalogo/delete",
            this.CuentaNueva,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
   imprimirList() {
      this.dialogimprimirList=true
    },

DDataReporteBasico(e){
this.dialogimprimirList=e
},
    newwnuevo() {

      this.BarraTablaDinamicaAprir =false
      this.editando=false;
      this.permitirSalvar = true;
      this.CuentaNueva = {
        activo: true,
        borrado: false,
        cuenta: "",
        descripcion: "",
        origen: "",      
        tipo: "",      
        tipogasto: "",      
        grupo: "",      
        departamento:null,      
        cuentaGubernamental: "",      
        gasto: null,      
        empresa:1,
        descargo:null
      }
      this.parametros={Tipo:null,Grupo:null,Origen:null}
      //  EventBus.$emit("actualizaBarraBusqueda2");
    },


CargarRcuenta() {
   this.$axios.get(this.$hostname + this.$hName + '/catalogo/findall', {headers:this.$store.getters.GetheadersAxios})
   .then(res => {
        this.Rcuenta = res.data;
      });
    },

SelctCbcuentaNuevaDesc() {
if (this.CuentaNueva.descargo == null) {
        this.CuentaNueva.descargo = "";
      }

      if (this.CuentaNueva.descargo.cuenta == undefined) {
        this.alerta("Seleccione un tipo cuenta", "error");
        this.CuentaNueva.descargo = "";
        return;
      }
}, 


SelctCbReportcuentaInicio() {
if (this.datosImprecion.CuentaInicio == null) {
        this.datosImprecion.CuentaInicio = "";
      }

      if (this.datosImprecion.CuentaInicio.cuenta == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.datosImprecion.CuentaInicio = "";
        return;
      }
}, 

SelctCbReportcuentaFinal() {
if (this.datosImprecion.CuentaFinal == null) {
        this.datosImprecion.CuentaFinal = "";
      }

      if (this.datosImprecion.CuentaFinal.cuenta == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.datosImprecion.CuentaFinal = "";
        return;
      }
}, 





CbFilCuenta(e){

if(e.target.value.length==1){

if(e.target.value.charCodeAt(0)>=97 & e.target.value.charCodeAt(0)<=122 ){

//console.log("ok letra")

this.RfilCuenta="descripcion"


this.CargarRcuenta()

}else{this.RfilCuenta="cuenta"}



if(e.target.value.charCodeAt(0)>=48 & e.target.value.charCodeAt(0)<=57 ){
this.CargarRcuenta()
//console.log("ok numero")
this.RfilCuenta="cuenta"

}else{this.RfilCuenta="descripcion"}

  //numero 48 a 57
  //letras 97 a 122 

}


},

SelctCbCuentaNuevaDescValidControl(){

if(this.CuentaNueva.descargo != null){

if(this.CuentaNueva.descargo.tipo == 'DE'){
 this.alerta("No puede seleccionar una cuenta de detalle", "error");
 this.CuentaNueva.descargo = null;
}

}

},


SelctCbcuentaorigen() {

      if (this.parametros.Origen == null) {
        this.parametros.Origen = "";
      }

      if (this.parametros.Origen.codigo == undefined) {
        this.alerta("Seleccione un origen", "error");
        this.parametros.Origen = "";
        return;
      }

    },


CargarRTipopago() {
   this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findall', {headers:this.$store.getters.GetheadersAxios}
   ).then(res => {
        this.RTipoGasto = res.data;
      });
    },


SelctCbTipoGasto() {


if (this.CuentaNueva.gasto == null) {
        this.CuentaNueva.gasto = null;
      }

      if (this.CuentaNueva.gasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.CuentaNueva.gasto = null;
        return;
      }

    },


    SelctCbCuentatipo() {

      if (this.parametros.Tipo == null) {
        this.parametros.Tipo = "";
      }

      if (this.parametros.Tipo.codigo == undefined) {
        this.alerta("Seleccione un tipo", "error");
        this.parametros.Tipo = "";
        return;
      }

    },


SelctCbGrupo() {
      if (this.parametros.Grupo == null) {
        this.parametros.Grupo = "";
      }

      if (this.parametros.Grupo.codigo == undefined) {
        this.alerta("Seleccione un grupo", "error");
        this.parametros.Grupo = "";
        return;
      }

    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/catalogo/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    }
  },


computed:{

    ActDescBtn(){      
if(this.CuentaNueva.cuenta!=""){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.CuentaNueva.cuenta==""){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))   
    }
    }


};
</script>

<style></style>
 