<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Ventas Por Vendedor'"
  :descripcion="'Ventas Por Vendedor'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitVentasVendedor'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="VentasVendedor.desde"
        hide-details
        @blur="BlurDesde"
        v-on:blur="BuscarVentas"
        @keyup.enter="BuscarVentas"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="VentasVendedor.hasta"
        hide-details
        @blur="BlurHasta"
        v-on:blur="BuscarVentas"
         @keyup.enter="BuscarVentas"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
              
<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
<v-data-table

      :headers="[
                { text: 'Nombre', sortable: false,  value: 'nombre', width:'auto',sortable:false, class: ['no-gutters black--text'] },
                 { text: 'Sucursal', sortable: false, value: 'sucursal.descripcion', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: '$ Meta de Ventas', sortable: false, value: 'metadeventa', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: '$ Ventas', sortable: false, value: 'totalventa', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: '%Comisión', sortable: false, align:'center', value: 'comisionventa', width:'auto', width:'auto', sortable:false, class: ['no-gutters black--text'] },
                 { text: 'Valor Comisiones', sortable: false,align:'center', value: 'ValorComision', width:'auto', sortable:false, class: ['no-gutters black--text'] }
                 ]"
              dense
              :items="listVentas"
              :search="search"      
              :items-per-page="15"
              class="elevation-1 TbSize13"
            >

<template v-slot:item.metadeventa="props"> 
  <span style="display:none;">{{props.item.indx = listVentas.indexOf(props.item)}}</span>
  <span >{{currencyFormatter(props.item.metadeventa)}}</span>
</template>

<template v-slot:item.totalventa="props"> 
  <span >{{currencyFormatter(props.item.totalventa)}}</span>
</template>

<template v-slot:item.comisionventa="props"> 
 <span >{{currencyFormatter(props.item.comisionventa)}}</span>
</template>

<template v-slot:item.ValorComision="props"> 
 <span >{{currencyFormatter(props.item.ValorComision)}}</span>
</template>

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.meta)}} </strong></td>                                                                         
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.ventas)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.comicion)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valorcomision)}} </strong></td>
  </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Meta:{{currencyFormatter(totales.meta)}}</span>
        <span>Total Ventas:{{currencyFormatter(totales.ventas)}}</span>
        <span>Total Comisión:{{currencyFormatter(totales.comicion)}}</span>
       <span>Valor Comisiones:{{currencyFormatter(totales.valorcomision)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>

</v-data-table>

 </div>
<!-- <span>Ventas totales en este periodo:0.00</span> -->
</v-col>



              </v-row>

            </v-form>
       </div>

  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("VentasVendedorNew", this.new);
    // EventBus.$on("VentasVendedorSave", this.save);
    // EventBus.$on("VentasVendedorRemove", this.remove);
    // EventBus.$on("VentasVendedorAnular", this.remove);
    // EventBus.$on("VentasVendedorPrintList", this.imprimirList);
    EventBus.$on("emitVentasVendedor", this.datostabla);
    EventBus.$on("onResize", this.onResize);

    this.BuscarVentas()
  },

  data:()=>  ({


      Rpropietario:[],
      RSucursal:[],
      RCaja:[],
      
      listVentas:[],
      
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

totales:{
  meta:0,
  ventas:0,
  comicion:0,
  valorcomision:0

},

      VentasVendedor: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    
    datostabla(e) {

      this.VentasVendedor = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() {},
    anular() { },
    imprimirList() {},
    newwnuevo() {
      (this.VentasVendedor = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },



calculartotal(){
this.totales={
  meta:0,
  ventas:0,
  comicion:0,
  valorcomision:0
},



this.listVentas.forEach(ele => {

if(ele.comisionventa!=0){
  ele.ValorComision= (ele.totalventa*(ele.comisionventa/100))
}else{ele.ValorComision=0 }

this.totales.ventas+=ele.totalventa
this.totales.valorcomision+=ele.ValorComision


this.totales.meta+=ele.metadeventa
this.totales.comicion+=ele.comisionventa

});

},



BuscarVentas(){

var Link=`/ConsultaRapida/buscarVentasVendedor/${this.VentasVendedor.desde}/${this.VentasVendedor.hasta}`
this.$axios.get(this.$hostname+this.$hName+Link,{headers:this.$store.getters.GetheadersAxios}
  ).then(res=>( 
    this.listVentas=res.data.ListVendedor,
    setTimeout(this.calculartotal,200)
  ))

},



BlurDesde(){

if(this.VentasVendedor.desde.toString().length==0){
  this.VentasVendedor.desde=new Date().toISOString().substr(0,10)
}


 },


 BlurHasta(){

if(this.VentasVendedor.hasta.toString().length==0){
  this.VentasVendedor.hasta=new Date().toISOString().substr(0,10)
}

 },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { },


  },



computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
  }



};
</script>

<style></style>
