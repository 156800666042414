<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
<!-- ContenidoBase-Cajas  -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Cajas'" :descripcion="'Para crear Cajas , Para cobros , Punto de ventas.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
<!-- ContenidoBase-Cajas  -->

      <tr>
        <!-- BarraPrimaria-Caja Pv --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'min-width': 300 + 'px', 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px' }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">

                <center>
                  <!-- m<v-btn-toggle ></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>

                  <!-- <v-btn
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>   :url="$hostname + $hName + '/services/caja/findAllActivoss'" -->


                </center>

                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/cajas/findAllActivos'"
                  :nombre="'Cajas'" :emitnombre="'emitCaja'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'descripcion' },
                    { text: 'Sucursal', value: 'sucursal.descripcion' },
                    { text: 'Deposito', value: 'deposito' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Descripción', NomValue: 'descripcion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Sucursal', NomValue: 'sucursal', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
  { Text: 'Deposito', NomValue: 'deposito', Ctsl: 1, Type: 2, State: true },
  { Text: 'PV', NomValue: 'cajaconsulta', Ctsl: 1, Type: 0.1, State: true },
  { Text: 'Caja', NomValue: 'cobradora', Ctsl: 1, Type: 0.1, State: true },
  { Text: 'Domicilio', NomValue: 'cajadomicilio', Ctsl: 1, Type: 0.1, State: true },
  { Text: 'Marca', NomValue: 'marca', Ctsl: 1, Type: 0.1, State: true },
  { Text: 'Código de Barra', NomValue: 'soloBarra', Ctsl: 1, Type: 0.1, State: true },
]" />



              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria-Caja Pv ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <!-- Formulario-Caja Pv  -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <!-- fila 1 ------------------------------>
                      <v-col cols="12" md="12" sm="12">
                        <!-- descripciom:-->

                        <v-text-field label="* Descripción:" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="caja.descripcion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- fn fila 1 ------------------------------>

                      <!-- fila 2 ------------------------------>

                      <!-- Sucursal: color="grey darken-4"       -->
                      <v-col cols="12" md="6" sm="6">
                        <v-combobox autocomplete="off" dense outlined label="Sucursal:" :items="ListaSucursales"
                          item-text="descripcion" :rules="[$rules.required]" v-model="caja.sucursal"
                          @focus="CargarRSucursal" @blur="SelctCbSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-users"></i>
                          </template>
                        </v-combobox>
                        <!-- -------------------------------------------------formato de pagina color="grey darken-4"    -->
                        <v-combobox autocomplete="off" dense outlined @change="cambio" label="Formato de Impresión:"
                          :items="formatoPagina" item-text="descripcion" :rules="[$rules.required]"
                          v-model="ElegidoformatoPagina" @blur="SelctCbformaPagina">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-file-o"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn Sucursal-->

                      <!-- Depósito:-->
                      <v-col cols="12" md="6" sm="6">
                        <AutoNumeric autocomplete="off" label="* Depósito:" dense outlined
                          v-model.number="caja.deposito">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </AutoNumeric>

 <!-- ----------------------------- vendedores -->
                    <v-combobox autocomplete="off" dense outlined label="Vendedores:" :items="RVendedores" 
                    v-if="this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true"
                          item-text="nombre" v-model="caja.vendedor" @blur="SelctCbVendedores"
                          @focus="cargarVendedores">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-sign-language"></i>
                          </template>
                        </v-combobox>


                      </v-col>
                      <!-- fn Deposito -->

                      <!-- Formato de Página:-->


                      <v-col cols="12" md="6" sm="6">
                        <v-switch class="mt-0" color="teal" label="" v-model="caja.cajaconsulta" hide-details>
                          <template v-slot:label>
                            <strong v-if="caja.cajaconsulta == true" style="color:#000000; font-size:13px;">Punto de
                              ventas:
                              Si</strong>
                            <strong v-if="caja.cajaconsulta == false" style="color:#000000; font-size:13px;">Punto de
                              ventas:
                              No</strong>
                          </template>
                        </v-switch>

                        <v-switch class="mt-0" color="teal" label="" v-model="caja.cobradora" hide-details>
                          <template v-slot:label>
                            <strong v-if="caja.cobradora == true" style="color:#000000; font-size:13px;">Caja:
                              Si</strong>
                            <strong v-if="caja.cobradora == false" style="color:#000000; font-size:13px;">Caja:
                              No</strong>
                          </template>
                        </v-switch>


                        <v-switch class="mt-0" color="teal" label="" v-model="caja.cajadomicilio" hide-details>
                          <template v-slot:label>
                            <strong v-if="caja.cajadomicilio == true" style="color:#000000; font-size:13px;">Domicilio:
                              Si</strong>
                            <strong v-if="caja.cajadomicilio == false" style="color:#000000; font-size:13px;">Domicilio:
                              No</strong>
                          </template>
                        </v-switch>


                      </v-col>


                      <v-col cols="12" md="6" sm="6">

                        <v-switch class="mt-0" color="teal" label="" v-model="caja.soloBarra" hide-details>
                          <template v-slot:label>
                            <strong v-if="caja.soloBarra == true" style="color:#000000; font-size:13px;">Solo usar
                              código
                              de barra para vender: Si</strong>
                            <strong v-if="caja.soloBarra == false" style="color:#000000; font-size:13px;">Solo usar
                              código
                              de barra para vender: No</strong>
                          </template>
                        </v-switch>

                        <v-switch class="mt-0" color="teal" label="" v-model="caja.marca" hide-details>
                          <template v-slot:label>
                            <strong v-if="caja.marca == true" style="color:#000000; font-size:13px;">Marca:
                              Si</strong>
                            <strong v-if="caja.marca == false" style="color:#000000; font-size:13px;">Marca:
                              No</strong>
                          </template>
                        </v-switch>

                        <v-switch class="mt-0" color="teal" label="" v-model="caja.veryFoms" hide-details>
                          <template v-slot:label>
                            <strong v-if="caja.veryFoms == true" style="color:#000000; font-size:13px;">Verifone:
                              Si</strong>
                            <strong v-if="caja.veryFoms == false" style="color:#000000; font-size:13px;">Verifone:
                              No</strong>
                          </template>
                        </v-switch>

                      </v-col>
                      <!-- fn Formato de Página-->
                      <!-- fn fila 3 -------------------------->


                    </v-row>




                  </div>
                </v-card>


              </v-form>
              <!-- Formulario-Caja Pv  -->

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-- ------------------------------------------------------ Modal-notificacion general-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarCaja'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!-- ------------------------------------------------------ tb-data todo general-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip> -->


          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripción', value: 'descripcion', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Sucursal', value: 'sucursal.descripcion', width: 'auto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Deposito', value: 'deposito', width: 'auto', sortable: false, class: ['no-gutters black--text'] }
            
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">
              <template v-slot:item.deposito="props">
                <span>{{ currencyFormatter(props.item.deposito) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import ContenidoBase from "../../components/ContenidoBase";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase
  },

  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitCaja", this.datostabla);
    // EventBus.$on("cajaNew", this.new);
    // EventBus.$on("cajaSave", this.save);
    // EventBus.$on("cajaRemove", this.remove);
    // EventBus.$on("cajaAnular", this.anularr);
    // EventBus.$on("cajaPrintList", this.imprimirList);
    // this.cargarTodo();

  },

  created() {
    var json = {
      titulo: "Cajas",
      descripcion: "Para crear Cajas , Para cobros , punto de ventas.",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "cajaNew",
        "cajaSave",
        "cajaRemove",
        "cajaAnular",
        "cajaPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {


      // Listas

      ejecucion: true,
      windowSize: { width: "auto", height: "auto" },
      drawerRight: true,
      drawerleft: true,
      ListaSucursales: [],
      RVendedores:[],
      list: [],
      // formato de pagina
      formatoPagina: [
        { key: "rollo", descripcion: "Impresora Rollo" },
        { key: "rolloT", descripcion: "Impresora Rollo Termica" },
        { key: "medPag", descripcion: "Media Pagina" },
        { key: "medPagT", descripcion: "Media Pagina Texto" },
        { key: "pagCom", descripcion: "Pagina Completa" },
        { key: "fiscale", descripcion: "Impresora Fiscal Epson" },
        { key: "fiscals", descripcion: "Impresora Fiscal Start" },
        { key: "rolloBlue", descripcion: "Impresora Rollo bluetooth" }
      ],


      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },


      aalert: {
        estado: false,
        color: null
      },
      search: "",
      acti_tb_data: false,
      valid: true,
      ElegidoformatoPagina: null,
      caja: {
        codigo: 0,
        descripcion: null,
        sucursal: null,
        deposito: 0,
        cajaconsulta: false,
        cobradora: true,
        formatoimpresioncontado: null,
        soloBarra: false,
        marca: false,
        veryFoms: false,
        cajadomicilio:false,
        vendedor:null
      },


      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: true,
        anular: true,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },





      //header de la tabla ver

      //header de la tabla barra dinamica
      headers: [
        {
          text: "Descripcion",
          value: "descripcion"
        }
      ],
      //el body de la tabla
      slotlist: [
        {
          dt0: "codigo",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Código:"
        },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        }
      ],
      //variables de la tabla dinamica para presentarla
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false

    };
  },

  methods: {

    ver() {


      var json = {
        titulo: "Cajas",
        descripcion: "Para crear Cajas , Para cobros , Punto de ventas.",
        save: true,
        eliminar: true,
        anular: false,
        nuevo: true,
        imprimir: true,
        accion: [
          "cajaNew",
          "cajaSave",
          "cajaRemove",
          "cajaAnular",
          "cajaPrintList"
        ]
      };
      EventBus.$emit("anularBotonesAccion", json);




    },


    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      // return this.formatNumber(params);


      if (number == null) { number = 0 }

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");



    },


    cargarVendedores() {

this.$axios.get(this.$hostname + this.$hName + '/vendedores/findallactiv', { headers: this.$store.getters.GetheadersAxios })
  .then(res => (this.RVendedores = res.data))

},



SelctCbVendedores() {


var _this = this;
setTimeout(function () {
  if (_this.caja.vendedores == null) {
    _this.caja.vendedores = "";
  }

  if (!_this.caja.vendedores.hasOwnProperty('codigo')) {
    _this.caja.vendedores = "";
    _this.caja.vendedores = null;
    return;
  }
}, 300);

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {
      if (this.caja.sucursal == null) {
        this.caja.sucursal = "";
      }
      if (this.caja.sucursal.codigo == undefined) {
        this.caja.sucursal = "";
        this.alerta("Seleccione una sucursal", "error");
        return;
      }
    },


   // Comprobando si el valor es nulo o indefinido.
    SelctCbformaPagina() {
      if (this.ElegidoformatoPagina == null) {
        this.ElegidoformatoPagina = "";
      }
      if (this.ElegidoformatoPagina.key == undefined) {
        this.ElegidoformatoPagina = "";
        this.alerta("Seleccione un formato de pagina", "error");
        return;
      }
    },

// Llamar a la API y obtener los datos de la API.

    CargarRSucursal() {

      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: headers })
        .then(res => (this.ListaSucursales = res.data))
        .catch(error => console.log(error));
    },

    cambio() {
      this.formatoPagina.forEach(element => {
        if (this.ElegidoformatoPagina === element) {
          this.caja.formatoimpresioncontado = element.key;
        }
      });
    },
    // Una función que se llama cuando se hace clic en una fila de la tabla.
    datostabla(e) {
      this.caja = JSON.parse(JSON.stringify(e));
      this.ElegidoformatoPagina = this.formatoPagina.filter(r => r.key === this.caja.formatoimpresioncontado.trim())[0];
      this.acti_tb_data = false;


      if (this.windowSize.width <= 600) {

        this.BarraTablaDinamicaAprir = false


      }

    },
   // Guardando los datos en la base de datos .
    save() {

      if (this.$refs.form.validate()) {

        var caja = JSON.parse(JSON.stringify(this.caja))
        if(caja.cajaconsulta==null){caja.cajaconsulta= false }
        if(caja.soloBarra==null){caja.soloBarra= false }
        if(caja.cobradora==null){caja.cobradora= false }
        if(caja.marca==null){caja.marca= false }
        if(caja.veryFoms==null){caja.veryFoms= false }
        if(caja.cajadomicilio==null){caja.cajadomicilio= false }

        var alm = {
          codigo: this.caja.sucursal.almacen
        }
        caja.almacen = alm


        caja.borrado = false
        if (this.ejecucion == true) {

          this.ejecucion = false

          EventBus.$emit("loading", true);
          this.$axios
            .post(this.$hostname + this.$hName + "/cajas/save", caja, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bien") })
            .catch(error => { EventBus.$emit("loading", false), this.alerta("Dato no guardado correctamente", "error") });

        }



      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }


    },


   // Creación de un mensaje de notificación.
    NotifMessage() {

      if (this.caja.codigo != 0 && this.caja.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarCaja"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una caja para eliminar", "error") }


    },

    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))
      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    // Envío de una solicitud POST al servidor para borrar la caja seleccionada.
    eliminar() {
      if (this.$refs.form.validate()) {

        var caja = JSON.parse(JSON.stringify(this.caja))
        // var jsn={
        //   datos:JSON.parse(JSON.stringify(this.caja)), 
        //   // usuario:JSON.parse(JSON.stringify(this.$user)) 
        // }

        this.$axios
          .post(this.$hostname + this.$hName + "/cajas/borrado/", caja, { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.alerta(res.data.mensage, res.data.estado) })
          .catch(error => this.alerta("Dato no guardado correctamente", "error"));


        // this.$axios.delete(this.$hostname + this.$hName + "/cajas/borrado/",{headers:this.$store.getters.GetheadersAxios},
        //     JSON.parse(JSON.stringify(caja)))
        //   .then(res => {console.log('Ressssspuuuu'),console.log(res.data),this.alerta(res.data.mensage, res.data.estado)})
        //   .catch(error =>{this.alerta("Dato no  eliminado correctamente", "error")});
      }
    },
    anular() { },

   // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/cajas/imprimirCaja/", { headers: headers }).then(res => {
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/
          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'Imprimir lista');

      });


      // console.log('Entrando a imprimir')
      // window.open(this.$hostname + this.$hName + "/cajas/imprimirCaja/")

      //  await this.$axios.get(this.url,{headers:headers}).then(res => {
      //         this.list =  JSON.parse(JSON.stringify(res.data)) ;
      //       });


    },
    // Crear un nuevo objeto y asignarle valores.
    newnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.caja = {

        codigo: 0,
        descripcion: null,
        sucursal: null,
        deposito: 0,
        cajaconsulta: false,
        cobradora: true,
        formatoimpresioncontado: null,
        soloBarra: false,
        marca: false,
        veryFoms: false,
        cajadomicilio:false
      };


      this.ejecucion = true;
      this.ElegidoformatoPagina = null
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },
    // Abriendo una panel de datos.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir
      //this.BarraSecundariaAprir = !this.BarraSecundariaAprir

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },
    // Un método que se llama cuando se hace clic en un botón y muentra un modal con los datos 
    // en una tabla.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },


    // Hacer una llamada axios al backend y devolver los datos al frontend.
    async filtro() {
      var headers = this.$store.getters.GetheadersAxios

      await this.$axios.get(this.$hostname + this.$hName + "/cajas/findAllActivos", { headers: headers })
        .then(res => { this.list = res.data; });
    }
  },
  computed: {

    ActDescBtn() {

      if (this.caja.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        // var objjct = {
        //     save: true,
        //     eliminar: true,
        //     anular: false,
        //     nuevo: true,
        //     imprimir: true,
        //     vercuenta:false, 
        //   };

      }


      if (this.caja.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }


        // var objjct = {
        //       save: true,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false, 
        //     };


      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      //EventBus.$emit("ActDesaBotones", objjct);
    }



  }




};
</script>

<style>
</style>
