<template>
<div class="col-full " :class="$style.centered">
    <h1 :class="$style.headinglarge">Usted no tiene acceso a esta opción  contacte al administrado</h1>
    <p :class="$style.textlarge">¿  Que desea hacer  ?</p>
<v-row class="d-flex justify-center" >

<v-hover
        v-slot:default="{ hover }"
        close-delay="200"

      >
        
        <v-btn :elevation="hover ? 24 : 2" 
        :outlined="hover ? false : true" 
        dark
        :color="hover ? 'teal darken-3' : 'teal'"
        v-ripple="{ class: `white--text` }"
        @click.prevent="IrHome"
        
        > 
   <i style="font-size: 18px;" class="fa fa-home"> </i>
   <h2>Ir al Inicio</h2>
          
      </v-btn>   
      </v-hover>

<v-hover
        v-slot:default="{ hover }"
        close-delay="200"
      >
        
        <v-btn :elevation="hover ? 24 : 2" 
        :outlined="hover ? false : true" 
        dark
        :color="hover ? 'red darken-3' : 'red'"
        v-ripple="{ class: `white--text` }"
        @click.prevent="CerrarSesion"
        > 
   <i style="font-size: 18px;" class="fa fa-exchange"> </i>
   <h2>CERRAR SESION</h2>
          
      </v-btn>   
      </v-hover>
</v-row>
   
    <!-- <router-link :to="{ name:'VistaPrincipal' }">haga Click aqui</router-link></p> -->
    <img src="../assets/img/advertencia.jpg" width="400px" height="400px" />
</div>
</template>
<script>
 
export default{

methods:{


IrHome(){

this.$router.push({path: '/Principal'})

},

CerrarSesion(){

sessionStorage.setItem("ConfiguracionGeneral",  JSON.stringify(null));
this.$store.commit('MutationaddConfiguracionGeneral',null );


 sessionStorage.setItem("OtrasConfigPv",  JSON.stringify(null));
 this.$store.commit('MutationDataOtrasConfigPv',null );


 sessionStorage.setItem("AuthorizationToken",  JSON.stringify(null));
        this.$store.commit('MutationHeadersAxios', null);
        this.$store.commit('MutationHeadersTenant', null);
        this.$store.commit('MutationHeadersAxios',null );
 

sessionStorage.setItem("Usuario",  JSON.stringify(null));

var rou = JSON.parse(JSON.stringify(this.$store.getters.GetDatosItemsRouterDefour))
this.$store.commit('MutationDataRouter',null );
sessionStorage.setItem("itemsRouter", JSON.stringify(null));

this.$store.commit('MutationaddUser',null );
sessionStorage.setItem("AuthorizationToken",  JSON.stringify(null));
this.$store.commit('MutationHeadersAxios',null );

this.$router.push({ path: '/' })
location.reload();

}
    
}

}
</script>
<style module>
.headinglarge {
font-size:50px;
}
.textlarge
{
font-size:50px;
font-weight:100;
}
.centered {
    text-align: center;
}
</style>