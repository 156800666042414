<template>
  <div id="ResponsiveCss">{{botonCuenta}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Documentos Entrada Diario'" :descripcion="'Documentos Entrada Diario'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">


            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <center>
                  <!-- <v-btn-toggle  ></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i
                      style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>

                </center>
                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/documentoentradadiario/findall'"
                  :nombre="'Documentos'" :headers="[{text:'Documento',value:'descripcion'}]"
                  :slotname="'item.descripcion'" :emitnombre="'emitDocumentoCONT'"
                  :SeachNombre="'Búsqueda por descripción del Documento'" :ContListSlot="[
                   {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
                   {Text:'Documento', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
                  {Text:'Operación', NomValue:'operacion', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'Estado', NomValue:'activo', Ctsl:1, Type:0.1, State:true},
                  {Text:'Cerrado', NomValue:'cerrado', Ctsl:1, Type:0.4, State:true}
                  
                  ]" />
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
            <div class="px-2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <!-- <v-row> -->
                <!-- fila 1 ------------------------------>
                <!-- <v-col cols="12" md="12"> -->
                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-card-actions v-if="windowSize.width<=800">
                      <v-spacer></v-spacer>
                      <v-btn @click.prevent="BarraSecundariaAprir=!BarraSecundariaAprir" x-small color="grey">CUENTA<i
                          style="font-size: 20px;" class="fa fa-bars"></i></v-btn>
                    </v-card-actions>


                    <v-row>
                      <!-- Documentos:-->
                      <v-col cols="12" md="12">
                        <v-text-field label="* Descripción:" dense outlined :rules="[$rules.required]"
                          v-model="documento.descripcion" autocomplete="off">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- fn Documentos:-->

                    <!-- Factura:-->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field label="* ID.Documento:" maxlength="5" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="documento.nombrecorto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>


                        <v-text-field type="number" label="Secuencia Inicial" dense outlined :rules="[$rules.required]"
                          v-model.number="documento.secuenciainicial">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>



                      </v-col>

                      <v-col cols="12" md="6">

                        <v-combobox v-model="documento.operacion" label="* Operación:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="ROperacion" item-text="descripcion"
                          @blur="SelctCbOperacion" @focus="CargarROperacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class=" fa fa-line-chart"></i>
                          </template>
                        </v-combobox>





                        <v-switch v-model="documento.activo" label="Activo" color="success" hide-details></v-switch>
                      </v-col>
                      <!-- fn Factura


<v-switch
                        v-model="documento.comprobante"
                        label="Generar Comprobante"
                        color="success"
                        hide-details
                      ></v-switch>


//fa fa-file-text-o-->
                    </v-row>

                  </div>
                </v-card>


              </v-form>

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">


            <!------------------------------------------------------------- Tabla Cuenta   -->
            <center>

              <v-btn small color="cyan darken-3" @click.prevent="acti_Modal_cuenta=!acti_Modal_cuenta"> <i
                  style=" color:white; font-size: 20px;" class="fa fa-plus-square"> </i> <span style="color:white;">
                  Agregar Cuenta</span></v-btn>
              <br> <br>
              <v-spacer></v-spacer>
              <h4 class="font-weight-bold">Cuenta</h4>
            </center>

            <v-card>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" v-on="on" v-model="search" @keyup="filtro"
                    label="Búsqueda por descripción del Documento" single-line hide-details>
                    <template v-slot:prepend-inner>
                      <i style="font-size: 30px;" class="fa fa-search"></i>
                    </template>


                  </v-text-field>

                </template>
                <span>Búsqueda por descripción del Documento</span>
              </v-tooltip>





              <v-data-table @click:row="Modaldatostabla" :items-per-page="10" :headers="HCuenta" :items="Tbcuenta"
                :search="search">
                <template v-slot:item.catalogocuentas.descripcion="props">
                  <div style="border-top:2px solid gray;">

                    <b>{{props.item.catalogocuentas.descripcion}}</b>

                    <!-- <br> -->
                    <!-- <span v-if="props.item.tipo==1">{{props.item.TipoCuentaDesc="Efectivo"}}</span>
              <span v-if="props.item.tipo==2">{{props.item.TipoCuentaDesc="Itbis"}}</span>
              <span v-if="props.item.tipo==3">{{props.item.TipoCuentaDesc="Cuenta por cobrar"}}</span>
              <span v-if="props.item.tipo==4">{{props.item.TipoCuentaDesc="Descuento"}}</span>
              <span v-if="props.item.tipo==5">{{props.item.TipoCuentaDesc="Inventario"}}</span>
              <span v-if="props.item.tipo==6">{{props.item.TipoCuentaDesc="Ingresos"}}</span>
              <span v-if="props.item.tipo==7">{{props.item.TipoCuentaDesc="Costo"}}</span>
              <span v-if="props.item.tipo==8">{{props.item.TipoCuentaDesc="Nota de Crédito"}}</span>
              <span v-if="props.item.tipo==9">{{props.item.TipoCuentaDesc="Retención"}}</span>
              <span v-if="props.item.tipo==10">{{props.item.TipoCuentaDesc="Servicios"}}</span>
              <span v-if="props.item.tipo==11">{{props.item.TipoCuentaDesc="Cupones"}}</span>
              <span v-if="props.item.tipo==12">{{props.item.TipoCuentaDesc="Puntos"}}</span>
              <span v-if="props.item.tipo==13">{{props.item.TipoCuentaDesc="Prima"}}</span>
              <span v-if="props.item.tipo==14">{{props.item.TipoCuentaDesc="Tarjeta"}}</span> -->




                    <!-- <span v-if="props.item.tipo==1">{{props.item.TipoCuentaDesc="CXP"}}</span>
              <span v-if="props.item.tipo==2">{{props.item.TipoCuentaDesc="Gastos"}}</span>
              <span v-if="props.item.tipo==3">{{props.item.TipoCuentaDesc="Itbis"}}</span>
              <span v-if="props.item.tipo==4">{{props.item.TipoCuentaDesc="Retención"}}</span>
              <span v-if="props.item.tipo==5">{{props.item.TipoCuentaDesc="Retención a terceros"}}</span>
              <span v-if="props.item.tipo==6">{{props.item.TipoCuentaDesc="Descuento"}}</span>
              <span v-if="props.item.tipo==7">{{props.item.TipoCuentaDesc="Caja"}}</span>
              <span v-if="props.item.tipo==8">{{props.item.TipoCuentaDesc="Banco"}}</span>
              <span v-if="props.item.tipo==9">{{props.item.TipoCuentaDesc="Inventario"}}</span>
              <span v-if="props.item.tipo==10">{{props.item.TipoCuentaDesc="Costo"}}</span>
              <span v-if="props.item.tipo==11">{{props.item.TipoCuentaDesc="Prima"}}</span> -->
                    <!-- <br> -->

                    <span v-if="props.item.debito==false">Crédito</span>
                    <span v-if="props.item.debito==true">Debito</span>

                    <p style="display:none;">{{documento.documentoentradadiarioCatalogoList=Tbcuenta}} </p>

                  </div>
                </template>
              </v-data-table>
            </v-card>

            <!------------------------------------------------------------------------------ fin tabla cuenta -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>

        <v-toolbar dense flat color="#BDBDBD">

          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>


          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Search" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código:' , value: 'codigo', sortable:false, class: ['no-gutters black--text']},
              { text: 'Descripción:',value: 'descripcion', width:250, sortable:false, class: ['no-gutters black--text'] },
              { text: 'Id.Documento:',value: 'nombrecorto', wndth:120, sortable:false, class: ['no-gutters black--text'] },
              { text: 'Secuencia Inicial:', value: 'secuenciainicial', sortable:false, class: ['no-gutters black--text']  },
              { text: 'Operación:', value: 'operacion.descripcion', sortable:false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">

              <template v-slot:item.fechaCreacion="props">
                <span>{{fecFormatter(props.item.fechaCreacion)}}</span>
              </template>

              <template v-slot:item.comprobante="props">
                <span v-if="props.item.comprobante==true">Activo</span>
                <span v-if="props.item.comprobante==false">inactivo</span>
              </template>



            </v-data-table>
          </v-card>


        </v-card-text>

      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec=='eliminarDocumento'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn v-if="notif.Exec=='eliminarCuenta'" @click.prevent="eliminarCuenta(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    <!-- ------------------------------------------------------ Elim_notif cuenta-->
    <v-dialog label="myAlert" v-model="Elim_notif_cuenta.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif_cuenta.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent=" 
            eliminarCuenta(), (Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif cuenta -->




    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->




    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo">

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> Cuentas Contables </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="cerrarmodalcuenta">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row>



              <v-col cols="12" md="12">

                <v-combobox v-model="modalCuenta.cuenta" label="* Cuenta:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="Rcuenta" :item-text="RfilCuenta"
                  @blur="SelctCbcuenta" @keyup="CbFilModalCuenta" @change="SelctCbCuentaValidControl"
                  @focus="CargarRcuenta">

                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col cols="4" md="4"> <b style="font-size: 16px;"> Cuenta: </b> </v-col>
                      <v-col cols="6" md="6"> <b style="font-size: 16px;">Descripción: </b> </v-col>
                      <!-- <v-col cols="2" md="2" > <b style="font-size: 16px;">Tipo:</b> </v-col> -->
                    </v-row>
                  </template>




                  <template slot="item" slot-scope="item" margin-top="50px">
                    <v-row>
                      <v-col cols="4" md="4"> <span style="font-size: 14px;"> {{item.item.cuenta }}</span> </v-col>
                      <v-col cols="6" md="6"> <span style="font-size: 14px;">{{ item.item.descripcion}}</span> </v-col>
                      <!-- <v-col cols="2" md="2"> <span style="font-size: 12px;">({{item.item.tipo }})</span> </v-col> -->
                    </v-row>

                  </template>



                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn @click.prevent="acti_Nueva_cuenta= !acti_Nueva_cuenta" color="secondary" fab x-small dark>
                      <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                    </v-btn>





                  </template>


                </v-combobox>
              </v-col>


              <!-- <v-col cols="6" md="6">
  <v-combobox style="display:none"
                    v-model="modalCuenta.tipo"
                    label="* Tipo:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="tipoCatalogo"
                    item-text="descripcion"
                    @blur="SelctCbtipo"
                   
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>

 <v-combobox
            autocomplete="off"
            dense
            outlined
            style="display:none"
            label="Moneda"
            :items="Rmoneda"
            item-text="nombrecorto"
            
            v-model="modalCuenta.moneda"
            @blur="SelctCbMoneda"
            @focus="cargarMoneda"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>
          </v-combobox> 
//<->

              </v-col> -->



              <v-col cols="6" md="6">
                <v-combobox v-model="modalCuenta.origen" label="* Origen:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="OrigenCatalogo" item-text="descripcion"
                  @blur="SelctCborigen">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
                  </template>
                </v-combobox>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>


        <hr />
        <v-btn-toggle rounded class="d-flex justify-end">

          <v-btn dark color="green darken-1" @click.prevent="saveCuenta">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

          <v-btn dark v-if="modalCuenta.codigoDocumento!=0 || modalCuenta.hasOwnProperty('marc') " color="red"
            @click.prevent="removeCuenta">
            <i style="font-size: 28px;" class="fa fa-trash"> </i>
            Eliminar
          </v-btn>

        </v-btn-toggle>
        <hr />



      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal- nueva cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Nueva_cuenta" persistent
      max-width="90%" max-height="80%">
      <CrearNuevaCuenta @EmitCrearCuenta="RecCrearCuenta" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal nueva cuenta-->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->




    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import CrearNuevaCuenta from "../../components/CrearCuentaNueva";
import { EventBus } from "../../event-bus.js";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";

export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    CrearNuevaCuenta,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico

  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);

    EventBus.$on("emitDocumentoCONT", this.datostabla);
    // EventBus.$on("DocumentoCONTNew", this.new);
    // EventBus.$on("DocumentoCONTSave", this.save);
    // EventBus.$on("DocumentoCONTRemove", this.remove);
    // EventBus.$on("DocumentoCONTAnular", this.anular);
    // EventBus.$on("DocumentoCONTPrintList", this.imprimirList);
    this.filtro()

    EventBus.$on("onResize", this.onResize);

  },
  created() {
    var json = {
      titulo: "Documentos Entrada Diario",
      descripcion: "Documentos Entrada Diario",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "DocumentoCONTNew",
        "DocumentoCONTSave",
        "DocumentoCONTRemove",
        "DocumentoCONTAnular",
        "DocumentoCONTPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

      windowSize: { width: "auto", height: "auto" },
      editedIndex: -1,

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'DOCUMENTOS ENTRADA DIARIO',
        UrlImprimir: '/documentoentradadiario/ImprimirDoc',
        UrlExel: '/documentoentradadiario/ExcelDoc',
        UrlCorreo: '/documentoentradadiario/CorreoDoc',
      },



      tipoCatalogo: [
        { codigo: 1, descripcion: "CXP" },
        { codigo: 2, descripcion: "Gastos" },
        { codigo: 3, descripcion: "Itbis" },
        { codigo: 4, descripcion: "Retención" },
        { codigo: 5, descripcion: "Retención a terceros" },
        { codigo: 6, descripcion: "Descuento" },
        { codigo: 7, descripcion: "Caja" },
        { codigo: 8, descripcion: "Banco" },
        { codigo: 9, descripcion: "Inventario" },
        { codigo: 10, descripcion: "Costo" },
        { codigo: 11, descripcion: "Prima" }
      ],

      OrigenCatalogo: [
        { codigo: 1, descripcion: "Crédito", debito: false },
        { codigo: 2, descripcion: "Debito", debito: true },
      ],


      ROrigen: [
        { codigo: 1, origen: "D", descripcion: "Crédito" },
        { codigo: 2, origen: "C", descripcion: "Debito" },
      ],


      RTipo: [
        { codigo: 1, tipo: "CO", descripcion: "Control" },
        { codigo: 2, tipo: "DE", descripcion: "Detalle" },
      ],


      RGrupo: [

        { codigo: "AC", descripcion: "Activo" },
        { codigo: "PA", descripcion: "Pasivo" },
        { codigo: "CA", descripcion: "Capital" },
        { codigo: "IN", descripcion: "Ingreso" },
        { codigo: "CO", descripcion: "Costo" },
        { codigo: "GA", descripcion: "Gasto" }

      ]

      , ROperacion: [],
      RTipoGasto: [],
      Rmoneda: [],
      RfilCuenta: "cuenta",
      Rcuenta: [],
      valid: true,


      Colss: {
        p1: 6,
      },


      acti_Modal_cuenta: false,
      acti_Nueva_cuenta: false,
      Tbcuenta: [],

      debito: false,
      credito: false,


      documento: {
        codigo: 0,
        descripcion: "",
        nombrecorto: "",
        operacion: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        comprobante: false,
        borrado: false,
        activo: true,
        documentoentradadiarioCatalogoList: []
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },


      objec: {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      },

      modalCuenta: {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      },



      headers: [{ text: "Documento", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código" },
        { dt0: "descripcion", dt1: null, dt2: null, dt3: 1, dt4: "Descripción" },
        { dt0: "nombrecorto", dt1: null, dt2: null, dt3: 1, dt4: "Id.Documento" },
        { dt0: "fechaCreacion", dt1: null, dt2: null, dt3: 2, dt4: "creación" },
        { dt0: "secuenciainicial", dt1: null, dt2: null, dt3: 1, dt4: "Secuencia Inicial" },
        { dt0: "cantidadcopias", dt1: null, dt2: null, dt3: 1, dt4: "Cantidad  de  copia" },
        { dt0: "operacion", dt1: "descripcion", dt2: null, dt3: 1, dt4: "Operación" },
        { dt0: "usuarioCreacion", dt1: null, dt2: null, dt3: 1, dt4: "Creado por" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },

      ],


      HCuenta: [

        { text: "Descripción:", value: "catalogocuentas.descripcion" },

      ],

      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      Elim_notif_cuenta: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este Cuenta?"
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: true
    };
  },
  methods: {


    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },


    RecCrearCuenta(e) {

      if (e.estado == 'cerrar') {

        this.acti_Nueva_cuenta = false

      }

    },




    // SelctCbCuentaNuevaDescValidControl,SelctCbcuentaNuevaDesc






    CbFilModalCuenta(e) {

      if (e.target.value.length == 1) {

        if (e.target.value.charCodeAt(0) >= 97 & e.target.value.charCodeAt(0) <= 122) {

          //console.log("ok letra")

          this.RfilCuenta = "descripcion"


          this.CargarRcuenta()

        } else { this.RfilCuenta = "cuenta" }



        if (e.target.value.charCodeAt(0) >= 48 & e.target.value.charCodeAt(0) <= 57) {
          this.CargarRcuenta()
          //console.log("ok numero")
          this.RfilCuenta = "cuenta"

        } else { this.RfilCuenta = "descripcion" }

        //numero 48 a 57
        //letras 97 a 122 

      }




      // console.log(e.target.value)
      // //this.Rcuenta=[]

      //         if (e.target.value.length >= 1) {
      //           //console.log("ok1")
      //           this.$axios
      //             .post(
      //               this.$hostname +
      //                 this.$hName +
      //                 "/services/CuentaCatalogo/findallcuenta",
      //               { descripcion: e.target.value }
      //             )
      //             .then(res => (this.Rcuenta = res.data));
      //         }




    },




    SelctCbCuentaValidControl() {

      console.log(this.modalCuenta)

      if (this.modalCuenta.cuenta != null) {


        if (this.modalCuenta.cuenta.tipo == 'CO') {
          this.alerta("No puede seleccionar una cuenta control", "error");
          this.modalCuenta.cuenta = null;
        }


      }


    },



    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivo', {
        usuario: JSON.parse(JSON.stringify(this.$user))
      })
        .then(res => (this.Rmoneda = res.data))

    },

    SelctCbMoneda() {

      if (this.modalCuenta.moneda == null) {

        this.modalCuenta.moneda = ''
      }

      if (this.modalCuenta.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.modalCuenta.moneda = ''
        return

      }

    },


    SelctCbcuenta() {

      if (this.modalCuenta.cuenta == null) {
        this.modalCuenta.cuenta = "";
      }

      if (this.modalCuenta.cuenta.cuenta == undefined) {
        this.alerta("Seleccione una cuenta", "error");
        this.modalCuenta.cuenta = "";
        return;
      }

    },




    SelctCbtipo() {

      if (this.modalCuenta.tipo == null) {
        this.modalCuenta.tipo = "";
      }

      if (this.modalCuenta.tipo.codigo == undefined) {
        this.alerta("Seleccione un tipo", "error");
        this.modalCuenta.tipo = "";
        return;
      }

    },



    SelctCborigen() {

      if (this.modalCuenta.origen == null) {
        this.modalCuenta.origen = "";
      }

      if (this.modalCuenta.origen.codigo == undefined) {
        this.alerta("Seleccione un origen", "error");
        this.modalCuenta.origen = "";
        return;
      }

    },

    SelctCbOperacion() {

      if (this.documento.operacion == null) {
        this.documento.operacion = "";
      }

      if (this.documento.operacion.codigo == undefined) {
        this.alerta("Seleccione una operacion", "error");
        this.documento.operacion = "";
        return;
      }

    },





    CargarROperacion() {
      this.$axios.get(this.$hostname + this.$hName + '/operaciones/find/ENT', { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.ROperacion = res.data;
      });
    },


    CargarRTipopago() {
      this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findallactivo', { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.RTipoGasto = res.data;
      });
    },




    CargarRcuenta() {
      this.$axios.get(this.$hostname + this.$hName + '/catalogo/findall', { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.Rcuenta = res.data;
      });
    },

    fecFormatter(params0) {
      var params = new Date(params0).toISOString().substr(0, 10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


      // if(this.abrirTablaDinamica ==false){

      // this.Colss.p1=9
      // }else{

      // this.Colss.p1=6
      // }


    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },


    alertaModal(e, l) {


      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newcuenta()
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }

    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },




    newcuenta() {
      this.CuentaNueva = {

        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true,
      }

      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      },



        this.acti_Nueva_cuenta = false



    },


    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.documento = {
        codigo: 0,
        descripcion: "",
        nombrecorto: "",
        operacion: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        comprobante: false,
        borrado: false,
        activo: true,
        documentoentradadiarioCatalogoList: []
      },






        this.Tbcuenta = [],

        this.modalCuenta = {
          codigoDocumento: 0,
          cuenta: null,
          tipo: null,
          origen: null,
          moneda: null
        },

        this.objec = {
          codigoDocumento: 0,
          cuenta: null,
          tipo: null,
          origen: null,
          moneda: null
        },


        this.ejecucion = true
      EventBus.$emit("actualizaBarraBusqueda2")

    },





    save() {

      this.debito = false
      this.credito = false

      if (this.Tbcuenta.length > 0) {


        this.Tbcuenta.forEach(element1 => {
          if (element1.debito == true) { this.debito = true }
          if (element1.debito == false) { this.credito = true }


          // debito:false
          // debito:false,
          // credito:false,

        });



      }

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {

          this.ejecucion = false
          console.log(this.documento)
          this.$axios.post(this.$hostname + this.$hName + '/documentoentradadiario/save',
            this.documento, { headers: this.$store.getters.GetheadersAxios })
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta('Dato no guardado corretamente', 'error'))
        }
      } else {

        this.alerta("Los campos en rojo son requeridos", "error");

      }

    },



    removeCuenta() {

      this.Elim_notif_cuenta.estado = !this.Elim_notif_cuenta.estado

    },

    eliminarCuenta() {

      this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null
      };

    },


    saveCuenta() {


      if (this.$refs.form2.validate()) {
        if(this.modalCuenta.cuenta.tipo.trim()==="CO"){
    this.alerta("Esta es una cuenta control. Por lo tanto no la puede asignar", "error");
  return;
}

        //console.log('buenno ok')


        //lert("Ok Entro")

        // {"activo":true,
        // "borrado":false,
        // "cuenta":"400-010-001-001",
        // "descripcion":"INGRESOS SOBRE VENTA",
        // "empresa":0,"grupo":"IN","origen":"C","tipo":"DE"}
        var add = false;


        if (this.Tbcuenta.length == 0) {

          add = true;

        }

        this.Tbcuenta.forEach(element1 => {


          //console.log(element1.catalogocuentas.cuenta)

          if (element1.catalogocuentas.cuenta == this.modalCuenta.cuenta.cuenta) {



            this.alertaModal("Esta cuenta ya está  agregada", "error");
            add = false;

          } else {

            add = true;

          }
        });


        if (add == true) {

          var json = {

            catalogocuentas: {
              cuenta: this.modalCuenta.cuenta.cuenta,
              descripcion: this.modalCuenta.cuenta.descripcion,
              empresa: this.modalCuenta.cuenta.empresa,
              grupo: this.modalCuenta.cuenta.grupo,
              origen: this.modalCuenta.cuenta.origen,
              tipo: this.modalCuenta.cuenta.tipo
            },
            debito: this.modalCuenta.origen.debito
            // moneda: this.modalCuenta.moneda,
            //tipo: this.modalCuenta.tipo.codigo

          }

          this.Tbcuenta.unshift(json);

          this.acti_Modal_cuenta = !this.acti_Modal_cuenta
          this.alertaModal("Cuenta agregada correctamente", "bien")

          this.modalCuenta = {
            codigoDocumento: 0,
            cuenta: null,
            tipo: null,
            origen: null,
            moneda: null
          }

        }




        // Tbcuenta:[]

        // var json={
        //         catalogocuentas= modalCuenta.cuenta,
        //         debito= modalCuenta.origen.debito,
        //         tipo= modalCuenta.tipo.codigo
        //     }

        // this.TbGanadero.push(this.ganaderoo); 


        // this.$axios.get(this.$hostname + this.$hName + '/services/Moneda/save',
        //             JSON.parse(JSON.stringify(this.documento)))
        //              .then(res => this.alerta("Dato guardado correctamente", "bien"))
        //              .catch(error => this.alerta(error,'error'))


      } else {

        this.alertaModal("Los campos en rojos son obligatorios", "error");

        // this.alerta("los campos en rojo son Requeridos", "error");

      }

    },


    imprimirList() {
      this.dialogimprimirList = true
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    NotifMessage() {

      if (this.documento.codigo != 0 && this.documento.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarDocumento"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un documento para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },



    remove() {

      if (this.documento.codigo != 0 && this.documento.codigo != null) {

        var jsn = {

          estado: "bien",
          mensage: "Cuenta sin movimiento"

        }

        this.Movimientoeliminar(jsn)



        // this.$axios.get(this.$hostname + this.$hName + '/services/DocumentoPv/DocumentoTieneOperacion',
        //             JSON.parse(JSON.stringify(this.documento)))
        //             .then(res => this.Movimientoeliminar(res.data) )
        //             .catch(error => this.alertaModal(error,'error'))

      }

      // this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    Movimientoeliminar(e) {



      if (e.estado == "error") {

        this.alertaModal(e.mensage, e.estado)

      }

      if (e.estado == "bien") {
        this.Elim_notif.estado = !this.Elim_notif.estado;
      }

      //console.log(e)



    },

    eliminar() {
      if (this.documento.codigo != 0 && this.documento.codigo != null) {


        if (this.$refs.form.validate()) {
          this.$axios.post(this.$hostname + this.$hName + '/documentoentradadiario/borrar', this.documento,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              if (res.data)
                this.alerta('Dato eliminado correctamente', 'bien')
            })
            .catch(error => this.alerta('Dato no eliminado correctamente', 'error'))
        }
      }


    },

    cerrarmodalcuenta() {


      //   if(this.modalCuenta.codigoDocumento!=0){

      // var json={

      //     catalogocuentas:{
      //             cuenta: this.objec.cuenta.cuenta,
      //             descripcion: this.objec.cuenta.descripcion,
      //             empresa:this.objec.cuenta.empresa,
      //             grupo: this.objec.cuenta.grupo,
      //             origen: this.objec.cuenta.origen ,
      //             tipo: this.objec.cuenta.tipo 
      //         },

      //       debito: this.objec.origen.debito,
      //       tipo: this.objec.tipo.codigo

      //   }

      // //console.log(this.objec)

      // this.Tbcuenta.push(json); 

      //   }

      if (this.objec.cuenta != null) {

        var json = {
          catalogocuentas: {
            cuenta: this.objec.cuenta.cuenta,
            descripcion: this.objec.cuenta.descripcion,
            empresa: this.objec.cuenta.empresa,
            grupo: this.objec.cuenta.grupo,
            origen: this.objec.cuenta.origen,
            tipo: this.objec.cuenta.tipo
          },
          moneda: this.objec.moneda,
          debito: this.objec.origen.debito,
          tipo: this.objec.tipo.codigo
        };

        this.Tbcuenta.unshift(json);

      }


      this.acti_Modal_cuenta = !this.acti_Modal_cuenta


      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }

    },

    Modaldatostabla(e) {

      // modalCuenta:{
      //   codigoDocumento:0,
      //   cuenta:null,
      //   tipo:null,
      //   origen:null
      // },

      if (e.hasOwnProperty('moneda')) {
        this.modalCuenta.moneda = JSON.parse(JSON.stringify(e.moneda))
        this.objec.moneda = JSON.parse(JSON.stringify(e.moneda))
      } else {
        this.modalCuenta.moneda = null
        this.objec.moneda = null
      }


      this.modalCuenta.codigoDocumento = this.documento.codigo
      this.objec.codigoDocumento = this.documento.codigo
      //console.log(e)

      // this.editedIndex = this.Tbcuenta.indexOf(e)
      // this.modalCuenta.cuenta = Object.assign({}, e.catalogocuentas)


      this.modalCuenta.cuenta = e.catalogocuentas
      this.objec.cuenta = e.catalogocuentas
      // this.modalCuenta.tipo.codigo=e.tipo

      if (this.modalCuenta.codigoDocumento == 0) {
        this.modalCuenta.marc = true
      }


      if (e.debito == false) {
        this.modalCuenta.origen = this.OrigenCatalogo[0]
        this.objec.origen = this.OrigenCatalogo[0]
      } else {

        this.modalCuenta.origen = this.OrigenCatalogo[1]
        this.objec.origen = this.OrigenCatalogo[1]


      }


      this.tipoCatalogo.forEach(element1 => {

        if (element1.codigo == e.tipo) {

          this.modalCuenta.tipo = element1
          this.objec.tipo = element1

        }
      });

      const index = this.Tbcuenta.indexOf(e)
      this.Tbcuenta.splice(index, 1)


      this.acti_Modal_cuenta = !this.acti_Modal_cuenta


    },





    datostabla(e) {

      console.log('datos general')
      console.log(e)
      var jso = JSON.parse(JSON.stringify(e));

      if (e.hasOwnProperty('fechaCreacion')) {

        jso.fechaCreacion = new Date(e.fechaCreacion).toISOString().substr(0, 10);
      }

      if (e.hasOwnProperty('fechaModificacion')) {
        jso.fechaModificacion = new Date(e.fechaModificacion).toISOString().substr(0, 10);
      }

      this.documento = jso;




      this.acti_tb_data = false;
      this.documento.usuarioModificacion = "admin"

      //console.log(e)

      this.$axios.get(this.$hostname + this.$hName + '/documentoentradadiario/getDetalle/' + e.codigo, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.Tbcuenta = res.data, this.documento.documentoentradadiarioCatalogoList = res.data });

    },

    docTipoCuenta(e) {

      this.documento.documentoentradadiarioCatalogoList = []
      this.documento.documentoentradadiarioCatalogoList = e






      // this.documento.cuenta.forEach(element1 => {

      // this.tipoCatalogo.forEach(element2 => {

      //         if (element2.codigo==element1.tipo) {



      //             element1.push("TipoCuentaDesc:"+element2.descripcion)

      //         }


      //  });

      //       });


    },


    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data
      this.filtro()

    },

    async filtro() {
      await this.$axios.get(this.$hostname + this.$hName + '/documentoentradadiario/findall', { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.list = res.data;
      });
    },

  },

  computed: {

    botonCuenta() {


      if (this.windowSize.width <= 800) {
        this.BarraSecundariaAprir = false
      }

      if (this.windowSize.width > 800) {
        this.BarraSecundariaAprir = true
      }





      if (this.documento.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }

      if (this.documento.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }

  }







};
</script>

<style>

</style>
