<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px">
<ContenidoBase 
  :titulo="'REGISTRO DE MANTENIMIENTOS'"
  :descripcion="'Crear Registro de mantenimientos'"
   :EstadoBtn="EstadoBtn"
   @MethodNuevo="newwRegistroMantenimientos"
   @MethodGuardar="saveRegistroMantenimientos"
    @MethodAnular="NotifMessage" 
   @MethodImprimir="imprimirList"  
/>
  </tr>
    <tr>
  <!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir"   >

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
      <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
  <!-- <v-btn-toggle  ></v-btn-toggle> -->
        <v-btn small width="95%" color="yellow accent-4"    @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;" class="fa fa-eye"> </i>  ver</v-btn>
       
  </center>

<!-- BarraDinamicaNormal -->
   
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-76"   >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'height':this.windowSize.height-76+'px' ,'max-height':this.windowSize.height-76+ 'px'  }">
 <div class="px-2">
 <center>

 <v-col cols="12" md="12" sm="12"> 
<v-card color="grey lighten-3" >
  <!-- <h3 v-if="this.RegistroMantenimientos.codigo==0">NUEVO REGISTRO</h3>
  <h3 v-if="this.RegistroMantenimientos.codigo>0">EDITANDO REGISTRO</h3> -->
<div class="px-2">

  <v-form ref="formMant" v-model="valid" lazy-validation>
    <v-row>
     
<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-1" >

 <v-tooltip top>
<template v-slot:activator="{ on }">

<v-combobox

                            ref="RefCombMant"
                            v-model="RegMantenimiento"
                            label="Seleccionar Mantenimiento :"
                            outlined
                            dense
                            autocomplete="off"
                            :items="Rmantenimiento"
                            no-filter
                            item-text="id"
                            @blur="SelctCbmantenimiento(RegMantenimiento)"
                            @focus="cargarmantenimiento"
                            @change="changeRegmantenimiento"
                            @keyup="CbFilmantenimiento"
                        >

<template v-slot:selection="{ attrs, item, parent, selected}">
<span >#Mat: {{item.id}}-- {{item.cliente.nombre}}</span>
<!-- <span >#Mat: {{item.id}}</span> 
item-text="id"-->
<!-- <span v-if="props.item.unidadProducto!=null && props.item.unidadProducto.hasOwnProperty('codigoProducto')" >{{ item.codigoProducto }}</span> 
-->
</template>

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-font"
                            ></i>
                          </template>

<template slot="item" slot-scope="item">
 <!-- slot="item" slot-scope="item" -->
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<!-- <span >#Mat: {{item.id}}-- {{item.cliente.nombre}}</span> -->

<!-- <span style="font-size:13px; display:none;">{{item.item.indx = item.item.id+"--"+item.item.cliente.nombre}}</span> -->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>#Mant:</b> {{item.item.id}}</p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CLIENTE: </b>{{item.item.cliente.nombre}}</p>
  <!-- <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>ESPECIALIDAD: </b>{{item.item.especialidad.descripcion}}</p>-->
<span v-if="item.item.anulado==false" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="item.item.activo==true"><b>STATUS:</b> <span class="red--text">Pendiente</span></p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="item.item.activo==false"><b >STATUS:</b><span class="green--text" >Realizada</span></p> 
</span>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="item.item.anulado==true"><b>ESTADO:</b> <span class="red--text">Anulado</span></p>
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="item.item.anulado==false"><b >ESTADO:</b><span class="green--text" >Activo</span></p> 
</v-col>
 

 </template>
</v-combobox>
 </template>
          <span><b>Nota: </b>Busqueda por nombre de cliente </span>
  </v-tooltip>

</v-col>

  <v-col cols="12" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 px-1" >
 <v-combobox
                            v-model="RegistroMantenimientos.cliente"
                            label="Cliente:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="Rcliente"
                            item-text="nombre"
                            @blur="SelctCbCliente"
                            @focus="cargarCliente"
                            style="font-size:13px;"
                            @keyup="CbFilClient"
                            @change="cargarFacturaCliente"
                            
                           
                        >

                          <template v-slot:prepend>
                            <i
                                style="font-size: 20px;"
                                class="fa fa-user-circle-o"
                            ></i>
                          </template>

<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<!--style="border: 1px dashed  #616161;"  -->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p  class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO:</b> {{item.item.codigo}}</p>
  <p  class="pa-0 ma-0 py-0 my-0"><b>NOMBRE: </b>{{item.item.nombre}}</p>
  <span class="pa-0 ma-0 py-0 my-0">
  <span class="px-2"><b>TEL.: </b> {{selectTelefonoCliente(item.item)}}</span>
</span>
</v-col>
 </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="dialogCrearcliente=true" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Crear Cliente</span>
        </v-tooltip> 
        
 </template>

                        </v-combobox>

</v-col>

 <v-col cols="12" md="3" sm="4" class="pa-0 ma-0 py-0 my-0 px-1">
<v-textarea
                  autocomplete="off"
                    label="* Direccion:"
                    dense
                    outlined
                    rows="1"
                    v-model="infCliente.direccion"
                    disabled
                    id="formdisabledInput"
                    style="font-size:13px;"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>
 </v-col>

 <v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<!----------------------------------- Telefono  -->
<v-text-field
         
            id="formdisabledInput"
            label="Télefono :"
            dense
            outlined
            autocomplete="off"
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="infCliente.telefono"
            style="font-size:13px"
            disabled
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>
          </v-text-field>
  </v-col>

 <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
 <v-text-field label="E-mail"
                      id="formdisabledInput"
                     dense outlined
                     v-model="infCliente.email"
                     disabled
                     style="font-size:13px"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>
 </v-col>

 <v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
   <v-text-field label="Duración Vigencia"
                    style="font-size:13px;"
                    :rules="[$rules.required]"
                     dense outlined
                     v-model.number="RegistroMantenimientos.vigenciacant"
                     @keyup="KeyUpCantidad"
                     @input="ChangeCreaFechafin"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-repeat"></i> 
                      </template>
                    </v-text-field>

 </v-col>

<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<!-- ----------------------------- Frecuencia-->
<v-combobox
            style="font-size:13px;"
            autocomplete="off"
            placeholder=" "
            dense
            outlined
            label="Vigencia:"
            :items="Rfrecuencia"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="RegistroMantenimientos.vigenciauni"
            @blur="SelctCbViengencia"
            @change="ChangeCreaFechafin"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-google-wallet"></i>
            </template>
          </v-combobox>

</v-col>


<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Inicio "
        v-model="RegistroMantenimientos.fecha"
        :rules="[$rules.required]"
        style="font-size:13px;"
        @input="ChangeCreaFechafin"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>
</v-col>

<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field
        id="formdisabledInput"
        dense
        outlined
        type="date"  
        label="Fecha final "
        v-model="RegistroMantenimientos.fechafin"
        :rules="[$rules.required]"
        style="font-size:13px;"
        disabled
        
        
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>
</v-col>


<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field label="Repetir cada"
                    style="font-size:13px;"
                    :rules="[$rules.required]"
                     dense outlined
                     v-model.number="RegistroMantenimientos.frecuenciacant"
                     @keyup="KeyUpCantidad"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-repeat"></i> 
                      </template>
                    </v-text-field>
</v-col>

<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
   <!-- ----------------------------- Frecuencia-->
<v-combobox
            style="font-size:13px;"
            autocomplete="off"
            placeholder=" "
            dense
            outlined
            label="Frecuencia:"
            :items="Rfrecuencia"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="RegistroMantenimientos.frecuenciauni"
            @blur="SelctCbFrecuencia"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-google-wallet"></i>
            </template>
          </v-combobox>

</v-col>

<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 py-0 my-0 px-1">
<v-text-field label="Hora Estimada"
                    style="font-size:13px;"
                    :rules="[$rules.required]"
                     dense outlined
                     v-model="RegistroMantenimientos.horaestimada"
                     type="time"
                     
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>
</v-col>

<v-col v-if="false" cols="12" md="3" sm="6" class="pa-0 ma-0 py-0 my-0 px-1">
 <v-radio-group hide-details class="ma-1 pa-1"  v-model="RegistroMantenimientos.rutinario" row>     
                  <v-radio label="Rutinario" color="green" :value="true"></v-radio>
                  <v-radio label="No Rutinario" color="green" :value="false"></v-radio>                                       
</v-radio-group>
</v-col>
<v-col v-if="RegistroMantenimientos.anulado==true"  cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-1" >
  <h3 class="red--text" >Registro Anulado</h3>
</v-col>

</v-row>
  </v-form>
 
</div>
 </v-card>
</v-col>

<v-col cols="12" md="12" sm="12" class="py-0" > 
<v-row>
<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-1">
 <v-card>
<v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12">
  <span :class="`d-flex justify-star white--text px-1`"><b>EQUIPOS FACTURADOS AL CLIENTE</b></span>
</v-col>

<v-row class="pa-0 ma-0 ">
  <v-col  class="pa-0 ma-0 py-2 my-0 px-1"  cols="12" md="6" sm="6">
    <v-text-field
        dense
        outlined
        type="date"  
        label="desde "
        v-model="FiltFact.desde"
        :rules="[$rules.required]"
        style="font-size:13px;"
        hide-details
        @blur="BlurDesdeFilt"
        @input="cargarFacturaCliente"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>
  </v-col>

   <v-col  class="pa-0 ma-0 py-2 my-0 px-1"  cols="12" md="6" sm="6">
     <v-text-field
        dense
        outlined
        type="date"  
        label="hasta "
        v-model="FiltFact.hasta"
        :rules="[$rules.required]"
        style="font-size:13px;"
        hide-details
         @blur="BlurHastaFilt"
         @input="cargarFacturaCliente"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>
  </v-col>
</v-row>


<v-data-table
      class="RowSelectColor TbSize13" 
      item-key="indx"
      :headers="[
      { text: '', value: 'data-table-expand', class: ['no-gutters black--text'] },
      {text: 'FECHA', sortable: false,  value: 'fecha', class: ['no-gutters black--text']},
                { text: '# FACTURA', sortable: false, value: 'facturasventaPK.secuencia', class: ['no-gutters black--text'] },
                { text: 'ACCIÓN', sortable: false, value: 'action', class: ['no-gutters black--text'] },
                
                
                ]"
              dense
              :items="ListFactura" 
              show-expand  
              :single-expand="true" 
              :expanded.sync="expanded" 
              :items-per-page="15"
            >
  <template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<v-btn fab @click.prevent="AddFact(item)" x-small icon color="green"><i style="font-size: 20px;" class="fa fa-check-circle"></i></v-btn>            
  </template>
              <template v-slot:item.fecha="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = ListFactura.indexOf(props.item)}}</span>
                <span >{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="pa-0 ma-0 px-1 py-1 ">
       <v-data-table
       
      hide-default-footer
      hide-default-header
      class="RowSelectColor TbSize13" 
      item-key="indx"
      :headers="[{ text: '# FACTURA', sortable: false, value: 'refcodigo', class: ['no-gutters black--text'] }]"
              dense
              :items="item.facturasventaDetalleList"   
              :items-per-page="-1"
              :single-select="false"
              :show-select="true"
              v-model="selectedListFactEquipo"
              @item-selected="obtenerDatoSelectFactEquipo"
              @click:row="obtenerDatoSelectFactEquipoRow"
            >

                <template v-slot:item.refcodigo="props">
          <span style="font-size:13px; display:none;">{{props.item.indx = item.facturasventaDetalleList.indexOf(props.item)+"Ref-"+item.facturasventaPK.secuencia}}</span>
        <v-col  class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12"> 
          <!-- <span>{{props.item.cantidad}} - {{props.item.unidades.descripcion}} / {{props.item.productos.descripcion}}</span> -->
          <span> {{props.item.productos.descripcion}}</span>
        </v-col>
              </template>
             <!-- <template v-slot:item.refcodigo="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = ListFactura.indexOf(props.item)}}</span>
                <span >{{ FormatoFecha(props.item.fecha) }}</span>
              </template> -->

      <!-- <template v-slot:item="props"> 
        <span style="font-size:13px; display:none;">{{props.item.indx = ListFactura.indexOf(props.item)}}</span>
        <v-col  class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12"> 
          <span>{{props.item.cantidad}} - {{props.item.unidades.descripcion}} / {{props.item.productos.descripcion}}</span>
        </v-col>
      </template>  -->
      <template v-slot:no-data>
      <span class=" black--text" >Factura sin equipos</span>
      </template>
</v-data-table> 
       
      </td>
    </template>

</v-data-table>


 </v-card>
</v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-1">

 <v-card>
<v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12">
  <span :class="`d-flex justify-star white--text px-1`"><b>EQUIPOS PARA MANTENIMIENTO</b></span>
</v-col>

<div v-if="RegistroMantenimientos.id==0" class="py-2 px-2">
<v-form ref="formEquipo" v-model="valid" lazy-validation > 
<v-row> 
<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-2"> 
  <v-combobox
                            v-model="equipo"
                            label="Seleccionar equipo:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="Requipos"
                            item-text="descripcion"
                            @blur="SelctCbEquipo"
                            @focus="cargarEquipo"
                            style="font-size:13px;"
                            hide-details
                            @keyup="CbFilEquipo"
                        >

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-bolt"></i>
                          </template>

                        </v-combobox>
</v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 px-2">
 <v-combobox
                            v-model="actividad"
                            label="Seleccionar Actividad:"
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RActividad"
                            item-text="descripcion"
                            @blur="SelctCbActividad"
                            @focus="cargarActividad"
                            @keyup="CbFilRegAct"
                            style="font-size:13px;"
                            hide-details
                        >

      <template v-slot:prepend>
      <i @mouseover="btnCrearActivida=true" v-if="btnCrearActivida==false"  style="font-size: 20px;" class="fa fa-ils"></i>

<v-tooltip top   v-if="btnCrearActivida==true"  >
<template v-slot:activator="{ on }">
   
<v-btn @mouseout="btnCrearActivida=false" @click.prevent="dialogCrearActivida=true" v-on="on" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>

 </template>
    <span>Crear Nueva Actividad</span>
 </v-tooltip>
  </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="AgregarEquipo" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Agrerar equipo</span>
        </v-tooltip> 
        
 </template> 


<!-- <template v-slot:append-outer>
  <v-tooltip top>
<template v-slot:activator="{ on }">  
   
<v-btn class="pa-0 ma-0" @click.prevent="AgregarOrdenDetalle" v-on="on" color="green" outlined  small dark>
<i style="font-size: 25px;" class="fa fa-plus-square"></i>
</v-btn>
 </template>
          <span>Agrerar Orden</span>
        </v-tooltip> 
        
 </template>  -->
                        </v-combobox>


</v-col>
</v-row> 
</v-form>
<v-form ref="formTbEquipo" v-model="valid" lazy-validation > 
  <v-data-table
       id="TbBord"
      v-if="RegistroMantenimientos.mantenimientoDetalleList.length>0"
      class="elevation-1 RowSelectColor TbSize13"
       item-key="indx"
      :headers="[{text: 'CÓDIGO', sortable: false, width:100, value: 'producto.codigo', class: ['no-gutters black--text']},
                { text: 'EQUIPO', sortable: false, value: 'descripcion', class: ['no-gutters black--text'] },
                { text: 'ACTIVIDAD', sortable: false, width:300, value: 'actividad', class: ['no-gutters black--text'] },
                { text: 'ACCIÓN', sortable: false,width:50, value: 'action', class: ['no-gutters black--text'] }]"
              dense
              :items="RegistroMantenimientos.mantenimientoDetalleList"     
              :items-per-page="15"
              
            >
  <template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<v-btn fab @click.prevent="deleEquipo(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>             
</template>

            <template v-slot:item.descripcion="props">
              <v-text-field label="" dense outlined autocomplete="off" hide-details
                          id="formdisabledInput" style="font-size:13px;" v-model="props.item.descripcion"
                          :disabled="RegMantenimiento!=null && props.item.producto.servicio==false" maxlength="50">
                        </v-text-field>
              </template>

              <template v-slot:item.producto.codigo="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = RegistroMantenimientos.mantenimientoDetalleList.indexOf(props.item)}}</span>
              
                <v-text-field label="" dense outlined autocomplete="off" hide-details
                          id="formdisabledInput" style="font-size:13px;" :value="props.item.producto.codigo"
                          disabled>
                        </v-text-field>
              </template>

              <template v-slot:item.actividad="props">
                <v-combobox
                            v-model="props.item.actividad"
                            label=""
                            required
                            outlined
                            dense
                            :rules="[$rules.required]"
                            autocomplete="off"
                            :items="RActividad"
                            item-text="descripcion"
                            @blur="SelctCbActividadTb(props.item.actividad)"
                            @focus="cargarActividad"
                            @keyup="CbFilRegAct"
                            style="font-size:13px;"
                            hide-details
                        >
                 </v-combobox>
              </template>
</v-data-table>
</v-form>
</div>

<div v-if="RegistroMantenimientos.id>0" class="py-2 px-2">
 <v-data-table
      v-if="RegistroMantenimientos.mantenimientoDetalleList.length>0"
      class="RowSelectColor TbSize13"
       item-key="indx"
      :headers="[{text: 'CÓDIGO', sortable: false,  value: 'producto.codigo', class: ['no-gutters black--text']},
                { text: 'EQUIPO', sortable: false, value: 'descripcion', class: ['no-gutters black--text'] }
              ]"
              dense
              :items="RegistroMantenimientos.mantenimientoDetalleList"     
              :items-per-page="15"
              
            >
  <template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<v-btn fab @click.prevent="deleEquipo(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                 
</template>

              <template v-slot:item.producto.codigo="props">
                <span style="font-size:13px; display:none;">{{props.item.indx = RegistroMantenimientos.mantenimientoDetalleList.indexOf(props.item)}}</span>
                <span style="font-size:13px;">{{props.item.producto.codigo}}  </span>
              </template>

</v-data-table>
</div>
 </v-card>


</v-col>
</v-row>
</v-col>

 </center>
</div>  

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
         
        <!-- BarraBusqueda2 -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarRegistroMantenimientos'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->



</v-dialog>

<!-- ------------------------------------------------------ Modal- Cliente-->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogCrearcliente" persistent fullscreen hide-overlay>

<v-card>
 <div>

<v-toolbar dark color="indigo darken-4" dense>
  
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrardialogCrearcliente"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
 </v-card-actions>
    </v-toolbar> 

<v-col cols="12" md="12" sm="12">

 <v-card flat >
<ClientesBasico ref="RefComponCliente"/>
 </v-card>

</v-col>

</div>
</v-card>


</v-dialog>

<!-- ------------------------------------------------------ Modal- CrearActivida-->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogCrearActivida" persistent fullscreen hide-overlay>

<v-card>
 <div>

<v-toolbar dark color="indigo darken-4" dense>
  
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="dialogCrearActivida=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
 </v-card-actions>
    </v-toolbar> 

<v-col cols="12" md="12" sm="12" >
<v-card flat>
<RegistroActividades/>
</v-card > 
</v-col>

</div>
</v-card>


</v-dialog>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>



</template>

<script>
import VueAutonumeric from "../../components/AutoNumericNuevo";

import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,changeZone,getParamFecha} from "@/js/Funciones.js";
import RegistroEspecialidades from "@/EventoMantenimiento/Mant/RegistroEspecialidades.vue";
import ClientesBasico from "@/CuentaXCobrar/Mant/ClientesBasico.vue";
import RegistroActividades from "@/EventoMantenimiento/Mant/RegistroActividades.vue";
export default {

   components: {
    VueAutonumeric,
    ContenidoBase,
    RegistroEspecialidades,
    ClientesBasico,
    RegistroActividades

  },

mounted() {





// var oj =this.$vuetify.lang
// console.log(JSON.parse(locales))




// this.$vuetify.lang.current = 'es'

// console.log(this.$vuetify.lang)


  },

   created(){
  },
  // destroyed(){
  //   location.reload()
  // },

  data:()=> ({

  windowSize:{width:"auto", height:"auto"},

// <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>CÓDIGO: {{item.item.codigo}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DESCRIPCIÓN: {{item.item.descripcion}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>ESPECIALIDAD: {{item.item.especialidad.descripcion}}</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 red--text" v-if="item.item.activo==true"><b>Activo</b></p>
//   <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 green--text" v-if="item.item.activo==false"><b>In-Activo</b></p>

RegMantenimiento:null,
Rmantenimiento:[],
dialogCrearActivida:false,
actividad:null,
btnCrearActivida:false,
RActividad:[],


Rfrecuencia:[
{codigo:1, des:'dia', descripcion:"DÍA"},
{codigo:2, des:'mes', descripcion:"MES"},
{codigo:3, des:'ano', descripcion:"AÑO"}
],


expanded:[],
selectedListFactEquipo:[],
Rcliente:[],
REspecialidad:[],


valid:true,
dialogCrearcliente:false,

Requipos:[],
ListFactura:[],
ListFactSelected:[],
FactClientSelected:null,
equipo:null,

infCliente:{
direccion:'',
telefono:'',
email:''
},

OrdenAutomatizada:[],
RegistroMantenimientos:{
id: 0,
activo: true,
cliente: null,
fecha: fechaNueva(),
fechafin: null,
frecuenciacant: 0,
frecuenciauni: null,
horaestimada: 0,
rutinario: true,
vigenciacant: 0,
vigenciauni: null,
mantenimientoDetalleList:[],
anulado:false,
borrado:false

},

FiltFact:{
  desde:fechaNueva(),
  hasta:fechaNueva()
  },


/*FiltFact:{
  desde:new Date().toISOString().substr(0,10),
  hasta:new Date().toISOString().substr(0,10)
  },*/

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },




  ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

       notif: {
        estado: false,
        nombre: "",
        Exec:""
      },



    BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false

}),

 methods: {
  currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,changeZone,getParamFecha,

newwRegistroMantenimientos() {

this.BarraTablaDinamicaAprir = false

this.RegistroMantenimientos={
id: 0,
activo: true,
cliente: null,
fecha: fechaNueva(),
fechafin: null,
frecuenciacant: 0,
frecuenciauni: null,
horaestimada: 0,
rutinario: true,
vigenciacant: 0,
vigenciauni: null,
mantenimientoDetalleList:[],
anulado:false,
borrado:false
},

this.selectedListFactEquipo=[]
this.ListFactura=[]
this.ejecucion = true
this.RegMantenimiento=null
this.equipo=null,
this.actividad=null

EventBus.$emit("loading", false); 

    },

async saveRegistroMantenimientos() {

 if(this.RegistroMantenimientos.mantenimientoDetalleList.length==0){ 
   this.alerta(" Agrege al menos un equipo para mantenimiento", "error"); 
   return 0; 
   }



 
  if(this.$refs.formMant.validate()) {


if(this.ejecucion==true){

  this.ejecucion=false
  EventBus.$emit("loading", true); 

 if(this.$refs.formTbEquipo.validate()) {
await this.GetOrdenAutomatizada()
await this.saveRegistroMantContin()
 }else{
  this.ejecucion=true
  EventBus.$emit("loading", true); 
  this.alerta("Seleccione los campos en rojo de los equipos para mantenimientos", "error");
 }
}      
}else{

              this.alerta("los campos en rojo son requeridos", "error");

       }

},

async saveRegistroMantContin(){

/*var datos= JSON.parse(JSON.stringify(this.RegistroMantenimientos))
datos.ordenesMantenimientoList=JSON.parse(JSON.stringify(this.OrdenAutomatizada))*/
EventBus.$emit("loading", true);
var obj={
  mantenimiento: JSON.parse(JSON.stringify(this.RegistroMantenimientos)),
  ordenesMantenimientoList:JSON.parse(JSON.stringify(this.OrdenAutomatizada)),
 }

obj.mantenimiento.frecuenciauni=JSON.parse(JSON.stringify(this.RegistroMantenimientos.frecuenciauni.codigo))
obj.mantenimiento.vigenciauni=JSON.parse(JSON.stringify(this.RegistroMantenimientos.vigenciauni.codigo))
obj.mantenimiento.fecha=this.RegistroMantenimientos.fecha+"T04:00"
obj.mantenimiento.fechafin=this.RegistroMantenimientos.fechafin+"T04:00"

this.$axios
            .post(this.$hostname + this.$hName + "/mantenimientos/save",
            obj, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bienLimpio")
            })
            .catch(error => { this.alerta("Dato no guardado ", "error") });

/*
var obj={
   datos: JSON.parse(JSON.stringify(this.RegistroMantenimientos)),
   usuario:this.$store.getters.GetdatosInfUsu.userid,
   OrdenAutomatizada:JSON.parse(JSON.stringify(this.OrdenAutomatizada)),
 }

obj.datos.frecuenciauni=this.RegistroMantenimientos.frecuenciauni.codigo
obj.datos.vigenciauni=this.RegistroMantenimientos.vigenciauni.codigo


// console.log(this.$refs.RefCombEsp.lazySearch)internalSearch
        this.$axios
        .post(this.$hostname + this.$hName + "/services/Mantenimientos/save", JSON.parse(JSON.stringify(obj)))
        .then(res =>{this.alerta("Dato guardado correctamente!", "bienLimpio")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));*/

},



NotifMessage() {

if(this.RegistroMantenimientos.id!=0 && this.RegistroMantenimientos.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarRegistroMantenimientos"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una Registro Mantenimientos para eliminar", "error")}


    },

 Exec_notif(e) {

this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

 
eliminar() {


  EventBus.$emit("loading", true); 
  var datos=JSON.parse(JSON.stringify(this.RegistroMantenimientos))
  datos.frecuenciauni=JSON.parse(JSON.stringify(this.RegistroMantenimientos.frecuenciauni.codigo))
  datos.vigenciauni=JSON.parse(JSON.stringify(this.RegistroMantenimientos.vigenciauni.codigo))

  this.$axios
            .post(this.$hostname + this.$hName + "/mantenimientos/Anulando",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage,res.data.estado)})
            .catch(error => { this.alerta("Dato no Anulado ", "error") });

/*var obj={
   datos: JSON.parse(JSON.stringify(this.RegistroMantenimientos)),
   usuario:this.$store.getters.GetdatosInfUsu.userid,
 }

 this.$axios
        .post(this.$hostname + this.$hName + "/mantenimientos/Anulando", JSON.parse(JSON.stringify(obj)))
        .then(res =>{this.alerta(res.data.mensage,res.data.estado)})
        .catch(error =>{ this.alerta("Dato no Anuulado correctamente", "error")});*/
},

anular(){

},

imprimirList() {
  EventBus.$emit("loading", true); 

var obj={
   idcod:this.RegistroMantenimientos.id,
   vigencia:this.RegistroMantenimientos.vigenciauni.descripcion,
   frecuencia:this.RegistroMantenimientos.frecuenciauni.descripcion
 }

this.$axios.post(this.$hostname + this.$hName +'/mantenimientos/imprimirMantenimiento', JSON.parse(JSON.stringify(obj)),{ headers: this.$store.getters.GetheadersAxios }).then(res => {
  EventBus.$emit("loading", false); 
/*let pdfWindow = window.open("Reporte de imprecion")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>") */
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'registro Mantenimiento');
         
      });



    },



CbFilClient(e){

  if (e.target.value.length >= 2) {
         
         this.$axios
           .get(
             this.$hostname +
               this.$hName +
               "/clientes/findtodo/" +
             (e.target.value === "" ? "A" : e.target.value) +
             "/3/25/0",
             {headers:this.$store.getters.GetheadersAxios}
           )
           .then(res => (this.Rcliente = res.data));
       }
 /* if (e.target.value.length >= 2) {    
var obj=`/services/clienteservices/findAllClientesCombo/${this.$store.getters.GetdatosInfUsu.userid}/25/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj)
            .then(res => (this.Rcliente = res.data));
        }*/

},


cargarCliente(){

this.$axios.get(this.$hostname+this.$hName+'/clientes/findtodo/a/3/25/0',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rcliente=res.data))

/*var obj=`/services/clienteservices/findAllClientesCombo/${this.$store.getters.GetdatosInfUsu.userid}/25/null`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
  this.Rcliente=res.data
  })*/
//  var headers = this.$store.getters.GetheadersAxios
//       this.$axios.get(this.$hostname + this.$hName + '//', {headers})
//           .then(res => (this.Rcliente = res.data))
},


SelctCbCliente(){

   if (this.RegistroMantenimientos.cliente == null) {

        this.RegistroMantenimientos.cliente = ''
      }

      if (this.RegistroMantenimientos.cliente.codigo == undefined) {

        this.alerta("Seleccione un Cliente", "error");
        this.RegistroMantenimientos.cliente = ''
        return

      }

},

// Un método que recibe un cliente y verifica en una lista si tiene telefono .
selectTelefonoCliente(e) {
      if (typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return ' ' }
    },
CbFilEquipo(e){
  if (e.target.value.length >= 2) {    
var obj=`/mantenimientos/findAllProductoCombo/25/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj, { headers: this.$store.getters.GetheadersAxios })
            .then(res => (this.Requipos = res.data));
        }

},


cargarEquipo(){
var obj=`/mantenimientos/findAllProductoCombo/25/null`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.Requipos=res.data
  })
//  var headers = this.$store.getters.GetheadersAxios
//       this.$axios.get(this.$hostname + this.$hName + '//', {headers})
//           .then(res => (this.Rcliente = res.data))
},


SelctCbEquipo(){

   if (this.equipo == null) {

        this.equipo = ''
      }

      if (this.equipo.codigo == undefined) {

       // this.alerta("Seleccione una equipo", "error");
        this.equipo = ''
        return

      }

},


SelctCbViengencia(){
 if(this.RegistroMantenimientos.vigenciauni==null){

    this.RegistroMantenimientos.vigenciauni=''
  }

if(this.RegistroMantenimientos.vigenciauni.codigo==undefined){

this.alerta("Seleccione una Viengencia", "error");
this.RegistroMantenimientos.vigenciauni=''
return
}
},


SelctCbFrecuencia(){
 if(this.RegistroMantenimientos.frecuenciauni==null){

    this.RegistroMantenimientos.frecuenciauni=''
  }

if(this.RegistroMantenimientos.frecuenciauni.codigo==undefined){

this.alerta("Seleccione una frecuencia", "error");
this.RegistroMantenimientos.frecuenciauni=''
return
}
},





KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}

if(e.target.value.length==0){e.target.value=0}

  if(e.key){
     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     if(e.target.value==null){ e.target.value=0 }

    }
    else{e.key=""}

if(e.target.value.length==0){ e.target.value=0
        e.key=0
     }
  
},


ListFactObtenerDatoSelect(e){
this.ListFactDatostabla(e.item)
},
ListFactDatostabla(e){
this.FactClientSelected=null
this.ListFactSelected=[]
this.ListFactSelected.push(e)
this.RegistroMantenimientos.mantenimientoDetalleList=JSON.parse(JSON.stringify(e.mantenimientoDetalleList))
this.FactClientSelected=JSON.parse(JSON.stringify(e))
},

AgregarEquipo(){

if(this.$refs.formEquipo.validate()) { 

//this.equipo
//this.ListFactura[this.FactClientSelected.indx].mantenimientoDetalleList=[]

var obj = {
id:0,
mantenimiento:null,
producto:  JSON.parse(JSON.stringify(this.equipo)), 
descripcion:this.equipo.descripcion.trim(),
facturasventaDetallePK:{
 id:this.equipo.codigo,
 producto:this.equipo.codigo,
 secuencia:this.equipo.codigo
}, 
actividad:JSON.parse(JSON.stringify(this.actividad)),
}




this.RegistroMantenimientos.mantenimientoDetalleList.push(obj)
//this.ListFactura[this.FactClientSelected.indx].mantenimientoDetalleList.push(this.equipo)

// AgregarEquipo
// equipo

}




},

deleEquipo(item){

const index =  this.RegistroMantenimientos.mantenimientoDetalleList.indexOf(item)
//confirm('Desea eliminar este equipo?') && this.RegistroMantenimientos.mantenimientoDetalleList.splice(index, 1)
var c= confirm('Desea eliminar este equipo?') 
if(c== true){
// this.nuevoClientes.ListFact= this.RLisFac
// this.nuevoClientes.ListFactDele.push(item);
this.RegistroMantenimientos.mantenimientoDetalleList.splice(index, 1)

var ObjItem=[]
var elet= item

 ObjItem = this.selectedListFactEquipo.filter(e=> 
e.facturasventaDetallePK.producto==elet.facturasventaDetallePK.producto && e.facturasventaDetallePK.secuencia==elet.facturasventaDetallePK.secuencia
&& e.facturasventaDetallePK.id== elet.facturasventaDetallePK.id)


if(ObjItem.length >0){
const indexRegm =  this.selectedListFactEquipo.indexOf(ObjItem[0])
this.selectedListFactEquipo.splice(indexRegm, 1)
}

}

//this.nuevoClientes.ListTel=this.RLisTel
},



BlurDesdeFilt(){

if(this.FiltFact.desde.toString().length==0){
  this.FiltFact.desde=this.fechaNueva()
}


 },


 BlurHastaFilt(){

if(this.FiltFact.hasta.toString().length==0){
  this.FiltFact.hasta=this.fechaNueva()
}

 },


cargarFacturaCliente(){


if(this.RegistroMantenimientos.cliente!=null){
var obj=`/mantenimientos/findFactClientes/${this.FiltFact.desde}/${this.FiltFact.hasta}/${this.RegistroMantenimientos.cliente.codigo}`
this.$axios.get(this.$hostname+this.$hName+obj,{ headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.ListFactura=res.data
  })

}

//  var headers = this.$store.getters.GetheadersAxios
//       this.$axios.get(this.$hostname + this.$hName + '//', {headers})
//           .then(res => (this.Rcliente = res.data))
},


AddFact(e){

if(e.facturasventaDetalleList.length>0){

e.facturasventaDetalleList.forEach(elet => {
 this.AgregarComprobEquipo(elet)
});


}else{
this.alerta("Esta factura no posee equipos", "error")
}

},

obtenerDatoSelectFactEquipo(e){
//this.selectedListFactEquipo.push(e)
//this.AgregarComprobEquipo(e.item)

if(e.value==true){
  e.item.markSelt=true
this.AgregarComprobEquipo(e.item)
}else{

var ObjItem=[]
var elet= e.item
if(this.RegistroMantenimientos.mantenimientoDetalleList.length>0){
 ObjItem = this.RegistroMantenimientos.mantenimientoDetalleList.filter(e=> 
e.facturasventaDetallePK.producto==elet.facturasventaDetallePK.producto && e.facturasventaDetallePK.secuencia==elet.facturasventaDetallePK.secuencia
&& e.facturasventaDetallePK.id== elet.facturasventaDetallePK.id)
}

if(ObjItem.length >0){
const indexRegm =  this.RegistroMantenimientos.mantenimientoDetalleList.indexOf(ObjItem[0])
this.RegistroMantenimientos.mantenimientoDetalleList.splice(indexRegm, 1)
const indexSelc =  this.selectedListFactEquipo.indexOf(elet)
this.selectedListFactEquipo.splice(indexSelc, 1)
}
}

},

obtenerDatoSelectFactEquipoRow(e){
if(!e.hasOwnProperty('markSelt')){
e.markSelt=true
this.AgregarComprobEquipo(e)
}else{

if(e.markSelt==false){
  e.markSelt=true
  this.AgregarComprobEquipo(e)
}else{
  e.markSelt=false
  this.EliminaDeselecionEquipo(e)
}


}


//this.selectedListFactEquipo.push(e)
},

//agrega y verifica que no exista en la tabla de equipos
AgregarComprobEquipo(elet){
var ObjItem=[]
if(this.RegistroMantenimientos.mantenimientoDetalleList.length>0){
 ObjItem = this.RegistroMantenimientos.mantenimientoDetalleList.filter(e=> 
e.facturasventaDetallePK.producto==elet.facturasventaDetallePK.producto && e.facturasventaDetallePK.secuencia==elet.facturasventaDetallePK.secuencia
&& e.facturasventaDetallePK.id== elet.facturasventaDetallePK.id)
}

if(ObjItem.length==0){
var obj = {
id:0,
mantenimiento:null,
descripcion:elet.descripcion.trim(),
producto:  JSON.parse(JSON.stringify(elet.productos)), 
facturasventaDetallePK:JSON.parse(JSON.stringify(elet.facturasventaDetallePK)),
actividad:null,
}
 this.RegistroMantenimientos.mantenimientoDetalleList.push(obj)

elet.markSelt=true
this.selectedListFactEquipo.push(elet)
}
},
//elimina y verifica que si exista en la tabla de equipos
EliminaDeselecionEquipo(elet){

var ObjItem=[]
if(this.RegistroMantenimientos.mantenimientoDetalleList.length>0){
 ObjItem = this.RegistroMantenimientos.mantenimientoDetalleList.filter(e=> 
e.facturasventaDetallePK.producto==elet.facturasventaDetallePK.producto && e.facturasventaDetallePK.secuencia==elet.facturasventaDetallePK.secuencia
&& e.facturasventaDetallePK.id== elet.facturasventaDetallePK.id)
}

if(ObjItem.length >0){
const indexRegm =  this.RegistroMantenimientos.mantenimientoDetalleList.indexOf(ObjItem[0])
this.RegistroMantenimientos.mantenimientoDetalleList.splice(indexRegm, 1)
const indexSelc =  this.selectedListFactEquipo.indexOf(elet)
this.selectedListFactEquipo.splice(indexSelc, 1)
}


},



ChangeCreaFechafin(){

var CantV=this.RegistroMantenimientos.vigenciacant
var fechini=new Date(this.RegistroMantenimientos.fecha)

if(this.RegistroMantenimientos.vigenciauni!=null){
  if(this.RegistroMantenimientos.vigenciauni.codigo==1){
  fechini.setDate(fechini.getDate() + CantV);
  this.RegistroMantenimientos.fechafin=new Date(fechini).toISOString().substr(0,10)
  }
   

if(this.RegistroMantenimientos.vigenciauni.codigo==2){
  fechini.setMonth(fechini.getMonth() + CantV);
   this.RegistroMantenimientos.fechafin=new Date(fechini).toISOString().substr(0,10)
  }


  if(this.RegistroMantenimientos.vigenciauni.codigo==3){
  fechini.setFullYear(fechini.getFullYear() + CantV);
   this.RegistroMantenimientos.fechafin=new Date(fechini).toISOString().substr(0,10)
  }

}
},

async GetOrdenAutomatizada(){
if(this.RegistroMantenimientos.mantenimientoDetalleList.length>0){
this.OrdenAutomatizada=[]
var fechini=new Date(this.RegistroMantenimientos.fecha)
var fechfin=new Date(this.RegistroMantenimientos.fechafin)
var fech=new Date(this.RegistroMantenimientos.fecha)
var CantF=this.RegistroMantenimientos.frecuenciacant
var stt = true

if(this.RegistroMantenimientos.frecuenciauni!=null){
 while (stt==true) {
  if(this.RegistroMantenimientos.frecuenciauni.codigo==1){
  fech.setDate(fech.getDate() + CantF);
  fech =new Date(fech)
  // this.RegistroMantenimientos.fechafin=new Date(fechini).toISOString().substr(0,10)
  }


if(this.RegistroMantenimientos.frecuenciauni.codigo==2){
  fech.setMonth(fech.getMonth() + CantF);
  fech =new Date(fech)
 }
//Nota:Provar esto
// if(this.RegistroMantenimientos.frecuenciauni.codigo==2){
//   fech.setFullYear(fech.getFullYear() + CantF);
//   fech =new Date(fech)
//  }


if(this.RegistroMantenimientos.frecuenciauni.codigo==3){
  fech.setFullYear(fech.getFullYear() + CantF);
  fech =new Date(fech)
 }

// if(this.RegistroMantenimientos.frecuenciauni.codigo==3){
//   fech.setFullYear(fech.getFullYear() + CantV);
//   fech =new Date(fech)
//  }


 if(fech>=fechfin){ 
   stt=false
 }else{

/*fechaestimada: new Date(fech).toISOString().substr(0,10),
fechapautada: new Date(fech).toISOString().substr(0,10),*/

var obj={
id: 0,
activa: true,
cliente: JSON.parse(JSON.stringify(this.RegistroMantenimientos.cliente)),
fecha: fechaNueva()+"T04:00",
fechaestimada: getParamFecha(fech),
fechapautada: getParamFecha(fech),
hora: JSON.parse(JSON.stringify(this.RegistroMantenimientos.horaestimada)),
mantenimiento: null,
pendiente: true,
tecnico:null,
ordenesMantDetalleList:[]
}

await Promise.all(this.RegistroMantenimientos.mantenimientoDetalleList.map(async (elet) => { 
                var obbj={
              id: 0, 
              actividad: JSON.parse(JSON.stringify(elet.actividad)),
              descripcion: elet.descripcion,
              producto:JSON.parse(JSON.stringify(elet.producto))
              }
              obj.ordenesMantDetalleList.push(obbj)
       }));

/*this.RegistroMantenimientos.mantenimientoDetalleList.forEach(elet => {
var obbj={
id: 0, 
actividad: JSON.parse(JSON.stringify(elet.actividad)),
descripcion: elet.descripcion,
producto:JSON.parse(JSON.stringify(elet.producto))
}
obj.ordenesMantDetalleList.push(obbj)
});*/

this.OrdenAutomatizada.push(obj)
}

 }
}

}
// while (fech<fechfin) {}


// for (let index = 0; index < array.length; index++) {
//   const element = array[index];
  
// }
// activa: true
// cliente: {activado: true, asArs: false, asMedico: false, borrado: false, carnet: '', …}
// fecha: "2022-02-07T00:00:00-04:00"
// fechaestimada: "2022-02-07T00:00:00-04:00"
// fechapautada: "2022-02-07T00:00:00-04:00"
// hora: "13:30"
// id: 1
// mantenimiento: {activo: true, cliente: {…}, fecha: '2022-02-01T00:00:00-04:00', fechafin: '2022-02-28T00:00:00-04:00', frecuenciacant: 2, …}
// status: true
// tecnico:

},


cargarActividad(){
var obj=`/actividades/findAllActivo/null`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{this.RActividad=res.data})
},

CbFilRegAct(e){
  if (e.target.value.length >= 2) {    
var obj=`/actividades/findAllActivo/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj,{ headers: this.$store.getters.GetheadersAxios })
            .then(res => (this.RActividad = res.data));
        }

},


SelctCbActividad(){

   if (this.actividad == null) {

        this.actividad = ''
      }

      if (this.actividad.id == undefined) {

       // this.alerta("Seleccione una actividad", "error");
        this.actividad = ''
        return

      }

},

SelctCbActividadTb(e){

  if( e!= null && !e.hasOwnProperty('id')){e=null}

},

cargarmantenimiento(){

var obj=`/mantenimientos/findallMantCliente/null`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  this.Rmantenimiento=res.data

  })

},


// filterComb (item, queryText, itemText) {
//         const textOne = item.id.toLowerCase()
//         const textTwo = item.cliente.nombre.toLowerCase()
//         const searchText = queryText.toLowerCase()

//        // const hasValue = val => val != null ? val : ''
//        //const text = hasValue(itemText)
//         //const query = hasValue(queryText)

//         return textOne.indexOf(searchText) > -1 ||
//           textTwo.indexOf(searchText) > -1
  
//     },


CbFilmantenimiento(e){

   if (e.target.value.length >= 0) {    
var obj=`/mantenimientos/findallMantCliente/${(e.target.value === "" ? "null" : e.target.value)}`
         this.$axios.get(this.$hostname +this.$hName +obj, { headers: this.$store.getters.GetheadersAxios })
            .then(res => {this.Rmantenimiento = res.data});
        }
},

SelctCbmantenimiento(e){


if (this.RegMantenimiento != null) {
if(this.RegMantenimiento.hasOwnProperty('id')){
}else{
this.RegMantenimiento = null
}

}else{
  this.RegMantenimiento = ''
}


},
changeRegmantenimiento(e){

  console.log('changeRegmantenimiento',e)

if(e!=null && e.hasOwnProperty('id')){

var obj =JSON.parse(JSON.stringify(e))

//obj.fecha= new Date(e.fecha).toISOString().substr(0,10)
//obj.fechafin= new Date(e.fechafin).toISOString().substr(0,10)

obj.fecha=obj.nuevaFecha
obj.fechafin=obj.nuevaFechafin 
var vig = this.Rfrecuencia.filter(ev=> ev.codigo == e.vigenciauni)
var fre = this.Rfrecuencia.filter(ev=> ev.codigo == e.frecuenciauni)
obj.vigenciauni=vig[0]
obj.frecuenciauni=fre[0]
this.RegistroMantenimientos=JSON.parse(JSON.stringify(obj))
   }else{

//this.RegistroActividades.codigo=0 
   }

},


cerrardialogCrearcliente(){
this.dialogCrearcliente=false

this.$refs.RefComponCliente.newwnuevo()


},



alerta(e, l) {

 if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false); 
      }
  
      if (l == "bienLimpio") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwRegistroMantenimientos();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false); 
      }
    },

  },


computed:{

    ActDescBtn(){


if( this.RegistroMantenimientos.cliente!=null && this.RegistroMantenimientos.cliente.hasOwnProperty('codigo')){
  this.infCliente=JSON.parse(JSON.stringify(this.RegistroMantenimientos.cliente))

    // this.infCliente.telefono

if(this.RegistroMantenimientos.cliente.hasOwnProperty('telefonosClientesList') && this.RegistroMantenimientos.cliente.telefonosClientesList.length >0 ){
  this.infCliente.telefono= JSON.parse(JSON.stringify(this.RegistroMantenimientos.cliente.telefonosClientesList[0].telefonosClientesPK.telefono))
}else{ this.infCliente.telefono=""}
    
  
}else{
  this.infCliente={
direccion:'',
telefono:'',
email:''
}
}


if(this.RegistroMantenimientos.id>0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}


if(this.RegistroMantenimientos.id<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }


}
     

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>