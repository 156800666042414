<template>
 <div >{{ActDescBtn}}
 <table border="1" style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Registro de formula a producción Real'"
  :descripcion="'aqui se registran los diferentes producción Real'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="deep-purple accent-2"
                  @click.prevent="imprimirListCompleta"
                  dark
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i> Listado</v-btn> 
            </center>



<BarraDinamicaNormal
              :nombre="'Producción Real'"
              @EmitDAta="datostabla"
              @EmitBuscar="filtro"
              :SeachNombre="'Búsqueda general'"
              :list="list"

              :headers="[
        { text: 'Código', value: 'id' },
        { text: 'Descripcion', value: 'unidadesProductos.productos.descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true}, 
              {Text:'Fecha',NomValue:'fecha', Ctsl:1, Type:1, State:true},
              {Text:'Referencia', NomValue:'referencia', Ctsl:1, Type:0, State:true},
              {Text:'Formula', NomValue:'produccion', Nv2:'unidadesProductos', Nv3:'productos',Nv4:'descripcion', Ctsl:4, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.2, State:true},
              ]"
            />
            

            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app  id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <v-container>
   
 <v-card
 color="grey lighten-3"
  >
  
  <v-container>
    
  <v-form ref="formRegistroProduccion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>
<!-- Columna 1 ------------------------------------>
                <v-col cols="12" lg="6" md="6" sm="6">
                <!-- Campo Fecha -->
              <v-text-field style="font-size: 13px;" dense outlined type="date" label="* Fecha:" :rules="[$rules.required]"
                v-model="RegistroProduccion.fecha" @blur="BlurFecha">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            <!-- Campo # Lote y referencia --> 
                        <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                          <v-row no-gutters>
                            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                              <v-text-field disabled id="formdisabledInput" style="font-size: 13px;" label="# Lote Producción"  dense outlined v-model="LoteId">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-hashtag"></i>
                          </template>
                        </v-text-field>
			                      </v-col>

			                     <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                            <v-text-field label="Referencia"  dense outlined v-model="RegistroProduccion.referencia">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>
			                     </v-col>
                        </v-row>
                      </v-col>



                        
                <!-- Campo Formula -->
                        <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                          <v-row no-gutters>
                            <v-col class="pa-0 ma-0 py-0 my-0" cols="10" md="10" sm="10" >
                          <v-combobox  disabled autocomplete="off" dense outlined label="Formula a Producida:" :items="[]" 
                        style="font-size: 13px;" item-text="id"  v-model="RegistroProduccion.produccion"
                            :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                          <!-- <span class="black--text" ># {{RegistroProduccion.produccion.id }} -  {{item.unidadesProductos.productos.descripcion}}</span> -->
                          <span class="black--text" ># {{item.id }} -  {{item.unidadesProductos.productos.descripcion}}</span>
                          </template>
                        </v-combobox>
                      </v-col>
                      <v-col class="pl-3 pt-2 ma-0 py-0 my-0" cols="2" md="2" sm="2" >
                        
                      <v-tooltip  top>
                          <template v-slot:activator="{ on }">  
                          
                          <v-btn  @click.prevent="dialogAddFormula=true, CargarFormulaProduccion(),SeachBuscar=''" v-on="on" color="blue darken-3" outlined  small dark>
                          <i style="font-size: 25px;" class="fa fa-search"></i>
                          </v-btn>
                          </template>
                                  <span>Buscar formula</span>
                                </v-tooltip> 
                      </v-col>

                      

                      </v-row>
                        </v-col>

                       <!-- Campo Cantidad  y unidad -->  
                        <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                          <v-row no-gutters>
                            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                              <VueAutonumeric  label="Cantidad a Producir" dense outlined autocomplete="off" 
                        v-model.number="RegistroProduccion.cantidadProducida" 
                        :rules="[$rules.Numericc ,$rules.required]" style="font-size: 13px;" 
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-sort-numeric-asc"></i>
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>
			    </v-col>

			   <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
          <v-combobox  disabled autocomplete="off" dense outlined label="Unidad:" :items="[]" 
                        style="font-size: 13px;" item-text="id"  v-model="RegistroProduccion.formulas"
                            :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-balance-scale"></i>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                          <span class="black--text" >  {{item.unidadesProductos.unidades.descripcion}}<b></b></span>
                          </template>
                  </v-combobox>
			   </v-col>
         
           </v-row>
           </v-col>
              <!-- Campo Almacén   ChangeAlmacen-->  
             <v-combobox id="formdisabledInput" style="font-size: 13px;"  disabled autocomplete="off" dense outlined label="Alm. Materia Prima:" :items="Ralmacen"
                          item-text="descripcion" v-model="RegistroProduccion.almacenMaterial" @blur="SelctCbAlmacenMateria"
                          @focus="CargarRalmacenMateria" @change="changeAlmacen('materiaprima')" :rules="[$rules.required]"> 
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                            <!-- <i style="font-size: 20px;" class="fa fa-user-circle"></i> -->
                          </template>
                        </v-combobox>  
                        <!-- Campo Almacén -->  
             <v-combobox id="formdisabledInput" style="font-size: 13px;"   disabled autocomplete="off" dense outlined label="Alm. Productos Terminado:" :items="Ralmacen"
                          item-text="descripcion" v-model="RegistroProduccion.almacenProductos" @blur="SelctCbAlmacenProductos"
                          @focus="CargarRalmacenMateria" @change="changeAlmacen('productosterminado')" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                            <!-- <i style="font-size: 20px;" class="fa fa-user-circle"></i> -->
                          </template>
                        </v-combobox>    

                           
            </v-col>
<!-- final Columna 1 -------------------------------------->
<!-- Columna 2 -------------------------------------------->
                <v-col cols="12" lg="6" md="6" sm="6">
            <!-- documento  entrada -->  
              <v-combobox id="formdisabledInput" style="font-size: 13px;"  disabled autocomplete="off" dense outlined label="Documentos Entrada:" :items="RDocumentoEntrada"
                item-text="descripcion" :rules="[$rules.required]" v-model="RegistroProduccion.documentoEntrada"
                @blur="SelctCbDocumentoEntrada" @focus="cargarDocumentoEntrada">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>
               <!-- documento  entrada --> 
              <v-combobox id="formdisabledInput" style="font-size: 13px;"  disabled autocomplete="off" dense outlined label="Documentos Salida:" :items="RDocumentoSalida"
                item-text="descripcion" :rules="[$rules.required]" v-model="RegistroProduccion.documentoSalida"
                @blur="SelctCbDocumentoSalida" @focus="cargarDocumentoSalida">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>

              <!-- Unidad --> 
                 <v-combobox  disabled autocomplete="off" dense outlined label="Unidad:" :items="[]" 
                        style="font-size: 13px;" item-text="id"  v-model="RegistroProduccion.formulas"
                            :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-balance-scale"></i>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                          <span class="black--text" >  {{item.unidadesProductos.unidades.descripcion}}</span>
                          </template>
                  </v-combobox>

                  
                      <!-- Costos indirectos --> 
                  <VueAutonumeric  label="Costos indirectos" dense outlined autocomplete="off" 
                        v-model.number="RegistroProduccion.costosIndirecto" 
                        :rules="[$rules.required]" style="font-size: 13px;" 
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>


              <!-- Gastos Ventas y  Administrativos--> 

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                  <v-row no-gutters>
                            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                              <VueAutonumeric  label="Gastos Ventas" dense outlined autocomplete="off" 
                        v-model.number="RegistroProduccion.gastosVentas" 
                        :rules="[$rules.required]" style="font-size: 13px;" 
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>
			                      </v-col>
                            
			                     <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                            <VueAutonumeric  label="Gastos Administrativos" dense outlined autocomplete="off" 
                        v-model.number="RegistroProduccion.gastosAdministrativo" 
                        :rules="[$rules.required]" style="font-size: 13px;" 
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>
			                     </v-col>

                </v-row>
             </v-col>
       
 <!-- Gastos Transportación y  Financieros--> 

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                  <v-row no-gutters>
                            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                              <VueAutonumeric  label="Gastos Transportación" dense outlined autocomplete="off" 
                        v-model.number="RegistroProduccion.gastosTransportacion" 
                        :rules="[$rules.required]" style="font-size: 13px;" 
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>
			                      </v-col>

			                     <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                            <VueAutonumeric  label="Gastos Financieros" dense outlined autocomplete="off" 
                        v-model.number="RegistroProduccion.gastosFinancieros" 
                        :rules="[$rules.required]" style="font-size: 13px;" 
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                          <!-- <span ></span> -->
                        </template>
                      </VueAutonumeric>
			                     </v-col>

                </v-row>
             </v-col>
                      
                </v-col>
   <!-- final Columna 2 ------------------------------------->             
        


              </v-row>
            </v-form>  
            
       <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-1">  
 <v-card>
<v-col style="background-color:#1A237E;" class="pa-0 ma-0 py-0 my-0"  cols="12" md="12" sm="12">
  <span :class="`d-flex justify-star white--text px-1`"><b> FORMULACIÓN A PRODUCCIÓN </b></span>
</v-col>
<v-data-table  id="TbBord"
      class="RowSelectColor TbSize13 elevation-1" 
      item-key="indx"
      :headers="[
                {text: 'CÓDIGO', sortable: false,width:130,  value: 'unidadesProductos.unidadesProductosPK', class: ['no-gutters black--text']},
                { text: 'NOMBRE', sortable: false, value: 'unidadesProductos.productos', class: ['no-gutters black--text'] },
                { text: 'UNIDAD', sortable: false, width:180, value: 'unidadesProductos.unidades', class: ['no-gutters black--text'] },
                { text: 'CANT.FORMULA', sortable: false,width:110, value: 'cantidadProduccion', class: ['no-gutters black--text'] },
                { text: 'CANT.REAL', sortable: false,width:110, value: 'cantidadReal', class: ['no-gutters black--text'] },
                // { text: 'ACCIÓN', sortable: false,width:50, value: 'action', class: ['no-gutters black--text'] },
                
                       
                ]"
              dense
              :items="ListProduct" 

              :items-per-page="15"
            >
  <template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<!-- icon -->
<v-btn fab @click.prevent="BtnAccionDelete(item)" x-small  color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>            
  </template>
       
             <!-- //  unidadesProductosPK.producto,productos.descripcion,unidades.descripcion   
            unidadesProductosPK-->

             <template v-slot:item.unidadesProductos.unidadesProductosPK="props">
                    <v-text-field style="font-size: 13px;" disabled background-color="white" color="grey darken-4"
                     :value="props.item.unidadesProductos.unidadesProductosPK.producto"
                     id="formdisabledInput"
                    label=""  hide-details outlined dense>
                  </v-text-field> 
              </template>

              <template v-slot:item.unidadesProductos.productos="props">
                    <v-text-field disabled background-color="white" color="grey darken-4"
                     :value="props.item.unidadesProductos.productos.descripcion" style="font-size: 13px;"
                     id="formdisabledInput"
                     label=""  hide-details outlined dense>
                  </v-text-field> 
              </template>
              
              <template v-slot:item.unidadesProductos.unidades="props">
                    <v-text-field disabled background-color="white" color="grey darken-4"
                     :value="props.item.unidadesProductos.unidades.descripcion" style="font-size: 13px;"
                     id="formdisabledInput"
                     label=""  hide-details outlined dense>
                  </v-text-field> 
              </template> 
              <template v-slot:item.cantidadProduccion="props">
                    <v-text-field style="font-size: 13px;" disabled background-color="white" color="grey darken-4"
                     :value="props.item.cantidadProduccion "
                     id="formdisabledInput"
                     label=""  hide-details outlined dense>
                  </v-text-field> 
              </template>
              <template v-slot:item.cantidadReal="props">

<!-- <span style="font-size:13px; display:none;">{{props.item.indx = ListProduct.indexOf(props.item)}}</span> -->
<span v-if="props.item.cantidadReal==null" style="display:none;">{{props.item.cantidadReal =0 }}</span>
  <VueAutonumeric  id="formdisabledInput" label="" dense outlined autocomplete="off"
          v-model.number="props.item.cantidadReal" 
          :rules="[$rules.Numericc]" style="font-size: 13px;"  hide-details
          >
          <template v-slot:prepend>
            <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
            <!-- <span ></span> -->
          </template>
        </VueAutonumeric>
</template>

</v-data-table>


 </v-card>
</v-col>    

  </v-container>
  </v-card>   
       </v-container>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

<!-------------------------------------------------------- Modal- formula-->
<v-dialog transition="fab-transition" label="Modal Formula" v-model="dialogAddFormula" persistent
      max-width="90%" max-height="80%">

      <v-card>

<v-toolbar flat color="indigo" dense>

  <i style="font-size: 20px;" class=""> </i>
  <v-toolbar-title style="color:white;" class="font-weight-light"> FORMULA PRODUCCION </v-toolbar-title>
  <i style="font-size: 20px;" class=""> </i>

  <v-spacer></v-spacer>
  <v-btn color="grey darken-4" fab small @click="dialogAddFormula=false">
    <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
  </v-btn>
</v-toolbar>


<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;">
  <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-text-field autocomplete="off" v-on="on" v-model="SeachBuscar" @keyup="CargarFormulaProduccion()"
               label="Buscar" single-line hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>
          </template>
          <span>Busqueda</span>
        </v-tooltip>

   <v-data-table class="RowSelectColor TbSize13 elevation-1"  dense @click:row="addClikFormula" :footer-props="arrayPropiedases" :headers="[
         {text: 'CODIGO', value: 'id' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'FECHA', value: 'fecha' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'REF.', value: 'referencia' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'PRODUCTO', value: 'unidadesProductos.productos.descripcion' ,sortable: false, class: ['no-gutters black--text']},
         {text: 'UNIDAD', value: 'unidadesProductos.unidades.descripcion' ,sortable: false, class: ['no-gutters black--text']}
        ]" :items="listItems" item-key="id"  :search="SeachBuscar" >


            <template v-slot:item.fecha="props">
              <span style="font-size:13px;"> {{ FormatoFecha(props.item.fecha)}}</span>
            </template>


        </v-data-table>



</v-col>

</v-card>


</v-dialog>
    <!-- ------------------------------------------------------ fn Modal- producto-->



    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='anulaRegistro'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>


          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>
<script>
import { EventBus } from "@/event-bus";
import BarraBusqueda2 from "@/components/BarraBusquedaDinam";
import BarraDinamicaNormal from "@/components/BarraDinamicaNormalPlus.vue";
import ContenidoBase from "@/components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva} from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase,
    VueAutonumeric
  },
  created() {
    var json = {
      titulo: "Registro de formula a producción Real",
      descripcion: "aqui se registran los diferentes producción Real",
    };

    //EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
   // EventBus.$on("emitCXCTiposClientes", this.datostabla);
   // EventBus.$on("onResize", this.onResize);
   var _this = this;
        setTimeout(function () {
          _this.getCodigoUltimo();
        }, 900);
  },

 data:()=> ({
  
      LoteId:0,
      windowSize:{width:"auto", height:"auto"},
      dialogAddFormula:false,
      SeachBuscar:'',
      arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
        AddProducto:null,
        listItems:[],
        ListProduct: [],
        Rproducto:[],
        Ralmacen:[],
        RDocumentoEntrada:[],
        RDocumentoSalida:[],
        RegistroProduccion: {
          id:0,
          referencia:'',
          cantidadProducida:0,
          almacenProductos:null,
          almacenMaterial:null,
          documentoEntrada:null,
          documentoSalida:null,
          formulas:null,
          fecha: fechaNueva(),
          unidadesProductos:null,
          produccionDetalleList:[],
          costosIndirecto:0,
          gastosVentas:0, 
          gastosAdministrativo:0,
          gastosTransportacion:0,
          gastosFinancieros:0,
          produccion:null,
         produccionRealDetalleList:[]
      },

      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,



      
      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,FormatoFecha,HoraFormatter,fechaNueva,
onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if(this.BarraTablaDinamicaAprir==true){this.filtro()}

    },
    datostabla(e) {
      this.LoteId=e.id
      this.RegistroProduccion = JSON.parse(JSON.stringify(e));
      this.RegistroProduccion.almacenProductos=JSON.parse(JSON.stringify(e.almacenProducto))
      this.RegistroProduccion.fecha=new Date(e.fecha).toISOString().substr(0, 10)
      this.RegistroProduccion.formulas=JSON.parse(JSON.stringify(e.produccion.formulas));
      this.ListProduct=JSON.parse(JSON.stringify(e.produccionRealDetalleList))


      this.acti_tb_data=false
    },
    save() {
//this.ListProduct

if (this.$refs.formRegistroProduccion.validate()) {

  if(this.ListProduct.length==0){
    this.alerta("agregue almeno un producto a la lista de formulación", "error");
    return;
  }


  EventBus.$emit("loading", true);
  if(this.RegistroProduccion.id!=0){this.RegistroProduccion.fechaModificacion=this.fechaNueva()}
  this.RegistroProduccion.unidadesProductos=this.RegistroProduccion.formulas.unidadesProductos
  this.RegistroProduccion.almacenProducto=JSON.parse(JSON.stringify(this.RegistroProduccion.almacenProductos))

var datos= JSON.parse(JSON.stringify(this.RegistroProduccion))
datos.produccionRealDetalleList=JSON.parse(JSON.stringify(this.ListProduct))


this.$axios.post(this.$hostname + this.$hName + "/produccionReal/save",
            datos, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente!", "bien")
            })
            .catch(error => { this.alerta("Dato ", "error") });

 /* var obj={
   datos: JSON.parse(JSON.stringify(this.RegistroProduccion)),
   usuario:this.$store.getters.GetdatosInfUsu.userid,
   detalle:this.ListProduct
 }
 this.$axios
        .post(this.$hostname + this.$hName + "/produccionReal/save", JSON.parse(JSON.stringify(obj)))
        .then(res =>{this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>{this.alerta("Dato no guardado correctamente!", "error")})*/


}else{
this.alerta("Los campos en rojo son requeridos", "error");
}

    },


    NotifMessage() {

if(this.RegistroProduccion.id!=0 && this.RegistroProduccion.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea anular este registro?",
        Exec:"anulaRegistro"
      }
      
this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una pruducción para anula", "error")}


    },

 Exec_notif(e) {
this.notif=JSON.parse(JSON.stringify(e))

    },


  
    eliminar() {  
if (this.RegistroProduccion.id!=0) {
  EventBus.$emit("loading", true); 
  var obj=`/produccionReal/anular/${this.RegistroProduccion.id}/`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res =>{this.alerta("Dato anulado correctamente!", "bien")})
.catch(error =>{this.alerta("Dato no anulado correctamente!", "error")})

}

    },
    anular() {
    },
    imprimirList() {
      EventBus.$emit("loading", true);
var obj=`/produccionReal/imprimir/${this.RegistroProduccion.id}/`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res =>{
  EventBus.$emit("loading", false);
  //this.alerta("Dato eliminado correctamente!", "bien")
var _this = this;
setTimeout(function () {
  /*let pdfWindow = window.open("Reporte de imprecion")
  pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
      encodeURI(res.data) + "'></embed>")*/
      var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'produccion real');
}, 1000);


})
.catch(error =>{
  this.alerta("Reporte no Impreso!", "error")
  EventBus.$emit("loading", false);
})



    },

    imprimirListCompleta(){
      EventBus.$emit("loading", true);
var obj=`/produccionReal/imprimirListado/`
this.$axios.get(this.$hostname+this.$hName+obj,{headers:this.$store.getters.GetheadersAxios})
.then(res =>{
  EventBus.$emit("loading", false);
  //this.alerta("Dato eliminado correctamente!", "bien")
var _this = this;
setTimeout(function () {
  /*let pdfWindow = window.open("Reporte de imprecion")
  pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
      encodeURI(res.data) + "'></embed>")*/
      var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'imprimir lista');
}, 2000);


})
.catch(error =>{
  this.alerta("Reporte no Impreso!", "error")
  EventBus.$emit("loading", false);
})


    },

    newnuevo() {

      this.AddProducto=null,
        this.ListProduct=[],
        this.Rproducto=[],


        this.RegistroProduccion= {
          id:0,
          referencia:'',
          cantidadProducida:0,
          almacenProductos:null,
          almacenMaterial:null,
          documentoEntrada:null,
          documentoSalida:null,
          formulas:null,
          fecha: fechaNueva(),
          unidadesProductos:null,
          produccionDetalleList:[],
          costosIndirecto:0,
          gastosVentas:0, 
          gastosAdministrativo:0,
          gastosTransportacion:0,
          gastosFinancieros:0,
          produccion:null,
          produccionRealDetalleList:[]

      }

       EventBus.$emit("loading", false);
       this.filtro()
      //  EventBus.$emit("actualizaBarraBusqueda2");
    },



    AgregarProducto(){

if (this.$refs.formAddPro.validate()) {

var obj={
  id: 0,
  cantidad: 1,
  unidadesProductos:this.AddProducto
}
  this.ListProduct.unshift(obj)
  this.AddProducto=null
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}
     
    },


    BtnAccionDelete(item) {
      const index = this.ListProduct.indexOf(item);
      confirm("¿ Desea eliminar este producto de la lista ?") &&
        this.ListProduct.splice(index, 1);
    },





    CargarRproducto() {
      var obj=`/unidades-productos/a/false/buscar`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });
    /*  var obj=`/services/producto/a/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
  this.Rproducto=res.data
  })*/
},




CbFilProducto(e) {
if (e.target.value.length >= 0) {
  //var obj=`/services/unidades-productos/${e.target.value}/false/buscar/${this.$store.getters.GetdatosInfUsu.userid}/`
 

  var obj=`/unidades-productos/${(e.target.value === "" ? "A" : e.target.value)}/false/buscar`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });

  /*var obj=`/services/producto/${e.target.value}/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{this.Rproducto = res.data})*/



}

},

SelctCbProduc() {

  
  var _this = this;
      setTimeout(function () {
        if (_this.RegistroProduccion.unidadesProductos == null) {
          _this.RegistroProduccion.unidadesProductos= "";
        }

        if (!_this.RegistroProduccion.unidadesProductos.hasOwnProperty('productos')) {
          _this.RegistroProduccion.unidadesProductos = "";
          _this.RegistroProduccion.unidadesProductos = null;
          return;
        }
      }, 300);
},


changeAlmacen(e){
  var _this = this;

  setTimeout(function () {

if(_this.RegistroProduccion.almacenMaterial!=null && _this.RegistroProduccion.almacenProductos!==null ){
  if (_this.RegistroProduccion.almacenMaterial.hasOwnProperty('codigo') && _this.RegistroProduccion.almacenProductos.hasOwnProperty('codigo')) {
    if(_this.RegistroProduccion.almacenMaterial.codigo ==_this.RegistroProduccion.almacenProductos.codigo){

      _this.alerta("No puede seleccionar el mismo almacén, materia prima y productos terminado.", "error");
      if(e=='materiaprima'){
        _this.RegistroProduccion.almacenMaterial=null
      }
      
      if(e=='productosterminado'){
        _this.RegistroProduccion.almacenProductos=null
      }

    }
        }
      }



      }, 300);


  
},




CargarRalmacenMateria(){

  
  var _this = this;
      setTimeout(function () {
        if (_this.RegistroProduccion.almacenMaterial == null) {
          _this.RegistroProduccion.almacenMaterial= "";
        }

        if (!_this.RegistroProduccion.almacenMaterial.hasOwnProperty('codigo')) {
          _this.RegistroProduccion.almacenMaterial = "";
          _this.RegistroProduccion.almacenMaterial = null;
          return;
        }
      }, 300);



  var obj=`/services/almacen`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
  this.Ralmacen = res.data.filter((ee) => ee.activado == true && ee.desactivado==false);
})
},
SelctCbAlmacenMateria(){
  var _this = this;
      setTimeout(function () {
        if (_this.RegistroProduccion.almacenMaterial == null) {
          _this.RegistroProduccion.almacenMaterial= "";
        }

        if (!_this.RegistroProduccion.almacenMaterial.hasOwnProperty('codigo')) {
          _this.RegistroProduccion.almacenMaterial = "";
          _this.RegistroProduccion.almacenMaterial = null;
          return;
        }
      }, 300);
},

 SelctCbAlmacenProductos(){
  var _this = this;
      setTimeout(function () {
        if (_this.RegistroProduccion.almacenProductos == null) {
          _this.RegistroProduccion.almacenProductos= "";
        }

        if (!_this.RegistroProduccion.almacenProductos.hasOwnProperty('codigo')) {
          _this.RegistroProduccion.almacenProductos = "";
          _this.RegistroProduccion.almacenProductos = null;
          return;
        }
      }, 300);

 },  

cargarDocumentoEntrada(){
//RDocumentoEntrada


var lnk=`/services/documentoInventario/entradas/${this.$store.getters.GetdatosInfUsu.userid}`
 this.$axios
        .get(this.$hostname + this.$hName +lnk)
        .then(res =>{ this.RDocumentoEntrada=res.data})


},

SelctCbDocumentoEntrada(){
  var _this = this;
      setTimeout(function () {
        if (_this.RegistroProduccion.documentoEntrada == null) {
          _this.RegistroProduccion.documentoEntrada= "";
        }

        if (!_this.RegistroProduccion.documentoEntrada.hasOwnProperty('codigo')) {
          _this.RegistroProduccion.documentoEntrada = "";
          _this.RegistroProduccion.documentoEntrada = null;
          return;
        }
      }, 300);

},
cargarDocumentoSalida(){


  var lnk=`/services/documentoInventario/salidas/${this.$store.getters.GetdatosInfUsu.userid}`
 this.$axios
        .get(this.$hostname + this.$hName +lnk)
        .then(res =>{ this.RDocumentoSalida=res.data})

},
 SelctCbDocumentoSalida(){

  var _this = this;
      setTimeout(function () {
        if (_this.RegistroProduccion.documentoSalida == null) {
          _this.RegistroProduccion.documentoSalida= "";
        }

        if (!_this.RegistroProduccion.documentoSalida.hasOwnProperty('codigo')) {
          _this.RegistroProduccion.documentoSalida = "";
          _this.RegistroProduccion.documentoSalida = null;
          return;
        }
      }, 300);


},

BlurFecha(){

if(this.RegistroProduccion.fecha.toString().length==0){
  this.RegistroProduccion.fecha=this.fechaNueva()
}

 },



 CargarFormulaProduccion() {


  this.$axios.get(this.$hostname + this.$hName + "/produccion/findall/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.listItems = JSON.parse(JSON.stringify(res.data));
      });

  /*var obj=`/services/produccion/findall/${this.$store.getters.GetdatosInfUsu.userid}`
      this.$axios.get(this.$hostname+this.$hName+obj)
      .then(res=>{
       this.listItems = res.data
      })*/



    /*  var obj=`/services/formulas/findall/${this.$store.getters.GetdatosInfUsu.userid}`
      this.$axios.get(this.$hostname+this.$hName+obj)
      .then(res=>{
        // this.listItems = res.data
        console.log(res.data)
      })*/

    },

    addClikFormula(e){
     this.ListProduct=[]

 var obj={
  id : 0,
  activo : true,
almacenMaterial:JSON.parse(JSON.stringify(e.almacenMaterial)),
almacenProductos:JSON.parse(JSON.stringify(e.almacenProductos)),
cantidadProducida : JSON.parse(JSON.stringify(e.cantidadProducir)),
costosIndirecto : 0,
documentoEntrada:JSON.parse(JSON.stringify(e.documentoEntrada)),
documentoSalida:JSON.parse(JSON.stringify(e.documentoSalida)),
fecha : fechaNueva(),
gastosAdministrativo : 0,
gastosFinancieros : 0,
gastosTransportacion : 0,
gastosVentas : 0,
produccion:JSON.parse(JSON.stringify(e)),
produccionRealDetalleList:[],
referencia : JSON.parse(JSON.stringify(e.referencia)),
formulas:JSON.parse(JSON.stringify(e.formulas))
 }
 this.dialogAddFormula=false

 this.RegistroProduccion=JSON.parse(JSON.stringify(obj))



     if(e.produccionDetalleList.length>0){
      e.produccionDetalleList.forEach(element => {

        var obj={
          cantidadProduccion: element.cantidadTotal,
          cantidadReal: 0,
          costo:element.unidadesProductos.costo,
          id:0,
          unidadesProductos: element.unidadesProductos
        }
        this.ListProduct.push(obj)
        });
 
     }


     this.$refs.formRegistroProduccion.resetValidation()

    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },
 
    async getCodigoUltimo() {

      this.$axios.get(this.$hostname + this.$hName + "/produccionReal/getCodigoUltimo/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.LoteId = JSON.parse(JSON.stringify(res.data));
      });

     /* var obj=`/produccionReal/getCodigoUltimo/${this.$store.getters.GetdatosInfUsu.userid}`
      this.$axios.get(this.$hostname+this.$hName+obj)
      .then(res=>{ this.LoteId=res.data+1 })*/

    },


    async filtro() {
     this.$axios.get(this.$hostname + this.$hName + "/produccionReal/findall/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.list = JSON.parse(JSON.stringify(res.data));
      });
    /* var obj=`/produccionReal/findall/${this.$store.getters.GetdatosInfUsu.userid}`
      this.$axios.get(this.$hostname+this.$hName+obj)
      .then(res=>{
         this.list = res.data
      })*/

      var _this = this;
        setTimeout(function () {
          _this.getCodigoUltimo();
        }, 900);
    }





  },


computed:{

    ActDescBtn(){


if(this.RegistroProduccion.id>0){
 this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }

        if(this.RegistroProduccion.activo==false){ this.EstadoBtn.anular=false}
}


if(this.RegistroProduccion.id<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }
}
     
this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }


};
</script>

<style></style>
