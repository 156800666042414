<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 <tr max-height="48px" height="48px"  >
<ContenidoBase 
  :titulo="'Reporte de productos con balance cero'"
  :descripcion="'Imprime los productos con balance cero.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesAlfabeticoProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;">Reporte de productos con balance cero</span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">



          <!----------------------------------------------  Departamento -->
<v-combobox
                        
                        autocomplete="off"
                        dense
                        outlined
                        label="Departamento:"
                        :items="Rdepartamento"
                        item-text="descripcion"
                        v-model="ProductosBalance.departamento"
                        @focus="cargarDepartamento"
                        @blur="SelctCbDesc"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 30px;"
                            class="fa fa-building"
                          ></i>
                        </template>
                      </v-combobox>


                      <v-combobox autocomplete="off" dense outlined label="Fabricante:" :items="RFabricante"
                          item-text="nombre" v-model="ProductosBalance.fabricante" @focus="CargarFabricante"
                          @blur="SelctCbFabricante">
                          <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('nombre')" >{{item.nombre.trim()}}</span>
                      </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-wrench"></i>
                          </template>
                        </v-combobox>



<v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Productos con Movimiento:"
              v-model="ProductosBalance.producMovim"
            >
          <template v-slot:label>
         <strong v-if="ProductosBalance.producMovim==true" style="color:#000000;">Productos con Movimiento: Si</strong>
         <strong v-if="ProductosBalance.producMovim==false" style="color:#000000;">Productos con Movimiento: No</strong>
        </template>
</v-switch>


</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<v-combobox
                    v-model="ProductosBalance.almacen"
                    label=" * Almacén"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="Ralmacen"
                    :rules="[$rules.required]"
                    item-text="descripcion"
                    @blur="SelctCbAlmacen"
                    @focus="CargarRalmacen"
                  >
                    <template v-slot:prepend>
                      <v-img
                        height="20px"
                        width="20px"
                        src="../../assets/warehouse.png"
                      ></v-img>
                    </template>
                  </v-combobox>

                  <v-text-field dense outlined type="date" label=" * Fecha Inicial:" :rules="[$rules.required]"
                          v-model="ProductosBalance.desde">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                    </v-text-field>
                  <v-text-field dense outlined type="date" label=" * Fecha Final:" :rules="[$rules.required]"
                          v-model="ProductosBalance.hasta" >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                    </v-text-field>
</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
 ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="ProductosBalanceLink"

/>

  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() { 
    var json = { 
      titulo: "Reporte de productos con balance cero",
      descripcion: "Imprime los productos con balance cero.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,

      accion: [
        "ReportesProductosBalanceNew",
        "ReportesProductosBalanceSave",
        "ReportesProductosBalanceRemove",
        "ReportesProductosBalanceAnular",
        "ReportesProductosBalancePrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesProductosBalanceNew", this.new);
    // EventBus.$on("ReportesProductosBalanceSave", this.save);
    // EventBus.$on("ReportesProductosBalanceRemove", this.remove);
    // EventBus.$on("ReportesProductosBalanceAnular", this.remove);
    // EventBus.$on("ReportesProductosBalancePrintList", this.imprimirList);
    EventBus.$on("emitReportesProductosBalance", this.datostabla);
    EventBus.$on("onResize", this.onResize);

      this.$refs.ModRepFilt.CrearObjecto=this.validarObjecto;

  },

  data:()=>  ({

    
      Ralmacen:[],
      Rdepartamento:[],
      RFabricante:[],
      ListaTiposClientes: [],

      windowSize:{width:"auto", height:"auto"},

 ProductosBalanceLink: { 

      nombre:'REPORTE DE PRODUCTOS CON BALANCE CERO',
        UrlImprimir: '/reportesInv/balanceCero', 
        UrlExel: '/reportesInv/ExcelbalanceCero', 
        UrlCorreo: '/reportesInv/correobalanceCero',
 },

      ProductosBalance: {

        
        departamento:null,
        almacen:null,
       producMovim:false,
       fabricante:null
      },




EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.ProductosBalance = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() {},
    eliminar(){ },
    anular(){},
    imprimirList() {},

    DDataReporteBasico(){},

    validarObjecto(){


if (!this.$refs.form.validate()){
  this.$refs.ModRepFilt.detener=true;
  return ;
}else{
  this.$refs.ModRepFilt.detener=false;
}

var where=" binv.balance = 0 and p.activo=true "
// where+=` and fv.fecha >= ' ${new Date(this.ProductosBalance.desde).toISOString().substr(0,10)} ' and fv.fecha  <=' ${new Date(this.ProductosBalance.hasta).toISOString().substr(0,10)} '`
//  where+=` and fv.fecha >= '${this.ProductosBalance.desde}' and fv.fecha  <='${this.ProductosBalance.hasta}'`

this.ProductosBalanceLink.almacen="Todos"
this.ProductosBalanceLink.departamento="Todos"
this.ProductosBalanceLink.fabricante="Todos"
if(this.ProductosBalance.almacen!=null){
where+=` and am.codigo = ${this.ProductosBalance.almacen.codigo} `
this.ProductosBalanceLink.almacen=JSON.parse(JSON.stringify(this.ProductosBalance.almacen.descripcion))
}

if(this.ProductosBalance.departamento!=null){
where+=` and p.departamento = ${this.ProductosBalance.departamento.codigo} `
this.ProductosBalanceLink.departamento=JSON.parse(JSON.stringify(this.ProductosBalance.departamento.descripcion))
}

if(this.ProductosBalance.producMovim==true){
  if (this.ProductosBalance.desde!=null && this.ProductosBalance.hasta !=null ){
    where += ` and ${this.$store.getters.GetheadersAxios["X-TENANT-ID"]}.totalsalidas_sin_tra(p.codigo,binv.unidad,'${this.ProductosBalance.desde }','${this.ProductosBalance.hasta}',am.codigo)>0 `
  }
}


if(this.ProductosBalance.fabricante!=null){
where+=` and p.fabricante = ${this.ProductosBalance.fabricante.codigo} `
this.ProductosBalanceLink.fabricante=JSON.parse(JSON.stringify(this.ProductosBalance.fabricante.nombre))
}

this.ProductosBalanceLink.where=where

var CONDUCION=`DESDE: ${this.FormatoFecha(this.ProductosBalance.desde)} \t HASTA: ${this.FormatoFecha(this.ProductosBalance.hasta)} `
this.ProductosBalanceLink.condicion=CONDUCION

},

    newwnuevo() {
      (this.ProductosBalance = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },








CargarRalmacen() {
      this.$axios
    .get(this.$hostname + this.$hName + "/almacen/activos", 
    {headers:this.$store.getters.GetheadersAxios} 
    )
    .then(res => {
      this.Ralmacen = res.data;
    });

    },

 SelctCbAlmacen() {
      if (this.ProductosBalance.almacen== null) {
        this.ProductosBalance.almacen = "";
      }

      if (this.ProductosBalance.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.ProductosBalance.almacen = "";
        return;
      }
    },


cargarDepartamento(){


this.$axios
          .get(
              this.$hostname + this.$hName + "/departamento/activo",
              {
                headers: this.$store.getters.GetheadersAxios
              }
          ).then(res => this.Rdepartamento = res.data)
          .catch(error => {
            if (error.response) {
              this.alerta(error.response.request.responseText, 'error')
            } else {
              this.alerta(error, 'error')
            }
          });

},


 SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.ProductosBalance.departamento == null) {
        this.ProductosBalance.departamento = "";
      }

      if (this.ProductosBalance.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.ProductosBalance.departamento = "";
        return;
      }
    },



CargarFabricante() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/fabricante/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RFabricante = res.data)
        .catch()
    },

SelctCbFabricante(){

  var _this = this;
      setTimeout(function () {
        if (_this.ProductosBalance.fabricante == null) {
          _this.ProductosBalance.fabricante = "";
        }

        if (!_this.ProductosBalance.fabricante.hasOwnProperty('codigo')) {
          _this.ProductosBalance.fabricante = "";
          _this.ProductosBalance.fabricante = null;
          return;
        }
      }, 300);

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
