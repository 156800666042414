<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">  
<ContenidoBase 
  :titulo="'Análisis de beneficios'"
  :descripcion="'Análisis de beneficios'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitAnalisisBeneficios'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
<div class="px-2">
 <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>

 <center> 
<!---------------------------------------------------    -->
<v-col  cols="12" md="6" sm="6" style="border: 1px solid  #616161;" class="pa-0 ma-0 py-0 my-0" > 
<v-card>
<v-col style="background-color:#BDBDBD; border-bottom: 1px solid  #616161; " class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <v-row>
<v-col  cols="12" md="6" sm="6" > 
<!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="AnalisisBusqueda.desde"
        hide-details
        @blur="BlurDesde"
        v-on:blur="BuscarBeneficio"
         @keyup.enter="BuscarBeneficio"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>     
<v-col  cols="12" md="6" sm="6">            
<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="AnalisisBusqueda.hasta"
        hide-details
        @blur="BlurHasta"
        v-on:blur="BuscarBeneficio"
        @keyup.enter="BuscarBeneficio"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
    </v-col>             
  </v-row>
  <!-- <span ><b>Configuración Bancos</b></span> -->
</v-col>

 <!-- fila 1 -->
<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 " v-if="$store.getters.GetdatosInfUsu.usuario.micromarket==false"> 
  <v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center" > 
  <v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end "> 
  <h5 class="py-2 pr-2 ">Facturas Privadas:</h5>
 </v-col>
  <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
  <!-- ----------------------------- Facturas Privadas -->
<VueAutonumeric label="" dense outlined
id="formdisabledInput"
 disabled
 autocomplete="off"
  v-model.number="AnalisisBeneficios.ventasSinSeguro"
   hide-details

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
 </v-col>
  </v-row>
</v-col>
 <!-- fila 2 -->
<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" v-if="$store.getters.GetdatosInfUsu.usuario.micromarket==false"> 
  <v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center" > 
  <v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Facturas ARS:</h5>
 </v-col>
 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 " > 
<VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.ventasConSeguro"
  hide-details>
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
 </v-col>
 </v-row>
</v-col>
 <!-- fila 3 -->
<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 " v-if="$store.getters.GetdatosInfUsu.usuario.micromarket==false"> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center" style="border: 2px solid  #616161; " > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end "> 
  <h5 class="py-2 pr-2">INFORMATIVO: Valor Cobertura:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <!-- ----------------------------- Valor Cobertura-->
<VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.cover"
  hide-details
 
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>

</v-row>
</v-col>
<!-- fila 4 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center" > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Ventas Bruta:</h5>
  <!--Ventas Brutas  -->
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
<VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.ventasBrutas"
  hide-details
 
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col> 
</v-row>
</v-col>

<!-- fila 4 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center" style="border: 2px solid  #616161; "  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end" > 
  <h5 class="py-2 pr-2">INFORMATIVO: Valor ITBIS:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
<VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.valorImpuesto"
  hide-details
 
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>
<!-- fila 5 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center "  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Descuentos:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.descuento"
  hide-details
 
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
 </v-col>
 </v-row>
</v-col>

<!-- fila 6 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Devoluciones Clientes:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <VueAutonumeric label=""
 id="formdisabledInput"
 disabled 
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.devolucionesClientes"
  hide-details

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
 </v-row>
</v-col>
<hr style="border: 1px dashed #000000; " > 

<!-- fila 7 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Ventas Netas:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <VueAutonumeric label="" 
  id="formdisabledInput"
  disabled
  dense 
  outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.ventasNeta"
   hide-details


  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric> 
  </v-col>
 </v-row>
 </v-col>


<!-- fila 8 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Costo de Ventas:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.costoVentas"
   hide-details

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
 </v-row>
</v-col>
<hr style="border: 1px dashed #000000; " >

<!-- fila 9 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Beneficios Brutos:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
  <VueAutonumeric label=""
 id="formdisabledInput"
 disabled 
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.beneficioBruto"
   hide-details

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
 </v-col>

</v-row>
 </v-col>

<v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <center><b>Gastos Generales</b></center>
</v-col>

<!-- fila 10 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Gastos Proveedores Formales:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
  <VueAutonumeric label="" 
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.gastoProveeFormales"
  hide-details


  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>

<!-- fila 11 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Gastos Proveedores Informales:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <VueAutonumeric label=""
 id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.gastoProveeInformales"
  hide-details

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>

<!-- fila 12 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Gastos Menores:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
<VueAutonumeric label=""
id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.gastoMenores"
  hide-details
  >
    <template v-slot:prepend>  
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>

<v-col style="background-color:#BDBDBD;" class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <center><b>Gastos Fijos Provisionales</b></center>
</v-col>

<!-- fila 13 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" v-for="(list, i) in AnalisisBeneficios.ListgastosFijos" :key="i"> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"  > 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">{{list.descripcion}}:</h5>
</v-col>

<v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
<VueAutonumeric 
 label=""
id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  :value="list.valor"
  hide-details
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>


 <!-- ----------------------------- 10% Ventas mes anterio-->
<!-- <v-col  cols="12" md="6" sm="6" class="pa-1" > 
 
<VueAutonumeric label="10% Ventas mes anterior"
id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.numero"
  hide-details
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col> -->

<!-- ----------------------------- nomina del personal-->
<!-- <v-col  cols="12" md="6" sm="6" class="pa-1" > 
<VueAutonumeric label="nomina del personal"
id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.numero"
  hide-details
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col> -->

<hr style="border: 1px dashed #000000; " >

<!-- fila 13 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"> 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Gastos Totales:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
<VueAutonumeric label=""
id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.gastoTotales"
  hide-details
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>

<hr style="border: 1px dashed #000000; " >

<!-- fila 13 -->

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 "> 
<v-row class="pa-0 ma-0 py-0 my-0 d-flex justify-center"> 
<v-col  cols="4" md="4" sm="5" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"> 
  <h5 class="py-2 pr-2">Beneficios Netos:</h5>
</v-col>

 <v-col  cols="8" md="6" sm="6" class="pa-0 ma-0 py-0 my-0 "> 
   <VueAutonumeric label=""
id="formdisabledInput"
 disabled
 dense 
 outlined
 autocomplete="off"
  v-model.number="AnalisisBeneficios.beneficiosNeto"
  hide-details
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>
</v-row>
</v-col>

<br>
</v-card>
</v-col>
<!--------------------------------------------------- fn -->
</center>
 </v-form>
 </div>
 
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import VueAutonumeric from "../components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    VueAutonumeric

  },
  created() {
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("AnalisisBeneficiosNew", this.new);
    // EventBus.$on("AnalisisBeneficiosSave", this.save);
    // EventBus.$on("AnalisisBeneficiosRemove", this.remove);
    // EventBus.$on("AnalisisBeneficiosAnular", this.remove);
    // EventBus.$on("AnalisisBeneficiosPrintList", this.imprimirList);
    EventBus.$on("emitAnalisisBeneficios", this.datostabla);

    this.BuscarBeneficio()
  },

  data:()=>  ({


      Rpropietario:[],
   
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

      AnalisisBusqueda: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
      },

AnalisisBeneficios:{

beneficioBruto:0,
beneficiosNeto:0,
costoVentas:0,
cover:0,
descuento:0,
devolucionesClientes:0,
gastoMenores:0,
gastoProveeFormales:0,
gastoProveeInformales:0,
gastoTotales:0,
gastosFj:0,
valorImpuesto:0,
ventasBrutas:0,
ventasConSeguro:0,
ventasNeta:0,
ventasSinSeguro:0,
ListgastosFijos:[]

},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {

      this.AnalisisBeneficios = e;
      this.acti_tb_data=false
    },
    save() {},
    remove() {},
    eliminar() {},
    anular() {},
    imprimirList() {},
    newwnuevo() {
      (this.AnalisisBeneficios = {

       }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


BuscarBeneficio(){

EventBus.$emit("loading", true);
var Link=`/ConsultaRapida/buscarbeneficios/${this.AnalisisBusqueda.desde}/${this.AnalisisBusqueda.hasta}`
this.$axios.get(this.$hostname+this.$hName+Link,{headers:this.$store.getters.GetheadersAxios}
  ).then(res=>{
    this.AnalisisBeneficios=res.data

//var _this=this;
setTimeout(function(){ 
  EventBus.$emit("loading", false);
}, 300);
    

  } )

},



BlurDesde(){
if(this.AnalisisBusqueda.desde.toString().length==0){
  this.AnalisisBusqueda.desde=new Date().toISOString().substr(0,10)
}
 },


 BlurHasta(){
if(this.AnalisisBusqueda.hasta.toString().length==0){
  this.AnalisisBusqueda.hasta=new Date().toISOString().substr(0,10)
}
 },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {}
  },


computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
  }



};
</script>

<style></style>
