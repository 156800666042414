<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Pacientes Ingresados'"
  :descripcion="'Pacientes Ingresados'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">
         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver">
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/tipocliente/findalltipoclientes'"
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitPacientesIngresados'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist" /> -->
          </template>
        </v-tooltip>
      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <div class="px-2">
  <v-card color="grey lighten-3">
  <div class="px-2">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="4" sm="4">
<!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha desde"
         :rules="[$rules.required]"
        v-model="PacientesIngresados.desde"
        hide-details
        style="font-size:13px;"
        @blur="BlurDesde"
        @input="BuscarPaciente"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>
</v-col>
<!-- fn columna 1 -->
<!-- columna 2 -->
<v-col cols="12" md="4" sm="4">
<!-- ----------------------------- hasta -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Hasta"
         :rules="[$rules.required]"
        v-model="PacientesIngresados.hasta"
        hide-details
        style="font-size:13px;"
        @blur="BlurHasta"
        @input="BuscarPaciente"
        
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>
</v-col>
<!-- fn columna 2 -->
<!-- columna 2 -->
<v-col cols="12" md="4" sm="4">
  <!-- ----------------------------- ARS -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="ARS:"
            :items="RArs"
            :rules="[$rules.required]"
            item-text="nombre"
            v-model="PacientesIngresados.ars"
            @blur="SelctCbArs"
            @focus="cargarArs"
            style="font-size:13px;"
            @change="BuscarPaciente"
            >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-shield"></i>
            </template>
          </v-combobox>
</v-col>
<!-- fn columna 2 -->       
<v-col cols="12" md="12" sm="12" style="margin-top:-25px">
 <div style="border: 1px solid #000000;" >

<v-data-table
                :headers="[
                { text: 'Habitacián', sortable: false,  value: 'Habitacion', width:'auto',class: ['no-gutters black--text'] },
                 { text: 'Paciente', sortable: false, value: 'Paciente', width:'auto',class: ['no-gutters black--text'] },
                 { text: 'Feche Entrada', sortable: false, value: 'Fechaingreso', width:'auto',class: ['no-gutters black--text'] },
                 { text: 'Privado/Seguro', sortable: false, value: 'Ars', width:'auto',class: ['no-gutters black--text'] },
                 { text: 'Actividad', sortable: false, value: 'Actividad', width:'auto',class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listDatos"
              :search="search"      
              :items-per-page="15">
 
<template v-slot:item.Fechaingreso="props">
    <span>{{FormatoFecha(props.item.Fechaingreso) }}</span>
</template>


<!--<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(0)}} </strong></td>
  ---<td class="border-top-bottom"  ><strong></strong></td> 
 </tr>
 </template>
 <template  v-slot:footer v-if="windowSize.width<=600 ">
  <v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Meta de Ventas:0.00</span>
        <span>Total Ventas:0.00</span>
        <span>Total % Comisión:0.00</span>
       <span>Valor Comisiones:0.00</span>
      </v-list-item-content>
  </v-list-item>
  </template>-->
</v-data-table>
 </div>
</v-col>
              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >
 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">       
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>
      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>
            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>
      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />
        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>
        <hr />
        <v-card-actions>
          <v-btn @click.prevent="eliminar(), (Elim_notif.estado = !Elim_notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
<!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Pacientes Ingresados",
      descripcion: "Pacientes Ingresados",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [
        "Pacientes IngresadosNew",
        "Pacientes IngresadosSave",
        "Pacientes IngresadosRemove",
        "Pacientes IngresadosAnular",
        "Pacientes IngresadosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("Pacientes IngresadosNew", this.new);
    // EventBus.$on("Pacientes IngresadosSave", this.save);
    // EventBus.$on("Pacientes IngresadosRemove", this.remove);
    // EventBus.$on("Pacientes IngresadosAnular", this.remove);
    // EventBus.$on("Pacientes IngresadosPrintList", this.imprimirList);
    EventBus.$on("emitPacientes Ingresados", this.datostabla);
  
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({
      RArs:[],
      listDatos:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      PacientesIngresados: {
       
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        ars:null
        // fechaCreacion: new,
        //  fechaModificacion: "", 
      },
EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      headers: [{ text: "Tipo Clientes", value: "descripcion" }],
      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],
      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },
      aalert: {
        estado: false,
        color: null
      },
    }),
  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },
  
    datostabla(e) {
      this.PacientesIngresados = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() {},
    eliminar() {},
    anular() {},
    imprimirList() {},
    newwnuevo() {
      (this.PacientesIngresados = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },
cargarArs(){

this.$axios.get(this.$hostname+this.$hName+'/ars/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RArs=res.data))

},
SelctCbArs(){

 if(this.PacientesIngresados.ars==null){
    this.PacientesIngresados.ars=''
  }

if(this.PacientesIngresados.ars.codigo==undefined){
this.alerta("Seleccione una ars", "error");
this.PacientesIngresados.ars=''
return
}
},

BlurDesde(){
if(this.PacientesIngresados.desde.toString().length==0){
  this.PacientesIngresados.desde=new Date().toISOString().substr(0,10)
}

 },

 BlurHasta(){

if(this.PacientesIngresados.hasta.toString().length==0){
  this.PacientesIngresados.hasta=new Date().toISOString().substr(0,10)
}

 },


BuscarPaciente(){

var where = ` and fecha>=' ${this.PacientesIngresados.desde} ' and fecha<=' ${this.PacientesIngresados.hasta} '  `

if(this.PacientesIngresados.ars!=null){ where+=` and ars= ${this.PacientesIngresados.ars.codigo}`}

var obj={
  where:where
}


this.$axios
        .post(this.$hostname + this.$hName +"/ConsultaRapida/PacientesIngresados",obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ this.GetDataObj(res.data) })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});


},

GetDataObj(e){

// Habitacion[0],Paciente[2],Fechaingreso[1],Ars[3],Actividad[4]
var listPac=[]
this.listDatos=[]
e.forEach(elem => {
var obj={
Habitacion:elem[0],
Paciente: elem[2],
Fechaingreso:elem[1],
Ars:elem[3],
Actividad:elem[4]
}

listPac.push(obj)
});

this.listDatos=listPac

},






    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },
    async filtro() {},

  },

computed:{

  ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}


};
</script>
<style></style>
