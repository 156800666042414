<template>
  <div  v-bind:style="{ 'width':'100%','height':this.windowSize.height-100+'px' }" >

    <center>
    <h1 id="home-letr">BIENVENIDO</h1>
    </center>

     <img id="home-img-mini"  src="@/assets/TransporNet.jpg" height="100%" width="100%" style="backgroud-color:white;"/> 
</div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
export default {

mounted() {

var json = {
      titulo: "DecaSystems",
      descripcion: "Caribbean's Systems Developers",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      vercuenta: false,
      Transp:true,
      btnBarraDi:false,
      accion: [
        "principalNew",
        "principalSave",
        "principalRemove",
        "principalprincipal",
        "principalPrintList",
        "principalVercuenta"

      ]
    };
    EventBus.$emit("anularBotonesAccion", json);

     EventBus.$on("onResize", this.onResize);


//setTimeout(this.ReactInfo,300);



},
created() {



    
  },

 data() {
    return {
     
windowSize:{width:"auto", height:"auto"},

    };
  },
  methods: {

    onResize(e){

console.log(e)
this.windowSize=e


this.windowSize={width:e.width, height:e.height}

},

ReactInfo(){

console.log('Oooooohhhh ok Viendo')


var json = {
      titulo: "DecaSystems",
      descripcion: "Caribbean's Systems Developers",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      vercuenta: false,
      accion: [
        "principalNew",
        "principalSave",
        "principalRemove",
        "principalprincipal",
        "principalPrintList",
        "principalVercuenta"

      ]
    };
    EventBus.$emit("anularBotonesAccion", json);

  }





}



}
</script>

<style scoped>

#home-bienv{


 position:fixed; border-radius: 25px;
 margin-top: 8%;

} 


#home-letr {font-size: 80px;}

#home-img-mini{
border: 2px solid red; 
 height: 300px; 
 border-radius: 25px;

}



 @media screen and (max-width: 800px) {

#home-letr {font-size: 50px;}

#home-img-mini{
height: 110px;
border: 2px solid red; 
border-radius: 25px;
/* margin-right: 250px; */
}


}
</style>