<template>
  <div>
    {{ ActDescBtn }}
    <table
      style="position: absolute"
      width="100%"
      :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8"
    >
<!-- ContenidoBase-Modificacion Fact Controlada  -->
      <tr max-height="48px" height="48px">
        <ContenidoBase
          :titulo="'Modificación Factura Controlada'"
          :descripcion="'Modificación Factura Controlada'"
          :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla"
          @MethodNuevo="newwnuevo"
          @MethodGuardar="save"
          @MethodEliminar="remove"
          @MethodAnular="anular"
          @MethodImprimir="imprimirList"
        />
      </tr>
<!-- ContenidoBase-Modificacion Fact Controlada -->

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraTablaDinamicaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                      small
                      color="yellow accent-4"
                      @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver</v-btn
                    >
                    <v-btn
                      small
                      dark
                      color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px" class="fa fa-print"> </i>
                      Listado</v-btn
                    >
                  </v-btn-toggle>
                </center>
                <BarraBusqueda2 v-if="false"
                  :url="
                    $hostname +
                    $hName +
                    '/services/tipocliente/findalltipoclientes'
                  "
                  :nombre="'Tipo de Clientes'"
                  :headers="headers"
                  :slotname="'item.descripcion'"
                  :emitnombre="'emitCXCINVUnidades'"
                  :SeachNombre="'Búsqueda por descripcion '"
                  :slotlist="slotlist"
                />
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app
            id="inspire"
            class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }"
          >
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                   <!-- Formulario-Modificacion Fact Controlada   -->
                  <v-form ref="formFactControlada" v-model="valid" lazy-validation>
                    <!-- <input
                      type="number"
                      v-model="facturaControl.codigo"
                      style="display: none"
                    /> -->
                    
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox
                          autocomplete="off"
                          dense
                          outlined
                          label="Documento:"
                          :items="RDocumento"
                          item-text="descripcion"
                          v-model="facturaBusqueda.documento"
                          @focus="cargarDocumento"
                          @blur="SelctCbDocumento"
                          :rules="[$rules.required]"
                          v-on:blur="buscar"
                        >
                        
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-sitemap"
                            ></i>
                          </template>
                        </v-combobox>

                        <!---------------------- documento-->
                        <!-- <v-combobox
            autocomplete="off"
            dense
            outlined
            
            label="Documento:"
            :items="RDocumento"
            item-text="descripcion"
            :rules="[rules.required]"
            v-model="facturaControl.documento"
            @blur="SelctCbDocumento"
            @focus="cargar"
            @change="buscar"
          >
            <template v-slot:prepend>
              <i style="font-size: 30px;" class="fa fa-book"></i>
            </template>
          </v-combobox> -->
          
                        <!---------------------- No. Receta-->
                        <v-text-field
                          v-model="facturaControl.receta"
                          :rules="[$rules.required]"
                          label="No. Receta"
                          required
                          outlined
                          dense
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 30px"
                              class="fa fa-hashtag"
                            ></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <!---------------------- No. factura-->

                        <!-- <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
                          <v-row no-gutters>
                          </v-row>
                      </v-col> -->
                        <v-text-field
                          autocomplete="off"
                          v-model="facturaBusqueda.nofactura"
                          type="number"
                          :rules="[$rules.required]"
                          @keyup.enter.prevent="buscar"
                          @blur="buscar"
                          label="No. Factura"
                          required
                          outlined
                          dense
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 30px" class="fa fa-clone"></i>
                          </template>

                          <!-- <template v-slot:append-outer>

<v-btn @click.prevent="buscar" color="secondary" fab x-small dark>
                <i style="font-size: 20px;" class="fa fa-search"></i>
  </v-btn>
            </template>   -->
                        </v-text-field>



                      


                        <!---------------------- Exequatur-->
                        <v-text-field
                          v-model="facturaControl.exequartur"
                          :rules="[$rules.required]"
                          label="exequátur"
                          required
                          outlined
                          dense
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 30px"
                              class="fa fa-id-badge"
                            ></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">
                        <!---------------------- Medico-->
                        <v-text-field
                          v-model="facturaControl.medico"
                          :rules="[$rules.required]"
                          label="Medico"
                          required
                          outlined
                          dense
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 30px"
                              class="fa fa-user-md"
                            ></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                  <!-- fn Formulario-Modificacion Fact Controlada   -->
                </div>
              </v-card>
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraSecundariaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          ></div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
                {
                  text: 'Código',
                  value: 'codigo',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Descripción',
                  value: 'descripcion',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Nombre Corto:',
                  value: 'nombrecorto',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Tasa',
                  value: 'tasa',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Tasa de compra:',
                  value: 'tasaCompra',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Tasa de Venta:',
                  value: 'tasaVenta',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
              ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    ContenidoBase,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitFacturaControlada", this.datostabla);
    // EventBus.$on("FacturaControladaNew", this.new);
    // EventBus.$on("FacturaControladaSave", this.save);
    // EventBus.$on("FacturaControladaRemove", this.remove);
    // EventBus.$on("FacturaControladaAnular", this.anular);
    // EventBus.$on("FacturaControladaPrintList", this.imprimirList);
    //this.filtro()
    //this.cargar()
  },
  created() {
    var json = {
      titulo: "Modificación Factura Controlada",
      descripcion: "Modificación Factura Controlada",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: false,
      accion: [
        "FacturaControladaNew",
        "FacturaControladaSave",
        "FacturaControladaRemove",
        "FacturaControladaAnular",
        "FacturaControladaPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      RDocumento: [],
      obj: [],
      valid: true,

      RSucursal: [],

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      facturaBusqueda: {
        documento:null,
        nofactura: null
      },

  facturaControl: {
      receta:null,
      exequartur:null,
      medico:null  
  },
      

      EstadoBtn: {
        BarraTablaDinamicaAprir: false,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      rules: {
        required: (value) => !!value || "Requerido.",
        edad: [
          (val) => val > 10 || `Edad debe ser mayor a 10`,
          (val) => val < 70 || `Edad debe ser menor a 120`,
        ],
        counter: (value) =>
          (value && value.length <= 50) || "Max 20 characters",
        RTel: (value) => value != 10 || `telefono debe tener 10 digitos`,
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Monedas", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripción:",
        },
        {
          dt0: "nombrecorto",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Nombre Corto:",
        },
        { dt0: "tasa", dt1: null, dt2: null, dt3: 5, dt4: "Tasa:" },
        {
          dt0: "tasaCompra",
          dt1: null,
          dt2: null,
          dt3: 5,
          dt4: "Tasa de compra:",
        },
        {
          dt0: "tasaVenta",
          dt1: null,
          dt2: null,
          dt3: 5,
          dt4: "Tasa de Venta:",
        },
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null,
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?",
      },

      abrirTablaDinamica: true,
      abrirTablaSegunda: false,
    };
  },
  methods: {

  // Comprobando si el objeto tiene una propiedad llamada facturasventaPK. 
  //y busca en el servidor una factura
    buscar() {

      this.facturaControl= {
      receta:null,
      exequartur:null,
      medico:null  
  }

if(this.facturaBusqueda.documento==null){
  this.$refs.formFactControlada.validate()
  this.alerta("Seleccione un documento", "error");
  return
}
      if (
        (this.facturaBusqueda.documento != null) &&
        (this.facturaBusqueda.nofactura != null) &&
        (this.facturaBusqueda.nofactura.length>0) 

      ) {
        //console.log('ok')

        this.$axios
          .get(
            this.$hostname +
              this.$hName +
              "/facturasventas/findFactControlada/" +
              this.facturaBusqueda.documento.codigo +
              "/" +
              this.facturaBusqueda.nofactura
          )
          .then((res) => {
            console.log(res.data.hasOwnProperty('facturasventaPK'))
          //  facturaBusqueda.nofactura,facturaBusqueda.documento,formFactControlada
           if(res.data.hasOwnProperty('facturasventaPK')==true){
              this.facturaControl=res.data
              this.alerta("Factura seleccionada  ", "bien2");
           }else{
            this.alerta("Este numero de factura no posee receta controlada ", "error");
           }
          
          });
      }
    },

    mostrar(e) {
      console.log('----Mostrando Fac Controlada----')
      console.log(e)
      this.facturaControl.noreceta = e.receta;
      this.facturaControl.exequatur = e.exequartur;
      this.facturaControl.medico = e.medico;
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

   // Llamar a una API y obtener los datos de la API.
    cargarDocumento() {
      this.$axios
        .get(this.$hostname + this.$hName + "/documentoventas/findallActivos", {headers: this.$store.getters.GetheadersAxios})
        .then((res) => (this.RDocumento = res.data));
    },
    
   // Comprobando si el valor de la selección es nulo o no.
    SelctCbDocumento() {
var _this = this;
      setTimeout(function () {
        if (_this.facturaBusqueda.documento == null) {
          _this.facturaBusqueda.documento = "";
        }

        if (!_this.facturaBusqueda.documento.hasOwnProperty('codigo')) {
          _this.facturaBusqueda.documento = "";
          _this.facturaBusqueda.documento = null;
          return;
        }
      }, 300);
    },

  // Un método que se llama cuando el usuario hace clic en el botón.
    abrirTabla() {
      // console.log("entro");
      this.abrirTablaDinamica = !this.abrirTablaDinamica;
    },
    abrirTabla2() {
      // console.log("entro");
      this.abrirTablaSegunda = !this.abrirTablaSegunda;
    },

    alerta(e, l) {

      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }

      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

    // Crear un nuevo objeto y asignarlo a la propiedad de datos de la instancia de Vue.
    newwnuevo() {
     
      this.facturaBusqueda= {
        documento:null,
        nofactura: null
      }

  this.facturaControl= {
      receta:null,
      exequartur:null,
      medico:null  
  }
      
        this.ejecucion = true;
        EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
    },

   // Guardando los datos en la base de datos.
    save() {
      if (this.$refs.formFactControlada.validate()) {
           if(this.facturaControl.hasOwnProperty('facturasventaPK')==true){

            if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);

        this.$axios
        .post(this.$hostname + this.$hName + "/facturasventas/SaveFacturaControlada",this.facturaControl,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));


        }


           }else{
            this.alerta("Este numero de factura no posee factura controlada ", "error");
           }


        


        
      } else {
        this.alerta("Esta factura no tiene  receta controlada", "error");
      }
    },

    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      if (this.moneda.codigo != 0 && this.moneda.codigo != null) {
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/services/Moneda/remove",
              JSON.parse(JSON.stringify(this.moneda))
            )
            .then((res) => this.alerta("Dato eliminado correctamente", "bien"))
            .catch((error) => this.alerta(error, "error"));
        }
      }
    },

    anular() {},

    imprimirList() {},

    datostabla(e) {
      this.facturaControl = e;

      this.acti_tb_data = false;

      //console.log(e)
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/Moneda/findall", {})
        .then((res) => {
          this.list = res.data;
        });
    },
  },

  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
    },
  },
};
</script>

<style></style>
