<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{GetEjecucion}}
  <!-- #3f51b5 --> 
  <!-- tooltip -->
  <v-col class="pa-0 ma-0 py-0 my-0 "  style="background-color: #dd4b39" cols="12" md="12" sm="12">
    <v-row no-gutters > 
     
      <v-col class="pa-0 ma-0 py-0 my-0 pt-1"   cols="12" md="9" sm="8"  >
        <span :class="`d-flex justify-start white--text`">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
          <i v-on="on" style="font-size: 20px;" class="fa fa-info-circle px-2"> </i><b v-on="on">Cierre de Cajas</b>
        </template>
           <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Cierre de Cajas: </span> Crea Cierre
              de Cajas</i>
          </v-tooltip>
        
        </span>   
      </v-col>
      <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="3" sm="4"  >
        <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarCierreCaja()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewCierreCaja()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCierreCaja" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Crea o edita cliente y retornar atrás </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>
        <!-- <v-btn  color="grey darken-4" fab x-small @click="cancelarCierreCaja()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn> -->
      </v-col>
      
      
    </v-row>


 <!--   <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Cierre de Cajas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Cierre de Cajas: </span> Crea Cierre
              de Cajas</i>

          </v-tooltip>
 
    <div class="d-flex justify-end">
    <v-btn  color="grey darken-4" fab x-small @click="cancelarCierreCaja()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
     </div> -->
  </v-col>
<!-- fn tooltip -->
<!-- Contenido -->
<v-col class="pa-0 ma-0 py-0 my-0 px-2"   cols="12" md="12" sm="12"  >
  <v-row>
            <!--------- columna 1 Cierre ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->
              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <h1><b>Cierre #{{ CierresCajas.cierrescajasPK.secuencia }}</b></h1>
                <v-container>

                  <v-form ref="formCierre" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-card color="grey lighten-3">
                      <center><b> Tarjeta </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <!-- ----------------------------- Cheques Emitidos -->
                          <v-text-field label="(Cantidad):"  autocomplete="off" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadtarjeta" @keyup="KeyUpCantidad"
                            v-on:keyup="CalcularDenominacion" @blur="blurCantidadtarjeta" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>

                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <!-- ----------------------------- Cheques Emitidos -->
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.tarjeta" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>
                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>
                    <v-card color="grey lighten-3">
                      <center><b> Cheque </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field label="(Cantidad):" autocomplete="off" @blur="blurCantidadcheques" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadcheques" @keyup="KeyUpCantidad"
                            v-on:keyup="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.cheques" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>

                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>
                    <v-card color="grey lighten-3">
                      <center><b> Transferencia </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadtranferencia" @blur="blurCantidadtranferencia" @keyup="KeyUpCantidad"
                            v-on:keyup="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.transferencia" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>

                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>

                    <v-card color="grey lighten-3">
                      <center><b> Recibo de ingreso </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadrecibo" @blur="blurCantidadrecibo" @keyup="KeyUpCantidad" hide-details
                            v-on:keyup="CalcularDenominacion">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>


                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.recibos" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>

                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>
                  </v-form>
                  <!--------------------------------------------------- tb MONEDA -->

                  <v-card>
                    <v-toolbar flat dense color="#BDBDBD">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">

                          <v-btn v-on="on" @click.prevent="AddMonedaCierrecaja" color="green" fab x-small dark>
                            <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                          </v-btn>


                        </template>
                        <span>Agregar Moneda</span>
                      </v-tooltip>

                      <v-toolbar-title class="font-weight-light">
                        <v-card-title>MONEDA</v-card-title>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
              
                      <strong style="font-size: 15px;">{{Monedafactura.nombrecorto.trim()}}.{{ currencyFormatter(Totales.CierresCajas.totalTotal)
                      }}</strong>

                    </v-toolbar>
                    <v-data-table
                      :headers="[{ sortable: true, text: 'Valor', value: 'valor', width: 'auto' }, { sortable: false, text: 'Moneda', value: 'moneda', width: 200 }, { sortable: false, text: 'ACCIÓN', value: 'action', width: 90 }]"
                      :items="RLisMon">

                      <template v-slot:item.valor="props">
                        <!-- ----------------------------- cantidad -->
                        <VueAutonumeric label="" autocomplete="off" style="font-size: 13px;" required outlined dense
                          v-model="props.item.valor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>


                      </template>

                      <template v-slot:item.moneda="props">
                        <!-- -----------------------------------------Moneda   @blur="SelctCbMoneda"  -->
                        <v-combobox autocomplete="off" dense outlined label="" :items="Rmoneda" item-text="nombrecorto"
                          :rules="[$rules.required]" v-model="props.item.moneda"
                          @blur="SelctCbMonedaCierre(props.item.moneda)" style="font-size:13px;" @focus="cargarMoneda">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>

                      </template>

                      <template v-slot:item.action="{ item }">
                        <!-- <v-btn fab @click.prevent="editMon(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                        <v-btn style="margin-top:-30px" fab @click.prevent="deleMondaCierre(item)" x-small
                          color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                      </template>
                    </v-data-table>
                  </v-card>
                  <!--------------------------------------------------- fn tb  MONEDA -->


                </v-container>
              </v-col>




            </v-col>
            <!--------- fn columna 1 Cierre ---------->

            <!--------- fn columna 2 Cierre ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <v-form ref="formDenominacionPeso" @submit.prevent="" v-model="valid" lazy-validation>
                <v-data-table color="primary" class="elevation-1" dense :headers="[{ text: 'Denominación', value: 'valor', width: 'auto' },
                { text: 'Cantidad', sortable: false, value: 'cantidad' },
                { text: 'Valor', value: 'Vtotal', width: 'auto' },
                ]" :items="ListDenomMoneda" :items-per-page="-1">


                  <template v-slot:item.valor="props">
                    <span style="font-size: 15px;">{{ currencyFormatter(props.item.valor) }}</span>
                  </template>


                  <template v-slot:item.cantidad="props">
                    <!-- ----------------------------- cantidad -->
                    <!-- <input 
      style="border: 2px solid #607D8B; font-size: 15px; "
      autocomplete="off"
      size="5"
        dense outlined
        v-model.number="props.item.cantidad"
         @keyup="KeyUpCantidad"
         v-on:keyup="CalcularDenominacion"                  
     /> -->

                    <v-text-field label="" autocomplete="off" dense outlined v-model.number="props.item.cantidad"
                      @keyup="KeyUpCantidad" @blur="blurCantNotnull(props.item)" v-on:input="CalcularDenominacion"
                      hide-details>
                    </v-text-field>

                  </template>

                  <template v-slot:item.Vtotal="props">
                    <p style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{ props.item.cantidad = 0 }}
                    </p>
                    <span v-bind:style="{ 'font-size': '17px;', 'color': getColor(props.item.Vtotal) }">
                    {{Monedafactura.nombrecorto.trim()}}.{{currencyFormatter(props.item.Vtotal)
                    }}</span>
                  </template>






                  <template v-slot:item.action="{ item }">
                    <!-- <v-btn fab @click.prevent="editMon(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                    <!-- <v-btn  fab @click.prevent="deleDenominPeso(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->
                  </template>

                  <template v-slot:body.append v-if="windowSize.width > 600">
                    <tr>
                      <td class="border-top-bottom"><strong></strong></td>
                      <td class="border-top-bottom"><strong class="d-flex justify-end"
                          style="font-size:13px;">Totales:</strong></td>
                      <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
                          {{ currencyFormatter(Totales.CierresCajas.totalDenomiacionvalor) }} </strong></td>
                      <td class="border-top-bottom"><strong></strong></td>
                    </tr>
                  </template>

                  <template v-slot:footer v-if="windowSize.width <= 600">
                    <v-list-item style="font-size: 80%; border: 2px solid #757575;" class="white--text">
                      <v-list-item-content>
                        <b>TOTAL: {{ currencyFormatter(Totales.CierresCajas.totalDenomiacionvalor) }}</b>
                      </v-list-item-content>
                    </v-list-item>
                  </template>




                </v-data-table>


              </v-form>


            </v-col>

   </v-row>
</v-col>
<!-- fn Contenido-->

<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 
  },
  mounted() {
  },
  beforeDestroy() {

  },

  data: () => ({ 
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    // Monedafactura: null,
    Totales: {
      CierresCajas: {
        totalTotal: 0,
        totalDenomiacionvalor: 0,
      },
    },
    RLisMon:[],
    ListDenomMoneda:[],
    CierresCajas: {
      cierrescajasPK: { caja: 0, secuencia: 0 },
      cantidadtarjeta: 0,
      tarjeta: 0,
      cantidadcheques: 0,
      cheques: 0,
      cantidadrecibo: 0,
      recibos: 0,
      efectivo: 0,
      cierrecajaMonedaList: [],
      DenominacionCierrecajaList: [],
      datoTarjeta: null,
      cantidadtranferencia:0,
      transferencia:0,

    },
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),
  props: { 

    cajaSelect: {
      required: false,
      type: Object,
      default: null
    },

    Monedafactura: {
      required: false,
      type: Object,
      default: null
    },
    configuraciones: {
      required: false,
      type: Object,
      default: null
    },
    OtrasConfigPv: {
      required: false,
      type: Object,
      default: null
    },


  },

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,
   
   // Usca el ultimi cierre de caja
  async getUltimocierre(){
    var _this = this;
      this.dialogCierreCaja = true;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.cajaSelect.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
          this.CierresCajas.cierrescajasPK.caja= this.cajaSelect.codigo
          setTimeout(function(){ 
            _this.updateConfiguracion()
            _this.$emit("DataRetornCierre", _this.CierresCajas)
            _this.cargarDenominacion(res.data)
          }, 300);

    
        });

      
  },

cargarDenominacion(e){
 var _this = this;
  this.$axios
        .get(
          this.$hostname + this.$hName + "/denominacionmoneda/findallActivos/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListDenomMoneda = res.data),
            setTimeout(function () {
             _this.CalcularDenominacion();
            }, 500);
        });

},

    // Creando un nuevo objeto y asignándolo a la variable CierresCajas.
    newnewCierreCaja() {
      this.ListDenomMoneda = [];
      (this.Rmoneda = []),
        (this.RLisMon = []),
        (this.CierresCajas = {
          cierrescajasPK: { caja: 0, secuencia: 0 },
          cantidadtarjeta: 0,
          tarjeta: 0,
          cantidadcheques: 0,
          cheques: 0,
          cantidadrecibo: 0,
          recibos: 0,
          efectivo: 0,
          cierrecajaMonedaList: [],
          DenominacionCierrecajaList: [],
          datoTarjeta: null,
          cantidadtranferencia:0,
           transferencia:0
        }),
        (this.Totales.CierresCajas = {
          totalTotal: 0,
          totalDenomiacionvalor: 0,
        });
        this.loading(false)
 var _this=this;
    setTimeout(function(){ 
     _this.getUltimocierre()
     }, 300);
        
    },

    cancelarCierreCaja() {
      this.dialogCierreCaja = false;
      this.newnewCierreCaja();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarCierreCaja", this.dialogCierreCaja)
     }, 300);
    },


    CalcularDenominacion() {
      var _this = this;
      var Lisdnm = JSON.parse(JSON.stringify(this.ListDenomMoneda));
      this.Totales.CierresCajas.totalDenomiacionvalor = 0;
      Lisdnm.forEach((element) => {
        element.Vtotal = element.cantidad * element.valor;
        if (element.denominacion === undefined) {
          element.denominacion = { codigo: element.codigo, descripcion: element.descripcion };
        }
        this.Totales.CierresCajas.totalDenomiacionvalor += element.Vtotal;
      });
      setTimeout(function () {
        _this.ListDenomMoneda = JSON.parse(JSON.stringify(Lisdnm));
      }, 500);
      this.CierresCajas.efectivo =
        this.Totales.CierresCajas.totalDenomiacionvalor;
      /*this.Totales.CierresCajas.totalTotal =
        this.Totales.CierresCajas.totalDenomiacionvalor +
        this.CierresCajas.cheques * this.CierresCajas.cantidadcheques +
        this.CierresCajas.recibos * this.CierresCajas.cantidadrecibo +
        this.CierresCajas.tarjeta * this.CierresCajas.cantidadtarjeta;*/
      this.Totales.CierresCajas.totalTotal =
        this.Totales.CierresCajas.totalDenomiacionvalor +
        this.CierresCajas.cheques + this.CierresCajas.recibos + this.CierresCajas.transferencia + this.CierresCajas.tarjeta;
    },
    KeyUpCantidad(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0;
      }
      if (e.key) {
        var mynumeral = require("numeral");
        e.target.value = mynumeral(e.target.value).value();
        if (e.target.value == null) {
          e.target.value = 0;
        }
      } else {
        e.key = "";
      }
      if (e.target.value.length == 0) {
        e.target.value = 0;
        e.key = 0;
      }
    },

    blurCantidadtarjeta() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadtarjeta==null){
  _this.CierresCajas.cantidadtarjeta=0
}

if(_this.CierresCajas.cantidadtarjeta.length == 0){
  _this.CierresCajas.cantidadtarjeta=0
}

}, 300);

  },

  blurCantidadcheques() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadcheques==null){
  _this.CierresCajas.cantidadcheques=0
}

if(_this.CierresCajas.cantidadcheques.length == 0){
  _this.CierresCajas.cantidadcheques=0
}

}, 300);

  },

  blurCantidadtranferencia() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadtranferencia==null){
  _this.CierresCajas.cantidadtranferencia=0
}

if(_this.CierresCajas.cantidadtranferencia.length == 0){
  _this.CierresCajas.cantidadtranferencia=0
}

}, 300);

  },

  blurCantidadrecibo() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadrecibo==null){
  _this.CierresCajas.CierresCajas.cantidadrecibo=0
}

if(_this.CierresCajas.cantidadrecibo.length == 0){
  _this.CierresCajas.cantidadrecibo=0
}

}, 300);

  },

  // Llamar a una API y obtener los datos de la API y almacenarlos en la variable Rmoneda.
  cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    AddMonedaCierrecaja() {
      var jsn = {
        codigo: 0,
        valor: 0,
        moneda: null,
      };
      this.RLisMon.push(jsn);
    },
    // Eliminar el elemento de la Tabla.
    deleMondaCierre(item) {
      const index = this.RLisMon.indexOf(item);
      confirm("¿ Desea eliminar este Moneda ?") &&
        this.RLisMon.splice(index, 1);
    },

    SelctCbMonedaCierre(e) {
      setTimeout(function () {

      }, 300);

      /*var _this = this;
            setTimeout(function () {
              if (e.moneda == null) {
                e.moneda = "";
              }
      
              if (e.moneda.hasOwnProperty('codigo')) {
                e.moneda = "";
                e.moneda = null;
                return;
              }
            }, 300);*/
    },

    getColor(e) {
      if (e <= 0) {
        return "red";
      } else {
        return "green";
      }
    },

     // Comprobando si el valor es nulo o vacío y si lo es, lo establece en 0.
     blurCantNotnull(e) {
      var _this = this;
      setTimeout(function () {
        if (e.cantidad == null) {
          e.cantidad = 0
          return
        }
        if (e.cantidad.length == 0) {
          e.cantidad = 0
          return
        }
      }, 300);


      /*if(e==null){
        e=0
        return
      }
      if(e.length==0){
        e=0
        return
      }*/

    },

    
    // Comprobando si el valor de veryFoms es verdadero. Si es verdadero, llamará a la función
    // validarCierreCarnet(). Si es falso llamará a la función PostSalvarCierreCaja(falso).
    SalvarCierreCaja() {
      var lccaja = JSON.parse(localStorage.caja);
      if (lccaja.veryFoms == true) {
        //if(this.tipoTarjeta.azul==true){return 0;}
        //if(this.tipoTarjeta.cardnet==true){this.validarCierreCarnet();return 0;}
        this.loading(true)
        this.validarCierreCarnet();
        return 0;
      } else {
        this.PostSalvarCierreCaja(false);
      }
    },
// Hacer una solicitud  al servidor para procesar el cierre del veriphone.
    async validarCierreCarnet() {
      this.loading(true)
      //http://localhost:22105/api/Prueba
      // this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>( this.Rmoneda=res.data))

      var num = "";
      // num = (this.Facturasventa.tarjeta.toFixed(2) + "").replace(".", "");

      var obj = {
        amount: 0,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: 0,
        referenceNumber: 0,
      };

      //var stobj='{"Status":"Successful","Host":{"Value":6,"Description":"Credit"},"Mode":{"Value":"C@5","Description":"Sale with CHIP(EMV)"},"Card":{"Product":"Mastercard","CardNumber":"534217******5024","HolderName":"SCOTIABANK/TARJETAHABIENTE"},"Transaction":{"AuthorizationNumber":"007656","Reference":2,"RetrievalReference":214611000062,"DataTime":"26/5/2022 11:04:40 a. m.","ApplicationIdentifier":"A0000000041010","LoyaltyDeferredNumber":null,"TID":null},"Batch":22,"TerminalID":"80029126","MerchantID":"349011300","Acquired":"CardNET","Reserved":null,"Signature":null}'

      this.$axios
        .post("http://localhost:22105/api/ProcessClose", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          var e = JSON.parse(res.data);
          var _this = this;
          if (e.Status == "Successful") {
            this.CierresCajas.datoTarjeta = JSON.stringify(e);
            this.alerta("Cierre finalizada correctamente", "bien2");
            setTimeout(function () {
              _this.PostSalvarCierreCaja(true);
            }, 3000);
          }

          if (e.Status == "Failed") {
            if (
              e.Messages[0] ==
              "La cadena de entrada no tiene el formato correcto."
            ) {
              setTimeout(function () {
                _this.PostSalvarCierreCaja(false);
              }, 3000);
            } else {
              this.alerta("Transacción cancelada", "error");
            }
          }
        })
        .catch((error) => {
          this.alerta("Transacción cancelada", "error");
          this.loading(false)
        });
    },

 // Envío de una solicitud POST al servidor para guardar el cierre caja.
 PostSalvarCierreCaja(verifone) {
      this.CierresCajas.cierrecajaMonedaList = this.RLisMon;
      this.CierresCajas.denominacionCierrecajaList = this.ListDenomMoneda;
      this.CierresCajas.cierrescajasPK.caja = this.cajaSelect.codigo;
      this.CierresCajas.sucursal = this.cajaSelect.sucursal;
      this.CierresCajas.cajero = this.$store.getters.GetOtrasConfigPv.cajero
      var MONEDABASE=this.$store.getters.GetConfiguracionGeneral.config.monedabase
      this.CierresCajas.moneda = MONEDABASE.codigo
      // this.CierresCajas.moneda = this.Monedafactura.codigo
        // var msg ={
        //           accion:"print",
        //           formato:this.caja.formatoimpresioncontado.trim(),
        //           impresora:JSON.parse(localStorage.printerSelect).nombre,
        //           data:this.configuraciones,
        //           cierre:JSON.parse(ooop),
        //           caja:this.caja,
        //           verifone:verifone,
        //           cajero:this.$store.getters.GetOtrasConfigPv.cajero
        //        //   fac:this.ReimprecionFacturaSeleccionada
        //         }
        //        this.imprimir(msg,"cierrescaja/imprimir")

        // var bool=true
        // if(bool==true){
        //   return 0;
        // }
        this.loading(true)
      this.$axios
        .post(
          this.$hostname + this.$hName + "/cierrecaja/saveOrUpdate/",
          this.CierresCajas,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas = res.data.Cierre;
          // if (res.data.cierrescajasPK.secuencia>0) {}
          this.alerta("Cierre de caja salvado correctamente!!!", "bien");
          this.dialogCierreCaja = false;
          if (localStorage.printerSelect != null) {
            var msg = {
              accion: "print",
              formato: this.cajaSelect.formatoimpresioncontado.trim(),
              impresora: JSON.parse(localStorage.printerSelect).nombre,
              data: this.configuraciones,
              cierre: res.data.Cierre,
              caja: this.cajaSelect,
              verifone: verifone,
              cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              ListFaccierre: res.data.ListFaccierre,
            };
            this.imprimir(msg, "cierre");
          }
          this.alerta("Datos salvado correctamente !!!", "bien");

        var _this=this;
 setTimeout(function(){ _this.newnewCierreCaja() }, 300);
         // this.newnewCierreCaja();
        })
        .catch((erro) => {
          this.loading(false)
          this.alerta("El cierre de caja no se salvo correctamente", "error");
        });
    },

// Actualización de los datos en la configuracion.
updateConfiguracion() {
      if (this.OtrasConfigPv != null) {
        // var cond = this.$store.getters.GetConfiguracionGeneral.configPv.conduce
         var cond = this.configuraciones.configPv.conduce;
        var mar = this.cajaSelect.marca;

        var OtrasConfigPv= JSON.parse(JSON.stringify(this.OtrasConfigPv))


      OtrasConfigPv.caja =(cond && mar ? "*" : "") +
          this.cajaSelect.descripcion.trim() +
          "-" +
          this.CierresCajas.cierrescajasPK.secuencia;

       /* if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
          this.OtrasConfigPv.cajero = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          this.OtrasConfigPv.cajero = this.$store.getters.GetdatosInfUsu.usuario.nombre
        }*/

        this.$store.commit("MutationDataOtrasConfigPv", OtrasConfigPv);
        sessionStorage.setItem("OtrasConfigPv",JSON.stringify(OtrasConfigPv));
      }
    },

// Envío de una solicitud POST al servidor con los datos a imprimir.
imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.newnewCierreCaja();
          })
          .catch((error) => console.error(error));
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.newnewCierreCaja();
          })
          .catch((error) => console.error(error));
      }
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },
    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>