<template>
  <div>{{ GetLogin }}
    <!--<div  v-bind:style="{ 'width':'100%','height':this.windowSize.height-20+'px','border': '2px solid #000000'  }" >  -->
    <div
      v-bind:style="{ 'width':'100%','height':this.windowSize.height-sizeOnrefom+'px','border': '2px solid #000000'  }">

      <v-img src="../assets/Baselogin.jpg" height="100%" width="100%" style="backgroud-color:white;">
        <br>
        <v-container class="pa-0 ma-0 py-0 my-0 px-3">
          <img src="../assets/log.png" height="18%" width="18%" style="backgroud-color:white;" />
        </v-container>

        <v-row>
          <!-- columna 1 -->
          <v-col cols="0" md="2" sm="2">

          </v-col>
          <!-- fn columna 1 -->
          <!--  columna 2 -->
          <v-col cols="12" md="8" sm="8">
            <center style="margin-top:-46px">
              <v-container>
                <v-card color="grey lighten-3">

                  <v-toolbar flat dense color="grey darken-2" style="border: 1px solid #000000;">

                    <div>
                      <v-avatar tile size="45">

                        <i style="font-size: 30px; color:#FFFFFF;" class="fa fa-users"></i>
                        <!-- <img
                          src="@/assets/iconos_micromarket/custom-reports.png"
                          alt="Reporte"
                        > -->

                      </v-avatar>
                      <span class="font-weight-light" style="font-size: 18px; color:#FFFFFF;"> <b>Iniciar
                          Sesión</b></span>
                    </div>
                    <v-spacer></v-spacer>
                    <!-- <div class="d-flex justify-end"></div> -->

                    <v-btn style="margin-right:30px;" width="25%" @click="dialogPassOlvidada=true" text small>
                      <span style="text-decoration-line: underline; color:#FFFFFF; font-size:9px; ">
                        Olvidaste tu contraseña
                      </span>
                    </v-btn>

                    <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                    <!-- style="color:white;" -->
                    <!-- <i class=""> </i> -->

                    <!-- <v-spacer></v-spacer> -->
                    <!-- <v-btn
                      color="grey darken-4"
                      fab
                      small
                      @click="cerrarmodalcuenta"
                    >
                     <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                    </v-btn> -->
                  </v-toolbar>

                  <hr>

                  <v-container style="border: 1px solid #000000;">
                    <v-form ref="formLogin" @submit.prevent="" v-model="valid" lazy-validation>
                      <v-row>
                        <!-- columna 1 -->
                        <v-col cols="12" md="12" sm="12">


                          <!-------------------------------------------------- nombe login -->


                          <v-text-field label="Usuario:" dense outlined :rules="[$rules.required]" autocomplete="off"
                            v-model="Login.username" :disabled="loading" @keyup.enter="inicioSesion">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-user"></i>
                            </template>
                          </v-text-field>

                          <!-------------------------------------------------- Pass -->


                          <v-text-field label="Contraseña:" dense type="password" outlined :rules="[$rules.required]"
                            autocomplete="off" v-model="Login.password" :disabled="loading" @keyup.enter="inicioSesion">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                            </template>
                          </v-text-field>


                        </v-col>
                        <!-- fn columna 1 -->

                        <v-col cols="12" md="12" sm="12" style="margin-top:-25px">
                          <!-- <v-list-item-content> color:#FFFFFF </v-list-item-content>  dialogNewConfigGeneral,dialogNewRegistro-->
                          <h4 class="font-weight-light" style="color:#424242;"><b>No tienes cuenta?</b></h4>
                          
                          <v-btn width="25%" class="text-decoration-underline" @click="dialogSessionAdmin=true" text
                            small>
                            <span style="text-decoration-line: underline;">
                              Registrate aquí
                            </span>
                          </v-btn>
                        </v-col>

                      </v-row>
                    </v-form>
                  </v-container>

                  <hr>

                  <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;"
                    class="d-flex justify-center">


                    <v-btn-toggle dense rounded>

                      <v-btn color="#3c3b3a" :loading="loading" :disabled="loading" @click.prevent="inicioSesion">
                        <i style="font-size: 28px; color:#FFFFFF; " class="fa fa-sign-in"> </i>

                        <span style="color:#FFFFFF;">Login </span>

                        <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>

                      </v-btn>
                    </v-btn-toggle>


                  </v-toolbar>

                </v-card>
              </v-container>
            </center>
          </v-col>
          <!-- fn columna 2 -->

          <!--  columna 3 -->
          <v-col cols="0" md="2" sm="2">


          </v-col>
          <!-- fn columna 3 -->
        </v-row>


        <!-- <v-snackbar
              v-model="snackbarFooter"
                :multi-line="false"
                :timeout="0"
            >


              <template v-slot:action="{ attrs }">
                <v-btn
                  color="red"
                  text
                  v-bind="attrs"
                  @click="snackbarFooter = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar> -->


      </v-img>

      <!-- modales -->

 <!-- ------------------------------------------------------ Modal New Configuracion Generarl -->
 <v-dialog v-model="dialogNewConfigGeneral" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
<ComponentNewConfigGeneral @CloseConfig="dialogNewConfigGeneral=false"/>


</v-dialog>
<!-- ------------------------------------------------------ fn Modal New Configuracion Generarl -->

 <!-- ------------------------------------------------------ fn Modal Pass Admin -->

 <v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogSessionAdmin" persistent max-width="60%" max-height="80%" min-width="45%">
<SesionAdmin  @inicioSession="(dialogNewConfigGeneral=true, dialogSessionAdmin=false)"    @CloseSession="dialogSessionAdmin=false"/>
</v-dialog>
  <!-- ------------------------------------------------------ fn Modal New Configuracion Generarl -->






      <!-- ------------------------------------------------------ Modal New registro -->

      <v-dialog v-model="dialogNewRegistro" persistent fullscreen hide-overlay transition="dialog-bottom-transition">

        <v-card>
          <v-toolbar dark color="grey darken-2">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <v-toolbar-title v-on="on">
                  Registrate
                </v-toolbar-title>

              </template>
              <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                <span> Registro: </span> Creación de usuario</i>

            </v-tooltip>

            <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

            <v-spacer></v-spacer>

            <v-card-actions>

              <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width-70"
                color="transparent">
                <v-btn-toggle>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn id="boton-mini" v-on="on" @click="NewRegistro" color="#90A4AE" small dark>
                        <i style="font-size: 20px;" class="fa fa-file-o"> </i>
                        Limpiar
                      </v-btn>
                    </template>
                    <span>Limpiar </span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn id="boton-mini" v-on="on" @click="saveRegistro" color="success" small dark>
                        <i style="font-size: 20px;" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
                        SALVAR
                      </v-btn>
                    </template>
                    <span>Guardar Registro</span>
                  </v-tooltip>

                  <!-- <v-tooltip top>
                  <template v-slot:activator="{ on }">
                     <v-btn id="boton-mini" v-on="on" @click="removeComponentsNotaCredito" color="red"  small  dark>
                      <i style="font-size: 20px;" class="fa fa-trash"> </i>
                      ELIMINAR </v-btn>
                   </template>
                            <span>Eliminar configuración</span>
                    </v-tooltip> -->

                </v-btn-toggle>

              </v-sheet>

            </v-card-actions>

            <v-btn color="grey darken-4" fab small @click="cerrarRegistro">
              <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn>
          </v-toolbar>

          <v-list three-line subheader>

            <v-container>

              <v-card color="grey lighten-3">
                <v-toolbar flat dense color="grey darken-2" style="border: 1px solid #000000;">


                  <v-avatar tile size="45">

                    <i style="font-size: 30px; color:#FFFFFF;" class="fa fa-pencil-square-o"></i>

                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px; color:#FFFFFF;"> <b>Registro de
                      Usuario</b></span>

                </v-toolbar>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="formRegistro" v-model="valid" lazy-validation>
                    <input type="number" v-model="registro.codigo" style="display:none;" />
                    <v-row>
                      <!-- fila 1 ------------------------------>
                      <v-col cols="12" md="12" sm="12">

                        <!-- Nombre:-->
                        <v-text-field label="Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="registro.nombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>

                        <!-- Nombre:-->
                        <v-text-field label="usuario:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="registro.usuario" @blur="validarIduser">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>

                          <template v-slot:append-outer>

                            <!-- <v-tooltip top>
                            <template v-slot:activator="{ on }">
                            <i v-if="ValidIdUser==true" v-on="on" style="font-size: 30px; color:#F44336; " class="fa fa-times"></i>
                            </template>
                                      <span>El Usuario ya existe agregado</span>
                              </v-tooltip> -->

                            <i v-if="ValidIdUser==true" v-on="on" style="font-size: 30px; color:#F44336; "
                              class="fa fa-times"></i>
                            <i v-if="ValidIdUser==false" style="font-size: 30px; color:#4CAF50;"
                              class="fa fa-check"></i>
                          </template>


                        </v-text-field>


                        <!-- contraseña:-->
                        <v-text-field label="clave:" autocomplete="off" type="password" dense outlined
                          :rules="[$rules.required]" v-model="registro.clave">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                          </template>
                        </v-text-field>

                        <!-- contraseña:-->
                        <v-text-field label="Confirmación:" autocomplete="off" type="password" dense outlined
                          :rules="[$rules.required]" v-model="ContraseñaConfirmacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                          </template>

                          <template v-slot:append-outer>
                            <i v-if="registro.clave!=ContraseñaConfirmacion " style="font-size: 30px; color:#F44336; "
                              class="fa fa-times"></i>
                            <i v-if="registro.clave==ContraseñaConfirmacion" style="font-size: 30px; color:#4CAF50;"
                              class="fa fa-check"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  email -->
                        <v-text-field label="E-mail" dense outlined v-model="registro.correo" :rules="[$rules.email]"
                          @blur="validarEmail">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                          </template>

                          <template v-slot:append-outer>

                            <!-- <v-tooltip top>

                            <template v-slot:activator="{ on }">
                            <i v-if="ValidEmail==true" v-on="on" style="font-size: 30px; color:#F44336; " class="fa fa-times"></i>
                            </template>
                                      <span>El correo ya existe agregado</span>
                              </v-tooltip> -->

                            <i v-if="ValidEmail==true" style="font-size: 30px; color:#F44336; " class="fa fa-times"></i>
                            <i v-if="ValidEmail==false" style="font-size: 30px; color:#4CAF50;" class="fa fa-check"></i>
                          </template>
                        </v-text-field>

                        <v-text-field label="Palabra secreta:" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="registro.palabrasecreta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>


                        <v-switch class="mt-0" color="teal" inset hide-details label="Tipo de usuario:"
                          v-model="registro.consulta">
                          <template v-slot:label>
                            <strong v-if="registro.consulta==true" style="color:#000000;">Tipo de usuario:
                              Consulta</strong>
                            <strong v-if="registro.consulta==false" style="color:#000000;">Tipo de usuario:
                              Reporte</strong>
                          </template>


                        </v-switch>


                      </v-col>
                      <!-- fn Documentos:-->


                      <!-- fn fila 1 ------------------------------>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card>
            </v-container>

          </v-list>
        </v-card>
      </v-dialog>
      <!-- fn Modal New registro----------------------------------------------------------------------------------------------->


      <!-- ------------------------------------------------------ Modal PassOlvidada -->

      <v-dialog v-model="dialogPassOlvidada" persistent fullscreen hide-overlay transition="dialog-bottom-transition">

        <v-card>
          <v-toolbar dark color="grey darken-2">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <v-toolbar-title v-on="on">
                  Recuperación de Contraseña
                </v-toolbar-title>

              </template>
              <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                <span> Recuperación: </span> Recuperar contraseña olvidada</i>

            </v-tooltip>

            <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

            <v-spacer></v-spacer>

            <v-card-actions>

              <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width-70"
                color="transparent">

              </v-sheet>

            </v-card-actions>

            <v-btn color="grey darken-4" fab small @click="cerrarPassOlvidada">
              <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn>
          </v-toolbar>

          <v-list three-line subheader>

            <v-container>

              <v-card color="grey lighten-3">
                <v-toolbar flat dense color="grey darken-2" style="border: 1px solid #000000;">


                  <v-avatar tile size="45">

                    <i style="font-size: 30px; color:#FFFFFF;" class="fa fa-user-secret"></i>

                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px; color:#FFFFFF;"> <b>Contraseña
                      Olvidada</b></span>

                </v-toolbar>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="formOlvidada" v-model="valid" lazy-validation>
                    <input type="number" v-model="passWord.codigo" style="display:none;" />
                    <v-row>
                      <!-- fila 1 ------------------------------>
                      <v-col cols="12" md="12" sm="12">


                        <!-- -----------------------------  email -->
                        <v-text-field label="E-mail" dense outlined v-model="passWord.correo"
                          :rules="[$rules.required , $rules.email]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                          </template>
                        </v-text-field>

                        <v-text-field label="Palabra secreta:" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="passWord.palabrasecreta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-secret"></i>
                          </template>
                        </v-text-field>

                        <v-card class="mx-auto" max-width="500" outlined style="border: 1px solid #000000;"
                          v-if="usuario.codigo>0">
                          <v-toolbar flat color="grey darken-2" dense style="border: 1px solid #000000;"
                            class="d-flex justify-center">
                            <i style="font-size: 30px; color:#FFFFFF;" class="fa fa-info-circle"> </i>
                            <span class="font-weight-light" style="font-size: 20px; color:#FFFFFF;"> <b>
                                Información</b></span>
                          </v-toolbar>

                          <v-list-item three-line>
                            <v-list-item-content>
                              <v-list-item-title class="overline  mb-1"><b style="font-size: 20px;">{{ usuario.nombre
                              }}</b></v-list-item-title>
                              <v-list-item-subtitle><b>Usuario: </b> {{ usuario.usuario }}</v-list-item-subtitle>
                              <v-list-item-subtitle><b>Clave: </b> {{ usuario.clave }}</v-list-item-subtitle>
                              <v-list-item-subtitle><b>Correo: </b> {{ usuario.correo }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar color="grey" size="68">
                              <v-avatar size="68" color="grey">
                                <span class="white--text headline">{{ getletra(usuario.nombre) }}</span>

                              </v-avatar>


                            </v-list-item-avatar>
                          </v-list-item>
                        </v-card>


                      </v-col>
                      <!-- fn Documentos:-->


                      <hr>

                      <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;"
                        class="d-flex justify-center">


                        <v-btn-toggle dense rounded>

                          <v-btn color="#3c3b3a" :loading="loading" :disabled="loading"
                            @click.prevent="validarPassOlvidada">
                            <i style="font-size: 28px; color:#FFFFFF; " class="fa fa-thumbs-up"> </i>

                            <span style="color:#FFFFFF;"> verificar </span>

                            <template v-slot:loader>
                              <span class="custom-loader">
                                <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                                <!-- <v-icon light>cached</v-icon> -->
                              </span>
                            </template>

                          </v-btn>
                        </v-btn-toggle>


                      </v-toolbar>

                      <!-- fn fila 1 ------------------------------>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card>
            </v-container>


          </v-list>
        </v-card>
      </v-dialog>
      <!-- fn Modal PassOlvidada----------------------------------------------------------------------------------------------->


      <v-dialog v-model="loading" hide-overlay persistent width="300">
        <v-card color="grey darken-2" dark>
          <v-card-text>
            Validando Sesion...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
        :timeout="120000" top="top">
        {{ aalert.nombre }}
        <v-btn dark text @click="aalert.estado = false">Close</v-btn>
      </v-snackbar>


      <!-- fn modales -->


    </div>
    <!-- <v-sheet id="footInfo" style="margin-top:35px;" height="150px"> -->
    <v-sheet v-if="footerInfdeca" id="footInfo" height="100px">

      <v-container class="py-0 my-0">
        <v-row no-gutters>
          <v-col cols="6" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
            <v-list-item-content class="pa-0 ma-0 py-0 my-0">
              <span id="labText-min" style="color:#FFFFFF;"> DECASYSTEMS, S.R.L.</span>
              <span id="labText-min" style="color:#FFFFFF;">República Dominicana</span>
              <span id="labText-min" style="color:#FFFFFF;">Cerros de Gurabo II, Calle 4, #12, Santiago.</span>
              <span id="labText-min" style="color:#FFFFFF;">Teléfono: 809-247-4811</span>
            </v-list-item-content>
          </v-col>
          <v-col cols="6" md="6" sm="6" class="d-flex justify-end pa-0 ma-0 py-0 my-0">
            <!-- imgajust -->
            <v-avatar class="ma-2" :size="60">
              <img id="img-ajustada" src="../assets/Google_Mail.png" alt="Gmail">
            </v-avatar>

            <v-avatar class="ma-2" :size="60">
              <img id="img-ajustada" src="../assets/Facebook.png" alt="Face book">
            </v-avatar>

          </v-col>

        </v-row>
      </v-container>
      <!-- <span style="color:#FFFFFF;" >© copyright. Todos los derechos reservados. DECASYSTEMS.</span> -->

    </v-sheet>


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import VueAutonumeric from "../components/AutoNumericNuevo";
import { mapGetters, mapMutations } from 'vuex';
import ComponentNewConfigGeneral from "@/Formularios/ConfigGeneral.vue";
import SesionAdmin from "@/Formularios/SesionAdmin.vue";
import { VueEasyJwt } from "vue-easy-jwt";
const JWT = new VueEasyJwt();

export default {

  components: {
    VueAutonumeric,
    ComponentNewConfigGeneral,
    SesionAdmin

  },

  created() {

    sessionStorage.setItem("Usuario", JSON.stringify(null));


    var json = {
      titulo: "Login",
      descripcion: "Login",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "LoginNew",
        "LoginSave",
        "LoginRemove",
        "LoginAnular",
        "LoginPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);


  },

  mounted() {
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("LoginNew", this.new);
    // EventBus.$on("LoginSave", this.save);
    // EventBus.$on("LoginRemove", this.remove);
    // EventBus.$on("LoginAnular", this.remove);
    // EventBus.$on("LoginPrintList", this.imprimirList);
    // EventBus.$on("emitLogin", this.datostabla);
    var objjct = {
      login: true,
      pv: false,
      mt: false

    }

    EventBus.$emit("GetLogin", objjct);
    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({

    dialogSessionAdmin:false,
    footerInfdeca: true,
    //sizeOnrefom: 170,
    sizeOnrefom: 110,

    Valid: true,
    ValidEmail: false,
    ValidIdUser: false,
    dialogPassOlvidada: false,
    ContraseñaConfirmacion: "",

    dialogNewRegistro: false,
    snackbarFooter: true,
    Rpropietario: [],
    RCaja: [],
    Rproducto: [],
    RCuenta: [],
    ListaTiposClientes: [],

    windowSize: { width: "auto", height: "auto" },

    imgajust: 80,

    loading: false,


    registro: {
      codigo: 0,
      nombre: '',
      usuario: '',
      clave: '',
      consulta: false,
      correo: '',
      palabrasecreta: ''
    },


    passWord: { correo: "", palabrasecreta: null },


    usuario: {

      codigo: 0,
      clave: "",
      consulta: false,
      correo: "",
      nombre: "",
      usuario: ""
    },


    Login: {
      username: null,
      password: null
    },

    ejecucion: true,
    text: "",
    valid: true,

    dialogNewConfigGeneral:false,




    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),

  methods: {


    getletra(e) {


      var letra
      if (e.length > 0) {

        letra = e.substr(0, 2)

      } else {

        letra = " "
      }

      return letra;

    },


    cerrarPassOlvidada() {

      this.dialogPassOlvidada = false

      this.passWord = { correo: "", palabrasecreta: "" }

      this.usuario = {
        codigo: 0,
        clave: "",
        consulta: false,
        correo: "",
        nombre: "",
        usuario: ""
      }

    },


    validarPassOlvidada() {

      if (this.$refs.formOlvidada.validate()) {


        this.$axios.post(this.$hostname + this.$hName + '/services/reportes/OlvidoContr',
          JSON.parse(JSON.stringify(this.passWord)))
          .then(res => {
            if (res.data.usuario != null) {
              this.usuario = res.data.usuario
            }
            this.alerta(res.data.mensage, res.data.estado)  /*, */
          })
          .catch(error => this.alerta(error, 'error'))
      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }


    },


    validarIduser() {
      this.$axios.post(this.$hostname + this.$hName + '/services/reportes/ValidarIdUser',
        JSON.parse(JSON.stringify(this.registro)))
        //  .then(res => this.alerta(res.data.mensage,res.data.estado))
        .then(res => {
          this.ValidIdUser = res.data.estado
        })
        .catch(error => this.alerta(error, 'error'))


    },

    validarEmail() {

      this.$axios.post(this.$hostname + this.$hName + '/services/reportes/ValidarEmail',
        JSON.parse(JSON.stringify(this.registro)))
        //  .then(res => this.alerta(res.data.mensage,res.data.estado))
        .then(res => {
          this.ValidEmail = res.data.estado
        })
        .catch(error => this.alerta(error, 'error'))

    },


    close() {


    },

    onResize(e) {
      this.windowSize = e
    },


    cerrarRegistro() {

      this.dialogNewRegistro = false

    },


    NewRegistro() {

      this.registro = {
        codigo: 0,
        nombre: '',
        usuario: '',
        clave: '',
        consulta: false,
        correo: '',
      },


        this.ejecucion = true


    },
    saveRegistro() {


      if (this.ValidIdUser == true) {
        this.alerta("El usuario ya existe agregado", "error");
        return
      }

      if (this.ValidEmail == true) {
        this.alerta("El Correo ya existe agregado", "error");
        return
      }


      if (this.registro.clave != this.ContraseñaConfirmacion) {

        this.alerta("la clave de confirmación no coincide", "error");
        return
      }


      if (this.$refs.formRegistro.validate()) {
        if (this.ejecucion == true) {

          this.ejecucion = false

          var jsn = {
            datos: JSON.parse(JSON.stringify(this.registro)),
          }
          //http://:8080/ProyectWs/services/reportes/findallUsuario
          this.$axios.post(this.$hostname + this.$hName + '/services/reportes/saveOrUpdate',
            JSON.parse(JSON.stringify(jsn)))
            //  .then(res => this.alerta(res.data.mensage,res.data.estado))
            .then(res => this.alerta("Dato Guardado correctamente", "bien"))
            .catch(error => this.alerta(error, 'error'))
        }
      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }
    },


    inicioSesion() {
      if (this.$refs.formLogin.validate()) {
        this.loading = true
        setTimeout(this.validarSesion, 2000);
      }
    },

    validarSesion() {
      const data = { usuario: this.Login.username, clave: this.Login.password }
      this.$axios.post(this.$hostname + this.$hName + `/login/auth`, data, { headers: data }).then(res => {
        this.IniciadoSeccion(res.data)
      })
        .catch(error => {
          this.loading = false
          if (error.response) {
            // Request made and server responded
            //   console.log('ECONNREFUSED')
            // console.log(error.response.request.responseText.includes('ECONNREFUSED'))
            if (error.response.request.responseText.includes('ECONNREFUSED')) {
              this.alerta('Servidor no esta disponible.', 'error')
            } else {
              this.alerta(error.response.request.responseText, 'error')
            }

            this.loading = false
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.request) {
            this.loading = false
            console.log("catchrequest");
            // The request was made but no response was received
            console.log(error.request);
          } else {
            this.loading = false
            console.log("ElsecatchTodo");
            console.log(error)
            // Something happened in setting up the request that triggered an Error
            console.log('Error'+ error.message);
          }

        })

    },

    IniciadoSeccion(e) {
      //console.log(e)
      //jwt.decode(token.encode('UTF-8'), SECRET_KEY)

/*var obj={
  nombre:'JéanCáarlos'
}
var strin='eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkrDqWhuIETDqWUiLCJpYXQiOjE1MTYyMzkwMjJ9.0BGsxaL8UWwV3ACQ2KCxX0hd5d0Dn1LbT4JRdoolgWs'
console.log(strin)*/

/*console.log('Vienendo INformcacion encode')
console.log(JWT.decodeToken(strin))*/

/*
>>> key = "secret"
>>> encoded = jwt.encode({"some": "payload"}, key, algorithm="HS256")
>>> print(encoded)
eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzb21lIjoicGF5bG9hZCJ9.4twFt5NiznN84AWoo1d7KO1T_yoc0Z6XOpOVswacPZg
>>> jwt.decode(encoded, key, algorithms="HS256")*/

      const TOKEN = JWT.decodeToken(e.token);
      const TENANT_ID = TOKEN.tenant;
      //---Ojo:Creando Variables vuex y session usuario
//console.log(TOKEN)
      this.$store.commit('MutationHeadersAxios', e.token, TOKEN);
      this.$store.commit('MutationHeadersTenant', TENANT_ID);
      sessionStorage.setItem("AuthorizationToken", JSON.stringify(e.token));

     /* this.loading = false
      this.$router.push({ path: '/Principal' })
      this.$store.commit('MutationbtnAppBar', true);*/

      var usss = {
        imagen: '../assets/img-deca/entrenamientoB.png',
        nombre: 'Jean Carlos',
        correo: 'Jean@gmail.com',
        user: 'Jean_22',
        ...TOKEN
      }

      var TokeUsuario=JSON.parse(JSON.stringify(TOKEN))
      var nombreUse=TokeUsuario.usuario.nombre
      
      if(nombreUse!=null && nombreUse.length>0){
      nombreUse=nombreUse.replaceAll('Ã¡', 'á')
      nombreUse=nombreUse.replaceAll('Ã©', 'é')
      nombreUse=nombreUse.replaceAll('Ã­', 'í')
      nombreUse=nombreUse.replaceAll('Ã³', 'ó')
      nombreUse=nombreUse.replaceAll('Ãº', 'ú')
      // console.log('elreal Toke')
      // console.log(TOKEN.usuario.sudo)
       TokeUsuario.usuario.nombre=nombreUse
}
      sessionStorage.setItem("Usuario", JSON.stringify(TokeUsuario));
      this.$store.commit('MutationaddUser', TokeUsuario);
      sessionStorage.setItem("ComprobanteAgotado", JSON.stringify(true));
      //Dia de pago
      var diaPago=new Date().getDate()
      if(diaPago>=1 && diaPago<=10 ){
        sessionStorage.setItem("mostrarDiaPago", JSON.stringify(true));
      }else{
        sessionStorage.setItem("mostrarDiaPago", JSON.stringify(false));
      }
  
      this.$axios.get(this.$hostname + this.$hName + '/configuraciones/find',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          sessionStorage.setItem("ConfiguracionGeneral", JSON.stringify(res.data));
          this.$store.commit('MutationaddConfiguracionGeneral', res.data);

          // var ConfOtros = JSON.parse(sessionStorage.getItem("OtrasConfigPv"))
          // if (ConfOtros != null) {
          //   this.$store.commit('MutationDataOtrasConfigPv', ConfOtros);
          // }else{}

          var cj = null
          if (localStorage.hasOwnProperty('caja')) {
            var c = JSON.parse(localStorage.caja);
            cj = c.descripcion.trim()
          }

          var impre = null
          if (localStorage.hasOwnProperty('printerSelect')) {
            var impre = JSON.parse(localStorage.printerSelect)
          }

          var obj = {
            caja: cj,
            cajero: TOKEN.usuario.nombre!=null ? TOKEN.usuario.nombre : TOKEN.usuario.username,
            moneda: res.data.config.monedabase.descripcion.trim(),
            impresora: impre,
            terminal: null,
            turno: null
          }
          //this.$store.commit("MutationDataOtrasConfigPv", this.OtrasConfigPv);
          sessionStorage.setItem("OtrasConfigPv",JSON.stringify(obj));
          this.$store.commit('MutationDataOtrasConfigPv', obj);

          if (TOKEN.usuario.sudo == true) {

this.loading = false
this.$router.push({ path: '/Principal' })
this.$store.commit('MutationbtnAppBar', true);

  var rouDef = this.$store.getters.GetDatosItemsRouterDefour
  sessionStorage.setItem("itemsRouter", JSON.stringify(rouDef));
  this.$store.commit('MutationDataRouter', rouDef);
} else {

  this.CrearRouter(TOKEN)

}

        })

      


    },
    CrearRouter(e) { 

     this.$axios.post(this.$hostname + this.$hName + "/usuario/menuDisponible",
              e.usuario,
              { headers: this.$store.getters.GetheadersAxios })
            .then((res) => {
              this.loading = false
             this.$router.push({ path: '/Principal' })
              this.$store.commit('MutationbtnAppBar', true);
              this.CrearRouterContinuar(res.data)
            
            })
            .catch((error) =>{this.alerta("Dato no guardado correctamente", "error")});

     
    },

    CrearRouterContinuar(eo) {
      var rou = this.$store.getters.GetDatosItemsRouterDefour
      var CrearRow = []
    eo.forEach(ele => {
       var objModulo = rou.filter(e => e.codigo == ele.modulo.codigo)
        var ObjCrearRow = CrearRow.filter(e => e.codigo == ele.modulo.codigo)

        if (ObjCrearRow.length == 0) {

            var objsub = JSON.parse(JSON.stringify(objModulo[0]))
            objsub.items = []

            if(ele.nombre.includes("Reportes")){
              var subMen = objModulo[0].items.filter(e => e.codigo == 1000)[0]
              objsub.items.push(subMen)
            }else{
              var subMen = objModulo[0].items.filter(e => e.codigo == ele.codigo)[0]
              objsub.items.push(subMen)
            }
            CrearRow.push(objsub)           
         }

         if (ObjCrearRow.length > 0) {
          var objsub = JSON.parse(JSON.stringify(objModulo[0]))
          if(ele.nombre.includes("Reportes")){ 
            var subMen = objModulo[0].items.filter(e => e.codigo == 1000)[0] 
            CrearRow.filter(e => e.codigo == ele.modulo.codigo)[0].items.push(subMen)
          }else{
            var subMen = objModulo[0].items.filter(e => e.codigo == ele.codigo)[0]
            CrearRow.filter(e => e.codigo == ele.modulo.codigo)[0].items.push(subMen)
          }


          CrearRow.filter(ee => ee.codigo == ele.modulo.codigo)[0].items.sort(function (a, b) {
                if (a.orden > b.orden) { return 1; }
                if (a.orden < b.orden) { return -1; }
                return 0;
              });
             
          }
          
    });

    
    CrearRow.sort(function (a, b) {
        if (a.orden > b.orden) { return 1; }
        if (a.orden < b.orden) { return -1; }
        return 0;
      });
      sessionStorage.setItem("itemsRouter", JSON.stringify(CrearRow));
      this.$store.commit('MutationDataRouter', CrearRow);

    },

    CrearRouter1(e) {
      var rou = this.$store.getters.GetDatosItemsRouterDefour
      var CrearRow = []

      e.usuario.menuDisponible.forEach(ele => {
        rou.forEach(element => {
          var ObjCrearRow = element.items.filter(ee => ee.codigo == ele.codigo)
          if (ObjCrearRow.length > 0) {
            var ObjMenu = CrearRow.filter(ee => ee.codigo == element.codigo)
            if (ObjMenu.length > 0) {
              CrearRow.filter(ee => ee.codigo == element.codigo)[0].items.push(ObjCrearRow[0])
              CrearRow.filter(ee => ee.codigo == element.codigo)[0].items.sort(function (a, b) {
                if (a.orden > b.orden) { return 1; }
                if (a.orden < b.orden) { return -1; }
                return 0;
              });

            } else {
              var objModulo = JSON.parse(JSON.stringify(element))
              objModulo.items = JSON.parse(JSON.stringify(ObjCrearRow))
              CrearRow.push(objModulo)
            }
          }

        });
      });

      CrearRow.sort(function (a, b) {
        if (a.orden > b.orden) { return 1; }
        if (a.orden < b.orden) { return -1; }
        return 0;
      });
      sessionStorage.setItem("itemsRouter", JSON.stringify(CrearRow));
      this.$store.commit('MutationDataRouter', CrearRow);

    },


    CrearRouter2(e) {
      var rou = this.$store.getters.GetDatosItemsRouterDefour
      var CrearRow = []

      e.usuario.menuDisponible.forEach(ele => {

        var obj = rou.filter(e => e.codigo == ele.modulo.codigo)
        var ObjCrearRow = CrearRow.filter(e => e.codigo == ele.modulo.codigo)

        if (ObjCrearRow.length == 0) {
          var objsub = JSON.parse(JSON.stringify(obj[0]))
          var subMen = objsub.items.filter(e => e.codigo == ele.codigo)[0]
          objsub.items = []
          objsub.items.push(subMen)
          CrearRow.push(objsub)
        }

        if (ObjCrearRow.length > 0) {
          var objsub = JSON.parse(JSON.stringify(obj[0]))
          var subMen = objsub.items.filter(e => e.codigo == ele.codigo)[0]
          ObjCrearRow[0].items.push(subMen)
          ObjCrearRow[0].items.sort(function (a, b) {
            if (a.orden > b.orden) {
              return 1;
            }
            if (a.orden < b.orden) {
              return -1;
            }
            return 0;
          });


        }

      });


      CrearRow.sort(function (a, b) {
        if (a.orden > b.orden) {
          return 1;
        }
        if (a.orden < b.orden) {
          return -1;
        }
        return 0;
      });

      sessionStorage.setItem("itemsRouter", JSON.stringify(CrearRow));
      this.$store.commit('MutationDataRouter', CrearRow);

    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.NewRegistro();
        //this.new();

      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    }
    ,


    getImgUrl() {
      var images = require("@/assets/img/Nofoto.png");
      return images;
    }
    ,


    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) {
        number = 0
      }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    }
    ,


  },


  computed: {


    GetLogin() {

      this.$store.commit('MutationbtnAppBar', false);
      this.$store.commit('MutationActivarBarraAccion', false);
      if (this.windowSize.width < 600) {
        this.imgajust = 30

      } else {
        this.imgajust = 80
      }

      if (this.windowSize.height < 350) {

        this.sizeOnrefom = 0
        this.footerInfdeca = false

      } else {

        this.sizeOnrefom = 110
        this.footerInfdeca = true
      }


    }

  }


}
</script>

<style lang="scss">
#footInfo {
  // position: relative;
  /* bottom: 0; */
  //margin: 0  0 0 0;
  // clear: both;
  /* display: table-footer-group; */
  // width: 100%;
  /* top: 275%; */
  background: #1C2C35 url(../assets/147.png);
  // display: block;
  /* padding: 2em; */
  /* height: 3em; */
  //display: inline-table;
}


.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}


@media screen and (min-width: 1058px) {
  #bottomteclado {
    min-width: 160px;
  }

}


@media screen and (max-width: 1058px) {
  #bottomteclado {
    min-width: 100px;
  }

}
</style>