<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
<!-- ContenidoBase-Recibo de Otros Ingreso -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Recibo de Otros Ingreso'"
          :descripcion="'Realizar Recibo de Otros Ingreso de efectivo a la Empresa'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="anular" @MethodImprimir="imprimirList" @MethodVeercuenta="vercuenta" />
      </tr>
<!-- fn ContenidoBase-Recibo de Otros Ingreso -->
      <tr>
        <!-- BarraPrimaria-Recibo de Otros Ingreso --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <!-- <v-btn-toggle  ></v-btn-toggle> -->
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>


                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>


                <BarraDinamicaLimitePlus :PropsNombre="'Recibo Otros Ingreso'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'secuencia2' },
                    { text: 'cliente', value: 'nombre' },
                    { text: 'valor', value: 'valor' },
                    { text: 'concepto', value: 'concepto' },
                    { text: 'referencia', value: 'referencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'secuencia2', Ctsl: 1, Type: 0, State: true },
  { Text: 'Nombre', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
  { Text: 'Valor $', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
  { Text: 'Fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
  { Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.2, State: true },
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName +'/RecibosOtrosIngreso/findAllReciboOtroIngreso'"
              :nombre="'Recibo Otros Ingreso'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitCxCReciboIngreso'"
              :SeachNombre="'Búsqueda por el nombre del cliente'"
              :slotlist="slotlist" 
              :urlcount="'/RecibosOtrosIngreso/totalRecibo'"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Recibo de Otros Ingreso ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
<!-- Formulario-Recibo de Otros Ingreso -->
            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>

                      <input type="number" v-model="form.OtrosIngresos.secuencia" style="display:none;" />


                      <!-- -----------------------------  columna-1 -->
                      <v-col cols="6" md="6">

                        <!---------------------------------------------- documento @change="getCrearCont"-->
                        <v-form ref="formDoc" v-model="valid" lazy-validation>
                          <v-combobox v-model="form.OtrosIngresos.documento" autocomplete="off" dense outlined
                            label="Documentos:" :items="RDocumento" item-text="descripcion" :rules="[$rules.required]"
                            @blur="SelctCbDocumento" @change="getCrearCont" @focus="cargarDocumento">
                            <template v-slot:prepend>
                              <i style="font-size: 30px;" class="fa fa-book"></i>
                            </template>
                          </v-combobox>
                        </v-form>

                        <!-------------------------------------------------- fecha -->
                        <v-text-field dense outlined type="date" label="Fecha" v-model="form.OtrosIngresos.fecha"
                          :rules="[$rules.required]" :disabled="disableAll">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <!-------------------------------------------------- Referencia -->
                        <v-text-field label="No.Referencia" dense outlined v-model="form.OtrosIngresos.referencia"
                          :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                          </template>
                        </v-text-field>
                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Concepto"  rows="4" dense outlined
                          v-model="form.OtrosIngresos.concepto" :disabled="disableAll">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

<v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
:rules="[$rules.required]" v-model="form.OtrosIngresos.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
@change="ChangeMonedaTasa">

<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-money"></i>
</template>

<template v-slot:selection="{ attrs, item, parent, selected }">
<span id="ImputColorDisable">
{{ item.descripcion }} ({{ item.nombrecorto }})
</span>

</template>


<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
</p>
</v-col>
</template>
</v-combobox>
</div>

                      </v-col>
                      <!-- ----------------------------- fn columna-1 -->

                      <!-- -----------------------------  columna-2 -->
                      <v-col cols="6" md="6">


                        <!-------------------------------------------------- Valor @input="sumaValor"   -->
                        <v-form ref="formValor" v-model="valid" lazy-validation>

                          <VueAutonumeric label="Valor:" dense outlined autocomplete="off"
                            v-model.number="form.OtrosIngresos.valor" :rules="[$rules.required]" @blur="getCrearCont">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>
                        </v-form>

                        <!-------------------------------------------------- Nombre  :rules="[rules.required]"-->

                        <v-combobox ref="RefCombClient" autocomplete="off" dense outlined label="Nombre:"
                          :items="RCliente" item-text="codnom" v-model="form.OtrosIngresos.cliente"
                          @blur="SelctCbCliente" @change="ClienteSelect" @focus="cargarCliente" @keyup="CbFilcliente"
                          :disabled="disableAll" no-filter>
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('codigo')" ><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                    <span class="fontSize13 colorNombre" v-if="item!=null && !item.hasOwnProperty('codigo')" >{{item}}</span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>




                        <!-- <v-text-field
            label="Nombre*:"
            dense
            outlined
            :rules="[rules.required]"
            v-model="form.OtrosIngresos.nombre"
            autocomplete="off"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-font"></i>
            </template>
          </v-text-field> 
          
          -->

                        <!-------------------------------------------------- cedula :rules="[rules.required, rules.RulesCel]" -->
                        <v-text-field id="formdisabledInput" autocomplete="off" v-mask="MakCelRnc" label="* Cedula/RNC:"
                          dense outlined maxlength="13" :rules="[$rules.RulesCelRncValidar]"
                          v-model="form.OtrosIngresos.cedula" @blur="MakaraCel" @focus="MakCelRnc = '#############'"
                          :disabled="form.OtrosIngresos.cliente != null && form.OtrosIngresos.cliente.hasOwnProperty('codigo')">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- telefono :rules="[rules.required, rules.RulesTel]" -->
                        <v-text-field id="formdisabledInput" label="* teléfono:" dense outlined autocomplete="off"
                          maxlength="13" v-mask="'(###)###-####'" v-model="form.OtrosIngresos.telefono"
                          :disabled="form.OtrosIngresos.cliente != null && form.OtrosIngresos.cliente.hasOwnProperty('codigo')">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>




                        <!------------------------------------------- caja-->

                        <v-combobox v-model="form.OtrosIngresos.caja" label="caja" required outlined dense
                          autocomplete="off" :items="RCaja" item-text="descripcion" @blur="SelctCbCaja"
                          @focus="cargarCaja">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-cube"></i>
                          </template>
                        </v-combobox>
                    
<div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">
<VueAutonumeric v-if="form.OtrosIngresos.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
v-model.number="form.OtrosIngresos.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
</VueAutonumeric>
                        </div>



                      </v-col>




                      <!-- ----------------------------- fn columna-2 -->
                    </v-row>
                  </v-form>




                </div>
              </v-card>





            </div>
<!-- fn Formulario-Recibo de Otros Ingreso -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-if="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <v-list>

              <!-- <v-btn 
                  color="teal darken-2"
                  dark
                  text 
                  small
                  @click.prevent="abrirTabla2"
                  >
                  <i style="font-size: 25px;" class="fa fa-search"> </i>
                Filtrar
      </v-btn>  -->


              <v-btn id="boton-mini" color="red darken-3" dark small text width="100%" @click.prevent="abrirTabla2">
                Cerrar

                <i style="font-size: 25px;" class="fa fa-close"> </i>

              </v-btn>



              <ClienteInformacion :Obje="ObjCliente" />



            </v-list>
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.factura" @keyup.enter="CargarListTb" label="Búsqueda por código"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'secuencia2', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Nombre', value: 'nombre', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cédula', value: 'cedula', width: 150, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento:', value: 'documento.descripcion', width: 180, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Concepto', value: 'concepto', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Referencia', value: 'referencia', width: 120, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor', width: 120, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'fecha', width: 200, sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">

              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>


              <template v-slot:item.valor="props">
                <span>${{ currencyFormatter(props.item.valor) }}</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn  tb-data todo general -->

    <!-------------------------------------------------------- Modal-agregar cuenta -->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="90%" max-height="80%">
      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-agregar cuenta -->





    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general  -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal-Reporte Basico -->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Reporte Basico -->


    <!-- ------------------------------------------------------ Modal-Filtro tb-datos -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->
              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoModal" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="6" md="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="6" md="6">



              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle dense rounded class="">


            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Filtro tb-datos -->






    <!-- fn Modales ----------------------------------------------------------------------------------------------->




  </div>

</template>

<script>
import numeral from 'numeral'
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ClienteInformacion from "../../components/ClienteInformacion";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import CuentasContables from "../../components/CuentasContables";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
export default {
  inject: ['theme'],

  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ClienteInformacion,
    CuentasContables,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteBasico

  },
  //mounted-carga de inicio
  mounted() {
    EventBus.$on("emitCxCReciboIngreso", this.datostabla);

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CXCReciboIngresoNew", this.new);
    // EventBus.$on("CXCReciboIngresoSave", this.save);
    // EventBus.$on("CXCReciboIngresoRemove", this.remove);
    // EventBus.$on("CXCReciboIngresoAnular", this.anular);
    // EventBus.$on("CXCReciboIngresoPrintList", this.imprimirList);
    // EventBus.$on("CXCReciboIngresoVercuenta", this.vercuenta);

    this.cargarCaja()
    this.cargarCliente()

    EventBus.$on("onResize", this.onResize);
    //notaError: probar el save : la secuencia da error. por que no hay registro en la bse

    this.getMonedaBase()
  },
  //mounted-carga de inicio
  created() {
    var json = {
      titulo: "Recibo de Otros Ingreso",
      descripcion: "Realizar Recibo de Otros Ingreso de efectivo a la Emplresa",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: true,
      accion: [
        "CXCReciboIngresoNew",
        "CXCReciboIngresoSave",
        "CXCReciboIngresoRemove",
        "CXCReciboIngresoAnular",
        "CXCReciboIngresoPrintList",
        "CXCReciboIngresoVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      MakCelRnc: "#############",
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'OTROS INGRESOS',
        UrlImprimir: '/otrosingresos/print',
        UrlExel: '/otrosingresos/excel',
        UrlCorreo: '/otrosingresos/correo',
        secuencia: 0,
      },



      disabledDocumento: false,
      disableAll: false,
      btnAgCuenta: false,
      disabledDocumento: false,
      acti_Modal_cuenta: false,
      valid: true,
      objCliAccion: false,
      tbFacCargar: false,


      dialogFiltros: false,

      Modallfiltro: {
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null

      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },


      objeTem: null,
      TotalCuenta: {
        credito: 0,
        debito: 0

      },
      Rmoneda:[],
      RDocumento: [],
      RCliente: [],
      RLisCuenta: [],
      RCaja: [],

      ObjCliente: null,
      contabilidad: null,

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      form: {

        caja: null,
        codigo: 0,

        cliente: null,

        OtrosIngresos: {
          secuencia: 0,
          secuencia2: 0,
          caja: null,
          documento: null,
          nombre: null,
          cedula: '',
          telefono: null,
          referencia: null,
          concepto: null,
          fecha: fechaNueva(),
          valor: 0,
          moneda:null,
          tasa:null

        },


        RLisCuenta: [],
        diasCredito: 0,


        TotalCuenta: {
          credito: 0,
          debito: 0

        },



        Total: {
          valor: 0,
          pendiente: 0,
          ncr: 0,
          itbis: 0,
          balanceTotal: 0,
        }

      },



      Colss: {
        p1: 12,
      },





      headers: [{ text: "Nombre", value: "nombre" }],

      slotlist: [

        { dt0: "secuencia2", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "$" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        { dt0: "concepto", dt1: null, dt2: null, dt3: 1, dt4: "Concepto:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Anulada" },
        // eerroor { dt0: "moneda", dt1: "nombrecorto", dt2: null, dt3: 1, dt4: "Moneda:" },

      ],

      list: [],
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 10,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },



      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea Anular este registro?"
      },


      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,

    onResize(e) {
      //this.windowSize=e

    },


   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCliente = res.data))

    },

    SelctCbCliente() {


      /*  if (this.form.OtrosIngresos.cliente == null) {
          this.form.OtrosIngresos.cliente = "";
        }
        if (this.form.OtrosIngresos.cliente.codigo == undefined) {
          this.form.OtrosIngresos.cliente = "";
          this.alerta("Seleccione un cliente", "error");
          return;
        }*/

      /*  if (this.form.cliente == null) {
  
          this.form.cliente = ''
        }
  
        if (this.form.cliente.codigo == undefined) {
  
          this.alerta("Seleccione un cliente", "error");
          this.form.cliente = ''
          this.form.OtrosIngresos.cedula = null
          this.form.OtrosIngresos.nombre = null
          this.form.OtrosIngresos.telefono = null
          return
        }*/

    },

   // Una función que se llama cuando el usuario ingresa un valor en el campo de entrada.
    MakaraCel() {
      // .toString()toString()
      if (this.form.OtrosIngresos.cedula.trim().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }
      if (this.form.OtrosIngresos.cedula.trim().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }



    },

    // Una función que se llama cuando un usuario selecciona un cliente de una lista desplegable.
    ClienteSelect(e) {
      if (e != null && e.hasOwnProperty("codigo")) {
        this.ObjCliente = e
        this.BarraSecundariaAprir = true
        if (e.hasOwnProperty('cedrnc')) { this.form.OtrosIngresos.cedula = e.cedrnc.trim() }
        if (e.hasOwnProperty('nombre')) { this.form.OtrosIngresos.nombre = e.nombre.trim() }
        if (e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList.length > 0) {
          this.form.OtrosIngresos.telefono = e.telefonosClientesList[0].telefonosClientesPK.telefono
        }

        setTimeout(this.MakaraCel, 200);

        // if (e.hasOwnProperty('telefono')) {  }



      } else {
        this.form.OtrosIngresos.cedula = null
        this.form.OtrosIngresos.nombre = null
        this.form.OtrosIngresos.telefono = null
        this.BarraSecundariaAprir = false

      }

      /*   if (e == null || e == "") {
           this.form.OtrosIngresos.cedula = null
           this.form.OtrosIngresos.nombre = null
           this.form.OtrosIngresos.telefono = null
           this.BarraSecundariaAprir = false
   
         } else {
   
           // qlq
           this.ObjCliente = e
           this.BarraSecundariaAprir = true
   
           if (e.hasOwnProperty('cedrnc')) {
   
             this.form.OtrosIngresos.cedula = e.cedrnc
           }
   
   
           if (e.hasOwnProperty('nombre')) {
   
             this.form.OtrosIngresos.nombre = e.nombre
           }
   
   
           if (e.hasOwnProperty('telefono')) {
   
             this.form.OtrosIngresos.telefono = e.telefonoPrincipal
   
           }
   
         }*/

    },

    CbFilcliente(e) {


      if (e.target.value.length >= 0) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value) +
            "/3/25/0", { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.RCliente = res.data));
      }

    },





   // Comprobando si el usuario ha seleccionado un valor del menú desplegable. Si no, está mostrando un
   // mensaje de error.
    SelctCbCaja() {

      if (this.form.OtrosIngresos.caja == null) {

        this.form.OtrosIngresos.caja = ''
      }

      if (this.form.OtrosIngresos.caja.codigo == undefined) {

        this.alerta("Seleccione una caja", "error");
        this.form.OtrosIngresos.caja = ''
        return

      }

    },

    // Llamar a la API y obtener los datos de la API.
    cargarCaja() {
      this.$axios.get(this.$hostname + this.$hName + '/cajas/findAllActivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCaja = res.data))

    },



    ActivarSave(json) {
      EventBus.$emit("ActDesaBotones", json);
    },




    //por aquii

    // Realización de una solicitud GET al servidor.
    getCrearCont() {



      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {

      } else {


        if (this.form.OtrosIngresos.secuencia == 0) {
          // console.log('/contabilidad/crear-contabilidad-cxc/'+this.form.OtrosIngresos.documento.codigo+'/'+this.form.OtrosIngresos.valor+'/0/0/0/0/0/0')


          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxc/' + this.form.OtrosIngresos.documento.codigo + '/' + this.form.OtrosIngresos.valor + '/0/0/0/0/'+this.form.OtrosIngresos.valor+'/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });

          //&& this.form.Total.abono!=0 && this.form.Total.descuento!=0 && this.form.Total.itbis!=0 && this.form.Total.isr!=0
          //this.llenarcuenta(res.data)

        }
        if (this.form.OtrosIngresos.secuencia != 0) {
          var obj = {
            datos: JSON.parse(JSON.stringify(this.form)),
            usuario: JSON.parse(JSON.stringify(this.$user))

          }

          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.form.OtrosIngresos.documento.codigo + '/' + this.form.OtrosIngresos.secuencia2 + '/' + this.form.OtrosIngresos.documento.operaciones.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });



        }

      }



    },






   // Tomando los datos del componente secundario y colocándolos en el componente principal.
    CCuentasContables(e, t, b) {

      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          this.form.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.form.TotalCuenta = JSON.parse(JSON.stringify(t))
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          //this.RLisCuenta= this.RLisCuenta
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }









      this.acti_Modal_cuenta = b

      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 
      //this.RLisCuenta= JSON.parse(JSON.stringify(e));
      //this.form.RLisCuenta= this.RLisCuenta
      //this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

    },


   // Creando un nuevo objeto llamado objeTem y asignándole el valor del objeto RLisCuenta.
    vercuenta() {



      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }


      if (this.$refs.formDoc.validate()) {

        if(this.RLisCuenta.length==0){
        this.getCrearCont();
        }
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {

        this.alerta("Los campos en rojo son requeridos", "error");

      }

    },


    cargarPaginacion(e) {

      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },



    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {

      // var p=Math.trunc(number)
      // var pd=number-p

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },


    DiaCre(e) {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.OtrosIngresos.diasCredito = mynumeral(this.form.OtrosIngresos.diasCredito).value()

        if (this.form.OtrosIngresos.diasCredito == null) {

          this.form.OtrosIngresos.diasCredito = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },




   // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentocxc/findallOtrosIngreso', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.form.OtrosIngresos.documento == null) {

        this.form.OtrosIngresos.documento = ''
      }

      if (this.form.OtrosIngresos.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.form.OtrosIngresos.documento = ''
        return

      }

    },




    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
      //  this.abrirTablaSegunda = !this.abrirTablaSegunda;
    },


    // Crear un nuevo objeto y asignarlo a la variable de formulario.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.contabilidad = null
        this.RLisCuenta = []

      this.form = {

        selected: [],
        codigo: 0,
        ncf: "",
        itbis: 0,

        cliente: null,
        caja: null,

        OtrosIngresos: {
          secuencia: 0,
          secuencia2: 0,
          caja: null,
          documento: null,
          nombre: null,
          cedula: '',
          telefono: null,
          referencia: null,
          concepto: null,
          fecha: fechaNueva(),
          valor: 0,
          moneda:null,
          tasa:null

        },


        RLisCuenta: [],
        diasCredito: 0,


        TotalCuenta: {
          credito: 0,
          debito: 0

        },

        Total: {
          valor: 0,
          pendiente: 0,
          ncr: 0,
          itbis: 0,
          balanceTotal: 0,
        }

      },



        this.RLisCuenta = [],
        this.disableAll = false,


        this.modalCuenta = {
          cuenta: null
        },


        this.BarraSecundariaAprir = false

      this.ejecucion = true
      this.Limpiarfiltro()
      EventBus.$emit("loading", false);
      this.getMonedaBase()
      // EventBus.$emit("actualizaBarraBusqueda2")

    },



    // Guardando los datos en la base de datos.
    save() {

      if (this.form.TotalCuenta.credito != this.form.TotalCuenta.debito) {
        this.acti_Modal_cuenta = true
        this.alerta("El total de debito y credito no coincide en el asiento contable", "error");
        return
      }

      //wow aquii
      if (this.$refs.form.validate()) {

        if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
          this.alerta("Los campos en rojo son requeridos", "error");
          return
        }


        if (this.ejecucion == true) {

          if(this.RLisCuenta.length==0){
          this.getCrearCont()
        }

          this.ejecucion = false

          // var obj={
          //         datos:JSON.parse(JSON.stringify(this.form))  ,
          //         usuario:JSON.parse(JSON.stringify(this.$user)),
          //         RLisCuenta:JSON.parse(JSON.stringify(this.RLisCuenta)),  

          //       }
          this.form.OtrosIngresos.contabilidad = this.contabilidad
          if(this.RLisCuenta.length>0){
            this.form.OtrosIngresos.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }


          var obj = JSON.parse(JSON.stringify(this.form.OtrosIngresos))
          obj.nombre = JSON.parse(JSON.stringify(this.$refs.RefCombClient.internalSearch))
          if (obj.cliente != null && !obj.cliente.hasOwnProperty('codigo')) {
            obj.cliente = null
          }

          obj.tasa= JSON.parse(JSON.stringify(this.form.OtrosIngresos.tasa))
          EventBus.$emit("loading", true);
          this.$axios.post(this.$hostname + this.$hName + '/otrosingresos/salvar',
            obj, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato Guardado corretamente", "bien") })
            .catch(error => this.alerta('Datos no guardado correctamente', 'error'))

        }
      } else {

        this.alerta("los campos en rojo son requeridos", "error");

      }





    },

   // Cambiar el estado de la notificación.
    anular() {

      if (this.form.OtrosIngresos.secuencia != 0 && this.form.OtrosIngresos.secuencia != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;

      }

    },

    // Eliminación de un registro de la base de datos.
    eliminar() {
      if (this.form.OtrosIngresos.secuencia != 0 && this.form.OtrosIngresos.secuencia != null) {


        if (this.$refs.form.validate()) {

          var obj = {
            datos: JSON.parse(JSON.stringify(this.form)),
            usuario: JSON.parse(JSON.stringify(this.$user)),
            contabilidad: JSON.parse(JSON.stringify(this.contabilidad))
          }


          this.form.OtrosIngresos.contabilidad = this.contabilidad
          var obj = JSON.parse(JSON.stringify(this.form.OtrosIngresos))
          obj.nombre = JSON.parse(JSON.stringify(this.$refs.RefCombClient.internalSearch))
          if (obj.cliente != null && !obj.cliente.hasOwnProperty('codigo')) {
            obj.cliente = null
          }

          this.$axios.post(this.$hostname + this.$hName + '/otrosingresos/anular', obj,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => this.alerta('Dato anulado correctamente', 'bien'))
            .catch(error => this.alerta('Dato no anulado correctamente', 'error'))
        }
      }


    },

    remove() {

    },
    //Muestra el modal de las opciones de imprecion
    imprimirList() {

      if (this.form.OtrosIngresos.secuencia > 0) {
        this.datosImprecion.secuencia = this.form.OtrosIngresos.secuencia
        this.dialogimprimirList = true
      }


    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },


   // Una función que se llama cuando se hace clic en una fila en una tabla.
    datostabla(e) {
      this.form.OtrosIngresos.cedula = ' '
      this.BarraSecundariaAprir = false
      this.form.cliente = []
      this.contabilidad = null
      this.acti_tb_data = false
      this.contabilidad = null
      this.RLisCuenta = []

      //this.form.recibosingresoDetalleList=JSON.parse(JSON.stringify([]));


      //this.form.codigo=e.secuencia2
      //this.form.documento=e.documentocxc

      var _this = this;
      setTimeout(function () {
        var obj = JSON.parse(JSON.stringify(e));
        obj.fecha = new Date(e.fecha).toJSON().split("T")[0]
        if (e.identificacion != null) {
          obj.cedula = e.identificacion.trim()
          obj.identificacion = null
        }

        console.log(e)
        console.log('----e--')
        console.log(e)
        if (e.cliente == null) {
          obj.cliente = JSON.parse(JSON.stringify(e.nombre.trim()));
        }else{
          obj.cliente.codnom = `${e.cliente.codigo} - ${e.cliente.nombre.trim()} `
        }

        if (obj.cedula.length > 0) {
          if (!obj.cedula.includes('-')) {
            setTimeout(this.MakaraCel, 300);
          } else {

            if (obj.cedula.length == 11) {
              _this.MakCelRnc = "###-#####-#";
            }

            if (obj.cedula.length == 13) {
              _this.MakCelRnc = "###-#######-#";
            }
          }
        }
        _this.form.OtrosIngresos = JSON.parse(JSON.stringify(obj));
        if(e.moneda==null){
          _this.getMonedaBase()
        }   
      }, 300);






      //this.RLisCuenta=JSON.parse(JSON.stringify(e.contabilidad.contabilidadDetalleList));
      //contabilidad.contabilidadDetalleList-pendiete
      //aquiii


      /*   if (e.cliente != null && e.hasOwnProperty('cliente')) {
           this.ClienteSelect(this.form.OtrosIngresos.cliente)
         }*/

      this.Total = {
        valor: 0,
        pendiente: 0,
        ncr: 0,
        itbis: 0,
        balanceTotal: 0,
      }


      this.form.TotalCuenta = {
        credito: 0,
        debito: 0

      }

      this.RCaja.forEach(element => {

        if (e.caja == element.codigo) {

          this.form.caja = element
          this.form.OtrosIngresos.caja = element.codigo

        }
      });



      if (e.hasOwnProperty('cliente')) {
        this.form.cliente = e.cliente
      }



      if (e.activo == true) {
        var json = {
          save: true,
          eliminar: false,
          anular: true,
          nuevo: true,
          imprimir: true,
          vercuenta: true,
        };


        var _this = this;
            setTimeout(function () {
              _this.getCrearCont()
            }, 900);
        // setTimeout(this.getCrearCont, 200);
      }



      if (e.activo == false) {
        var json = {
          save: false,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };


      }




      //this.ActivarSave(json)


    },



    async llenarcuenta(e) {

      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.form.OtrosIngresos.fecha;
        this.contabilidad.documento = this.form.OtrosIngresos.documento.codigo;
        // this.contabilidad
      }
      var obj = JSON.parse(JSON.stringify(e.contabilidadDetalleList));

      await obj.forEach(elee => {

        if (elee.cuenta == null) {
          const index = obj.indexOf(elee)
          obj.splice(index, 1)
        }
      });

      this.RLisCuenta = JSON.parse(JSON.stringify(obj));
      this.form.RLisCuenta = this.RLisCuenta

      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)

    },

    calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0
      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0

      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {

          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito


        });
      }

    },


  // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumentoModal() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },
//muestra un panel con una tabla de datos
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

   // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    // Limpieza del filtro y recarga de la mesa.
    Limpiarfiltro() {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

   // Una función que se llama cuando el usuario hace clic en el botón de búsqueda.
    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


   // Un método que se llama cuando el usuario cambia el número de elementos por página.
    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.factura)
    },

  // Un método que se llama cuando el usuario cambia el número de elementos por página.
    CargarPaginacion(e, s) {

      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    // Llamar a un método que está en el backend, que se encarga de devolver los datos al frontend.
    CargarListTb() {
      if (this.Modallfiltro.factura != null && this.Modallfiltro.factura.toString().length == 0) { this.Modallfiltro.factura = null }
      var doc = null
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.factura + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/otrosingresos/findListOtrosIngresoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.form.OtrosIngresos.moneda= MONEDA
  this.form.OtrosIngresos.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.form.OtrosIngresos.moneda == null) {
          _this.form.OtrosIngresos.moneda = "";
        }

        if (!_this.form.OtrosIngresos.moneda.hasOwnProperty('codigo')) {
          _this.form.OtrosIngresos.moneda = "";
          _this.form.OtrosIngresos.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.form.OtrosIngresos.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
          this.ejecucion = true;
      }
    },



    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/otrosingresos/findall/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart + "/" + (this.search === "" ? "0" : this.search),
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    }
  }
  ,

  computed: {

    ActDescBtn() {

      if (this.form.OtrosIngresos.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if (this.form.OtrosIngresos.activo == false) {
          this.EstadoBtn.vercuenta = false
          this.EstadoBtn.guardar = false
          this.EstadoBtn.anular = false

        } else {
          this.EstadoBtn.vercuenta = true

        }

      }

      if (this.form.OtrosIngresos.secuencia <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }




};
</script>


<style lang="scss" scoped>
.border-total {

  border: 2px solid #757575;
  // border-bottom: 6px solid #757575;

}


.border-top-bottom {

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}
</style>

