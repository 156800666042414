<template>
<div>

<table  style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
<tr max-height="48px" height="48px"  >   
<ContenidoBase 
  :titulo="'Configuración Proveedores'"
  :descripcion="'Configuración Proveedores'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
</tr>

<tr>
<!-- BarraPrimaria 96 --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                 
            </center>
<!-- <BarraDinamicaNormal
              :url="$hostname + $hName + '/services/tipocliente/findalltipoclientes'"
              :nombre="'Tipo de Clientes'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            /> -->





            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->


<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- fn contenido central ------------------------------------------------------------------------------------->
<td :max-height="this.windowSize.height-96" >
  <v-app id="inspire">
    <v-container>
<img src="@/assets/En_cofig.jpg" height="50%" width="100%" style="backgroud-color:white;"/> 
  </v-container>
<v-container style="display:none;">

    <v-list-item>
      <v-list-item-content>
        <h2>Rango de periodos de envejecimiento de las cxc</h2>
        <hr>

        
         
        <span>Imprimir Balance Pendiente En Recibo de Ingreso
        <v-switch  @change="impBaYesNo" v-model="form.configuracion.balanceIngreso" inset :label="`${form.ImprimirRI.toString()}`"></v-switch>       
        </span>
          
       <span><span></span></span>
        
      </v-list-item-content>
    </v-list-item>



 <v-data-table dense
 :items-per-page="-1"
 
 :headers="[{ 
   text: 'NOMBRE', value: 'nombre' ,sortable: false, class: ['no-gutters']}, 
   {text: 'RANGO INICIAL', value: 'inicio' , class: ['no-gutters']}, 
    {text: 'RANGO FINAL', value: 'fin' , class: ['no-gutters']}, 
     {text: 'CANTIDAD DIAS', value: 'CantidadDias' , class: ['no-gutters']}, 
      {text: 'ACCION', value: 'action' , class: ['no-gutters']}, 


      ]" :items="form.envejecimiento" item-key="codigo" class="elevation-1">

<template v-slot:item.action="{ item }">  

<v-btn fab @click.prevent="editRango(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 
<v-btn fab @click.prevent="deleRango(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
               
   </template>

      <template v-slot:item.CantidadDias="props">

<span>{{currencyFormatter(props.item.fin- props.item.inicio)}}</span>
      
  
      </template>

 <template v-slot:footer>
 <v-btn 
                  color="teal darken-2"
                  dark
                  text 
                  small
                  @click.prevent="dialogRango = !dialogRango"
                  >
                  <i style="font-size: 25px;" class="fa fa-plus"> </i>
                AGREGAR RANGO
      </v-btn>
 </template>

          
  </v-data-table>
  




</v-container>

<!--------------------------------------------------------------------- Modales -->
<!-- ------------------------------------------------------ Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogRango" persistent max-width="60%" max-height="50%">
 <v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> <b>RANGO DE CONFIGURACION</b></v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="CerrarRango()"
        > 
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>


<v-form v-model="valid" ref="formRango" lazy-validation>

                <v-row>



<input
                  type="number"
                  v-model="form.Rango.codigo"
                  style="display:none;"
                />

<!-- -----------------------------  columna-1 -->
<v-col cols="12" md="12">

  <!----------------------------------------------  Nombre -->
   <v-text-field
        dense
        outlined
        label="Nombre*"
        v-model="form.Rango.nombre"
        autocomplete="off"
        :rules="[rules.required]"
        
       
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
</v-text-field>

<!-------------------------------------------------- inicio -->
   <v-text-field
        dense
        outlined  
        label="inicio"
        autocomplete="off"
        @keyup="Rangoinicio"
        v-model="form.Rango.inicio"
        
       
       
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-arrows-h"></i>
                </template>
  
</v-text-field>



<!-------------------------------------------------- fin -->
   <v-text-field
        dense
        outlined  
        label="Fin"
        @keyup="RangoFin"
        v-model="form.Rango.fin"
        autocomplete="off"
       
       
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-arrows-h"></i>
                </template>
  
</v-text-field>



</v-col>
 <!-- ----------------------------- fn columna-1 -->

                </v-row>  
  </v-form>
  
</v-card-text>
<v-divider></v-divider>
<v-card-actions>
          <v-spacer></v-spacer>
          
<v-btn-toggle rounded class="" >

<v-btn color="blue lighten-1" @click.prevent="AddRangoSave">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Guardar</v-btn>

  <!-- <v-btn color="#90A4AE" @click.prevent="CancelarRango">
              <i style="font-size: 28px;" class="fa fa-ban"> </i>
              Cancelar</v-btn> -->

            
          </v-btn-toggle>

        </v-card-actions>

</v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal filtro -->

 <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->

<!--------------------------------------------------------------------- fn Modales -->


   </v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
</tr>

</table>


</div>
</template>

<script>
import numeral from 'numeral'
import AutoNumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    AutoNumeric,
    ContenidoBase
  },
  mounted() {
    this.FindIdConf()
   // EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    //  EventBus.$on("emiCentroSalud", this.datostabla);
    // EventBus.$on("CXCCentrosSaludNew", this.new);
    // EventBus.$on("CXCCentrosSaludSave", this.save);
    // EventBus.$on("CXCCentrosSaludRemove", this.remove);
    // EventBus.$on("CXCCentrosSaludAnular", this.anular);
    // EventBus.$on("CXCCentrosSaludPrintList", this.imprimirList);
    // EventBus.$on("onResize", this.onResize);
    // this.cargarTodo();
   // nota:Probar el guardar 

EventBus.$on("onResize", this.onResize);


  },
  created() {
    var json = {
      titulo: "Configuración Proveedores",
      descripcion: "Configuración Proveedores",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      vercuenta: false,
      accion: [
        "CXPConfiguracionNew",
        "CXPConfiguracionSave",
        "CXPConfiguracionRemove",
        "CXPConfiguracionAnular",
        "CXPConfiguracionPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

data() {
    return {

windowSize:{width:"auto", height:"auto"},
valid: true,
BarraTablaDinamicaAprir:false,
BarraSecundariaAprir:false,
 
  dialogRango:false,


aalert: {
        estado: false,
        color: null
      },


  form:{

ImprimirRI:"No",


configuracion: {
        empresa: 1,
        balanceIngreso: false,
        
    },

envejecimiento: [],



Rango:{
codigo:0,
nombre:null,
inicio:0,
fin:0
}



  },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RulesTel: value => (value && value.length == 13) || `telefono debe tener 10 digitos`,
        RulesCel: value => (value && value.length  ==13) || `telefono debe tener 11 digitos`,
        RulesCelRnc: value => (value && value.length==9 || value.length==11 || value.length==13 ) || `Cedula o RNC Requerido`,


        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },





    };
},
methods:{

onResize(e){

console.log(e)
this.windowSize=e


},


 currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
     
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },




  FindIdConf(){

 this.$axios.post(this.$hostname + this.$hName + '/services/Conguracioncxc/findIdConguracioncxc', {codigo:1}).then(res => {
        this.form.configuracion = res.data.configuracion; this.form.envejecimiento=res.data.envejecimiento; 

if(res.data.configuracion.balanceIngreso==false){this.form.ImprimirRI='No'} else{this.form.ImprimirRI='Si'}
        console.log('repuesta')
        console.log(res.data)
      });

  },


impBaYesNo(e){
if(e==false){this.form.ImprimirRI='No'} else{this.form.ImprimirRI='Si'}

console.log('Configuracion....')
console.log(this.form.configuracion)

this.$axios.post(this.$hostname + this.$hName + '/services/Conguracioncxc/saveConf', JSON.parse(JSON.stringify(this.form.configuracion)))
.then(res => { this.alerta("guardado correctamente", "bien"), setTimeout(this.FindIdConf,2000);      }   )
.catch(error => this.alerta(error,'error'))


},



AddRangoSave(){

if (this.$refs.formRango.validate()) {
      
          this.$axios
            .post(
              this.$hostname + this.$hName + "/services/Conguracioncxc/SaveConfEnvej",
              JSON.parse(JSON.stringify(this.form))
            )
            .then(res => {this.alerta("Dato guardado correctamente", "bien"), this.CerrarRango(), this.FindIdConf() })
            .catch(error => this.alerta(error, "error"));
        
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }



},

CerrarRango(){

this.dialogRango= !this.dialogRango


this.form.Rango={
codigo:0,
nombre:null,
inicio:0,
fin:0
}




},

editRango(e){


//const index =  this.form.envejecimiento.indexOf(e)
//this.form.envejecimiento.splice(index, 1)

this.form.Rango=JSON.parse(JSON.stringify(e))

this.dialogRango=true

},

deleRango(e){

const index =  this.form.envejecimiento.indexOf(e)
//confirm('Desea eliminar este rango?') && this.form.envejecimiento.splice(index, 1)

var ccf = confirm('Desea eliminar este rango?');

console.log('Confirmacion eliminar')
console.log(ccf)


if(ccf=true){ 
  
  this.form.envejecimiento.splice(index, 1) 
  
  this.$axios.post(this.$hostname + this.$hName + '/services/Conguracioncxc/removeConf', JSON.parse(JSON.stringify(e)))
.then(res => { this.alerta("Eliminado correctamente", "bien"), this.FindIdConf()  }   )
.catch(error => this.alerta(error,'error'))

}



//this.nuevoClientes.ListFact= this.RLisFac
  



},




Rangoinicio(e){

  if(e.key){
     var mynumeral = require('numeral')
     this.form.Rango.inicio  = mynumeral(this.form.Rango.inicio).value()
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(this.form.Rango.inicio==null){

this.form.Rango.inicio=0
     }
     
    }
    else{
     console.log("no se puede poner letras o signos")
    }
},


RangoFin(e){

  if(e.key){
     var mynumeral = require('numeral')
     this.form.Rango.fin  = mynumeral(this.form.Rango.fin).value()
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(this.form.Rango.fin==null){

this.form.Rango.fin=0
     }
     
    }
    else{
     console.log("no se puede poner letras o signos")
    }
},


alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },


newwnuevo(){

},

abrirTabla(){
       this.alerta("Este modulo de configuracion no consta de una tabla ,ya que no es necesaria.","error")
    },

save(){

},
remove(){

},
anular(){

},
imprimirList(){

}

}





};
</script>

<style></style>
