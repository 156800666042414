<template>
  <div id="ResponsiveCss">
    {{ ActDescBtn }}
    <table
      style="position: absolute"
      width="100%"
      :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8"
    >
      <tr max-height="48px" height="48px">
        <ContenidoBase
          :titulo="'Registro Facturas Manuales de Proveedores'"
          :descripcion="'Esta función permite realizar facturas a proveedores.'"
          :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla"
          @MethodNuevo="newwnuevo"
          @MethodGuardar="save"
          @MethodEliminar="NotifMessage"
          @MethodAnular="NotifMessage"
          @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta"
        />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraTablaDinamicaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn
                      small
                      color="yellow accent-4"
                      @click.prevent="actualiza_tb_ver"
                      ><i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>
                    <v-btn
                      color="teal darken-2"
                      dark
                      text
                      small
                      @click.prevent="
                        (dialogFiltros = true),
                          (ObjTemTbDinam = JSON.parse(
                            JSON.stringify(Modallfiltro)
                          ))
                      "
                    >
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <v-navigation-drawer >  -->

                <BarraDinamicaLimitePlus
                  :PropsNombre="'Facturas Proveedores'"
                  PropsSearch
                  :PropsSeachNombre="'Búsqueda por #Factura'"
                  :TbPaginator="TbPaginator"
                  :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems"
                  @CargarPaginacion="CargarPaginacion"
                  @EmitDAta="datostabla"
                  :headers="[
                    { text: 'Código', value: 'facturasProveedoresPK.codigo' },
                    /* { text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]"
                  :ContListSlot="[
                    {
                      Text: 'Código',
                      NomValue: 'facturasProveedoresPK',
                      Nv2: 'codigo',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: '#Factura',
                      NomValue: 'referencia',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'proveedor',
                      NomValue: 'proveedor',
                      Nv2: 'nombre',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'fecha',
                      NomValue: 'fecha',
                      Ctsl: 1,
                      Type: 1,
                      State: true,
                    },
                    {
                      Text: 'Valor $',
                      NomValue: 'total',
                      Ctsl: 1,
                      Type: 2,
                      State: true,
                    },
                    {
                      Text: 'Estado',
                      NomValue: 'activada',
                      Ctsl: 1,
                      Type: 0.2,
                      State: true,
                    },
                  ]"
                />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app
            id="inspire"
            class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }"
          >
            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input
                      type="number"
                      v-model="form.FacturaManuales.codigo"
                      style="display: none"
                    />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="3" sm="3">
                        <!------------------------------------------ Documentos -->
                        <v-combobox
                          autocomplete="off"
                          dense
                          outlined
                          label="* Documentos:"
                          :items="RDocumento"
                          item-text="descripcion"
                          :rules="[$rules.required]"
                          v-model="form.FacturaManuales.documentoproveedor"
                          @blur="SelctCbDocumento"
                          @focus="cargarDocumento"
                          v-on:blur="getCrearCont"
                          id="formdisabledInput" :disabled="form.FacturaManuales.facturasProveedoresPK.codigo>0"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 30px" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!-- Secuencia NCF --------------------------------------------------->
                        <v-text-field
                          label=" Secuencia NCF :"
                          dense
                          outlined
                          v-model="datosDGII.secuencia"
                          v-on:blur="completarDigitos"
                          
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-barcode"
                            ></i>
                          </template>
                        </v-text-field>

                        <!------------------------------------------------ ITBIS incluido -->
<!--form.FacturaManuales.itbis  -->
                        <AutoNumeric
                          label="ITBIS:"
                          dense
                          outlined
                          autocomplete="off"
                          v-model.number="form.FacturaManuales.totalimpuesto"
                          id="formdisabledInput" :disabled="form.FacturaManuales.facturasProveedoresPK.codigo>0"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>

                        <!-- ----------------------------- descuento -->
                        <!-- form.FacturaManuales.descuentoIndividual -->
                        <AutoNumeric
                          label="Descuento:"
                          dense
                          outlined
                          autocomplete="off"
                          v-model.number="form.FacturaManuales.descuentoIdividual"
                          id="formdisabledInput" :disabled="form.FacturaManuales.facturasProveedoresPK.codigo>0"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>
                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="3" sm="3">
                        <!-------------------------------------------------- proveedor -->

                        <v-combobox
                          autocomplete="off"
                          dense
                          outlined
                          label="* proveedor:"
                          :items="Rproveedor"
                          item-text="nombre"
                          v-model="form.FacturaManuales.proveedor"
                          @blur="SelctCbProveedor"
                          @focus="cargarProveedor"
                          @keyup="CbFilProveedor"
                          @change="ChangeCbProveedor"
                          :rules="[$rules.required]"
                          id="formdisabledInput" :disabled="form.FacturaManuales.facturasProveedoresPK.codigo>0"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-user-circle"
                            ></i>
                          </template>
                        </v-combobox>

                        <!----------------------------------------------  Fecha entrada -->
                        <v-text-field
                          dense
                          outlined
                          type="date"
                          label="Fecha "
                          :rules="[$rules.required]"
                          v-model="form.FacturaManuales.fecha"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-calendar"
                            ></i>
                          </template>
                        </v-text-field>

                        <AutoNumeric
                          label="Imp. Selectivo:"
                          dense
                          outlined
                          autocomplete="off"
                          v-model.number="form.FacturaManuales.imp"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>


                        <!---------------------------------------------- moneda-->
                        <v-combobox id="formdisabledInput" :disabled="this.form.FacturaManuales.facturasProveedoresPK.codigo >0" autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                          item-text="nombrecorto" :rules="[$rules.required]" v-model="form.FacturaManuales.moneda"
                          @blur="SelctCbMoneda" @focus="cargarMoneda"
                          @change="ChangeMonedaTasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="3" sm="3">
                        <!-- Factura No --------------------------------------------------->
                        <v-text-field
                          label="* Factura No:"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="form.FacturaManuales.referencia"
                          @blur="getCrearCont"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                        <!-- Plazo --------------------------------------------------->

                        <AutoNumeric
                          label="Plazo:"
                          dense
                          outlined
                          autocomplete="off"
                          v-model.number="form.FacturaManuales.diascredito"
                          id="formdisabledInput"
                        :disabled="form.FacturaManuales.facturasProveedoresPK.codigo>0"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-clock-o"
                            ></i>
                          </template>
                        </AutoNumeric>

                        <!-- Tipo de gasto --------------------------------------------------->
                        <v-combobox
                          v-model="datosDGII.tipogasto"
                          label="Tipo de Gasto:"
                          required
                          outlined
                          dense
                          :rules="[$rules.required]"
                          autocomplete="off"
                          :items="RTipoGasto"
                          item-text="descripcion"
                          @focus="CargarRTipopago"
                          @blur="SelctCbTipoGasto"
                          v-on:blur="getCrearCont"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>

                        <AutoNumeric v-if="form.FacturaManuales.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
v-model.number="form.FacturaManuales.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
</AutoNumeric>

                      </v-col>
                      <!-- columna 3 ------------------------------>

                      <!-- columna 4 ------------------------------>
                      <v-col cols="12" md="3" sm="3">
                        <!-- Código fijo NCF --------------------------------------------------->
                        <v-text-field
                          label=" Código fijo NCF :"
                          dense
                          outlined
                          v-model="datosDGII.codigofijo"
                          @blur="BlurCambioMayuscula"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-list-alt"
                            ></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Valor -->
                        <AutoNumeric
                          label="* Valor:"
                          dense
                          outlined
                          autocomplete="off"
                          v-model.number="form.FacturaManuales.total"
                          :rules="[$rules.required]"
                          @blur="getCrearCont"
                          id="formdisabledInput" :disabled="form.FacturaManuales.facturasProveedoresPK.codigo>0"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>

                        <!-------------------------------------------------- Comentario -->
                        <v-textarea
                          autocomplete="off"
                          label="Comentario"
                          dense
                          outlined
                          v-model="form.FacturaManuales.comentario"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px"
                              class="fa fa-commenting"
                            ></i>
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- columna 4 ------------------------------>
                    </v-row>
                  </v-form>
                </div>
              </v-card>
            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td
          width="109"
          :max-height="this.windowSize.height - 96"
          v-show="BarraSecundariaAprir"
        >
          <div
            class="overflow-y-auto"
            v-bind:style="{
              'max-height': this.windowSize.height - 96 + 'px',
              'max-width': '325px',
              width: '325px',
            }"
          >
            <!--        <BarraBusqueda2-->
            <!--              :url="$hostname + $hName + '/services/caja/findAll'"-->
            <!--              :nombre="'Cajas'"-->
            <!--              :headers="headers"-->
            <!--              :slotname="'item.descripcion'"-->
            <!--              :emitnombre="'emitCaja'"-->
            <!--              :slotlist="slotlist"-->
            <!--              :SeachNombre="'Búsqueda por descripción caja'"-->
            <!--            />-->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                dark
                small
                color="deep-purple accent-2"
                @click.prevent="OpendialogimprimirListFilt()"
              >
                <i style="font-size: 25px" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="Modallfiltro.factura"
                @keyup.enter="CargarListTb"
                label="Búsqueda por #Factura"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              class="TbSize13"
              :headers="[
                {
                  text: 'Código',
                  value: 'facturasProveedoresPK.codigo',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: '#Factura',
                  value: 'referencia',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Fecha',
                  value: 'fecha',
                  width: 120,
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Proveedor',
                  value: 'proveedor.nombre',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Valor',
                  value: 'valor',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Documento',
                  value: 'documentoproveedor.descripcion',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Moneda',
                  value: 'moneda.descripcion',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Comentario',
                  value: 'comentario',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
                {
                  text: 'Estado',
                  value: 'activada',
                  sortable: false,
                  class: ['no-gutters black--text'],
                },
              ]"
              dense
              @pagination="cargarPaginacionAll"
              :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page"
              :pageCount="TbPaginator.pageCount"
              :items="ListItems"
              :search="Modallfiltro.factura"
              @click:row="datostabla"
              :server-items-length="TbTotalItem"
            >
              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>

              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == true">Activo</span>
                <span v-if="props.item.activada == false">Anulado</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec == 'AnularFactPro'"
            @click.prevent="anular(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      id="alerMov"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog
      transition="fab-transition"
      label="Modal agregar factura"
      v-model="dialogFiltros"
      persistent
      max-width="90%"
      max-height="80%"
    >
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light"
            ><b>FILTROS</b></v-toolbar-title
          >
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="
              (dialogFiltros = false),
                (Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam)))
            "
          >
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-row>
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox
                autocomplete="off"
                dense
                outlined
                label="Proveedor:"
                style="font-size: 13px"
                :items="Rproveedor"
                item-text="nombre"
                v-model="Modallfiltro.proveedores"
                @blur="SelctCbProveedoresFiltro"
                @focus="cargarProveedor"
                @keyup="CbFilProveedor"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <!---------------------------------------------- documento -->
              <v-combobox
                autocomplete="off"
                dense
                outlined
                label="Documentos:"
                :items="RDocumento"
                item-text="descripcion"
                :rules="[$rules.required]"
                v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro"
                @focus="cargarDocumento"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-book"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">
              <!----------------------------------------------  Desde -->
              <v-text-field
                dense
                outlined
                type="date"
                label="Desde*"
                v-model="Modallfiltro.Desde"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field
                dense
                outlined
                type="date"
                label="Hasta*"
                v-model="Modallfiltro.Hasta"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-2 -->
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-------------------------------------------------------- fn Modal filtro -->

    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog
      transition="fab-transition"
      label="Modal agregar cuenta"
      v-model="dialogimprimirListFilt"
      persistent
      max-width="80%"
      max-height="50%"
    >
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">
        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
          <v-avatar tile size="45">
            <img
              src="@/assets/iconos_micromarket/custom-reports.png"
              alt="Reporte"
            />
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px">
            <b>REPORTE LISTADO REGISTRO FACTURAS DE PROVEEDORES</b>
          </span>

          <v-spacer></v-spacer>
          <v-btn
            color="red"
            fab
            small
            @click.prevent="dialogimprimirListFilt = false"
          >
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr />

        <v-container style="border: 1px solid #000000">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento -->
                <v-combobox
                  autocomplete="off"
                  dense
                  outlined
                  label="Documentos:"
                  :items="RDocumento"
                  item-text="descripcion"
                  :rules="[$rules.required]"
                  v-model="datosImprecionAll.documento"
                  @blur="SelctCbReportDocumento"
                  @focus="cargarDocumento"
                >
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <v-text-field
                  dense
                  outlined
                  type="date"
                  label="Desde*"
                  v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]"
                  @blur="ReportBlurDesde"
                >
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6" sm="6">
                <!-------------------------------------------------- Hasta -->
                <v-text-field
                  dense
                  outlined
                  type="date"
                  label="Hasta*"
                  v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]"
                  @blur="ReportBlurHasta"
                >
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr />

        <ModalReporteFiltros
          ref="ModRepfil"
          @DataReporteBasico="DDataReporteBasico"
          :Datos="datosImprecionAll"
        />
      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->

    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog
      transition="fab-transition"
      label="Modal Reporte Basico"
      v-model="dialogimprimirList"
      persistent
      max-width="80%"
      max-height="50%"
    >
      <ModalReporteBasico
        @DataReporteBasico="DDataReporteBasico"
        :Datos="datosImprecion"
      />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog
      transition="fab-transition"
      label="Modal agregar cuenta"
      v-model="acti_Modal_cuenta"
      persistent
      max-width="80%"
      max-height="50%"
    >
      <CuentasContables
        @CuentasContables="CCuentasContables"
        :RLisCuenta="RLisCuenta"
        :objeTem="objeTem"
        :Total="TotalCuenta"
      />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import CuentasContables from "../../components/CuentasContables";
import {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  fechaNueva
} from "@/js/Funciones.js";

export default {
  components: {
    AutoNumeric,
    BarraDinamicaLimitePlus,
    CrearProducto,
    ContenidoBase,
    BarraDinamicaLimite,
    ModalReporteFiltros,
    ModalReporteBasico,
    CuentasContables,
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitFacturaManuales", this.datostabla);
    // EventBus.$on("cxpFacturaManualesNew", this.new);
    // EventBus.$on("cxpFacturaManualesSave", this.save);
    // EventBus.$on("cxpFacturaManualesRemove", this.remove);
    // EventBus.$on("cxpFacturaManualesAnular", this.anular);
    // EventBus.$on("cxpFacturaManualesPrintList", this.imprimirList);
    // EventBus.$on("cxpFacturaManualesVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.CargarRTipopago();
    this.configuraciones = this.$store.getters.GetConfiguracionGeneral;
    this.getMonedaBase()
      
  },

  created() {
    var json = {
      titulo: "Registro Facturas Manuales de Proveedores",
      descripcion: "Esta función permite realizar facturas a proveedores.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,

      accion: [
        "cxpFacturaManualesNew",
        "cxpFacturaManualesSave",
        "cxpFacturaManualesRemove",
        "cxpFacturaManualesAnular",
        "cxpFacturaManualesPrintList",
        "cxpFacturaManualesVercuenta",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      dialogOrdenSugerida: false,
      datosDGII:{codigoFijo:"",secuencia:0,tipogasto:null},
      Rmoneda: [],
      Rproveedor: [],
      RallProducto: [],
      Rdepartamento: [],
      Ralmacen: [],
      RDocumento: [],
      RTipoGasto: [],
      Rmoneda:[],

      dialogFiltros: false,
      Modallfiltro: {
        proveedores: null,
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: "REGISTRO FACTURAS MANUALES DE PROVEEDORES",
        UrlImprimir: "/facturasproveedor/imprimircomprobante",
        UrlExel: "/facturasproveedor/excelcomprobante",
        UrlCorreo: "/facturasproveedor/correocomprobante",
      },

      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: "LISTADO DE REGISTRO FACTURAS MANUALES DE PROVEEDORES",
        UrlImprimir: "/facturasproveedor/ImprimirList",
        UrlExel: "/facturasproveedor/ExcelList",
        UrlCorreo: "/facturasproveedor/CorreoList",
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documento: null,
      },

      acti_Modal_cuenta: false,
      objeTem: null,
      TotalCuenta: null,
      RLisCuenta: [],
      contabilidad: null,

      aalert: {
        estado: false,
        color: null,
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: "",
      },

      search: "",
      ObjTemp: null,
      form: {
        TotalProd: {
          cantidad: 0,
          cantExit: 0,
          costo: 0,
          impuesto: 0,
          descuento: 0,
          importe: 0,
        },
        RLisCuenta: [],
        TotalCuenta: {
          credito: 0,
          debito: 0,
        },
        
        FacturaManuales: {
          facturasProveedoresPK: { codigo: 0, documento: 0 },
          fecha: fechaNueva(),
          proveedor: null,
          ncf: "",
          comentario: "",
          comentarioSuspencion: "",
          descuentoIdividual: 0,
          tasa: 0,
          documentoproveedor: null,
          borrado: false,
          activada: true,
          moneda: null,
          pendiente: 0.0,
          referencia: "",
          selectivo: 0.0,
          tasa: 1.0,
          tipogasto: null,
          totaldescuento: 0.0,
          totalimpuesto: 0.0,
          total: 0,
          facturasProveedoresDetalleList: [],
          usuarioModificacion: "",
          usuarioCreacion: "",
          diascredito: 0,
        },
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },

      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0,
      },

      rules: {
        required: (value) => !!value || "Required.",
        edad: [
          (val) => val > 10 || `Edad debe ser mayor a 10`,
          (val) => val < 70 || `Edad debe ser menor a 120`,
        ],
        porciento: (value) => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: (value) =>
          (value && value.length <= 50) || "Max 20 characters",
        RTel: (value) => value != 10 || `telefono debe tener 10 digitos`,
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" },
      ],

      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" },
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
    };
  },

  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    onResize(e) {
      // this.windowSize = e
    },

    vercuenta() {
      if (this.$refs.form.validate()) {
        this.getCrearCont()
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta));
        this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },

    getCrearCont() {
      this.form.FacturaManuales.facturasProveedoresPK.codigo;
      this.form.FacturaManuales.documentoproveedor.codigo;

      if (!this.$refs.form.validate()) {
      } else {

        if (this.form.FacturaManuales.facturasProveedoresPK.codigo == 0) {
          console.log(
            "/contabilidad/crear-contabilidad-cxp/" +
              this.form.FacturaManuales.documentoproveedor.codigo +
              "/" +
              this.form.FacturaManuales.total +
              "/0/0/0/0"
          );

          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/contabilidad/crear-contabilidad-cxp/" +
                this.form.FacturaManuales.documentoproveedor.codigo +
                "/" +
                this.form.FacturaManuales.total +
                "/0/0/0/0",
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.llenarcuenta(res.data);
            });

          //&& this.form.Total.abono!=0 && this.form.Total.descuento!=0 && this.form.Total.itbis!=0 && this.form.Total.isr!=0
          //this.llenarcuenta(res.data)
        }
        if (this.form.FacturaManuales.facturasProveedoresPK.codigo != 0) {
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/contabilidad/findContabilidad/" +
                this.form.FacturaManuales.facturasProveedoresPK.documento +
                "/" +
                this.form.FacturaManuales.facturasProveedoresPK.codigo +
                "/" +
                this.form.FacturaManuales.documentoproveedor.operacion.codigo,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.llenarcuenta(res.data);
            });
        }
      }
    },

    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.form.FacturaManuales.fecha;
        this.contabilidad.documento =
          this.form.FacturaManuales.documentoproveedor.codigo;
        // this.contabilidad
      }

      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));

      this.form.RLisCuenta = this.RLisCuenta;

      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1);
    },

    calcularTotalcuenta(e) {
      this.form.TotalCuenta.credito = 0;
      this.form.TotalCuenta.debito = 0;

      if (e.toString().length > 0) {
        this.RLisCuenta.forEach((element) => {
          this.form.TotalCuenta.credito += element.credito;
          this.form.TotalCuenta.debito += element.debito;
        });
      }
    },

    CCuentasContables(e, t, b) {
      if (e != undefined) {
        if (e.length == 0) {
          this.getCrearCont();
        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t));
        }
      }

      this.acti_Modal_cuenta = b;
    },

    CargarRTipopago() {
      this.$axios
        .get(this.$hostname + this.$hName + "/categoriagastos/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RTipoGasto = res.data;
        });
    },

    SelctCbTipoGasto() {
      if (this.datosDGII.tipogasto== null) {
        this.datosDGII.tipogasto = "";
      }
      if (this.datosDGII.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.datosDGII.tipogasto= "";
        return;
      }
    },
    cargarDocumento() {
      this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/documentoscxp/findAllDocumentosFacturasProveedores",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.RDocumento = res.data));
    },
    SelctCbDocumento() {
      if (this.form.FacturaManuales.documentoproveedor == null) {
        this.form.FacturaManuales.documentoproveedor = "";
      }
      if (this.form.FacturaManuales.documentoproveedor.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.form.FacturaManuales.documentoproveedor = "";
        return;
      }
    },
    CargarRalmacen() {
      this.$axios
        .post(this.$hostname + this.$hName + "/services/Almacen/findall", {})
        .then((res) => {
          this.Ralmacen = res.data;
        });
    },

    SelctCbAlmacen() {
      if (this.form.OrdenSugerida.almacen == null) {
        this.form.OrdenSugerida.almacen = "";
      }

      if (this.form.OrdenSugerida.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.form.OrdenSugerida.almacen = "";
        return;
      }
    },

    keyupDiaOrden() {
      if (e.key) {
        var mynumeral = require("numeral");
        this.form.OrdenSugerida.dia = mynumeral(
          this.form.OrdenSugerida.dia
        ).value();
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.form.OrdenSugerida.dia == null) {
          this.form.OrdenSugerida.dia = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },

    addClikProd() {},

    newCrearProducto() {},

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
      if (number == null) {
        number = 0;
      }
      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    save() {
      // console.log(this.$refs.form);
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          this.form.FacturaManuales.ncf =
          this.datosDGII.codigofijo +
            this.datosDGII.secuencia;
          this.form.FacturaManuales.tipogasto =
          this.datosDGII.tipogasto.codigo;

          if (this.form.FacturaManuales.facturasProveedoresPK.codigo === 0) {
            this.form.FacturaManuales.usuarioCreacion =
              this.$store.getters.GetdatosInfUsu.usuario.username;
          } else {
            this.form.FacturaManuales.usuarioModificacion =
              this.$store.getters.GetdatosInfUsu.usuario.username;
          }

if(this.RLisCuenta.length>0){
  this.contabilidad.contabilidadDetalleList=this.RLisCuenta
  this.form.FacturaManuales.cont=this.contabilidad
}


this.form.FacturaManuales.totaldescuento=this.form.FacturaManuales.descuentoIdividual

          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/facturascxpmanual/save",
              this.form.FacturaManuales,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => this.alerta("Dato guardado correctamente", "bien"))
            .catch((error) => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },

    NotifMessage() {
      if (
        this.form.FacturaManuales.facturasProveedoresPK.codigo != 0 &&
        this.form.FacturaManuales.facturasProveedoresPK.codigo != null
      ) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularFactPro",
        };

        this.Exec_notif(notif);
      } else {
        this.alerta("Seleccione una factura proveedor para anular", "error");
      }
    },

    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e));

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
    },

    anular() {
      if (this.$refs.form.validate()) {  
        //Pruerba Update 
        EventBus.$emit("loading", true);
        var linkverificar=`/${this.form.FacturaManuales.facturasProveedoresPK.codigo}/${this.form.FacturaManuales.facturasProveedoresPK.documento}/1`
        this.$axios.get(
          this.$hostname + this.$hName +"/pagoproveedores/VerificarMovimientoPago"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee pago", "error")
          }else{ 
            this.VerificarMovimNotDebito(linkverificar);
          }
            
        })
        .catch((error) => this.alerta(error, "error"));
  
       } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }

    },
    VerificarMovimNotDebito(Links){
      
      this.$axios.get(
          this.$hostname + this.$hName +"/notadebitoproveedores/VerificarMovimientoNotaDebito"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee Nota de debito", "error")
          }else{ 
            this.contAnular()
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },

    

    contAnular(){

      var link=`/${this.form.FacturaManuales.facturasProveedoresPK.codigo}/${this.form.FacturaManuales.facturasProveedoresPK.documento}`
    this.$axios.get(
          this.$hostname + this.$hName +"/facturascxpmanual/anular"+link,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==false){
            this.alerta("Dato anulado correctamente", "bien")
          }else{
            this.alerta("Dato no anulado correctamente", "error")
          }
          
        
        })
        .catch((error) => this.alerta(error, "error"));

    },
    

    imprimirList() {
      if (this.form.FacturaManuales.facturasProveedoresPK.codigo > 0) {
        this.datosImprecion.codigo =
          this.form.FacturaManuales.facturasProveedoresPK.codigo;
        this.datosImprecion.documento =
          this.form.FacturaManuales.facturasProveedoresPK.documento;
        this.datosImprecion.orden = this.form.FacturaManuales.ordencompra;

        this.dialogimprimirList = true;
      } else {
        this.alerta("Seleccione una factura para poder imprimir", "error");
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e;
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true;
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);
    },

    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where =
        "facturas_proveedores.proveedor = proveedores.codigo AND facturas_proveedores.documento = documentoproveedor.codigo";
      //this.datosImprecionAll.documento = "";
      if (this.datosImprecionAll.FecRegi == true) {
        where += ` and date_trunc('day',facturas_proveedores.fecha_creacion)>= ' ${new Date(
          this.datosImprecionAll.desde
        )
          .toISOString()
          .substr(
            0,
            10
          )}' and date_trunc('day',facturas_proveedores.fecha_creacion) <= ' ${new Date(
          this.datosImprecionAll.hasta
        )
          .toISOString()
          .substr(0, 10)} ' `;
      } else {
        where += ` and facturas_proveedores.fecha>= ' ${new Date(
          this.datosImprecionAll.desde
        )
          .toISOString()
          .substr(0, 10)}' and facturas_proveedores.fecha <= ' ${new Date(
          this.datosImprecionAll.hasta
        )
          .toISOString()
          .substr(0, 10)} ' `;
      }
      
      if (this.datosImprecionAll.documento !== null && this.datosImprecionAll.documento!='') {
        where += ` and facturas_proveedores.documento= ${this.datosImprecionAll.documento.codigo}`;
        //d = "" + this.datosImprecionAll.documento.descripcion;
        this.datosImprecionAll.documento = this.datosImprecionAll.documento.descripcion;
      }else{
        this.datosImprecionAll.documento = "";
      }
      this.datosImprecionAll.where = where;

    },

    newwnuevo() {
      this.BarraTablaDinamicaAprir = false;
      this.form.FacturaManuales = {
        facturasProveedoresPK: { codigo: 0, documento: 0 },
        fecha: fechaNueva(),
        proveedor: null,
        ncf: "",
        comentario: "",
        comentarioSuspencion: "",
        descuentoIdividual: 0,
        tasa: 0,
        documentoproveedor: null,
        borrado: false,
        activada: true,
        moneda: null,
        pendiente: 0.0,
        referencia: "",
        selectivo: 0.0,
        tasa: 1.0,
        tipogasto: null,
        totaldescuento: 0.0,
        totalimpuesto: 0.0,
        itbis:0.0,
        total:0.00,
        facturasProveedoresDetalleList: [],
        diascredito:0
      };
      this.datosDGII={codigoFijo:"",secuencia:0,tipogasto:null}
      this.ejecucion = true;
      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
      this.getMonedaBase()
    },

    datostabla(e) {
      this.RLisCuenta=[]
      this.datosDGII.tipogasto=null
      this.datosDGII.codigoFijo=""
      this.datosDGII.secuencia=""

      if(e.ncf!=null){
        if(e.ncf.trim().length==0){
        this.datosDGII.codigofijo = ""
        this.datosDGII.secuencia = 0;
        }
        
      if (e.ncf.trim().length == 11) {
        this.datosDGII.codigofijo = e.ncf.trim().substr(0, 3);
        this.datosDGII.secuencia = e.ncf.trim().substr(3);
      }
      if (e.ncf.trim().length == 13) {
        this.datosDGII.codigofijo = e.ncf.trim().substr(0, 5);
        this.datosDGII.secuencia = e.ncf.trim().substr(5);
      }
      if (e.ncf.trim().length >= 19) {
        this.datosDGII.codigofijo = e.ncf.trim().substr(0, 11);
        this.datosDGII.secuencia = e.ncf.trim().substr(11);
      }
      }

     // this.datosDGII.tipogasto = this.RTipoGasto.filter(t =>t.codigo=e.tipogasto)[0]
      this.ListProd = [];
      this.form.FacturaManuales = JSON.parse(JSON.stringify(e));
      this.form.FacturaManuales.fecha = new Date(e.fecha)
        .toISOString()
        .substr(0, 10);
        if (this.form.FacturaManuales.itbis==null){
          this.form.FacturaManuales.itbis  = 0
                    
        }

 if(e.hasOwnProperty('tipogasto') && e.tipogasto!=null){
        var _this = this;
        setTimeout(function () {
          var ObjProRow = _this.RTipoGasto.filter((ee) => ee.codigo == e.tipogasto);
          _this.datosDGII.tipogasto=JSON.parse(JSON.stringify(ObjProRow[0]))
          
        }, 500);
    }
      this.acti_tb_data = false;
    },
    completarDigitos(e) {
      if (e.target.value != null) {
        var resultado = ("000000000" + e.target.value).substring(
          ("000000000" + e.target.value).length - 8,
          ("000000000" + e.target.value).length
        );
        this.form.FacturaManuales.secuencia = resultado;
        this.datosDGII.secuencia=resultado
      }
    },
    cargarProveedor() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/proveedores/findallbyDesc/25/0/*",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => (this.Rproveedor = res.data));
    },

    SelctCbProveedorOrden() {
      if (this.form.OrdenSugerida.proveedor == null) {
        this.form.OrdenSugerida.proveedor = "";
      }

      if (this.form.OrdenSugerida.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.form.OrdenSugerida.proveedor = "";

        //qlq2

        return;
      }
    },


ChangeCbProveedor(e){
  this.datosDGII.tipogasto=null
  if(e!=null){
    if(e.hasOwnProperty('gasto')&& e.gasto!=null){
      this.datosDGII.tipogasto = JSON.parse(JSON.stringify(e.gasto))
    }
  }
},

    SelctCbProveedor() {
      if (this.form.FacturaManuales.proveedor == null) {
        this.form.FacturaManuales.proveedor = "";
      }

      if (this.form.FacturaManuales.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.form.FacturaManuales.proveedor = "";
        return;
      }
      //this.datosDGII.codigofijo= this.form.FacturaManuales.proveedor.codigoFijo;
     console.log(this.form.FacturaManuales.proveedor)
     console.log(this.form.FacturaManuales.proveedor.codigoFijo.trim().toUpperCase())
     
     if(this.form.FacturaManuales.proveedor.codigoFijo!=null){
      this.datosDGII.codigofijo=JSON.parse(JSON.stringify(this.form.FacturaManuales.proveedor.codigoFijo.trim().toUpperCase()))  
     }else{
      this.datosDGII.codigofijo=null
     }

      if(this.form.FacturaManuales.hasOwnProperty('diascredito')&&this.form.FacturaManuales.diascredito!=null && this.form.FacturaManuales.diascredito>0){
        this.form.FacturaManuales.diascredito =   this.form.FacturaManuales.proveedor.diaCredito;
      }else{
        this.form.FacturaManuales.diascredito =30
      }
      
      ///this.datosDGII.tipogasto = this.form.FacturaManuales.proveedor.gasto;
      
    },

    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
              this.$hName +
              "/proveedores/findallbyDesc/25/0/" +
              (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.Rproveedor = res.data));
      }
    },

    getMonedaBase(){
      //this.form.FacturaManuales.moneda = this.configuraciones.config.monedabase;
      //this.form.FacturaManuales.tasa = this.configuraciones.config.monedabase.tasa;
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.form.FacturaManuales.moneda= MONEDA
  this.form.FacturaManuales.tasa=MONEDA.tasa
    },


    // SelctCbMoneda() {
    //   if (this.form.FacturaManuales.moneda == null) {
    //     this.form.FacturaManuales.moneda = "";
    //   }
    //   if (this.form.FacturaManuales.moneda.codigo == undefined) {
    //     this.alerta("Seleccione una moneda", "error");
    //     this.form.FacturaManuales.moneda = "";
    //     return;
    //   }
    //   this.cambiarTasa();
    // },
    // cambiarTasa() {
    //   this.form.FacturaManuales.tasa = this.form.FacturaManuales.moneda.tasa;
    // },
    // cargarMoneda() {
    //   this.$axios
    //     .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
    //       headers: this.$store.getters.GetheadersAxios,
    //     })
    //     .then((res) => (this.Rmoneda = res.data));
    // },


    SelctCbMoneda() {

      var _this = this;
      setTimeout(function () {
        if (_this.form.FacturaManuales.moneda == null) {
          _this.form.FacturaManuales.moneda = "";
        }

        if (!_this.form.FacturaManuales.moneda.hasOwnProperty('codigo')) {
          _this.form.FacturaManuales.moneda = "";
          _this.form.FacturaManuales.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);

    },

    ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.form.FacturaManuales.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},
    cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },

    SelctCbProveedoresFiltro() {
      if (this.Modallfiltro.proveedores == null) {
        this.Modallfiltro.proveedores = "";
      }

      if (this.Modallfiltro.proveedores.codigo == undefined) {
        this.alerta("Seleccione un proveedores", "error");
        this.Modallfiltro.proveedores = "";

        //qlq2

        return;
      }
    },

    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {
        this.Modallfiltro.documento = "";
      }

      if (this.Modallfiltro.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = "";
        return;
      }
    },

    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva();
      }
    },

    ReportBlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva();
      }
    },

    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documento == null) {
        this.datosImprecionAll.documento = "";
      }

      if (this.datosImprecionAll.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documento = "";
        return;
      }
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro();
      }
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro();
    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      this.Modallfiltro = {
        proveedores: null,
        factura: null,
        documento: null,
        Desde: null,
        Hasta: null,
      };

      this.ListItems = [];
      this.TbTotalItem = 0;
      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      };

      if (
        this.Modallfiltro.factura != null &&
        this.Modallfiltro.factura.toString().length == 0
      ) {
        this.Modallfiltro.factura = null;
      }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg));
      this.CargarListTb();
      this.dialogFiltros = false;
    },

    cargarPaginacionAll(e) {
      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      this.CargarPaginacion(e, this.Modallfiltro.factura);
    },

    CargarPaginacion(e, s) {
      this.Modallfiltro.factura = JSON.parse(JSON.stringify(s));

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15;
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e));
      this.CargarListTb();
    },

    CargarListTb() {
      var prove = null;
      var doc = null;
      if (this.Modallfiltro.proveedores != null) {
        prove = this.Modallfiltro.proveedores.codigo;
      }
      if (this.Modallfiltro.documento != null) {
        doc = this.Modallfiltro.documento.codigo;
      }
      var obj =
        this.TbPaginator.itemsPerPage +
        "/" +
        this.TbPaginator.pageStart +
        "/" +
        this.Modallfiltro.factura +
        "/" +
        prove +
        "/" +
        doc +
        "/" +
        this.Modallfiltro.Desde +
        "/" +
        this.Modallfiltro.Hasta;
      this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/facturasproveedor/findListFactProveedorPlus/" +
            obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListItems = res.data.ListItems),
            (this.TbTotalItem = res.data.TbTotalItem);
        });
    },


    

    

    BlurCambioMayuscula(){

    //  this.datosDGII.codigofijo=JSON.parse(JSON.stringify(this.form.FacturaManuales.proveedor.codigoFijo.trim().toUpperCase()))  


if(this.datosDGII.codigofijo.length>0){
  this.datosDGII.codigofijo=JSON.parse(JSON.stringify(this.datosDGII.codigofijo.toUpperCase()))  
}

},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/facturascxpmanual/findall/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.list = res.data;
        });
    },

    abrirTabla2() {

      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {
    ActDescBtn() {
      if (this.form.FacturaManuales.facturasProveedoresPK.codigo > 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
        if(this.form.FacturaManuales.activada==false){this.EstadoBtn.anular=false}
      }

      if (this.form.FacturaManuales.facturasProveedoresPK.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        };
      }

      this.windowSize = JSON.parse(
        JSON.stringify(this.$store.getters.GetwindowSize)
      );
    },
  },
};
</script>

<style></style>
