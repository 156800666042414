<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
<tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte Auxiliar Cuentas por Pagar'"
  :descripcion="'Imprime Reporte Auxiliar Cuentas por Pagar.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>


   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesAuxiliarCXP'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Reporte Auxiliar Cuentas por Pagar </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="AuxiliarCXP.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>

<!-------------------------------------------------- proveedor -->
 <v-combobox
            autocomplete="off"
            dense
            outlined
            label="proveedor:"
            :items="Rproveedor"
            item-text="nombre"
            v-model="AuxiliarCXP.proveedor"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>                                 
</v-combobox>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

        

 <!-- -----------------------------  hasta  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Hasta:"
         :rules="[$rules.required]"
        v-model="AuxiliarCXP.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>
<!-- ----------------------------- Tipo de Proveedor -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Tipo de Proveedor:"
            :items="RTipoProv"
            item-text="descripcion"
            v-model="AuxiliarCXP.tipo"
            @blur="SelctCbTipoProveedor"
            @focus="cargarTipoProveedor"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-handshake-o"></i>
            </template>
          </v-combobox>
</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>
<ModalReporteFiltros
 ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="AuxiliarCXPLink"
/>

  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {  
      titulo: "Reporte Auxiliar Cuentas por Pagar",
      descripcion: "Imprime Reporte Auxiliar Cuentas por Pagar.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [ 

        "ReportesAuxiliarCXPNew",
        "ReportesAuxiliarCXPSave",
        "ReportesAuxiliarCXPRemove",
        "ReportesAuxiliarCXPAnular",
        "ReportesAuxiliarCXPPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesAuxiliarCXPNew", this.new);
    // EventBus.$on("ReportesAuxiliarCXPSave", this.save);
    // EventBus.$on("ReportesAuxiliarCXPRemove", this.remove);
    // EventBus.$on("ReportesAuxiliarCXPAnular", this.remove);
    // EventBus.$on("ReportesAuxiliarCXPPrintList", this.imprimirList);
    EventBus.$on("emitReportesAuxiliarCXP", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto=this.validarObjecto;


  },

  data:()=>  ({


BarraTablaDinamicaAprir:false,

      Rpropietario:[],
      Rproveedor:[],
      RCaja:[],
      RTipoProv:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

AuxiliarCXPLink:{
nombre:'REPORTE AUXILIAR CUENTAS POR PAGAR',
        UrlImprimir: '/reportesCXP/auxiliarcxp', 
        UrlExel: '/reportesCXP/excelAuxiliarcxp', 
        UrlCorreo: '/reportesCXP/correoAuxiliarcxp',
},

      AuxiliarCXP: { 
        desde: fechaNueva(),
        hasta: fechaNueva(),
        Resumen:true,
        tipo:null,
        proveedor:null 
      },

     EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
      text: "",
      valid: true,
     BarraPrimariaAbrir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraPrimariaAbrir = !this.BarraPrimariaAbrir;

    },
   
    datostabla(e) {

      this.AuxiliarCXP = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() {},
    eliminar() { },
    anular() { },
    imprimirList() {},
    DDataReporteBasico(){},

    validarObjecto(){


var where1=` c.codigo > 0 and fv.fecha >= '${this.AuxiliarCXP.desde}' and fv.fecha  <='${this.AuxiliarCXP.hasta}' `
var where2=` c.codigo > 0 and r.fecha >= '${this.AuxiliarCXP.desde}' and r.fecha  <='${this.AuxiliarCXP.hasta}' `
var where3=` c.codigo > 0 and nc.fecha >= '${this.AuxiliarCXP.desde}' and nc.fecha  <='${this.AuxiliarCXP.hasta}' `
var where4=` c.codigo > 0 and nb.fecha >= '${this.AuxiliarCXP.desde}' and nb.fecha  <='${this.AuxiliarCXP.hasta}' `
var where5=` p.codigo > 0 and c.fecha >= '${this.AuxiliarCXP.desde}' and c.fecha  <='${this.AuxiliarCXP.hasta}' `

if(this.AuxiliarCXP.tipo!=null){ 
where1 +=` and c.tipo= ${this.AuxiliarCXP.tipo.codigo}`
where2 +=` and c.tipo= ${this.AuxiliarCXP.tipo.codigo}`
where3 +=` and c.tipo= ${this.AuxiliarCXP.tipo.codigo}`
where4 +=` and c.tipo= ${this.AuxiliarCXP.tipo.codigo}`
}

if(this.AuxiliarCXP.proveedor!=null){ 

where1 +=` and c.codigo= ${this.AuxiliarCXP.proveedor.codigo}`
where2 +=` and c.codigo= ${this.AuxiliarCXP.proveedor.codigo}`
where3 +=` and c.codigo= ${this.AuxiliarCXP.proveedor.codigo}`
where4 +=` and c.codigo= ${this.AuxiliarCXP.proveedor.codigo}`
where5 +=` and p.codigo= ${this.AuxiliarCXP.proveedor.codigo}`
this.AuxiliarCXPLink.proveedor=JSON.parse(JSON.stringify(this.AuxiliarCXP.proveedor.codigo))
}else{ delete this.AuxiliarCXPLink["proveedor"]}

//this.VentasProductosClientes
this.AuxiliarCXPLink.where1=where1
this.AuxiliarCXPLink.where2=where2
this.AuxiliarCXPLink.where3=where3
this.AuxiliarCXPLink.where4=where4
this.AuxiliarCXPLink.where5=where5
this.AuxiliarCXPLink.Condicion=""
this.AuxiliarCXPLink.fechaini=JSON.parse(JSON.stringify(this.AuxiliarCXP.desde))
this.AuxiliarCXPLink.fechafin=JSON.parse(JSON.stringify(this.AuxiliarCXP.hasta))


},




    newwnuevo() {
      (this.AuxiliarCXP = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarTipoProveedor(){

 this.$axios.get(this.$hostname+this.$hName+'/tipoproveedor/findallactivos',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RTipoProv=res.data))

},

SelctCbTipoProveedor(){
 if(this.AuxiliarCXP.tipo==null){

    this.AuxiliarCXP.tipo=''
  }

if(this.AuxiliarCXP.tipo.codigo==undefined){

//this.alerta("Seleccione un tipo proveedor", "error");
this.AuxiliarCXP.tipo=''
return

}

},



cargarProveedor(){

this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))

},


SelctCbProveedor(){
  if(this.AuxiliarCXP.proveedor==null){

    this.AuxiliarCXP.proveedor=''
  }

if(this.AuxiliarCXP.proveedor.codigo== undefined){

this.alerta("Seleccione un proveedor", "error");
this.AuxiliarCXP.proveedor=''

//qlq2

return

}

},

CbFilProveedor(e) {
     
 if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
              this.$hName +
              "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
    },



BlurDesde(){

if(this.AuxiliarCXP.desde.toString().length==0){
  this.AuxiliarCXP.desde=new Date().toISOString().substr(0,10)
}

 },


 BlurHasta(){

if(this.AuxiliarCXP.hasta.toString().length==0){
  this.AuxiliarCXP.hasta=new Date().toISOString().substr(0,10)
}

 },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {}
  }
};
</script>

<style></style>
