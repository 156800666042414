<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
<tr max-height="48px" height="48px"  >
    
<ContenidoBase 
  :titulo="'Configuración Bancos'"
  :descripcion="'Configuración de Bancos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>


  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBANConfiguracion'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>
      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

  <v-container>

  <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>

              <!-- ----------------------------------- Columna 1 -->
                <v-col cols="12" md="12" sm="12">
 
<!-------------------------------------------------- terminal -->
  <!--:items="RSuplidor",@blur="SelctCbSuplidor", @focus="cargarSuplidor", @keyup="CbFilSuplidor" -->
 <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Seleccione Terminal Para imprimir Cheques:"
            item-text="nombre"
            v-model="configuracion.descripcion"
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-print"></i>
                      </template>                              
</v-combobox>
                </v-col>
                <!-- -----------------------------------fn Columna 1 -->

              </v-row>
            </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
<!-- ------------------------------------------------------ Modal  Etiqueta-->

<v-dialog  v-model="dialogEtiqueta"  persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  
        <v-card >
          
          <v-toolbar dark color="indigo">

<v-app-bar-nav-icon  @click.stop="acti_tb_data_etiqueta=true,filtroEtiqueta()">
      </v-app-bar-nav-icon>
            

            <!-- <v-btn icon dark @click="cerrarmodalEtiqueta()">
              <v-icon>mdi-close</v-icon>
            </v-btn> -->


<v-tooltip bottom >
<template v-slot:activator="{ on }">

  <v-toolbar-title v-on="on">
            Configuración de impresora de códigos de barras
  </v-toolbar-title>

 </template  >
 <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> 
  <span > Configuración de impresora de códigos de barras: </span> Crear, Modificar y desactivar Impresoras</i>

  </v-tooltip>

            <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

            <v-spacer></v-spacer>


<v-card-actions >  
<!-- <span>{{windowSize.width}}</span> 
@click.prevent="dialogAddProducto =!dialogAddProducto"
@click.prevent="dialogCrearProducto =!dialogCrearProducto"
@click.prevent="dialogCrearProducto =!dialogCrearProducto"
@click.prevent="dialogCrearProducto =!dialogCrearProducto"
-->
<v-sheet 
              
              id="scrolling-techniques-7"
              class="overflow-x-auto"
              :max-width="windowSize.width-70"
              color="transparent"
            >   
<v-btn-toggle>


  <v-tooltip top>
<template v-slot:activator="{ on }">
   <v-btn id="boton-mini" v-on="on" color="#90A4AE"  small dark>
    <i style="font-size: 20px;" class="fa fa-file-o"> </i>
    Limpiar </v-btn>
 </template>
          <span>Limpiar </span>
  </v-tooltip>


<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn id="boton-mini"  v-on="on"  color="success" small   dark >
<i style="font-size: 20px;" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
SALVAR</v-btn>
 </template>
          <span>Guardar configuración</span>
  </v-tooltip>



<v-tooltip top>
<template v-slot:activator="{ on }">
   <v-btn id="boton-mini" v-on="on" color="red"  small  dark>
    <i style="font-size: 20px;" class="fa fa-trash"> </i>
    ELIMINAR </v-btn>
 </template>
          <span>Eliminar configuración</span>
  </v-tooltip>

          </v-btn-toggle>

</v-sheet>

 </v-card-actions>

   <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalEtiqueta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>

            <!-- <v-toolbar-items>
              <v-btn dark text @click="dialog = false">Save</v-btn>
            </v-toolbar-items> -->

          </v-toolbar>
          <v-list three-line subheader>

<v-container>

<v-card color="grey lighten-3">
<v-container>
<v-form ref="formModalEtiqueta" v-model="valid" lazy-validation>
<v-row>

<!----------------------------------- columna 1 -->
  <v-col cols="12" md="12" sm="12">
    <v-text-field
                    v-model="configuracion.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="* Nombre de la impresora:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-print"></i>
                    </template>
                  </v-text-field>

  
  </v-col>
<!---------------------------------- fn columna 1 -->

<!------------------------------------- columna 2 -->
<v-col cols="12" md="12" sm="12">
   <!-------------------------------------------------- Comentario -->
<v-textarea
                  autocomplete="off"
                    label="* Configuración de la impresora:"
                    dense
                    outlined
                    v-model="configuracion.descripcion" 
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-cogs"></i>
                    </template>
                  </v-textarea>
</v-col>
<!------------------------------------ fn columna 2 -->





</v-row>
</v-form>
</v-container>
  </v-card>

</v-container>

          </v-list>
         
        </v-card>
  

      </v-dialog>
<!-- ------------------------------------------------------ fn Modal Etiqueta-->

<!-- ------------------------------------------------------ tb-data-Etiqueta-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data_etiqueta"
      persistent
      max-width="100%"
      max-height="100%"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE DE ETIQUETA </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data_etiqueta = false"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="searchEtiqueta"
                @keyup="filtroEtiqueta"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[{ text: 'Código', value: 'codigo', width:'auto' },
        { text: 'Descripcion', value: 'descripcion', width:'auto' }
      ]"
              dense
              :items="listEtiqueta"
              :search="searchEtiqueta"
              @click:row="datostablaEtiqueta"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   <!-- ------------------------------------------------------ tb-data-Etiqueta-->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Configuración Bancos",
      descripcion: "Configuración de Bancos",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [ 
        "BANConfiguracionNew",
        "BANConfiguracionSave",
        "BANConfiguracionRemove",
        "BANConfiguracionAnular",
        "BANConfiguracionPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANConfiguracionNew", this.new);
    // EventBus.$on("BANConfiguracionSave", this.save);
    // EventBus.$on("BANConfiguracionRemove", this.remove);
    // EventBus.$on("BANConfiguracionAnular", this.remove);
    // EventBus.$on("BANConfiguracionPrintList", this.imprimirList);
    EventBus.$on("emitBANConfiguracion", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {

      dialogEtiqueta:false,
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      configuracion: {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        //fechaCreacion: new,
      //  fechaModificacion: "",
        usuarioCreacion: "Admin",
        usuarioModificacion: "",
        empresa:1
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

     
      text: "",
      valid: false,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      listEtiqueta:[],

      search: "",
      searchEtiqueta: "",

      acti_tb_data: false,
      acti_tb_data_etiqueta:false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},


cerrarmodalEtiqueta(){

this.dialogEtiqueta=false

},


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },


datostablaEtiqueta(e){

this.acti_tb_data_etiqueta=false

},



    datostabla(e) {

      this.configuracion = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.configuracion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },
    remove() {

if(this.configuracion.codigo!=0 && this.configuracion.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.configuracion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.configuracion.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.configuracion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {},
    newwnuevo() {
      (this.configuracion = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

filtroEtiqueta(){

this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.listEtiqueta = res.data;
        });


},



    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
