<template>
    <div v-resize="onResize" v-dirkeydown="onkeydownCode"
    v-bind:style="{ 'background-color': 'transparent', 'position': 'absolute',  'width': '100%', 'height': this.windowSize.height - 5 + 'px' }">{{ GetEjecucion }}
    <hr  style="border: 1px solid #FFFFFF;"> 
    <v-row no-gutters >
     <!-- toolbar Contenido-Accion  @DataRetornCierre="DataRetornCierre"-->
     <PvToolbarContenidoAccion ref="RefPvToolbarContenidoAccion" :modofactura="modofactura" @DataRetornGeneral="DataRetornGeneral"   :cajaSelect="caja"   :headerFactura="headerFactura" :OtrasConfigPv="OtrasConfigPv" :configuraciones="configuraciones"/>
    <!-- fn toolbar Contenido-Accion -->
    <div class="overflow-y-auto px-1"
      v-bind:style="{  'width': '100%','height': this.windowSize.height - 79+ 'px', 'max-height': this.windowSize.height - 79 + 'px', 'border': '1px solid transparent', 'background-color': '#FFFFFF' }"> 
        
    <!-- col Informacion Header factura -->
    <PvInformacionHeaderFactura ref="RefPvInformacionHeaderFactura" :modofactura="modofactura" :cajaSelect="caja" @DataRetornInfHeaderFactura="DataRetornInfHeaderFactura" @DataRetornInfHeaderFacturaChange="DataRetornInfHeaderFacturaChange"  />
     <!-- fn col Informacion Header factura -->

    <!-- col Busqueda producto -->
    <PvBusquedaAgregarProducto ref="RefPvBusquedaAgregarProducto" :modofactura="modofactura" :totalGeneralFooter="Totales.totalGeneralFooter" :cajaSelect="caja"  :configuraciones="configuraciones"   :headerFactura="headerFactura" @DataRetornAgregarProducto="DataRetornAgregarProducto"  />
    <!-- col busqueda producto -->

      <!-- col Tabla producto  max-height:348px; height:348px; -->
      <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;" >
      <div style="border: 1px solid #000000; max-height:360px; height:360px;"  class="overflow-x-auto" >
     <v-data-table 
          :loading="loaderTabla"
          id="TbBord"
        class="elevation-1 RowSelectColor TbSize13"
        :headers="[
                  { text: 'Código', sortable: false,  value: 'productos.codigo', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Descripción', sortable: false,  value: 'descripcion', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Unidad', sortable: false, value: 'unidades.descripcion', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Cantidad',align:'center', sortable: false, value: 'cantidad', width:'auto', class: ['no-gutters black--text']  },
                  { text: '$ Precio',align:'center', sortable: false, value: 'precio', width:'auto', class: ['no-gutters black--text']  },
                  { text: '$ Valor',align:'center', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Descuento', align:'center', sortable: false, value: 'descuento', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Impuesto', sortable: false, value: 'valorimpuesto', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Valor neto', sortable: false, value: 'valorneto', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Acción',align:'center' ,sortable: false, value: 'action', width:'auto', class: ['no-gutters black--text']  },

                  ]"
                dense
                :items="RProductoCompra"    
                :items-per-page="15"  
                :footer-props="arrayPropiedases" 
                item-key="indx"  
              >
   
<!-- Item-codigo -->
    <template v-slot:item.productos.codigo="props">
      <span style="display:none;">{{ props.item.indx = RProductoCompra.indexOf(props.item)+`${props.item.productos.codigo}${props.item.unidades.codigo}` }} {{ props.item.id = RProductoCompra.indexOf(props.item) }}   </span>
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2"> {{ props.item.productos.codigo }} </span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
    </v-col>
    </template>
<!-- fn Item-codigo -->

<!-- Item-Descripción -->
  <template v-slot:item.descripcion="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
      <span class="px-1 blue--text"
                              v-if="props.item.paramOfert == true"
                              style=" font-size:12px; border: 1px dashed  #616161;"><b> **OFERTA** </b> </span>
      <span class="px-2">{{ props.item.descripcion }} </span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px; border: 1px dashed  #616161;"><b>Vendedor: </b>{{
                              props.item.vendedor.nombre
                            }}</span>
   </v-col>
  </template>
<!-- fn Item-Descripción -->

<!-- Item-Unidad -->
<template v-slot:item.unidades.descripcion="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">{{ props.item.unidades.descripcion }} </span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
    </v-col>
</template>
<!-- fn Item-Unidad -->

<!-- Item-cantidad -->
<template v-slot:item.cantidad="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">{{ props.item.cantidad }} </span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
                          </v-col>
</template>
<!-- fn Item-cantidad -->

<!-- Item-precio -->
<template v-slot:item.precio="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">{{currencyFormatter(props.item.precio/tasaMoneda )}}</span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
                          </v-col>
</template>
<!-- fn Item-precio -->
<!-- Item-valor -->
  <template v-slot:item.valor="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">{{currencyFormatter(props.item.valor/tasaMoneda )}}</span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
                          </v-col>
    <!-- <span style="display:none;">{{ props.item.indx = RProductoCompra.indexOf(props.item) }}</span> -->
  </template>
<!-- fn Item-Valor -->

<!-- Item-descuento -->
      <template v-slot:item.descuento="props">
        <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">($ {{ currencyFormatter2(props.item.DescValor) }})
                              (% {{ currencyFormatter2(props.item.descuento) }})</span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
                          </v-col>
    </template>
<!-- fn Item-descuento -->

<!-- Item-impuesto -->
<template v-slot:item.valorimpuesto="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">{{currencyFormatter(props.item.valorimpuesto/tasaMoneda )}}</span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
                          </v-col>
 </template>
<!-- fn Item-impuesto -->

<!-- Item-valor neto -->
<template v-slot:item.valorneto="props">
    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorListProducto(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
    <span class="px-2">{{currencyFormatter(props.item.valorneto/tasaMoneda )}}</span>
    </v-col>
    <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-2" style=" font-size:12px;"><b></b></span>
                          </v-col>
 </template>
<!-- fn Item-valor neto -->

  <template v-slot:item.action="{ item }">
    
      <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->
  <v-tooltip top>
  <template v-slot:activator="{ on }">
  <v-btn :disabled="!modificando" v-on="on" fab @click.prevent="getExistProdXAlmacen(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn> 

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-eye"><span> Ver Existencia.</span>
  </i>   
  </v-tooltip>

  <v-tooltip top >
  <template v-slot:activator="{ on }">
  <v-btn :disabled="item.paramOfert == true || !modificando" v-on="on" fab @click.prevent="editarProductoListaProdcto(item)" x-small color="orange"><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-pencil"><span> Editar registro.</span>
  </i>   
  </v-tooltip>

  <v-tooltip top>
  <template v-slot:activator="{ on }">
  <v-btn :disabled="!modificando" v-on="on" fab @click.prevent="eliminarProductoListaProdcto(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> 

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-trash-o"><span> Eliminar registro.</span>
  </i>   
  </v-tooltip>

  <!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
    
  </template> 

    </v-data-table>

  </div>
      </v-col>
      <!-- fn col Tabla producto -->

      <!-- col footer -->
      <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;" >
        <v-row  class="pa-0 ma-0 py-0 my-0" no-gutters > 
          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="4" sm="12" > 
            <v-textarea autocomplete="off" label="comentario:" rows="3" dense outlined v-model="Facturasventa.comentario">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-commenting"></i>
                            </template>
                          </v-textarea>
          </v-col>

          <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="4" sm="12" > 
            <v-textarea autocomplete="off" label="Ship To:" rows="3" dense outlined v-model="Facturasventa.shipto">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-ship"></i>
                            </template>
                          </v-textarea>
          </v-col>

          <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="12" style="font-size: 13px;" > 
      <!-- <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="6" md="6" sm="6">
              <b>SUB-TOTAL: </b> </p>-->
              <v-row no-gutters> 
            <!-- SUB-TOTAL  -->
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <b class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-end">SUB-TOTAL: </b> 
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <p class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-start">{{ currencyFormatter(Totales.totalGeneralFooter.subtotal/tasaMoneda) }}</p> 
            </v-col>
            <!-- fn SUB-TOTAL  -->

            <!-- DESCUENTO  -->
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <b class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-end">DESCUENTO: </b> 
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <p class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-start">{{ currencyFormatter(Totales.totalGeneralFooter.descuento/tasaMoneda) }}</p> 
            </v-col>
            <!-- fn DESCUENTO  -->
            <!-- TOTAL  -->
            <!-- <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <b class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-end">TOTAL: </b> 
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0  " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <p class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-start">{{ currencyFormatter(0) }}</p> 
            </v-col> -->
            <!-- fn TOTAL  -->
            <!-- IMPUESTOS  -->
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <b class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-end">IMPUESTOS: </b> 
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <p class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-start">{{ currencyFormatter(Totales.totalGeneralFooter.itbis/tasaMoneda) }}</p> 
            </v-col>
            <!-- fn IMPUESTOS  -->
            <!-- RETENCIONES  -->
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <b class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-end">RETENCIONES: </b> 
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <p class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-start">{{ currencyFormatter(Totales.totalGeneralFooter.Retencion/tasaMoneda) }}</p> 
            </v-col>
            <!-- fn RETENCIONES  -->
            <v-col class="pa-0 ma-0 py-0 my-0 "  cols="12" md="12" sm="12"> 
            <hr style="border:1px solid #000000;">
          </v-col>
            <!-- TOTAL NETO  -->
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6"> 
              <b class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-end">TOTAL NETO: </b> 
            </v-col>
            <v-col class="pa-0 ma-0 py-0 my-0 " style="border: 1px dashed  #616161;" cols="6" md="6" sm="6" > 
              <p class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-start">{{ currencyFormatter(Totales.totalGeneralFooter.valorneto/tasaMoneda) }}</p> 
            </v-col>
            <!-- fn TOTAL NETO  -->
            
            
              </v-row><br>
              <!-- <br><br><br> -->
          </v-col>

          </v-row>
      </v-col>
      <!-- fn  col  footer -->

      
    </div>
    </v-row>

      <!-- modales ---------------------------->

<!-- ------------------------------------------------------ Modal- Nombre factura temporal-->
     <v-dialog transition="fab-transition" label="Modal factura temporal" v-model="dialogGuardartemporal" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion Nombre factura temporal ------------------------------->
      <div v-if="dialogGuardartemporal"  v-dirkeydown="finalizarGuardartemporalKeyCtrl">
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Nombre a factura
                temporal</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Nombre : </span> Asignar nombre a
              factura temporal </i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarGuardarTemporal()"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="ValidarFomsguardartemporal()" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y Guardar factura temporal, Al presionar la tecla (CTRL), ejecuta este boton </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
                      <span>
                          <b>Nota:</b><span style="color: #C62828;"> Salvar al presionar tecla (CTRL).</span>
                        </span>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Nombre a factura temporal</b>
                <v-form v-model="valid" @submit.prevent="" ref="formNombreFactTem" lazy-validation>
                  <!-- Row 1 edicion      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                      <v-text-field ref="RefTextFieldNombreFactTem" v-model="nombreFactTem" :rules="[$rules.required]" autocomplete="off" label="Nombre:"
                        required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------- Sucursal:-->
                      <v-combobox v-if="cambiarDestinoSucursal == true" autocomplete="off" dense outlined
                        label="Sucursal:" :items="RSucursal" item-text="descripcion" :rules="[$rules.required]"
                        v-model="sucursalFactTemporal" @blur="SelctCbSucursal" @focus="CargarRSucursal">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                        </template>
                      </v-combobox>




                    </v-col>

                  </v-row>
                  <!-- Row 1 edicion ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Nombre factura temporal-->


     <!-- ------------------------------------------------------ Modal-Configuracion pv -->
    <v-dialog transition="fab-transition" label="M Configuracion pv " v-model="dialogConfigPv" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion de ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> CONFIGURACIÓN
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> CONFIGURACIÓN: </span>
              Configuración</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-4" fab small @click="dialogConfigPv = false">
              <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
            </v-btn>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <div class="px-2">
              <v-card color="grey lighten-3">

                <div class="px-2">

                  <v-row>
                    <!-- Columna 1 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="4" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CONFIGURACIÓN DE CAJA</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">

                        <v-combobox v-model="caja" label="Caja" required outlined dense autocomplete="off" :items="RCaja"
                          item-text="descripcion" @blur="SelctCbCajaConfigPv" @focus="cargarCaja"
                          @change="UpdateConfigPv">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-fax"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                    </v-col>
                    <!-- fn Columna 1 -->
                    <!-- Columna 2 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="4" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CONFIGURACIÓN DE IMPRESORA</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">

                        <v-combobox v-if="false" autocomplete="off" dense outlined label="Seleccione Terminal:"
                          item-text="terminal" :items="listTerminal" v-model="terminal" @focus="findAllTerminal"
                          @change="updateImpresoraLocal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-trademark"></i>
                          </template>
                        </v-combobox>

                        <v-combobox autocomplete="off" dense outlined label="Seleccione Impresora:" item-text="nombre"
                          :items="terminal.impresoras" v-model="OtrasConfigPv.impresora" @change="updateImpresoraLocal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-print"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                    </v-col>
                    <!-- fn Columna 2 -->
                    <!-- Columna 3 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="4" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CAMBIO DE TURNO</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">

                        <v-combobox autocomplete="off" dense outlined label="Turno:" item-text="nombre"
                          v-model="OtrasConfigPv.turno" @change="UpdateConfigPv">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                    </v-col>
                    <!-- fn Columna 3 -->



                  </v-row>
                </div>



              </v-card>
            </div>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Modal-Configuracion pv-->
    
  
  <!-- ------------------------------------------------------ Modal- Forma de pago-->
  <v-dialog transition="fab-transition" fullscreen label="Modal Forma de pago" v-model="dialogFormaDepago" persistent max-width="100%"
      max-height="100%">
      
      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="12" sm="12">
      <v-row no-gutters>
        <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" :md="PanelFormaPagoNotaCredito==false?12:6" sm="12" >
      <center>
        <v-col cols="12" :md="PanelFormaPagoNotaCredito==false?7:12" sm="12"> 
          <v-card style="border: 2px solid #dd4b39;">
            <v-toolbar dark color="#dd4b39" dense>
              <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> FORMA DE PAGO 
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> FORMA DE PAGO </span></i>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-card-actions>
              <v-btn-toggle>
              <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="12" sm="12">
                <v-btn color="grey darken-4" fab x-small @click="dialogFormaDepago = false">
                  <i style="font-size: 20px; color: white" class="fa fa-close"></i>
                </v-btn>
              </v-col>
              </v-btn-toggle>
              </v-card-actions>

            </v-toolbar>
            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-1 px-2" >
            <!-- forma de pago pv -->
              <v-row>
                    <!--  columna 1  @blur="ActualizarProductPago"-->
                    <v-col v-show="PanelFormaPagoColmna1" cols="12" md="6" sm="6">
                      <VueAutonumeric :disabled="activaConduceCredito == true || disabledEfectivo" id="formdisabledInput"
                        ref="RefTextFieldForPagoEfectivo" label="Efectivo:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.efectivo" @keydown.enter="enterEfectivo" @blur="BlurEfectivoPago"
                        :rules="[$rules.Numericc]" style="font-size: 13px;" @input="calculostotales" hide-details>
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                          <!-- <span ></span> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/efectivo35.png" alt="Efectivo">
                          </v-avatar>

                        </template>
                      </VueAutonumeric>
                      <!--@blur="ActualizarProductPago"  -->
                      <VueAutonumeric :disabled="activaConduceCredito == true" id="formdisabledInput" class="mt-1"
                        label="Tarjeta:" dense outlined autocomplete="off" v-model.number="Facturasventa.tarjeta"
                        @input="calculostotales" @keydown.enter="enterTarjeta" :rules="[$rules.Numericc]"
                        style="font-size: 13px;" hide-details @focus="OpenPanelFormaPagoTarjeta" @blur="BlurTarjetaPago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/tarjeta35.png" alt="Tarjeta">
                          </v-avatar>


                        </template>
                      </VueAutonumeric>
                      <VueAutonumeric :disabled="activaConduceCredito == true" id="formdisabledInput" class="mt-1"
                        label="Cheque:" dense outlined autocomplete="off" v-model.number="Facturasventa.cheques"
                        @input="calculostotales" @keydown.enter="enterCheque" :rules="[$rules.Numericc]"
                        style="font-size: 13px;" hide-details @focus="OpenPanelFormaPagoCheque('Cheque')"
                        @blur="BlurChequesPago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/cheques35.png" alt="Cheque">
                          </v-avatar>



                        </template>
                      </VueAutonumeric>


                      <VueAutonumeric :disabled="activaConduceCredito == true" id="formdisabledInput" class="mt-1"
                        label="Transferencia:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.transferencia" @input="calculostotales"
                        @keydown.enter="enterTransferencia" :rules="[$rules.Numericc]" style="font-size: 13px;"
                        hide-details @focus="OpenPanelFormaPagoCheque('Transferencia')" @blur="BlurTransferenciaPago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/transferencia35.png" alt="Transferencia">
                          </v-avatar>
                        </template>
                      </VueAutonumeric>

                    </v-col>
                    <!-- fn columna 1 -->

                    <!--  columna 2  -->
                    <v-col v-show="PanelFormaPagoColmna2" cols="12" md="6" sm="6">

                      <!-- @blur="ActualizarProductPago" -->
                      <VueAutonumeric :disabled="activaConduceCredito == true || disabledCredito" id="formdisabledInput"
                        ref="RefTextFieldForPagoCxc" class="mt-1" label="Crédito (cxc):" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.cxc" @input="calculostotales" @focus="condicionByPago"
                        @keydown.enter="enterCxc" :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details
                        @blur="BlurCxcPago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/credito35.png" alt="Crédito">
                          </v-avatar>
                        </template>
                      </VueAutonumeric>
                      <VueAutonumeric v-show="false" class="mt-1" label="Cupones:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.cupones" @input="calculostotales" @keydown.enter="enterCupones"
                        :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details @blur="BlurCuponesPago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/cupones35.png" alt="Cupones">
                          </v-avatar>
                        </template>
                      </VueAutonumeric>
                      <VueAutonumeric class="mt-1" label="Conduce:" dense outlined autocomplete="off"
                       v-if="modofactura!=`modificar`"
                        v-model.number="Facturasventa.valorConduce" @input="calculostotales" @keydown.enter="enterConduce"
                        :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details @blur="BlurConducePago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i>  color="transparent"-->
                          <v-avatar @click="btnActivaInactivaConduceCredito" tile size="30">
                            <img v-if="activaConduceCredito == true"
                              src="@/assets/icons_pv/formapago/conduce35activo2.png" alt="conduce">
                            <img v-if="activaConduceCredito == false" src="@/assets/icons_pv/formapago/conduce.png"
                              alt="conduce">

                          </v-avatar>

                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric :disabled="activaConduceCredito == true" id="formdisabledInput" class="mt-1"
                        label="Nota de crédito:" dense outlined autocomplete="off" v-model.number="Facturasventa.valorNc"
                        @input="calculostotales" @keydown.enter="enterNC" :rules="[$rules.Numericc]"
                        style="font-size: 13px;" hide-details @focus="OpenPanelFormaPagoNotacredito"
                        @blur="BlurNotaCreditoPago" v-on:blur="BlurValorNcPago">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/notacredito35.png" alt="Nota de crédito">
                          </v-avatar>
                        </template>
                      </VueAutonumeric>
                    </v-col>
                    <!-- columna 2- Opcion 2 Tarjeta -->
                    <v-col style="border: 1px dashed  #616161; margin-left:-10px;" v-show="PanelFormaPagoTarjeta" cols="12" md="6" sm="6" >

                      <v-form ref="formTarjetaFormPago" v-model="valid" lazy-validation  >

                        <v-row>
                          <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <span :class="`d-flex justify-center white--text`"><b>Tarjeta</b></span>
                          </v-col>
                          <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="6"
                            md="6" sm="6">
                            <v-btn color="grey darken-4" fab x-small
                              @click="PanelFormaPagoTarjeta = false, PanelFormaPagoColmna2 = true">
                              <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-text-field style="font-size:13px;" v-model="Facturasventa.numtar" :rules="[$rules.required]"
                          autocomplete="off" label="Número Tarjeta:" required outlined dense class="mt-2" hide-details>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>

                        <v-combobox autocomplete="off" dense outlined label="Tipo de Tarjeta" :items="RTipoTarjeta"
                          item-text="nombre" :rules="[$rules.required]" v-model="tipoTarjeta" @blur="SelctCbTipoTarjeta"
                          @focus="cargarTipoTarjeta" class="mt-2" hide-details>

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-combobox>

                      </v-form>

                    </v-col>
                    <!-- fn columna 2- Opcion 2 Tarjeta  -->

                    <!-- columna 2- Opcion 2 cheque -->
                    <v-col style="border: 1px dashed  #616161; margin-left:-10px;" v-show="PanelFormaPagoCheque" cols="12" md="6" sm="6">
                      <v-row>
                        <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                          <span :class="`d-flex justify-center white--text`"><b>{{ NombreFormaPagoCheqTras }}</b></span>
                        </v-col>
                        <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="6"
                          md="6" sm="6">
                          <v-btn color="grey darken-4" fab x-small
                            @click="PanelFormaPagoCheque = false, PanelFormaPagoColmna2 = true">
                            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-text-field style="font-size:13px;" v-model="Facturasventa.numeroCheque"
                        :rules="[$rules.required]" autocomplete="off" :label="'Número ' + NombreFormaPagoCheqTras"
                        required outlined dense class="mt-2" hide-details>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                        </template>
                      </v-text-field>

                      <v-combobox autocomplete="off" dense outlined label="Banco" :items="RBanco" item-text="nombre"
                        :rules="[$rules.required]" v-model="bancoCheque" @blur="SelctCbBanco" @focus="cargarBanco"
                        class="mt-2" hide-details>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                        </template>
                      </v-combobox>



                    </v-col>
                    <!-- fn columna 2- Opcion 2 Cheque  -->
                    <!-- fn columna 2  -->

               </v-row>
            <!-- fn forma de pago pv -->
               <center>
                <v-tooltip dense top>
                <template v-slot:activator="{ on }">
                  <h1 v-on="on" style="border: 2px solid #757575;" block>
                    <span v-if="devuelta < 0" class="red--text">Devuelta: $ ({{ currencyFormatter2(devuelta * (-1)) }}
                      )</span>
                    <span v-if="devuelta == 0">Devuelta: $ 0.00</span>
                    <span v-if="devuelta > 0" class="green--text">Devuelta: $ {{ currencyFormatter2(devuelta) }}</span>
                  </h1>
                </template>
                <b><span> <strong style="  background-color:black; font-size:14px;">Letra Negro:</strong> no contiene
                    devuelta ni faltante,
                    <strong style="  background-color:red; font-size:14px;">Letra Rojo:</strong> contiene faltante,
                    <strong style="  background-color:green; font-size:14px;">Letra Verde:</strong> contiene
                    devuelta.</span></b>

              </v-tooltip>
              <!-- Btn-Cobrar -->
              <v-hover v-slot:default="{ hover }" close-delay="200">
                <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                  @click.prevent="realizarCobro" :color="hover ? 'green darken-3' : 'green'"
                  v-ripple="{ class: `white--text` }" :loading="!ejecucion" :disabled="!ejecucion"  >

                  <h2>Cobrar</h2>
                  <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>
                </v-btn>
              </v-hover>
              <!-- fn Btn-Cobrar -->

               </center>
<br/>
            </v-col>

          </v-card>
        </v-col>
      </center>
      </v-col>
      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="6" sm="12" >
      <center v-show="PanelFormaPagoNotaCredito==true" >
        <v-col cols="12" md="12" sm="12">
          <v-card style="border: 2px solid #dd4b39;">
<!--------------------- Panel Forma pago nota de credito  ------------------------------->
<div style="max-height:300px; min-height:300px " class="overflow-x-auto" v-show="PanelFormaPagoNotaCredito">

<v-toolbar dark color="#dd4b39" dense>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">

      <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Forma de
        Pago Nota crédito </v-toolbar-title>

    </template>
    <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Forma de Pago Nota crédito:
      </span> Forma de Pago Nota crédito</i>

  </v-tooltip>

  <v-spacer></v-spacer>
  
  <v-card-actions>
              <v-btn-toggle>
              <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="12" sm="12">
                <v-btn color="grey darken-4" fab x-small @click="PanelFormaPagoNotaCredito = false, PanelMenuOpcionBotones = true">
                  <i style="font-size: 20px; color: white" class="fa fa-close"></i>
                </v-btn>
              </v-col>
              </v-btn-toggle>
              </v-card-actions>
</v-toolbar>

<v-col cols="12" md="12" sm="12"  >

  <v-text-field id="formdisabledInput" label="Escriba el nombre del cliente:" autocomplete="off" dense
    outlined :rules="[$rules.required]" v-model="BuscarClienteFormaPagoNC" hide-details
    @keyup="CbFilNotacreditocontado">
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-font"></i>
    </template>
  </v-text-field>


  <v-data-table v-model="selectedListFPagoNC" dense :headers="[
    { text: 'CLIENTE', value: 'cliente', sortable: false, class: ['no-gutters black--text'] },
    { text: 'CEDULA', value: 'cedula', sortable: false, class: ['no-gutters black--text'] },
    { text: 'VALOR PENDIENTE', value: 'pendiente', sortable: false, align: 'center', class: ['no-gutters black--text '] },
  ]
  " :items="ListFormaPagoNC" item-key="indx" class="elevation-1" :single-select="true"
    :show-select="true" @item-selected="obtenerDatoSelectFormaPagoNC"
    @click:row="obtenerDatoSelectFormaPagoNCClickRow" :items-per-page="5">
    <!-- hide-default-footer -->
    <template v-slot:item.pendiente="props">
      <span style="display:none;">{{ props.item.indx = ListFormaPagoNC.indexOf(props.item) }}</span>
      <span style="font-size:13px;">{{ currencyFormatter(props.item.pendiente) }}</span>
    </template>



  </v-data-table>



</v-col>
</div>
<!--------------------- fn Panel Forma pago nota de credito  ------------------------------->

          </v-card>
        </v-col>
      </center>
      </v-col>
      </v-row>

    </v-col>

  </v-dialog>
  <!-- ------------------------------------------------------  fn Modal- Forma de pago-->
  
<!-- ------------------------------------------------------ Modal- Anulacion-->
<v-dialog transition="fab-transition" fullscreen label="Modal Anulacion factura" v-model="dialogAnularFactura" persistent max-width="100%"
      max-height="100%">
       <center  style="margin-top: 10%;">
      <v-col class="pa-0 ma-0 py-0 my-0 " cols="12" md="7" sm="12">
       
          <v-card style="border: 2px solid #dd4b39;">
            <v-toolbar dark color="#dd4b39" dense>
              <v-tooltip bottom>
              <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ANULAR FACTURA
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> ANULAR FACTURA </span></i>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-card-actions>
              <v-btn-toggle>
              <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="12" sm="12">
                <v-btn color="grey darken-4" fab x-small @click="dialogAnularFactura = false">
                  <i style="font-size: 20px; color: white" class="fa fa-close"></i>
                </v-btn>
              </v-col>
              </v-btn-toggle>
              </v-card-actions>

            </v-toolbar>
            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-1 px-2 pb-2" >
              <div style="border: 1px solid #000000; min-height:168px;  max-height:168px; height:168px;  ">
                <v-form class="pt-3 px-2"   ref="formAnulacion" @submit.prevent="" v-model="valid" lazy-validation>
                  <v-combobox autocomplete="off" dense outlined label="Motivo de anulación:" :items="RTipoAnulacion"
                          item-text="descripcion" :rules="[$rules.required]" v-model="TipoAnulacion"
                          @blur="SelctCbMotivoAnulacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class=" fa fa-ban"></i>
                          </template>
                        </v-combobox>
                
                </v-form>
                
                <!-- Btn-Anular -->
              <v-hover v-slot:default="{ hover }" close-delay="200">
                <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                  @click.prevent="AnulacionFacturaAnular" :color="hover ? 'red darken-3' : 'red'"
                  v-ripple="{ class: `white--text` }" :loading="!ejecucion" :disabled="!ejecucion"  >

                  <h2>Anular</h2>
                  <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>
                </v-btn>
              </v-hover>
              <!-- fn Btn-Anular -->
              </div>
     
             
            </v-col>
          </v-card>
        
      </v-col>
    </center>
</v-dialog>
  <!-- ------------------------------------------------------  fn Modal- Anulacion-->
  
    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notifDescClient.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title class="d-flex justify-center">
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACIÓN
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
       
        </v-card-title>
        <hr />

        <v-card-text>
        <center>
          <h3><b>{{ notifDescClient.nombre }}</b></h3>
        </center>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn 
            @click.prevent="confirmDescuentoCliente(true)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>
          <v-btn @click.prevent="confirmDescuentoCliente(false)" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->
    <!-- ------------------------------------------------------  alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
<!-- ------------------------------------------------------  fn alerta -->
<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv" :cajaSelect="caja" />
            </div>
            <!--------------------- fn Panel Menu Opcion Botones ------------------------------->
        <!-- fn modales ---------------------------->
    </div>
</template>
<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import PvToolbarContenidoAccion from "@/PuntodeVenta/UtilidadComponents/PvToolbarContenidoAccion.vue";
import PvInformacionHeaderFactura from "@/PuntodeVenta/UtilidadComponents/PvInformacionHeaderFactura.vue";
import PvBusquedaAgregarProducto from "@/PuntodeVenta/UtilidadComponents/PvBusquedaAgregarProducto.vue";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc
} from "@/js/Funciones.js";

export default {
  components: {
    VueAutonumeric,
    PvMenuOpcionBotones,
    PvToolbarContenidoAccion,
    PvInformacionHeaderFactura,
    PvBusquedaAgregarProducto
  },
  directives: {
    dirkeydown: {
      // Definición de directiva
      bind(el, binding) {
        // __HandleScrollHandler__
        el.__HandledKey__ = event => binding.value(event)
        document.body.addEventListener('keydown', el.__HandledKey__)
      },
      unbind(el) {
        document.body.removeEventListener('keydown', el.__HandledKey__)
      }

    }
  },

  created() { 
     this.loadPv();
  },
  mounted() {
    EventBus.$on("emitEjecConfigPv", this.EjecConfigPv);
    this.onResize();
    this.UsarLote = this.$store.getters.GetConfiguracionGeneral.configInv.usarLote
    this.ConfImpfiscal = this.$store.getters.GetConfiguracionGeneral.config.impresoraFiscal
    this.IncluirItbisGeneral = this.$store.getters.GetConfiguracionGeneral.configPv.impuestoincluido;
    this.cambiarDestinoSucursal = this.$store.getters.GetConfiguracionGeneral.configPv.cambiarDestino
  },
  beforeDestroy() {
    EventBus.$off("emitEjecConfigPv");
  },

  data: () => ({ 
    expanded:[],
    Pedidosfacturacion:null,
    tasaMoneda:1,
    // salvarNuevo,editar,modificar
    modofactura:'salvarNuevo',
    ejecucion:true,
    valid: true,
EjecutarPermisoPv:false,
ConfImpfiscal: false,
IncluirItbisGeneral: false,
loaderTabla:false,
configuraciones:null,
 arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
 },
 RProductoCompra:[],
 UsarLote:false,
 descProductoExistencia:'',
 dialogExistenciaProducto:false,
 dialogFormaDepago:false,
 ListaProdxAlmacen:[],
 windowSize: { width: "auto", height: "auto" },
 caja:null,
 RCaja:[],
 dialogConfigPv:false,
 OtrasConfigPv: {
      caja: null,
      terminal: null,
      impresora: null,
      turno: null,
      cajero: null,
      moneda: null,
 },
 listTerminal:[],
 terminal: {
      defaulPrinter: "",
      impresoras: [],
      terminal: "",
 },

 CierresCajas: {
      cierrescajasPK: { caja: 0, secuencia: 0 },
      cantidadtarjeta: 0,
      tarjeta: 0,
      cantidadcheques: 0,
      cheques: 0,
      cantidadrecibo: 0,
      recibos: 0,
      efectivo: 0,
      cierrecajaMonedaList: [],
      DenominacionCierrecajaList: [],
      datoTarjeta: null,
      cantidadtranferencia:0,
      transferencia:0,

 },
 editando:false,
 modificando:false,
 dialogFormaDepago:false,
    Facturasventa : {
        facturasventaPK: { secuencia: 0, documento: 0 },
        empresa: 1,
        fecha: new Date(),
        referencia: "",
        diascredito: 0,
        ncf: "",
        valor: 0.0,
        tasa: 1,
        comentario: "",
        valordescuencto: 0.0,
        valorimpuesto: 0.0,
        pendiente: 0.0,
        activada: true,
        efectivo: 0.0,
        tarjeta: 0.0,
        cheques: 0.0,
        cxc: 0.0,
        puntos: 0.0,
        valorConduce: 0.0,
        cupones: 0.0,
        cedularncncf: "",
        direccionncf: "",
        telefononcf: "",
        codigocupon: "",
        numeroCheque: "",
        nombreDgii: "",
        documentoFactura: null,
        umerocierre: 0,
        autorizacion: null,
        cobertura: null,
        medico: null,
        exequartur: null,
        diagnostico: null,
        especialidad: null,
        enviada: false,
        glosada: false,
        coberturaPendiente: null,
        arsCobrarEmpleado: false,
        arsIncobrable: false,
        arsComentario: null,
        usuario: "",
        usuarioCobro: "",
        fechaImpresion: null,
        nif: null,
        horaImpresion: null,
        receta: null,
        valorRecargo: 0,
        descGloblal: true,
        recgGlobal: false,
        numtar: null,
        valorNc: 0.0,
        codigoNc: 0,
        secuenciaNc: 0,
        retencion: 0.0,
        sumado: false,
        ncfVencimiento: null,
        medicoCodigo: null,
        totalIsr: 0.0,
        totalOtroImpuesto: 0,
        motivoAnular: null,
        facturaManual: false,
        descripcion: "",
        numeroOrden: null,
        lineaAerea: null,
        numeroVuelo: null,
        rutaSalidaE: null,
        rutaSalidaD: null,
        rutaRetornoE: null,
        rutaRetornoD: null,
        turno: null,
        gaspremium: 0.0,
        valordonacion: 0.0,
        valorbonogas: 0.0,
        valorasignacion: 0.0,
        valorcomision: 0.0,
        valorotros: 0.0,
        borrado: false,
        fechaIngreso: null,
        fechaSalida: null,
        tipoAsistencia: null,
        numeroHabitacion: null,
        facturaRecurrente: false,
        configuracionDocumentoRecurrente: 0,
        valorTraspaso: null,
        valorPruebaMetro: null,
        detalleJson: "",
        generadaDesdeEnviosPaquetes: false,
        generadaDesdeBoleteria: false,
        conduceSecuencia: null,
        conduceDocumento: null,
        shipto: '',
        incoterms: null,
        pagare: null,
        pagareValor: null,
        usuariosAnulacion: null,
        fechaAnulacion: null,
        usuariosEditar: null,
        fechaEdicion: null,
        bancoCheque: null,
        ars: null,
        caja: null,
        cliente: null,
        clinica: null,
        impuesto: null,
        moneda: null,
        sucursal: null,
        tipoTarjeta: null,
        tipocomprobante: null,
        vendedor: null,
        contabilidad: null,
        transferencia: 0.0,
        domicilio: null,
        facturasventaDetalleList: [],
        datoTarjeta: null,
      },
      disabledEfectivo:false,
      disabledCredito:false,
      ListFormaPagoNC:[],
    PanelFormaPagoColmna1: true,
    PanelFormaPagoColmna2: true,
    PanelFormaPagoTarjeta: false,
    PanelFormaPagoCheque: false,
    PanelFormaPagoNotaCredito: false,
      activaConduceCredito:false,
      selectedListFPagoNC: [],
      tipoTarjeta: null,
      RTipoTarjeta: [],
      NombreFormaPagoCheqTras: "",
      bancoCheque:null,
      RBanco:[],
      BuscarClienteFormaPagoNC: null,
devuelta: 0.0,
 Totales:{
  totalGeneralFooter: {
        subtotal: 0,
        descuento: 0,
        total: 0,
        itbis: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
        valorneto:0
      },

 },
 Monedafactura:null,
 headerFactura:null,
 TasaMoneda: 1,
 CantRedondeo: 2,
 OBJTEMP:null,
 notifDescClient: {
      estado: false,
      nombre: "¿Desea aplicarle el descuento del cliente a los productos de esta factura?",
      Exec: ""
    },
    Retencionfactura: {
      ValorPorc: true,
      Retencion: 0,
    },
    RSucursal:[],
cambiarDestinoSucursal: false,
sucursalFactTemporal:null,
nombreFactTem:'',  
dialogGuardartemporal:false,
deleteFacturaTemporal:null,
conduceSelect:null,
dialogAnularFactura:false,
TipoAnulacion:null,
RTipoAnulacion: [
      { codigo: 1, descripcion: "Deterioro de Factura Pre-Impresa" },
      { codigo: 2, descripcion: "Errores de Impresión(Factrua Pre-Impresa" },
      { codigo: 3, descripcion: "Impresión Defectuosa" },
      { codigo: 4, descripcion: "Duplicidad de Factura" },
      { codigo: 5, descripcion: "Correción de la Información" },
      { codigo: 6, descripcion: "Cambio de Productos" },
      { codigo: 7, descripcion: "Devolución de Productos" },
      { codigo: 8, descripcion: "Omisión de Productos" },
      { codigo: 9, descripcion: "Errorres en la secuencias NCF" },
    ],
 aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),

  methods: { 
    currencyFormatter,fechaNueva,RedondeoValor,RedondeoValorCantRed,RedondeoBasico,
    currencyFormatter2(value) {
      return (
        "" +
        parseFloat(this.RedondeoValor(value / this.tasaMoneda )).toLocaleString(
          "en",
          { minimumFractionDigits: this.CantRedondeo }
        )
      );
    },
    onResize() {
      //divSelecProd
      /*var jsn = { width: window.innerWidth, height: window.innerHeight - 112 };
      this.windowSize = jsn;*/
      //this.windowSize=e
    },

    onkeydownCode(ee) {
       //key: "End "keyCode: 35
      if(ee.keyCode==35){
        ee.preventDefault();
        // data.opcion==='DataSalvarFactura'

        //valida si la forma de pago esta abierta y si lo esta se cobra al darle otra ves a end
        if(this.dialogFormaDepago==false){
          var data={
          opcion:'DataSalvarFactura'
        }
      this.DataRetornGeneral(data)
        }else{
            this.realizarCobro()
        }
        
      }

      //key:"F7":118
    if (ee.keyCode == 118) {
         ee.preventDefault();
        //this.activoF7=true
        var _this = this;
        setTimeout(function () {
        //  _this.$refs.RefTextFieldBuscaVendedorCodigo.focus();
        }, 500);
        return;
      }




    },
    
    EjecConfigPv() {
      this.findAllTerminal();

      // :items="terminal.impresoras"
      //  v-model="OtrasConfigPv.impresora"

      if (localStorage.hasOwnProperty("printerSelect")) {
        this.OtrasConfigPv.impresora = JSON.parse(localStorage.printerSelect);
      } else {
        this.OtrasConfigPv.impresora = null;
      }

      this.dialogConfigPv = true;
    },

    // Cargar los datos de la base de datos y almacenarlos en el almacenamiento local dotos.
    async loadPv() {

var User = ""
if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
  User = this.$store.getters.GetdatosInfUsu.usuario.username
} else {
  User = this.$store.getters.GetdatosInfUsu.usuario.nombre
}
this.OtrasConfigPv = {
  caja: null,
  terminal: null,
  impresora: null,
  turno: null,
  cajero: User,
  moneda: null,
};
if (!localStorage.caja) {
  this.dialogConfigPv = true;
} else {
  var d = JSON.parse(localStorage.caja);
  await this.$axios
    .get(
      this.$hostname + this.$hName + "/cajas/findbycodigo/" + d.codigo,
      { headers: this.$store.getters.GetheadersAxios }
    )
    .then((res) => {
    //   this.RestaurarFacturaBusqueda.sucursal = res.data.sucursal
      this.sucursalFactTemporal = res.data.sucursal
      this.caja = res.data;
      localStorage.setItem("caja", JSON.stringify(res.data));
    })
    .catch((error) => {
      console.error(error);
    });
}
await this.$axios
  .get(this.$hostname + this.$hName + "/configuraciones/find", {
    headers: this.$store.getters.GetheadersAxios,
  })
  .then((res) => {
    this.configuraciones = res.data;
    //this.$store.commit('MutationaddConfiguracionGeneral',res.data);
  })
  .catch((error) => console.error(error));
//this.configuraciones =this.$store.getters.GetConfiguracionGeneral;
// console.log(!localStorage.hasOwnProperty("printerSelect"))
// console.log(!this.OtrasConfigPv.hasOwnProperty("impresora"))

//!localStorage.printerSelect
if (
  !localStorage.hasOwnProperty("printerSelect") ||
  !this.OtrasConfigPv.hasOwnProperty("impresora")
) {
  this.OtrasConfigPv.impresora = null;
} else {
  this.OtrasConfigPv.impresora = JSON.parse(localStorage.printerSelect);
}
this.Monedafactura = this.configuraciones.config.monedabase;

this.InfomCaja();
this.findCierreActual();
this.findAllTerminal();
    },

    // Establecer el foco en el campo de texto.
    InfomCaja() {
      if (this.$store.getters.GetOtrasConfigPv != null) {
        this.OtrasConfigPv = this.$store.getters.GetOtrasConfigPv;
      }
    },
    // Hacer una solicitud GET al servidor y devolver los datos.
    findAllTerminal() {
      this.$axios
        .get("http://localhost:15999/impresora/inicial/listaImpresora")
        .then((res) => {
          this.listTerminal = res.data;

          if (res.data.length > 0) {
            this.terminal = res.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    findCierreActual() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.caja.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
          this.updateConfiguracion();
        });
    },

    // Actualización de los datos en la configuracion.
    updateConfiguracion() {
      if (this.OtrasConfigPv != null) {
        var cond = this.configuraciones.configPv.conduce;
        var mar = this.caja.marca;
        this.OtrasConfigPv.caja =
          (cond && mar ? "*" : "") +
          this.caja.descripcion.trim() +
          "-" +
          this.CierresCajas.cierrescajasPK.secuencia;

        if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
          this.OtrasConfigPv.cajero = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          this.OtrasConfigPv.cajero = this.$store.getters.GetdatosInfUsu.usuario.nombre
        }

        this.$store.commit("MutationDataOtrasConfigPv", this.OtrasConfigPv);
        sessionStorage.setItem(
          "OtrasConfigPv",
          JSON.stringify(this.OtrasConfigPv)
        );
      }
    },


    UpdateConfigPv(e) {
      if (e != null) {
        var User = ""
        if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
          User = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          User = this.$store.getters.GetdatosInfUsu.usuario.nombre
        }
        this.OtrasConfigPv.moneda =
          this.configuraciones.config.monedabase.descripcion;
        this.OtrasConfigPv.cajero = User;
        this.OtrasConfigPv.caja =
          e.descripcion.trim() + "-" + this.CierresCajas.cierrescajasPK.secuencia;
        this.$store.commit("MutationDataOtrasConfigPv", this.OtrasConfigPv);
        sessionStorage.setItem(
          "OtrasConfigPv",
          JSON.stringify(this.OtrasConfigPv)
        );
        localStorage.setItem("caja", JSON.stringify(e));
        this.loadPv();
      }
    },

    updateImpresoraLocal(e) {
      localStorage.setItem(
        "printerSelect",
        JSON.stringify(this.OtrasConfigPv.impresora)
      );
    },


    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RCaja.
    cargarCaja() {
      this.$axios
        .get(this.$hostname + this.$hName + "/cajas/findAllActivos", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCaja = res.data));
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCajaConfigPv() {
      var _this = this;
      setTimeout(function () {
        if (_this.caja == null) {
          _this.caja = "";
        }

        if (!_this.caja.hasOwnProperty('codigo')) {
          _this.caja = "";
          _this.caja = null;
          return;
        }
      }, 300);

// me quede aquiiii

    },

    getExistProdXAlmacen(e){
      this.$refs.RefPvBusquedaAgregarProducto.getExistProdXAlmacen(e)
    },

    DataRetornInfHeaderFactura(e){
      this.headerFactura= JSON.parse(JSON.stringify(e))
      this.Monedafactura= JSON.parse(JSON.stringify(e.moneda))
      this.tasaMoneda=JSON.parse(JSON.stringify(e.moneda.tasa))
      this.sucursalFactTemporal = JSON.parse(JSON.stringify(e.sucursal))

    },

    async DataRetornAgregarProducto(prods){
       const result1 =  await this.convalidandoProducto(prods);
      this.RProductoCompra.unshift(JSON.parse(JSON.stringify(result1)));
      // this.RProductoCompra.push= JSON.parse(JSON.stringify(e)) 
      this.buscarAgregarEliminarOferta(result1)
      this.loaderTablaCargar()
      this.calcularTotalesFactura() 
      EventBus.$emit("loading", false);
    },
    loaderTablaCargar(){
      this.loaderTabla=true
var _this=this;
setTimeout(function(){ _this.loaderTabla=false }, 900);
    },

// convalida si ya esta agregado o no el producto
   async convalidandoProducto(producto){
    // var element= JSON.parse(JSON.stringify(producto))
    return new Promise((resolve, reject) => {
      (async () => {
        var ObjProRow = this.RProductoCompra.filter((ee) => ee.paramOfert==false &&  ee.productos.codigo == producto.productos.codigo &&
        ee.unidades.codigo == producto.unidades.codigo);
      if (ObjProRow.length > 0) { 
        var CANTIDAD=0 
        //Para saver si es editando o agregando
        if(producto.hasOwnProperty("editando") && producto.editando==true){
          CANTIDAD=(producto.cantidad)
        }else{
          CANTIDAD=(producto.cantidad+ObjProRow[0].cantidad)
        }
        
        var INDX= ObjProRow[0].indx
        var PRODUCTO=JSON.parse(JSON.stringify(producto))
            PRODUCTO.cantidad=CANTIDAD
            const result1 =  await this.$refs.RefPvBusquedaAgregarProducto.verificarValidarCalcularVIDProducto(PRODUCTO);
            result1.indx=INDX
            if(result1.hasOwnProperty("editando") && result1.editando==true){
             delete result1["editando"]
             }
           const indice = this.RProductoCompra.indexOf(ObjProRow[0]);
           this.RProductoCompra[indice] = result1;
           this.$refs.RefPvBusquedaAgregarProducto.nuevoProducto()
           this.$refs.RefPvBusquedaAgregarProducto.RedireccionFocus('RefComboxBuscaProduc')
           this.buscarAgregarEliminarOferta(result1)
           this.loaderTablaCargar()
           this.calcularTotalesFactura() 
            return
      }else{
        // const result1 =  await this.verificarValidarCalcularVIDProducto(this.productoSeleccionado);
        const resultAdd  =  await this.$refs.RefPvBusquedaAgregarProducto.verificarValidarCalcularVIDProducto(producto);
        this.$refs.RefPvBusquedaAgregarProducto.nuevoProducto()
        this.$refs.RefPvBusquedaAgregarProducto.RedireccionFocus('RefComboxBuscaProduc')
        resolve(resultAdd)
        
      }

      })()
    });
    },

confirmDescuentoCliente(conf){

  var OBJ=JSON.parse(JSON.stringify(this.OBJTEMP.headerFactura))

  if(conf==true){
        this.aplicarActualizarDescuentoCliente(OBJ)
       }else{
        this.quitarActualizarDescuentoCliente(OBJ)
       }

this.notifDescClient.estado=false
this.OBJTEMP=null
},

DataRetornInfHeaderFacturaChange(e){
  // ------Change Cliente
  if(e.nombreChange==="changeCliente"){
    if(this.RProductoCompra.length>0){
    if(e.headerFactura.descuento>0 ){
      this.OBJTEMP=JSON.parse(JSON.stringify(e))
      this.notifDescClient.estado=true
    }else{
      this.quitarActualizarDescuentoCliente(e.headerFactura)
    }
  }
  }
  // ------ fn Change Cliente
  // ------ Change Comprobante
  if(e.nombreChange==="changeComprobante"){ 
    if(this.RProductoCompra.length>0){
      var _this=this;
      setTimeout(function(){
      _this.aplicarActualizarComprobante(e.headerFactura)
    }, 300);
    }
  }
  // ------ fn Change Comprobante
  
 // ------ Change Comprobante
 if(e.nombreChange==="changeMoneda"){ 
  var _this=this;  
        /*this.$store.commit("MutationDataOtrasConfigPv", OtrasConfPv);
        sessionStorage.setItem("OtrasConfigPv",JSON.stringify(OtrasConfPv));*/
var OtrasConfPv = _this.$store.getters.GetOtrasConfigPv;
OtrasConfPv.moneda = e.headerFactura.moneda!=null ?e.headerFactura.moneda.descripcion.trim():"";
this.tasaMoneda=JSON.parse(JSON.stringify(e.headerFactura.moneda.tasa))
    if(this.RProductoCompra.length>0){
      setTimeout(function(){
        // _this.aplicarCambioMoneda(e.headerFactura)
        _this.UpdateConfigMoneda()
    }, 300);
    }
  }
  // ------ fn Change Comprobante
},

// Convertir los valores de las entradas a la nueva moneda.
UpdateConfigMoneda() {
      var TasaMmon = this.tasaMoneda;

      if (this.Facturasventa.efectivo > 0) {
        this.Facturasventa.efectivo = JSON.parse(
          JSON.stringify(this.Facturasventa.efectivo / TasaMmon)
        );
      }

      if (this.Facturasventa.tarjeta > 0) {
        this.Facturasventa.tarjeta = JSON.parse(
          JSON.stringify(this.Facturasventa.tarjeta / TasaMmon)
        );
      }

      if (this.Facturasventa.cheques > 0) {
        this.Facturasventa.cheques = JSON.parse(
          JSON.stringify(this.Facturasventa.cheques / TasaMmon)
        );
      }

      if (this.Facturasventa.transferencia > 0) {
        this.Facturasventa.transferencia = JSON.parse(
          JSON.stringify(this.Facturasventa.transferencia / TasaMmon)
        );
      }

      if (this.Facturasventa.cxc > 0) {
        this.Facturasventa.cxc = JSON.parse(
          JSON.stringify(this.Facturasventa.cxc / TasaMmon)
        );
      }

      if (this.Facturasventa.cupones > 0) {
        this.Facturasventa.cupones = JSON.parse(
          JSON.stringify(this.Facturasventa.cupones / TasaMmon)
        );
      }

      if (this.Facturasventa.puntos > 0) {
        this.Facturasventa.puntos = JSON.parse(
          JSON.stringify(this.Facturasventa.puntos / TasaMmon)
        );
      }

      if (this.Facturasventa.valorNc > 0) {
        this.Facturasventa.valorNc = JSON.parse(
          JSON.stringify(this.Facturasventa.valorNc / TasaMmon)
        );
      }
    },


async aplicarCambioMoneda(headerFactura){
  var bar = new Promise((resolve, reject) => { 
  this.RProductoCompra.filter((ee) => ee.paramOfert== false).forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      PRODUCTO.editando=true
      // const result1 =  await this.convalidandoProducto(PRODUCTO);
      /*const resUniPro =  await this.$refs.RefPvBusquedaAgregarProducto.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
      PRODUCTO.precioOrig=JSON.parse(JSON.stringify(resUniPro.precio))
      PRODUCTO.precio=JSON.parse(JSON.stringify(resUniPro.precio))
      PRODUCTO.precioDig=JSON.parse(JSON.stringify(resUniPro.precio))*/
      PRODUCTO.precio=JSON.parse(JSON.stringify(element.precioOrig))
      PRODUCTO.precioDig=JSON.parse(JSON.stringify(element.precioOrig))
      
      //precioOrig:195.07642
      var INDX= PRODUCTO.indx
      const result1 =  await this.$refs.RefPvBusquedaAgregarProducto.CalcularValorImpuestoDescuento(PRODUCTO);
      result1.indx=INDX
            if(result1.hasOwnProperty("editando") && result1.editando==true){
             delete result1["editando"]
             }
            //  element=JSON.parse(JSON.stringify(result1))
            const indice = this.RProductoCompra.indexOf(element);
            // this.RProductoCompra.splice(indice, 0,result1)
            this.RProductoCompra[indice] = result1;
           
    })()
  });
  resolve()
});

bar.then(() => {
  var _this=this;
  setTimeout(function(){ 
  _this.loaderTablaCargar()
  _this.calcularTotalesFactura() 
}, 300);
});

},



async aplicarActualizarComprobante(headerFactura){
  var bar = new Promise((resolve, reject) => { 
  this.RProductoCompra.filter((ee) => ee.paramOfert== false).forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      PRODUCTO.editando=true
      // const result1 =  await this.convalidandoProducto(PRODUCTO);

      var INDX= PRODUCTO.indx
      const result1 =  await this.$refs.RefPvBusquedaAgregarProducto.CalcularValorImpuestoDescuento(PRODUCTO);
      result1.indx=INDX
            if(result1.hasOwnProperty("editando") && result1.editando==true){
             delete result1["editando"]
             }
            //  element=JSON.parse(JSON.stringify(result1))
            const indice = this.RProductoCompra.indexOf(element);
            // this.RProductoCompra.splice(indice, 0,result1)
            this.RProductoCompra[indice] = result1;
           
    })()
  });
  resolve()
});

bar.then(() => {
  var _this=this;
  setTimeout(function(){ 
  _this.loaderTablaCargar()
  _this.calcularTotalesFactura() 
}, 300);
});

 /*this.RProductoCompra.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      PRODUCTO.editando=true
      PRODUCTO.changeListaPrecio=true
      PRODUCTO.descuentoKeys=headerFactura.descuento
      PRODUCTO.ValorPorc=headerFactura.ValorPorc
      console.log(PRODUCTO)
      const result1 =  await this.convalidandoProducto(PRODUCTO);
    })()
  });*/
},


async aplicarActualizarDescuentoCliente(headerFactura){
  this.RProductoCompra.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      PRODUCTO.editando=true
      PRODUCTO.changeListaPrecio=true
      PRODUCTO.descuentoKeys=headerFactura.descuento
      PRODUCTO.ValorPorc=headerFactura.ValorPorc
      const result1 =  await this.convalidandoProducto(PRODUCTO);
    })()
  });
},

async quitarActualizarDescuentoCliente(headerFactura){
  this.RProductoCompra.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      PRODUCTO.editando=true
      PRODUCTO.changeListaPrecio=true
      PRODUCTO.descuentoKeys=0
      PRODUCTO.ValorPorc=false
      PRODUCTO.descuDefoul=true
      const result1 =  await this.convalidandoProducto(PRODUCTO);
    })()
  });
},

  GetColorListProducto(e){
    // wsedfksajdfkj mme quede aquii
   /* unidadesProductosPK.producto
unidadesProductosPK.unidad*/
    
  var PRODUCTOSELEC=this.$refs.RefPvBusquedaAgregarProducto.buscarProduc
  if(PRODUCTOSELEC!=null && PRODUCTOSELEC.hasOwnProperty('indx') && PRODUCTOSELEC.indx== e.indx && PRODUCTOSELEC.unidadesProductosPK.producto ==e.unidadesProductosPK.producto && PRODUCTOSELEC.unidadesProductosPK.unidad ==e.unidadesProductosPK.unidad){
    return '#FF9800'
  }else{
    return 'transparent'
  }


  },


    editarProductoListaProdcto(item){
      var _this=this;
setTimeout(function(){ 
  var PRODUCTO=JSON.parse(JSON.stringify(item));
      PRODUCTO.editando=true
      PRODUCTO.precio=JSON.parse(JSON.stringify(PRODUCTO.precioDig))
      PRODUCTO.codigoProducto=JSON.parse(JSON.stringify(_this.$refs.RefPvBusquedaAgregarProducto.GetCodigoProdUni(PRODUCTO)))
      _this.$refs.RefPvBusquedaAgregarProducto.buscarProduc=JSON.parse(JSON.stringify(PRODUCTO))
      _this.$refs.RefPvBusquedaAgregarProducto.productoSeleccionado=JSON.parse(JSON.stringify(PRODUCTO));
      _this.$refs.RefPvBusquedaAgregarProducto.vendedorSelect=item.hasOwnProperty('vendedor')&& item.vendedor!=null && item.vendedor.hasOwnProperty('codigo')?JSON.parse(JSON.stringify(item.vendedor)):null;
      _this.$refs.RefPvBusquedaAgregarProducto.RedireccionFocus('RefTextFieldProductoCantidad')
      
}, 300);
   
    },


    eliminarProductoListaProdcto(item){
    const index = this.RProductoCompra.indexOf(item);
    var eliminar=confirm("¿ Desea eliminar este producto ?");
    if(eliminar==true){
      this.RProductoCompra.splice(index, 1);
      this.calcularTotalesFactura()
    }


    },

  calcularTotalesFactura(){
      this.Totales.totalGeneralFooter={
        subtotal: 0,
        descuento: 0,
        total: 0,
        itbis: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
        valorneto:0
      }

      var TOTALES={
        subtotal: 0,
        descuento: 0,
        total: 0,
        itbis: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
        valorneto:0
      }

    
      // Totales.totalGeneralFooter.Retencion


      var bar = new Promise((resolve, reject) => { 
      var _this=this;
// setTimeout(function(){ }, 600);
// _this.Totales.totalGeneralFooter.
  this.RProductoCompra.forEach(async (element) => {
    (async () => { 
    if(element.paramOfert==false){

  /*TOTALES.subtotal+=element.precioXcantidadNt
  TOTALES.descuento+=element.DescValor
  TOTALES.itbis+=element.valorimpuesto
  TOTALES.valorneto+=element.valorneto*/
  // this.RedondeoValorCantRed(,3)
TOTALES.subtotal+=this.RedondeoValorCantRed(element.precioXcantidadNt,3)
  TOTALES.descuento+=this.RedondeoValorCantRed(element.DescValor,3)
  TOTALES.itbis+=this.RedondeoValorCantRed(element.valorimpuesto,3)
  TOTALES.valorneto+=this.RedondeoValorCantRed(element.valorneto,3)
  
}
})()
});
resolve()
      });

    bar.then(() => {
    var _this=this;
    setTimeout(function(){ 

      if (_this.Retencionfactura.Retencion > 0) {
        if (_this.Retencionfactura.ValorPorc == true) {
          TOTALES.Retencion =
            _this.Retencionfactura.Retencion / _this.Monedafactura.tasa;
        }
        if (_this.Retencionfactura.ValorPorc == false) {
          TOTALES.Retencion = this.RedondeoBasico(
            (_this.Retencionfactura.Retencion * TOTALES.subtotal) /
            100 /
            _this.Monedafactura.tasa)
        }
      } else {
        TOTALES.Retencion = 0;
      }

      TOTALES.valorneto= (TOTALES.valorneto-TOTALES.Retencion)
   _this.Totales.totalGeneralFooter=JSON.parse(JSON.stringify(TOTALES))
      }, 300);      
    });
  },

  async buscarAgregarEliminarOferta(ProductoBase){

    return  new Promise((resolve, reject) => {

      //eliminando las ofertas del producto
       this.RProductoCompra.filter((ee) => 
          ee.paramOfert==true && 
          ee.ProductoBase.productos.codigo == ProductoBase.productos.codigo &&
          ee.ProductoBase.unidades.codigo == ProductoBase.unidades.codigo
        ).forEach(async (element) => {
    (async () => { 
      const index = this.RProductoCompra.indexOf(element);
      this.RProductoCompra.splice(index, 1);
    })()
  });
// fn eliminando las ofertas del producto

//  buscar la oferta  del producto
(async () => { 
var link1 = `/producto/getOferctaProducto/${ProductoBase.cantidad}/${ProductoBase.productos.codigo}/${ProductoBase.unidades.codigo}`
                    this.$axios.get(this.$hostname + this.$hName + link1,
                      { headers: this.$store.getters.GetheadersAxios })
                      .then(res => {
                        if (res.data.sizeList > 0) {
                          this.AgregarOfertasCompra(res.data.listUniProd, ProductoBase)
                        }

                      var _this=this;
                      setTimeout(function(){ resolve() }, 600);

                      })
                      .catch(error => { console.log('-E-:' + error) }
                      );
 })()                     
//  fin buscar la oferta  del producto
    });
    
},

AgregarOfertasCompra(ListOferta, ProductoBase) {

  ListOferta.forEach(element => {
    var OBJ=JSON.parse(JSON.stringify(element))
  OBJ.ProductoBase = ProductoBase
  OBJ.DescValor = 0
  OBJ.ProductoValidado = true
  OBJ.RecarValor = 0
  OBJ.ValorPorc = false
  OBJ.ValorPorcRecarg = 0
  OBJ.cobertura = 0
  OBJ.descuento = 0
  OBJ.descuentoKeys = 0
  OBJ.recargo = 0
  OBJ.descripcion = OBJ.productos.descripcion.trim() + " " + OBJ.unidades.descripcion.trim()
  OBJ.valor = 0
  OBJ.paramEspecial = false,
  OBJ.paramOfert = true
  const index = this.RProductoCompra.indexOf(ProductoBase);
  // console.log('--index---')
  // console.log(index)
  if(index==-1){
    this.RProductoCompra.splice(1, 0,OBJ)
  }else{
    this.RProductoCompra.splice(index+1, 0,OBJ)
  }
  
 // this.RProductoCompra.push(element)
});


},


/*DataRetornCierre(cierre){
  this.CierresCajas.cierrescajasPK=JSON.parse(JSON.stringify(cierre.cierrescajasPK))
},*/

DataRetornGeneral(data){
  //Datos del cierre
if(data.opcion==='DataCierre'){
  this.CierresCajas.cierrescajasPK=JSON.parse(JSON.stringify(data.datos.cierrescajasPK))
}
//Datos del AplicarRestaurarFact
if(data.opcion==='AplicarRestaurarFact'){
  this.RProductoCompra=[]
  this.limpiarTodoCompleto();
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.fecha=data.datos.headerFactura.fecha
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.moneda=data.datos.headerFactura.moneda
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.cliente=data.datos.headerFactura.cliente
  if(data.datos.headerFactura.cliente!=null){
      this.$refs.RefPvInformacionHeaderFactura.getChangeClienteBusqueda(data.datos.headerFactura.cliente)
  }
var _this=this;
setTimeout(function(){ 
  _this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  _this.$refs.RefPvInformacionHeaderFactura.headerFactura.nombreFactTem=data.datos.nombreFactTem
  _this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  _this.$refs.RefPvInformacionHeaderFactura.DataRetornInfHeaderFactura()
}, 300);

  
  /*this.$refs.RefPvInformacionHeaderFactura.headerFactura.nombreFactTem=data.datos.nombreFactTem
  this.headerFactura.nombreFactTem=data.datos.nombreFactTem*/
  this.deleteFacturaTemporal=data.datos.deleteFacturaTemporal
  data.datos.ListDetalleFactTemp.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      const result1 =  await this.DataRetornAgregarProducto(PRODUCTO);
    })()
  });

}
//Datos del Retenciones Gub

if(data.opcion==='DataRetencionesGub'){
  this.Retencionfactura=JSON.parse(JSON.stringify(data.datos))

  var _this=this;
    setTimeout(function(){
      _this.calcularTotalesFactura()
}, 300); 
  
}
//Datos ConvertirCotizacionAfactura
if(data.opcion==='convertirCotizacionAfactura'){
  this.limpiarTodoCompleto();
this.RProductoCompra=[]
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.fecha=data.datos.headerFactura.fecha
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.moneda=data.datos.headerFactura.moneda
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.cliente=data.datos.headerFactura.cliente
  if(data.datos.headerFactura.cliente!=null){
     this.$refs.RefPvInformacionHeaderFactura.getChangeClienteBusqueda(data.datos.headerFactura.cliente)
  }
  data.datos.listCotizDetalle.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      const result1 =  await this.DataRetornAgregarProducto(PRODUCTO);
    })()
  });



}


//Datos convertirConduceAfactura
if(data.opcion==='convertirConduceAfactura'){
  this.limpiarTodoCompleto();
  this.conduceSelect=data.datos.conduceSelect
this.RProductoCompra=[]
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.fecha=data.datos.headerFactura.fecha
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.moneda=data.datos.headerFactura.moneda
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.cliente=data.datos.headerFactura.cliente
  if(data.datos.headerFactura.cliente!=null){
     this.$refs.RefPvInformacionHeaderFactura.getChangeClienteBusqueda(data.datos.headerFactura.cliente)
  }
  data.datos.listConduceDetalle.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      const result1 =  await this.DataRetornAgregarProducto(PRODUCTO);
    })()
  });



}


//Datos del GuardarTemporal(
if(data.opcion==='DataGuardarTemporal'){
  this.OpenDialogGuardartemporal()
}

//Datos del DataSalvarFactura
  if(data.opcion==='DataSalvarFactura'){
    if (this.RProductoCompra.length <= 0) {
        this.alerta("agrege al menos un producto a la lista", "error");
        return 0;
      }
      if (!this.headerFactura.documento.hasOwnProperty('codigo')) {
        this.alerta("Debe seleccionar un documento", "error");
        return;
      }
      if(this.modofactura==="salvarNuevo"){
        this.validarTipoDocumentoFactura()  
      }

      if(this.modofactura==="modificar"){
        this.editarFactura()  
      }
       
  }

//Datos del DataSalvarFactura
if(data.opcion==='DataEditarFactura'){
  this.modofactura="modificar"
  }

//Datos del DataSalvarFactura
if(data.opcion==='DataNuevaFactura'){
  this.limpiarTodoCompleto();

  }

//Datos del DataSalvarFactura
if(data.opcion==='selecionarFacturaBuscada'){
this.RProductoCompra=[]
 this.$refs.RefPvInformacionHeaderFactura.headerFactura.fecha=data.datos.headerFactura.fecha
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.moneda=data.datos.headerFactura.moneda
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.cliente=data.datos.headerFactura.cliente
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.documento=data.datos.headerFactura.documento
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.ncf=data.datos.headerFactura.ncf
  if(data.datos.headerFactura.cliente!=null){
     this.$refs.RefPvInformacionHeaderFactura.getChangeClienteBusqueda(data.datos.headerFactura.cliente)
  }

  if(data.datos.factura.efectivo>0 && data.datos.factura.cxc>0){
    this.disabledEfectivo=false
    this.disabledCredito=false
  }else{
    if(data.datos.factura.efectivo>0){ this.disabledCredito=true}
    if(data.datos.factura.cxc>0){ this.disabledEfectivo=true}
  }

  var _this=this;
setTimeout(function(){ 
  _this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  _this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  _this.Facturasventa=data.datos.factura
  _this.$refs.RefPvInformacionHeaderFactura.headerFactura.diascredito=data.datos.headerFactura.diascredito
  _this.$refs.RefPvInformacionHeaderFactura.DataRetornInfHeaderFactura()
  _this.bononesAccion()
}, 300);
  data.datos.listDetalle.forEach( async (element) => {
    (async () => { 
      const result1 =  await this.DataRetornAgregarProducto(element);
    })()
  });

  }


//Datos del DataAnularFactura
if(data.opcion==='DataAnularFactura'){
  this.dialogAnularFactura=true
  // console.log(this.Facturasventa)
  // this.AnulacionFacturaSeleccionada = JSO.parse(JSON.stringify())
  }
  
//Datos del convertirPedidoAfactura
if(data.opcion==='convertirPedidoAfactura'){
this.limpiarTodoCompleto();
this.RProductoCompra=[]
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.fecha=data.datos.headerFactura.fecha
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.moneda=data.datos.headerFactura.moneda
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.cliente=data.datos.headerFactura.cliente
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.documento=null
  this.Pedidosfacturacion=JSON.parse(JSON.stringify(data.datos.pedidoFactura))
  this.Facturasventa.comentario= data.datos.pedidoFactura.comentario
 
  if(data.datos.headerFactura.cliente!=null){
     this.$refs.RefPvInformacionHeaderFactura.getChangeClienteBusqueda(data.datos.headerFactura.cliente)
  }
  data.datos.listDetalle.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      const result1 =  await this.DataRetornAgregarProducto(PRODUCTO);
    })()
  });
}

//Datos del convertirPedidoAfactura
if(data.opcion==='EditarPedidoAfactura'){
  console.log('EditarPedidoAfactura')
  console.log(data)
this.limpiarTodoCompleto();
this.RProductoCompra=[]
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.fecha=data.datos.headerFactura.fecha
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.moneda=data.datos.headerFactura.moneda
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.tipocomprobante=data.datos.headerFactura.tipocomprobante
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.vendedor=data.datos.headerFactura.vendedor
  this.$refs.RefPvInformacionHeaderFactura.headerFactura.cliente=data.datos.headerFactura.cliente
  this.Facturasventa.comentario= data.datos.pedidoFactura.comentario
  if(data.datos.headerFactura.cliente!=null){
     this.$refs.RefPvInformacionHeaderFactura.getChangeClienteBusqueda(data.datos.headerFactura.cliente)
  }
  

this.$refs.RefPvInformacionHeaderFactura.headerFactura.documento=data.datos.headerFactura.documento
var _this=this;
  setTimeout(function(){  
    _this.Pedidosfacturacion=JSON.parse(JSON.stringify(data.datos.pedidoFactura))
    _this.$refs.RefPvToolbarContenidoAccion
    var OBJ={
        documento:data.datos.headerFactura.documento,
        secuencia:data.datos.pedidoFactura.idpedido
      }
      _this.$refs.RefPvInformacionHeaderFactura.getInformacionFactura(OBJ)
      _this.$refs.RefPvToolbarContenidoAccion.botonesAccion.anularPedido=true
  
  }, 1000);

  

  data.datos.listDetalle.forEach( async (element) => {
    (async () => { 
      var PRODUCTO=JSON.parse(JSON.stringify(element))
      const result1 =  await this.DataRetornAgregarProducto(PRODUCTO);
    })()
  });
}
  
//Datos del DataAnularFactura
if(data.opcion==='DataAnularPedidoFactura'){
  var link=`${this.Pedidosfacturacion.idpedido}/`
     this.$axios
          .get(
            this.$hostname + this.$hName + "/pedidosfacturacion/facturaconpedido/" + link,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if(res.data==true){
                    this.alerta("Este pedido no puede ser anulado, porque tiene factura de venta aplicada", "error");
                    return
                  }else{
                    this.loading(true)
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/pedidosfacturacion/anular/" + this.Pedidosfacturacion.idpedido +"/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.alerta(res.data.mensage, res.data.estado);
        })
        .catch((error) => {
          this.alerta(error, "error");
          this.loading(false)
        });
      }
      })
          .catch((error) => {
            console.error(error);
          });
     }


  // console.log(this.Facturasventa)
  // this.AnulacionFacturaSeleccionada = JSO.parse(JSON.stringify())
  
  
},

validarTipoDocumentoFactura(){
  // credito,contado,pedido
   var TIPO_DOC='contado'
  //  var TIPO_DOC='pedido'

  if(this.headerFactura.documento!=null && this.headerFactura.documento.operacion.codigo==37){
    this.salvarPedidofactura()
  }else{
    this.calcularDevuelta()
    this.openFormaDepago()
  }

  // if(TIPO_DOC==='contado'){}
  
    
},

openFormaDepago(){
this.dialogFormaDepago=true
this.RedireccionFocus('RefTextFieldForPagoEfectivo')
},

OpenDialogGuardartemporal() {
      if (this.RProductoCompra.length == 0) {
        this.alerta("Debe seleccionar al menos un producto", "error");
        return;
      }

      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccGuardartemporal')
        return;
      }

      var vend = this.$store.getters.GetConfiguracionGeneral.configPv.vendedorobligatorio
      
      if (vend == true && this.headerFactura.vendedor == null) {
        this.alerta("Debe seleccionar un vendedor", "error");
        return;
      }

      if(this.headerFactura.vendedor!=null){ this.Facturasventa.vendedor = this.headerFactura.vendedor  }
      
      if (this.headerFactura.cliente != null) {
        this.nombreFactTem = this.headerFactura.cliente.nombre;
        if (this.cambiarDestinoSucursal == true && this.sucursalFactTemporal == null) {
          this.dialogGuardartemporal = true;
          this.RedireccionFocus('RefTextFieldNombreFactTem')
          // this.focusRefTextFieldNombreFactTem()
          return
        }

        var _this = this;
        setTimeout(function () {
          _this.guardartemporal();
        }, 500);



      } else {
        // this.focusRefTextFieldNombreFactTem()
        this.RedireccionFocus('RefTextFieldNombreFactTem')
        if(this.headerFactura.hasOwnProperty('nombreFactTem')){
          this.nombreFactTem =JSON.parse(JSON.stringify(this.headerFactura.nombreFactTem)) 
        } 
        this.dialogGuardartemporal = true;
      }
    },


// Guardando los datos en la base de datos de factura temporal.
async guardartemporal() {


 this.Facturasventa.facturastemporalesDetalle = [];
if (this.RProductoCompra.length == 0) {
  this.alerta("Debe seleccionar al menos un producto", "error");
  return;
}

// const result = await this.ConvertirMonedaRd();
// var totalGeneral = JSON.parse(JSON.stringify(result.totalGeneral))
// var ListDet = JSON.parse(JSON.stringify(result.ListDet))
const result = await this.detalleFactTemporal();
var ListDet = JSON.parse(JSON.stringify(result))

if (this.ejecucion == true) {
  this.ejecucion = false;
  this.Facturasventa.fecha =this.headerFactura.fecha+"T04:00";
var objfact = JSON.parse(JSON.stringify(this.Facturasventa));
  objfact.fecha=this.headerFactura.fecha+"T04:00";
  if (this.headerFactura.cliente != null) {
    objfact.cliente = this.headerFactura.cliente;
    objfact.nombreDgii = this.headerFactura.cliente.nombre
  objfact.cedulaDgii = this.headerFactura.cliente.cedrnc
  objfact.telefonoDgii = this.$refs.RefPvInformacionHeaderFactura.selectTelefonoCliente(this.headerFactura.cliente)
  objfact.direccionDgii = this.headerFactura.cliente.direccion
  }

  objfact.moneda = this.headerFactura.moneda
  objfact.tasa = this.headerFactura.moneda.tasa
  objfact.caja = this.caja
  objfact.sucursal = this.headerFactura.sucursal;

  //this.Facturasventa.valordescuento=this.Descuentofactura.descuento
  objfact.descripcion = this.nombreFactTem;
  //this.Totales.totalGeneral.total;
  
  objfact.valor = this.Totales.totalGeneralFooter.valorneto;
  objfact.valorimpuesto = this.Totales.totalGeneralFooter.itbis;
  objfact.valordescuento = this.Totales.totalGeneralFooter.descuento;
  objfact.retencion = this.Totales.totalGeneralFooter.Retencion;
  objfact.facturastemporalesDetalleList = ListDet;
  
  objfact.valorRecargo = 0
  objfact.cobertura = 0
  objfact.coberturaPendiente = 0

   
  // objfact.direccionDgii = this.Facturasventa.direccionncf

 /* 
  objfact.valorRecargo = totalGeneral.recargo;
  objfact.cobertura = totalGeneral.cobertura;
  objfact.coberturaPendiente = totalGeneral.cobertura;
  objfact.nombreDgii = this.Facturasventa.nombreDgii
  objfact.cedulaDgii = this.Facturasventa.cedularncncf
  objfact.telefonoDgii = this.Facturasventa.telefononcf
  // objfact.direccionDgii = this.Facturasventa.direccionncf
   if (this.SelectMedicoFactura != null) {
    objfact.medicoCodigo = this.SelectMedicoFactura
  }
  */
  
  

  /*if(this.tipoTarjeta!=null && this.tipoTarjeta.hasOwnProperty('codigo') ){
    objfact.tipotar=this.tipoTarjeta.codigo
  }*/

  if (this.headerFactura.tipocomprobante == null) {
    objfact.tipocomprobante = this.configuraciones.configPv.comprobantefacturacion;
  } else {
    objfact.tipoComprobante = this.headerFactura.tipocomprobante.codigo
  }
  objfact.hora = new Date()

  if (this.cambiarDestinoSucursal == true) {
    objfact.sucursal = this.sucursalFactTemporal.codigo
  }

  if (this.headerFactura.vendedor != null && this.headerFactura.vendedor.hasOwnProperty('codigo')) {
    objfact.vendedor = this.headerFactura.vendedor
  }


  if (this.deleteFacturaTemporal != null) {
    this.deleRestaurarFact(this.deleteFacturaTemporal);
  }
  objfact.enUso=false
  this.loading(true)
  this.$axios
    .post(this.$hostname + this.$hName + "/temporales/save", objfact, {
      headers: this.$store.getters.GetheadersAxios,
    })
    .then((res) => {
      this.alerta("Guardado corretamente...", "bien");
    })
    .catch((error) => this.alerta(error, "error"));
}
},


detalleFactTemporal(){
  var RObjProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompra));
  return new Promise((resolve, reject) => {
  
    var ListDet = []
        // this.RProductoCompraTemp.forEach((dato) => {
          RObjProductoCompra.forEach(async (dato) => {
            (async () => { 
          var detalle = {
            facturastemporalesDetallePK: {
              secuencia: 0,
              producto: dato.productos.codigo,
              unidad: dato.unidades.codigo,
              // id: this.RProductoCompraTemp.indexOf(dato),
              id: RObjProductoCompra.indexOf(dato),
            },
            almacen: this.caja.almacen.codigo,
            cantidad: dato.cantidad,
            valordescuento: dato.valordescuento,
            valorimpuesto: dato.valorimpuesto,
            precio: dato.precio,
            descuento: dato.descuento,
            codigocupon: null,
            valorcupon: 0.0,
            cantidadPendiente: dato.cantdad,
            oferta: null,
            valorRecargo: 0.0,
            recargo: 0.0,
            descripcion: dato.descripcion,
            costounidad: dato.costo,
            cantidadDespachada: dato.cantidadDespachada,
            borrado: false,
            cobertura: 0,
            impuesto: dato.impuesto,
            productos: dato.productos,
            unidades: dato.unidades,
            vendedor: dato.vendedor,
          };
         /* if(!dato.hasOwnProperty('valordescuento')&& dato.DescValor > 0 ){
            detalle.valordescuento=dato.DescValor
          }
          if(!dato.hasOwnProperty('impuesto') && dato.hasOwnProperty('valorimpuesto') && dato.valorimpuesto > 0 ){
            detalle.impuesto=dato.valorimpuesto
          }*/

          ListDet.push(detalle);
        })()
        });

        resolve(ListDet)
  });
},

 // Hacer una solicitud de publicación al servidor para borrar la factura temporal.
 deleRestaurarFact(e) {
      this.$axios
        .post(this.$hostname + this.$hName + "/temporales/borrado/", e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          // this.alerta(res.data.mensage, res.data.estado);
          // this.RestaurarFacturaBusqueda.buscar=""
        })
        .catch((error) =>
          this.alerta("Dato no eliminado correctamente.", "error")
        );
    },


// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},
DataRetornMenuOpcBotonesPermisosPv(){

},

DataRetornPadrePermisoPv(e) {
//imprimir Guardar temporal
if (e == 'AccGuardartemporal') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.OpenDialogGuardartemporal()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
},

finalizarGuardartemporalKeyCtrl(e){
      
      if (e.keyCode ==17 ) {
           this.ValidarFomsguardartemporal()
        }
  
  
      },

cancelarGuardarTemporal(){
  this.dialogGuardartemporal = false,
  this.nombreFactTem = ''
},

ValidarFomsguardartemporal() {
      // this.Facturasventa.facturastemporalesDetalle = [];
      if (this.RProductoCompra.length == 0) {
        this.alerta("Debe seleccionar al menos un producto", "error");
        return;
      }

      if (!this.$refs.formNombreFactTem.validate()) {
        this.alerta("Los compos en rojos son requerido", "error");
        return;
      }

      this.guardartemporal()

    },


CargarRSucursal() {

      this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.RSucursal = JSON.parse(JSON.stringify(res.data));
      });

    },

    SelctCbSucursal() {
      var _this = this;
      setTimeout(function () {
        if (_this.sucursalFactTemporal == null) {
          _this.sucursalFactTemporal = "";
        }

        if (!_this.sucursalFactTemporal.hasOwnProperty('codigo')) {
          _this.sucursalFactTemporal = "";
          _this.sucursalFactTemporal = null;
          return;
        }
      }, 300);
    },

    limpiarTodoCompleto(){
      this.deleteFacturaTemporal=null
      this.sucursalFactTemporal = null;
      this.ejecucion = true;
      this.cancelarGuardarTemporal()
      this.$refs.RefPvInformacionHeaderFactura.cargaInicial()
      this.RProductoCompra=[]
      this.Totales={
  totalGeneralFooter: {
        subtotal: 0,
        descuento: 0,
        total: 0,
        itbis: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
        valorneto:0
      },

 }
 this.disabledEfectivo=false
this.disabledCredito=false
 this.modofactura='salvarNuevo'
 this.dialogFormaDepago = false
 this.Facturasventa = {
        facturasventaPK: { secuencia: 0, documento: 0 },
        empresa: 1,
        fecha: new Date(),
        referencia: "",
        diascredito: 0,
        ncf: "",
        valor: 0.0,
        tasa: 1,
        comentario: "",
        valordescuencto: 0.0,
        valorimpuesto: 0.0,
        pendiente: 0.0,
        activada: true,
        efectivo: 0.0,
        tarjeta: 0.0,
        cheques: 0.0,
        cxc: 0.0,
        puntos: 0.0,
        valorConduce: 0.0,
        cupones: 0.0,
        cedularncncf: "",
        direccionncf: "",
        telefononcf: "",
        codigocupon: "",
        numeroCheque: "",
        nombreDgii: "",
        documentoFactura: null,
        umerocierre: 0,
        autorizacion: null,
        cobertura: null,
        medico: null,
        exequartur: null,
        diagnostico: null,
        especialidad: null,
        enviada: false,
        glosada: false,
        coberturaPendiente: null,
        arsCobrarEmpleado: false,
        arsIncobrable: false,
        arsComentario: null,
        usuario: "",
        usuarioCobro: "",
        fechaImpresion: null,
        nif: null,
        horaImpresion: null,
        receta: null,
        valorRecargo: 0,
        descGloblal: true,
        recgGlobal: false,
        numtar: null,
        valorNc: 0.0,
        codigoNc: 0,
        secuenciaNc: 0,
        retencion: 0.0,
        sumado: false,
        ncfVencimiento: null,
        medicoCodigo: null,
        totalIsr: 0.0,
        totalOtroImpuesto: 0,
        motivoAnular: null,
        facturaManual: false,
        descripcion: "",
        numeroOrden: null,
        lineaAerea: null,
        numeroVuelo: null,
        rutaSalidaE: null,
        rutaSalidaD: null,
        rutaRetornoE: null,
        rutaRetornoD: null,
        turno: null,
        gaspremium: 0.0,
        valordonacion: 0.0,
        valorbonogas: 0.0,
        valorasignacion: 0.0,
        valorcomision: 0.0,
        valorotros: 0.0,
        borrado: false,
        fechaIngreso: null,
        fechaSalida: null,
        tipoAsistencia: null,
        numeroHabitacion: null,
        facturaRecurrente: false,
        configuracionDocumentoRecurrente: 0,
        valorTraspaso: null,
        valorPruebaMetro: null,
        detalleJson: "",
        generadaDesdeEnviosPaquetes: false,
        generadaDesdeBoleteria: false,
        conduceSecuencia: null,
        conduceDocumento: null,
        shipto: null,
        incoterms: null,
        pagare: null,
        pagareValor: null,
        usuariosAnulacion: null,
        fechaAnulacion: null,
        usuariosEditar: null,
        fechaEdicion: null,
        bancoCheque: null,
        ars: null,
        caja: null,
        cliente: null,
        clinica: null,
        impuesto: null,
        moneda: null,
        sucursal: null,
        tipoTarjeta: null,
        tipocomprobante: null,
        vendedor: null,
        contabilidad: null,
        transferencia: 0.0,
        domicilio: null,
        facturasventaDetalleList: [],
        datoTarjeta: null,
      };
      this.activaConduceCredito=false
      this.conduceSelect=null
      this.dialogAnularFactura=false
      this.TipoAnulacion=null
      this.Pedidosfacturacion=null
      this.$refs.RefPvToolbarContenidoAccion.botonesAccion.anularPedido=false
      this.loading(false)
      this.bononesAccion()

  var _this=this;
  setTimeout(function(){ 
    _this.$refs.RefPvInformacionHeaderFactura.changeDocumentoInfFact(_this.headerFactura.documento)
   }, 300);

     

    },


    // Tomando el valor de la entrada y sumándolo al valor de la variable para saver el valor efectivo.
    enterEfectivo() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.efectivo)
        );
      }
      this.Facturasventa.efectivo = Objefc;
      //this.Facturasventa.efectivo = this.Totales.totalGeneral.total
      this.calcularDevuelta();
    },

    // Un método que se llama cuando el usuario abandona el campo de entrada.
    BlurEfectivoPago() {
      if (this.Facturasventa.efectivo < 0) {
        this.Facturasventa.efectivo = 0
        this.calculostotales()
      }
    },
 // Tomando el valor de la entrada y agregándolo al objeto del pago de tarjeta.
    enterTarjeta() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.tarjeta)
        );
      }

      this.Facturasventa.tarjeta = Objefc;
      this.calcularDevuelta();
    },

      BlurTarjetaPago() {
      if (this.Facturasventa.tarjeta < 0) {
        this.Facturasventa.tarjeta = 0
        this.calculostotales()
      }
    },

// Poniendo el valor de la variable PanelFormaPagoTarjeta verdadero si los datos de la tarjeta no es correcto.
    OpenPanelFormaPagoTarjeta() {
      this.defoulFormaPagoPanel();
      this.PanelFormaPagoColmna2 = false;
      this.PanelFormaPagoTarjeta = true;
    },

     // Tomando el valor de la entrada de cheques y sumándolo al valor de la entrada devuelta.
     enterCheque() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.cheques)
        );
      }
      this.Facturasventa.cheques = Objefc;
      this.calcularDevuelta();
    },

    // Abriendo un panel en un formulario cheque.
    OpenPanelFormaPagoCheque(e) {
      this.defoulFormaPagoPanel();
      this.NombreFormaPagoCheqTras = e;
      this.PanelFormaPagoColmna2 = false;
      this.PanelFormaPagoCheque = true;
    },

    // Un método que se llama cuando el usuario abandona el campo de entrada.
    BlurChequesPago() {
      if (this.Facturasventa.cheques < 0) {
        this.Facturasventa.cheques = 0
        this.calculostotales()
      }
    },


// convierte la devuelta de la tranferencia para poner el monto restante a el campo.
    enterTransferencia() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.transferencia)
        );
      }

      this.Facturasventa.transferencia = Objefc;
      this.calcularDevuelta();
    },

    BlurTransferenciaPago() {
      if (this.Facturasventa.transferencia < 0) {
        this.Facturasventa.transferencia = 0
        this.calculostotales()
      }
    },

    condicionByPago() {
      if (this.headerFactura.cliente != null && this.headerFactura.cliente.hasOwnProperty("codigo")) {
        if (this.headerFactura.cliente.diascredito == null || this.headerFactura.cliente.diascredito == 0) {
            this.alerta("Para hacer esta operación necesita elegir un cliente a credito", "error");
            this.RedireccionFocus('RefTextFieldForPagoEfectivo')
            return ;
          }
      }else{
        this.alerta("Seleccione un cliente a credito","error")
        this.RedireccionFocus('RefTextFieldForPagoEfectivo')
        return;
        // this.filtroTipoCliente = 1
        // this.OpenDialogCrearEditarCliente();

      }

    },
    // Tomando el valor del campo de entrada y agregándolo al valor del campo cxc completand el valor del faltante.
    enterCxc() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.cxc)
        );
      }
      this.Facturasventa.cxc = Objefc;
      this.calcularDevuelta();
    },

    BlurCxcPago() {
      if (this.Facturasventa.cxc < 0) {
        this.Facturasventa.cxc = 0
        this.calculostotales()
      }
    },


    //  convierte la devuelta de la cupones para poner el monto restante a el campo faltante.
    enterCupones() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.cupones)
        );
      }
      this.Facturasventa.cupones = Objefc;
      this.calcularDevuelta();
    },

    BlurCuponesPago() {
      if (this.Facturasventa.cupones < 0) {
        this.Facturasventa.cupones = 0
        this.calculostotales()
      }
    },
    // convierte la devuelta de la conduce para poner el monto restante a el campo faltante.
    enterConduce() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.valorConduce)
        );
      }
      this.Facturasventa.valorConduce = Objefc;
      this.calcularDevuelta();
    },

    BlurConducePago() {
      if (this.Facturasventa.valorConduce < 0) {
        this.Facturasventa.valorConduce = 0
        this.calculostotales()
      }
    },

    // Activa conduce a credito
    btnActivaInactivaConduceCredito() {
      this.activaConduceCredito = !this.activaConduceCredito

      if (this.activaConduceCredito == true) {
        this.Facturasventa.efectivo = 0
        this.Facturasventa.tarjeta = 0
        this.Facturasventa.cheques = 0
        this.Facturasventa.transferencia = 0
        this.Facturasventa.cxc = 0
        // this.Facturasventa.cupones=0
        this.Facturasventa.valorNc = 0
        this.Facturasventa.numtar = null
        this.tipoTarjeta = null
        this.bancoCheque = null
        this.Facturasventa.numeroCheque = ""
      }

      this.calculostotales()


    },

    //  //  convierte la devuelta de la Nota de credito para poner el monto restante a el campo faltante.
    enterNC() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.valorNc)
        );
      }
      this.Facturasventa.valorNc = Objefc;

      this.calcularDevuelta();
    },

    OpenPanelFormaPagoNotacredito() {
      this.CargarNotacreditocontado()
      this.defoulFormaPagoPanel();
      this.PanelMenuOpcionBotones = false;
      this.PanelFormaPagoNotaCredito = true;

    },

    CargarNotacreditocontado() {

if (this.PanelFormaPagoNotaCredito == false) {
  var fe = this.fechaNueva()
  this.$axios.get(this.$hostname + this.$hName +
    `/devolucion/notacreditocontado/${fe}/null`,
    { headers: this.$store.getters.GetheadersAxios }
  )
    .then((res) => {
      this.ListFormaPagoNC = res.data.ListNotacreditoContado
    });
}

    },

    BlurNotaCreditoPago() {
      if (this.selectedListFPagoNC.length > 0) {

        if (this.Facturasventa.valorNc > this.selectedListFPagoNC[0].pendiente) {
          this.alerta("no puede digitar un valor más alto del valor pendiente de la nota de crédito", "error");
          this.Facturasventa.valorNc = JSON.parse(JSON.stringify(this.selectedListFPagoNC[0].pendiente))
        }

      }
      this.calculostotales()

    },

    BlurValorNcPago() {
      if (this.Facturasventa.valorNc < 0) {
        this.Facturasventa.valorNc = 0
        this.calculostotales()
      }
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RTipoTarjeta.
    cargarTipoTarjeta() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tarjeta/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RTipoTarjeta = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoTarjeta() {
      var _this = this;
    setTimeout(function () {
  if (_this.tipoTarjeta == null) {
    _this.tipoTarjeta = "";
  }

  if (!_this.tipoTarjeta.hasOwnProperty('codigo')) {
    _this.tipoTarjeta = "";
    _this.tipoTarjeta = null;
    this.alerta("Seleccione un Tipo de tarjeta", "error");
  }
  return;
}, 300);

    },


    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RBanco.
    cargarBanco() {
      this.$axios
        .get(this.$hostname + this.$hName + "/bancos/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RBanco = res.data));
    },
    // Comprobando si el valor de la selección no está definido.
    SelctCbBanco() {

      var _this = this;
    setTimeout(function () {
  if (_this.bancoCheque  == null) {
    _this.bancoCheque  = "";
  }

  if (!_this.bancoCheque .hasOwnProperty('codigo')) {
    _this.bancoCheque  = "";
    _this.bancoCheque  = null;
    this.alerta("Seleccione un banco", "error");
  }
  return;
}, 300);
    },

    CbFilNotacreditocontado(e) {

if (e.target.value.length == 0) {
  this.CargarNotacreditocontado()
} else {
  this.$axios
    .get(
      this.$hostname +
      this.$hName +
      "/devolucion/notacreditocontado/null/" + e.target.value,
      { headers: this.$store.getters.GetheadersAxios }
    )
    .then((res) => {
      this.ListFormaPagoNC = res.data.ListNotacreditoContado
    });

}

},
    obtenerDatoSelectFormaPagoNC(e) {
      if (e.value == true) {
        this.obtenerDatoSelectFormaPagoNCClickRow(e.item);
      }
    },
    obtenerDatoSelectFormaPagoNCClickRow(e) {
      this.selectedListFPagoNC = [];
      this.selectedListFPagoNC.push(e);
      if (this.Facturasventa.valorNc == 0) {
        this.Facturasventa.valorNc = JSON.parse(JSON.stringify(e.pendiente))
      }

      /* if (this.Facturasventa.valorNc > e.valor) {
         this.Facturasventa.valorNc = JSON.parse(JSON.stringify(e.pendiente))
       }*/

      // if (this.Totales.totalGeneral.total > e.valor) {
      if (this.Totales.totalGeneralFooter.valorneto > e.valor) {
        this.Facturasventa.valorNc = JSON.parse(JSON.stringify(e.pendiente))
      } else {
        // this.Facturasventa.valorNc = JSON.parse(JSON.stringify(this.Totales.totalGeneral.total))
        this.Facturasventa.valorNc = JSON.parse(JSON.stringify(this.Totales.totalGeneralFooter.valorneto))
      }


      this.calculostotales()
      //this.Facturasventa.
    },

    // Establecer el valor de las variables en verdadero o falso.
    defoulFormaPagoPanel() {
      this.PanelFormaPagoColmna1 = true;
      this.PanelFormaPagoColmna2 = true;
      this.PanelFormaPagoTarjeta = false;
      this.PanelFormaPagoCheque = false;
      this.PanelMenuOpcionBotones = true;
      this.PanelFormaPagoNotaCredito = false;
    },

     // Cálculo del cambio a devolver al cliente.
    calcularDevuelta() {
      var tot = this.RedondeoBasico(
        this.Facturasventa.efectivo +
        this.Facturasventa.cxc +
        this.Facturasventa.cupones +
        this.Facturasventa.puntos +
        this.Facturasventa.transferencia +
        this.Facturasventa.valorNc +
        this.Facturasventa.cheques +
        this.Facturasventa.valorConduce +
        this.Facturasventa.tarjeta)
      // this.devuelta =Math.round((tot - this.Totales.totalGeneral.total) * 100) / 100;
      this.devuelta =Math.round((tot - this.Totales.totalGeneralFooter.valorneto) * 100) / 100;
    },

    calculostotales() {
      if (this.Facturasventa.efectivo > 0) {
      }
      if (this.Facturasventa.cxc > 0) {
      }
      this.calcularDevuelta();
    },

     // Esperando a que el usuario haga clic en el botón para inicial el proceso de cobro de factura.
     async realizarCobro() {

if (this.caja != null) {
  if (this.caja.cobradora == false) {
    this.alerta("En esta caja no esta permitido cobrar", "error");
    return 0;
  }
} else {
  this.alerta("Seleccione una caja", "error");
  return 0;
}

var vend = this.$store.getters.GetConfiguracionGeneral.configPv.vendedorobligatorio
var Medic = this.$store.getters.GetConfiguracionGeneral.configPv.usarMedico
var comentarioObligatorio=this.$store.getters.GetConfiguracionGeneral.configPv.comentarioObligatorio
this.Facturasventa.vendedor=JSON.parse(JSON.stringify(this.headerFactura.vendedor))  
if (vend == true && this.Facturasventa.vendedor == null) {
  this.alerta("Debe seleccionar un vendedor", "error");
  return 0;
}

if (comentarioObligatorio == true && this.Facturasventa.comentario.trim().length==0 ) {
  this.alerta("Debe colocar un comentario a esta factura", "error");
  // this.OpenComentarioFactura()
  return 0;
}

if(this.headerFactura.documento.operacion.codigo==35 && this.Facturasventa.valorConduce==0){
  this.alerta("Esta utilizando un documento conduce, debe de colocar al menos un valor en el campo conduce ", "error");
  return 0;
}

/*
if (Medic == true && this.SelectMedicoFactura == null) {
  this.alerta("Debe seleccionar un medico", "error");
  return 0;
}

if(this.Totales.totalGeneral.cobertura>0 && this.clienteFactura.ars==null ){
this.alerta("Debe asignarle una ars a  el cliente seleccionado.", "error");
return 0 ;
}*/


this.loading(true)
// const result = await this.ActualizarProductPago();
// this.loading(false)
var _this = this;
setTimeout(function () {
   

   if(_this.modofactura==="salvarNuevo"){
    _this.ContrealizarCobro(); 
      }

      if(_this.modofactura==="modificar"){
        _this.contEditarFactura()  
      }

}, 900);



},

// Comprobando si el pago es mayor al total de la factura.
async ContrealizarCobro() {
      //var moneda=this.$store.getters.GetOtrasConfigPv.moneda
      if (this.RProductoCompra.length <= 0) {
        this.alerta("agrege al menos un producto a la lista", "error");
        return 0;
      }

      if (this.UsarLote == true) {
        var ObjRow = this.RProductoCompra.filter((ee) => ee.lote.length == 0);
        if (ObjRow.length > 0) {
          var msj = `Esta utilizando la configuración de lote, y este producto:#${ObjRow[0].indx + 1}-(${ObjRow[0].descripcion}),no tiene lote asignado`
          this.alerta(msj, "error");
        }

      }

      if (this.devuelta < 0) {
        this.alerta(
          "El valor a pagar es menor que el valor de la factura",
          "error"
        );
        return 0;
      }

      if (this.Totales.totalGeneralFooter.valorneto == 0 && this.Totales.totalGeneralFooter.cobertura == 0) {
        return 0;
      }
      //console.log('---Verificar Redondeo---')
      //console.log(this.RedondeoBasico(2011.5786666666))
      //RedondeoBasico
      //currencyFormatter2
      //if(this.Facturasventa.efectivo<=0){}
      if (this.RedondeoBasico(this.Facturasventa.tarjeta) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Tarjeta ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cheques) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Cheques ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.transferencia) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Transferencia ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cxc) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Crédito(cxc) ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cupones) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' cupones ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.valorConduce) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' valor Conduce ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.valorNc) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Nota de crédito ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      var SumaTotal = this.RedondeoBasico(this.Facturasventa.tarjeta + this.Facturasventa.transferencia + this.Facturasventa.cheques + this.Facturasventa.cxc
        + this.Facturasventa.cupones + this.Facturasventa.valorConduce + this.Facturasventa.valorNc)

      if (SumaTotal > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("Las formas de pago sobre pasa el total de la factura  ", "error")
        return 0;
      }

    this.Facturasventa.cliente=JSON.parse(JSON.stringify(this.headerFactura.cliente))
      // if(this.headerFactura.cliente!=null){this.Facturasventa.cliente=JSON.parse(JSON.stringify(this.headerFactura.cliente)) }
      if (this.activaConduceCredito == true) {
        
        if (this.headerFactura.cliente == null) {
          this.alerta("Para hacer esta operación necesita elegir un cliente a credito", "error");
          return 0;
        } else {
          if (this.headerFactura.cliente.diascredito == null || this.headerFactura.cliente.diascredito == 0) {
            this.alerta("Para hacer esta operación necesita elegir un cliente a credito", "error");
            return 0;
          }
        }

        var CONDUC_DOCUM = this.$store.getters.GetConfiguracionGeneral.configCxc.documentoVenta

        if (CONDUC_DOCUM == null) {
          this.alerta("No tiene la configuración necesaria para hacer esta operación", "error");
          return 0;
        }

      }



      /*this.Facturasventa.efectivo*/

      if (this.Facturasventa.cxc > 0) {
        if (
          this.headerFactura.cliente.hasOwnProperty("suspensiondiascredito") &&
          this.headerFactura.cliente.suspensiondiascredito != null &&
          this.headerFactura.cliente.suspensiondiascredito == true
        ) {
          this.alerta("El Cliente Esta suspeniddo", "error");
          return 0;
        }

        if (this.headerFactura.cliente.diascredito == 0) {
          this.alerta(
            "El cliente seleccionado no es un cliente a credito",
            "error"
          );
          return 0;
        } else {
          if (
            this.headerFactura.cliente.limitecredito == null ||
            this.headerFactura.cliente.limitecredito == 0
          ) {
            this.alerta("El Cliente excede limite de credito ", "error");
            return 0;
          } else {
            var balancecliente = 0;
            if (
              this.headerFactura.cliente.balancecliente != null &&
              this.headerFactura.cliente.balancecliente.balance > 0
            ) {
              balancecliente = this.headerFactura.cliente.balancecliente.balance;
            }

            //var moneda=this.$store.getters.GetOtrasConfigPv.moneda
            //(App.client.selectedClient.balancecliente.balance / _this.util.exchangeRate) +
            //(_this.pago.creditValue / _this.util.exchangeRate) > App.client.selectedClient.limiteCredito)
            //---------validando balance del cliente
            var cliBalanc =
              balancecliente / this.headerFactura.moneda.tasa + this.Facturasventa.cxc;
            if (cliBalanc > this.headerFactura.cliente.limitecredito) {
              this.alerta("El Cliente excede limite de credito ", "error");
              return 0;
            }
          }
        }
      }


      if (this.Facturasventa.valorNc > 0) {

        if (this.selectedListFPagoNC.length == 0) {
          this.alerta("Debe seleccionar una nota de crédito para poder cobrar con nota de crédito", "error");
          return 0;
        }

        if (this.Facturasventa.valorNc >  this.Totales.totalGeneralFooter.valorneto) {
          this.alerta("El valor de pago nota de crédito es mayor al valor de la factura", "error");
          return 0;
        }

      }
      
      // && this.Facturasventa.tipoTarjeta==null
      // this.Facturasventa.numtar

      // ------- pagos con tarjeta de credito
      if (this.Facturasventa.tarjeta > 0) {
        if (!this.$refs.formTarjetaFormPago.validate()) {
          this.alerta("Los datos de la tarjeta son requeridos ", "error");
          this.OpenPanelFormaPagoTarjeta();
          return 0;
        }

        var lccaja = JSON.parse(localStorage.caja);
        if (lccaja.veryFoms == true) {

          if (this.tipoTarjeta.azul == true) {
            return 0;
          }
          if (this.tipoTarjeta.cardnet == true) {
            this.validarcarnet();
            return 0;
          }
          this.PostrealizarCobro();
        } else {
          this.PostrealizarCobro();
        }
      } else {
        this.PostrealizarCobro();
      }
    },

// Hacer una solicitud de publicación al servidor.
async validarcarnet() {
      this.loading(true)
      //http://localhost:22105/api/Prueba
      // this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>( this.Rmoneda=res.data))

      var num = "";
      num = (this.Facturasventa.tarjeta.toFixed(2) + "").replace(".", "");

      var obj = {
        amount: num,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: 0,
        referenceNumber: 0,
      };

      //var stobj='{"Status":"Successful","Host":{"Value":6,"Description":"Credit"},"Mode":{"Value":"C@5","Description":"Sale with CHIP(EMV)"},"Card":{"Product":"Mastercard","CardNumber":"534217******5024","HolderName":"SCOTIABANK/TARJETAHABIENTE"},"Transaction":{"AuthorizationNumber":"007656","Reference":2,"RetrievalReference":214611000062,"DataTime":"26/5/2022 11:04:40 a. m.","ApplicationIdentifier":"A0000000041010","LoyaltyDeferredNumber":null,"TID":null},"Batch":22,"TerminalID":"80029126","MerchantID":"349011300","Acquired":"CardNET","Reserved":null,"Signature":null}'

      this.$axios
        .post("http://localhost:22105/api/ProcessNormalSale", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          var e = JSON.parse(res.data);
          e.tipo = "Carnet";

          if (e.Status == "Successful") {
            this.Facturasventa.numtar = e.Card.CardNumber;
            this.Facturasventa.datoTarjeta = JSON.stringify(e);

            //   App.checkIn();
            this.alerta("Transacción finalizada correctamente", "bien2");
            var _this = this;
            setTimeout(function () {
              _this.PostrealizarCobro();
            }, 3000);
          }

          if (e.Status == "Failed") {
            this.alerta("Transacción cancelada", "error");
          }
        })
        .catch((error) => {
          this.alerta("Transacción cancelada", "error");
          this.loading(false)
        });
    },
    // Crear un nuevo objeto y empujarlo a un objto detalle de factura.
async llenarDetalleFactura(){

  /*this.RProductoCompra.forEach((d) => {
        d.valorimpuesto = 0;
        if (d.productos.incluirimpuestoenprecio == true) {
          if (d.productos.impuesto != null) {
            d.valorimpuesto =
              d.precio - d.precio / (1 + d.productos.impuesto.tasa / 100);
          }
        }
        if (d.productos.incluirimpuestoenprecio == false) {
          if (d.productos.impuesto != null) {
            d.valorimpuesto = d.precio * (d.productos.impuesto.tasa / 100);
          }
        }
        this.llenarDetalle(d);
      });*/


      return new Promise((resolve, reject) => { 
        var facturasventaDetalleList=[]
  this.RProductoCompra.filter((ee) => ee.paramOfert== false).forEach( async (dato) => {
    (async () => { 
      // var PRODUCTO=JSON.parse(JSON.stringify(element))
      var detalle = {
        facturasventaDetallePK: {
          documento: 0,
          secuencia: 0,
          producto: 0,
          unidad: 0,
          id: 0,
        },
        almacen: this.caja.almacen.codigo,
        cantidad: dato.cantidad,
        valordescuento: dato.valordescuento,
        valorimpuesto: dato.valorimpuesto,
        precio: dato.precio,
        descuento: dato.descuento,
        codigocupon: null,
        valorcupon: 0.0,
        cantidadPendiente: dato.cantdad,
        oferta: null,
        recargo: 0.0,
        descripcion: dato.descripcion,
        costounidad: dato.costo,
        cantidadDespachada: dato.cantidadDespachada,
        borrado: false,
        cobertura: 0,
        impuesto: null,
        productos: dato.productos,
        unidades: dato.unidades,
        vendedor: dato.vendedor,
        valorRecargo:  dato.hasOwnProperty("valorRecargo") && dato.valorRecargo != null ? dato.valorRecargo : 0,
        RecarValor:dato.hasOwnProperty("RecarValor") && dato.RecarValor != null ? dato.RecarValor : 0,
        ValorPorcRecarg:true
      };
      facturasventaDetalleList.push(detalle);      
    })()
  });
  resolve(facturasventaDetalleList)
});


},

async GetUltimoCierre(){
    return new Promise((resolve, reject) => { 
  (async () => { 
    this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.caja.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          resolve(res.data)
        });
  })()
});
},
// Creación de una nueva factura.
async PostrealizarCobro() {
      /* console.log('--Hora1--:')
       console.log(new Date())
       console.log('--Hora2--:')
       console.log(new Date().getTime())
        console.log('--Hora3--:')
       console.log(this.HoraFormatter2(new Date()))
       console.log('--Hora4--:')
       var hora =new Date() 
       console.log(hora.getHours() + ':' + hora.getMinutes() + ':' + hora.getSeconds())*/
       (async () => { 
      this.Facturasventa.tipocomprobante=JSON.parse(JSON.stringify(this.headerFactura.tipocomprobante))  
      //this.Facturasventa.fecha = new Date().valueOf()
      this.Facturasventa.fecha = this.headerFactura.fecha+"T04:00"
      var _this=this;
      if (this.bancoCheque != null) {
        this.Facturasventa.bancoCheque = this.bancoCheque.codigo;
      }
      if (this.tipoTarjeta) {
        this.Facturasventa.tipoTarjeta = this.tipoTarjeta.codigo;
      }
      this.Facturasventa.horaFactura = new Date().getTime()
      this.Facturasventa.facturasventaDetalleList = [];
     
      const result1 =  await this.llenarDetalleFactura();
      this.Facturasventa.facturasventaDetalleList=JSON.parse(JSON.stringify(result1))
          

      if (this.headerFactura.cliente != null) {
        this.Facturasventa.cliente = this.headerFactura.cliente ;
       /* if (this.CoberturaSeguro.cobertura > 0) {
          this.Facturasventa.ars = this.CoberturaSeguro.ars;
          this.Facturasventa.cobertura = this.Totales.totalGeneral.cobertura;
          this.Facturasventa.coberturaPendiente =
            this.Totales.totalGeneral.cobertura;
          this.Facturasventa.autorizacion = this.CoberturaSeguro.autorizacion;
          this.Facturasventa.medico = this.CoberturaSeguro.medico;
          this.Facturasventa.dianostico = this.CoberturaSeguro.dianostico;
          this.Facturasventa.especialidad = this.CoberturaSeguro.especiales;
          this.Facturasventa.exequartur = this.CoberturaSeguro.exequartur;
        }*/
      }
      this.Facturasventa.caja = this.caja;
      if (
        this.Facturasventa.tipocomprobante == null ||
        this.Facturasventa.tipocomprobante == ""
      ) {
        this.Facturasventa.tipocomprobante =
          this.configuraciones.configPv.comprobantefacturacion;
      }
      if (this.DatosPagareFactura != null && this.DatosPagareFactura.contrato.CantidadCuotas > 0) {

        var Obj = {
          secuencia: 0,
          fecha: fechaNueva(),
          valorPrestamo: this.DatosPagareFactura.contrato.valorPrestamo,
          fechaInicial: this.DatosPagareFactura.contrato.fecha,
          inicial: this.DatosPagareFactura.contrato.inicio,
          valorRecibido: this.DatosPagareFactura.contrato.valorRecibido,
          porcientoInteres: this.DatosPagareFactura.contrato.interes,
          cantidadCuotas: this.DatosPagareFactura.contrato.CantidadCuotas,
          capitalPaga: this.DatosPagareFactura.contrato.Capitalpagar,
          interesTotal: this.DatosPagareFactura.contrato.Interespagar,
          montoCuota: this.DatosPagareFactura.contrato.MontoCuotas,
          tipoInteres: this.DatosPagareFactura.contrato.TipoInteres.codigo,
          interesMora: this.DatosPagareFactura.contrato.InteresMora,
          prorroga: this.DatosPagareFactura.contrato.prorroga,
          cliente: this.headerFactura.cliente,
          activo: true,
          borrado: false
        }

        this.Facturasventa.pagareObj = Obj;
      } else {
        this.Facturasventa.pagareObj = null;
      }
     /* if (this.RecetaProdControlado != null) {
        this.Facturasventa.recetaObj = this.RecetaProdControlado;
        this.Facturasventa.recetaObj.recetaDetalleList = this.RecetaProdControlado.detalleList;
        this.Facturasventa.receta = this.RecetaProdControlado.numero;
        this.Facturasventa.exequartur = this.RecetaProdControlado.exq;
        this.Facturasventa.medico = this.RecetaProdControlado.medico;

      }*/

      const resultCierre =  await this.GetUltimoCierre();
      this.Facturasventa.numerocierre=JSON.parse(JSON.stringify(resultCierre))
      this.Facturasventa.diascredito= this.headerFactura.diascredito
      //this.Facturasventa.numerocierre= this.CierresCajas.cierrescajasPK.secuencia
      //numerocierre: datos.nuemrocierre,,
      this.Facturasventa.sucursal=JSON.parse(JSON.stringify(this.headerFactura.sucursal)) 
      // this.Facturasventa.sucursal = this.caja.sucursal;
      this.Facturasventa.valor = this.Totales.totalGeneralFooter.valorneto;
      this.Facturasventa.valorimpuesto = this.Totales.totalGeneralFooter.itbis;
      this.Facturasventa.valordescuento = this.Totales.totalGeneralFooter.descuento;
      this.Facturasventa.retencion = this.Totales.totalGeneralFooter.Retencion;
      this.Facturasventa.valorRecargo = this.Totales.totalGeneralFooter.recargo;
      this.Facturasventa.moneda = this.headerFactura.moneda;
      this.Facturasventa.tasa = this.headerFactura.moneda.tasa;
      this.Facturasventa.usuario = this.OtrasConfigPv.cajero;
      this.Facturasventa.usuarioCobro = this.OtrasConfigPv.cajero;
      if(this.Pedidosfacturacion!=null){
        this.Facturasventa.pedidosFactura=JSON.parse(JSON.stringify(this.Pedidosfacturacion))
      }
      
      /*if (this.Facturasventa.vendedor != null) {
        this.Facturasventa.vendedor = this.Facturasventa.vendedor;
      }
      if (this.domiciliofactura != null) {
        this.ConstruirDomicilio(this.Facturasventa, this.devuelta);
      }*/

      if (this.selectedListFPagoNC.length > 0) {
        this.Facturasventa.codigoNc = this.selectedListFPagoNC[0].notacreditocontadoPK.documento;
        this.Facturasventa.secuenciaNc = this.selectedListFPagoNC[0].notacreditocontadoPK.secuencia;
      }

      if (
        this.caja.marca &&
        this.configuraciones.configPv.conduce &&
        this.Facturasventa.efectivo < 250000 && //el monto maximo que tieene la dgi para consumidor final B02
        this.Facturasventa.tipocomprobante.codigo === 1 &&
        this.Facturasventa.efectivo > 0 &&
        this.Facturasventa.efectivo >= this.Facturasventa.valor &&
        this.Facturasventa.tarjeta == 0 &&
        this.Facturasventa.cxc == 0 &&
        this.selectedListFPagoNC.length <= 0 && this.conduceSelect == null
      ) {
        if (this.ejecucion == true) {
          this.loading(true)
          this.ejecucion = false
         // this.Facturasventa.fecha = new Date().valueOf()
         this.Facturasventa.fecha =this.headerFactura.fecha+"T04:00"
          setTimeout(function(){
          _this.$axios
            .post(
              _this.$hostname + this.$hName + "/conduce/save",
              _this.Facturasventa,
              { headers: _this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (_this.deleteFacturaTemporal != null) {
                _this.deleRestaurarFact(_this.deleteFacturaTemporal);
              }
              _this.objTemp = res.data;
              _this.RListFactpendiente = res.data;
              if (localStorage.printerSelect != null) {
                var msg = {
                  accion: "print",
                  formato: _this.caja.formatoimpresioncontado.trim(),
                  impresora: JSON.parse(localStorage.printerSelect).nombre,
                  data: _this.configuraciones,
                  fac: res.data,
                  caja: _this.caja,
                  //   fac:this.ReimprecionFacturaSeleccionada
                };
                _this.imprimir(msg, "conduce");
              }
              _this.alerta("Datos salvado correctamente !!!", "bien");
            })
            .catch((error) => {
              if (error.response) {
                _this.alerta(error.response.request.responseText, "error");
              } else {
                _this.alerta(error, "error");
                _this.ejecucion = true
              }
            });
          }, 600);
            //--
        }


      } else if (this.Facturasventa.valorConduce > 0) {
        if (this.caja.sucursal.documentoConduce == null) {
          this.alerta("La sucursal no esta configurada para conduce.")
          return;
        }
        if (this.ejecucion == true) {
          //this.Facturasventa.fecha = new Date().valueOf()
          this.Facturasventa.fecha =this.headerFactura.fecha+"T04:00";
          this.ejecucion = false
          this.Facturasventa.conduceCredito = this.activaConduceCredito
          if(this.activaConduceCredito==false){
            this.Facturasventa.efectivo=this.Facturasventa.valorConduce
          }
          var _this=this;
 setTimeout(function(){
          _this.$axios
            .post(
              _this.$hostname + _this.$hName + "/conduce/save",
              _this.Facturasventa,
              { headers: _this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (_this.deleteFacturaTemporal != null) {
                _this.deleRestaurarFact(_this.deleteFacturaTemporal);
              }
              if (_this.conduceSelect != null) {
                _this.deleteConduce(_this.conduceSelect);
              }
              _this.objTemp = res.data;
              _this.RListFactpendiente = res.data;
              if (localStorage.printerSelect != null) {
                var msg = {
                  accion: "print",
                  formato: _this.caja.formatoimpresioncontado.trim(),
                  impresora: JSON.parse(localStorage.printerSelect).nombre,
                  data: _this.configuraciones,
                  fac: res.data,
                  caja: _this.caja,
                  //   fac:this.ReimprecionFacturaSeleccionada
                };
                _this.imprimir(msg, "conduce");
              }
              _this.alerta("Datos salvado correctamente !!!", "bien");
            })
            .catch((error) => {
              if (error.response) {
                _this.alerta(error.response.request.responseText, "error");
              } else {
                _this.alerta(error, "error");
              }
              _this.loading(false)
              _this.ejecucion = true
            });
          }, 600);
          //--

        }
      }
      else {

        var docu = 0;
        var docu=this.headerFactura.documento.codigo
        this.Facturasventa.facturasventaPK.documento=this.headerFactura.documento.codigo
        this.Facturasventa.documentoFactura=JSON.parse(JSON.stringify(this.headerFactura.documento))
      
      
        /* if (this.Facturasventa.cxc > 0) {
          docu = this.caja.sucursal.documentofacturacredito
        } else {
          docu = this.caja.sucursal.documentofacturacion
        }*/

        var link = docu + "/" + this.Facturasventa.tipocomprobante.codigo + "/" + "PV"
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/" + link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data == true) {

              if (this.ejecucion == true) {
                //  this.Facturasventa.fecha = new Date().toISOString().substr(0, 10)
                var fech = new Date(new Date().toLocaleDateString("en-DO")).toISOString()
                //this.Facturasventa.fecha = new Date().valueOf()

                /*his.loading(false)
                return */
                this.ejecucion = false
                this.loading(true)
               // this.Facturasventa.fecha = fech
                  this.Facturasventa.fecha =this.headerFactura.fecha+"T04:00";
 setTimeout(function(){ 
                _this.$axios
                  .post(_this.$hostname + _this.$hName + "/pv/save", _this.Facturasventa, {
                    headers: _this.$store.getters.GetheadersAxios,
                  })
                  .then((res) => {
                    if (_this.deleteFacturaTemporal != null) {
                      _this.deleRestaurarFact(_this.deleteFacturaTemporal);
                    }
                    if (_this.conduceSelect != null) {
                      _this.deleteConduce(_this.conduceSelect);
                    }
                    _this.objTemp = res.data;
                    _this.RListFactpendiente = res.data;
                    _this.alerta("Datos salvado correctamente !!!", "bien");
                    if (localStorage.printerSelect != null) {
                      var msg = {
                        accion: "print",
                        formato: _this.caja.formatoimpresioncontado.trim(),
                        impresora: JSON.parse(localStorage.printerSelect).nombre,
                        data: _this.configuraciones,
                        fac: res.data,
                        caja: _this.caja,
                      };
                      _this.imprimir(msg, "factura");
                    }


                  })
                  .catch((error) => {
                    _this.ejecucion = true
                    _this.loading(false)
                  });

                }, 600);

              }



            } else {
              this.alerta("No posee secuencia para la factura que está intentado realizar", "error")
            }
          })
          .catch(error => { });


      }
   
    })() 
   },


// Eliminación de una fila de una tabla.
deleteConduce(e) {
      this.$axios
        .get(this.$hostname + this.$hName + "/conduce/deleteConduce/" + e.secuencia + "/" + e.documento, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.VerConducelistFactCliente = []
        })

    },



   imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.limpiarTodoCompleto();
          })
          .catch((error) => {
            console.error(error)
            EventBus.$emit("loading", false);
          });
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            EventBus.$emit("loading", false);
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.limpiarTodoCompleto();
          })
          .catch((error) => {
            this.alerta("La impresora no esta disponible...","error")
            console.error(error)
            EventBus.$emit("loading", false);
          });
      }
    },

    bononesAccion(){
      // console.log('--bononesAccion--bononesAccion')
      // console.log(this.Facturasventa.facturasventaPK.secuencia)
var _this=this;
setTimeout(function(){ 
  // _this.cargarFiltro(e) 
 /* console.log('--this.$refs.RefPvToolbarContenidoAccion.botonesAccion--')
  console.log(_this.$refs.RefPvToolbarContenidoAccion.botonesAccion)*/

  if(_this.Facturasventa.facturasventaPK.secuencia==0){
    _this.$refs.RefPvToolbarContenidoAccion.botonesAccion={
    salvar:true,
    editar:false,
    anular:false,
    anularPedido:false
    }
    _this.modofactura="salvarNuevo"
  }
  
if(_this.Facturasventa.facturasventaPK.secuencia>0){
    _this.$refs.RefPvToolbarContenidoAccion.botonesAccion={
    salvar:true,
    editar:true,
    anular:true,
    anularPedido:false
    }
    _this.modofactura="editar"
  }

_this.getInformacionFactura()
}, 300);

 /* this.$refs.RefPvToolbarContenidoAccion.botonesAccion={
    salvar:true,
    editar:true,
    anular:false
    }*/



     /* facturasventaPK:Object
      documento:0
      secuencia:0*/

    },

    getInformacionFactura(e){

      var OBJ={
        documento:this.headerFactura.documento,
        secuencia:this.Facturasventa.facturasventaPK.secuencia
      }

      this.$refs.RefPvInformacionHeaderFactura.getInformacionFactura(OBJ)
    },


    editarFactura(){
      var link=`${this.Facturasventa.facturasventaPK.secuencia}/${this.Facturasventa.facturasventaPK.documento}`
     this.$axios
          .get(
            this.$hostname + this.$hName + "/devolucion/devolucionFactura/" + link,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {

                  if(res.data==true){
                    this.alerta("Esta factura no puede ser modificada, porque tiene devoluciones de venta aplicada", "error");
                    return
                  }else{

                     if(this.Facturasventa.caja.codigo!=this.caja.codigo){
        this.alerta("Esta factura no fue realizada en la caja seleccionada", "error");
        return
      }


      if(this.Facturasventa.numerocierre !=this.CierresCajas.cierrescajasPK.secuencia){
        this.alerta("Esta factura no pertenece al cierre actual", "error");
        return
      }
      // valor
      if (this.Facturasventa.cxc > 0 && this.Facturasventa.cxc != this.Facturasventa.pendiente) {
          this.alerta("Esta factura no se puede ser modificada ya que posee movimiento.", "error")
          return;
        }

        if (this.Facturasventa.tarjeta > 0) {
          this.alerta("Esta factura no se puede editar ya que fue pagada con tarjeta.", "error")
          return;
        }

        this.calcularDevuelta()
        this.openFormaDepago()
         /* console.log('--editarFactura--')
          console.log(this.Facturasventa)*/

                  }
          })
          .catch((error) => {
            console.error(error);
          });



      
    },

    async contEditarFactura(){

      if (this.devuelta < 0) {
        this.alerta(
          "El valor a pagar es menor que el valor de la factura",
          "error"
        );
        return 0;
      }

      if (this.Totales.totalGeneralFooter.valorneto == 0 && this.Totales.totalGeneralFooter.cobertura == 0) {
        return 0;
      }
      //console.log('---Verificar Redondeo---')
      //console.log(this.RedondeoBasico(2011.5786666666))
      //RedondeoBasico
      //currencyFormatter2
      //if(this.Facturasventa.efectivo<=0){}
      if (this.RedondeoBasico(this.Facturasventa.tarjeta) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Tarjeta ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cheques) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Cheques ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.transferencia) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Transferencia ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cxc) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Crédito(cxc) ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cupones) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' cupones ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.valorConduce) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' valor Conduce ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.valorNc) > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("La forma de pago ' Nota de crédito ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      var SumaTotal = this.RedondeoBasico(this.Facturasventa.tarjeta + this.Facturasventa.transferencia + this.Facturasventa.cheques + this.Facturasventa.cxc
        + this.Facturasventa.cupones + this.Facturasventa.valorConduce + this.Facturasventa.valorNc)

      if (SumaTotal > this.RedondeoBasico(this.Totales.totalGeneralFooter.valorneto)) {
        this.alerta("Las formas de pago sobre pasa el total de la factura  ", "error")
        return 0;
      }

      this.Facturasventa.diascredito= this.headerFactura.diascredito
      this.Facturasventa.fecha = this.headerFactura.fecha
      this.Facturasventa.vendedor = this.headerFactura.vendedor 
      // this.Facturasventa.usuariosEditar= this.OtrasConfigPv.cajero
      this.Facturasventa.usuariosEditar= this.$store.getters.GetdatosInfUsu.usuario.usuario
      this.Facturasventa.valor = this.Totales.totalGeneralFooter.valorneto;
      this.Facturasventa.valorimpuesto = this.Totales.totalGeneralFooter.itbis;
      this.Facturasventa.valordescuento = this.Totales.totalGeneralFooter.descuento;
      this.Facturasventa.retencion = this.Totales.totalGeneralFooter.Retencion;
      this.Facturasventa.valorRecargo = this.Totales.totalGeneralFooter.recargo;
      this.Facturasventa.moneda = this.headerFactura.moneda;
      this.Facturasventa.tasa = this.headerFactura.moneda.tasa;
      this.Facturasventa.usuarioAutorizacion=JSON.parse(sessionStorage.getItem("usuarioAutorizacion"))
      const result1 =  await this.llenarDetalleFactura();
      this.Facturasventa.facturasventaDetalleList=JSON.parse(JSON.stringify(result1))
    
      var _this=this; 
      setTimeout(function(){ 
                _this.$axios
                  .post(_this.$hostname + _this.$hName + "/pv/editar", _this.Facturasventa, {
                    headers: _this.$store.getters.GetheadersAxios,
                  })
                  .then((res) => {
                    _this.alerta("Datos salvado correctamente !!!", "bien");
                    sessionStorage.setItem("usuarioAutorizacion", JSON.stringify(null));
                   /* if (localStorage.printerSelect != null) {
                      var msg = {
                        accion: "print",
                        formato: _this.caja.formatoimpresioncontado.trim(),
                        impresora: JSON.parse(localStorage.printerSelect).nombre,
                        data: _this.configuraciones,
                        fac: res.data,
                        caja: _this.caja,
                      };
                      _this.imprimir(msg, "factura");
                    }*/


                  })
                  .catch((error) => {
                    _this.ejecucion = true
                    _this.loading(false)
                  });

                }, 600);



    },

    // Comprobando si el valor de la selección es nulo o no.
    SelctCbMotivoAnulacion() {
      var _this = this;
      setTimeout(function () {
        if (_this.TipoAnulacion == null) {
          _this.TipoAnulacion = "";
        }

        if (!_this.TipoAnulacion.hasOwnProperty('codigo')) {
          _this.TipoAnulacion = "";
          _this.TipoAnulacion = null;
          return;
        }
      }, 300);
    },

     // Comprobar si el formulario es válido, si es válido, es comprobar si la
    // AnulacionFacturaSeleccionada.cxc es mayor a 0 y si la AnulacionFacturaSeleccionada.valor no es
    // igual a la AnulacionFacturaSeleccionada.pendiente. Si lo es, está alertando al usuario de que no
    // se puede anular la factura. Si no es así, se está comprobando si la ejecución es cierta. Si es
    // así, está configurando el ejec
    AnulacionFacturaAnular() {
      
      var ANULAR_FACTURA= JSON.parse(JSON.stringify(this.Facturasventa))

      if (this.$refs.formAnulacion.validate()) {
        if (ANULAR_FACTURA.cxc > 0 && ANULAR_FACTURA.valor != ANULAR_FACTURA.pendiente) {
          this.alerta("Esta factura no se puede anular ya que posee algún movimiento.", "error")
          return;
        }
        if (this.ejecucion == true) {
          this.ejecucion = false;
          //TipoAnulacion
          this.loading(true)
          ANULAR_FACTURA.motivoAnular = this.TipoAnulacion.descripcion;
          //     var obj={
          //   datos:JSON.parse(JSON.stringify(this.anular)),
          //   usuario:JSON.parse(JSON.stringify(this.$user))
          // }
          if (
            ANULAR_FACTURA.hasOwnProperty("datoTarjeta") &&
            ANULAR_FACTURA.datoTarjeta != null
          ) {
            this.Anulacioncarnet();
          }

          if (
            ANULAR_FACTURA.hasOwnProperty("datoTarjeta") &&
            ANULAR_FACTURA.datoTarjeta == null
          ) {
            this.ProcessFacturaAnula(ANULAR_FACTURA);
          }
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

     // Envío de una solicitud POST al servidor para anular la factura.
     ProcessFacturaAnula(ANULAR_FACTURA) {
      //this.AnulacionFacturaSeleccionada.
      var obj = {
        secuencia: ANULAR_FACTURA.facturasventaPK.secuencia,
        documento: ANULAR_FACTURA.facturasventaPK.documento,
        motivoAnular: ANULAR_FACTURA.motivoAnular,
        datoTarjeta: ANULAR_FACTURA.datoTarjeta,
        usuariosAnulacion:this.$store.getters.GetdatosInfUsu.usuario.usuario,
        fechaAnulacion:this.fechaNueva()+"T04:00"
      };



      this.$axios
        .post(
          this.$hostname + this.$hName + "/AnularFactura/AnularFacturaPv",
          JSON.parse(JSON.stringify(obj))
        )
        .then((res) => {
          this.alerta("Dato Anulado correctamente", "bien");
          this.loading(false)
          if (localStorage.printerSelect != null) {
                      var msg = {
                        accion: "print",
                        formato: this.caja.formatoimpresioncontado.trim(),
                        impresora: JSON.parse(localStorage.printerSelect).nombre,
                        data: this.configuraciones,
                        fac: res.data,
                        caja: this.caja,
                      };
                      this.imprimir(msg, "factura");
                    }
        })
        .catch((error) => {
          this.alerta(error, "error");
          this.loading(false)
        });
    },


    // Hacer una solicitud de publicación al servidor enviando y comprobando los datos de carnet .
    Anulacioncarnet(ANULAR) {
      // var bool=true

      // if(bool==true){
      //   this.ProcessFacturaAnula()
      //   return 0;
      // }

      var ANULAR_FACTURA=JSON.parse(JSON.stringify(ANULAR))

      this.loading(true)
      var obj = JSON.parse(ANULAR_FACTURA.datoTarjeta);

      var objinf = {
        amount: 0,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: obj.Host.Value,
        referenceNumber: obj.Transaction.Reference,
      };

      this.$axios
        .post("http://localhost:22105/api/ProcessAnnulment", objinf, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          
          var e = JSON.parse(res.data);

          if (e.Status == "Successful") {
            obj.TarjetaAnulada = e;
            ANULAR_FACTURA.datoTarjeta = JSON.stringify(obj);
            var _this = this;
            setTimeout(function () {
              _this.ProcessFacturaAnula(ANULAR_FACTURA);
            }, 3000);
          }

          if (e.Status == "Failed") {
            this.alerta("Anulacion cancelada", "error");
            this.loading(false)
          }
        })
        .catch((error) => this.alerta("Anulacion cancelada", "error"));

      //AnulafacturatarjetacreditoCar
    },

    async salvarPedidofactura(){
      
      if (this.RProductoCompra.length <= 0) {
        this.alerta("agrege al menos un producto a la lista", "error");
        return 0;
      }

      if (this.headerFactura.cliente == null) {
        this.alerta("Debe seleccionar un cliente para hacer un pedido", "error");
        return;
      }


      this.loading(true)
      var pedidoFactura={
        idpedido:0,
      }

      var BOOL_NUEVO=true
      if(this.Pedidosfacturacion!=null){
        // pedidoFactura.idpedido=this.Pedidosfacturacion.idpedido
        pedidoFactura=JSON.parse(JSON.stringify(this.Pedidosfacturacion))
        BOOL_NUEVO=false
      }else{
        pedidoFactura.cerrado=false
        pedidoFactura.valorfacturado=0
      }
    
      pedidoFactura.sucursal=this.headerFactura.sucursal
      pedidoFactura.diascredito= this.headerFactura.diascredito
      pedidoFactura.fecha = this.headerFactura.fecha
      pedidoFactura.vendedor = this.headerFactura.vendedor 
      pedidoFactura.documento=this.headerFactura.documento

      if(this.headerFactura.cliente!=null){
        pedidoFactura.cliente= this.headerFactura.cliente
  }
      // this.Facturasventa.usuariosEditar= this.OtrasConfigPv.cajero
      // this.Facturasventa.usuariosEditar= this.$store.getters.GetdatosInfUsu.usuario.usuario
      pedidoFactura.valorpedido = this.Totales.totalGeneralFooter.valorneto;
      pedidoFactura.valorimpuesto = this.Totales.totalGeneralFooter.itbis;
      pedidoFactura.valordescuento = this.Totales.totalGeneralFooter.descuento;
      pedidoFactura.comentario= this.Facturasventa.comentario
      // this.Facturasventa.moneda = this.headerFactura.moneda;
      // this.Facturasventa.tasa = this.headerFactura.moneda.tasa;

      const result1 =  await this.llenarDetallePedidoFactura(this.RProductoCompra);
      pedidoFactura.pedidosfacturacionDetalleList=JSON.parse(JSON.stringify(result1))

    var _this=this; 
      setTimeout(function(){ 
                _this.$axios
                  .post(_this.$hostname + _this.$hName + "/pedidosfacturacion/save", pedidoFactura, {
                    headers: _this.$store.getters.GetheadersAxios,
                  })
                  .then((res) => {
                    _this.alerta("Datos salvado correctamente !!!", "bien");
                    if(BOOL_NUEVO==true){
                      EventBus.$emit("loading", true);
                      if(_this.caja.formatoimpresioncontado.trim().includes('medPag')){
                        if (localStorage.printerSelect != null) {
                      var msg = {
                        accion: "print",
                        formato: _this.caja.formatoimpresioncontado.trim(),
                        impresora: JSON.parse(localStorage.printerSelect).nombre,
                        data: _this.configuraciones,
                        obj: res.data,
                        caja: _this.caja,
                      };
                      _this.imprimir(msg, "pedidofactura");
                    }
                      }else{

                        var headers = _this.$store.getters.GetheadersAxios
_this.$axios.get(_this.$hostname + _this.$hName + "/pedidosfacturacion/imprimir/"+res.data.idpedido, { headers: headers }).then(res => {
  EventBus.$emit("loading", false)
 /* let pdfWindow = window.open("Reporte de imprecion")
  pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>")*/
    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'pedido facturacion');
});
                      }

      


              







                    }
                   /* if (localStorage.printerSelect != null) {
                      var msg = {
                        accion: "print",
                        formato: _this.caja.formatoimpresioncontado.trim(),
                        impresora: JSON.parse(localStorage.printerSelect).nombre,
                        data: _this.configuraciones,
                        fac: res.data,
                        caja: _this.caja,
                      };
                      _this.imprimir(msg, "factura");
                    }*/


                  })
                  .catch((error) => {
                    console.log('Eroor:'+error)
                    _this.alerta("Dato no salvado", "error");
                    _this.ejecucion = true
                    _this.loading(false)
                  });

                }, 600);
   }, 


       // Crear un nuevo objeto y empujarlo a un objto detalle de factura.
async llenarDetallePedidoFactura(RProductoCompra){

    return new Promise((resolve, reject) => { 
      var DetalleList=[]
// RProductoCompra.filter((ee) => ee.paramOfert== false)
RProductoCompra.forEach( async (dato) => {
  (async () => { 
    // var PRODUCTO=JSON.parse(JSON.stringify(element))
/*cantfacturada: 0
cantpedida: 1
codvencimiento: 0
id: 1
marca: false
oferta: false
pordescuento: 0
porimpuesto: 0
precio: 195.08
producto: {codigo: 45, descripcion: 'Glucerna Vainilla 8 oz', descripcioncorta: 'Glucerna', codigoanterior: '                    ', tipoproducto: 'P', …}
unidad: {empresa: null, codigo: 1, descripcion: 'UNIDAD                        ', desactivo: false, fechaCreacion: 1493858858815, …}
valordescuento: 0
valorimpuesto: 29.76*/

    var detalle = {
      id: 0,
      cantpedida: dato.cantidad,
      cantfacturada:0,
      valordescuento: dato.valordescuento,
      valorimpuesto: dato.valorimpuesto,
      precio: dato.precio,
      pordescuento: dato.descuento,
      oferta: dato.paramOfert,
      porimpuesto:null,
      producto: dato.productos,
      unidad: dato.unidades,
      codvencimiento:0,
      marca: false,
      vendedor: null,
    };
    DetalleList.push(detalle);      
  })()
});
resolve(DetalleList)
});


},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
       
        this.limpiarTodoCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },

    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },


    loading(e) {
      EventBus.$emit("loading", e);
    },
  },
  computed: { 
    GetEjecucion() {
    this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    // this.bononesAccion()

    if(this.modofactura==='salvarNuevo'){
    this.editando=false
    this.modificando=true
   }
   if(this.modofactura==='editar'){
    this.editando=true
    this.modificando=false
   }

   if(this.modofactura==='modificar'){
    this.editando=true
    this.modificando=true
   }

    }
  },

};
</script>
<style lang="scss">
</style>