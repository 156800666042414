<template>
  <div id="ResponsiveCss">{{ botonCuenta }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Documentos de Inventario'"
          :descripcion="'Crear, Modificar y Desactivar Documentos de Inventario'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="eliminar"
          @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>
                <!-- <v-navigation-drawer >  -->


                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/documento-inv/findall'"
                  :nombre="'Documentos'" :emitnombre="'emitDocumentoINV'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'descripcion' },
                    { text: 'Id.Documento', value: 'nombrecorto' },
                    { text: 'Creación', value: 'fechaCreacion' },
                    { text: 'Secuencia Inicial', value: 'secuenciainicial' },
                    { text: 'Cantidad de copia', value: 'cantidadcopias' },
                    { text: 'Operación ', value: 'operacion.descripcion' },
                    { text: 'Creado por', value: 'usuarioCreacion' },
                    { text: 'activo', value: 'activo' },
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Descripción', NomValue: 'descripcion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Id.Documento', NomValue: 'nombrecorto', Ctsl: 1, Type: 0, State: true },
  { Text: 'Creación', NomValue: 'fechaCreacion', Ctsl: 1, Type: 1, State: true },
  { Text: 'Secuencia Inicial', NomValue: 'secuenciainicial', Ctsl: 1, Type: 0, State: true },
  { Text: 'Cantidad de copia', NomValue: 'cantidadcopias', Ctsl: 1, Type: 0, State: true },
  { Text: 'Operación', NomValue: 'operacion', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
  { Text: 'Creado por', NomValue: 'usuarioCreacion', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.1, State: true },
]" />


                <!-- <BarraBusqueda2
                  :url="$hostname + $hName + '/services/DocumentoPv/findall'"
                  :nombre="'Documentos'"
                  :headers="headers"
                  :slotname="'item.descripcion'"
                  :emitnombre="'emitDocumento'"
                  :SeachNombre="'Búsqueda por descripción del Documento'"
                  :slotlist="slotlist"
                /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation>
                <!-- <v-row> -->
                <!-- fila 1 ------------------------------>
                <!-- <v-col cols="12" md="12"> -->
                <v-card color="grey lighten-3">

                  <v-card-actions v-if="windowSize.width <= 800">
                    <v-spacer></v-spacer>
                    <v-btn @click.prevent="BarraSecundariaAprir = !BarraSecundariaAprir" x-small color="grey">CUENTA<i
                        style="font-size: 20px;" class="fa fa-bars"></i></v-btn>
                  </v-card-actions>
                  <v-container>

                    <v-row>
                      <!-- Documentos:-->
                      <v-col cols="12" md="12">
                        <v-text-field label="* Descripción:" dense outlined :rules="[$rules.required]"
                          autocomplete="off" v-model="documento.descripcion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- fn Documentos:-->

                    <!-- Factura:-->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field label="* ID.Documento:" maxlength="5" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="documento.nombrecorto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </v-text-field>


                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="Succursal" :items="RSucursal"
                          item-text="descripcion" v-model="documento.sucursal" @blur="SelctCbSucursal"
                          @focus="cargarSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>

                        <v-combobox v-model="documento.almacenOrigen" label="Almacén origen" outlined dense
                          autocomplete="off" :items="Ralmacen" item-text="descripcion" @blur="SelctCbAlmacen"
                          @focus="CargarRalmacen" v-if="mostrarAlmacenesCombo">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                          </template>
                        </v-combobox>

                        <v-switch v-model="datosAdicionales.activo" label="Activo" color="success" hide-details></v-switch>


                      </v-col>

                      <v-col cols="12" md="6">
                        <v-combobox v-model="documento.operacion" label="* Operación:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="ROperacion" item-text="descripcion"
                          @blur="SelctCbOperacion" @focus="CargarROperacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class=" fa fa-line-chart"></i>
                          </template>
                        </v-combobox>


                        <!-- Operación:-->
                        <v-text-field type="number" label="Secuencia Inicial:" dense outlined :rules="[$rules.required]"
                          v-model.number="documento.secuenciainicial">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>

                        <!-- <v-text-field
                                            type="number"
                                            label="Cantidad de Copias:"
                                            dense
                                            outlined
                                            :rules="[$rules.required]"
                                            v-model.number="documento.cantidadcopias"
                                          >
                                            <template v-slot:prepend>
                                              <i style="font-size: 20px;" class="fa fa-files-o"></i>
                                            </template>
                                          </v-text-field> -->

                        <v-combobox v-model="documento.almacenDestino" label="Almacén destino" outlined dense
                          autocomplete="off" :items="Ralmacen" item-text="descripcion" @blur="SelctCbAlmacen"
                          @focus="CargarRalmacen" v-if="mostrarAlmacenesCombo">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                          </template>
                        </v-combobox>
                        <v-switch v-model="documento.comprobante" label="Generar Comprobante" color="success"
                          hide-details></v-switch>
                      </v-col>
                      <!-- fn Factura
      //fa fa-file-text-o-->
                    </v-row>
                  </v-container>

                </v-card>


              </v-form>
            </v-container>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">
            <!------------------------------------------------------------- Tabla Cuenta   -->
            <center>
              <v-btn small color="cyan darken-3" @click.prevent="acti_Modal_cuenta = !acti_Modal_cuenta">
                <i style=" color:white; font-size: 20px;" class="fa fa-plus-square">
                </i>
                <span style="color:white;"> Agregar Cuenta</span>
              </v-btn>
              <br> <br>
              <v-spacer></v-spacer>

              <h4 class="font-weight-bold"> Cuenta</h4>

            </center>

            <v-card>

              <!-- <h5>Cuenta</h5> -->

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" v-on="on" v-model="search" @keyup="filtro"
                    label="Búsqueda por descripción del Documento" single-line hide-details>
                    <template v-slot:prepend-inner>
                      <i style="font-size: 30px;" class="fa fa-search"></i>
                    </template>
                  </v-text-field>
                </template>
                <span>Búsqueda por descripción del Documento</span>
              </v-tooltip>


              <v-data-table @click:row="Modaldatostabla" :items-per-page="10" :headers="HCuenta" :items="Tbcuenta"
                :search="search">
                <template v-slot:item.catalogocuentas.descripcion="props">
                  <div style="border-top:2px solid gray;">
                    <b>{{ props.item.catalogocuentas.descripcion }}</b>

                    <br />
                    <span v-if="props.item.tipo == 1">{{
                        (props.item.TipoCuentaDesc = "Inventario")
                    }}</span>
                    <span v-if="props.item.tipo == 2">{{
                        (props.item.TipoCuentaDesc = "Costo")
                    }}</span>
                    <span v-if="props.item.tipo == 3">{{
                        (props.item.TipoCuentaDesc = "ITBIS")
                    }}</span>
                    <span v-if="props.item.tipo == 4">{{
                        (props.item.TipoCuentaDesc = "Descuento")
                    }}</span>
                    <span v-if="props.item.tipo == 5">{{
                        (props.item.TipoCuentaDesc = "Devolucion")
                    }}</span>
                    <span v-if="props.item.tipo == 6">{{
                        (props.item.TipoCuentaDesc = "Nota de Crédito")
                    }}</span>
                    <span v-if="props.item.tipo == 7">{{
                        (props.item.TipoCuentaDesc = "Caja")
                    }}</span>
                    <span v-if="props.item.tipo == 8">{{
                        (props.item.TipoCuentaDesc = "Ingreso")
                    }}</span>
                    <br />
                    <span v-if="props.item.debito == false">Crédito</span>
                    <span v-if="props.item.debito == true">Debito</span>

                    <p style="display:none;">
                      {{ (documento.cuenta = Tbcuenta) }}
                    </p>
                  </div>
                </template>
              </v-data-table>
            </v-card>

            <!------------------------------------------------------------------------------ fin tabla cuenta -->

          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->


    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <!--          <v-tooltip top>-->
          <!--            <template v-slot:activator="{ on }">-->
          <!--              <v-btn v-on="on"-->
          <!--                     dark-->
          <!--                     small-->
          <!--                     color="deep-purple accent-2"-->
          <!--                     @click.prevent=""-->
          <!--              >-->
          <!--                <i style="font-size: 25px;" class="fa fa-print"> </i>-->
          <!--                Listado-->
          <!--              </v-btn>-->

          <!--            </template>-->
          <!--            <span>Imprimir Listado</span>-->
          <!--          </v-tooltip>-->
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', width: 100, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripción', value: 'descripcion', width: 250, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Id.Documento', value: 'nombrecorto', width: 100, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Creación', value: 'fechaCreacion', width: 120, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Secuencia Inicial', value: 'secuenciainicial', width: 190, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cantidad  de  copia', value: 'cantidadcopias', width: 190, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Operación', value: 'operacion.descripcion', width: 190, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Creado por', value: 'usuarioCreacion', width: 200, sortable: false, class: ['no-gutters black--text'] },
              { text: 'Generar Comprobante', value: 'comprobante', width: 200, sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">
              <template v-slot:item.fechaCreacion="props">
                <span>{{
                    fecFormatter(props.item.fechaCreacion)
                }}</span>
              </template>

              <template v-slot:item.comprobante="props">
                <span v-if="props.item.comprobante == true">Activo</span>
                <span v-if="props.item.comprobante == false">Desactivo</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-- ------------------------------------------------------ id="alerMov"  alerta -->
    <v-snackbar id="alerMov" v-model="aalert.estado" :color="aalert.color" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Elim_notif cuenta-->
    <v-dialog label="myAlert" v-model="Elim_notif_cuenta.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif_cuenta.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminarCuenta(),
            (Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="
            Elim_notif_cuenta.estado = !Elim_notif_cuenta.estado
          " small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif cuenta -->

    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            remove(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Cuentas Contables
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="cerrarmodalcuenta">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="12" md="12">
                <v-combobox v-model="modalCuenta.cuenta" label="* Cuenta:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="Rcuenta" :item-text="RfilCuenta"
                  @blur="SelctCbcuenta" @keyup="CbFil" @change="SelctCbCuentaValidControl" @focus="CargarRcuenta">

                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col cols="4" md="4"><b style="font-size: 16px;"> Cuenta: </b></v-col>
                      <v-col cols="6" md="6"><b style="font-size: 16px;">Descripción: </b></v-col>
                      <v-col cols="2" md="2"><b style="font-size: 16px;">Tipo:</b></v-col>
                    </v-row>

                  </template>


                  <template slot="item" slot-scope="item" margin-top="50px">


                    <v-row>
                      <v-col cols="4" md="4"><span style="font-size: 14px;"> {{ item.item.cuenta }}</span></v-col>
                      <v-col cols="6" md="6"><span style="font-size: 14px;">{{ item.item.descripcion }}</span></v-col>
                      <v-col cols="2" md="2"><span style="font-size: 12px;">({{ item.item.tipo }})</span></v-col>
                    </v-row>

                    <!-- <v-simple-table style="width:100%;">

                      <thead>
                        <tr>
                          <th class="text-left">Cuenta:</th>
                          <th class="text-left">descripcion:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ item.item.cuenta }}</td>
                          <td>{{ item.item.descripcion }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table> -->


                  </template>

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn @click.prevent="
                      acti_Nueva_cuenta = !acti_Nueva_cuenta
                    " color="secondary" fab x-small dark>
                      <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                    </v-btn>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="modalCuenta.tipo" label="* Tipo:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="tipoCatalogo" item-text="descripcion"
                  @blur="SelctCbtipo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>

                <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda" item-text="nombrecorto"
                  v-model="modalCuenta.moneda" @blur="SelctCbMoneda" @focus="cargarMoneda">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-combobox>


              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="modalCuenta.origen" label="* Origen:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="OrigenCatalogo" item-text="descripcion"
                  @blur="SelctCborigen">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <v-btn-toggle rounded class="d-flex justify-end">
          <v-btn color="green darken-1" @click.prevent="saveCuenta">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

          <v-btn v-if="modalCuenta.codigoDocumento != 0 || modalCuenta.hasOwnProperty('marc')" color="red"
            @click.prevent="removeCuenta">
            <i style="font-size: 28px;" class="fa fa-trash"> </i>
            Eliminar
          </v-btn>
        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

    <!-- ------------------------------------------------------ Modal- nueva cuenta-->
    <v-dialog label="Modal agregar cuenta" v-model="acti_Nueva_cuenta" persistent max-width="90%" max-height="80%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="indigo">
          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light">
            Crear Cuenta Nueva
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="
            (acti_Nueva_cuenta = !acti_Nueva_cuenta), newcuenta()
          ">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />
          <v-form ref="form3" v-model="valid" lazy-validation>
            <v-row>
              <input type="number" v-model="CuentaNueva.codigo" style="display:none;" />

              <v-col cols="12" md="6">
                <v-text-field label="* Cuenta:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="CuentaNueva.cuenta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox v-model="CuentaNueva.descargo" label="Cuenta Descargo:" required outlined dense
                  autocomplete="off" :items="Rcuenta" item-text="cuenta" @blur="SelctCbcuentaNuevaDesc"
                  @focus="CargarRcuenta" @change="SelctCbCuentaNuevaDescValidControl">

                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col cols="6" md="6"><b style="font-size: 18px;"> Cuenta: </b></v-col>
                      <v-col cols="6" md="6"><b style="font-size: 18px;">Descripción: </b></v-col>
                    </v-row>
                  </template>


                  <template slot="item" slot-scope="item" margin-top="50px">

                    <v-row>
                      <v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
                      <v-col cols="6" md="6">{{ item.item.descripcion }}</v-col>
                    </v-row>


                  </template>

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field label="* Descripción:" dense autocomplete="off" outlined :rules="[$rules.required]"
                  v-model="CuentaNueva.descripcion">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="CuentaNueva.origen" label="Origen:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="ROrigen" item-text="descripcion"
                  @blur="SelctCbcuentaorigen">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="6" md="6">
                <v-combobox v-model="CuentaNueva.tipo" label="Tipo:" required outlined dense :rules="[$rules.required]"
                  autocomplete="off" :items="RTipo" item-text="descripcion" @blur="SelctCbCuentatipo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox v-model="CuentaNueva.tipogasto" label="Tipo de Gasto:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                  @focus="CargarRTipopago" @blur="SelctCbTipoGasto">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-tag"></i>
                  </template>
                </v-combobox>
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox v-model="CuentaNueva.grupo" label="Grupo:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="RGrupo" item-text="descripcion"
                  @blur="SelctCbGrupo">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-cubes"></i>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <hr />
        <!--   -->
        <v-btn-toggle rounded class="d-flex justify-end ">
          <v-btn color="green darken-1" @click.prevent="saveCuentaNueva">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>
        </v-btn-toggle>
        <hr />
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal nueva cuenta-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">

      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>






    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus.js";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";

export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico,
    ModalReporteFiltros
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);


    EventBus.$on("emitDocumentoINV", this.datostabla);
    // EventBus.$on("INVDocumentoNew", this.new);
    // EventBus.$on("INVDocumentoSave", this.save);
    // EventBus.$on("INVDocumentoRemove", this.remove);
    // EventBus.$on("INVDocumentoAnular", this.anular);
    // EventBus.$on("INVDocumentoPrintList", this.imprimirList);


    EventBus.$on("onResize", this.onResize);
    this.filtro();
  },
  created() {
    var json = {
      titulo: "Documentos de Inventario",
      descripcion: "Crear, Modificar y Desactivar Documentos de Inventario",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "INVDocumentoNew",
        "INVDocumentoSave",
        "INVDocumentoRemove",
        "INVDocumentoAnular",
        "INVDocumentoPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'DOCUMENTOS DE INVENTARIO',
        UrlImprimir: '/documento-inv/ImprimirDoc',
        UrlExel: '/documento-inv/ExcelDoc',
        UrlCorreo: '/documento-inv/CorreoDoc',
      },




      operacionModulo: 'INV',
      mostrarAlmacenesCombo: false,
      idOperacionTransferencia: 7,
      RSucursal: [],
      Ralmacen: [],
      editedIndex: -1,
      windowSize: { width: "auto", height: "auto" },
      tipoCatalogo: [
        { codigo: 1, descripcion: "Inventario" },
        { codigo: 2, descripcion: "Costo" },
        { codigo: 3, descripcion: "ITBIS" },
        { codigo: 4, descripcion: "Descuento" },
        { codigo: 5, descripcion: "Devolucion" },
        { codigo: 6, descripcion: "Nota Crédito" },
        { codigo: 7, descripcion: "Caja" },
        { codigo: 8, descripcion: "Ingreso" }
      ],

      OrigenCatalogo: [
        { codigo: 1, descripcion: "Crédito", debito: false },
        { codigo: 2, descripcion: "Debito", debito: true }
      ],

      ROrigen: [
        { codigo: 1, origen: "D", descripcion: "Crédito" },
        { codigo: 2, origen: "C", descripcion: "Debito" }
      ],

      RTipo: [
        { codigo: 1, tipo: "CO", descripcion: "Control" },
        { codigo: 2, tipo: "DE", descripcion: "Detalle" }
      ],

      RGrupo: [
        { codigo: "AC", descripcion: "Activo" },
        { codigo: "PA", descripcion: "Pasivo" },
        { codigo: "CA", descripcion: "Capital" },
        { codigo: "IN", descripcion: "Ingreso" },
        { codigo: "CO", descripcion: "Costo" },
        { codigo: "GA", descripcion: "Gasto" }
      ],

      ROperacion: [],
      RTipoGasto: [],
      Rmoneda: [],

      RfilCuenta: "cuenta",
      Rcuenta: [],
      valid: true,

      Colss: {
        p1: 6
      },

      acti_Modal_cuenta: false,
      acti_Nueva_cuenta: false,
      Tbcuenta: [],

      debito: false,
      credito: false,

      CuentaNueva: {
        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true
      },

      documento: {
        codigo: 0,
        descripcion: null,
        fechaCreacion: new Date().toISOString().substr(0, 10),
        nombrecorto: null,
        operacion: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        usuarioCreacion: "",
        usuarioModificacion: "",
        comprobante: false,
        desactivado: false,
        cuenta: [],
        sucursal: null,
        almacenDestino: null,
        almacenOrigen: null
      },
      datosAdicionales:{
        activo:true,
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      objec: {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      },

      modalCuenta: {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      },

      headers: [{ text: "Documento", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripción:"
        },
        {
          dt0: "nombrecorto",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Id.Documento:"
        },
        {
          dt0: "fechaCreacion",
          dt1: null,
          dt2: null,
          dt3: 2,
          dt4: "Creación:"
        },
        {
          dt0: "secuenciainicial",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Secuencia Inicial:"
        },
        {
          dt0: "cantidadcopias",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Cantidad  de  copia:"
        },
        {
          dt0: "operaciones",
          dt1: "descripcion",
          dt2: null,
          dt3: 1,
          dt4: "Operación:"
        },
        {
          dt0: "usuarioCreacion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Creado por:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "desactivado" },
      ],



      HCuenta: [{ text: "Descripción:", value: "catalogocuentas.descripcion" }],

      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },

      Elim_notif_cuenta: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este Cuenta?"
      },

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: true
    };


  },
  methods: {
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    onResize(e) {
      // this.windowSize = e
    },


    SelctCbSucursal() {

      if (this.documento.sucursal == null) {

        this.documento.sucursal = ''
      }

      if (this.documento.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.documento.sucursal = ''
        return

      }
    },


    cargarSucursal() {

      this.$axios.get(this.$hostname + this.$hName + '/sucursal/findallActivos/', {
        headers: this.$store.getters.GetheadersAxios
      })
        .then(res => (this.RSucursal = res.data))

    },


    SelctCbCliente() {
      if (this.Modallfiltro.cliente == null) {

        this.Modallfiltro.cliente = ''
      }

      if (this.Modallfiltro.cliente.codigo == undefined) {

        this.alerta("Seleccione un cliente", "error");
        this.Modallfiltro.cliente = ''


        //qlq2

        return

      }

    },


    SelctCbMoneda() {

      if (this.modalCuenta.moneda == null) {

        this.modalCuenta.moneda = ''
      }

      if (this.modalCuenta.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.modalCuenta.moneda = ''
        return

      }

    },

    cargarMoneda() {
      this.$axios.get(
        this.$hostname + this.$hName + '/monedas/findallActivos/',
        {
          headers: this.$store.getters.GetheadersAxios
        }
      ).then(res => (this.Rmoneda = res.data))
    },


    SelctCbcuentaNuevaDesc() {
      if (this.CuentaNueva.descargo == null) {
        this.CuentaNueva.descargo = "";
      }

      if (this.CuentaNueva.descargo.cuenta == undefined) {
        this.alerta("Seleccione una Cuenta descargo", "error");
        this.CuentaNueva.descargo = "";
        return;
      }
    },

    // SelctCbcuentaNuevaDesc() {

    //       if (this.CuentaNueva.descargo == null ) {
    //         this.CuentaNueva.descargo = "";
    //       }

    //       if (this.CuentaNueva.descargo.cuenta == undefined) {
    //         this.alerta("Seleccione una Cuenta", "error");
    //         this.CuentaNueva.descargo = "";
    //         return;
    //       }

    //     },

    // SelctCbCuentaNuevaDescValidControl,SelctCbcuentaNuevaDesc

    SelctCbCuentaNuevaDescValidControl() {
      if (this.CuentaNueva.descargo != null) {
        if (this.CuentaNueva.descargo.tipo == "DE") {
          this.alerta("No puede seleccionar una cuenta de detalle", "error");
          this.CuentaNueva.descargo = null;
        }
      }
    },

    CbFil(e) {
      if (e.target.value.length == 1) {
        if (
          (e.target.value.charCodeAt(0) >= 97) &
          (e.target.value.charCodeAt(0) <= 122)
        ) {
          //console.log("ok letra")

          this.RfilCuenta = "descripcion";

          this.CargarRcuenta();
        } else {
          this.RfilCuenta = "cuenta";
        }

        if (
          (e.target.value.charCodeAt(0) >= 48) &
          (e.target.value.charCodeAt(0) <= 57)
        ) {
          this.CargarRcuenta();
          //console.log("ok numero")
          this.RfilCuenta = "cuenta";
        } else {
          this.RfilCuenta = "descripcion";
        }

        //numero 48 a 57
        //letras 97 a 122
      }

      // console.log(e.target.value)
      // //this.Rcuenta=[]

      //         if (e.target.value.length >= 1) {
      //           //console.log("ok1")
      //           this.$axios
      //             .post(
      //               this.$hostname +
      //                 this.$hName +
      //                 "/services/CuentaCatalogo/findallcuenta",
      //               { descripcion: e.target.value }
      //             )
      //             .then(res => (this.Rcuenta = res.data));
      //         }
    },

    SelctCbCuentaValidControl() {


      if (this.modalCuenta.cuenta != null) {
        if (this.modalCuenta.cuenta.tipo == "CO") {
          this.alerta("No puede seleccionar una cuenta control", "error");
          this.modalCuenta.cuenta = null;
        }
      }
    },

    SelctCbTipoGasto() {
      if (this.CuentaNueva.tipogasto == null) {
        this.CuentaNueva.tipogasto = "";
      }

      if (this.CuentaNueva.tipogasto.codigo == undefined) {
        this.alerta("Seleccione un tipo de gasto", "error");
        this.CuentaNueva.tipogasto = "";
        return;
      }
    },

    SelctCbcuenta() {
      if (this.modalCuenta.cuenta == null) {
        this.modalCuenta.cuenta = "";
      }

      if (this.modalCuenta.cuenta.cuenta == undefined) {
        this.alerta("Seleccione una Cuenta", "error");
        this.modalCuenta.cuenta = "";
        return;
      }
    },

    SelctCbGrupo() {
      if (this.CuentaNueva.grupo == null) {
        this.CuentaNueva.grupo = "";
      }

      if (this.CuentaNueva.grupo.codigo == undefined) {
        this.alerta("Seleccione un Grupo", "error");
        this.CuentaNueva.grupo = "";
        return;
      }
    },

    SelctCbCuentatipo() {
      if (this.CuentaNueva.tipo == null) {
        this.CuentaNueva.tipo = "";
      }

      if (this.CuentaNueva.tipo.codigo == undefined) {
        this.alerta("Seleccione un Tipo", "error");
        this.CuentaNueva.tipo = "";
        return;
      }
    },

    SelctCbtipo() {
      if (this.modalCuenta.tipo == null) {
        this.modalCuenta.tipo = "";
      }

      if (this.modalCuenta.tipo.codigo == undefined) {
        this.alerta("Seleccione un Tipo", "error");
        this.modalCuenta.tipo = "";
        return;
      }
    },

    SelctCbcuentaorigen() {
      if (this.CuentaNueva.origen == null) {
        this.CuentaNueva.origen = "";
      }

      if (this.CuentaNueva.origen.codigo == undefined) {
        this.alerta("Seleccione un Origen", "error");
        this.CuentaNueva.origen = "";
        return;
      }
    },

    SelctCborigen() {
      if (this.modalCuenta.origen == null) {
        this.modalCuenta.origen = "";
      }

      if (this.modalCuenta.origen.codigo == undefined) {
        this.alerta("Seleccione un Origen", "error");
        this.modalCuenta.origen = "";
        return;
      }
    },

    SelctCbOperacion() {
      if (this.documento.operacion == null) {
        this.documento.operacion = "";
      }

      if (this.documento.operacion.codigo == undefined) {
        this.alerta("Seleccione una operación", "error");
        this.documento.operacion = "";
      }

      this.mostrarAlmacenesCombo = this.idOperacionTransferencia === this.documento.operacion.codigo
    },

    CargarROperacion() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/operaciones/find/" + this.operacionModulo,
          {
            headers: this.$store.getters.GetheadersAxios
          }
        )
        .then(res => {
          this.ROperacion = res.data;
        });
    },

    CargarRTipopago() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/categoriagastos/findallactivo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        )
        .then(res => {
          this.RTipoGasto = res.data;
        });
    },

    CargarRcuenta() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/catalogo/findall",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        )
        .then(res => {
          this.Rcuenta = res.data;
        });
    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }

      // if (this.abrirTablaDinamica == false) {
      //   this.Colss.p1 = 9;
      // } else {
      //   this.Colss.p1 = 6;
      // }
    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    alertaModal(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newcuenta();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = `${e}`);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    newcuenta() {
      this.CuentaNueva = {
        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true
      };

      (this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }),
        (this.acti_Nueva_cuenta = false);
    },

    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.datosAdicionales={activo:true}
      this.documento = {
        codigo: 0,
        descripcion: null,
        fechaCreacion: new Date().toISOString().substr(0, 10),
        nombrecorto: null,
        operaciones: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        usuarioCreacion: "admin",
        usuarioModificacion: "admin",
        comprobante: false,
        desactivado: false,
        cuenta: []
      }
      this.Tbcuenta = [],
        this.modalCuenta = {
          codigoDocumento: 0,
          cuenta: null,
          tipo: null,
          origen: null,
          moneda: null
        }
      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }
      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      }
      this.newcuenta();
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },

    saveCuentaNueva() {
      if (this.$refs.form3.validate()) {
        
        if (this.CuentaNueva.descargo == "") {
          this.CuentaNueva.descargo = null
        }

        this.$axios.post(
          this.$hostname + this.$hName + '/documento-inv/cuentas',
          this.CuentaNueva, {
          headers: this.$store.getters.GetheadersAxios
        }
        ).then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error => this.alertaModal(error, 'error'))
      } else {
        this.alertaModal("los campos en rojo son Requeridos", "error");
      }

    },

    save() {

      this.debito = false
      this.credito = false

      if (this.Tbcuenta.length > 0) {
        this.Tbcuenta.forEach(element1 => {
          if (element1.debito == true) {
            this.debito = true
          }
          if (element1.debito == false) {
            this.credito = true
          }

        });


        if (this.debito == false || this.credito == false) {
          this.alertaModal("El Catálogo de cuenta debe de ser de partida doble", "error");
          return

        }

      }

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {

          this.ejecucion = false
          EventBus.$emit("loading", true);
          if (this.documento.codigo === 0) {
            this.documento.usuarioCreacion = this.$store.getters.GetdatosInfUsu.usuario.username
          } else {
            this.documento.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username
          }

          if (this.mostrarAlmacenesCombo && this.documento.almacenDestino == null && this.documento.almacenOrigen == null) {
            this.alerta("Para los documentos de tipo transferencia es necesario seleccionar los almacenes para transferir", 'error')
            return
          }

          if (this.mostrarAlmacenesCombo && +this.documento.almacenDestino.codigo === +this.documento.almacenOrigen.codigo) {
            this.alerta("El almacén de origen no puedo ser el mismo al del destino", 'error')
            return
          }

          this.documento['documentoinventarioCatalogoList'] = this.documento.cuenta;
          this.documento.desactivado = !this.datosAdicionales.activo;
          this.$axios.post(
            this.$hostname + this.$hName + '/documento-inv',
            this.documento,
            { headers: this.$store.getters.GetheadersAxios }
          ).then(res => {
            this.alerta("Dato guardado correctamente", "bien")
            this.mostrarAlmacenesCombo = false;
          })
            .catch(error => {
              if (error.response) {
                this.alerta(error.response.request.responseText, 'error')
              } else {
                this.alerta(error, 'error')
              }
            })
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }

    },


    remove() {

      if (this.documento.codigo != 0 && this.documento.codigo != null) {

        if (this.documento.codigo !== 0) {
          this.documento.usuarioModificacion = this.$store.getters.GetdatosInfUsu.usuario.username
        }
        EventBus.$emit("loading", true);
        this.$axios.delete(this.$hostname + this.$hName + '/documento-inv/' + this.documento.codigo,
          {
            headers: this.$store.getters.GetheadersAxios
          }).then(res => {
            EventBus.$emit("loading", false);
            this.Movimientoeliminar(res.data)
            this.newwnuevo()
            this.filtro()
          })
          .catch(error => {
            EventBus.$emit("loading", false);
            this.alertaModal(error, 'error')
          })
      }
    },


    removeCuenta() {
      this.Elim_notif_cuenta.estado = !this.Elim_notif_cuenta.estado;


    },

    eliminarCuenta() {
      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      };


      this.newcuenta();
    },

    Movimientoeliminar(e) {
      if (e.estado == "error") {
        this.alertaModal(e.mensage, e.estado);
      }

      if (e.estado == "bien") {
        this.Elim_notif.estado = !this.Elim_notif.estado;
      }
      this.mostrarAlmacenesCombo = false;
    },

    eliminar() {

        if (this.documento.codigo != 0 && this.documento.codigo != null) {
          this.Elim_notif.estado = !this.Elim_notif.estado;
        } else {
          this.alerta("Seleccione un documento", "error")
        }
      
    },


    saveCuenta() {
      if (this.$refs.form2.validate()) {
        if(this.modalCuenta.cuenta.tipo.trim()==="CO"){
    this.alerta("Esta es una cuenta control. Por lo tanto no la puede asignar", "error");
  return;
}

        //console.log('buenno ok')
        //lert("Ok Entro")

        // {"activo":true,
        // "borrado":false,
        // "cuenta":"400-010-001-001",
        // "descripcion":"INGRESOS SOBRE VENTA",
        // "empresa":0,"grupo":"IN","origen":"C","tipo":"DE"}
        var add = false;

        if (this.Tbcuenta.length == 0) {
          add = true;
        }

        this.Tbcuenta.forEach(element1 => {


          if (element1.catalogocuentas.cuenta === this.modalCuenta.cuenta.cuenta) {
            this.alertaModal("Esta  cuenta ya está  agregada", "error");
            add = false;
          } else {
            add = true;
          }
        });

        if (add == true) {
          var json = {
            catalogocuentas: {
              cuenta: this.modalCuenta.cuenta.cuenta,
              descripcion: this.modalCuenta.cuenta.descripcion,
              empresa: this.modalCuenta.cuenta.empresa,
              grupo: this.modalCuenta.cuenta.grupo,
              origen: this.modalCuenta.cuenta.origen,
              tipo: this.modalCuenta.cuenta.tipo
            },

            moneda: this.modalCuenta.moneda,
            debito: this.modalCuenta.origen.debito,
            tipo: this.modalCuenta.tipo.codigo
          };


          this.Tbcuenta.unshift(json);

          this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
          this.alertaModal("Cuenta agregada correctamente", "bien");

          this.modalCuenta = {
            codigoDocumento: 0,
            cuenta: null,
            tipo: null,
            origen: null,
            moneda: null,

          };
        }

        // Tbcuenta:[]

        // var json={
        //         catalogocuentas= modalCuenta.cuenta,
        //         debito= modalCuenta.origen.debito,
        //         tipo= modalCuenta.tipo.codigo
        //     }

        // this.TbGanadero.push(this.ganaderoo);

        // this.$axios.post(this.$hostname + this.$hName + '/services/Moneda/save',
        //             JSON.parse(JSON.stringify(this.documento)))
        //              .then(res => this.alerta("Dato guardado correctamente", "bien"))
        //              .catch(error => this.alerta(error,'error'))
      } else {
        this.alertaModal("Los campos en rojos son obligatorios", "error");

        // this.alerta("los campos en rojo son Requeridos", "error");
      }
    },


    cerrarmodalcuenta() {

      // if (this.modalCuenta.codigoDocumento != 0) {
      //   var json = {
      //     catalogocuentas: {
      //       cuenta: this.objec.cuenta.cuenta,
      //       descripcion: this.objec.cuenta.descripcion,
      //       empresa: this.objec.cuenta.empresa,
      //       grupo: this.objec.cuenta.grupo,
      //       origen: this.objec.cuenta.origen,
      //       tipo: this.objec.cuenta.tipo
      //     },

      //     debito: this.objec.origen.debito,
      //     tipo: this.objec.tipo.codigo
      //   };
      //   this.Tbcuenta.push(json);
      // }

      if (this.objec.cuenta != null) {

        var json = {
          catalogocuentas: {
            cuenta: this.objec.cuenta.cuenta,
            descripcion: this.objec.cuenta.descripcion,
            empresa: this.objec.cuenta.empresa,
            grupo: this.objec.cuenta.grupo,
            origen: this.objec.cuenta.origen,
            tipo: this.objec.cuenta.tipo

          },
          moneda: this.objec.moneda,
          debito: this.objec.origen.debito,
          tipo: this.objec.tipo.codigo
        };

        this.Tbcuenta.unshift(json);

      }


      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;

      this.modalCuenta = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      };

      this.objec = {
        codigoDocumento: 0,
        cuenta: null,
        tipo: null,
        origen: null,
        moneda: null
      };
    },

    Modaldatostabla(e) {
      // modalCuenta:{
      //   codigoDocumento:0,
      //   cuenta:null,
      //   tipo:null,
      //   origen:null
      // },


      if (e.hasOwnProperty('moneda')) {
        this.modalCuenta.moneda = JSON.parse(JSON.stringify(e.moneda))
        this.objec.moneda = JSON.parse(JSON.stringify(e.moneda))
      } else {
        this.modalCuenta.moneda = null
        this.objec.moneda = null
      }


      this.modalCuenta.codigoDocumento = this.documento.codigo;
      this.objec.codigoDocumento = this.documento.codigo;
      //console.log(e)

      // this.editedIndex = this.Tbcuenta.indexOf(e)
      // this.modalCuenta.cuenta = Object.assign({}, e.catalogocuentas)

      this.modalCuenta.cuenta = e.catalogocuentas;

      this.objec.cuenta = e.catalogocuentas;

      // this.modalCuenta.tipo.codigo=e.tipo
      if (this.modalCuenta.codigoDocumento == 0) {
        this.modalCuenta.marc = true
      }

      if (e.debito == false) {
        this.modalCuenta.origen = this.OrigenCatalogo[0];
        this.objec.origen = this.OrigenCatalogo[0];
      } else {
        this.modalCuenta.origen = this.OrigenCatalogo[1];
        this.objec.origen = this.OrigenCatalogo[1];
      }

      this.tipoCatalogo.forEach(element1 => {
        if (element1.codigo == e.tipo) {
          this.modalCuenta.tipo = element1;
          this.objec.tipo = element1;
        }
      });

      const index = this.Tbcuenta.indexOf(e);
      this.Tbcuenta.splice(index, 1);

      this.acti_Modal_cuenta = !this.acti_Modal_cuenta;
    },

    anular() {


    },

    imprimirList() { this.dialogimprimirList = true },

    datostabla(e) {
      this.documento = JSON.parse(JSON.stringify(e));
      this.datosAdicionales.activo= !e.desactivado;

      if (this.documento.operacion != null) {
        this.mostrarAlmacenesCombo = +this.documento.operacion.codigo === this.idOperacionTransferencia
      }

      this.acti_tb_data = false;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documento-inv/cuentas/" + this.documento.codigo,
          {
            headers: this.$store.getters.GetheadersAxios
          }
        )
        .then(res => {
          this.Tbcuenta = res.data
          this.documento.cuenta = res.data;
        });


      if (this.windowSize.width <= 600) {

        this.BarraTablaDinamicaAprir = false

      }


    },

    docTipoCuenta(e) {
      this.documento.cuenta = [];
      this.documento.cuenta = e;
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/documento-inv/findall",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        )
        .then(res => {
          this.list = res.data;
        });
    },
    SelctCbAlmacen() {
      // if (this.documento.almacenOrigen == null) {
      //   this.AnalisisVentas.almacen = "";
      // }

      if (!this.documento.almacenOrigen) {
        this.alerta("Seleccione un Almacén", "error");
        return;
      }
    },
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos", {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },

  },

  computed: {

    botonCuenta() {


      if (this.windowSize.width <= 800) {
        this.BarraSecundariaAprir = false
      }

      if (this.windowSize.width > 800) {
        this.BarraSecundariaAprir = true
      }


      if (this.documento.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }


      if (this.documento.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }


  }


};
</script>

<style>

</style>
