<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Especiales -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Especiales'"
          :descripcion="'En esta opcion se crean los diferentes especiales de productos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>
    <!-- ContenidoBase-Especiales -->
      <tr>
        <!-- BarraPrimaria-Especiales --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>



                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/especiales/findall/'"
                  :nombre="'Especiales'" :emitnombre="'emitEspeciales'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descuento %', value: 'porciento' },
                    { text: 'Fecha de creación', value: 'fecha' },
                    { text: 'Fecha de inicio', value: 'fechainicio' },
                    { text: 'Fecha de fin', value: 'fechafin' },
                    { text: 'Comentario', value: 'comentario' },
                    { text: 'Producto', value: 'producto.descripcion' },
                    { text: 'Departamento', value: 'departamento.descripcion' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Producto', NomValue: 'producto', Nv2: 'descripcion', Ctsl: 2, Type: 0.3, PrgState: 1, PrgValue: 'por' },
  { Text: 'departamento', NomValue: 'departamento', Nv2: 'descripcion', Ctsl: 2, Type: 0.3, PrgState: 2, PrgValue: 'por' },
  { Text: 'Descuento %', NomValue: 'porciento', Ctsl: 2, Type: 0, State: true },
  // {Text:'Fecha de creación', NomValue:'fecha', Ctsl:1, Type:1, State:true},
  { Text: 'Fecha de inicio', NomValue: 'fechainicio', Ctsl: 1, Type: 1, State: true },
  { Text: 'Fecha de fin', NomValue: 'fechafin', Ctsl: 1, Type: 1, State: true },
  { Text: 'Comentario', NomValue: 'comentario', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.1, State: true }
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/Especiales/findall'"
              :nombre="'Especiales'"
              :headers="headers"
              :slotname="'item.comentario'"
              :emitnombre="'emitEspeciales'"
              :SeachNombre="'Búsqueda por Concepto del especial'"
              :slotlist="slotlist"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>



          </div>
        </td>
        <!-- fn BarraPrimaria-Especiales ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">

 <!-- Formulario-Especiales   -->

              <v-form ref="form" v-model="valid" lazy-validation>
                <input type="number" v-model="Especiales.codigo" style="display:none;" />

                <v-card color="grey lighten-3">
                  <div class="px-2">
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-radio-group v-model="Especiales.por" row>


                          <v-radio label="Por Producto" :value="1"></v-radio>
                          <v-radio label="Por Departamento" :value="2"></v-radio>

                          <!-- <v-row>
                          <v-col cols="12" md="6">
                            <v-radio label="Por Producto" :value="1"></v-radio
                          ></v-col>
                     
                          <v-col cols="12" md="6">
                            <v-radio
                              label="Por Departamento"
                              :value="2"
                            ></v-radio>
                          </v-col>
                        </v-row> -->


                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-combobox v-if="Especiales.por == 1" autocomplete="off" dense outlined label="Productos:"
                          :items="Rproducto" item-text="descripcion" :rules="[$rules.required]" @keyup="CbFil"
                          v-model="Especiales.producto" @blur="SelctCbProduc" @change="VerifEspecial">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-shopping-cart"></i>
                          </template>
                        </v-combobox>

                        <v-combobox v-if="Especiales.por == 2" autocomplete="off" dense outlined label="Departamento:"
                          :items="Rdepartamento" item-text="descripcion" :rules="[$rules.required]"
                          v-model="Especiales.departamento" @keyup="CbFil" @blur="SelctCbDesc" @change="VerifEspecial">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-building"></i>
                          </template>
                        </v-combobox>

                        <!-- <v-text-field
            v-model="Especiales.producto"
            :rules="[rules.required]"
            label="Productos"
            required
            outlined
            dense
          >
            <template v-slot:prepend>
              <vue-fontawesome
                icon="shopping-cart"
                size="2"
                color="black"
              ></vue-fontawesome>
            </template>
          </v-text-field> -->
                      </v-col>
                      <!-- </v-row>
      <v-row>   Por aqui-->
                      <v-col cols="12" md="6">
                        <Autonumeric v-model="Especiales.porciento" :rules="[$rules.porciento]" label="Descuentos"
                          required outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-percent"></i>
                          </template>
                        </Autonumeric>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field dense outlined type="date" color="grey darken-4" label="Fecha Inicial"
                          :rules="[$rules.required]" v-model="Especiales.fechainicio" @input="VerifEspecial">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <v-text-field label="Hora Inicial"
                    style="font-size:13px;"
                    :rules="[$rules.required]"
                     dense outlined
                     v-model="Especiales.horainicio"
                     type="time"
                     id="formdisabledInput"
                   
                     
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>

                      </v-col>
                      <!-- </v-row>
      <v-row> -->
                      <v-col cols="12" md="6">
                        <v-text-field dense outlined type="date" color="grey darken-4" label="Fecha Final"
                          :rules="[$rules.required]" v-model="Especiales.fechafin" @input="VerifEspecial">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <v-text-field label="Hora fin"
                              style="font-size:13px;"
                              :rules="[$rules.required]"
                              dense outlined
                              v-model="Especiales.horafin"
                              type="time"
                              id="formdisabledInput"
                              
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="9">
                        <v-textarea label="Concepto" v-model="Especiales.comentario" :rules="[$rules.required]" required
                          outlined dense>
                          <template v-slot:prepend>
                            <vue-fontawesome icon="clipboard" size="2" color="black"></vue-fontawesome>
                          </template>
                        </v-textarea>
                      </v-col>

                      <v-col cols="3" md="3">
                        <v-switch v-model="Especiales.activo" label="Activo" color="success" hide-details></v-switch>
                      </v-col>
                    </v-row>
                  </div>

                </v-card>
              </v-form>
              <!-- Formulario-Especiales   -->

            </div>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarEspecial'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Especial por:', value: 'descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: '%Descuento', value: 'porciento', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha de creación', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha de inicio:', value: 'fechainicio', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha de fin', value: 'fechafin', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Comentario', value: 'comentario', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">
              <template v-slot:item.descripcion="props">
                <span v-if="props.item.por == 1"><b>Producto: </b>{{ props.item.producto.descripcion }}</span>
                <span v-if="props.item.por == 2"><b>Departamento: </b>{{ props.item.departamento.descripcion }}</span>
              </template>

              <template v-slot:item.porciento="props">
                <span>{{
                    currencyFormatter(props.item.porciento)
                }}</span>
              </template>

              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.fechafin="props">
                <span>{{ FormatoFecha(props.item.fechafin) }}</span>
              </template>

              <template v-slot:item.fechainicio="props">
                <span>{{ FormatoFecha(props.item.fechainicio) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->
    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->

  </div>

</template>

<script>
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamEspecial";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase
  },

  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitEspeciales", this.datostabla);
    // EventBus.$on("EspecialesNew", this.new);
    // EventBus.$on("EspecialesSave", this.save);
    // EventBus.$on("EspecialesRemove", this.remove);
    // EventBus.$on("EspecialesAnular", this.anular);
    // EventBus.$on("EspecialesPrintList", this.imprimirList);

    this.cargar();
    this.filtro();
  },
  created() {
    var json = {
      titulo: "Especiales",
      descripcion:
        "En esta opcion se crean los diferentes especiales de productos ",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "EspecialesNew",
        "EspecialesSave",
        "EspecialesRemove",
        "EspecialesAnular",
        "EspecialesPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data: () => ({
    windowSize: { width: "auto", height: "auto" },
    Rproducto: [],
    Rdepartamento: [],

    Especiales: {
      codigo: 0,
      por: 1,
      producto: null,
      departamento: null,
      porciento: 0,
      comentario: "",
      activo: true,
      borrado: false,
      fechainicio: fechaNueva(),
      fechafin:  fechaNueva(),
      horainicio:null,
      horafin:null,
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    Colss: {
      p1: 8,
    },
    valid: true,
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    firstname: null,
    lastname: null,
    email: null,



    headers: [{ text: "Especiales", value: "comentario" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      { dt0: "por", dt1: null, dt2: null, dt3: null, dt4: "por" },
      { dt0: "porciento", dt1: null, dt2: null, dt3: 1, dt4: "%Descuento:" },
      { dt0: "fechainicio", dt1: null, dt2: null, dt3: 2, dt4: "Fecha Inicial:" },
      { dt0: "fechafin", dt1: null, dt2: null, dt3: 2, dt4: "Fecha Final:" },
      { dt0: "comentario", dt1: null, dt2: null, dt3: 1, dt4: "Concepto :" },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],


    list: [],
    search: "",
    acti_tb_data: false,

    ejecucion: true,
    aalert: {
      estado: false,
      color: null
    },


    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },


    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false
  }),

  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    // Un método que se llama una api para traer producto.
    CbFil(e) {
      //console.log(e.target.value)

      if (this.Especiales.por == 1) {
        if (e.target.value.length >= 2) {
          //console.log("ok1")

          this.$axios.get(this.$hostname + this.$hName + "/producto/findbydesc/" + e.target.value + "/25/0", { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.Rproducto = res.data; });


        }
      }

      if (this.Especiales.por == 2) {
        this.cargar();

        // if (e.target.value.length>=3){
        // this.$axios.post(this.$hostname+this.$hName+'/services/Especiales/getAllDepartamento',{})
        // .then(res=>( this.Rdepartamento=res.data))
        // }
        //console.log("ok2")
      }

      // console.log(this.Especiales.producto.length)
      //if(this.Especiales.producto.toString().length>3){}

      //  Especiales:{
      //     por:1,
      //     producto:null,
    },

   // Comprobando si el producto es nulo o indefinido.
    SelctCbProduc() {
      if (this.Especiales.producto == null) {
        this.Especiales.producto = "";
      }

      if (this.Especiales.producto.codigo == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.Especiales.producto = "";
        return;
      }
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.Especiales.departamento == null) {
        this.Especiales.departamento = "";
      }

      if (this.Especiales.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.Especiales.departamento = "";
        return;
      }
    },

    cargar() {
      // this.$axios.post(this.$hostname+this.$hName+'/services/Especiales/getAllProduct',{})
      // .then(res=>( this.Rproducto=res.data))



      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/especiales/getAllDepartamento/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.Rdepartamento = res.data; });

      this.$axios.get(this.$hostname + this.$hName + "/producto/findbydesc/a/25/0", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.Rproducto = res.data; });


    },

   // Abriendo una mesa.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


      // if(this.abrirTablaDinamica==true){this.Colss.p1=8}
      // if(this.abrirTablaDinamica==false){this.Colss.p1=12}



    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },

    // resetea las variables del formulario.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.Especiales = {
        codigo: 0,
        por: 1,
        producto: null,
        departamento: null,
        porciento: 0,
        comentario: "",
        activo: true,
        fechainicio: fechaNueva(),
        fechafin:  fechaNueva(),
        horainicio:null,
        horafin:null,
      }

      this.ejecucion = true
      EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
    },




    // Envío de una solicitud POST al servidor guardando el especial.
    save() {

      if (this.Especiales.fechafin < this.Especiales.fechainicio) {
        this.alerta("La 'FECHA FINAL' es menor a la 'FECHA INICIAL'", "error");
        return
      }



      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);

          // var jsn={
          // datos:JSON.parse(JSON.stringify(this.Especiales)),
          // usuario:JSON.parse(JSON.stringify(this.$user)) 
          // }
          //           this.$axios
          //             .post(
          //               this.$hostname + this.$hName + "/services/Especiales/save",
          //               JSON.parse(JSON.stringify(jsn))
          //             )
          //             .then(res => this.alerta(res.data.mensage,res.data.estado))
          //             .catch(error => this.alerta(error, "error"));

var ESP=JSON.parse(JSON.stringify(this.Especiales))
ESP.fechafin=this.Especiales.fechafin+"T04:00"
ESP.fechainicio=this.Especiales.fechainicio+"T04:00"

if(this.Especiales.horafin.length==5){
  ESP.horafin=this.Especiales.horafin+":00"
}else{
  ESP.horafin=this.Especiales.horafin
}
if(this.Especiales.horainicio.length==5){
ESP.horainicio=this.Especiales.horainicio+":00"
}else{
  ESP.horainicio=this.Especiales.horainicio
}
          this.$axios
            .post(this.$hostname + this.$hName + "/especiales/save/", ESP, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage, res.data.estado) })
            .catch(error => {
              EventBus.$emit("loading", false);
              this.alerta("Dato no guardado correctamente", "error")
            });
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },
    // Crear un objeto de notificación y luego llamar a la función Exec_notif.
    NotifMessage() {

      if (this.Especiales.codigo != 0 && this.Especiales.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarEspecial"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una caja para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    anular() {


    },

    // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/especiales/imprimir/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/
          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'Especiales');

      });

    },

    // Hacer una solicitud de publicación al servidor.
    eliminar() {
      if (this.Especiales.codigo != 0 && this.Especiales.codigo != null) {
        if (this.$refs.form.validate()) {


          this.$axios
            .post(this.$hostname + this.$hName + "/especiales/borrado/", this.Especiales, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage, res.data.estado) })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));
        }
      }
    },

    // Realización duna verificacion si es un departamento o producto.
    VerifEspecial(e) {
      var codigo = 0

      if (this.Especiales.por == 1) {
        if (this.Especiales.producto == null) {
          return
        }
        codigo = 0
      }

      if (this.Especiales.por == 2) {
        if (this.Especiales.departamento == null) {
          return
        }
        codigo = this.Especiales.departamento.codigo
      }

      if (this.Especiales.fechainicio.toString().length > 0 && this.Especiales.fechafin.toString().length > 0) {
        // console.log('Entrando entrando')
        // var jsn={
        // datos:JSON.parse(JSON.stringify(this.Especiales)),
        // usuario:JSON.parse(JSON.stringify(this.$user)) 
        // }
        //           this.$axios
        //             .post(
        //               this.$hostname + this.$hName + "/services/Especiales/VerificarProducto/"+,
        //               JSON.parse(JSON.stringify(jsn))
        //             )
        //             .then(res => {if(res.data.estado=="error"){this.alerta(res.data.mensage,res.data.estado)}}
        //             )
        //             .catch(error => this.alerta(error, "error"));



        // var headers=this.$store.getters.GetheadersAxios

        //        this.$axios.get(this.$hostname + this.$hName + "/especiales/VerificarProducto/"+codigo, {headers:headers})
        //         .then(res => {if(res.data.estado=="error"){this.alerta(res.data.mensage,res.data.estado)}});



      }

    },


    // Tomando los datos de la tabla y poniéndolos en un formulario.
    datostabla(e) {
      if (!e.hasOwnProperty('porciento')) {
        e.porciento = 0

      }

      var jsn = JSON.parse(JSON.stringify(e))

      jsn.fechainicio = new Date(e.fechainicio).toISOString().substr(0, 10);
      jsn.fechafin = new Date(e.fechafin).toISOString().substr(0, 10);

if(e.horainicio!=null && e.horainicio.length>0){
  jsn.horainicio=e.horainicio.substr(0, 5)
}

if(e.horafin!=null && e.horafin.length>0){
  jsn.horafin=e.horafin.substr(0, 5)
}

      this.Especiales = JSON.parse(JSON.stringify(jsn));


      // this.Especiales.descuento = e.porciento;




      this.acti_tb_data = false;


      if (this.windowSize.width <= 600) {

        this.BarraTablaDinamicaAprir = false

      }
      //console.log(e)
    },

   // Un método que se llama cuando el usuario hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

   // Llamar a la API y obtener los datos.
    async filtro() {
      var headers = this.$store.getters.GetheadersAxios

      await this.$axios.get(this.$hostname + this.$hName + "/especiales/findall/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.list = res.data; });
    }

    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },




  computed: {

    ActDescBtn() {


      if (this.Especiales.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }



        var objjct = {
          save: true,
          eliminar: true,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };

      }


      if (this.Especiales.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        var objjct = {
          save: true,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };


      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }





  }






};
</script>

<style>
</style>
