<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
   <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte Productos Mas Comprados'"
  :descripcion="'Imprime Productos Mas Vendidos Por Proveedor'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesProductosMasComprados'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Productos Mas Comprados </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="ProductosMasComprados.desde"
        @blur="ReportBlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


                <v-combobox                   
                        autocomplete="off"
                        dense
                        outlined
                        label="Productos:"
                        :items="Rproducto"
                        item-text="codProDesc"
                        @keyup="CbFilProducto"
                        @blur="SelctCbProduc"
                        v-model="ProductosMasComprados.producto"
                       @focus="CargarRproducto" 
                       hide-details
                       no-filter
                      >
                      <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-shopping-cart"
                          ></i>
                        </template>            
  </v-combobox>



</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="ProductosMasComprados.hasta"
        @blur="ReportBlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


                <v-combobox
            autocomplete="off"
            dense
            outlined
            label="proveedor:"
            :items="Rproveedor"
            item-text="nombre" 
            style="font-size: 13px;" 
            v-model="ProductosMasComprados.proveedor"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            hide-details
            
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
</v-combobox>

</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
ref="ModRepfil"
@DataReporteBasico="DDataReporteBasico"
:Datos="ProductosMasComprados"/>

  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros,
   ModalReporteBasico,
  },
  created() { 
    var json = {  
      titulo: "Reporte Productos Mas Comprados",
      descripcion: "Imprime Productos Mas Vendidos Por Proveedor",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [ 
        "ReportesProductosMasCompradosNew",
        "ReportesProductosMasCompradosSave",
        "ReportesProductosMasCompradosRemove",
        "ReportesProductosMasCompradosAnular",
        "ReportesProductosMasCompradosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("onResize", this.onResize);

     this.$refs.ModRepfil.CrearObjecto=this.validarObjecto;
  },

  data:()=>  ({

      Rproveedor:[],
      Rproducto:[],
      Rpropietario:[],
      RCaja:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      ProductosMasComprados: {
      
      nombre:'PRODUCTOS MAS COMPRADOS',
      UrlImprimir: '/reportesCXP/ImprimirProductomascomprado', 
      UrlExel: '/reportesCXP/ExelProductomascomprado', 
      UrlCorreo: '/reportesCXP/CorreoProductomascomprado', 
        desde: fechaNueva(),
        hasta: fechaNueva(),
        producto:null,
        proveedor:null

        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
ReportBlurDesde(){
  if(this.ProductosMasComprados.desde.toString().length==0){
    this.ProductosMasComprados.desde=this.fechaNueva()
  }
},
ReportBlurHasta(){
  if(this.ProductosMasComprados.hasta.toString().length==0){
    this.ProductosMasComprados.hasta=this.fechaNueva()
  }
},

    datostabla(e) {

      this.ProductosMasComprados = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() {},
    eliminar() { },
    anular() { },
    imprimirList() {},

DDataReporteBasico(e){
},

validarObjecto(){ 
//this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
this.ProductosMasComprados.moneda=this.moneda =JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim())) 

},



    newwnuevo() {
      (this.ProductosMasComprados = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


    CargarRproducto(){

this.$axios.get(this.$hostname +this.$hName +
    "/unidades-productos/*/false/buscar",
    {headers:this.$store.getters.GetheadersAxios}
  ).then(res => {this.Rproducto = res.data }  );
},

CbFilProducto(e) {
   if (e.target.value.length >= 0) {

this.$axios
  .get(this.$hostname +
      this.$hName +
      "/unidades-productos/"+(e.target.value === "" ? "A" : e.target.value) +"/false/buscar",
    {headers:this.$store.getters.GetheadersAxios}
  )
  .then(res => {this.Rproducto = res.data });
   }
  },


SelctCbProduc() {

  var _this = this;
      setTimeout(function () {
        if (_this.ProductosMasComprados.producto == null) {
          _this.ProductosMasComprados.producto = "";
        }

        if (!_this.ProductosMasComprados.producto.hasOwnProperty('productos')) {
          _this.ProductosMasComprados.producto = "";
          _this.ProductosMasComprados.producto = null;
          return;
        }
      }, 300);

  },



  cargarProveedor(){

this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))

},

CbFilProveedor(e) {
     
       if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
      
    },

SelctCbProveedor(){

  var _this = this;
      setTimeout(function () {
        if (_this.ProductosMasComprados.proveedor == null) {
          _this.ProductosMasComprados.proveedor = "";
        }

        if (!_this.ProductosMasComprados.proveedor.hasOwnProperty('codigo')) {
          _this.ProductosMasComprados.proveedor = "";
          _this.ProductosMasComprados.proveedor = null;
          return;
        }
      }, 300);

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
      //     usuario:JSON.parse(JSON.stringify(this.$user)) 
      //   })
      //   .then(res => {
      //     this.list = res.data;
      //   });
    }
  },

computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))  


    }

}



};
</script>

<style></style>
