<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Reporte de Estado de Cuenta'" :descripcion="'Imprime el Estado de Cuenta de Clientes.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReporteEstadoCuenta'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

<!-- Formulario-Estado de Cuenta -->
            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>


                  <span class="font-weight-light" style="font-size: 20px;"> Reporte de Estado de Cuenta </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  Fecha de Corte  -->
                        <v-text-field dense outlined type="date" label="Fecha de Corte:" :rules="[$rules.required]"
                          v-model="EstadoCuenta.fecha">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>


                        <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                          item-text="nombrecorto" v-model="EstadoCuenta.moneda" @blur="SelctCbMoneda"
                          @focus="cargarMoneda">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>



                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  Cliente  -->
                        <v-combobox autocomplete="off" dense outlined label="Cliente:" :items="Rcliente"
                          item-text="codnom" @keyup="CbFilcliente" v-model="EstadoCuenta.cliente" @blur="SelctCbClient"
                          @focus="cargarCliente" no-filter>

                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-user-circle-o"></i>
                          </template>
                        </v-combobox>


                        <v-combobox :items="RFormaImprecion" item-text="descripcion" @blur="SelctCbSelectFormaImpr"
                          label="Forma de impreción" required outlined dense v-model="EstadoCuenta.fomaImpr">
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-print"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn columna 2 -->

                    </v-row>

                  </v-form>
                </v-container>

                <hr>


                <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico" :Datos="EstadoCuenta" />



              </v-card>
            </v-container>
<!-- fn Formulario-Estado de Cuenta -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { EventBus } from "../../event-bus";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Reporte de Estado de Cuenta",
      descripcion: "Imprime el Estado de Cuenta de Clientes.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [

        "ReporteEstadoCuentaNew",
        "ReporteEstadoCuentaSave",
        "ReporteEstadoCuentaRemove",
        "ReporteEstadoCuentaAnular",
        "ReporteEstadoCuentaPrintList" 
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  //mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReporteEstadoCuentaNew", this.new);
    EventBus.$on("ReporteEstadoCuentaSave", this.save);
    EventBus.$on("ReporteEstadoCuentaRemove", this.remove);
    EventBus.$on("ReporteEstadoCuentaAnular", this.remove);
    EventBus.$on("ReporteEstadoCuentaPrintList", this.imprimirList);
    EventBus.$on("emitReporteEstadoCuenta", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
  },
  // fn mounted-carga de inicio
  data: () => ({


    Rpropietario: [],
    RCaja: [],
    RtipoCliente: [],
    Rcliente: [],
    Rmoneda: [],
    RFormaImprecion: [
      { codigo: 1, descripcion: "Pagína completa" },
      { codigo: 2, descripcion: "Rollo" },
    ],

    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    EstadoCuenta: {
      nombre: 'Reporte de Estado de Cuenta',
      UrlImprimir: '/reportescxc/ImprimirEstadoCuenta',
      UrlExel: '/reportescxc/ExcelEstadoCuenta',
      UrlCorreo: '/reportescxc/CorreoEstadoCuenta',
      fecha: fechaNueva(),
      moneda: null,
      cliente: null,
      fomaImpr: { codigo: 1, descripcion: "Pagína completa" },
      usarReferencia:false
      //fechaCreacion: new,
      //  fechaModificacion: "", 
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,


    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },

    datostabla(e) {

      this.EstadoCuenta = e;
      this.acti_tb_data = false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() { this.dialogimprimirList = true },
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(e) { this.dialogimprimirList = e },
    // valida el formulario y envia una sentencia consulta sql
    validarObjecto() {

    },
    newwnuevo() {
      (this.EstadoCuenta = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },




   // Hacer una solicitud GET al servidor y devolver los datos al cliente.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rcliente = res.data))

    },


    // Comprobando si el cliente es nulo o indefinido.
    SelctCbClient() {
      if (this.EstadoCuenta.cliente == null) {
        this.EstadoCuenta.cliente = "";
      }

      if (this.EstadoCuenta.cliente.codigo == undefined) {
        //  this.alerta("Seleccione un cliente", "error");
        this.EstadoCuenta.cliente = "";
        return;
      }

    },

    CbFilcliente(e) {
      //console.log(e.target.value)

      if (e.target.value.length >= 2) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rcliente = res.data));
      }

    },


   // Al verificar si el valor de la selección es nulo, si es nulo, establece el valor en la primera
   // opción en la selección.
    SelctCbSelectFormaImpr() {
      if (this.EstadoCuenta.fomaImpr == null) {

        this.EstadoCuenta.fomaImpr = { codigo: 1, descripcion: "Pagína completa" }
      }

      if (this.EstadoCuenta.fomaImpr.codigo == undefined) {
        //  this.alerta("Seleccione un cliente", "error");
        this.EstadoCuenta.fomaImpr = { codigo: 1, descripcion: "Pagína completa" };
        return;
      }

    },



    // Llamar a una API y obtener una lista de monedas.
    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbMoneda() {
      if (this.EstadoCuenta.moneda == null) {
        this.EstadoCuenta.moneda = ''
      }
      if (this.EstadoCuenta.moneda.codigo == undefined) {
        //this.alerta("Seleccione una moneda", "error");
        this.EstadoCuenta.moneda = ''
        return
      }

    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
      //     usuario:JSON.parse(JSON.stringify(this.$user)) 
      //   })
      //   .then(res => {
      //     this.list = res.data;
      //   });
    }
  }
};
</script>

<style>

</style>
