<template>
  <div>{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">

        <ContenidoBase :titulo="'Usuarios'" :descripcion="'Crear, Modificar y Desactivar Usuarios'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">

                <BarraDinamicaNormal :url="$hostname + $hName + '/usuario/findAllSudoFalse'" :nombre="'Usuario'"
                  :emitnombre="'emitConfUsuario'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Usuario', value: 'usuario' },
                    { text: 'Fecha creación', value: 'fechaCreacion' },
                    { text: 'Estado', value: 'status' }
                  ]" :ContListSlot="[
                    { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Nombre', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Usuario', NomValue: 'usuario', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Creación', NomValue: 'fechaCreacion', Ctsl: 1, Type: 1, State: true },
                    { Text: 'Estado', NomValue: 'status', Ctsl: 1, Type: 0.1, State: true },
                  ]" />


              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <v-card color="grey lighten-3">

                <v-col cols="12" md="12" sm="12" class="px-2">

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>

                      <v-col cols="12" md="6" sm="6" class=" py-0 my-0">

                        <!-- Nombre:-->
                        <v-text-field label="Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="usuario.nombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6" class=" py-0 my-0">
                        <!--@blur="validarIduser"  -->
                        <v-text-field label="usuario:" id="formdisabledInput" :disabled="(usuario.codigo>0)" autocomplete="off" @blur="ValidIdUserBlur" dense outlined :rules="[$rules.required]"
                          v-model="usuario.usuario">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-tooltip left>
                            <template v-slot:activator="{ on }">
                            <i v-show="ValidIdUser == false" v-on="on" style="font-size: 30px; color:#F44336;" class="fa fa-times"></i>
                            </template>
                            <span>{{ValidIdUserDesc}}</span>
                            </v-tooltip>


                            <i v-show="ValidIdUser == true" style="font-size: 30px; color:#4CAF50;"
                              class="fa fa-check"></i>
                          </template>
                        </v-text-field>
                      </v-col>


                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="clave:" autocomplete="off" type="password" dense outlined
                          :rules="[passwordValidation]" v-model="usuario.clave">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                          </template>
                        </v-text-field>

                      </v-col>

                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                        <v-text-field label="Confirmación:" autocomplete="off" type="password" dense outlined
                          :rules="[passwordValidation]" v-model="ContraseñaConfirmacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                          </template>

                          <template v-slot:append-outer>
                            <i v-if="usuario.clave != ContraseñaConfirmacion" style="font-size: 30px; color:#F44336; "
                              class="fa fa-times"></i>
                            <i v-if="usuario.clave == ContraseñaConfirmacion" style="font-size: 30px; color:#4CAF50;"
                              class="fa fa-check"></i>
                          </template>
                        </v-text-field>
                      </v-col>


                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                        <v-combobox autocomplete="off" dense outlined label="Sucursales" :items="Rsucursa"
                          item-text="descripcion" v-model="sucursales" @focus="cargarSucursales"
                          @blur="SelctCbSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                        <v-row class="d-flex justify-center">

                          <span class="pe-1">
                            <v-checkbox class="mt-0" color="teal" label="" v-model="usuario.status" width="50">
                              <template v-slot:label>
                                <strong v-if="usuario.status === true" style="color:#000000; font-size:13px;">Activo:
                                  Si</strong>
                                <strong v-if="usuario.status === false" style="color:#000000; font-size:13px;">Activo:
                                  No</strong>
                              </template>
                            </v-checkbox>
                          </span>

                          <span class="pe-1">
                            <v-checkbox class="mt-0" color="teal" label="" v-model="usuario.dashboard" width="50">
                              <template v-slot:label>
                                <strong v-if="usuario.dashboard === true"
                                  style="color:#000000; font-size:13px;">DashBoard: Si</strong>
                                <strong v-if="usuario.dashboard === false"
                                  style="color:#000000; font-size:13px;">DashBoard: No</strong>
                              </template>
                            </v-checkbox>
                          </span>

                          <span class="pe-1" v-show="(AdminUser==true)">
                            <v-checkbox class="mt-0" color="teal" label="" v-model="usuario.sudo" width="50">
                              <template v-slot:label>
                                <strong v-if="usuario.sudo === true"
                                  style="color:#000000; font-size:13px;">administrador:
                                  Si</strong>
                                <strong v-if="usuario.sudo === false"
                                  style="color:#000000; font-size:13px;">administrador: No</strong>
                              </template>
                            </v-checkbox>
                          </span>

                          <span class="pe-1">
                            <v-checkbox class="mt-0" color="teal" label="" v-model="usuario.anularTranferenciaBanco"
                              width="50">
                              <template v-slot:label>
                                <strong v-if="usuario.anularTranferenciaBanco === true"
                                  style="color:#000000; font-size:13px;">Anular Tranferencia Banco: Si</strong>
                                <strong v-if="usuario.anularTranferenciaBanco === false"
                                  style="color:#000000; font-size:13px;">Anular Tranferencia Banco: No</strong>
                              </template>
                            </v-checkbox>
                          </span>

                        </v-row>
                      </v-col>

                      <v-col cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">

                        <!-- <v-list-item-content></v-list-item-content> -->
                        <!-- v-model="FichaVehiculoEnTurno" @blur="SelctCbFichaEnTurno" @focus="CargarRFicha"-->
                        <v-row class="pa-0 ma-0 py-0 my-0">

                          <v-col v-for="(sl, i) in ItemsRoute" :key="i" cols="12" md="4" sm="4" v-show="sl.menuList.length>0"  >

                            <v-combobox :label="sl.nombre" v-model="usuario.menuUsuarioList" @change="eveSelectComb"
                              required outlined dense multiple autocomplete="off" :items="sl.menuList"
                              item-text="nombre" hide-details>
                              <template v-slot:selection="data">
                                <!-- {{data.item.toString().length}} -->
                                <!-- <span class="pe-1"> hola: {{data.item.length}} {{JSON.stringify(data.item)}}  </span>         -->
                              </template>

                              <template slot="item" slot-scope="item">
                                <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                                  <!-- class="mt-0" <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Descripción: {{item.item.descripcion}}</b></p> -->
                                  <v-row no-gutters>
                                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                                      <v-checkbox class="pa-0 ma-0 py-0 my-0" color="teal" label=""
                                        v-model="item.item.Actv" dense hide-details>
                                        <template v-slot:label>
                                          <strong style="color:#000000; font-size:13px;">
                                            {{ item.item.nombre }}:
                                            <span v-if="item.item.Actv"> Si</span>
                                            <span v-else> No</span>
                                          </strong>
                                        </template>
                                      </v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </template>
                            </v-combobox>
                          </v-col>

                          <!-- Permisos extras adicionales -->
                          <v-col  v-for="(sl, i) in ItemsPV.filter((ee) => ee.codigo == 50)"  cols="12" md="4" sm="4">
                            <v-combobox :label="sl.operacion" v-model="usuario.usuarioPermisoPvList"
                              @change="eveSelectComb" required outlined dense multiple autocomplete="off"
                              :items="sl.funciones" item-text="titulo" hide-details>
                              <template v-slot:selection="data">
                                <!-- no select multimple -->
                              </template>

                              <template slot="item" slot-scope="item">

                                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                                  <v-row no-gutters>
                                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                                      <v-checkbox class="pa-0 ma-0 py-0 my-0" color="teal" label=""
                                        v-model="item.item.permiso" dense hide-details>
                                        <template v-slot:label>
                                          <strong v-if="item.item.permiso == true"
                                            style="color:#000000; font-size:13px;">{{
                                            item.item.titulo
                                            }}: Si </strong>
                                          <strong v-if="item.item.permiso == false"
                                            style="color:#000000; font-size:13px;">{{ item.item.titulo }}:
                                            No</strong>
                                        </template>
                                      </v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-col>

                              </template>


                            </v-combobox>

                          </v-col>
                          <!-- FIN Permisos extras adicionales -->
                        </v-row>
                      </v-col>

                      <hr>
                      <v-col v-show="(ModelPv==true)" cols="12" md="12" sm="12" style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">
                        <br>
                        <b>Opciones del Punto de ventas</b>

                        <v-row class="pa-0 ma-0 py-0 my-0">


                          <v-col v-for="(sl, i) in ItemsPV.filter((ee) => ee.codigo != 50)" :key="i" cols="12" md="4" sm="4">
                            <v-combobox :label="sl.operacion" v-model="usuario.usuarioPermisoPvList"
                              @change="eveSelectComb" required outlined dense multiple autocomplete="off"
                              :items="sl.funciones" item-text="titulo" hide-details>
                              <template v-slot:selection="data">
                                <!-- no select multimple -->
                              </template>

                              <template slot="item" slot-scope="item">

                                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                                  <v-row no-gutters>
                                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                                      <v-checkbox class="pa-0 ma-0 py-0 my-0" color="teal" label=""
                                        v-model="item.item.permiso" dense hide-details>
                                        <template v-slot:label>
                                          <strong v-if="item.item.permiso == true"
                                            style="color:#000000; font-size:13px;">{{
                                            item.item.titulo
                                            }}: Si </strong>
                                          <strong v-if="item.item.permiso == false"
                                            style="color:#000000; font-size:13px;">{{ item.item.titulo }}:
                                            No</strong>
                                        </template>
                                      </v-checkbox>
                                    </v-col>
                                  </v-row>
                                </v-col>

                              </template>


                            </v-combobox>

                          </v-col>

                        </v-row>

                      </v-col>


                    </v-row>
                  </v-form>
                </v-col>
              </v-card>
            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>

    </v-snackbar>


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    BarraDinamicaNormal
  },
  created() {
    var json = {
      titulo: "Usuario",
      descripcion: "Usuario",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi: false,
      accion: [
        "USUConfiguracionNew",
        "USUConfiguracionSave",
        "USUConfiguracionRemove",
        "USUConfiguracionAnular",
        "USUConfiguracionPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
    this.findAllModulos()
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("USUConfiguracionNew", this.new);
    // EventBus.$on("USUConfiguracionSave", this.save);
    // EventBus.$on("USUConfiguracionRemove", this.remove);
    // EventBus.$on("USUConfiguracionAnular", this.remove);
    // EventBus.$on("USUConfiguracionPrintList", this.imprimirList);
    EventBus.$on("emitConfUsuario", this.datostabla);
    //EventBus.$on("onResize", this.onResize);
    //this.ItemsRoute = JSON.parse(JSON.stringify(this.$store.getters.GetDatosItemsRouterDefour))
    this.usuario.micromarket=this.$store.getters.GetdatosInfUsu.usuario.micromarket
    this.usuario.transportnet=this.$store.getters.GetdatosInfUsu.usuario.transportnet
    this.usuario.gubernamental=this.$store.getters.GetdatosInfUsu.usuario.gubernamental
  },

  data: () => ({
    AdminUser:true,
    ModelPv:false,
    ItemsRoute: [],
    windowSize: { width: "auto", height: "auto" },
    Rsucursa: [],
    tenantId: '',
    ejecucion: true,
    CombSelect: null,

    ContraseñaConfirmacion: "",
    ValidIdUser: false,
    ValidIdUserDesc:'',
    configuracion: {
      codigo: 0,
      desc: "",
      empresa: 1,
      clave: "",
      sucursal: null,
      activo: true

    },
    usuario: {
      codigo: 0,
      nombre: '',
      usuario: '',
      clave: '',
      sudo: false,
      dashboard: false,
      micromarket: false,
      anularTranferenciaBanco: false,
      status: true,
      menuUsuarioList: null,
      usuarioPermisoPvList: null,
      schema: ''
    },
    sucursales: null,

    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    text: "",
    valid: false,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],
    detalleMenu: [],
    list: [],
    listEtiqueta: [],

    search: "",
    searchEtiqueta: "",

    acti_tb_data: false,

    aalert: {
      estado: false,
      color: null
    },

    //Opciones del Punto de ventas
    ItemsPV: [
      {
        codigo: 1,
        operacion: "Producto",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Crear Producto", permiso: false, operacion: 1, fn: 1, op: 1 },
          { codigo: 2, titulo: "Cambiar Cantidad", permiso: false, operacion: 1, fn: 2, op: 1 },
          { codigo: 3, titulo: "Cambiar Precio", permiso: false, operacion: 1, fn: 3, op: 1 },
          { codigo: 4, titulo: "Cambiar Descuento", permiso: false, operacion: 1, fn: 4, op: 1 },
          //  { codigo: 5, titulo: "Cambiar Descuento en valor", permiso: false, operacion: 1, fn: 5, op: 1 },
          { codigo: 6, titulo: "Cambiar Vendedor", permiso: false, operacion: 1, fn: 6, op: 1 },
          { codigo: 7, titulo: "Eliminar Linea", permiso: false, operacion: 1, fn: 7, op: 1 },
          { codigo: 8, titulo: "Cambiar Recargo", permiso: false, operacion: 1, fn: 8, op: 1 },
          { codigo: 9, titulo: "Permite convertir producto en oferta", permiso: false, operacion: 1, fn: 9, op: 1 },
          { codigo: 10, titulo: "permitir vender por debajo del precio actual", permiso: false, operacion: 1, fn: 10, op: 1 },
          // { codigo: 9, titulo: "Cambiar Descuento en valor", permiso: false, operacion: 1, fn: 9, op: 1 },
        ]
      },

      {
        codigo: 2,
        operacion: "Cliente",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Crear Cliente", permiso: false, operacion: 2, fn: 1, op: 2 },
        ]
      },

      {
        codigo: 3,
        operacion: "Facturas",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Consultar Facturas", permiso: false, operacion: 3, fn: 1, op: 3 },
          // { codigo: 2, titulo: "Cambiar Descuento", permiso: false, operacion: 3, fn: 2, op: 3 },
          // { codigo: 3, titulo: "Cambiar Descuento En Porcentaje", permiso: false, operacion: 3, fn: 3, op: 3 },
          { codigo: 4, titulo: "Cambiar Moneda", permiso: false, operacion: 3, fn: 4, op: 3 },
          { codigo: 5, titulo: "Cambiar Comprobante", permiso: false, operacion: 3, fn: 5, op: 3 },
          { codigo: 6, titulo: "Crear Factura a Domicilio", permiso: false, operacion: 3, fn: 6, op: 3 },
          { codigo: 7, titulo: "Cambiar Vendedor", permiso: false, operacion: 3, fn: 7, op: 3 },
          { codigo: 8, titulo: "Facturas a Credito", permiso: false, operacion: 3, fn: 8, op: 3 },
          { codigo: 9, titulo: "Cancelar Creacion de Factura", permiso: false, operacion: 3, fn: 9, op: 3 },
          { codigo: 10, titulo: "Abrir Caja Registradora F2", permiso: false, operacion: 3, fn: 10, op: 3 },
          { codigo: 11, titulo: "Anular Factura", permiso: false, operacion: 3, fn: 11, op: 3 },
          { codigo: 12, titulo: "convertir Cotizacion a Factura", permiso: false, operacion: 3, fn: 12, op: 3 },
          { codigo: 13, titulo: "convertir Conduce a Factactura", permiso: false, operacion: 3, fn: 13, op: 3 },
          { codigo: 14, titulo: "Editar Factura comercial", permiso: false, operacion: 3, fn: 14, op: 3 },
          { codigo: 15, titulo: "Cambiar Caja", permiso: false, operacion: 3, fn: 15, op: 3 },
          { codigo: 16, titulo: "Permitir facturar a cliente que excede limite de crédito", permiso: false, operacion: 3, fn: 16, op: 3 },
        ]
      },

      {
        codigo: 4,
        operacion: "Devoluciones",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Devoluciones", permiso: false, operacion: 4, fn: 1, op: 4 },
        ]
      },

      {
        codigo: 5,
        operacion: "Recibos de Ingreso",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Recibos de Ingreso", permiso: false, operacion: 5, fn: 1, op: 5 },
        ]
      },


      {
        codigo: 6,
        operacion: "Otros Ingresos",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Otros Ingresos", permiso: false, operacion: 6, fn: 1, op: 6 },
        ]
      },

      {
        codigo: 7,
        operacion: "Reimprimir",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Reimprimir Factura", permiso: false, operacion: 7, fn: 1, op: 7 },
          { codigo: 2, titulo: "Reimprimir Devolucion", permiso: false, operacion: 7, fn: 2, op: 7 },
          { codigo: 3, titulo: "Reimprimir Recibo Ingreso", permiso: false, operacion: 7, fn: 3, op: 7 },
          { codigo: 4, titulo: "Reimprimir Recibo Otros Ingresos", permiso: false, operacion: 7, fn: 4, op: 7 },
          { codigo: 5, titulo: "Reimprimir Vale de Caja", permiso: false, operacion: 7, fn: 5, op: 7 },
          { codigo: 6, titulo: "Reimprimir Cotización", permiso: false, operacion: 7, fn: 6, op: 7 },
          { codigo: 7, titulo: "Reimprimir Conduce", permiso: false, operacion: 7, fn: 7, op: 7 },

        ]
      },


      {
        codigo: 8,
        operacion: "Facturas Temporales",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Guardar Facturas", permiso: false, operacion: 8, fn: 1, op: 8 },
          { codigo: 2, titulo: "Restaurar Facturas", permiso: false, operacion: 8, fn: 2, op: 8 },
          { codigo: 3, titulo: "Eliminar Facturas", permiso: false, operacion: 8, fn: 3, op: 8 },
          { codigo: 4, titulo: "Imprimir Facturas", permiso: false, operacion: 8, fn: 4, op: 8 },
        ]
      },

      {
        codigo: 9,
        operacion: "Seguros",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Facturas a Seguros", permiso: false, operacion: 9, fn: 1, op: 9 },
        ]
      },


      {
        codigo: 10,
        operacion: "Vales de Caja",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Vales de Caja", permiso: false, operacion: 10, fn: 1, op: 10 },
        ]
      },


      {
        codigo: 11,
        operacion: "Cierres de Caja",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Cierres de Caja", permiso: false, operacion: 11, fn: 1, op: 11 },
        ]
      },


      {
        codigo: 12,
        operacion: "Mensajeros",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Asignar Mensajero", permiso: false, operacion: 12, fn: 1, op: 12 },
          { codigo: 2, titulo: "Recibir Mensajero", permiso: false, operacion: 12, fn: 2, op: 12 },
          { codigo: 3, titulo: "Marcar Como No Recibido", permiso: false, operacion: 12, fn: 3, op: 12 },
          { codigo: 4, titulo: "Facturas en rutas", permiso: false, operacion: 12, fn: 4, op: 12 },
          { codigo: 5, titulo: "Cambiar", permiso: false, operacion: 12, fn: 5, op: 12 },
        ]
      },

      {
        codigo: 13,
        operacion: "Impresoras Fiscales",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Manejar Impresoras Fiscales", permiso: false, operacion: 13, fn: 1, op: 13 },
        ]
      },

      {
        codigo: 14,
        operacion: "Descuentos Globales",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Cambiar Descuento", permiso: false, operacion: 14, fn: 1, op: 14 },
          // { codigo: 2, titulo: "Descuentos Globales Valor", permiso: false, operacion: 14, fn: 2, op: 14 },
          { codigo: 3, titulo: "Permitir Descuento por Encima del Maximo", permiso: false, operacion: 14, fn: 3, op: 14 },
        ]
      },


      {
        codigo: 15,
        operacion: "Recargos Globales",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Recargo Globales", permiso: false, operacion: 15, fn: 1, op: 15 },
          //{ codigo: 2, titulo: "Recargos Globales Valor", permiso: false, operacion: 15, fn: 2, op: 15 },
        ]
      },

      {
        codigo: 16,
        operacion: "Recetas",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Ver Recetas", permiso: false, operacion: 16, fn: 1, op: 16 },
          { codigo: 2, titulo: "Listado de receta", permiso: false, operacion: 16, fn: 2, op: 16 },

        ]
      },

      {
        codigo: 17,
        operacion: "Divisas",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Divisa", permiso: false, operacion: 17, fn: 1, op: 17 },
          { codigo: 2, titulo: "Reimprimir Divisa", permiso: false, operacion: 17, fn: 2, op: 17 },
          { codigo: 3, titulo: "Anular Divisa", permiso: false, operacion: 17, fn: 3, op: 17 },

        ]
      },

      {
        codigo: 18,
        operacion: "Retenciones",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Hacer Retenciones Gubernamentales", permiso: false, operacion: 18, fn: 1, op: 18 },
        
        ]
      },

      {
        codigo: 19,
        operacion: "Farmaco Vigilancia",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Farm.Vigilancia", permiso: false, operacion: 19, fn: 1, op: 19 },
        
        ]
      },

      {
        codigo: 20,
        operacion: "Productos pendientes entrega",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Productos pendientes entrega", permiso: false, operacion: 20, fn: 1, op: 20 },
        
        ]
      },


      {
        codigo: 21,
        operacion: "Cliente Preferencial",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "Registrar Clientes Preferencial", permiso: false, operacion: 21, fn: 1, op: 21 },
          { codigo: 2, titulo: "Consulta de Consumo Clientes Preferencial", permiso: false, operacion: 21, fn: 2, op: 21 },
        
        ]
      },


      {
        codigo: 50,
        operacion: "Permisos Extras adicinales",
        funciones: [
          //La operacion:identifica el codigo del modulo completo
          { codigo: 1, titulo: "(INVENTARIO) Eliminar despacho pedido", permiso: false, operacion: 50, fn: 1, op: 50 },
          { codigo: 2, titulo: "(INVENTARIO) Anular pedido almacen", permiso: false, operacion: 50, fn: 2, op: 50 },
          { codigo: 3, titulo: "(CXC) Anular recibo de  ingreso", permiso: false, operacion: 50, fn: 3, op: 50 },
          { codigo: 4, titulo: "(Boton) Agregar producto a tabla", permiso: false, operacion: 50, fn: 4, op: 50 },
          { codigo: 5, titulo: "(CXP) Modificar Registro Facturas de Proveedores", permiso: false, operacion: 50, fn: 5, op: 50 },
        
        ]
      },



    ]


  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    onResize(e) {
      this.windowSize = e
    },

    abrirTabla() {


      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if(this.BarraTablaDinamicaAprir==true){
        EventBus.$emit("actualizaBarraBusqueda2");
      }
  
    },

    datostabla(e) {
      console.log(e)
      this.ValidIdUser=true
      this.usuario = { ...this.usuario, ...e };
      this.usuario.clave = "";
      this.sucursales = e.sucursal
      this.acti_tb_data = false
      this.setSelectedItems()
      this.setSelectedItemsPv()
    },

    save() {
      if (this.$refs.form.validate() && this.ejecucion) {

if(this.usuario.codigo==0 && this.ValidIdUser==false){
  this.alerta("Este Id de usuario ya esta registrado", "error");
  return
}



        this.ejecucion = false;
        this.usuario.schema = this.$store.getters.GetheadersAxios["X-TENANT-ID"];
        this.usuario.micromarket=this.$store.getters.GetdatosInfUsu.usuario.micromarket
        this.usuario.transportnet=this.$store.getters.GetdatosInfUsu.usuario.transportnet
        this.usuario.gubernamental=this.$store.getters.GetdatosInfUsu.usuario.gubernamental

        let data = {
          tenantUsuario: this.usuario,
          sucursales: this.sucursales,
          menuUsuarioList: this.usuario.menuUsuarioList ? this.usuario.menuUsuarioList.filter(menu => menu.Actv) : [],
          usuarioPermisoPvList: this.usuario.usuarioPermisoPvList ? this.usuario.usuarioPermisoPvList.filter(i => i.permiso) : []
        }


        var ItmMenu = []

        this.ItemsRoute.forEach(element => {
          var ObjProRow = element.menuList.filter((ee) => ee.Actv == true);
          if (ObjProRow.length > 0) {
            ObjProRow.forEach(elent => {
              elent.menu = element.codigo
            });
          }
          ItmMenu = ObjProRow.concat(ItmMenu)
          // _this.RProductoCompraTemp = ObjProRow.concat(_this.RProductoCompraOfercta)
        });

        var ItmPermiso = []

        this.ItemsPV.forEach(element => {
          var ObjProRow = element.funciones.filter((ee) => ee.permiso == true);
          ItmPermiso = ObjProRow.concat(ItmPermiso)
          // _this.RProductoCompraTemp = ObjProRow.concat(_this.RProductoCompraOfercta)
        });


        data.menuUsuarioList = ItmMenu
        data.usuarioPermisoPvList = ItmPermiso

        this.$axios
          .post(this.$hostname + this.$hName + "/usuario", data, { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            this.alerta("Dato guardado correctamente", "bien")
            this.newwnuevo()
          })
          .catch(error => {
            if (error.response) {
              this.alerta(error.response.request.responseText, 'error')
            } else {
              this.alerta(error, 'error')
            }
          })
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    remove() {
    },
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
      this.ValidIdUser=false
      this.usuario = {
        codigo: 0,
        nombre: '',
        usuario: '',
        clave: '',
        sudo: false,
        dashboard: false,
        micromarket: this.$store.getters.GetdatosInfUsu.usuario.micromarket,
        transportnet: this.$store.getters.GetdatosInfUsu.usuario.transportnet,
        gubernamental:this.$store.getters.GetdatosInfUsu.usuario.gubernamental,

        anularTranferenciaBanco: false,
        status: true,
        menuUsuarioList: [],
        usuarioPermisoPvList: [],
      };
      this.ContraseñaConfirmacion = "";
      this.sucursales = null;
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      //this.findAllUsuarios();
      this.findAllModulos();
      this.unCheckPermisosPV();

    },

    unCheckPermisosPV() {
      this.ItemsPV
        .forEach(item => item.funciones.forEach(funcion => funcion.permiso = false))
    },

    eveSelectComb(e) {
      this.CombSelect = null
    },

    SelctCbSucursal() {

      if (this.sucursales == null) {
        this.sucursales = ''
      }

      if (this.sucursales.codigo == undefined) {
        this.alerta("Seleccione una Salida", "error");
        this.sucursales = ''
        return
      }
    },

    cargarSucursales() {
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: headers })
        .then(res => {
          this.Rsucursa = res.data
        })
        .catch(error => console.log(error));
    },

    ValidIdUserBlur(){

      if(this.usuario.codigo==0){
      console.log(this.usuario.usuario.trim())
      if(this.usuario.usuario.trim().length==0){
        return
      }
      var headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + "/usuario/ValidarIdUsuario/"+this.usuario.usuario, { headers: headers })
        .then(res => {
          if(res.data==false){
            this.ValidIdUser=false
            this.alerta("Este Id de usuario ya esta registrado", "error");
            this.ValidIdUserDesc="Este Id de usuario ya esta registrado"
          }else{
            this.ValidIdUser=true
          }

        })
        .catch(error => console.log(error));
      }

    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        //this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario: JSON.parse(JSON.stringify(this.$user))
        })
        .then(res => {
          this.list = res.data;
        });
    },

    findAllModulos() {

  var UserData=JSON.parse(JSON.stringify(this.$store.getters.GetdatosInfUsu.usuario))

  this.AdminUser=UserData.sudo
  this.$axios.get(this.$hostname + this.$hName + '/modulo', { headers: this.$store.getters.GetheadersAxios })
        .then(res => {

if(UserData.sudo==true){
this.ItemsRoute = res.data
this.ModelPv=true
}else{
  this.AsignarRolesUsuarios(res.data)
}     
        })
        .catch(console.log)

    
    },

AsignarRolesUsuarios(e){
  var Data=JSON.parse(JSON.stringify(this.$store.getters.GetDataRouter))
  //Activa ModelPv
var pvModel=Data.filter(ee => ee.codigo == 3)
if(pvModel.length>0){
  if(pvModel[0].items.filter(ee => ee.codigo == 20).length>0){
    this.ModelPv=true
  }
 }
  
  var CreateModulo=[]
  Data.forEach(elemt => {
    var ObjMo = CreateModulo.filter(ee => ee.codigo == elemt.codigo)
    var ObjDat =e.filter(ee => ee.codigo == elemt.codigo)
    if(ObjMo.length==0){
      var itemsObj= JSON.parse(JSON.stringify(ObjDat[0]))   
       itemsObj.menuList=[]
       CreateModulo.push(itemsObj)
    }

    /*var ObjDat1 =ObjDat[0].menuList.filter(ee => ee.codigo == Dat.codigo)*/
    

   elemt.items.forEach(Dat=>{

    if(Dat.codigo!=1000){
      var ObjDat1 =ObjDat[0].menuList.filter(ee => ee.codigo == Dat.codigo)
       CreateModulo.filter(ee => ee.codigo == elemt.codigo)[0].menuList.push(ObjDat1[0])
    }else{
      var ObjDat2 =ObjDat[0].menuList.filter(ee => ee.nombre.includes('Reportes'))
      CreateModulo.filter(ee => ee.codigo == elemt.codigo)[0].menuList.push(ObjDat2[0])
    }
      /*if(!this.$route.path.includes('/Reportes/')){}
      //Reportes*/
 
    });
    

  });


  

  var _this = this;
            setTimeout(function () {
              _this.ItemsRoute=JSON.parse(JSON.stringify(CreateModulo))  
            }, 500);
 
},


    findAllUsuarios() {
      this.$axios.get(this.$hostname + this.$hName + '/usuario', { headers: this.$store.getters.GetheadersAxios })
        .then(res => this.list = res.data)
        .catch(console.log)
    },
    findPermisoPv(item) {
      return !!this.usuario.usuarioPermisoPvList.find(itemPv => item.op === itemPv.op && item.fn === itemPv.fn);
    },

    findMenu(item) {
      return !!this.usuario.menuUsuarioList.find(menu => item.codigo === menu.menu);
    },
    setSelectedItems() {
      this.ItemsRoute = this.ItemsRoute.map(menu => {
        return {
          ...menu,
          menuList: menu.menuList.map(menuItem => {
            return { ...menuItem, Actv: this.findMenu(menuItem) }
          })
        }
      })
    },
    setSelectedItemsPv() {
      this.ItemsPV = this.ItemsPV.map(menu => {
        return {
          ...menu,
          funciones: menu.funciones.map(menuItem => {
            return { ...menuItem, permiso: this.findPermisoPv(menuItem) }
          })
        }
      })
    },

    passwordValidation() {
      if (this.usuario.codigo === 0) {
        if (!!this.usuario.clave && !!this.ContraseñaConfirmacion) {
          return true
        }
        return "Este campo es obligatorio"
      }
      return true
    }
  },
  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }

  }
};
</script>

<style>

</style>
