<template>
  <div>
    <table style="position: absolute" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Reporte de Compra de Bienes y Servicio'"
          :descripcion="'Reporte de Compra de Bienes y Servicio'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px" class="fa fa-eye"> </i>
                      ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesBienesServicios'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto"
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <v-container>
              <v-card color="grey lighten-3">
                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000">
                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte" />
                  </v-avatar>

                  <span class="font-weight-light" style="font-size: 20px">
                    Reporte 606
                  </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr />

                <v-container style="border: 1px solid #000000">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  desde  -->
                        <v-text-field dense outlined type="date" label="Desde:" :rules="[$rules.required]"
                          v-model="BienesServicios.desde" @blur="BlurDesde">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- fn columna 1 -->

                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  Hasta  -->
                        <v-text-field dense outlined type="date" :rules="[$rules.required]" label="Hasta:"
                          v-model="BienesServicios.hasta" @blur="BlurHasta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- fn columna 2 -->

                      <!-- columna 3 -->
                      <v-col cols="12" md="12" sm="12">
                        <v-radio-group style="margin-top: -20px" v-model="BienesServicios.RadioOpc" column>
                          <v-radio label="Reporte Por Pantalla" color="teal lighten-2" :value="1"></v-radio>
                          <v-radio label="Generar Archivo para la DGII" color="teal lighten-2" :value="2"></v-radio>
                          <v-radio label="Generar Archivo por Categoria de Gasto" color="teal lighten-2" :value="3">
                          </v-radio>
                          <v-radio label="Generar Archivo por Categoria de Gasto Resumido" color="teal lighten-2"
                            :value="4"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <!-- fn columna 3 -->
                    </v-row>
                  </v-form>
                </v-container>

                <hr />

                <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="BienesServicios" />
              </v-card>
            </v-container>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">
          <div class="overflow-y-auto" v-bind:style="{
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          }">
            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>
        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color: #dd4b39" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%" :vertical="true" :timeout="120000"
      top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros,
  },
  created() {
    var json = {
      titulo: "Reporte de Compra de Bienes y Servicios",
      descripcion: "",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi: false,
      accion: [
        "ReportesBienesServiciosNew",
        "ReportesBienesServiciosSave",
        "ReportesBienesServiciosRemove",
        "ReportesBienesServiciosAnular",
        "ReportesBienesServiciosPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesBienesServiciosNew", this.new);
    // EventBus.$on("ReportesBienesServiciosSave", this.save);
    // EventBus.$on("ReportesBienesServiciosRemove", this.remove);
    // EventBus.$on("ReportesBienesServiciosAnular", this.remove);
    // EventBus.$on("ReportesBienesServiciosPrintList", this.imprimirList);
    EventBus.$on("emitReportesBienesServicios", this.datostabla);

    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({
    Rpropietario: [],
    RCaja: [],
    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    BienesServicios: {
      nombre: "REPORTE DE COMPRA DE BIENES Y SERVICIO",
      UrlImprimir: "/reportesmaf/ImprimirListBienesServicio",
      UrlExel: "/reportesmaf/ExcelListBienesServicio",
      UrlCorreo: "/reportesmaf/CorreoListBienesServicio",

      desde: fechaNueva(),
      hasta: fechaNueva(),
      RadioOpc: 1,

      //fechaCreacion: new,
      //  fechaModificacion: "",
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },

    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:",
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" },
    ],

    list: [],
    search: "",
    acti_tb_data: false,

    aalert: {
      estado: false,
      color: null,
    },
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
    onResize(e) {
      this.windowSize = e;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },

    datostabla(e) {
      this.BienesServicios = e;
      this.acti_tb_data = false;
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() { },
    DDataReporteBasico() { },
    newwnuevo() {
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    BlurDesde() {
      if (this.BienesServicios.desde.toString().length == 0) {
        this.BienesServicios.desde = this.fechaNueva()
      }
    },

    BlurHasta() {
      if (this.BienesServicios.hasta.toString().length == 0) {
        this.BienesServicios.hasta = this.fechaNueva()
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { },
  },
};
</script>

<style>
</style>
