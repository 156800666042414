<template v-slot:item[value.value]="props">



  <div>
{{ver(props)}}
  </div>
</template>

<script>
export default {
props:{
    value:{
        required:false,
        type:Boolean,
    }
},

methods:{

    ver(){
        console.log(this.value)


    }
}

}
</script>



<style>

</style>