<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Código de seguridad'"
  :descripcion="'Para generar los Código de seguridad'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitTranspFinesCortesia'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

<!-- fila 1 ------------------------------>
<v-col cols="12" md="12">
<v-row>
<!--  Codigo seguridad -->
  <v-col cols="12" md="12" style="  background:#212F3C; border: 2px solid #000000;   border-radius: 2px;">
    <center>
  <h1>

         <strong style="color:#FFFFFF;">{{form.noPin}}</strong>
  </h1>

<VueAutonumeric 
                      label="Porciento Descuento:"
                      autocomplete="off"
                      color="grey darken-4" 
                      background-color="white"
                      :rules="[$rules.required, $rules.porciento]"
                      filled
                      dense
                      v-model="form.porcientoDescuento"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-percent"></i>
                      </template>
                    </VueAutonumeric>


  <hr  style="border-top: 1px dashed #FFFFFF;"> 

<v-list-item-subtitle style="color:#BDBDBD;">Código seguridad</v-list-item-subtitle>
  </center>       
</v-col>
<!-- fn Codigo seguridad-->

</v-row>
</v-col>
<!-- fila 1 ------------------------------>

<!-- fila 2 ------------------------------>
<v-col  cols="12" md="12">
<v-row>

<!--  codigo_cedula-->
  <v-col cols="12" md="12" style=" border: 2px solid #424242; background:#BDBDBD;  border-radius: 2px;">

<v-card-actions >   
        <v-btn
          dark
          color="#212F3C"
          @click.prevent="copiar"
        >
        <i style="font-size: 18px;" class="fa fa-clone"> </i> 
          Copiar código
        </v-btn> 
        
        <v-spacer></v-spacer>
        
         <v-btn
          dark
          color="#212F3C"
          @click.prevent="GetNewping"
        >
        <i style="font-size: 18px;" class="fa fa-exchange">  </i> 
         Generar código
        </v-btn> 

 <!-- <v-btn
          text
          color="black accent-4"
          @click.prevent="nuevoget"
        >
        <i style="font-size: 18px;" class="fa fa-exchange">  </i> 
         Generar código
        </v-btn> -->

       
   </v-card-actions>    
   
</v-col>

<!-- fn codigo_cedula-->


</v-row>
</v-col>
<!-- fn fila2  ------------------------------>





                <!-- <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="form.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="* Nombre:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <v-switch
                    label="Activo"
                    v-model="form.activo"
                  ></v-switch>
                </v-col> -->


              </v-row>
      </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase
    
  },
  created() {
    var json = {
      titulo: "Código de seguridad",
      descripcion: "Para generar los Código de seguridad",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [
        "TranspDescuentoNew",
        "TranspDescuentoSave",
        "TranspDescuentoRemove",
        "TranspDescuentoAnular",
        "TranspDescuentoPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspDescuentoNew", this.new);
    // EventBus.$on("TranspDescuentoSave", this.save);
    // EventBus.$on("TranspDescuentoRemove", this.remove);
    // EventBus.$on("TranspDescuentoAnular", this.remove);
    // EventBus.$on("TranspDescuentoPrintList", this.imprimirList);
    EventBus.$on("emitTranspDescuento", this.datostabla);
    this.cargarTodo();
    this.GetCaja();

    EventBus.$on("onResize", this.onResize);
  },

  data:()=> ({
   
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
form: {
id: 0,
noPin: "-------",
porcientoDescuento: 0,
usuarioGenera: "",
  },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
  
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {






onResize(e){

//console.log("imprii ")

//console.log(e)
this.windowSize=e


},


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.form = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.form)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },
    remove() {

if(this.form.codigo!=0 && this.form.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.form)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.form.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {},
    imprimirList() {},
    newwnuevo() {
      (this.form = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

copiar(){

 var aux = document.createElement("input");
  aux.setAttribute("value", this.form.noPin);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand("copy");
  document.body.removeChild(aux);

},

async GetCaja(){

await this.$axios.get(this.$hostname+'micromarket/services/pv/1')
.then((response)=> {this.form.usuarioGenera=response.data.cajero})
.catch((error)=>{console.log(error)})

},


GetNewping(){

if (this.$refs.form.validate()) {

//this.form.codigoSe=Math.floor(Math.random()*9000) + 1000
  var ping =Math.floor(Math.random()*9000) + 1000
//   alert(ping)
// TransServices alert("Pinn:"+ping+"==>"+res.data) 
   var headers=this.$store.getters.GetheadersAxios
    this.$axios.get(this.$hostname + this.$hName + "/TransServices/verificarping/"+ping, {headers:headers})
    .then(res => { 
      
      if(res.data==true){ this.GetNewping()}
      if(res.data==false){this.GuardarPing(ping)}
      
  
    });

}else{ this.alerta("Digite un porciento ", "error")}


},


GuardarPing(e){


var jsn={

        id: 0,
        noPin: e,
        usado: false,
        idcaja: null,
        porcientoDescuento: this.form.porcientoDescuento,
        nombrePasajero: null,
        parentezco: null,
        chofer: null,
        usuarioGenera: "Jc",
        usuarioUtilizo: null,
        borrado: false
}


this.$axios
        .post(this.$hostname + this.$hName + "/TransServices/save/",jsn,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{
          this.form.id= res.data.id, this.form.noPin=res.data.noPin,
          console.log('Ressssspuuuu'),console.log(res.data),this.alerta(" ping Generado ! ", "bien")
        })
        .catch(error => this.alerta("Ping no  Generado !", "error"));
},





async nuevoget() {


if (this.$refs.form.validate()) {


 await this.$axios.post(this.$hostname+'micromarket/services/generar/GenCodigoCortesia', {})
 .then((response) =>{ this.form.noPin=response.data+"" })
 .catch((error)=>{console.log(error)})


var jsn={
   datos:JSON.parse(JSON.stringify(this.form)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

await this.$axios.post(this.$hostname+'micromarket/services/generar/SavecodigoGenerado', JSON.parse(JSON.stringify(jsn)))
 .then((res) =>{ this.alerta('Código generado','bien') })
 .catch((error)=>{this.alerta(error,'error')})
}
else{
this.alerta("el campos 'Porciento Descuento' es requeridos", "error");
}


//alert(Math.round(Math.random()*9))
//  axios.post(this.$hostname+'micromarket/services/generar/generarcodigocortesia', {})
//  .then(res => (this.form.codigoSe=res.data))



//this.salvar()

 // this.form.codigoSe=Math.floor(Math.random()*9000) + 1000
},




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        //this.newwnuevo();
        this.ejecucion = false;
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
