<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Mensajeros -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Mensajeros'" :descripcion="'Creacion de Mensajeros que transportan'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
      <!-- fn ContenidoBase-Mensajeros  -->

      <tr>
        <!-- BarraPrimaria-Mensajeros --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">

                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <!-- <v-btn-toggle  ></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i
                      style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>


                </center>


                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/Mesajeros/findall/'"
                  :nombre="'Mensajeros'" :emitnombre="'emitMensajeros'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Nombre', value: 'nombre' },
                    { text: 'Cedula', value: 'cedula' },
                    { text: 'Telefono', value: 'telefono' },
                    { text: 'Sucursal', value: 'sucursal.descripcion' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Nombre', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
  { Text: 'Cedula', NomValue: 'cedula', Ctsl: 1, Type: 0, State: true },
  { Text: 'Telefono', NomValue: 'telefono', Ctsl: 1, Type: 0, State: true },
  { Text: 'Sucursal', NomValue: 'sucursal', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.1, State: true },
]" />
                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/Mesajeros/findall'"
              :nombre="'Mensajeros'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitMensajeros'"
              :SeachNombre="'Búsqueda por nombre de Mensajero'"
              :slotlist="slotlist"

            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>



          </div>
        </td>
        <!-- fn BarraPrimaria-Mensajeros  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">

             <!-- Formulario-Mensajeros  -->
              <v-form ref="form" v-model="valid" lazy-validation>

                <v-card color="grey lighten-3">
                  <div class="px-2">

                    <input type="number" v-model="mensajero.codigo" style="display:none;" />


                    <v-row>
                      <!-- ----------------------------------------- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                          v-model="mensajero.nombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>

                        <!------------------------------- Sucursal:-->
                        <v-combobox autocomplete="off" dense outlined label="Sucursal:" :items="RSucursal"
                          item-text="descripcion" :rules="[$rules.required]" v-model="mensajero.sucursal"
                          @blur="SelctCbSucursal" @focus="CargarRSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                        <!------------------------------- meta de envio:-->
                        <v-text-field label="Meta de Envios:" dense outlined v-model="mensajero.metadeenvios"
                          @keyup="NumeralMen">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-flag-checkered"></i>
                          </template>
                        </v-text-field>


                      </v-col>
                      <!-- ----------------------------------------- fn columna 1 -->
                      <!-- ----------------------------------------- columna 2 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- ----------------------------------------- cedula  -->
                        <v-text-field v-mask="'###-#######-#'" label="* Cédula:" autocomplete="off" dense outlined
                          maxlength="13" :rules="[$rules.required, $rules.RulesCel]" v-model="mensajero.cedula">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                          </template>

                        </v-text-field>

                        <!-- ----------------------------------------- telefono  -->
                        <v-text-field label="* Teléfono:" dense outlined autocomplete="off" maxlength="13"
                          v-mask="'(###)###-####'" :rules="[$rules.required, $rules.RulesTel]"
                          v-model="mensajero.telefono">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>

                        <!-- Activo:-->
                        <v-switch v-model="mensajero.activo" label="Activo" color="success" hide-details></v-switch>


                      </v-col>


                      <v-col cols="12" md="6" sm="6">

                      </v-col>


                    </v-row>

                  </div>
                </v-card>



                <!-- fn fila 3 -------------------------->
              </v-form>
              <!-- fn Formulario-Mensajeros  -->

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">





            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>

        <v-toolbar dense flat color="#BDBDBD">

          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip> -->
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>


          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Nombre', value: 'nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cedula', value: 'cedula', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Telefono', value: 'telefono', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Sucursal', value: 'sucursal.descripcion', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla" :items-per-page="15">


            </v-data-table>
          </v-card>


        </v-card-text>

      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarMensajero'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>



</template>

<script>
import numeral from 'numeral'
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus";
import { mask } from 'vue-the-mask'
import { isUndefined } from 'util';
import ContenidoBase from "../../components/ContenidoBase";
export default {

  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase

  },



  directives: {
    mask,
  },

  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitMensajeros", this.datostabla);
    // EventBus.$on("MensajerosNew", this.new);
    // EventBus.$on("MensajerosSave", this.save);
    // EventBus.$on("MensajerosRemove", this.remove);
    // EventBus.$on("MensajerosAnular", this.anular);
    // EventBus.$on("MensajerosPrintList", this.imprimirList);
    this.filtro()
    //this.cargar()


  },

  created() {
    var json = {
      titulo: "Mensajeros",
      descripcion: "Creacion de Mensajeros que transportan",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: false,

      accion: [
        "MensajerosNew",
        "MensajerosSave",
        "MensajerosRemove",
        "MensajerosAnular",
        "MensajerosPrintList"
      ]

    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {

      windowSize: { width: "auto", height: "auto" },
      RSucursal: [],

      valid: true,

      mensajero: {
        codigo: 0,
        nombre: null,
        cedula: '',
        sucursal: '',
        telefono: '',
        metadeenvios: 0,
        activo: true
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: true,
        anular: true,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },


      headers: [{ text: "Mensajero", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        { dt0: "cedula", dt1: null, dt2: null, dt3: 1, dt4: "Cedula:" },
        { dt0: "telefono", dt1: null, dt2: null, dt3: 1, dt4: "Teléfono:" },
        { dt0: "sucursal", dt1: "descripcion", dt2: null, dt3: 1, dt4: "Sucursal:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],






      list: [],
      search: "",
      acti_tb_data: false,

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false

    };
  },


  methods: {


    NumeralMen(e) {
      if (e.key) {

        var mynumeral = require('numeral')
        this.mensajero.metadeenvios = mynumeral(this.mensajero.metadeenvios).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.mensajero.metadeenvios == null) {

          this.mensajero.metadeenvios = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }
    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {
      if (this.mensajero.sucursal == null) {

        this.mensajero.sucursal = ''
      }

      if (this.mensajero.sucursal.codigo == undefined) {

        this.alerta("Seleccione una sucursal", "error");
        this.mensajero.sucursal = ''
        return

      }

    },



    // Abriendo una tabla y muestra todo los registros.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },




    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RSucursal.
    CargarRSucursal() {

      this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.RSucursal = JSON.parse(JSON.stringify(res.data));
      });

    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    // solicitud a una api para guardando los datos en la base de datos.
    save() {

      //console.log(""+this.mensajero.sucursal.codigo)

      //console.log(this.$refs.form)

      if (this.$refs.form.validate()) {


        if (this.mensajero.sucursal.codigo == undefined) {


          this.alerta("Seleccione una sucursal", "error");

          this.mensajero.sucursal = ''
          return

        }


        if (this.ejecucion == true) {

          this.ejecucion = false

          var mensajero = JSON.parse(JSON.stringify(this.mensajero))
          this.$axios
            .post(this.$hostname + this.$hName + "/Mesajeros/save/", mensajero, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { console.log('Ressssspuuuu'), console.log(res.data), this.alerta(res.data.mensage, res.data.estado) })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));

        }
      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }

    },



   // Crear un objeto de notificación y luego llamar al método Exec_notif().
    NotifMessage() {

      if (this.mensajero.codigo != 0 && this.mensajero.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarMensajero"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un Mensajero para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },



   // Envío de una solicitud a un api para borrar el mensajero
    eliminar() {
      if (this.mensajero.codigo != 0 && this.mensajero.codigo != null) {

        if (this.$refs.form.validate()) {


          var mensajero = JSON.parse(JSON.stringify(this.mensajero))
          this.$axios
            .post(this.$hostname + this.$hName + "/Mesajeros/borrado/", mensajero, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage, res.data.estado) })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));

        }
      }


    },

    anular() {
      console.log("anular");
    },
    // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/imprimir/", { headers: headers }).then(res => {
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/

          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'mesajeros');

      });





      // console.log('Entrando a imprimir')
      // window.open(this.$hostname + this.$hName + "/cajas/imprimirCaja/")

      //  await this.$axios.get(this.url,{headers:headers}).then(res => {
      //         this.list =  JSON.parse(JSON.stringify(res.data)) ;
      //       });


    },

    // Abriendo una ventana modal.
    newnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.mensajero = {
        codigo: 0,
        nombre: null,
        cedula: '',
        sucursal: "",
        telefono: '',
        metadeenvios: 0,
        activo: true
      },

        this.ejecucion = true
      EventBus.$emit("actualizaBarraBusqueda2")

    },

    // Tomando los datos de la tabla y poniéndolos en un formulario.
    datostabla(e) {

      var mensajero = JSON.parse(JSON.stringify(e))

      console.log('---- Informacion ---')

      mensajero.nombre = JSON.parse(JSON.stringify(e.nombre.trim()))
      mensajero.cedula = JSON.parse(JSON.stringify(e.cedula.trim()))
      mensajero.telefono = JSON.parse(JSON.stringify(e.telefono.trim()))
      this.mensajero = mensajero

      // this.mensajero.codigo=e.codigo,
      // this.mensajero.nombre= e.nombre,
      // this.mensajero.cedula= e.cedula,
      // this.mensajero.sucursal= e.sucursal.codigo,
      // this.mensajero.telefono= e.telefono,
      // this.mensajero.metadeenvios= e.metadeenvios,
      // this.mensajero.activo=e.activo

      //this.mensajero = e;

      this.acti_tb_data = false;


      if (this.windowSize.width <= 600) {

        this.BarraTablaDinamicaAprir = false


      }

      // console.log(e);
    },

   // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data
      this.filtro()

    },

    // Hacer una solicitud GET al servidor y devolver los datos a la variable de lista.
    async filtro() {
      var headers = this.$store.getters.GetheadersAxios

      await this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/findall/", { headers: headers })
        .then(res => { this.list = res.data; });
    }

  },

  computed: {

    ActDescBtn() {


      if (this.mensajero.codigo > 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        var objjct = {
          save: true,
          eliminar: true,
          anular: false,
          nuevo: true,
          imprimir: false,
          vercuenta: false,
        };

      }


      if (this.mensajero.codigo <= 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }



        var objjct = {
          save: true,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: false,
          vercuenta: false,
        };


      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }





  }



};
</script>

<style>
</style>
