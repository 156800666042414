var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"indigo","dense":""}},[_c('i',{staticStyle:{"font-size":"20px"}}),_c('v-toolbar-title',{staticClass:"font-weight-light",staticStyle:{"color":"white"}},[_vm._v(" PRODUCTOS ")]),_c('i',{staticStyle:{"font-size":"20px"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-4","fab":"","small":""},on:{"click":function($event){return _vm.cerrarModalAddProd()}}},[_c('i',{staticClass:"fa fa-close",staticStyle:{"font-size":"20px","color":"white"}})])],1),_c('v-card-text',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"autocomplete":"off","label":"Buscar","single-line":"","hide-details":""},on:{"keyup":_vm.CbFilProducto,"focus":_vm.CargarRproducto},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('i',{staticClass:"fa fa-search",staticStyle:{"font-size":"30px"}})]},proxy:true}],null,true),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},on))]}}])},[_c('span',[_vm._v("Busqueda")])]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":"","items-per-page":-1,"headers":[
        { text: 'CODIGO', value: 'productos.codigo', sortable: false, class: ['no-gutters'] },
        { text: 'PRODUCTO', value: 'productos.descripcion', sortable: false, class: ['no-gutters'] },
        { text: 'COSTO', value: 'costo', sortable: false, class: ['no-gutters'] },
        { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters'] },
        { text: 'EXISTENCIA', value: 'existencias', sortable: false, align: 'center', class: ['no-gutters'] } ],"items":_vm.RallProducto,"item-key":"codigo"},on:{"click:row":_vm.addClikProd},scopedSlots:_vm._u([{key:"item.productos.descripcion",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(props.item.productos.descripcion))])]}},{key:"item.costo",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.currencyFormatter(props.item.costo)))])]}},{key:"item.precio",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.currencyFormatter(props.item.precio)))])]}}])})],1)],1),_c('v-snackbar',{staticStyle:{"margin-top":"20%"},attrs:{"color":_vm.aalert.color,"vertical":true,"timeout":120000,"top":"top"},model:{value:(_vm.aalert.estado),callback:function ($$v) {_vm.$set(_vm.aalert, "estado", $$v)},expression:"aalert.estado"}},[_vm._v(" "+_vm._s(_vm.aalert.nombre)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.aalert.estado = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }