<template>
<div v-bind:style="{ 'position': 'absolute' , 'border': '2px solid #FFFFFF' , 'width':'100%','height':this.windowSize.height-10+'px' }" >{{ActDescBtn}}

<!-- columna general 1 #F44336-->
<v-col cols="12" md="12" sm="12">
 <v-tabs
        v-model="tab"
       background-color="blue-grey lighten-4"
       color="indigo darken-4" 
        centered
        icons-and-text
        grow
        dense
      >
        <v-tabs-slider></v-tabs-slider>
  
        <v-tab href="#tab-1">
          MANTENIMIENTO
        <i style="font-size: 20px;" class="fa fa-file-o"></i>
        </v-tab>
  
        <v-tab href="#tab-2" @change="ChangeTabsConsultaMant" >
          CONSULTA MANTENIMIENTO
         <i style="font-size: 20px;" class="fa fa-search"></i>
        </v-tab>
  
        <v-tab href="#tab-3">
          REGISTRO ORDENES
         <i style="font-size: 20px;" class="fa fa-pencil-square-o"></i>
        </v-tab>

          <v-tab href="#tab-4" @change="ChangeTabsConsultaOrden">
          CONSULTA ORDENES
         <i style="font-size: 20px;" class="fa fa-search"></i>
        </v-tab>

        <v-tab @change="ChangeTabsCalendOrd" href="#tab-5">
          CALENDARIO ORDENES
         <i style="font-size: 20px;" class="fa fa-calendar"></i>
        </v-tab>
      </v-tabs>

<v-tabs-items v-model="tab">
<!-------------------------------------------- Tab item 1 -->
<v-tab-item :value="'tab-1'">
<v-card flat class=" py-1">
 <RegistroMantenimiento />
</v-card > 
          <!-- <v-card flat>
            <v-card-text> 
             
            </v-card-text>
           </v-card >  -->
</v-tab-item>

<!-------------------------------------------- Tab item 2 -->
<v-tab-item :value="'tab-2'">
  <v-card flat class=" py-1">
 <ConsultaMantenimientos ref="RefComponConsutMan" />
</v-card > 
</v-tab-item>

<!-------------------------------------------- Tab item 3 -->
<v-tab-item :value="'tab-3'">
         <v-card flat class=" py-1">
            <CrearOrdenes  />
           </v-card >         
</v-tab-item>

<!-------------------------------------------- Tab item 4 -->
<v-tab-item :value="'tab-4'">
          <v-card flat class=" py-1">
              <ConsultaOrdenes ref="RefComponConstOrd"/>
           </v-card > 
</v-tab-item>

<!-------------------------------------------- Tab item 5 -->
<v-tab-item :value="'tab-5'">
 <v-card flat class=" py-1">
              <CalendarioOrdenes ref="RefComponCalendario" />
           </v-card > 
</v-tab-item>

</v-tabs-items>
</v-col >




</div>
</template>

<script>
import VueAutonumeric from "../../components/AutoNumericNuevo";

import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
import RegistroMantenimiento from "@/EventoMantenimiento/Mant/RegistroMantenimiento.vue";
import ConsultaMantenimientos from "@/EventoMantenimiento/Mant/ConsultaMantenimientos.vue";
import CrearOrdenes from "@/EventoMantenimiento/Mant/CrearOrdenes.vue";
import ConsultaOrdenes from "@/EventoMantenimiento/Mant/ConsultaOrdenes.vue";
import CalendarioOrdenes from "@/EventoMantenimiento/Mant/CalendarioOrdenes.vue";
export default {

   components: {
    VueAutonumeric,
    ContenidoBase,
    RegistroMantenimiento,
    ConsultaMantenimientos,
    CrearOrdenes,
    ConsultaOrdenes,
    CalendarioOrdenes

  },

mounted() {


  },

   created(){
  },
  // destroyed(){
  //   location.reload()
  // },

  data:()=> ({

  windowSize:{width:"auto", height:"auto"},
  tab:'tab-1',


}),

 methods: {

ChangeTabsCalendOrd(){
  //actualizar calendario
  console.log('')


var _this=this;
setTimeout(function(){ 
_this.$refs.RefComponCalendario.CargarOrdenesRadio()
}, 500);

},


ChangeTabsConsultaMant(){
var _this=this;
setTimeout(function(){ 
_this.$refs.RefComponConsutMan.CargarListMantenimiento()
}, 500);

},


ChangeTabsConsultaOrden(){
var _this=this;
setTimeout(function(){ 
_this.$refs.RefComponConstOrd.CargarListOrden()
}, 500);


},


ActualizarCalendario(){

}

  },


computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>