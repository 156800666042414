<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Listado Movimiento de Productos'"
          :descripcion="'Imprime un listado de los movimientos de un Producto o de todos los Productos.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesMovimientoProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>


                  <span class="font-weight-light" style="font-size: 20px;"> Listado Movimiento de Productos </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  desde  -->
                        <v-text-field dense outlined type="date" label="Fecha Incial:" :rules="[$rules.required]"
                          v-model="MovimientoProductos.desde" @blur="BlurDesde">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <v-combobox v-model="MovimientoProductos.almacen" label="Almacén" required outlined dense
                          autocomplete="off" :items="Ralmacen" item-text="descripcion" @blur="SelctCbAlmacen"
                          @focus="CargarRalmacen">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                          </template>
                        </v-combobox>



                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  hasta  -->
                        <v-text-field dense outlined type="date" label="Fecha Final:" :rules="[$rules.required]"
                          v-model="MovimientoProductos.hasta" @blur="BlurHasta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Producto -->
                        <v-combobox autocomplete="off" dense outlined label="Productos:" :items="Rproducto"
                          :rules="[$rules.required]" item-text="codProDesc" @keyup="CbFilProducto"
                          v-model="MovimientoProductos.producto" @blur="SelctCbProduc" @focus="CargarRproducto" no-filter>
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>

                        </v-combobox>







                      </v-col>
                      <!-- fn columna 2 -->

                    </v-row>

                  </v-form>
                </v-container>

                <hr>

                <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico"
                  :Datos="MovimientoProductoslink" />


              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Listado Movimiento de Productos",
      descripcion: "Imprime un listado de los movimientos de un Producto o de todos los Productos.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [

        "ReportesMovimientoProductosNew",
        "ReportesMovimientoProductosSave",
        "ReportesMovimientoProductosRemove",
        "ReportesMovimientoProductosAnular",
        "ReportesMovimientoProductosPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesMovimientoProductosNew", this.new);
    // EventBus.$on("ReportesMovimientoProductosSave", this.save);
    // EventBus.$on("ReportesMovimientoProductosRemove", this.remove);
    // EventBus.$on("ReportesMovimientoProductosAnular", this.remove);
    // EventBus.$on("ReportesMovimientoProductosPrintList", this.imprimirList);
    EventBus.$on("emitReportesMovimientoProductos", this.datostabla);
    EventBus.$on("onResize", this.onResize);

    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;

    var VarHora = new Date().toLocaleTimeString().substr(0, 4) + " " + new Date().toLocaleTimeString('en-US').substr(8)
    console.log(VarHora)

  },

  data: () => ({


    Ralmacen: [],
    Rproducto: [],
    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },

    MovimientoProductoslink: {

      nombre: 'LISTADO MOVIMIENTO DE PRODUCTOS',
      UrlImprimir: '/reportesInv/movimientoproductos',
      UrlExel: '/reportesInv/excel-movimientoproductos',
      UrlCorreo: '/reportesInv/correo-movimientoproductos',

    },
    MovimientoProductos: {
      desde: fechaNueva(),
      hasta: fechaNueva(),
      almacen: null,
      producto: null
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
    },

    datostabla(e) {

      this.MovimientoProductos = e;
      this.acti_tb_data = false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() { },

    DDataReporteBasico() { },

    validarObjecto() {
      if (!this.$refs.form.validate()) {
        this.$refs.ModRepFilt.detener = true;
        return;
      } else {
        this.$refs.ModRepFilt.detener = false;
      }
      var where = ` p.codigo  > 0 and a.fecha >= ' ${this.MovimientoProductos.desde} ' and a.fecha  <=' ${this.MovimientoProductos.hasta} '`
      var s = ""
      this.MovimientoProductoslink.producto = ""
      if (this.MovimientoProductos.producto != null) {
        where += ` and p.codigo = ${this.MovimientoProductos.producto.productos.codigo} `
        this.MovimientoProductoslink.producto = JSON.parse(JSON.stringify(this.MovimientoProductos.producto.productos.descripcion))
      }
      if (this.MovimientoProductos.almacen != null) {

        where += ` and a.codigo= ${this.MovimientoProductos.almacen.codigo} `
        s += `Almacen: ${this.MovimientoProductos.almacen.descripcion} `
        this.MovimientoProductoslink.almacen = ` and a.codigo = ${this.MovimientoProductos.almacen.codigo} `
      } else {
        s += `Todos los almacen `
        this.MovimientoProductoslink.almacen = ""
      }
      this.MovimientoProductoslink.where = where
      this.MovimientoProductoslink.f1 =this.MovimientoProductos.desde
      this.MovimientoProductoslink.f2 = this.MovimientoProductos.hasta
      this.MovimientoProductoslink.s = s
      this.MovimientoProductoslink.fechabala = this.fechaNueva()
      console.log("Este es el Where")
      console.log(where)

    },


    newwnuevo() {
      (this.MovimientoProductos = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Ralmacen = res.data;
        });
    },



    SelctCbAlmacen() {
      if (this.MovimientoProductos.almacen == null) {
        this.MovimientoProductos.almacen = "";
      }

      if (this.MovimientoProductos.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.MovimientoProductos.almacen = "";
        return;
      }
    },



    CargarRproducto() {

      this.$axios.get(this.$hostname + this.$hName +
        "/unidades-productos/*/false/buscar",
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => { this.Rproducto = res.data });
    },




    CbFilProducto(e) {


      if (e.target.value.length >= 0) {

        this.$axios
          .get(this.$hostname +
            this.$hName +
            "/unidades-productos/" + (e.target.value === "" ? "A" : e.target.value) + "/false/buscar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.Rproducto = res.data });
      }

    },

    SelctCbProduc() {
      if (this.MovimientoProductos.producto == null) {
        this.MovimientoProductos.producto = "";
      }

      if (this.MovimientoProductos.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.MovimientoProductos.producto = "";
        return;
      }
    },


    BlurDesde() {

      if (this.MovimientoProductos.desde.toString().length == 0) {
        this.MovimientoProductos.desde = this.fechaNueva()
      }

    },


    BlurHasta() {

      if (this.MovimientoProductos.hasta.toString().length == 0) {
        this.MovimientoProductos.hasta = this.fechaNueva()
      }

    },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          suario: JSON.parse(JSON.stringify(this.$user))
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style>
</style>
