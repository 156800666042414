<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px"  >    
<ContenidoBase 
  :titulo="'Alfabetico de Productos'"
  :descripcion="'Imprime una lista de todos los productos.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesAlfabeticoProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;" >Alfabetico de Productos</span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


<v-text-field
            label="Letra Inicial:"
            dense
            maxlength="1"
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="AlfabeticoProductos.letraInicio"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-sort-alpha-desc"></i>
            </template>
          </v-text-field>

          <v-text-field
            label="Registro Sanitario:"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="AlfabeticoProductos.sanitario"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-leanpub"></i>
            </template>
          </v-text-field>


</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<v-text-field
            label="Letra Final:"
            maxlength="1"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="AlfabeticoProductos.letraFinal"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-sort-alpha-asc"></i>
            </template>
          </v-text-field>

<v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Resumido:"
              v-model="AlfabeticoProductos.RegBlanco"
            >
          <template v-slot:label>
         <strong v-if="AlfabeticoProductos.RegBlanco==true" style="color:#000000;">Registro Sanitario en Blanco: Si</strong>
         <strong v-if="AlfabeticoProductos.RegBlanco==false" style="color:#000000;">Registro Sanitario en Blanco: No</strong>
        </template>
            
            
            </v-switch>



</v-col>
<!-- fn columna 2 -->


              
              </v-row>



            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros2
ref="ModRepfil"
@DataReporteBasico="DDataReporteBasico"
:Datos="AlfabeticoProductos"
/>


  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros2 from "../../components/ModalReporteFiltros2";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros2
  },
  created() {
    var json = { 
      titulo: "Alfabetico de Productos",
      descripcion: "Imprime una lista de todos los productos.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [
        "ReportesAlfabeticoProductosNew",
        "ReportesAlfabeticoProductosSave",
        "ReportesAlfabeticoProductosRemove",
        "ReportesAlfabeticoProductosAnular",
        "ReportesAlfabeticoProductosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesAlfabeticoProductosNew", this.new);
    // EventBus.$on("ReportesAlfabeticoProductosSave", this.save);
    // EventBus.$on("ReportesAlfabeticoProductosRemove", this.remove);
    // EventBus.$on("ReportesAlfabeticoProductosAnular", this.remove);
    // EventBus.$on("ReportesAlfabeticoProductosPrintList", this.imprimirList);
    EventBus.$on("emitReportesAlfabeticoProductos", this.datostabla);
   // EventBus.$on("onResize", this.onResize);

 this.$refs.ModRepfil.CrearObjecto=this.validarObjecto;

  },

  data:()=>  ({


      Rpropietario:[],
      RSucursal:[],
      RCaja:[],
    
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      AlfabeticoProductos: {
      nombre:'REPORTE ALFABETICO DE PRODUCTOS',
      UrlImprimir: '/reportesInv/ImprimirListAlfabProd', 
      UrlExel: '/reportesInv/ExcelListAlfabProd', 
      UrlCorreo: '/reportesInv/CorreoListAlfabProd', 
        letraInicio:null,
        sanitario:null,
        letraFinal:null,
        RegBlanco:false
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
    

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){// this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.AlfabeticoProductos = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() {},
    anular() { },
    imprimirList() {},

DDataReporteBasico(e){
},

validarObjecto(){ 
//this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
var where = " p.codigo > 0";

this.AlfabeticoProductos.condcion=""

        if (this.AlfabeticoProductos.letraInicio != null && this.AlfabeticoProductos.letraInicio.length>0 ) {
            where += ` and substring(UPPER(p.descripcion) from 1 for 1)>='${this.AlfabeticoProductos.letraInicio.toUpperCase()}'` ;
          this.AlfabeticoProductos.condcion=`  Desde la Letra: ${this.AlfabeticoProductos.letraInicio.toUpperCase()} `
        }

        if (this.AlfabeticoProductos.letraFinal != null && this.AlfabeticoProductos.letraFinal.length>0 ) {
            where += ` and substring(UPPER(p.descripcion) from 1 for 1)<='${this.AlfabeticoProductos.letraFinal.toUpperCase()}'` ;
          this.AlfabeticoProductos.condcion=`  Hasta la Letra:  ${this.AlfabeticoProductos.letraFinal.toUpperCase()} `
        }

      if (this.AlfabeticoProductos.sanitario != null && this.AlfabeticoProductos.sanitario.length>0 ) {
            where += ` and upper(registro_mencantil) like '%'${this.AlfabeticoProductos.sanitario.toUpperCase()}'` ;
        }

         if (this.AlfabeticoProductos.RegBlanco ==true ) {
            where += " and (registro_mencantil isnull or registro_mencantil='')" ;
        }

        this.AlfabeticoProductos.where=where
        console.log(where);
     //   ic.imprimir("listado_nota_credito", parametros, "cxp");
},



    newwnuevo() {
      (this.AlfabeticoProductos = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
      //     usuario:JSON.parse(JSON.stringify(this.$user)) 
      //   })
      //   .then(res => {
      //     this.list = res.data;
      //   });
    }
  },

computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))     

    }

}




};
</script>

<style></style>
