<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
<!-- tooltip -->  
<v-col class="pa-0 ma-0 py-0 my-0 "  style="background-color: #dd4b39" cols="12" md="12" sm="12">
    <v-row no-gutters > 
      <v-col class="pa-0 ma-0 py-0 my-0 pt-3"   cols="12" md="9" sm="8"  >
        <span :class="`d-flex justify-start white--text`">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
          <i v-on="on" style="font-size: 20px;" class="fa fa-info-circle px-2"> </i><b v-on="on">MEDICAMENTOS PENDIENTES A ENTREGAR</b>
        </template>
           <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> MEDICAMENTOS PENDIENTES A ENTREGAR </span> </i>
          </v-tooltip>
        </span>   
      </v-col>

      <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="3" sm="4"  >
        <v-card-actions>
            <v-btn-toggle>
<!--  v-show="medicamentosPendiente.completada==true" v-if="false"   -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn  v-if="false" id="boton-mini" v-on="on" @click="ImprimirMedicPend(medicamentosPendiente)" color="blue" small dark>
                    <i style="font-size: 20px;" class="fa fa-print"> </i>
                    IMPRIMIR
                  </v-btn>
                </template>
                <span>IMPRIMIR: Cancela y retornar atrás</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarMedicPend()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="nuevoRegistro()" color="grey" small dark>
                    <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>
                          <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>


              <v-tooltip bottom >
                  <template v-slot:activator="{ on }">
                    <v-btn  v-show="medicamentosPendiente.completada==false" id="boton-mini" v-on="on" @click.prevent="salvarRegistro" color="light-blue darken-4"
                      small dark>
                      <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                      SALVAR
                    </v-btn>
                  </template>
                  <span>SALVAR: Guardar Receta Controlada </span>
                </v-tooltip>
              
            </v-btn-toggle>

          </v-card-actions>
        <!-- <v-btn  color="grey darken-4" fab x-small @click="cancelarCierreCaja()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn> -->
      </v-col>
      

    </v-row> 
</v-col>
  <!-- fn tooltip -->
<!-- Contenido -->
<v-col class="pa-0 ma-0 py-0 my-0 pt-2 px-2"   cols="12" md="12" sm="12">
  <v-card color="grey lighten-3">
    <v-form ref="formMedicamentosPendiente" @submit.prevent="" v-model="valid" lazy-validation>
      <v-row no-gutters>
        <v-col class="pa-0 ma-0 py-0 my-0"   cols="12" md="4" sm="4">
        <v-text-field label="No. FACTURA" dense autocomplete="off" outlined v-model="medicamentosPendiente.noFactura"
                  style="font-size: 12px;" hide-details :rules="[$rules.required]" @keyup="NumeralFac" v-on:keyup.enter="buscarFactura" >
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">

                        <v-btn @click.prevent="buscarFactura()" v-on="on" color="blue darken-3" fab
                          x-small dark>
                          <i style="font-size: 20px;" class="fa fa-search"></i>
                        </v-btn>

                      </template>
                      <span>BUSCAR FACTURA</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
        </v-col>
 <v-col  v-if="medicamentosPendiente.completada==true" class="pa-0 ma-0 py-0 my-0"   cols="12" md="8" sm="8">
  <b style="font-size:25px;" :class="`d-flex justify-center green--text`"> ENVIADA COMPLETA </b>
</v-col>
              <v-col class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" v-if="listFactCliente.length>0">
                <div style="border: 1px solid #000000;">

                  <!-- NOTA: PROCESO PENDIENTE PRIMERA VES  -->
                  <v-data-table v-if="procesoPendienteExistente==false" class="RowSelectColor elevation-1 TbSize13" :single-select="true" item-key="indx"
                    :show-select="true" v-model="selectedFactura" @item-selected="ObtenerDatoSelect"
                    @click:row="Datostabla" :headers="[
                      { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                      { text: '# Secuencia', sortable: false, value: 'sec', width: 129 },
                      { text: 'Cliente', sortable: false, value: 'clinica.nombre', width: 129 },
                      { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
                    ]" dense :items="listFactCliente" hide-default-footer :items-per-page="5">

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      listFactCliente.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.nuevaFecha) }}</span>
                    </template>

                    <template v-slot:item.sec="props">
                      <span style="font-size:13px;">{{ props.item.documentoFactura.nombrecorto.trim() }}-{{
                        props.item.facturasventaPK.secuencia }} </span>
                    </template>


                    <template v-slot:item.clinica.nombre="props">
                      <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                      </span>
                    </template>

                    <template v-slot:item.valor="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                    </template>
                  </v-data-table>

                  <!-- NOTA: PROCESO PENDIENTE YA EXISTENTE  -->

                  <v-data-table v-if="procesoPendienteExistente==true" class="RowSelectColor elevation-1 TbSize13" :single-select="true" item-key="indx"
                    :show-select="true" v-model="selectedFactura" @item-selected="ObtenerDatoSelectProcExit"
                    @click:row="DatostablaProcExit" :headers="[
                      { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                      { text: '# Secuencia', sortable: false, value: 'sec', width: 129 },
                      { text: 'Cliente', sortable: false, value: 'clinica', width: 129 },
                      { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
                    ]" dense :items="listFactCliente" hide-default-footer :items-per-page="5">

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      listFactCliente.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.facturasventa.nuevaFecha) }}</span>
                    </template>

                    <template v-slot:item.sec="props">
                      <span style="font-size:13px;">{{ props.item.facturasventa.documentoFactura.nombrecorto.trim() }}-{{
                      props.item.facturasventa.facturasventaPK.secuencia }} </span>
                    </template>


                    <template v-slot:item.clinica="props">
                      <span v-if="props.item.facturasventa.cliente != null" style="font-size:13px;">{{ props.item.facturasventa.cliente.nombre }}
                      </span>
                    </template>

                    <template v-slot:item.valor="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.facturasventa.valor) }}</span>
                    </template>
                  </v-data-table>


                </div>
              </v-col>


    </v-row>
    </v-form>

    <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

 <!-- Infomacion Selecionada -->
 <v-row no-gutters>
  <!-- v-if="this.RecetaProdControlado.cliente != null" -->
  <!-- Fila 1 -->
                  <v-col cols="12" md="6" sm="6">
                    <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <span :class="`d-flex justify-center white--text`"><b>DATOS DE LA FACTURA</b></span>
                    </v-col>
                    <div v-if="FacturaSeleccionada!=null" style="font-size: 13px;">
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                      {{ FormatoFecha(FacturaSeleccionada.nuevaFecha) }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NO.FACTURA : </b>
                      {{ FacturaSeleccionada.documentoFactura.nombrecorto.trim() }}-{{FacturaSeleccionada.facturasventaPK.secuencia }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> SUCURSAL : </b>
                      {{ getObjContainsKey(FacturaSeleccionada,'sucursal','descripcion') }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CAJA : </b>
                      {{ getObjContainsKey(FacturaSeleccionada,'caja','descripcion') }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR: </b>
                      ${{ getObjContainsKey(FacturaSeleccionada,'moneda','nombrecorto')}} {{ currencyFormatter(FacturaSeleccionada.valor/FacturaSeleccionada.tasa)}}  </p>
                    </div>
                  </v-col>
<!-- fn Fila 1 -->
<!-- Fila 2 -->
                  <v-col cols="12" md="6" sm="6">
                    <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <span :class="`d-flex justify-center white--text`"><b>DATOS DEL CLIENTE</b></span>
                    </v-col>
                    <div v-if="FacturaSeleccionada!=null" style="font-size: 13px;">
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CÓDIGO #: </b>
                      {{ getObjContainsKeyClient(FacturaSeleccionada,'cliente','codigo') }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NOMBRE: </b>
                      {{ getObjContainsKeyClient(FacturaSeleccionada,'cliente','nombre') }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CÉDULA : </b>
                      {{ getObjContainsKeyClient(FacturaSeleccionada,'cliente','cedrnc') }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TELEFONO : </b>
                      {{ selectTelefonoCliente(FacturaSeleccionada.cliente) }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> DIRECCIÓN: </b>
                      {{ getObjContainsKeyClient(FacturaSeleccionada,'cliente','direccion') }}</p>
                    </div>

                  </v-col>
<!-- fn Fila 2 -->


                    <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <span :class="`d-flex justify-center white--text`"><b>DETALLE DE LA FACTURA</b></span>
                    </v-col>
                      <!-- , 'border': '2px solid #000000'  style="background-color:#dd4b39;" -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <div v-bind:style="{ 'height': this.windowSize.height-250 + 'px', 'max-height': this.windowSize.height-250 + 'px'}"
                            class="overflow-x-auto" ref="RefScroll">
                      <v-data-table class="RowSelectColor TbSize13" item-key="indx" :headers="[
                    { text: 'COD.PRODUCTO', sortable: false,  value: 'codigopro', width:120, class: ['no-gutters  black--text'] },
                    { text: 'PRODUCTO', sortable: false, value: 'descripcion', width: 'auto', class: ['no-gutters  black--text'] },
                    { text: 'CANTIDAD FACTURADA', sortable: false, value: 'cantidadFacturada', width: 'auto', class: ['no-gutters  black--text'] },
                    { text: 'CANTIDAD PENDIENTE', sortable: false, value: 'cantidadPendiente', width: 'auto', class: ['no-gutters  black--text'] },
                    { text: 'CANTIDAD ENTREGADA AL CLIENTE', sortable: false, value: 'cantEntrega', width: 'auto', class: ['no-gutters  black--text'] },
                    { text: 'ESTADO', sortable: false, value: 'estado', class: ['no-gutters black--text'] }

                  ]" dense :items="medicamentosPendiente.productosPendientesEntregaDetalleList" :items-per-page="-1" hide-default-footer id="TbBord" :loading="loaderTabla" >


<template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
<v-btn fab @click.prevent="AddFact(item)" x-small icon color="green"><i style="font-size: 20px;" class="fa fa-check-circle"></i></v-btn>            
  </template>
                    <template v-slot:item.codigopro="props">
                      <span style="display:none;"> {{ props.item.indx = medicamentosPendiente.productosPendientesEntregaDetalleList.indexOf(props.item) }} </span>
                      <span style="display:none; font-size:13px;" >
                        <span v-if="!props.item.hasOwnProperty('codigoProducto') || props.item.codigoProducto==null">
                        {{ props.item.codigoProducto=GetCodigoProdUni(props.item) }}</span>
                      </span>
                        <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
                           autocomplete="off" :value="props.item.codigoProducto">
                       </v-text-field>
                  
                    </template>

                    <template v-slot:item.descripcion="props">
                      <!-- <span style="font-size:13px;">{{ props.item.descripcion }}</span> -->
                    <!-- v-text-field -->
                      <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
                   autocomplete="off" :value="props.item.descripcion">
                     </v-text-field>
                    </template>

                    <template v-slot:item.cantidadFacturada="props">
                      <!-- <span style="font-size:13px;">{{props.item.cantidadFacturada}}</span> v-model.number -->
                      
                      <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
                       autocomplete="off" :value="props.item.cantidadFacturada">
                     </v-text-field>
                    </template>

                    <template v-slot:item.cantidadPendiente="props">
                      <!-- <span style="font-size:13px;">{{props.item.cantidadPendiente}}</span>  -->

                      <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
                       autocomplete="off" :value="(calculoPendiente(props.item))">
                     </v-text-field>

                    </template>


                    <template v-slot:item.cantEntrega="props">
                      <!-- <span style="font-size:13px;">{{props.item.CantidadEntregar}} </span>  -->

                      <VueAutonumeric :ref="'cantEntrega'+props.item.indx"  :disabled="medicamentosPendiente.completada==true" label="" id="formdisabledInput"  style="font-size:13px;" dense hide-details outlined
                      @keydown.enter="cantEntregaKeydownEnter('cantEntrega'+props.item.indx)" autocomplete="off" v-model.number="props.item.cantEntrega" @blur="blurCantidadEntregar(props.item)"
                       >
                      </VueAutonumeric>
                    </template>

                    <template v-slot:item.estado="props"> 
<!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
              
                    <span  class="px-3" > 
                    <i v-if="props.item.cantidadPendiente==0" style="font-size: 20px;" class="fa fa-check-circle green--text"></i>
                    <i v-if="props.item.cantidadPendiente>0" style="font-size: 20px;" class="fa fa-times-circle-o red--text"></i>
                    </span> 
                    <!-- <v-btn fab @click.prevent="AddFact(item)" x-small icon color="green"><i style="font-size: 20px;" class="fa fa-check-circle"></i></v-btn>             -->
                    </template>

                  </v-data-table>
                </div>
                    </v-col>


                </v-row>
                <!-- fn Infomacion Selecionada -->






              </div>
   </v-col>
    
  </v-card>
</v-col>
<!-- fn Contenido -->


<!---------------------  Panel Menu Opcion Botones ------------------------------->
            <div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  -:cajaSelect="cajaSelect"--------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"  />
            </div>
            <!--------------------- fn Panel Menu Opcion Botones ------------------------------->

            <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,HoraFormatter2,fechaNueva,getParamFecha} from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric,
    PvMenuOpcionBotones
  },
  created() { 

  },
  mounted() {
 
    this.cambiarDestinoSucursal = this.$store.getters.GetConfiguracionGeneral.configPv.cambiarDestino
  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },


    windowSize: { width: "auto", height: "auto" },
    procesoPendienteExistente:false,
    valid: true,
    loaderTabla:false,
    selectedFactura:[],
    listFactCliente:[],
    FacturaSeleccionada:null,
    medicamentosPendiente:{
      noFactura:0,
      codigo:0,
      activo: true,
      completada:false,
      facturasventa:null,
      fecha:fechaNueva(),
      usuario:'',
      productosPendientesEntregaDetalleList:[]
    },

    

    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
      },
 

    
  }),

  props: { 

configuraciones: {
  required: false,
  type: Object,
  default: null
},

cajaSelect: {
  required: false,
  type: Object,
  default: null
},



},

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,HoraFormatter2,fechaNueva,getParamFecha,
 
    AddFact(e){

/*if(e.facturasventaDetalleList.length>0){

e.facturasventaDetalleList.forEach(elet => {
 this.AgregarComprobEquipo(elet)
});


}else{
this.alerta("Esta factura no posee equipos", "error")
}*/

},

nuevoRegistro(){

  this.selectedFactura=[]
  this.listFactCliente=[]
  this.FacturaSeleccionada=null
    this.medicamentosPendiente={
      noFactura:0,
      codigo:0,
      activo: true,
      completada:false,
      facturasventa:null,
      fecha:fechaNueva(),
      usuario:'',
      productosPendientesEntregaDetalleList:[]
    }
},

cancelarMedicPend(){
      this.nuevoRegistro()
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarMedicPend", false)
     }, 300);
    },

    DataRetornMenuOpcBotonesPermisosPv(){},

    DataRetornPadrePermisoPv(e) {},


    ImprimirMedicPend(OBJMEDIV){
     if (localStorage.printerSelect != null) {
              var msg = {
                accion: "print",
                formato: this.cajaSelect.formatoimpresioncontado.trim(),
                impresora: JSON.parse(localStorage.printerSelect).nombre,
                data: this.configuraciones,
                pendienteEntrega:OBJMEDIV ,
                caja: this.cajaSelect,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
                accionTitulo:"IMPRECION",
                schema: this.$store.getters.GetheadersAxios["X-TENANT-ID"]
              };
            }

            this.imprimir(msg, "pendienteentrega");
//:cajaSelect="caja"

    },


    imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.nuevoRegistro()
          })
          .catch((error) => { 
            this.ejecucion=true 
            console.error(error)
          });
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
        .catch((error) => { 
            this.ejecucion=true 
            console.error(error)
          });
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.nuevoRegistro()
          })
          .catch((error) => {
            console.error(error)
            this.ejecucion=true 
          });
      }
    },


    NumeralFac(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.medicamentosPendiente.noFactura = mynumeral(this.medicamentosPendiente.noFactura).value();
        if (this.medicamentosPendiente.noFactura == null) {
          this.medicamentosPendiente.noFactura = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },


buscarFactura(){
  if(this.medicamentosPendiente.noFactura==0){
    this.nuevoRegistro()
    return
  }
  this.selectedFactura = [];
  this.listFactCliente = [];

  this.$axios
        .get(this.$hostname + this.$hName + "/ProductosPendientesEntrega/ProductosPendientesByFacSecList/"+this.medicamentosPendiente.noFactura, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {

          if(res.data.length>0){
            this.procesoPendienteExistente=true
            this.listFactCliente = res.data;
          }else{
            this.procesoPendienteExistente=false
            this.buscarFacturaTbFactura()
          }

          
        })
        .catch((error) => {
              this.alerta("factura no encontrada", "error")
            });
        
},


buscarFacturaTbFactura (){
  this.selectedFactura = [];
      this.listFactCliente = [];
      var cliente = "null",
        fecini = "null",
        fecfin = "null",
        caja = "null",
        sec = this.medicamentosPendiente.noFactura;
        this.nuevoRegistro()
         this.medicamentosPendiente.noFactura=sec 
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reimpresion/facturasCaja/" +sec +
          "/" +fecini +"/" +fecfin +"/" +
          cliente +"/" +30 +"/" +0+"/"+caja,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {

          if(res.data.listas.length==0){
            this.alerta("No existe factura con este numero.", "error")
          }
         
          this.listFactCliente = res.data.listas;
        })
        .catch((error) => {this.alerta("Factura no encontrada ", "error")});
},


ObtenerDatoSelect(e) {
      this.Datostabla(e.item);
    },

    // Creando un nuevo objeto y asignándolo a la variable AnulacionFacturaSeleccionada.
    Datostabla(e) {

      this.selectedFactura = [];
      var noFac=this.medicamentosPendiente.noFactura
  this.medicamentosPendiente={
      noFactura:noFac,
      codigo:0,
      activo: true,
      completada:false,
      facturasventa:{ ...e },
      fecha:fechaNueva(),
      productosPendientesEntregaDetalleList:[]
    }
      var _this=this;
setTimeout(function(){ 

 // _this.medicamentosPendiente.productosPendientesEntregaDetalleList=e.facturasventaDetalleList
  _this.dataListFactCliente(e.facturasventaDetalleList)
  _this.FacturaSeleccionada = { ...e };
  _this.selectedFactura.push(e); 
}, 300);


      
      // this.AnulacionFacturaSeleccionada=objjt
    },


    ObtenerDatoSelectProcExit(e) {
      this.DatostablaProcExit(e.item);
    },

    // Creando un nuevo objeto y asignándolo a la variable AnulacionFacturaSeleccionada.
    DatostablaProcExit(e) {
      var noFac=this.medicamentosPendiente.noFactura
  this.medicamentosPendiente={
      codigo:0,
      activo: true,
      completada:false,
      facturasventa:null,
      fecha:fechaNueva(),
      productosPendientesEntregaDetalleList:[]
    }

      this.selectedFactura = [];
      var _this=this;
      var OBJ={...e}
      OBJ.productosPendientesEntregaDetalleList=[]
setTimeout(function(){ 
  _this.dataListFactClienteProcExit(e.productosPendientesEntregaDetalleList)
  _this.FacturaSeleccionada = { ...e.facturasventa  };
  _this.selectedFactura.push(e); 
  _this.medicamentosPendiente=OBJ
  _this.medicamentosPendiente.fecha=e.nuevaFecha
  _this.medicamentosPendiente.noFactura=noFac
}, 300);

    },


    async dataListFactClienteProcExit(DATALIST){

DATALIST.sort(function (a, b) {
        if (a.codigo > b.codigo) {return 1;}
        if (a.codigo < b.codigo) {return -1;
      }
  // a must be equal to b
  return 0;
});

   // this.dataListFactCliente(res.data.listas.facturasventaDetalleList)
  var ARRALIST=[]
  await Promise.all(DATALIST.map(async (element) => { 
 var OBJ={...element}
 OBJ.codigoProducto=this.GetCodigoProdUni(element)
 OBJ.cantEntrega=0
    ARRALIST.push(OBJ)
       
       }));

       this.medicamentosPendiente.productosPendientesEntregaDetalleList=ARRALIST

       this.loaderTabla=true
       var _this = this;
        setTimeout(function () {
        _this.loaderTabla=false
        }, 800);

},





async dataListFactCliente(DATALIST){
   // this.dataListFactCliente(res.data.listas.facturasventaDetalleList)
  var ARRALIST=[]
  await Promise.all(DATALIST.map(async (element) => { 

    var OBJ={
          codigo:0,
          codigoProducto:this.GetCodigoProdUni(element),
          descripcion:`${element.productos.descripcion.trim()}  ${element.unidades.descripcion.trim()}`,
          productos:element.productos,
          unidades:element.unidades,
          cantidadFacturada:element.cantidad,
          cantidadPendiente:0.00,
          cantidadEntregada:element.cantidad,
          cantEntrega:element.cantidad
    }
    
    ARRALIST.push(OBJ)
       
       }));

       this.medicamentosPendiente.productosPendientesEntregaDetalleList=ARRALIST

       this.loaderTabla=true
       var _this = this;
        setTimeout(function () {
        _this.loaderTabla=false
        }, 800);

},



    GetCodigoProdUni(unidaproducto) {
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
       /* if(unidaproducto.lote!==""){
          console.log(unidaproducto.lote.substring(10))
          this.lote = parseInt(unidaproducto.lote.substring(10)); 
        }
        return unidaproducto.lote;*/
        var CODPRO=unidaproducto.productos.codigo
        var CODUNI=unidaproducto.unidades.codigo
        var codigoP = ('000000' + CODPRO).substring(('000000' + CODPRO).length - 6, ('000000' + CODPRO).length)
        var codigoU = ('0000' + CODUNI).substring(('0000' + CODUNI).length - 4, ('0000' + CODUNI).length)
        return codigoP + codigoU;

      } else {
        var CODPRO=unidaproducto.productos.codigo
        var CODUNI=unidaproducto.unidades.codigo
        var codigoP = ('000000' + CODPRO).substring(('000000' + CODPRO).length - 6, ('000000' + CODPRO).length)
        var codigoU = ('0000' + CODUNI).substring(('0000' + CODUNI).length - 4, ('0000' + CODUNI).length)
        return codigoP + codigoU;
      }
    },

    getObjContainsKey(OBJ,KEY,NOM){
      var DESC=''
        if(OBJ.hasOwnProperty(`${KEY}`) && OBJ[KEY]!=null){

          if(NOM!=null){
            if(OBJ[KEY].hasOwnProperty(`${NOM}`) && OBJ[KEY][NOM]!=null){
            DESC=(OBJ[KEY][NOM]+"").trim()
            }else{
              DESC='N/A'
            }
          }else{
            DESC=(OBJ[KEY]+"").trim()
          }
        }else{
          DESC='N/A'
        }

        return DESC;
    },


    getObjContainsKeyClient(OBJ,KEY,NOM){
      var DESC=''
      /*if(OBJ.hasOwnProperty('cliente')&& OBJ.cliente!=null ){
      }else{
        DESC='N/A'
      }*/ 
        if(OBJ.hasOwnProperty(`${KEY}`) && OBJ[KEY]!=null){
          if(NOM!=null){
            if(OBJ[KEY].hasOwnProperty(`${NOM}`) && OBJ[KEY][NOM]!=null){
              DESC= (OBJ[KEY][NOM]+"").trim();
            }else{
              DESC='N/A'
            }
          }else{
            DESC=OBJ[KEY].trim()
          }
        }else{
          DESC='N/A'
        }
    

        return DESC;
    },


    selectTelefonoCliente(e) {
      if (e !=null &&typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return 'N/A' }
    },


    blurCantidadEntregar(e){
      var _this = this;

      if(this.medicamentosPendiente.codigo>0){
        if((e.cantEntrega+e.cantidadEntregada)>e.cantidadFacturada){
          e.cantEntrega=(e.cantidadFacturada-e.cantidadEntregada)
        }

      }else{
        if(e.cantEntrega>e.cantidadFacturada){
          e.cantEntrega=e.cantidadFacturada
         // e.cantidadPendiente=e.cantidadFacturada-e.cantEntrega
         }else{
         // e.cantidadPendiente=e.cantidadFacturada-e.cantEntrega
         }
         e.cantidadEntregada=e.cantEntrega
      }
         


            this.loaderTabla=true
      
        setTimeout(function () {
        _this.loaderTabla=false
        }, 500);

    },

    calculoPendiente(e){

if(this.medicamentosPendiente.codigo>0){
  e.cantidadPendiente=e.cantidadFacturada-(e.cantEntrega+e.cantidadEntregada)
 return e.cantidadPendiente;
      }else{
        e.cantidadPendiente=e.cantidadFacturada-e.cantEntrega;
        return e.cantidadPendiente 
      }

    },

    async salvarRegistro(){
      EventBus.$emit("loading", true);
      if(this.medicamentosPendiente.productosPendientesEntregaDetalleList.length==0){
        this.alerta("no tiene detalle de factura para realizar este proceso", "error");
        return;
      }


      var OBJmedicamentoPed=JSON.parse(JSON.stringify(this.medicamentosPendiente))
      OBJmedicamentoPed.fecha=this.medicamentosPendiente.fecha+"T04:00"
      OBJmedicamentoPed.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario

      if(this.medicamentosPendiente.codigo>0){ 

        var LISTADO= this.medicamentosPendiente.productosPendientesEntregaDetalleList.filter((ee) => ee.cantEntrega>0);
        if(LISTADO.length==0){
          this.alerta("Debe de indicar al menos un registro de la lista con cantidad a entregar", "error");
          return;
        }else{

          var ARRALIST=[]
  await Promise.all(LISTADO.map(async (element) => { 
    var OBJ={...element}
    OBJ.cantidadEntregada=(element.cantidadEntregada+element.cantEntrega)
    ARRALIST.push(OBJ)
       }));

       OBJmedicamentoPed.productosPendientesEntregaDetalleList=ARRALIST
        }

        var LISTADO1= LISTADO.concat(this.medicamentosPendiente.productosPendientesEntregaDetalleList.filter((ee) => ee.cantEntrega==0));
        var LISTADOCON= LISTADO1.filter((ee) => ee.cantidadPendiente>0);
        if(LISTADOCON.length==0){
          OBJmedicamentoPed.completada=true
        }
      }

   
      this.$axios
            .post(
              this.$hostname + this.$hName + "/ProductosPendientesEntrega/save",
              OBJmedicamentoPed,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => { 
              var RESDATA=res.data
              var DETALLEFACT=res.data.facturasventa.facturasventaDetalleList

              // this.alerta("Dato guardado correctamente!", "bien2");
              //if(res.data.estado==="bien"){}
    Promise.all(RESDATA.productosPendientesEntregaDetalleList.map(async (d) => { 
        var ObjProRow = DETALLEFACT.filter((ee) => ee.productos.codigo == d.productos.codigo && ee.unidades.codigo==d.unidades.codigo );
        d.precio=ObjProRow[0].precio
        d.valor=(ObjProRow[0].precio*d.cantidadFacturada)
     }));
     this.ImprimirMedicPend(RESDATA)
  

      
              var _this = this;
        setTimeout(function () {
          _this.alerta('Dato Guardado correctamente','bien2');
        }, 500);
              

            })
            .catch((error) => {
              this.alerta("Dato no guardado correctamente", "error")
            });




    },

    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },

    RedireccionBlur(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].blur() }, 300)
    },

    cantEntregaKeydownEnter(REDIREC){
    this.RedireccionBlur(REDIREC)
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)
        this.nuevoRegistro()

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },

    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>