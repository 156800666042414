<template>
  <div>
    <v-form ref="form3" v-model="valid" lazy-validation>
      <v-row>

        <input type="number" v-model="CuentaNueva.codigo" style="display:none;" />

        <v-col cols="12" md="6" sm="6">
          <v-text-field label="* Cuenta:" autocomplete="off" dense outlined :rules="[rules.required]"
            v-model="CuentaNueva.cuenta">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
            </template>
          </v-text-field>
        </v-col>


        <v-col cols="12" md="6" sm="6">


          <v-combobox v-model="CuentaNueva.descargo" label="Cuenta Descargo:" required outlined dense autocomplete="off"
            :items="Rcuenta" :item-text="RfilCuenta" @keyup="CbFilCuenta" @blur="SelctCbcuentaNuevaDesc"
            @focus="CargarRcuenta" @change="SelctCbCuentaNuevaDescValidControl">

            <template slot="item" slot-scope="item" margin-top="50px">
              <v-row>
                <v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
                <v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
              </v-row>

            </template>

            <template v-slot:prepend-item>
              <v-row>
                <v-col cols="6" md="6"> <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                <v-col cols="6" md="6"> <b style="font-size: 18px;">Descripción: </b> </v-col>
              </v-row>
            </template>

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
            </template>
          </v-combobox>

        </v-col>

        <v-col cols="12" md="12">
          <v-text-field label="* Descripción:" dense autocomplete="off" outlined :rules="[rules.required]"
            v-model="CuentaNueva.descripcion">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-font"></i>
            </template>
          </v-text-field>
        </v-col>


        <v-col cols="12" md="6" sm="6">
          <v-combobox v-model="CuentaNueva.origen" label="Origen:" required outlined dense :rules="[rules.required]"
            autocomplete="off" :items="ROrigen" item-text="descripcion" @blur="SelctCbcuentaorigen">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-bandcamp"></i>
            </template>
          </v-combobox>
        </v-col>

        <v-col cols="12" md="6" sm="6">
          <v-combobox v-model="CuentaNueva.tipo" label="Tipo:" required outlined dense :rules="[rules.required]"
            autocomplete="off" :items="RTipo" item-text="descripcion" @blur="SelctCbCuentatipo">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-tag"></i>
            </template>
          </v-combobox>
        </v-col>


        <v-col cols="12" md="6" sm="6">
          <v-combobox v-model="CuentaNueva.tipogasto" label="Tipo de Gasto:" required outlined dense
            :rules="[rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
            @focus="CargarRTipopago" @blur="SelctCbTipoGasto">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-tag"></i>
            </template>
          </v-combobox>
        </v-col>


        <v-col cols="12" md="6" sm="6">
          <v-combobox v-model="CuentaNueva.grupo" label="Grupo:" required outlined dense :rules="[rules.required]"
            autocomplete="off" :items="RGrupo" item-text="descripcion" @blur="SelctCbGrupo">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-cubes"></i>
            </template>
          </v-combobox>
        </v-col>


      </v-row>
      <hr />
      <!--   d-flex justify-end -->
      <v-btn-toggle rounded class="">

        <v-btn dark color="green darken-1" @click.prevent="saveCuentaNueva">
          <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
          Salvar
        </v-btn>


      </v-btn-toggle>
      <hr />


    </v-form>


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

  </div>


</template>

<script>
export default {

  data() {

    return {

      valid: true,

      RfilCuenta: "cuenta",
      Rcuenta: [],
      RTipoGasto: [],



      ROrigen: [
        { codigo: 1, origen: "D", descripcion: "Crédito" },
        { codigo: 2, origen: "C", descripcion: "Debito" },
      ],

      RTipo: [
        { codigo: 1, tipo: "CO", descripcion: "Control" },
        { codigo: 2, tipo: "DE", descripcion: "Detalle" },
      ],

      RGrupo: [

        { codigo: "AC", descripcion: "Activo" },
        { codigo: "PA", descripcion: "Pasivo" },
        { codigo: "CA", descripcion: "Capital" },
        { codigo: "IN", descripcion: "Ingreso" },
        { codigo: "CO", descripcion: "Costo" },
        { codigo: "GA", descripcion: "Gasto" }

      ],



      CuentaNueva: {
        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true,
      },



      aalert: {
        estado: false,
        color: null
      },

      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

    }
  },

  methods: {


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.new();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    alertaModal(e, l) {


      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newcuenta()
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }

    },



    newcuenta() {
      this.CuentaNueva = {

        cuenta: null,
        descripcion: null,
        descargo: null,
        origen: null,
        tipo: null,
        tipogasto: null,
        grupo: null,
        activo: true,
      }

      //this.acti_Nueva_cuenta=false


      //@emitcamioncamion="emittipocamion"


      // EventBus.$on(this.emitnombre,this.datotabla)
      // EventBus.$on("tiponuevodef", this.cambiosw);
      this.$emit("NuevaCuenta", false);


    },


    saveCuentaNueva() {
      if (this.$refs.form3.validate()) {


        if (this.CuentaNueva.descargo == "") {

          this.CuentaNueva.descargo = null

        }
        var CtaNueva=JSON.parse(JSON.stringify(this.CuentaNueva))
        this.$axios.post(this.$hostname + this.$hName + '/catalogo/save',
        CtaNueva,{headers:this.$store.getters.GetheadersAxios})
             .then(res => this.alertaModal(res.data.mensage,res.data.estado))
             .catch(error => this.alertaModal('Dato no guardado correctamente','error'))
        /*this.$axios.post(this.$hostname + this.$hName + '/services/CuentaCatalogo/save',
          JSON.parse(JSON.stringify(this.CuentaNueva)))
          .then(res => this.alertaModal(res.data.mensage, res.data.estado))
          .catch(error => this.alertaModal(error, 'error'))*/
      } else {

        this.alertaModal("los campos en rojo son Requeridos", "error");

      }



    },


    SelctCbGrupo() {


      if (this.CuentaNueva.grupo == null) {
        this.CuentaNueva.grupo = "";
      }

      if (this.CuentaNueva.grupo.codigo == undefined) {
        this.alerta("Selecciones un Grupo", "error");
        this.CuentaNueva.grupo = "";
        return;
      }

    },




    SelctCbTipoGasto() {


      if (this.CuentaNueva.tipogasto == null) {
        this.CuentaNueva.tipogasto = "";
      }

      if (this.CuentaNueva.tipogasto.codigo == undefined) {
        this.alerta("Selecciones un tipo gasto", "error");
        this.CuentaNueva.tipogasto = "";
        return;
      }

    },



    CargarRTipopago() {
      this.$axios.post(this.$hostname + this.$hName + '/services/CategoriaGasto/findall', {}).then(res => {
        this.RTipoGasto = res.data;
      });
    },




    SelctCbCuentatipo() {

      if (this.CuentaNueva.tipo == null) {
        this.CuentaNueva.tipo = "";
      }

      if (this.CuentaNueva.tipo.codigo == undefined) {
        this.alerta("Selecciones un Tipo", "error");
        this.CuentaNueva.tipo = "";
        return;
      }

    },




    SelctCbcuentaorigen() {

      if (this.CuentaNueva.origen == null) {
        this.CuentaNueva.origen = "";
      }

      if (this.CuentaNueva.origen.codigo == undefined) {
        this.alerta("Selecciones un Origen", "error");
        this.CuentaNueva.origen = "";
        return;
      }

    },






    CbFilCuenta(e) {

      if (e.target.value.length == 1) {

        if (e.target.value.charCodeAt(0) >= 97 & e.target.value.charCodeAt(0) <= 122) {

          //console.log("ok letra")

          this.RfilCuenta = "descripcion"


          this.CargarRcuenta()

        } else { this.RfilCuenta = "cuenta" }



        if (e.target.value.charCodeAt(0) >= 48 & e.target.value.charCodeAt(0) <= 57) {
          this.CargarRcuenta()
          //console.log("ok numero")
          this.RfilCuenta = "cuenta"

        } else { this.RfilCuenta = "descripcion" }

        //numero 48 a 57
        //letras 97 a 122 

      }

    },


    SelctCbcuentaNuevaDesc() {


      if (this.CuentaNueva.descargo == null) {
        this.CuentaNueva.descargo = "";
      }

      if (this.CuentaNueva.descargo.cuenta == undefined) {
        this.alerta("Selecciones un tipo Cuenta", "error");
        this.CuentaNueva.descargo = "";
        return;
      }

    },

    CargarRcuenta() {
      this.$axios.post(this.$hostname + this.$hName + '/services/CuentaCatalogo/findall', {}).then(res => {
        this.Rcuenta = res.data;
      });
    },



    SelctCbCuentaNuevaDescValidControl() {

      if (this.CuentaNueva.descargo != null) {

        if (this.CuentaNueva.descargo.tipo == 'DE') {
          this.alerta("No puede seleccionar una cuenta de detalle", "error");
          this.CuentaNueva.descargo = null;
        }

      }

    },






  }






}
</script>

<style>

</style>