<template @click="buscando">
  <aside v-show="isVisible" class="barra-busqueda">
    <v-card>
      <v-card-title>
        <!-- :class="`d-flex justify-center mb-6`" -->
        <center>
          <v-card-text style="font-size: 25px;" class="font-weight-bold">
            {{ nombre }}
          </v-card-text>
          <!-- <h5>{{ nombre }}</h5> -->
        </center> <br>
        <v-spacer></v-spacer>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-text-field autocomplete="off" v-on="on" v-model="search" @keyup="filtro" :label="SeachNombre" single-line
              hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>
          </template>
          <span>{{SeachNombre}}</span>
        </v-tooltip>
      </v-card-title>

      <v-data-table @click:row="obtenerDato" :items-per-page="paginator.itemsPerPage" @pagination="cargarMasdatos"
        :headers="headers" :items="list" :search="search" :server-items-length="limiteTotalRegistros"
        :footer-props="arrayPropiedases">
        <template v-slot:[slotname]="props">
          <div style="border-top:2px solid gray;">
            <v-list-item-title v-for="(sl, i) in slotlist" :key="i">

              <!-- <p v-if="sl.dt3==0"> 
          <b v-if="props.item[sl.dt0]==true && sl.dt4=='anulada'">Anulada</b>
          </p> -->

              <!-- Estado Activo----->
              <p v-if="sl.dt3==0">
                <b class="green--text" v-if="props.item[sl.dt0]==true">{{sl.dt4}}</b>
              </p>

              <!-- Estado anulado ----->
              <p v-if="sl.dt3==0.1">
                <b class="red--text" v-if="props.item[sl.dt0]==false">{{sl.dt4}}</b>
              </p>

              <!-- <p v-if="sl.dt4=='anulada'">
          <b v-if="props.item[sl.dt0]==false">Anulada</b>
          </p> -->

              <div v-if="sl.dt3 == 1 && sl.dt4!='anulada'">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ props.item[sl.dt0] }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">

                  <span v-if="props.item.hasOwnProperty(sl.dt0)">
                    <b>{{ sl.dt4 }}</b>
                    {{ props.item[sl.dt0][sl.dt1] }}
                  </span>
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>

                  {{ props.item[sl.dt0][sl.dt1][sl.dt2] }}

                </div>
              </div>
              <!-- parcia la fecha dia/mes/aaaa -->
              <div v-if="sl.dt3 == 2">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ fecFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ fecFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ fecFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 3">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ TelFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ TelFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ TelFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 4">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ cedFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ cedFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }}</b>
                  {{ cedFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 5">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }}</b>
                  {{ currencyFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null) & props.item.hasOwnProperty(sl.dt0) ">
                  <div v-if="props.item[sl.dt0].hasOwnProperty(sl.dt1)">
                    <b>{{ sl.dt4 }}</b>
                    {{ currencyFormatter(props.item[sl.dt0][sl.dt1]) }}
                  </div>
                </div>

                <div v-if="sl.dt2 != null & props.item.hasOwnProperty(sl.dt0)">
                  <b>{{ sl.dt4 }}</b>
                  {{ currencyFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>
            </v-list-item-title>
          </div>
          <div></div>
        </template>
      </v-data-table>
    </v-card>
  </aside>
</template>

<script>
// import axios from "axios";
import cuerpo from "../../src/components/cuerpo";
import { EventBus } from "@/event-bus.js";
export default {
  components: {
    cuerpo
  },
  data() {
    return {
      objFiltro: null,
      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" }
      ],
      limiteTotalRegistros: 0,
      json: {},
      paginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: true
      },
      isVisible: true,
      list: [],
      search: ""
    };
  },
  props: {
    headers: {
      required: true,
      type: Array
    },

    slotname: {
      required: false,
      type: String
    },

    url: {
      required: true,
      type: String
    },
    nombre: {
      required: true,
      type: String
    },

    SeachNombre: {
      required: true,
      type: String
    },

    slotlist: {
      required: false,
      type: Array
    },

    emitnombre: {
      required: true,
      type: String
    },

    urlcount: {
      required: false,
      type: String
    }
  },

  created() {

    this.filtro()
  },
  mounted: function () {
    EventBus.$on("actualizaBarraBusqueda2", this.filtro);
    EventBus.$on("BarraDinamLimitadaFiltro", this.Modalfiltro);
  },
  methods: {


    cargarMasdatos(e) {

      this.paginator = e

      if (this.paginator.itemsPerPage == -1) {

        this.paginator.itemsPerPage = 15

      }


      this.filtro()

      // this.json = {
      //   descripcion: this.search,
      //   ultimoRegistro: e.pageStop,
      //   paginacion: e.page,
      //   limite: this.paginator.itemsPerPage
      // };
      // this.paginator.pageStop = e.pageStop;
      // this.$axios.post(this.url, this.json).then(res => (this.list = res.data));
    },
    ver(e) {
      console.log(e);
    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },

    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    FormatoFecha(params0) {
      var params = new Date(params0).toISOString().substr(0, 10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    TelFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            "(" +
            params.substring(0, 3) +
            ")" +
            params.substring(3, 6) +
            "-" +
            params.substring(6, 10)
          );
        }
        return params;
      }
    },

    cedFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(0, 3) +
            "-" +
            params.substring(3, 7) +
            "-" +
            params.substring(7, 11)
          );
        }
      }
    },

    async filtro() {


      var obj = {
        datos: {},
        descripcion: this.search,
        ultimoRegistro: this.paginator.pageStart,
        paginacion: this.paginator.page,
        limite: this.paginator.itemsPerPage,
        usuarios: this.$CodeUser,
        objFiltro: JSON.parse(JSON.stringify(this.objFiltro)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }


      // await this.$axios.post(this.url, JSON.parse(JSON.stringify(obj)))
      // .then(res => {this.list = res.data;});


      var obj1 = "/null/null/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart
      console.log("ElLink==>" + this.url + obj1)

      var headers = this.$store.getters.GetheadersAxios
      await this.$axios.get(this.url + obj1, { headers: headers }).then(res => {
        console.log('Ver Datos'), console.log(res.data)

        // this.list =  JSON.parse(JSON.stringify(res.data)) ;
      });

      //this.limiteTotalRegistros = res.data.totalRegistros
    },


    async Modalfiltro(e) {



      if (e.opcion == 1) {

        this.objFiltro = JSON.parse(JSON.stringify(e))
        var obj = {
          datos: {},
          descripcion: '',
          ultimoRegistro: this.paginator.pageStart,
          paginacion: this.paginator.page,
          limite: this.paginator.itemsPerPage,
          usuarios: this.$CodeUser,
          objFiltro: JSON.parse(JSON.stringify(e)),

          usuario: JSON.parse(JSON.stringify(this.$user))
        }


        await this.$axios
          .post(e.url, JSON.parse(JSON.stringify(obj)))
          .then(res => {
            this.list = res.data;
          });


      } else {




        this.objFiltro = null
        this.search = ''

        this.list = []

        var pagi = {
          itemsLength: 0,
          itemsPerPage: 5,
          page: 1,
          pageCount: 0,
          pageStart: 0,
          pageStop: 0,




          // page:1,
          // itemsPerPage: 5,
          // pageStart: 1,
          // pageStop: 15,
          // pageCount: 10,
          // itemsLength: 5
        }


        this.cargarMasdatos(pagi)




        var _this = this;

        // this.filtro() , cargar

        setTimeout(function () { _this.cargarFiltro(e) }, 300);

        //setTimeout(this.cargarFiltro,200);


      }

    },


    async cargarFiltro(e) {
      if (e.urlcount != null) {

        var obj = {
          datos: {},
          usuario: JSON.parse(JSON.stringify(this.$user))
        }


        // await this.$axios.post(e.urlcount,JSON.parse(JSON.stringify(obj))

        //   //{descripcion: "0",
        //     // ultimoRegistro: 10,
        //     // paginacion: 1,
        //      //limite:25 }

        //   )
        //   .then(res => (this.limiteTotalRegistros = res.data.totalRegistros),this.filtro() );


        var headers = this.$store.getters.GetheadersAxios
        await this.$axios.get(e.urlcount, { headers: headers })
          .then(res => { this.limiteTotalRegistros = res.data.totalRegistros, this.filtro() });



      }
    },



    obtenerDato(e) {
      EventBus.$emit(this.emitnombre, e);
      //this.Rdata=e
      //this.RdataDetalle=e[this.slotlist]
    }

  }

};
</script>

<style>

</style>

