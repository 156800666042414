<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte Comisión por producto'"
  :descripcion="'Imprime las Comisión por producto.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesComisionProducto'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <!-- Formulario-Comisión por producto -->

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Comisión por producto </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="ComisionProducto.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


<!-------------------------------------------------- Sucursal -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Sucursal:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="ComisionProducto.sucursal"
            @focus="cargarSucursal"
            @blur="SelctCbSucursal"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
 </v-combobox>

      <v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Agrupar por departamento:"
              v-model="ComisionProducto.grupsDepartamento"
              @change="changeGrupDepartemento"
            >
          <template v-slot:label>
         <strong v-if="ComisionProducto.grupsDepartamento==true" style="color:#000000;">Agrupar por departamento: Si</strong>
         <strong v-if="ComisionProducto.grupsDepartamento==false" style="color:#000000;">Agrupar por departamento: No</strong>
        </template>   
     </v-switch>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="ComisionProducto.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


<!-- -----------------------------  Vendedor  -->
<v-combobox
                            autocomplete="off"
                            dense
                            outlined
                            label="Vendedor:"
                            :items="RVendedores"
                            item-text="nombre"
                            v-model="ComisionProducto.vendedor"
                            @focus="cargarVendedores"
                            @blur="SelctCbVendedor"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sign-language"></i>
                          </template>
                        </v-combobox>

                        <v-combobox
                        v-if="ComisionProducto.grupsDepartamento==true"
                        autocomplete="off"
                        dense
                        outlined
                        label="Departamento:"
                        :items="Rdepartamento"
                        item-text="descripcion"
                        v-model="ComisionProducto.departamento"
                        @focus="cargarDepartamento"
                        @blur="SelctCbDesc"
                        hide-details
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 30px;"
                            class="fa fa-building"
                          ></i>
                        </template>
                      </v-combobox>



</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
 ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="ComisionProducto"
/>



  </v-card>
 </v-container>
 <!-- fn Formulario-Comisión por producto-->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {
    var json = { 
      titulo: "Reporte Comisión por producto",
      descripcion: "Imprime las Comisión por producto.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [ 

        "ReportesComisionProductoNew",
        "ReportesComisionProductoSave",
        "ReportesComisionProductoRemove",
        "ReportesComisionProductoAnular",
        "ReportesComisionProductoPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  // mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesComisionProductoNew", this.new);
    // EventBus.$on("ReportesComisionProductoSave", this.save);
    // EventBus.$on("ReportesComisionProductoRemove", this.remove);
    // EventBus.$on("ReportesComisionProductoAnular", this.remove);
    // EventBus.$on("ReportesComisionProductoPrintList", this.imprimirList);
    EventBus.$on("emitReportesComisionProducto", this.datostabla);
   

    EventBus.$on("onResize", this.onResize);

 this.$refs.ModRepFilt.CrearObjecto=this.validarObjecto;

  },
    // fn mounted-carga de inicio

  data:()=>  ({

    
      Rpropietario:[],
      RVendedores:[],
      RCaja:[],
      RSucursal:[],
      Rdepartamento:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      ComisionProducto: {
        nombre:'REPORTE COMISIÓN POR PRODUCTO',
        UrlImprimir: '/reportes-pv/ImprimirListComisionProducto', 
        UrlExel: '/reportes-pv/ExcelListComisionProducto', 
        UrlCorreo: '/reportes-pv/CorreoListComisionProducto',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        sucursal:null,
        vendedor:null,
        grupsDepartamento:false,
        departamento:null
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },
EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {

      this.ComisionProducto = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() {},
    eliminar() {},
    anular() {},
    imprimirList() {},
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(){},

   // Creación de una cláusula where para una consulta.
    validarObjecto(){

      var fecdesde=this.ComisionProducto.desde
      var fechasta=this.ComisionProducto.hasta

var where="fv.secuencia>0"
where+=` and fv.fecha >= ' ${fecdesde} ' and fv.fecha  <=' ${fechasta} '`

if(this.ComisionProducto.sucursal!=null){ 
  where+= `and sc.codigo= ${this.ComisionProducto.sucursal.codigo}` 
  this.ComisionProducto.ssucursal = JSON.parse(JSON.stringify(this.ComisionProducto.sucursal.descripcion.trim()))
  }else{ delete this.ComisionProducto["ssucursal"]}
//this.ComisionProducto
if(this.ComisionProducto.vendedor!=null){ 
  where+= `and vd.codigo= ${this.ComisionProducto.vendedor.codigo}`
  this.ComisionProducto.nombrevendedor=this.ComisionProducto.vendedor.nombre.trim()
}else{
  this.ComisionProducto.nombrevendedor=null
}


if(this.ComisionProducto.grupsDepartamento==true){ 
  if(this.ComisionProducto.departamento!=null){
    where+= `and dp.codigo= ${this.ComisionProducto.departamento.codigo}`
   }
  this.ComisionProducto.nombre='REPORTE COMISIÓN POR PRODUCTO VENTA DEPARTAMENTO'
  //this.ComisionProducto.departamento = JSON.parse(JSON.stringify(this.ComisionProducto.departamento.descripcion.trim()))
  }else{
    this.ComisionProducto.nombre='REPORTE COMISIÓN POR PRODUCTO'
  }




this.ComisionProducto.where=where

},


    newwnuevo() {
      (this.ComisionProducto = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


// Un método que se llama cuando el campo de entrada pierde el foco y valida si es nulo el compo
//si lo es coloca la fecha actual.
BlurDesde(){
if(this.ComisionProducto.desde.toString().length==0){
  this.ComisionProducto.desde=this.fechaNueva()
}
 },

// Un método que se llama cuando el campo de entrada pierde el foco y valida si es nulo el compo
//si lo es coloca la fecha actual.
 BlurHasta(){
if(this.ComisionProducto.hasta.toString().length==0){
  this.ComisionProducto.hasta=this.fechaNueva()
}
 },

// Realizar una solicitud GET al servidor y devolver los datos al Vue HTML.
cargarSucursal(){

this.$axios.get(this.$hostname+this.$hName+'/sucursal/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RSucursal=res.data))

},

// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbSucursal(){


  var _this = this;
setTimeout(function () {
  if (_this.ComisionProducto.sucursal == null) {
    _this.ComisionProducto.sucursal = "";
  }

  if (!_this.ComisionProducto.sucursal.hasOwnProperty('codigo')) {
    _this.ComisionProducto.sucursal = "";
    _this.ComisionProducto.sucursal = null;
    return;
  }
}, 300);



  /*if(this.ComisionProducto.sucursal==null){

    this.ComisionProducto.sucursal=''
  }

if(this.ComisionProducto.sucursal.codigo==undefined){

this.alerta("Seleccione un sucursal", "error");
this.ComisionProducto.sucursal=''
return

}*/

},


cargarVendedores() {
      let headers = this.$store.getters.GetheadersAxios
      this.$axios
          .get(this.$hostname + this.$hName + '/vendedores/findallactiv', {headers})
          .then(res => (this.RVendedores = res.data))
    },


    SelctCbVendedor() {
var _this = this;
setTimeout(function () {
  if (_this.ComisionProducto.vendedor == null) {
    _this.ComisionProducto.vendedor = "";
  }

  if (!_this.ComisionProducto.vendedor.hasOwnProperty('codigo')) {
    _this.ComisionProducto.vendedor = "";
    _this.ComisionProducto.vendedor = null;
    return;
  }
}, 300);

},


changeGrupDepartemento(e){

if(e==false){
  this.ComisionProducto.departamento=null
}
},


cargarDepartamento(){


this.$axios
          .get(
              this.$hostname + this.$hName + "/departamento/activo",
              {
                headers: this.$store.getters.GetheadersAxios
              }
          ).then(res => this.Rdepartamento = res.data)
          .catch(error => {
            if (error.response) {
              this.alerta(error.response.request.responseText, 'error')
            } else {
              this.alerta(error, 'error')
            }
          });

},


 SelctCbDesc() {
      // producto:null,
      //     departamento:null,


      var _this = this;
setTimeout(function () {
  if (_this.ComisionProducto.departamento == null) {
    _this.ComisionProducto.departamento = "";
  }

  if (!_this.ComisionProducto.departamento.hasOwnProperty('codigo')) {
    _this.ComisionProducto.departamento = "";
    _this.ComisionProducto.departamento = null;
    _this.alerta("Seleccione un departamento", "error");
    return;
  }
}, 300);

    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
