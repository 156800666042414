<template>
  <div>

    <v-card>

      <v-toolbar flat color="indigo">

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> CREAR PRODUCTOS </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="cerrarModalCrearProd()">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-container>

        <v-form ref="formCrearProd" v-model="valid" lazy-validation>

          <v-row>
            <!-- Columna 1 ------------------------------------------->
            <v-col cols="12" md="4" sm="4">

              <!----------------------------------------------  Nombre -->
              <v-text-field dense outlined label="Nombre*" v-model="form.crearProd.nombre" autocomplete="off"
                :rules="[rules.required]">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>
              <!----------------------------------------------  fabricante -->
              <v-text-field dense outlined label="fabricante*" v-model="form.crearProd.nombre" autocomplete="off"
                :rules="[rules.required]">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>


              <!----------------------------------------------  Costo -->
              <AutoNumeric label="Costo *:" dense outlined autocomplete="off" v-model.number="form.crearProd.costo">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                </template>
              </AutoNumeric>

              <!------------------------------------------------ Unidad compra -->
              <v-switch v-model="form.crearProd.unidadCompra" label="Unidad compra" color="success" hide-detail>
              </v-switch>

            </v-col>
            <!-- fn Columna 1 ------------------------------------------->

            <!-- Columna 2 ------------------------------------------->
            <v-col cols="12" md="4" sm="4">
              <!----------------------------------------------  unidad -->
              <v-text-field dense outlined label="Unidad*" v-model="form.crearProd.nombre" autocomplete="off"
                :rules="[rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>

              <!----------------------------------------------  Departamento -->
              <v-combobox autocomplete="off" dense outlined label="Departamento:" :items="Rdepartamento"
                item-text="descripcion" :rules="[rules.required]" v-model="form.crearProd.departamento"
                @focus="cargarDepartamento" @blur="SelctCbDesc">
                <template v-slot:prepend>
                  <i style="font-size: 30px;" class="fa fa-building"></i>
                </template>
              </v-combobox>

              <!----------------------------------------------  Beneficio -->
              <!-- @keyup="DSkeyuBenefic" -->
              <v-text-field v-model.number="form.crearProd.beneficio" type="number" :rules="[rules.porciento]"
                label="Beneficio" outlined dense>
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-percent"></i>
                </template>
              </v-text-field>

              <!------------------------------------------------ Unidad Base -->

              <v-switch v-model="form.crearProd.unidadBase" label="Unidad Base" color="success" hide-detail></v-switch>

            </v-col>
            <!-- fn Columna 2 ------------------------------------------->



            <!-- Columna 3  ------------------------------------------->
            <v-col cols="12" md="4" sm="4">
              <!----------------------------------------------  Factor -->
              <AutoNumeric label="Factor:" dense outlined autocomplete="off" v-model.number="form.crearProd.factor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                </template>
              </AutoNumeric>

              <!----------------------------------------------  codigo de barra -->
              <v-text-field dense outlined label="Código de barra" v-model="form.crearProd.nombre" autocomplete="off"
                :rules="[rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>

              <!----------------------------------------------  Costo -->
              <AutoNumeric label="Precio *:" dense outlined autocomplete="off" v-model.number="form.crearProd.precio"
                :rules="[rules.required]">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                </template>
              </AutoNumeric>


              <!----------------------------------------------  impuesto -->
              <v-text-field dense outlined label="Impuesto*" v-model="form.crearProd.nombre" autocomplete="off"
                :rules="[rules.required]">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-font"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- fn Columna 3 ------------------------------------------->


          </v-row>
        </v-form>
        <hr />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="">

            <v-btn dark color="green darken-1" @click.prevent="CrearProducto">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>


      </v-container>
    </v-card>


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
export default {

  components: {
    AutoNumeric
  },
  mounted() {

  },

  created() {

  },

  data() {

    return {

      Rdepartamento: [],

      valid: true,


      form: {

        crearProd: {
          nombre: null,
          factor: 0,
          departamento: null,
          costo: 0,
          beneficio: 0,
          precio: 0,
          unidadCompra: false,
          unidadBase: false
        }


      },



      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },


      aalert: {
        estado: false,
        color: null
      },




    }

  },


  methods: {

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.new();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },





    NuevoProducto() {

      this.form.crearProd = {
        nombre: null,
        factor: 0,
        departamento: null,
        costo: 0,
        beneficio: 0,
        precio: 0,
        unidadCompra: false,
        unidadBase: false
      }

    },



    CrearProducto() {
      if (this.$refs.formCrearProd.validate()) {


        //this.$axios.post(this.hostname+this.hName + '/services/ConciliacionARS/Save',
        // this.$axios.post(this.hostname+this.hName+'/services/proyectos/save',
        //             JSON.parse(JSON.stringify(this.form.NewProyecto)))
        //              .then(res => {this.alerta("Dato guardado correctamente", "bien"),  this.form.datosBasicos.proyecto=null, this.dialogProyect=!this.dialogProyect})
        //              .catch(error => this.alerta(error,'error')) 

      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }


    },

    cerrarModalCrearProd() {

      this.NuevoProducto()


      var Objt = { estado: false }

      this.$emit("EmitCrearProducto", Objt);


    },


    DSkeyuBenefic(e) {
      if (e.target.value.length != 0) {
        this.form.crearProd.beneficio = parseFloat(e.target.value);

      } else {
        this.form.crearProd.beneficio = 0
      }
    },

    cargarDepartamento() {


      this.$axios.post(this.$hostname + this.$hName + "/services/Especiales/getAllDepartamento", {})
        .then(res => (this.Rdepartamento = res.data));


    },

    SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.form.crearProd.departamento == null) {
        this.form.crearProd.departamento = "";
      }

      if (this.form.crearProd.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.form.crearProd.departamento = "";
        return;
      }
    },




  }




}
</script>

<style>

</style>