<template>
  
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}

  <!-- tooltip -->
  <v-col class="pa-0 ma-0 py-0 my-0 "  style="background-color: #dd4b39" cols="12" md="12" sm="12">
    <v-row no-gutters > 
      <v-col class="pa-0 ma-0 py-0 my-0 pt-1"   cols="12" md="9" sm="8"  >
        <span :class="`d-flex justify-start white--text`">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
          <i v-on="on" style="font-size: 20px;" class="fa fa-info-circle px-2"> </i><b v-on="on">Devolución</b>
        </template>
           <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Devolución: </span> Devolución</i>
          </v-tooltip>
        </span>   
      </v-col>

      <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="3" sm="4"  >
        <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarFacturaSeleccionada()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>
            </v-btn-toggle>

          </v-card-actions>
        <!-- <v-btn  color="grey darken-4" fab x-small @click="cancelarCierreCaja()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn> -->
      </v-col>
      

    </v-row> 
  </v-col>
  <!-- fn tooltip -->

  <!-- Contenido -->
<v-col class="pa-0 ma-0 py-0 my-0 pt-2 px-2"   cols="12" md="12" sm="12">
  <v-card color="grey lighten-3">

<v-form ref="formDevolucion" @submit.prevent="" v-model="valid" lazy-validation>
  <v-row>

    <v-col cols="12" md="4" sm="4" class="py-0 my-0">
      <v-tooltip top>
        <template v-slot:activator="{ on }">

          <v-text-field v-on="on" label="# Digite Número Factura:" dense autocomplete="off" outlined
            type="number" v-model.number="DevolucionBusqueda.secuencia"
            @keydown.enter="buscarFacturaDevolucion" style="font-size: 12px;">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-hashtag"></i>
            </template>
          </v-text-field>


        </template>
        <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
          <span> Aquí se registra el número de factura que deseamos búscar.</span>
        </i>
      </v-tooltip>
    </v-col>
  </v-row>
</v-form>

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
  <div style="border: 1px solid #000000;">

    <!-- fn Tabla Busqueda Devolucion -->
    <div v-if="DevolucionFacturaSeleccionada.secuencia == 0">
      <v-data-table class="RowSelectColor" :single-select="true" item-key="indx" :show-select="true"
        v-model="DevolucionSelected" @item-selected="DevolucionObtenerDatoSelect"
        @click:row="DevolucionDatostabla" :headers="[
          { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
          { text: '# Secuencia', sortable: false, value: 'sec', width: 129 },
          { text: 'Cliente', sortable: false, value: 'clinica.nombre', width: 129 },
          { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
        ]" dense :items="DevolucionlistFactCliente">

        <template v-slot:item.fecha="props">
          <span style="font-size:13px; display:none;">{{ props.item.indx =
          DevolucionlistFactCliente.indexOf(props.item)
          }}</span>
          <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
        </template>

        <template v-slot:item.facturasventaPK.secuencia="props">
          <span style="font-size:13px;">{{ props.item.facturasventaPK.secuencia }} </span>
        </template>

        <template v-slot:item.sec="props">
          <span style="font-size:13px;">{{ props.item.documentoFactura.nombrecorto.trim() }}-{{
            props.item.facturasventaPK.secuencia }} </span>
        </template>

        <template v-slot:item.clinica.nombre="props">
          <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
          </span>
        </template>

        <template v-slot:item.valor="props">
          <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
        </template>
      </v-data-table>
    </div>
    <!-- fn Tabla Busqueda Devolucion -->

    <!-- Devolucion Selecionada -->
    <v-col v-if="DevolucionFacturaSeleccionada.secuencia > 0" style="border: 1px solid #000000;"
      class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
      <v-row>
        <v-col cols="12" md="8" sm="8">
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA #: </b>
            {{ DevolucionFacturaSeleccionada.secuencia }}</p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
            {{ FormatoFecha(DevolucionFacturaSeleccionada.fecha) }}</p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NCF : </b>
            {{ DevolucionFacturaSeleccionada.ncf }}</p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE : </b>
            {{ DevolucionFacturaSeleccionada.cliente }}</p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR : </b>
            {{ currencyFormatter(DevolucionFacturaSeleccionada.valortotal) }}</p>
          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FORMA DE PAGO : </b>
            {{ DevolucionFacturaSeleccionada.formapago }}</p>

          <v-textarea autocomplete="off" label="comentario:" rows="2" dense outlined
            v-model="DevolucionFacturaSeleccionada.comentario" hide-details>
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-commenting"></i>
            </template>
          </v-textarea>
          <v-row>
            <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <v-checkbox
              @change="changeDevolucionEfectivo"
                v-if="DevolucionFacturaSeleccionada.Pagoefec > 0 && DevolucionFacturaSeleccionada.cheques == 0 &&
                  DevolucionFacturaSeleccionada.cupones == 0 && DevolucionFacturaSeleccionada.cxc == 0 &&
                  DevolucionFacturaSeleccionada.puntos == 0 && DevolucionFacturaSeleccionada.tarjeta == 0 &&
                  DevolucionFacturaSeleccionada.valorNc == 0 && DevolucionFacturaSeleccionada.transferencia == 0"
                class="mt-0 ma-3" v-model="DevolucionFacturaSeleccionada.efectivo" label="" color="teal "
                hide-details>
                <template v-slot:label>
                  <strong v-if="DevolucionFacturaSeleccionada.efectivo == true"
                    style="color:#000000; font-size:13px;">Devolucion en Efectivo: Si</strong>
                  <strong v-if="DevolucionFacturaSeleccionada.efectivo == false"
                    style="color:#000000; font-size:13px;">Devolucion en Efectivo: No</strong>
                </template>
              </v-checkbox>
            </v-col>


            <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <v-checkbox @change="DevolucionValorTotal()" class="mt-0 ma-3"
                v-model="DevolucionFacturaSeleccionada.quitaritbis" label="" color="teal " hide-details>
                <template v-slot:label>
                  <strong v-if="DevolucionFacturaSeleccionada.quitaritbis == true"
                    style="color:#000000; font-size:13px;">Quitar itbis: Si</strong>
                  <strong v-if="DevolucionFacturaSeleccionada.quitaritbis == false"
                    style="color:#000000; font-size:13px;">Quitar itbis: No</strong>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12" md="4" sm="4">

          <br>
          <v-hover v-slot:default="{ hover }" close-delay="200">

            <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
              :color="hover ? 'red darken-3' : 'red'" v-ripple="{ class: `white--text` }"
              @click.prevent="NewDevolucionFacturaSeleccionada">

              <h2>CANCELAR</h2>

            </v-btn>
          </v-hover>

          <v-hover v-slot:default="{ hover }" close-delay="200">

            <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
              :color="hover ? 'blue darken-3' : 'blue'" v-ripple="{ class: `white--text` }"
              @click.prevent="openAceptarDevolucion">

              <h2>FINALIZAR</h2>

            </v-btn>
          </v-hover>


        </v-col>
      </v-row>
      <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
      <p style="border: 1px dashed  #616161;">
        <strong style="color:#000000; font-size:13px;">El valor de la devolucion sera RD$:
          {{ DevolucionFacturaSeleccionada.valordevolucion }}</strong>
        <v-data-table item-key="indx" :show-select="true"
          v-model="DevolucionFacturaSeleccionada.SeletDetalleList" :headers="[
            { text: 'DESCRIPCIÓN', value: 'descripcion', sortable: false, width: 'auto', class: ['no-gutters black--text'] },
            { text: 'CANTIDAD', value: 'cantidad', sortable: false, width: 200, class: ['no-gutters black--text'] },
            { text: 'PRECIO', value: 'precio', sortable: false, width: 200, class: ['no-gutters black--text'] },
            { text: 'VALOR', value: 'valor', sortable: false, width: 200, class: ['no-gutters black--text'] },
          ]" dense :items="DevolucionFacturaSeleccionada.DetalleList" :items-per-page="15"
          @item-selected="SelcDevObtenerDatoSelect" @toggle-select-all="AllSelcDevObtenerDatoSelect">
          <template v-slot:item.descripcion="props">
            <span style="font-size:13px; display:none;">{{ props.item.indx =
            DevolucionFacturaSeleccionada.DetalleList.indexOf(props.item)
            }}</span>
            <span style="font-size:13px;">{{ props.item.descripcion }}</span>
          </template>

          <template v-slot:item.cantidad="props">

            <!-- <span style="font-size:13px; display:none;">{{props.item.cantidad = props.item.cantidadPendiente}}</span> -->

            <VueAutonumeric label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
              v-model.number="props.item.cantidad" v-on:blur="DevolucionValorTotal()"
              @blur="DevolucionVerificaCantidad(props.item)">
            </VueAutonumeric>
          </template>

          <template v-slot:item.precio="props">
            <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span>
          </template>

          <template v-slot:item.valor="props">

            <span style="font-size:13px;">{{ currencyFormatter(DevolucionValor(props.item)) }}</span>



          </template>

        </v-data-table>
        <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->
      </p>


    </v-col>
    <!-- fn devolucion Selecionada -->

  </div>
</v-col>

</v-card>
</v-col>
<!-- fn Contenido-->

<!-- ------------------------------------------------------ Modal- Aceptar Devolucion-->
<v-dialog transition="fab-transition" label="Modal Aceptar Devolucion" v-model="dialogAceptarDevolucion" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Aceptar Devolucion ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Información
                devolución</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Información devolución: </span>
              cliente y cedula </i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarAceptarDevolucion()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewAceptarDevolucion()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>



              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarAceptarDevolucion()" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y finalizar devolución </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> FINALIZAR DEVOLUCIÓN </b>
                <v-form v-model="valid" ref="formAceptarDevolucion" lazy-validation>
                  <!-- Row 1      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">


                      <!-- ----------------------------- nombre -->

                      <v-text-field label="* Cliente:" autocomplete="off" dense outlined :rules="[$rules.required]"
                        v-model="AceptarDevolucion.nombre">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>


                      <v-text-field autocomplete="off" v-mask="MakCelRnc" label=" Cedula/RNC:" dense outlined
                        maxlength="13" :rules="[$rules.RulesCelRncValidar]" v-model="AceptarDevolucion.cedula"
                        @blur="MakaraCelAceptarDevolucion" v-on:blur="validandoCedRnc(AceptarDevolucion.cedula)" @focus="MakCelRnc = '#############'">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                        </template>
                      </v-text-field>

                    </v-col>

                  </v-row>
                  <!-- Row 2 ------------------------------------------->

                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Aceptar Devolucion -->


<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>



</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant, fechaNueva,getParamFecha,RedondeoValor,
  validaCedRnc } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 
            console.log('created--PVCONFIGU')
  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    windowSize: { width: "auto", height: "auto" },
    MakCelRnc: "#############",
    valid: true,
    DevolucionSelected: [],
    DevolucionlistFactCliente: [],
    DevolucionBusqueda: {
      secuencia: 0,
    },
    devolucion: {
      devoclucionPK: { documento: 0, secuencia: 0 },
      fecha: new Date(),
      referencia: "",
      valor: 0,
      usuario: "",
      ncf: "",
      caja: 0,
      numerocierre: 0,
      efectivo: false,
      cobertura: 0,
      fechaImpresion: null,
      nif: "",
      horaImpresion: null,
      comentario: "",
      borrado: false,
      devolucionDetalleList: [],
      facturasventa: null,
      nombre: "",
      cedula: "",
      notacreditocontado: null,
      notacreditoclientes: null,
      documentoinventario: null,
      almacen: null,
    },
    DevolucionFacturaSeleccionada: {
      secuencia: 0,
      fecha: null,
      ncf: "",
      cliente: "",
      comentario: "",
      turno: 0,
      formapago: "",
      DetalleList: [],
    },

    dialogAceptarDevolucion: false,
    AceptarDevolucion: {
      nombre: null,
      cedula: "",
    },

    CierresCajas:{
      cierrescajasPK: { caja: 0, secuencia: 0 }
    },
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),

  props: { 

cajaSelect: {
  required: false,
  type: Object,
  default: null
},

configuraciones: {
      required: false,
      type: Object,
      default: null
    },

},
  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant, fechaNueva,getParamFecha,RedondeoValor,
  validaCedRnc,
// buscando la ultimasecuencia del cierre
  async getUltimocierre(){
    var _this = this;
      this.dialogCierreCaja = true;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.cajaSelect.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
          this.CierresCajas.cierrescajasPK.caja= this.cajaSelect.codigo
        });   
  },


     // Hacer una llamada API al servidor y obtiene un lista de facturas.
    getBuscarFacturaDevolucion(){
      this.DevolucionBusqueda.secuencia = 0;
      this.DevolucionSelected = [];
      this.DevolucionlistFactCliente = [];
      // this.dialogDevolucion = true;
      var fech = this.fechaNueva();

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/devolucion/" +
          fech +
          "/facturasFecha/" +
          this.cajaSelect.sucursal.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.DevolucionlistFactCliente = res.data;
        });

    },

    // Hacer una llamada axios al backend y devolver una lista de objetos de factura.
    buscarFacturaDevolucion(e) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/devolucion/" +
          e.target.value +
          "/facturas/" +
          this.cajaSelect.sucursal.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.DevolucionlistFactCliente = res.data;
        });
    },

    // Creación de un nuevo objeto con las propiedades del objeto DevolucionFacturaSeleccionada.
    NewDevolucionFacturaSeleccionada() {
      this.DevolucionFacturaSeleccionada = {
        secuencia: 0,
        fecha: null,
        ncf: "",
        cliente: "",
        comentario: "",
        turno: 0,
        formapago: "",
        DetalleList: [],
      };
      this.loading(false)
    },

    cancelarFacturaSeleccionada() {
      this.NewDevolucionFacturaSeleccionada();
      this.dialogDevolucion = false
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarDevolucion", false)
     }, 300);
    },


    DevolucionValor(e) {
      //DevolucionFacturaSeleccionada.DetalleList
      //this.RedondeoBasico(this.Facturasventa.tarjeta)
      //this.DevolucionFacturaSeleccionada.quitaritbis
      var itbisTasa = 0.00;
      var itbis = 0.00;
      var descuento = 0.00;
      var descuentoValor = 0.00;
      var precioSinItbis = 0.00;
      var valor = 0.00;
      console.log(valor)
      if (e.productos.impuesto != null) {
        itbisTasa = e.productos.impuesto.tasa;
      }
      if (e.valordescuento > 0) {
          var DESCUENTOVALOR= this.devolucionDescuento(e)
        descuentoValor = this.RedondeoValor(DESCUENTOVALOR / e.cantidad);
      }
      if (e.descuento > 0) {
        if (itbisTasa > 0) {
          if (e.productos.incluirimpuestoenprecio) {
            descuento = e.cantidad * this.RedondeoValor(((e.precio / (1 + (itbisTasa / 100))) * (e.descuento / 100)));
            precioSinItbis = e.cantidad * this.RedondeoValor((e.precio / (1 + (itbisTasa / 100))));
            itbis = this.RedondeoValor((precioSinItbis - descuento) * (itbisTasa / 100));
            if (this.DevolucionFacturaSeleccionada.quitaritbis) {
              itbis = 0;
            }
            valor = (precioSinItbis - descuento) + itbis;
          } else {
            descuento = e.cantidad * this.RedondeoValor((e.precio * (e.descuento / 100)));
            precioSinItbis = e.cantidad * this.RedondeoValor(e.precio);
            itbis = this.RedondeoValor((precioSinItbis - descuento) * (itbisTasa / 100));
            if (this.DevolucionFacturaSeleccionada.quitaritbis) {
              itbis = 0;
            }
            valor = (precioSinItbis - descuento) + itbis;
          }
        } else {
          if (descuentoValor > 0) {
            valor = (e.cantidad * e.precio) - (e.cantidad * descuentoValor);
          } else {
            valor = e.cantidad * (e.precio) * (e.descuento / 100);
          }
        }
      } else {
        if (this.DevolucionFacturaSeleccionada.quitaritbis) {
          if (e.valorimpuesto > 0) {
            valor = e.cantidad * (e.precio - e.valorimpuesto);
          } else {
            valor = e.cantidad * (e.precio);
          }
        } else {
          valor = e.cantidad * e.precio;
        }
      }
      e.valor = valor;
      return e.valor;
    },
    DevolucionValorTotal() {

this.DevolucionFacturaSeleccionada.valordevolucion = 0
if (this.DevolucionFacturaSeleccionada.SeletDetalleList.length > 0) {

  this.DevolucionFacturaSeleccionada.SeletDetalleList.forEach(element => {
    this.DevolucionFacturaSeleccionada.valordevolucion += element.valor;
  });

}

   },

    DevolucionObtenerDatoSelect(e) {
      this.DevolucionDatostabla(e.item);
    },

    DevolucionDatostabla(e) {
      console.log('--DevolucionDatostabla--')
      console.log(e)
      this.DevolucionSelected = [];
      var objjt = {
        factcaja:e.caja,
        valordevolucion: 0,
        valortotal: e.valor,
        secuencia: e.facturasventaPK.secuencia,
        fecha: new Date(e.fecha).toISOString().substr(0, 10),
        ncf: e.ncf,
        cliente: e.cliente == null ? "" : e.cliente.nombre,
        cedula: e.cliente != null ? e.cliente.cedrnc : "",
        comentario: "",
        turno: e.turno,
        formapago: "",
        DetalleList: [],
        SeletDetalleList: [],
        efectivo: false,
        quitaritbis: false,
        cheques:
          e.hasOwnProperty("cheques") && e.cheques != null ? e.cheques : 0,
        cupones:
          e.hasOwnProperty("cupones") && e.cupones != null ? e.cupones : 0,
        cxc: e.hasOwnProperty("cxc") && e.cupones != null ? e.cxc : 0,
        Pagoefec:
          e.hasOwnProperty("efectivo") && e.cupones != null ? e.efectivo : 0,
        puntos: e.hasOwnProperty("puntos") && e.puntos != null ? e.puntos : 0,
        tarjeta:
          e.hasOwnProperty("tarjeta") && e.tarjeta != null ? e.tarjeta : 0,
        valorNc:
          e.hasOwnProperty("valorNc") && e.valorNc != null ? e.valorNc : 0,
        transferencia:
          e.hasOwnProperty("transferencia") && e.transferencia != null
            ? e.transferencia
            : 0,
      };
      //DetalleList:e.facturasventaDetalleList,

      e.facturasventaDetalleList.forEach((element) => {
        element.cantidad = element.cantidadPendiente;
        objjt.DetalleList.push(element);
      });

      this.DevolucionSelected.push(e);
      this.DevolucionFacturaSeleccionada = objjt;
    },


    changeDevolucionEfectivo(){
  var _this=this;
setTimeout(function(){ 
  if(_this.DevolucionFacturaSeleccionada.efectivo==true){
     var CODCAJASELC=_this.DevolucionFacturaSeleccionada.factcaja.codigo
     if(_this.cajaSelect.codigo!=CODCAJASELC){
      _this.DevolucionFacturaSeleccionada.efectivo=false 
      _this.alerta("Esta Factura no se realizo en esta caja, no puede hacer la devolucion en efectivo ", "error");
      
     }
  }
  
}, 300);

    },


    // Comprobando si el usuario ha seleccionado al menos un producto para devolver.
    openAceptarDevolucion() {
      if (this.DevolucionFacturaSeleccionada.SeletDetalleList.length == 0) {
        this.alerta("Seleccione al menos 1 producto", "error");
        return 0;
      }

      var ObjProRow = this.DevolucionFacturaSeleccionada.SeletDetalleList.filter((ee) => ee.cantidadPendiente == 0);

      if (ObjProRow.length > 0) {
        this.alerta("no puede hacer una devolución a un producto con la cantidad en 0", "error");
        return 0;
      }


      if (this.DevolucionFacturaSeleccionada.cliente.length == 0) {
        this.newnewAceptarDevolucion();
        this.dialogAceptarDevolucion = true;
      } else {
        this.AceptarDevolucion = {
          nombre: this.DevolucionFacturaSeleccionada.cliente,
          cedula: this.DevolucionFacturaSeleccionada.cedula,
        };
        this.FinalizarDevolucionFactura();
      }
    },


    // Cerrando el cuadro de diálogo del modal de la devolucion.
    cancelarAceptarDevolucion() {
      this.dialogAceptarDevolucion = false;
      this.newnewAceptarDevolucion();
      this.getBuscarFacturaDevolucion()
      this.NewDevolucionFacturaSeleccionada()
      this.DevolucionSelected=[]
    },

// Creando un nuevo objeto llamado AceptarDevolucion y asignándolo a la propiedad de datos del mismo
    // nombre.
    newnewAceptarDevolucion() {
     
      this.AceptarDevolucion = {
        nombre: null,
        cedula: "",
      };
    },

// Crear un nuevo objeto y asignarle valores.
    FinalizarDevolucionFactura() {
      console.log('FinalizarDevolucionFactura')
      //this.$store.getters.GetdatosInfUsu.usuario.nombre
      //this.$store.getters.GetdatosInfUsu.usuario.codigo
      var l = this.DevolucionSelected[0];
      delete l["horaFactura"]
      this.devolucion = {
        devoclucionPK: { documento: 0, secuencia: 0 },
        // fecha: new Date(),
        fecha: null,
        referencia: "",
        valor: 0.0,
        usuario: this.$store.getters.GetdatosInfUsu.usuario.codigo,
        ncf: "",
        caja: this.cajaSelect.codigo,
        numerocierre: this.CierresCajas.cierrescajasPK.secuencia,
        efectivo: this.DevolucionFacturaSeleccionada.efectivo,
        quitaritbis: this.DevolucionFacturaSeleccionada.quitaritbis,
        cobertura: null,
        fechaImpresion: null,
        nif: null,
        horaImpresion: null,
        comentario: this.DevolucionFacturaSeleccionada.comentario,
        borrado: false,
        devolucionDetalleList: [],
        facturasventa: l,
        nombre: this.AceptarDevolucion.nombre,
        cedula: this.AceptarDevolucion.cedula,
        notacreditocontado: null,
        notacreditoclientes: null,
        documentoinventario: null,
        almacen: this.cajaSelect.almacen,
        activo: true
      };


      this.devolucion.devolucionDetalleList = [];
      var bar = new Promise((resolve, reject) => {
        this.DevolucionFacturaSeleccionada.SeletDetalleList.forEach(async (d) => {
        this.detalleDevolucion(d);
        //this.devolucion.valor = this.devolucion.valor + d.cantidad * d.precio;
        this.devolucion.valor = this.devolucion.valor + d.valor;
        resolve()
      });
    
});

bar.then(() => {
  console.log('All done Devolucion arrays!')
  this.saveDevolucion();
});
   
    },


    // Agregar un nuevo objeto a la lista de devolucion detalle.
    async detalleDevolucion(datos) {
      var det = {
        cantidad: datos.cantidad,
        costo: datos.precio,
        codigo: 0,
        documentofactura: null,
        borrado: false,
        producto: datos.productos,
        unidad: datos.unidades,
      };
      this.devolucion.devolucionDetalleList.push(det);
    },

    // Envío de una solicitud POST al servidor guardando la devolucion.
    saveDevolucion() {
      if (this.devolucion.devolucionDetalleList.length > 0) {
        //this.devolucion.usuario=this.$store.getters.GetOtrasConfigPv.cajero
        console.log(this.devolucion);
        this.loading(true)
        this.$axios
          .post(
            this.$hostname + this.$hName + "/devolucion/save",
            this.devolucion,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {

            (this.devolucion = res.data), this.cancelarAceptarDevolucion();
            this.alerta("Devolucion salvada correctamente !!!", "bien");
            if (localStorage.printerSelect != null) {
              var msg = {
                accion: "print",
                formato: this.cajaSelect.formatoimpresioncontado.trim(),
                impresora: JSON.parse(localStorage.printerSelect).nombre,
                data: this.configuraciones,
                devolucion: res.data,
                caja: this.cajaSelect,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
              this.imprimir(msg, "devolucion");
            }
            // this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => {
            this.loading(false)
            if (error.response) {
              this.alerta(error.response.request.responseText, "error");
            } else {
              this.alerta(error, "error");
            }
          });
      }
    },

    DevolucionVerificaCantidad(e) {
      if (e.cantidad > e.cantidadPendiente) {
        e.cantidad = e.cantidadPendiente;
      }
      if (e.cantidad <= 0) {
        e.cantidad = e.cantidadPendiente;
      }
    },
    devolucionDescuento(ee){

var Cant = ee.cantidad
var PrecioVal = 0


if (this.ConfImpfiscal == true) {
  PrecioVal = ee.precio
} else {

  if (ee.productos.incluirimpuestoenprecio == true) {
    if (ee.productos.impuesto != null) {
      PrecioVal = (ee.precio) / (1 + (ee.productos.impuesto.tasa / 100))
    } else { PrecioVal = ee.precio }
  } else {

    PrecioVal = ee.precio
  }

}

// ee.DescValor = ((ee.descuento * (PrecioVal * Cant)) / 100)
var valordescuento = ((ee.descuento * (PrecioVal * Cant)) / 100)

return valordescuento;

    },


    // Validando el formulario y si es válido llamará al método FinalizarDevolucionFactura().
    SalvarAceptarDevolucion() {
      if (this.$refs.formAceptarDevolucion.validate()) {

        if(this.AceptarDevolucion.cedula!=null && this.AceptarDevolucion.cedula.length>0){ 
          var CEDULA = this.AceptarDevolucion.cedula.replace("-", "");
        if(this.validaCedRnc(CEDULA)==false){
          var NOM= CEDULA.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return 0;
        }
        }

        this.FinalizarDevolucionFactura();
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },

    MakaraCelAceptarDevolucion() {
      if (this.AceptarDevolucion.cedula.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }

      if (this.AceptarDevolucion.cedula.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },
// Envío de una solicitud POST al servidor con los datos a imprimir.
imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.NewDevolucionFacturaSeleccionada();
          })
          .catch((error) => {this.NewDevolucionFacturaSeleccionada();});
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.NewDevolucionFacturaSeleccionada();
          })
          .catch((error) => {this.NewDevolucionFacturaSeleccionada()});
      }
    },

AllSelcDevObtenerDatoSelect(e) {

if (e.value == true) {

  var _this = this;
  setTimeout(function () {

    _this.DevolucionFacturaSeleccionada.SeletDetalleList.forEach(element => {

      if (element.cantidadPendiente == 0) {
        const index = _this.DevolucionFacturaSeleccionada.SeletDetalleList.indexOf(element);
        _this.DevolucionFacturaSeleccionada.SeletDetalleList.splice(index, 1);
        _this.alerta("no puede hacer una devolución a un producto con la cantidad en 0", "error");
      }

    });
  }, 200);

}

var _this = this;
setTimeout(function () { _this.DevolucionValorTotal() }, 400);

},


SelcDevObtenerDatoSelect(e) {
// @item-selected="DevolucionObtenerDatoSelect"
if (e.value == true) {
  if (e.item.cantidadPendiente == 0) {
    var _this = this;
    setTimeout(function () {
      const index = _this.DevolucionFacturaSeleccionada.SeletDetalleList.indexOf(e.item);
      _this.DevolucionFacturaSeleccionada.SeletDetalleList.splice(index, 1);
    }, 200);
    this.alerta("no puede hacer una devolución a un producto con la cantidad en 0", "error");

  }


}

var _this = this;
setTimeout(function () { _this.DevolucionValorTotal() }, 400);
},


    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>