<template>
<div v-bind:style="{ 'position': 'absolute' , 'border': '2px solid #FFFFFF' , 'width':'100%','height':this.windowSize.height-10+'px' }" >
<!-- columna general 1 #F44336-->
<v-col cols="12" md="12" sm="12">{{ActDescBtn}}
  <v-toolbar-title  class="d-flex justify-center pa-0 ma-0 py-0 my-0" >{{ InforCalendario}}</v-toolbar-title>
<v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            HOY
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
<i  style="font-size: 20px;" class="fa fa-arrow-left"></i>

          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
           <i  style="font-size: 20px; " class="fa fa-arrow-right"></i>
          </v-btn>
          <!-- color:white; <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title> -->
          <v-spacer></v-spacer>

<v-col cols="4" md="4" sm="4" class="pa-0 ma-0 py-0 my-0 px-2 mt-7">
<v-radio-group class="py-0" @change="CargarOrdenesRadio" v-model="opcion" row>     
                  <!-- <v-radio label="RUTINARIOS" color="green" :value="1"></v-radio>
                  <v-radio label="NO RUTINARIOS" color="green" :value="2"></v-radio>-->
                  <v-radio label="TODOS" color="green" :value="1"></v-radio>
                  <v-radio label="PENDIENTE" color="green" :value="2"></v-radio>                                       
                  <v-radio label="REALIZADO" color="green" :value="3"></v-radio>  
                  <!-- <v-radio label="CANCELADO" color="green" :value="5"></v-radio> -->
                  
</v-radio-group>
</v-col>

   <v-col cols="3" md="2" sm="2" class="pa-0 ma-0 py-0 my-0 px-2  mt-7"  >

   <v-select
                            v-model="mont"
                            label="Mes:"
                            outlined
                            dense
                            autocomplete="off"
                            :items="LabMes"
                            item-text="mes"
                            @change="ChangCalendar"
                        >
                        </v-select>
  
</v-col>

<v-col cols="3" md="2" sm="2" class="pa-0 ma-0 py-0 my-0 px-2  mt-7"  >
  <v-text-field
          
            label="Año:"
            dense
            outlined
            autocomplete="off"
         
          @keyup="KeyUpCantidad"
            v-model.number="year"
            @blur="ChangCalendar"
          >
          </v-text-field>
</v-col>

          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
              
              <span v-if="typeToLabel[type]=='Month'">MES</span>

                <span v-if="typeToLabel[type]=='Day'" >DÍA</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>DÍA</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item> -->
              <v-list-item @click="type = 'month'">
                <v-list-item-title>MES</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="420" >
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange" 
          locale="me"
          :weekdays="[1,2,3,4,5,6,0]"
          eventMoreText="ver ordenes"
        > 
      
     <!-- hide-header     -->
        </v-calendar>

<!-- ------------------------------------------------------ Modal- Evento orden seleccionado -->
<v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="selectedOpen" persistent fullscreen hide-overlay>
<v-card>
<div>
 
 <v-toolbar dark :color="selectedEvent.color" dense>
<v-toolbar-title > <i style="font-size: 20px;" class="fa fa-info-circle"> </i> {{selectedEvent.name}}</v-toolbar-title>
            <v-spacer></v-spacer>

<v-card-actions >     
<v-btn
          color="grey darken-4"
          fab
          small
          @click="CerrarSelectOrdeComponent"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>

 </v-card-actions>

    </v-toolbar> 
 
<v-col cols="12" md="12" sm="12" >
<v-card flat>
<CrearOrdenes @EmitOrdenNuevo="selectedOpen=false,CargarOrdenesRadio()" ref="RefComponOrden"/>

<!-- {{selectedEvent}} -->
</v-card > 
</v-col>



</div>
</v-card>
</v-dialog>


        
      </v-sheet>
    </v-col>
  </v-row>

</v-col>
</div>
</template>

<script>
import VueAutonumeric from "../../components/AutoNumericNuevo";

import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
import CrearOrdenes from "@/EventoMantenimiento/Mant/CrearOrdenes.vue";
export default {

   components: {
    VueAutonumeric,
    ContenidoBase,
    CrearOrdenes
  },

mounted() {

this.$refs.calendar.checkChange()
  },

   created(){
  },
  // destroyed(){
  //   location.reload()
  // },

  data:()=> ({

windowSize:{width:"auto", height:"auto"},
opcion:1,

LabMes:[{cod:0,mes:''},{cod:1,mes:'Enero'}, {cod:2,mes:'Febrero'}, {cod:3,mes:'Marzo'}, {cod:4,mes:'Abril'}, 
{cod:5,mes:'Mayo'}, {cod:6,mes:'Junio'}, {cod:7,mes:'Julio'},{cod:8,mes:'Agosto'},{cod:9,mes:'Septiembre'},
{cod:10,mes:'Octubre'},{cod:11,mes:'Noviembre'},{cod:12,mes:'Diciembre'}],     
year:0,
mont:null,
InforCalendario:'',

focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['red','green'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference']

}),

 methods: {

verDatos(){

// console.log('Verrr Datos')
// console.log(e)
return ""
},
ChangCalendar(){

// year:0,
// mont:null,
var Ms=""


if(this.year!="" && this.year!=0 ){ 
}else{
this.year=this.$refs.calendar.lastStart.year
}

if(this.mont!=""){ 


  var Obj = this.LabMes.filter(e=> e.mes== this.mont)
  Ms+=Obj[0].cod

}else{
  var Obj = this.LabMes.filter(e=> e.cod== this.$refs.calendar.lastStart.month)
//this.mont=this.$refs.calendar.lastStart.month
  this.mont=Obj[0].mes

}

//   console.log(" viendo la loqueraEntro a blanc")
// console.log(this.year+"-"+Ms+"-01")

this.focus=this.year+"-"+Ms+"-01"

},


viewDay ({ date }) {

        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))

          this.SelectOrdeComponent(this.selectedEvent)
             //RefComponOrden
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() => requestAnimationFrame(() => open()))
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },

SelectOrdeComponent(e){
var Orden=JSON.parse(JSON.stringify(e.Orden))
Orden.fechaestimada=e.Orden.nuevaFechaestimada
Orden.fechapautada=e.Orden.nuevaFechapautada
Orden.fecha=e.Orden.nuevaFecha
/*Orden.fechaestimada=new Date(e.Orden.fechaestimada).toISOString().substr(0,10)
Orden.fechapautada=new Date(e.Orden.fechapautada).toISOString().substr(0,10)
Orden.fecha=new Date(e.Orden.fecha).toISOString().substr(0,10)*/
console.log("ESTA ES LA OR",Orden)
var _this=this;
setTimeout(function(){ 
if(e!=null){
  _this.$refs.RefComponOrden.CrearOrdenes=JSON.parse(JSON.stringify(Orden))
 // console.log(_this.$refs.RefComponOrden.CrearOrdenes)
 // _this.$refs.RefComponOrden.SelectOrdeComponent(_this.RegistroActividades.especialidad)
  }else{
//_this.$refs.RefComponOrden.SelectRefEspecial(null)
  }
}, 500);

},

CerrarSelectOrdeComponent(){

  this.$refs.RefComponOrden.newwCrearOrdenes()
  this.selectedOpen=false
},

      updateRange1 ({ start, end }) {
var LbMes=['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
      
      //  this.InforCalendario=" "+LbMes[this.$refs.calendar.lastStart]+" - "+this.$refs.calendar.lastStart.year

        this.InforCalendario=" "+LbMes[start.month]+" - "+start.year
        this.year=start.year
        this.mont=this.LabMes[start.month].mes

        // console.log('Informacion')
        // console.log(this.$refs.calendar)


        const events = []

        const min = new Date(`${start.date}T00:00:00`)
        const max = new Date(`${end.date}T23:59:59`)

        const days = (max.getTime() - min.getTime()) / 86400000
        const eventCount = this.rnd(days, days + 20)

      const allDay = this.rnd(0, 3) === 0

        for (let i = 0; i < eventCount; i++) {

          const allDay = this.rnd(0, 3) === 0
          const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          const second = new Date(first.getTime() + secondTimestamp)

/*const jsn={ 
       name: 'Weekly Meeting',
          start: '2022-01-07 09:00',
          end: '2022-01-10 10:00',
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
      
}*/
//this.VarHora=new Date(sm).toString().substr(16,5) registro de turno
var fs =new Date(first)
var sc =new Date(second)
const jsn={
            name:this.names[this.rnd(0, this.names.length - 1)],
            start: new Date(first).toISOString().substr(0,10)+" "+fs.getHours() + ':' + fs.getMinutes(),
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: !allDay,

        }

// console.log(new Date(end).toISOString().substr(16,5))

events.push(jsn)
 

        // const jsn={
        //     name: this.names[this.rnd(0, this.names.length - 1)],
        //     start: new Date(first).toISOString(),
        //     end: new Date(second).toISOString(),
        //     color: this.colors[this.rnd(0, this.colors.length - 1)],
        //     timed: !allDay,

        // }

            // console.log("Obj=====>")
            // console.log(jsn)
            // events.push(jsn)


           //const allDay = this.rnd(0, 3) === 0
          // const firstTimestamp = this.rnd(min.getTime(), max.getTime())
          // const first = new Date(firstTimestamp - (firstTimestamp % 900000))
          // const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
          // const second = new Date(first.getTime() + secondTimestamp)

          // events.push({
          //   name: this.names[this.rnd(0, this.names.length - 1)],
          //   start: first,
          //   end: second,
          //   color: this.colors[this.rnd(0, this.colors.length - 1)],
          //   timed: !allDay,
          // })
        }

        this.events = events
      },

ObtenerOrdenes(start,end){
 var LbMes=['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
 this.InforCalendario=" "+LbMes[start.month]+" - "+start.year
        this.year=start.year
        this.mont=this.LabMes[start.month].mes

if(this.$store.getters.GetdatosInfUsu==null){
var infU=sessionStorage.getItem("UserData")
this.$store.commit('MutationaddUser', JSON.parse(infU));
}


var cli=0
var obj=`/ordenes/findConsultaOrden/${start.date}/${end.date}/${cli}/${this.opcion}/true`
this.$axios.get(this.$hostname+this.$hName+obj, { headers: this.$store.getters.GetheadersAxios })
.then(res=>{
  if(res.data.length>0){this.GenerarOrdenCalendario(res.data)}else{this.events=[]}
 // this.listaOrden=res.data
  })

},



CargarOrdenesRadio(){
var start = this.$refs.calendar.lastStart
var end = this.$refs.calendar.lastEnd
this.ObtenerOrdenes(start,end)
 
},


updateRange ({ start, end }) {
this.ObtenerOrdenes(start,end)
     
      },

      COPIAGenerarOrdenCalendario(e){
const events = []
e.forEach(elent => {
  console.log(elent)
//$F{tiporecibo}==1 ? "Ingreso a caja" : "Ingreso a banco" 
const allDay = this.rnd(0, 3) === 0
const jsn={
            name: "#Orden: "+elent.id+" - "+elent.cliente.nombre+" - "+ new Date(elent.fechapautada).toISOString().substr(0,10),
            start: new Date(elent.fechapautada).toISOString().substr(0,10)+" "+elent.hora,
            color: elent.pendiente ==true ? this.colors[0] : this.colors[1],
            timed: !allDay,
            Orden:JSON.parse(JSON.stringify(elent))

        }

// if(elent.pendiente==true){ jsn.color='blue'}
// if(elent.pendiente==false){ jsn.color='red'}
events.push(jsn)
  
});

this.events = events

// const events = []

// const allDay = this.rnd(0, 3) === 0
// var fs =new Date()
// const jsn={
//             name: this.names[this.rnd(0, this.names.length - 1)],
//             start: new Date().toISOString().substr(0,10)+" "+fs.getHours() + ':' + fs.getMinutes(),
//             color: this.colors[this.rnd(0, this.colors.length - 1)],
//             timed: !allDay,

//         }
        this.events = events


},

async GenerarOrdenCalendario(e){
const events = []
await Promise.all(e.map(async (elent) => { 
//$F{tiporecibo}==1 ? "Ingreso a caja" : "Ingreso a banco" 
const allDay = this.rnd(0, 3) === 0
const jsn={
            name: "#Orden: "+elent.id+" - "+elent.cliente.nombre+" - "+ elent.nuevaFechapautada,
            start: elent.nuevaFechapautada+" "+elent.hora,
            color: elent.pendiente ==true ? this.colors[0] : this.colors[1],
            timed: !allDay,
            Orden:JSON.parse(JSON.stringify(elent))

        }

// if(elent.pendiente==true){ jsn.color='blue'}
// if(elent.pendiente==false){ jsn.color='red'}

// 
events.push(jsn)
       }));

this.events = events

// const events = []

// const allDay = this.rnd(0, 3) === 0
// var fs =new Date()
// const jsn={
//             name: this.names[this.rnd(0, this.names.length - 1)],
//             start: new Date().toISOString().substr(0,10)+" "+fs.getHours() + ':' + fs.getMinutes(),
//             color: this.colors[this.rnd(0, this.colors.length - 1)],
//             timed: !allDay,

//         }
        this.events = events


},



      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },


KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}

if(e.target.value.length==0){e.target.value=0}

  if(e.key){
     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     if(e.target.value==null){ e.target.value=0 }

    }
    else{e.key=""}

if(e.target.value.length==0){ e.target.value=0
        e.key=0
     }
  
},



  },


computed:{

    ActDescBtn(){


this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>