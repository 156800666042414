<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
<!-- tooltip -->
<v-col class="pa-0 ma-0 py-0 my-0 "  style="background-color: #dd4b39" cols="12" md="12" sm="12">
    <v-row no-gutters > 
      <v-col class="pa-0 ma-0 py-0 my-0 pt-1"   cols="12" md="9" sm="8"  >
        <span :class="`d-flex justify-start white--text`">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
          <i v-on="on" style="font-size: 20px;" class="fa fa-info-circle px-2"> </i><b v-on="on">Restaurar Factura</b>
        </template>
           <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Restaurar Factura: </span> Restaurar Factura</i>
          </v-tooltip>
        </span>   
      </v-col>

      <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="12" md="3" sm="4"  >
        <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarRestaurarFactura()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>
            </v-btn-toggle>

          </v-card-actions>
        <!-- <v-btn  color="grey darken-4" fab x-small @click="cancelarCierreCaja()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn> -->
      </v-col>
      

    </v-row> 
</v-col>
  <!-- fn tooltip -->
<!-- Contenido -->
<v-col class="pa-0 ma-0 py-0 my-0 pt-2 px-2"   cols="12" md="12" sm="12">
  <v-card color="grey lighten-3">
    <v-form ref="formRestaurarFactura" @submit.prevent="" v-model="valid" lazy-validation>
      <v-row>

<v-col cols="12" md="4" sm="4" class="py-0 my-0">
  <v-tooltip top>
    <template v-slot:activator="{ on }">

      <v-text-field v-on="on" label="buscar por nombre:" dense autocomplete="off" outlined
        v-model="RestaurarFacturaBusqueda.buscar" @keyup="BuscarRestaurarFacturas"
        style="font-size: 12px;">
        <template v-slot:prepend>
          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
        </template>
      </v-text-field>

    </template>
    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
      <span> Aquí se registra el Nombre del cliente que deseamos búscar.</span>
    </i>
  </v-tooltip>
</v-col>
<v-col cols="12" md="4" sm="4" class="py-0 my-0">

  <v-combobox v-if="cambiarDestinoSucursal == true" autocomplete="off" dense outlined label="Sucursal:"
    :items="RSucursal" item-text="descripcion" :rules="[$rules.required]"
    v-model="RestaurarFacturaBusqueda.sucursal" @blur="SelctCbSucursalFactTempSeach"
    @focus="CargarRSucursal" @change="BuscarRestaurarFacturas">
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sitemap"></i>
    </template>
  </v-combobox>
</v-col>

</v-row>
    </v-form>

    <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

                <v-data-table id="TbBord" item-key="indx"  :search="RestaurarFacturaBusqueda.buscar"  :headers="[{ text: 'NOMBRE', sortable: true, value: 'descripcion', width: 'auto' }, 
                { text: 'TELEFONO', sortable: true, value: 'telefono', width: 'auto' }, 
                { text: 'FECHA', sortable: false, value: 'nuevaFecha', width: 'auto' },
                { text: 'VALOR FACTURA', sortable: false, value: 'valor', width: 'auto' }, { text: 'HORA', sortable: false, value: 'hora', width: 'auto' },
                { text: 'Tipo factura', sortable: false, value: 'tipo', width: 'auto' },
                { text: 'ACCIÓN', value: 'action', width: '110', sortable: true}]" :items="RestaurarFactlistFact"
                   :items-per-page="15" :footer-props="arrayPropiedases" dense>
                  <template v-slot:item.descripcion="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{props.item.descripcion }} </span>
                    </v-col>
                  </template>

                  <template v-slot:item.telefono="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{ RestaurarFacturaTelefono(props.item) }} </span>
                    </v-col>
                  </template>

                  <template v-slot:item.nuevaFecha="props">
                    <span style="display:none;">{{ props.item.indx = RestaurarFactlistFact.indexOf(props.item) }}</span>
                    <span style="display:none;" v-if="!props.item.hasOwnProperty('valor')">{{ props.item.valor= 0 }}</span>
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{ FormatoFecha(props.item.nuevaFecha) }} </span>
                    </v-col>
                  </template>

                  <template v-slot:item.valor="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{currencyFormatter(props.item.valor)}} </span>
                    </v-col>
                  </template>

              
                  <template v-slot:item.hora="props">
                    <v-col v-bind:style="{ 'font-size': '12px;', 'background': GetColorRestaurarFact(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <input id="formdisabledInput" disabled style="font-size:12px;" type="time" :value="HoraFormatter2(props.item.hora)" />
                    </v-col>
                  </template>
<!-- 'border': '1px solid #000000'
style="border: 1px solid #000000;" -->

                  <template v-slot:item.tipo="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{TipoRestaurarFactura(props.item)}} </span>
                    </v-col>
                  </template>

               
                  <template v-slot:item.action="{ item }">
                    <v-btn fab @click.prevent="verificarusofacturatemporalAplicar(item)" x-small color="green"><i
                        style="font-size: 20px;" class="fa fa-check"></i></v-btn>
                    <v-btn fab @click.prevent="ImprimirRestaurarFact(item)" x-small color="blue"><i
                        style="font-size: 20px;" class="fa fa-print"></i></v-btn>
                    <v-btn fab @click.prevent="LlamadeleRestaurarFact(item)" x-small color="red darken-3"><i
                        style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                  </template>
                </v-data-table>
                <!------- Por Aquii -->
              </div>
            </v-col>
    
  </v-card>
</v-col>
<!-- fn Contenido -->


<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv" :cajaSelect="cajaSelect" />
            </div>
            <!--------------------- fn Panel Menu Opcion Botones ------------------------------->

            <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,HoraFormatter2,fechaNueva,getParamFecha} from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric,
    PvMenuOpcionBotones
  },
  created() { 

  },
  mounted() {
 
    this.cambiarDestinoSucursal = this.$store.getters.GetConfiguracionGeneral.configPv.cambiarDestino
  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    setIntervalBuscarRestaurar:null,
    RestaurarFactlistFact:[],
    RestaurarFacturaBusqueda: {
      buscar: "",
      sucursal: null
    },
    cambiarDestinoSucursal:false,
    RSucursal:[],
    EjecutarPermisoPv:false,
    ObjEjecPermisoPv:null,
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },
 

    
  }),

  props: { 

cajaSelect: {
  required: false,
  type: Object,
  default: null
},

configuraciones: {
  required: false,
  type: Object,
  default: null
},



},

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,HoraFormatter2,fechaNueva,getParamFecha,
    getRestaurarfactura(){
      this.RestaurarFacturaBusqueda.sucursal= this.cajaSelect.sucursal
      this.BuscarRestaurarFacturas()
var configPv = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.configPv))
if( configPv.hasOwnProperty('tiempoActualizarFacturaTemporal')&& configPv.tiempoActualizarFacturaTemporal!=null && configPv.tiempoActualizarFacturaTemporal>0){
  this.ejecutarBuscarRestaurarFacturasFrecuencia()
      }
   return;
    },

// Hacer una solicitud GET al servidor y devolver una lista de objetos de facturas temporales.
    BuscarRestaurarFacturas() {
      this.$axios
        .get(this.$hostname + this.$hName + "/temporales/todas/" + this.RestaurarFacturaBusqueda.sucursal.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RestaurarFactlistFact = res.data;
        });
    },

    ejecutarBuscarRestaurarFacturasFrecuencia(){
  var INTERVALO =  ((this.$store.getters.GetConfiguracionGeneral.configPv.tiempoActualizarFacturaTemporal)*1000)
  this.$store.getters.GetConfiguracionGeneral.configPv.tiempoActualizarFacturaTemporal
  var _this=this;
  this.setIntervalBuscarRestaurar= setInterval(function(){ _this.BuscarRestaurarFacturas() }, INTERVALO);

    },

    canselarBuscarRestaurarFacturasFrecuencia(){
  clearInterval(this.setIntervalBuscarRestaurar)
  this.setIntervalBuscarRestaurar=null
    },

    cancelarRestaurarFactura(){
      this.ObjEjecPermisoPv =null
      var _this=this;
    setTimeout(function(){ 
      _this.canselarBuscarRestaurarFacturasFrecuencia()
      _this.$emit("DataRetornCancelarRestFact", false)
     }, 300);
    },

    CargarRSucursal() {

this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
  this.RSucursal = JSON.parse(JSON.stringify(res.data));
});

    },
    SelctCbSucursalFactTempSeach() {
      var _this = this;
      setTimeout(function () {
        if (_this.RestaurarFacturaBusqueda.sucursal == null) {
          _this.RestaurarFacturaBusqueda.sucursal = "";
        }

        if (!_this.RestaurarFacturaBusqueda.sucursal.hasOwnProperty('codigo')) {
          _this.RestaurarFacturaBusqueda.sucursal = "";
          _this.RestaurarFacturaBusqueda.sucursal = null;
          return;
        }
      }, 300);
    },


    RestaurarFacturaTelefono(e){
      if(e.cliente!=null){
        return this.selectTelefonoCliente(e.cliente)
      }else{
        return ' '
      }

    },

    // Un método que recibe un cliente y verifica en una lista si tiene telefono .
    selectTelefonoCliente(e) {
      if (typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return ' ' }
    },

    GetColorRestaurarFact(e){
    if(e.hasOwnProperty('reimprimida') && e.reimprimida!=null && e.reimprimida==true){
      return '#66BB6A'
    }else{
      return 'transparent'
    
    }
    },

    // Devolver una cadena basada en el valor del objeto.
    TipoRestaurarFactura(e) {
      var data = ""

      if (e.ars != null) {
        return data = " Sequro "
      }

      if (e.telefonoDomicilio != null) {
        return data = " Domicilio "
      }

      if (e.cxc != null && e.cxc > 0) {
        return data = " Cuenta por cobrar "
      }

      if (e.tarjeta != null && e.tarjeta > 0) {
        return data = " Tarjeta ";
      }

      if (e.efectivo != null && e.efectivo > 0) {
        return data = " Efectivo ";
      }

      return data;
    },

verificarusofacturatemporalAplicar(e){
this.AplicarRestaurarFact(e)
return
},

async findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},




// Una función que se llama cuando se hace clic en un botón y carga el objecto factura temporal 
    //y asignando los valores de la factura.
    async AplicarRestaurarFact(e) {
  var OBJ = {
    Facturasventa :{ facturasventaPK: { secuencia: 0, documento: 0 }},
    headerFactura:{
     // fecha:getParamFecha(e.fecha).split("T")[0],
      fecha: e.nuevaFecha,
      listaPrecio:null,
      tipocomprobante:null,
      documento:null,
      cliente:null,
      sucursal:null,
      moneda:null,
      vendedor:null,
      diascredito:0,
      centroCosto:null,
      ValorPorc:false,
      descuento:0,
      activarListasPrecios:false
    },
  }

      if (e.moneda != null) {
        var monedaa = e.moneda
       /* monedaa.tasa = e.tasa
        this.Monedafactura = monedaa*/
        OBJ.headerFactura.moneda = monedaa
      }
       OBJ.headerFactura.tipocomprobante= e.tipoComprobante
      if ( e.hasOwnProperty('cliente')&&e.cliente != null) {
        OBJ.headerFactura.cliente = JSON.parse(JSON.stringify(e.cliente))
        }
          // _this.SelectMedicoFactura = e.medicoCodigo
          OBJ.headerFactura.vendedor = e.vendedor
          OBJ.headerFactura.tipocomprobante = e.tipoComprobante
          OBJ.Facturasventa.nombreDgii = e.nombreDgii
          OBJ.Facturasventa.cedularncncf = e.cedulaDgii
          OBJ.Facturasventa.telefononcf = e.telefonoDgii
          OBJ.Facturasventa.tipocomprobante = e.tipoComprobante
          OBJ.Facturasventa.direccionncf = e.direccionDgii
          OBJ.Facturasventa.nombreFactTem=e.descripcion
          OBJ.nombreFactTem=e.descripcion
          OBJ.deleteFacturaTemporal=JSON.parse(JSON.stringify(e));
      var ListDetalleTemp=[]
      var bar = new Promise((resolve, reject) => {
        if (e.facturastemporalesDetalleList.length > 0) {
          var listFtempDetalle = JSON.parse(JSON.stringify(e.facturastemporalesDetalleList))
          listFtempDetalle.sort(function (a, b) {
            if (a.facturastemporalesDetallePK.id > b.facturastemporalesDetallePK.id) { return 1; }
            if (a.facturastemporalesDetallePK.id < b.facturastemporalesDetallePK.id) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

       (async () => {
          if(e.efectivo>0){
          OBJ.Facturasventa.efectivo=JSON.parse(JSON.stringify(e.efectivo))
          }
          if(e.cxc>0){
            OBJ.Facturasventa.cxc=JSON.parse(JSON.stringify(e.cxc))
          }
          if(e.tarjeta>0){
           OBJ.Facturasventa.tarjeta=JSON.parse(JSON.stringify(e.tarjeta))
          OBJ.Facturasventa.numtar=JSON.parse(JSON.stringify(e.numtar))

          if(e.tipotar!=null){
          this.$axios.get(this.$hostname + this.$hName + "/tarjeta/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.length>0){
            var ObjProRow = res.data.filter((ee) => ee.codigo == e.tipotar);
            if (ObjProRow.length > 0) { 
              OBJ.tipoTarjeta=ObjProRow[0]
            }
          }});

           }}  
           const restrList =  await this.ContAplicarRestaurarFact(listFtempDetalle,OBJ);
           /*console.log('--restrList--')
           console.log(restrList)*/
           resolve()
        })() 
        }  
        

      });


      bar.then(() => { 
        // this.$emit("DataRetornAplicarRestaurarFact", OBJ) 
      });

    },

    async ContAplicarRestaurarFactdfgd2(listFtempDetalleData,data){  
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
     var BOOL_existencia=false
      var OBJrestrList=[];
      var bar = new Promise((resolve, reject) => {

        (async () => {

if (listFtempDetalleData.length > 0) {
  var listFtempDetalle = JSON.parse(JSON.stringify(listFtempDetalleData.filter((ee) => ee.precio>0)))

  listFtempDetalle.sort(function (a, b) {
    if (a.facturastemporalesDetallePK.id < b.facturastemporalesDetallePK.id) { return 1; }
    if (a.facturastemporalesDetallePK.id > b.facturastemporalesDetallePK.id) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  setTimeout(() => {
    (async () => {
  // console.log(listFtempDetalle)
  const restrList =  await this.detalleRestaurarFactTem(listFtempDetalle);
  OBJrestrList= JSON.parse(JSON.stringify(restrList.ListTem))
  if(restrList.BOOL_existencia==true){
    _this.alerta("hay producto que intenta vender, que la cantiada es mayor a la existencia", "error");
  }    


setTimeout(() => {
  resolve()
}, 500); 


    })()
  }, 500); 

}


})() 




  /*      listFtempDetalle.forEach(async (element) => {
           (async () => { 
            const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            if(resUniPro!=null){
                var Obj =JSON.parse(JSON.stringify(resUniPro))
                Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}` 
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false
                Obj.precio = element.precio
               // Obj.precioOrig = resUniPro.precio
              //  Obj.precioDig = resUniPro.precio
                Obj.precioOrig = element.precio
                Obj.precioDig = element.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                ListDetalleTemp.push(Obj)
            } 
            })()
            }); 
            resolve()*/
      });

      bar.then(() => {
        OBJ.ListDetalleFactTemp=OBJrestrList
        this.cancelarRestaurarFactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornAplicarRestaurarFact", OBJ)  
       rresolve(OBJrestrList)  
      }, 300);
      
        
    });

  });

    },


    

  

findUnidadProductoConExistenciaAlmacen(VARLINK){
return  new Promise((resolve, reject) => {
// var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/findUnidadProductoConExistenciaAlmacen/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
    //  EventBus.$emit("loading", false); 
  })
  .catch(error => {
      // EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},


async ContAplicarRestaurarFact(listFtempDetalle,data){  
  return new Promise((rresolve, reject) => { 
    console.log('viendo inf--')
    console.log(listFtempDetalle)
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var bar = new Promise((resolve, reject) => {
       (async () => { 
        const restrList =  await this.detalleRestaurarFactTem(listFtempDetalle);
        //ListDetalleTemp=restrList.ListTem;
        if(restrList.BOOL_existencia==true){
            _this.alerta("hay producto que intenta vender, que la cantiada es mayor a la existencia", "error");
          }   
          
          /**/ await Promise.all(restrList.ListTem.map(async (element) => {
            if(element!=null){
                var Obj =JSON.parse(JSON.stringify(element))
                Obj.indx=(restrList.ListTem.indexOf(element)+`${element.productos.codigo}${element.unidades.codigo}`)
                Obj.id=restrList.ListTem.indexOf(element)
                Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}` 
                Obj.precio = element.precio
                 Obj.precioOrig = element.precio
                 Obj.precioDig = element.precio
                 Obj.paramEspecial = false
                 Obj.paramOfert = false
                 
               /* 
               Obj.vendedor = element.vendedor
               Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false
               // Obj.precioOrig = resUniPro.precio
              //  Obj.precioDig = resUniPro.precio
                
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;*/
                
                ListDetalleTemp.push(Obj)
            } 
          }));

          setTimeout(() => {
          resolve()
        }, 500); 


      })()
      

      /* listFtempDetalle.forEach(async (element) => {
           (async () => { 
            const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            console.log(resUniPro)
            if(resUniPro!=null){
                var Obj =JSON.parse(JSON.stringify(resUniPro))
                Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}` 
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false
                Obj.precio = element.precio
               // Obj.precioOrig = resUniPro.precio
              //  Obj.precioDig = resUniPro.precio
                Obj.precioOrig = element.precio
                Obj.precioDig = element.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                Obj.vendedor = element.vendedor
                ListDetalleTemp.push(Obj)
            } 
            })()
            }); */  
      });

      bar.then(() => {
        OBJ.ListDetalleFactTemp=ListDetalleTemp
        this.cancelarRestaurarFactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornAplicarRestaurarFact", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },

//Organizar Y preparar detalle factura temporal
async detalleRestaurarFactTem(listFtempDetalle){
      var OBJDATA={
        sucursal:this.cajaSelect.sucursal.codigo,
        permitirNegativos:this.configuraciones.configInv.permitirNegativos,
        facturaTempDetalle:listFtempDetalle
      }
      return new Promise((rresolve, reject) => { 
        this.$axios
            .post(
              this.$hostname + this.$hName + "/temporales/existenciaListFactTemDetalle",
              OBJDATA,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.loading(false)

             /**/ var _this=this;
               setTimeout(function(){  
                  rresolve(res.data)  
                }, 300);

              /*this.alerta(res.data.mensage, res.data.estado);
              this.cancelarFarmacoVigilancia();*/
            })
            .catch((error) => {
             /* this.loading(false)
              this.alerta("Dato no borrado correctamente", "error")*/
            }
            );
      });
    },


//11-04-2024
 async ContAplicarRestaurarFact11042024(listFtempDetalle,data){  
  console.log(data)
  return new Promise((rresolve, reject) => { 
      var ListDetalleTemp=[]
      var OBJ=JSON.parse(JSON.stringify(data))
      var bar = new Promise((resolve, reject) => {
        listFtempDetalle.forEach(async (element) => {
           (async () => { 
            const resUniPro =  await this.findUnidaProducto(element.productos.codigo,element.unidades.codigo);
            if(resUniPro!=null){
                var Obj =JSON.parse(JSON.stringify(resUniPro))
                Obj.descripcion=`${element.productos.descripcion.trim()} ${element.unidades.descripcion.trim()}` 
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.descuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = false
                Obj.precio = element.precio
               // Obj.precioOrig = resUniPro.precio
              //  Obj.precioDig = resUniPro.precio
                Obj.precioOrig = element.precio
                Obj.precioDig = element.precio
                Obj.paramEspecial = false
                Obj.paramOfert = false
                Obj.valorimpuesto= element.valorimpuesto!=null?element.valorimpuesto:0;
                Obj.vendedor = element.vendedor
                ListDetalleTemp.push(Obj)
            } 
            })()
            }); 
            resolve()
      });

      bar.then(() => {
        OBJ.ListDetalleFactTemp=ListDetalleTemp
        this.cancelarRestaurarFactura()
    var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornAplicarRestaurarFact", OBJ)  
       rresolve(ListDetalleTemp)  
      }, 300);
      
        
    });

  });

    },

    // Impresión de un recibo.
    ImprimirRestaurarFact(d) {
      if (this.EjecutarPermisoPv == false) {
        this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(d))
        this.funcionValidaPermisoPv('AccImprimirRestaurarFact')
        return;
      }

      if (localStorage.printerSelect != null) {
        // d.reimprimida=true
        // this.actualizarRestaurarFacReimprimida(d)
        var msg = {
          accion: "print",
          formato: this.cajaSelect.formatoimpresioncontado.trim(),
          impresora: JSON.parse(localStorage.printerSelect).nombre,
          data: this.configuraciones,
          fac: d,
          caja: this.cajaSelect,
        };
        this.imprimir2(msg, "factura-temp",this.actualizarRestaurarFacReimprimida(d));
      }
    },

    actualizarRestaurarFacReimprimida(e){
      this.$axios
        .post(this.$hostname + this.$hName + "/temporales/reimprimida/", e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RestaurarFactlistFact[e.indx].reimprimida=res.data
          // this.BuscarRestaurarFacturas();
        })
        .catch((error) =>
          this.alerta("Dato no eliminado correctamente.", "error")
        );

    },

// Llamar a una función que verificará si el usuario tiene permiso para eliminar un registro. Si el
    // usuario no tiene permiso llama un panel para validar el usuario con permiso
    LlamadeleRestaurarFact(e) {
      if (this.EjecutarPermisoPv == false) {
        this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(e))
        this.funcionValidaPermisoPv('AccDeleRestaurarFact')
        return;
      }
      this.deleRestaurarFact(e)
    },
    // Hacer una solicitud de publicación al servidor para borrar la factura temporal.
    deleRestaurarFact(e) {
      this.$axios
        .post(this.$hostname + this.$hName + "/temporales/borrado/", e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          // this.alerta(res.data.mensage, res.data.estado);
          // this.RestaurarFacturaBusqueda.buscar=""
          this.BuscarRestaurarFacturas();
        })
        .catch((error) =>
          this.alerta("Dato no eliminado correctamente.", "error")
        );
    },


    imprimir2(datos, operacion,callback) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            // this.limpiarPuntoVentaCompleto();

            callback(datos.fac);
          })
          .catch((error) => console.error(error));
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            callback(datos.fac);
            // this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => console.error(error));
      }
    },



    // Llamar a una función en un componente secundario para los permisos de usuario.
    funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

    },

    DataRetornMenuOpcBotonesPermisosPv(){},

    DataRetornPadrePermisoPv(e) {
      //Aplicar RestaurarFact
      if (e == 'AccImprimirRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.ImprimirRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

//Aplicar deleRestaurarFact
if (e == 'AccDeleRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.LlamadeleRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },

    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>