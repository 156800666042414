<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Cuadres'"
  :descripcion="'Cuadres de Turnos por Sucursal'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitESTCuadres'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 


  

  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
  <v-container>  
<v-card color="grey lighten-3">
      <v-container>
              <v-row>


<v-col cols="12" md="4" sm="4" >
<!-------------------------------------------------- Sucursal -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Estaciones:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="Cuadres.sucursal"
            @focus="cargarSucursal"
            @blur="SelctCbSucursalDesp"
            style="font-size:12px;"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 18px;" class="fa fa-sitemap"></i>
            </template>
 </v-combobox>
</v-col>

<v-col cols="12" md="2" sm="3">

  <!------------------------------- Caja -->
        <v-combobox
                    v-model="Cuadres.caja"
                    label="caja"
                    :rules="[$rules.required]"
                    outlined
                    dense
                   
                    autocomplete="off"
                    :items="RCaja"
                    item-text="descripcion"
                    @blur="SelctCbCaja"
                    @focus="cargarCaja" 
                    style="font-size:12px;" 
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 18px;"
                              class="fa fa-fax"
                            ></i>
                          </template>
                        </v-combobox>

</v-col>

<v-col cols="12" md="2" sm="3" >

<!-------------------------------------------------- Turno -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Turno:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="Cuadres.sucursal"
            style="font-size:12px;"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 18px;" class="fa fa-slideshare"></i>
            </template>
 </v-combobox>


</v-col>


                <v-col cols="12" md="2" sm="3">
                  <!-- ----------------------------- Dia de credito -->
                    <v-text-field label="Acumulado del Día:"
                     dense outlined
                     v-model="Cuadres.dias"
                     @keyup="KeyUpDiaAc"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-sun-o"></i> 
                      </template>
                    </v-text-field>
                </v-col>

             
                <v-col cols="12" md="2" sm="4">
                  <!-- -----------------------------  fecha de ingreso -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha"
        v-model="Cuadres.fecha"
        style="font-size:12px;"
        >

                <template v-slot:prepend>
                  <i style="font-size: 18px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>

            
                </v-col>
              </v-row> 
              </v-container>
              </v-card>
            </v-container>


          <v-row >
<!-- columna 1 ---------------------------------->
            <v-col cols="12" md="4" sm="5"  >

              <div color="grey lighten-3" style="border: 2px solid #757575;" >
                <v-data-table
                color="primary"
      class="elevation-1"
     
      dense
  :headers="[{ text: 'Denominación', value: 'valor',width:'auto'}, 
  { text: 'Cantidad', value: 'cantidad',width:'auto'},
  { text: 'Valor', value: 'Vtotal',width:'auto'},
 ]"
        :items="ListDenomMoneda"
        :items-per-page="-1"
      >
<template v-slot:body.append v-if="windowSize.width >600">
<tr> 
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:13px;">Totales:</strong></td>
<td class="border-total"  ><strong class="d-flex justify-center" style="font-size:13px;"> {{currencyFormatter(totales.totalDenomiacionvalor)}} </strong></td>
</tr>
</template>

<template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 80%; border: 2px solid #757575;" class="white--text" > 
      <v-list-item-content>
        <b>TOTAL: {{currencyFormatter(totales.totalDenomiacionvalor)}}</b>
      </v-list-item-content>
    </v-list-item>
</template>



				

<template v-slot:item.valor="props">
<span style="font-size: 17px;" >{{currencyFormatter(props.item.valor)}}</span>
</template>

 
<template v-slot:item.cantidad="props">


  <!-- ----------------------------- cantidad -->
<!-- <input 
  style="border: 2px solid #607D8B; font-size: 17px; "
  autocomplete="off"
  size="8"
    dense outlined
    v-model.number="props.item.cantidad"
     @keyup="KeyUpCantidad"
     v-on:keyup="CalcularDenominacion"                 
 /> -->


                    <v-text-field label=""
                   
                    autocomplete="off"
                    
                     dense outlined
                     v-model.number="props.item.cantidad"
                     @keyup="KeyUpCantidad"
                     v-on:keyup="CalcularDenominacion"
                     >
                    </v-text-field>
   
    </template>

<template v-slot:item.Vtotal="props" >

  <p style="display:none;" v-if="props.item.cantidad.length==0">{{props.item.cantidad=0}} </p>
  <!-- jsnn.cantidad=0
  jsnn.Vtotal=0
 -->
  <!-- <span>$RD.{{props.item.Vtotal=(props.item.valor)*(props.item.cantidad)}}</span> -->
<span v-bind:style="{ 'font-size':'17px;', 'color':getColor(props.item.Vtotal)}" >$RD.{{currencyFormatter(props.item.Vtotal)}}</span>
  <!-- <span>Valor:{{props.item.cantidad}}</span> -->

 </template>


    </v-data-table>
              </div>

            </v-col>
 <!-- fn columna 1 ---------------------------------->
<!-- columna 2 ---------------------------------->
            <v-col cols="12" md="2" sm="3"  >

             <!-- style="background-color:#EEEEEE;" -->

              <v-card color="grey lighten-3">

                 <VueAutonumeric
                        autocomplete="off"
                       
                        label="Gas Premium (INFO):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-free-code-camp"
                          ></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        
                        label="Ventas a Crédito:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-shopping-cart"
                          ></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        label="Tarjeta de Crédito:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-credit-card"
                          ></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        label="Valor en Cheques:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>

                 <VueAutonumeric
                 style="margin-top:-15px"
                        autocomplete="off"
                        label="Valor en BonoGas:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        label="Pagos por Comisión:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>



                 <VueAutonumeric
                 style="margin-top:-15px"
                        autocomplete="off"
                        label="Valor Donaciones:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>


                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        label="Valor Otros:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>


                 <VueAutonumeric
                 style="margin-top:-15px"
                        autocomplete="off"
                        label="Valor Asignaciones:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>
                 
                 <VueAutonumeric
                 style="margin-top:-15px"
                        autocomplete="off"
                        label="Crédito Distribuidora:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-credit-card-alt"
                          ></i>
                        </template>
                      </VueAutonumeric>
                 

                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        label="Distribuidoras:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-truck"
                          ></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric
                      style="margin-top:-15px"
                        autocomplete="off"
                        label="Descuento (INFO):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-bookmark"
                          ></i>
                        </template>
                      </VueAutonumeric>
             
<hr style="border-bottom: 2px dashed #212121;"> <br>


    <VueAutonumeric
             style="margin-top:-15px"
                        autocomplete="off"
                        label="Total del Día:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>
    
  
  
    <VueAutonumeric
    style="margin-top:-15px"
                        autocomplete="off"
                        label="Depósito Bancario:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
                      </VueAutonumeric>
    
     
    
    <VueAutonumeric
    style="margin-top:-15px"
                        autocomplete="off"
                        label="Total General:"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-money"
                          ></i>
                        </template>
   </VueAutonumeric>
              
              </v-card>

            </v-col>
            <!-- fn columna 2 ---------------------------------->

            <!-- columna 3 ---------------------------------->

            <v-col cols="12" md="6" sm="4">

<div style="border: 2px solid #757575; overflow: auto;  max-height:450px; min-height:450px;   " class="overflow-y-auto" >
              <v-data-table
      class="elevation-1"
     
      dense
  :headers="[{ text: 'Metros', value: 'mt',width:'auto'}, 
  { text: 'Tipo Gas', value: 'tg',width:'auto'},
  { text: 'Lectura Iniciales', value: 'li',width:'auto'},
  { text: 'Lectura finales', value: 'lf',width:'auto'},
  { text: 'Diferencias', value: 'df',width:'auto'},
  { text: 'Metros Finales', value: 'mf',width:'auto'},

 ]"
        :items="ListMetro"
        :items-per-page="-1"
      >
      
      <template v-slot:footer>
<v-list-item style="font-size: 80%; border: 2px solid #757575;" class="white--text" > 
      <v-list-item-content>
        <b>Total Galones Vendidos: {{currencyFormatter(0)}}</b>
      </v-list-item-content>
    </v-list-item>
 </template>

 <template v-slot:no-data>
      <v-card-title>No hay metros en esta sucursal</v-card-title>

      <!-- <v-sheet v-if="tbFacCargar"
    :color="`grey ${theme.isDark ? 'darken-2' : 'red darken-1'}`"
    class="px-3 pt-3 pb-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      max-width="auto"
      type="card"
    ></v-skeleton-loader>
  </v-sheet> -->
 </template>




      </v-data-table>
      </div>



              <v-card color="grey lighten-3">
                <v-row>
<!--  -->
                  <v-col cols="12" md="6" sm="12" >
                    <VueAutonumeric
                        autocomplete="off"
                       
                        label="Asignaciones Gas (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-free-code-camp"
                          ></i>
                        </template>
                      </VueAutonumeric>  

                      <VueAutonumeric
                        autocomplete="off"
                       
                        label="Donaciones Gas (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-free-code-camp"
                          ></i>
                        </template>
                      </VueAutonumeric> 

                      <VueAutonumeric
                        autocomplete="off"
                       
                        label="Pruebas Gas (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-free-code-camp"
                          ></i>
                        </template>
                      </VueAutonumeric> 

                      <VueAutonumeric
                        autocomplete="off"
                       
                        label="Inventario Inicial (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-cubes"
                          ></i>
                        </template>
                      </VueAutonumeric> 

                      <VueAutonumeric
                        autocomplete="off"
                       
                        label="Compras Gas (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-free-code-camp"
                          ></i>
                        </template>
                      </VueAutonumeric> 

                </v-col> 

                
                  <v-col cols="12" md="6" sm="12" >


                    <VueAutonumeric
                        autocomplete="off"
                       
                        label="Salidas Gas (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-free-code-camp"
                          ></i>
                        </template>
                      </VueAutonumeric> 



                    <VueAutonumeric
                        autocomplete="off"
                       
                        label="Existencia en Libro (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-wpforms"
                          ></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric
                        autocomplete="off"
                       
                        label="Inventario Fisico Final (Galones):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-cubes"
                          ></i>
                        </template>
                      </VueAutonumeric> 

                      <VueAutonumeric
                        autocomplete="off"
                        label="Diferencia (INFO):"
                        dense
                        outlined
                        v-model="Cuadres.codigo"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-delicious"
                          ></i>
                        </template>
                      </VueAutonumeric> 

                    

                </v-col> 


                </v-row>
              </v-card>

            </v-col>
<!-- fn columna 3 ---------------------------------->

          </v-row>


            </v-form>
      
  


        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarCuadre'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import numeral from 'numeral'
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase
  },
  
  created() {
    var json = {
      titulo: "Cuadres",
      descripcion: "Cuadres de Turnos por Sucursal",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "ESTCuadresNew",
        "ESTCuadresSave",
        "ESTCuadresRemove",
        "ESTCuadresAnular",
        "ESTCuadresPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ESTCuadresNew", this.new);
    // EventBus.$on("ESTCuadresSave", this.save);
    // EventBus.$on("ESTCuadresRemove", this.remove);
    // EventBus.$on("ESTCuadresAnular", this.remove);
    // EventBus.$on("ESTCuadresPrintList", this.imprimirList);
    EventBus.$on("emitESTCuadres", this.datostabla);
    this.cargarTodo();

    this.cargarDenominocion()

    EventBus.$on("onResize", this.onResize);
  },



  data() {
    return {
      ListaTiposClientes: [],
      ListDenomMoneda:[],
      ListMetro:[],
      Rdenominacion:[],
      RSucursal:[],
      RCaja:[],
      windowSize:{width:"auto", height:"auto"},

      Cuadres: {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        sucursal:null,
        caja:null,
        dias:0,
        fecha:new Date().toISOString().substr(0,10)
        
      },

      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },




      totales:{

        totalDenomiacionvalor:0

      },

     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
     
notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.Cuadres = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.Cuadres)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },



NotifMessage() {

if(this.Bancos.codigo!=0 && this.Bancos.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarCuadre"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un cuadre para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },




    remove() {

if(this.Cuadres.codigo!=0 && this.Cuadres.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Cuadres)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.Cuadres.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.Cuadres)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {},
    newwnuevo() {
      (this.Cuadres = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: ""
  
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },

KeyUpDiaAc(e){

  if(e.key){
     var mynumeral = require('numeral')
     this.Cuadres.dias  = mynumeral(this.Cuadres.dias).value()
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(this.Cuadres.dias==null){

this.Cuadres.dias=0
     }
     
    }
    else{
     console.log("no se puede poner letras o signos")
    }



},


getColor(e){

  if(e<=0){

    return 'red'
  }else{

    return 'green'

  }


},


CalcularDenominacion(){

   this.totales.totalDenomiacionvalor=0

  
this.ListDenomMoneda.forEach(element => {

  element.Vtotal= (element.cantidad)*(element.valor)
  
  this.totales.totalDenomiacionvalor+=element.Vtotal

  
});

},





KeyUpCantidad(e){

//  if(e.key.charCodeAt(0)>=48 & e.key.charCodeAt(0)<=57 ){}

  if(e.key){

     var mynumeral = require('numeral')
     e.target.value  = mynumeral(e.target.value).value()
     
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(e.target.value==null){

        e.target.value=0
     }

    }
    else{

      e.key=""

     console.log("no se puede poner letras o signos")
    }


if(e.target.value.length==0){

        e.target.value=0
        e.key=0
     }
    

    
},



SelctCbCaja(){


if(this.Cuadres.caja==null){

    this.Cuadres.caja=''
  }

if(this.Cuadres.caja.codigo==undefined){

this.alerta("Selecciones un caja", "error");
this.Cuadres.caja=''
return

}

},

cargarCaja(){
this.$axios.post(this.hostname+this.hName+'/services/caja/findAllActivos',{
  usuario:JSON.parse(JSON.stringify(this.$user))  
})
.then(res=>( this.RCaja=res.data))
},






cargarSucursal(){

this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{
usuario:JSON.parse(JSON.stringify(this.$user))  
})
.then(res=>( this.RSucursal=res.data))

},

SelctCbSucursalDesp(){

 if(this.Cuadres.sucursal==null){

    this.Cuadres.sucursal=''
  }

if(this.Cuadres.sucursal.codigo==undefined){

this.alerta("Seleccione un sucursal", "error");
this.Cuadres.sucursal=''
return

}
},





cargarDenominocion(){

       this.$axios.post(this.$hostname + this.$hName + '/services/denominacionmoneda/findall', {
           usuario:JSON.parse(JSON.stringify(this.$user)) 
      }).then(res => {
        this.Rdenominacion = res.data,

res.data.forEach(element => {

  if(element.activa==true){

    console.log('La Denominacion')
    console.log(element)



  var jsnn= JSON.parse(JSON.stringify(element))

  jsnn.cantidad=0
  jsnn.Vtotal=0

  this.ListDenomMoneda.push(jsnn);

  }

  
});

      });
    

},


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },



computed:{

    ActDescBtn(){      
if(this.Cuadres.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.Cuadres.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

    }
    }


};
</script>

<style></style>
