<template>
  <div>

    <v-card>

      <v-toolbar flat color="indigo" dense>

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> PRODUCTOS </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="cerrarModalAddProd()">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-text-field autocomplete="off" v-on="on" v-model="search" @keyup="CbFilProducto" @focus="CargarRproducto"
              label="Buscar" single-line hide-details>
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>
          </template>
          <span>Busqueda</span>
        </v-tooltip>

        <v-data-table dense @click:row="addClikProd" hide-default-footer :items-per-page="-1" :headers="[
          { text: 'CODIGO', value: 'productos.codigo', sortable: false, class: ['no-gutters'] },
          { text: 'PRODUCTO', value: 'productos.descripcion', sortable: false, class: ['no-gutters'] },
          { text: 'COSTO', value: 'costo', sortable: false, class: ['no-gutters'] },
          { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters'] },
          { text: 'EXISTENCIA', value: 'existencias', sortable: false, align: 'center', class: ['no-gutters'] },
        ]
        " :items="RallProducto" item-key="codigo" class="elevation-1">

          <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA -->

          <template v-slot:item.productos.descripcion="props">
            <span style="font-size:13px;"> {{ props.item.productos.descripcion }}</span>
          </template>

          <template v-slot:item.costo="props">
            <span style="font-size:13px;"> {{ currencyFormatter(props.item.costo) }}</span>
          </template>

          <template v-slot:item.precio="props">
            <span style="font-size:13px;"> {{ currencyFormatter(props.item.precio) }}</span>
          </template>
        </v-data-table>



      </v-card-text>
    </v-card>


    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}

      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>



  </div>
</template>

<script>
import AutoNumeric from "../components/AutoNumericNuevo";
import { EventBus } from "../event-bus";
export default {

  components: {
    AutoNumeric
  },
  mounted() {

    this.CargarRproducto()

  },

  created() {

  },


  data() {

    return {

      search: '',
      RallProducto: [],
      aalert: {
        estado: false,
        color: null
      },

    }


  },
  methods: {


    CargarRproducto() {

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/unidades-productos/*/false/buscar",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => { this.RallProducto = res.data, console.log(res.data) });
    },



    CbFilProducto(e) {
      console.log(e.target.value)
      if (e.target.value.length >= 2) {
        //console.log("ok1")
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/unidades-productos/" + e.target.value + "/false/buscar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.RallProducto = res.data, console.log(res.data)));
      }
    },




    filtro() {




    },
    addClikProd(e) {

      this.alerta("Producto agregado", "bien")

      var Objt = {
        estado: "datos",
        datos: e
      }
      this.$emit("EmitAgregarProducto", Objt);

    },


    cerrarModalAddProd() {

      this.search = ""
      this.CargarRproducto()

      var Objt = { estado: "cerrar" }
      this.$emit("EmitAgregarProducto", Objt);

    },

    currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        //  this.NewCliente();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);


      }
    },








  }


}
</script>

<style>
</style>