<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Relación de Notas de Crédito'"
          :descripcion="'Imprime las Notas de Crédito Generadas en el Punto de ventas'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!--                <BarraBusqueda2-->
                <!--                    :url="-->
                <!--                $hostname + $hName + '/services/tipocliente/findalltipoclientes'-->
                <!--              "-->
                <!--                    :nombre="'Tipo de Clientes'"-->
                <!--                    :headers="headers"-->
                <!--                    :slotname="'item.descripcion'"-->
                <!--                    :emitnombre="'emitReportesRelacionNC'"-->
                <!--                    :SeachNombre="'Búsqueda por descripcion '"-->
                <!--                    :slotlist="slotlist"-->
                <!--                />-->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
<!-- Formulario-Relacion de Notas de Credito -->

            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;">Relación de Notas de Crédito</span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="cerrarmodalcuenta"
                  >
                   <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  desde  -->
                        <v-text-field dense outlined type="date" label="Desde:" :rules="[$rules.required]"
                          v-model="RelacionNC.desde">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <!------------------------------------------- caja-->

                        <v-combobox v-model="RelacionNC.caja" label="Caja" required outlined dense autocomplete="off"
                          :items="RCaja" item-text="descripcion" @blur="SelctCbCaja" @focus="cargarCaja">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-cube"></i>
                          </template>
                        </v-combobox>

                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox autocomplete="off" dense outlined label="Sucursal:" :items="RSucursal"
                          item-text="descripcion" v-model="RelacionNC.sucursal" @focus="cargarSucursal"
                          @blur="SelctCbSucursal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  Hasta  -->
                        <v-text-field dense outlined type="date" :rules="[$rules.required]" label="Hasta:"
                          v-model="RelacionNC.hasta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <v-text-field label="# Cierre:" dense autocomplete="off" outlined
                          v-model="RelacionNC.numeroCierre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                          </template>
                        </v-text-field>


                        <!------------------------------------------- caja-->

                        <v-combobox v-model="RelacionNC.tipo_nota" label="Tipo de Nota de Crédito" required outlined
                          dense autocomplete="off" :items="RTipoNota" item-text="descripcion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>


                      </v-col>
                      <!-- fn columna 2 -->
                    </v-row>
                  </v-form>
                </v-container>
                <hr>
                <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="RelacionNC" />
              </v-card>
            </v-container>
            <!-- fn Formulario-Relacion de Notas de Credito -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--
                    <BarraBusqueda2
                          :url="$hostname + $hName + '/services/caja/findAll'"
                          :nombre="'Cajas'"
                          :headers="headers"
                          :slotname="'item.descripcion'"
                          :emitnombre="'emitCaja'"
                          :slotlist="slotlist"
                          :SeachNombre="'Búsqueda por descripción caja'"
                        /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Relación de Notas de Crédito",
      descripcion: "Imprime las Notas de Crédito Generadas en el Punto de ventas",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ReportesRelacionNotasCreditoNew",
        "ReportesRelacionNotasCreditoSave",
        "ReportesRelacionNotasCreditoRemove",
        "ReportesRelacionNotasCreditoAnular",
        "ReportesRelacionNotasCreditoPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReportesRelacionNotasCreditoNew", this.new);
    EventBus.$on("ReportesRelacionNotasCreditoSave", this.save);
    EventBus.$on("ReportesRelacionNotasCreditoRemove", this.remove);
    EventBus.$on("ReportesRelacionNotasCreditoAnular", this.remove);
    EventBus.$on("ReportesRelacionNotasCreditoPrintList", this.imprimirList);
    EventBus.$on("emitReportesRelacionNotasCredito", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({
    Rpropietario: [],
    RSucursal: [],
    RCaja: [],
    RTipoNota: [
      { codigo: 1, descripcion: 'PENDIENTES' },
      { codigo: 2, descripcion: 'SALDADAS' },
      { codigo: 3, descripcion: 'TODAS' },
    ],

    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    RelacionNC: {
      desde: fechaNueva(),
      hasta: fechaNueva(),
      caja: null,
      numeroCierre: 0,
      sucursal: null,
      tipo_nota: { codigo: 3, descripcion: 'TODAS' },
      nombre: 'RELACION DE NOTAS DE CREDITO',
      UrlImprimir: '/reportes-pv/ImprimirListRelacionNotasCredito',
      UrlExel: '/reportes-pv/ExcelListRelacionNotasCredito',
      UrlCorreo: '/reportes-pv/CorreoListRelacionNotasCredito',
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
    },
    datostabla(e) {
      this.RelacionNC = e;
      this.acti_tb_data = false
    },
    save() {
    },
    remove() {
    },
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
    },


    // Llamar a la API y obtener los datos de la base de datos.
    cargarCaja() {
      let headers = this.$store.getters.GetheadersAxios
      this.$axios.get(this.$hostname + this.$hName + '/cajas/findAllActivos', { headers })
        .then(res => (this.RCaja = res.data))
    },

   // Llamar a la API y obtener los datos de la API.
    cargarSucursal() {
      let headers = this.$store.getters.GetheadersAxios
      this.$axios
        .get(this.$hostname + this.$hName + '/sucursal/findallActivos/', { headers })
        .then(res => (this.RSucursal = res.data))
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {

      if (this.RelacionNC.sucursal == null) {

        this.RelacionNC.sucursal = ''
      }

      if (this.RelacionNC.sucursal.codigo == undefined) {

        this.alerta("Seleccione un sucursal", "error");
        this.RelacionNC.sucursal = ''
        return

      }
    },


   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCaja() {

      if (this.RelacionNC.caja == null) {

        this.RelacionNC.caja = ''
      }

      if (this.RelacionNC.caja.codigo == undefined) {

        this.alerta("Seleccione un caja", "error");
        this.RelacionNC.caja = ''
        return

      }
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    },
    //Retorna los datas del componete modalReporteFiltros
    DDataReporteBasico() {
    }
  }
};
</script>

<style>
</style>
