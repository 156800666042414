<script>
import { VTextField } from "vuetify/lib";
import AutoNumeric from "autonumeric/dist/autoNumeric.min"
const defaultOptions = {};
export default {
  extends: VTextField,
  props: {
    anOptions: { // autonumeric options (see doc)
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    options: {
                type    : [Object, String, Array],
                required: false,
                default() {
                    return defaultOptions;
                },
            },
  },
  data() {
    return {
      anElement: null, // autonumeric instance
      changedByInput: false, // Flag to know if the value is changed by user input 
      initialOptions    : null, // Store the options that were first used when initializing the component
      hasContentEditable: true, // Store if the element should be set as `contenteditable` on initialization
    };
  },
  mounted() {
    // Create the AutoNumeric instance on the VTextField input element
    var MAPOBJ= {...this.anOptions,...this.initialOptions}
    this.anElement = new AutoNumeric(this.$refs.input, MAPOBJ);
    // Set the AutoNumeric  default value
    this.anElement.set(this.value);
  },
  created() {
    if (Array.isArray(this.options)) {
                // This allows the user to use multiple options (strings or objects) in an array, and overwrite the previous one with the next option element ; this is useful to tune the wanted format
                let optionObjects = {};
                this.options.forEach(optionElement => {
                    this.initialOptions = this.manageOptionElement(optionElement);
                    optionObjects       = Object.assign(optionObjects, this.initialOptions); // Merge each options objects one after the other
                });

                this.initialOptions = optionObjects;
            } else {
                this.initialOptions = this.manageOptionElement(this.options);
            }
            // Define if the generated non-input element should have the contenteditable attribute set to `true`
            this.hasContentEditable = !this.initialOptions.readOnly;
  },
  methods: {
    manageOptionElement(optionElement) {
                let options;
                if (typeof optionElement === 'string' || optionElement instanceof String) {
                  console.log('Vienod 1')
                    options = AutoNumeric.getPredefinedOptions()[optionElement];
                    if (options === void(0) || options === null) {
                        // If the given `optionElement` does not exist, we switch back to the default options
                        console.warn(`The given pre-defined options [${optionElement}] is not recognized by AutoNumeric.\nSwitching back to the default options.`);
                        options = defaultOptions; // Default value
                    }
                } else { // A `settings` object
                    options = optionElement;
                    
                }

                return options;
            },


    onInput() {
      // User has changed the input
      this.changedByInput = true; // set the flag to true
      this.updateVModel(); // emit v-model
    },
    updateVModel() { // emit raw value
      if (this.anElement !== null) {
        this.$emit("input", this.anElement.getNumber());
      }
    },
    genInput() {
      const listeners = Object.assign({}, this.listeners$);
      delete listeners["change"];
      let element = this.$createElement("input", {
        style: {},
        attrs: {
          ...this.attrs$,
          autofocus: this.autofocus,
          disabled: this.disabled,
          id: this.computedId,
          placeholder: this.placeholder,
          readonly: this.readonly,
          type: this.type
          // contenteditable: this.hasContentEditable,
        },
        on: {
          blur: this.onBlur,
          input: this.onInput,
          focus: this.onFocus,
          keydown: this.onKeyDown,
          "autoNumeric:formatted": () => {
            this.changedByInput = false; // Remove the flag when autonumeric finish formatting
          }
        },
        ref: "input"
      });
      return element;
    }
  },
  watch: {
    value(newVal) {
      // Check if the last v-model update is fired by the input
      if (!this.changedByInput) {
        this.anElement.set(this.value); // Set the AutoNumeric raw value
      }
    }
  }
};
</script>