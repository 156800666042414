<template>
  <v-app id="inspire">
    <!-- <v-content> -->
    <!-- <v-container > -->
    <v-row>
      <v-col cols="4" lg="4" md="4" sm="4" v-show="abrirTablaDinamica">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <!-- <v-navigation-drawer > -->
            <BarraBusqueda2
              :url="$hostname + $hName + '/servicios/ticket/findall'"
              :nombre="'Tickets'"
              :headers="headers"
              :slotname="'item.ganadero[nombre]'"
              :emitnombre="'emitTicket'"
              :slotlist="slotlist"
            />
            <!-- </v-navigation-drawer> -->
          </template>
        </v-tooltip>
      </v-col>
      <v-col cols="12" lg="8" md="8" sm="8">
        <v-tooltip center>
          <template v-slot:activator="{ on }">
            <v-row>
              <v-row>
                <v-switch class="ma-4" label="Valid"></v-switch>
                <v-switch class="ma-4" label="Lazy"></v-switch>
              </v-row>
              <v-form ref="form">
                <v-text-field label="Name" dense outlined>
                  <template v-slot:prepend>
                    <vue-fontawesome
                      icon="file"
                      size="2"
                      color="blue"
                    ></vue-fontawesome>
                  </template>
                </v-text-field>
                   <v-text-field label="Name" dense outlined>
                  <template v-slot:prepend>
                    <vue-fontawesome
                      icon="file"
                      size="2"
                      color="blue"
                    ></vue-fontawesome>
                  </template>
                </v-text-field>

                   <v-text-field label="Name" dense outlined>
                  <template v-slot:prepend>
                    <vue-fontawesome
                      icon="file"
                      size="2"
                      color="blue"
                    ></vue-fontawesome>
                  </template>
                </v-text-field>

                <v-checkbox label="Do you agree?" required></v-checkbox>

                <v-btn color="success" class="mr-4">
                  Validate
                </v-btn>

                <v-btn color="error" class="mr-4">
                  Reset Form
                </v-btn>

                <v-btn color="warning">
                  Reset Validation
                </v-btn>
              </v-form>
            </v-row>
          </template>
        </v-tooltip>
      </v-col>
      <v-col cols="4" lg="4" md="4" sm="4" v-show="abrirTablaSegunda">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
           
            <BarraBusqueda
              :url="$hostname + $hName + '/servicios/ticket/findall'"
              :nombre="'Tickets'"
              :headers="headers"
              :slotname="'item.ganadero[nombre]'"
              :emitnombre="'emitTicket'"
              :slotlist="slotlist"
            />
            
          </template>
        </v-tooltip>
      </v-col>
    </v-row>

  </v-app>
</template>

<script>
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import BarraBusqueda from "../components/BarraBusquedaDinam";
import { EventBus } from "../event-bus.js";
export default {
  components: {
    BarraBusqueda2,
    BarraBusqueda

  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);

  },
  data() {
    return {
       rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },
   
      headers: [{ text: "Ganadero", value: "ganadero[nombre]" }],

      slotlist: [
        { dt0: "numeroTicket", dt1: null, dt2: null, dt3: 1, dt4: "#Ticket:" },
        { dt0: "ganadero", dt1: "nombre", dt2: null, dt3: 1, dt4: "Ganadero:" },
        { dt0: "fecha", dt1: null, dt2: null, dt3: 2, dt4: "Fecha:" },
        {
          dt0: "conductor",
          dt1: "nombre",
          dt2: null,
          dt3: 1,
          dt4: "Conductor:"
        },
        { dt0: "ruta", dt1: "descripcion", dt2: null, dt3: 1, dt4: "Ruta:" },
        {
          dt0: "ruta",
          dt1: "tipoRuta",
          dt2: "descripcion",
          dt3: 1,
          dt4: "Tipo Ruta:"
        }
      ],

      titulo: "Nombre del Formulario",
      toggle_exclusive: false,
      abrirTablaDinamica: true,
      abrirTablaSegunda:false
    };
  },
  methods: {
    abrirTabla() {
      // console.log("entro");
      this.abrirTablaDinamica = !this.abrirTablaDinamica;
    },
    abrirTabla2() {
      // console.log("entro");
      this.abrirTablaSegunda = !this.abrirTablaSegunda;
    },
  }
};
</script>
<style scoped></style>
