<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >

 <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Turnos'"
  :descripcion="'Crear, Modificar y Desactivar Turnos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle></v-btn-toggle>
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitESTTurnos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
                <!-- Columna 1 -------------------------------->
                <v-col cols="12" md="6" sm="6">
                  <!-------------------------------------- Descripcion-->
                  <v-text-field
                    v-model="Turnos.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Descripción:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

                  <!-------------------------------------- Hora entrada-->
                </v-col>
                <!-- fn columna 1 ------------------------------->

                <!-- columna 2 ---------------------------------->
             
                <v-col cols="12" md="6" sm="6">
                <span>Selecciones Los Dias:</span>

        <v-btn-toggle 
        dense   
          v-model="Turnos.DiasSelect"
          multiple
        >
          <v-btn small>
            <b>L</b>
          </v-btn>

          <v-btn small>
            <b>M</b>
          </v-btn>

          <v-btn small>
            <b>M</b>
          </v-btn>

          <v-btn small>
            <b>J</b>
          </v-btn>

           <v-btn small>
            <b>V</b>
          </v-btn>

           <v-btn small>
            <b>S</b>
          </v-btn>

           <v-btn small>
            <b>D</b>
          </v-btn>

        </v-btn-toggle>

            


                </v-col>
      <!-- fn columna 2 ---------------------------------------------->


<v-col cols="12" md="6" sm="6">


<v-text-field label="Hora Entrada:"
                     dense outlined
                     v-model="Turnos.horaEntrada"
                     autocomplete="off"
                     type="time"
             
                     :rules="[$rules.required]"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>



  <!-- <v-dialog
        ref="dialog"
        v-model="modalHentr"
        :return-value.sync="Turnos.horaEntrada"
        persistent
       
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="Turnos.horaEntrada"
            label="Hora Entrada:"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          >
          <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                </template>
          
          
          </v-text-field>
        </template>
        <v-time-picker
           landscape 
           ampm-in-title
          v-if="modalHentr"
          v-model="Turnos.horaEntrada"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modalHentr = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(Turnos.horaEntrada)">OK</v-btn>
        </v-time-picker>
      </v-dialog> -->


 

</v-col>
<v-col cols="12" md="6" sm="6">


<v-text-field label="Hora Salida:"
                     dense outlined
                     v-model="Turnos.horaSalida"
                     autocomplete="off"
                     type="time"
             
                     :rules="[$rules.required]"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>



<!-- <v-dialog
        ref="dialog1"
        v-model="modalHSali"
        :return-value.sync="Turnos.horaSalida"
        persistent
       
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="Turnos.horaSalida"
            label="Hora Entrada:"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          >
          <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                </template>
          
          
          </v-text-field>
        </template>
        <v-time-picker
           landscape 
           ampm-in-title
          v-if="modalHSali"
          v-model="Turnos.horaSalida"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modalHSali = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialog1.save(Turnos.horaSalida)">OK</v-btn>
        </v-time-picker>
      </v-dialog> -->




</v-col>



              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay 
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Descripcion', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] }
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :items-per-page="15"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   
   <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarTurno'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Turnos",
      descripcion: "Crear, Modificar y Desactivar Turnos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "ESTTurnosNew",
        "ESTTurnosSave",
        "ESTTurnosRemove",
        "ESTTurnosAnular",
        "ESTTurnosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ESTTurnosNew", this.new);
    // EventBus.$on("ESTTurnosSave", this.save);
    // EventBus.$on("ESTTurnosRemove", this.remove);
    // EventBus.$on("ESTTurnosAnular", this.remove);
    // EventBus.$on("ESTTurnosPrintList", this.imprimirList);
    EventBus.$on("emitESTTurnos", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {


      modalHentr:false,
      modalHSali:false,
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      Turnos: {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        horaEntrada:null,
        horaSalida:null,
        DiasSelect:[]
   
      },

      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },

     

      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],
      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {
      
      this.Turnos = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.Turnos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },


NotifMessage() {

if(this.Turnos.codigo!=0 && this.Turnos.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este turno?",
        Exec:"eliminarTurno"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un turno para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },




    remove() {


if(this.Turnos.codigo!=0 && this.Turnos.codigo!=null){

      this.Elim_notif.estado = !this.Elim_notif.estado;
}else{ this.alerta("Seleccione un tipo de cliente para eliminar", "error")}


    },
    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Turnos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.Turnos.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.Turnos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() {},
    newwnuevo() {
      (this.Turnos = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
         horaEntrada:null,
        horaSalida:null 
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },


computed:{

    ActDescBtn(){      
if(this.Turnos.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.Turnos.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

    }
    }


};
</script>

<style></style>
