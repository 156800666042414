<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Producto'" :descripcion="'Creación y Modificación de Productos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>



      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>
                <!-- <v-navigation-drawer >  -->





                <BarraDinamicaLimitePlus :PropsNombre="'Productos'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por producto'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'unidadesProductosPK.producto' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'unidadesProductosPK', Nv2: 'producto', Ctsl: 2, Type: 0, State: true },
  { Text: 'Productos', NomValue: 'productos', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
  { Text: 'Unidad', NomValue: 'unidades', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
  { Text: 'Existencia', NomValue: 'existencias', Ctsl: 1, Type: 0, State: true },
  { Text: 'Precio $', NomValue: 'precio', Ctsl: 1, Type: 2, State: true },
  { Text: 'Costo $', NomValue: 'costo', Ctsl: 1, Type: 2, State: true }
]" />
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <!-- <input
                  type="number"
                  v-model="form.FacturaProveedor.codigo"
                  style="display:none;"
                /> -->

                <v-card color="transparent">

                  <v-list-item>
                    <v-list-item-content>
                      <!-- windowSize:{width:"auto", height:"auto"}, -->
                      <h4>PRODUCTOS</h4>
                    </v-list-item-content>
                  </v-list-item>




                  <v-row>
                    <!-- columna 1 ------------------------------>
                    <v-col cols="12" md="4" sm="6">

                      <!------------------------------------------ Codigo -->
                      <v-text-field label="Codigo:" dense outlined v-model="form.Producto.codigo" :disabled="true">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------------------ Nombre -->
                      <v-text-field v-model="form.Producto.des1" :rules="[$rules.required]" autocomplete="off"
                        label="* Descripción:" required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------------------ Descuento -->
                      <AutoNumeric label="Descuento Máximo:" required outlined dense v-model="form.Producto.des1">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>
                      </AutoNumeric>

                      <!------------------------------------------ img -->
                      <v-file-input dense outlined accept="image/png, image/jpeg, image/bmp" @change="ConvertirImg"
                        ref="files" id="foto" show-size counter small-chips v-model="form.cargaImg" multiple
                        prepend-icon="mdi-camera" label="Elegir Foto">
                      </v-file-input>


                    </v-col>
                    <!-- fn columna 1 ------------------------------>

                    <!-- columna 2 ------------------------------>
                    <v-col cols="12" md="4" sm="6">


                      <!------------------------------------------ Codigo Anterior -->
                      <v-text-field label="Código Anterior:" dense outlined v-model="form.Producto.codigo"
                        :disabled="true">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                        </template>
                      </v-text-field>


                      <!-------------------------------------------------- costo -->

                      <AutoNumeric label="Costo:" dense outlined autocomplete="off" v-model.number="form.Producto.des1">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </AutoNumeric>
                      <!-- ----------------------------- Referencia -->

                      <v-text-field label="Referencia:" autocomplete="off" dense outlined v-model="form.Producto.des1">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------- avatar img -->
                      <v-avatar size="100" tile>
                        <v-img :src="getImgUrl()"></v-img>
                      </v-avatar>


                    </v-col>
                    <!-- fn columna 2 ------------------------------>

                    <!-- columna 3 ------------------------------>
                    <v-col cols="12" md="4" sm="6">


                      <!-------------------------------------------------- Impuesto -->

                      <!-- @blur="SelctCbProyecto",@focus="cargarProyecto"  -->
                      <v-combobox autocomplete="off" dense outlined label="Impuesto" :items="RImpuesto"
                        item-text="nombre" :rules="[$rules.required]" v-model="form.Producto.des1">

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>

                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="dialogNewImpuesto = true" v-on="on" color="green" fab x-small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>

                            </template>
                            <span>Nuevo Impuesto</span>
                          </v-tooltip>


                        </template>

                      </v-combobox>

                      <!-------------------------------------------------- Precio -->

                      <AutoNumeric label="Precio:" dense outlined autocomplete="off" v-model.number="modalunidad.des">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </AutoNumeric>


                      <!-- Tipo de Inventario --------------------------------------------------->
                      <v-combobox v-model="form.Producto.des1" label="Tipo de Inventario:" required outlined dense
                        :rules="[$rules.required]" autocomplete="off" :items="RTipoInv" item-text="descripcion"
                        @focus="CargarRTiInv" @blur="SelctCbTipoInv">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-tag"></i>
                        </template>
                      </v-combobox>
                    </v-col>
                    <!-- fn columna 3 ------------------------------>

                    <!-- columna 4 ------------------------------>
                    <!-- <v-col cols="12" md="3" sm="6">


</v-col> -->
                    <!-- columna 4 ------------------------------>
                  </v-row>


                </v-card>




                <v-card color="transparent">

                  <v-list-item>
                    <v-list-item-content>
                      <h4>CONFIGURACIÓN DE PRODUCTO</h4>
                    </v-list-item-content>
                  </v-list-item>

                  <v-row>
                    <!-- columna 5 ------------------------------>
                    <v-col cols="12" md="4" sm="6">

                      <!------------------------------------------------ Activo-->
                      <v-switch dense v-model="form.Producto.des3" label="Activo" color="success" hide-detail>
                      </v-switch>


                      <!------------------------------------------------ Imprimir etiqueta-->
                      <v-switch dense v-model="form.Producto.des3" label="Imprimir etiqueta" color="success"
                        hide-detail></v-switch>

                      <!------------------------------------------------ Servicio-->
                      <v-switch dense v-model="form.Producto.des3" label="Servicio" color="success" hide-detail>
                      </v-switch>


                    </v-col>

                    <!-- columna 6 ------------------------------>
                    <v-col cols="12" md="4" sm="6">

                      <!------------------------------------------------ Calcular precio-->
                      <v-switch dense v-model="form.Producto.des3" label="Calcular precio" color="success" hide-detail>
                      </v-switch>


                      <!------------------------------------------------ Digitar Cantidad al vender-->
                      <v-switch dense v-model="form.Producto.des3" label="Digitar Cantidad al vender" color="success"
                        hide-detail></v-switch>

                    </v-col>

                    <!-- columna 7 ------------------------------>



                    <!-- columna 8 ------------------------------>
                    <v-col cols="12" md="4" sm="6">



                      <!------------------------------------------------ Pedir Nombre en el Punto de ventas-->
                      <v-switch dense v-model="form.Producto.des3" label="Pedir Nombre en el Punto de ventas"
                        color="success" hide-detail></v-switch>

                      <!------------------------------------------------ Pedir Nombre en el Punto de ventas-->
                      <v-switch dense v-model="form.Producto.des3" label="Pedir precio en Punto de ventas"
                        color="success" hide-detail></v-switch>


                    </v-col>

                  </v-row>
                </v-card>

              </v-form>




            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">



          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt = true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.producto" @keyup.enter="CargarListTb" label="Búsqueda por producto"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'unidadesProductosPK.producto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Productos', value: 'productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Nombre Corto', value: 'productos.descripcioncorta', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Unidad', value: 'unidades.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Existencia', value: 'existencias', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Precio $', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Costo $', value: 'costo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fabricante', value: 'productos.fabricante.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Departamento', value: 'productos.departamento.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Existencia minima', value: 'productos.existenciaminima', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Existencia maxima', value: 'productos.existenciamaxima', sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
              :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.producto"
              @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.precio="props">
                <span>{{ currencyFormatter(props.item.precio) }}</span>
              </template>

              <template v-slot:item.costo="props">
                <span>{{ currencyFormatter(props.item.costo) }}</span>
              </template>



              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == true">Activo</span>
                <span v-if="props.item.activada == false">Anulado</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Modal- agregar Unidades-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogModalUnidades" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo">

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> Unidades </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="cerrarmodalUnidades">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>
          <v-form ref="form2" v-model="valid" lazy-validation>
            <v-row>


              <!-- Modal unidad Columna 1 -->
              <v-col cols="12" md="6">

                <!--:item-text="RfilCuenta", @blur="SelctCbcuenta",@keyup="CbFilModalCuenta",@change="SelctCbCuentaValidControl",@focus="CargarRcuenta"  -->
                <!------------------------------------------ unidad -->
                <v-combobox v-model="modalunidad.des" label="* Unidad:" required outlined dense
                  :rules="[$rules.required]" autocomplete="off" :items="Runidad">

                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col cols="6" md="6"> <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                      <v-col cols="6" md="6"> <b style="font-size: 18px;">Descripción: </b> </v-col>
                    </v-row>
                  </template>




                  <template slot="item" slot-scope="item" margin-top="50px">
                    <v-row>
                      <v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
                      <v-col cols="6" md="6">{{ item.item.descripcion }}</v-col>
                    </v-row>

                  </template>



                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn @click.prevent="acti_Nueva_cuenta = !acti_Nueva_cuenta" color="secondary" fab x-small dark>
                      <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                    </v-btn>
                  </template>
                </v-combobox>

                <AutoNumeric label="Factor:" dense outlined autocomplete="off" v-model.number="modalunidad.des">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </AutoNumeric>

                <AutoNumeric label="Beneficio:" dense outlined autocomplete="off" v-model.number="modalunidad.des">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </AutoNumeric>


                <v-switch dense v-model="modalunidad.des3" label="Unidad compra" color="success" hide-detail></v-switch>



              </v-col>


              <!-- Modal unidad Columna 2 -->
              <v-col cols="12" md="6">

                <!------------------------------------------ Codigo barra -->
                <v-text-field label="Código Anterior:" dense outlined v-model="modalunidad.des" :disabled="true">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-barcode"></i>
                  </template>
                </v-text-field>


                <AutoNumeric label="Costo:" dense outlined autocomplete="off" v-model.number="modalunidad.des">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </AutoNumeric>


                <AutoNumeric label="Precio:" dense outlined autocomplete="off" v-model.number="modalunidad.des">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </AutoNumeric>
                <v-switch dense v-model="modalunidad.des3" label="Unidad Base" color="success" hide-detail></v-switch>



              </v-col>

            </v-row>
          </v-form>
        </v-card-text>


        <hr />
        <v-btn-toggle rounded class="d-flex justify-end">

          <v-btn color="green darken-1" @click.prevent="saveCuenta">
            <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
            Salvar
          </v-btn>

          <v-btn v-if="modalunidad.codigo != 0" color="red" @click.prevent="removeCuenta">
            <i style="font-size: 28px;" class="fa fa-trash"> </i>
            Eliminar
          </v-btn>

        </v-btn-toggle>
        <hr />



      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar Unidades-->



    <!-- ------------------------------------------------------ Modal- crear Producto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogCrearProducto" persistent
      max-width="80%" max-height="70%">
      <CrearProducto @EmitCrearProducto="RecCrearProducto" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- crear producto-->

    <!-- ------------------------------------------------------ Modal- nueva Produecto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogAddProducto" persistent
      max-width="80%" max-height="70%">

      <AgregarProducto :statusFoms="dialogAddProducto" @EmitAgregarProducto="RecAgregarProducto" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- nueva producto-->





    <!-- ------------------------------------------------------ Modal Nuevo impuesto -->
    <v-dialog transition="fab-transition" v-model="dialogNewImpuesto" persistent max-width="60%" max-height="50%">
      <v-form ref="formNewFabricante" v-model="valid" lazy-validation>


        <v-card>

          <v-toolbar flat color="indigo">

            <i style="font-size: 20px;" class=""> </i>
            <v-toolbar-title style="color:white;" class="font-weight-light"> <b> Creación de Nuevo Impuesto</b>
            </v-toolbar-title>
            <i style="font-size: 20px;" class=""> </i>

            <v-spacer></v-spacer>
            <v-btn color="grey darken-4" fab small @click="dialogNewImpuesto = !dialogNewImpuesto">
              <!--  CerrarFiltro() -->
              <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <br>


            <v-row>


              <input type="number" v-model="NewImpuesto.codigo" style="display:none;" />



              <!-- -----------------------------  columna-1 -->
              <v-col cols="12" md="12">


                <!-------------------------------------------------- nombre -->
                <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="NewImpuesto.nombre">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>





                <!-- ----------------------------- descuento -->
                <AutoNumeric label="* Tasa:" required outlined dense v-model="NewImpuesto.tasa"
                  :rules="[$rules.required, $rules.porciento]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>
                </AutoNumeric>




              </v-col>
              <!-- ----------------------------- fn columna-1 -->

            </v-row>


          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn-toggle rounded class="">



              <v-btn color="#90A4AE" @click.prevent="NewImpuesto.codigo = 0,
              NewFabricante.nombre = null, NewFabricante.activo = true">
                <i style="font-size: 28px;" class="fa fa-file-o"> </i>
                Nuevo
              </v-btn>

              <v-btn color="green" @click.prevent="SaveImpuesto">
                <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
                Guardar
              </v-btn>

            </v-btn-toggle>

          </v-card-actions>

        </v-card>
      </v-form>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Nuevo Impuesto-->






    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";

import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    AutoNumeric,
    CrearProducto,
    AgregarProducto,
    ContenidoBase,
    BarraDinamicaLimitePlus
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitINVProducto", this.datostabla);
    // EventBus.$on("INVProductoNew", this.new);
    // EventBus.$on("INVProductoSave", this.save);
    // EventBus.$on("INVProductoRemove", this.remove);
    // EventBus.$on("INVProductoAnular", this.anular);
    // EventBus.$on("INVProductoPrintList", this.imprimirList);
    // EventBus.$on("INVProductoVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);
    this.filtro();

    this.tipoAlmacen = JSON.parse(JSON.stringify(this.$RTipoInventario))

  },

  created() {
    var json = {
      titulo: "Producto",
      descripcion: "Creación y Modificación de Productos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: false,

      accion: [
        "INVProductoNew",
        "INVProductoSave",
        "INVProductoRemove",
        "INVProductoAnular",
        "INVProductoPrintList",
        "INVProductoVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      tab: null,

      valid: true,
      ejecucion: true,
      dialogAddProducto: false,
      dialogCrearProducto: false,
      dialogOrdenSugerida: false,


      dialogModalUnidades: false,
      dialogNewImpuesto: false,


      dialogFiltros: false,
      Modallfiltro: {
        producto: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },


      proveedor: null,
      producto: null,

      RImpuesto: [],


      RTipoInv: [],
      Runidad: [],


      RallProducto: [],
      Ralmacen: [],

      aalert: {
        estado: false,
        color: null
      },

      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },

      search: '',
      ObjTemp: null,
      ObjTempUnidad: null,

      modalunidad: {
        codigo: 0,
        des: null
      },






      NewImpuesto: {

        codigo: 0,
        nombre: null,
        tasa: 0

      },





      form: {

        imagen: null,
        cargaImg: null,

        Producto: {
          codigo: 0,
          des1: null,
          des2: "",
          des3: true,
        },


        OrdenSugerida: {
          desde: new Date().toISOString().substr(0, 10),
          hasta: new Date().toISOString().substr(0, 10),
          dia: 0,
          proveedor: null,
          almacen: null,
          opcion: 1
        },






        TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },

        ListProd: [],


        FacturaProveedor: {

          fechaorde: null,
          moneda: null,
          proveedor: null,
          direccionEnvio: '',
          concepto: '',
          descuentoIndividual: 0,
          tasa: 0,
          documento: null

        }

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },


      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],

      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },


    getImgUrl() {

      console.log(this.form.cargaImg)


      //   var images = require("../assets/" + pet);
      var images
      if (this.form.cargaImg == null || this.form.cargaImg.length == 0) {
        images = require("@/assets/img/Nofoto.png");

      } else {
        if (this.form.imagen != null) {

          images = this.form.imagen

        }
      }
      //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
      // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },

    ConvertirImg() {
      var _this = this;

      var filesSelected = document.getElementById("foto").files;
      if (filesSelected.length > 0) {
        var fileToLoad = filesSelected[0];

        var fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64

          var newImage = document.createElement("img");
          newImage.src = srcData;
          _this.form.imagen = srcData;


          // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
          // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
          // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },


    // <!-- form:{ prepend-icon="mdi-camera"

    //  imagen:null,
    //  cargaImg:null, -->














    RecAgregarProducto(e) {


      if (e.estado == 'cerrar') {

        this.dialogAddProducto = e.false

      }


      if (e.estado == 'datos') {

        console.log('emitiendo datos')
        console.log(e.datos)

      }




    },

    RecCrearProducto(e) {

      this.dialogCrearProducto = e.estado
    },


    cerrarmodalUnidades() {

      this.dialogModalUnidades = false


    },


    SaveFabricante() {



    },






    SaveImpuesto() {

    },



    vercuenta() {


    },


    VerFacturaProveedor() {



    },


    CargarRTiInv() {


      this.RTipoInv = JSON.parse(JSON.stringify(this.$RTipoInventario))

    },


    SelctCbTipoInv() {


      // if (this.form.FacturaProveedor.tipogasto == null) {
      //         this.form.FacturaProveedor.tipogasto = "";
      //       }

      //       if (this.form.FacturaProveedor.tipogasto.codigo == undefined) {
      //         this.alerta("Selecciones un tipo gasto", "error");
      //         this.form.FacturaProveedor.tipogasto = "";
      //         return;
      //       }

    },








    CargarRalmacen() {
      this.$axios
        .post(this.$hostname + this.$hName + "/services/Almacen/findall", {})
        .then(res => {
          this.Ralmacen = res.data;
        });
    },

    SelctCbAlmacen() {
      if (this.form.OrdenSugerida.almacen == null) {
        this.form.OrdenSugerida.almacen = "";
      }

      if (this.form.OrdenSugerida.almacen.codigo == undefined) {
        this.alerta("Selecciones un Almacén", "error");
        this.form.OrdenSugerida.almacen = "";
        return;
      }
    },






    keyupDiaOrden() {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.OrdenSugerida.dia = mynumeral(this.form.OrdenSugerida.dia).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.form.OrdenSugerida.dia == null) {

          this.form.OrdenSugerida.dia = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }

    },


    addClikProd() {




    },



    cerrarModalAddProd() {

      this.dialogAddProducto = false

      // if(this.ObjTemp!=null){

      // this.form.ListProd.push(this.ObjTemp)


      // }

      this.newProducto()


    },

    newCrearProducto() {



    },



    newProducto() {


      this.ObjTemp = null

    },





    save() {
      console.log(this.$refs.form);

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;

          this.$axios
            .post(
              this.$hostname + this.$hName + "/services/TarjetasCredito/save",
              JSON.parse(JSON.stringify(this.TipoProveedor))
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      if (this.TipoProveedor.codigo != 0 && this.TipoProveedor.codigo != null) {
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/services/TarjetasCredito/remove",
              JSON.parse(JSON.stringify(this.TipoProveedor))
            )
            .then(res => this.alerta("Dato eliminado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      }
    },

    anular() {
      console.log("anular");
    },
    imprimirList() {
      console.log("imprimir");
    },
    newwnuevo() {
      this.TipoProveedor = {
        codigo: 0,
        nombre: null,
        porciento: 0
      };

      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    datostabla(e) {
      this.Producto = e.Productos;
      this.acti_tb_data = false;
      //console.log(e);
    },





    SelctCbProveedorOrden() {

      if (this.form.OrdenSugerida.proveedor == null) {

        this.form.OrdenSugerida.proveedor = ''
      }

      if (this.form.OrdenSugerida.proveedor.codigo == undefined) {

        this.alerta("Selecciones una proveedor", "error");
        this.form.OrdenSugerida.proveedor = ''

        //qlq2

        return

      }



    },


    async filtro() {
      // await this.$axios
      //   .post(
      //     this.$hostname + this.$hName + "/services/TarjetasCredito/findall",
      //     {}
      //   )
      //   .then(res => {
      //     this.list = res.data;
      //   });
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.CargarListTb() }
    },

    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },


    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      console.log('Entrando Al actualiza_tb_ver ')
      console.log(this.acti_tb_data)

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        producto: null
      }


      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.producto != null && this.Modallfiltro.producto.toString().length == 0) { this.Modallfiltro.producto = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.producto)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.producto = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.producto
      this.$axios.get(this.$hostname + this.$hName + '/producto/findListProductoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },




    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },
  computed: {

    ActDescBtn() {


      if (this.form.Producto.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }


      if (this.form.Producto.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }




    }

  }




};
</script>

<style>
</style>
