<template>
<div>{{ActivarBotones}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px"  > 
<ContenidoBase 
  :titulo="'Tipos de proveedores'"
  :descripcion="'Crear, Modificar y Desactivar Tipos de Proveedores'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
  
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <!--------------------- aqui va la otra tabla izquierda --------->

            <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>


<BarraDinamicaNormal
              ref="BarraDiNor"
              :url="$hostname + $hName + '/tipoproveedor/findall'"
              :nombre="'Tipo Proveedor'"
              :emitnombre="'emitTipoProveedor'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
              { text: 'Código', value: 'codigo'},
              { text: 'Descripción', value: 'descripcion' }, 
              { text: 'Porcentaje ISR:', value: 'retencion' }, 
              ]"

              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Porcentaje ISR',NomValue:'retencion', Ctsl:1, Type:2, State:true},
              ]"

            />



            <!-- <v-navigation-drawer > 
            <BarraBusqueda2
              :url="$hostname + $hName + '/services/TipoProveedorCXP/findall'"
              :nombre="'Tipo Proveedor'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitTipoProveedor'"
              :SeachNombre="'Búsqueda por descripción'"
              :slotlist="slotlist"
            /> -->


            <!-- </v-navigation-drawer> -->
          </template>
        </v-tooltip>

     


      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
<div class="px-2">
   
<v-card color="grey lighten-3">
<div class="px-2">
 <v-form ref="form" v-model="valid" lazy-validation>
                <input
                  type="number"
                  v-model="form.TipoProveedor.codigo"
                  style="display:none;"
                />
                <v-row>
                  <!-- fila 1 ------------------------------>
                  <v-col cols="12" md="12" sm="12">
                    <!-- Nombre:-->
                      <v-text-field
                        label="* Descripción:"
                        autocomplete="off"
                        dense
                        outlined
                        :rules="[$rules.required]"
                        v-model="form.TipoProveedor.descripcion"
                      >
                      
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <!-- fn Documentos:-->

                    <!-- Porcentaje:-->
                    <v-col cols="12" md="6" sm="6">
                      <AutoNumeric
                        autocomplete="off"
                        label="* Porcentaje ISR:"
                        dense
                        outlined
                        :rules="[$rules.porciento]"
                        v-model="form.TipoProveedor.retencion"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-list-alt"
                          ></i>
                        </template>
                      </AutoNumeric>
                    </v-col>
                    <!-- fn Porcentaje
//fa fa-file-text-o-->
                 
                  <!-- fn fila 1 ------------------------------>
                </v-row>
              </v-form>
</div>
</v-card>
       
</div>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
 <!-- ------------------------------------------------------ tb-data-->
            <v-dialog
              label="myAlert"
              v-model="acti_tb_data"
              persistent
              transition="dialog-bottom-transition"
              fullscreen hide-overlay 
            >
              <v-card>
                <v-toolbar flat color="#BDBDBD">
                  <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
                  <v-toolbar-title class="font-weight-light">
                    REPORTE
                  </v-toolbar-title>
                  <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

                  <v-spacer></v-spacer>
                  <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on" v-if="false"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
                  <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="acti_tb_data = !acti_tb_data"
                  >
                    <i
                      style="font-size: 20px; color:#dd4b39;"
                      class="fa fa-close"
                    ></i>
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <v-card>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        @keyup="filtro"
                        label="Buscar"
                        single-line
                        hide-details
                      >
                        <template v-slot:prepend-inner>
                          <i style="font-size: 35px;" class="fa fa-search"></i>
                        </template>
                      </v-text-field>
                    </v-card-title>

                    <v-data-table
                      :headers="[
                      { text: 'Código', value: 'codigo', class: ['no-gutters black--text'] },
                      { text: 'descripción', value: 'descripcion', class: ['no-gutters black--text'] },
                      { text: 'Porcentaje ISR', value: 'retencion', class: ['no-gutters black--text'] }
                      ]"
                      :items-per-page="15"
                      dense
                      :items="list"
                      :search="search"
                      @click:row="datostabla"
                    >
                      <template v-slot:item.retencion="props">
                        <span>{{
                          currencyFormatter(props.item.retencion)
                        }}</span>
                      </template>








                      
                    </v-data-table>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- ------------------------------------------------------ fn tb-data-->

            <!-- ------------------------------------------------------ Elim_notif-->
            <v-dialog
              label="myAlert"
              v-model="Elim_notif.estado"
              persistent
              max-width="350"
              transition="fab-transition"
            >
              <template justify="center" v-slot:activator="{ on }"></template>

              <v-card>
                <v-card-title>
                  <h4>
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                    CONFIRMACION
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                  </h4>
                </v-card-title>
                <hr />

                <v-card-text>
                  <h5>{{ Elim_notif.nombre }}</h5>
                </v-card-text>

                <hr />
                <v-card-actions>
                  <v-btn
                    @click.prevent="
                      eliminar(), (Elim_notif.estado = !Elim_notif.estado)
                    "
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-check"></i> Si
                  </v-btn>

                  <v-btn
                    @click.prevent="Elim_notif.estado = !Elim_notif.estado"
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-ban"></i> No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ------------------------------------------------------  fn Elim_notif -->

            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
 

<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->


<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
  
</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
     EventBus.$on("emitTipoProveedor", this.datostabla);
    // EventBus.$on("cxpTipoProveedorNew", this.new);
    // EventBus.$on("cxpTipoProveedorSave", this.save);
    // EventBus.$on("cxpTipoProveedorRemove", this.remove);
    // EventBus.$on("cxpTipoProveedorAnular", this.anular);
    // EventBus.$on("cxpTipoProveedorPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
  },

  created() {
    var json = {
      titulo: "Tipos de proveedores",
      descripcion:
        "Crear, Modificar y Desactivar Tipos de Proveedores",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,

      accion: [
        "cxpTipoProveedorNew",
        "cxpTipoProveedorSave",
        "cxpTipoProveedorRemove",
        "cxpTipoProveedorAnular",
        "cxpTipoProveedorPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize:{width:"auto", height:"auto"},
      valid: true,
      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },


dialogimprimirList:false,
datosImprecion:{
nombre:'TIPOS DE PROVEEDORES',
 UrlImprimir: '/tipoproveedor/imprimirListado', 
 UrlExel: '/tipoproveedor/excelListado', 
 UrlCorreo: '/tipoproveedor/correoListado', 
},




      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },


form:{
      TipoProveedor: {
        codigo: 0,
        descripcion: null,
        retencion: 0,
        fechaCreacion: new Date().toISOString().substr(0,10)

      },


      Usuario:{
              
              user:this.$User,
              codeUser:this.$CodeUser

      }

},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

      headers: [{ text: "Descripción", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "descripcion", dt1: null, dt2: null, dt3: 1, dt4: "descripción:" },
        { dt0: "retencion", dt1: null, dt2: null, dt3: 5, dt4: "Porcentaje ISR:" },
      ],


      

      list: [],
      search: "",
      acti_tb_data: false,

       BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {


onResize(e){

// console.log("imprii ")
// console.log(e)
// this.windowSize=e

},

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;


var jsn={
   TipoProveedor:JSON.parse(JSON.stringify(this.form.TipoProveedor)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

          this.$axios
            .post(
              this.$hostname + this.$hName + "/tipoproveedor/save",this.form.TipoProveedor,
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => {this.alerta("Datos guardados correctamente!", "bien")})
            .catch(error => {
              console.log(error)
            this.alerta("Este tipo de proveedor ya existe !!!", "error")
            });
        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
    remove() {
       if (this.form.TipoProveedor.codigo != 0 && this.form.TipoProveedor.codigo != null) {
      this.Elim_notif.estado = !this.Elim_notif.estado;}
    },

    eliminar() {
      if (this.form.TipoProveedor.codigo != 0 && this.form.TipoProveedor.codigo != null) {
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/tipoproveedor/borrar",this.form.TipoProveedor,
              {headers:this.$store.getters.GetheadersAxios}
              
            )
            .then(res =>  {
                if (res.data){
                  this.alerta("Datos eliminados correctamente", "bien")
                }})
            .catch(error => this.alerta('Dato no eliminado correctamente:'+error, "error"));
        }
      }
    },

    anular() {
      console.log("anular");
    },
    imprimirList() {  this.dialogimprimirList=true},

    DDataReporteBasico(e){
this.dialogimprimirList=e
},

    newwnuevo() {

this.BarraTablaDinamicaAprir = false

      this.form.TipoProveedor = {
        codigo: 0,
        descripcion: null,
        retencion: 0,
        fechaCreacion: new Date().toISOString().substr(0,10)

      };

      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    datostabla(e) {

      var Obj = JSON.parse(JSON.stringify(e))
      if(e.retencion==null){
       Obj.retencion=0
      }
    
      this.form.TipoProveedor = Obj;
      this.acti_tb_data = false;
      //console.log(e);
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/tipoproveedor/findall",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => {
          this.list = res.data;
        });
    },

    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }


    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    }
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

computed:{

ActivarBotones(){
if(this.form.TipoProveedor.codigo!=0){

  
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }

}else{


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }

}

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
//EventBus.$emit("ActDesaBotones", json);

},
}



};
</script>

<style></style>
