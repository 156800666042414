<template>
 <div>{{GetEjecucion}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Registro de cortesía Medica'"
  :descripcion="'Para realizar los registros de cortesía medicas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>

<BarraDinamicaNormal
              :url="$hostname + $hName + '/registrocortesia/findall/'"
              :nombre="'Cortesia Medica'"
              :emitnombre="'emitTranspCortesiaMedica'"
              :SeachNombre="'Búsqueda general'"

      :headers="[
        { text: 'Código', value: 'id'},
        { text: 'Cantidad', value: 'cantidad'},
        { text: 'Fecha Expiracion', value: 'fechaExpiracion'},
        { text: 'Centro Salud', value: 'centroSalud'},
        { text: 'Indicación', value: 'indicacionesMedicas'},
        { text: 'Nombre', value: 'persona.nombre'},
        { text: 'Cedula', value: 'persona.cedula'},
        { text: 'Telefono', value: 'persona.telefono'},
        { text: 'Ciudad', value: 'persona.ciudad'},
        { text: 'Direccion', value: 'persona.direccion'},
        { text: 'E-mail', value: 'persona.email'},  
        { text: 'Activo', value: 'activo'}
           ]"
              :ContListSlot="[
              {Text:'', NomValue:'persona', Nv2:'foto', Ctsl:2, Type:3, State:true},
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Cantidad', NomValue:'cantidad', Ctsl:1, Type:2, State:true},
              {Text:'Expiración', NomValue:'fechaExpiracion', Ctsl:1, Type:1, State:true},
              {Text:'Centro de Salud', NomValue:'centroSalud', Ctsl:1, Type:0, State:true},
              {Text:'Indicación', NomValue:'indicacionesMedicas', Ctsl:1, Type:0, State:true},
              {Text:'Nombre', NomValue:'persona', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Cedula', NomValue:'persona', Nv2:'cedula', Ctsl:2, Type:0, State:true},
              {Text:'Telefono', NomValue:'persona', Nv2:'telefono', Ctsl:2, Type:0, State:true},
              {Text:'Ciudad', NomValue:'persona', Nv2:'ciudad', Ctsl:2, Type:0, State:true},
              {Text:'Dirección', NomValue:'persona', Nv2:'direccion', Ctsl:2, Type:0, State:true},
              {Text:'E-mail', NomValue:'persona', Nv2:'email', Ctsl:2, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ]"
            />
          </template>
        </v-tooltip>


      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container><!-- style="border: 1px solid #000000;" -->
  <v-form ref="formCortesiaMedica" @submit.prevent=""  v-model="valid" lazy-validation>

 <v-row>
<!-- columna 1 ----------------------------------------------->
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="CortesiaMedica.persona.nombre"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Nombre y Apellido:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                </v-col>
<!-- fn columna 1 ----------------------------------------------->
</v-row>

<v-row style="margin-top:-23px;">
  
<!--  columna 2 ----------------------------------------------->
<v-col cols="12" md="4" sm="4" >

<!-- -----------------------------  Codigo -->
                  
                  <v-text-field 
                    label="Codigo cedula:"
                    autocomplete="off"
                    dense
                    outlined
                    :rules="[$rules.required]"
                    v-model="CortesiaMedica.persona.codigoCedula"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                    </template>
                  </v-text-field>
</v-col>

<!-- fn columna 2 ----------------------------------------------->

<!--  columna 3 ----------------------------------------------->
<v-col cols="12" md="4" sm="4" >

 <!-- ----------------------------------------- cedula :rules="[$rules.required, $rules.RulesCel]" -->
<v-text-field
            v-mask="maskCedula"
            label="Cédula:"
            @input="MaskChange"
            autocomplete="off"
            dense
            outlined
            maxlength="13"
            :rules="[$rules.required,$rules.RulesCel]"
            v-model="CortesiaMedica.persona.cedula"
          >
          
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
              </template>
       
          </v-text-field>
</v-col>

<v-col cols="12" md="4" sm="4">

<!-- -----------------------------  tb telefonos -->
<v-text-field
          
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
            maxlength="13"
            @input="MaskChange"
            v-mask="maskTelefono"
            :rules="[$rules.required,$rules.RulesTel]"
            v-model="CortesiaMedica.persona.telefono"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>

          </v-text-field> 

</v-col> 
</v-row>

<v-row style="margin-top:-23px"> 
<v-col cols="12" md="4" sm="4">

  <v-text-field
                    v-model="CortesiaMedica.centroSalud"
                    autocomplete="off"
                    label="Centro de Salud:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-hospital-o"></i>
                    </template>
                  </v-text-field>
</v-col>

<v-col cols="12" md="4" sm="4">

<!-- ----------------------------- Cantidad -->
<VueAutonumeric 
                      label="Cantidad de cortesia:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      :rules="[$rules.required, $rules.NumNocero]"
                      outlined
                      dense
                      v-model="CortesiaMedica.cantidad"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-sort-numeric-asc"></i>
                      </template>
                    </VueAutonumeric>


</v-col>


<v-col cols="12" md="4" sm="4">
  
   <!-- -----------------------------  email -->
             <v-text-field label="E-mail:"
                     dense outlined
                     autocomplete="off"
                     v-model="CortesiaMedica.persona.email"
                     :rules="[$rules.email]"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>  

</v-col>

</v-row>

<v-row style="margin-top:-23px">
<v-col cols="12" md="6" sm="6">

<!-- ----------------------------- direccion -->

                  <v-textarea
                  autocomplete="off"
                    label="Direccion:"
                    rows="3"
                    dense
                    outlined
                    v-model="CortesiaMedica.persona.direccion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>


<!-- -----------------------------  Fecha Expiración  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Expiración:"
        v-model="CortesiaMedica.fechaExpiracion"
        :rules="[$rules.required]"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>

<!-- ----------------------------- Ciudad -->

                  <v-textarea
                  autocomplete="off"
                    label="Indicaciones Médica:"
                    rows="3"
                    dense
                    outlined
                    v-model="CortesiaMedica.indicacionesMedicas"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-file-text-o"></i>
                    </template>
                  </v-textarea>

</v-col>

<v-col cols="12" md="6" sm="6" >

<!-- ----------------------------- Ciudad -->

                  <v-textarea
                  autocomplete="off"
                    label="Ciudad:"
                    rows="3"
                    dense
                    outlined
                    v-model="CortesiaMedica.persona.ciudad"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map"></i>
                    </template>
                  </v-textarea>

           <!-- ----------------------------- foto -->       

                  <v-file-input       
        dense
        outlined  
        accept="image/png, image/jpeg, image/bmp"
        @change="ConvertirImg"
        ref="files"
        id="foto"
        show-size  
        counter           
        small-chips
        v-model="cargaImg"
        multiple
        prepend-icon="mdi-camera"
        label="Elegir Foto"
      >
<template v-slot:append-outer>

                  <v-tooltip top>
                <template v-slot:activator="{ on }"> 
                  
                <i style="font-size: 20px;" v-on="on" class="fa fa-eye"></i>
                </template>

<v-avatar  
                  size="230"
                  tile
                >
                  <v-img :src="CortesiaMedica.persona.foto"></v-img>
             </v-avatar> 

                          <!-- <span>Buscar Solicitud</span> getImgUrl() -->
                        </v-tooltip> 
                        
                  </template>
      </v-file-input>



                  <v-switch
                    label="Activo"
                    v-model="CortesiaMedica.activo"
                  ></v-switch>
</v-col>
</v-row>         
            </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

<v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                   @click.prevent="imprimirList()"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>




          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>


            <v-data-table
              :headers="[
        { text: 'Código', value: 'id', width:90},
        { text: 'Cantidad', value: 'cantidad', width:150},
        { text: 'Fecha Expiracion', value: 'fechaExpiracion', width:150},
        { text: 'Centro Salud', value: 'centroSalud', width:150},
        { text: 'Indicación', value: 'indicacionesMedicas', width:300},
        { text: 'Nombre', value: 'persona.nombre', width:300},
        { text: 'Cedula', value: 'persona.cedula', width:150},
        { text: 'Telefono', value: 'persona.telefono', width:150},
        { text: 'Ciudad', value: 'persona.ciudad', width:150},
        { text: 'Direccion', value: 'persona.direccion', width:300},
        { text: 'E-mail', value: 'persona.email',width:200},  
        { text: 'Activo', value: 'activo'}
]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.fechaExpiracion="props">
                <span>{{ FormatoFecha(props.item.fechaExpiracion) }}</span>
              </template>

              <template v-slot:item.cantidad="props">
                <span>{{ currencyFormatter(props.item.cantidad) }}</span>
              </template>

              <template v-slot:item.porcientoDescuento="props">
                <span>{{ currencyFormatter(props.item.porcientoDescuento) }}</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    
<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarCortesia'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    VueAutonumeric,
    ContenidoBase

  },
  created() {
    var json = { 
      titulo: "Registro de cortesía Medica",
      descripcion: "Para realizar los registros de cortesía medicas",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [ 
        "TranspCortesiaMedicaNew",
        "TranspCortesiaMedicaSave",
        "TranspCortesiaMedicaRemove",
        "TranspCortesiaMedicaAnular",
        "TranspCortesiaMedicaPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspCortesiaMedicaNew", this.new);
    // EventBus.$on("TranspCortesiaMedicaSave", this.save);
    // EventBus.$on("TranspCortesiaMedicaRemove", this.remove);
    // EventBus.$on("TranspCortesiaMedicaAnular", this.remove);
    // EventBus.$on("TranspCortesiaMedicaPrintList", this.imprimirList);
    EventBus.$on("emitTranspCortesiaMedica", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  }, 

  data:()=> ({

     windowSize:{width:"auto", height:"auto"},

      maskTelefono:'#############',
      maskCedula:'#############',
   
  
      ListaTiposClientes: [],
     

ejecucion:true,

cargaImg:null,
verImg:null,

CortesiaMedica: {
id: 0,
cantidad:0,
activo: true,
fecha: new Date().toISOString().substr(0,10),
fechaExpiracion: new Date().toISOString().substr(0,10),
indicacionesMedicas:'',
centroSalud:'',

persona:{
id: 0,
nombre: null,
codigoCedula:null,
telefono: "",
cedula: "",
ciudad: null,
direccion: null,
email: "",
foto:null
}


},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },


    
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

    
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,



      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      
    },
    datostabla(e) {


console.log('Datos Datos')
console.log(e)


e.fechaExpiracion=new Date(e.fechaExpiracion).toISOString().substr(0,10)

      this.CortesiaMedica = e;
      this.acti_tb_data=false

      this.maskTelefono='#############',
      this.maskCedula='#############',

      setTimeout(this.MaskChange,200);


    },
    save() {




if(this.$refs.formCortesiaMedica.validate()) {

this.CortesiaMedica.foto=this.CortesiaMedica.fotoPerfil
//this.CortesiaMedica.numeroSindicato=this.CortesiaMedica.sindicato


if(this.ejecucion==true){

  this.ejecucion=false

var Json=JSON.parse(JSON.stringify(this.CortesiaMedica)) 

this.$axios
        .post(this.$hostname + this.$hName + "/registrocortesia/save/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));
}      
}else{
              this.alerta("los campos en rojo son requeridos", "error");
       }
      


    },


NotifMessage() {

if(this.CortesiaMedica.id!=0 && this.CortesiaMedica.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarCortesia"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una cortesia medica para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },



    eliminar() {

 

if (this.CortesiaMedica.id!=0) {

var Json=JSON.parse(JSON.stringify(this.CortesiaMedica)) 

this.$axios
        .post(this.$hostname + this.$hName + "/registrocortesia/borrado/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"))

        
      }


    },
    anular() {

//       if (this.Elim_notif.estado) {

// var jsn={
//    datos:JSON.parse(JSON.stringify(this.CortesiaMedica)),
//    usuario:JSON.parse(JSON.stringify(this.$user)) 
//   }

//         this.$axios
//           .post(
//             this.$hostname +
//               this.$hName +
//               "/services/tipocliente/removeTipoCliente",
//             JSON.parse(JSON.stringify(jsn))
//           )
//           .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
//           .catch(error =>
//             this.alerta(
//               // "hubo un error , por favor verifique el campo descripcion!"
//               "Dato no eliminado",
//               "error"
//             )
//           );
//       }



    },
    imprimirList() {

window.open(this.$hostname+this.$hName+'/services/cortesia/printlistCortesia/')

    },
    newwnuevo() {

this.CortesiaMedica={
id: 0,
cantidad:0,
activo: true,
fechaDesde: new Date().toISOString().substr(0,10),
fechaHasta: new Date().toISOString().substr(0,10),
institucion:'',
matricula:'',
porcientoDescuento:0,

persona:{
id: 0,
nombre: null,
codigoCedula:null,
telefono: "",
cedula: "",
ciudad: null,
direccion: null,
email: "",
foto:null
}

 }
  
      this.cargaImg=null
     

        this.ejecucion = true;
        EventBus.$emit("actualizaBarraBusqueda2");
    },

getImgUrl() {


   //   var images = require("../assets/" + pet);
    var images
   if(this.CortesiaMedica.persona.foto ==null || this.CortesiaMedica.persona.foto.length==0 ){
      images = require("@/assets/img/Nofoto.png");

   }else{
     if(this.CortesiaMedica.persona.foto!=null || this.CortesiaMedica.persona.foto.length >0 ){

       images=this.CortesiaMedica.persona.foto

     }
   }
     //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
     // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },



ConvertirImg(e) {


      var _this = this;
      var filesSelected = e
     // var filesSelected = document.getElementById("foto").files;
      if (filesSelected.length > 0) {
        var fileToLoad = filesSelected[0];

        var fileReader = new FileReader();

        fileReader.onload = function(fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64

          var newImage = document.createElement("img");
          newImage.src = srcData;
          _this.CortesiaMedica.persona.foto = srcData;

      
          // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
          // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
          // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        };
        fileReader.readAsDataURL(fileToLoad);
      }else{

            this.CortesiaMedica.persona.foto=require("@/assets/img/Nofoto.png");

      }
    },



    MaskChange(){

// -------- maskTelefono ---------------
if(this.CortesiaMedica.persona.telefono.length>0){

  this.maskTelefono='(###)###-####'
}else{

this.maskTelefono='#############'

}
// -------- maskCedula ---------------

// -------- maskTelefono ---------------

if(this.CortesiaMedica.persona.cedula.length>0){

  this.maskCedula='###-#######-#'
}else{

this.maskCedula='#############'

}
// -------- maskCedula ---------------

    },


FormatoFecha(params0) {

//console.log('Fecha Fecha')
//console.log(params0)


var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

       this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {

       var headers=this.$store.getters.GetheadersAxios
      await this.$axios.get(this.$hostname + this.$hName + "/registrocortesia/findall/", {headers:headers})
        .then(res => {this.list = res.data;});
    }
  },
  computed:{

    GetEjecucion(){

// -------- ActDescBtn -----------
if(this.CortesiaMedica.id>0){

  this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.CortesiaMedica.id<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}
     
//EventBus.$emit("ActDesaBotones", objjct);
// -------- fn ActDescBtn -----------


// --------  Img -----------


   if(this.CortesiaMedica.persona.foto==null || this.CortesiaMedica.persona.foto.length==0 ){
     this.CortesiaMedica.persona.foto = require("@/assets/img/Nofoto.png");
   }
   
  //  else{
  //    if(this.Vehiculo.fotoVehiculo!=null || this.Vehiculo.fotoVehiculo.length >0 ){

  //      this.verImg= this.Vehiculo.fotoVehiculo

  //    }
  //  }
// -------- fn Img -----------


    }

  }
};
</script>

<style></style>
