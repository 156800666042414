var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0 ma-0 py-0 my-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_vm._v(_vm._s(_vm.GetEjecucion)+" "),_c('v-toolbar',{attrs:{"dark":"","color":"#dd4b39","dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-toolbar-title',_vm._g({},on),[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"}}),_vm._v(" Retención Factura ")])]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Retención Factura : ")]),_vm._v(" Retención Factura ")])]),_c('v-spacer'),_c('v-card-actions',[_c('v-btn-toggle',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","color":"orange","small":"","dark":""},on:{"click":function($event){return _vm.cancelarRetencionFactura()}}},on),[_c('i',{staticClass:"fa fa-reply-all",staticStyle:{"font-size":"20px"}}),_vm._v(" CANCELAR ")])]}}])},[_c('span',[_vm._v("CANCELAR: No Agregar la retención a la factura y retornar atrás")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","color":"grey","small":"","dark":""},on:{"click":function($event){return _vm.NewNewRetencionFactura()}}},on),[_c('v-avatar',{attrs:{"tile":"","size":"28","color":"transparent"}},[_c('img',{attrs:{"src":require("@/assets/icons_pv/extras/limpiarPantalla.png"),"alt":"Limpiar"}})]),_c('i',{staticStyle:{"font-size":"20px"}}),_vm._v(" NUEVO ")],1)]}}])},[_c('span',[_vm._v("NUEVO: Limpia los campo para un nuevo registro")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","color":"light-blue darken-4","small":"","dark":""},on:{"click":function($event){return _vm.SalvarRetencionFactura()}}},on),[_c('i',{staticClass:"fa fa-plus",staticStyle:{"font-size":"20px"}}),_vm._v(" SALVAR ")])]}}])},[_c('span',[_vm._v("SALVAR: Aceptar la retención y retornar atrás")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('VueAutonumeric',{ref:"RefTextFieldRetencionFactura",staticStyle:{"font-size":"13px"},attrs:{"label":"Retención","dense":"","outlined":"","autocomplete":"off","rules":[_vm.$rules.Numericc]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.SalvarRetencionFactura()}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.Retencionfactura.ValorPorc == true)?_c('i',{staticClass:"fa fa-usd",staticStyle:{"font-size":"20px"}}):_vm._e(),(_vm.Retencionfactura.ValorPorc == false)?_c('i',{staticClass:"fa fa-percent",staticStyle:{"font-size":"20px"}}):_vm._e()]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-radio-group',{staticStyle:{"margin-top":"-18%"},attrs:{"dense":"","column":""},on:{"change":function($event){}},model:{value:(_vm.Retencionfactura.ValorPorc),callback:function ($$v) {_vm.$set(_vm.Retencionfactura, "ValorPorc", $$v)},expression:"Retencionfactura.ValorPorc"}},[_c('v-radio',{attrs:{"label":"Valor","color":"teal lighten-2","value":true}}),_c('v-radio',{attrs:{"label":"Porciento","color":"teal lighten-2","value":false}})],1)]},proxy:true}]),model:{value:(_vm.Retencionfactura.Retencion),callback:function ($$v) {_vm.$set(_vm.Retencionfactura, "Retencion", _vm._n($$v))},expression:"Retencionfactura.Retencion"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }