<template @click="buscando">
  <aside v-show="isVisible" class="barra-busqueda">
    <v-card>{{ sizeLabe }}
      <v-card-title>
        <center>
          <v-card-text
              v-bind:style="{  'font-size':this.sizeNombre+'px'}"
              class="font-weight-bold">
            {{ nombre }}
          </v-card-text>

          <!-- class="justify-center headline font-weight-bold" -->

          <!-- <h5>{{ nombre }}</h5> -->
        </center>
        <v-spacer></v-spacer>

        <v-tooltip right>
          <template v-slot:activator="{ on }">

            <v-text-field
                v-if="Campofecha==false"
                autocomplete="off"
                v-on="on"
                v-model="search"
                @keyup="filtro"
                :label="SeachNombre"
                single-line
                hide-details
            >
              <template v-slot:prepend-inner>
                <i style="font-size: 30px;" class="fa fa-search"></i>
              </template>
            </v-text-field>


            <v-text-field
                v-if="Campofecha==true"
                dense
                rounded
                outlined
                type="date"
                @input="filtro"
                :label="SeachNombre"
                v-model="search"
            >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

              <template v-slot:append-outer>

                <v-btn @click.prevent="FechNew" color="grey lighten-1" fab x-small dark>
                  <i style="font-size: 20px;" class="fa fa-file-o"></i>
                </v-btn>
              </template>
            </v-text-field>

          </template>
          <span>{{ SeachNombre }}</span>
        </v-tooltip>
      </v-card-title>


      <v-data-table
          @click:row="obtenerDato"
          @pagination="cargarPaginacion"
          hide-default-header
          :items-per-page="paginator.itemsPerPage"
          :page="paginator.page"
          :headers="headers"
          :items="list"
          :search="search"
          v-if="TbActi"

      >
        <template v-slot:item="props">
          <!-- Notas:
            {Text: Se refiere al nombre descriptivo,
             NomValue: se refiere al primer nombre del objecto,
             Nv2: se refiere al seg nombre objecto, Nv3: al tercero,
             Ctsl: cantidad de slot de objecto que pose,
             Type: se refiere al tipo:(texto=0,fecha=1, numerico=2),
             State:
             }

             ContListSlot:[
          {Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
          {Text:'Banco',NomValue:'banco', Ctsl:1, Type:0, State:true},
          {Text:'Beneficio',NomValue:'beneficio', Ctsl:1, Type:0, State:true},
          {Text:'Fecha',NomValue:'fecha', Ctsl:1, Type:1, State:true},
          {Text:'Valor',NomValue:'valor', Ctsl:1, Type:2, State:true},
          {Text:'Sucursal',NomValue:'sucursal', Nv2:'nombre', Ctsl:2, Type:0, State:true},
          {Text:'Documento',NomValue:'sucursal', Nv2:'documento', Nv3:'desc' ,Ctsl:3, Type:0, State:true}
          ],

             -->
          <tr>
            <td @click.prevent="obtenerDato(props.item)" v-ripple="{ class: `indigo--text` }">

              <v-list-item-content>
<span v-for="(sl, i) in ContListSlot" :key="i">

  
      <span
          v-if="props.item.hasOwnProperty(sl.NomValue) && props.item[sl.NomValue]!=null && props.item[sl.NomValue].toString().length > 0 ">

       <span v-if="sl.Ctsl==1">
        <span v-if="sl.Type==0"><b>{{ sl.Text }}:</b>{{ props.item[sl.NomValue] }}.</span>
        <span v-if="sl.Type==1"><b>{{ sl.Text }}:</b>{{ FormatoFecha(props.item[sl.NomValue]) }}.</span>
        <span v-if="sl.Type==2"><b>{{ sl.Text }}:</b>{{ currencyFormatter(props.item[sl.NomValue]) }}.</span>

        <span v-if="sl.Type==0.1 & props.item[sl.NomValue]==false"> <b>{{ sl.Text }}:</b><span class="red--text">Inactivo.</span></span>
        <span v-if="sl.Type==0.1 & props.item[sl.NomValue]==true"><b>{{ sl.Text }}:</b><span
            class="green--text">Activo. </span></span>
        <span v-if="sl.Type==0.2 & props.item[sl.NomValue]==true"> <b>{{ sl.Text }}:</b><span class="green--text"> Activo.</span></span>
        <span v-if="sl.Type==0.2 & props.item[sl.NomValue]==false"> <b>{{ sl.Text }}:</b><span class="red--text"> Anulado.</span></span>
        <span v-if="sl.Type==0.3 & props.item[sl.NomValue]==false"> <b>{{ sl.Text }}:</b><span class="green--text">Activo.</span></span>
        <span v-if="sl.Type==0.3 & props.item[sl.NomValue]==true"> <b>{{ sl.Text }}:</b><span class="red--text">Inactivo.</span></span>
        <span v-if="sl.Type==0.4 & props.item[sl.NomValue]==true" ><span class="green--text" >{{sl.Text}}</span></span>
         <span v-if="sl.Type==3">
          
          <v-avatar
              size="50"
              tile
          >
                  <v-img :src="props.item[sl.NomValue]"></v-img>
             </v-avatar> 
        </span>

        

    </span>

    <span v-if="sl.Ctsl==2 & props.item[sl.NomValue].hasOwnProperty(sl.Nv2)">
        <span v-if="sl.Type==0"><b>{{ sl.Text }}: </b>{{ props.item[sl.NomValue][sl.Nv2] }}.</span>
        <span v-if="sl.Type==1"><b>{{ sl.Text }}: </b>{{ FormatoFecha(props.item[sl.NomValue][sl.Nv2]) }}.</span>
        <span v-if="sl.Type==2"><b>{{ sl.Text }}: </b>{{ currencyFormatter(props.item[sl.NomValue][sl.Nv2]) }}.</span>

    <span v-if="sl.Type==0.3"> 
      <span
          v-if="props.item[sl.PrgValue]==sl.PrgState"><b>{{ sl.Text }}: </b>{{ props.item[sl.NomValue][sl.Nv2] }}.</span>
    </span>
    <span v-if="sl.Type==3">
          
          <v-avatar
              size="50"
              tile
          >
                  <v-img :src="props.item[sl.NomValue][sl.Nv2]"></v-img>
             </v-avatar> 
        </span>

    </span>

    <span v-if="sl.Ctsl==3 & props.item[sl.NomValue].hasOwnProperty(sl.Nv2)">
      <span v-if="props.item[sl.NomValue][sl.Nv2].hasOwnProperty(sl.Nv3)"><b>{{ sl.Text }}: </b>
        <span v-if="sl.Type==0">{{ props.item[sl.NomValue][sl.Nv2][sl.Nv3] }}.</span>
        <span v-if="sl.Type==1">{{ FormatoFecha(props.item[sl.NomValue][sl.Nv2][sl.Nv3]) }}.</span>
        <span v-if="sl.Type==2">{{ currencyFormatter(props.item[sl.NomValue][sl.Nv2][sl.Nv3]) }}.</span>
    </span>
    </span>

  </span> 

  <span v-if="sl.Type==4"><b>{{ sl.Text }}:</b>
  
          <span v-if="sl.NomValue=='convinada'"> 
            $.{{ CalculoConvinadoCierre(props.item) }}
          </span>
          
        </span>


</span>
              </v-list-item-content>

            </td>
          </tr>
          <hr>

          <!-- <span>{{props.item}}
            </span> -->
        </template>
      </v-data-table>

    </v-card>
  </aside>
</template>

<script>
// import axios from "axios";
import cuerpo from "../../src/components/cuerpo";
import {EventBus} from "@/event-bus.js";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";

export default {
  inject: ['theme'],
  components: {
    cuerpo
  },
  data() {
    return {

      sizeNombre: 25,
      TbActi: true,

      ObjTemTabla: null,
      indexTabla: null,
      json: {},
      paginator: {
        itemsPerPage: 5,
        pageStart: 10,
        pageStop: 15,
        pageCount: 50,
        itemsLength: 5,
        page: 1
      },
      isVisible: true,
      list: [],
      search: ""
    };
  },
  props: {
    headers: {
      required: true,
      type: Array
    },

    url: {
      required: true,
      type: String
    },
    nombre: {
      required: true,
      type: String
    },

    SeachNombre: {
      required: true,
      type: String
    },

    ContListSlot: {
      required: false,
      type: Array
    },

    emitnombre: {
      required: true,
      type: String
    },

    SizeNom: {
      required: false,
      type: Number
    },

    Campofecha: {
      required: false,
      type: Boolean
    },
    buscarDesc: {
      required: false,
      type: Boolean
    },


  },

  created() {

    /////////
    // this.$axios.post(this.url, {usuario:this.$CodeUser}).then(res => (this.list = res.data));

    this.filtro()
    //this.list.push(this.slotlist)
    //this.list.push(this.slotlist)
  },
  mounted: function () {
    EventBus.$on("actualizaBarraBusqueda2", this.filtro);
    EventBus.$on("BarraDinamFiltro", this.Modalfiltro);

  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,

    cargarPaginacion(e) {
      this.paginator = JSON.parse(JSON.stringify(e))
    },

    FechNew() {

      this.search = ""


    },
    CalculoConvinadoCierre(e) {

      var suma = (e.efectivo) + (e.cheques * e.cantidadcheques) + (e.recibos * e.cantidadrecibo) + (e.tarjeta * e.cantidadtarjeta)
      return this.currencyFormatter(suma)

    },


    


    TelFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
              "(" +
              params.substring(0, 3) +
              ")" +
              params.substring(3, 6) +
              "-" +
              params.substring(6, 10)
          );
        }
        return params;
      }
    },


    async filtro(e) {

      this.ObjTemTabla = null
      this.indexTabla = null


      var headers = this.$store.getters.GetheadersAxios
      var ur = this.url
      if (this.buscarDesc) {
        ur+='/'+(this.search==''?'a':this.search)+'/50/0'
      }
      await this.$axios.get(ur, {headers: headers}).then(res => {
        this.list = JSON.parse(JSON.stringify(res.data));
      });

    },


    async Modalfiltro(e) {


      console.log('ModalDinamicoFiltro')
      console.log(e)


//   if(e.opcion==1){
//   console.log('Entrando al modalFiltro filt==>'+e.url)
//  this.objFiltro=JSON.parse(JSON.stringify(e)) 

//  await this.$axios
//         .post(e.url, {
//            descripcion:"", objFiltro:this.objFiltro,
//           usuario:JSON.parse(JSON.stringify(this.$user)) 

//         })
//         .then(res => {
//           this.list =JSON.parse(JSON.stringify(res.data));
//         });
//   }

      if (e.opcion == 'Buscar') {
//var headers=this.$store.getters.GetheadersAxios
        await this.$axios
            .post(e.url, e.Modallfiltro, {headers: this.$store.getters.GetheadersAxios})
            .then(res => {
              console.log('Ressssspuuuu'), console.log(res.data), this.list = JSON.parse(JSON.stringify(res.data))
            })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));
// await this.$axios.get(e.url+e.Modallfiltro+"/",{headers:headers}).then(res => {
//         this.list =  JSON.parse(JSON.stringify(res.data)) ;
//       });

      }


      if (e.opcion == 'Limpiar') {

        this.objFiltro = null
        this.list = []
        this.filtro()

//var _this=this;
        //setTimeout(function(){ _this.cargarFiltro(e) }, 300);

        //setTimeout(this.cargarFiltro,200);

      }

    },

    obtenerDato(e) {

      this.ObtenerTemp(e)
      //this.filtro()
      EventBus.$emit(this.emitnombre, e);
      //this.Rdata=e
      //this.RdataDetalle=e[this.slotlist]


    },

    ObtenerTemp(e) {

// const index =  this.nuevoClientes.ListFact.indexOf(item)
// this.nuevoClientes.ListFact.splice(index, 1)
// this.nuevoClientes.ListFactDele.push(item);


      if (this.ObjTemTabla != null) {

        //this.list[this.indexTabla]=JSON.parse(JSON.stringify(this.ObjTemTabla))
        console.log('Ver el index: ' + this.indexTabla)
        console.log(this.list[this.indexTabla])

        this.list[this.indexTabla] = JSON.parse(JSON.stringify(this.ObjTemTabla))

        this.TbActi = false


        var _this = this;

        setTimeout(function () {
          _this.TbActi = true


        }, 5);

//setTimeout(this.TbActi=true,8000);

        // var jsn =JSON.parse(JSON.stringify(this.list))
        // this.list=[]
        // this.list=JSON.parse(JSON.stringify(jsn))

      }
      this.indexTabla = JSON.parse(JSON.stringify(this.list.indexOf(e)))
      this.ObjTemTabla = JSON.parse(JSON.stringify(e))

    }


  },
  computed: {

    sizeLabe() {

      if (this.SizeNom != undefined) {

        this.sizeNombre = this.SizeNom


      } else {

        this.sizeNombre = 25

      }


//sizeNombre


    }

  }

};
</script>
<style></style>
