<template>
  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" v-resize="onResize"> {{ActDescBtn}}

    <!--  Modales -->
    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" :type="aalert.type" id="alerMov" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Modal- crear Producto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogCrearProducto" persistent
      max-width="90%" max-height="80%">
      <CrearProducto @EmitCrearProducto="RecCrearProducto" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- crear producto-->

    <!-- ------------------------------------------------------ Modal- nueva Produecto-->
    <v-dialog transition="fab-transition" label="Modal agregar producto" v-model="dialogAddProducto" persistent
      max-width="90%" max-height="80%">

      <AgregarProducto :statusFoms="dialogAddProducto" ref="RefAgregarProducto" @EmitAgregarProducto="RecAgregarProducto"
        :unidadminima="PropsUnidadminima" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- nueva producto-->


    <!-- ------------------------------------------------------ Modal  Etiqueta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogEtiqueta" persistent
      max-width="90%" max-height="80%">
      <ImprimirEtiquetasComponet @EmitCerrarImpriEtiqueta="RecEmitCerrarImpriEtiqueta" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Etiqueta-->


    <!-- ------------------------------------------------------ Modal Producto Sugerido -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogProductoSugerido" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>SUGERIR PRODUCTOS A PEDIR</b>
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogProductoSugerido=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="ProductoSugerido.desde"
                @blur="ProdcSugeDesde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="ProductoSugerido.hasta"
                @blur="ProdcSugeBlurHasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

            <!-- columna 3 -->
            <v-col cols="12" md="12" sm="12">


              <v-radio-group style="margin-top:-20px;" v-model="ProductoSugerido.RadioOpc" column>
                <v-radio label="Productos Con Existencia 0" color="teal lighten-2" :value="1"></v-radio>
                <v-radio label="Productos Con Ventas y Existencia en Cero" color="teal lighten-2" :value="2"></v-radio>
                <v-radio label="Productos Con Ventas y Existencia" color="teal lighten-2" :value="3"></v-radio>
                <v-radio label="Pedido = existencia - ventas" color="teal lighten-2" :value="4"></v-radio>
              </v-radio-group>

            </v-col>
            <!-- fn columna 3 -->



          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarSugerencia">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar Sugerencia
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-------------------------------------------------------- fn Modal Producto Sugerido -->

    <!-- ------------------------------------------------------  Modal Loader -->

    <v-dialog v-model="Reparaloading" persistent width="300">
      <v-card color="grey darken-2" dark>
        <v-card-text>
          <br>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal loader -->


    <!-- fn Modales -->


    <!--------------------------------------------------- tb add producto  -->
    <v-card>

      <v-toolbar flat dense color="#BDBDBD">

        <v-card-actions>


          <v-btn-toggle rounded dense>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <!--OpenAddProducto  -->
                <v-btn v-on="on" dark @click.prevent="funcionValidaPermisoPv('AccBtnAgregarProductoTabla')" color="green">
                  <i style="font-size: 20px;" class="fa fa-plus-square"></i><!-- fab x-small dark  -->
                  Agregar Productos
                </v-btn>


              </template>
              <span>Agregar Productos</span>
            </v-tooltip>
<!--CelldCrearProducto==true  -->
            <v-tooltip v-if="false" top>
              <template v-slot:activator="{ on }">

                <v-btn v-on="on" dark color="#90A4AE" @click.prevent="dialogCrearProducto =!dialogCrearProducto">
                  <i style="font-size: 28px;" class="fa fa-file-o"> </i>
                  Crear Producto
                </v-btn>

              </template>
              <span>Crear Productos</span>
            </v-tooltip>


            <v-tooltip v-if="CelldImprirEtiqueta==true" top>
              <template v-slot:activator="{ on }">

                <v-btn v-on="on " dark color="orange lighten-2" @click.prevent="dialogEtiqueta =!dialogEtiqueta">
                  <i style="font-size: 28px;" class="fa fa-tags"> </i>
                  Etiquetas
                </v-btn>

              </template>
              <span>Etiquetas</span>
            </v-tooltip>



            <v-tooltip top v-if="CelldSugerirProd==true">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark @click.prevent="openProductoSugerido" color="blue lighten-3">
                  <i style="font-size: 20px;" class="fa fa-plus-square"></i><!-- fab x-small dark  -->
                  AGREGAR PRODUCTOS SUGERIDOS
                </v-btn>

              </template>
              <span>AGREGAR PRODUCTOS SUGERIDOS</span>
            </v-tooltip>

        <v-tooltip top v-if="this.PropsDespachoPedido==true">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" dark @click.prevent="CargarProductoPedido" color="blue lighten-3" :loading="!ejecutarCargarProducto" :disabled="!ejecutarCargarProducto">
                  <i style="font-size: 20px;" class="fa fa-plus-square"></i><!-- fab x-small dark  -->
                  CARGAR PRODUCTOS PEDIDO
                  <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>
                </v-btn>

              </template>
              <span>CARGAR PRODUCTOS PEDIDO</span>
            </v-tooltip>
          </v-btn-toggle>
        </v-card-actions>

        <v-spacer></v-spacer>
          <v-card-actions>

            <v-tooltip top >
              <template v-slot:activator="{ on }">
              <v-btn
              v-on="on" 
              icon
              color="green"
              @click="AddNewRowListProd(`${{index:ListProd[ListProd.length - 1]}}`)"
              
            >
            <i style="font-size: 20px;" class="fa fa-plus"></i>
            </v-btn>
          </template>
              <span>AGRERE LINEA A LA TABLA</span>
            </v-tooltip>

          </v-card-actions>


        <!-- id="TbBord"   <v-toolbar-title class="font-weight-light"><v-card-title>FACTURAS INICIALES</v-card-title></v-toolbar-title> -->

      </v-toolbar>
      <v-data-table @current-items="PosicionFocus" class="elevation-1" id="TbBord" dense :headers="HeaderDate"
        :items="ListProd" item-key="indx" :items-per-page="15" :footer-props="arrayPropiedases">


        <template v-slot:item.valor="props">
          <span style="display:none;">
          {{props.item.indx = ListProd.indexOf(props.item)}} 
          <!-- {{ props.item.valor = props.item.cantidad * (props.item.costo+props.item.impuesto)}}  -->
          <!-- {{ `props.item.valor = ${props.item.cantidad} * (${props.item.costo}+${props.item.impuesto})`}} -->
        </span>

          <!-- <span style="display:none;">{{props.item.importe =JSON.parse(JSON.stringify(props.item.valor)) }} </span> -->

          <!-- <span>{{currencyFormatter(props.item.valor)}}</span> -->

          <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
            autocomplete="off" :value="currencyFormatter(props.item.valor)">
          </v-text-field>

        </template>


        <template v-slot:item.action="{ item }">
          <span style="display:none;">{{item.indx = ListProd.indexOf(item)}} </span>
          <v-btn fab @click.prevent="eliminarItem(item)" x-small color="red darken-3"><i style="font-size: 20px;"
              class="fa fa-trash-o"></i></v-btn>
        </template>

        <template v-slot:item.unidadProducto="props">
          <span v-if="props.item.unidadProducto!=null && props.item.unidadProducto.hasOwnProperty('costo')"
            style="display:none;">{{props.item.unidadProducto.codigoProducto=GetCodigoProdUni(props.item.unidadProducto)}}</span>
          <!--@focus=""@change="" @blur="" v-on:keydown="OnKeyupAddRowProduc(props.item)" @keyup="KeyupAddRowProducCombox"-->
          <v-combobox hide-details :ref="'codigo'+props.item.indx" v-model="props.item.unidadProducto" label="" outlined
            dense autocomplete="off" :items="RproductoBuscados" item-text="codigoProducto"
            v-on:change="changeSelectProduc(props.item)" @blur="BlurTabSelectProduc(props.item)"
            v-on:keyup.down="OnKeyupAddRowProducCombox(props.item)" style="font-size: 13px;">

            <template v-slot:selection="{ attrs, item, parent, selected}">

              <span
                v-if="props.item.unidadProducto!=null && !props.item.unidadProducto.hasOwnProperty('codigoProducto')">{{
                item }}</span>
              <span
                v-if="props.item.unidadProducto!=null && props.item.unidadProducto.hasOwnProperty('codigoProducto')">{{
                item.codigoProducto }}</span>

            </template>

            <template slot="item" slot-scope="item" margin-top="50px">
              <span> {{item.item.productos.descripcion}}-{{item.item.unidades.descripcion}} </span>
            </template>

          </v-combobox>
        </template>



        <template v-slot:item.producto.descripcion="props">
          <!-- <span style="font-size:13px;" >{{props.item.producto.descripcion}}</span> -->
          <span v-if="props.item.producto!=null" style="display:none;">{{ props.item.propsDesProdUnid = props.item.producto.descripcion+" "+props.item.unidad.descripcion}}</span>
          <v-text-field v-if="props.item.producto!=null" label="" id="formdisabledInput" style="font-size:13px;" dense
            disabled hide-details outlined autocomplete="off" :value="props.item.propsDesProdUnid">
          </v-text-field>
        </template>


        <template v-slot:item.fechavencimiento="props">
          <v-text-field v-if="props.item.producto!=null" id="formdisabledInput" dense hide-details
            style="font-size:13px;" outlined type="date" label=""  :ref="'vencimiento'+props.item.indx"
            :disabled="!props.item.producto.controlVencimiento" @blur="fechaVencimientoAVencer(props.item)" v-model="props.item.fechavencimiento">
          </v-text-field>
        </template>


        <!--@input="calcularLineas(props.item)"  -->
        <template v-slot:item.cantidadpedida="props">
          <AutoNumeric label="" style="font-size:13px;" id="formdisabledInput" disabled dense hide-details outlined
            autocomplete="off" v-model.number="props.item.cantidadpedida">
          </AutoNumeric>
        </template>


        <!--@input="calcularLineas(props.item)"  -->
        <template v-slot:item.cantidad="props">
          <AutoNumeric label="" style="font-size:13px;" dense :ref="'cantidad'+props.item.indx" hide-details outlined
            autocomplete="off" v-model.number="props.item.cantidad"
            v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)" @blur="calcularTotales"
            v-on:input="VerificarCantidad(props.item)">
          </AutoNumeric>
        </template>

        <!-- @input="calcularLineas(props.item)" -->
        <template v-slot:item.costo="props">
          <AutoNumeric label="" id="formdisabledInput" :disabled="DisableCosto" style="font-size:13px;" dense
            hide-details outlined autocomplete="off" v-model.number="props.item.costo"
            v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)" @blur="calcularTotales">
          </AutoNumeric>
        </template>

        <!--@input="calcularLineas(props.item)"  -->
        <template v-slot:item.impuesto="props">
          <AutoNumeric label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.impuesto" v-on:keydown.down="OnKeyupAddRowProducCombox(props.item)"
            @blur="calcularTotales">
          </AutoNumeric>
        </template>

        <template v-slot:item.existencia="props">
          <!-- <span>{{props.item.existencia}}</span> -->
          <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
            autocomplete="off" :value="props.item.existencia">
          </v-text-field>
        </template>


        <template v-slot:item.venta="props">
          <AutoNumeric label="" id="formdisabledInput" disabled style="font-size:13px;" dense hide-details outlined
            autocomplete="off" v-model.number="props.item.venta">
          </AutoNumeric>
        </template>

        <template v-slot:item.unidadProducto.factor="props">
          <AutoNumeric label=""
            v-if="props.item.unidadProducto!=null && props.item.unidadProducto.hasOwnProperty('factor')"
            id="formdisabledInput" disabled style="font-size:13px;" dense hide-details outlined autocomplete="off"
            v-model.number="props.item.unidadProducto.factor">
          </AutoNumeric>
        </template>

        <template v-slot:item.almacen="props">
          <v-combobox :ref="'Almacen'+props.item.indx" style="font-size:13px;" hide-details autocomplete="off" dense
            outlined label="" :items="Ralmacen" item-text="descripcion" v-model="props.item.almacen"
            @focus="CargarRalmacen">
            <template v-slot:selection="{ attrs, item, parent, selected}">
            <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
           </template>
          </v-combobox>
        </template>
        CelldExistenc
        <template v-slot:body.append v-if="windowSize.width >600">
          <tr>

            <td class="border-top-bottom"><strong>Cantidad:{{ListProd.length}}</strong></td>
            <td class="border-top-bottom" v-if="CelldAlmacen==true"><strong></strong></td>
            <td class="border-top-bottom"><strong class="d-flex justify-end"
                style="font-size:15px; color: #00838F;">Total:</strong></td>
            <td class="border-total" v-if="CelldFechav==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>

            <td class="border-total" v-if="CellCantpedida==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
              </strong></td>
            <td class="border-total" v-if="CelldExistenc==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-total" v-if="CelldVenta==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-total" v-if="CelldFactor==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> </strong></td>
            <td class="border-top-bottom"><strong></strong></td>
            <td class="border-total" v-if="CelldImpuesto==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> {{currencyFormatter(TotalProd.impuesto)}} </strong></td>
            <td class="border-total" v-if="CelldImporte==true"><strong class="d-flex justify-center"
                style="font-size:15px; color: #00838F;"> {{currencyFormatter(TotalProd.importe)}} </strong></td>

            <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
            <td class="border-top-bottom"><strong></strong></td>
          </tr>
        </template>

        <template v-slot:footer v-if="windowSize.width<=600">
          <v-list-item style="font-size: 70%;" class="white--text">
            <v-list-item-content>
              <span></span>
              <span></span>
              <span></span>
              <span>IMPUESTO: {{currencyFormatter(TotalProd.impuesto)}} </span>
              <span>IMPORTE: {{currencyFormatter(TotalProd.importe)}}</span>

            </v-list-item-content>
          </v-list-item>

        </template>
      </v-data-table>
    </v-card>
    <!--------------------------------------------------- fn tb add producot -->

<!---------------------  Panel Menu Opcion Botones ------------------------------->
<div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv"/>
            </div>


  </v-col>

</template>
<script>
import { EventBus } from "../../event-bus";
import AutoNumeric from "../../components/AutoNumericNuevo";
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import CrearDocumento from "../../Inventario/UtilidadComponents/CrearDocumento";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoBasico,RedondeoValorCantRed } from "@/js/Funciones.js";
import ImprimirEtiquetasComponet from "../../Inventario/UtilidadComponents/ImprimirEtiquetas";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
export default {

  components: {
    AutoNumeric,
    CrearProducto,
    CrearDocumento,
    AgregarProducto,
    ImprimirEtiquetasComponet,
    PvMenuOpcionBotones


  },

  mounted() {
    this.onResize()
    // EventBus.$on("onResize", this.onResize);
    this.CrearHeader()

  },
  data: () => ({
    ejecutarCargarProducto:true,
    RpedidoDetalle:[],
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    dialogCrearProducto: false,
    dialogAddProducto: false,
    dialogEtiqueta: false,
    dialogProductoSugerido: false,
    VarKeyCode: 0,
    RproductoBuscados: [],
    Ralmacen: [],
    Rproducto: [],
    aalert: {
      estado: false,
      color: null,
      type: ""

    },
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: true
    },
    TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
    HeaderDate: [],
    sugerencia: [],
    Reparaloading: false,
    dialogProductoSugerido: false,
    ProductoSugerido: {
      desde: new Date().toISOString().substr(0, 10),
      hasta: new Date().toISOString().substr(0, 10),
      RadioOpc: 1
    },
    lote: 0,
    DisableCosto: false,



    CelldImprirEtiqueta: false,
    CelldSugerirProd: false,
    CelldCrearProducto: false,

    CelldFechav: false,
    CellCantpedida: false,
    CelldVenta: false,
    CelldAlmacen: false,
    CelldImpuesto: false,
    CelldImporte: true,
    CelldExistenc: true,
    CelldFactor: false,


    AnchoCodigo: 150,
    AnchosValores: 120,
    AnchoCantidades: 90,
    AnchoAccion: 50,
    AnchoFecha: 80,
    AnchoAlmacen: 160





  }),

  props: {

    ListProd: {
      required: true,
      type: Array

    },
    EntradaAlmacen: {
      required: false,
      type: Object
    },



    PropsPedidoAlmacen: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsFactProve: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsDespachoPedido: {
      required: false,
      type: Boolean,
      default: false
    },


    PropsProduccionCombos: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsEntradaSalida: {
      required: false,
      type: Boolean,
      default: false
    },


    PropsTranferencia: {
      required: false,
      type: Boolean,
      default: false
    },


    PropsInventarioFisico: {
      required: false,
      type: Boolean,
      default: false
    },

    PropsUnidadminima: {
      required: false,
      type: Boolean,
      default: null
    },

    
    PropsDefault: {
      required: false,
      type: Boolean,
      default: false
    },

    ProsActuAlmacen: {
      required: false,
      type: Boolean,
      default: false
    },

  },


  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoBasico,RedondeoValorCantRed,


    onResize(e) {

      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 }
      this.windowSize = jsn


    },

    OpenAddProducto() {
      this.dialogAddProducto = true

      var _this = this;
      setTimeout(function () {
        _this.$refs.RefAgregarProducto.CargarRproducto()
      }, 200);
    },


    CrearHeader() {
      // this.HeaderDate.push({ text: 'CÓDIGO', value: 'unidadProducto',width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'PRODUCTO', value: 'producto.descripcion',width:300,sortable:false})
      // this.HeaderDate.push({ text: 'ALMACEN', value: 'almacen', width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'FECHA V.', value: 'fechavencimiento',width:80,sortable:false})
      // this.HeaderDate.push( { text: 'CANTIDAD', value: 'cantidad', width:80,sortable:false})
      // this.HeaderDate.push({ text: 'EXISTENCIA', value: 'existencia',width:80,sortable:false})
      // this.HeaderDate.push( { text: 'COSTO', value: 'costo', width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'IMPUESTO', value: 'impuesto', width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'IMPORTE', value: 'valor', width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'ACCIÓN', value: 'action',width:'auto',sortable:false})
      // -------------------------------------------------------------------------------------
      // this.HeaderDate.push({ text: 'CÓDIGO', value: 'unidadProducto',width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'PRODUCTO', value: 'producto.descripcion',width:300,sortable:false})
      // if(this.CelldAlmacen==true){this.HeaderDate.push({ text: 'ALMACEN', value: 'almacen', width:'auto',sortable:false})}
      // if(this.CelldFechav==true){this.HeaderDate.push({ text: 'FECHA V.', value: 'fechavencimiento',width:80,sortable:false})}
      // this.HeaderDate.push({ text: 'CANTIDAD', value: 'cantidad', width:80,sortable:false})
      // this.HeaderDate.push({ text: 'EXISTENCIA', value: 'existencia',width:80,sortable:false})
      // this.HeaderDate.push({ text: 'VENTAS', value: 'venta', width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'COSTO', value: 'costo', width:'auto',sortable:false})
      // this.HeaderDate.push({ text: 'IMPUESTO', value: 'impuesto', width:'auto',sortable:false})
      //  this.HeaderDate.push({ text: 'IMPORTE', value: 'valor', width:'auto',sortable:false})
      //  this.HeaderDate.push({ text: 'ACCIÓN', value: 'action',width:'auto',sortable:false})
      //-----------------------------------------------------------------------------------------

      //--------------------------------- InventarioFisico-------------------------------------------
      if (this.PropsInventarioFisico == true) {
        this.HeaderDate = [

          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          // { text: 'ALMACEN', value: 'almacen', width:this.AnchoAlmacen,sortable:false},
          // { text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false},
          //  { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false },
          // { text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false},
          { text: 'FACTOR', value: 'unidadProducto.factor', width: this.AnchoCantidades, sortable: false },
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false },
          // { text: 'IMPUESTO', value: 'impuesto', width:this.AnchosValores,sortable:false},
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false },
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }
        ]

        this.DisableCosto = true
        this.CelldFactor = true
        this.CelldImprirEtiqueta = false
        this.CelldSugerirProd = false
        this.CelldCrearProducto = false
        this.CelldFechav = false
        this.CellCantpedida = false
        this.CelldVenta = false
        this.CelldAlmacen = false
        this.CelldImpuesto = false
      }




      //---------------------------------PropsTranferencia-------------------------------------------
      if (this.PropsTranferencia == true) {
        this.HeaderDate = [

          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          // { text: 'ALMACEN', value: 'almacen', width:this.AnchoAlmacen,sortable:false},
          // { text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false},
          // { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false },
          // { text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false},
          //{ text: 'FACTOR', value: 'unidadProducto.factor', width:this.AnchoCantidades,sortable:false},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false },
          { text: 'IMPUESTO', value: 'impuesto', width: this.AnchosValores, sortable: false },
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false },
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }

        ]

        this.DisableCosto = true
        this.CelldImprirEtiqueta = false
        this.CelldSugerirProd = false
        this.CelldCrearProducto = true
        this.CelldFechav = false
        this.CellCantpedida = false
        this.CelldVenta = false
        this.CelldAlmacen = false
        this.CelldImpuesto = true
      }


      //--------------------------------- EntradaSalida-------------------------------------------

      if (this.PropsEntradaSalida == true) {
        this.HeaderDate = [
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          { text: 'ALMACEN', value: 'almacen', width: this.AnchoAlmacen, sortable: false },
          { text: 'FECHA V.', value: 'fechavencimiento', width: this.AnchoFecha, sortable: false },
          // { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false },
          //  { text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false},
          //  { text: 'FACTOR', value: 'unidadProducto.factor', width:this.AnchoCantidades,sortable:false},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false },
          { text: 'IMPUESTO', value: 'impuesto', width: this.AnchosValores, sortable: false },
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false },
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }

        ]

        this.CelldImprirEtiqueta = false
        this.CelldSugerirProd = false
        this.CelldCrearProducto = true
        this.CelldFechav = true
        this.CellCantpedida = false
        this.CelldVenta = false
        this.CelldAlmacen = true
        this.CelldImpuesto = true
      }





      //--------------------------------- PropsProduccionCombos-------------------------------------------

      if (this.PropsProduccionCombos == true) {

        this.HeaderDate = [


          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          { text: 'ALMACEN', value: 'almacen', width: this.AnchoAlmacen, sortable: false },
          // { text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false},
          // { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false },
          // { text: 'EXISTENCIA', value: 'existencia',width:this.AnchoCantidades,sortable:false},
          // { text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false},
          // { text: 'FACTOR', value: 'unidadProducto.factor', width:this.AnchoCantidades,sortable:false},
          { text: 'COSTO UND.', value: 'costo', width: this.AnchosValores, sortable: false },
          // { text: 'IMPUESTO', value: 'impuesto', width:this.AnchosValores,sortable:false},
          { text: 'COSTO TOTAL', value: 'valor', width: this.AnchosValores, sortable: false },
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }
        ]

        this.DisableCosto = true
        this.CelldImprirEtiqueta = false
        this.CelldSugerirProd = false
        this.CelldCrearProducto = true
        this.CelldFechav = false
        this.CellCantpedida = false
        this.CelldVenta = false
        this.CelldAlmacen = true
        this.CelldImpuesto = false
        this.CelldExistenc = false
      }



      //--------------------------------- DespachoPedido-------------------------------------------


      if (this.PropsDespachoPedido == true) {
        this.HeaderDate = [

          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          // { text: 'ALMACEN', value: 'almacen', width:this.AnchoAlmacen,sortable:false},
          // { text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false},
          { text: 'CANT.PEDIDA', value: 'cantidadpedida', width: this.AnchoCantidades, sortable: false },
          { text: 'CANT.DESPACHAR', value: 'cantidad', width: this.AnchoCantidades+25, sortable: false },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false },
          // { text: 'VENTAS', value: 'venta', width:this.AnchoCantidades,sortable:false},
          // { text: 'FACTOR', value: 'unidadProducto.factor', width:this.AnchoCantidades,sortable:false},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false },
          // { text: 'IMPUESTO', value: 'impuesto', width:this.AnchosValores,sortable:false},
          // { text: 'IMPORTE', value: 'valor', width:this.AnchosValores,sortable:false},
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }

        ]


        this.DisableCosto = true
        this.CelldImprirEtiqueta = false
        this.CelldSugerirProd = false
        this.CelldCrearProducto = true

        this.CelldFechav = false
        this.CellCantpedida = true
        this.CelldVenta = false
        this.CelldAlmacen = false
        this.CelldImpuesto = false

        this.CelldImporte = false

      }


      //---------------------------------PedidoAlmacen-------------------------------------------

      if (this.PropsPedidoAlmacen == true) {
        this.HeaderDate = [
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          //  { text: 'ALMACEN', value: 'almacen', width:this.AnchoAlmacen,sortable:false},
          //   { text: 'FECHA V.', value: 'fechavencimiento',width:this.AnchoFecha,sortable:false},
          //   { text: 'CANT.PEDIDA', value: 'cantidadpedida', width:this.AnchoCantidades,sortable:false},
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false },
          { text: 'VENTAS', value: 'venta', width: this.AnchoCantidades, sortable: false },
          // { text: 'FACTOR', value: 'unidadProducto.factor', width:this.AnchoCantidades,sortable:false},
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false },
          //  { text: 'IMPUESTO', value: 'impuesto', width:this.AnchosValores,sortable:false},
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false },
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }
        ]




        this.DisableCosto = true

        this.CelldImprirEtiqueta = false
        this.CelldSugerirProd = true
        this.CelldCrearProducto = false
        this.CelldFechav = false
        this.CellCantpedida = true
        this.CelldVenta = true
        this.CelldAlmacen = false
        this.CelldImpuesto = false
        this.CellCantpedida = false
      }


      // Cantidad Despachada



      //---------------------------------Default-------------------------------------------
      if (this.PropsDefault == true) {
        this.HeaderDate = [
          { text: 'CÓDIGO', value: 'unidadProducto', width: this.AnchoCodigo, sortable: false },
          { text: 'PRODUCTO', value: 'producto.descripcion', width: 'auto', sortable: false },
          { text: 'ALMACEN', value: 'almacen', width: this.AnchoAlmacen, sortable: false },
          { text: 'FECHA V.', value: 'fechavencimiento', width: this.AnchoFecha, sortable: false },
          { text: 'CANT.PEDIDA', value: 'cantidadpedida', width: this.AnchoCantidades, sortable: false },
          { text: 'CANTIDAD', value: 'cantidad', width: this.AnchoCantidades, sortable: false },
          { text: 'EXISTENCIA', value: 'existencia', width: this.AnchoCantidades, sortable: false },
          { text: 'VENTAS', value: 'venta', width: this.AnchoCantidades, sortable: false },
          { text: 'FACTOR', value: 'unidadProducto.factor', width: this.AnchoCantidades, sortable: false },
          { text: 'COSTO', value: 'costo', width: this.AnchosValores, sortable: false },
          { text: 'IMPUESTO', value: 'impuesto', width: this.AnchosValores, sortable: false },
          { text: 'IMPORTE', value: 'valor', width: this.AnchosValores, sortable: false },
          { text: 'ACCIÓN', value: 'action', width: this.AnchoAccion, sortable: false }
        ]

        this.CelldImprirEtiqueta = true
        this.CelldSugerirProd = true
        this.CelldCrearProducto = true
        this.CelldFechav = true
        this.CellCantpedida = true
        this.CelldVenta = true
        this.CelldAlmacen = true
        this.CelldImpuesto = true
        this.CelldFactor = true



      }






    },

    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },


    GetCodigoProdUni(unidaproducto) {
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
        if(unidaproducto.lote!==""){
          console.log(unidaproducto.lote.substring(10))
          this.lote = parseInt(unidaproducto.lote.substring(10)); 
        }
        return unidaproducto.lote;
      } else {
        var pk = unidaproducto.unidadesProductosPK;
        var codigoP = ('000000' + pk.producto).substring(('000000' + pk.producto).length - 6, ('000000' + pk.producto).length)
        var codigoU = ('0000' + pk.unidad).substring(('0000' + pk.unidad).length - 4, ('0000' + pk.unidad).length)
        return codigoP + codigoU;
      }
    },

    fechaVencimientoAVencer(t) {

      if(t.fechavencimiento!=null && t.fechavencimiento.length==0){
t.fechavencimiento=null
}

      var e = new Date(), d = new Date(t.fechavencimiento)
      e.setMonth(e.getMonth() + 3)
      if (d < e) {
        this.alerta("Este producto esta próximo a vencerse ")
        return
      }
    },


    newDetalle() {

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      var detalle = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: 0.00,
        costo: 0.00,
        valor: 0.00,
        impuesto: 0.00,
        impuestoIncluido: false,
        existencia: 0,
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: null,
        producto: null,
        codigoProducto: "",
        unidadProducto: null,
        venta: 0.00,
        lote: 0,
      }



      this.ListProd.push(detalle)
    },


    addDetalle(unidadProducto) {

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: 1,
        costo: unidadProducto.costo,
        valor: unidadProducto.costo,
        impuesto: this.calcularImpuesto(unidadProducto),
        impuestoIncluido: this.impuestoIncuidos(unidadProducto),
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: unidadProducto.unidades,
        producto: unidadProducto.productos,
        codigoProducto: "",
        existencia: 0,
        unidadProducto: unidadProducto,
        venta: (unidadProducto.venta == null ? 0.00 : unidadProducto.venta),
        cantidadpedida: 0,
        lote: this.lote,
      }

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      if (unidadProducto == null) {
        return
      }
      if (unidadProducto.unidades != null && unidadProducto.unidades.codigo != undefined) {
        u = unidadProducto.unidades.codigo;
      }
      if (unidadProducto.productos != null && unidadProducto.productos.codigo != undefined) {
        p = unidadProducto.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {

        this.findExistencia(p, u, a, e)

      }

      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)

      this.ListProd.push(e)
    },
    addDetallePedido(unidadProducto) {

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      var p = 0, u = 0, a = 0
      var e = {
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: unidadProducto.cantidad,
        costo: unidadProducto.costo,
        valor: unidadProducto.costo,
        impuesto: this.calcularImpuesto(unidadProducto),
        impuestoIncluido: this.impuestoIncuidos(unidadProducto),
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: unidadProducto.unidades,
        producto: unidadProducto.productos,
        codigoProducto: "",
        existencia: 0,
        unidadProducto: unidadProducto,
        venta: unidadProducto.ventas,
        cantidadpedida: unidadProducto.hasOwnProperty('cantidadpedida')?unidadProducto.cantidadpedida:0,
        lote: this.lote,
      }

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      if (unidadProducto == null) {
        return
      }
      if (unidadProducto.unidades != null && unidadProducto.unidades.codigo != undefined) {
        u = unidadProducto.unidades.codigo;
      }
      if (unidadProducto.productos != null && unidadProducto.productos.codigo != undefined) {
        p = unidadProducto.productos.codigo;
      }
      if (VarAlmacen != null && VarAlmacen.codigo != undefined) {
        a = VarAlmacen.codigo;
      }
      if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {

         this.findExistencia(p, u, a, e)
        e.existencia = 0.00
      }

      // var _this = this;
      // setTimeout(function(){_this.ListProd.push(e)},500)

      this.ListProd.push(e)
    },

    addDetalleSugerencia(unidadProducto) {

var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

var p = 0, u = 0, a = 0
var e = {
  movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
  fechavencimiento: null,
  cantidad: unidadProducto.hasOwnProperty('cantidad') && unidadProducto.cantidad!=null ? unidadProducto.cantidad:0,
  // cantidad: 0,
  costo: unidadProducto.costo,
  valor: unidadProducto.costo,
  impuesto: this.calcularImpuesto(unidadProducto),
  impuestoIncluido: this.impuestoIncuidos(unidadProducto),
  id: 0,
  borrado: false,
  almacen: VarAlmacen,
  unidad: unidadProducto.unidades,
  producto: unidadProducto.productos,
  codigoProducto: "",
  existencia: unidadProducto.hasOwnProperty('existencias') && unidadProducto.existencias!=null ? unidadProducto.existencias:0,
  unidadProducto: unidadProducto,
  venta: unidadProducto.hasOwnProperty('ventas') && unidadProducto.ventas!=null ? unidadProducto.ventas:0,
  cantidadpedida: unidadProducto.hasOwnProperty('cantidadpedida')?unidadProducto.cantidadpedida:0,
  lote: this.lote,
}


// var _this = this;
// setTimeout(function(){_this.ListProd.push(e)},500)

this.ListProd.push(e)
 
},





    RecEmitCerrarImpriEtiqueta(e) {
      this.dialogEtiqueta = e

    },


    RecCrearProducto(e) {
      this.dialogCrearProducto = e.estado
    },

    RecAgregarProducto(e) {
      if (e.estado == 'cerrar') {
        this.dialogAddProducto = e.false
      }
      if (e.estado == 'datos') {


        if(this.PropsDespachoPedido==true){
          this.CantPendienteDespachoPedido(e.datos)
        }else{
        this.addDetalle(e.datos)
        this.calcularTotales();
        }
        
        
      }
    },

    borradoReal(e) {
      console.log('Laaa laa S')
    },
    eliminarItem(e) {
      if (e != null) {
        const index = this.ListProd.indexOf(e)
        var c = confirm('¿ Desea eliminar este Registro ?') && this.ListProd.splice(index, 1)

        if (c != false) {

          this.calcularTotales()
          this.borradoReal(e);
        }


        //this.ListProd.splice(this.ListProd.indexOf(e))
      }
    },





    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 500)
    },

    RedireccionBlur(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].blur() }, 500)
    },



    PosicionFocus(e) {
      if (this.VarKeyCode == 40) {
        var ok = e.length - 1
        this.RedireccionFocus('codigo' + ok)
      }

      this.VarKeyCode = 0
    },


    NewObjetoProducto(e) {
      // e.unidadProducto = null

      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      e.almacen = VarAlmacen
      e.costo = 0
      e.cantidad = 1
      e.producto = null
      e.impuesto = 0
      e.impuestoIncluido = false
      e.existencia = 0
      e.valor = 0
      e.lote = 0
    },




    findExistencia(p, u, a, e) {
      //let rs = new Promise()
      var url = '/unidades-productos/findExistencia/' + p + '/' + u + '/' + a;
      if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
        url = '/unidades-productos/findExistenciaLote/' + p + '/' + u + '/' + a + '/' + this.lote;
      }
      this.$axios.get(this.$hostname + this.$hName + url,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => { e.existencia = res.data })

    },

    impuestoIncuidos(e) {
      if (e == null) {
        return false;
      }
      
      if (e.productos == null) {
        return false
      }
      if( e.productos.hasOwnProperty("impuestoincluido") && e.productos.impuestoincluido != null) {
        return e.productos.impuestoincluido
      }

      if( e.productos.hasOwnProperty("incluirimpuestoenprecio") && e.productos.incluirimpuestoenprecio != null) {
        return e.productos.incluirimpuestoenprecio
      }else{
        return false
      }
    },

    calcularImpuesto(unidadProducto) {
      if (unidadProducto.productos == null) {
        return 0.00
      }
      if (unidadProducto.productos.impuesto == null) {
        return 0.00
      }
      var tasa = (unidadProducto.productos.impuesto.tasa == null || unidadProducto.productos.impuesto.tasa == 0 ? 1 : unidadProducto.productos.impuesto.tasa) / 100
      var valImpuesto = unidadProducto.costo * tasa;
      return valImpuesto
    },



    AsignarObjetoProducto(e, Obj) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))

      e.unidadProducto = JSON.parse(JSON.stringify(Obj))
      e.almacen = VarAlmacen
      e.costo = Obj.costo
      e.cantidad = 1
      e.producto = JSON.parse(JSON.stringify(Obj.productos))
      e.impuesto = this.calcularImpuesto(e.unidadProducto)
      e.impuestoIncluido = this.impuestoIncuidos(e.unidadProducto)
      e.unidad = JSON.parse(JSON.stringify(Obj.unidades))
      e.lote = this.lote
      this.findExistencia(Obj.unidadesProductosPK.producto, Obj.unidadesProductosPK.unidad, e.almacen.codigo, e)
      e.valor = e.cantidad * (e.costo + e.impuesto)

if(this.PropsDespachoPedido==true){
      var NoNullObjProRow = this.RpedidoDetalle.filter((ee) => ee.producto!=null);
      var ObjProRow = NoNullObjProRow.filter((ee) => ee.producto.codigo ==Obj.productos.codigo && ee.unidad.codigo==Obj.unidades.codigo);
      //addDetallePedido
      e.cantidad=1
      if(ObjProRow.length>0){
        e.cantidadpedida=ObjProRow[0].cantidad
      }else{
        e.cantidadpedida=0 
      }
}

if(e.producto.hasOwnProperty('controlVencimiento') && e.producto.controlVencimiento==true && this.CelldFechav==true){
  this.RedireccionBlur('codigo' + e.indx)
  this.RedireccionFocus('vencimiento' + e.indx)
}else{
  this.RedireccionBlur('codigo' + e.indx)
  this.RedireccionFocus('cantidad' + e.indx)
}

    },

    changeSelectProduc(e) {
      this.RproductoBuscados = []
      this.NewObjetoProducto(e)

      if (e.unidadProducto != null && e.unidadProducto.hasOwnProperty('costo')) {
        this.AsignarObjetoProducto(e, e.unidadProducto)

      } else {
        var link = '';
        if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
          link = this.$hostname + this.$hName + '/unidades-productos/' + e.unidadProducto + '/false/buscar/'+this.PropsInventarioFisico;
          console.log(link)
        } else {
          if (this.PropsInventarioFisico) {
            link = this.$hostname + this.$hName + '/unidades-productos/' + e.unidadProducto + '/false/buscar/true';
          } else {
            link = this.$hostname + this.$hName + '/unidades-productos/' + e.unidadProducto + '/false/buscar';
          }
        }
        if (this.$store.getters.GetConfiguracionGeneral.configInv.usarLote) {
          this.lote = parseInt(e.unidadProducto.substring(10));
          console.log(e.unidadProducto.substring(10))
        }
        this.$axios.get(link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {

            this.RproductoBuscados = res.data;
            if (res.data.length == 1) {
              this.AsignarObjetoProducto(e, res.data[0])
              this.RproductoBuscados = []
            }

          })
      }

    },


    BlurTabSelectProduc(e) {

      if (this.RproductoBuscados.length > 0) {
        this.AsignarObjetoProducto(e, this.RproductoBuscados[0])
        this.RproductoBuscados = []

        this.RedireccionBlur('Almacen' + e.indx)
        this.RedireccionFocus('cantidad' + e.indx)

        return

      }


      if (e.unidadProducto == null && this.RproductoBuscados.length == 0) {
        this.RedireccionBlur('Almacen' + e.indx)
        this.alerta("Debe seleccionar un producto", "error")

      }

      if (e.unidadProducto != null && !e.unidadProducto.hasOwnProperty('costo') && this.RproductoBuscados.length == 0) {
        this.RedireccionBlur('Almacen' + e.indx)
        this.alerta("Debe seleccionar un producto", "error")


        var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
        e.unidadProducto = null
        e.almacen = VarAlmacen
        e.costo = 0
        e.cantidad = 1
        e.producto = null
        e.impuesto = 0
        e.impuestoIncluido = false
        e.existencia = 0
        e.valor = 0
        e.lote = 0
      }

    },



    AddNewRowListProd(e) {
      var VarAlmacen = JSON.parse(JSON.stringify(this.EntradaAlmacen))
      var detalle = {
        indx: e.indx + 1,
        movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
        fechavencimiento: null,
        cantidad: 0.00,
        costo: 0.00,
        valor: 0.00,
        impuesto: 0.00,
        impuestoIncluido: false,
        existencia: 0,
        id: 0,
        borrado: false,
        almacen: VarAlmacen,
        unidad: null,
        producto: null,
        codigoProducto: "",
        unidadProducto: null,
        venta: 0.00,
        lote:0
      }
      this.ListProd.push(detalle)
      this.RedireccionBlur('codigo' + e.indx)
    },




    OnKeyupAddRowProducCombox(e) {
      if (this.RproductoBuscados.length == 0) {
        this.AddNewRowListProd(e);
        this.VarKeyCode = 40

      }

    },


    calcularTotales() {
      this.TotalProd.impuesto = 0.00
      this.TotalProd.importe = 0.00

var _this=this;
setTimeout(function(){ 
  
  var bar = new Promise((resolve, reject) => {
    (async () => {
      _this.ListProd.forEach(async(dt) => {
        if(dt.unidadProducto!=null){
         if(dt.cantidad>0){
          _this.TotalProd.impuesto += (dt.impuesto * dt.cantidad)
          dt.valor = (dt.cantidad * (dt.costo+dt.impuesto))
          dt.importe= (dt.cantidad * (dt.costo+dt.impuesto))
         _this.TotalProd.importe +=(dt.valor)
        // console.log('----')
        // console.log(dt.valor)
        // console.log('----')
       // console.log(`${_this.TotalProd.importe}`)
         //  props.item.valor =props.item.cantidad * (props.item.costo+props.item.impuesto)      
        if(dt.impuesto==null){
          console.log('ver---')
          console.log(indx)
        }
        }
       }

      });
      resolve()
      })() 

  });
  

  bar.then(() => {  
    _this.$emit("DataTotalList", _this.TotalProd)  
  }); 
  
  

}, 300);

      
    



    },

openProductoSugerido(){

  var BOOL_EJECUT =this.validarProductoSugerido()
if(BOOL_EJECUT==false){
  return
}

  this.dialogProductoSugerido = true

},
    BuscarSugerencia() {
      // this.Reparaloading = true
      this.$emit("limpiarListData", false)
      this.TotalProd.impuesto = 0.00
      this.TotalProd.importe = 0.00

var _this=this;
setTimeout(function(){
  EventBus.$emit("loading", true);
      this.sugerencia = []
      var VarAlmacen = JSON.parse(JSON.stringify(_this.EntradaAlmacen))
      _this.$axios.get(_this.$hostname + _this.$hName + '/pedidos/sugerencia/' + _this.ProductoSugerido.desde + '/' + _this.ProductoSugerido.hasta + '/' + _this.ProductoSugerido.RadioOpc + '/' + VarAlmacen.codigo,
        { headers: _this.$store.getters.GetheadersAxios })
        .then(res => {
          console.log('--res.data--')
          console.log(res.data)
          _this.sugerencia = res.data
          _this.sugerencia.forEach(e => {
            // this.addDetallePedido(e)
             _this.addDetalleSugerencia(e)
          });
          // this.Reparaloading = false
          EventBus.$emit("loading", false);
          _this.dialogProductoSugerido = false

          setTimeout(function(){_this.calcularTotales()},500)

        })

}, 300);

      

    },

    validarProductoSugerido(){
    },


    ProdcSugeDesde() {
      if (this.ProductoSugerido.desde.toString().length == 0) {
        this.ProductoSugerido.desde = new Date().toISOString().substr(0, 10)
      }

    },


    ProdcSugeBlurHasta() {

      if (this.ProductoSugerido.hasta.toString().length == 0) {
        this.ProductoSugerido.hasta = new Date().toISOString().substr(0, 10)
      }

    },


    VerificarCantidad(e) {
      if (this.PropsDespachoPedido == true && e.cantidad != e.cantidadpedida) {
        this.alerta("La cantidad pedida es diferente a la cantidad despachada", "warning");
      }
    },


    ActualizarAlmacenEnTabla() {
      EventBus.$emit("loading", true);
  var bar = new Promise((resolve, reject) => {
    this.ListProd.forEach(e => {
        e.almacen = this.EntradaAlmacen

        if (e.unidadProducto != null && e.unidadProducto.hasOwnProperty('costo')) {
          this.findExistencia(e.unidadProducto.unidadesProductosPK.producto, e.unidadProducto.unidadesProductosPK.unidad, this.EntradaAlmacen.codigo, e)
        }
      });

resolve()

});

bar.then(() => {
  console.log(' ActualizarAlmacenEnTabla All done!');
  this.$emit("DataActAlmacen", false)
  var _this = this;
      setTimeout(function () {
        EventBus.$emit("loading", false);
         }, 900);
});

      
    },

    CargarProductoPedido(){
    this.$emit("DataCleanList", [])

      if(this.ejecutarCargarProducto==true){
        this.ejecutarCargarProducto=false
        var _this = this;
      setTimeout(function () {
          _this.PostCargarProductoPedido();
         }, 900);
      }
      
      
    },

    async PostCargarProductoPedido(){
        var NoNullObjProRow = this.RpedidoDetalle.filter((ee) => ee.producto!=null);
        this.$emit("DataCleanList", [])
    
        await Promise.all(NoNullObjProRow.map(async (element) => { 
        this.$axios
              .get(
                this.$hostname + this.$hName + "/unidades-productos/find/" + element.producto.codigo + "/" + element.unidad.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
              var ObjProRow=JSON.parse(JSON.stringify(res.data))
              ObjProRow.cantidadpedida=element.cantidad
              ObjProRow.cantidad=element.cantidad
              this.addDetallePedido(ObjProRow)
              });
     }));
 
    

       

    
   /* NoNullObjProRow.forEach(element => {      
        this.$axios
              .get(
                this.$hostname + this.$hName + "/unidades-productos/find/" + element.producto.codigo + "/" + element.unidad.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                console.log(res.data)
              var ObjProRow=JSON.parse(JSON.stringify(res.data))
              ObjProRow.cantidadpedida=element.cantidad
              ObjProRow.cantidad=element.cantidad
              this.addDetallePedido(ObjProRow)
              });

       // this.addDetallePedido(ObjProRow)
        });*/

      var _this = this;
      setTimeout(function () {
         _this.ejecutarCargarProducto=true
          _this.calcularTotales();
         }, 900);

    },

    CantPendienteDespachoPedido(e){
      var ObjUniPro=JSON.parse(JSON.stringify(e))
      var NoNullObjProRow = this.RpedidoDetalle.filter((ee) => ee.producto!=null);
      var ObjProRow = NoNullObjProRow.filter((ee) => ee.producto.codigo ==e.productos.codigo && ee.unidad.codigo==e.unidades.codigo);
      //addDetallePedido
      ObjUniPro.cantidad=1
      if(ObjProRow.length>0){
        ObjUniPro.cantidadpedida=ObjProRow[0].cantidad
      }else{
        ObjUniPro.cantidadpedida=0 
      }

      var _this = this;
      setTimeout(function () {
          _this.addDetallePedido(ObjUniPro)
          _this.calcularTotales();
         }, 500);
     // cantidad
    },

    newwTotalfooter(){
      this.TotalProd={ cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }
    },

    async getUnidadesProductosAddList(producto, unidad, detalle) {
      await this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/' + producto + '/' + unidad,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
           detalle.unidadProducto=res.data
          this.ListProd.push(detalle)
        });
    },

    async AddExistenciaProductosList(almacen) {
      EventBus.$emit("loading", true);
      // findExistencia

var bar = new Promise((resolve, reject) => {
  this.ListProd.forEach((value, index, array) => {

    if (value.unidadProducto == null) {
        return
      }
         var p = 0, u = 0, a = 0
         almacen
     if (value.unidad != null && value.unidad.hasOwnProperty('codigo')) {
        u = value.unidad.codigo;
      }
      if (value.producto != null && value.unidad.hasOwnProperty('codigo')) {
        p = value.producto.codigo;
      }
      if (almacen != null && almacen.hasOwnProperty('codigo')) {
        a = almacen.codigo;
      }

    if (a == 0 || p == 0 || u == 0) {
        e.existencia = 0.00
      } else {
        this.findExistencia(p, u, a, value)
      }

    });

    resolve()
});

bar.then(() => {
console.log('All done , AddExistenciaProductosList!');

      var _this = this;
      setTimeout(function () {
        EventBus.$emit("loading", false);
      }, 900);
});




     /* await this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/' + producto + '/' + unidad,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
           detalle.unidadProducto=res.data
          this.ListProd.push(detalle)
        });*/
    },

// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

DataRetornMenuOpcBotonesPermisosPv(e) {

},

DataRetornPadrePermisoPv(e) {
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccBtnAgregarProductoTabla') {
       // this.EjecutarPermisoPv = true
        var _this = this;
        //var OBJDATA=JSON.parse(JSON.stringify(this.ObjEjecPermisoPv)) 
        //this.ObjEjecPermisoPv=null
        setTimeout(function () {
          _this.OpenAddProducto();
         // _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    } ,


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e),
          (this.aalert.type = "success");

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e),
          (this.aalert.type = "error");

        this.ejecucion = true;
      }


      if (l == "warning") {
        (this.aalert.estado = true),
          (this.aalert.color = "warning"),
          (this.aalert.nombre = e)
            (this.aalert.type = "warning");

        this.ejecucion = true;
      }


    },




  },

  computed: {

    ActDescBtn() {

      if (this.ProsActuAlmacen == true) {

        //this.ActualizarAlmacenEnTabla()

      }

      if (this.ListProd.length == 0) {
        this.newDetalle()

      }
    }


  }


}
</script>

<style>

</style>