<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Existencia de productos'"
  :descripcion="'Las existencias en esta pantalla estan representadas en la unidad base es decir la unidad miníma del producto.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitExitenciaProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central -------------------------------------------------------------------------------->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" > 
 <v-row class="pa-0 ma-0 py-0 my-0">
   <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 px-2 pb-2 ">
     <h4>Consultas de productos</h4>
   </v-col>
 <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 pb-2 ">
   <v-combobox                   
                        autocomplete="off"
                        dense
                        outlined
                        label="Productos:"
                        :items="Rproducto"
                        item-text="codProDesc"
                        @keyup="CbFilProducto"
                        @blur="SelctCbProduc"
                        v-model="ExitenciaProductos.producto"
                       @focus="CargarRproducto" 
                       hide-details
                       @change="BuscarFiltros"
                       no-filter
                      >
                      <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                        <template v-slot:prepend>
                          <i
                          @click="productoClick"
                            style="font-size: 20px;"
                            class="fa fa-shopping-cart"
                          ></i>
                        </template>            
  </v-combobox>
 </v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 pb-2">
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="proveedor:"
            :items="Rproveedor"
            item-text="nombre" 
            style="font-size: 13px;" 
            v-model="ExitenciaProductos.proveedor"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            hide-details
            @change="BuscarFiltros"
            
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
                    </v-combobox>
</v-col>

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
 <!-- -----------------------------  almacen  -->
<v-combobox 
                    v-model="ExitenciaProductos.almacen"
                    label="Almacén"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="Ralmacen"
                    item-text="descripcion"
                    @blur="SelctCbAlmacen"
                    @focus="CargarRalmacen" 
                    hide-details  
                    @change="BuscarFiltros"
                  >
                    <template v-slot:prepend>
                      <v-img
                        height="20px"
                        width="20px"
                        src="../assets/warehouse.png"
                      ></v-img>
                    </template>
 </v-combobox>
</v-col>

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
<v-combobox                 
                        autocomplete="off"
                        dense
                        outlined
                        label="Departamento:"
                        :items="RDepartamento"
                        item-text="descripcion"
                        :rules="[$rules.required]"
                        v-model="ExitenciaProductos.departamento" 
                        hide-details 
                        @focus="cargarDepartamento"
                        @blur="SelctCbDesc"
                        @change="BuscarFiltros"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-building"
                          ></i>
                        </template>
                      </v-combobox>
</v-col>

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
<v-combobox                 
                        autocomplete="off"
                        dense
                        outlined
                        label="Fabricante:"
                        :items="RFabricante"
                        item-text="nombre"
                        :rules="[$rules.required]"
                        v-model="ExitenciaProductos.fabricante" 
                        @focus="CargarRFabricantes"
                        @blur="SelctCbFabricantes"
                        hide-details 
                        @change="BuscarFiltros"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-wrench"
                          ></i>
                        </template>
</v-combobox>
</v-col>

</v-row>
</v-col>
<v-row>


<!-- fn columna 1 -->        
<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2">
 <div style="border: 1px solid #000000;" >
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'Nombre', sortable: false,  value: 'productos.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Und', sortable: false, value: 'unidad.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Fabricante', sortable: false, value: 'productos.fabricante.nombre', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Costo', sortable: false, value: 'unidadproducto.costo', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Precio', sortable: false, value: 'unidadproducto.precio', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Exixtencia', sortable: false, value: 'balance', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Valor', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Almacén', sortable: false, value: 'almacenes.descripcion', width:'auto' , class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listDatos"
              :search="search"      
              @pagination="cargarPaginacionAll"
              :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page"
              :pageCount="TbPaginator.pageCount"
              :server-items-length="TbTotalItem"
            >

<template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.valor )}}</span>
    </template>

<!-- 
<template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.unidadproducto.costo*props.item.balance )}}</span>
    </template> -->


    <template v-slot:item.unidadproducto.costo="props">
    <span>{{currencyFormatter(props.item.unidadproducto.costo )}}</span>
    </template>

      <template v-slot:item.unidadproducto.precio="props">
    <span>{{currencyFormatter(props.item.unidadproducto.precio )}}</span>
    </template>


 <template v-slot:item.action="{ item }"> 
    <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn v-on="on" fab @click.prevent="" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn> 

 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-eye"> 
          <span> Ver registro.</span>
    </i>   
</v-tooltip>
<!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
  
 </template> 

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.costo)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.precio)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.existencia)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.valor)}} </strong></td>
 
 <td class="border-top-bottom"  ><strong></strong></td>
 
 </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total costo: {{currencyFormatter(totales.costo)}}</span>
        <span>Total precio: {{currencyFormatter(totales.precio)}}</span>
        <span>Total existencia: {{currencyFormatter(totales.existencia)}}</span>
       <span>Valor valor: {{currencyFormatter(totales.valor)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>
</v-col>

              </v-row>

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>


<script>
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
    var json = {
      titulo: "Existencia de productos",
      descripcion: "Las existencias en esta pantalla estan representadas en la unidad base es decir la unidad miníma del producto.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "ExitenciaProductosNew",
        "ExitenciaProductosSave",
        "ExitenciaProductosRemove",
        "ExitenciaProductosAnular",
        "ExitenciaProductosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ExitenciaProductosNew", this.new);
    // EventBus.$on("ExitenciaProductosSave", this.save);
    // EventBus.$on("ExitenciaProductosRemove", this.remove);
    // EventBus.$on("ExitenciaProductosAnular", this.remove);
    // EventBus.$on("ExitenciaProductosPrintList", this.imprimirList);
    EventBus.$on("emitExitenciaProductos", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({


      Ralmacen:[],
      RDepartamento:[],
      RFabricante:[],
      Rproveedor:[],
      Rproducto:[], 
      listDatos:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      ExitenciaProductos: {
        proveedor:null,
        producto:null,
        almacen:null,
        departamento:null,
        fabricante:null
        // fechaCreacion: new,
        //  fechaModificacion: "", 
      },

totales:{
  costo:0,
  precio:0,
  existencia:0,
  valor:0
},

TbTotalItem:0,
TbPaginator: {
        page:1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },



EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,

onResize(e){

this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {

      this.ExitenciaProductos = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() {},
    newwnuevo() {
      this.ExitenciaProductos= {
        proveedor:null,
        producto:null,
        almacen:null,
        departamento:null,
        fabricante:null
        // fechaCreacion: new,
        //  fechaModificacion: "", 
      },

this.totales={
  costo:0,
  precio:0,
  existencia:0,
  valor:0
}


this.BuscarFiltros()

     //   EventBus.$emit("actualizaBarraBusqueda2");
    },






CargarRproducto(){

  this.$axios.get(this.$hostname +this.$hName +
      "/unidades-productos/*/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    ).then(res => {this.Rproducto = res.data }  );
},

CbFilProducto(e) {
     if (e.target.value.length >= 0) {

 this.$axios
    .get(this.$hostname +
        this.$hName +
        "/unidades-productos/"+(e.target.value === "" ? "A" : e.target.value) +"/false/buscar",
      {headers:this.$store.getters.GetheadersAxios}
    )
    .then(res => {this.Rproducto = res.data });
     }
    },


 SelctCbProduc() {


 if (this.ExitenciaProductos.producto == null) {
        this.ExitenciaProductos.producto = "";
      }

      if (this.ExitenciaProductos.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.ExitenciaProductos.producto = "";
        return;
      }

    },

CargarRalmacen() {
      this.$axios
    .get(this.$hostname + this.$hName + "/almacen/activos", 
    {headers:this.$store.getters.GetheadersAxios} 
    )
    .then(res => {
      this.Ralmacen = res.data;
    });

    },

 SelctCbAlmacen() {
      if (this.ExitenciaProductos.almacen== null) {
        this.ExitenciaProductos.almacen = "";
      }

      if (this.ExitenciaProductos.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.ExitenciaProductos.almacen = "";
        return;
      }
    },

CargarRFabricantes() {
      this.$axios
          .get(
              this.$hostname + this.$hName + "/fabricante/activo",
              {
                headers: this.$store.getters.GetheadersAxios
              }
          ).then(res => this.RFabricante = res.data)
          .catch()
    },

 SelctCbFabricantes() {
      if (this.ExitenciaProductos.fabricante == null) {
        this.ExitenciaProductos.fabricante = "";
      }

      if (this.ExitenciaProductos.fabricante.codigo == undefined) {
        this.alerta("Seleccione un fabricante", "error");
        this.ExitenciaProductos.fabricante = "";
        return;
      }
    },


cargarDepartamento(){

 this.$axios
          .get(
              this.$hostname + this.$hName + "/departamento/activo",
              {
                headers: this.$store.getters.GetheadersAxios
              }
          ).then(res => this.RDepartamento = res.data)
          .catch(error => {
            if (error.response) {
              this.alerta(error.response.request.responseText, 'error')
            } else {
              this.alerta(error, 'error')
            }
          });
},


 SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.ExitenciaProductos.departamento == null) {
        this.ExitenciaProductos.departamento = "";
      }

      if (this.ExitenciaProductos.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.ExitenciaProductos.departamento = "";
        return;
      }
    },







cargarProveedor(){

this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))

},

CbFilProveedor(e) {
     
       if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
      
    },

SelctCbProveedor(){
  if(this.ExitenciaProductos.proveedor==null){

    this.ExitenciaProductos.proveedor=''
  }

if(this.ExitenciaProductos.proveedor.codigo== undefined){

this.alerta("Seleccione un proveedor", "error");
this.ExitenciaProductos.proveedor=''

//qlq2

return

}

},


BuscarFiltros(){
var Pg={
itemsLength: this.TbPaginator.itemsLength,
itemsPerPage: this.TbPaginator.itemsPerPage,
page: 1,
pageCount: 1,
pageStart: 0,
pageStop: this.TbPaginator.itemsPerPage,
}

this.TbPaginator= JSON.parse(JSON.stringify(Pg))
this.BuscarExistenciproducto()
},



cargarPaginacionAll(e) {

if(e.itemsPerPage==-1){e.itemsPerPage=15}
this.TbPaginator= JSON.parse(JSON.stringify(e))
this.BuscarExistenciproducto()
    },




BuscarExistenciproducto(){


var _this=this;
setTimeout(function(){ 
console.log(_this.ExitenciaProductos)
var sma = _this.$store.getters.GetheadersAxios["X-TENANT-ID"]
var where=` where bl.borrado= false`
if(_this.ExitenciaProductos.proveedor!=null && _this.ExitenciaProductos.proveedor.hasOwnProperty('codigo') ){ where+=` and bl.producto in (select producto from ${sma}.producto_suplidor ps where ps.proveedor = ${_this.ExitenciaProductos.proveedor.codigo} )` }
if(_this.ExitenciaProductos.almacen!=null && _this.ExitenciaProductos.almacen.hasOwnProperty('codigo')){ where+=` and al.codigo= ${_this.ExitenciaProductos.almacen.codigo}` }
if(_this.ExitenciaProductos.departamento!=null && _this.ExitenciaProductos.departamento.hasOwnProperty('codigo')){ where+=` and pr.departamento= ${_this.ExitenciaProductos.departamento.codigo}` }
if(_this.ExitenciaProductos.fabricante!=null && _this.ExitenciaProductos.fabricante.hasOwnProperty('codigo')){ where+=` and pr.fabricante= ${_this.ExitenciaProductos.fabricante.codigo}` }
if(_this.ExitenciaProductos.producto!=null && _this.ExitenciaProductos.producto.hasOwnProperty('unidadesProductosPK')){ where+=` and bl.producto= ${_this.ExitenciaProductos.producto.unidadesProductosPK.producto}` }

var q=`select & from ${sma}.balanceinventario bl INNER JOIN ${sma}.productos pr on bl.producto=pr.codigo INNER JOIN ${sma}.unidades un on bl.unidad= un.codigo INNER JOIN ${sma}.almacenes al on bl.almacen=al.codigo ${where} `

// var q=`select & from ${sma}.almacenes al, ${sma}.unidades_productos up INNER JOIN ${sma}.productos pr on up.producto=pr.codigo left JOIN 
// ${sma}.producto_suplidor ps on ps.producto=up.producto ${where} order by pr.codigo`

var obj={
  limit:_this.TbPaginator.itemsPerPage,
  ultimo:_this.TbPaginator.pageStart,
  qry:q
}


var link = ``
_this.$axios
        .post(_this.$hostname + _this.$hName +"/ConsultaRapida/ExistenciaProductos",obj,{headers:_this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          console.log(res.data.list)
          _this.TbTotalItem=res.data.TbTotalItem,_this.calcularTotal(res.data.list)
        })
        .catch(error => {_this.alerta("Reporte no Impreso("+error+")", "error")});

  }, 300);




},



async calcularTotal(e){

this.totales={
  costo:0,
  precio:0,
  existencia:0,
  valor:0
}


var obj=JSON.parse(JSON.stringify(e))

 await obj.forEach(elent => {

elent.valor= elent.unidadproducto.costo*elent.balance
this.totales.costo+=elent.unidadproducto.costo
this.totales.precio+=elent.unidadproducto.precio
this.totales.existencia+=elent.balance
this.totales.valor+=elent.valor
});

this.listDatos=obj

// props.item.unidadproducto.costo,props.item.unidadproducto.precio,    balance
// totales.costo,totales.precio,totales.existencia,totales.valor

// <template v-slot:item.valor="props">
//     <span>{{currencyFormatter(props.item.unidadproducto.costo*props.item.balance )}}</span>
//     </template>

},


productoClick(){
console.log('--viendo--productoClick')

},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    },

  },


computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

    }
  }


};
</script>

<style></style>
