<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Devoluciones a Proveedor'"
  :descripcion="'Devoluciones a Proveedor'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitDevolucionesProveedor'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
             
<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2" > 

<v-row class="pa-0 ma-0 py-0 my-0" >
<v-col cols="12" md="3" sm="4" class="pa-0 ma-0 px-2">
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="DevolucionesProveedor.desde"
        hide-details
        style="font-size:13px"
        @blur="BlurDesde"
         @input="BuscarDevolucionProveedor"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="3" sm="4" class="pa-0 ma-0 px-2">
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="DevolucionesProveedor.hasta"
        hide-details
        style="font-size:13px"
        @blur="BlurHasta"
         @input="BuscarDevolucionProveedor"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="6" sm="4" class="pa-0 ma-0 px-2">
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="proveedor:"
            :items="Rproveedor"
            item-text="nombre" 
            style="font-size: 13px;" 
            v-model="DevolucionesProveedor.proveedor"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            hide-details   
            @change="BuscarDevolucionProveedor"
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
                    </v-combobox>
</v-col>

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
<v-switch
              class="mt-0"
              color="teal" 
              label=""
              v-model="DevolucionesProveedor.pendiente"
              hide-details
              @change="BuscarDevolucionProveedor"
            >
          <template v-slot:label>
         <strong v-if="DevolucionesProveedor.pendiente==true" style="color:#000000; font-size:13px;">Pendiente: Si</strong>
         <strong v-if="DevolucionesProveedor.pendiente==false" style="color:#000000; font-size:13px;">Pendiente: No</strong>
        </template>
         </v-switch> 
</v-col>


</v-row>
</v-col>

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-2 my-0 px-2">
<div style="border: 1px solid #000000;" >
<v-data-table

      :headers="[
                 { text: 'ver', sortable: false,  value: 'action', width:'auto', class: ['no-gutters black--text']  },
                  { text: 'Fecha', sortable: false,  value: 'fecha', width:'auto', class: ['no-gutters black--text']   },
                 { text: 'Proveedor', sortable: false, value: 'proveedor.nombre', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'No.devolución', sortable: false, value: 'devolucionproveedorPK.secuencia', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Valor devolucón', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text']    },
                 ]"
              dense
              :items="listDevolucion"
              :search="search"      
              :items-per-page="15"
            >

 <template v-slot:item.action="{ item }"> 
    <!-- { text: 'Acción', sortable: false, value: 'action', width:'auto' }, -->

<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn v-on="on" fab @click.prevent="VerDetalleDevolucion(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn> 

 </template>
 <i style="font-size: 20px;" dark dense class="fa fa-eye"> 
          <span> Ver registro.</span>
    </i>   
</v-tooltip>
<!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->             
  
 </template> 


<template v-slot:item.fecha="props">
    <span>{{FormatoFecha(props.item.fecha) }}</span>
</template>

<template v-slot:item.valor="props">
    <span  v-if="props.item.valor>=0" >{{currencyFormatter(props.item.valor )}}</span>
    <span v-if="props.item.valor<0"> ( {{currencyFormatter( (-1*props.item.valor) )}} )</span>
</template>



<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
  <td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totalvalodDevolucion)}} </strong></td>

  </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Valor : {{ currencyFormatter(totalvalodDevolucion) }}</span>
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>
</v-col>


            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


<!-- ------------------------------------------------------ Modal- nueva Factura-->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="DialogDevolucion" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
 dense
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Detalle Devolución </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="DialogDevolucion=false, DevolucionesSelec=null"
        >
        <!-- cerrarModalHis() -->
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form ref="form3" v-model="valid" lazy-validation >

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2" v-if="DevolucionesSelec!=null" > 

<v-row class="pa-0 ma-0 py-0 my-0" >
<v-col cols="12" md="2" sm="3" class="pa-0 ma-0 px-2">

<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha:"
        :rules="[$rules.required]"
        v-model="DevolucionesSelec.fecha"
        hide-details
        disabled
        id="formdisabledInput"
        style="font-size:13px;"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

</v-col>

<v-col cols="12" md="5" sm="5" class="pa-0 ma-0 px-2">

<v-text-field
        dense
        outlined  
        label="Proveedor:"
        v-model="DevolucionesSelec.proveedor.nombre"
        hide-details
        disabled
        id="formdisabledInput"
        style="font-size:13px;"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
                </v-text-field>

</v-col>
<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 px-2">
<v-text-field
        dense
        outlined  
        label="#Devolución:"
         :rules="[$rules.required]"
        v-model="DevolucionesSelec.devolucionproveedorPK.secuencia"
        hide-details
         disabled
         id="formdisabledInput"
         style="font-size:13px;"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-slack"></i>
                </template>
                </v-text-field>

</v-col>

<v-col cols="12" md="3" sm="2" class="pa-0 ma-0 px-2">
<VueAutonumeric label="Valor:" dense outlined
 autocomplete="off"
  v-model.number="DevolucionesSelec.valor"
  hide-details
  disabled
  id="formdisabledInput"
  style="font-size:13px;"
  

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>

</v-col>
</v-row>


<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2">
 <div style="border: 1px solid #000000;" >


<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'Código', sortable: false,  value: 'productos.codigo', width:120, class: ['no-gutters black--text']  },
                 { text: 'Producto', sortable: false, value: 'productos.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Costo', sortable: false, value: 'costo', width:150, class: ['no-gutters black--text']  },
                 { text: 'Cantidad', sortable: false, value: 'cantidad', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Importe', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text']  }
                 ]"
              dense
              :items="DevolucionesSelec.devolucionproveedorDetalleList"    
              :items-per-page="15"
            >



<template v-slot:item.costo="props">
    <span>{{currencyFormatter(props.item.costo )}}</span>
</template>

<template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.valor )}}</span>
</template>

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.costo)}} </strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-total">
<strong class="d-flex justify-start" style="font-size:15px; color: #00838F;">{{currencyFormatter(totales.valor)}}</strong>
</td>

  </tr>
 </template>


  
 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Costo: {{currencyFormatter(totales.costo)}}</span>
        <span>Total Importe: {{currencyFormatter(totales.valor)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>
</v-data-table>
 </div>
</v-col>

</v-col>


</v-form>
</v-card-text>

<hr/> 
 
 
</v-card>
</v-dialog>


    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import VueAutonumeric from "../components/AutoNumericNuevo";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    VueAutonumeric

  },

  created() {
    var json = {
      titulo: "Devoluciones a Proveedor",
      descripcion: "Devoluciones a Proveedor",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "DevolucionesProveedorNew",
        "DevolucionesProveedorSave",
        "DevolucionesProveedorRemove",
        "DevolucionesProveedorAnular",
        "DevolucionesProveedorPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("DevolucionesProveedorNew", this.new);
    // EventBus.$on("DevolucionesProveedorSave", this.save);
    // EventBus.$on("DevolucionesProveedorRemove", this.remove);
    // EventBus.$on("DevolucionesProveedorAnular", this.remove);
    // EventBus.$on("DevolucionesProveedorPrintList", this.imprimirList);
    EventBus.$on("emitDevolucionesProveedor", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({



      DialogDevolucion:false,
      Rproveedor:[],
      clientSuff:'',
      listDevolucion:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

      totalvalodDevolucion:0,
      DevolucionesProveedor: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        proveedor:null,
        pendiente:false
        //fechaCreacion: new,
       //  fechaModificacion: "", 
      },

      DevolucionesSelec:null,

totales:{
  costo:0,
  valor:0
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e

},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.DevolucionesProveedor = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() {},
    newwnuevo() {
      (this.DevolucionesProveedor = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

getInfProveedor(e){

this.clientSuff=''

 if(e!=null){
if(e.hasOwnProperty('codigo')){

this.clientSuff=e.telefono
}else{
this.clientSuff=""

}
 
  }




},


cargarProveedor(){

this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))

},

CbFilProveedor(e) {
     
if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
      
    },

SelctCbProveedor(){
  if(this.DevolucionesProveedor.proveedor==null){

    this.DevolucionesProveedor.proveedor=''
  }

if(this.DevolucionesProveedor.proveedor.codigo== undefined){

this.alerta("Seleccione un proveedor", "error");
this.DevolucionesProveedor.proveedor=''

//qlq2

return

}

},



BlurDesde(){
if(this.DevolucionesProveedor.desde.toString().length==0){
  this.DevolucionesProveedor.desde=new Date().toISOString().substr(0,10)
}

 },


 BlurHasta(){

if(this.DevolucionesProveedor.hasta.toString().length==0){
  this.DevolucionesProveedor.hasta=new Date().toISOString().substr(0,10)
}

 },


BuscarDevolucionProveedor(){


  if (this.DevolucionesProveedor.proveedor == null){
      return 0;
   }



//  var cli = "null"

//      if (this.HistorialProveedor.proveedor != null){
//             cli = this.HistorialCliente.proveedor.codigo
//         }
    

 var f1=new Date(this.DevolucionesProveedor.desde).toISOString().substr(0,10)
 var f2=new Date(this.DevolucionesProveedor.hasta).toISOString().substr(0,10)

// var Obj={}

var link = `/ConsultaRapida/DevolucionesProveedor/${f1}/${f2}/${this.DevolucionesProveedor.proveedor.codigo}/${this.DevolucionesProveedor.pendiente}`
this.$axios
        .get(this.$hostname + this.$hName +link,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.CalcularDevolucion(res.data)
        })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});


},

CalcularDevolucion(e){

this.listDevolucion=[]
this.totalvalodDevolucion=0

e.list.forEach(elent => {
  this.totalvalodDevolucion+=elent.valor
});

this.listDevolucion=e.list

},


VerDetalleDevolucion(e){

this.DevolucionesSelec=null
//this.listDetalleDevolucion=e.devolucionproveedorDetalleList

var ob=JSON.parse(JSON.stringify(e))

ob.fecha=new Date(e.fecha).toISOString().substr(0,10)
this.DevolucionesSelec=ob

this.DialogDevolucion=true


this.totales={
  costo:0,
  valor:0,
}

e.devolucionproveedorDetalleList.forEach(elet => {
 this.totales.costo+= elet.costo
 this.totales.valor+= elet.valor
});



},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { },

  },

computed:{

  ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}


};
</script>

<style></style>
