<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Notas de Credito a Bancos -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Notas de Crédito'" :descripcion="'Notas de Crédito a Bancos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
      <!-- ContenidoBase-Notas de Credito a Bancos  -->

      <tr>
        <!-- BarraPrimaria-Notas de Credito a Bancos  --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>

                  </v-btn-toggle>
                </center>


                <BarraDinamicaLimitePlus :PropsNombre="'Notas de Crédito'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'notacreditobancoPK.secuencia' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{ Text: 'Secuencia', NomValue: 'notacreditobancoPK', Nv2: 'secuencia', Ctsl: 2, Type: 0, State: true },
{ Text: '# Ncf', NomValue: 'ncf', Ctsl: 1, Type: 0, State: true },
{ Text: 'Cuenta', NomValue: 'cuenta', Nv2: 'cuenta', Ctsl: 2, Type: 0, State: true },
{ Text: 'Banco', NomValue: 'cuenta', Nv2: 'banco', Nv3: 'nombre', Ctsl: 3, Type: 0, State: true },
{ Text: 'Documento', NomValue: 'documentobancos', Nv2: 'descripcion', Ctsl: 2, Type: 0, State: true },
{ Text: '# Refernecia', NomValue: 'referencia', Ctsl: 1, Type: 0, State: true },
{ Text: 'concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
{ Text: 'Fecha', NomValue: 'nuevaFecha', Ctsl: 1, Type: 1, State: true },
{ Text: 'Valor', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
// { Text: 'Activo', NomValue: 'activado', Ctsl: 1, Type: 0.4, State: true },
{ Text: 'Estado', NomValue: 'activado', Ctsl: 1, Type: 0.2, State: true }
]" />


                <!-- [
                    { text: 'Código', value: 'notacreditoclientesPK.secuencia' },
                    { text: 'cliente', value: 'cliente.nombre' },
                    { text: 'valor', value: 'valor' },
                    { text: 'concepto', value: 'concepto' },
                    { text: 'referencia', value: 'referencia' },
                    { text: 'ncf', value: 'ncf' },
                    /* { text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
                  ] 

                  [
                    {
                      Text: 'Código',
                      NomValue: 'notacreditoclientesPK',
                      Nv2: 'secuencia',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Nombre',
                      NomValue: 'cliente',
                      Nv2: 'nombre',
                      Ctsl: 2,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Valor $',
                      NomValue: 'valor',
                      Ctsl: 1,
                      Type: 2,
                      State: true,
                    },
                    {
                      Text: 'referencia',
                      NomValue: 'referencia',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'concepto',
                      NomValue: 'concepto',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'ncf',
                      NomValue: 'ncf',
                      Ctsl: 1,
                      Type: 0,
                      State: true,
                    },
                    {
                      Text: 'Estado',
                      NomValue: 'activada',
                      Ctsl: 1,
                      Type: 0.1,
                      State: true,
                    },
                  ]
                  -->


              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Notas de Credito a Bancos ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="formNotasCredito" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 ----------------------------------------------->
                      <v-col cols="12" md="4" sm="4">
                        <!-------------------------------------------------------- Referencia -->
                        <!--@blur="SelctCbOperacion" @focus="CargarROperacion"  -->

                        <v-combobox v-model="NotasCredito.cuenta" label="Cuenta:" required outlined dense disabled
                          :rules="[$rules.required]" autocomplete="off" :items="RCuentaBanco" item-text="cuenta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                          </template>
                        </v-combobox>

                        <!-- -----------------------------  fecha  -->
                        <v-text-field dense outlined type="date" label="Fecha:" :rules="[$rules.required]" v-model="NotasCredito.fecha"
                          @blur="buscarPeriodo">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>
                        <!-- -----------------------------------------Moneda-->
                        <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                          item-text="nombrecorto" :rules="[$rules.required]" v-model="NotasCredito.moneda"
                          @blur="SelctCbMoneda" @focus="cargarMoneda" @change="ChangeMonedaTasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 1 ------------------------------------------->

                      <!-- columna 2  --------------------------------------------->
                      <v-col cols="12" md="4" sm="4">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="NotasCredito.documentobancos"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>
                        <!-------------------------------------------------------- Referencia -->

                        <v-text-field label="No. de Documento:" dense outlined v-model="NotasCredito.referencia"
                          autocomplete="off" @blur="convertirMayusculaNodoc" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
                        <!-------------------------------------------------- Valor-->
                        <VueAutonumeric label="Valor:" dense outlined autocomplete="off"
                          v-model.number="NotasCredito.valor" :rules="[$rules.required]" @blur="getCrear">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                      </v-col>
                      <!-- fn columna 2  ------------------------------------------>

                      <!-- columna 3 ----------------------------------------------->
                      <v-col cols="12" md="4" sm="4">
                        <!-- -----------------------------------------taza -->
                        <VueAutonumeric label="tasa:" dense outlined autocomplete="off"
                          v-model.number="NotasCredito.tasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>
                        <!------------------------------------------------------ NCF -->
                        <v-text-field label="NCF" autocomplete="off" dense outlined v-model="NotasCredito.ncf"
                          @blur="convertirMayusculaNCF">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>
                        <!-- ----------------------------- comentario -->
                        <v-textarea autocomplete="off" label="comentario:" :rows="1" dense outlined
                          v-model="NotasCredito.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- fn columna 3  --------------------------------->

                    </v-row>
                  </v-form>

                </div>
              </v-card>

              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta"
                    :Total="TotalCuenta" />

                </v-col>
              </v-row>
            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->
      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-- [
        { text: 'Código', value: 'notacreditobancoPK.secuencia' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Valor', value: 'valor' },
        { text: 'Referencia', value: 'referencia' },
        { text: 'Concepto', value: 'concepto' },
      ] -->


    <!-------------------------------------------------------- tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpenImpimirListado()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>
            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
                single-line hide-details type="number">
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Secuencia', value: 'notacreditobancoPK.secuencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'ncf', value: 'ncf', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Cuenta', value: 'cuenta.cuenta', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Banco', value: 'cuenta.banco.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Documento', value: 'documentobancos.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: '# Refernecia', value: 'referencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'moneda', value: 'moneda.descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Concepto', value: 'concepto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activado', sortable: false, class: ['no-gutters black--text'] },
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">


              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>


              <template v-slot:item.activado="props">
                <span v-if="props.item.activado == true">Activo</span>
                <span v-if="props.item.activado == false">Inactivo</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general -->



    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'anularNotaCred'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-------------------------------------------------------- Modal- agregar cuenta-->
    <!-- <v-dialog label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent max-width="90%" max-height="80%">
<CuentasContables
@CuentasContables="CCuentasContables"
:RLisCuenta="RLisCuenta"
:objeTem="objeTem"
:Total="TotalCuenta"
/>
</v-dialog> -->
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->



    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px" class=""> </i>
          <v-toolbar-title style="color: white" class="font-weight-light">
            <b>FILTROS</b>
          </v-toolbar-title>
          <i style="font-size: 20px" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color: white" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br />

          <v-form ref="filtrosVer" v-model="valid" lazy-validation>
            <v-row>



              <!-- -----------------------------  columna-1 -->
              <v-col cols="6" md="6">

                <v-combobox v-model="Modallfiltro.cuentaBanco" label="Cuenta de banco:" outlined dense
                  autocomplete="off" :items="RCuentaBanco" item-text="cuenta" @focus="cargarCuentaBanco"
                  @blur="SelctCbCuentaBancoFiltro">
                  <template slot="item" slot-scope="item">
                    {{ item.item.cuenta }}-{{ item.item.banco.nombre }}
                  </template>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>


                <!----------------------------------------------  Desde -->
                <v-text-field dense outlined type="date" label="Desde*" :rules="[$rules.required]"
                  v-model="Modallfiltro.Desde">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
              <!-- ----------------------------- fn columna-1 -->

              <!-- -----------------------------  columna-2 -->
              <v-col cols="6" md="6">


                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                  @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 30px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>


                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" :rules="[$rules.required]"
                  v-model="Modallfiltro.Hasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>
              </v-col>
              <!-- ----------------------------- fn columna-2 -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px" class="fa fa-search"> </i>
              Buscar
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal filtro -->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE NOTA DE CRÉDITO </b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="formImpresion" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">

              <v-col cols="12" md="6" sm="6">

                <v-combobox v-model="datosImprecionAll.cuentaBanco" label="Cuenta de banco:" required outlined dense
                   autocomplete="off" :items="RCuentaBanco" item-text="cuenta"
                  @focus="cargarCuentaBanco" @blur="SelctCbReportCuentaBanco">
                  <template slot="item" slot-scope="item">
                    {{ item.item.cuenta }}-{{ item.item.banco.nombre }}
                  </template>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                  </template>
                </v-combobox>

                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="BlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>


              </v-col>

              <v-col cols="12" md="6" sm="6">

                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion"  v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 30px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>

                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="BlurHasta">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>

                </v-text-field>


              </v-col>


              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2 d-flex justify-center">
<v-radio-group  hide-details class="py-0" v-model="datosImprecionAll.opcion" row>     
                  <!-- <v-radio label="RUTINARIOS" color="green" :value="1"></v-radio>
                  <v-radio label="NO RUTINARIOS" color="green" :value="2"></v-radio>-->
                  <v-radio label="TODOS" color="green" :value="1"></v-radio>
                  <v-radio label="ACTIVO" color="green" :value="2"></v-radio>                                       
                  <v-radio label="ANULO" color="green" :value="3"></v-radio>  
                  <!-- <v-radio label="CANCELADO" color="green" :value="5"></v-radio> -->
                  
</v-radio-group>
</v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->





    <!-- fn Modales ----------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import CuentasContables from "@/Bancos/UtilidadComponents/CuentasContables";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    VueAutonumeric,
    CuentasContables,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    BarraDinamicaLimitePlus
  },

  created() {
    var json = {
      titulo: "Notas de Crédito",
      descripcion: "Notas de Crédito a Bancos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta: false,
      accion: [
        "BANNotasCreditoNew",
        "BANNotasCreditoSave",
        "BANNotasCreditoRemove",
        "BANNotasCreditoAnular",
        "BANNotasCreditoPrintList",
        "BANNotasCreditoVercuenta"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANNotasCreditoNew", this.new);
    // EventBus.$on("BANNotasCreditoSave", this.save);
    // EventBus.$on("BANNotasCreditoRemove", this.remove);
    // EventBus.$on("BANNotasCreditoAnular", this.remove);
    // EventBus.$on("BANNotasCreditoPrintList", this.imprimirList);
    EventBus.$on("emitBANNotasCredito", this.datostabla);
    //  EventBus.$on("BANNotasCreditoVercuenta", this.vercuenta);
    this.cargarTodo();
    this.cargarCuentaBanco()
    EventBus.$on("onResize", this.onResize);
   // this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
  },

  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      objeTem: null,
      RDocumento: [],
      RCuentaBanco: [],
      Rmoneda: [],
      RTipoGasto: [],
      ListaTiposClientes: [],
      RLisCuenta: [],
      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      contabilidad: null,
      NotasCredito: {
        activado: true,
        borrado: false,
        notacreditobancoPK: { secuencia: 0, documento: 0 },
        fecha: fechaNueva(),
        ncf: "",
        referencia: "",
        concepto: "",
        valor: 0.00,
        tasa: 1,
        conciliacion: 0,
        cuenta: null,
        documentobancos: null,
        tipogasto: null,
        moneda: null,
        contabilidad: null
      },



      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'LISTA DE NOTAS CRÉDITO',
        UrlImprimir: '/notacreditobanco/ImprimirList',
        UrlExel: '/notacreditobanco/ExcelList',
        UrlCorreo: '/notacreditobanco/CorreoList',
        secuencia: 0,
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documentos: null,
        cuentaBanco: null,
        opcion:1
      },

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'NOTAS CRÉDITO',
        UrlImprimir: '/notacreditobanco/Imprimir',
        UrlExel: '/notacreditobanco/Excel',
        UrlCorreo: '/notacreditobanco/Correo',
        codigo: 0,
        documento: 0
      },



      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
        cuentaBanco: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },


      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },


      acti_Modal_cuenta: false,
      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,

    onResize(e) {

      //this.windowSize=e
    },

    async cargarTodo() {

    },
   // Establecer el valor de la variable acti_tb_data en falso.
    datostabla(e) {
      this.RLisCuenta=[]
      this.TotalCuenta={credito: 0,debito:0}
      //this.form.RLisCuenta=[]
      var Jsn = JSON.parse(JSON.stringify(e));
      Jsn.fecha=e.nuevaFecha
      // Jsn.fecha = new Date(e.fecha).toISOString().substr(0, 10);
      this.NotasCredito = Jsn;
      this.acti_tb_data = false

      var _this = this;
        setTimeout(function () {
          _this.getCrear();
        }, 500);
      
    },
    buscarPeriodo(e) {
      if (this.NotasCredito.fecha != null) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/contabilidad/findPeriodo/" + this.NotasCredito.fecha,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data === "") {
              this.alerta("No existen periodo para esta fecha", "error")
            }
            this.periodoDetalle = res.data
            if (this.periodoDetalle.cerrado === true) {
              this.alerta("Periodo esta cerrado no puede trabajar en esta fecha", "error");
            }
          })
          .catch((err) => {
            console.log(err), this.alerta("No existen periodo para esta fecha", "error")
          });
      }
      this.getCrear()
    },

    
    // Guardando los datos en la base de datos.
    save() {


      if (this.NotasCredito.conciliacion !== null && this.NotasCredito.conciliacion > 0) {
        this.alerta(
          "Este Note de Credito esta conciliado no puede ser modificado",
          "error"
        );
        return;
      }
      //EventBus.$emit("loading", true);
      if (this.TotalCuenta.credito != this.TotalCuenta.debito) {
        this.alerta("El Total de Debito y Credito no Coincide en el Asiento Contable", "error");
        return
      }

      this.NotasCredito.contabilidad = this.contabilidad;
      if(this.RLisCuenta.length>0){
        this.NotasCredito.contabilidad.contabilidadDetalleList=this.RLisCuenta
      }


      if (this.$refs.formNotasCredito.validate()) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/notacreditobanco/salvar", this.NotasCredito,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            this.alerta("Dato guardado correctamente!", "bien")

          })
          .catch(error =>
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },


  // Creando un nuevo objeto llamado notif y asignándolo a la variable notif.
    NotifMessage() {

      if (this.NotasCredito.notacreditobancoPK.secuencia != 0 && this.NotasCredito.notacreditobancoPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "anularNotaCred"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una nota de credito para anular", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))
      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {

    },


    verificarMovimientoNota(){

var bool=false;
//False: No tiene movimiento/True:contieneMovimiento
return  new Promise((resolve, reject) => {
var linkverificar=`/${this.NotasCredito.notacreditobancoPK.secuencia}/${this.NotasCredito.notacreditobancoPK.documento}`
      this.$axios.get(
        this.$hostname + this.$hName +"/notacreditobanco/VerificarMovimientoConciliacion"+linkverificar,
        { headers: this.$store.getters.GetheadersAxios }
      )
      .then((res) => {
        // if(res.data==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")}
       // else{this.VerificarMovimNotDebito(linkverificar)}
        resolve(res.data)
      })
      .catch((error) => {
        resolve(false)
        this.alerta(error, "error")}); 
});

// return bar.then(() => {return bool});

},



async anular() {
      if (this.notif.estado) {}


      const result =  await this.verificarMovimientoNota();
    if(result==true){this.alerta("Dato no anulado correctamente, Cheque conciliado", "error")
      return ;
    }
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/notacreditobanco/anular", this.NotasCredito,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato anulado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no anulado",
              "error"
            )
          );
      
    },
    imprimirList() {
      this.datosImprecion.codigo = this.NotasCredito.notacreditobancoPK.secuencia
      this.datosImprecion.documento = this.NotasCredito.notacreditobancoPK.documento
      this.dialogimprimirList = true
    },


    OpenImpimirListado(){
      this.dialogimprimirListFilt = true

      var _this = this;
        setTimeout(function () {
          _this.$refs.ModRepFilt.CrearObjecto = _this.validarObjecto;
        }, 500);
      
    },
           
    
    validarObjecto() {

if (!this.$refs.formImpresion.validate()) {
  this.$refs.ModRepFilt.detener = true;
  return;
} else {
  this.$refs.ModRepFilt.detener = false;
}

//nc.activado=true and  $P!{where}
var WHERE=` nc.secuencia > 0 and nc.fecha >= '${this.datosImprecionAll.desde}' and nc.fecha <= '${this.datosImprecionAll.hasta}' `
var CONDUCION=`Desde: ${this.FormatoFecha(this.datosImprecionAll.desde)} \t Hasta: ${this.FormatoFecha(this.datosImprecionAll.hasta)} \n`

if(this.datosImprecionAll.documentos!=null){
  WHERE+=` and db.codigo=${this.datosImprecionAll.documentos.codigo}`
  CONDUCION+=`Documento: ${this.datosImprecionAll.documentos.descripcion} \t`
}


if(this.datosImprecionAll.cuentaBanco!=null){
  WHERE+=` and c.codigo=${this.datosImprecionAll.cuentaBanco.codigo}`
  CONDUCION+=`Cuenta: ${this.datosImprecionAll.cuentaBanco.cuenta.trim()} \t`
}

if(this.datosImprecionAll.opcion==1){CONDUCION+=`Opcion: Todos ` }
if(this.datosImprecionAll.opcion==2){CONDUCION+=`Opcion: Activos`
WHERE+=` and nc.activado=true`
}
if(this.datosImprecionAll.opcion==3){CONDUCION+=`Opcion: Anulados`
  WHERE+=` and nc.activado=false`
 }

this.datosImprecionAll.where=WHERE
this.datosImprecionAll.condicion=CONDUCION



    },

    DDataReporteBasico(e) { this.dialogimprimirList = e },
   // Creando un nuevo objeto y asignándolo a la propiedad de datos NotasCredito.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.RLisCuenta = []
      this.TotalCuenta = { credito: 0, debito: 0 }
      this.contabilidad = null
      this.NotasCredito = {
        activado: true,
        borrado: false,
        notacreditobancoPK: { secuencia: 0, documento: 0 },
        fecha: fechaNueva(),
        ncf: "",
        referencia: "",
        concepto: "",
        valor: 0.00,
        tasa: 1,
        conciliacion: 0,
        cuenta: null,
        documentobancos: null,
        tipogasto: null,
        moneda: null,
        contabilidad: null
      }
      //EventBus.$emit("actualizaBarraBusqueda2");
    },

    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documentobanco/documentonotacredito',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    SelctCbDocumento() {
      if (this.NotasCredito.documentobancos == null) {

        this.NotasCredito.documentobancos = ''
      }

      if (this.NotasCredito.documentobancos.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.NotasCredito.documentobancos = ''
        return

      }
      this.NotasCredito.cuenta = this.NotasCredito.documentobancos.cuenta;
    },


    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return
      }

    },


    SelctCbReportDocumento() {

var _this = this;
setTimeout(function () {
  if (_this.datosImprecionAll.documentos == null) {
    _this.datosImprecionAll.documentos = "";
  }

  if (!_this.datosImprecionAll.documentos.hasOwnProperty('codigo')) {
    _this.datosImprecionAll.documentos = "";
    _this.datosImprecionAll.documentos = null;
    return;
  }
}, 300);

},


    cargarCuentaBanco() {

      this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/findallactivo',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RCuentaBanco = res.data))

    },

    SelctCbCuentaBanco() {
      if (this.NotasCredito.cuenta == null) {

        this.NotasCredito.cuenta = ''
      }

      if (this.NotasCredito.cuenta.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.NotasCredito.cuenta = ''
        return
      }
    },


    SelctCbCuentaBancoFiltro() {
      if (this.Modallfiltro.cuentaBanco == null) {
        this.Modallfiltro.cuentaBanco = ''
      }
      if (this.Modallfiltro.cuentaBanco.codigo == undefined) {
        this.alerta("Seleccione un Cuenta Banco", "error");
        this.Modallfiltro.cuentaBanco = ''
        return
      }

    },


    SelctCbReportCuentaBanco() {

      var _this = this;
setTimeout(function () {
  if (_this.datosImprecionAll.cuentaBanco == null) {
    _this.datosImprecionAll.cuentaBanco = "";
  }

  if (!_this.datosImprecionAll.cuentaBanco.hasOwnProperty('codigo')) {
    _this.datosImprecionAll.cuentaBanco = "";
    _this.datosImprecionAll.cuentaBanco = null;
    return;
  }
}, 300);

    },




   // Llamando a un método llamado llenarcuenta()
    getCrear() {
      if (this.NotasCredito.valor <= 0) {
        return;
      }

      if (this.$refs.formNotasCredito.validate()) {
        if (this.NotasCredito.notacreditobancoPK.secuencia == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-ban-multimoneda/' + this.NotasCredito.documentobancos.codigo + '/' + this.NotasCredito.valor + '/0/0/0/0/' + this.NotasCredito.moneda.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }

        if (this.NotasCredito.notacreditobancoPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.NotasCredito.notacreditobancoPK.documento + '/' + this.NotasCredito.notacreditobancoPK.secuencia + '/' + this.NotasCredito.documentobancos.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              if (res.data.toString().length > 0) {
                this.llenarcuenta(res.data)
              }
            });
        }
      } else {

      }

    },
    // Tomando los datos del primer componente y poniéndolos en el segundo componente.
    llenarcuenta(e) {
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.NotasCredito.fecha;
        this.contabilidad.documento = this.NotasCredito.documentobancos.codigo;
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
     // this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },
    calcularTotalcuenta(e) {

      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0
      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }

    },
    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))

    },

    SelctCbMoneda() {

      if (this.NotasCredito.moneda == null) {

        this.NotasCredito.moneda = ''
      }

      if (this.NotasCredito.moneda.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.NotasCredito.moneda = ''
        return

      }

      this.getCrear();
    },

    ChangeMonedaTasa(e) {

      if (e != null) {
        this.NotasCredito.tasa = JSON.parse(JSON.stringify(e.tasa))
      }
    },

    vercuenta() {

      if (!this.$refs.formDoc.validate() || !this.$refs.formValor.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (this.$refs.formNotasCredito.validate()) {

        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {

        this.alerta("Los campos en rojo son requeridos", "error");
      }

      // this.acti_Modal_cuenta=!this.acti_Modal_cuenta
      // this.objeTem= JSON.parse(JSON.stringify(this.RLisCuenta));

    },

    convertirMayusculaNodoc(e) {
      if (e.target.value.length > 0) {

        this.NotasCredito.Nodoc = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))
        //console.log(e.target.value.toString().toUpperCase())

      }

    },

    convertirMayusculaNCF(e) {
      if (e.target.value.length > 0) {

        this.NotasCredito.ncf = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))

      }

    },

    CCuentasContables(e, t) {
      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.TotalCuenta = JSON.parse(JSON.stringify(t))

    },



    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },


    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    // muestra un panel con una tabla de datos 
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

  // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    // Limpieza del filtro y recarga de la mesa.
    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
        cuentaBanco: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      if(!this.$refs.filtrosVer.validate()){
         this.alerta("Debe de seleccionar los campos en rojo","error")
        return;
      }
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

   // Un método que se llama cuando el usuario hace clic en los botones de paginación.
    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

   // Llamar a una API y obtener datos de la API.
    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      var doc = null
      var Cuen = null

      if (this.Modallfiltro.cuentaBanco != null) { Cuen = this.Modallfiltro.cuentaBanco.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }

      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + doc + "/" + Cuen + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/notacreditobanco/findListNotaCreditoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })

    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
      EventBus.$emit("loading", false);
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/notacreditobanco/findall",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {

    ActDescBtn() {
      if (this.NotasCredito.notacreditobancoPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
  
        if(this.NotasCredito.activado==false){
          this.EstadoBtn.anular=false
          this.EstadoBtn.guardar=false
        }


      }


      if (this.NotasCredito.notacreditobancoPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }





};
</script>

<style>

</style>
