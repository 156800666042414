<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Reporte Listado Conciliación Ars'" :descripcion="'Imprime Listado Conciliación Ars.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesListadoConciliacionArs'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>


                  <span class="font-weight-light" style="font-size: 20px;"> Listado Conciliación Ars </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  desde  -->
                        <v-text-field dense outlined type="date" label="Desde:" :rules="[$rules.required]"
                          v-model="ListadoConciliacionArs.desde">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>


                        <!-------------------------------------------------- Ars -->
                        <v-combobox autocomplete="off" dense outlined label="ARS" :items="RArs"
                          item-text="nombre" v-model="ListadoConciliacionArs.ars"
                          @blur="SelctCbArs" @focus="cargarArs">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shield"></i>
                          </template>
                        </v-combobox>


                        <v-switch  class="mt-0"  color="teal" label=""
                                v-model="ListadoConciliacionArs.ordenAscDesc">
                                <template v-slot:label>
                                  <strong v-if="ListadoConciliacionArs.ordenAscDesc == true"
                                    style="color:#000000; font-size:13px;">Ordenar fecha: Ascendente.</strong>
                                  <strong v-if="ListadoConciliacionArs.ordenAscDesc == false"
                                    style="color:#000000; font-size:13px;">Ordenar fecha: Descendente.</strong>
                                </template>
                              </v-switch>



                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  Hasta  -->
                        <v-text-field dense outlined type="date" :rules="[$rules.required]" label="Hasta:"
                          v-model="ListadoConciliacionArs.hasta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>


                        <v-radio-group  hide-details class="py-0" v-model="ListadoConciliacionArs.cobertura" row>     
                        <v-radio label="TODOS" color="green" :value="1"></v-radio>
                        <v-radio label="ACTIVAS" color="green" :value="2"></v-radio>                                       
                        <v-radio label="ANULADAS" color="green" :value="3"></v-radio>  
                        </v-radio-group>

                      </v-col>
                      <!-- fn columna 2 -->

                    </v-row>

                  </v-form>
                </v-container>

                <hr>

                <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico"
                  :Datos="ListadoConciliacionArs" />


              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>



    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";


export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {
    var json = {
      titulo: "Reporte Listado Conciliación Ars",
      descripcion: "Imprime Listado Conciliación Ars.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [

        "ReportesListadoConciliacionArsNew",
        "ReportesListadoConciliacionArsSave",
        "ReportesListadoConciliacionArsRemove",
        "ReportesListadoConciliacionArsAnular",
        "ReportesListadoConciliacionArsPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReportesListadoConciliacionArsNew", this.new);
    EventBus.$on("ReportesListadoConciliacionArsSave", this.save);
    EventBus.$on("ReportesListadoConciliacionArsRemove", this.remove);
    EventBus.$on("ReportesListadoConciliacionArsAnular", this.remove);
    EventBus.$on("ReportesListadoConciliacionArsPrintList", this.imprimirList);
    EventBus.$on("emitReportesListadoConciliacionArs", this.datostabla);
    this.cargarTodo();
    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({



    Rpropietario: [],
    RCaja: [],
    RArs: [],
    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    ListadoConciliacionArs: {
      nombre: 'Reporte Listado Conciliación Ars',
      UrlImprimir: '/reportescxc/ImprimirConciliacionArs',
      UrlExel: '/reportescxc/ExcelConciliacionArs',
      UrlCorreo: '/reportescxc/CorreoConciliacionArs',
      desde: fechaNueva(),
      hasta: fechaNueva(),
      ars: null,
      cobertura:1,
      ordenAscDesc:true
      //fechaCreacion: new,
      //  fechaModificacion: "", 
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },
    validarObjecto() {
      if (!this.$refs.form.validate()) {
        this.$refs.ModRepFilt.detener = true;
        return;
      } else {
        this.$refs.ModRepFilt.detener = false;
      }
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/tipocliente/findalltipoclientes",
      //     {
      //       usuario:JSON.parse(JSON.stringify(this.$user)) 
      //     }
      //   )
      //   .then(res => (this.ListaTiposClientes = res.data))
      //   .catch(error => console.log(error));
    },
    datostabla(e) {

      this.ListadoConciliacionArs = e;
      this.acti_tb_data = false
    },
    save() {

      if (this.$refs.form.validate()) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.ListadoConciliacionArs)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }




    },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() { },
    DDataReporteBasico() { },
    newwnuevo() {
      (this.ListadoConciliacionArs = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: "",
        cobertura:1
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },



    cargarArs() {

      this.$axios.get(this.$hostname + this.$hName + '/ars/findallactivos', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RArs = res.data))

    },



    SelctCbArs() {

      var _this = this;
      setTimeout(function () {
        if (_this.ListadoConciliacionArs.ars == null) {
          _this.ListadoConciliacionArs.ars = "";
        }

        if (!_this.ListadoConciliacionArs.ars.hasOwnProperty('codigo')) {
          _this.ListadoConciliacionArs.ars = "";
          _this.ListadoConciliacionArs.ars = null;
          return;
        }
      }, 300);

    },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
      //     usuario:JSON.parse(JSON.stringify(this.$user)) 
      //   })
      //   .then(res => {
      //     this.list = res.data;
      //   });
    }
  }
};
</script>

<style>

</style>
