import { parse } from "@fortawesome/fontawesome-svg-core";

export function readTextFile(file) {
  var rawFile = new XMLHttpRequest();
  rawFile.open("GET", file, false);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status == 0) {
        var allText = rawFile.responseText;
        return allText;
      }
    }
  }
  rawFile.send(null);
}
///readTextFile("file:///C:/your/path/to/file.txt");
//Source: https://stackoverflow.com/questions/14446447
var CantRedondeo = 2;

export function RedondeoBasico(x) {
  return Math.round((x + Number.EPSILON) * 100) / 100;
}
export function RedondeoValor(x) {
  var n = JSON.parse(JSON.stringify(CantRedondeo))
  var REDONDEO = Math.round(x * Math.pow(10, n)) / Math.pow(10, n)
  return parseFloat(REDONDEO);
}

export function RedondeoValorCantRed(x, cantRedondeo) {
  var n = JSON.parse(JSON.stringify(cantRedondeo))
  return parseFloat(Math.round(x * Math.pow(10, n)) / Math.pow(10, n));
}


export function currencyFormatter(number) {
  if (number == null) {
    number = 0
  }
  return number
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function currencyFormatterCant(number,cant) {
  if (number == null) {
    number = 0
  }

  if(cant==2){
 return number
    .toFixed(cant)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }else{
    var num = number
    .toFixed(cant)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
var NUMERO=num.split(".")[0];
var DECIMAL=num.split(".")[1];
 return `${NUMERO}.${DECIMAL.replace(",", "")}`;
  }
   



 }

export function FormatoFecha(params0) {
 /* console.log('--FormatoFecha--')
  console.log(params0)*/
  // var params = new Date(params0).toISOString().substr(0, 10);
  if(typeof params0 === 'string'){
    if(!params0.includes("T")){
      params0+="T04:00"
    }
  }
  var params = new Date(params0).toLocaleDateString("en-CA");
  if (params != null) {
    if (params.length > 0) {
      return (
        params.substring(8, 10) +
        "-" +
        params.substring(5, 7) +
        "-" +
        params.substring(0, 4)
      );
    }
  }
}

export function HoraFormatter(params0) {

  var params = new Date(params0).toISOString();
  if (params != null) {
    if (params.length > 0) {
      return (params.substr(11, 5));
    }
  }
}

export function HoraFormatter2(params0) {
  var params = new Date(params0);
  if (params != null) {
    if (params.toString().length > 0) {
      // console.log(params.toString())
      // var ho = `${params.getHours()}:${params.getMinutes()}`
      var ho = params.toString().substr(16, 5)
      return (ho);
    }
  }
}

export function fechaNueva() {
  return new Date().toLocaleDateString("en-CA");
}
export function changeZone(fecha) {
  var params = new Date(fecha+"T04:00");

  return params;
}

export function getHoraActual() {
  var horaCompl;
  var fecha =  new Date();
  var hora24 = fecha.getHours() ;
  var minutes = fecha.getMinutes() ;
  var hora12 ;
  var ampm ;
  if (hora24 > 12) { 
    hora12 -= (hora24 - 12);
    ampm="PM";
  }else{
    hora12= hora24;
    ampm="AM"; 
  }


  return `${hora12}:${minutes} ${ampm}`;
}


export function getParamFecha(fecha) {
  var params = new Date(fecha).toLocaleDateString("en-CA");
  return params+"T04:00"
}

export function getFechaZone(fecha) {
  var params = fecha
  // Date().toLocaleDateString("en-CA");
  return params+"T04:00"
}


export function validaCedRnc(e){

  /* Validación de número de cédula dominicana
	 * con longitud de 11 caracteres numéricos o 13 caracteres incluyendo los dos guiones de presentación
	 * ejemplo sin guiones 00116454281, ejemplo con guiones 001-1645428-1
	 * el retorno es 1 para el caso de cédula válida y 0 para la no válida
	 */

  var CEDULA = e.replaceAll("-", "").trim();
  if(CEDULA!=null && CEDULA.length>0){

  if(CEDULA.length>11){CEDULA=CEDULA.substr(0,11) }


if(CEDULA.length==11){

  var c = CEDULA.replace(/-/g,'');  
	    var cedula = c.substr(0, c.length - 1);  
	    var verificador = c.substr(c.length - 1, 1);  
	    var suma = 0;  
		var cedulaValida = false;
	    if(CEDULA.length < 11) { return false; }  
	    for (var i=0; i < cedula.length; i++) {  
	       var mod = "";  
	         if((i % 2) == 0){mod = 1} else {mod = 2}  
	         var res = cedula.substr(i,1) * mod;  
	         if (res > 9) {  
	              res = res.toString();  
	             var uno = res.substr(0,1);  
	              var dos = res.substr(1,1);  
	              res = eval(uno) + eval(dos);  
	         }  
	         suma += eval(res);  
	    }  
	    var el_numero = (10 - (suma % 10)) % 10;  
	    if (el_numero == verificador && cedula.substr(0,3) != "000") {  
	      cedulaValida = true;
	    }  
	    else   {  
	     cedulaValida = false;
	    }  

		return cedulaValida;
	
}
console.log('Validar Rnc')
if(CEDULA.length==9){
		var suma=0
		var digmultiplicador='79865432'
		var digito=0
		var division =0
		var vresto=0

    var rncValida = false;
		/*For i=1 To 8
			suma=suma+(Val(Substr(cedula_rnc,i,1))*Val(Substr(digmultiplicador,i,1)))
		Endfor*/

    for (var i=0; i < 8; i++) {  
      suma=suma+(CEDULA.substr(i,1)*digmultiplicador.substr(i,1))
    }

		/*division = int(suma / 11)
		vresto   = int(suma - (division * 11))*/

    division = parseInt((suma / 11))
		vresto   = parseInt((suma - (division * 11)))

    if(vresto ==0){digito = 2}
    else{
      if(vresto == 1){digito = 1}else{ digito = 11 - vresto }
    }
    console.log(CEDULA.substr(8,1))
    if(digito == CEDULA.substr(8,1)){
      rncValida= true
    }else{
      rncValida=false
    }

    return rncValida;
	
}


  }

return true

}


//----NOTA Convetir monedas---
// Esto es para convertir la moneda a la moneda seleccionada partiendo de la moneda base
  //--** ((monedaBase/tasaSelect)*monto)   ejemplo: ((57/58)*500)= 491.37931034482756  **--//

  // Esto es para devolver al valor original partiendo de la moneda base
  //--** ((monto*tasaSelect)/monedaBase)   ejemplo: ((491.37931034482756*58)/57)= 500  **--//

//---TOMANDO ENVIANDO  LA MONEDA BASE--

export function desConversorMoneda(monto,tasaSelec){
  //((monto*tasaSelect)/monedaBase)

  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelec!=null?tasaSelec:MONEDA.tasa )
  var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)
  return RedondeoValorCantRed(getTasaMonedaInfoDesConv(monto,tasaSelec,MONEDA.tasa),CANTREDONDEO);
}

export function getTasaMonedaInfoDesConv(valor,tasaSelec,monedaBaseTasa){
  var BVALOR=0
  //var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelec!=null?tasaSelec:monedaBaseTasa )
  BVALOR=((valor*tasaSelec)/monedaBaseTasa)
  return BVALOR;
}


export function conversorMoneda(monto,tasaSelec){
  //((monedaBase/tasaSelect)*monto)
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelec!=null?tasaSelec:MONEDA.tasa )
  var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)
  return RedondeoValorCantRed(getTasaMonedaInfoConv(monto,tasaSelec,MONEDA),CANTREDONDEO);
}

export function getTasaMonedaInfoConv(valor,tasaSelec,monedaBase){
  var BVALOR=0
  //var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelec!=null?tasaSelec:monedaBase.tasa )
  BVALOR=((monedaBase.tasa/MONEDAFACTURA)*valor)
  return BVALOR;
}


export function currencyFormatter2MonedaConv(value,tasaSelec){
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelec!=null?tasaSelec:MONEDA.tasa )
  var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)
  return (
   "" +
   parseFloat(RedondeoValorCantRed(getTasaMonedaInfoConv(value,MONEDAFACTURA,MONEDA),CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
  );
  
}







  
//---TOMANDO LA MONEDA BASE Del SISTEMA--
export function getTasaMonedaInfo(valor,moneda){
    var BVALOR=0
    var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
    var MONEDAFACTURA= (moneda!=null?moneda.tasa:MONEDA.tasa )
    BVALOR=((MONEDA.tasa/MONEDAFACTURA)*valor)
    return BVALOR;
}


export function currencyFormatter2MonedaInf(value,moneda){
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (moneda!=null?moneda.tasa:MONEDA.tasa )
  var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)

  return (
   "" +
   parseFloat(RedondeoValorCantRed(value,CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
  );
  
}

export function getDecimalPlaces(moneda){
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
   var MONEDAFACTURA= (moneda!=null?moneda.tasa:MONEDA.tasa )
   var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)
   return CANTREDONDEO;
}
//---TOMANDO ENVIANDO  LA MONEDA BASE--

export function getTasaMonedaInfo2(valor,tasa,tasaSelect){
  var BVALOR=0
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelect!=null?tasaSelect:MONEDA.tasa )
  BVALOR=((tasa/MONEDAFACTURA)*valor)
  return BVALOR;
}

export function getTasaMonedaInfo2Interna(valor,tasa,tasaSelect){
  var BVALOR=0
   //var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  //var MONEDAFACTURA= (tasaSelect!=null?tasaSelect:MONEDA.tasa )
  BVALOR=((tasa/tasaSelect)*valor)
  return BVALOR;
}

export function currencyFormatter2MonedaInf2(value,tasa,tasaSelect){
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelect!=null?tasaSelect:MONEDA.tasa )
  var CANTREDONDEO=(MONEDAFACTURA!=tasa?4:2)
  
  return (
   "" +
   parseFloat(RedondeoValorCantRed(getTasaMonedaInfo2Interna(value,tasa,MONEDAFACTURA),CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
  );
  
  }



/*export function currencyFormatter2MonedaInf2(value,tasa,tasaSelect){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
var MONEDAFACTURA= (tasaSelect!=null?tasaSelect:MONEDA.tasa )
var CANTREDONDEO=(MONEDAFACTURA!=tasa?4:2)

return (
 "" +
 parseFloat(RedondeoValorCantRed(value,CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
);

}*/


