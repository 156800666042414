export default {
   // Definición de directiva
   bind(el, binding) {
    // __HandleScrollHandler__
    el.__HandledKey__ = event => binding.value(event)
    document.body.addEventListener('keydown', el.__HandledKey__)
  },
  unbind(el) {
    document.body.removeEventListener('keydown', el.__HandledKey__)
  }

}