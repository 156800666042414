<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">

        <ContenidoBase :titulo="'Reporte de Análisis de Antigüedad de Facturas'"
          :descripcion="'Imprime las facturas vencidas.'" :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla"
          @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove" @MethodAnular="anular"
          @MethodImprimir="imprimirList" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReporteDepositoFacturas'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

<!-- Formulario-Analisis de Antigüedad-->
            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>


                  <span class="font-weight-light" style="font-size: 20px;"> Reporte de Análisis de Antigüedad de
                    Facturas </span>

                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  fecha  -->
                        <v-text-field dense outlined type="date" label="Fecha de corte:" :rules="[$rules.required]"
                          v-model="AnalisisAntiguedad.Fecha">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <!-- ----------------------------- Tipo de Cliente -->
                        <v-combobox autocomplete="off" dense outlined label="Tipo de Cliente:" :items="RtipoCliente"
                          item-text="descripcion" v-model="AnalisisAntiguedad.tipocliente" @blur="SelctCbTipoCliente"
                          @focus="cargarTipoCliente">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-users"></i>
                          </template>
                        </v-combobox>


                        <!-- ----------------------------- Vendedor @keyup="CbFilvendedor" -->

                        <v-combobox v-model="AnalisisAntiguedad.vendedor" label=" Vendedor:" required outlined dense
                          autocomplete="off" :items="Rvendedor" item-text="nombre" @blur="SelctCbvendedor"
                          @focus="CargarRvendedor" style="font-size: 13px;">
                          <template slot="item" slot-scope="item">
                            <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                            <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0"
                              cols="12" md="12" sm="12">
                              <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Descripción:
                                  {{ item.item.nombre }}</b></p>
                              <span class="pa-0 ma-0 py-0 my-0">
                                <span class="pe-1" style="border: 1px dashed  #616161;"><b>Cedula.: </b>
                                  {{ item.item.cedula }} </span>
                                <span class="pe-1" style="border: 1px dashed  #616161;"><b>Telefono: </b>
                                  {{ item.item.telefono }} </span>
                                <!-- <span class="pe-1" style="border: 1px dashed  #616161;"><b>Placa: </b> {{item.item.placa }} </span> -->
                              </span>
                            </v-col>

                          </template>

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-universal-access"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  Cliente  -->
                        <v-combobox autocomplete="off" dense outlined label="Cliente:" :items="Rcliente"
                          item-text="codnom" @keyup="CbFilcliente" v-model="AnalisisAntiguedad.cliente"
                          @blur="SelctCbClient" @focus="cargarCliente" no-filter>
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre.trim()}}</span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle-o"></i>
                          </template>
                        </v-combobox>

                        <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                          item-text="nombrecorto" v-model="AnalisisAntiguedad.moneda" @blur="SelctCbMoneda"
                          @focus="cargarMoneda">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>



                        <v-switch class="mt-0" color="teal" inset hide-details label="Formato Resumido:"
                          v-model="AnalisisAntiguedad.Resumen">
                          <template v-slot:label>
                            <strong v-if="AnalisisAntiguedad.Resumen == true" style="color:#000000;">Formato Resumido:
                              Si</strong>
                            <strong v-if="AnalisisAntiguedad.Resumen == false" style="color:#000000;">Formato Resumido:
                              No</strong>
                          </template>
                        </v-switch>





                      </v-col>
                      <!-- fn columna 2 -->

                    </v-row>

                  </v-form>
                </v-container>

                <hr>

                <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="AnalisisAntiguedad" />

              </v-card>
            </v-container>
<!-- fn Formulario-Analisis de Antigüedad-->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros

  },
  created() {
    var json = {
      titulo: "Reporte de Análisis de Antiguedad de Facturas",
      descripcion: "Imprime las facturas vencidas.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ReporteAnalisisAntiguedadNew",
        "ReporteAnalisisAntiguedadSave",
        "ReporteAnalisisAntiguedadRemove",
        "ReporteAnalisisAntiguedadAnular",
        "ReporteAnalisisAntiguedadPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReporteAnalisisAntiguedadNew", this.new);
    EventBus.$on("ReporteAnalisisAntiguedadSave", this.save);
    EventBus.$on("ReporteAnalisisAntiguedadRemove", this.remove);
    EventBus.$on("ReporteAnalisisAntiguedadAnular", this.remove);
    EventBus.$on("ReporteAnalisisAntiguedadPrintList", this.imprimirList);
    EventBus.$on("emitReporteAnalisisAntiguedad", this.datostabla);

    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({




    RtipoCliente: [],
    Rcliente: [],
    Rvendedor: [],
    Rmoneda: [],

    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },
    AnalisisAntiguedad: {
      nombre: 'Antiguedad de Facturas',
      UrlImprimir: '/reportescxc/ImprimirAntiguedadfacturas',
      UrlExel: '/reportescxc/ExcelAntiguedadfacturas',
      UrlCorreo: '/reportescxc/CorreoAntiguedadfacturas',
      vendedor: null,
      Resumen: true,
      tipocliente: null,
      cliente: null,
      moneda: null,
      Fecha: fechaNueva(),
    },
    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },

    datostabla(e) {

      this.AnalisisAntiguedad = e;
      this.acti_tb_data = false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico() { },
    imprimirList() { },
    newwnuevo() {
      (this.AnalisisAntiguedad = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },




    // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rcliente = res.data))
    },


    // El código anterior intenta verificar si el usuario ha seleccionado un cliente del menú
    // desplegable. Si el usuario no ha seleccionado un cliente, entonces el código está intentando
    // establecer el cliente en nulo.
    SelctCbClient() {

      var _this = this;
      setTimeout(function () {
        if (_this.AnalisisAntiguedad.cliente == null) {
          _this.AnalisisAntiguedad.cliente = "";
        }

        if (!_this.AnalisisAntiguedad.cliente.hasOwnProperty('codigo')) {
          _this.AnalisisAntiguedad.cliente = "";
          _this.AnalisisAntiguedad.cliente = null;
          return;
        }
      }, 300);

      /*  if (this.AnalisisAntiguedad.cliente == null) {
          this.AnalisisAntiguedad.cliente = "";
        }
  
        if (this.AnalisisAntiguedad.cliente.codigo == undefined) {
          this.alerta("Seleccione un cliente", "error");
          this.AnalisisAntiguedad.cliente = "";
          return;
        }*/

    },

    CbFilcliente(e) {
      //console.log(e.target.value)

      if (e.target.value.length >= 0) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rcliente = res.data));
      }
    },




  // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RtipoCliente.
    cargarTipoCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/tipoclientes/findall', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RtipoCliente = res.data))


    },


    // Comprobando si el valor es nulo o indefinido.
    SelctCbTipoCliente() {

      var _this = this;
      setTimeout(function () {
        if (_this.AnalisisAntiguedad.tipocliente == null) {
          _this.AnalisisAntiguedad.tipocliente = "";
        }

        if (!_this.AnalisisAntiguedad.tipocliente.hasOwnProperty('codigo')) {
          _this.AnalisisAntiguedad.tipocliente = "";
          _this.AnalisisAntiguedad.tipocliente = null;
          return;
        }
      }, 300);


      /*if(this.AnalisisAntiguedad.tipocliente==null){
         this.AnalisisAntiguedad.tipocliente=''
       }
     if(this.AnalisisAntiguedad.tipocliente.codigo==undefined){
     this.alerta("Seleccione un tipo de cliente", "error");
     this.AnalisisAntiguedad.tipocliente=''
     return
     }*/

    },



  // Llamar a la API y obtener los datos de la API y almacenarlos en la variable Rvendedor.
    CargarRvendedor() {
      this.$axios.get(this.$hostname + this.$hName + '/vendedores/findallactiv', { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        this.Rvendedor = res.data;
      });
    },


   // El código anterior está comprobando si el usuario ha seleccionado un valor del menú desplegable.
   // Si el usuario no ha seleccionado un valor, entonces el código establece el valor en nulo.
    SelctCbvendedor() {


      var _this = this;
      setTimeout(function () {
        if (_this.AnalisisAntiguedad.vendedor == null) {
          _this.AnalisisAntiguedad.vendedor = "";
        }

        if (!_this.AnalisisAntiguedad.vendedor.hasOwnProperty('codigo')) {
          _this.AnalisisAntiguedad.vendedor = "";
          _this.AnalisisAntiguedad.vendedor = null;
          return;
        }
      }, 300);



      /*   if (this.AnalisisAntiguedad.vendedor == null) {
           this.AnalisisAntiguedad.vendedor = "";
         }
   
         if (this.AnalisisAntiguedad.vendedor.codigo == undefined) {
           this.alerta("Seleccione un vendedor", "error");
           this.AnalisisAntiguedad.vendedor = "";
           return;
         }*/

    },


  // Llamar a una API y obtener los datos de la API.
    cargarMoneda() {


      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))
    },

    // Comprobando si el valor es nulo o indefinido.
    SelctCbMoneda() {

      var _this = this;
      setTimeout(function () {
        if (_this.AnalisisAntiguedad.moneda == null) {
          _this.AnalisisAntiguedad.moneda = "";
        }

        if (!_this.AnalisisAntiguedad.moneda.hasOwnProperty('codigo')) {
          _this.AnalisisAntiguedad.moneda = "";
          _this.AnalisisAntiguedad.moneda = null;
          return;
        }
      }, 300);



      /*  if (this.AnalisisAntiguedad.moneda == null) {
          this.AnalisisAntiguedad.moneda = ''
        }
        if (this.AnalisisAntiguedad.moneda.codigo == undefined) {
          this.alerta("Seleccione una moneda", "error");
          this.AnalisisAntiguedad.moneda = ''
          return
        }*/

    },





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {

    }
  }
};
</script>

<style>
</style>
