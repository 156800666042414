<template>
  <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  <tr max-height="48px" height="48px"  > 
<ContenidoBase 
  :titulo="'Clientes'"
  :descripcion="'aqui se registran los clientes de la empresa'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
   
/>
    </tr>
  
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
          <v-tooltip left>
          <template v-slot:activator="{ on }">
            <!--------------------- aqui va la otra tabla izquierda --------->
            <!-- <v-navigation-drawer >  -->
            <center>
              <v-btn-toggle>
                <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                  <i style="font-size: 25px;" class="fa fa-eye"></i> ver
                </v-btn>
                


<v-btn 
                  color="teal darken-2"
                  dark
                  text 
                  small
                  @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))"
                  >
                  <i style="font-size: 25px;" class="fa fa-search"> </i>
                Filtrar
      </v-btn>

              </v-btn-toggle>
            </center>
            <!-- <v-navigation-drawer > 
              
              { text: 'Código', value: 'codigo' },
                { text: 'Nombre', value: 'nombre' },
                { text: 'Balance', value: 'balancecliente.balance' }
              
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Nombre', NomValue:'nombre', Ctsl:1, Type:0, State:true},
              {Text:'Balance $', NomValue:'balancecliente', Nv2:'balance' ,  Ctsl:2, Type:2, State:true},
              {Text:'Estado', NomValue:'activado', Ctsl:1, Type:0.1, State:true},
              ]"
              -->

              <BarraDinamicaLimitePlus
              :PropsNombre="'Cliente'"
              PropsSearch
              :PropsSeachNombre="'Búsqueda por nombre'"
              :TbPaginator="TbPaginator"
              :TbTotalItem="TbTotalItem"
              :PropsList="ListCliente"
              @CargarPaginacion="CargarPaginacion"
              @EmitDAta="datostabla"
              :headers="[
              { text: 'Código', value: 'codigo' },
             /* { text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
              ]"
              :ContListSlot="[
              {Text:'Código',NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Nombre', NomValue:'nombre',  Ctsl:1, Type:0, State:true},
              {Text:'Balance $',NomValue:'balancecliente', Nv2:'balance' , Ctsl:2, Type:2, State:true},
              {Text:'Estado',NomValue:'activado', Ctsl:1, Type:0.1, State:true},
              ]"

            />



            <!-- <BarraBusqueda2
              :url="$hostname + $hName +'/services/clienteservices/findAllClientesFiltro'"
              :nombre="'Cliente'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitCXCClientes'"
              :SeachNombre="'Búsqueda por el nombre del cliente'"
              :slotlist="slotlist"
              :urlcount="'/services/clienteservices/totalRegistrosCliente'"
            /> -->



            <!-- </v-navigation-drawer> -->
          </template>
        </v-tooltip>
        
      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
<div class="px-2">

  <v-form v-model="valid" ref="form" lazy-validation>

<v-card
 color="grey lighten-3"
  >
  <div class="px-2">
    
              <v-row>
                <!-- -----------------------------  col 1 -->
                <v-col cols="12" md="6" sm="6">
                  <!-- -----------------------------  Codigo -->
                  
                  <v-text-field 
                    label="Codigo:"
                    dense
                    outlined
                    v-model="nuevoClientes.codigo"
                    :disabled="true"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                    </template>
                  </v-text-field>

                  <!-- ----------------------------- nombre -->
                 
                  <v-text-field 
                    label="* Nombre:"
                    autocomplete="off"
                    dense
                    outlined
                    :rules="[$rules.required]"
                    v-model="nuevoClientes.nombre"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                  


                  <!-- ----------------------------- cedula/rnc --> 
                
                  <v-text-field 
                  autocomplete="off"
                    v-mask="MakCelRnc"
                    label="* Cedula/RNC:"
                    dense
                    outlined
                    maxlength="13"
                    :rules="[$rules.RulesCelRnc]"
                    v-model="nuevoClientes.cedrnc"
                    @blur="MakaraCel"
                    @focus="MakarNo"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                    </template>
                  </v-text-field>
                 
                  <!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                    label="* Direccion:"
                    dense
                    outlined
                    v-model="nuevoClientes.direccion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>
                 


<!-- ----------------------------- Ciudad -->
                  
                  <v-text-field label="* Ciudad:" dense outlined
                  autocomplete="off"
                    v-model="nuevoClientes.ciudad"
                    :rules="[$rules.required]"
                    >
                  <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map"></i> 
                  </template>
                    </v-text-field>

<!-- ----------------------------- pais -->
<v-combobox
            autocomplete="off"
            dense
            outlined
           
            label="* pais:"
            :items="Rpais"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="nuevoClientes.pais"
            @blur="SelctCbPais"
            @focus="cargarPais"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-globe"></i>
            </template>
          </v-combobox>

           <!-- -----------------------------  email -->
             <v-text-field label="E-mail:"
                     dense outlined
                     v-model="nuevoClientes.email"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>           

<!-- ----------------------------- Plazos de credito -->
                    <v-text-field label="Plazos de Crédito:"
                     dense outlined
                     v-model="nuevoClientes.diascredito"
                     @keyup="DiaCre"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-credit-card"></i> 
                      </template>
                    </v-text-field>


<!-- ----------------------------- Limite de credito -->
<VueAutonumeric label="Límite de Crédito:" dense outlined
 autocomplete="off"
  v-model.number="nuevoClientes.limiteCredito"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>




                    <!-- ----------------------------- Ref. Comercial #1 -->
                
                    <v-text-field label="Ref. Comercial #1:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.referenciacomercial"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-signs"></i> 
                      </template>
                    </v-text-field>

                    


                    <!-- ----------------------------- Ref. Comercial #2 -->
                
                    <v-text-field label="Ref. Comercial #2:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.referenciacomercial2"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-signs"></i> 
                      </template>
                    </v-text-field>

                     <!-- ----------------------------- Ref. Personal #1 -->
                     
                    <v-text-field label="Ref. Personal #1:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.referenciapersonal"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-signs"></i> 
                      </template>
                    </v-text-field>

                    <!-- ----------------------------- Ref. Personal #2 -->
                   
                    <v-text-field label="Ref. Personal #2:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.referenciapersonal2"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-signs"></i> 
                      </template>
                    </v-text-field>



                     <!-- ----------------------------- Aviso/Alerta -->
                    <v-text-field label="Aviso/Alerta:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.avisoalerta"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-bullhorn"></i> 
                      </template>
                    </v-text-field>

                  <!-- ----------------------------- comentario -->
              
                  <v-textarea
                  autocomplete="off"
                    label="comentario:"
                    dense
                    outlined
                    v-model="nuevoClientes.comentario"
                    
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-commenting"></i>
                    </template>
                  </v-textarea>



                </v-col>
                <!-- ----------------------------- fn col 1 -->


               <!-- -----------------------------  col 2 -->
                <v-col cols="12" md="6" sm="6" >
                  <!-- -----------------------------  tb telefonos -->
<v-text-field
          
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="telefonos"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>

<template v-slot:append-outer>

  <v-tooltip top>
<template v-slot:activator="{ on }">
   
<v-btn @click.prevent="addTel" v-on="on" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>

 </template>
          <span>Agregar Teléfono</span>
        </v-tooltip>

        
 </template> 

          </v-text-field>
<v-data-table  
                      :headers="[{ text: 'telefonos', value: 'telefono', sortable:false, class: ['no-gutters black--text'] },{ text: 'actions', value: 'action', sortable:false, class: ['no-gutters black--text'] }]"
                      :items="RLisTel"
                      :items-per-page="5"
                      class="elevation-1"
                    > 
                    <template v-slot:item.action="{ item }">

<v-btn fab @click.prevent="editTel(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 
<v-btn fab @click.prevent="deleTel(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
          
                        
                    </template>

                    </v-data-table>

          <br>
         
              


<!-- -----------------------------  fecha de nacimiento -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha de Nacimiento:"
        v-model="nuevoClientes.fechanacimiento"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>


<!-- -----------------------------  fecha de ingreso -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha de ingreso:"
        v-model="nuevoClientes.fechaingreso"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>



 
<!-- ----------------------------- Tipo de Cliente -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Tipo de Cliente:"
            :items="RtipoCliente"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="nuevoClientes.tipocliente"
            @blur="SelctCbTipoCliente"
            @focus="cargarCliente"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-users"></i>
            </template>
          </v-combobox>


<!-- ----------------------------- Otra Ubicacióne -->
                  
                    <v-text-field label="Otra Ubicación:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.otraubicacion"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i> 
                      </template>
                    </v-text-field>


  <!-- ----------------------------- vendedores -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Vendedores:"
            :items="RVendedores"
            item-text="nombre"
           
            v-model="nuevoClientes.vendedores"
            @blur="SelctCbVendedores"
            @focus="cargarVendedores"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sign-language"></i>
            </template>
          </v-combobox>


<!-- ----------------------------- Paraje -->


                    <v-text-field label="Paraje:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.paraje"
                     
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-location-arrow"></i> 
                      </template>
                    </v-text-field>


<!-- ----------------------------- Cerca de -->


                    <v-text-field label="Cerca de:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.cercade"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-quora"></i> 
                      </template>
                    </v-text-field>


                    

                     <!-- ----------------------------- Donde Trabaja -->

                 
                    <v-text-field label="Donde Trabaja:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.dondetrabaja"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-building-o"></i> 
                      </template>
                    </v-text-field>
                    

 <!-- ----------------------------- Que Trabaja -->

 
                    <v-text-field label="Que Trabaja:"
                    autocomplete="off"
                     dense outlined
                     v-model="nuevoClientes.quetrabaja"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-question-circle-o"></i> 
                      </template>
                    </v-text-field>



 <!-- ----------------------------- zona-->
                    <v-text-field label="Zona"
                     dense outlined
                     v-model="nuevoClientes.zona"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-pin"></i> 
                      </template>
                    </v-text-field>


                     <!-- ----------------------------- Tipo-->
<!-- <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Tipo:"
            :items="RTipo"
            item-text="descripcion"
          
            v-model="nuevoClientes.tipo"
            @blur="SelctCbTipo"
            @focus="cargarTipo"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-tag"></i>
            </template>
          </v-combobox>               -->
 <!-- ----------------------------- statuscliente -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Estatus:"
            :items="RStatus"
            item-text="descripcion"
           :rules="[$rules.required]"
            v-model="nuevoClientes.statuscliente"
            @blur="SelctCbStatus"
            @focus="cargarStatus"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-users"></i>
            </template>
          </v-combobox>

          


<!-- ----------------------------- tipocomprobante -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Tipos de Comprobante:"
            :items="RtipoComprobante"
            :rules="[$rules.required]"
            item-text="descripcion"
            v-model="nuevoClientes.tipocomprobante"
            @blur="SelctCbTipoComprobante"
            @focus="cargarTipoComprobante"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-tag"></i>
            </template>
          </v-combobox>

<!------------------------------------------------ Activado -->
<v-switch
              v-model="nuevoClientes.activado"
              label="Activo"
              color="success"
              hide-detail

              ></v-switch>
                </v-col>
</v-row>

</div>
</v-card>
<hr>
<v-card
 color="grey lighten-3"
  >
<div class="px-2">



<v-row>
<v-col cols="12" sm="12" md="12" >

<!--------------------------------------------------- tb PASAPORTE -->
<v-card>

<v-toolbar
        flat
        dense
        color="#BDBDBD"
      >
<v-tooltip top>
<template v-slot:activator="{ on }">
   
<v-btn v-on="on" @click.prevent="acti_Nueva_Pasaporte=!acti_Nueva_Pasaporte" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>


 </template>
          <span>Agregar pasaporte</span>
        </v-tooltip>


<v-toolbar-title class="font-weight-light"><v-card-title>PASAPORTE</v-card-title></v-toolbar-title>
    
  
      </v-toolbar>
      <v-data-table
        :headers="[{ text: '#PASAPORTE', value: 'numeropasaporte',width:'auto', sortable:false, class: ['no-gutters black--text'] },{ text: 'NOMBRE', value: 'nombre', width:'auto', sortable:false, class: ['no-gutters black--text'] },{ text: 'PARENTESCO', value: 'parentesco',width:'auto', sortable:false, class: ['no-gutters black--text'] },{ text: 'actions', value: 'action', width:'auto', sortable:false, class: ['no-gutters black--text'] }]"
        :items="RLisPas"
      >
    
     <template v-slot:item.action="{ item }">

<v-btn fab @click.prevent="editPas(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 
<v-btn fab @click.prevent="delePas(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
               
                    </template>
    </v-data-table>
</v-card>
<!--------------------------------------------------- tb Factura inicial -->
<v-card>

<v-toolbar
        flat
        dense
        color="#BDBDBD"
      >
<v-tooltip top>
<template v-slot:activator="{ on }">
   
<v-btn v-on="on" @click.prevent="acti_Nueva_facturaInicial=!acti_Nueva_facturaInicial, disableDocu=false" color="green" fab x-small dark>
<i style="font-size: 20px;" class="fa fa-plus-square"></i>
</v-btn>


 </template>
          <span>Agregar Facturas Iniciales</span>
        </v-tooltip>


<v-toolbar-title class="font-weight-light"><v-card-title>FACTURAS INICIALES</v-card-title></v-toolbar-title>
    
     
<!-- addFact -->

<!--fc/,width:150, dc/,width:200, ac/,width:120  -->
      </v-toolbar>
      <v-data-table
        :headers="[{ text: 'DOCUMENTO', value: 'documentoFactura.descripcion',width:'auto', sortable:false, class: ['no-gutters black--text']}, { text: 'REFERENCIA', value: 'referencia', width:'auto', sortable:false, class: ['no-gutters black--text']},{ text: 'NCF', value: 'ncf', width:'auto', sortable:false, class: ['no-gutters black--text']},
        { text: 'FECHA', value: 'fecha',width:'auto', sortable:false, class: ['no-gutters black--text']},{ text: 'DÍAS DE CRÉDITO', value: 'diascredito',width:'auto', sortable:false, class: ['no-gutters black--text']},{ text: 'ITBIS', value: 'valorimpuesto', width:'auto', sortable:false, class: ['no-gutters black--text']},
        { text: 'VALOR', value: 'valor', width:'auto', sortable:false, class: ['no-gutters black--text']},{ text: 'actions', value: 'action', width:'auto', sortable:false, class: ['no-gutters black--text']}]"
        :items="nuevoClientes.ListFact"
      >
     

     <template v-slot:item.action="{ item }"> 

<v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn> 
<v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
               
   </template>

<template v-slot:item.valor="props">
    <span>{{props.item.moneda.nombrecorto}}{{currencyFormatter(props.item.valor)}}</span>
   
  </template>


<template v-slot:item.valorimpuesto="props">
    <span>{{props.item.moneda.nombrecorto}}{{currencyFormatter(props.item.valorimpuesto)}}</span>
    </template>

<template v-slot:item.diascredito="props">
    <span>{{currencyFormatter(props.item.diascredito)}}</span>
    </template>

    <template v-slot:item.fecha="props">
    <span>{{ fecFormatter(props.item.fecha)}}</span>
    </template>



    </v-data-table>
</v-card>
<!--------------------------------------------------- fn tb Factura inicial -->

</v-col>
              </v-row>
</div>
</v-card>


            </v-form>
  </div>
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ Modal- nueva pasaporte-->
<v-dialog transition="fab-transition" label="Modal agregar Pasaporte" v-model="acti_Nueva_Pasaporte" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
 dense
        flat
        color="indigo"
        dense
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Crear Pasaporte Nueva </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="acti_Nueva_Pasaporte=!acti_Nueva_Pasaporte, cerrarModalPas()"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form2"
    v-model="valid"
    lazy-validation
  >
<v-row>
  
<!------------------------------------------ # numero de pasaporte -->
<v-col cols="12" md="6" sm="6">
      <v-text-field
            label="* # Pasaporte:"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="pasaporte.numeropasaporte"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-address-book-o"></i>
            </template>
          </v-text-field>
  </v-col>

<!------------------------------------------------------ nombre -->
<v-col cols="12" md="6" sm="6">

<v-text-field
            label="* nombre:"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="pasaporte.nombre"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-font"></i>
            </template>
          </v-text-field>

</v-col>

<!-------------------------------------------------------- parentesco -->
<v-col cols="12" md="12">
      <v-text-field
            label="* parentesco:"
            dense
            autocomplete="off"
            outlined
            :rules="[$rules.required]"
            v-model="pasaporte.parentesco"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-cc-mastercard"></i>
            </template>
          </v-text-field>
</v-col>


<v-col cols="12" md="6" sm="6">
  <!--------------------------------------------------------- Emision -->
  <v-text-field
        dense
        outlined
        type="date"  
        label="Fecha de Emision"
        v-model="pasaporte.fechaemision"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>
<!----------------------------------------------------- Estado civil -->
   <v-text-field
            label="Estado Civil:"
            dense
            autocomplete="off"
            outlined
          
            v-model="pasaporte.estadocivil"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-venus-mars"></i>
            </template>
          </v-text-field>

          <!-------------------------------------------- nacionalidad -->
 <v-text-field
            label="Nacionalidad:"
            dense
            autocomplete="off"
            outlined
          
            v-model="pasaporte.nacionalidad"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-flag-o"></i>
            </template>
          </v-text-field>

          <!-- ----------------------------- direccion -->

                  <v-textarea
                    label="* Direccion"
                    dense
                    outlined
                    rows="2"
                    v-model="pasaporte.direccionexterior"
                    
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>

              <!-------------------------------------------- tipoPasaporte -->
 <v-text-field
            label="Tipo Pasaporte:"
            dense
            autocomplete="off"
            outlined
          
            v-model="pasaporte.tipopasaporte"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-font"></i>
            </template>
          </v-text-field>

</v-col>




<v-col cols="12" md="6" sm="6">
  <!--------------------------------------------------- vencimiento -->
   <v-text-field
        dense
        outlined
        type="date"  
        label="Fecha de Vencimiento"
        v-model="pasaporte.fechavencimiento"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
</v-text-field>

<!-- -----------------------------------------lugar de nacimiento -->
 <v-text-field
            label="Lugar de Nacimiento:"
            dense
            autocomplete="off"
            outlined
          
            v-model="pasaporte.lugarnacimiento"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-street-view"></i>
            </template>
          </v-text-field>

          <!----------------------------------------------------- numero telefono -->
 <v-text-field
          
            label="Numero Telefono En el Exterior:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="pasaporte.numerotelefonoexterior"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template>
</v-text-field>

<!-------------------------------------------- Ocupacion -->
 <v-text-field
            label="Ocupacion:"
            dense
            autocomplete="off"
            outlined
          
            v-model="pasaporte.ocupacion"
          >
            <template v-slot:prepend>
              <i  style="font-size: 20px;" class="fa fa-wrench"></i>
            </template>
          </v-text-field>


</v-col>




</v-row>
</v-form>
</v-card-text>

<hr/> 
     <!--   d-flex justify-end -->
          <v-btn-toggle   rounded class="d-flex justify-end" >

            <v-btn color="green darken-1" @click.prevent="addPas">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar</v-btn>

          </v-btn-toggle>
      <hr/>  
</v-card>
</v-dialog>


<!-- ------------------------------------------------------ Modal- nueva Factura-->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="acti_Nueva_facturaInicial" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Crear Factura inicial </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click=" acti_Nueva_facturaInicial=! acti_Nueva_facturaInicial, cerrarModalfac()"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form3"
    v-model="valid"
    lazy-validation
  >
<v-row>
  
<!------------------------------------------ Documentos -->
<v-col cols="12" md="6" sm="6">
     <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Documentos:"
            :items="RDocumento"
            item-text="descripcion"
            :rules="[$rules.required]"
            :disabled="disableDocu" 
            v-model="facturaInicial.documentoFactura"
            @blur="SelctCbDocumento"
            @focus="cargarDocumento"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 30px;" class="fa fa-book"></i>
            </template>
          </v-combobox>
  </v-col>

<!------------------------------------------------------ NCF -->
<v-col cols="12" md="6" sm="6">

 <v-text-field
                label="NCF"
                autocomplete="off"
                dense
                outlined
                v-model="facturaInicial.ncf"
              >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-barcode"></i>
                </template>
              </v-text-field>

</v-col>

<!-------------------------------------------------------- Referencia -->
<v-col cols="12" md="12" sm="6">

                    <v-text-field label="* Referencia:"
                     dense outlined
                     v-model="facturaInicial.referencia"
                     :rules="[$rules.required]"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-signs"></i> 
                      </template>
                    </v-text-field>
</v-col>


<v-col cols="12" md="6" sm="6">

<!--------------------------------------------------------- fecha -->
  <v-text-field
        dense
        outlined
        type="date"  
        label="* Fecha:"
        v-model="facturaInicial.fecha"
        :rules="[$rules.required]"
        >

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>

<!----------------------------------------------------- ITBIS -->
   <VueAutonumeric label="ITBIS:" dense outlined
 autocomplete="off"
  v-model.number="facturaInicial.valorimpuesto"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>

  <!-- -----------------------------------------Moneda-->
 <v-combobox
            autocomplete="off"
            dense
            outlined
           
            label="Moneda"
            :items="Rmoneda"
            item-text="nombrecorto"
            :rules="[$rules.required]"
            v-model="facturaInicial.moneda"
            @blur="SelctCbMoneda"
            @focus="cargarMoneda"
            @change="ChangeMonedaTasa"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>
          </v-combobox>

</v-col>




<v-col cols="12" md="6" sm="6">
 <!--------------------------------------------------- Dia de credito -->
                    <v-text-field label="* Días de Crédito:"
                     dense outlined
                     v-model="facturaInicial.diascredito"
                     @keyup="DiaCreFact"
                   
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-credit-card"></i> 
                      </template>
                    </v-text-field>

    <!-- -----------------------------------------valor -->
 <VueAutonumeric label="* Valor:" dense outlined
 autocomplete="off"
 :rules="[$rules.required]"
  v-model.number="facturaInicial.valor"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>

  <!-- -----------------------------------------taza -->
 <VueAutonumeric label="* tasa:" dense outlined
 autocomplete="off"
  v-model.number="facturaInicial.tasa"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
  
</v-col>


</v-row>
</v-form>
</v-card-text>

<hr/> 
     <!--   d-flex justify-end -->
          <v-btn-toggle rounded class="d-flex justify-end" >

            <v-btn color="green darken-1" @click.prevent="addFact">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar</v-btn>

          </v-btn-toggle>
      <hr/>  
</v-card>
</v-dialog>




            <!-- ------------------------------------------------------ tb-data-->
            <v-dialog
              label="myAlert"
              v-model="acti_tb_data"
              persistent
              transition="dialog-bottom-transition"
              fullscreen hide-overlay 
            >
              <v-card>
                <v-toolbar flat color="#BDBDBD">
                  <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
                  <v-toolbar-title class="font-weight-light">REPORTE</v-toolbar-title>
                  <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>

                  <v-spacer></v-spacer>
                  <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent="dialogimprimirListFilt=true, datosImprecion.secuencia=0"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
                  <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
                    <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <v-card>
                    <v-card-title>
                      <v-text-field
                        v-model="Modallfiltro.cliente"
                        @keyup="CargarListTb"
                        label="Buscar"
                        single-line
                        hide-details
                      >
                        <template v-slot:prepend-inner>
                          <i style="font-size: 35px;" class="fa fa-search"></i>
                        </template>
                      </v-text-field>
                    </v-card-title>

                    <v-data-table
                    class="TbSize13"
                      :headers="[
        { text: 'Código', sortable: false, value: 'codigo', class:['black--text'] },
        { text: 'Nombre', sortable: false, value: 'nombre', width: 250,class: ['no-gutters black--text'] },
        { text: 'Cedula/Rnc:', sortable: false , value: 'cedrnc', width: 130,class: ['no-gutters black--text'] },
        { text: 'Fec.nacimiento', sortable: false , value: 'fechanacimiento', width: 150, class: ['no-gutters black--text'] },
        { text: 'Email:', sortable: false, value: 'email', width: 120, class: ['no-gutters black--text'] },
        { text: 'Ciudad', sortable: false, value: 'ciudad', width: 150, class: ['no-gutters black--text'] },
        { text: 'Dirección', sortable: false, value: 'direccion', width: 250, class: ['no-gutters black--text'] },
       // { text: 'pais', sortable: false,  value: 'pais.nombre', width: 150 },
        { text: 'Cerca de:', sortable: false, value: 'cercade', width: 250, class: ['no-gutters black--text'] },
        { text: 'Paraje:', sortable: false, value: 'paraje', width: 250, class: ['no-gutters black--text'] },
        { text: 'Otra Ubicación:', sortable: false, value: 'otraubicacion', width: 250, class: ['no-gutters black--text'] },
        { text: 'Donde Trabaja:', sortable: false, value: 'dondetrabaja', width: 120, class: ['no-gutters black--text'] },
        { text: 'Ref. Comercial #1:', sortable: false, value: 'referenciacomercial',width: 250, class: ['no-gutters black--text']},
        { text: 'Ref. Comercial #2:', sortable: false, value: 'referenciacomercial2',width: 250, class: ['no-gutters black--text']},
        { text: 'Ref. Personal #1:', sortable: false, value: 'referenciapersonal', width: 250, class: ['no-gutters black--text'] },
        { text: 'Ref. Personal #2:', sortable: false, value: 'referenciapersonal2', width: 250, class: ['no-gutters black--text'] },
        { text: 'Aviso/Alerta', sortable: false, value: 'avisoalerta', width: 120, class: ['no-gutters black--text'] },
        { text: 'Comentario', sortable: false, value: 'comentario', width: 250, class: ['no-gutters black--text'] },
        { text: 'Fecha Ingreso', sortable: false, value: 'fechaingreso', width: 150, class: ['no-gutters black--text'] },
        { text: 'Días de Crédito', sortable: false, value: 'diascredito', width: 120, class: ['no-gutters black--text'] },
        { text: 'Lím.Crédito', sortable: false, value: 'limiteCredito', width: 120, class: ['no-gutters black--text'] },
        { text: 'Tipo de Cliente', sortable: false, value: 'tipocliente.descripcion',width: 120, class: ['no-gutters black--text']},
        { text: 'vendedores', sortable: false, value: 'vendedores.nombre', width: 120, class: ['no-gutters black--text'] },
        { text: 'Zona', sortable: false, value: 'zona', width: 120, class: ['no-gutters black--text'] },
        { text: 'tipo', sortable: false, value: 'tipo', width: 120, class: ['no-gutters black--text'] },
        { text: 'Estatus', sortable: false, value: 'statuscliente', width: 120, class: ['no-gutters black--text'] },
        { text: 'Activo', sortable: false, value: 'activado', width: 120, class: ['no-gutters black--text'] }
      ]"
                      dense
                      @pagination="cargarPaginacionAll"
                      :items-per-page="TbPaginator.itemsPerPage"
                      :page="TbPaginator.page"
                      :pageCount="TbPaginator.pageCount"
                      :items="ListCliente"
                      :search="Modallfiltro.cliente"
                      @click:row="datostabla"
                      :server-items-length="TbTotalItem"
                    >
                      <template v-slot:item.activado="props">
                        <span v-if="props.item.activado==true" style="font-size:13px;" >Activo</span>
                        <span v-if="props.item.activado==false" style="font-size:13px;" >In-Activo</span>
                      </template>


                      <template v-slot:item.fechanacimiento="props">
                        <span style="font-size:13px;" >{{fecFormatter(props.item.fechanacimiento)}}</span>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-dialog>
            <!-- ------------------------------------------------------ fn tb-data-->

            <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec='AnularGasto'"
            @click.prevent="anular(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->



<!-- ------------------------------------------------------ Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="60%" max-height="50%">
 <v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small   
          @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))"
        > 
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>

 


                <v-row>


<v-col cols="12" md="12">
<!-------------------------------------------------- Nombre  -->

<!-- <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Nombre:"
            :items="RCliente"
            item-text="nombre"
            :rules="[rules.required]"
           
            v-model="Modallfiltro.cliente"
            @blur="SelctCbCliente"
            @focus="cargarClienteLimt"
            @keyup="CbFilcliente"
            
          >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
                    </v-combobox> -->


<!-- ----------------------------- Tipo de Cliente -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Tipo de Cliente*:"
            :items="RtipoCliente"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="Modallfiltro.tipocliente"
            @blur="SelctCbTipoClienteLimt"
            @focus="cargarCliente"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-users"></i>
            </template>
          </v-combobox>


<!------------------------------------------------ cliende de seguro -->
<v-switch
              v-model="Modallfiltro.Clseguro"
              label="Solo Clientes de Seguro"
              color="success"
              hide-detail

              ></v-switch>

<!------------------------------------------------ cliende de activos -->
<v-switch
              v-model="Modallfiltro.activos"
              label="Activos"
              color="success"
              hide-detail

              ></v-switch>






</v-col>




                </v-row>  
  
  
</v-card-text>
<v-divider></v-divider>
<v-card-actions>
          <v-spacer></v-spacer>
          
<v-btn-toggle dense rounded class="" >


  <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro</v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar</v-btn>

          </v-btn-toggle>

        </v-card-actions>

</v-card>
</v-dialog>

<!-- ------------------------------------------------------ fn Modal filtro -->

<!-- ------------------------------------------------------ Modal- Reportes-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent max-width="80%" max-height="50%">
<!----------------------------------------------------------------- Cuerpo Reporte -->
 <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
      
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;" > <b>REPORTE LISTADO DE CLIENTE </b> </span >

        <v-spacer></v-spacer>
        <v-btn
          color="red"
          fab
          small
          @click.prevent="dialogimprimirListFilt=false"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row class="pa-0 ma-0">

<v-col cols="12" md="12">

<!-- ----------------------------- Tipo de Cliente -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Tipo de Cliente:"
            :items="RtipoCliente"
            item-text="descripcion"
            v-model="datosImprecion.tipocliente"
            @blur="SelctCbReportTipoCliente"
            @focus="cargarCliente"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-users"></i>
            </template>
          </v-combobox>


<!------------------------------------------------ cliende de seguro -->
<v-switch
              v-model="datosImprecion.Clseguro"
              label="Solo Clientes de Seguro"
              color="success"
              hide-detail

              ></v-switch>

<!------------------------------------------------ cliende de activos -->
<v-switch
              v-model="datosImprecion.activos"
              label="Activos"
              color="success"
              hide-detail

              ></v-switch>

</v-col>

              </v-row>
            </v-form>
 </v-container>

<hr>

<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

  </v-card>
<!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


</v-dialog>
<!-- ------------------------------------------------------ fn Modal reportes -->


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
 

<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->





<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>


</template>
<script>
import numeral from 'numeral'
import { mask } from 'vue-the-mask'
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinamLimite";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";

export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    BarraDinamicaLimitePlus,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico
  },

  directives: {
      mask,
    },

  mounted() {

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitCXCClientes", this.datostabla);
    // EventBus.$on("CXCClientesNew", this.new);
    // EventBus.$on("CXCClientesSave", this.save);
    // EventBus.$on("CXCClientesRemove", this.remove);
    // EventBus.$on("CXCClientesAnular", this.anular);
    // EventBus.$on("CXCClientesPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);


   //notaError: en el wsb saber cual es el campo para filtrar Solo Clientes de Seguro

  },
  created() {
    var json = {
      titulo: "Clientes",
      descripcion: "aqui se registran los clientes de la empresa",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta:false,

      accion: [
        "CXCClientesNew",
        "CXCClientesSave",
        "CXCClientesRemove",
        "CXCClientesAnular",
        "CXCClientesPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

 data:()=> ({
 windowSize:{width:"auto", height:"auto"},

dialogimprimirList:false,
dialogimprimirListFilt:false,
datosImprecion:{
nombre:'CLIENTE',
 UrlImprimir: '/clientes/Imprimir', 
 UrlExel: '/clientes/Excel', 
 UrlCorreo: '/clientes/Correo', 
 secuencia: 0, 
 tipocliente: null,
 Clseguro:false,
 activos:true
},


dialogFiltros:false,

Modallfiltro:{
  cliente:null,
  tipocliente:null,
  Clseguro:false,
  activos:true
        
  },

ObjTemTbDinam:null,
ListCliente:[],
TbTotalItem:0,
TbPaginator: {
        page:1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },



RLisTel:[],
RLisPas:[],
RLisFac:[],
RCliente:[],

MakCelRnc:'#############',

    RtipoCliente:[],
    RVendedores:[],
    RStatus:[
{codigo:1, descripcion:"Activo"},
{codigo:2, descripcion:"Suspendido"},
{codigo:3, descripcion:"Cancelado"},
{codigo:4, descripcion:"Incobrable"},
{codigo:5, descripcion:"Mano de Abogado"},
],

RTipo:[
{codigo:1, descripcion:"DISTRIBUIDOR"},
{codigo:2, descripcion:"MAYORISTA"},
{codigo:3, descripcion:"DETALLISTA"}
],

    Rpais:[],
    RtipoComprobante:[],
    RDocumento:[],
    Rmoneda:[],

    telefonos:"",
      Colss: {
        p1: {
          cols: 12,
          lg: 12,
          sm: 12
        }
      },

ObjTemp:null,

pasaporte:{
cod:0,
numeropasaporte:null,
nombre:null,
parentesco:null,
fechaemision:null,
fechavencimiento:null,
estadocivil:"",
lugarnacimiento:"",
nacionalidad:"",
numerotelefonoexterior:"",
direccionexterior:"",
ocupacion:"",
tipopasaporte:""
},

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },





disableDocu:false,
facturaInicial:{

documentoFactura:null,
ncf:"",
referencia:null,
fecha:null,
diascredito:0,
valorimpuesto:0,
moneda:null,
tasa:0,
pendiente:0,
valor:0
  
},

      nuevoClientes: {
        ListFactDele:[],
        ListFact:[],
        ListPas:[],
        ListTel:[],
        codigo: 0,
        nombre: null,
        cedrnc: "",
        direccion: null,
        ciudad: null,
        paraje: "",
        cercade: "",
        otraubicacion: "",
        dondetrabaja: "",
        quetrabaja:"",
        referenciacomercial: "",
        referenciacomercial2: "",
        referenciapersonal: "",
        referenciapersonal2: "",
        avisoalerta: "",
        comentario: "",
        email: "",
        fechanacimiento: null,
        fechaingreso: null,
        diascredito: 0,
        limiteCredito: 0,
        tipocliente: null,
        vendedores: null,
        zona: "",
        tipo: null,
        statuscliente: null,
        pais: null,
        tipocomprobante: null,
        activado: true,
        cheque: false,
        ordenCompraPv: false,
       // usuarios:this.$CodeUser,
        telefonoPrincipal:''
      },

      valid: true,

     
      headers: [{ text: "Nombre", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        {
          dt0: "balancecliente",
          dt1: "balance",
          dt2: null,
          dt3: 5,
          dt4: "Balance:$"
        },
        { dt0: "activado", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

     

      list: [],
      search: "",
      acti_Nueva_Pasaporte:false,
      acti_Nueva_facturaInicial:false,
      acti_tb_data: false,

      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 5,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },
notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
     
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},


BuscarFiltros(){

//var e=null
//EventBus.$emit('BarraDinamLimitadaFiltro', e);

//por aqui

var e={
  opcion:1,
Modallfiltro:this.Modallfiltro,
url:this.$hostname + this.$hName + '/clientes/findFiltro'
}

EventBus.$emit('BarraDinamLimitadaFiltro', e);



this.dialogFiltros= false



},



cargarClienteLimt(){

this.$axios.get(this.$hostname+this.$hName+'clientes/findtodo/A/3',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RCliente=res.data))

},




CbFilcliente(e) {
     
        if (e.target.value.length >= 2) {
         
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/clientes/findtodo/"+(e.target.value===''?"A":e.target.value)+"/3",
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.RCliente = res.data));
        }
      
    },

SelctCbCliente(){
  if(this.Modallfiltro.cliente==null){

    this.Modallfiltro.cliente=''
  }

if(this.Modallfiltro.cliente.codigo== undefined){

this.alerta("Seleccione un cliente", "error");
this.Modallfiltro.cliente=''


//qlq2

return

}

},






MakarNo(){

this.MakCelRnc='#############'

},

MakaraCel(){


if(this.nuevoClientes.cedrnc.toString().length<11){

this.MakCelRnc='###-#####-#'
}


if(this.nuevoClientes.cedrnc.toString().length >=11){

this.MakCelRnc='###-#######-#'
}

// RulesCelRnc
// this.MakCelRnc='###-#####-#',
// MakCelRnc:'###-#######-#',
//console.log(this.nuevoClientes.cedrnc.toString().length)

},

cargarDocumento(){

this.$axios.get(this.$hostname+this.$hName+'/documentoventas/documentoFacturaIniciales',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RDocumento=res.data))

},

SelctCbDocumento(){
  if(this.facturaInicial.documentoFactura==null){

    this.facturaInicial.documentoFactura=''
  }

if(this.facturaInicial.documentoFactura.codigo== undefined){

this.alerta("Seleccione un documento", "error");
this.facturaInicial.documentoFactura=''
return

}

},



SelctCbMoneda(){

 if(this.facturaInicial.moneda==null){

    this.facturaInicial.moneda=''
  }

if(this.facturaInicial.moneda.codigo==undefined){

this.alerta("Seleccione una moneda", "error");
this.facturaInicial.moneda=''
return

}

},

ChangeMonedaTasa(e){

if(e!=null){
console.log("MonedaTasa")
console.log(e)
this.facturaInicial.tasa=JSON.parse(JSON.stringify(e.tasa))
}
},


cargarMoneda(){

this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rmoneda=res.data))

},




cargarTipoComprobante(){

this.$axios.get(this.$hostname+this.$hName+'/tiponcf/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RtipoComprobante=res.data))

},

SelctCbTipoComprobante(){

 if(this.nuevoClientes.tipocomprobante==null){

    this.nuevoClientes.tipocomprobante=''
  }

if(this.nuevoClientes.tipocomprobante.codigo==undefined){

this.alerta("Seleccione un tipo de comprobante", "error");
this.nuevoClientes.tipocomprobante=''
return

}

},



cargarPais(){

this.$axios.get(this.$hostname+this.$hName+'/paises/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rpais=res.data))

},

SelctCbPais(){

 if(this.nuevoClientes.pais==null){

    this.nuevoClientes.pais=''
  }

if(this.nuevoClientes.pais.id==undefined){

this.alerta("Seleccione un pais", "error");
this.nuevoClientes.pais=''
return

}

},

cargarTipo(){

// this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{})
// .then(res=>( this.RTipo=res.data))

},

SelctCbTipo(){

 if(this.nuevoClientes.tipo==null){

    this.nuevoClientes.tipo=''
  }

if(this.nuevoClientes.tipo.codigo==undefined){

this.alerta("Seleccione un tipo", "error");
this.nuevoClientes.tipo=''
return

}

},







cargarStatus(){

// this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{})
// .then(res=>( this.RStatus=res.data))

},

SelctCbStatus(){

 if(this.nuevoClientes.statuscliente==null){

    this.nuevoClientes.statuscliente=''
  }

if(this.nuevoClientes.statuscliente.codigo==undefined){

this.alerta("Seleccione un Estatus", "error");
this.nuevoClientes.statuscliente=''
return

}

},


cargarVendedores(){

this.$axios.get(this.$hostname+this.$hName+'/vendedores/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RVendedores=res.data))

},

SelctCbVendedores(){

 if(this.nuevoClientes.vendedores==null){

    this.nuevoClientes.vendedores=''
  }

if(this.nuevoClientes.vendedores.codigo==undefined){

this.alerta("Seleccione un vendedor", "error");
this.nuevoClientes.vendedores=''
return

}

},



cargarCliente(){

this.$axios.get(this.$hostname+this.$hName+'/tipoclientes/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RtipoCliente=res.data))

},

SelctCbTipoClienteLimt(){

 if(this.Modallfiltro.tipocliente==null){

    this.Modallfiltro.tipocliente=''
  }

if(this.Modallfiltro.tipocliente.codigo==undefined){

this.alerta("Seleccione un tipo de cliente", "error");
this.Modallfiltro.tipocliente=''
return

}

},


SelctCbTipoCliente(){

 if(this.nuevoClientes.tipocliente==null){

    this.nuevoClientes.tipocliente=''
  }

if(this.nuevoClientes.tipocliente.codigo==undefined){

this.alerta("Seleccione un tipo de cliente", "error");
this.nuevoClientes.tipocliente=''
return

}

},


SelctCbReportTipoCliente(){

 if(this.datosImprecion.tipocliente==null){

    this.datosImprecion.tipocliente=''
  }

if(this.datosImprecion.tipocliente.codigo==undefined){

this.alerta("Seleccione un tipo de cliente", "error");
this.datosImprecion.tipocliente=''
return

}

},



DiaCreFact(e){

  if(e.key){
     var mynumeral = require('numeral')
     this.facturaInicial.diascredito  = mynumeral(this.facturaInicial.diascredito).value()
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(this.facturaInicial.diascredito==null){

this.facturaInicial.diascredito=0
     }
     
    }
    else{
     console.log("no se puede poner letras o signos")
    }
},


DiaCre(e){

  if(e.key){
     var mynumeral = require('numeral')
     this.nuevoClientes.diascredito  = mynumeral(this.nuevoClientes.diascredito).value()
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(this.nuevoClientes.diascredito==null){

this.nuevoClientes.diascredito=0
     }
     
    }
    else{
     console.log("no se puede poner letras o signos")
    }
},


delePas(item){

const index =  this.RLisPas.indexOf(item)
  confirm('Desea eliminar este Pasaporte?') && this.RLisPas.splice(index, 1)

this.nuevoClientes.ListPas=this.RLisPas

},

editPas (item) {

this.pasaporte=Object.assign({}, item); 
this.ObjTemp= Object.assign({}, item);   


// this.pasaporte=item
// this.ObjTempasaporte=item;

const index =  this.RLisPas.indexOf(item)
this.RLisPas.splice(index, 1)



this.nuevoClientes.ListPas=this.RLisPas
this.acti_Nueva_Pasaporte=true

        // this.editedIndex = this.desserts.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        // this.dialog = true
 },


newPasaporte(){

this.pasaporte={
numeropasaporte:null,
nombre:null,
parentesco:null,
fechaemision:null,
fechavencimiento:null,
estadocivil:"",
lugarnacimiento:"",
nacionalidad:"",
numerotelefonoexterior:"",
direccionexterior:"",
ocupacion:"",
tipopasaporte:""
}

this.ObjTemp=null

},

newFactura(){

this.facturaInicial={

documentoFactura:null,
ncf:"",
referencia:null,
fecha:null,
diascredito:0,
valorimpuesto:0,
moneda:null,
tasa:0,
pendiente:0,
valor:0
  
}

this.ObjTemp=null;
},

cerrarModalPas(){

if(this.ObjTemp!=null){

this.nuevoClientes.ListPas.push(this.ObjTemp)


}

this.newPasaporte()
},


cerrarModalfac(){

if(this.ObjTemp!=null){

this.nuevoClientes.ListFact.push(this.ObjTemp)
this.nuevoClientes.ListFact= JSON.parse(JSON.stringify(this.nuevoClientes.ListFact))   

}

this.newFactura()
},




addFact(){

if (this.$refs.form3.validate()) {



this.nuevoClientes.ListFact.push(this.facturaInicial)
this.acti_Nueva_facturaInicial=false


this.nuevoClientes.ListFact= JSON.parse(JSON.stringify(this.nuevoClientes.ListFact))   

this.newFactura()





}else{

this.alerta("Los campos marcados en rojo son obligatorios", "error");

}

//{ text: '#PASAPORTE', value: 'numeropasaporte' },{ text: 'NOMBRE', value: 'nombre' },{ text: 'PARENTESCO', value: 'parentesco' }


},



deleFact(item){


if(item.facturasventaPK==null){
  console.log("No hay Pk")

const index =  this.nuevoClientes.ListFact.indexOf(item)
confirm('Desea eliminar este Factura?') && this.nuevoClientes.ListFact.splice(index, 1)
//this.nuevoClientes.ListFact= this.RLisFac
  

}else{

 var jsn={
   datos:JSON.parse(JSON.stringify(item)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

this.$axios.get(this.$hostname + this.$hName + '/cliente/tienemovimiento/factura/'+item.facturasventaPK.secuencia+'/'+item.facturasventaPK.documento+'',
{headers:this.$store.getters.GetheadersAxios})
.then(res =>{ this.deleFact2(item,res.data) });



//this.disableDocu=true;
console.log("hay Pk")
}

},

deleFact2(item, bool){

//var RLisFac = JSON.parse(JSON.stringify(this.nuevoClientes.ListFact))

console.log(bool)


if(bool.boobean==true){
  console.log("Entro true")

this.alerta(bool.mensage, bool.estado);

}else{



console.log("Entro false")

var c =confirm('Desea eliminar este Factura?')

if(c!= false){
const index =  this.nuevoClientes.ListFact.indexOf(item)
this.nuevoClientes.ListFact.splice(index, 1)
this.nuevoClientes.ListFactDele.push(item);

//this.nuevoClientes.ListFact= JSON.parse(JSON.stringify(this.nuevoClientes.ListFact))
//setTimeout(this.nuevoClientes.ListFact= JSON.parse(JSON.stringify(this.nuevoClientes.ListFact))  ,900);
this.acti_Nueva_facturaInicial=true 
setTimeout(this.acti_Nueva_facturaInicial=false ,500);

}





}

},



editFact (item) {


if(item.facturasventaPK==null){
  console.log("No hay Pk")
  this.disableDocu=false;

}else{

this.disableDocu=true;
console.log("hay Pk")
}

if(item.hasOwnProperty('fecha')){
item.fecha=new Date(item.fecha).toISOString().substr(0,10)
}

//this.facturaInicial=Object.assign({}, item); 
//this.ObjTemp= Object.assign({}, item);   
this.facturaInicial= JSON.parse(JSON.stringify(item)) 
this.ObjTemp= JSON.parse(JSON.stringify(item)) 



const index =  this.nuevoClientes.ListFact.indexOf(item)
this.nuevoClientes.ListFact.splice(index, 1)


this.acti_Nueva_facturaInicial=true

        // this.editedIndex = this.desserts.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        // this.dialog = true
},



addPas(){

if (this.$refs.form2.validate()) {

this.RLisPas.push(this.pasaporte)
this.acti_Nueva_Pasaporte=false

this.nuevoClientes.ListPas=this.RLisPas

this.newPasaporte()

}else{

this.alerta("Los campos marcados en rojo son  obligatorios", "error");

}

//{ text: '#PASAPORTE', value: 'numeropasaporte' },{ text: 'NOMBRE', value: 'nombre' },{ text: 'PARENTESCO', value: 'parentesco' }


},


deleTel(item){

const index =  this.RLisTel.indexOf(item)
confirm('Desea eliminar este teléfono?') && this.RLisTel.splice(index, 1)


this.nuevoClientes.ListTel=this.RLisTel


},


editTel (item) {

//console.log(item)

this.telefonos=item.telefono


const index =  this.RLisTel.indexOf(item)
this.RLisTel.splice(index, 1)
this.nuevoClientes.ListTel=this.RLisTel
        // this.editedIndex = this.desserts.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        // this.dialog = true
  
  
  },


addTel(){

//console.log(this.telefonos.toString().length)

 if(this.telefonos.toString().length==13){


this.RLisTel.push({telefono:this.telefonos})

this.telefonos=""

this.nuevoClientes.ListTel=this.RLisTel

}else{

this.alerta("Teléfono invalido", "error");


}

//addTel,[{ telefono: '809-555-5555' ,eliminar:''}],ListTel

},


    cargarPaginacion(e) {
      //console.log(e);
      this.paginator = e;

      if (this.paginator.itemsPerPage == -1) {
        this.paginator.itemsPerPage = 15;
      }

      this.filtro();
    },

    fecFormatter(params0) {
      if (params0 != null) {
      var params= new Date(params0).toISOString().substr(0,10);

        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

if(number==null){number=0}

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

if(this.BarraTablaDinamicaAprir==true){   
      this.Limpiarfiltro()  
    }

      // if (this.abrirTablaDinamica == true) {
      //   this.Colss = { p1: { cols: 12, lg: 8, sm: 8 } };
      // }
      // if (this.abrirTablaDinamica == false) {
      //   this.Colss = { p1: { cols: 12, lg: 12, sm: 12 } };
      // }
    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    newwnuevo() {

this.BarraTablaDinamicaAprir=false

     this.nuevoClientes= {
       ListFactDele:[],
        ListFact:[],
        ListPas:[],
        ListTel:[],
        codigo: 0,
        nombre: null,
        cedrnc: "",
        direccion: null,
        ciudad: null,
        paraje: "",
        cercade: "",
        otraubicacion: "",
        dondetrabaja: "",
        quetrabaja:"",
        referenciacomercial: "",
        referenciacomercial2: "",
        referenciapersonal: "",
        referenciapersonal2: "",
        avisoalerta: "",
        comentario: "",
        email: "",
        fechanacimiento: null,
        fechaingreso: null,
        diascredito: 0,
        limiteCredito: 0,
        tipocliente: null,
        vendedores: null,
        zona: "",
        tipo: null,
        statuscliente: null,
        pais: null,
        tipocomprobante: null,
        activado: true,
        cheque: false,
        ordenCompraPv: false,
       // usuarios:this.$CodeUser
      },

this.RLisTel=[],
this.RLisPas=[],
this.RLisFac=[],

        (this.ejecucion = true);
      EventBus.$emit("actualizaBarraBusqueda2");
    },


     

    save() {

      if(this.RLisTel.length>0){
          this.nuevoClientes.telefonoPrincipal=this.RLisTel[0].telefono
      }else{ this.nuevoClientes.telefonoPrincipal=''}

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;

var obj={
  nuevoClientes: JSON.parse(JSON.stringify(this.nuevoClientes))   ,
  ListFactDele: JSON.parse(JSON.stringify(this.nuevoClientes.ListFactDele))   ,
  ListFact: JSON.parse(JSON.stringify(this.nuevoClientes.ListFact))  ,
  ListTel: JSON.parse(JSON.stringify(this.nuevoClientes.ListTel))  ,
  ListPas: JSON.parse(JSON.stringify(this.nuevoClientes.ListPas)) ,
  usuario:JSON.parse(JSON.stringify(this.$user)) 
}

if(this.nuevoClientes.statuscliente !=null){
this.nuevoClientes.statuscliente=JSON.parse(JSON.stringify(this.nuevoClientes.statuscliente.codigo))
}

if(this.nuevoClientes.tipo !=null){
obj.nuevoClientes.tipo=JSON.parse(JSON.stringify(this.nuevoClientes.tipo.descripcion))
}

this.nuevoClientes.telefonosClientesList=obj.ListTel;
this.nuevoClientes.facturasIniciales=obj.ListFact;
 
delete obj.nuevoClientes["ListFactDele"]
delete obj.nuevoClientes["ListFact"]
delete obj.nuevoClientes["ListTel"]
delete obj.nuevoClientes["ListPas"]

console.log('Datos Datosss')
console.log(obj)



          this.$axios
            .post(
              this.$hostname + this.$hName + "/clientes/save",
              this.nuevoClientes,{headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => {this.alerta("Dato guardado correctamente", "bien") }  )
            .catch(error => {this.alerta('Dato no guardado correctamente', "error")} );
        }
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },

    remove() {


 if (this.nuevoClientes.codigo != 0 && this.nuevoClientes.codigo != null) {
      this.Elim_notif.estado = !this.Elim_notif.estado;
 }else{this.alerta('Seleccione un cliente para  eliminar',"error")}


    },

    eliminar() {
      if (this.nuevoClientes.codigo != 0 && this.nuevoClientes.codigo != null) {
        if (this.$refs.form.validate()) {


//this.nuevoClientes.usuarios=this.$CodeUser
 var jsn={
   datos:JSON.parse(JSON.stringify(this.nuevoClientes)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

          this.$axios
            .post(
              this.$hostname + this.$hName + "/cliente/borrado",this.nuevoClientes,
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => this.alerta("Dato eliminado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      }
    },

anular(){


},
imprimirList(){


  if(this.nuevoClientes.codigo>0){
this.datosImprecion.secuencia=this.nuevoClientes.codigo
this.dialogimprimirList=true
}else{
this.alerta("Seleccione una reposición de caja chica para poder imprimir","error")
}
    


},

DDataReporteBasico(e){
this.dialogimprimirList=e
},




  datostabla(e) {
     
     this.acti_tb_data=false
this.MakCelRnc='#############'
if(!e.hasOwnProperty('cedrnc')){
e.cedrnc=""
}

this.RLisTel=[]
this.RLisPas=[]
this.RLisFac=[]

// console.log('Dataaaa dataaaa')
// console.log(e)

     this.nuevoClientes = e;

if(e.fechaingreso!=null){
this.nuevoClientes.fechaingreso=e.fechaingreso.split("T")[0]
}else{
this.nuevoClientes.fechaingreso=null
}


if(e.fechanacimiento!=null){
this.nuevoClientes.fechanacimiento=e.fechanacimiento.split("T")[0]  
}else{

this.nuevoClientes.fechanacimiento=null

}


if(this.nuevoClientes.tipo==null){
  this.nuevoClientes.tipo=null
}


if(this.nuevoClientes.vendedores==null){
this.nuevoClientes.vendedores=null

}



this.RStatus.forEach(element => {
  if(e.statuscliente==element.codigo){
this.nuevoClientes.statuscliente=element

  }
});

this.RTipo.forEach(element => {
  if(e.tipo==element.descripcion){
this.nuevoClientes.tipo=element

  }
}); 

this.nuevoClientes.ListFactDele=[]



 this.$axios.get(this.$hostname + this.$hName + '/clientes/find/'+e.codigo,{headers:this.$store.getters.GetheadersAxios} )
.then(res =>  {  
console.log('datos datos'),console.log(res.data.ListFact),
  this.tb_clienteTel(res.data.ListTel),
this.nuevoClientes.ListFact=JSON.parse(JSON.stringify(res.data.ListFact)), 
console.log('Datos Factura'),
console.log(res.data.ListFact),
//this.tb_clienteFac(res.data.ListFact),
  this.tb_clientePas(res.data.ListPas) 

});


if(e.hasOwnProperty('cedrnc')){


setTimeout(this.MakaraCel,900);

//this.MakaraCel() 
}





//nota: mequede revisando el problema tostrin pare ce en cedula

      // this.acti_tb_data=false;

      //console.log(e)
    },


tb_clientePas(e){

//console.log(e)


//{ text: '#PASAPORTE', value: 'numeropasaporte' },{ text: 'NOMBRE', value: 'nombre', width:200 },
//{ text: 'PARENTESCO', value: 'parentesco' },{ text: 'actions', value: 'action' }

e.forEach(element => {

if(element.fechaemision!=null){element.fechaemision=new Date(element.fechaemision).toISOString().substr(0, 10)}else{element.fechaemision=null}
if(element.fechavencimiento!=null){element.fechavencimiento=new Date(element.fechavencimiento).toISOString().substr(0, 10)}else{element.fechavencimiento=null}

if(element.hasOwnProperty('cliente')){

  if(element.cliente.fechaCreacion!=null & element.cliente.fechaCreacion!=""){
    element.cliente.fechaCreacion=new Date(element.cliente.fechaCreacion).toISOString().substr(0, 10)
  }

if(element.cliente.fechaModificacion!=null & element.cliente.fechaModificacion!=""){
    element.cliente.fechaModificacion=new Date(element.cliente.fechaModificacion).toISOString().substr(0, 10)
  }


if(element.cliente.hasOwnProperty('tipocliente')){

if(element.cliente.tipocliente.fechaCreacion!=null & element.cliente.tipocliente.fechaCreacion!=""){
    element.cliente.tipocliente.fechaCreacion=new Date(element.cliente.tipocliente.fechaCreacion).toISOString().substr(0, 10)
  }

  if(element.cliente.tipocliente.fechaModificacion!=null & element.cliente.tipocliente.fechaModificacion!=""){
    element.cliente.tipocliente.fechaModificacion=new Date(element.cliente.tipocliente.fechaModificacion).toISOString().substr(0, 10)
  }


}






}



this.RLisPas.push(element)
});

this.nuevoClientes.ListPas=this.RLisPas



//RLisPas
// ListFact:[],
//         ListPas:[],
//         ListTel:[],
//this.nuevoClientes.ListFact=this.RLisFac

},


tb_clienteFac(e){

var ListFact=[]

if(e!=null){

e.forEach(element => {

var factInic={
documentoFactura:JSON.parse(JSON.stringify(element.documentoFactura)),
facturasventaPK:JSON.parse(JSON.stringify(element.facturasventaPK)), 
ncf:JSON.parse(JSON.stringify(element.ncf)),
referencia:JSON.parse(JSON.stringify(element.referencia)),
fecha:JSON.parse(JSON.stringify( new Date(element.fecha).toISOString().substr(0,10) )),
diascredito:JSON.parse(JSON.stringify(element.diascredito)),
valorimpuesto:JSON.parse(JSON.stringify(element.valorimpuesto)),
moneda:JSON.parse(JSON.stringify(element.moneda)),
tasa:JSON.parse(JSON.stringify(element.tasa)),
pendiente:JSON.parse(JSON.stringify(element.pendiente)),
valor:JSON.parse(JSON.stringify(element.valor))
}  

ListFact.push(factInic);

                    });


}


this.nuevoClientes.ListFact=JSON.parse(JSON.stringify(ListFact))   

},

tb_clienteTel(e){

e.forEach(element => {
this.RLisTel.push(element.telefonosClientesPK)
});

this.nuevoClientes.ListTel=this.RLisTel

},


    async filtro() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/clientes/findtodo/"+(this.search===''?"A":+this.search)+"/3/"+this.paginator.itemsPerPage+"/"+this.paginator.pageStart,
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => {
          this.list = res.data;
        });
    },


actualiza_tb_ver() {

this.acti_tb_data=!this.acti_tb_data ;
this.Limpiarfiltro()
      
    },
     
Limpiarfiltro(){

  console.log('Entrando Al actualiza_tb_ver ')
console.log(this.acti_tb_data)

var Pg={
itemsLength: this.TbPaginator.itemsLength,
itemsPerPage: this.TbPaginator.itemsPerPage,
page: 1,
pageCount: 1,
pageStart: 0,
pageStop: this.TbPaginator.itemsPerPage,
}



this.Modallfiltro={
Clseguro:false,
activos:true,
cliente:null,
tipocliente:null,
}

this.ListItems=[]
this.TbTotalItem=0
this.TbPaginator= JSON.parse(JSON.stringify(Pg))
this.CargarListTb()
this.dialogFiltros= false

},

BuscarFiltros(){
var Pg={
itemsLength: this.TbPaginator.itemsLength,
itemsPerPage: this.TbPaginator.itemsPerPage,
page: 1,
pageCount: 1,
pageStart: 0,
pageStop: this.TbPaginator.itemsPerPage,
}

if ( this.Modallfiltro.cliente!=null && this.Modallfiltro.cliente.toString().length==0) {this.Modallfiltro.cliente=null }

this.TbPaginator= JSON.parse(JSON.stringify(Pg))
this.CargarListTb()
this.dialogFiltros= false

},


cargarPaginacionAll(e) {

if(e.itemsPerPage==-1){e.itemsPerPage=15}
this.CargarPaginacion(e,this.Modallfiltro.cliente) 
    },

CargarPaginacion(e,s){
 
this.Modallfiltro.cliente= JSON.parse(JSON.stringify(s))      
console.log(s);
console.log('Ver paginacion')
console.log(e)

if(e.itemsPerPage==-1){e.itemsPerPage=15}
//var pg =
this.TbPaginator= JSON.parse(JSON.stringify(e))
this.CargarListTb()

},

CargarListTb(){
var tipo=null
if(this.Modallfiltro.tipocliente!=null){ tipo =this.Modallfiltro.tipocliente.codigo }
var obj=this.TbPaginator.itemsPerPage+"/"+this.TbPaginator.pageStart+"/"+this.Modallfiltro.cliente+"/"+tipo+"/"+this.Modallfiltro.Clseguro+"/"+this.Modallfiltro.activos

this.$axios.get(this.$hostname+this.$hName+'/clientes/findListClientePlus/'+obj ,{headers:this.$store.getters.GetheadersAxios})
.then(res=>{ this.ListCliente=res.data.ListCliente, this.TbTotalItem=res.data.TbTotalItem })
},








  },

computed:{

    ActDescBtn(){

if(this.nuevoClientes.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }


  // var objjct = {
  //     save: true,
  //     eliminar: true,
  //     anular: false,
  //     nuevo: true,
  //     imprimir: true,
  //     vercuenta:false, 
  //   };

}


if(this.nuevoClientes.codigo<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }


// var objjct = {
//       save: true,
//       eliminar: false,
//       anular: false,
//       nuevo: true,
//       imprimir: true,
//       vercuenta:false, 
//     };


}
     
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }



};
</script>

<style scoped>

</style>
