<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 
    <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte Facturas con Boletos Generados'"
  :descripcion="'Imprime Facturas con Boletos Generados por rango de fecha'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
    
    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReporteFacturasBoletos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
<!-- Formulario-Facturas con Boletos Generados -->
 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Impresion de Facturas con Boletos Generados </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
   

  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="FacturasBoletos.desde"
        @blur="BlurDesde">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="FacturasBoletos.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>           
</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="FacturasBoletos"
/>

  </v-card>
 </v-container>
<!-- fn  Formulario-Facturas con Boletos Generados -->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
 
  
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() { 
    var json = { 
      titulo: "Reporte Depósito Facturas al Cobro",
      descripcion: "Imprime las Facturas Pendientes por Cobrar de Clientes.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false
     
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  //mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);

    // EventBus.$on("emitReporteFacturasBoletoss", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
  },
  // fn mounted-carga de inicio
  data:()=>  ({


      Rpropietario:[],
      RCaja:[],
      RtipoCliente:[],
      Rcliente:[],

      windowSize:{width:"auto", height:"auto"},
      FacturasBoletos: {
        nombre:'Facturas con Boletos Generados',
        UrlImprimir: '/reportes-pv/ImprimirFacturasBoletosGenerados', 
        UrlExel: '/reportes-pv/ExcelFacturasBoletosGenerados', 
        UrlCorreo: '/reportes-pv/CorreoFacturasBoletosGenerados', 
        desde: fechaNueva(),
        hasta: fechaNueva(),

        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
     

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
onResize(e){
//this.windowSize=e
},

// Validando el formulario y luego configurando el valor de la variable "detener" a verdadero o falso.
validarObjecto() {

if (!this.$refs.form.validate()) {
  this.$refs.ModRepFilt.detener = true;
  return;
} else {
  this.$refs.ModRepFilt.detener = false;
}

this.FacturasBoletos.where = "f.fecha >='" + this.FacturasBoletos.desde + "' and f.fecha<='" + this.FacturasBoletos.hasta + "' ";

},


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {},
    save() {},
    remove() {},
    eliminar() {},
    anular() { },
    imprimirList() {},
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(){},
    newwnuevo() {
      (this.FacturasBoletos = {
        desde:fechaNueva(),
        hasta:fechaNueva()
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


// Un método que se llama cuando la entrada pierde el foco y valida si
//el campo esta null y si lo esta coloca la fecha actual.
 BlurDesde(){

if(this.FacturasBoletos.desde.toString().length==0){
  this.FacturasBoletos.desde=this.fechaNueva()
}


 },

// Un método que se llama cuando la entrada pierde el foco y valida si
//el campo esta null y si lo esta coloca la fecha actual.
 BlurHasta(){

if(this.FacturasBoletos.hasta.toString().length==0){
  this.FacturasBoletos.hasta=this.fechaNueva()
}

 },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


  
 
  },

  computed:{

ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
//EventBus.$emit("ActDesaBotones", objjct);
}
}
};
</script>

<style></style>
