<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Notas de Crédito a los Proveedores'"
          :descripcion="'Registrar Notas de Crédito a los Proveedores.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>


      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"><i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>
                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>
                  </v-btn-toggle>

                </center>
                <!-- <v-navigation-drawer >  -->



                <BarraDinamicaLimitePlus :PropsNombre="'Notas de Crédito'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por codigo'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'notacreditoproveedoresPK.codigo' },
                    /* { text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'notacreditoproveedoresPK', Nv2: 'codigo', Ctsl: 2, Type: 0, State: true },
  { Text: '#Documento', NomValue: 'referencia', Ctsl: 1, Type: 0, State: true },
  { Text: 'proveedor', NomValue: 'proveedor', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
  { Text: 'fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
  { Text: 'Valor $', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
  { Text: 'NCF', NomValue: 'ncf', Ctsl: 1, Type: 0, State: true },
  { Text: 'Concepto', NomValue: 'concepto', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.2, State: true }
]" />

                <!-- <BarraDinamicaNormal
              :url="$hostname + $hName + '/notacreditoproveedor/findall'"
              :nombre="'Nota Credito'"
              :headers="[
              { text: 'Código', value: 'notacreditoproveedoresPK.codigo' },
              { text: 'Proveedor', value: 'proveedor.nombre' },
              { text: 'valor', value: 'valor' },
              { text: 'concepto', value: 'concepto' },
              { text: 'referencia', value: 'referencia' },
              { text: 'ncf', value: 'ncf' },    ]"
              :emitnombre="'emitNotaCredito'"
              :SeachNombre="'Búsqueda por nombre del proveedor'"
              :ContListSlot="[{Text:'Código',NomValue:'notacreditoproveedoresPK', Nv2:'codigo', Ctsl:2, Type:0, State:true},
              {Text:'Nombre',NomValue:'proveedor', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Valor $', NomValue:'valor',  Ctsl:1, Type:2, State:true},
              {Text:'Referencia', NomValue:'referencia',  Ctsl:1, Type:0, State:true},
              {Text:'Concepto', NomValue:'concepto',  Ctsl:1, Type:0, State:true},
              {Text:'NCF', NomValue:'ncf',  Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activada', Ctsl:1, Type:0.1, State:true}]"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="form.NotaCredito.notacreditoproveedoresPK.codigo"
                      style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="3" sm="3">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="* Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[rules.required]"
                          v-model="form.NotaCredito.documentoproveedor" @blur="SelctCbDocumento"
                          @focus="cargarDocumento" v-on:blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>

                        <!----------------------------------------------  Fecha  -->
                        <v-text-field dense outlined type="date" label="Fecha " :rules="[rules.required]"
                          v-model="form.NotaCredito.fecha">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <!------------------------------------------------ ITBIS incluido  :rules="[rules.porciento]"-->

                        <AutoNumeric label="ITBIS(informativo):" dense outlined autocomplete="off"
                          v-model.number="form.NotaCredito.itbis" >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>


                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="3" sm="3">

                        <!-------------------------------------------------- proveedor -->

                        <v-combobox autocomplete="off" dense outlined label="* proveedor:" :items="Rproveedor"
                          item-text="nombre" :rules="[$rules.required]" v-model="form.NotaCredito.proveedor"
                          @blur="SelctCbProveedor" @focus="cargarProveedor" @keyup="CbFilProveedor"
                          @change="changeProveedor"
                          v-on:blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>

                        <!-- no documento  --------------------------------------------------->
                        <v-text-field label="No. de Documento :" dense outlined v-model="form.NotaCredito.referencia">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                        <!-------------------------------------------------- Valor -->
                        <AutoNumeric label="* Valor:" dense outlined autocomplete="off"
                          v-model.number="form.NotaCredito.valor" @blur="getCrearCont" :rules="[rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </AutoNumeric>


                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="3" sm="3">


                        <!-- Tipo de gasto --------------------------------------------------->
                        <v-combobox v-model="form.NotaCredito.gasto" label="Tipo de Gasto:" required outlined dense
                          autocomplete="off" :items="RTipoGasto" item-text="descripcion" @focus="CargarRTipopago"
                          @blur="SelctCbTipoGasto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-tag"></i>
                          </template>
                        </v-combobox>
                        <!--  NCF --------------------------------------------------->
                        <v-text-field label="NCF:" dense outlined v-model="form.NotaCredito.ncf">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-barcode"></i>
                          </template>
                        </v-text-field>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

<v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
:rules="[$rules.required]" v-model="form.NotaCredito.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
@change="ChangeMonedaTasa">

<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-money"></i>
</template>

<template v-slot:selection="{ attrs, item, parent, selected }">
<span id="ImputColorDisable">
{{ item.descripcion }} ({{ item.nombrecorto }})
</span>

</template>


<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
</p>
</v-col>
</template>
</v-combobox>
                        </div>



                      </v-col>
                      <!-- columna 3 ------------------------------>

                      <!-- columna 4 ------------------------------>
                      <v-col cols="12" md="3" sm="3">
                        <!-------------------------------------------------- Comentario -->
                        <v-textarea autocomplete="off" rows="4" label="* Concepto:" dense outlined
                          v-model="form.NotaCredito.concepto" :rules="[$rules.required]" v-on:blur="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">
<AutoNumeric v-if="form.NotaCredito.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
v-model.number="form.NotaCredito.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
</AutoNumeric>
</div>

                      </v-col>
                      <!-- columna 4 ------------------------------>

                    </v-row>
                  </v-form>

                </div>
              </v-card>


            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/notacreditoproveedor/findAll'"
              :nombre="'Nota credito'"
              :headers="headers"
              :slotname="'item.proveedor.nombre'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por codigo"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'notacreditoproveedoresPK.codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Proveedor', value: 'proveedor.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'NCF', value: 'ncf', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Referencia', value: 'referencia', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Comentario', value: 'concepto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado', value: 'activada', sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
              :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.factura"
              @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.valor="props">
                <span>{{
                    currencyFormatter(props.item.valor)
                }}</span>
              </template>
              <template v-slot:item.fecha="props">
                <span>
                  {{ FormatoFecha(props.item.fecha) }}
                </span>
              </template>
              <template v-slot:item.activada="props">
                <span>{{
                    (props.item.activada === true ? "Activo" : "Inactivo")
                }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'AnularNC'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->


    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>
          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <br>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="Proveedor:" style="font-size: 13px;"
                :items="Rproveedor" item-text="nombre" v-model="Modallfiltro.proveedores"
                @blur="SelctCbProveedoresFiltro" @focus="cargarProveedor" @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>

              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>

            </v-col>


            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>
            </v-col>
            <!-- ----------------------------- fn columna-1 -->
            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">
              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>


            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal filtro -->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO REGISTRO NOTAS DE CREDITO A
              PROVEEDORES</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">


              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documentos"
                  @blur="SelctCbReportDocumento" @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>
              </v-col>


              <v-col cols="12" md="6" sm="6">
                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>

              <v-col cols="12" md="6" sm="6">


                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->



    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>

import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import CuentasContables from "../../components/CuentasContables";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    ContenidoBase,
    BarraDinamicaLimitePlus,
    CuentasContables,
    ModalReporteFiltros,
    ModalReporteBasico,


  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitNotaCredito", this.datostabla);
    // EventBus.$on("cxpNotaCreditoNew", this.new);
    // EventBus.$on("cxpNotaCreditoSave", this.save);
    // EventBus.$on("cxpNotaCreditoRemove", this.remove);
    // EventBus.$on("cxpNotaCreditoAnular", this.anular);
    // EventBus.$on("cxpNotaCreditoPrintList", this.imprimirList);
    // EventBus.$on("cxpNotaCreditoVercuenta", this.vercuenta);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.getMonedaBase()
  },

  created() {
    var json = {
      titulo: "Notas de Crédito a los Proveedores",
      descripcion:
        "Registrar Notas de Crédito a los Proveedores.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,

      accion: [
        "cxpNotaCreditoNew",
        "cxpNotaCreditoSave",
        "cxpNotaCreditoRemove",
        "cxpNotaCreditoAnular",
        "cxpNotaCreditoPrintList",
        "cxpNotaCreditoVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,


      dialogOrdenSugerida: false,
      acti_Modal_cuenta: false,


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'NOTAS DE CREDITO A LOS PROVEEDORES',
        UrlImprimir: '/notacreditoproveedor/imprimir',
        UrlExel: '/notacreditoproveedor/excel',
        UrlCorreo: '/notacreditoproveedor/correo',
      },
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO REGISTRO NOTAS DE CREDITO A PROVEEDORES',
        UrlImprimir: '/notacreditoproveedor/ImprimirList',
        UrlExel: '/notacreditoproveedor/ExcelList',
        UrlCorreo: '/notacreditoproveedor/CorreoList',
        desde: fechaNueva(),
        hasta: fechaNueva(),
        documentos: null
      },



      Rmoneda: [],
      Rproveedor: [],
      RallProducto: [],
      Rdepartamento: [],
      Ralmacen: [],
      RDocumento: [],
      RTipoGasto: [],
      RLisCuenta: [],


      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      search: '',
      objeTem: [],
      TotalCuenta: {
          credito: 0,
          debito: 0
        },
      contabilidad: null,


      dialogFiltros: false,
      Modallfiltro: {
        proveedores: null,
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },



      form: {
        NotaCredito: {
          notacreditoproveedoresPK: { codigo: 0, documento: 0 },
          fecha: fechaNueva(),
          moneda: null,
          proveedor: null,
          direccionEnvio: '',
          concepto: '',
          descuentoIndividual: 0,
          tasa: 0,
          documentoproveedor: null,
          ncf: '',
          valor: 0,
          itbis: 0,
          contabilidad: null,
          referencia: "",
          gasto: null
        },

        RLisCuenta: [],
        diasCredito: 0,

        TotalCuenta: {
          credito: 0,
          debito: 0

        },

        Total: {
          valor: 0,
          pendiente: 0,
          ncr: 0,
          itbis: 0,
          balanceTotal: 0,
        }

      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: true,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },

      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Fecha", value: "fecha" }, { text: "Proveedor", value: "proveedor.nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],



      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    onResize(e) {
      // this.windowSize=e
    },

    vercuenta() {
      if (!this.$refs.form.validate()) {
        this.alerta("Los campos en rojo son requeridos", "error");
        return
      }

      if (this.$refs.form.validate()) {
        //  this.getCrearCont()
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))
        this.acti_Modal_cuenta = !this.acti_Modal_cuenta
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },

    CargarRTipopago() {
      this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findallactivo', { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.RTipoGasto = res.data;
      });
    },
    llenarcuenta(e) {
      console.log(e)
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.form.NotaCredito.fecha;
        this.contabilidad.documento = this.form.NotaCredito.documentoproveedor.codigo;
        // this.contabilidad
      }
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },

    calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0

      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {

          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
        });
      }

    },


    SelctCbTipoGasto() {


      if (this.form.NotaCredito.gasto == null) {
        this.form.NotaCredito.gasto = "";
      }

      if (this.form.NotaCredito.gasto.codigo == undefined) {
        this.alerta("Seleccione un tipo gasto", "error");
        this.form.NotaCredito.gasto = "";
        return;
      }

    },





    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentoscxp/findAllDocumentosNotaCredito', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    getCrearCont() {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.$refs.form.validate()) {
        if (this.form.NotaCredito.notacreditoproveedoresPK.codigo == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-cxp/' + this.form.NotaCredito.documentoproveedor.codigo + '/' + this.form.NotaCredito.valor + '/' + this.form.NotaCredito.itbis + '/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        if (this.form.NotaCredito.notacreditoproveedoresPK.codigo != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.form.NotaCredito.notacreditoproveedoresPK.documento + '/' + this.form.NotaCredito.notacreditoproveedoresPK.codigo + '/' + this.form.NotaCredito.documentoproveedor.operacion.codigo,
          { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data) });
        }
      }
    },


    CCuentasContables(e, t, b) {



      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
          // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          //this.RLisCuenta= this.RLisCuenta
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }



      // this.form.RLisCuenta= JSON.parse(JSON.stringify(e));
      // this.form.TotalCuenta=JSON.parse(JSON.stringify(t)) 

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))
      this.acti_Modal_cuenta = b

    },





    SelctCbDocumento() {
      if (this.form.NotaCredito.documentoproveedor == null) {

        this.form.NotaCredito.documentoproveedor = ''
      }

      if (this.form.NotaCredito.documentoproveedor.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.form.NotaCredito.documentoproveedor = ''
        return

      }

    },
    keyupDiaOrden() {

      if (e.key) {
        var mynumeral = require('numeral')
        this.form.OrdenSugerida.dia = mynumeral(this.form.OrdenSugerida.dia).value()
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.form.OrdenSugerida.dia == null) {

          this.form.OrdenSugerida.dia = 0
        }

      }
      else {
        console.log("no se puede poner letras o signos")
      }

    },


    addClikProd() {




    },


    newCrearProducto() {



    },





    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))
      if (number == null) { number = 0 }
      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },


    FormatoFecha(params0) {
      var params = new Date(params0).toISOString().substr(0, 10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.form.NotaCredito.contabilidad = this.contabilidad;
      if(this.RLisCuenta.length>0){
        this.contabilidad.contabilidadDetalleList=this.RLisCuenta
        this.form.NotaCredito.contabilidad=this.contabilidad
        }
          this.ejecucion = false;
          EventBus.$emit("loading", true);

          this.$axios
            .post(
              this.$hostname + this.$hName + "/notacreditoproveedor/salvar", this.form.NotaCredito,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },


    NotifMessage() {

      if (this.form.NotaCredito.notacreditoproveedoresPK.codigo != 0 && this.form.NotaCredito.notacreditoproveedoresPK.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularNC"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una nota de credito para anular", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {
      
    },

    anular() {
      if (this.form.NotaCredito.notacreditoproveedoresPK.codigo != null && this.form.NotaCredito.notacreditoproveedoresPK.codigo  != 0) {
        if (this.$refs.form.validate()) {
          EventBus.$emit("loading", true);
          var linkverificar=`/${this.form.NotaCredito.notacreditoproveedoresPK.codigo}/${this.form.NotaCredito.notacreditoproveedoresPK.documento}/2`
        this.$axios.get(
          this.$hostname + this.$hName +"/pagoproveedores/VerificarMovimientoPago"+linkverificar,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta Nota de credito posee pago", "error")
          }else{ 
            this.VerificarMovimNotDebito(linkverificar)
          }

        })
        .catch((error) => this.alerta(error, "error"));
        }
      }

      

    },

    VerificarMovimNotDebito(Links){
      
      this.$axios.get(
          this.$hostname + this.$hName +"/notadebitoproveedores/VerificarMovimientoNotaDebito"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta factura posee Nota de debito", "error")
          }else{ 
            this.VerificarMovimCheque(Links)
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },


    VerificarMovimCheque(Links){
      this.$axios.get(
          this.$hostname + this.$hName +"/cheques/VerificarMovimientoCheque"+Links,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data==true){
            this.alerta("Dato no anulado correctamente, esta Nota de credito posee Cheque", "error")
          }else{ 
            this.contAnular()
          }
          
        })
        .catch((error) => this.alerta(error, "error"));

    },




contAnular(){
  this.$axios
            .post(
              this.$hostname + this.$hName + "/notacreditoproveedor/anular", this.form.NotaCredito,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato Anulado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
},


    imprimirList() {
      if (this.form.NotaCredito.notacreditoproveedoresPK.codigo > 0) {


        this.datosImprecion.codigo = this.form.NotaCredito.notacreditoproveedoresPK.codigo
        this.datosImprecion.documento = this.form.NotaCredito.notacreditoproveedoresPK.documento

        //    comprobante_de_nota_credito_cxp

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },


    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "notacreditoproveedores.proveedor = proveedores.codigo and notacreditoproveedores.documento = documentoproveedor.codigo AND notacreditoproveedores.gasto = categoriagastos.codigo";
      where += ` and notacreditoproveedores.fecha>= '${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and notacreditoproveedores.fecha <= '${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)}' `

      this.datosImprecionAll.documento = ""


      if (this.datosImprecionAll.documentos != null) {
        where += ` and notacreditoproveedores.documento= ${this.datosImprecionAll.documentos.codigo}`;
        this.datosImprecionAll.documento = this.datosImprecionAll.documentos.descripcion

      }

      this.datosImprecionAll.where = where

      //   ic.imprimir("listado_nota_credito", parametros, "cxp");
    },


    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.objeTem = [],
        this.TotalCuenta = null,
        this.contabilidad = null,
        this.RLisCuenta = [],

        this.form = {
          NotaCredito: {
            notacreditoproveedoresPK: { codigo: 0, documento: 0 },
            fecha: fechaNueva(),
            moneda: null,
            proveedor: null,
            direccionEnvio: '',
            concepto: '',
            descuentoIndividual: 0,
            tasa: 0,
            documentoproveedor: null,
            ncf: '',
            valor: 0,
            itbis: 0,
            contabilidad: null,
            referencia: "",
            gasto: null
          },

          RLisCuenta: [],
          diasCredito: 0,

          TotalCuenta: {
            credito: 0,
            debito: 0

          },

          Total: {
            valor: 0,
            pendiente: 0,
            ncr: 0,
            itbis: 0,
            balanceTotal: 0,
          }

        },











        this.ejecucion = true;
        EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
      this.getMonedaBase()
    },

    datostabla(e) {
      this.form.NotaCredito = JSON.parse(JSON.stringify(e))
      this.form.NotaCredito.fecha = new Date(e.fecha).toISOString().substr(0, 10)
      this.acti_tb_data = false;
      this.contabilidad = null
      this.RLisCuenta = []
      var _this = this;
     setTimeout(function () { _this.getCrearCont() }, 300);
      //console.log(e);
    },


    cargarProveedor() {

      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))

    },


    SelctCbProveedorOrden() {

      if (this.form.OrdenSugerida.proveedor == null) {

        this.form.OrdenSugerida.proveedor = ''
      }

      if (this.form.OrdenSugerida.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.form.OrdenSugerida.proveedor = ''

        //qlq2

        return

      }



    },

    changeProveedor(e){
      console.log(e)
      if(e.gasto!=null){
          this.form.NotaCredito.gasto=JSON.parse(JSON.stringify(e.gasto))
      }else{
        this.form.NotaCredito.gasto=null
      }
     
    },

    SelctCbProveedor() {
      if (this.form.NotaCredito.proveedor == null) {

        this.form.NotaCredito.proveedor = ''
      }

      if (this.form.NotaCredito.proveedor.codigo == undefined) {

        this.alerta("Seleccione un proveedor", "error");
        this.form.NotaCredito.proveedor = ''

        //qlq2

        return

      }

    },

    CbFilProveedor(e) {

      if (e.target.value.length >= 0) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === "" ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }

    },






    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.form.NotaCredito.moneda= MONEDA
  this.form.NotaCredito.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.form.NotaCredito.moneda == null) {
          _this.form.NotaCredito.moneda = "";
        }

        if (!_this.form.NotaCredito.moneda.hasOwnProperty('codigo')) {
          _this.form.NotaCredito.moneda = "";
          _this.form.NotaCredito.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.form.NotaCredito.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},




    SelctCbProveedoresFiltro() {
      if (this.Modallfiltro.proveedores == null) {
        this.Modallfiltro.proveedores = ''
      }
      if (this.Modallfiltro.proveedores.codigo == undefined) {
        this.alerta("Seleccione un proveedores", "error");
        this.Modallfiltro.proveedores = ''
        return
      }
    },


    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {
        this.Modallfiltro.documento = ''
      }
      if (this.Modallfiltro.documento.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return
      }
    },



    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }
    },
    ReportBlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }
    },

    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documentos == null) {
        this.datosImprecionAll.documentos = "";
      }
      if (this.datosImprecionAll.documentos.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documentos = "";
        return;
      }
    },




    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        proveedores: null,
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      var prove = null
      var doc = null
      if (this.Modallfiltro.proveedores != null) { prove = this.Modallfiltro.proveedores.codigo }
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + prove + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/notacreditoproveedor/findListNotaCreditoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {

          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })
    },


    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/notacreditoproveedor/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },

    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    }
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {

    ActDescBtn() {

      if (this.form.NotaCredito.notacreditoproveedoresPK.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        if(this.form.NotaCredito.activada==false){
          this.EstadoBtn.anular=false
        }


        

      }


      if (this.form.NotaCredito.notacreditoproveedoresPK.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }

  }










};
</script>

<style>

</style>
