<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Formulario de Habilitación Transitoria'"
  :descripcion="'Formulario de Habilitación Transitoria para establecimietos farmaceuticos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
</tr>
   
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
               
              
            </center>
            <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitSaludformularioHab'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>

                <v-col cols="12" md="12" sm="12">
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Datos generales del establecimiento</b></center>  
<v-row>
  <!-- Dge Col 1 -->
    <v-col cols="12" md="6" sm="6">
      <!------------------------------------------- nombre -->
                <v-text-field
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Nombre de solicitante:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                  <!------------------------------------------- nombre -->
                  <v-text-field
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Razon social:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-registered"></i>
                    </template>
                  </v-text-field>

                      <!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                    label="Direccion:"
                    rows="2"
                    dense
                    outlined
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>


<!-- ----------------------------- sector -->  
                  <v-text-field label="sector:" dense outlined
                  autocomplete="off"
                  rows="2"
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    >
                  <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i> 
                  </template>
                    </v-text-field>


<!-- ----------------------------- Municipio -->
                  <v-text-field label="Municipio:" dense outlined
                  autocomplete="off"
                  rows="2"
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    >
                  <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-o"></i> 
                  </template>
                    </v-text-field> 




    </v-col>
<!-- fn Dge Col 1 -->

<!-- Dge Col 2 -->
    <v-col cols="12" md="6" sm="6">

      <!-- ----------------------------- Municipio -->
                  
                  <v-text-field label="Provincia:" dense outlined
                  autocomplete="off"
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    >
                  <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-o"></i> 
                  </template>
                    </v-text-field> 

                    <!-- ----------------------------- teléfono -->
                    <v-text-field
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="formularioHab.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template> 
          </v-text-field>

           <!-------------------------------  email -->
             <v-text-field label="Fax:"
                     dense outlined
                     v-model="formularioHab.descripcion"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-fax"></i> 
                      </template>
                    </v-text-field>

                     <!-- -----------------------------  email -->
             <v-text-field label="Correo electrónico:"
                     dense outlined
                     v-model="formularioHab.descripcion"
                      
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field>
    </v-col>
<!-- fn Dge col 2 -->
</v-row>
  </v-container>
</v-card>   
<hr>
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Categorías de Drogas Controladas que Solicita</b></center>  
<v-row>
    <v-col cols="12" md="6" sm="6">

          <v-switch v-model="formularioHab.activo"
           label="Farmacia"
            color="success"
            hide-details
            ></v-switch>

    
            <v-switch v-model="formularioHab.activo"
           label="Privada"
            color="success"
            hide-details
            ></v-switch>

            
    </v-col>

 <v-col cols="12" md="6" sm="6">

   <v-switch v-model="formularioHab.activo"
           label="Distribuidora farmacéutica"
            color="success"
            hide-details
            ></v-switch>

            <v-switch v-model="formularioHab.activo"
           label="Pública"
            color="success"
            hide-details
            ></v-switch>

 </v-col>



</v-row>
  </v-container>
</v-card>
<hr>
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Datos de Director Técnico / Regente Farmacéutico</b></center>  
<v-row>
  <!-- Cgcs Col 1 -->
    <v-col cols="12" md="6" sm="6">
      <!------------------------------------------- nombre -->
                <v-text-field
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Nombre:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

                  <!-- ----------------------------------------- cedula  -->
<v-text-field
            v-mask="'###-#######-#'"
            label="Cédula:"
            autocomplete="off"
            dense
            outlined
            maxlength="13"
            :rules="[$rules.required, $rules.RulesCel]"
            v-model="formularioHab.descripcion"
          >
          
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
              </template>
       
          </v-text-field>

<!------------------------------------------- Título : -->
                <v-text-field
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Título:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-tumblr"></i>
                    </template>
                  </v-text-field>

                  <!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                  rows="2"
                    label="Direccion:"
                    dense
                    outlined
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea>  
    </v-col>
    <!-- Cgcs Col 1 -->

     <!-- Cgcs Col 2 -->
    <v-col cols="12" md="6" sm="6">


      <!-- ----------------------------- teléfono -->
                    <v-text-field
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="formularioHab.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template> 
          </v-text-field>

          <!-- ----------------------------- teléfono -->
                    <v-text-field
            label="Celular:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="formularioHab.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-mobile"></i>
            </template> 
          </v-text-field>

          <!-- ------------------------------- exequatur -->
                    <v-text-field
                      autocomplete="off"
                      label="No. exequátur"
                      v-model="formularioHab.descripcion"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="id-badge"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>

                    <!-- -----------------------------  email -->
                    <v-text-field label="Correo electrónico:"
                   
                     dense outlined
                     v-model="formularioHab.descripcion"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field> 
  
    </v-col>
    <!-- Cgcs Col 2 -->
</v-row>
  </v-container>
</v-card>
<hr>
<v-card color="grey lighten-3">
  <v-container>
    <center><b>Datos del propietario</b></center>  
<v-row>
    <v-col cols="12" md="6" sm="6">
        <!------------------------------------------- nombre -->
                <v-text-field
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label=" Nombre:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>


                  <!-- ----------------------------- direccion -->
                  <v-textarea
                  autocomplete="off"
                  rows="2"
                    label="Direccion:"
                    dense
                    outlined
                    v-model="formularioHab.descripcion"
                    :rules="[$rules.required]"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                    </template>
                  </v-textarea> 

                  <!------------------------------------------- cedula  -->
<v-text-field
            v-mask="'###-#######-#'"
            label="Cédula:"
            autocomplete="off"
            dense
            outlined
            maxlength="13"
            :rules="[$rules.required, $rules.RulesCel]"
            v-model="formularioHab.descripcion"
          >
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
              </template>
       
          </v-text-field> 

    </v-col>

    <v-col cols="12" md="6" sm="6">

<!-- ----------------------------- teléfono -->
                    <v-text-field
            label="teléfono:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="formularioHab.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-phone"></i>
            </template> 
          </v-text-field>

          <!-- ----------------------------- teléfono -->
                    <v-text-field
            label="Celular:"
            dense
            outlined
            autocomplete="off"
           
            maxlength="13"
            v-mask="'(###)###-####'"
            v-model="formularioHab.descripcion"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-mobile"></i>
            </template> 
          </v-text-field>

          <!-- -----------------------------  email -->
                    <v-text-field label="Correo electrónico:"
                   
                     dense outlined
                     v-model="formularioHab.descripcion"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i> 
                      </template>
                    </v-text-field> 
    </v-col>


</v-row>
  </v-container>
</v-card>
                </v-col>
              </v-row>
            </v-form>

       </v-container>


        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->

    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

<v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>



          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarFormulario'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Formulario de Habilitación Transitoria",
      descripcion: "para establecimietos farmaceuticos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "SaludformularioHabNew",
        "SaludformularioHabSave",
        "SaludformularioHabRemove",
        "SaludformularioHabAnular",
        "SaludformularioHabPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("SaludformularioHabNew", this.new);
    // EventBus.$on("SaludformularioHabSave", this.save);
    // EventBus.$on("SaludformularioHabRemove", this.remove);
    // EventBus.$on("SaludformularioHabAnular", this.remove);
    // EventBus.$on("SaludformularioHabPrintList", this.imprimirList);
    EventBus.$on("emitSaludformularioHab", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      formularioHab: {
        activo: true,
        codigo: 0,
        descripcion: ""
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,



notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.formularioHab = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.formularioHab)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },




NotifMessage() {

if(this.formularioHab.codigo!=0 && this.formularioHab.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarFormulario"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un formulario para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },



    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.formularioHab)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.formularioHab.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      // if (this.Elim_notif.estado) {}

var jsn={
   datos:JSON.parse(JSON.stringify(this.formularioHab)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      
    },
    imprimirList() {},
    newwnuevo() {
      (this.formularioHab = {
        activo: true,
        codigo: 0,
        descripcion: "",
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },


computed:{

    ActDescBtn(){      
if(this.formularioHab.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.formularioHab.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

    }
    }


};
</script>

<style></style>
