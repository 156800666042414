var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dialogClienteBalancePunto==true)?_c('div',{directives:[{name:"dirkeydown",rawName:"v-dirkeydown",value:(_vm.ConsultaBalanceKeyESC),expression:"ConsultaBalanceKeyESC"}],staticClass:"overflow-x-auto",staticStyle:{"min-height":"500px"}},[_c('v-col',{staticClass:"pa-0 ma-0 py-0 my-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_vm._v(_vm._s(_vm.GetEjecucion)+" "),_c('v-toolbar',{attrs:{"dark":"","color":"#dd4b39","dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-toolbar-title',_vm._g({},on),[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"}}),_vm._v(" CONSULTA BALANCES PUNTOS ")])]}}],null,false,4069563228)},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v("CONSULTA BALANCES PUNTOS: ")]),_vm._v(" CONSULTA BALANCES PUNTOS")])]),_c('v-spacer'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","color":"grey","small":"","dark":""},on:{"click":function($event){return _vm.NewClienteBalancePunto()}}},on),[_c('v-avatar',{attrs:{"tile":"","size":"28","color":"transparent"}},[_c('img',{attrs:{"src":require("@/assets/icons_pv/extras/limpiarPantalla.png"),"alt":"Limpiar"}})]),_c('i',{staticStyle:{"font-size":"20px"}}),_vm._v(" NUEVO ")],1)]}}],null,false,3629660189)},[_c('span',[_vm._v("NUEVO: Limpia los campo para un nuevo registro")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","color":"orange","small":"","dark":""},on:{"click":function($event){return _vm.CancelarClienteBalancePunto()}}},on),[_c('i',{staticClass:"fa fa-reply-all",staticStyle:{"font-size":"20px"}}),_vm._v(" CANCELAR ")])]}}],null,false,3206847528)},[_c('span',[_vm._v("CANCELAR: Cancela y retornar atrás")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{attrs:{"color":"grey lighten-3"}},[_c('br'),_c('v-form',{ref:"formReimprecion",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{class:"d-flex justify-center"},[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({ref:"RefTextFieldCodigoBarra",attrs:{"background-color":"white","color":"grey darken-4","autocomplete":"off","label":"Buscar Código barra cliente Preferencial","single-line":"","outlined":"","dense":""},on:{"keyup":_vm.SeachFilCodigoBarra},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('i',{staticClass:"fa fa-search",staticStyle:{"font-size":"25px"}})]},proxy:true}],null,true),model:{value:(_vm.searchCodigoBarra),callback:function ($$v) {_vm.searchCodigoBarra=$$v},expression:"searchCodigoBarra"}},on))]}}],null,false,198248556)},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Aquí se busca el codigo de barra del cliente preferencial.")])])])],1)],1)],1)],1),_c('v-col',{staticClass:" pa-0 ma-0 py-0 my-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('center',[_c('br'),_c('div',{class:"black--text"},[((""+_vm.CLienteBalancePunto.codigoClinete).length>0)?_c('h1',[_vm._v("Código: "+_vm._s(_vm.CLienteBalancePunto.codigoClinete))]):_vm._e(),_c('h1',[_c('b',{staticClass:"blue--text"},[_vm._v(_vm._s(_vm.CLienteBalancePunto.cliente)+" ")])]),_c('h1',[_vm._v(_vm._s(_vm.CLienteBalancePunto.balancePunto))])])])],1)],1),_c('v-snackbar',{staticStyle:{"margin-top":"20%"},attrs:{"color":_vm.aalert.color,"vertical":true,"timeout":_vm.aalert.timeout,"right":_vm.aalert.right,"top":_vm.aalert.top},model:{value:(_vm.aalert.estado),callback:function ($$v) {_vm.$set(_vm.aalert, "estado", $$v)},expression:"aalert.estado"}},[_vm._v(" "+_vm._s(_vm.aalert.nombre)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.aalert.estado = false}}},[_vm._v("Close")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }