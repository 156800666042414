<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
  
  <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Impuestos'"
  :descripcion="'Crear, Modificar y Desactivar Impuestos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraDinamicaNormal
              :url="
                $hostname + $hName + '/impuestos/findall'
              "
              :nombre="'Impuestos'"
              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripción', value: 'descripcion' }
        ]"
              :slotname="'item.descripcion'"
              :emitnombre="'emitMOFTiposImpuestos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'desactivado', Ctsl:1, Type:0.3, State:true},
              ]"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2">

  
  <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
               <!-- columna 1------------------------------------->
                <v-col cols="12" md="12" sm="12">
                  <!-- ----------------------------------------- Nombre -->
                  <v-text-field
                    v-model="Impuestos.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="* Nombre:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                </v-col>
                <!-- fn columna 1 -------------------------------->
              
            <!-- columna 2 --------------------------------------->
                <v-col cols="12" md="12" sm="12">

  <!-- -----------------------------------------taza -->
 <VueAutonumeric label="* tasa:" dense outlined
 autocomplete="off"
  v-model.number="Impuestos.tasa"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>



                  <!-- <v-switch
                    label="Activo"
                    v-model="Impuestos.activo"
                  ></v-switch> -->
                </v-col>
            <!-- fn columna 2  ----------------------------------->

              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

<v-tooltip top>
<template v-slot:activator="{ on }"> 
 
 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>

          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Descripcion', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] }
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
             
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    
<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarTipoImpuesto'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
 

<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico 
  },
  created() {
    var json = {
      titulo: "Impuestos",
      descripcion: "Crear, Modificar y Desactivar Impuestos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "MOFTiposImpuestosNew",
        "MOFTiposImpuestosSave",
        "MOFTiposImpuestosRemove",
        "MOFTiposImpuestosAnular",
        "MOFTiposImpuestosPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("MOFTiposImpuestosNew", this.new);
    // EventBus.$on("MOFTiposImpuestosSave", this.save);
    // EventBus.$on("MOFTiposImpuestosRemove", this.remove);
    // EventBus.$on("MOFTiposImpuestosAnular", this.remove);
    // EventBus.$on("MOFTiposImpuestosPrintList", this.imprimirList);
    EventBus.$on("emitMOFTiposImpuestos", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      Impuestos: {
        desactivado: false,
        borrado: false,
        tasa:0,
        codigo: 0,
        descripcion: ""
      },


dialogimprimirList:false,
datosImprecion:{
nombre:'IMPUESTOS',
 UrlImprimir: '/impuestos/ImprimirListImpuestos', 
 UrlExel: '/impuestos/ExcelListImpuestos', 
 UrlCorreo: '/impuestos/CorreoListImpuestos', 
 secuencia: 0, 
 documento: 0 
},



EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",
      acti_tb_data: false,


 notif: {
        estado: false,
        nombre: "",
        Exec:""
      },
      

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/impuestos/findall",
         {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.list = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.Impuestos = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.Impuestos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/impuestos/save",this.Impuestos,
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },




NotifMessage() {

if(this.Impuestos.codigo!=0 && this.Impuestos.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este Registro?",
        Exec:"eliminarTipoImpuesto"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un tipo de impuesto para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.Impuestos)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.Impuestos.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/impuestos/borrar",this.Impuestos,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => {
            if(res.data){
              this.alerta("Dato eliminado correctamente!", "bien")  
            }
            })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/impuesto/borrar",this.Impuestos,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
   imprimirList() {
    this.dialogimprimirList=true
    },

DDataReporteBasico(e){
this.dialogimprimirList=e
},

    newwnuevo() {
      (this.Impuestos = {
        desactivado: false,
        borrado: false,
        tasa:0,
        codigo: 0,
        descripcion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/impuesto/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    }
  },

computed:{

    ActDescBtn(){      
if(this.Impuestos.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.Impuestos.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}

    }
    }







};
</script>

<style></style>
