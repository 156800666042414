<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <tr max-height="48px" height="48px">

        <ContenidoBase :titulo="'Configuracion'"
          :descripcion="'En esta Opcion se seleccionan los diferentes documentos y configuraciones del Punto de ventas'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria 96 --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>

                </center>
                <!-- <BarraDinamicaNormal
              :url="$hostname + $hName + '/services/tipocliente/findalltipoclientes'"
              :nombre="'Tipo de Clientes'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' } 
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            /> -->





                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCXCTiposClientes'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <v-container>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-container>

                  <v-row>

                    <!-- columna 1 -->
                    <v-col cols="12" md="6" sm="6">
                      <!----------------------- Documento AFS  -->
                      <v-combobox v-model="nuevaConfiguracion.documentofacturacion" :rules="[$rules.required]"
                        label="Documento asignado para factura de seguro" :items="Listadocumentofacturacion"
                        @focus="CargarRdocumentofactura" item-text="descripcion" required outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </v-combobox>

                      <!----------------------- Documento ARI  -->
                      <!-- <v-combobox
            v-model="nuevaConfiguracion.documentorecibo"
            :rules="[$rules.required]"
            :items="Listadocumentorecibo"
            item-text="descripcion"
            label="Documento asignado para Recibo de Ingresos"
            @focus="CargarRdocumentoRecibo"
            required
            outlined
            dense
          > 
            <template v-slot:prepend>
              <v-img
                height="30px"
                width="20px"
                src="../../assets/file-alt.svg"
              ></v-img>
            </template>
          </v-combobox> -->

                      <!----------------------- Documento AOI  -->
                      <!-- <v-combobox
            v-model="nuevaConfiguracion.otrosIngresos"
            :rules="[$rules.required]"
            :items="ListaOtrosIngresos"
            item-text="descripcion"
            label="Documento asignado para Otros Ingresos"
            required
            @focus="CargarRdocumentoOtrosIngresos"
            outlined
            dense
          >

          
            <template v-slot:prepend>
              <v-img
                height="30px"
                width="20px"
                src="../../assets/file-alt.svg"
              ></v-img>
            </template>
          </v-combobox> -->

                      <!----------------------- Documento ANC  -->
                      <v-combobox v-model="nuevaConfiguracion.documentoNotaCredito" :rules="[$rules.required]"
                        :items="ListadocumentoNotaCredito" item-text="descripcion"
                        label="Documento asignado para Nota de Crédito" @focus="CargarRdocumentoNotCredito" required
                        outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </v-combobox>

                      <!----------------------- Documento AD  -->
                      <v-combobox v-model="nuevaConfiguracion.documentoDevolucion" :rules="[$rules.required]"
                        :items="ListadocumentoDevolucion" item-text="descripcion"
                        label="Documento asignado para Devolución" @focus="CargarRDocumentoDevoluc" required outlined
                        dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </v-combobox>

                      <!----------------------- Bandera barra peso  -->
                      <v-text-field v-model="nuevaConfiguracion.banderapeso"
                        label="Bandera del código de barra para el peso" :rules="[$rules.required]" required outlined
                        dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </v-text-field>

                      <!----------------------- comprobante fiscal  -->
                      <v-combobox v-model="nuevaConfiguracion.comprobantefacturacion" :rules="[$rules.required]"
                        :items="ListaTipoComprobantes" item-text="descripcion"
                        label="Tipo de comprobante fiscal asignado regularmente" @focus="cargarTipoComprobante" required
                        outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </v-combobox>

                      <!----------------------- Porcentaje descuento facturas a crédito  -->
                      <AutoNumeric v-model="nuevaConfiguracion.descuentoCredito"
                        label="Porcentaje de descuento facturas a crédito" required outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </AutoNumeric>

                      <!----------------------- Porcentaje descuento facturas  Seguro -->
                      <AutoNumeric v-model="nuevaConfiguracion.descuentoSeguro"
                        label="Porcentaje de descuento facturas de Seguro" required outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </AutoNumeric>

                      <!----------------------- Porcentaje descuento facturas domicilio -->
                      <AutoNumeric v-model="nuevaConfiguracion.descuentoDomicilio"
                        label="Porcentaje de descuento facturas a domicilio" required outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </AutoNumeric>

                      <!----------------------- Porcentaje descuento facturas tarjeta -->
                      <AutoNumeric v-model="nuevaConfiguracion.descuentoTarjeta"
                        label="Porcentaje de descuento facturas con tarjeta" required outlined dense>
                        <template v-slot:prepend>
                          <v-img height="20px" width="20px" src="../../assets/file-alt.svg"></v-img>
                        </template>
                      </AutoNumeric>

                      <!----------------------- conversión automática -->
                      <v-label>Moneda para hacer la conversión automática en el punto de
                        venta</v-label>

                      <v-combobox dense outlined :rules="[$rules.required]" :items="ListaMoneda" item-text="descripcion"
                        @focus="CargarMoneda" v-model="nuevaConfiguracion.monedaConversion"></v-combobox>


                    </v-col>
                    <!-- fn columna 1 -->

                    <!-- columna 2 -->
                    <v-col cols="12" md="6" sm="6">
                      <!----------------------- Especificar el vendedor -->
                      <v-switch class="mt-0" color="teal" label="" v-model="nuevaConfiguracion.vendedorobligatorio">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.vendedorobligatorio == true"
                            style="color:#000000; font-size:13px;">Especificar el vendedor sea obligatoria: Si</strong>
                          <strong v-if="nuevaConfiguracion.vendedorobligatorio == false"
                            style="color:#000000; font-size:13px;">Especificar el vendedor sea obligatoria: No</strong>
                        </template>
                      </v-switch>
                      <!-- <v-switch
          inset 
              hide-details
            v-model="nuevaConfiguracion.vendedorobligatorio"
            label="Especificar el vendedor sea obligatoria"
          ></v-switch>   -->


                      <!----------------------- Especificar si una factura -->
                      <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="nuevaConfiguracion.variosvendedores"
            >
          <template v-slot:label>
         <strong v-if="nuevaConfiguracion.variosvendedores==true" style="color:#000000; font-size:13px;">Especificar si una factura puede tener varios vendedores(vendedores por artículos): Si</strong>
         <strong v-if="nuevaConfiguracion.variosvendedores==false" style="color:#000000; font-size:13px;">Especificar si una factura puede tener varios vendedores(vendedores por artículos): No</strong>
        </template>
         </v-switch>  -->

                      <!----------------------- descuento facturas a crédito -->
                      <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="nuevaConfiguracion.descuentofacturacredito"
            >
          <template v-slot:label>
         <strong v-if="nuevaConfiguracion.descuentofacturacredito==true" style="color:#000000; font-size:13px;">Permitir descuento en facturas a crédito: Si</strong>
         <strong v-if="nuevaConfiguracion.descuentofacturacredito==false" style="color:#000000; font-size:13px;">Permitir descuento en facturas a crédito: No</strong>
        </template>
         </v-switch> -->

                      <!----------------------- imprimir facturas -->
                      <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="nuevaConfiguracion.imprimirfacturatemporal"
            >
          <template v-slot:label>
         <strong v-if="nuevaConfiguracion.imprimirfacturatemporal==true" style="color:#000000; font-size:13px;">Permitir imprimir facturas en la pantalla de guardar/restaurar: Si</strong>
         <strong v-if="nuevaConfiguracion.imprimirfacturatemporal==false" style="color:#000000; font-size:13px;">Permitir imprimir facturas en la pantalla de guardar/restaurar: No</strong>
        </template>
         </v-switch> -->



                      <!----------------------- Sumar ITBIS  -->
                      <!-- <v-switch
              
              style="margin-top:-25px"
              color="teal" 
              label=""
              v-model="nuevaConfiguracion.sumaitbisfactura"
            >
          <template v-slot:label>
         <strong v-if="nuevaConfiguracion.sumaitbisfactura==true" style="color:#000000; font-size:13px;">Sumar ITBIS al final de la factura: Si</strong>
         <strong v-if="nuevaConfiguracion.sumaitbisfactura==false" style="color:#000000; font-size:13px;">Sumar ITBIS al final de la factura: No</strong>
        </template>
         </v-switch> -->

                      <!----------------------- Imprimir lista  -->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.imprimirlistamensajeros">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.imprimirlistamensajeros == true"
                            style="color:#000000; font-size:13px;">Imprimir lista de entregas a mensajeros: Si</strong>
                          <strong v-if="nuevaConfiguracion.imprimirlistamensajeros == false"
                            style="color:#000000; font-size:13px;">Imprimir lista de entregas a mensajeros: No</strong>
                        </template>
                      </v-switch>

                      <!----------------------- Permitir vender costo  -->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.venderalcosto">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.venderalcosto == true"
                            style="color:#000000; font-size:13px;">Permitir vender por debajo del costo: Si</strong>
                          <strong v-if="nuevaConfiguracion.venderalcosto == false"
                            style="color:#000000; font-size:13px;">Permitir vender por debajo del costo: No</strong>
                        </template>
                      </v-switch>

                      <!----------------------- Impuestos Incluidos  -->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.impuestoincluido">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.impuestoincluido == true"
                            style="color:#000000; font-size:13px;">Impuestos Incluidos (MODO RETAIL IMPRESORA FISCAL):
                            Si</strong>
                          <strong v-if="nuevaConfiguracion.impuestoincluido == false"
                            style="color:#000000; font-size:13px;">Impuestos Incluidos (MODO RETAIL IMPRESORA FISCAL):
                            No</strong>
                        </template>
                      </v-switch>

                      <!----------------------- Médico Obligatorio  -->
                      <v-switch style="margin-top:-25px" color="teal" label="" v-model="nuevaConfiguracion.usarMedico">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.usarMedico == true"
                            style="color:#000000; font-size:13px;">Médico Obligatorio en el pv: Si</strong>
                          <strong v-if="nuevaConfiguracion.usarMedico == false"
                            style="color:#000000; font-size:13px;">Médico Obligatorio en el pv: No</strong>
                        </template>
                      </v-switch>
                      <!----------------------- Facturar CXC -->
                      <v-switch style="margin-top:-25px" color="teal" label="" v-model="nuevaConfiguracion.soloCxc">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.soloCxc == true" style="color:#000000; font-size:13px;">Solo
                            Facturar CXC: Si</strong>
                          <strong v-if="nuevaConfiguracion.soloCxc == false" style="color:#000000; font-size:13px;">Solo
                            Facturar CXC: No</strong>
                        </template>
                      </v-switch>


                      <!----------------------- busqueda avanzada  -->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.busquedaExtendida">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.busquedaExtendida == true"
                            style="color:#000000; font-size:13px;">Usar busqueda avanzada de productos: Si</strong>
                          <strong v-if="nuevaConfiguracion.busquedaExtendida == false"
                            style="color:#000000; font-size:13px;">Usar busqueda avanzada de productos: No</strong>
                        </template>
                      </v-switch>


                      <!----------------------- busqueda avanzada  -->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.noBajarPrecio">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.noBajarPrecio == true"
                            style="color:#000000; font-size:13px;">No permitir vender por debajo del precio actual:
                            Si</strong>
                          <strong v-if="nuevaConfiguracion.noBajarPrecio == false"
                            style="color:#000000; font-size:13px;">No permitir vender por debajo del precio actual:
                            No</strong>
                        </template>
                      </v-switch>

                      <!----------------------- Agregar devuelta   -->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.agregarDevueltaRecepcionDomiciilio">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.agregarDevueltaRecepcionDomiciilio == true"
                            style="color:#000000; font-size:13px;">Agregar devuelta en recepcion domicilio: Si</strong>
                          <strong v-if="nuevaConfiguracion.agregarDevueltaRecepcionDomiciilio == false"
                            style="color:#000000; font-size:13px;">Agregar devuelta en recepcion domicilio: No</strong>
                        </template>
                      </v-switch>

                      <!----------------------- Conduces Automaticos -->
                      <v-switch style="margin-top:-25px" color="teal" label="" v-model="nuevaConfiguracion.conduce">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.conduce == true"
                            style="color:#000000; font-size:13px;">Conduces Automaticos: Si</strong>
                          <strong v-if="nuevaConfiguracion.conduce == false"
                            style="color:#000000; font-size:13px;">Conduces Automaticos: No</strong>
                        </template>
                      </v-switch>


                      <!----------------------- Pedir Cobertura por Producto-->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.pedirCobertura">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.pedirCobertura == true"
                            style="color:#000000; font-size:13px;">Pedir Cobertura por Producto: Si</strong>
                          <strong v-if="nuevaConfiguracion.pedirCobertura == false"
                            style="color:#000000; font-size:13px;">Pedir Cobertura por Producto: No</strong>
                        </template>
                      </v-switch>

                      <!-----------------------Comentario sea Obligatorio-->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.comentarioObligatorio">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.comentarioObligatorio == true"
                            style="color:#000000; font-size:13px;">Comentario sea Obligatorio: Si</strong>
                          <strong v-if="nuevaConfiguracion.comentarioObligatorio == false"
                            style="color:#000000; font-size:13px;">Comentario sea Obligatorio: No</strong>
                        </template>
                      </v-switch>
                      <!-----------------------No Usar Domicilios-->
                      <v-switch style="margin-top:-25px" color="teal" label=""
                        v-model="nuevaConfiguracion.noBajarPrecio">
                        <template v-slot:label>
                          <strong v-if="nuevaConfiguracion.noBajarPrecio == true"
                            style="color:#000000; font-size:13px;">No Usar Domicilios: Si</strong>
                          <strong v-if="nuevaConfiguracion.noBajarPrecio == false"
                            style="color:#000000; font-size:13px;">No Usar Domicilios: No</strong>
                        </template>
                      </v-switch>



                    </v-col>
                    <!-- fn columna 2 -->
                  </v-row>


                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <!--------------------------------------------------- tb Factura inicial -->
                      <v-card>

                        <v-toolbar flat color="#BDBDBD" dense>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn v-on="on" @click.prevent="agregarDatos" color="green" fab x-small dark>
                                <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                              </v-btn>


                            </template>
                            <span>Agregar Descuento automático</span>
                          </v-tooltip>


                          <v-toolbar-title class="font-weight-light">
                            <v-card-title>DESCUENTO AUTOMÁTICO</v-card-title>
                          </v-toolbar-title>
                        </v-toolbar>

                        <v-form v-model="valid" ref="formDescAut" lazy-validation>

                          <v-data-table dense :headers="[{ text: 'DESDE', value: 'desde' }, { text: 'HASTA', value: 'hasta' }, { text: 'DESCUENTO', value: 'descuento' },
                          { text: 'ESPECIAL', value: 'especial' }, { text: 'actions', value: 'action' }]"
                            :items="ListaMonedaConversion">


                            <template v-slot:item.action="{ item }">

                              <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                              <v-btn fab @click.prevent="deleteItem(item)" x-small color="red darken-3"><i
                                  style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                            </template>

                            <template v-slot:item.desde="props">

                              <AutoNumeric label="" dense outlined :rules="[$rules.NumNocero]" autocomplete="off"
                                v-model.number="props.item.desde">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                                </template>
                              </AutoNumeric>
                              <!-- <span>{{currencyFormatter(props.item.desde)}}</span> -->
                            </template>


                            <template v-slot:item.hasta="props">
                              <AutoNumeric label="" dense outlined :rules="[$rules.NumNocero]" autocomplete="off"
                                v-model.number="props.item.hasta">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-usd"></i>
                                </template>
                              </AutoNumeric>
                            </template>

                            <template v-slot:item.descuento="props">


                              <AutoNumeric label="" :rules="[$rules.required, $rules.porciento2]" required outlined
                                dense v-model="props.item.descuento">
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-percent"></i>
                                </template>
                              </AutoNumeric>

                              <!-- <span>{{currencyFormatter(props.item.descuento)}} %</span> -->
                            </template>

                            <template v-slot:item.especial="props">

                              <v-switch v-if="props.item.especial == true" dense v-model="props.item.especial" inset
                                label="Si"></v-switch>
                              <v-switch v-if="props.item.especial == false" dense v-model="props.item.especial" inset
                                label="No"></v-switch>

                            </template>

                          </v-data-table>

                        </v-form>


                      </v-card>
                      <!--------------------------------------------------- fn tb Factura inicial -->




                    </v-col>
                  </v-row>
                  <!------------otras funciones---------------->
                  <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
                    :timeout="120000" top="top">
                    {{ aalert.nombre }}
                    <v-btn dark text @click="aalert.estado = false">Close</v-btn>
                  </v-snackbar>

                  <!-- ------------------------------------------------------ Elim_notif-->
                  <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350"
                    transition="fab-transition">
                    <template justify="center" v-slot:activator="{ on }"></template>

                    <v-card>
                      <v-card-title>
                        <h4>
                          <i style="font-size: 28px;" class="fa fa-warning"></i>
                          CONFIRMACION
                          <i style="font-size: 28px;" class="fa fa-warning"></i>
                        </h4>
                      </v-card-title>
                      <hr />

                      <v-card-text>
                        <h5>{{ Elim_notif.nombre }}</h5>
                      </v-card-text>

                      <hr />
                      <v-card-actions>
                        <v-btn @click.prevent="
                          eliminar(), (Elim_notif.estado = !Elim_notif.estado)
                        " small>
                          <i style="font-size: 20px;" class="fa fa-check"></i> Si
                        </v-btn>

                        <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
                          <i style="font-size: 20px;" class="fa fa-ban"></i> No
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
                    :timeout="120000" top="top">
                    {{ aalert.nombre }}
                    <v-btn dark text @click="aalert.estado = false">Close</v-btn>
                  </v-snackbar>

                  <!-- ------------------------------------------------------ tb-data-->
                  <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
                    transition="fab-transition">
                    <v-card>
                      <v-toolbar flat color="#BDBDBD">
                        <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
                        <v-toolbar-title class="font-weight-light">
                          REPORTE
                        </v-toolbar-title>
                        <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
                          <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                        </v-btn>
                      </v-toolbar>
                    </v-card>
                  </v-dialog>
                  <!-- ------------------------------------------------------ fn tb-data-->
                </v-container>
              </v-form>



            </v-container>
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>




  </div>



</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    AutoNumeric,
    ContenidoBase
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ConfiguracionPVNew", this.new);
    EventBus.$on("ConfiguracionPVSave", this.save);
    EventBus.$on("ConfiguracionPVRemove", this.remove);
    EventBus.$on("ConfiguracionPVAnular", this.anular);
    EventBus.$on("ConfiguracionPVPrintList", this.imprimirList);

    // this.ListaMonedaConversion.push({
    //   desde: 0,
    //   hasta: 0,
    //   descuento: 0,
    //   especial: 0
    // });

    EventBus.$on("onResize", this.onResize);
    this.cargarTodo();
  },
  created() {
    var json = {
      titulo: "Configuracion",
      descripcion:
        "En esta Opcion se seleccionan los diferentes documentos y configuraciones del Punto de ventas",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ConfiguracionPVNew",
        "ConfiguracionPVSave",
        "ConfiguracionPVRemove",
        "ConfiguracionPVAnular",
        "ConfiguracionPVPrintList"
      ],
      btnBarraDi: false
    };

    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      switch1: true,
      windowSize: { width: "auto", height: "auto" },
      //////////LISTAS/////////////
      Listadescuentofacturacredito: [],
      ListadocumentoDevolucion: [],
      ListadocumentoNotaCredito: [],
      Listadocumentofacturacion: [],
      Listadocumentorecibo: [],
      ListaOtrosIngresos: [],
      Listadocumentoconduce: [],
      ListaTipoComprobantes: [],
      ListaMonedaConversion: [],
      ListaMoneda: [],
      ListaDescuentoRangoValor: [],
      ///////////EL OBJETO PRINCIPAL//////////////////
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      nuevaConfiguracion: {
        descuentoRangoValorList: [],
        borrado: false,
        busquedaExtendida: false,
        comprobantefacturacion: null,
        descuentoCredito: 0,
        descuentoDomicilio: 0,
        descuentoSeguro: 0,
        descuentoTarjeta: 0,
        descuentofacturacredito: false,
        documentoDevolucion: null,
        documentoNotaCredito: null,
        documentofacturacion: null,
        documentorecibo: null,
        empresa: 0,
        imprimirfacturatemporal: false,
        imprimirlistamensajeros: false,
        impuestoincluido: false,
        monedaConversion: null,
        noBajarPrecio: false,
        otrosIngresos: null,
        soloCxc: false,
        sumaitbisfactura: false,
        usarMedico: false,
        variosvendedores: false,
        vendedorobligatorio: false,
        venderalcosto: false,
        banderapeso: 0,
        pedirCobertura: false,
        comentarioObligatorio: false,
        agregarDevueltaRecepcionDomiciilio: false,
        conduce: false,
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: false,
        nuevo: false,
        guardar: false,
        eliminar: false,
        anular: false,
        imprimir: false,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },



      //para la validacion del formulario///
      valid: null,
      /////para abrir el cuadro de notificacion/////////
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },
      acti_tb_data: false,
      aalert: {
        estado: false,
        color: null
      }

    };
  },
  methods: {

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },


    abrirTabla() {
      this.alerta("Este modulo de configuracion no consta de una tabla ,ya que no es necesaria.", "error")
    },
    agregarDatos(e) {
      // console.log(e);
      // this.ListaMonedaConversion.push(e);
      var json = {
        desde: 0,
        hasta: 0,
        descuento: 0,
        especial: false
      };
      this.ListaMonedaConversion.push(json);
    },
    deleteItem(item) {
      if (this.ListaMonedaConversion.length != 0) {
        const index = this.ListaMonedaConversion.indexOf(item);
        confirm("estas seguro que desea eliminar este registro de esta tabla?") && this.ListaMonedaConversion.splice(index, 1);

        // if (this.ListaMonedaConversion.length == 0) {
        //   this.ListaMonedaConversion.pop();
        //   var json = {
        //     desde: 0,
        //     hasta: 0,
        //     descuento: 0,
        //     especial: 0
        //   };
        //   this.ListaMonedaConversion.push(json);
        // }
      }
    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/cargarConfiguracion",
          {
            usuario: JSON.parse(JSON.stringify(this.$user))
          }
        )
        .then(res => { this.nuevaConfiguracion = res.data.configuracion, this.ListaMonedaConversion = res.data.Descuento })
        .catch(error => console.log(error));
      if (isNullOrUndefined(this.nuevaConfiguracion.banderapeso)) {
        this.nuevaConfiguracion.banderapeso = "0";
      }

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentoConduce",
      //     {}
      //   )
      //   .then(res => (this.Listadocumentoconduce = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentoDevolucion",
      //     {}
      //   )
      //   .then(res => (this.ListadocumentoDevolucion = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentoNotaCredito",
      //     {}
      //   )
      //   .then(res => (this.ListadocumentoNotaCredito = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentoPV",
      //     {}
      //   )
      //   .then(res => (this.Listadocumentofacturacion = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentoRecibo",
      //     {}
      //   )
      //   .then(res => (this.Listadocumentorecibo = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentosComprobantes",
      //     {}
      //   )
      //   .then(res => (this.ListaTipoComprobantes = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/configuracionpv/findlistDocumentoOtrosIngreso",
      //     {}
      //   )
      //   .then(res => (this.ListaOtrosIngresos = res.data))
      //   .catch(error => console.log(error));

      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/Moneda/findall", {
      //     usuario:JSON.parse(JSON.stringify(this.$user)) 
      //   })
      //   .then(res => (this.ListaMoneda = res.data))
      //   .catch(error => console.log(error));

      await this.cargarTablaRangoDescuentoValor();
    },


    CargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + "/monedas/findallActivos/", { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.ListaMoneda = res.data))
        .catch(error => console.log(error));

    },

    CargarRdocumentofactura() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/DdefectoFactura",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Listadocumentofacturacion = res.data;
        });
    },


    CargarRdocumentoRecibo() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/DdefectoRecibodIngreso",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Listadocumentorecibo = res.data;
        });
    },



    CargarRdocumentoOtrosIngresos() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/DdefectoOtrosIngresos",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.ListaOtrosIngresos = res.data;
        });
    },


    CargarRdocumentoNotCredito() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/DdefectoNotadCrédito/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.ListadocumentoNotaCredito = res.data;
        });
    },



    CargarRDocumentoDevoluc() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/DdefectoDevolución/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.ListadocumentoDevolucion = res.data;
        });
    },



    cargarTipoComprobante() {

      this.$axios.get(this.$hostname + this.$hName + '/Tipocomprobantes/findall', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.ListaTipoComprobantes = res.data))

    },


    async cargarTablaRangoDescuentoValor() {
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/ConfiguracionDPv/findallDescuentoRangoValor",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => { this.ListaDescuentoRangoValor = res.data })
        .catch(error => console.log(error));

      // this.ListaMonedaConversion = [];
      // this.ListaDescuentoRangoValor.forEach(e => {
      //   var json = {
      //     codigo: 0,
      //     borrado: false,
      //     desde: e.desde,
      //     hasta: e.hasta,
      //     especial: e.especial,
      //     descuento: e.descuento
      //   };

      //   this.ListaMonedaConversion.push(json);
      // });

    },

    datostabla() { },
    save() {

      var retorna = false


      if (this.ListaMonedaConversion.length > 0) {

        if (this.$refs.formDescAut.validate()) {


          this.ListaMonedaConversion.forEach(element => {

            if (element.desde > element.hasta) {
              this.alerta("En descuento automático, 'Desde' es mayor que 'Hasta'", "error");
              retorna = true
              return
            }

          });

          if (retorna == true) { return }

        } else {
          this.alerta("En el descuento automático hay campo requerido", "error");
          return
        }

      }


      if (this.$refs.form.validate()) {
        this.nuevaConfiguracion.descuentoRangoValorList = this.ListaMonedaConversion;

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.nuevaConfiguracion)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }
        this.$axios
          .post(
            this.$hostname + this.$hName + "/ConfiguracionDPv/save", this.nuevaConfiguracion
            , { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("se guardo correctamente!", "bien"))
          .catch(error => console.log(error));
      } else {
        this.alerta(
          "por favor verifique los campos en rojo y que todos los datos sean suministrados correctamente, si estan vacios pongale (0) "
        );
      }


    },
    remove() {
      this.Elim_notif.estado = !this.Elim_notif.estado;
    },
    eliminar() {
      this.$axios
        .post(
          this.$hostname + this.$hName + "/configuracionpv/remove",
          this.nuevaConfiguracion, { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res =>
          this.alerta(
            "no se guardo correctamente, por favor verifique los campos en rojo!",
            "error"
          )
        )
        .catch(error => console.log(error));
    },
    anular() { },
    imprimirList() { },
    newwnuevo() {
      this.nuevaConfiguracion = {
        descuentoRangoValorList: [],
        borrado: false,
        busquedaExtendida: false,
        comprobantefacturacion: null,
        descuentoCredito: 0,
        descuentoDomicilio: 0,
        descuentoSeguro: 0,
        descuentoTarjeta: 0,
        descuentofacturacredito: false,
        documentoDevolucion: null,
        documentoNotaCredito: null,
        documentofacturacion: null,
        documentorecibo: null,
        empresa: 0,
        imprimirfacturatemporal: false,
        imprimirlistamensajeros: false,
        impuestoincluido: false,
        monedaConversion: null,
        noBajarPrecio: false,
        otrosIngresos: null,
        soloCxc: false,
        sumaitbisfactura: false,
        usarMedico: false,
        variosvendedores: false,
        vendedorobligatorio: false,
        venderalcosto: false,
        banderapeso: 0,
        pedirCobertura: false,
        comentarioObligatorio: false,
        agregarDevueltaRecepcionDomiciilio: false,
        conduce: false,
      },

        this.ListaMonedaConversion = []


      this.cargarTodo();
      // EventBus.$emit("actualizaBarraBusqueda2");
    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();

      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    currencyFormatter(params) {
      //return this.formatNumber(params) + ".00";
      return this.formatNumber(params);
    },

    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    }
  }
};
</script>

<style>
</style>
