var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(_vm.ActDescBtn)+" "),_c('table',{staticStyle:{"position":"absolute"},attrs:{"width":"100%","height":this.windowSize.height - 8,"max-height":this.windowSize.height - 8}},[_c('tr',{attrs:{"max-height":"48px","height":"48px"}},[_c('ContenidoBase',{attrs:{"titulo":'Precio Producto',"descripcion":'Ver Precio de los Productos',"EstadoBtn":_vm.EstadoBtn},on:{"abrirBarraTablaDinamica":_vm.abrirTabla,"MethodNuevo":_vm.newwnuevo,"MethodGuardar":_vm.save,"MethodEliminar":_vm.remove,"MethodAnular":_vm.anular,"MethodImprimir":_vm.imprimirList}})],1),_c('tr',[_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.BarraTablaDinamicaAprir),expression:"BarraTablaDinamicaAprir"}],attrs:{"width":"109","max-height":this.windowSize.height - 96}},[_c('div',{staticClass:"overflow-y-auto",style:({
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          })},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('center',[_c('v-btn',{attrs:{"small":"","width":"95%","color":"yellow accent-4"},on:{"click":function($event){$event.preventDefault();return _vm.actualiza_tb_ver($event)}}},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"font-size":"25px"}}),_vm._v(" ver")])],1)]}}])})],1)]),_c('td',{attrs:{"max-height":this.windowSize.height - 96}},[_c('v-app',{staticClass:"overflow-y-auto",style:({ 'max-height': this.windowSize.height - 96 + 'px' }),attrs:{"id":"inspire"}},[_c('v-container',[_c('div',{on:{"click":_vm.ver}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"align":'center',"justify":'center'}},[_c('img',{attrs:{"height":"250","width":"250","src":_vm.logo,"alt":"micromarket"},on:{"load":_vm.ver}})])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"align":'center',"justify":'center'}},[_c('b',[_c('i',{staticStyle:{"font-size":"35px"}},[_vm._v(_vm._s(_vm.usuario))])])])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"align":'center',"justify":'center'}},[_c('b',[_c('i',{staticStyle:{"font-size":"50px"}},[_vm._v(_vm._s(_vm.descripcion))])])])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"align":'center',"justify":'center'}},[_c('b',{staticStyle:{"font-size":"50px"}},[_vm._v("RD$."+_vm._s(_vm.currencyFormatter(_vm.precio)))])])],1)],1),_c('center',[_c('v-text-field',{staticStyle:{"font-size":"0px"},attrs:{"autofocus":true,"id":"codigo","color":"white","type":"number"},on:{"blur":_vm.ver,"change":_vm.ejecutar},model:{value:(_vm.producto.codigo),callback:function ($$v) {_vm.$set(_vm.producto, "codigo", $$v)},expression:"producto.codigo"}})],1)],1)])],1)],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.BarraSecundariaAprir),expression:"BarraSecundariaAprir"}],attrs:{"width":"109","max-height":this.windowSize.height - 96}},[_c('div',{staticClass:"overflow-y-auto",style:({
            'max-height': this.windowSize.height - 96 + 'px',
            'max-width': '325px',
            width: '325px',
          })})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }