<template>
 <div> {{ActDescBtn}}  
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
<tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte de Diario de Ventas'"
  :descripcion="'Imprime las Ventas de los Puntos de Venta'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList" 
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesDiarioVentas'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
<!-- Formulario-Reporte de Diario de Ventas -->
 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;" > Diario de Ventas </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="DiarioVentas.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

                
                
<!-------------------------------------------------- Sucursal  :rules="[$rules.required]"//:rules="DiarioVentas.Resumen==false?[$rules.required]:[]"    -->
<v-combobox 

            autocomplete="off"
            dense
            outlined
            label="Sucursal:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="DiarioVentas.sucursal"
            @focus="cargarSucursal"
            @blur="SelctCbSucursal"
            
          >
          <template v-slot:selection="{ attrs, item, parent, selected}">
                        <span class="black--text" v-if="item.hasOwnProperty('descripcion')" >{{item.descripcion.trim()}}</span>
                       </template>

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
 </v-combobox>






<v-combobox
v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda"
            autocomplete="off"
            dense
            outlined
            label="Moneda"
            :items="Rmoneda"
            item-text="nombrecorto"
            v-model="DiarioVentas.moneda"
            @blur="SelctCbMoneda"
            @focus="cargarMoneda"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-usd"></i>
            </template>
          </v-combobox>

          <v-switch
          class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Solo ITBIS:"
              v-model="DiarioVentas.itbis"
            >
          <template v-slot:label>
         <strong v-if="DiarioVentas.itbis==true" style="color:#000000;">Solo ITBIS: Si</strong>
         <strong v-if="DiarioVentas.itbis==false" style="color:#000000;">Solo ITBIS: No</strong>
        </template>
            
            
            </v-switch>

 <v-switch
              
              color="teal"
              inset 
              hide-details
              label="Resumido:"
              v-model="DiarioVentas.Resumen"
              @change="changeDsc"

            >
          <template v-slot:label>
         <strong v-if="DiarioVentas.Resumen==true" style="color:#000000;">Resumido: Si</strong>
         <strong v-if="DiarioVentas.Resumen==false" style="color:#000000;">Resumido: No</strong>
        </template>
            
            
            </v-switch>

            <v-switch
              v-if="$store.getters.GetdatosInfUsu.usuario.dsc"
              color="teal"
              inset 
              hide-details
              label="DSC:"
              v-model="DiarioVentas.dsc"
              @change="changeDsc"
            >
          <template v-slot:label>
         <strong v-if="DiarioVentas.dsc==true" style="color:#000000;">DSC: Si</strong>
         <strong v-if="DiarioVentas.dsc==false" style="color:#000000;">DSC: No</strong>
        </template>
        </v-switch>

        <v-switch
              color="teal"
              inset 
              hide-details
              label="Aplicar NC al Valor NETO:"
              v-model="DiarioVentas.aplicarNC"
            >
          <template v-slot:label>
         <strong v-if="DiarioVentas.aplicarNC==true" style="color:#000000;">Aplicar NC al Valor NETO: Si</strong>
         <strong v-if="DiarioVentas.aplicarNC==false" style="color:#000000;">Aplicar NC al Valor NETO: No</strong>
        </template>
        </v-switch>


</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="DiarioVentas.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>


<!------------------------------------------- caja--> 
<!-- @blur="SelctCbCaja" @focus="cargarCaja"  -->
<v-combobox
                    v-model="DiarioVentas.TipoVenta"
                    label="Tipo de Ventas"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="RTipoVenta"
                    item-text="desc"
                    @blur="SelctCbTipoVenta"  
                  > 
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-tag"
                            ></i>
                          </template>
                        </v-combobox>

                        <v-combobox autocomplete="off" dense outlined label="Tipos de Comprobante:"
                        :items="filterTipoComprobantePvItbis(RtipoComprobante)" item-text="descripcion"
                        v-model="DiarioVentas.comprobante" @blur="SelctCbTipoCompComprobant"
                        @focus="cargarTipoComprobante" >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-tag"></i>
                        </template>
                      </v-combobox>



</v-col>
<!-- fn columna 2 -->


              
              </v-row>






            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
ref="ModRepFilt"
@DataReporteBasico="DDataReporteBasico"
:Datos="DiarioVentas"
/>


  </v-card>
 </v-container>
<!-- fn Formulario-Reporte de Diario de Ventas -->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Reporte de Diario de Ventas",
      descripcion: "Imprime las Ventas de los Puntos de Venta",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [ 
        "ReportesDiarioVentasNew",
        "ReportesDiarioVentasSave",
        "ReportesDiarioVentasRemove",
        "ReportesDiarioVentasAnular",
        "ReportesDiarioVentasPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesDiarioVentasNew", this.new);
    // EventBus.$on("ReportesDiarioVentasSave", this.save);
    // EventBus.$on("ReportesDiarioVentasRemove", this.remove);
    // EventBus.$on("ReportesDiarioVentasAnular", this.remove);
    // EventBus.$on("ReportesDiarioVentasPrintList", this.imprimirList);
    EventBus.$on("emitReportesDiarioVentas", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
  },

  data:()=>  ({


      Rpropietario:[],
      RSucursal:[],
      RCaja:[],
      Rmoneda:[],
      RTipoVenta:[{codigo:1,desc:'Contado'},{codigo:2,desc:'Crédito'}],
      RtipoComprobante:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      DiarioVentas: {
        nombre:'REPORTE DE DIARIO DE VENTAS',
        UrlImprimir: '/reportes-pv/ImprimirListDiarioVentas', 
        UrlExel: '/reportes-pv/ExcelListDiarioVentas', 
        UrlCorreo: '/reportes-pv/CorreoListDiarioVentas', 
        desde: fechaNueva(),
        hasta:fechaNueva(),
        sucursal:null,
        itbis:false,
        Resumen:false,
        TipoVenta:null,
        moneda:null,
        dsc:false,
        comprobante:null,
        aplicarNC:false
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    }
   ,
    datostabla(e) {

      this.DiarioVentas = e;
      this.acti_tb_data=false
    },
    save() {},
    remove() { },
    eliminar() {},
    anular() { },
    imprimirList() {},
    // retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(){},
    newwnuevo() {
      (this.DiarioVentas = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },




// Hacer una llamada axios al backend y devolver los datos al frontend.
cargarSucursal(){

this.$axios.get(this.$hostname+this.$hName+'/sucursal/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RSucursal=res.data))

},




// Comprobando si el usuario ha seleccionado un valor del menú desplegable. Si no, está mostrando un
// mensaje de error.
SelctCbSucursal(){
var _this = this;
      setTimeout(function () {
        if (_this.DiarioVentas.sucursal == null) {
          _this.DiarioVentas.sucursal = "";
        }

        if (!_this.DiarioVentas.sucursal.hasOwnProperty('codigo')) {
          _this.DiarioVentas.sucursal = "";
          _this.DiarioVentas.sucursal = null;
          _this.alerta("Seleccione una sucursal", "error");
          return;
        }
      }, 300);



 /*if(this.DiarioVentas.sucursal==null){
    this.DiarioVentas.sucursal=''
  }

if(this.DiarioVentas.sucursal.codigo==undefined){
this.alerta("Seleccione una sucursal", "error");
this.DiarioVentas.sucursal=''
return
}*/



},


// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbTipoVenta(){

 if(this.DiarioVentas.TipoVenta==null){

    this.DiarioVentas.TipoVenta=''
  }

if(this.DiarioVentas.TipoVenta.codigo==undefined){

//this.alerta("Seleccione una sucursal", "error");
this.DiarioVentas.TipoVenta=''
return

}
},



// Un método que se llama cuando el campo de entrada pierde el foco y si esta nulo
// coloca la fecha actual.
BlurDesde(){

if(this.DiarioVentas.desde.toString().length==0){
  this.DiarioVentas.desde=this.fechaNueva()
}

 },


// Un método que se llama cuando el campo de entrada pierde el foco y si esta nulo
// coloca la fecha actual..
 BlurHasta(){

if(this.DiarioVentas.hasta.toString().length==0){
  this.DiarioVentas.hasta=this.fechaNueva()
}

 },






// Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
cargarMoneda(){

this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rmoneda=res.data))

},


// Comprobando si el valor de la selección es nulo o indefinido.
SelctCbMoneda(){

 if(this.DiarioVentas.moneda==null){

    this.DiarioVentas.moneda=''
  }

if(this.DiarioVentas.moneda.codigo==undefined){

this.alerta("Seleccione una moneda", "error");
this.DiarioVentas.moneda=''
return

}

},

 // Validando el formulario y luego creando una cláusula where para una consulta.
 validarObjecto() {
      if (!this.$refs.form.validate()) {
       this.alerta("Debe seleccionar los campos en rojo","error");
       this.$refs.ModRepFilt.detener = true;
       return 
      }else{
        this.$refs.ModRepFilt.detener = false;
      }
          
    },


    changeDsc(){
    var _this=this;
    setTimeout(function(){ 
      //if(_this.DiarioVentas.Resumen==true){_this.DiarioVentas.dsc=false}
    }, 300);
      
    },

    // Llamar a una API  y devolver los datos del comprobante.
    cargarTipoComprobante() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tiponcf/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RtipoComprobante = res.data.filter((ee) => ee.pv == true || ee.especial==true);
        });
    },

     // Comprobando si el objeto en nulo o indefinido.
     SelctCbTipoCompComprobant() {
      var _this = this;
      setTimeout(function () {
        if (_this.DiarioVentas.comprobante == null) {
          _this.DiarioVentas.comprobante = "";
        }

        if (!_this.DiarioVentas.comprobante.hasOwnProperty('codigo')) {
          _this.DiarioVentas.comprobante = "";
          _this.DiarioVentas.comprobante = null;
          return;
        }
      }, 300);
    },

    filterTipoComprobantePvItbis(List){
      //RtipoComprobante
      return List.filter((ee) => ee.pv==true && ee.itbis==true || ee.descripcion.trim().toLowerCase().includes('regimen especial') || ee.especial==true );
      //return List.filter((ee) => ee.pv==true && ee.itbis==true );
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },
  computed:{

ActDescBtn(){ 
  this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
}
}
};





</script>

<style></style>
