<template>
  <v-app id="inspire" v-resize="onResize">{{ ExcData }}


    <v-navigation-drawer v-if="temporaryy == false" v-model="abrirMenu" app clipped :temporary="temporaryy" width="360">
      <BarraNavegacion />
    </v-navigation-drawer>


    <v-navigation-drawer v-if="temporaryy == true" v-model="abrirMenu" app clipped v-bind:style="{ 'width': '370px' }"
      :temporary="temporaryy">
      <!--   absolute    v-bind:style="{  'width': '270px' } -->
      <BarraNavegacion />
    </v-navigation-drawer>

    <v-app-bar app clipped-left>
      <!-- <v-app-bar-nav-icon @click.stop="abrir_cerrar" /> -->
      <BarraInicial :PropsBtnConfig="BtnConfigOpcionPv" @abrir_cerrar="abrir_cerrar" />
    </v-app-bar>
    <v-content fluid>
      <!-- <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="background-color:#FFFFFF"></v-col> -->
        <!-- <p style="font-size: 8px;"  class="black--text pa-0 ma-0 py-0 my-0">5555555</p> -->
      <hr class="white--text"  style="border-bottom: 1px solid #FFFFFF; background-color:#FFFFFF">
    
      <router-view></router-view>
      <!-- <Contenido @abrirMenuDerecho="abrirMenuDerecho"/> -->


      <v-footer color="grey lighten-1" app class="pa-0 ma-0 py-0 my-0">
        <span v-if="windowSize.width > 800" class="white--text">
          <Footer />
        </span>

        <span v-if="windowSize.width <= 800 && windowSize.height >= 200" style="font-size: 70%;"
          class="white--text colorNombre">
          <img id="imgmini" src="@/assets/Flag_RD.png">
          <img id="imgmini" src="@/assets/log.png">
          <strong class="subheading colorNombre"> Copyright Decasystems / © 2014-2022 (809)-247-4811 </strong>
          <br>
          <strong class="colorNombre">Versión:{{ $Version }}</strong>
        </span>

      </v-footer>

      <!-- </v-container> -->
      <!------------otras funciones---------------->
      <v-snackbar v-model="aalert.estado" :color="aalert.color"
        style="margin-top:20%;max-height:400px;display:block;overflow:auto;" :vertical="true" :timeout="120000"
        top="top">
        {{ aalert.nombre }}
        <v-btn dark text @click="aalert.estado = false">Close</v-btn>
      </v-snackbar>

    </v-content>
    <!-- ------------------------------------------------------  Modal Loader -->

    <v-dialog v-model="Reparaloading" persistent width="300">
      <v-card color="grey darken-2" dark>
        <v-card-text>
          <br>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal loader -->


<!-- ------------------------------------------------------ Modal PdfViewer -->  
<v-dialog v-model="dialogPdfViewer" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <ComponentsPdfViewer ref="RefComponentsPdfViewer"   @DataRetornCancelarPdfViewer="dialogPdfViewer= false" />
  </v-card>
</v-dialog>
<!-- fn PdfViewer------------------------------------------------------------------->
  </v-app>
</template>

<!-- <script>
export default {


  props: {
    source: String
  },
  data: () => ({
   
  }),
  created() {
    this.$vuetify.theme.dark = true;
  }
};
</script> -->
<script>
import { EventBus } from "./event-bus.js";
import BarraInicial from "./components/BarraInicial";
import Footer from "./components/FooterPlantilla";
import Contenido from "./components/Contenido";
import BarraNavegacion from "./components/BarraNavegacion";
import { VueEasyJwt } from "vue-easy-jwt";
import axios from "axios";
import {changeZone,fechaNueva} from "@/js/Funciones.js";
import ComponentsPdfViewer from "@/components/PdfViewer.vue";
const JWT = new VueEasyJwt();


export default {
  // import Principal from './components/Principal';
  name: "App",
  components: {
    // Principal,
    Contenido,
    BarraInicial,
    BarraNavegacion,
    Footer,
    ComponentsPdfViewer
  },

  created() {

    // this.$vuetify.theme.dark = true;
  },
  mounted() {
    EventBus.$on("abrircerrarBarraPrincipal", this.abrir_cerrar2);
    EventBus.$on("cerrarBarraPrincipal", this.cerrar2);
    EventBus.$on("AbrirMenuOfDasboar", this.AbrirMenuOfDasboar);
    EventBus.$on("loading", this.Getloading);
    EventBus.$on("openPDF", this.openDialogPdfViewer);
    // console.log(this.$vuetify.application.top);
    this.onResize()
    this.ScrUpdate()
    this.agregarInterceptorErrores()

  },
  data: () => ({

    Reparaloading: false,
    dialogPdfViewer: false,
    dataPDF:'',
    temporaryy: false,
    abrirMenu: false,
    MenuDerecho: false,
    windowSize: { width: "auto", height: "auto" },
    aalert: {
      estado: false,
      color: null,
      nombre: 'Hola mundo'
    },
    drawer: null,
    BtnConfigOpcionPv: false,
  }),

  props: {
    source: String
  },


  methods: {
    changeZone,fechaNueva,
    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }


      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

      }
    },

    agregarInterceptorErrores() {
      let _this = this;
      axios.interceptors.response.use(response => {
        return response;
      }, error => {
        try {
          let statusCode = error.response.status;
          let msg = "";
          if (error.response) {
            msg = error.response.data
          } else if (error.request) {
            msg = error.request.data
          } else {
            msg = error.message;
          }
          switch (statusCode) {
            case 500:
              // _this.alerta("Operacion abortada!!!", "error")
              console.log("Operacion abortada!!!", "error")
              console.log(msg);
              break;
            case 404:
              console.log(msg);
              break;
            case 400:
              console.log("El objeto no coincide");
              console.log(msg);
              break;
            case 422:
              console.log("El objeto no coincide");
              _this.alerta(msg, "error");
              break;
          }
        } catch (e) {
          return Promise.reject(e)

        }
        return Promise.reject(error)
      })

      axios.interceptors.request.use((config) => {
        if(config.method==="post" && config.data!=undefined){
          var CONFIG2=config.data;
          if(CONFIG2.hasOwnProperty('fecha')){
            if(CONFIG2.fecha===null){
              CONFIG2.fecha=this.fechaNueva()
            }
            if(CONFIG2.fecha.toString().includes('T')){
              console.log(`Fecha llego mal ruta==> (${this.$route.fullPath})`)
            }else{
              CONFIG2.fecha=this.changeZone(CONFIG2.fecha.valueOf()) 
              config.data=JSON.stringify(CONFIG2)
            }
          }
         
        }

        const token = _this.$store.getters.GetheadersAxios;
        if (token) {
          config.headers = { ...token, ...config.headers };
        }
        return config;
      }, (err) => {
        return Promise.reject(err);
      });
    },

    ScrUpdate() {
      setTimeout(this.onResize, 800);
    },


    onResize() {
      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 }
      this.windowSize = jsn
      this.$store.commit('MutationwindowSize', jsn);
      //-112
      EventBus.$emit("onResize", jsn);
    },

    abrir_cerrar() {
      this.abrirMenu = !this.abrirMenu;

    },


    cerrar2() {

      var js = JSON.parse(sessionStorage.getItem("BotonesAccion"))
      if (!js.hasOwnProperty('Transp')) {
        this.abrirMenu = false
      }
      //var _this=this;
      //setTimeout(function(){ _this.abrirMenu=true }, 500);
      //setTimeout(this.abrirMenu=false,10000);
      // this.abrirMenu = true

    },
    AbrirMenuOfDasboar() {

      this.abrirMenu = true
    },

    Getloading(e) {
      this.Reparaloading = JSON.parse(JSON.stringify(e))
    },


    openDialogPdfViewer(URL_OR_BASE,ARCHIVO_NOMBRE){
// var PDF="";PDF+=``;
this.dialogPdfViewer=true

var _this = this;
      setTimeout(function () {
        _this.$refs.RefComponentsPdfViewer.cargarPdf(URL_OR_BASE,ARCHIVO_NOMBRE)
        }, 500);

},

   
    abrir_cerrar2() {

      if (this.abrirMenu = true) {
        this.abrirMenu = !this.abrirMenu;
      }
      //console.log('La real pampara')

      if (this.abrirMenu = true) {
        this.abrirMenu = !this.abrirMenu;
      }
    },


    abrirMenuDerecho() {
      this.MenuDerecho = !this.MenuDerecho;
    },


    async VerificarPenmisoLink() {

      var PenmisoLink = null
      var ObjItems = JSON.parse(JSON.stringify(this.$store.getters.GetDataRouter))

      ObjItems.forEach(el => {
        if (el.hasOwnProperty('items')) {
          var ObjCrearRow = el.items.filter(e => e.link == this.$route.fullPath)

          if (ObjCrearRow.length > 0) {
            PenmisoLink = true
            return 0;
          }


          if (ObjCrearRow.length == 0) {
            el.items.forEach(ell => {
              if (ell.hasOwnProperty('items')) {
                var ObjCrearRowItem = ell.items.filter(e => e.link == this.$route.fullPath)

                if (ObjCrearRowItem.length > 0) {
                  PenmisoLink = true
                  return 0;
                }

                if (ObjCrearRow.length == 0) {
                  if (PenmisoLink == null) {
                    PenmisoLink = false
                    return 0;
                  }
                }

              } else {

                if (PenmisoLink == null) {
                  PenmisoLink = false
                  return 0;
                }
              }

            });
          }

        }
      });

      if (PenmisoLink == false) {
        this.$router.push({ path: '/PageNotPermission' })
      }
      //PageNotFound
    }


  },


  computed: {

    ExcData() {

      //Nota: Manteniendo variable informacion usuario:
      //[&%
      // console.log('ExcData ExcData')
      // console.log(this.$route.name)

      var us = JSON.parse(sessionStorage.getItem("Usuario"))
      if (us != null) {
        this.$store.commit('MutationaddUser', us);
      } else {

        if (this.$route.name != "Login") {

          this.$router.push({ path: '/' })
          location.reload();
        }
      }
      //&]%

      //Nota: Manteniendo variable ConfiguracionGeneral:
      //[&%
      var Conf = JSON.parse(sessionStorage.getItem("ConfiguracionGeneral"))
      if (Conf != null) {
        this.$store.commit('MutationaddConfiguracionGeneral', Conf);
      }

      //&]%

      //Nota: Manteniendo variable Otras configuracion pv (Caja, impresora y tuno):
      //[&%
      var ConfOtros = JSON.parse(sessionStorage.getItem("OtrasConfigPv"))
      if (ConfOtros != null) {

        if (Conf != null) {
          ConfOtros.moneda = Conf.config.monedabase.descripcion
        }
        if (us != null) {
          ConfOtros.cajero = us.usuario.nombre
        }


        this.$store.commit('MutationDataOtrasConfigPv', ConfOtros);
      }

      //&]%


      //Nota: Manteniendo variable informacion to:
      //[&%

      var autk = JSON.parse(sessionStorage.getItem("AuthorizationToken"))
      if (autk != null) {
        const TOKEN = JWT.decodeToken(autk);
        const TENANT_ID = TOKEN.tenant;
        this.$store.commit('MutationHeadersAxios', autk);
        this.$store.commit('MutationHeadersTenant', TENANT_ID);
        this.$store.commit('MutationHeadersAxios', autk);
      } else {


      }

      // console.log(autk)
      //console.log(this.$store.getters.GetheadersAxios)
      //&]%


      //Nota: Manteniendo Las router:
      //[&%
      var rou = JSON.parse(sessionStorage.getItem("itemsRouter"))
      this.$store.commit('MutationDataRouter', rou);

      //&]%

      //Nota: Bottton menu  Las router:
      //[&%
      //fullPath: "/",name: "Login",path: "/"

      if (this.$route.name == "Login") {
        this.$store.commit('MutationActivarBarraAccion', false);
        this.$store.commit('MutationbtnAppBar', false);
      } else {
        this.$store.commit('MutationActivarBarraAccion', true);
        this.$store.commit('MutationbtnAppBar', true);
      }
      //[&%


      //Nota: Activa y desativa el botton en el pv:
      //[&%

     
      if (this.$route.name == "PVPuntoVenta" || this.$route.name=="PVFacturacionComercial" ) {
        this.BtnConfigOpcionPv = true

      } else {
        this.BtnConfigOpcionPv = false
      }

      //[&%


      //Nota: Configuracion :
      //[&%


      if (this.$route.name == "Principal") {
        this.temporaryy = false
        this.abrirMenu = true
      } else {
        this.temporaryy = true
      }

      //[&%

      //Nota: Verificando Links:
      //[&%


      // if(!this.$route.path.includes('/Reportes/')){}
      if (this.$route.name != "PageNotPermission" && this.$route.name != "Principal" && this.$route.name != "Login" && !this.$route.name.includes("pdf")) {
        this.VerificarPenmisoLink()
      }


      //[&%

    }


  }


};

</script>
<style lang="scss">
// @media screen and (max-height: 600px)
// {
// #img-ajustada{
// height: 30px;
// }

// }


// #img-ajustada{
// height: 80px;
// }
//.v-data-table__selected

.fontSize13 {
  font-size: 13px;
}

.RowSelectColorPv {
  background-color: #dd4b39 !important;
}

.RowSelectColorPvGreen {
  background-color: #81C784 !important;
}

.RowSelectColor .v-data-table__selected {
  background-color: #BDBDBD !important;
}


#imgmini {
  height: 20px;
  width: 45px;
  border-radius: 2px;
}

#formdisabledInput {
  color: black;
}


.colorNombre {
  color: #000000;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


// #TbFact, table, tbody, thead, tr, th, td {
//     margin: 0;
//     padding: 0;
//     border: 0;
//     outline: 0;
//     font-size: 100%;
//     vertical-align: baseline;
//     background: transparent;
//   }


.RowSelectColor .v-data-table__selected {
  background-color: #BDBDBD !important;
}


.TbSize13 table tbody tr td {
  font-size: 13px;
}

#TbBord table tr .text-start , table tr .text-center {
  //border-right: 2px solid #757575;
  //border-left: 2px solid #757575;
  margin: 0;
  padding: 0;
  //border: 0;
}

.inputMargin {
  //border-right: 2px solid #757575;
  //border-left: 2px solid #757575;
  //border: 2px solid #757575;
  //font-size:13px;

  margin: 0;
  padding: 0;
  width: auto;
  height: 42px;


  //border: 0;


}


.border-total {

  border: 2px solid #757575;
  // border-bottom: 6px solid #757575;

}


.border-top-bottom {

  border-top: 2px solid #757575;
  border-bottom: 2px solid #757575;
}


#alerMov {
  margin-top: 20%;
}

#labText-min {
  font-size: 20px;
}

@media screen and (max-width: 800px) {

  #labText-min {
    font-size: 13px;
  }

  #boton-mini {
    font-size: 0.45px;
  }


  #alerMov {
    margin-top: 50%;
  }


  #ResponsiveCss {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    //fixed
    // border: 2px solid #F44336;

  }

}
</style>
