<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <tr max-height="48px" height="48px"  >
      
<ContenidoBase 
  :titulo="'Reporte Cumpleaños de Clientes'"
  :descripcion="'Imprime los Cumpleaños de Clientes.'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
 
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>

            <!-- <BarraBusqueda2/> -->

          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 <!-- Formulario-Cumpleaños de Clientes -->

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;"> Reporte Cumpleaños de Clientes </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Mes cumple  @blur="SelctCbClient"  @focus="cargarCliente"-->
<v-combobox
                        autocomplete="off"
                        dense
                        outlined
                        label="Mes de Cumpleaños:"
                        :items="RMesCumple"
                        item-text="descripcion"
                        v-model="CumpleañosClientes.Mescumple"
                            
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-calendar-o"
                          ></i>
                        </template>
                      </v-combobox>
 
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">


<!-- ----------------------------- Tipo de Cliente -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Tipo de Cliente:"
            :items="RtipoCliente"
            item-text="descripcion"
            v-model="CumpleañosClientes.tipocliente"
            @blur="SelctCbTipoCliente"
            @focus="cargarTipoCliente"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-users"></i>
            </template>
          </v-combobox>


</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="CumpleañosClientes"
/>


  </v-card>
 </v-container>
 <!-- fn Formulario-Cumpleaños de Clientes -->
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>






<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() { 
    var json = { 
      titulo: "Reporte Cumpleaños de Clientes",
      descripcion: "Imprime los Cumpleaños de Clientes.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [  
        "ReporteCumpleañosClientesNew",
        "ReporteCumpleañosClientesSave",
        "ReporteCumpleañosClientesRemove",
        "ReporteCumpleañosClientesAnular",
        "ReporteCumpleañosClientesPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReporteCumpleañosClientesNew", this.new);
    // EventBus.$on("ReporteCumpleañosClientesSave", this.save);
    // EventBus.$on("ReporteCumpleañosClientesRemove", this.remove);
    // EventBus.$on("ReporteCumpleañosClientesAnular", this.remove);
    // EventBus.$on("ReporteCumpleañosClientesPrintList", this.imprimirList);
    // EventBus.$on("emitReporteCumpleañosClientes", this.datostabla);
  

    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({

windowSize:{width:"auto", height:"auto"},
      RtipoCliente:[],
      RMesCumple:[
   {codigo: 1 ,descripcion:'ENERO'},
   {codigo: 2 ,descripcion:'FEBRERO'},
   {codigo: 3 ,descripcion:'MARZO'},
   {codigo: 4 ,descripcion:'ABRIL'},
   {codigo: 5 ,descripcion:'MAYO'},
   {codigo: 6 ,descripcion:'JUNIO'},
   {codigo: 7 ,descripcion:'JULIO'},
   {codigo: 8 ,descripcion:'AGOSTO'},
   {codigo: 9 ,descripcion:'SEPTIEMBRE'},
   {codigo: 10 ,descripcion:'OCTUBRE'},
   {codigo: 11 ,descripcion:'NOVIEMBRE'},
   {codigo: 12 ,descripcion:'DICIEMBRE'},
],

  
      CumpleañosClientes: {
        nombre:'Cumpleaños de Clientes',
        UrlImprimir: '/reportescxc/ImprimirCumpleClient', 
        UrlExel: '/reportescxc/ExcelCumpleClient', 
        UrlCorreo: '/reportescxc/CorreoCumpleClient', 
        Fecha: new Date().toISOString().substr(0,10),
        tipocliente:null,
        Mescumple:null
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },
EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },
     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
   
    save(){},
    remove(){},
    anular(){},
    imprimirList(){},
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico(){},
   
    newwnuevo() {
      this.CumpleañosClientes={
        Fecha: new Date().toISOString().substr(0,10),
        tipocliente:null,
        Mescumple:null
      }
       
    },



// Un método que se llama cuando el usuario sale del cuadro combinado.
cargarTipoCliente(){

this.$axios.get(this.$hostname+this.$hName+'/tipoclientes/findall',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RtipoCliente=res.data))
},


// Un método que se llama cuando el usuario sale del cuadro combinado.
SelctCbTipoCliente(){

 if(this.CumpleañosClientes.tipocliente==null){

    this.CumpleañosClientes.tipocliente=''
  }

if(this.CumpleañosClientes.tipocliente.codigo==undefined){

this.alerta("Seleccione un tipo de cliente", "error");
this.CumpleañosClientes.tipocliente=''
return

}

},







    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },



  }
};
</script>

<style></style>
