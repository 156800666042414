<template>
 <div>{{GetEjecucion}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >

  <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Crear Vehículos'"
  :descripcion="'Para Registrar las Fichas de los Vehículos de Transporte'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  
  
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle></v-btn-toggle>
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>

<BarraDinamicaNormal
              :url="$hostname + $hName + '/vehiculo/findall/'"
              :nombre="'Fichas de los Vehículos'"
              :SizeNom="24"
              :emitnombre="'emitTranspVehiculo'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'id'},
        { text: 'Descripción', value: 'descripcion'},
        { text: 'Marca', value: 'marca'},
        { text: 'Modelo', value: 'modelo'},
        { text: 'Ficha', value: 'ficha'},
        { text: 'Capacidad', value: 'capacidad'}
           ]"
              :ContListSlot="[

              {Text:'', NomValue:'fotoVehiculo', Ctsl:1, Type:3, State:true},
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Marca', NomValue:'marca', Ctsl:1, Type:0, State:true},
              {Text:'Modelo', NomValue:'modelo', Ctsl:1, Type:0, State:true},
              {Text:'Ficha', NomValue:'ficha', Ctsl:1, Type:0, State:true},
              {Text:'Capacidad', NomValue:'capacidad', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ]"
            />



            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitTranspVehiculo'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>



      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container><!-- style="border: 1px solid #000000;" -->
    
  <v-form ref="formVehiculo" @submit.prevent=""  v-model="valid" lazy-validation>

 <v-row>
<!-- columna 1 ----------------------------------------------->
                <v-col cols="12" md="12" sm="12">
                  <v-text-field
                    v-model="Vehiculo.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="* Descripción:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>
                </v-col>
<!-- fn columna 1 ----------------------------------------------->
</v-row>
<!--  columna 2 ----------------------------------------------->
<v-row style="margin-top:-23px" >
                <v-col cols="12" md="4" sm="4"  >
                  <VueAutonumeric 
                      label="Capacidad:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      :rules="[$rules.required, $rules.NumNocero]"
                      outlined
                      dense
                      v-model="Vehiculo.capacidad"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-sort-numeric-asc"></i>
                      </template>
                    </VueAutonumeric>


                    <v-text-field
                    v-model="Vehiculo.modelo"
                    autocomplete="off"
                    label="Modelo:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-bus"></i>
                    </template>
                  </v-text-field>
                </v-col>
<!-- fn columna 2 ----------------------------------------------->
<!--  columna 3 ----------------------------------------------->
                <v-col cols="12" md="4" sm="4">

                  <v-text-field
                    v-model="Vehiculo.placa"
                    autocomplete="off"
                    label="Placa:"
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="Vehiculo.chasis"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Chasis:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-barcode"></i>
                    </template>
                  </v-text-field>

                </v-col>
<!-- fn columna 3 ----------------------------------------------->
<!--  columna 4 ----------------------------------------------->
                <v-col cols="12" md="4" sm="4">

                  <v-text-field
                    v-model="Vehiculo.marca"
                    autocomplete="off"
                    label="Marca:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-bus"></i>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="Vehiculo.ficha"
                    autocomplete="off"
                    :rules="[$rules.required]"
                    label="Ficha:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-bus"></i>
                    </template>
                  </v-text-field>
                </v-col>
<!-- fn columna 4 ----------------------------------------------->
</v-row>

<v-row style="margin-top:-23px;">
<v-col cols="12" md="6" sm="6" >

  <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Propietario:"
            :items="Rpropietario"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="Vehiculo.propietario"
            @focus="cargarPropietario"
            @blur="SelctCbPropietario"  
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-street-view"></i>
            </template>
          </v-combobox>
</v-col>

<v-col cols="12" md="6" sm="6">

  <!--@focus="cargarRutaSucursales" @blur="SelctCbSucursalSalida"  -->
  <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Conductor:"
            :items="Rconductor"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="Vehiculo.conductor" 
            @focus="cargarConductor" 
            @blur="SelctCbConductor" 
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-car"></i>
            </template>
          </v-combobox>
</v-col> 
</v-row>


<v-row style="margin-top:-23px">
<v-col cols="12" md="4" sm="4">
<!-------------------------------------------  Valor en libros -->
  <VueAutonumeric 
                      label="Valor en libros:"
                      autocomplete="off"
                      style="font-size: 13px;"                     
                      outlined
                      dense
                      v-model="Vehiculo.valorLibros"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>
                    
<!-------------------------------------------  Valor Compra-->
                    <VueAutonumeric 
                      label="Valor Compra:"
                      autocomplete="off"
                      style="font-size: 13px;"                     
                      outlined
                      dense
                      v-model="Vehiculo.valorCompra"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>


                    <v-switch
                    label="Activo"
                    v-model="Vehiculo.activo"
                  ></v-switch>

</v-col>
<v-col cols="12" md="4" sm="4">
<!------------------------------------------- Depreciación Acumulada -->  
  <VueAutonumeric 
                      label="Depreciación Acumulada:"
                      autocomplete="off"
                      style="font-size: 13px;"  
                      outlined
                      dense
                      v-model="Vehiculo.despreciacionAcumulada"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-area-chart"></i>
                      </template>
                    </VueAutonumeric>

<!-- -----------------------------  fecha  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha:"
        v-model="Vehiculo.fechaCompra"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
  
</v-text-field>


</v-col>
<v-col cols="12" md="4" sm="4">
<!-- ----------------------------- Valor Actual -->
  <VueAutonumeric 
                      label="Valor Actual:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      outlined
                      dense
                      v-model="Vehiculo.valorActual"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>


<v-file-input       
        dense
        outlined  
        accept="image/png, image/jpeg, image/bmp"
        @change="ConvertirImg"
        ref="files"
        id="foto"
        show-size  
        counter           
        small-chips
        v-model="cargaImg"
        multiple
        prepend-icon="mdi-camera"
        label="Elegir Foto"
      >


<template v-slot:append-outer>

                  <v-tooltip top>
                <template v-slot:activator="{ on }"> 
                  
                <i style="font-size: 20px;" v-on="on" class="fa fa-eye"></i>
                </template>

<v-avatar  
                  size="230"
                  tile
                >
                  <v-img :src="Vehiculo.fotoVehiculo"></v-img>
             </v-avatar> 

                          <!-- <span>Buscar Solicitud</span> getImgUrl() -->
                        </v-tooltip> 
                        
                  </template>
      </v-file-input>

</v-col>

</v-row>

             
            </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'id', width:90},
        { text: 'Descripción', value: 'descripcion', width:300},
        { text: 'Marca', value: 'marca', width:300},
        { text: 'Modelo', value: 'modelo', width:300},
        { text: 'Ficha', value: 'ficha', width:300},
        { text: 'Chasis', value: 'chasis', width:300},
        { text: 'Capacidad', value: 'capacidad', width:300},
        { text: 'Placa', value: 'placa',width:300},
        { text: 'Propietario', value: 'propietario.nombre', width:300},
        { text: 'Conductor', value: 'conductor.nombre', width:300},
        { text: 'Despreciacion Ac', value: 'despreciacionAcumulada',width:300},
        { text: 'Valor Actual', value: 'valorActual', width:300},
        { text: 'Valor Compra', value: 'valorCompra', width:300},
        { text: 'Valor Libros', value: 'valorLibros', width:300},
        { text: 'Fecha', value: 'fechaCompra', width: 300},
        { text: 'Estado', value: 'activo'}
]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.despreciacionAcumulada="props">
                <span>{{ currencyFormatter(props.item.despreciacionAcumulada) }}</span>
              </template>
              <template v-slot:item.valorActual="props">
                <span>{{ currencyFormatter(props.item.valorActual) }}</span>
              </template>
              <template v-slot:item.valorCompra="props">
                <span>{{ currencyFormatter(props.item.valorCompra) }}</span>
              </template>
              <template v-slot:item.valorLibros="props">
                <span>{{ currencyFormatter(props.item.valorLibros) }}</span>
              </template>
              <template v-slot:item.fechaCompra="props">
                <span>{{ FormatoFecha(props.item.fechaCompra) }}</span>
              </template>
              <template v-slot:item.activo="props">
                <span v-if="props.item.activo==true" >Activo</span>
                <span v-if="props.item.activo==false" >Inactivo</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarVehiculo'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->
  

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    VueAutonumeric,
    ContenidoBase
    
  },
  created() {
    var json = { 
      titulo: "Crear Vehículos",
      descripcion: "Para Registrar las Fichas de los Vehículos de Transporte",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "TranspVehiculoNew",
        "TranspVehiculoSave",
        "TranspVehiculoRemove",
        "TranspVehiculoAnular",
        "TranspVehiculoPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspVehiculoNew", this.new);
    // EventBus.$on("TranspVehiculoSave", this.save);
    // EventBus.$on("TranspVehiculoRemove", this.remove);
    // EventBus.$on("TranspVehiculoAnular", this.remove);
    // EventBus.$on("TranspVehiculoPrintList", this.imprimirList);
    EventBus.$on("emitTranspVehiculo", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  }, 

  data:()=> ({
    

      Rpropietario:[], 
      Rconductor:[] ,
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

ejecucion:true,

cargaImg:null,
verImg:null,

Vehiculo: {
id: 0,
activo: true,
capacidad: 0,
chasis: null,
conductor: null,
propietario: null,
descripcion: null,
despreciacionAcumulada: 0,
fechaCompra: new Date().toISOString().substr(0,10),
ficha: null,
fotoVehiculo:null,
marca: "",
modelo: "",
placa: "",
valorActual: 0,
valorCompra: 0,
valorLibros: 0

      },

EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

    
      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,




      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      
    },
    datostabla(e) {

      console.log('Datos Datos')
      console.log(e)

      this.Vehiculo = e;
      this.acti_tb_data=false
    },
    save() {


if(this.$refs.formVehiculo.validate()) {

if(this.ejecucion==true){

  this.ejecucion=false

var Json=JSON.parse(JSON.stringify(this.Vehiculo)) 
 this.$axios
        .post(this.$hostname + this.$hName + "/vehiculo/save/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

}      
}else{
              this.alerta("los campos en rojo son requeridos", "error");
       }
      
},


NotifMessage() {

if(this.Vehiculo.id!=0 && this.Vehiculo.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarVehiculo"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un vehiculo para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {



var Json=JSON.parse(JSON.stringify(this.Vehiculo)) 
this.$axios
        .post(this.$hostname + this.$hName + "/vehiculo/borrado/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

    },
    anular() {
    },
    imprimirList() {

      window.open(this.$hostname+this.$hName+'/services/vehiculo/printlistvehiculo/')

    },
    newwnuevo() {

this.Vehiculo={
id: 0,
activo: true,
capacidad: 0,
chasis: null,
conductor: null,
propietario: null,
descripcion: null,
despreciacionAcumulada: 0,
fechaCompra: new Date().toISOString().substr(0,10),
ficha: null,
fotoVehiculo:null,
marca: "",
modelo: "",
placa: "",
valorActual: 0,
valorCompra: 0,
valorLibros: 0

      }
      
      this.cargaImg=null
     

        this.ejecucion = true;
        EventBus.$emit("actualizaBarraBusqueda2");
    },

getImgUrl() {


   //   var images = require("../assets/" + pet);
    var images
   if(this.Vehiculo.fotoVehiculo==null || this.Vehiculo.fotoVehiculo.length==0 ){
      images = require("@/assets/img/Nofoto.png");

   }else{
     if(this.Vehiculo.fotoVehiculo!=null || this.Vehiculo.fotoVehiculo.length >0 ){

       images=this.Vehiculo.fotoVehiculo

     }
   }
     //var images = require("https://cdn.vuetifyjs.com/images/cards/foster.jpg");
     // var images ="https://cdn.vuetifyjs.com/images/cards/foster.jpg"
      return images;
    },



ConvertirImg(e) {

      var _this = this;
     // var filesSelected = document.getElementById("foto").files;
     var filesSelected = e
      if (filesSelected.length > 0) {
        var fileToLoad = filesSelected[0];

        var fileReader = new FileReader();

        fileReader.onload = function(fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64

          var newImage = document.createElement("img");
          newImage.src = srcData;
          _this.Vehiculo.fotoVehiculo = srcData;

      
          // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
          // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
          // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        };
        fileReader.readAsDataURL(fileToLoad);
      }else{

            this.Vehiculo.fotoVehiculo=require("@/assets/img/Nofoto.png");

      }
    },



 cargarPropietario(){


var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/propietario/findallPropietarioActivos/",{headers:headers})
.then(res => {this.Rpropietario = res.data})
.catch(error => console.log(error));


 },
SelctCbPropietario(){

  if(this.Vehiculo.propietario==null){

    this.Vehiculo.propietario=''
  }

if(this.Vehiculo.propietario.id==undefined){

this.alerta("Seleccione una Llegada", "error");
this.Vehiculo.propietario=''
return

}

},
cargarConductor(){

var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/propietario/findallConductorActivos/",{headers:headers})
.then(res => {this.Rconductor = res.data})
.catch(error => console.log(error));



},

 SelctCbConductor(){

   if(this.Vehiculo.conductor==null){

    this.Vehiculo.conductor=''
  }

if(this.Vehiculo.conductor.id==undefined){

this.alerta("Seleccione una Llegada", "error");
this.Vehiculo.conductor=''
return

}

 },


FormatoFecha(params0) {
var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
if(number==null){number=0}
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

       this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },


async filtro() {
      var headers=this.$store.getters.GetheadersAxios
      
      await this.$axios.get(this.$hostname + this.$hName + "/vehiculo/findall/", {headers:headers})
        .then(res => {this.list = res.data;});
    }

  },
  computed:{

    GetEjecucion(){

// -------- ActDescBtn -----------
if(this.Vehiculo.id>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}


if(this.Vehiculo.id<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}
     
//EventBus.$emit("ActDesaBotones", objjct);
// -------- fn ActDescBtn -----------


// --------  Img -----------


   if(this.Vehiculo.fotoVehiculo==null || this.Vehiculo.fotoVehiculo.length==0 ){
     this.Vehiculo.fotoVehiculo = require("@/assets/img/Nofoto.png");
   }
   
  //  else{
  //    if(this.Vehiculo.fotoVehiculo!=null || this.Vehiculo.fotoVehiculo.length >0 ){

  //      this.verImg= this.Vehiculo.fotoVehiculo

  //    }
  //  }






// -------- fn Img -----------





    }

  }
};
</script>

<style></style>
