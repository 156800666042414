<template>
  <div>

<v-card>

 <v-toolbar
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> CREACIÓN DE DOCUMENTO </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarModalCrearDoc()"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-container>

  <v-form ref="formCrearDoc" v-model="valid" lazy-validation>

  <v-row>
 <!-- Documentos:-->
                      <v-col cols="12" md="12">
                        <v-text-field
                          label="* Descripción:"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="documento.descripcion"
                          autocomplete="off"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <!-- fn Documentos:-->

                    <!-- Factura:-->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          label="* ID.Documento:"
                          maxlength="5"
                          autocomplete="off"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="documento.nombrecorto"
                        >
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-list-alt"
                            ></i>
                          </template>
                        </v-text-field>


<!-------------------------------------------------- Sucursal -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Succursal"
            :items="RSucursal"
            item-text="descripcion"
            v-model="documento.sucursal"
            @blur="SelctCbSucursal"
            @focus="cargarSucursal"
           
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
 </v-combobox>




<v-switch
                        v-model="documento.activo"
                        label="Activo"
                        color="success"
                        hide-details
                      ></v-switch>

                      </v-col>

                      <v-col cols="12" md="6">

 <v-combobox
                    v-model="documento.operacion"
                    label="* Operación:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="ROperacion"
                    item-text="descripcion"
                    @blur="SelctCbOperacion"
                    @focus="CargarROperacion"
                  >  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class=" fa fa-line-chart"
                            ></i>
                          </template>
</v-combobox>




<v-text-field
                        type="number"
                        label="Secuencia Inicial"
                        dense
                        outlined
                        :rules="[$rules.required]"
                        v-model.number="documento.secuenciainicial"
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-barcode"></i>
                        </template>
                      </v-text-field>






                      <v-switch
                        v-model="documento.comprobante"
                        label="Generar Comprobante"
                        color="success"
                        hide-details
                      ></v-switch>

                        
                      </v-col>



  </v-row>
  </v-form>

<hr/>
<v-card-actions>
  <v-spacer></v-spacer>
 <v-btn-toggle rounded class="" >

            <v-btn color="green darken-1" @click.prevent="CrearDocumento">
              <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
              Salvar</v-btn>

          </v-btn-toggle>
</v-card-actions>
</v-container> 
</v-card>


 <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>

  </div>
</template>

<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
export default {

components: {
    AutoNumeric
  },
  mounted() { 
   
  },

  created() { 

  },

data(){

    return{

Rdepartamento:[],
RSucursal:[],
ROperacion:[],
valid: true,

ejecucion:true,


documento:{
  codigo: 0,
  descripcion:null,
  fechaCreacion: new Date().toISOString().substr(0, 10),
  nombrecorto: null,
  operacion: null,
  cantidadcopias: 1,
  secuenciainicial: 1,
  usuarioCreacion:"admin",
  usuarioModificacion:"admin",
  comprobante: false,
  activo: true,
  sucursal:null,
  cuenta:[]
  },
form:{

},



aalert: {
        estado: false,
        color: null
      },




    }

}, 


methods:{

alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.new();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },





NuevoDocumento(){

this.documento = {
        codigo: 0,
        descripcion: null,
        fechaCreacion: new Date().toISOString().substr(0, 10),
        nombrecorto: null,
        operaciones: null,
        cantidadcopias: 1,
        secuenciainicial: 1,
        usuarioCreacion: "admin",
        usuarioModificacion: "admin",
        comprobante: false,
        activo: true,
        sucursal:null,
        cuenta: []
      }
      
this.ejecucion = true;

},



CrearDocumento(){
 if (this.$refs.formCrearDoc.validate()) {


if(this.ejecucion==true){

  this.ejecucion=false

  var jsn={
     documento:JSON.parse(JSON.stringify(this.documento))
    ,usuario:JSON.parse(JSON.stringify(this.$user)) 
            
  }

this.$axios.post(this.$hostname + this.$hName + '/services/DocumentoPv/save',
            JSON.parse(JSON.stringify(jsn)))
             .then(res => this.alerta("Dato guardado correctamente", "bien"))
             .catch(error => this.alerta(error,'error'))
}


//this.$axios.post(this.hostname+this.hName + '/services/ConciliacionARS/Save',
// this.$axios.post(this.hostname+this.hName+'/services/proyectos/save',
//             JSON.parse(JSON.stringify(this.form.NewProyecto)))
//              .then(res => {this.alerta("Dato guardado correctamente", "bien"),  this.form.datosBasicos.proyecto=null, this.dialogProyect=!this.dialogProyect})
//              .catch(error => this.alerta(error,'error')) 

 }else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }


},

cerrarModalCrearDoc(){

this.NuevoDocumento()


var Objt={estado:false}

this.$emit("EmitCrearDocumento", Objt);


},


DSkeyuBenefic(e){
if(e.target.value.length!=0){
  this.form.crearProd.beneficio  = parseFloat(e.target.value);
  
}else{
  this.form.crearProd.beneficio=0
}
},

cargarDepartamento(){


this.$axios.post(this.$hostname +this.$hName +"/services/Especiales/getAllDepartamento",{})
        .then(res => (this.Rdepartamento = res.data));


},


SelctCbSucursal(){

 if(this.documento.sucursal==null){

    this.documento.sucursal=''
  }

if(this.documento.sucursal.codigo==undefined){

this.alerta("Seleccione un sucursal", "error");
this.documento.sucursal=''
return

}
},


cargarSucursal(){

this.$axios.post(this.$hostname+this.$hName+'/services/sucursal/findall',{
usuario:JSON.parse(JSON.stringify(this.$user))  
})
.then(res=>( this.RSucursal=res.data))

},


 SelctCbOperacion() {

      if (this.documento.operacion == null) {
        this.documento.operacion = "";
      }

      if (this.documento.operacion.codigo == undefined) {
        this.alerta("Seleccione una operacion", "error");
        this.documento.operacion = "";
        return;
      }

    },


CargarROperacion() {
   this.$axios.post(this.$hostname + this.$hName + '/services/DocumentoCxP/findallOperacionCxP', {
     usuario:JSON.parse(JSON.stringify(this.$user))  
   }).then(res => {
        this.ROperacion = res.data;
      });
    },







}




}
</script>

<style>

</style>