<template>
  <div>{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
           :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase
            :titulo="'Ventas por Vendedor'"
            :descripcion="'Imprime las Ventas Generadas por Vendedor'"
            :EstadoBtn="EstadoBtn"
            @abrirBarraTablaDinamica="abrirTabla"
            @MethodNuevo="newwnuevo"
            @MethodGuardar="save"
            @MethodEliminar="remove"
            @MethodAnular="anular"
            @MethodImprimir="imprimirList"

        />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                        small
                        color="yellow accent-4"
                        @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn
                    >
                    <v-btn
                        small
                        dark
                        color="deep-purple accent-2"
                        @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn
                    >
                  </v-btn-toggle>
                </center>
                <!--                <BarraBusqueda2-->
                <!--                    :url="-->
                <!--                $hostname + $hName + '/services/tipocliente/findalltipoclientes'-->
                <!--              "-->
                <!--                    :nombre="'Tipo de Clientes'"-->
                <!--                    :headers="headers"-->
                <!--                    :slotname="'item.descripcion'"-->
                <!--                    :emitnombre="'emitReportesVentasVendedor'"-->
                <!--                    :SeachNombre="'Búsqueda por descripcion '"-->
                <!--                    :slotlist="slotlist"-->
                <!--                />-->
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

<!-- Formulario-Ventas por Vendedor -->
            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar
                    flat
                    color="#BDBDBD"
                    dense
                    style="border: 1px solid #000000;"
                >


                  <v-avatar tile size="45">
                    <img
                        src="@/assets/iconos_micromarket/custom-reports.png"
                        alt="Reporte"
                    >
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;"> Reporte Ventas por Vendedor </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="cerrarmodalcuenta"
                  >
                   <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">


                        <!-- -----------------------------  desde  -->
                        <v-text-field
                            dense
                            outlined
                            type="date"
                            label="Desde:"
                            :rules="[$rules.required]"
                            v-model="VentasVendedor.desde"
                            @blur="BlurDesde"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>


                        <!-------------------------------------------------- Sucursal -->
                        <v-combobox
                            autocomplete="off"
                            dense
                            outlined
                            label="Sucursal:"
                            :items="RSucursal"
                            item-text="descripcion"
                            v-model="VentasVendedor.sucursal"
                            @focus="cargarSucursal"
                            @blur="SelctCbSucursal"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                          </template>
                        </v-combobox>

                        <v-combobox
                            autocomplete="off"
                            dense
                            outlined
                            label="Vendedor:"
                            :items="RVendedores"
                            item-text="nombre"
                            v-model="VentasVendedor.vendedor"
                            @focus="cargarVendedores"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sign-language"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  Hasta  -->
                        <v-text-field
                            dense
                            outlined
                            type="date"
                            :rules="[$rules.required]"
                            label="Hasta:"
                            v-model="VentasVendedor.hasta"
                            @blur="BlurHasta"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <v-combobox
                            autocomplete="off"
                            dense
                            outlined
                            label="Carlcular comisión:"
                            :items="RComision"
                            item-text="descripcion"
                            v-model="VentasVendedor.tipo"
                            @blur="SelctCbTipo"
                            @change="changeDsc"

                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                          </template>
                        </v-combobox>


                        <v-col cols="12" md="12" sm="12" >
                          <v-row no-gutters >
                            <v-col cols="12" md="6" sm="6">
                            <v-switch
                            class="mt-0"
                            color="teal"
                            inset
                            hide-details
                            label="Resumido:"
                            v-model="VentasVendedor.resumen"
                        >
                          <template v-slot:label>
                            <strong v-if="VentasVendedor.resumen==true" style="color:#000000;">Resumido: Si</strong>
                            <strong v-if="VentasVendedor.resumen==false" style="color:#000000;">Resumido: No</strong>
                          </template>
                        </v-switch>
</v-col>

<v-col cols="12" md="6" sm="6">
  <v-switch
v-if="$store.getters.GetdatosInfUsu.usuario.dsc"
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="DSC:"
              v-model="VentasVendedor.dsc"
              @change="changeDsc"
            >
          <template v-slot:label>
         <strong v-if="VentasVendedor.dsc==true" style="color:#000000;">DSC: Si</strong>
         <strong v-if="VentasVendedor.dsc==false" style="color:#000000;">DSC: No</strong>
        </template>
            </v-switch>
</v-col>
                          </v-row>
                        </v-col>



                       




                      </v-col>
                      <!-- fn columna 2 -->
                    </v-row>
                  </v-form>
                </v-container>
                <hr>
                <ModalReporteFiltros
                    @DataReporteBasico="DDataReporteBasico"
                    :Datos="VentasVendedor"
                />
              </v-card>
            </v-container>
<!-- fn Formulario-Ventas por Vendedor -->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

            <!--
                    <BarraBusqueda2
                          :url="$hostname + $hName + '/services/caja/findAll'"
                          :nombre="'Cajas'"
                          :headers="headers"
                          :slotname="'item.descripcion'"
                          :emitnombre="'emitCaja'"
                          :slotlist="slotlist"
                          :SeachNombre="'Búsqueda por descripción caja'"
                        /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
        label="myAlert"
        v-model="acti_tb_data"
        persistent
        max-width="100%"
        max-height="100%"
        transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
              color="grey darken-4"
              fab
              small
              @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                  v-model="search"
                  @keyup="filtro"
                  label="Buscar"
                  single-line
                  hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
                :headers="slotHtabla"
                dense
                :items="list"
                :search="search"
                @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
        label="myAlert"
        v-model="Elim_notif.estado"
        persistent
        max-width="350"
        transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr/>

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr/>
        <v-card-actions>
          <v-btn
              @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
              small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
        v-model="aalert.estado"
        :color="aalert.color"
        style="margin-top: 20%;"
        :vertical="true"
        :timeout="120000"
        top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import {EventBus} from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Ventas por Vendedor",
      descripcion: "Imprime las Ventas Generadas por Vendedor",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ReportesVentasVendedorNew",
        "ReportesVentasVendedorSave",
        "ReportesVentasVendedorRemove",
        "ReportesVentasVendedorAnular",
        "ReportesVentasVendedorPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("ReportesVentasVendedorNew", this.new);
    EventBus.$on("ReportesVentasVendedorSave", this.save);
    EventBus.$on("ReportesVentasVendedorRemove", this.remove);
    EventBus.$on("ReportesVentasVendedorAnular", this.remove);
    EventBus.$on("ReportesVentasVendedorPrintList", this.imprimirList);
    EventBus.$on("emitReportesVentasVendedor", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data: () => ({

    // tipocomicion.add(new ComboUtil("Selección", 0));
    // tipocomicion.add(new ComboUtil("Comisión por venta", 1));
    // tipocomicion.add(new ComboUtil("Comisión por servicio", 2));

    RVendedores: [],
    RSucursal: [],
    RComision: [
      {codigo: 0, descripcion: "Selección"},
      {codigo: 1, descripcion: "Comisión por venta"},
      {codigo: 2, descripcion: "Comisión por servicio"},
    ],
    RCaja: [],

    ListaTiposClientes: [],
    windowSize: {width: "auto", height: "auto"},
    VentasVendedor: {
      desde: fechaNueva(),
      hasta: fechaNueva(),
      resumen: true,
      tipo: null,
      sucursal: null,
      vendedor: null,
      nombre: 'REPORTE VENTAS POR VENDEDOR',
      UrlImprimir: '/reportes-pv/ImprimirListVentasPorVendedor',
      UrlExel: '/reportes-pv/ExcelListVentasPorVendedor',
      UrlCorreo: '/reportes-pv/CorreoListVentasPorVendedor',
      dsc:false
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },


    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{text: "Tipo Clientes", value: "descripcion"}],

    slotlist: [
      {dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:"},
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      {dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo"},
    ],

    slotHtabla: [
      {text: "Código", value: "codigo"},
      {text: "Descripcion", value: "descripcion"}
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
    },
    datostabla(e) {
      this.VentasVendedor = e;
      this.acti_tb_data = false
    },
    save() {
    },
    remove() {
    },
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {
    },


    // Llamar a la API y obtener los datos de la API.
    cargarSucursal() {
      let headers = this.$store.getters.GetheadersAxios
      this.$axios
          .get(this.$hostname + this.$hName + '/sucursal/findallActivos/', {headers})
          .then(res => (this.RSucursal = res.data))

    },

    cargarVendedores() {
      let headers = this.$store.getters.GetheadersAxios
      this.$axios
          .get(this.$hostname + this.$hName + '/vendedores/findallactiv', {headers})
          .then(res => (this.RVendedores = res.data))
    },

   // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbSucursal() {
      if (this.VentasVendedor.sucursal == null) {
        this.VentasVendedor.sucursal = ''
      }

      if (this.VentasVendedor.sucursal.codigo == undefined) {
        this.alerta("Seleccione un sucursal", "error");
        this.VentasVendedor.sucursal = ''
        return

      }
    },

    SelctCbTipo() {
var _this = this;
      setTimeout(function () {
        if (_this.VentasVendedor.tipo == null) {
          _this.VentasVendedor.tipo = "";
        }

        if (!_this.VentasVendedor.tipo.hasOwnProperty('codigo')) {
          _this.VentasVendedor.tipo = "";
          _this.VentasVendedor.tipo = null;
          return;
        }
      }, 300);
},

BlurDesde() {

if (this.VentasVendedor.desde.toString().length == 0) {
  this.VentasVendedor.desde = this.fechaNueva()
}


},


BlurHasta() {

if (this.VentasVendedor.hasta.toString().length == 0) {
  this.VentasVendedor.hasta = this.fechaNueva()
}

},

changeDsc() {

var _this = this;
      setTimeout(function () {
        if (_this.VentasVendedor.tipo.codigo==2) {
          _this.VentasVendedor.dsc=false;
        }
      }, 300);
},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    },
//retorna datos del componente  reporte
    DDataReporteBasico() {

    }
  },

  computed: {


ActDescBtn() {
  this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

  if(this.VentasVendedor.tipo==null){
    this.VentasVendedor.tipo=this.RComision[0]
  }

  //EventBus.$emit("ActDesaBotones", objjct);
}
}

 

};
</script>

<style></style>
