<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  <!-- ContenidoBase-Denominaciones de Moneda -->
<tr max-height="48px" height="48px"  >   
<ContenidoBase 
  :titulo="'Denominaciones de Moneda'"
  :descripcion="'Crear, Modificar y Desactivar Denominación de Moneda'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
<!-- fn ContenidoBase-Denominaciones de Moneda -->
    <tr>
<!-- BarraPrimaria-Denominaciones de Moneda --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
         <v-tooltip left>
          <template v-slot:activator="{ on }">
              <!--------------------- aqui va la otra tabla izquierda --------->
              <!-- <v-navigation-drawer >  -->
              <center>
  <!-- <v-btn-toggle  ></v-btn-toggle> -->
        <v-btn small width="95%"  color="yellow accent-4"    @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;" class="fa fa-eye"> </i>  ver</v-btn>
      
</center>


<BarraDinamicaNormal
              ref="BarraDiNor" 
              :url="$hostname + $hName + '/denominacionmoneda/findall/'"
              :nombre="'Denominaciones de monedas'"
              :emitnombre="'emitDenMonedas'"
              :SeachNombre="'Búsqueda general'"
              :SizeNom="20"
              :headers="[
        { text: 'Código', value: 'codigo' },
        { text: 'Descripción', value: 'descripcion' }, 
        { text: 'Valor', value: 'valor' },
        { text: 'Estado', value: 'activa' }
           ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Descripción', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Valor', NomValue:'valor', Ctsl:1, Type:2, State:true},
              {Text:'Estado',NomValue:'activa', Ctsl:1, Type:0.1, State:true},
              ]"
            />

            <!-- <v-navigation-drawer >  -->
            <!-- <BarraBusqueda2
              :url="$hostname + $hName +'/services/denominacionmoneda/findall'"
              :nombre="'Denominaciones de monedas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitDenMonedas'"
              :SeachNombre="'Búsqueda por descripción de Moneda'"
              :slotlist="slotlist"

            /> -->
              <!-- </v-navigation-drawer> -->
          </template>
        </v-tooltip>
     
      </div>
      </td>
<!-- fn BarraPrimaria-Denominaciones de Moneda ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
<div class="px-2">
  

<v-card
 color="grey lighten-3"
  >
<div class="px-2">
  
<!-- Formulario-Denominaciones de Moneda   -->

 <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>

<input
                  type="number"
                  v-model="denominacionesMoneda.codigo"
                  style="display:none;"
                />



<!-- fila 1 ------------------------------>


<!-- Descripción:-->
  <v-col cols="12" md="12" sm="12">

  <v-text-field label="* Descripción:" dense outlined
  autocomplete="off"
  :rules="[$rules.required]"
  v-model="denominacionesMoneda.descripcion"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-font"></i>
    </template>
  </v-text-field>

</v-col>
<!-- fn Descripción:-->


<!-- valor-->
<v-col cols="12" md="6" sm="6">
 <Autonumeric label="* Valor:" dense outlined
  :rules="[$rules.required]"
  autocomplete="off"
  v-model.number="denominacionesMoneda.valor"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-money"></i>
    </template>
  </Autonumeric>
</v-col>
<!-- fn valor -->

<!-- fn fila 1 ------------------------------>


<!-- fila 2 ------------------------------>

<!-- Activo:-->
 <v-col cols="12" md="3" sm="3">
<v-switch
              v-model="denominacionesMoneda.activa"
              label="Activo"
              color="success"
              hide-details
            ></v-switch>
</v-col>
<!-- fn Activo-->


 <!-- fn fila2 -------------------------->

</v-row>
  </v-form>
  <!-- Formulario-Denominaciones de Moneda   -->
</div>
</v-card>


  
</div>   

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
 <!-- ------------------------------------------------------ tb-data todo general-->
<v-dialog 
 label="myAlert" v-model="acti_tb_data" persistent 
 transition="dialog-bottom-transition"
 fullscreen hide-overlay
>
 <v-card>

 <v-toolbar
 dense
        flat
        color="#BDBDBD"
      >
        
    <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>  
        <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
    <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

        <v-spacer></v-spacer>
        <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on" style="display:none;"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="acti_tb_data = !acti_tb_data"
        >
         <i  style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>


 <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          @keyup="filtro"
          label="Buscar"
          single-line
          hide-details
         
        >
        <template v-slot:prepend-inner>
                          <i style="font-size: 35px;" class="fa fa-search"></i>
                        </template>
    
        </v-text-field>
      </v-card-title>

      <v-data-table
        :headers="[
        { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Descripción', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] }, 
        { text: 'Valor', value: 'valor', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Estado', value: 'Estado', sortable:false, class: ['no-gutters black--text'] }
      ]"
        dense
        :items="list"
        :search="search"
        @click:row="datostabla"
        :items-per-page="15"
      >
    
    <template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.valor)}}</span>
    </template>

 <template v-slot:item.Estado="props">

   <span v-if="props.item.activa==true">Activo</span>
    <span v-if="props.item.activa==false">Desactivo</span>
    </template>


    

      </v-data-table>
    </v-card>


</v-card-text>
 
</v-card>


</v-dialog>
<!-- ------------------------------------------------------ fn tb-data todo general-->


<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminardenocion'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

            <!-- ------------------------------------------------------   alerta -->
            <v-snackbar
              v-model="aalert.estado"
              :color="aalert.color"
              style="margin-top: 20%;"
              :vertical="true"
              :timeout="120000"
              top="top"
            >
              {{ aalert.nombre }}
              <v-btn dark text @click="aalert.estado = false">Close</v-btn>
            </v-snackbar>
            <!-- ------------------------------------------------------  fn alerta -->

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

  

</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import Autonumeric from "../../components/AutoNumericNuevo";
import {EventBus} from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
export default {

   components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase

  },

mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitDenMonedas", this.datostabla);
    // EventBus.$on("DenMonedasNew", this.new);
    // EventBus.$on("DenMonedasSave", this.save);
    // EventBus.$on("DenMonedasRemove", this.remove);
    // EventBus.$on("DenMonedasAnular", this.anular);
    // EventBus.$on("DenMonedasPrintList", this.imprimirList);
    this.filtro()



  },
   created(){
 var json = {
      titulo:"Denominaciones de Moneda",
      descripcion: "Crear, Modificar y Desactivar Denominación de Moneda",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "DenMonedasNew",
        "DenMonedasSave",
        "DenMonedasRemove",
        "DenMonedasAnular",
        "DenMonedasPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  destroyed(){
   // location.reload()
  },


data:()=> ({

   windowSize:{width:"auto", height:"auto"},

Rrutas:[
{   codigo:0,
    desc:'1'
},
{   codigo:1,
    desc:'2'
},
{   codigo:2,
    desc:'3'
},
{   codigo:3,
    desc:'4'
},
{   codigo:5,
    desc:'5'
},

]

,valid:true,

denominacionesMoneda:{
codigo:0,
descripcion:null,
valor:0,
activa:true,
},

 EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },




   headers: [{ text: "Denominaciones", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "descripcion", dt1: null, dt2: null, dt3: 1, dt4: "Descripción:" },
        { dt0: "valor", dt1: null, dt2: null, dt3: 5, dt4: "Valor:" },
        { dt0: "activa", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],
   


list: [],
      search: "",
      acti_tb_data:false,

  ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


       BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
        

}),


methods: {




currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },


   // Llamando al método filtro() del componente BarraDiNor y abre un modal con los datos.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
      this.$refs.BarraDiNor.list=[]
      this.$refs.BarraDiNor.filtro()

          }

    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },


// restablece el formulario denomicacion moneda.
newwnuevo() {

this.BarraTablaDinamicaAprir = false
this.denominacionesMoneda={
codigo:0,
descripcion:null,
valor:0,
activa:true,
},

this.ejecucion = true
EventBus.$emit("actualizaBarraBusqueda2")
  
    },

// llamada a una api y guardando los datos de la moneda.
save() {

 
  if(this.$refs.form.validate()) {


if(this.ejecucion==true){

  this.ejecucion=false
   
var datos=JSON.parse(JSON.stringify(this.denominacionesMoneda)) 
this.$axios
        .post(this.$hostname + this.$hName + "/denominacionmoneda/save/",datos,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));
}      
}else{
              this.alerta("los campos en rojo son requeridos", "error");
       }

},



// Crear un objeto de notificación y luego llamar a la función Exec_notif().
NotifMessage() {

if(this.denominacionesMoneda.codigo!=0 && this.denominacionesMoneda.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminardenocion"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una caja para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },

eliminar() {
if(this.denominacionesMoneda.codigo!=0 && this.denominacionesMoneda.codigo!=null){
 if(this.$refs.form.validate()) {

var datos=JSON.parse(JSON.stringify(this.denominacionesMoneda)) 

this.$axios
        .post(this.$hostname + this.$hName + "/denominacionmoneda/borrado/",datos,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));
       
       } 
}

},

anular(){

},
// Abriendo una nueva ventana y escribiendo el pdf en ella.
imprimirList(){

      var headers = this.$store.getters.GetheadersAxios;

      this.$axios
        .get(this.$hostname + this.$hName + "/denominacionmoneda/imprimir/", {
          headers: headers,
        })
        .then((res) => {
          console.log("RespuestaImprimir"), console.log(res.data);
          /*let pdfWindow = window.open("Reporte de imprecion");
          pdfWindow.document.write(
            "<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
              encodeURI(res.data) +
              "'></embed>"
          );*/
          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'denominacion moneda');
        });

},


// Un método que se llama cuando se cargan los datos.
datostabla(e) {
this.denominacionesMoneda = e;

this.acti_tb_data=false;

//console.log(e)
    },

// Un método que se llama cuando se hace clic en un botón y despliega un modal mostrando datos en una tabla.
actualiza_tb_ver(){

this.acti_tb_data = !this.acti_tb_data
this.filtro()

},

// Llamar a una API y obtener una lista de datos.
async filtro() {
      var headers=this.$store.getters.GetheadersAxios
      
      await this.$axios.get(this.$hostname + this.$hName + "/denominacionmoneda/findall/", {headers:headers})
        .then(res => {this.list = res.data;});
    }

    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },
computed:{

    ActDescBtn(){


if(this.denominacionesMoneda.codigo>0){

 this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }

  // var objjct = {
  //     save: true,
  //     eliminar: true,
  //     anular: false,
  //     nuevo: true,
  //     imprimir: true,
  //     vercuenta:false, 
  //   };

}


if(this.denominacionesMoneda.codigo<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }



// var objjct = {
//       save: true,
//       eliminar: false,
//       anular: false,
//       nuevo: true,
//       imprimir: true,
//       vercuenta:false, 
//     };


}
     
 this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))     
//EventBus.$emit("ActDesaBotones", objjct);
    }





  }

}
</script>

<style>

</style>