<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">

        <ContenidoBase :titulo="'Historial de consumo del cliente'" :descripcion="'Imprime historial de consumo del cliente.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>

                <!-- <BarraBusqueda2/> -->

              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

<!-- Formulario-Historial de consumo del cliente-->
            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>


                  <span class="font-weight-light" style="font-size: 20px;"> Reporte historial de consumo del cliente </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="HistorialClientes.desde"
        @blur="BlurDesde">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
                        <!-- -----------------------------  Mes cumple  @blur="SelctCbClient"  @focus="cargarCliente"-->
                        <v-combobox autocomplete="off" dense outlined label="Cliente:" :rules="[$rules.required]" :items="Rcliente"
                          item-text="codnom" @keyup="CbFilcliente" v-model="data.cliente" @blur="SelctCbClient"
                          @focus="cargarCliente" no-filter>

                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-user-circle-o"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">


<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="HistorialClientes.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

                        <!-- ----------------------------- Tipo de Cliente -->
                        <!-- <v-combobox autocomplete="off" dense outlined label="Tipo de Cliente:" :items="RtipoCliente"
                          item-text="descripcion" v-model="CumpleañosClientes.tipocliente" @blur="SelctCbTipoCliente"
                          @focus="cargarTipoCliente">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-users"></i>
                          </template>
                        </v-combobox> -->


                      </v-col>
                      <!-- fn columna 2 -->

                    </v-row>

                  </v-form>
                </v-container>

                <hr>

                <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico"
                  :Datos="HistorialClientes" />


              </v-card>
            </v-container>
<!-- fn Formulario-Historial de consumo del cliente-->
          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->


    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>






    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Historial de consumo del cliente",
      descripcion: "Imprime el historial de consumo del cliente.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  // mounted-carga de inicio
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReporteCumpleañosClientesNew", this.new);
    // EventBus.$on("ReporteCumpleañosClientesSave", this.save);
    // EventBus.$on("ReporteCumpleañosClientesRemove", this.remove);
    // EventBus.$on("ReporteCumpleañosClientesAnular", this.remove);
    // EventBus.$on("ReporteCumpleañosClientesPrintList", this.imprimirList);
    // EventBus.$on("emitReporteCumpleañosClientes", this.datostabla);

    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;
    EventBus.$on("onResize", this.onResize);
  },
 // fn mounted-carga de inicio
  data: () => ({

    windowSize: { width: "auto", height: "auto" },
    Rcliente: [],
    RMesCumple: [
      { codigo: 1, descripcion: 'ENERO' },
      { codigo: 2, descripcion: 'FEBRERO' },
      { codigo: 3, descripcion: 'MARZO' },
      { codigo: 4, descripcion: 'ABRIL' },
      { codigo: 5, descripcion: 'MAYO' },
      { codigo: 6, descripcion: 'JUNIO' },
      { codigo: 7, descripcion: 'JULIO' },
      { codigo: 8, descripcion: 'AGOSTO' },
      { codigo: 9, descripcion: 'SEPTIEMBRE' },
      { codigo: 10, descripcion: 'OCTUBRE' },
      { codigo: 11, descripcion: 'NOVIEMBRE' },
      { codigo: 12, descripcion: 'DICIEMBRE' },
    ],
    datos: {

    },

    HistorialClientes: {
      nombre:'Historial de consumo del cliente',
      titulo: 'Historial de consumo del cliente',
      UrlImprimir: '/reportescxc/ImprimirHistorialConsumo',
      UrlExel: '/reportescxc/ExcelHistorialConsumo',
      UrlCorreo: '/reportescxc/CorreoHistorialConsumo',
      cliente: 0,
      desde: fechaNueva(),
        hasta: fechaNueva(),
      
      //fechaCreacion: new,
      //  fechaModificacion: "", 
    },
    data:{
      cliente:null
    },
    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: true,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },

    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

  FormatoFecha,
  fechaNueva,
    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },
    // Validando el formulario y luego configurando el valor de la propiedad cliente del objeto
    // HistorialClientes al valor de la propiedad codigo del objeto data.cliente.
    validarObjecto() {

      if (!this.$refs.form.validate()) {
        this.$refs.ModRepFilt.detener = true;
        return;
      } else {
        this.$refs.ModRepFilt.detener = false;
      }

      var WHERE= ` where a.fecha>='${this.HistorialClientes.desde}' and a.fecha<='${this.HistorialClientes.hasta}'`
      var condicion=`DESDE: ${this.FormatoFecha(this.HistorialClientes.desde)}                HASTA: ${this.FormatoFecha(this.HistorialClientes.hasta)}`

      var nomcli = "";

      if (this.data.cliente != null) {
        nomcli = this.data.cliente.nombre;
        this.HistorialClientes.cliente = this.data.cliente.codigo;
      }
      this.HistorialClientes.nombrecliente = nomcli;
      this.HistorialClientes.where=WHERE
      this.HistorialClientes.condicion=condicion
      
    },
    // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarCliente() {

      this.$axios.get(this.$hostname + this.$hName + '/clientes/findtodo/a/3/25/0', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rcliente = res.data))

    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      //console.log(e.target.value)

      if (e.target.value.length >= 0) {

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/3/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rcliente = res.data));
      }

    },
   // Comprobando si el cliente es nulo o indefinido.
    SelctCbClient() {
      if (this.data.cliente == null) {
        this.data.cliente = "";
      }

      if (this.data.cliente.codigo == undefined) {
        //  this.alerta("Seleccione un cliente", "error");
        this.data.cliente = "";
        return;
      }

    },
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },


    save() { },
    remove() { },
    anular() { },
    imprimirList() { },
    // Retorna los datos del componente ModalReporteFiltros
    DDataReporteBasico() { },

    newwnuevo() {


      this.data={
      cliente:null
    }

    },


 // Un método que se llama cuando la entrada pierde el foco
 // y valida si el campo esta nulo, si lo esta coloca la fecha actual.
 BlurDesde(){

if(this.HistorialClientes.desde.toString().length==0){
  this.HistorialClientes.desde=this.fechaNueva()
}


 },

 // Un método que se llama cuando la entrada pierde el foco
 // y valida si el campo esta nulo, si lo esta coloca la fecha actual.
 BlurHasta(){

if(this.HistorialClientes.hasta.toString().length==0){
  this.HistorialClientes.hasta=this.fechaNueva()
}

 },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },



  }
};
</script>

<style>

</style>
