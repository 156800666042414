<template>
  <div id="ResponsiveCss">{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Transferencias'" :descripcion="'Realizar Transferencias de productos entre almacenes.'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>

                  </v-btn-toggle>
                </center>
                <!-- <v-navigation-drawer >  -->


                <!-- [
              { text: 'Código', value: 'secuencia' },
              { text: 'cliente', value: 'cliente.nombre' },
              { text: 'valor', value: 'valor' },
              { text: 'concepto', value: 'concepto' },
             /* { text: 'Nombre', value: 'nombre' },
              { text: 'Balance', value: 'balancecliente.balance' }*/
              ]
              [
              {Text:'Código',NomValue:'secuencia', Ctsl:1, Type:0, State:true},
              {Text:'Nombre',NomValue:'cliente', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Valor $', NomValue:'valor',  Ctsl:1, Type:2, State:true},
              {Text:'concepto', NomValue:'concepto',  Ctsl:1, Type:0, State:true},
              {Text:'fecha', NomValue:'fecha',  Ctsl:1, Type:1, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ] -->

                <BarraDinamicaLimitePlus :PropsNombre="'Tranferencias'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'movimientosinventarioPK.secuencia' },
                 /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{Text:'Secuencia',NomValue:'transferenciasPK',Nv2:'secuencia', Ctsl:2, Type:0, State:true},
                  {Text:'Documento',NomValue:'documentoinventario', Nv2:'descripcion',Ctsl:2, Type:0, State:true},
                  {Text:'Fecha',NomValue:'fecha', Ctsl:1, Type:1, State:true},
                  { Text: 'hora', NomValue: 'hora', Ctsl: 1, Type: 0, State: true },
                  {Text:'Referencia', NomValue:'referencia',  Ctsl:1, Type:0, State:true},
                  {Text:'Concepto', NomValue:'concepto',  Ctsl:1, Type:0, State:true},
                  {Text:'Valor $', NomValue:'valor',  Ctsl:1, Type:2, State:true},
                  {Text:'Pendiente',NomValue:'pendiente', Ctsl:1, Type:0.4, State:true},
                  {Text: 'Estado',NomValue: 'activada',Ctsl: 1,Type: 0.2,State: true, },
                  { Text: 'Fecha Anulada', NomValue: 'fechaAnulacionNuevo', Ctsl: 1, Type: 1, State: true },
{ Text: 'hora Anulación', NomValue: 'horaAnulacion', Ctsl: 1, Type: 0, State: true },
{ Text: 'Usuario Anulación ', NomValue: 'usuarioAnulacion', Ctsl: 1, Type: 0, State: true },
          
                  ]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="Tranferencia.transferenciasPK.secuencia" style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="5" sm="5">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="* Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[rules.required]" style="font-size:13px;" placeholder=" "
                          v-model="Tranferencia.documentoinventario" @blur="SelctCbDocumento" @focus="cargarDocumento"
                          @change="changeDocumento">

                          <template v-slot:selection="{ attrs, item, parent, selected}">  
                         <span v-if="item.hasOwnProperty('codigo')">
                          <span class="black--text" >{{item.descripcion}}</span>
                        </span> 
                        </template>

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-book"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">

                                <v-btn @click.prevent="dialogCrearDocumento=true" v-on="on" color="green" fab x-small
                                  dark>
                                  <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                                </v-btn>

                              </template>
                              <span>Creación de documento</span>
                            </v-tooltip>


                          </template>
                        </v-combobox>

                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                      <!-- columna 2 ------------------------------>
                      <v-col cols="12" md="2" sm="2">

                        <!----------------------------------------------  Fecha entrada -->
                        <v-text-field dense outlined type="date" label="* Fecha:" :rules="[rules.required]"
                          v-model="Tranferencia.fecha" style="font-size:12px;">

                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- columna 2 ------------------------------>

                      <!-- columna 3 ------------------------------>
                      <v-col cols="12" md="2" sm="2">

                        <!-- Factura No --------------------------------------------------->
                        <v-text-field label=" No. de Referencia:" dense outlined v-model="Tranferencia.referencia"
                          style="font-size:13px;" placeholder=" ">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- columna 3 ------------------------------>

                      <v-col cols="12" md="3" sm="3">
                        <!-------------------------------------------------- Comentario -->
                        <v-textarea autocomplete="off" label="* Concepto:" rows="1" dense outlined
                          v-model="Tranferencia.concepto" style="font-size:13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>

                    </v-row>
                  </v-form>

                </div>
                <v-col cols="12" md="12" sm="12" style="margin-top:-35px;">
                  <!-- Tabla De producto  ------------------------------>
                  <TablaProductoInventario ref="RefTablaProducInvent" :ListProd="ListProd" :EntradaAlmacen="almacen" :PropsTranferencia="true"
                    @DataTotalList="DDataTotalList" />
                  <!-- fn Tabla De producto      ------------------------------>

                </v-col>
              </v-card>
            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->

    <!-- [
      { text: "Secuencia", value: "transferenciasPK.secuencia" },
      { text: "Documento", value: "documentoinventario.descripcion" },
      { text: "Referencia", value: "referencia" },
      { text: "Concepto", value: "concepto" },
      { text: "Fecha", value: "fecha" },
      { text: "Porciento", value: "porciento" }
    ] -->

    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por código"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
                <template v-slot:append-outer>
                  <v-btn color="teal darken-2" dark small
                    @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                    <i style="font-size: 25px;" class="fa fa-search"> </i>
                    Filtros
                  </v-btn>
                </template>


              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
             { text: 'Secuencia', value: 'transferenciasPK.secuencia', sortable:false, class: ['no-gutters black--text'] },
             { text: 'Documento', value: 'documentoinventario.descripcion', sortable:false, class: ['no-gutters black--text'] },
             { text: 'Referencia', value: 'referencia', sortable:false, class: ['no-gutters black--text'] },
             { text: 'Concepto', value: 'concepto', sortable:false, class: ['no-gutters black--text'] },
             { text: 'Fecha', value: 'fecha', sortable:false, class: ['no-gutters black--text'] },
             { text: 'Pendiente', value: 'pendiente', sortable:false, class: ['no-gutters black--text'] },
            ] " dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.codigo" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">
              <template v-slot:item.valor="props">
                <span>{{currencyFormatter(props.item.valor)}}</span>
              </template>

              <template v-slot:item.fecha="props">
                <span>{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

              <template v-slot:item.pendiente="props">
                <span v-if="props.item.pendiente==true">Si</span>
                <span v-if="props.item.pendiente==false">No</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec=='anularTranferencia'" @click.prevent="anular(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->




    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- ------------------------------------------------------ Modal- crear documento-->
    <v-dialog transition="fab-transition" v-model="dialogCrearDocumento" persistent max-width="90%" max-height="80%">
      <CrearDocumento @EmitCrearDocumento="RecCrearDocumento" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- crear producto-->



    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>




            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>


              <!---------------------------------------------- documento -->
              <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                item-text="descripcion" :rules="[$rules.required]" v-model="Modallfiltro.documento"
                @blur="SelctCbDocumentoFiltro" @focus="cargarDocumento">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-book"></i>
                </template>
              </v-combobox>




            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">

              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


              <v-switch class="mt-0" color="teal" inset hide-details label="Resumido:" v-model="Modallfiltro.pendiente">
                <template v-slot:label>
                  <strong v-if="Modallfiltro.pendiente==true" style="color:#000000;">pendiente: Si</strong>
                  <strong v-if="Modallfiltro.pendiente==false" style="color:#000000;">pendiente: No</strong>
                </template>
              </v-switch>




            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>


            <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-------------------------------------------------------- fn Modal filtro -->


    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE TRANSFERENCIA</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">


              <v-col cols="12" md="12" sm="12">
                <!---------------------------------------------- documento -->
                <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                  item-text="descripcion" v-model="datosImprecionAll.documento" @blur="SelctCbReportDocumento"
                  @focus="cargarDocumento">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>

              </v-col>
              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

          <v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Resumido:"
              v-model="datosImprecionAll.Resumen"
            >
          <template v-slot:label>
         <strong v-if="datosImprecionAll.Resumen==true" style="color:#000000;">Resumido: Si</strong>
         <strong v-if="datosImprecionAll.Resumen==false" style="color:#000000;">Resumido: No</strong>
        </template>
</v-switch>



              </v-col>

              <v-col cols="12" md="6" sm="6">



                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta ">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros2 ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->

    <!-- ------------------------------------------------------ Modal- agregar cuenta-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
      max-width="80%" max-height="50%">

      <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
        :Total="TotalCuenta" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal agregar cuenta-->

 <!-- ------------------------------------------------------ Modal- verificar producto -->
 <v-dialog transition="fab-transition" label="Modal verificar producto" v-model="dialogVerificaProducto" persistent
      max-width="90%" max-height="80%">

      <v-card>
      
      <v-toolbar flat color="indigo" dense>

        <i style="font-size: 20px;" class=""> </i>
        <v-toolbar-title style="color:white;" class="font-weight-light"> VERIFICACIÓN DE EXISTENCIA PRODUCTO </v-toolbar-title>
        <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn color="grey darken-4" fab small @click="dialogVerificaProducto=false">
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2">
 <div style="border: 1px solid #000000;" >
<v-data-table
v-if="dialogVerificaProducto"
      id="TbBord"
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'PRODUCTO', sortable: false,  value: 'producto.descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'CANTIDAD', sortable: false, value: 'poscantidad', width:'auto', class: ['no-gutters black--text']  },
                 { text: `EXISTENCIA(${Tranferencia.documentoinventario.almacenOrigen.descripcion.trim()})`, sortable: false, value: 'existencia', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'ACCIÓN', sortable: false, value: 'action', width:'auto' , class: ['no-gutters black--text'] },
                 ]"
              dense
              :items="listDatosVerificar(ListProd)"
              item-key="indx" 
              :footer-props="arrayPropiedases"
                   
            >


          <template v-slot:item.producto.descripcion="props">
          <!-- <span style="font-size:13px;" >{{props.item.producto.descripcion}}</span> -->
          <v-text-field v-if="props.item.producto!=null" label="" id="formdisabledInput" style="font-size:13px;" dense
            disabled hide-details outlined autocomplete="off" :value="props.item.propsDesProdUnid">
          </v-text-field>
        </template>

        <template v-slot:item.poscantidad="props">
          <span style="display:none;">{{props.item.poscantidad = props.item.cantidad}}  </span>
          <AutoNumeric label="" style="font-size:13px;" dense  hide-details outlined
            autocomplete="off"   v-model.number="props.item.poscantidad" @blur="posCantidadListExist(props.item,props.item.poscantidad)">
          </AutoNumeric>
        </template>
        
        <template v-slot:item.existencia="props">
          <!-- <span>{{props.item.existencia}}</span> -->
          <v-text-field label="" id="formdisabledInput" style="font-size:13px;" dense disabled hide-details outlined
            autocomplete="off" :value="props.item.existencia">
          </v-text-field>
        </template>


        <template v-slot:item.action="{ item }">
          <v-btn fab @click.prevent="eliminarItem(item)" x-small color="red darken-3"><i style="font-size: 20px;"
              class="fa fa-trash-o"></i></v-btn>
        </template>

</v-data-table>

 </div>
</v-col>

      </v-card-text>
    </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- verificar producto -->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearDocumento from "../../Inventario/UtilidadComponents/CrearDocumento";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros2 from "../../components/ModalReporteFiltros2";
import CuentasContables from "../../components/CuentasContables";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,RedondeoBasico } from "@/js/Funciones.js";
import TablaProductoInventario from "../../Inventario/UtilidadComponents/TablaProductoInventario";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearDocumento,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    CuentasContables,
    TablaProductoInventario,
    BarraDinamicaLimitePlus,
    ModalReporteFiltros2
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    //EventBus.$on("emitTransferencias", this.datostabla);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal()
  },

  created() {
    var json = {
      titulo: "Transferencias",
      descripcion: "Realizar Transferencias de productos entre almacenes.",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      vercuenta: true,

      accion: [
        "INVTransferenciasNew",
        "INVTransferenciasSave",
        "INVTransferenciasRemove",
        "INVTransferenciasAnular",
        "INVTransferenciasPrintList",
        "INVTransferenciasVercuenta"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      dialogOrdenSugerida: false,
      dialogCrearDocumento: false,
      dialogVerificaProducto:false,
      Rmoneda: [],
      RallProducto: [],
      Rdepartamento: [],
      RDocumento: [],
      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'TRANSFERENCIAS',
        UrlImprimir: '/transferenciasInv/imprimir',
        UrlExel: '/transferenciasInv/excel',
        UrlCorreo: '/transferenciasInv/correo',
      },
      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE TRANSFERENCIAS',
        UrlImprimir: '/transferenciasInv/ImprimirList',
        UrlExel: '/transferenciasInv/ExcelList',
        UrlCorreo: '/transferenciasInv/CorreoList',
        documento: null,
        desde: fechaNueva(),
        hasta: fechaNueva(),
        sucursal: null,
        Resumen:true
      },
      aalert: {
        estado: false,
        color: null
      },
      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },
      search: '',
      ObjTemp: null,
      objeTem: null,
      TotalCuenta: null,
      contabilidad: null,
      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
      ListProd: [],
      almacen: null,
      OrdenSugerida: {
        desde: fechaNueva(),
        hasta: fechaNueva(),
        dia: 0,
        proveedor: null,
        almacen: null,
        opcion: 1
      },
      Tranferencia: {
        transferenciasPK: { secuencia: 0, documento: 0 },
        fecha: fechaNueva(),
        concepto: '',
        tasa: 1,
        documentoinventario: null,
        empresa: 1,
        referencia: "",
        pendiente: true,
        bulto: 0,
        borrado: false,
        transferenciasDetalleList: [],
        usuarioAnulacion:null,
        fechaAnulacion:null,
        horaAnulacion:null
      },
      RLisCuenta: [],
      TotalCuenta: {
        credito: 0,
        debito: 0
      },
      acti_Modal_cuenta: false,
      RLisCuenta: [],
      TotalCuenta: {
        credito: 0,
        debito: 0
      },

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
        pendiente: false
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
      },
      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },

      

      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
      },
      headers: [{ text: "Tarjeta", value: "nombre" }],
      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    fechaNueva,
    RedondeoBasico,
    onResize(e) {
      // this.windowSize=e
    },
    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal', { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.almacen = res.data })
    },
    RecCrearDocumento(e) {
      this.dialogCrearDocumento = e.estado
    },

    cargarDocumento() {
      this.$axios.get(this.$hostname + this.$hName + '/documento-inv/transferencia', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))
    },
    SelctCbDocumento() {
      if (this.Tranferencia.documentoinventario == null) {
        this.Tranferencia.documentoinventario = ''
      }
      if (this.Tranferencia.documentoinventario.codigo == undefined) {
        this.alerta("Seleccione un documento", "error");
        this.Tranferencia.documentoinventario = ''
        return
      }
      this.almacen = this.Tranferencia.documentoinventario.almacenOrigen;
    },

    changeDocumento(e){

      var _this = this;
      setTimeout(function () {
        if (e == null) {
          return
        }

        if (!e.hasOwnProperty('codigo')) {
          return;
        }

        if(_this.ListProd.filter(r => r.producto != null).length > 0){
           _this.$refs.RefTablaProducInvent.AddExistenciaProductosList(e.almacenOrigen);
        // AddExistenciaProductosList,AddExistenciaProductosList
        }

      }, 300);

    },

    eliminarItem(e){
      this.$refs.RefTablaProducInvent.eliminarItem(e);
    },

    posCantidadListExist(item,value){
      item.cantidad= JSON.parse(JSON.stringify(value))
    },


    DDataTotalList(e) {

var _this=this;
setTimeout(function(){ 
  _this.TotalProd = JSON.parse(JSON.stringify(e))
}, 300);
    
    },

    listDatosVerificar(list){
     return list.filter((ee) => ee.unidadProducto != null && ee.cantidad > ee.existencia);
    },


save(){
  EventBus.$emit("loading", true);
  if (this.ListProd.filter(r => r.producto != null).length == 0) {
        this.alerta("Debe elegir almenos un producto para la transferencia", "error")
        return ;
      }

    if (this.$refs.form.validate()) {

      var BOOL_PERMITIR=this.$store.getters.GetConfiguracionGeneral.configInv.permitirNegativos
      if (BOOL_PERMITIR) {
        this.saveContinuar()
      }else{
        // var OBJ_TRANSF=JSON.parse(JSON.stringify(this.Tranferencia))
        var ObjProRow = this.ListProd.filter((ee) => ee.unidadProducto != null && ee.cantidad>0 && ee.cantidad > ee.existencia);
          if (ObjProRow.length > 0) {
            this.alerta("Productos que excede la existencia", "error");
            this.dialogVerificaProducto=true
            console.log(ObjProRow)
          }else{
            this.saveContinuar()
          }
        
      }
      
    } else {
        this.alerta("los campos en rojo son Requeridos", "error");
    }

},


    saveContinuar() {

        this.Tranferencia.transferenciasDetalleList = []
        var det = {};

        var bar = new Promise((resolve, reject) => {
          this.ListProd.filter(r => r.producto != null && r.cantidad>0).forEach(el => {
          det = {
            transferenciasDetallePK: { documento: 0, secuencia: 0, producto: 0, unidad: 0 },
            unidades: el.unidad,
            productos: el.producto,
            almacendestino: this.Tranferencia.documentoinventario.almacenDestino,
            almacenorigen: this.Tranferencia.documentoinventario.almacenOrigen,
            impuesto: el.impuesto,
            costo: el.costo,
            cantidad: el.cantidad,
            valor: el.valor,
            lote:el.lote,
            borrado: false
          }
          this.Tranferencia.transferenciasDetalleList.push(det)
        });

    resolve()
});

bar.then(() => {
  console.log('All done Procesar save!');
  this.Tranferencia.contabilidad = this.contabilidad;
        if(this.RLisCuenta.length>0){
        this.Tranferencia.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }

        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);
          this.$axios
            .post(
              this.$hostname + this.$hName + "/transferenciasInv/save", this.Tranferencia,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => {
              if (error.response) {
                this.alerta(error.response.request.responseText, 'error')
              } else {
                this.alerta(error, 'error')
              }
            });
        }
});



     

/* if (this.ListProd.filter(r => r.producto != null).length == 0) {
        this.alerta("Debe elegir almenos un producto para la transferencia", "error")
      }

      if (this.$refs.form.validate()) {
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }*/


      
    },




    NotifMessage() {

      if (this.Tranferencia.transferenciasPK.secuencia != 0 && this.Tranferencia.transferenciasPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "anularTranferencia"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una tranferencia para Anular", "error") }


    },
    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))
    },
    eliminar() {

    },
    anular() {



      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          EventBus.$emit("loading", true);

this.Tranferencia.usuarioAnulacion=this.$store.getters.GetdatosInfUsu.usuario.usuario
this.Tranferencia.fechaAnulacion=this.fechaNueva()+"T04:00"
this.Tranferencia.horaAnulacion=""


          this.$axios
            .post(
              this.$hostname + this.$hName + "/transferenciasInv/anular", this.Tranferencia,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    imprimirList() {
      if (this.Tranferencia.transferenciasPK.secuencia > 0) {
        this.datosImprecion.secuencia = this.Tranferencia.transferenciasPK.secuencia
        this.datosImprecion.documento = this.Tranferencia.transferenciasPK.documento
        this.datosImprecion.almacen = this.Tranferencia.documentoinventario.almacenOrigen.descripcion

        //          ic.imprimir("Comprobante_transferencia_producto", parametros, "inv");

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un tranferencia para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
    console.log('ver-Desde-->',this.datosImprecionAll.desde)
    console.log('ver-Hasta-->',this.datosImprecionAll.hasta)
    var condicion=`DESDE: ${this.FormatoFecha(this.datosImprecionAll.desde)}                HASTA: ${this.FormatoFecha(this.datosImprecionAll.hasta)}`
  
      
      var where = "t.pendiente=false ";
      //where += ` and t.fecha>= ' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)}' and t.fecha<= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `
        where += ` and t.fecha>= '${this.datosImprecionAll.desde}' and t.fecha<= '${this.datosImprecionAll.hasta}' `
      // this.datosImprecionAll.documento = ""

      if (this.datosImprecionAll.documento != null && this.datosImprecionAll.documento.hasOwnProperty('codigo') ) {
        where += ` and t.documento= ${this.datosImprecionAll.documento.codigo}`;
        condicion+=`\t DOCUMENTO: ${this.datosImprecionAll.documento.descripcion.trim()}`
      }
      this.datosImprecionAll.where = where
      this.datosImprecionAll.condicion=condicion
      //        ic.imprimir("ListadoTransferencia2", parametros, "inv");


    },



    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.contabilidad = null
      this.RLisCuenta = []

      this.TipoProveedor = {
        codigo: 0,
        nombre: null,
        porciento: 0
      };
      this.Tranferencia = {
        transferenciasPK: { secuencia: 0, documento: 0 },
        fecha: fechaNueva(),
        concepto: '',
        tasa: 1,
        documentoinventario: null,
        empresa: 1,
        referencia: "",
        pendiente: true,
        bulto: 0,
        borrado: false,
        transferenciasDetalleList: [],
        usuarioAnulacion:null,
        fechaAnulacion:null,
        horaAnulacion:null
      }
      this.TotalProd = { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }
      this.ListProd = []
      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);

      this.$refs.RefTablaProducInvent.TotalProd={ cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }







    },
    datostabla(e) {
      this.contabilidad = null
        this.RLisCuenta = []
      this.ListProd = []
      this.Tranferencia = e;
      this.Tranferencia.fecha = new Date(e.fecha).toJSON().split("T")[0]
      e.transferenciasDetalleList.forEach(e => {
        
        var detalle = {
          movimientosinventarioDetallePK: { secuencia: 0, producto: 0, unidad: 0, documento: 0 },
          fechavencimiento: null,
          cantidad: e.cantidad,
          costo: e.costo,
          valor: e.valor,
          impuesto: e.impuesto,
          impuestoIncluido: e.impuestoIncluido,
          existencia: 0,
          id: 0,
          borrado: false,
          almacen: this.Tranferencia.documentoinventario.almacenOrigen,
          unidad: e.unidades,
          producto: e.productos,
          codigoProducto: "",
           unidadProducto: null,
        }

       this.$refs.RefTablaProducInvent.getUnidadesProductosAddList(e.productos.codigo,e.unidades.codigo,detalle);
        
      });
      this.acti_tb_data = false;
      //console.log(e);

      



var _this = this;
        setTimeout(function () {
          _this.$refs.RefTablaProducInvent.calcularTotales()
          _this.getCrearCont();
        }, 500);



    },





    FiltroBlurDesde() {
      if (this.Modallfiltro.Desde.toString().length == 0) {
        this.Modallfiltro.Desde = this.fechaNueva()
      }
    },


    FiltroBlurHasta() {
      if (this.Modallfiltro.Hasta.toString().length == 0) {
        this.Modallfiltro.Hasta = this.fechaNueva()
      }
    },

    SelctCbDocumentoFiltro() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },



    SelctCbReportDocumento() {

      if (this.datosImprecionAll.documento == null) {
        this.datosImprecionAll.documento = ''
      }

      if (this.datosImprecionAll.documento.codigo == undefined) {
        //this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documento = ''
        return
      }

    },


    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    getCrearCont() {
      console.log('Entrando A generar')
      if (this.TotalProd.importe == 0) {
        return
      }
      if (this.$refs.form.validate()) {

        if (this.Tranferencia.transferenciasPK.secuencia == 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-inv/' + this.Tranferencia.documentoinventario.codigo + '/' + this.TotalProd.importe + '/' + this.TotalProd.impuesto + '/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.llenarcuenta(res.data) });
        }
        if (this.Tranferencia.transferenciasPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.Tranferencia.transferenciasPK.documento + '/' + this.Tranferencia.transferenciasPK.secuencia + '/' + this.Tranferencia.documentoinventario.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }
      }
    },
    llenarcuenta(e) {
   console.log(e)
    if(e.toString().trim().length==0){
      return
     }

      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.Tranferencia.fecha;
        this.contabilidad.documento = this.Tranferencia.documentoinventario.codigo;
      }

      
      this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },
    async calcularTotalcuenta(e) {
      this.TotalCuenta.credito = 0
      this.TotalCuenta.debito = 0

      if (e.toString().length > 0) {

await Promise.all(this.RLisCuenta.map(async (element) => { 
  this.TotalCuenta.credito += this.RedondeoBasico(element.credito)
  this.TotalCuenta.debito += this.RedondeoBasico(element.debito)
})); 

}

    /*  if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {
          this.TotalCuenta.credito += element.credito
          this.TotalCuenta.debito += element.debito
        });
      }*/
    },


    CCuentasContables(e, t, b) {
      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.RLisCuenta = this.RLisCuenta
      this.TotalCuenta = JSON.parse(JSON.stringify(t))

      this.acti_Modal_cuenta = b

    },



    vercuenta() {


      if (this.$refs.form.validate()) {
        if(this.RLisCuenta.length==0){
          this.getCrearCont();
        }
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.TotalCuenta))
        this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else { this.alerta("Los campos en rojo son requeridos", "error"); }

    },

    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        pendiente: false,
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
      }


      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      var doc = null
      if (this.Modallfiltro.documento != null) { doc = this.Modallfiltro.documento.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + doc + "/" + this.Modallfiltro.pendiente + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/transferenciasInv/findListTransferenciaPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },



    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/transferenciasInv/findall",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },





    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },

  computed: {

    ActDescBtn() {

      if (this.Tranferencia.transferenciasPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: false,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

 if(this.Tranferencia.activada==false){
  this.EstadoBtn.anular=false
 }
        

      }

      if (this.Tranferencia.transferenciasPK.secuencia <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }

  }



};
</script>

<style>

</style>
