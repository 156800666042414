<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >  
 <tr max-height="48px" height="48px"  > 
<ContenidoBase 
  :titulo="'ARS'"
  :descripcion="'aqui se registran las diferentes ARS'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   @MethodImportarDato="importarDato"
   
/>
    </tr>
 
 
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
      
      <v-tooltip left>
          <template v-slot:activator="{ on }">
            <center>
              <v-btn-toggle></v-btn-toggle>
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>
            <BarraDinamicaNormal
              ref="BarraDiNor"
              :url="$hostname + $hName + '/ars/findall'"
              :nombre="'ARS'"
              :emitnombre="'emitCXCARS'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'ARS', value: 'nombre' },
        { text: 'Dirección', value: 'direccion' },
        { text: 'Telefono', value: 'telefono'},
        { text: 'RNC/Cedula', value: 'rnc' },
        { text: 'Dias Creditos', value: 'diaCredito' },
        { text: 'Balance', value: 'cliente.balancecliente.balance' }
       
        ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'ARS', NomValue:'nombre', Ctsl:1, Type:0, State:true},
              {Text:'Dirección', NomValue:'direccion', Ctsl:1, Type:0, State:true},
              {Text:'Telefono', NomValue:'telefono', Ctsl:1, Type:0, State:true},
              {Text:'Dias Creditos', NomValue:'diaCredito', Ctsl:1, Type:0, State:true},
              {Text:'Balance', NomValue:'cliente', Nv2:'balancecliente', Nv3:'balance', Ctsl:3, Type:2, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            />

            <!--------------------- aqui va la otra tabla izquierda --------->
            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/arservices/findall'"
              :nombre="'ARS'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitCXCARS'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por Nombre'"
            /> -->
          </template>
        </v-tooltip>


      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 
  <div class="px-2">

<v-card
 color="grey lighten-3"
  >

<v-form ref="form" v-model="valid" lazy-validation>
              <div class="px-2">
                <v-row>

                  <v-col cols="6" md="6">
            <v-text-field 
                    label="Codigo Ars:"
                    dense
                    outlined
                    v-model="nuevoARS.codigo"
                    :disabled="true"
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                    </template>
                  </v-text-field>
                    <v-text-field
                      :rules="[$rules.required]"
                      label="Telefono:"
                      v-mask="['(###)-###-####']"
                      required
                      outlined
                      dense
                      v-model="nuevoARS.telefono"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-phone"></i>
                      </template>
                    </v-text-field>

                    <v-text-field
                      label="Días de Crédito:"
                      dense
                      outlined
                      v-model="nuevoARS.diaCredito"
                      @keyup="DiaCre"
                    >
                      <template v-slot:prepend>
                        <i
                          style="font-size: 20px;"
                          class="fa fa-credit-card"
                        ></i>
                      </template>
                    </v-text-field>


                    <v-textarea
                      :rules="[$rules.required]"
                      autocomplete="off"
                      label="Dirección:"
                      required
                      outlined
                      dense
                      v-model="nuevoARS.direccion"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-textarea>

<v-switch
              v-model="nuevoARS.activo"
              label="Activo"
              color="success"
              hide-detail

              ></v-switch>
                </v-col>


                  <!-- </v-col> -->

                  <v-col cols="6" md="6">
                    <v-text-field
                      :rules="[$rules.required]"
                      dense
                      label="Nombre"
                      autocomplete="off"
                      required
                      outlined
                      v-model="nuevoARS.nombre"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-male"></i>
                      </template>
                    </v-text-field>

                    <v-text-field
                      :rules="[$rules.required,$rules.RulesRnc]"

                      autocomplete="off"
                      dense
                      label="RNC"
                      v-mask="['###-#####-#']"
                      maxlength="11"
                      required
                      outlined
                      v-model="nuevoARS.rnc"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card"></i>
                      </template>
                    </v-text-field>

                    <v-text-field
                      v-model="nuevoARS.letra"
                      label="Letra Autorizacion"
                      autocomplete="off"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>

                    <v-combobox
                      :rules="[$rules.required]"
                      label="Tipos de Comprobante:"
                      :items="ListaTipoComprobantes"
                      required
                      item-text="descripcion"
                      outlined
                      dense
                      v-model="nuevoARS.tipoNcf"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-tag"></i>
                      </template>
                    </v-combobox>

                    <AutoNumeric
                      label="Descuento:"
                      required
                      outlined
                      dense
                      v-model="nuevoARS.descuento"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-percent"></i>
                      </template>
                    </AutoNumeric>

                    <v-text-field
                      v-model="nuevoARS.codigoArs"
                      label="Código PSS:"
                      autocomplete="off"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                </v-row>
               
              </div>
            </v-form>
        </v-card>    
  </div>
       

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay 
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          

          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              class="TbSize13"
              :headers="[
        { text: 'ARS', value: 'nombre', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Dirección', value: 'direccion', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Telefono', value: 'telefono',width:150, sortable:false, class: ['no-gutters black--text'] },
        { text: 'RNC/Cedula', value: 'rnc', width:150, sortable:false, class: ['no-gutters black--text'] },
        { text: 'Dias Creditos:', value: 'diaCredito', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Balance', value: 'cliente.balancecliente.balance', sortable:false, class: ['no-gutters black--text'] }
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.cliente.balancecliente.balance="props">
                <span v-if="props.item.cliente!=null && props.item.cliente.balancecliente != null" >
                  {{ currencyFormatter(props.item.cliente.balancecliente.balance) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title :class="`d-flex justify-center`" >
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions :class="`d-flex justify-center`" >
          <v-btn
            v-if="notif.Exec=='eliminarArs'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->



<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->

<!-- ------------------------------------------------------ Modal- Importar datos-->
<v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogImportarDatos" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion Vale de caja ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
</div> -->

        <v-toolbar dark color="indigo" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Enviar Reporte
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Enviar Reporte: </span> Importar Facturas</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle></v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab small @click="cerrarImportarDatos">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>

                 

                </template>
                <span>CANCELAR: Retornar y no importar Datos</span>
              </v-tooltip>

            

          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna Importar Datos -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Importar Facturas</b>
                <v-form v-model="valid" ref="formInputFile" lazy-validation>
                  <!-- Row 1 Importar Datos     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                 <v-form  ref="formInputFilePro" v-model="valid" lazy-validation>
                      <v-file-input :rules="[$rules.required]" type="file" @change="" v-model="ArchivoProveedores" id="excelProveedores" dense
                  outlined show-size counter multiple label="Archivo de Proveedores"
                  accept=".csv,.xlsx, .xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/xml ">
                  
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="SubirArchivoProve" v-on="on" color="blue" fab x-small
      dark>
      <i style="font-size: 20px;" class="fa fa-cloud-upload"></i>
    </v-btn>

  </template>
  <span>Subir Archivo</span>
</v-tooltip>
</template>
                </v-file-input>
                  </v-form>
                  
    
                    </v-col>

                  </v-row>
                  <!-- Row 2 Importar Datos ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Importar Facturas -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Importar Facturas-->





<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>
<script>
import AutoNumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from "../../event-bus";
import { isNullOrUndefined } from "util";
import numeral from "numeral";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    AutoNumeric,
    BarraDinamicaNormal,
    ContenidoBase,
    ModalReporteBasico
    
  },
 
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("CXCARSNew", this.new);
    EventBus.$on("CXCARSSave", this.save);
    EventBus.$on("CXCARSRemove", this.remove);
    EventBus.$on("CXCARSAnular", this.anular);
    EventBus.$on("CXCARSPrintList", this.imprimirList);
    EventBus.$on("emitCXCARS", this.datostabla);
    this.cargarTodo();
    EventBus.$on("onResize", this.onResize);

  },
  created() {
    var json = {
      titulo: "ARS",
      descripcion: "aqui se registran las diferentes ARS",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CXCARSNew",
        "CXCARSSave",
        "CXCARSRemove",
        "CXCARSAnular",
        "CXCARSPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },



data:()=> ({

      windowSize:{width:"auto", height:"auto"},
      valid: true,
      dialogImportarDatos:false,
      ArchivoProveedores:null,




      //Listados
      ListaTipoComprobantes: [],
      ListaARSs: [],
      //el objeto principal
      nuevoARS: {
        activo: true,
        borrado: false,
        cliente: null,
        codigo: 0,
        diaCredito: 0,
        direccion: "",
        letra: "",
        nombre: "",
        rnc: "",
        telefono: "",
        tipoNcf: null,
        descuento: 0,
        codigoArs: ""
      },


dialogimprimirList:false,
datosImprecion:{
nombre:'ARS',
 UrlImprimir: '/ars/imprimir', 
 UrlExel: '/ars/excel', 
 UrlCorreo: '/ars/correo', 
},



EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:true,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

      

       BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      headers: [{ text: "Descripción", value: "nombre" }],
      slotlist: [
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Descripcion:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
       
      ],

      list: [],
      search: "",
      acti_tb_data: false,



notif: {
        estado: false,
        nombre: "",
        Exec:""
      },


    
      aalert: {
        estado: false,
        color: null
      }
   
  }),

  
  methods: {

currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    DiaCre(e) {
      if (e.key) {
        var mynumeral = require("numeral");
        this.nuevoARS.diaCredito = mynumeral(
          this.nuevoARS.diaCredito
        ).value();
        //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
        if (this.nuevoARS.diaCredito == null) {
          this.nuevoARS.diaCredito = 0;
        }
      } else {
        console.log("no se puede poner letras o signos");
      }
    },
    abrirTabla() {

      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }





    },
    async cargarTodo() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/ars/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => (this.ListaARSs = res.data))
        .catch(error => console.log(error));

      await this.$axios
        .get(
          this.$hostname + this.$hName + "/tiponcf/findall",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.ListaTipoComprobantes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {
      this.nuevoARS = e;
      this.acti_tb_data=false

      console.log('data data')
      console.log(e)




    },
    save() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("loading", true);
var jsn={
   datos:JSON.parse(JSON.stringify(this.nuevoARS)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname + this.$hName + "/ars/save",this.nuevoARS,
            {headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato guardado correctamente!", "bien"))
          .catch(error =>
            this.alerta("Dato no guardado correctamente!","error" )
          );
      }else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },



NotifMessage() {

if(this.nuevoARS.codigo!=0 && this.nuevoARS.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarArs"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un custodio para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))


    },





    eliminar() {
      if (this.$refs.form.validate()) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.nuevoARS)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname + this.$hName + "/ars/borrar",this.nuevoARS,
           {headers:this.$store.getters.GetheadersAxios}
           
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              "Dato no eliminado correctamente!",
              "error"
            )
          );
      }
    },
    anular() {},
     imprimirList() {

this.dialogimprimirList=true



    },

DDataReporteBasico(e){
this.dialogimprimirList=e
},

    newwnuevo() {

this.BarraTablaDinamicaAprir=false


    this.nuevoARS = {
        activo: true,
        borrado: false,
        cliente: null,
        codigo: 0,
        diaCredito: 0,
        direccion: "",
        letra: "",
        nombre: "",
        rnc: "",
        telefono: "",
        tipoNcf: null,
        descuento: 0,
        codigoArs:""
      },
        EventBus.$emit("loading", false);
        EventBus.$emit("actualizaBarraBusqueda2");
    },

    importarDato() {
      this.dialogImportarDatos = !this.dialogImportarDatos
    },


    cerrarImportarDatos() {

      this.dialogImportarDatos = false

    },



SubirArchivoProve(){

      if (this.$refs.formInputFilePro.validate()) {

      }else {
        this.alerta("Seleccione un archivo ", "error");
      }

  
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {

var headers=this.$store.getters.GetheadersAxios


      await this.$axios
        .get(this.$hostname + this.$hName + "/ars/findall", {headers:headers})
        .then(res => {
          this.list = res.data
        });
    }
  },
  computed:{



    ActDescBtn(){

if(this.nuevoARS.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:true,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }




}

if(this.nuevoARS.codigo<=0){

  this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:true,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }

}
     
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }


 


};
</script>
