<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Resumen de Ventas'"
  :descripcion="'Resumen de Ventas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitBancos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>

<v-col  cols="12" md="12" sm="12"  > 
 <v-row >

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Desde"
         :rules="[$rules.required]"
        v-model="ResumenVentas.desde"
        hide-details
        style="font-size:13px;"
        @blur="BlurDesde"
        @input="GetResumenVentaList"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
<v-text-field
        dense
        outlined
        type="date"  
        label="Fecha Hasta"
         :rules="[$rules.required]"
        v-model="ResumenVentas.hasta"
        hide-details
        style="font-size:13px;"
        @blur="BlurHasta"
        @input="GetResumenVentaList"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<v-col cols="12" md="4" sm="4" class="pa-0 ma-0 px-2">
  <v-combobox
            autocomplete="off"
            dense
            outlined
           
            label="Succursal:"
            :items="RSucursal"
            item-text="descripcion"
            v-model="ResumenVentas.sucursal"
            @blur="SelctCbSucursal"
            @focus="cargarSucursal"
            hide-details
            style="font-size:13px;" 
            @change="GetchangeList"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>
</v-col>

</v-row>
</v-col>
<v-row>

    
<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0" >
 <div style="border: 1px solid #000000;" >
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: ' sucursal', sortable: false,  value: 'sucursal', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Contado ', sortable: false, value: 'contado', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Crédito', sortable: false, value: 'credito', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Otros', sortable: false, value: 'otros', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Total', sortable: false, value: 'Total', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Devoluciones', sortable: false, value: 'devolucion', width:'auto', class: ['no-gutters black--text']  },
                 ]"
              dense
              :items="listDatos"    
              :items-per-page="15"
            >

<template v-slot:item.contado="props">
    <span>{{currencyFormatter(props.item.contado )}}</span>
</template>

<template v-slot:item.credito="props">
    <span>{{currencyFormatter(props.item.credito )}}</span>
</template>

<template v-slot:item.otros="props">
    <span>{{currencyFormatter(props.item.otros )}}</span>
</template>

<template v-slot:item.Total="props">
    <span>{{currencyFormatter(props.item.Total )}}</span>
</template>

<template v-slot:item.devolucion="props">
    <span>{{currencyFormatter(props.item.devolucion )}}</span>
</template>


<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<!-- <td class="border-top-bottom"  ><strong></strong></td> -->
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.Contado)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.Credito)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.Otros)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.Total)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.Devoluciones)}} </strong></td>
 
 </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total contado: {{currencyFormatter(totales.Contado)}}</span>
        <span>Total Crédito: {{currencyFormatter(totales.Credito)}}</span>
        <span>Total Otros: {{currencyFormatter(totales.Otros)}}</span>
       <span>Valor Total: {{currencyFormatter(totales.Total)}}</span>
       <span>Valor Comisiones: {{currencyFormatter(totales.Devoluciones)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>
</v-col>



              </v-row>

            </v-form>
       </div>





  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },

  created() {
  
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);

    EventBus.$on("emitResumenVentas", this.datostabla);
    EventBus.$on("onResize", this.onResize);


  var inseg=this.$store.getters.GetConfiguracionGeneral.configPv.noIncluirSeguro

  if(inseg!=null){ this.ResumenVentas.noIncluirSeguro=inseg }else{
    this.ResumenVentas.noIncluirSeguro=false
  }


this.ResumenVentas.subo=this.$store.getters.GetdatosInfUsu.usuario.sudo

console.log('Informacion Holla')
console.log(this.$store.getters.GetConfiguracionGeneral.configPv.noIncluirSeguro)


this.GetResumenVentaList()

  },

  data:()=>  ({


      Rpropietario:[],
      RSucursal:[],
      listDatos:[],
      
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      ResumenVentas: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        sucursal:null,
        noIncluirSeguro:false,
        subo:false
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

totales:{
Contado:0,
Credito:0,
Otros:0,
Total:0,
Devoluciones:0
},


EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:true,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.ResumenVentas = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() {},
    imprimirList() {},
    newwnuevo() {


  this.listDatos=[],
      this.ResumenVentas.sucursal=null
      
      this.ResumenVentas.desde=new Date().toISOString().substr(0,10)
      this.ResumenVentas.hasta=new Date().toISOString().substr(0,10)
    
this.totales={
Contado:0,
Credito:0,
Otros:0,
Total:0,
Devoluciones:0
}



this.GetResumenVentaList()

},


cargarSucursal(){

this.$axios.get(this.$hostname+this.$hName+'/sucursal/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.RSucursal=res.data))

},

SelctCbSucursal(){

 if(this.ResumenVentas.sucursal==null){

    this.ResumenVentas.sucursal=''
  }

if(this.ResumenVentas.sucursal.codigo==undefined){

this.alerta("Seleccione un sucursal", "error");
this.ResumenVentas.sucursal=''
return

}
},


BlurDesde(){
if(this.ResumenVentas.desde.toString().length==0){
  this.ResumenVentas.desde=new Date().toISOString().substr(0,10)
}

 },


 BlurHasta(){

if(this.ResumenVentas.hasta.toString().length==0){
  this.ResumenVentas.hasta=new Date().toISOString().substr(0,10)
}

 },

GetchangeList(e){

this.GetResumenVentaList()

},

GetResumenVentaList(){

var f1=new Date(this.ResumenVentas.desde).toISOString().substr(0,10)
var f2=new Date(this.ResumenVentas.hasta).toISOString().substr(0,10)
var suc=null

if(this.ResumenVentas.sucursal!=null){
suc=this.ResumenVentas.sucursal.codigo
}


// var Obj={}

var link = `/ConsultaRapida/ResumenVentas/${f1}/${f2}/${suc}/${this.ResumenVentas.noIncluirSeguro}/${this.ResumenVentas.subo}`
this.$axios
        .get(this.$hostname + this.$hName +link,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.CalcularObj(res.data)
          })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});


},

async CalcularObj(e){ 

var List=[]
this.listDatos=[]

this.totales={
Contado:0,
Credito:0,
Otros:0,
Total:0,
Devoluciones:0
},


//Sucursal,Contado,Credito,Otros,Total,Devoluciones
//sucursal[0],efectivo[1],cheque[2],cxc[3],tarjeta[4],cuponer[5],debolucion[6], cobertura[7],valornc[8]

await e.forEach(elent =>{

var  obj={
sucursal:elent[0],
contado:elent[1],
credito:elent[3],
devolucion:elent[6],
otros:elent[2]+elent[4]+elent[5]+elent[7]+elent[8],
Total:0
}

obj.Total=(obj.contado+obj.credito+obj.otros)

List.push(obj)
this.totales.Contado+=obj.contado
this.totales.Credito+=obj.credito
this.totales.Otros+=obj.otros
this.totales.Total+=obj.Total
this.totales.Devoluciones+=obj.devolucion 
});

this.listDatos=List



},




alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    },

  },

computed:{

  ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}


};
</script>

<style></style>
