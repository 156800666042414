<template>
 <div>{{GetEjecucion}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Registro de Turnos'"
  :descripcion="'Registro de Turnos'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="verificarsave"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"    
/>
    </tr>
  
    
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>
            </center>

            <BarraDinamicaNormal
              :url="$hostname + $hName + '/turno/findall/'"
              :nombre="'Turnos'"
              :emitnombre="'emitTranspRegistroTurno'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'id'},
        { text: 'fecha', value: 'fecha'},
        { text: 'Entrada', value: 'horaInicial'},
        { text: 'Intervalo', value: 'intervalo'},
        { text: 'Intervalo', value: 'activo'},
  
           ]"
          :ContListSlot="[
             // {Text:'', NomValue:'fotoVehiculo', Ctsl:1, Type:3, State:true},
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Fecha', NomValue:'fecha', Ctsl:1, Type:1, State:true},
              {Text:'Entrada', NomValue:'horaInicial', Ctsl:1, Type:0, State:true},
              {Text:'Intervalo', NomValue:'intervalo', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true}
              ]"
            />
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container><!-- style="border: 1px solid #000000;" -->
    
  <v-form ref="formTurno" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="3" sm="3">

          <!-- -----------------------------  fecha  -->
              <v-text-field
                      dense
                      outlined
                      type="date"  
                      label="Fecha:"
                      v-model="RegistroTurno.fecha"
                      :rules="[$rules.required]"
                      >
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-calendar"></i>
                              </template>
                
              </v-text-field>
                </v-col>
              
                <v-col cols="12" md="3" sm="3">


<v-text-field label="Hora Entrada:"
                     dense outlined
                     v-model="RegistroTurno.hora"
                     autocomplete="off"
                     type="time"
                     :rules="[$rules.required]"
                     @blur="BlurHoraVal"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
 </v-text-field>


                  <!-- <v-dialog
        ref="dialog"
        v-model="modalHora"
        :return-value.sync="RegistroTurno.hora"
        persistent
       
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="RegistroTurno.hora"
            label="Hora Entrada:"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          >
          <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                </template>
          
          
          </v-text-field>
        </template>
        <v-time-picker
           landscape 
           ampm-in-title
          v-if="modalHora"
          v-model="RegistroTurno.hora"
          full-width
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modalHora = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialog.save(RegistroTurno.hora)">OK</v-btn>
        </v-time-picker>
      </v-dialog>
       -->
                </v-col>

                <v-col cols="12" md="3" sm="3">

                 <!-- ----------------------------- Dia de credito -->
                    <v-text-field label="Intervalo de tiempo:"
                     dense outlined
                     v-model="RegistroTurno.intervalo"
                     @keyup="KeyUpIntTi"
                     :rules="[$rules.required]"
                     >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-hourglass-half"></i> 
                      </template>
                    </v-text-field>
                </v-col>

                <v-col cols="12" md="3" sm="3">

          <v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Direccion Turno"
              v-model="RegistroTurno.esSentidoIda"
            >
          <template v-slot:label>
         <strong v-if="RegistroTurno.esSentidoIda==true" style="color:#000000;">Direccion Turno: IDA</strong>
         <strong v-if="RegistroTurno.esSentidoIda==false" style="color:#000000;">Direccion Turno: VUELTA</strong>
        </template>
               
            </v-switch>
                </v-col>

                </v-row>

<v-row >

<!-- columna 1 ---------------------------------- -->
                <v-col cols="12" md="7" sm="7">
                  <v-combobox
                    v-model="FichaVehiculoEnTurno"
                    label=" Ficha Vehiculo:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="RFicha"
                    item-text="ficha"
                    @blur="SelctCbFichaEnTurno"
                    @focus="CargarRFicha"
                  >            
<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Descripción: {{item.item.descripcion}}</b></p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Ficha.: </b> {{item.item.ficha }} </span>
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Modelo: </b> {{item.item.modelo }} </span>
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Placa: </b> {{item.item.placa }} </span>
</span>
</v-col>
 
</template>

  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-bus"></i> 
 </template>   

 <template v-slot:append-outer>

<v-tooltip top>
<template v-slot:activator="{ on }">

    <v-btn @click.prevent="AddFichaEnTurno" v-on="on"  color="secondary" fab x-small dark>
    <i style="font-size: 20px;" class="fa fa-plus-square"></i>
    </v-btn>
 </template>
<span>Agregar Ficha Vehiculo</span>
</v-tooltip>
 </template>   

                        </v-combobox>
<!-------------- tb-Ficha 1 -->
<v-data-table
      class="elevation-1"
      dense
  :headers="[{ text: 'Turno', value: 'numeroTurno', width:'auto', sortable:false},{ text: 'Ficha', value: 'ficha',width:'auto', sortable:false}, { text: 'Hora Salida', value: 'hora2',width:'auto', sortable:false},
{ text: 'Descripción', value: 'vehiculo.descripcion',width:'auto', sortable:false},{ text: 'Conductor', value: 'idchofer',width:'auto', sortable:false},
{ text: 'ACCIÓN', value: 'action',width:'auto'}]"
        :items="ListEnTurno"
        :items-per-page="-1"
        item-key="indx"
      >

     <template v-slot:item.action="{ item }"> 
<!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
<v-btn fab @click.prevent="deleListEnTurno(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>        
   </template>

    <template v-slot:item.ficha="props">
    <span v-if="TunoIdOrde==true" style="display:none;">{{props.item.numeroTurno = ListEnTurno.indexOf(props.item)+1}}</span>
    <span style="font-size:13px;">{{props.item.ficha}}</span>
    </template>

    <template v-slot:item.hora="props">

<input
                 type="time"
                  v-model="props.item.hora"
                  style="font-size:13px;"
                  disabled
                  id="formdisabledInput"
                  
                />

    <!-- <span style="font-size:13px;">{{props.item.hora}}</span> -->
    </template>

    <template v-slot:item.vehiculo.descripcion="props">
    <span style="font-size:13px;">{{props.item.vehiculo.descripcion}}</span>
    </template>

    <template v-slot:item.idchofer="props">
      <v-combobox
            style="font-size:13px;"
            autocomplete="off"
            dense
            outlined
            label=""
            :items="Rconductor"
            item-text="nombre"
            :rules="[$rules.required]"
            v-model="props.item.idchofer" 
            @focus="cargarConductor" 
            
            hide-details
          >
          </v-combobox>
    <!-- @blur="SelctCbConductor"  -->
    </template>

    </v-data-table>

<!-------------- fn tb-Ficha 1 -->



                </v-col>
<!-- fn columna 1  --------------------------------->


  <!--   columna 2 --------------------------------->

                <v-col cols="12" md="5" sm="5">
         
<v-combobox
                    v-model="FichaVehiculoFueraTurno"
                    label=" Ficha Vehiculo Parado:"
                    required
                    outlined
                    dense
                    autocomplete="off"
                    :items="RFicha"
                    item-text="ficha"
                    @blur="SelctCbFichaEnTurno"
                    @focus="CargarRFicha"
                  > 
              <template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Descripción: {{item.item.descripcion}}</b></p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Ficha.: </b> {{item.item.ficha }} </span>
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Modelo: </b> {{item.item.modelo }} </span>
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Placa: </b> {{item.item.placa }} </span>
</span>
</v-col>
 
</template>



  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-bus"></i> 
 </template>   

 <template v-slot:append-outer>

<v-tooltip top>
<template v-slot:activator="{ on }">

    <v-btn @click.prevent="AddFichaFueraTurno" v-on="on"  color="secondary" fab x-small dark>
    <i style="font-size: 20px;" class="fa fa-plus-square"></i>
    </v-btn>
 </template>
<span>Agregar Ficha Vehiculo</span>
</v-tooltip>

  </template>   

                        </v-combobox>


<!-------------- tb-Ficha 2 -->
<v-data-table
      class="elevation-1"
      dense
  :headers="[{ text: 'Ficha', value: 'ficha',width:'auto'}, { text: 'Vehiculo', value: 'vehiculo.descripcion',width:'auto'},
{ text: 'Chofer', value: 'idchofer.nombre',width:'auto'},{ text: 'ACCIÓN', value: 'action',width:'auto'}]"
        :items="ListFueraTurno"
        :items-per-page="-1"
        item-key="indx"
      >
     <template v-slot:item.action="{ item }"> 
       <span  style="display:none;">{{item.indx = ListFueraTurno.indexOf(item)}}</span>
<!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
<v-btn fab @click.prevent="deleListFueraTurno(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>        
   </template>

 <template v-slot:item.ficha="props">
    <span style="font-size:13px;">{{props.item.ficha}}</span>
    </template>

 <template v-slot:item.vehiculo.descripcion="props">
    <span style="font-size:13px;">{{props.item.vehiculo.descripcion}}</span>
    </template>

    <template v-slot:item.idchofer.nombre="props">
    <span style="font-size:13px;">{{props.item.idchofer.nombre}}</span>
    </template>

    </v-data-table>

<!-------------- fn tb-Ficha 2 -->



                </v-col>
<!-- fn columna 2 ------------------------------------->




              </v-row>
            </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <v-tooltip top>

<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>

      <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >


              <template v-slot:item.fecha="props">
                <span style="font-size:13px;" >{{ FormatoFecha(props.item.fecha) }}</span>
              </template>

            <template v-slot:item.horaInicial="props">
            <input
                            type="time"
                              v-model="props.item.horaInicial"
                              style="font-size:13px;"
                              disabled
                              id="formdisabledInput"
                              
                            />
                </template>

            <template v-slot:item.intervalo="props">
                <span style="font-size:13px;" >{{ props.item.intervalo }}</span>
              </template>

              <template v-slot:item.activo="props">

                <span v-if="props.item.activo==true" style="font-size:13px;" >Activo</span>
                <span v-if="props.item.activo==false" style="font-size:13px;" >Inactivo</span>
              </template>





            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='AnularTurno'"
            @click.prevent="anular(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";

export default {
  components: {
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase

  },
  created() {
    var json = {
      titulo: "Registro de Turnos",
      descripcion: "Registro de Turnos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "TranspRegistroTurnoNew",
        "TranspRegistroTurnoSave",
        "TranspRegistroTurnoRemove",
        "TranspRegistroTurnoAnular",
        "TranspRegistroTurnoPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {

    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspRegistroTurnoNew", this.new);
    // EventBus.$on("TranspRegistroTurnoSave", this.save);
    // EventBus.$on("TranspRegistroTurnoRemove", this.remove);
    // EventBus.$on("TranspRegistroTurnoAnular", this.remove);
    // EventBus.$on("TranspRegistroTurnoPrintList", this.imprimirList);
    EventBus.$on("emitTranspRegistroTurno", this.datostabla);
    //this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data:()=> ({
windowSize:{width:"auto", height:"auto"},

        TunoIdOrde:false,

        Rconductor:[], 
        RFicha:[],
        List:[],
        ListEnTurno:[],
        ListFueraTurno:[],
        RDatosFicha:[],
        modalHora:false,
        FichaVehiculoEnTurno:null,
        FichaVehiculoFueraTurno:null,


      ejecucion:true,
      VarHora:null,
      RegistroTurno: {
id : 0,
fecha:new Date().toISOString().substr(0,10),
horaInicial : null,
hora:null,
intervalo : 0,
viaje : null,
activo : true,
esSentidoIda : false,
borrado : false,
turnoDetalleList:[]

      },

      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [

        { text: 'Código', value: 'id',sortable:false},
        { text: 'fecha', value: 'fecha',sortable:false},
        { text: 'Entrada', value: 'horaInicial',sortable:false},
        { text: 'Intervalo', value: 'intervalo',sortable:false},
        { text: 'Intervalo', value: 'activo',sortable:false},
      ],

      list: [],
      search: "",
      acti_tb_data: false,


   notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
  
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
   
    datostabla(e) {

     this.TunoIdOrde=false

        console.log('datostabla')
        console.log(e)

this.ListFueraTurno=[]
this.ListEnTurno=[]
//hora: null
//horaInicial: "10:10:00"

      var json = JSON.parse(JSON.stringify(e))
      json.hora= JSON.parse(JSON.stringify(e.horaInicial.substr(0,5)))
      this.VarHora=JSON.parse(JSON.stringify(e.horaInicial.substr(0,5)))
      json.horaInicial=null
      json.fecha=new Date(e.fecha).toISOString().substr(0,10);
      this.VarHora=JSON.parse(JSON.stringify(e.horaInicial.substr(0,5)))
      this.RegistroTurno = JSON.parse(JSON.stringify(json))
      this.RegistroTurno.turnoDetalleList=[]

      if(json.turnoDetalleList.length>0){
      json.turnoDetalleList.forEach(ele => {

      ele.hora2=JSON.parse(JSON.stringify(ele.hora.substr(0,5)))
      ele.hora=null
      if(ele.fueraDeTurno==true){ this.ListFueraTurno.push(ele)}
      if(ele.fueraDeTurno==false){ this.ListEnTurno.push(ele)}  
      });

}

      this.acti_tb_data=false
    },

verificarsave(){

if(this.RegistroTurno.id!=0 && this.RegistroTurno.id!=null){
  this.save()

}else{
if (this.$refs.formTurno.validate()) {
 var headers=this.$store.getters.GetheadersAxios
    this.$axios.get(this.$hostname + this.$hName + "/turno/verificarturno/"+this.RegistroTurno.fecha+"/"+this.RegistroTurno.esSentidoIda, {headers:headers})
    .then(res => { 

      console.log('Verrr respuesta'),
      console.log(res.data)
      
       if(res.data==true){ this.alerta("Hay un Turno Activo en esta fecha", "error");}
       if(res.data==false){ this.save()}

      // if(res.data==false){this.GuardarPing(ping)}
      
    });
}else{this.alerta("Los campos en rojo son requeridos", "error");}
}



},

    save() {

if (this.$refs.formTurno.validate()) {

// if(this.ListEnTurno.length>0){this.RegistroTurno.turnoDetalleList +=this.ListEnTurno }
// if(this.ListFueraTurno.length>0){this.RegistroTurno.turnoDetalleList +=this.ListFueraTurno}

this.RegistroTurno.turnoDetalleList = this.ListEnTurno.concat(this.ListFueraTurno);

//ListFueraTurno,ListEnTurno

if(this.ejecucion==true){

  this.ejecucion=false
 
var Json=JSON.parse(JSON.stringify(this.RegistroTurno)) 

this.$axios
        .post(this.$hostname + this.$hName + "/turno/save/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

} 

}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },




NotifMessage() {



if(this.RegistroTurno.id!=0 && this.RegistroTurno.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea anular este registro?",
        Exec:"AnularTurno"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un turno para anular", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {


    },
    anular() {

      if (this.RegistroTurno.id!=0) {

var Json=JSON.parse(JSON.stringify(this.RegistroTurno)) 

this.$axios
        .post(this.$hostname + this.$hName + "/turno/anular/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamenteeee", "error"))

      }


  
    },
    imprimirList() {},
    newwnuevo() {
      this.RegistroTurno = {
       id : 0,
fecha:new Date().toISOString().substr(0,10),
horaInicial : null,
hora:null,
intervalo : 0,
viaje : null,
activo : true,
esSentidoIda : false,
borrado : false,
turnoDetalleList:[]
      },

      this.ListFueraTurno=[]
this.ListEnTurno=[]

      this.ejecucion=true
        EventBus.$emit("actualizaBarraBusqueda2");
    },


KeyUpIntTi(e){


  if(e.key){
     var mynumeral = require('numeral')
     this.RegistroTurno.codigo  = mynumeral(this.RegistroTurno.codigo).value()
     //console.log(mynumeral(""+this.mensajero.metadeenvios)).value()
     if(this.RegistroTurno.codigo==null){

this.RegistroTurno.codigo=0
     }
     
    }
    else{
     console.log("no se puede poner letras o signos")
    }


},


resetHora(){
if(this.ListEnTurno.length >0){
this.VarHora=this.RegistroTurno.hora
this.ListEnTurno.forEach(el => {
var h =this.VarHora.toString().substr(0,2)
var m= this.VarHora.toString().substr(3,4)
var a= parseInt(m)+parseInt(this.RegistroTurno.intervalo)
var sm=new Date().setHours( h,a,0)
this.VarHora=new Date(sm).toString().substr(16,5)

el.hora2=this.VarHora


});
}

},


BlurHoraVal(){
this.VarHora = JSON.parse(JSON.stringify(this.RegistroTurno.hora))


},


AddFichaEnTurno(){

  this.TunoIdOrde=true

if(this.FichaVehiculoEnTurno==null){
this.alerta("Seleccione una ficha de vehiculo", "error");
  return
}

// id : 0,
// fecha:new Date().toISOString().substr(0,10),
// horaInicial : null,
// intervalo : 10,
// viaje : null,
// activo : true,
// esSentidoIda : false,
// borrado : false,
// turnoDetalleList:[]


if(this.$refs.formTurno.validate()){ 


if(this.ListEnTurno.length==0){
this.VarHora=this.RegistroTurno.hora
}

var h =this.VarHora.toString().substr(0,2)
var m= this.VarHora.toString().substr(3,4)
var a= parseInt(m)+parseInt(this.RegistroTurno.intervalo)
var sm=new Date().setHours( h,a,0)
this.VarHora=new Date(sm).toString().substr(16,5)

var Jsn ={
   id : 0,
   ficha : this.FichaVehiculoEnTurno.ficha,
   hora2 : this.VarHora,
   turnoActivo : true,
   numeroTurno : 0,
   fueraDeTurno : false,
   turnoCerrado : false,
   borrado : false,
   ventaTicketList:[],
   ventaTicketList1:[],
   pagoBoletosSucursalesList:[],
   idchofer:this.FichaVehiculoEnTurno.conductor,
   vehiculo:this.FichaVehiculoEnTurno
}

this.ListEnTurno.push(Jsn)

}else{

this.alerta("los campos en rojo son requeridos", "error");

}


//deleListFueraTurno,deleListEnTurno,ListEnTurno,AddFichaEnTurno,AddFichaFueraTurno,FichaVehiculoEnTurno,FichaVehiculoFueraTurno,SelctCbFichaFueraTurno,SelctCbFichaEnTurno


},


deleListEnTurno(e){

 this.TunoIdOrde=false
const index =  this.ListEnTurno.indexOf(e)
var eliminar=confirm('Desea eliminar este Registro de la lista?') && this.ListEnTurno.splice(index, 1)

if(eliminar!=false){
this.resetHora()
}

},



AddFichaFueraTurno(){



//deleListFueraTurno,deleListEnTurno,ListEnTurno,AddFichaEnTurno,AddFichaFueraTurno,FichaVehiculoEnTurno,FichaVehiculoFueraTurno,SelctCbFichaFueraTurno,SelctCbFichaEnTurno

if(this.FichaVehiculoFueraTurno!=null){

var Jsn ={
   id : 0,
   ficha : this.FichaVehiculoFueraTurno.ficha,
   hora2 : this.VarHora,
   turnoActivo : true,
   numeroTurno : null,
   fueraDeTurno : true,
   turnoCerrado : false,
   borrado : false,
   ventaTicketList:[],
   ventaTicketList1:[],
   pagoBoletosSucursalesList:[],
   idchofer:this.FichaVehiculoFueraTurno.conductor,
   vehiculo:this.FichaVehiculoFueraTurno
}

this.ListFueraTurno.push(Jsn)

}else{

this.alerta("Seleccione una ficha de vehiculo parado", "error");


}

},

deleListFueraTurno(e){
const index =  this.ListFueraTurno.indexOf(e)
confirm('Desea eliminar este Registro de la lista?') && this.ListFueraTurno.splice(index, 1)

},



cargarConductor(){

var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/propietario/findallConductorActivos/",{headers:headers})
.then(res => {this.Rconductor = res.data})
.catch(error => console.log(error));



},

CargarRFicha() {


var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/vehiculo/findallActivos/",{headers:headers})
.then(res => {this.RFicha = res.data})
.catch(error => console.log(error));

    },

SelctCbFichaEnTurno() {

      if (this.FichaVehiculoEnTurno == null) {
        this.FichaVehiculoEnTurno = "";
      }

      if (this.FichaVehiculoEnTurno.id == undefined) {
       // this.alerta("Seleccione una Cuenta", "error");
        this.FichaVehiculoEnTurno = "";
        return;
      }

    },

SelctCbFichaFueraTurno() {
      if (this.FichaVehiculoFueraTurno == null) {
        this.FichaVehiculoFueraTurno = "";
      }

      if (this.FichaVehiculoFueraTurno.id == undefined) {
       // this.alerta("Seleccione una Cuenta", "error");
        this.FichaVehiculoFueraTurno = "";
        return;
      }

    },


FormatoFecha(params0) {
var params= new Date(params0).toISOString().substr(0,10);
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {


    var headers=this.$store.getters.GetheadersAxios
      
      await this.$axios.get(this.$hostname + this.$hName + "/turno/findall/", {headers:headers})
        .then(res => {this.list = res.data;});

    }

  },

  computed:{

    GetEjecucion(){

// -------- ActDescBtn -----------
if(this.RegistroTurno.id>0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

if(this.RegistroTurno.activo==false){
  this.EstadoBtn.anular=false
  this.EstadoBtn.guardar=false
  this.EstadoBtn.anular=false
}



}



if(this.RegistroTurno.id<=0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}
     
//EventBus.$emit("ActDesaBotones", objjct);
// -------- fn ActDescBtn -----------


// --------  Img -----------


  //  if(this.ControlTurno.persona.foto==null || this.ControlTurno.persona.foto.length==0 ){
  //    this.ControlTurno.persona.foto = require("@/assets/img/Nofoto.png");
  //  }
   
  //  else{
  //    if(this.Vehiculo.fotoVehiculo!=null || this.Vehiculo.fotoVehiculo.length >0 ){

  //      this.verImg= this.Vehiculo.fotoVehiculo

  //    }
  //  }
// -------- fn Img -----------



    }

  }


};
</script>

<style></style>
