<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Reporte Producción de boletos por unidades'"
  :descripcion="'Imprime Reporte Producción de boletos por unidades'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
  
  
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitTranspReporteProduccion'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>
<span class="font-weight-light" style="font-size: 20px;"> Reporte Producción de boletos por unidades</span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
   
  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="ReporteProduccion.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="Propietario:"
            :items="Rpropietario"
            item-text="nombre"
            v-model="ReporteProduccion.propietario"
            @focus="cargarPropietario"
            @blur="SelctCbPropietario"  
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-street-view"></i>
            </template>
          </v-combobox>

<v-switch
              class="mt-0"
              color="teal"
              inset 
              hide-details
              label="Resumido:"
              v-model="ReporteProduccion.Resumen"
            >
          <template v-slot:label>
         <strong v-if="ReporteProduccion.Resumen==true" style="color:#000000;">Resumido: Si</strong>
         <strong v-if="ReporteProduccion.Resumen==false" style="color:#000000;">Resumido: No</strong>
        </template>
            
            
            </v-switch>


</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="ReporteProduccion.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<v-combobox
                    v-model="ReporteProduccion.FichaVehiculo"
                    label=" Ficha Vehiculo:"
                    required
                    outlined
                    dense
                    
                    autocomplete="off"
                    :items="RFicha"
                    item-text="ficha"
                    @blur="SelctCbFicha"
                    @focus="CargarRFicha"
                  >            
<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="border: 1px solid #000000; font-size: 13px; "  class="pa-0 ma-0 py-0 my-0"   cols="12" md="12" sm="12" >
  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>Descripción: {{item.item.descripcion}}</b></p>
<span class="pa-0 ma-0 py-0 my-0">
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Ficha.: </b> {{item.item.ficha }} </span>
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Modelo: </b> {{item.item.modelo }} </span>
  <span class="pe-1" style="border: 1px dashed  #616161;"><b>Placa: </b> {{item.item.placa }} </span>
</span>
</v-col>
 
</template>

  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-bus"></i> 
 </template>   
 </v-combobox>




</v-col>
<!-- fn columna 2 -->


              
              </v-row>


            </v-form>
       </v-container>

<hr>
<ModalReporteFiltros
@DataReporteBasico="DDataReporteBasico"
:Datos="ReporteProduccion"
/>


</v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
    
  },
  created() {
    var json = {
      titulo: "Reporte Producción de boletos por unidades",
      descripcion: "Imprime Reporte Producción de boletos por unidades",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [
        "TranspReporteProduccionNew",
        "TranspReporteProduccionSave",
        "TranspReporteProduccionRemove",
        "TranspReporteProduccionAnular",
        "TranspReporteProduccionPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspReporteProduccionNew", this.new);
    // EventBus.$on("TranspReporteProduccionSave", this.save);
    // EventBus.$on("TranspReporteProduccionRemove", this.remove);
    // EventBus.$on("TranspReporteProduccionAnular", this.remove);
    // EventBus.$on("TranspReporteProduccionPrintList", this.imprimirList);
    EventBus.$on("emitTranspReporteProduccion", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },



 data:()=>  ({

      opcionFilFicha:'ficha',
      Rpropietario:[],
      RFicha:[],
      
      windowSize:{width:"auto", height:"auto"},
      ReporteProduccion: {

        nombre:'REPORTE PRODUCCIÓN DE BOLETOS POR UNIDADES',
        UrlImprimir: '/reportestrans/ImprimirProduccionBoletos', 
        UrlExel: '/reportestrans/ExcelProduccionBoletos', 
        UrlCorreo: '/reportestrans/CorreoProduccionBoletos', 
        propietario:null,
        FichaVehiculo:null,
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        Resumen:true

        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },  

      EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      

      aalert: {
        estado: false,
        color: null
      },
    
  }),



  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},



    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      await this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/findalltipoclientes",
          {
            usuario:JSON.parse(JSON.stringify(this.$user)) 
          }
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.ReporteProduccion = e;
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.ReporteProduccion)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
          JSON.parse(JSON.stringify(jsn))
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },
    remove() {},
    eliminar() { },
    anular() {},
    imprimirList() {},
   DDataReporteBasico(){},

    newwnuevo() {
      (this.ReporteProduccion = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


CargarRFicha() {

this.$axios.get(this.$hostname + this.$hName + "/vehiculo/findallActivos/",{headers:this.$store.getters.GetheadersAxios})
.then(res => {this.RFicha = res.data})
.catch(error => console.log(error));

    },


SelctCbFicha() {

 

      if (this.ReporteProduccion.FichaVehiculo == null) {
        this.ReporteProduccion.FichaVehiculo = "";
      }

      if (this.ReporteProduccion.FichaVehiculo.id == undefined) {
       // this.alerta("Seleccione una Cuenta", "error");
        this.ReporteProduccion.FichaVehiculo = "";
        return;
      }

    },






cargarPropietario(){

this.$axios.get(this.$hostname + this.$hName + "/propietario/findallPropietarioActivos/",{headers:this.$store.getters.GetheadersAxios})
.then(res => {this.Rpropietario = res.data})
.catch(error => console.log(error));


 },


 SelctCbPropietario(){

  if(this.ReporteProduccion.propietario==null){

    this.ReporteProduccion.propietario=''
  }

if(this.ReporteProduccion.propietario.id==undefined){

// this.alerta("Seleccione un Propietario", "error");
this.ReporteProduccion.propietario=''
return

}

},


BlurDesde(){

 
if(this.ReporteProduccion.desde.toString().length==0){
  this.ReporteProduccion.desde=new Date().toISOString().substr(0,10)
}

 },


 BlurHasta(){

if(this.ReporteProduccion.hasta.toString().length==0){
  this.ReporteProduccion.hasta=new Date().toISOString().substr(0,10)
}

 },






    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    }
  }
};
</script>

<style></style>
