<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
      <!-- ContenidoBase-Cuentas de Bancos -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Cuentas de Bancos'" :descripcion="'Crear y Modificar Cuentas de Bancos'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>
      <!-- fn ContenidoBase-Cuentas de Bancos -->
      <tr>
        <!-- BarraPrimaria-Cuentas de Bancos --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>
                </center>
                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/cuentabanco/findall'"
                  :nombre="'Cuentas Bancos'" :headers="[
                  { Text: 'Código', value: 'codigo' },
                  { Text: 'Banco', value: 'banco.nombre' },
                  { Text: 'Cuenta', value: 'cuenta' }]" :slotname="'item.descripcion'"
                  :emitnombre="'emitBANCuentaBanco'" :SeachNombre="'Búsqueda por descripcion '" :ContListSlot="[
                    { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Banco', NomValue: 'banco', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
                    { Text: 'Cuenta', NomValue: 'cuenta', Ctsl: 1, Type: 0, State: true },
                    { Text: 'Estado', NomValue: 'activo', Ctsl: 1, Type: 0.1, State: true },
                  ]" />
              </template>
            </v-tooltip>
          </div>
        </td>
        <!-- fn BarraPrimaria-Cuentas de Bancos ---------------------------------------------------------------------------------->
        <!-- contenido central --------------------------------------------------------------------------------->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
               <!-- Formulario-Cuentas de Bancos -->

            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="formBanco" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 ---------------------------------------->
                      <v-col cols="12" md="4" sm="4">


                        <!------------------------------------------- Banco-->

                        <v-combobox v-model="Bancos.banco" label="Banco:" required outlined dense
                          :rules="[$rules.required]" autocomplete="off" :items="RBanco" item-text="nombre"
                          @blur="SelctCbBanco" @focus="cargarBanco">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-university"></i>
                          </template>
                        </v-combobox>


                        <!-- ----------------------------- nombre -->
                        <v-text-field label="Cuenta de Banco:" autocomplete="off" dense outlined
                          :rules="[$rules.required]" v-model="Bancos.cuenta">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i>
                          </template>
                        </v-text-field>


                        <!-- -----------------------------  Balance -->
                        <VueAutonumeric autocomplete="off" label="Balance Inicial:" dense outlined
                          v-model.number="Bancos.balance">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>

                      </v-col>
                      <!-- fn columna 1---------------------------------------->


                      <!-- columna 2 ---------------------------------------->
                      <v-col cols="12" md="4" sm="4">



                        <!-- ----------------------------- Contacro -->
                        <v-text-field label="Contacto" autocomplete="off" dense outlined v-model="datosBantos.contacto"
                          disabled>

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-location-arrow"></i>
                          </template>
                        </v-text-field>

                        <!-- ----------------------------- telefonos -->
                        <v-text-field label="teléfono:" dense outlined autocomplete="off" disabled maxlength="13"
                          v-mask="'(###)###-####'" v-model="datosBantos.telefonos">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>


                        <v-switch label="Cheque impreso" v-model="Bancos.chequeimpreso"></v-switch>

                      </v-col>
                      <!-- fn columna 2 ---------------------------------------->

                      <!--  columna 3 ---------------------------------------->

                      <v-col cols="12" md="4" sm="4">

                        <span>
                          <b>Nota:</b><span style="color: #C62828;"> Para seleccionar el formato de cheque debe tener
                            activado el programa de impresión de cheques.</span>
                        </span>
                        <!-- ------------------------------------------------- Formato Cheque -->
                        <v-combobox autocomplete="off" dense outlined label="Formato Cheque:" :items="RformatoCheque"
                          @focus="cargarFormatos" v-model="Bancos.formatoCheque">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-file-text-o"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- fn columna 3 ---------------------------------------->
                    </v-row>
                  </v-form>
                </div>
              </v-card>

              <v-col cols="12" md="12" sm="12">
                <v-row>
                  <!-- Columna 1------------------------------------------ -->
                  <v-col cols="12" md="6" sm="6">
                    <v-card>
                      <v-toolbar flat dense color="#BDBDBD">

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-btn v-on="on" @click.prevent="acti_Nueva_DepositoTransito = true" color="green" fab
                              x-small dark>
                              <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                            </v-btn>


                          </template>
                          <span>Agregar depósitos en transito</span>
                        </v-tooltip>


                        <v-toolbar-title class="font-weight-light">
                          <v-card-title>DEPÓSITOS EN TRÁNSITO</v-card-title>
                        </v-toolbar-title>


                      </v-toolbar>
                      <v-data-table class="elevation-1" dense
                        :headers="[{ text: 'Documento', value: 'documentobancos.nombrecorto', width: 'auto' }, { text: 'REFERENCIA', value: 'referencia', width: 'auto' }, { text: 'FECHA', value: 'nuevaFecha', width: 'auto' },
                        { text: 'VALOR', value: 'valor', width: 'auto' }, { text: 'actions', value: 'action', width: 'auto' }]"
                        :items="Bancos.listTransitoDeposito" :items-per-page="-1">
                        <template v-slot:item.action="{ item }">

                          <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                          <v-btn fab @click.prevent="deleteDeposito(item)" x-small color="red darken-3"><i
                              style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                        </template>

                        <template v-slot:item.valor="props">
                          <span>{{ currencyFormatter(props.item.valor) }}</span>
                        </template>


                        <template v-slot:body.append v-if="windowSize.width > 600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong class="d-flex justify-end"
            style="font-size:15px; color: #00838F;">Total:</strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(totales.deposito) }} </strong></td>

        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.TotalProd.importe)}} </strong></td> -->
        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
        <td class="border-top-bottom"><strong></strong></td>
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width <= 600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>Total: {{totales.deposito}}</span>
    </v-list-item-content>
  </v-list-item>

</template>

</v-data-table>
</v-card>




</v-col>
<!-- Columna 2------------------------------------------ -->
<v-col cols="12" md="6" sm="6">
  <v-card>
    <v-toolbar flat dense color="#BDBDBD">

      <v-tooltip top>
        <template v-slot:activator="{ on }">

          <v-btn v-on="on" @click.prevent="acti_Nueva_TransitoCheque = true" color="green" fab x-small dark>
            <i style="font-size: 20px;" class="fa fa-plus-square"></i>
          </v-btn>


        </template>
        <span>Agregar Cheques en transito</span>
      </v-tooltip>


      <v-toolbar-title class="font-weight-light">
        <v-card-title>CHEQUES EN TRÁNSITO</v-card-title>
      </v-toolbar-title>


    </v-toolbar>
    <v-data-table class="elevation-1" dense :headers="[{ text: 'Documento', value: 'documentobancos.nombrecorto', width: 'auto' }, { text: 'REFERENCIA', value: 'referencia', width: 'auto' }, { text: 'FECHA', value: 'fechaNueva', width: 'auto' },
    { text: 'VALOR', value: 'valor', width: 'auto' }, { text: 'actions', value: 'action', width: 'auto' }]"
      :items="Bancos.listTransitoCheque" :items-per-page="-1">
      <template v-slot:item.action="{ item }">

        <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
        <v-btn fab @click.prevent="deleteCheques(item)" x-small color="red darken-3"><i style="font-size: 20px;"
            class="fa fa-trash-o"></i></v-btn>

      </template>

      <template v-slot:item.valor="props">
        <span>{{ currencyFormatter(props.item.valor) }}</span>
      </template>


      <template v-slot:body.append v-if="windowSize.width > 600">
        <tr>
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-top-bottom"><strong></strong></td>
          <td class="border-top-bottom"><strong class="d-flex justify-end"
              style="font-size:15px; color: #00838F;">Total:</strong></td>
          <!-- <td class="border-top-bottom"  ><strong></strong></td> -->

          <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
              {{ currencyFormatter(totales.cheque) }} </strong></td>

          <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.TotalProd.importe)}} </strong></td> -->
          <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
          <td class="border-top-bottom"><strong></strong></td>
        </tr>
      </template>

      <template v-slot:footer v-if="windowSize.width <= 600">
        <v-list-item style="font-size: 70%;" class="white--text">
          <v-list-item-content>
            <span>Total: {{ currencyFormatter(totales.cheque) }}</span>

          </v-list-item-content>
        </v-list-item>

      </template>

    </v-data-table>
  </v-card>
</v-col>


</v-row>
</v-col>


</div>
<!-- fn Formulario-Cuentas de Bancos -->
</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

    <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ Modal-Cheque en transito -->
<v-dialog transition="fab-transition" label="Modal agregar " v-model="acti_Nueva_TransitoCheque" persistent
  max-width="90%" max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> CREAR CHEQUES EN TRÁNSITO </v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="cerrarModalTransitoCheque()">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>
      <v-form ref="listTransitoCheque" v-model="valid" lazy-validation>
        <v-row>

          <!------------------------------------------ Documentos -->
          <v-col cols="12" md="6" sm="6">
            <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
              item-text="descripcion" :rules="[$rules.required]" v-model="TransitoCheque.documentobancos"
              @blur="SelctCbDocumento" @focus="cargarDocumento">
              <template v-slot:prepend>
                <i style="font-size: 30px;" class="fa fa-book"></i>
              </template>
            </v-combobox>

            <!--------------------------------------------------------- fecha -->
            <v-text-field dense outlined type="date" label="* Fecha:" v-model="TransitoCheque.fecha"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

            </v-text-field>


            <!-------------------------------------------------------- Referencia -->
            <v-text-field label="* Beneficiario:" dense outlined v-model="TransitoCheque.beneficiario"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-industry"></i>
              </template>
            </v-text-field>



          </v-col>


          <!------------------------------------------------------ NCF -->
          <v-col cols="12" md="6" sm="6">

            <!-------------------------------------------------------- Referencia -->
            <v-text-field label="* Referencia:" dense outlined v-model="TransitoCheque.referencia"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-map-signs"></i>
              </template>
            </v-text-field>

            <!-- -----------------------------------------valor -->
            <VueAutonumeric label="* Valor:" dense outlined autocomplete="off" :rules="[$rules.required]"
              v-model.number="TransitoCheque.valor">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </VueAutonumeric>



          </v-col>

        </v-row>
      </v-form>
    </v-card-text>

    <hr />
    <!--    -->
    <v-btn-toggle rounded class="d-flex justify-end">

      <v-btn dark color="green darken-1" @click.prevent="newModalAddCheque">
        <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
        Salvar
      </v-btn>

    </v-btn-toggle>
    <hr />
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Cheque en transito -->

<!-- ------------------------------------------------------ Modal-Deposito en transito -->
<v-dialog transition="fab-transition" label="Modal agregar " v-model="acti_Nueva_DepositoTransito" persistent
  max-width="90%" max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"> CREAR DEPOSITO EN TRÁNSITO </v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small @click="cerrarModalTransitoDeposito()">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>
      <v-form ref="formTransitoDeposito" v-model="valid" lazy-validation>
        <v-row>

          <!------------------------------------------ Documentos -->
          <v-col cols="12" md="6" sm="6">
            <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumentoDeposito"
              item-text="descripcion" :rules="[$rules.required]" v-model="TransitoDeposito.documentobancos
              " @blur="SelctCbDocumentoTransitoDeposito" @focus="cargarDocumentoDepositoTransito">
              <template v-slot:prepend>
                <i style="font-size: 30px;" class="fa fa-book"></i>
              </template>
            </v-combobox>

            <!--------------------------------------------------------- fecha -->
            <v-text-field dense outlined type="date" label="* Fecha:" v-model="TransitoDeposito.fecha"
              :rules="[$rules.required]">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

            </v-text-field>


          </v-col>


          <!------------------------------------------------------ NCF -->
          <v-col cols="12" md="6" sm="6">

            <!-------------------------------------------------------- Referencia -->
            <v-text-field label="* Referencia:" dense outlined v-model="TransitoDeposito.referencia"
              :rules="[$rules.required]">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-map-signs"></i>
              </template>
            </v-text-field>

            <!-- -----------------------------------------valor -->
            <VueAutonumeric label="* Valor:" dense outlined autocomplete="off" :rules="[$rules.required]"
              v-model.number="TransitoDeposito.valor">
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-usd"></i>
              </template>
            </VueAutonumeric>



          </v-col>

        </v-row>
      </v-form>
    </v-card-text>

    <hr />
    <!--   -->
    <v-btn-toggle rounded class="d-flex justify-end ">

      <v-btn dark color="green darken-1" @click.prevent="newModalAddDeposito">
        <i style="font-size: 28px;" class="fa fa-floppy-o"> </i>
        Salvar
      </v-btn>

    </v-btn-toggle>
    <hr />
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Deposito en transito -->





</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ tb-data todo general -->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
  transition="fab-transition">
  <v-card>
    <v-toolbar flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">


        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
          </v-text-field>
        </v-card-title>

        <v-data-table :headers="[
          { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Banco', value: 'banco.nombre', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Cuenta', value: 'cuenta', sortable: false, class: ['no-gutters black--text'] },
          { text: 'Estado', value: 'activo', sortable: false, class: ['no-gutters black--text'] },
        ]" dense :items="list" :search="search" @click:row="datostabla">
          <template v-slot:item.activo="props">
            <span v-if="props.item.activo == true">Activo</span>
            <span v-if="props.item.activo == false">Anulado</span>
          </template>
        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data todo general -->
<!-- ------------------------------------------------------ Modal-notificacion general -->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec = 'eliminarBancos'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>



      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn Modal-notificacion general  -->



<!------------otras funciones---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>



<!-- ------------------------------------------------------ Modal-Reporte Basico -->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal-Reporte Basico -->




<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
  },

  created() {
    var json = {
      titulo: "Cuentas de Bancos",
      descripcion: "Crear y Modificar Cuentas de Bancos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      accion: [
        "BANCuentaBancoNew",
        "BANCuentaBancoSave",
        "BANCuentaBancoRemove",
        "BANCuentaBancoAnular",
        "BANCuentaBancoPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("BANCuentaBancoNew", this.new);
    // EventBus.$on("BANCuentaBancoSave", this.save);
    // EventBus.$on("BANCuentaBancoRemove", this.remove);
    // EventBus.$on("BANCuentaBancoAnular", this.remove);
    // EventBus.$on("BANCuentaBancoPrintList", this.imprimirList);
    EventBus.$on("emitBANCuentaBanco", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'CUENTAS DE BANCOS',
        UrlImprimir: '/cuentabanco/Imprimir',
        UrlExel: '/cuentabanco/Excel',
        UrlCorreo: '/cuentabanco/Correo',

      },



      RBanco: [],
      RformatoCheque: [],
      RDocumento: [],
      RDocumentoDeposito: [],
      ListaTiposClientes: [],
      ListDepositoTransito: [],
      ListTransitoCheque: [],

      acti_tb_data: false,
      acti_Nueva_DepositoTransito: false,
      acti_Nueva_TransitoCheque: false,

      totales:{
deposito:0,
cheque:0
      },

      windowSize: { width: "auto", height: "auto" },
      datosBantos: { contacto: "", telefonos: "" },
      Bancos: {
        codigo: 0,
        cuenta: "",
        descripcion: "",
        direccion: "",
        ciudad: "",
        telefonos: "",
        contacto: "",
        banco: null,
        activo: true,
        balance: 0.00,
        chequeimpreso: false,
        listTransitoCheque: [],
        listTransitoDeposito: []
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },



      TransitoCheque: {
        chequesPK: { secuencia: 0, documento: 0 },
        documento: null,
        referencia: "",
        beneficiario: "",
        fecha: new Date().toISOString().substr(0, 10),
        valor: 0.00
      },


      TransitoDeposito: {
        depositosPK: { secuencia: 0, documento: 0 },
        documentobancos: null,
        referencia: "",
        fecha: new Date().toISOString().substr(0, 10),
        valor: 0.00
      },



      text: "",
      valid: false,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Codigo", value: "codigo" }, { text: "Cuenta", value: "cuenta" }, { text: "Banco", value: "banco.nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,

    onResize(e) {
      // this.windowSize=e
    },
    // Eliminando la fila de la tabla.
    deleteDeposito(item) {
      if (this.Bancos.listTransitoDeposito.length > 0) {
        const index = this.Bancos.listTransitoDeposito.indexOf(item);
        confirm("estas seguro que desea eliminar este registro de esta tabla?") && this.Bancos.listTransitoDeposito.splice(index, 1);
      }
      this.CalcularTotalesDepositoCheque()
    },
    // Eliminando la fila de la tabla.
    deleteCheques(item) {
      if (this.Bancos.listTransitoCheque.length > 0) {
        const index = this.Bancos.listTransitoCheque.indexOf(item);
        confirm("estas seguro que desea eliminar este registro de esta tabla?") && this.Bancos.listTransitoCheque.splice(index, 1);
      }
      this.CalcularTotalesDepositoCheque()
    },
    // Abriendo una tabla y luego filtrando la tabla.
    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()
      }

    },
    async cargarTodo() {
      //this.$store.commit('MutationDataRouter',rou )
      await this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cuentabanco/findall",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.list = res.data))
        .catch(error => console.log(error));
    },
    cargarImpresora() {

    },
    // Llamar a una API y obtener los datos y luego configurar los datos en la variable.
    datostabla(e) {
      this.datosBantos = { contacto: "", telefonos: "" }
      this.Bancos = e;
      this.datosBantos.contacto = e.banco.contacto;
      this.datosBantos.telefonos = e.banco.telefonocontacto;
      this.acti_tb_data = false


      this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/depositos-transito/' + e.cuenta.trim(),
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListDepositoTransito = res.data;
          this.Bancos.listTransitoDeposito = res.data;
          var _this=this;
        setTimeout(function(){ _this.CalcularTotalesDepositoCheque() }, 300);
        });

      this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/cheques-transito/' + e.cuenta.trim(),
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListTransitoCheque = res.data;
          this.Bancos.listTransitoCheque = res.data;
        var _this=this;
        setTimeout(function(){ _this.CalcularTotalesDepositoCheque() }, 300);
        });

        
    },
    // Envío de una solicitud POST al servidor.
    save() {
      EventBus.$emit("loading", true);
      if (this.$refs.formBanco.validate()) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/cuentabanco/salvar", this.Bancos,
            { headers: this.$store.getters.GetheadersAxios })
          .then(res => { this.alerta("Dato guardado correctamente!", "bien") })
          .catch(error => {
            EventBus.$emit("loading", false);
            this.alerta("Dato no guardado", "error")
          });
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

    },

    // Creación de un mensaje de notificación.
    NotifMessage() {
      if (this.Bancos.codigo != 0 && this.Bancos.codigo != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar banco?",
          Exec: "eliminarBancos"
        }
        this.Exec_notif(notif)
      } else {
        this.alerta("Seleccione un bancos para eliminar", "error")
      }
    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    // Realizar una llamada al servidor para comprobar si se están utilizando los datos. Si no se está
    // utilizando, eliminará los datos.
    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.Bancos)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }
      if (this.Bancos.codigo != 0) {
        this.$axios.get(this.$hostname + this.$hName + '/cuentabanco/isUsada/' + this.Bancos.cuenta.trim(),
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data) {
              this.alerta("No puede eliminar Esta cuenta de banco ya esta siendo usada !!", "error")
              return;
            }

            EventBus.$emit("loading", true);
            this.$axios
              .post(
                this.$hostname +
                this.$hName +
                "/cuentabanco/borrar", this.Bancos,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then(res => {
                if (res.data == true) {
                  { this.alerta("Dato eliminado correctamente", "bien") }
                } else {
                  this.alerta("Dato no eliminado", "error")
                }


              })
              .catch(error => { this.alerta("Dato no eliminado", "error") }

              );
          });
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.Bancos)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/cuentabanco/borrar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    // Muestra un modal con la opcion de imprecion
    imprimirList() { this.dialogimprimirList = true },
    // Retorna los  datos del componente ModalReporteFiltros
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    // Creando un nuevo objeto y asignándolo a la variable Bancos.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.Bancos = {
        codigo: 0,
        cuenta: "",
        descripcion: "",
        direccion: "",
        ciudad: "",
        telefonos: "",
        contacto: "",
        banco: null,
        activo: true,
        balance: 0.00,
        chequeimpreso: false,
        listTransitoCheque: [],
        listTransitoDeposito: []
      }

      this.totales={
deposito:0,
cheque:0
      }


      EventBus.$emit("loading", false);
      //  EventBus.$emit("actualizaBarraBusqueda2");
    },


    // Creando un nuevo objeto y asignándolo a la variable TransitoDeposito.
    newModalTransitoDeposito() {
      this.TransitoDeposito = {
        depositosPK: { secuencia: 0, documento: 0 },
        documentobancos: null,
        referencia: "",
        fecha: fechaNueva(),
        valor: 0.00
      }
    },
    // Empujando los datos a la matriz listTransitoDeposito.
    newModalAddDeposito() {
      if (this.$refs.formTransitoDeposito.validate()) {
        var mond = this.$store.getters.GetConfiguracionGeneral.config.monedabase
        this.TransitoDeposito.moneda = mond
        this.TransitoDeposito.tasa = mond.tasa
        this.TransitoDeposito.nuevaFecha = this.TransitoDeposito.fecha
        this.TransitoDeposito.fecha = this.TransitoDeposito.fecha+"T04:00"
        this.Bancos.listTransitoDeposito.push(this.TransitoDeposito);
        this.newModalTransitoDeposito();
      } else {
        this.alerta("Los campos con el sombreado rojo son requeridos para completar la operacion", "error")
      }
    },
    cargarFormatos() {
      try {
        this.$axios.get('http://localhost:15999/impresora/cheques/formatos')
          .then(res => {
            this.RformatoCheque = res.data;
          });
      } catch (err) {
        console.log(err)
      }
    },
 // Cerrar una ventana modal.
    cerrarModalTransitoDeposito() {
      this.acti_Nueva_DepositoTransito = false
      this.CalcularTotalesDepositoCheque()
    },





    // Creando un nuevo objeto y asignándolo a la variable TransitoCheque.
    newModalTransitoCheque() {
      this.TransitoCheque = {
        chequesPK: { secuencia: 0, documento: 0 },
        documentobancos: null,
        referencia: "",
        beneficiario: "",
        fecha: fechaNueva(),
        valor: 0.00,
        anulado:false
      }
    },

    // Empujando los datos a la matriz.
    newModalAddCheque() {
      if (this.$refs.listTransitoCheque.validate()) {
        var mond = this.$store.getters.GetConfiguracionGeneral.config.monedabase
        this.TransitoCheque.moneda = mond
        this.TransitoCheque.tasa = mond.tasa
        this.TransitoCheque.nuevaFecha = this.TransitoCheque.fecha
        this.TransitoCheque.fecha = this.TransitoCheque.fecha+"T04:00"
        this.TransitoCheque.anulado=false
        this.Bancos.listTransitoCheque.push(this.TransitoCheque)
        this.newModalTransitoCheque()
      } else {
        this.alerta("Los campos con el sombreado rojo son requeridos para completar la operacion", "error")

      }


    },



    cerrarModalTransitoCheque() {
      this.acti_Nueva_TransitoCheque = false
      this.CalcularTotalesDepositoCheque()
    },

   // Llamar a la API y obtener los datos de la API.
    cargarBanco() {
      this.$axios.get(this.$hostname + this.$hName + '/bancos/findall',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RBanco = res.data))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbBanco() {

      if (this.Bancos.banco == null) {

        this.Bancos.banco = ''
      }

      if (this.Bancos.banco.codigo == undefined) {

        this.alerta("Seleccione un Banco", "error");
        this.Bancos.banco = ''
        return
      }
      this.datosBantos.contacto = this.Bancos.banco.contacto;
      this.datosBantos.telefonos = this.Bancos.banco.telefonocontacto;

    },



  // Llamar a una API y obtener los datos de la API.
    cargarDocumentoDepositoTransito() {

      this.$axios.get(this.$hostname + this.$hName + '/documentobanco/documentodepositoTransito',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoDeposito = res.data))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumentoTransitoDeposito() {
      if (this.TransitoDeposito.documentobancos == null) {

        this.TransitoDeposito.documentobancos = ''
      }

      if (this.TransitoDeposito.documentobancos.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.TransitoDeposito.documentobancos = ''
        return

      }

    },

    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentobanco/documentochequesTransito',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },


    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbDocumento() {
      if (this.TransitoCheque.documentobancos == null) {

        this.TransitoCheque.documentobancos = ''
      }

      if (this.TransitoCheque.documentobancos.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.TransitoCheque.documentobancos = ''
        return

      }

    },

    convertirMayuscula(e) {
      if (e.target.value.length > 0) {
        this.Bancos.nombre = JSON.parse(JSON.stringify(e.target.value.toString().toUpperCase()))
        console.log(e.target.value.toString().toUpperCase())

      }

    },



   /* currencyFormatter(number) {
      //return this.formatNumber(params) + ".00";
      if (number == null) { number = 0 }
      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


      ///return this.formatNumber(params);

      //return params;
    },*/

// Recorriendo las matrices listTransitoDeposito y listTransitoCheque y sumando los valores en la
// propiedad valor.
CalcularTotalesDepositoCheque(){
  this.totales={
deposito:0,
cheque:0
      }
  
this.Bancos.listTransitoDeposito .forEach(element => {
  this.totales.deposito+= element.valor
});


this.Bancos.listTransitoCheque .forEach(element => {
  this.totales.cheque+= element.valor
});



},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },
    // Un método que se llama cuando se hace clic en un botón 
    //y muestra un modal con una tabla de datos .
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

   // Llamar a la API y obtener los datos.
    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/cuentabanco/findall",
          { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {

    ActDescBtn() {
      if (this.Bancos.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      if (this.Bancos.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }
  }





};
</script>

<style>

</style>
