<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
<!-- ContenidoBase-Monedas -->
      <ContenidoBase :titulo="'Monedas'" :descripcion="'Crear, Modificar y Desactivar Mondas'" :EstadoBtn="EstadoBtn"
        @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
        @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
<!-- fn  ContenidoBase-Monedas -->
      <tr>
        <!-- BarraPrimaria-Monedas --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">



            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <!-- <v-navigation-drawer >  -->
                <center>
                  <v-btn-toggle></v-btn-toggle>
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i
                      style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn>

                </center>


                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/monedas/findall/'"
                  :nombre="'Monedas'" :emitnombre="'emitMonedas'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Descripción', value: 'descripcion' },
                    { text: 'Cliente', value: 'cliente.nombre' },
                    { text: 'Tasa', value: 'tasa' },
                    { text: 'Tasa de compra', value: 'tasaCompra' },
                    { text: 'Tasa de Venta', value: 'tasaVenta' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'cliente', NomValue: 'cliente', Ctsl: 2, Type: 0, State: true },
  { Text: 'Nombre Corto', NomValue: 'nombrecorto', Ctsl: 1, Type: 0, State: true },
  { Text: 'Tasa', NomValue: 'tasa', Ctsl: 1, Type: 2, State: true },
  { Text: 'Tasa de compra', NomValue: 'tasaCompra', Ctsl: 1, Type: 2, State: true },
  { Text: 'Tasa de Venta', NomValue: 'tasaVenta', Ctsl: 1, Type: 2, State: true },
  { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.1, State: true },
]" />

                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/Moneda/findall'"
              :nombre="'Monedas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitMonedas'"
              :SeachNombre="'Búsqueda por descripción de Moneda'"
              :slotlist="slotlist"

            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria-Monedas ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">

              <v-card color="grey lighten-3">
                <div class="px-2">
                          <!-- Formulario-Monedas -->
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>

                      <input type="number" v-model="moneda.codigo" style="display:none;" />


                      <!-- fila 1 ------------------------------>

                      <!-- Descripción:-->
                      <v-col cols="12" md="9" sm="9">

                        <v-text-field label="* Descripción:" dense outlined autocomplete="off"
                          :rules="[$rules.required]" v-model="moneda.descripcion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- fn Descripción:-->

                      <!-- NombreCorto:-->
                      <v-col cols="12" md="3" sm="3">
                        <v-text-field label="* Simbología:" dense outlined autocomplete="off" :rules="[$rules.required]"
                          v-model="moneda.nombrecorto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <!-- fn NombreCorto -->


                      <!-- fn fila 1 ------------------------------>


                      <!-- fila 2 ------------------------------>


                      <!-- Tasa-->
                      <v-col cols="12" md="6" sm="6">
                        <VueAutonumeric label="* Tasa:" dense outlined autocomplete="off" v-model.number="moneda.tasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>




                      </v-col>
                      <!-- fn Tasa -->

                      <!-- Activo:-->
                      <v-col cols="12" md="3" sm="3">
                        <v-switch v-model="moneda.activada" label="Activo" color="success" hide-details></v-switch>
                      </v-col>
                      <!-- fn Activo-->





                      <!-- fn fila2 -------------------------->



                      <!-- fila 3 ------------------------------>
                      <v-col style="background-color: #BBDEFB;" cols="12" md="12">
                        <p>Las tasas de esta sección serán utilizadas unicamente para el cambio de divisas</p>
                        <v-row>

                          <!-- Tasa-->
                          <v-col cols="6" md="6">
                            <VueAutonumeric label="Tasa de compra:" dense outlined autocomplete="off"
                              v-model.number="moneda.tasaCompra">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>
                          </v-col>
                          <!-- fn Tasa -->

                          <!-- Tasa-->
                          <v-col cols="6" md="6">
                            <VueAutonumeric label="Tasa de venta:" dense outlined autocomplete="off"
                              v-model.number="moneda.tasaVenta">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-usd"></i>
                              </template>
                            </VueAutonumeric>

                          </v-col>
                          <!-- fn Tasa -->

                        </v-row>
                      </v-col>
                      <!-- fn fila 3 -------------------------->

                    </v-row>
                  </v-form>
                   <!-- Formulario-Monedas -->

                </div>
              </v-card>


            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general -->
    <v-dialog label="myAlert" v-model="acti_tb_data" transition="dialog-bottom-transition" fullscreen hide-overlay>
      <v-card>

        <v-toolbar dense flat color="#BDBDBD">

          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="false" v-on="on" dark small color="deep-purple accent-2" @click.prevent="">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado</v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>


          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Descripción', value: 'descripcion', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Nombre Corto:', value: 'nombrecorto', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Tasa', value: 'tasa', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Tasa de compra:', value: 'tasaCompra', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Tasa de Venta:', value: 'tasaVenta', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Estado:', value: 'activada', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla">

              <template v-slot:item.tasa="props">
                <span>{{ currencyFormatter(props.item.tasa) }}</span>
              </template>

              <template v-slot:item.tasaCompra="props">
                <span>{{ currencyFormatter(props.item.tasaCompra) }}</span>
              </template>

              <template v-slot:item.tasaVenta="props">
                <span>{{ currencyFormatter(props.item.tasaVenta) }}</span>
              </template>

              <template v-slot:item.activada="props">
                <span v-if="props.item.activada == true">Activo</span>
                <span v-if="props.item.activada == false">Anulado</span>
              </template>


            </v-data-table>
          </v-card>


        </v-card-text>

      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->

    <!-- ------------------------------------------------------ Modal-notificacion general-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarMoneda'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>




          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>



</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import { EventBus } from '../../event-bus'
import ContenidoBase from "../../components/ContenidoBase";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";


export default {

  components: {
    VueAutonumeric,
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase

  },

  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitMonedas", this.datostabla);
    // EventBus.$on("MonedasNew", this.new);
    // EventBus.$on("MonedasSave", this.save);
    // EventBus.$on("MonedasRemove", this.remove);
    // EventBus.$on("MonedasAnular", this.anular);
    // EventBus.$on("MonedasPrintList", this.imprimirList);
    this.filtro()



  },

  created() {
    var json = {
      titulo: "Monedas",
      descripcion: "Crear, Modificar y Desactivar Mondas",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "MonedasNew",
        "MonedasSave",
        "MonedasRemove",
        "MonedasAnular",
        "MonedasPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  // destroyed(){
  //   location.reload()
  // },

  data: () => ({

    windowSize: { width: "auto", height: "auto" },

    Rrutas: [
      {
        codigo: 0,
        desc: '1'
      },
      {
        codigo: 1,
        desc: '2'
      },
      {
        codigo: 2,
        desc: '3'
      },
      {
        codigo: 3,
        desc: '4'
      },
      {
        codigo: 5,
        desc: '5'
      },

    ]

    , valid: true,

    moneda: {
      codigo: 0,
      descripcion: null,
      nombrecorto: null,
      tasa: 0,
      tasaCompra: 0,
      tasaVenta: 0,
      activada: true,
      borrado: false
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: true,
      nuevo: true,
      guardar: true,
      eliminar: false,
      anular: false,
      imprimir: true,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    headers: [{ text: "Monedas", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      { dt0: "descripcion", dt1: null, dt2: null, dt3: 1, dt4: "Descripción:" },
      { dt0: "nombrecorto", dt1: null, dt2: null, dt3: 1, dt4: "Nombre Corto:" },
      { dt0: "tasa", dt1: null, dt2: null, dt3: 5, dt4: "Tasa:" },
      { dt0: "tasaCompra", dt1: null, dt2: null, dt3: 5, dt4: "Tasa de compra:" },
      { dt0: "tasaVenta", dt1: null, dt2: null, dt3: 5, dt4: "Tasa de Venta:" },
      { dt0: "activada", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],




    list: [],
    search: "",
    acti_tb_data: false,

    ejecucion: true,
    aalert: {
      estado: false,
      color: null
    },

    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },



    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false

  }),

  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter,




   // Abrir una tabla y filtrar los datos.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }


    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },


    // Restablese la variable del formulario.
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.moneda = {
        codigo: 0,
        descripcion: null,
        nombrecorto: null,
        tasa: 0,
        tasaCompra: 0,
        tasaVenta: 0,
        activada: true,
        borrado: false
      },

        this.ejecucion = true
      EventBus.$emit("actualizaBarraBusqueda2")

    },

    // Guardando los datos en la base de datos.
    save() {


      if (this.$refs.form.validate()) {


        if (this.ejecucion == true) {


          this.ejecucion = false

          if (this.moneda.tasaCompra == null) {
            this.moneda.tasaCompra = 0.00;
          }
          if (this.moneda.tasaVenta) {
            this.moneda.tasaVenta = 0.00;
          }

          this.$axios
            .post(this.$hostname + this.$hName + "/monedas/save/", this.moneda, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { console.log('Ressssspuuuu'), console.log(res.data), this.alerta("Dato guardado correctamente!", "bien") })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));

        }
      } else {

        this.alerta("los campos en rojo son requeridos", "error");

      }

    },


   // Crear un objeto de notificación y luego llamar al método Exec_notif().
    NotifMessage() {

      if (this.moneda.codigo != 0 && this.moneda.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarMoneda"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione una moneda para eliminar", "error") }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },


   // Hacer una solicitud a una api para borrar una moneda.
    eliminar() {
      if (this.moneda.codigo != 0 && this.moneda.codigo != null) {

        if (this.$refs.form.validate()) {

          this.$axios
            .post(this.$hostname + this.$hName + "/monedas/borrado/", this.moneda, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage, res.data.estado) })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));

        }
      }


    },

    anular() {
    },

   // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/monedas/imprimir/", { headers: headers }).then(res => {
        console.log('RespuestaImprimir'), console.log(res.data)
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/

          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'moneda');

      });
    },

   // Un método que muestra los datos en el formulario.
    datostabla(e) {

      this.moneda = e;
      this.acti_tb_data = false;

      //console.log(e)
    },

   // Un método que se llama cuando se hace clic en un botón y muestra una tabla.
    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data
      this.filtro()

    },


   // Llamar a la API y obtener los datos.
    async filtro() {
      var headers = this.$store.getters.GetheadersAxios

      await this.$axios.get(this.$hostname + this.$hName + "/monedas/findall/", { headers: headers })
        .then(res => { this.list = res.data; });
    }

  },


  computed: {

    ActDescBtn() {


      if (this.moneda.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }


        // var objjct = {
        //     save: true,
        //     eliminar: true,
        //     anular: false,
        //     nuevo: true,
        //     imprimir: true,
        //     vercuenta:false, 
        //   };

      }


      if (this.moneda.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }

        // var objjct = {
        //       save: true,
        //       eliminar: false,
        //       anular: false,
        //       nuevo: true,
        //       imprimir: true,
        //       vercuenta:false, 
        //     };


      }


      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      //EventBus.$emit("ActDesaBotones", objjct);
    }





  }








}
</script>

<style>

</style>