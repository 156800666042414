<template>
   <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" style="border: 1px solid #000000;">{{ GetEjecucion }}
     <v-toolbar dark color="indigo darken-4"  >
      <div class="pa-0 ma-0 py-0 my-0 px-4"  >
      <!-- Btn-Accion-Contenido cols="1" md="1" sm="1"-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-toolbar-title v-on="on"> <v-btn fab @click.prevent="methBtnBarraAccionFactura()" x-small color="transparent"><i style="font-size: 20px;" class="fa fa-bars"></i></v-btn> </v-toolbar-title>
            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Barra accion factura: </span></i>
          </v-tooltip>
        <!-- fn Btn-Accion-Contenido -->
        </div>
          <!-- cols 1 Btn-Contenido 1 -->
        <v-col v-if="windowSize.width > 1060" class="pa-0 ma-0 py-0 my-0 " cols="7" md="8" sm="7">
          
          <!-- v-col  btn-1 -->
             
              <v-row  class="pa-0 ma-0 py-0 my-0">
              <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column">
                <!--a
                 <v-col class="pa-0 ma-0 py-0 my-0" style="border: 1px solid #2bca33;" > -->
                   <!-- Btn Cierre de caja px-1--> 
                <v-tooltip bottom  >
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini2"  v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnCierreCaja')" :min-width="btnMinWidth" :max-width="btnMaxWidth"  
                    color="grey darken-2" small dark>
                    <v-avatar tile size="20" color="transparent">
                          <img src="@/assets/icons_pv/menu/cierrecaja64.png" alt="Cierre de caja">
                        </v-avatar> 
                    Cierre de caja      
                  </v-btn>
                </template>
                <span><b>Cierre de caja</b></span>
              </v-tooltip>
          <!--  fn Btn Cierre de caja -->
          <!-- Btn Devolucion -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnFacturaDevolucion')"  :min-width="btnMinWidth" :max-width="btnMaxWidth"
                color="grey darken-2" small dark>
                <v-avatar tile size="20" color="transparent">
                      <img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolucion">
                    </v-avatar>
                
                Devolución
              </v-btn>
            </template>
            <span><b>Devolución</b></span>
          </v-tooltip>
 <!--  fn Btn Devolucion -->
                <!-- </v-col>   
                a    -->
              </div>

              <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column">
                <!-- a
                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12"> -->
        <!-- Btn Guardar Temporal -->        
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini2" v-on="on" @click="methBtnGuardarTemporal()" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                    color="grey darken-2" small dark>
                    <v-avatar tile size="20" color="transparent">
                        <img src="@/assets/icons_pv/extras/guardar.png" alt="guardar ">
                      </v-avatar>
                    Guardar Fact
                  </v-btn>
                </template>
                <span><b>Guardar Factura</b></span>
              </v-tooltip>
<!--  fn Btn Guardar Temporal -->
<!-- Btn Restaurar factura -->
<v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnRestaurarFactura')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                color="grey darken-2" small dark>
                <v-avatar tile size="20" color="transparent">
                    <img src="@/assets/icons_pv/menu/restaurar64.png" alt="Restaurar factura ">
                  </v-avatar>
                  Rest factura
              </v-btn>
            </template>
            <span><b>Restaurar factura</b></span>
          </v-tooltip>
<!--  fn Btn Restaurar factura -->

                <!-- </v-col>
                a -->
              </div>

            <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column" >
              <!-- a
            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12"> -->
            <!-- Btn Reimprimir -->
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnReimprimir')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                                color="grey darken-2" small dark>
                                <v-avatar tile size="20" color="transparent">
                                      <img src="@/assets/icons_pv/menu/Reimpresion64.png" alt="Reimpresión">
                                    </v-avatar>
                                Reimprimir
                              </v-btn>
                            </template>
                            <span><b>Reimprimir</b></span>
                          </v-tooltip>
                  <!--  fn Btn Reimprimir -->  
                  <!-- Btn Cotización -->
                  <v-tooltip bottom>
                        <template v-slot:activator="{ on }"> 
                          <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnCotizacion')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                            color="grey darken-2" small dark>
                            <v-avatar tile size="20" color="transparent">
                                  <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                                </v-avatar>
                            Cotización
                          </v-btn>
                        </template>
                        <span><b>Cotización</b></span>
                      </v-tooltip>
                  <!--  fn Btn Cotización -->  

            <!-- </v-col> 
            a      -->
            </div>

              <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column" >
                <!-- a
                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12"> -->
              <!-- Btn Cotiz a Fact -->
                              <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarCotizacion')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                                  color="grey darken-2" small dark>
                                  <v-avatar tile size="20" color="transparent">
                                        <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                                      </v-avatar>
                                  Cotiz a Fact
                                </v-btn>
                              </template>
                              <span><b>Cotizacion a Factura</b></span>
                            </v-tooltip>
                  <!--  fn Btn Cotiz a Fact --> 
                  <!-- Btn Cond a Fact -->
                  <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarConduce')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                              color="grey darken-2" small dark>
                              <v-avatar tile size="20" color="transparent">
                                    <img src="@/assets/icons_pv/menu/Conduce2-64.png" alt="conduce">
                                  </v-avatar>
                              Cond a Fact
                            </v-btn>
                          </template>
                          <span><b>Conduce a Factura</b></span>
                        </v-tooltip>
                    <!--  fn Btn Cond a Fact -->  
                <!-- </v-col>  
                a -->
              </div>
              
              <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column" >
                <!-- a
                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12"> -->
                <!-- Btn Recibo de ingreso methBtnRecibo-->
                              <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnReciboIngreso')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                                  color="grey darken-2" small dark>
                                  <v-avatar tile size="20" color="transparent">
                                        <img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
                                      </v-avatar>
                                      Recibo
                                </v-btn>
                              </template>
                              <span><b>Recibo de ingreso</b></span>
                            </v-tooltip>
                            <!--  fn Btn Recibo de ingreso -->
                            <!-- Btn Retenciones -->
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnRetencionesGub')" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                              color="grey darken-2" small dark>
                              <v-avatar tile size="20" color="transparent">
                                    <img src="@/assets/icons_pv/menu/retenciones64.png" alt="Retenciones">
                                  </v-avatar>
                                  Retenciones
                            </v-btn>
                          </template>
                          <span><b>Retenciones</b></span>
                        </v-tooltip>
                        <!--  fn Btn Retenciones -->

                <!-- </v-col>
                a -->
              </div>

              <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column"  >
                <!-- a
                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12"> -->
              <!-- Btn Cotiz a Fact -->
                              <!-- <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-btn id="boton-mini2" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarCotizacion')" :min-width="btnMinWidth"
                                  color="grey darken-2" small dark>
                                  <v-avatar tile size="20" color="transparent">
                                        <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                                      </v-avatar>
                                  Cotiz a Fact
                                </v-btn>
                              </template>
                              <span><b>Cotizacion a Factura</b></span>
                            </v-tooltip> -->
                  <!--  fn Btn Cotiz a Fact --> 
                  <!-- Btn Cond a Fact -->
                  <!-- $refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarConduce') -->
                  <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn id="boton-mini2" v-on="on" @click="methBtnPedidoAfactura()" :min-width="btnMinWidth" :max-width="btnMaxWidth"
                              color="grey darken-2" small dark>
                              <v-avatar tile size="20" color="transparent">
                                    <img src="@/assets/icons_pv/menu/pedido64.png" alt="conduce">
                                  </v-avatar>
                              Pedid a Fact
                            </v-btn>
                          </template>
                          <span><b>Pedido a Factura</b></span>
                  </v-tooltip>
                    <!--  fn Btn Cond a Fact -->  
                <!-- </v-col>  
                a -->
              </div>

            </v-row>
           
        </v-col>
         <!-- fn cols 1 Btn-Contenido 1 -->

     
          <!-- <v-card-actions></v-card-actions> class="pa-0 ma-0 py-0 my-0"-->
         
          <v-col v-if="windowSize.width > 1060" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"  >
          <!-- <v-col class="pa-0 ma-0 py-0 my-0 pr-6 d-flex justify-end" pr-6    > </v-col> -->
            <v-btn-toggle> 
              <!-- <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column"  >    </div>     -->
<!-- Btn Nuevo -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini3" v-on="on" @click="methBtnNuevaFactura()" :max-width="70"
                    color="#90A4AE" small dark>
                    <i style="font-size: 20px;" class="fa fa-file-o"> </i>
                    Nuevo 
                  </v-btn>
                </template>
                <span>Nuevo Registro </span>
              </v-tooltip>
<!-- fn Btn Nuevo -->
<!-- Btn Salvar -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn :disabled="modofactura==='editar'" id="boton-mini3" v-on="on" @click="methBtnSalvarFactura()"  :max-width="70"
                    color="success" small dark>
                    <i style="font-size: 20px;" class="fa fa-check-circle"> </i>
                    Salvar 
                  </v-btn>
                </template>
                <span>Salvar Registro </span>
              </v-tooltip>
<!-- Btn Salvar -->


<!-- <div class="pa-0 ma-0 py-0 my-0 d-flex flex-column"  > </div>  -->
<!-- Btn Editar -->
            <v-tooltip bottom v-if="botonesAccion.editar==true">
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini3" v-on="on" @click="funcionValidaPermisoPv('AccEditarFacturaComercial')" :max-width="70"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-pencil-square-o"> </i>
                    Editar 
                  </v-btn>
                </template>
                <span>Editar Registro </span>
              </v-tooltip>
<!-- Btn Editar -->

<!-- Btn Anular -->
<v-tooltip bottom v-if="botonesAccion.anular==true">
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini3" v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnAnularFactura')" :max-width="70"
                    color="red" small dark>
                    <i style="font-size: 20px;" class="fa fa-ban"> </i>
                    Anular
                  </v-btn>
                </template>
                <span>Anular Registro </span>
 </v-tooltip>
<!-- Btn Anular -->


<!-- Btn Anular -->
<v-tooltip bottom v-if="botonesAccion.anularPedido==true">
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini3" v-on="on" @click="methBtnAnularPedido()" :max-width="70"
                    color="red" small dark>
                    <i style="font-size: 20px;" class="fa fa-ban"> </i>
                    Anular
                  </v-btn>
                </template>
                <span>Anular pedido </span>
 </v-tooltip>
<!-- Btn Anular -->



            </v-btn-toggle>
          
          </v-col>
         
          <v-divider v-if="windowSize.width <= 1060"></v-divider>
          <v-card-actions v-if="windowSize.width <= 1060">
          <!-- cols 2 Btn-movil -->
          <v-col  class="pa-0 ma-0 py-0 my-0 pl-2" cols="6" md="6" sm="6" >

<v-card-actions >

<!-- <v-btn @click.prevent="" v-bind="attrs" 
color="transparent" outlined dark>
<i v-bind:style="{ 'font-size': '30px', 'color': hover ? 'white' : '#BDBDBD' }" class="fa fa-reply-all"></i>
</v-btn> -->

<!-- Reimprimir btnes Movil; ------>
<v-bottom-sheet v-model="ReimprecionSheet">
<template v-slot:activator="{ on, attrs }">

<v-btn v-bind="attrs" v-on="on" color="white" outlined fab x-small dark>
<i style="font-size: 20px;" class="fa fa-th"></i>
</v-btn>
</template>
<v-list>

<v-col  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<center>
<v-btn-toggle >
<!-- Btn Nuevo -->
<v-tooltip bottom>
  <template v-slot:activator="{ on }">
    <v-btn  v-on="on" @click="methBtnNuevaFactura()" 
      color="#90A4AE" small dark>
      <i style="font-size: 20px;" class="fa fa-file-o"> </i>
      Nuevo
    </v-btn>
  </template>
  <span>Nuevo Registro </span>
</v-tooltip>
<!-- fn Btn Nuevo -->
<!-- Btn Salvar -->
<v-tooltip bottom>
  <template v-slot:activator="{ on }">
    <v-btn  v-on="on" @click="methBtnSalvarFactura()" 
      color="success" small dark>
      <i style="font-size: 20px;" class="fa fa-check-circle"> </i>
      Salvar
    </v-btn>
  </template>
  <span>Salvar Registro </span>
</v-tooltip>
<!-- Btn Salvar -->

<!-- Btn Editar -->
<v-tooltip bottom v-if="botonesAccion.editar==true">
  <template v-slot:activator="{ on }">
    <v-btn  v-on="on" @click="funcionValidaPermisoPv('AccEditarFacturaComercial')" 
      color="orange" small dark>
      <i style="font-size: 20px;" class="fa fa-pencil-square-o"> </i>
      Editar
    </v-btn>
  </template>
  <span>Editar Registro </span>
</v-tooltip>
<!-- Btn Editar -->

<!-- Btn Anular -->
<v-tooltip bottom v-if="botonesAccion.anular==true">
  <template v-slot:activator="{ on }">
    <v-btn  v-on="on" @click="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnAnularFactura')" 
      color=" red" small dark>
      <i style="font-size: 20px;" class="fa fa-ban"> </i>
      Anular
    </v-btn>
  </template>
  <span>Anular Registro </span>
</v-tooltip>
<!-- Btn Anular -->

<!-- Btn Anular -->
<v-tooltip bottom v-if="botonesAccion.anular==true">
  <template v-slot:activator="{ on }">
    <v-btn  v-on="on" @click="methBtnAnularPedido()" 
      color=" red" small dark>
      <i style="font-size: 20px;" class="fa fa-ban"> </i>
      Anular
    </v-btn>
  </template>
  <span>Anular Registro </span>
</v-tooltip>
<!-- Btn Anular -->
</v-btn-toggle>
</center>
</v-col>
<v-subheader class="d-flex justify-center"> <b> Funciones de Acciones </b></v-subheader>
<!-- 32px -->
<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnCierreCaja')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/cierrecaja64.png" alt="Cierre de caja">
<!-- <i style="font-size: 32px; color:#00796B; " class="fa fa-plus-square"></i> -->
</v-list-item-avatar>
<v-list-item-title>Cierre de caja</v-list-item-title>
</v-list-item>

<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnFacturaDevolucion')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolución">

</v-list-item-avatar>
<v-list-item-title>Devolución</v-list-item-title>
</v-list-item>

<v-list-item @click.prevent="methBtnGuardarTemporal()">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/extras/guardar.png" alt="guardar">
</v-list-item-avatar>
<v-list-item-title>Guardar Factura</v-list-item-title>
</v-list-item>

<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnRestaurarFactura')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/restaurar64.png" alt="Restaurar factura">
</v-list-item-avatar>
<v-list-item-title>Restaurar factura</v-list-item-title>
</v-list-item>

<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnReimprimir')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/Reimpresion64.png" alt="Reimprimir">
</v-list-item-avatar>
<v-list-item-title>Reimprimir</v-list-item-title>
</v-list-item>
<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnCotizacion')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
</v-list-item-avatar>
<v-list-item-title>Cotización</v-list-item-title>
</v-list-item>

<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarCotizacion')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotiz a Fact">
</v-list-item-avatar>
<v-list-item-title>Cotizacion a Factura</v-list-item-title>
</v-list-item>
<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarConduce')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/Conduce2-64.png" alt="Cond a Fact">
</v-list-item-avatar>
<v-list-item-title>Conduce a Factactura</v-list-item-title>
</v-list-item>
<!-- methBtnRecibo --> 
<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnReciboIngreso')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
</v-list-item-avatar>
<v-list-item-title>Recibo de ingreso</v-list-item-title>
</v-list-item>

<v-list-item @click.prevent="$refs.RefPvMenuOpcionBotones.ClickAccion('BtnRetencionesGub')">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/retenciones64.png" alt="Retenciones">
</v-list-item-avatar>
<v-list-item-title>Retenciones</v-list-item-title>
</v-list-item>
<!-- $refs.RefPvMenuOpcionBotones.ClickAccion('BtnBucarConduce') -->
<v-list-item @click.prevent="methBtnPedidoAfactura()">
<v-list-item-avatar tile size="32">
<img src="@/assets/icons_pv/menu/pedido64.png" alt="Cond a Fact">
</v-list-item-avatar>
<v-list-item-title>Pedido a Factura</v-list-item-title>
</v-list-item>


</v-list>
</v-bottom-sheet>
</v-card-actions>

          </v-col>
          <!-- fn cols 2 Btn-movil  -->
        </v-card-actions>
     </v-toolbar>

<!-- ------------------------------------------------------ Modal Recibo de ingreso -->  
<v-dialog v-model="dialogNewReciboingreso" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #dd4b39" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="dialogNewReciboingreso = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsRecibosIngresos />
    </v-list>
  </v-card>
</v-dialog>
<!-- fn Recibo de ingreso ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal Recibo de ingreso -->  
<v-dialog v-model="dialogNewCotizacion" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #dd4b39" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="dialogNewCotizacion = false">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsCotizacionBasica />
    </v-list>
  </v-card>
</v-dialog>
<!-- fn Recibo de ingreso ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal Cierre de caja -->  
<v-dialog v-model="dialogCierreCaja" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <!-- <v-list three-line subheader></v-list> -->
      <ComponentsPvModalCierreCaja ref="RefComponentsPvModalCierreCaja" :configuraciones="configuraciones" :cajaSelect="cajaSelect" :OtrasConfigPv="OtrasConfigPv" :Monedafactura="headerFactura!=null && headerFactura.hasOwnProperty('moneda') ? headerFactura.moneda: null" @DataRetornCierre="DataRetornCierre"   @DataRetornCancelarCierreCaja="dialogCierreCaja = false" />
    
  </v-card>
</v-dialog>
<!-- fn Cierre de caja ----------------------------------------------------------------------------------------------->
<!-- ------------------------------------------------------ Modal FacturaDevolucion -->  
<v-dialog v-model="dialogDevolucion" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <ComponentsPvModalFacturaDevolucion ref="RefComponentsPvModalFacturaDevolucion" :configuraciones="configuraciones" :cajaSelect="cajaSelect" :OtrasConfigPv="OtrasConfigPv"  @DataRetornCancelarDevolucion="dialogDevolucion = false" />
  </v-card>
</v-dialog>
<!-- fn FacturaDevolucion ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal Restaurar Factura -->  
<v-dialog v-model="dialogRestaurarFactura" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <ComponentsPvModalRestaurarFactura ref="RefComponentsPvModalRestaurarFactura" :configuraciones="configuraciones" :cajaSelect="cajaSelect" @DataRetornAplicarRestaurarFact="DataRetornAplicarRestaurarFact" @DataRetornCancelarRestFact="dialogRestaurarFactura= false" />
  </v-card>
</v-dialog>
<!-- fn Restaurar Factura ----------------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ Modal Reimpresion Factura -->  
<v-dialog v-model="dialogReimprecion" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <!-- DataRetornReimpresionFact -->
    <ComponentsPvModalReimpresion ref="RefComponentsPvModalReimpresion" :configuraciones="configuraciones" :OtrasConfigPv="OtrasConfigPv" :cajaSelect="cajaSelect" @DataRetornReimpresionFact="" @DataRetornCancelarReimpresion="dialogReimprecion= false" />
  </v-card>
</v-dialog>
<!-- fn Modal Reimpresion Factura ----------------------------------------------------------------------------------------------->


<!-- ------------------------------------------------------ Modal- Retencion Factura -->
<v-dialog transition="fab-transition" label="Modal Retencion Factura" v-model="dialogRetencionFactura" persistent :max-width="windowSize.width<800 ? `95%` :`45%`"
      :max-height="windowSize.width<800 ? `85%` :`45%`">
      <v-card class="mx-auto">
      <ComponentsPvModalRetencionesGub ref="RefComponentsPvModalRetencionesGub" :configuraciones="configuraciones" :OtrasConfigPv="OtrasConfigPv" :cajaSelect="cajaSelect" @DataRetornRetencionesGub="DataRetornRetencionesGub"  @DataRetornCancelarRetencionGub="dialogRetencionFactura= false" />
    </v-card>
    </v-dialog>
<!-- ------------------------------------------------------ fn Modal- Retencion Factura-->

<!-- ------------------------------------------------------ Modal cotizacion a Factura -->  
<v-dialog v-model="dialogBucarCotizacion" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <!-- DataRetornReimpresionFact -->
    <ComponentsPvModalCotizacionAfactura ref="RefComponentsPvModalCotizacionAfactura" :configuraciones="configuraciones" :OtrasConfigPv="OtrasConfigPv" :cajaSelect="cajaSelect" @DataRetornCotizacionAfactura="DataRetornCotizacionAfactura" @DataRetornCancelarCotizacionAfactura="dialogBucarCotizacion= false" />
  </v-card>
</v-dialog>
<!-- fn Modal cotizacion a Factura ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal conduce a Factura -->  
<v-dialog v-model="dialogBucarConduce" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <ComponentsPvModalConduceAfactura ref="RefComponentsPvModalConduceAfactura" :configuraciones="configuraciones" :OtrasConfigPv="OtrasConfigPv" :cajaSelect="cajaSelect" @DataRetornConduceAfactura="DataRetornConduceAfactura" @DataRetornCancelarConduceAfactura="dialogBucarConduce= false" />
  </v-card>
</v-dialog>
<!-- fn Modal cotizacion a Factura ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal busqueda de Factura -->  
<v-dialog v-model="dialogBucarFacturaEdit" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <ComponentsPvModalBuscarFactura ref="RefComponentsPvModalBuscarFactura" :configuraciones="configuraciones" :OtrasConfigPv="OtrasConfigPv" :cajaSelect="cajaSelect" @DataRetornBuscarFactura="DataRetornBuscarFactura" @DataRetornCancelarBuscarFactura ="dialogBucarFacturaEdit= false" />
  </v-card>
</v-dialog>
<!-- fn Modal busqueda de Factura ----------------------------------------------------------------------------------------------->

<!-- ------------------------------------------------------ Modal cotizacion a Factura -->  
<v-dialog v-model="dialogPedidoAfactura" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <!-- DataRetornReimpresionFact -->
    <ComponentsPvModalPedidoAfactura ref="RefComponentsPvModalPedidoAfactura" :configuraciones="configuraciones" :OtrasConfigPv="OtrasConfigPv" :cajaSelect="cajaSelect" @DataRetornPedidoAfactura ="DataRetornPedidoAfactura " @DataRetornCancelarPedidoAfactura ="dialogPedidoAfactura= false" />
  </v-card>
</v-dialog>
<!-- fn Modal cotizacion a Factura ----------------------------------------------------------------------------------------------->

<!---------------------  Panel Menu Opcion Botones ------------------------------->
    <div style="max-height:300px;" class="overflow-x-auto" v-show="PanelMenuOpcionBotones">
              <!-- columna---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv" :cajaSelect="cajaSelect" />
    </div>
    <!--------------------- fn Panel Menu Opcion Botones ------------------------------->
   </v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ComponentsRecibosIngresos from "@/CuentaXCobrar/Trans/RecibosIngresos.vue";
import ComponentsCotizacionBasica from "@/PuntodeVenta/Mant/CotizacionBasica.vue";
import ComponentsPvModalCierreCaja from "@/PuntodeVenta/UtilidadComponents/PvModalCierreCaja.vue";
import ComponentsPvModalFacturaDevolucion from "@/PuntodeVenta/UtilidadComponents/PvModalFacturaDevolucion.vue";
import ComponentsPvModalRestaurarFactura from "@/PuntodeVenta/UtilidadComponents/PvModalRestaurarFactura.vue";
import ComponentsPvModalReimpresion from "@/PuntodeVenta/UtilidadComponents/PvModalReimpresion.vue";
import ComponentsPvModalRetencionesGub from "@/PuntodeVenta/UtilidadComponents/PvModalRetencionesGub.vue";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import ComponentsPvModalCotizacionAfactura from "@/PuntodeVenta/UtilidadComponents/PvModalCotizacionAfactura.vue";
import ComponentsPvModalConduceAfactura from "@/PuntodeVenta/UtilidadComponents/PvModalConduceAfactura.vue";
import ComponentsPvModalBuscarFactura from "@/PuntodeVenta/UtilidadComponents/PvModalBuscarFactura.vue";
import ComponentsPvModalPedidoAfactura from "@/PuntodeVenta/UtilidadComponents/PvModalPedidoAfactura.vue";
export default {
  components: {
    VueAutonumeric,
    ComponentsRecibosIngresos,
    ComponentsCotizacionBasica,
    ComponentsPvModalCierreCaja,
    ComponentsPvModalFacturaDevolucion,
    PvMenuOpcionBotones,
    ComponentsPvModalRestaurarFactura,
    ComponentsPvModalReimpresion,
    ComponentsPvModalRetencionesGub,
    ComponentsPvModalCotizacionAfactura,
    ComponentsPvModalConduceAfactura,
    ComponentsPvModalBuscarFactura,
    ComponentsPvModalPedidoAfactura
  },
  created() { 
 
  },
  mounted() {
  },
  beforeDestroy() {

  },

  data: () => ({ 

    EjecutarPermisoPv:false,
    Monedafactura:null,
    PanelMenuOpcionBotones:false,
    dialogNewReciboingreso:false,
    dialogNewCotizacion:false,
    dialogCierreCaja: false,
    dialogDevolucion:false,
    dialogRestaurarFactura:false,
    ReimprecionSheet:false,
    dialogReimprecion:false,
    dialogRetencionFactura:false,
    dialogBucarCotizacion:false,
    dialogBucarConduce:false,
    dialogBucarFacturaEdit:false,
    dialogPedidoAfactura:false,
    windowSize: { width: "auto", height: "auto" },
    btnMinWidth:'110px',
    btnMaxWidth:'110px',
    modificando:false,
    editando:false,
    botonesAccion:{
    salvar:true,
    editar:false,
    anular:false,
    anularPedido:false
    }
  }),
  props: { 
    cajaSelect: {
      required: false,
      type: Object,
      default: null
    },
  
    headerFactura: {
      required: false,
      type: Object,
      default: null
    },
    OtrasConfigPv: {
      required: false,
      type: Object,
      default: null
    },
    configuraciones: {
      required: false,
      type: Object,
      default: null
    },

    // salvarNuevo,editar,modificar:
modofactura: {
  required: false,
  type: String,
  default: "salvarNuevo"
}

    
  },

  methods: { 
     methBtnBarraAccionFactura(){
      this.dialogBucarFacturaEdit=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.RefComponentsPvModalBuscarFactura.getFacturalistFactCliente() 
      }, 300);
     },
    methBtnNuevaFactura(){ 
      var Objc={
        opcion:'DataNuevaFactura',
        datos: JSON.parse(JSON.stringify(this.headerFactura))
      }
      this.$emit("DataRetornGeneral", Objc)
    },
    methBtnRecibo(){this.dialogNewReciboingreso=true},
    methBtnCotizacion(){this.dialogNewCotizacion=true},
    methBtnCierreCaja(){
      this.dialogCierreCaja = true;   
      var _this=this;
      setTimeout(function(){ _this.$refs.RefComponentsPvModalCierreCaja.getUltimocierre() }, 300);
      
    },
     methBtnFacturaDevolucion(){
  this.dialogDevolucion=true
  var _this=this;
      setTimeout(function(){ 
        _this.$refs.RefComponentsPvModalFacturaDevolucion.getBuscarFacturaDevolucion() 
        _this.$refs.RefComponentsPvModalFacturaDevolucion.getUltimocierre()
      }, 300);
    },

    methBtnRestaurarFactura(){
      this.dialogRestaurarFactura = true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.RefComponentsPvModalRestaurarFactura.getRestaurarfactura() 
      }, 300);
      return;
    },

    methBtnReimpresionFact(){
      this.dialogReimprecion=true
    },

    methBtnRetenciones(){
      this.dialogRetencionFactura=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.RefComponentsPvModalRetencionesGub.getRetencionFactura() 
      }, 300);
    },

    methBtnCotizacionAfactura(){
      this.dialogBucarCotizacion=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.RefComponentsPvModalCotizacionAfactura.getCotizacionlistFactCliente() 
      }, 300);
    },


    methBtnConduceAfactura(){
      this.dialogBucarConduce=true
      var _this=this;
      setTimeout(function(){ 
         _this.$refs.RefComponentsPvModalConduceAfactura.getConducelistFactura() 
      }, 300);
    },

    methBtnGuardarTemporal(){

      var Objc={
        opcion:'DataGuardarTemporal',
        datos: JSON.parse(JSON.stringify(this.headerFactura))
      }
      this.$emit("DataRetornGeneral", Objc)

    },

    methBtnSalvarFactura(){
      var Objc={
        opcion:'DataSalvarFactura',
        datos: JSON.parse(JSON.stringify(this.headerFactura))
      }
      this.$emit("DataRetornGeneral", Objc)
    },

    methBtnEditarFactura(){
        var Objc={
        opcion:'DataEditarFactura',
        datos: JSON.parse(JSON.stringify(this.headerFactura))
      }
      this.$emit("DataRetornGeneral", Objc)
    },

    methBtnAnularfactura(){
      var Objc={
        opcion:'DataAnularFactura',
        datos: JSON.parse(JSON.stringify(this.headerFactura))
      }
      this.$emit("DataRetornGeneral", Objc)
    },

    methBtnPedidoAfactura(){
      this.dialogPedidoAfactura=true
      var _this=this;
      setTimeout(function(){ 
        _this.$refs.RefComponentsPvModalPedidoAfactura.getPedidoAfacturalistFactCliente() 
      }, 300);
    },


    methBtnAnularPedido(){
      var Objc={
        opcion:'DataAnularPedidoFactura',
        datos: JSON.parse(JSON.stringify(this.headerFactura))
      }
      this.$emit("DataRetornGeneral", Objc)
    },


// Llamar a una función en un componente secundario para los permisos de usuario.
funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

},

    DataRetornPadrePermisoPv(e){

//Editar Factura comercial
if (e == 'AccEditarFacturaComercial') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.methBtnEditarFactura()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
    },
    DataRetornMenuOpcBotonesPermisosPv(e) {
      //Abre el boton del menu ReciboIngreso
      if (e == 'BtnReciboIngreso') {
        this.methBtnRecibo()
        return;
      }

      //Abre el boton del menu cierre de caja
      if (e == 'BtnCierreCaja') {
        this.methBtnCierreCaja()
        return;
      }


//Abre el boton del menu devolucion
      if (e == 'BtnFacturaDevolucion') {
        this.methBtnFacturaDevolucion()
        return;
      }

//Abre el boton del menu RestaurarFactura
      if (e == 'BtnRestaurarFactura') {
        this.methBtnRestaurarFactura()
        return;
      }

      //Abre el boton del menu RestaurarFactura
      if (e == 'BtnReimprimir') {
        this.methBtnReimpresionFact()
        return;
      }

      //Abre el boton del menu RetencionesGub
      if (e == 'BtnRetencionesGub') {
        this.methBtnRetenciones()
        return;
      }


      //Abre el boton del menu cotizacion a factura
      if (e == 'BtnBucarCotizacion') {
        this.methBtnCotizacionAfactura()
        return;
      }
 
      //Abre el boton del menu cotizacion 
      if (e == 'BtnCotizacion') {
        this.methBtnCotizacion()
        return;
      }

      //Abre el boton del menu cotizacion a factura
      if (e == 'BtnBucarConduce') {
        this.methBtnConduceAfactura()
        return;
      }


//Abre el boton del menu Anular Factura
      if (e == 'BtnAnularFactura') {
        this.methBtnAnularfactura()
        return;
      }


      
    },

    DataRetornCierre(e){
      // this.$emit("DataRetornCierre", e)
      // _this.$emit("DataRetornCierre", this.CierresCajas)
      var Objc={
        opcion:'DataCierre',
        datos: JSON.parse(JSON.stringify(e))
      }
      this.$emit("DataRetornGeneral", Objc)
    },

    DataRetornAplicarRestaurarFact(e){


      var Objc={
        opcion:'AplicarRestaurarFact',
        datos: JSON.parse(JSON.stringify(e))
      }

      this.$emit("DataRetornGeneral", Objc)
    },

    DataRetornRetencionesGub(e){
      var Objc={
        opcion:'DataRetencionesGub',
        datos: JSON.parse(JSON.stringify(e))
      }
      this.$emit("DataRetornGeneral", Objc)
    },

  DataRetornCotizacionAfactura(e){


var Objc={
  opcion:'convertirCotizacionAfactura',
  datos: JSON.parse(JSON.stringify(e))
}

this.$emit("DataRetornGeneral", Objc)
  },

    
  DataRetornConduceAfactura(e){
var Objc={
  opcion:'convertirConduceAfactura',
  datos: JSON.parse(JSON.stringify(e))
}

this.$emit("DataRetornGeneral", Objc)
  },

 DataRetornBuscarFactura(e){
  // PvModalBuscarFactura ,

  var Objc={
  opcion:'selecionarFacturaBuscada',
  datos: JSON.parse(JSON.stringify(e))
}

this.$emit("DataRetornGeneral", Objc)

 },

 DataRetornPedidoAfactura(e){
var Objc={
  opcion:e.opcion,
  datos: JSON.parse(JSON.stringify(e))
}
this.$emit("DataRetornGeneral", Objc)
  },


  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
          if(this.windowSize.width <= 850){
            this.btnMinWidth='auto'
          }else{
            this.btnMinWidth='110px'
          }


          if(this.modofactura==='salvarNuevo'){
    this.editando=false
    this.modificando=true
   }
   if(this.modofactura==='editar'){
    this.editando=true
    this.modificando=false
   }

   if(this.modofactura==='modificar'){
    this.editando=true
    this.modificando=true
   }

     },

},
};
</script>
<style lang="scss">




@media screen and (max-width: 2000px) {

 #boton-mini2 {
  font-size: 10px;
  }

}

@media screen and (max-width: 2000px) {

  #boton-mini3 {
    font-size: 10px;
  }

}

/*@media screen and (min-width: 850px) and (max-width: 1110px) {
  #boton-mini2 {
    font-size: 10px;
  }

  #boton-mini3 {
    font-size: 10px;
  }
}*/




</style>