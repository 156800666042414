<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 9"
      :max-height="this.windowSize.height - 9">
      <!-- ContenidoBase-Generar documentos -->
      <ContenidoBase :titulo="'Generar documentos recurrentes'"
        :descripcion="'Generación automática de facturas y pre-facturas a partir de las configuraciones pre-registradas.'"
        :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
        @MethodEliminar="remove" @MethodAnular="anular" @MethodImprimir="imprimirList" />
      <!-- fn ContenidoBase-Generar documentos -->

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
              </template>
            </v-tooltip>

          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>


                  <span class="font-weight-light" style="font-size: 20px;"> Generar documentos recurrentes </span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                   <!-- Formulario-Generar documentos  -->
                  <v-form ref="formGenerar" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  desde  -->
                        <v-text-field dense outlined type="date" label="Fecha:" :rules="[$rules.required]"
                          v-model="GenerarRecurrente.fecha">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>


                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-------------------------------------------------- Proyecto -->
                        <v-combobox autocomplete="off" dense outlined style="font-size:13px;" label="Proyecto"
                          :items="Rproyecto" item-text="nombre" :rules="[$rules.required]"
                          v-model="GenerarRecurrente.proyecto" @blur="SelctCbProyecto" @focus="cargarProyecto">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-area-chart"></i>
                          </template>

                        </v-combobox>


                      </v-col>
                      <!-- fn columna 2 -->

                      <v-col cols="12" md="12" sm="12">

                        <!------------------------------- Comentario-->
                        <v-textarea v-model="GenerarRecurrente.comentario" label="Comentario " style="font-size:13px;"
                          rows="1" outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-comments-o"></i>
                          </template>
                        </v-textarea>




                      </v-col>




                    </v-row>

                  </v-form>
                  <!-- Formulario-Generar documentos  -->
                </v-container>

                <hr>

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;" class="d-flex justify-center">

                  <v-btn-toggle dense rounded>

                    <v-btn dark color="green darken-3" @click.prevent="GenerarDocumento">
                      <i style="font-size: 28px;" class="fa fa-retweet"> </i>
                      Generar
                    </v-btn>

                    <v-btn dark color="light-blue darken-2" @click.prevent="ImprimirFacturasGeneradas">
                      <i style="font-size: 28px;" class="fa fa-print"> </i>
                      Imprimir Facturas Generadas
                    </v-btn>
                    <v-btn dark color="light-blue accent-2" target="_blank"
                      @click.prevent="ImprimirPreFacturasGeneradas">
                      <i style="font-size: 28px;" class="fa fa-print"> </i>
                      Imprimir Pre-Facturas Generadas
                    </v-btn>

                  </v-btn-toggle>
                </v-toolbar>

              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase

  },
  created() {

    var json = {
      titulo: "Generar documentos recurrentes",
      descripcion: "Generación automática de facturas y pre-facturas a partir de las configuraciones pre-registradas.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi: false,
      accion: [

        "GenerarFacturasRecurrenteNew",
        "GenerarFacturasRecurrenteSave",
        "GenerarFacturasRecurrenteRemove",
        "GenerarFacturasRecurrenteAnular",
        "GenerarFacturasRecurrentePrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("GenerarFacturasRecurrenteNew", this.new);
    // EventBus.$on("GenerarFacturasRecurrenteSave", this.save);
    // EventBus.$on("GenerarFacturasRecurrenteRemove", this.remove);
    // EventBus.$on("GenerarFacturasRecurrenteAnular", this.remove);
    // EventBus.$on("GenerarFacturasRecurrentePrintList", this.imprimirList);
    EventBus.$on("emitGenerarFacturasRecurrente", this.datostabla);
    this.cargarTodo();


  },

  data: () => ({


    Rpropietario: [],
    Rproyecto: [],
    RCaja: [],
    ListaTiposClientes: [],
    windowSize: { width: "auto", height: "auto" },

    GenerarRecurrente: {
      fecha: new Date().toISOString().substr(0, 10),
      proyecto: null,
      comentario: ''

    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
      // await this.$axios
      //   .post(
      //     this.$hostname +
      //       this.$hName +
      //       "/services/tipocliente/findalltipoclientes",
      //     {
      //       usuario:JSON.parse(JSON.stringify(this.$user)) 
      //     }
      //   )
      //   .then(res => (this.ListaTiposClientes = res.data))
      //   .catch(error => console.log(error));
    },
    datostabla(e) {

      this.GenerarRecurrente = e;
      this.acti_tb_data = false
    },
    save() {

      if (this.$refs.form.validate()) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.GenerarRecurrente)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/saveTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {

            if (res.data.includes("No se pudo generar")) {

              this.alerta("Dato guardado correctamente!", "warning");
            } else {
              this.alerta("Dato guardado correctamente!", "bien");
            }
          }).catch(error =>
            this.alerta("Dato no guardado", "error"
              // "hubo un error , por favor verifique el campo descripcion!",
              // "error"
            )
          );
      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }




    },
    remove() {

      if (this.GenerarRecurrente.codigo != 0 && this.GenerarRecurrente.codigo != null) {

        this.Elim_notif.estado = !this.Elim_notif.estado;
      } else { this.alerta("Seleccione un tipo de cliente para eliminar", "error") }


    },
    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.GenerarRecurrente)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.GenerarRecurrente.codigo != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => { this.alerta(res.data.mensage, res.data.estado) })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.GenerarRecurrente)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/removeTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() { },
    newwnuevo() {
      this.GenerarRecurrente = {
        fecha: null,
        proyecto: null,
        comentario: ''

      },
        EventBus.$emit("loading", false);
      EventBus.$emit("actualizaBarraBusqueda2");
    },



  // Realizar una solicitud GET al servidor y devolver los datos al componente Vue.
    cargarProyecto() {


      this.$axios.get(this.$hostname + this.$hName + '/Proyecto/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproyecto = res.data))

    },


    // Comprobando si el usuario ha seleccionado un proyecto de la lista desplegable. Si el usuario no
    // ha seleccionado un proyecto, se mostrará un mensaje de error.
    SelctCbProyecto() {

      if (this.GenerarRecurrente.proyecto == null) {

        this.GenerarRecurrente.proyecto = ''
      }

      if (this.GenerarRecurrente.proyecto.id == undefined) {

        this.alerta("Seleccione un proyecto", "error");
        this.GenerarRecurrente.proyecto = ''
        return

      }

    },


    BlurDesde() {

      if (this.GenerarRecurrente.fecha.toString().length == 0) {
        this.GenerarRecurrente.fecha = new Date().toISOString().substr(0, 10)
      }

    },

    // Envío de una solicitud GET al servidor para generar los documentos recurrente.
    GenerarDocumento() {

      if (this.$refs.formGenerar.validate()) {
        EventBus.$emit("loading", true);


        var com = this.GenerarRecurrente.comentario.trim()
        if (com.length == 0) {
          com = 'null'
        }

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/configuracion-recurrente/generarFacturasRecurrentes/" + this.GenerarRecurrente.fecha + "/"
            + this.GenerarRecurrente.proyecto.id + "/" + com,
            {
              headers: this.$store.getters.GetheadersAxios
            }
          )
          .then(res => {
            if (res.data.includes("No se pudo generar")) {
              this.alerta(res.data, "warning");
            } else {
              this.alerta("Dato guardado correctamente!", "bien");
            }
            EventBus.$emit("loading", false);
          })
          .catch(error => { EventBus.$emit("loading", false); }
          );

      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }


    },

    // Abriendo una nueva ventana y escribiendo el pdf en ella.
    ImprimirFacturasGeneradas() {


      if (this.$refs.formGenerar.validate()) {
        EventBus.$emit("loading", true);
        var fech = this.GenerarRecurrente.fecha
        var proy = this.GenerarRecurrente.proyecto.id
        var headers = this.$store.getters.GetheadersAxios
        this.$axios.get(this.$hostname + this.$hName + "/configuracion-recurrente/ImprimirGenerarFacturasRecurrentes/" + fech + "/" + proy, { headers: headers }).then(res => {
          EventBus.$emit("loading", false);
          /*let pdfWindow = window.open("Reporte de imprecion")
          pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
            encodeURI(res.data) + "'></embed>")*/

            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
            EventBus.$emit("openPDF",PDF ,'conf recurrente');

        })
          .catch(error => { EventBus.$emit("loading", false); }
          );


      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }



    },

   // Abriendo una nueva ventana y escribiendo el pdf en ella.
    ImprimirPreFacturasGeneradas() {

      if (this.$refs.formGenerar.validate()) {
        EventBus.$emit("loading", true);
        var fech = this.GenerarRecurrente.fecha
        var proy = this.GenerarRecurrente.proyecto.id
        var headers = this.$store.getters.GetheadersAxios
        this.$axios.get(this.$hostname + this.$hName + "/configuracion-recurrente/ImprimirGenerarPreFacturasRecurrentes/" + fech + "/" + proy, { headers: headers }).then(res => {
          EventBus.$emit("loading", false);
          /*let pdfWindow = window.open("Reporte de imprecion")
          pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
            encodeURI(res.data) + "'></embed>")*/

            var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
             EventBus.$emit("openPDF",PDF ,'generer prefacturas recurrente');

        })
          .catch(error => { EventBus.$emit("loading", false); }
          );


      } else {

        this.alerta("los campos en rojo son Requeridos", "error");

      }

    },




    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);
      }
      if (l == "warning") {
        (this.aalert.estado = true),
          (this.aalert.color = "warning"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
        EventBus.$emit("loading", false);

      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario: JSON.parse(JSON.stringify(this.$user))
        })
        .then(res => {
          this.list = res.data;
        });
    }
  },
  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }
  }




};
</script>

<style>

</style>
