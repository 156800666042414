<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
 <!-- v-toolbar -->
  <v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Reimpresión
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Reimpresión: </span> Recibo, Otros
    ingresos, Factura, Devolución, Vale de caja, Cotización</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions v-if="windowSize.width > 600">
  <v-btn-toggle>
    <!-- Reimprimir btn Canselar max-height: 130px; ------>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">

          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="cancelarReimpresion()"
            v-on="on" height="45px" min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>


              <v-avatar tile size="30" color="transparent">
                <i v-bind:style="{ 'font-size': '30px', 'color': hover ? 'blcak' : 'white' }"
                  class="fa fa-reply-all"></i>
              </v-avatar>

              <!-- CANCELAR: Cancela y retornar atrás, class="fa fa-reply-all"
<br>
<b v-bind:style="{  'font-size':'10px', 'color': hover ? 'white':'#000000'  }">Recibo</b> -->
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Cancela y retornar atrás</b> </span>
    </v-tooltip>


    <!-- Reimprimir btn recibo ------>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcRecibo')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 1" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
                </v-avatar>
              </v-badge>

              <br>
              <b v-if="BtnReimprecionvalue != 1"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Recibo</b>
              <b v-if="BtnReimprecionvalue == 1" class='white--text'>Recibo</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Recibo de ingresos </b> </span>
    </v-tooltip>

    <!-- Reimprimir btn Otros ingresos ------>
    <v-tooltip bottom v-if="false">
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcOtrosingresos')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 2" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/otrosIngresos64.png" alt="Otros ingresos">
                </v-avatar>
              </v-badge>
              <br>
              <b v-if="BtnReimprecionvalue != 2"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Otros
                ingresos</b>
              <b v-if="BtnReimprecionvalue == 2" class='white--text'>Otros ingresos</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Otros ingresos </b> </span>
    </v-tooltip>

    <!-- Reimprimir btn Factura ------>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcFactura')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 3" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/factura64.png" alt="Factura">
                </v-avatar>
              </v-badge>
              <br>
              <b v-if="BtnReimprecionvalue != 3"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Factura</b>
              <b v-if="BtnReimprecionvalue == 3" class='white--text'>Factura</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Factura</b></span>
    </v-tooltip>


    <!-- Reimprimir btn Devolución ------>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcDevolución')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 4" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolución">
                </v-avatar>
              </v-badge>
              <br>
              <b v-if="BtnReimprecionvalue != 4"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Devolución</b>
              <b v-if="BtnReimprecionvalue == 4" class='white--text'>Devolución</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Devolución</b> </span>
    </v-tooltip>


    <!-- Reimprimir btn Vale de caja ------>
    <v-tooltip bottom v-if="false">
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcValeCaja')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 5" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/valeCaja64.png" alt="Vale de caja">
                </v-avatar>
              </v-badge>

              <br>
              <b v-if="BtnReimprecionvalue != 5"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Vale de caja</b>
              <b v-if="BtnReimprecionvalue == 5" class='white--text'>Vale de caja</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Vale de caja </b> </span>
    </v-tooltip>


    <!-- Reimprimir btn Cotización ------>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcCotizacion')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 6" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                </v-avatar>
              </v-badge>

              <br>
              <b v-if="BtnReimprecionvalue != 6"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Cotización</b>
              <b v-if="BtnReimprecionvalue == 6" class='white--text'>Cotización</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Cotización </b> </span>
    </v-tooltip>


    <!-- Reimprimir btn Conduce ------>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" close-delay="200">
          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
            @click.prevent="ReimpresionOpciones('AccReimpresionOpcConduce')" v-on="on" height="45px"
            min-width="105px" dark>
            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
            <span>

              <v-badge overlap color="teal">
                <template v-slot:badge>
                  <i v-if="BtnReimprecionvalue == 7" style="font-size: 10px;" class="fa fa-check"></i>
                </template>

                <v-avatar tile size="30" color="transparent">
                  <img src="@/assets/icons_pv/menu/Conduce2.png" alt="Conduce">
                </v-avatar>
              </v-badge>

              <br>
              <b v-if="BtnReimprecionvalue != 7"
                v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Conduce</b>
              <b v-if="BtnReimprecionvalue == 7" class='white--text'>Conduce</b>
            </span>
          </v-btn>
        </v-hover>
      </template>
      <span><b> Conduce </b> </span>
    </v-tooltip>


  </v-btn-toggle>
</v-card-actions>


<v-card-actions v-if="windowSize.width <= 600">

  <v-btn @click.prevent="BtnReimprecionvalue = 1, dialogReimprecion = false" v-bind="attrs"
    color="transparent" outlined dark>
    <i v-bind:style="{ 'font-size': '30px', 'color': hover ? 'white' : '#BDBDBD' }" class="fa fa-reply-all"></i>
  </v-btn>

  <!-- Reimprimir btnes Movil; ------>
  <v-bottom-sheet v-model="ReimprecionSheet">
    <template v-slot:activator="{ on, attrs }">

      <v-btn v-bind="attrs" v-on="on" color="teal darken-2" outlined fab x-small dark>
        <i style="font-size: 20px;" class="fa fa-list-ul"></i>
      </v-btn>
    </template>
    <v-list>

      <v-subheader class="d-flex justify-center"> <b> Funciones de Reimpreción </b>


      </v-subheader>

      <!-- 32px -->
      <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcRecibo')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
          <!-- <i style="font-size: 32px; color:#00796B; " class="fa fa-plus-square"></i> -->
        </v-list-item-avatar>
        <v-list-item-title>Recibo</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="false" @click.prevent="ReimpresionOpciones('AccReimpresionOpcOtrosingresos')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/otrosIngresos64.png" alt="Otros ingresos">

        </v-list-item-avatar>
        <v-list-item-title>Otros ingresos</v-list-item-title>
      </v-list-item>

      <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcFactura')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/factura64.png" alt="Factura">
        </v-list-item-avatar>
        <v-list-item-title>Factura</v-list-item-title>
      </v-list-item>

      <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcDevolución')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolución">
        </v-list-item-avatar>
        <v-list-item-title>Devolución</v-list-item-title>
      </v-list-item>

      <v-list-item v-if="false" @click.prevent="ReimpresionOpciones('AccReimpresionOpcValeCaja')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/valeCaja64.png" alt="Vale de caja">
        </v-list-item-avatar>
        <v-list-item-title>Vale de caja</v-list-item-title>
      </v-list-item>

      <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcCotizacion')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
        </v-list-item-avatar>
        <v-list-item-title>Cotización</v-list-item-title>
      </v-list-item>

      <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcConduce')">
        <v-list-item-avatar tile size="32">
          <img src="@/assets/icons_pv/menu/Conduce2.png" alt="Conduce">
        </v-list-item-avatar>
        <v-list-item-title>Conduce</v-list-item-title>
      </v-list-item>


    </v-list>
  </v-bottom-sheet>
</v-card-actions>

  </v-toolbar>
<!-- fn v-toolbar -->
  <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <b :class="`d-flex justify-center`"> {{ LabelReimprecion }}</b>
            <br>

            <v-form v-show="BtnReimprecionvalue != 0" ref="formReimprecion" @submit.prevent="" v-model="valid"
              lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="ReimpresionBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="ReimpresionBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="ReimpresionBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta" @blur="SelctCbClienteBuscar" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" ref="RefCombClienteReImprimir" autocomplete="off" dense outlined
                        label="cliente:" :items="RCliente" item-text="codnom" style="font-size: 13px;"
                        v-model="ReimpresionBusqueda.cliente" @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter>
                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('codigo')" ><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                    <span class="fontSize13 colorNombre" v-if="item!=null && !item.hasOwnProperty('codigo')" >{{item}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getReimprecionlistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col v-show="BtnReimprecionvalue != 0" cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

                <!-- ReimprecionCargarPaginacion
    ReimprecionSelected,ReimprecionObtenerDatoSelect,ReimprecionlistFactCliente,ReimprecionCargarPaginacion,
    ReimprecionDatostabla,ReimprecionTotalRegistros
    ReimprecionPaginator -->
                <!-- @click:row="datostabla" -->

                <div v-if="ReimprecionFacturaSeleccionada.secuencia == 0">

                  <!-- Tabla Reimprecion Factura  @pagination="ReimprecionCargarPaginacion"
    :items-per-page="ReimprecionPaginator.itemsPerPage" :server-items-length="TotalRegistros"    -->
                  <v-data-table class="RowSelectColor" :single-select="true" item-key="indx" :show-select="true"
                    v-model="ReimprecionSelected" @item-selected="ReimprecionObtenerDatoSelect"
                    @click:row="ReimprecionDatostabla" :headers="[{ text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                    { text: '# Secuencia', sortable: false, value: 'secdoc', width: 129 },
                    { text: 'Cliente', sortable: false, value: 'nombre', width: 129 },
                    { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 }]" dense
                    :items="ListReimprecion" @pagination="ReimprecionCargarPaginacion"
                    :items-per-page="ReimprecionPaginator.itemsPerPage" :server-items-length="TotalRegistros"
                    :footer-props="arrayPropiedases">
                    <template v-slot:item.action="{ item }">
                      <!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
                      <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;"
                          class="fa fa-trash-o"></i></v-btn>

                    </template>

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      ListReimprecion.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                    </template>

                    <template v-slot:item.documentoFactura.descripcion="props">
                      <span style="font-size:13px;">{{ props.item.documentoFactura.descripcion }} </span>
                    </template>


                    <template v-slot:item.secdoc="props">
                      <span style="font-size:13px;">{{ props.item.secdoc }} </span>
                    </template>
                    <template v-slot:item.ncf="props">
                      <span style="font-size:13px;">{{ props.item.ncf }} </span>
                    </template>

                    <template v-slot:item.nombre="props">
                      <span v-if="props.item.nombre != null" style="font-size:13px;">{{ props.item.nombre }} </span>
                    </template>

                    <template v-slot:item.sucursal.descripcion="props">
                      <span v-if="props.item.sucursal != null" style="font-size:13px;">{{
                        props.item.sucursal.descripcion
                      }}
                      </span>
                    </template>


                    <template v-slot:item.valor="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                    </template>
                  </v-data-table>
                  <!-- fn Tabla Reimprecion Factura -->

                </div>

                <!-- informacion de fatura  selecionada reimprecion -->
                <v-col v-if="ReimprecionFacturaSeleccionada.secuencia > 0" style="border: 1px solid #000000;"
                  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <v-row>
                    <v-col cols="12" md="8" sm="8">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA #: </b>
                        {{ ReimprecionFacturaSeleccionada.secdoc }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                        {{ FormatoFecha(ReimprecionFacturaSeleccionada.fecha) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NCF : </b>
                        {{ ReimprecionFacturaSeleccionada.ncf }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE : </b>
                        {{ ReimprecionFacturaSeleccionada.cliente }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR : </b>
                        {{ currencyFormatter(ReimprecionFacturaSeleccionada.valor) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> COMENTARIO : </b>
                        {{ ReimprecionFacturaSeleccionada.comentario }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TURNO : </b>
                        {{ ReimprecionFacturaSeleccionada.turno }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FORMA DE PAGO : </b>
                        {{ ReimprecionFacturaSeleccionada.formapago }}</p>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">

                      <div style="border: 1px dashed  #616161;" class="d-flex justify-center">
                        <p style="font-size:20px;" class="pa-0 ma-0 py-0 my-0"><b> ¿DESEA {{ LabelReimprecion }}?</b>
                        </p>
                      </div>
                      <br>
                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'red darken-3' : 'red'" v-ripple="{ class: `white--text` }"
                          @click.prevent="NewReimprecionFacturaSeleccionada">

                          <h2>CANCELAR</h2>

                        </v-btn>
                      </v-hover>

                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'blue darken-3' : 'blue'" v-ripple="{ class: `white--text` }"
                          @click.prevent="ImpriReimprimirFactura">

                          <h2>IMPRIMIR</h2>

                        </v-btn>
                      </v-hover>



                    </v-col>
                  </v-row>
                  <p style="border: 1px dashed  #616161;">

                    <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
                    <v-data-table v-if="BtnReimprecionvalue == 1" :headers="[
                      { text: 'FECHA', value: 'fecha', sortable: false, class: ['no-gutters'] },
                      { text: 'FACTURA', value: 'factura', sortable: false, class: ['no-gutters'] },
                      { text: 'VALOR ORIGINAL', value: 'valororiginal', sortable: false, class: ['no-gutters'] },
                      { text: 'ABONO', value: 'abono', sortable: false, class: ['no-gutters'] },
                      { text: 'PENDIENTE', value: 'pendientedespues', sortable: false, class: ['no-gutters'] },
                    ]" dense :items="ReimprecionFacturaSeleccionada.DetalleList" :items-per-page="15">

                      <template v-slot:item.fecha="props">
                        <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                      </template>

                      <template v-slot:item.factura="props">
                        <span v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null"
                          style="font-size:13px;">
                          {{ props.item.facturasventa.facturasventaPK.secuencia }}
                        </span>

                        <span
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null"
                          style="font-size:13px;">
                          {{ props.item.notadebitoclientes.notadebitoclientesPK.secuencia }}
                        </span>
                      </template>

                      <template v-slot:item.valororiginal="props">

                        <span v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null"
                          style="font-size:13px;">
                          {{ currencyFormatter(props.item.facturasventa.valor) }}
                          <!-- <span style="display:none;">{{props.item.pendiente=props.item.facturasventa.valor-props.item.abono}}</span> -->
                        </span>
                        <span
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null"
                          style="font-size:13px;">
                          {{ props.item.notadebitoclientes.valor }}
                          <!-- <span style="display:none;">{{props.item.pendiente=props.item.notadebitoclientes.valor-props.item.abono}}</span> -->
                        </span>
                      </template>

                      <template v-slot:item.abono="props">
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.abono) }}</span>
                      </template>

                      <template v-slot:item.pendiente="props">
                        <!-- <span style="font-size:13px;">{{ currencyFormatter( props.item.pendiente) }}</span> -->
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.pendiente) }}</span>
                      </template>


                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->


                    <!-- Tabla ReimprecionFacturaSeleccionada de varias-->
                    <v-data-table v-if="BtnReimprecionvalue == 3 || BtnReimprecionvalue == 6 || BtnReimprecionvalue == 7"
                      :headers="[
                        { text: 'PRODUCTO', value: 'descripcion', sortable: false, class: ['no-gutters'] },
                        { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters'] },
                        { text: 'VALOR', value: 'valor', sortable: false, class: ['no-gutters'] },
                        { text: 'IMPORTE', value: 'importe', sortable: false, class: ['no-gutters'] },
                      ]" dense :items="ReimprecionFacturaSeleccionada.DetalleList" :items-per-page="15">

                      <template v-slot:item.valor="props">
                        <!-- <span style="font-size:13px;" >{{currencyFormatter( (props.item.cantidad * props.item.precio)+props.item.valorimpuesto)}}</span> -->
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span>
                      </template>

                      <template v-slot:item.descripcion="props">
                        <span style="font-size:13px;">{{ props.item.descripcion }}</span>
                      </template>

                      <template v-slot:item.cantidad="props">
                        <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                      </template>

                      <template v-slot:item.importe="props">
                        <!-- <span style="font-size:13px;">{{ currencyFormatter( props.item.importe) }}</span> -->
                        <!-- <span style="font-size:13px;" >{{currencyFormatter( (props.item.cantidad * props.item.precio))}}</span> -->

                        <span style="font-size:13px;" v-if="IncluirItbisGeneral == true">
                          {{ currencyFormatter((props.item.cantidad * props.item.precio)) }}
                        </span>

                        <span style="font-size:13px;" v-if="IncluirItbisGeneral == false">
                          {{ currencyFormatter((props.item.cantidad * props.item.precio) + (props.item.valorimpuesto *
                            props.item.cantidad))
                          }}
                        </span>
                      </template>
                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->


                    <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
                    <v-data-table v-if="BtnReimprecionvalue == 4" :headers="[
                      { text: 'PRODUCTO', value: 'producto.descripcion', sortable: false, class: ['no-gutters'] },
                      { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters'] },
                      { text: 'COSTO', value: 'costo', sortable: false, class: ['no-gutters'] },
                      { text: 'VALOR', value: 'valor', sortable: false, class: ['no-gutters'] },

                    ]" dense :items="ReimprecionFacturaSeleccionada.DetalleList" :items-per-page="15">

                      <template v-slot:item.valor="props">
                        <!-- <span style="font-size:13px;" >{{currencyFormatter( (props.item.cantidad * props.item.precio)+props.item.valorimpuesto)}}</span> -->
                        <span style="font-size:13px;">{{ currencyFormatter(ReimprecionItemSelectValor(props.item))
                        }}</span>
                      </template>

                      <template v-slot:item.producto.descripcion="props">
                        <span style="font-size:13px;">{{ props.item.producto.descripcion }}</span>
                      </template>

                      <template v-slot:item.cantidad="props">
                        <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                      </template>

                      <template v-slot:item.costo="props">
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.costo) }}</span>
                      </template>

                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->




                  </p>

                </v-col>
                <!-- fn  informacion de fatura  selecionada reimprecion -->



              </div>
            </v-col>




          </v-card>
  </v-col>





<!---------------------  Panel Menu Opcion Botones ------------------------------->
          <div style="max-height:300px;" class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv" :cajaSelect="cajaSelect" />
            </div>
            <!--------------------- fn Panel Menu Opcion Botones ------------------------------->

            <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>
</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric,
    PvMenuOpcionBotones
  },
  created() { 
            console.log('created--PVCONFIGU')
  },
  mounted() {
    this.IncluirItbisGeneral = this.$store.getters.GetConfiguracionGeneral.configPv.impuestoincluido;

  },
  beforeDestroy() {

  },

  data: () => ({ 
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    IncluirItbisGeneral: false,
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    filtroTipoCliente: 3,
    RCliente:[],
    EjecutarPermisoPv:false,
    BtnReimprecionvalue: 0,
    LabelReimprecion: "SELECCIONAR TIPO DE IMPRESIÓN",
    ReimprecionSheet: false,
    ReimpresionBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },
    ListReimprecion: [],
    TotalRegistros: 1,
    resertPagi: true,
    listFactVent: [],
    ReimprecionPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    ReimprecionSelected:[],
    ReimprecionFacturaSeleccionada: {
      secuencia: 0,
      fecha: null,
      ncf: "",
      cliente: "",
      comentario: "",
      turno: 0,
      formapago: "",
      DetalleList: [],
    },
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),
  props: { 

cajaSelect: {
  required: false,
  type: Object,
  default: null
},
configuraciones: {
  required: false,
  type: Object,
  default: null
},
OtrasConfigPv: {
  required: false,
  type: Object,
  default: null
},
  },

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,getParamFecha,
    // Crear un nuevo objeto con las mismas propiedades que el objeto en la matriz.
    NewReimprecionFacturaSeleccionada() {
      this.ReimprecionFacturaSeleccionada = {
        secuencia: 0,
        fecha: null,
        ncf: "",
        cliente: "",
        comentario: "",
        turno: 0,
        formapago: "",
        DetalleList: [],
        fac: {},
      };
    },
    cancelarReimpresion(){
      this.ListReimprecion = [] 
      this.NewReimprecionFacturaSeleccionada(), 
      this.BtnReimprecionvalue = 0 
      this.dialogReimprecion = false


      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarReimpresion", false)
     }, 300);
    },
    // Comprobando si el usuario tiene permiso para imprimir un dato.
    ReimpresionOpciones(e) {
      this.ListReimprecion = []
      this.NewReimprecionFacturaSeleccionada()
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv(e)
        return;
      }

    },

    // Obtener una lista de objetos del servidor y luego insertarlos en una tabla los datos obtenidos.
    getReimprecionlistFactCliente() {

this.ListReimprecion = [];
if (this.ReimpresionBusqueda.secuencia.length == 0) {
  this.ReimpresionBusqueda.secuencia = 0;
}
//var lccaja = JSON.parse(localStorage.caja);
//lccaja.codigo
//lccaja.sucursal.codigo

var cliente = "null",
  fecini = "null",
  fecfin = "null",
  sec = 0;
if (this.$refs.formReimprecion.validate()) {
  var url = "";
  if (this.BtnReimprecionvalue == 0) {
    return;
  }
  if (this.BtnReimprecionvalue == 1) {
    url = "recibos";
  }
  if (this.BtnReimprecionvalue == 2) {
    url = "otrosingresos";
  }
  if (this.BtnReimprecionvalue == 3) {
    url = "facturas";
  }
  if (this.BtnReimprecionvalue == 4) {
    url = "devolucion";
  }
  if (this.BtnReimprecionvalue == 5) {
    url = "valecajas";
  }
  if (this.BtnReimprecionvalue == 6) {
    url = "cotizacion";
  }
  if (this.BtnReimprecionvalue == 7) {
    url = "conduce";
  }
  if (this.ReimpresionBusqueda.cliente != null) {
    //cliente = this.ReimpresionBusqueda.cliente.nombre;
    // cliente = this.$refs.RefCombClienteReImprimir.internalSearch;
    if(this.$refs.RefCombClienteReImprimir.selectedItem.hasOwnProperty('codigo')){
        cliente = this.$refs.RefCombClienteReImprimir.selectedItem.nombre;
       }else{
        cliente = this.$refs.RefCombClienteReImprimir.selectedItem;
       }
  }
  if (this.ReimpresionBusqueda.fechaInicial != null) {
    fecini = this.ReimpresionBusqueda.fechaInicial;
  }
  if (this.ReimpresionBusqueda.fechaFinal != null) {
    fecfin = this.ReimpresionBusqueda.fechaFinal;
  }
  if (this.ReimpresionBusqueda.secuencia != null) {
    sec = this.ReimpresionBusqueda.secuencia;
  }

  var _this = this;
setTimeout(function () {
  _this.$axios
    .get(
      _this.$hostname +
      _this.$hName +
      "/reimpresion/" +
      url +
      "/" +
      sec +
      "/" +
      fecini +
      "/" +
      fecfin +
      "/" +
      cliente +
      "/" +
      _this.ReimprecionPaginator.itemsPerPage +
      "/" +
      (_this.ReimprecionPaginator.pageStart),
      { headers: _this.$store.getters.GetheadersAxios }
    )
    .then((res) => {
      (console.log('---informacion---')),
      (console.log(res.data)),
      _this.ListReimprecion = [];
      (_this.TotalRegistros = res.data.cantidad),
        (_this.listFactVent = res.data.listas);
        _this.listFactVent.forEach((e) => {
        var det = null;
        if (_this.BtnReimprecionvalue == 1) {
          det = {
            fecha: e.fecha,
            secuencia: e.recibosdeingresoPK.secuencia,
            secdoc: e.documentocxc.nombrecorto.trim() + "-" + e.recibosdeingresoPK.secuencia,
            nombre: e.cliente === null ? "" : e.cliente.nombre,
            valor: e.valor,
            ncf: "",
            comentario: e.concepto,
            turno: "",
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.recibosingresoDetalleList,
            recibos: e,
          };
        }
        if (_this.BtnReimprecionvalue == 2) {
          det = {
            fecha: e.fecha,
            secuencia: e.secuencia2,
            secdoc: e.documento.nombrecorto.trim() + "-" + e.secuencia2,
            nombre: e.nombre.trim(),
            valor: e.valor,
            ncf: "",
            comentario: e.concepto,
            turno: "",
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.facturasventaDetalleList,
            recibo: e,
          };
        }
        if (_this.BtnReimprecionvalue == 3) {
          det = {
            fecha: e.fecha,
            secuencia: e.facturasventaPK.secuencia,
            secdoc: e.documentoFactura.nombrecorto.trim() + "-" + e.facturasventaPK.secuencia,
            nombre: e.cliente === null ? "" : e.cliente.nombre,
            valor: e.valor,
            ncf: e.ncf,
            comentario: e.comentario,
            turno: e.turno,
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.facturasventaDetalleList,
            factura: e,
          };
        }
        if (_this.BtnReimprecionvalue == 4) {
          //(e.notacreditocontado===null && e.notacreditocontado.cliente===null ?'':e.notacreditocontado.cliente.nombre),
          det = {
            fecha: e.fecha,
            secuencia: e.devoclucionPK.secuencia,
            secdoc: e.documentoinventario.nombrecorto.trim() + "-" + e.devoclucionPK.secuencia,
            nombre:
              e.notacreditocontado != null &&
                e.notacreditocontado.cliente != null
                ? e.notacreditocontado.cliente
                : "",
            valor: e.valor,
            ncf: e.ncf,
            comentario: e.comentario,
            turno: "",
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.devolucionDetalleList,
            devolucion: e,
          };
        }
        if (_this.BtnReimprecionvalue == 5) {
          det = {
            fecha: e.fecha,
            secuencia: e.secuencia,
            secdoc: e.secuencia,
            nombre: e.beneficiario,
            valor: e.valor,
            ncf: "",
            comentario: e.descripcion,
            turno: "",
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.facturasventaDetalleList,
            valecaja: e,
          };
        }
        if (_this.BtnReimprecionvalue == 6) {
          det = {
            fecha: e.fecha,
            secuencia: e.secuencia,
            secdoc: e.secuencia,
            nombre: e.nombrecliente,
            valor: e.valor,
            ncf: "",
            comentario: e.comentario,
            turno: "",
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.cotizacionDetalleList,
            cotizacion: e,
          };
        }
        if (_this.BtnReimprecionvalue == 7) {
          det = {
            fecha: e.fecha,
            secuencia: e.conduceInventarioPK.secuencia,
            nombre: e.cliente == null ? "" : e.cliente.nombre,
            secdoc: e.documentoinventario.nombrecorto.trim() + "-" + e.conduceInventarioPK.secuencia,
            valor: e.valor,
            ncf: "",
            comentario: e.comentario,
            turno: null,
            pago: _this.ObtenerFormaDepago(e),
            detalle: e.conduceInventarioDetalleList,
            conduce: e,
          };
        }
        if (det != null) {
          _this.ListReimprecion.push(det);
        }
      });
    });

  }, 300);

} else {
  this.alerta("Los campos en rojo son requeridos", "error");
}
    },

// Comprobando si el objeto tiene una propiedad y si la tiene devuelve el valor de la propiedad.
    ObtenerFormaDepago(e) {
      var Fpago = '';

      if (e.hasOwnProperty("cxc") && e.cxc > 0) {
        Fpago = "Cuenta Por Cobrar"
        return Fpago;
      }

      if (e.hasOwnProperty("tarjeta") && e.tarjeta > 0) {
        Fpago = "Tarjeta de Credito"
        return Fpago;
      }

      if (e.hasOwnProperty("cheques") && e.cheques > 0) {
        Fpago = "Cheques"
        return Fpago;
      }

      if (e.hasOwnProperty("transferencia") && e.transferencia > 0) {
        Fpago = "Transferencia"
        return Fpago;
      }

      if (e.hasOwnProperty("efectivo") && e.efectivo > 0) {
        Fpago = "Efectivo"
        return Fpago;
      }

      return Fpago;

    },

// Restableciendo la paginación Reimprecion.
    ReimprecionResetCargarPaginacion() {
      this.ReimprecionPaginator = {
        itemsPerPage: 15,
        pageStart: 0,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      }

      var _this = this;
      setTimeout(function () { _this.getReimprecionlistFactCliente() }, 300);

    },

    ReimprecionCargarPaginacion(e) {
      this.ReimprecionSelected = [];
      this.ReimprecionPaginator = e;
      if (this.ReimprecionPaginator.itemsPerPage == 1) {
        this.ReimprecionPaginator.itemsPerPage = 15;
      }
      this.getReimprecionlistFactCliente();
    },

    ReimprecionObtenerDatoSelect(e) {
      this.ReimprecionDatostabla(e.item);
    },
    ReimprecionDatostabla(e) {
      this.NewReimprecionFacturaSeleccionada();
      this.ReimprecionSelected = [];
      this.ReimprecionSelected.push(e);
      var objjt = null;
      objjt = {
        secuencia: e.secuencia,
        secdoc: e.secdoc,
        fecha: new Date(e.fecha),
        ncf: e.ncf,
        cliente: e.nombre,
        comentario: e.comentario,
        turno: e.turno,
        formapago: e.pago,
        DetalleList: e.detalle,
        valor: e.valor,
        factura: JSON.parse(JSON.stringify(e))
      };

      if (this.BtnReimprecionvalue == 1) {
        objjt.factura.recibos.fecha = new Date(e.recibos.fecha).toISOString().substr(0, 10)
      }
      if (this.BtnReimprecionvalue == 2) {
        objjt.factura.recibo.fecha = new Date(e.fecha).toISOString().substr(0, 10)
      }

      if (this.BtnReimprecionvalue == 3) {
        objjt.factura.fecha = new Date(e.fecha).toISOString().substr(0, 10)
        objjt.factura.factura.fecha = new Date(e.fecha).toISOString().substr(0, 10)


        /*var sec=e.factura.facturasventaPK.secuencia
        var doc=e.factura.facturasventaPK.documento
        this.$axios
        .get(this.$hostname + this.$hName + `/facturasventas/findDomicilioFactura/${sec}/${doc}`, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          console.log('domicilio')
          console.log(res.data)
        })
        .catch((error) => console.log(error));*/

      }

      if (this.BtnReimprecionvalue == 4) {
        objjt.factura.devolucion.fecha = new Date(e.devolucion.fecha).toISOString().substr(0, 10)
        objjt.factura.devolucion.facturasventa.fecha = new Date(e.devolucion.facturasventa.fecha).toISOString().substr(0, 10)
        objjt.quitaritbis = e.devolucion.quitaritbis
      }

      if (this.BtnReimprecionvalue == 5) {
        objjt.factura.valecaja.fecha = new Date(e.valecaja.fecha).toISOString().substr(0, 10)
      }

      if (this.BtnReimprecionvalue == 6) {
        objjt.factura.cotizacion.fecha = new Date(e.cotizacion.fecha).toISOString().substr(0, 10)
      }

      if ( this.BtnReimprecionvalue== 7) {
        objjt.factura.conduce.fecha = new Date(e.conduce.fecha).toISOString().substr(0, 10)
      }

      //objjt.factura.fecha=new Date(e.fecha).toISOString().substr(0, 10)
      /* var tzoffset = (new Date(e.fecha)).getTimezoneOffset() * 60000; //offset in milliseconds
       var localISOTime = (new Date(Date.now(e.fecha) - tzoffset)).toISOString().slice(0, -1);
       console.log(localISOTime)  // => '2015-01-26T06:40:36.181'
       objjt.factura.fecha=localISOTime
       objjt.factura.factura.fecha=localISOTime*/
      /* console.log('Imprimiendo aqui...')
       console.log(new Date(e.fecha).toISOString().substr(0, 10))*/
      // console.log(objjt)
      this.ReimprecionFacturaSeleccionada = objjt;
    },
    
    // Llamar a una función en un componente secundario para los permisos de usuario.
    funcionValidaPermisoPv(e) {

var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

//if (Sudo == false) { }
var obj = {
  accion: JSON.parse(JSON.stringify(e)),
  Permiso: permiso,
  Sudo: Sudo
}
this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

    },

    DataRetornMenuOpcBotonesPermisosPv(){},

    DataRetornPadrePermisoPv(e) {
      if (e == 'AccReimpresionOpcRecibo') {
        this.BtnReimprecionvalue = 1
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcOtrosingresos') {
        this.BtnReimprecionvalue = 2
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcFactura') {
        this.BtnReimprecionvalue = 3
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcDevolución') {
        this.BtnReimprecionvalue = 4
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcValeCaja') {
        this.BtnReimprecionvalue = 5
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcCotizacion') {
        this.BtnReimprecionvalue = 6
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcConduce') {
        this.BtnReimprecionvalue = 7
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

    },


// Impresión de un Objecto.
    ImpriReimprimirFactura() {
      if (localStorage.printerSelect != null) {
        var msg = {
          accion: "reprint",
          formato: this.cajaSelect.formatoimpresioncontado.trim(),
          impresora: JSON.parse(localStorage.printerSelect).nombre,
          data: this.configuraciones,
          fac: this.ReimprecionFacturaSeleccionada,
          cajero: this.OtrasConfigPv.cajero,
          caja: this.cajaSelect,
        };
        if (this.BtnReimprecionvalue == 1) {
          this.imprimir(msg, "recibo");
        }
        if (this.BtnReimprecionvalue == 2) {
          this.imprimir(msg, "otros-ingreso");
        }
        if (this.BtnReimprecionvalue == 3) {
          this.imprimir(msg, "factura");
        }
        if (this.BtnReimprecionvalue == 4) {
          this.imprimir(msg, "devolucion");
        }
        if (this.BtnReimprecionvalue == 5) {
          this.imprimir(msg, "vale");
        }
        if (this.BtnReimprecionvalue == 6) {
          this.imprimir(msg, "cotizacion");
        }
        if (this.BtnReimprecionvalue == 7) {
          this.imprimir(msg, "conduce");
        }
      }

    },

    imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          console.log(res.data);
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            // this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => console.error(error));
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            // this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => console.error(error));
      }
    },

    // Llamar a una API y obtener los datos del cliente.
    cargarClienteBusqueda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + this.filtroTipoCliente + "/25/0", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)+
            "/" + this.filtroTipoCliente + "/25/0",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },

    
  },
  
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      //-------Modale reimprecion--------
      if (this.BtnReimprecionvalue == 0) {
        this.LabelReimprecion = "SELECCIONAR UN TIPO DE IMPRESIÓN";
      }

      if (this.BtnReimprecionvalue == 1) {
        this.LabelReimprecion = "REIMPRIMIR RECIBO";
      }
      if (this.BtnReimprecionvalue == 2) {
        this.LabelReimprecion = "REIMPRIMIR OTROS INGRESOS";
      }
      if (this.BtnReimprecionvalue == 3) {
        this.LabelReimprecion = "REIMPRIMIR FACTURA";
      }
      if (this.BtnReimprecionvalue == 4) {
        this.LabelReimprecion = "REIMPRIMIR DEVOLUCIÓN";
      }
      if (this.BtnReimprecionvalue == 5) {
        this.LabelReimprecion = "REIMPRIMIR VALE DE CAJA";
      }
      if (this.BtnReimprecionvalue == 6) {
        this.LabelReimprecion = "REIMPRIMIR COTIZACIÓN";
      }
      if (this.BtnReimprecionvalue == 7) {
        this.LabelReimprecion = "REIMPRIMIR CONDUCE";
      }
      //------- fn Modale reimprecion--------
     }
  }
};
</script>
<style lang="scss">
</style>