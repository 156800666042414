<template>
  
<div v-if="dialogConsultaConsumoCliente==true" v-dirkeydown="KeyESC" 
>
  
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
  <!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> CONSULTA DE CONSUMO CLIENTES
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>CONSULTA DE CONSUMO CLIENTES: </span> CONSULTA DE CONSUMO CLIENTES</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewNuevo()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>
  <v-tooltip bottom>

    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click="CancelarCerrarModal()" color="orange"
        small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

</v-card-actions>
</v-toolbar>
<!-- fn v-toolbar accion  -->
<v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row :class="`d-flex justify-center`">

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field ref="RefTextFieldCodigoBarra" background-color="white" color="grey darken-4"
                    autocomplete="off" v-on="on" v-model="searchCodigoBarra" @keyup="SeachFilCodigoBarra"
                      label="Buscar Código barra cliente Preferencial" single-line outlined dense>
                    <template v-slot:prepend-inner>
                      <i style="font-size: 25px;" class="fa fa-search"></i>
                    </template>
                  </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se busca el codigo de barra del cliente preferencial.</span>
                    </i>
                  </v-tooltip>
                </v-col>

              </v-row>
            </v-form>
          </v-card>

          <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0"  >
            <!-- :class="`d-flex justify-center white--text`" -->
             <center>
              <br>
            <div :class="`black--text`" >
              <h1 v-if="(``+CLienteSelect.codigoClinete).length>0">Código: {{CLienteSelect.codigoClinete}}</h1>
              <h1><b class="blue--text">{{CLienteSelect.cliente}} </b></h1>
              <h1>{{CLienteSelect.balancePunto}}</h1>
            </div>
           </center>


 <!--------- columna 2 Historial de compras---------->
 <v-col style="border: 1px solid #000000; " cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">

<v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
  <span :class="`d-flex justify-center white--text`"><b>Historial de compras del cliente</b></span>
</v-col>

<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

  <!--------------------------------------------------  Header tb Historial compra cliente -->
  <!-- <v-col style="background-color:#3d6687;" class="py-0 my-0" cols="12" md="12" sm="12">
        <v-row style="border: 1px solid #000000;">
          <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">Fecha</b>
          </v-col>
          <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">
              Cantidad</b></v-col>
          <v-col class="pa-0 ma-0" cols="4" md="4"><b :class="`d-flex justify-center white--text`"> Precio
            </b></v-col>
          <v-col class="pa-0 ma-0" cols="4" md="4"><b :class="`d-flex justify-center white--text`"> No.Factura
            </b></v-col>
        </v-row>
      </v-col> -->
  <!-------------------------------------------------- fn Header tb Historial compra cliente  -->
  <!-------------------------------------------------- tb Historial compra cliente 
:single-select="true" :show-select="true" 
-->
  <!--  hide-default-header  -->
  <div
    v-bind:style="{ 'height': this.windowSize.height - 310 + 'px', 'max-height': this.windowSize.height - 310 + 'px' }"
    class="overflow-x-auto">
    <v-data-table dense item-key="indx" :headers="[
      { text: 'FECHA', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
      { text: 'No.FACTURA', value: 'factura', sortable: false, class: ['no-gutters black--text'] },
      { text: 'PRODUCTO', value: 'producto', sortable: false, class: ['no-gutters black--text'] },
      { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters black--text'] },
      { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
    ]
    " :items="RHistorialCompracliente" class="elevation-1 TbSize13" :footer-props="arrayPropiedases"
      :items-per-page="TbPaginatorHisCli.itemsPerPage" @pagination="cargarPaginacionAllHisCli"
      :server-items-length="TbTotalItemHisCli" :page="TbPaginatorHisCli.page"
      :pageCount="TbPaginatorHisCli.pageCount">

      <template v-slot:item.fecha="props">
        <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
      </template>

      <template v-slot:item.precio="props">
        <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span>
      </template>

    </v-data-table>
  </div>
  <!-------------------------------------------------- fn tb Historial compra cliente-->



</v-col>


</v-col>
<!--------- fn columna 2 Historial de compras ---------->


            </v-col>


</v-col>

<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

</v-col>
</div> 
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  directives: {
    dirkeydown: {
      // Definición de directiva
      bind(el, binding) {
        // __HandleScrollHandler__
        el.__HandledKey__ = event => binding.value(event)
        document.body.addEventListener('keydown', el.__HandledKey__)
      },
      unbind(el) {
        document.body.removeEventListener('keydown', el.__HandledKey__)
      }

    }
  },

  components: {
    VueAutonumeric
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 

    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },

    windowSize: { width: "auto", height: "auto" },
    valid: true,
    clienteSeleccionado:null,
    dialogConsultaConsumoCliente: false,
    RHistorialCompracliente: [],
    searchCodigoBarra:'',
    CLienteSelect: {
      codigoClinete: '',
      cliente: '',
    },


    TbTotalItemHisCli: 0,
    TbPaginatorHisCli: {
      page: 1,
      itemsPerPage: 10,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,
  
// Hacer una llamada API al servidor y devolver los datos de la cotizacion.
SeachFilCodigoBarra(e) {

  var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true){
        var BANDERA_CODBAR_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.banderaCodigoBarraPreferencial
        //key: "Enter"keyCode: 13
        if(e.keyCode == 13){
          if(e.target.value.substr(0,2)===BANDERA_CODBAR_CLI_PRE){
            this.SeachTextFilBarraPreferencial(e.target.value)
            return
          }
        }
      }

},

 // Busqueda de codigo_barra_preferencial del cliente .
 SeachTextFilBarraPreferencial(e){
  
  if(e.trim().length>0){
    this.newPaginacionAllHIscli()
this.$axios
        .get(this.$hostname + this.$hName + "/clientes/getclientebarrapreferencial/"+e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.hasOwnProperty("codigo")){
            this.CLienteSelect.codigoClinete=res.data.codigo
            this.CLienteSelect.cliente=res.data.nombre
            this.clienteSeleccionado=res.data
            // this.CLienteSelect.balancePunto=`PUNTOS ACUMULADO: ${currencyFormatter(res.data.balancePuntos)}`
            // this.clienteFactura.codnom= `${res.data.codigo} - ${res.data.nombre.trim()} `
            this.alerta("Cliente Seleccionado", "bien2");

            var _this = this;

            setTimeout(function () {
       
   _this.CargarHistorialCliente()
 
               }, 800);



         /*setTimeout(function () {
            _this.NewNuevo()
            _this.searchCodigoBarra=""
            _this.focusRefTextFieldCodigoBarra()
  
               }, 10000);*/
          }else{
            this.alerta("No existe cliente preferencial con ese código", "error");
            this.NewNuevo

          }
          
        })
        .catch(error => { this.alerta("dato no encontrado", "error") });


  }else{

  }
         

},


// Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
NewNuevo() {
  this.clienteSeleccionado=null
  this.RHistorialCompracliente = [];
  this.TbTotalItemHisCli=0
  this.searchCodigoBarra=""
  this.CLienteSelect= {
      codigoClinete: '',
      cliente: '',
    }

    },
    

    CancelarCerrarModal() {
      this.dialogConsultaConsumoCliente = false;
      this.NewNuevo();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarConsumoCliente", this.dialogConsultaConsumoCliente)
     }, 300);
    },


focusRefTextFieldCodigoBarra(){
 this.dialogConsultaConsumoCliente=true
  var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldCodigoBarra.focus();
        }, 500);

},

newPaginacionAllHIscli(){
        this.TbTotalItemHisCli = 0
        this.TbPaginatorHisCli = {
          page: 1,
          itemsPerPage: 50,
          pageStart: 1,
          pageStop: 15,
          pageCount: 10,
          itemsLength: 5,
        }

},

cargarPaginacionAllHisCli(e) {
      this.TbPaginatorHisCli = JSON.parse(JSON.stringify(e));
      this.CargarHistorialCliente()
    },

    // Llamar a una API y obtener los datos factura historial del cliente..
    CargarHistorialCliente() {
      this.RHistorialCompracliente = []
      if (this.clienteSeleccionado == null || this.clienteSeleccionado.codigo == 0) {
        return
      }

      //    /FindHistorialCliente/{limit}/{ultimo}/{cliente}
      var obj =
        this.TbPaginatorHisCli.itemsPerPage +
        "/" +
        this.TbPaginatorHisCli.pageStart +
        "/" +
        this.clienteSeleccionado.codigo;

      this.$axios
        .get(
          this.$hostname + this.$hName + "/facturasventas/FindHistorialCliente/" + obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.RHistorialCompracliente = res.data.ListHistCliente),
            (this.TbTotalItemHisCli = res.data.TbTotalItem);
        });


    },




KeyESC(e){
      if (e.keyCode ==27 ) {
           this.CancelarCerrarModal()
        }
  
  
      },


alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },


    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>