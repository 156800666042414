<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
<tr max-height="48px" height="48px"  >     
<ContenidoBase 
  :titulo="'Reporte de Control de Drogas'"
  :descripcion="'Imprime Reporte de Control de Drogas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   

    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesControlDrogas'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Reporte de Control de Drogas </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">


  <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="ControlDrogas.desde"
        @blur="ReportBlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>

<v-combobox                 
                        autocomplete="off"
                        dense
                        outlined
                        label="Elija ventas o compras:"
                        :items="RTipoVC"
                        item-text="descripcion"
                        :rules="[$rules.required]"
                        v-model="ControlDrogas.TipoVC"  
                        @blur="SelctCbTipoVC"
                      >
                        <template v-slot:prepend>
                          <i
                            style="font-size: 20px;"
                            class="fa fa-wpforms"
                          ></i>
                        </template>
                      </v-combobox>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="ControlDrogas.hasta"
        @blur="ReportBlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
                </v-text-field>
</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros
ref="ModRepfil"
@DataReporteBasico="DDataReporteBasico"
:Datos="LinkDataProductoReorden"
/>

  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->

    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import {currencyFormatter,FormatoFecha,HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() { 
    var json = {  
      titulo: "Reporte de Control de Drogas",
      descripcion: "Imprime Reporte de Control de Drogas",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [ 
        "ReportesControlDrogasNew",
        "ReportesControlDrogasSave",
        "ReportesControlDrogasRemove",
        "ReportesControlDrogasAnular",
        "ReportesControlDrogasPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesControlDrogasNew", this.new);
    // EventBus.$on("ReportesControlDrogasSave", this.save);
    // EventBus.$on("ReportesControlDrogasRemove", this.remove);
    // EventBus.$on("ReportesControlDrogasAnular", this.remove);
    // EventBus.$on("ReportesControlDrogasPrintList", this.imprimirList);
    EventBus.$on("emitReportesControlDrogas", this.datostabla);
   // EventBus.$on("onResize", this.onResize);

   this.$refs.ModRepfil.CrearObjecto=this.validarObjecto;
  },

  data:()=>  ({


      RFabricante:[],
      Rpropietario:[],
      RCaja:[],
      RTipoVC:[
            {codigo:1, descripcion: 'Ventas'},
            {codigo:2, descripcion: 'Compras'},
      ],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

LinkDataProductoReorden:{
  nombre:'REPORTE DE CONTROL DE DROGAS',
      UrlImprimir: '/reportesInv/ImprimirControlDrogas', 
      UrlExel: '/reportesInv/ExcelControlDrogas', 
      UrlCorreo: '/reportesInv/CorreoControlDrogas', 

},

      ControlDrogas: {
        TipoVC:null,
        desde: fechaNueva(),
        hasta:fechaNueva(),
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

      EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },




     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
onResize(e){//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    datostabla(e) {

      this.ControlDrogas = e;
      this.acti_tb_data=false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() {},
DDataReporteBasico(e){
},

validarObjecto(){ 
//this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]


// LinkDataProductoReorden
//       ControlDrogas: {
//         TipoVC:null,
//         desde: new Date().toISOString().substr(0,10),
//         hasta:new Date().toISOString().substr(0,10),
//       },

var where = "";
this.LinkDataProductoReorden.Condicion=""


if(this.ControlDrogas.TipoVC.codigo==1){
this.LinkDataProductoReorden.TipoVC=1
where+= " fvd.secuencia > 0 and p.controlado = true";
where+=` and fv.fecha >=  ' ${this.ControlDrogas.desde}' and fv.fecha <= ' ${this.ControlDrogas.hasta}' `
//ic.imprimir("reporte_control_de_drogas", parametros, "rep");
}


if(this.ControlDrogas.TipoVC.codigo==2){
  this.LinkDataProductoReorden.TipoVC=2
  where+= " fpd.codigo > 0 and p.controlado = true";
  where+=` and fp.fecha >= ' ${this.ControlDrogas.desde}' and fp.fecha <=' ${this.ControlDrogas.hasta}' `
//ic.imprimir("reporte_control_drogas_compras", parametros, "rep");
}

this.LinkDataProductoReorden.where=where
this.LinkDataProductoReorden.desde= this.FormatoFecha(this.ControlDrogas.desde)
this.LinkDataProductoReorden.hasta=this.FormatoFecha(this.ControlDrogas.hasta)

},





    newwnuevo() {
      (this.ControlDrogas = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },





ReportBlurDesde(){
if(this.ControlDrogas.desde.toString().length==0){
  this.ControlDrogas.desde=this.fechaNueva()
}

 },


  ReportBlurHasta(){

if(this.ControlDrogas.hasta.toString().length==0){
  this.ControlDrogas.hasta=this.fechaNueva()
}

 },


SelctCbTipoVC(){


   if(this.ControlDrogas.TipoVC==null){

    this.ControlDrogas.TipoVC=''
  }

if(this.ControlDrogas.TipoVC.codigo==undefined){

this.alerta("Seleccione un sucursal", "error");
this.ControlDrogas.TipoVC=''
return
}
},





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      // await this.$axios
      //   .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
      //     usuario:JSON.parse(JSON.stringify(this.$user)) 
      //   })
      //   .then(res => {
      //     this.list = res.data;
      //   });
    }
  },

computed:{

    ActDescBtn(){

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))     


 if(this.ControlDrogas.TipoVC==null || this.ControlDrogas.TipoVC==""){
   this.ControlDrogas.TipoVC=JSON.parse(JSON.stringify(this.RTipoVC[0]))
 }



    }

}


};
</script>

<style></style>
