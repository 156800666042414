<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
  
<tr max-height="48px" height="48px">   
<ContenidoBase 
  :titulo="'Reporte Mayor General'"
  :descripcion="'Imprime Reporte Mayor General'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesDiarioGeneral'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">

<v-toolbar
        flat
        color="#BDBDBD"
        dense
        style="border: 1px solid #000000;"
      >
        
  
 <v-avatar tile  size="45"  >
      <img
        src="@/assets/iconos_micromarket/custom-reports.png"
        alt="Reporte"
      >
    </v-avatar>

    
<span class="font-weight-light" style="font-size: 20px;" > Reporte Mayor General </span >

  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
   <!-- style="color:white;" -->
    <!-- <i class=""> </i> -->

        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
      </v-toolbar>
           
<hr>

  <v-container style="border: 1px solid #000000;">
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">

<v-combobox label="Cuenta inicial:"
                    v-model="MayorGeneral.cuentaIni"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbReportcuentaInicio"
                    @focus="CargarRcuenta"
                    
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
</v-combobox>

<!-------------------------------------------------------- Período -->
<v-combobox label="Período Contable:"
                    v-model="MayorGeneral.periodo"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="Rperiodo"
                    item-text="desPeriodo"
                    @focus="CargarRperiodo"
                    @blur="SelctCbPeriodo"
                    @change="ChangePeriodo"


                  >  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-clock-o"
                            ></i>
                          </template>
</v-combobox> 

 <!-- -----------------------------  desde  -->
 <v-text-field label="* Fecha inicial:"
        dense
        outlined
        type="date"  
         :rules="[$rules.required]"
        v-model="MayorGeneral.desde"
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

 </v-text-field>

</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">

<v-combobox label="Cuenta Final:"
                    v-model="MayorGeneral.cuentaFin"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="Rcuenta"
                    @keyup="CbFilCuenta"
                    :item-text="RfilCuenta"
                    @blur="SelctCbReportcuentaFinal"
                    @focus="CargarRcuenta"
                    
                  > 

              <template slot="item" slot-scope="item" margin-top="50px">
                 <v-row>
<v-col cols="6" md="6">{{ item.item.cuenta }}</v-col>
<v-col cols="6" md="6">{{ item.item.descripcion}}</v-col>
</v-row>      
                </template>

<template v-slot:prepend-item>
                  <v-row>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;"> Cuenta: </b> </v-col>
                  <v-col cols="6" md="6" > <b style="font-size: 18px;">Descripción: </b> </v-col>
                 </v-row>
                  </template>


  <template v-slot:prepend>
  <i style="font-size: 20px;" class="fa fa-sticky-note-o"></i> 
 </template>   
</v-combobox>
              
<v-row > 
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                          <v-switch label="Resumido:"
                          class="mt-0"
                          color="teal"
                          inset 
                          v-model="MayorGeneral.Resumen"
                           >
                        <template v-slot:label>
                        <strong v-if="MayorGeneral.Resumen==true" style="color:#000000;">Resumido: Si</strong>
                        <strong v-if="MayorGeneral.Resumen==false" style="color:#000000;">Resumido: No</strong>
                        </template>
                          </v-switch>

                        </v-col>
                        <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" >
                        <v-switch
                        v-if="$store.getters.GetdatosInfUsu.usuario.dsc"
                                      class="mt-0"
                                      color="teal"
                                      inset 
                                      
                                      label="DSC:"
                                      v-model="MayorGeneral.dsc"
                                    >
                                  <template v-slot:label>
                                <strong v-if="MayorGeneral.dsc==true" style="color:#000000;">DSC: Si</strong>
                                <strong v-if="MayorGeneral.dsc==false" style="color:#000000;">DSC: No</strong>
                                </template>        
                           </v-switch>
                          </v-col>

</v-row>
 
<v-col cols="12" md="12" sm="12">
<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="* Fecha final:"
        v-model="MayorGeneral.hasta"
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

</v-text-field>

</v-col>

                
</v-col>
<!-- fn columna 2 -->
         
              </v-row>

            </v-form>
       </v-container>

<hr>

<ModalReporteFiltros2
@DataReporteBasico="DDataReporteBasico"
:Datos="MayorGeneral"
/>


  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   
    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteFiltros2 from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,
  changeZone
} from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteFiltros2
    
  },
  created() { 
    var json = {  
      titulo: "Reporte Mayor General",
      descripcion: "Imprime Reporte Mayor General",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      accion: [ 
        "ReportesMayorGeneralNew",
        "ReportesMayorGeneralSave",
        "ReportesMayorGeneralRemove",
        "ReportesMayorGeneralAnular",
        "ReportesMayorGeneralPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesMayorGeneralNew", this.new);
    // EventBus.$on("ReportesMayorGeneralSave", this.save);
    // EventBus.$on("ReportesMayorGeneralRemove", this.remove);
    // EventBus.$on("ReportesMayorGeneralAnular", this.remove);
    // EventBus.$on("ReportesMayorGeneralPrintList", this.imprimirList);
    EventBus.$on("emitReportesMayorGeneral", this.datostabla);
   this.CargarRperiodo()
setTimeout(this.SelctCbPeriodo,600);

    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({


   
      Rperiodo:[],
      Rcuenta:[],
      RfilCuenta:"cuenta",
    
      windowSize:{width:"auto", height:"auto"},

      MayorGeneral: {
        nombre:'REPORTE MAYOR GENERAL',
        UrlImprimir: '/reportescont/ImprimirMayorGeneral', 
        UrlExel: '/reportescont/ExcelMayorGeneral', 
        UrlCorreo: '/reportescont/CorreoMayorGeneral', 
        Resumen:true,
        cuentaIni:null,
        cuentaFin:null,
        periodo:null,
        dsc:false,
        desde:null,
        hasta:null
        //fechaCreacion: new,
      //  fechaModificacion: "", 
      },

     EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },

      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
    
      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
    changeZone,
onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {

    },
    datostabla(e) {

      this.MayorGeneral = e;
      this.acti_tb_data=false
    },
    save() {},
    remove() {},
    eliminar() { },
    anular() { },
    imprimirList() {},
    DDataReporteBasico(){},



    newwnuevo() {
        EventBus.$emit("actualizaBarraBusqueda2");
    },



CargarRcuenta() {
   this.$axios.get(this.$hostname + this.$hName + '/catalogo/findall', {headers:this.$store.getters.GetheadersAxios})
   .then(res => {
        this.Rcuenta = res.data;
      });
    },


CbFilCuenta(e){

if(e.target.value.length==1){

if(e.target.value.charCodeAt(0)>=97 & e.target.value.charCodeAt(0)<=122 ){

//console.log("ok letra")

this.RfilCuenta="descripcion"


this.CargarRcuenta()

}else{this.RfilCuenta="cuenta"}



if(e.target.value.charCodeAt(0)>=48 & e.target.value.charCodeAt(0)<=57 ){
this.CargarRcuenta()
//console.log("ok numero")
this.RfilCuenta="cuenta"

}else{this.RfilCuenta="descripcion"}

  //numero 48 a 57
  //letras 97 a 122 

}


},




SelctCbReportcuentaInicio() {
if (this.MayorGeneral.cuentaIni == null) {
        this.MayorGeneral.cuentaIni = "";
      }

      if (this.MayorGeneral.cuentaIni.cuenta == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.MayorGeneral.cuentaIni = "";
        return;
      }
}, 

SelctCbReportcuentaFinal() {
if (this.MayorGeneral.cuentaFin == null) {
        this.MayorGeneral.cuentaFin = "";
      }

      if (this.MayorGeneral.cuentaFin.cuenta == undefined) {
        //this.alerta("Seleccione un tipo cuenta", "error");
        this.MayorGeneral.cuentaFin = "";
        return;
      }
}, 



CargarRperiodo() {

    this.$axios.get(this.$hostname + this.$hName + '/periodofiscal/findallactivoDetalle', {headers:this.$store.getters.GetheadersAxios})
   .then(res => {
if(res.data.length>0){
res.data.forEach(ele => {
var jsn={
cerrado: ele.cerrado,
fechafin: ele.nuevaFechaFin,
fechainicio: ele.nuevaFechaInicio,
// fechafin: new Date(ele.fechafin).toISOString().substr(0, 10),
// fechainicio: new Date(ele.fechainicio).toISOString().substr(0, 10),
id: ele.id,
numeroperiodo: 1,
desPeriodo: this.getDescripcionMeses(ele.nuevaFechaInicio)
}

this.Rperiodo.push(jsn)
});
}

      });

    },


getDescripcionMeses(e) {
        var letraMes ="";
     //   var fecha = new Date(e)
       // var mes = fecha.getMonth();
       var fecha = this.changeZone(e)
        var mes = fecha.getMonth();
        switch (mes) {
            case 0:
                letraMes = "Cierre Enero " + fecha.getFullYear();
                break;
            case 1:
                letraMes = "Cierre Febrero " + fecha.getFullYear();
                break;
            case 2:
                letraMes = "Cierre Marzo " + fecha.getFullYear();
                break;
            case 3:
                letraMes = "Cierre Abril " + fecha.getFullYear();
                break;
            case 4:
                letraMes = "Cierre Mayo " + fecha.getFullYear();
                break;
            case 5:
                letraMes = "Cierre Junio " + fecha.getFullYear();
                break;
            case 6:
                letraMes = "Cierre Julio " + fecha.getFullYear();
                break;
            case 7:
                letraMes = "Cierre Agosto " + fecha.getFullYear();
                break;
            case 8:
                letraMes = "Cierre Septiembre " + fecha.getFullYear();
                break;
            case 9:
                letraMes = "Cierre Octubre " + fecha.getFullYear();
                break;
            case 10:
                letraMes = "Cierre Noviembre " + fecha.getFullYear();
                break;
            case 11:
                letraMes = "Cierre Diciembre " + fecha.getFullYear();
                break;
            default:
                letraMes = "N/A";
        }

        return letraMes;
    },


SelctCbPeriodo() {

  var _this = this;
      setTimeout(function () {
        if (_this.MayorGeneral.periodo== null) {
          _this.MayorGeneral.periodo = _this.Rperiodo[0];
          _this.MayorGeneral.desde=_this.Rperiodo[0].fechainicio
          _this.MayorGeneral.hasta=_this.Rperiodo[0].fechafin
        }

        if (!_this.MayorGeneral.periodo.hasOwnProperty('id')) {
          _this.MayorGeneral.periodo = _this.Rperiodo[0];
          _this.MayorGeneral.desde=_this.Rperiodo[0].fechainicio
          _this.MayorGeneral.hasta=_this.Rperiodo[0].fechafin
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);

    },


    ChangePeriodo(e) {
  if (typeof e === 'object' && e != null) {
    this.MayorGeneral.desde = JSON.parse(JSON.stringify(e.fechainicio))
    this.MayorGeneral.hasta = JSON.parse(JSON.stringify(e.fechafin))
 
  }

},


    BlurDesde(){
if(this.MayorGeneral.desde.toString().length==0){
  // this.MayorGeneral.desde=this.fechaNueva()
  this.MayorGeneral.desde=this.MayorGeneral.periodo.fechainicio
}else{
  if(this.MayorGeneral.periodo.fechainicio<=this.MayorGeneral.desde && this.MayorGeneral.periodo.fechafin>=this.MayorGeneral.desde)
    {}else{
      this.MayorGeneral.desde=this.MayorGeneral.periodo.fechainicio
     this.alerta("La fecha seleccionada esta fuera de rango de la fecha del periodo", "error");

    }
}
 },


 BlurHasta(){
if(this.MayorGeneral.hasta.toString().length==0){
  this.MayorGeneral.hasta=this.MayorGeneral.periodo.fechafin
  }else{
    //"La fecha seleccionada esta fuera de rango de la fecha del periodo"

    if(this.MayorGeneral.periodo.fechainicio<=this.MayorGeneral.hasta && this.MayorGeneral.periodo.fechafin>=this.MayorGeneral.hasta)
    {}else{
      this.MayorGeneral.hasta=this.MayorGeneral.periodo.fechafin
     this.alerta("La fecha seleccionada esta fuera de rango de la fecha del periodo", "error");

    }

  }

 },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },

    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { }
  }
};
</script>

<style></style>
