<template>
  <div v-resize="onResize">

    <!------------otras funciones---------------->
    <v-snackbar
        v-model="aalert.estado"
        :color="aalert.color"
        style="margin-top: 20%;"
        :vertical="true"
        :timeout="120000"
        top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- ------------------------------------------------------ Modal-Correo-->
    <v-dialog transition="dialog-bottom-transition" label="Modal agregar Correo" v-model="dialogEmail" persistent
              max-width="80%" max-height="50%">

      <v-card color="grey lighten-3">
        <v-toolbar
            flat
            color="#BDBDBD"
            dense
            style="border: 1px solid #000000;"
        >

          <v-avatar tile size="45">
            <i style="font-size: 40px; color:#CDDC39;" class="fa fa-envelope-o"> </i>
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>Enviar Reporte</b> </span>

          <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
          <!-- style="color:white;" -->
          <!-- <i class=""> </i>     grey darken-4  -->

          <v-spacer></v-spacer>
          <v-btn
              color="red"
              fab
              small
              @click.prevent="CerrarCorreo"
          >
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="formReportCorreo" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0 py-0 my-0">

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

                <v-text-field
                    label="* Correo:"
                    autocomplete="off"
                    dense
                    outlined
                    :rules="[$rules.required]"
                    v-model="EnviarReporte.para"
                >
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                  </template>
                </v-text-field>

                <v-text-field
                          label="* Asunto:"
                          dense
                          outlined
                          :rules="[$rules.required]"
                          v-model="EnviarReporte.asunto"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>


                <v-textarea
                    autocomplete="off"
                    label="comentario:"
                    rows="5"
                    dense
                    outlined
                    v-model="EnviarReporte.mensaje"
                    :rules="[$rules.required]"

                >
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-commenting"></i>
                  </template>
                </v-textarea>


              </v-col>

            </v-row>
          </v-form>
        </v-container>

        <hr>

        <v-toolbar
            flat
            color="#BDBDBD"
            dense
            style="border: 1px solid #000000;"
            class="d-flex justify-center"
        >

          <v-btn-toggle dense rounded>
            <v-btn
                color="light-blue darken-2"
                @click.prevent="ReporteCorreo">
              <i style="font-size: 28px; color:#CDDC39;" class="fa fa-envelope-o"> </i>
              <span style="color:#FFFFFF;">Email </span>

            </v-btn>

          </v-btn-toggle>
        </v-toolbar>

      </v-card>


    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Correo -->

    <!-- ------------------------------------------------------  Modal Loader -->

    <v-dialog
        v-model="Reparaloading"

        persistent
        width="300"

    >
      <v-card
          color="grey darken-2"
          dark
      >
        <v-card-text>
          hohooho
          <br></br>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal loader -->


    <!----------------------------------------------------------------- Cuerpo Reporte -->
    <v-card color="grey lighten-3">

      <v-toolbar
          flat
          color="#BDBDBD"
          dense
          style="border: 1px solid #000000;"
      >


        <v-avatar tile size="45">
          <img
              src="@/assets/iconos_micromarket/custom-reports.png"
              alt="Reporte"
          >
        </v-avatar>
        <span class="font-weight-light" style="font-size: 20px;"> <b>Reporte </b> </span>

        <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
        <!-- style="color:white;" -->
        <!-- <i class=""> </i>     grey darken-4  -->

        <v-spacer></v-spacer>
        <v-btn
            color="red"
            fab
            small
            @click.prevent="CerrarReporte"
        >
          <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

      <hr>

      <v-container style="border: 1px solid #000000;">
        <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
          <v-row class="pa-8 ma-8  d-flex justify-center">
            <h1>{{ Datos.nombre }}</h1>
          </v-row>
        </v-form>
      </v-container>

      <hr>

      <v-toolbar
          flat
          color="#BDBDBD"
          dense
          style="border: 1px solid #000000;"
          class="d-flex justify-center"
      >

        <v-btn-toggle dense rounded>
          <v-btn
              color="light-blue darken-2"
              @click.prevent="dialogEmail=true"
          >
            <i style="font-size: 28px; color:#CDDC39;" class="fa fa-envelope-o"> </i>
            <span style="color:#FFFFFF;">Email </span>

          </v-btn
          >
          <v-btn
              color="light-blue accent-2"
              target="_blank"
              @click.prevent="ReporteImprimir"

          >
            <i style="font-size: 28px;" class="fa fa-print"> </i>

            <span style="color:#FFFFFF;">Printer </span>
          </v-btn
          >
          <v-btn color="green darken-3"
                 @click.prevent="ReporteExel"
          >
            <i style="font-size: 28px;" class="fa fa-file-excel-o"> </i>
            <span style="color:#FFFFFF;">Excel </span>
          </v-btn
          >
        </v-btn-toggle>
      </v-toolbar>

    </v-card>
    <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->


  </div>

</template>

<script>
import { EventBus } from "@/event-bus.js";
export default {
  mounted() {
    EventBus.$on("onResize", this.onResize);

  },

  created() {


  },

  data: () => ({

    Reparaloading: false,

    EnviarReporte: {
     // autor: "Jean22v@gmail.com",
      para: '',
      mensaje: '',
      asunto:''

    },


    dialogEmail: false,
    windowSize: {width: "auto", height: "auto"},
    valid: true,
    aalert: {
      estado: false,
      color: null
    },

  }),

  props: {

    Datos: {
      required: true,
      type: Object

    },

    ExelGenerado: {
      required: false,
      type: Boolean,
      default: false
    },


  },

  methods: {

    onResize(e) {
      var jsn = {width: window.innerWidth, height: window.innerHeight - 112}
      this.windowSize = jsn

// console.log("imprii ")
// console.log(e)
// this.windowSize=e


    },


    CerrarReporte() {
      this.$emit("DataReporteBasico", false)
    },

    ReporteImprimir() {
      //this.Reparaloading = true
      EventBus.$emit("loading", true);
      var obj = JSON.parse(JSON.stringify(this.Datos))
      obj.tenant = this.$store.getters.GetheadersAxios["X-TENANT-ID"]
     this.$axios
        .post(this.$hostname + this.$hName + this.Datos.UrlImprimir,obj,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{
          //console.log(res.data);
          var _this=this; 
         // this.Reparaloading=false
         EventBus.$emit("loading", false);
         setTimeout(function(){
        // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")

      /*let pdfWindow = window.open("Reporte de impresión")
       pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
       encodeURI(res.data) + "'></embed>") */ 
       var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
       EventBus.$emit("openPDF",PDF ,_this.Datos.nombre);
      }, 1000);
     // this.CrearObjecto=function(){};
      })
        .catch(error => {
          //this.Reparaloading=false
          EventBus.$emit("loading", false)
          , this.alerta("Reporte no Impreso("+error+")", "error")});

    },


    CerrarCorreo() {


      this.EnviarReporte = {
       // autor: "Jean22v@gmail.com",
        para: '',
        mensaje: '',
        asunto:''
      }

      this.dialogEmail = false

    },


    ReporteCorreo() {

      
      if(this.$refs.formReportCorreo.validate()) { 

     /* var obj = {
        tenant: this.$store.getters.GetheadersAxios["X-TENANT-ID"],
        secuencia: this.Datos.secuencia,
        documento: this.Datos.documento,
        EnviarReporte: this.EnviarReporte
      }*/
var obj=this.Datos
obj.para=this.EnviarReporte.para
obj.mensaje=this.EnviarReporte.mensaje
obj.asunto=this.EnviarReporte.asunto
obj.tenant = this.$store.getters.GetheadersAxios["X-TENANT-ID"]
EventBus.$emit("loading", true);
      // this.Reparaloading = true
      this.$axios
          .post(this.$hostname + this.$hName + this.Datos.UrlCorreo, obj, {headers: this.$store.getters.GetheadersAxios})
          .then(res => {
          //  this.Reparaloading = false
            EventBus.$emit("loading", false);
            this.alerta("Correo enviado correctamente!","bien")
            this.CerrarCorreo()
          })
          .catch(error => {
           // this.Reparaloading = false
           EventBus.$emit("loading", false);
            // this.alerta("Reporte no Impreso(" + error + ")", "error")
          });
        }else{
          this.alerta("los campos en rojo son requeridos", "error")
        }

    },

    ReporteExel() {
      // this.Reparaloading = true
      EventBus.$emit("loading", true);
      var obj = JSON.parse(JSON.stringify(this.Datos))
      obj.tenant = this.$store.getters.GetheadersAxios["X-TENANT-ID"]


// var obj={
// tenant:this.$store.getters.GetheadersAxios["X-TENANT-ID"],
// secuencia:this.Datos.secuencia,
// documento: this.Datos.documento,
// }


      this.$axios
          .post(this.$hostname + this.$hName + this.Datos.UrlExel, obj, {headers: this.$store.getters.GetheadersAxios})
          .then(res => {
            var _this = this;
            EventBus.$emit("loading", false);
            // this.Reparaloading = false
            // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
            setTimeout(function () {
              _this.verExel(res.data)
            }, 2000);

          })
          .catch(error => {
            EventBus.$emit("loading", false);
            // this.Reparaloading = false
            //this.alerta("Reporte no Impreso(" + error + ")", "error")
          });


    },

    verExel(e) {

      console.log(e)

      // if (this.ExelGenerado == true) {var contentType = 'application/vnd-xls';
      var contentType = 'application/vnd.ms-excel';
      // }

      if (this.ExelGenerado == false) {
        var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }

      var blob1 = this.b64toBlob(e, contentType);
      var blobUrl1 = URL.createObjectURL(blob1);
      let anchor = document.createElement('a');
      anchor.href = blobUrl1;
      anchor.download = this.Datos.nombre;
      anchor.click();
      URL.revokeObjectURL(blobUrl1);

    },

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        // this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        // this.ejecucion = true;
      }
    },

  },


}
</script>

<style>

</style>