<template @click="buscando">
  <aside v-show="isVisible" class="barra-busqueda">

    <v-card>

      
      <v-card-title>
        <h5>{{ nombre }}</h5>

        <v-spacer></v-spacer>

       
<v-tooltip right>
      <template v-slot:activator="{ on }">
       <v-text-field
       autocomplete="off"
           v-on="on"
          v-model="search"
          @keyup="filtro"
          :label="SeachNombre"
          single-line
          hide-details
        >
        <template v-slot:prepend-inner>
                          <i style="font-size: 30px;" class="fa fa-search"></i>
                        </template>
        
        
        </v-text-field>
      
      </template>
      <span>{{SeachNombre}}</span>
    </v-tooltip>




      </v-card-title>

      <v-data-table
        @click:row="obtenerDato"
        :items-per-page="5"
        :headers="headers"
        :items="list"
        :search="search"
      >
        <template v-slot:[slotname]="props">
          <div style="border-top:2px solid gray;">
            <v-list-item-title v-for="(sl, i) in slotlist" :key="i">

<p v-if="sl.dt3==0.1">
    <b class="red--text" v-if="props.item[sl.dt0]==false">{{sl.dt4}}</b>
</p>

                <div v-if="sl.dt4=='por' & props.item[sl.dt0]==1">
                  <b>Producto: </b>{{ props.item.producto.descripcion}}
                </div>

                <div v-if="sl.dt4=='por' & props.item[sl.dt0]==2">
                  <b>Departamento: </b>{{ props.item.departamento.descripcion}}
                </div>

              <div v-if="sl.dt3 == 1">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }} </b>{{ props.item[sl.dt0] }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }} </b>{{ props.item[sl.dt0][sl.dt1] }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }} </b>{{ props.item[sl.dt0][sl.dt1][sl.dt2] }}
                </div>
              </div>

              <div v-if="sl.dt3 == 2">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }} </b>{{ fecFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ fecFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }} </b
                  >{{ fecFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 3">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }} </b>{{ TelFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ TelFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }} </b
                  >{{ TelFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 4">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }} </b>{{ cedFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ cedFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }} </b
                  >{{ cedFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>

              <div v-if="sl.dt3 == 5">
                <div v-if="(sl.dt2 == null) & (sl.dt1 == null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ currencyFormatter(props.item[sl.dt0]) }}
                </div>

                <div v-if="(sl.dt2 == null) & (sl.dt1 != null)">
                  <b>{{ sl.dt4 }} </b
                  >{{ currencyFormatter(props.item[sl.dt0][sl.dt1]) }}
                </div>

                <div v-if="sl.dt2 != null">
                  <b>{{ sl.dt4 }} </b
                  >{{ currencyFormatter(props.item[sl.dt0][sl.dt1][sl.dt2]) }}
                </div>
              </div>
            </v-list-item-title>
          </div>
          <div></div>
        </template>
      </v-data-table>
    </v-card>
  </aside>
</template>

<script>
// import axios from "axios";
import cuerpo from '../../src/components/cuerpo'
import { EventBus } from "@/event-bus.js";
export default {
  components:{
  cuerpo
  },
  data() {
    return {
       slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Tarjeta", value: "nombre" },
        { text: "Porciento", value: "porciento" }
      ],


      isVisible: true,
      list: [],
      search: "",

      
    };
  },
  props: {
    headers: {
      required: true,
      type: Array
    },

    slotname: {
      required: false,
      type: String
    },

    url: {
      required: true,
      type: String
    },
    nombre: {
      required: true,
      type: String
    },

    SeachNombre: {
      required: true,
      type: String
    },

    slotlist: {
      required: false,
      type: Array
    },

    emitnombre: {
      required: true,
      type: String
    }
  },

  created() {
    this.$axios.post(this.url, {
      usuario:JSON.parse(JSON.stringify(this.$user)) 
    }).then(res => (this.list = res.data));

    //this.list.push(this.slotlist)
  },
  mounted: function() {
    EventBus.$on("actualizaBarraBusqueda2", this.filtro);
  },
  methods: {

ver(e){
console.log(e)
},


    currencyFormatter(params) {
      //return this.formatNumber(params) + ".00";
      return this.formatNumber(params);
    },

    formatNumber(number) {
      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");


return number.toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

    },

    fecFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },

    TelFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            "(" +
            params.substring(0, 3) +
            ")" +
            params.substring(3, 6) +
            "-" +
            params.substring(6, 10)
          );
        }
        return params;
      }
    },

    cedFormatter(params) {
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(0, 3) +
            "-" +
            params.substring(3, 7) +
            "-" +
            params.substring(7, 11)
          );
        }
      }
    },

    async filtro() {
      await this.$axios.post(this.url, {
        
        usuario:JSON.parse(JSON.stringify(this.$user))

      }).then(res => {
        this.list = res.data;
      });
    },

    obtenerDato(e) {
      EventBus.$emit(this.emitnombre, e);
      //this.Rdata=e
      //this.RdataDetalle=e[this.slotlist]

  
    }



  }
};
</script>
<style></style>
