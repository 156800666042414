import { Bar } from '../../BaseCharts'

export default {
  extends: Bar,
  mounted() {
    this.UpdateRenderChart()
  },

  data:()=>({
    chartData:{
      labels: [],
      datasets: [
        {
          label: '',
          backgroundColor: '',
          data: []
        }
      ]
    },
   

    // chartData:{
    //   labels: [
    //     'January',
    //     'February',
    //     'March',
    //     'April',
    //     'May',
    //     'June',
    //     'July',
    //     'August',
    //     'September',
    //     'October',
    //     'November',
    //     'December'
    //   ],
    //   datasets: [
    //     {
    //       label: 'Data One',
    //       backgroundColor: '#f87979',
    //       data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
    //     }
    //   ]
    // }




  }),

   methods:{

    UpdateRenderChart(){
      
      this.renderChart( this.chartData
        ,
        { responsive: true, maintainAspectRatio: false }
      )

    }

   }

}
