<template>
<div v-if="dialogClienteBalancePunto==true" v-dirkeydown="ConsultaBalanceKeyESC" style="min-height:500px;" class="overflow-x-auto">
  
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
  <!-- v-toolbar accion  -->
<v-toolbar dark color="#dd4b39" dense>

<v-tooltip bottom>
  <template v-slot:activator="{ on }">

    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> CONSULTA BALANCES PUNTOS
    </v-toolbar-title>

  </template>
  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>CONSULTA BALANCES PUNTOS: </span> CONSULTA BALANCES PUNTOS</i>

</v-tooltip>

<v-spacer></v-spacer>

<v-card-actions>

  <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewClienteBalancePunto()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>
  <v-tooltip bottom>

    <template v-slot:activator="{ on }">
      <v-btn id="boton-mini" v-on="on" @click="CancelarClienteBalancePunto()" color="orange"
        small dark>
        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
        CANCELAR
      </v-btn>
    </template>
    <span>CANCELAR: Cancela y retornar atrás</span>
  </v-tooltip>

</v-card-actions>
</v-toolbar>
<!-- fn v-toolbar accion  -->
<v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row :class="`d-flex justify-center`">

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field ref="RefTextFieldCodigoBarra" background-color="white" color="grey darken-4"
                    autocomplete="off" v-on="on" v-model="searchCodigoBarra" @keyup="SeachFilCodigoBarra"
                      label="Buscar Código barra cliente Preferencial" single-line outlined dense>
                    <template v-slot:prepend-inner>
                      <i style="font-size: 25px;" class="fa fa-search"></i>
                    </template>
                  </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se busca el codigo de barra del cliente preferencial.</span>
                    </i>
                  </v-tooltip>
                </v-col>

              </v-row>
            </v-form>
          </v-card>

          <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0"  >
            <!-- :class="`d-flex justify-center white--text`" -->
             <center>
              <br>
            <div :class="`black--text`" >
              <h1 v-if="(``+CLienteBalancePunto.codigoClinete).length>0">Código: {{CLienteBalancePunto.codigoClinete}}</h1>
              <h1><b class="blue--text">{{CLienteBalancePunto.cliente}} </b></h1>
              <h1>{{CLienteBalancePunto.balancePunto}}</h1>
            </div>
          </center>
            </v-col>


</v-col>

<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>

</v-col>
</div> 
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  directives: {
    dirkeydown: {
      // Definición de directiva
      bind(el, binding) {
        // __HandleScrollHandler__
        el.__HandledKey__ = event => binding.value(event)
        document.body.addEventListener('keydown', el.__HandledKey__)
      },
      unbind(el) {
        document.body.removeEventListener('keydown', el.__HandledKey__)
      }

    }
  },

  components: {
    VueAutonumeric
  },
  created() { 

  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    dialogClienteBalancePunto: false,

    searchCodigoBarra:'',
    CLienteBalancePunto: {
      codigoClinete: '',
      cliente: '',
      balancePunto: null,
    },


    
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
 },

  }),

  methods: { 
    currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant,fechaNueva,
  
// Hacer una llamada API al servidor y devolver los datos de la cotizacion.
SeachFilCodigoBarra(e) {

  var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true){
        var BANDERA_CODBAR_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.banderaCodigoBarraPreferencial
        //key: "Enter"keyCode: 13
        if(e.keyCode == 13){
          if(e.target.value.substr(0,2)===BANDERA_CODBAR_CLI_PRE){
            this.SeachTextFilBarraPreferencial(e.target.value)
            return
          }
        }
      }

},

 // Busqueda de codigo_barra_preferencial del cliente .
 SeachTextFilBarraPreferencial(e){
  if(e.trim().length>0){
this.$axios
        .get(this.$hostname + this.$hName + "/clientes/getclientebarrapreferencial/"+e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.hasOwnProperty("codigo")){
            this.CLienteBalancePunto.codigoClinete=res.data.codigo
            this.CLienteBalancePunto.cliente=res.data.nombre
            this.CLienteBalancePunto.balancePunto=`PUNTOS ACUMULADO: ${currencyFormatter(res.data.balancePuntos)}`
            // this.clienteFactura.codnom= `${res.data.codigo} - ${res.data.nombre.trim()} `
            this.alerta("Cliente Seleccionado", "bien2");

            var _this = this;
          setTimeout(function () {
            _this.NewClienteBalancePunto()
            _this.searchCodigoBarra=""
            _this.focusRefTextFieldCodigoBarra()
  
               }, 10000);



          }else{
            this.alerta("No existe cliente preferencial con ese código", "error");
          }
          
        })
        .catch(error => { this.alerta("dato no encontrado", "error") });


  }else{

  }
         

},


// Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
NewClienteBalancePunto() {
  this.searchCodigoBarra=""
  this.CLienteBalancePunto= {
      codigoClinete: '',
      cliente: '',
      balancePunto: null,
    }

    },
    

    CancelarClienteBalancePunto() {
      this.dialogClienteBalancePunto = false;
      this.NewClienteBalancePunto();
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarClienteBalancePunto", this.dialogClienteBalancePunto)
     }, 300);
    },


focusRefTextFieldCodigoBarra(){
 this.dialogClienteBalancePunto=true
  var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldCodigoBarra.focus();
        }, 500);

},

ConsultaBalanceKeyESC(e){
      if (e.keyCode ==27 ) {
           this.CancelarClienteBalancePunto()
        }
  
  
      },

alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        // this.limpiarPuntoVentaCompleto();
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },  

    loading(e) {
      EventBus.$emit("loading", e);
    },


    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>