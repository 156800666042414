<template>
<v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ GetEjecucion }}
  <!-- toolbar accion -->
  <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Retención
                      Factura </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Retención Factura : </span>
                    Retención Factura </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="cancelarRetencionFactura()" color="orange" small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar la retención a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="NewNewRetencionFactura()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>

                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="SalvarRetencionFactura()" color="light-blue darken-4"
                          small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar la retención y retornar atrás</span>
                    </v-tooltip>


                  </v-btn-toggle>

                </v-card-actions>

  </v-toolbar>
   <!-- fn toolbar accion -->
   <v-col cols="12" md="12" sm="12">
                <VueAutonumeric label="Retención" dense outlined ref="RefTextFieldRetencionFactura"
                  @keydown.enter="SalvarRetencionFactura()" autocomplete="off" :rules="[$rules.Numericc]"
                  v-model.number="Retencionfactura.Retencion" style="font-size:13px;">
                  <template v-slot:prepend>
                    <i v-if="Retencionfactura.ValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                    <i v-if="Retencionfactura.ValorPorc == false" style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>

                  <template v-slot:append-outer>

                    <v-radio-group dense @change="" style="margin-top: -18%;" v-model="Retencionfactura.ValorPorc" column>
                      <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                      <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                    </v-radio-group>
                  </template>
                </VueAutonumeric>

   </v-col>

</v-col>
</template>
<script>
import { EventBus } from "../../event-bus";
import { currencyFormatter, FormatoFecha, HoraFormatter,RedondeoValorCantRed,currencyFormatterCant } from "@/js/Funciones.js";
import VueAutonumeric from "../../components/AutoNumericNuevo";
export default {
  components: {
    VueAutonumeric
  },
  created() { 
            console.log('created--PVCONFIGU')
  },
  mounted() {
 

  },
  beforeDestroy() {

  },

  data: () => ({ 
    windowSize: { width: "auto", height: "auto" },
    valid: true,
    objTemp:null,
    Retencionfactura: {
      ValorPorc: true,
      Retencion: 0,
    },
    

  }),

  methods: { 

    getRetencionFactura() {
      this.objTemp = JSON.parse(JSON.stringify(this.Retencionfactura));
      var _this = this;
      setTimeout(function () {
        _this.$refs.RefTextFieldRetencionFactura.focus();
      }, 500);
    },

    // Creando un nuevo objeto llamado Retencionfactura y asignándolo a la propiedad de datos
    NewNewRetencionFactura() {
      this.Retencionfactura = {
        ValorPorc: true,
        Retencion: 0,
      };
    },
    cancelarRetencionFactura(){
      this.Retencionfactura = JSON.parse(JSON.stringify(this.objTemp));
      var _this=this;
    setTimeout(function(){ 
      _this.$emit("DataRetornCancelarRetencionGub", false)
     }, 300);
    },

// Activa las variables necesarios para la retenciones de los productos
SalvarRetencionFactura() {
      this.objTemp = null;
      // this.CalcularTotalesProductosCompra();
      var _this=this;
      setTimeout(function(){ 
      _this.$emit("DataRetornRetencionesGub", _this.Retencionfactura)
      _this.$emit("DataRetornCancelarRetencionGub", false)
     }, 300);
    },

    
  },
  computed: { 
    GetEjecucion() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
     },

},
};
</script>
<style lang="scss">
</style>