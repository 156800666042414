<template>
 <div> {{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px">   

<ContenidoBase 
  :titulo="'Historial Proveedores (CXP)'"
  :descripcion="'Historial Proveedores (CXP)'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"  
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <v-btn-toggle>
                <v-btn
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                <v-btn
                  small
                  dark
                  color="deep-purple accent-2"
                  @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn
                >
              </v-btn-toggle>
            </center>
            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitHistorialProveedor'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">

  <div class="px-2" >
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>

<v-col  cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-2" > 

<v-row class="pa-0 ma-0 py-0 my-0" >
<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2">
<!-- ----------------------------- proveedor -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="proveedor:"
            :items="Rproveedor"
            item-text="nombre" 
            style="font-size: 13px;" 
            v-model="HistorialProveedor.proveedor"
            @blur="SelctCbProveedor"
            @focus="cargarProveedor"
            @keyup="CbFilProveedor"
            @change="BuscarHistorialProveedor"
            hide-details>

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                      </template>
                    </v-combobox>
</v-col>


<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 px-2">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="Balance:" dense outlined
 autocomplete="off"
  v-model.number="infProv.balance"
  hide-details
  disabled
  id="formdisabledInput"

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>

</v-col>

<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 px-2" >
<!-- ----------------------------- Límite-->
<VueAutonumeric label="Límite" dense outlined
 autocomplete="off"
  v-model.number="infProv.limite"
  hide-details
  disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
</v-col>

<v-col cols="12" md="2" sm="2" class="pa-0 ma-0 px-2" >
<!--------------------------------- Plazo -->    

<VueAutonumeric label="Plazo:" dense outlined
 autocomplete="off"
  v-model.number="infProv.plazo"
  hide-details
  disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-clock-o"></i>
    </template>
  </VueAutonumeric>


</v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 py-2">
 <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="HistorialProveedor.desde"
        @input="BuscarHistorialProveedor"
        hide-details
        @blur="BlurDesde"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>

<v-col cols="12" md="6" sm="6" class="pa-0 ma-0 px-2 py-2" >
 <!-- -----------------------------  Hasta  --> 
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="HistorialProveedor.hasta"
        @input="BuscarHistorialProveedor"
        hide-details
        @blur="BlurHasta"
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>


</v-row> 
</v-col>
 <v-row>


<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2">
 <div style="border: 1px solid #000000;" >
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'Fecha', sortable: false,  value: 'fecha', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'No.Documento', sortable: false, value: 'noDoc', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Descripción', sortable: false, value: 'descripcion', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Débito(Pagos)', sortable: false, value: 'debito', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Crédito(Factura)', sortable: false, value: 'credito', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Balance', sortable: false, value: 'balance', width:'auto', class: ['no-gutters black--text']  }
                 ]"
              dense
              :items="listHisProveedor"
              :search="search"      
              :items-per-page="15"
            >


<template v-slot:item.fecha="props">
    <span>{{FormatoFecha(props.item.fecha) }}</span>
</template>

<template v-slot:item.debito="props">
    <span>{{currencyFormatter(props.item.debito )}}</span>
</template>

<template v-slot:item.credito="props">
    <span>{{currencyFormatter(props.item.credito )}}</span>
</template>

<template v-slot:item.balance="props">
    <span  v-if="props.item.balance>=0" >{{currencyFormatter(props.item.balance )}}</span>
    <span v-if="props.item.balance<0"> ( {{currencyFormatter( (-1*props.item.balance) )}} )</span>
</template>

<template v-slot:body.append v-if="windowSize.width >600">
<tr>  
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.debito)}} </strong></td>
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(totales.credito)}} </strong></td>
<td class="border-total">
  <strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> 
   <span v-if="totales.BalanceTotal>=0" > {{currencyFormatter(totales.BalanceTotal)}} </span>
   <span v-if="totales.BalanceTotal<0" > ( {{currencyFormatter( (-1*totales.BalanceTotal)  )}} )</span>
</strong>
</td>

  </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Débito: {{currencyFormatter(totales.debito)}}</span>
        <span>Total Crédito: {{currencyFormatter(totales.credito)}}</span>
        <span>Total Balance: {{currencyFormatter(totales.BalanceTotal)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>
</v-data-table>
 </div>
</v-col>

<v-col cols="12" md="12" sm="12" >
<hr>
</v-col>


<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-2">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="1 - 30 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorVencido.a30dia"
  hide-details
   disabled
  id="formdisabledInput"
  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-usd"></i>
    </template>
  </VueAutonumeric>
  </v-col>

<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-2">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="31 - 60 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorVencido.a60dia"
  hide-details
   disabled
  id="formdisabledInput"

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

  </v-col>


<v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-2">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="31 - 90 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorVencido.a90dia"
  hide-details
   disabled
  id="formdisabledInput"

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

  </v-col>

  <v-col cols="12" md="3" sm="3" class="pa-0 ma-0 py-0 my-0 px-2">
<!-- ----------------------------- Balance -->
<VueAutonumeric label="Mas de 90 Días:" dense outlined
 autocomplete="off"
  v-model.number="ValorVencido.amasdia"
  hide-details
   disabled
  id="formdisabledInput"

  >
    <template v-slot:prepend>
      <i style="font-size: 20px;" class="fa fa-sun-o"></i>
    </template>
  </VueAutonumeric>

  </v-col>

<v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 px-2">
<span><b>Devoluciones Pendientes de Notas de Crédito</b></span>

<div style="border: 1px solid #000000;" >



  
<v-data-table
      class="elevation-1 RowSelectColor TbSize13"
      :headers="[
                { text: 'Fecha', sortable: false,  value: 'fecha', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'Proveedor', sortable: false, value: 'proveedor.nombre', width:'auto', class: ['no-gutters black--text']  },
                 { text: 'No.devolución', sortable: false, value: 'devolucionproveedorPK.secuencia', width:'auto', class: ['no-gutters black--text']  },
                 { text: '$ Valor devolucón', sortable: false, value: 'valor', width:'auto', class: ['no-gutters black--text']  },
                 ]"
              dense
              :items="listDevolucion"     
              :items-per-page="15"
              hide-default-footer

            >

<template v-slot:item.fecha="props">
    <span>{{FormatoFecha(props.item.fecha) }}</span>
</template>

<template v-slot:item.valor="props">
    <span>{{currencyFormatter(props.item.valor )}}</span>
</template>



<template v-slot:body.append v-if="windowSize.width >600">
<tr>  

<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong></strong></td>
<td class="border-top-bottom"  ><strong class="d-flex justify-end" style="font-size:15px; color: #00838F;">Total:</strong></td>                                                                               
<td class="border-total" ><strong class="d-flex justify-start" style="font-size:15px; color: #00838F;"> {{currencyFormatter(TotalDevolucion)}} </strong></td>

  </tr>
 </template>

 <template v-slot:footer v-if="windowSize.width<=600">
<v-list-item style="font-size: 70%;" class="white--text" > 
      <v-list-item-content>
        <span>Total Valor: {{currencyFormatter(TotalDevolucion)}}</span>
      </v-list-item-content>
</v-list-item>

  </template>


</v-data-table>

 </div>

</v-col>


              </v-row>

            </v-form>
       </div>

  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="slotHtabla"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


<!-- ------------------------------------------------------ Modal- nueva Factura-->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="ModHistorialVenta" persistent max-width="90%" max-height="80%">
 <v-card>

 <v-toolbar
 dense
        flat
        color="indigo"
      >
        
    <i style="font-size: 20px;" class=""> </i>  
        <v-toolbar-title  style="color:white;"  class="font-weight-light"> Historial de Ventas </v-toolbar-title>
    <i style="font-size: 20px;" class=""> </i>

        <v-spacer></v-spacer>
        <v-btn
          color="grey darken-4"
          fab
          small
          @click="ModHistorialVenta=!ModHistorialVenta"
        >
        <!-- cerrarModalHis() -->
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn>
      </v-toolbar>

<v-card-text>
  <br>
<v-form
    ref="form3"
    v-model="valid"
    lazy-validation
  >
<v-row>
 

<!-- columna 1 -->
<v-col cols="12" md="6" sm="6">
 <!-- -----------------------------  desde  -->
<v-text-field
        dense
        outlined
        type="date"  
        label="Desde:"
         :rules="[$rules.required]"
        v-model="HistorialProveedor.desde"
        hide-details
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>
<!-- fn columna 1 -->


<!-- columna 2 -->
<v-col cols="12" md="6" sm="6">
<!-- -----------------------------  Hasta  -->
<v-text-field
        dense
        outlined
        type="date" 
         :rules="[$rules.required]" 
        label="Hasta:"
        v-model="HistorialProveedor.hasta"
        hide-details
        >
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

                </v-text-field>
</v-col>


<v-col cols="12" md="12" sm="12">
 <div style="border: 1px solid #000000;" >
<v-data-table

      :headers="[
                { text: 'Fecha', sortable: false,  value: 'codigofijo', width:'auto' },
                 { text: '# Factura', sortable: false, value: 'secuenciainicial', width:'auto' },
                 { text: 'Cantidad', sortable: false, value: 'secuenciaactual', width:'auto' },
                 { text: 'Producto', sortable: false, value: 'secuenciafinal', width:'auto' },
                 { text: 'Precio', sortable: false, value: 'activada', width:'auto', width:'auto' },
                 { text: 'Valor', sortable: false, value: 'action', width:'auto' },
                 { text: 'Descuento', sortable: false, value: 'action', width:'auto' }
                 ]"
              dense
              :items="listHisProveedor"
              :search="search"      
              :items-per-page="15"
            >
</v-data-table>

 </div>

</v-col>







</v-row>
</v-form>
</v-card-text>

<hr/> 
 
 
</v-card>
</v-dialog>




    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog
      label="myAlert"
      v-model="Elim_notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            @click.prevent="
              eliminar(), (Elim_notif.estado = !Elim_notif.estado)
            "
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../event-bus";
import BarraBusqueda2 from "../components/BarraBusquedaDinam";
import ContenidoBase from "../components/ContenidoBase";
import VueAutonumeric from "../components/AutoNumericNuevo";
import {currencyFormatter,FormatoFecha,HoraFormatter} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    VueAutonumeric

  },

  created() {
    var json = {
      titulo: "Historial Proveedores (CXP)",
      descripcion: "Historial Proveedores (CXP)",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi:false,
      
      accion: [
        "HistorialProveedorNew",
        "HistorialProveedorSave",
        "HistorialProveedorRemove",
        "HistorialProveedorAnular",
        "HistorialProveedorPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("HistorialProveedorNew", this.new);
    // EventBus.$on("HistorialProveedorSave", this.save);
    // EventBus.$on("HistorialProveedorRemove", this.remove);
    // EventBus.$on("HistorialProveedorAnular", this.remove);
    // EventBus.$on("HistorialProveedorPrintList", this.imprimirList);
    EventBus.$on("emitHistorialProveedor", this.datostabla);
    EventBus.$on("onResize", this.onResize);
  },

  data:()=>  ({



      ModHistorialVenta:false,
      Rproveedor:[],
      clientSuff:'',
      listHisProveedor:[],
      listDevolucion:[],
      
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      HistorialProveedor: {
        desde: new Date().toISOString().substr(0,10),
        hasta:new Date().toISOString().substr(0,10),
        proveedor:null
        //fechaCreacion: new,
       //  fechaModificacion: "", 
      },

infProv:{
balance:0,
limite:0,
plazo:0
},

ValorVencido:{
  a30dia:0,
  a60dia:0,
  a90dia:0,
  amasdia:0,
},
TotalDevolucion:0,
totales:{
BalanceTotal:0,
credito:0,
debito:0,
},


EstadoBtn:{ 
        BarraTablaDinamicaAprir:false,
        nuevo:false,
        guardar:false,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },



     
      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea anular este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
    
    }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
currencyFormatter,FormatoFecha,HoraFormatter,
onResize(e){
this.windowSize=e

},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
  
    datostabla(e) {

      this.HistorialProveedor = e;
      this.acti_tb_data=false
    },
    save() {},
    remove() {},
    eliminar() { },
    anular() {},
    imprimirList() {},
    newwnuevo() {
      (this.HistorialProveedor = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },


cargarProveedor(){

this.$axios.get(this.$hostname+this.$hName+'/proveedores/findallbyDesc/25/0/*',{headers:this.$store.getters.GetheadersAxios})
.then(res=>( this.Rproveedor=res.data))

},

CbFilProveedor(e) {
     
if (e.target.value.length >= 0) {
          this.$axios
            .get(
              this.$hostname +
                this.$hName +
                "/proveedores/findallbyDesc/25/0/"+(e.target.value===""?"*":e.target.value),
              {headers:this.$store.getters.GetheadersAxios}
            )
            .then(res => (this.Rproveedor = res.data));
        }
      
      
    },

SelctCbProveedor(){
  if(this.HistorialProveedor.proveedor==null){

    this.HistorialProveedor.proveedor=''
  }

if(this.HistorialProveedor.proveedor.codigo== undefined){

this.alerta("Seleccione un proveedor", "error");
this.HistorialProveedor.proveedor=''

//qlq2

return

}

},


BuscarHistorialProveedor(){


  if (this.HistorialProveedor.proveedor == null){
      return 0;
   }



//  var cli = "null"

//      if (this.HistorialProveedor.proveedor != null){
//             cli = this.HistorialCliente.proveedor.codigo
//         }
    



 var f1=new Date(this.HistorialProveedor.desde).toISOString().substr(0,10)
 var f2=new Date(this.HistorialProveedor.hasta).toISOString().substr(0,10)

// var Obj={}

var link = `/ConsultaRapida/HistorialProveedores/${f1}/${f2}/${this.HistorialProveedor.proveedor.codigo}`
this.$axios
        .get(this.$hostname + this.$hName +link,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{ 
          this.CalcularHistorial(res.data)
        })
        .catch(error => {this.alerta("Reporte no Impreso("+error+")", "error")});


},


async CalcularHistorial(e){

this.TotalDevolucion=0
this.listDevolucion=[]

e.listDevolucion.forEach(elent => {
  this.TotalDevolucion+=elent.valor
  
});

this.listDevolucion=e.listDevolucion


this.totales={
BalanceTotal:0,
credito:0,
debito:0,
}


this.ValorVencido.a30dia= e.vencidosValor[0]
this.ValorVencido.a60dia= e.vencidosValor[1]
this.ValorVencido.a90dia= e.vencidosValor[2]
this.ValorVencido.amasdia= e.vencidosValor[3]


var listado= []

var obj= JSON.parse(JSON.stringify(e.list))

await obj.forEach(elent => {
this.totales.BalanceTotal+=(elent[3]-elent[4])
var obj={
  fecha:elent[0],
  noDoc:elent[1],
  descripcion:elent[2],
  debito:elent[3],
  credito:elent[4],
  balance:this.totales.BalanceTotal
}
this.totales.debito+=elent[3]
this.totales.credito+=elent[4]
listado.push(obj)
});

this.listHisProveedor=listado

},



BlurDesde(){
if(this.HistorialProveedor.desde.toString().length==0){
  this.HistorialProveedor.desde=new Date().toISOString().substr(0,10)
}

 },


 BlurHasta(){

if(this.HistorialProveedor.hasta.toString().length==0){
  this.HistorialProveedor.hasta=new Date().toISOString().substr(0,10)
}

 },





    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .post(this.$hostname + this.$hName + "/services/tipocliente/findalltipoclientes", {
          usuario:JSON.parse(JSON.stringify(this.$user)) 
        })
        .then(res => {
          this.list = res.data;
        });
    },




  },

computed:{

  ActDescBtn(){

if( this.HistorialProveedor.proveedor!=null && this.HistorialProveedor.proveedor.hasOwnProperty('codigo')){
 // this.infCliente=JSON.parse(JSON.stringify(this.HistorialCliente.cliente))


if(this.HistorialProveedor.proveedor.balanceproveedor!=null){
  this.infProv.balance=JSON.parse(JSON.stringify(this.HistorialProveedor.proveedor.balanceproveedor.balance)) 
    }else{this.infProv.balance=0}

if(this.HistorialProveedor.proveedor.limiteCredito!=null){
  this.infProv.limite= JSON.parse(JSON.stringify(this.HistorialProveedor.proveedor.limiteCredito)) 
    }else{this.infProv.limite=0}
    
    if(this.HistorialProveedor.proveedor.diaCredito!=null){
      this.infProv.plazo=JSON.parse(JSON.stringify(this.HistorialProveedor.proveedor.diaCredito)) 
    }else{this.infProv.plazo=0}

}else{

this.infProv={
balance:0,
limite:0,
plazo:0
}

}

this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

  }

}



};
</script>

<style></style>
