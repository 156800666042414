<template>
 <div>
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Caja Chica'"
  :descripcion="'Creación de cajas chicas'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"    
/>
    </tr>
 
 
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  width="95%"
                  small
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
            </center>
            <BarraDinamicaNormal
            ref="BarraDiNor"  
              :url="
                $hostname + $hName + '/cajachica/findall'
              "
              :nombre="'Caja Chica'"
              :headers="[ { text: 'Codigo', value: 'codigo' },
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'Custodio', value: 'custodio.nombre'},
        { text: 'Fondo', value: 'valorFondo' },
        { text: 'Balance', value: 'fondoActual' }
        ]"
              :emitnombre="'emitCajaCajaChica'"
              :SeachNombre="'Búsqueda por descripcion '"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Caja Chica', NomValue:'descripcion', Ctsl:1, Type:0, State:true},
              {Text:'Custodio', NomValue:'custodio', Nv2:'nombre' ,Ctsl:2, Type:0, State:true},
              {Text:'Fondo', NomValue:'valorFondo', Ctsl:1, Type:2, State:true},
              {Text:'Balance', NomValue:'fondoActual', Ctsl:1, Type:2, State:true},
              {Text:'Estado',NomValue:'activado', Ctsl:1, Type:0.1, State:true},
              ]"
            />
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <div class="px-2" >
  <v-card color="grey lighten-3">
  <div class="px-2"> 
  <v-form ref="form" @submit.prevent=""  v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    v-model="CajaChica.descripcion"
                    :rules="[$rules.required]"
                    autocomplete="off"
                    label="Descripción:"
                    required
                    outlined
                    dense
                  >
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-font"></i>
                    </template>
                  </v-text-field>

                  <!-------------------------------------------------------- Cuenta Bancaria -->
<v-combobox
                    v-model="CajaChica.custodio"
                    label="Custodio:"
                    required
                    outlined
                    dense
                    :rules="[$rules.required]"
                    autocomplete="off"
                    :items="RCustodio"
                    item-text="nombre"
                    @focus="cargaCustodio"
                  >  
                          <template v-slot:prepend>
                            <i
                              style="font-size: 20px;"
                              class="fa fa-briefcase"
                            ></i>
                          </template>
</v-combobox>





                </v-col>
             
                <v-col cols="12" md="6" sm="6">

                  <VueAutonumeric
                      label="Valor fondo:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      required
                      outlined
                      dense
                      v-model="CajaChica.valorFondo"
                     :rules="[$rules.required]"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>


                    <VueAutonumeric id="formdisabledInput"
                      label="Fondo actual:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      required
                      outlined
                      dense
                      v-model="CajaChica.fondoActual"
                      :disabled="CajaChica.codigo>0"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>


                </v-col>
              </v-row>
            </v-form>
       </div>
  </v-card>
 </div>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <!-- <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> -->

          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Caja Chica', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Custodio', value: 'custodio.nombre', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Fondo', value: 'valorFondo', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Balance', value: 'fondoActual', sortable:false, class: ['no-gutters black--text'] },
        { text: 'Estado', value: 'activado', sortable:false, class: ['no-gutters black--text'] },
      ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
                   
<template v-slot:item.activado="props">
  <span v-if="props.item.activado==true">Activo</span>
  <span v-if="props.item.activado==false">inactivo</span>
 </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarCajaChica'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->
    

    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent max-width="80%" max-height="50%">
 

<ModalReporteBasico
@DataReporteBasico="DDataReporteBasico"
:Datos="datosImprecion"
/>

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->




<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteBasico from "../../components/ModalReporteBasico";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    BarraDinamicaNormal,
    ModalReporteBasico
       

  },
  created() {  
    var json = {
      titulo: "Caja Chica",
      descripcion: "Creación de cajas chicas",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "CajaCajaChicaNew",
        "CajaCajaChicaSave",
        "CajaCajaChicaRemove",
        "CajaCajaChicaAnular",
        "CajaCajaChicaPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("CajaCajaChicaNew", this.new);
    // EventBus.$on("CajaCajaChicaSave", this.save);
    // EventBus.$on("CajaCajaChicaRemove", this.remove);
    // EventBus.$on("CajaCajaChicaAnular", this.remove);
    // EventBus.$on("CajaCajaChicaPrintList", this.imprimirList);
    EventBus.$on("emitCajaCajaChica", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {

dialogimprimirList:false,
datosImprecion:{
nombre:'CAJA CHICA',
 UrlImprimir: '/cajachica/Imprimir', 
 UrlExel: '/cajachica/Excel', 
 UrlCorreo: '/cajachica/Correo', 
 secuencia: 1, 
 documento: 0 
},

      RCustodio:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},
      CajaChica: {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: ""
      },

     EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },



      text: "",
      valid: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],



      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },
    
    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){
//this.windowSize=e
},

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }



    },
    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/cajachica/findall",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.ListaTiposClientes = res.data))
        .catch(error => console.log(error));
    },
    datostabla(e) {

      this.CajaChica = JSON.parse(JSON.stringify(e));
      this.acti_tb_data=false
    },
    save() {

if (this.$refs.form.validate()) {

      var jsn={
   datos:JSON.parse(JSON.stringify(this.CajaChica)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }
      this.$axios
        .post(
          this.$hostname +
            this.$hName +
            "/cajachica/salvar",this.CajaChica,{headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => this.alerta("Dato guardado correctamente!", "bien"))
        .catch(error =>
          this.alerta("Dato no guardado","error"
            // "hubo un error , por favor verifique el campo descripcion!",
            // "error"
          )
        );
}else{
this.alerta("Los campos en rojo son requeridos", "error");
}




    },

NotifMessage() {

if(this.CajaChica.codigo!=0 && this.CajaChica.codigo!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarCajaChica"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione un caja para eliminar", "error")}


    },

 Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },


    eliminar() {

  var jsn={
   datos:JSON.parse(JSON.stringify(this.CajaChica)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

if (this.CajaChica.codigo!=0) {
        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/cajachica/borrar",
            this.CajaChica,{headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => {this.alerta(res.data.mensage, res.data.estado)  })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {
      if (this.Elim_notif.estado) {

var jsn={
   datos:JSON.parse(JSON.stringify(this.CajaChica)),
   usuario:JSON.parse(JSON.stringify(this.$user)) 
  }

        this.$axios
          .post(
            this.$hostname +
              this.$hName +
              "/cajachica/borrar",this.CajaChica
            ,{headers:this.$store.getters.GetheadersAxios}
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }
    },
    imprimirList() { this.dialogimprimirList=true},

DDataReporteBasico(e){
this.dialogimprimirList=e
},




    newwnuevo() {

      this.BarraTablaDinamicaAprir = false
      this.CajaChica = {
        activado: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        custodio:null,
        valorFondo:0.00,
        fondoActual:0.00
      }
      
       // EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName + "/cajachica/findall", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {
          this.list = res.data;
        });
    },
    
cargaCustodio(){
this.$axios
        .get(
          this.$hostname +
            this.$hName +
            "/custodio/findallactivo",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.RCustodio = res.data))
        .catch(error => console.log(error));
},
  },



computed:{

    ActDescBtn(){      
if(this.CajaChica.codigo>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}


if(this.CajaChica.codigo<=0){
this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:false,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }
}




this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
    }
    }




};
</script>

<style></style>
