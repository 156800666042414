<template>
  <div>
    <v-app-bar v-resize="onResize" color="#dd4b39" dark fixed>
      <v-app-bar-nav-icon v-if="$store.getters.GetdatosSistem.btnAppBar" @click="abrir_cerrar"></v-app-bar-nav-icon>

      <v-toolbar-title v-ripple="{ class: `white--text` }" v-if="$store.getters.GetdatosSistem.btnAppBar">
        <img @click.prevent="MetReturnHome" v-if="$store.getters.GetdatosSistem.btnAppBar" id="imagen-mini"
          alt="Usuario" :src="CargarImgLogo($store.getters.GetdatosInfUsu.usuario)" />
        <!-- {{$nombreEmpresa}} -->
      </v-toolbar-title>

      <div class="px-2" v-if="PropsBtnConfig && $store.getters.GetOtrasConfigPv != null">
        <v-chip class="px-2" color="#FFFFFF" outlined label small>
          <i left style="font-size: 25px;" class="fa fa-fax"></i>

          <b class="px-1">CAJA: </b> <span v-if="$store.getters.GetOtrasConfigPv.caja != null"
            class="px-2">{{ $store.getters.GetOtrasConfigPv.caja }}</span>
        </v-chip>

        <v-chip class="px-2" color="#FFFFFF" outlined label small>
          <i left style="font-size: 25px;" class="fa fa-user"></i>
          <b class="px-1">CAJERO:</b> <span v-if="$store.getters.GetOtrasConfigPv.cajero != null"
            class="px-2">{{ $store.getters.GetOtrasConfigPv.cajero }}</span>
        </v-chip>

        <v-chip class="px-2" color="#FFFFFF" outlined label small>
          <i left style="font-size: 25px;" class="fa fa-usd"></i>
          <b class="px-1">MONEDA:</b> <span v-if="$store.getters.GetOtrasConfigPv.moneda != null"
            class="px-2">{{ $store.getters.GetOtrasConfigPv.moneda }}</span>
        </v-chip>


        <v-chip v-if="$store.getters.GetConfiguracionGeneral.config.estacion == true" class="px-2" color="#FFFFFF"
          outlined label small>
          <i left style="font-size: 25px;" class="fa fa-clock-o"></i>
          <b class="px-1">TURNO:</b> <span v-if="$store.getters.GetOtrasConfigPv.turno != null"
            class="px-2">{{ $store.getters.GetOtrasConfigPv.turno }}</span>
        </v-chip>
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->


      <img v-if="$store.getters.GetdatosSistem.btnAppBar" id="imagen-mini" alt="Usuario"
        src="@/assets/entrenamiento.png" />
      <v-menu left bottom v-if="$store.getters.GetdatosSistem.btnAppBar">
        <!-- <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template> -->

        <!-- <v-list>
          <v-list-item
            v-for="n in 5"
            :key="n"
            @click="() => {}"
          >
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list> -->
      </v-menu>

      <v-menu left bottom v-if="$store.getters.GetdatosSistem.btnAppBar">
        <template v-slot:activator="{ on }">

          <v-list v-ripple="{ class: `white--text` }" v-show="windowSize.width > 586" class="pa-0 ma-0 py-0 my-0"
            style="background-color:#dd4b39;">
            <p v-on="on" v-if="$store.getters.GetConfiguracionGeneral != null" class="pa-0 ma-0 py-0 my-0"
              :class="`white--text`"><b>{{ $store.getters.GetConfiguracionGeneral.config.nombre }}</b></p>
            <p v-on="on" class="pa-0 ma-0 py-0 my-0" :class="`white--text`"><b> </b>
              {{ $store.getters.GetdatosInfUsu.usuario.nombre }}</p>
          </v-list>

          <!-- <v-col  style=" border: 2px solid #000000" class="pa-0 ma-0 py-0 my-0"   cols="2" md="2" sm="2" >
  <p class="pa-0 ma-0 py-0 my-0" :class="`white--text`"><b>{{$store.getters.GetConfiguracionGeneral.config.nombre}}</b></p>
  <p class="pa-0 ma-0 py-0 my-0" :class="`white--text`"><b>Crear y modificar</b></p>
</v-col > -->


          <!-- <span v-show="windowSize.width > 586"  v-on="on" style="margin-left:-45px"  :class="`white--text pb-5`"><b>{{$store.getters.GetConfiguracionGeneral.config.nombre}}</b></span>
    <span v-show="windowSize.width > 586"  v-on="on"   :class="`white--text pt-3`">{{$store.getters.GetdatosInfUsu.usuario.nombre}}<b></b></span>
     size="55"-->
          <v-avatar v-ripple="{ class: `white--text` }" v-on="on" color="grey darken-2" class="ma-2" size="65">
            <span
              v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo == null"
              class="white--text headline">{{ getletra($store.getters.GetdatosInfUsu.usuario.nombre) }}</span>
            <img
              v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo != null"
              id="img-mini" alt="Usuario" style=" border: 2px solid #dd4b39;  border-radius: 10px;"
              :src="$store.getters.GetConfiguracionGeneral.config.logo" />
          </v-avatar>

        </template>
        <v-card style="  background:#dd4b39; border: 2px solid #000000;   border-radius: 2px;" max-width="344"
          class="mx-auto">

          <v-list-item>
            <v-list-item-avatar color="grey">

              <v-avatar size="68" color="grey">
                <span
                  v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo == null"
                  class="white--text headline">{{ getletra($store.getters.GetdatosInfUsu.usuario.nombre) }}</span>
                <img
                  v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo != null"
                  id="img-mini" alt="Usuario" style=" border: 2px solid #dd4b39;  border-radius: 10px;"
                  :src="$store.getters.GetConfiguracionGeneral.config.logo" />
                <!-- <v-img  aspect-ratio="1" class="grey lighten-2" max-width="300"
      max-height="300" :src="$ImgName"> </v-img>   -->
              </v-avatar>

            </v-list-item-avatar>

            <v-list-item-content class="ma-2">
              <span v-if="$store.getters.GetConfiguracionGeneral != null"
                class="headline">{{ $store.getters.GetConfiguracionGeneral.config.nombre }}</span>
              <v-list-item-subtitle>{{ $store.getters.GetdatosInfUsu.usuario.nombre }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ $store.getters.GetdatosInfUsu.usuario.email }}</v-list-item-subtitle>
              <!-- <v-list-item-subtitle>{{$store.getters.GetdatosInfUsu.usuario.username}}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>


          <!-- <hr>
      
  <hr> -->
          <v-card-text>
            <!-- {{Gtdats.imagen}} -->
          </v-card-text>




          <v-card-actions style=" border: 2px solid #424242; background:#BDBDBD;  border-radius: 2px;">
            <v-btn text color="black accent-4" @click.prevent="CerrarSesion">
              <i style="font-size: 18px;" class="fa fa-exchange"> </i>
              Cerrar sesion
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn text color="black accent-4" @click.prevent="ConfigGeneral">
              <i style="font-size: 18px;" class="fa fa-cogs"> </i>
              Configuracion
            </v-btn>


          </v-card-actions>

        </v-card>
      </v-menu>


      <v-btn v-show="PropsBtnConfig" @click.prevent="EjecConfigPv" icon>
        <i style="font-size: 18px;" class="fa fa-cogs"> </i>
      </v-btn>
    </v-app-bar>
    <!-------------------------------------------------------- Modales -->


    <!-------------------------------------------------------- Modal- Modal- Configuración general -->
    <v-dialog label="Modal Configuración general " v-model="OpenDialogConfigGeneral" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card>


        <!------------otras funciones---------------->
        <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
          :timeout="120000" top="top">
          {{ aalert.nombre }}
          <v-btn dark text @click="aalert.estado = false">Close</v-btn>

        </v-snackbar>

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Configuración
                general </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Configuración general:
              </span>Configuración general</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>


            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click.prevent="saveConfg" color="success" small dark>
                    SALVAR
                    <i style="font-size: 20px;" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
                  </v-btn>
                </template>
                <span>SALVAR: Configuración</span>
              </v-tooltip>

            </v-btn-toggle>

            <v-btn color="grey darken-4" fab small @click="CloseDialogConfigGeneral">
              <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn>



          </v-card-actions>

          <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click=""
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
        </v-toolbar>
        <div v-if="OpenDialogConfigGeneral">
          <!-- configuracion 1 -->
          <v-col cols="12" md="12" sm="12">
            <v-row>

              <v-col cols="12" md="4" sm="4" class=" py-0 my-0">
                <v-text-field style="font-size:13px;" v-model="GeneralConfiguracion.nombre" :rules="[$rules.required]"
                  autocomplete="off" label="* Nombre:" required outlined dense>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-home"></i>
                  </template>
                </v-text-field>

                <!-- ----------------------------- direccion -->
                <v-textarea style="font-size:13px;" autocomplete="off" label="* Direccion:" dense outlined
                  v-model="GeneralConfiguracion.direccion" :rules="[$rules.required]" rows="3">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                  </template>
                </v-textarea>

                <v-file-input dense outlined accept="image/png, image/jpeg, image/bmp" @change="ConvertirImg"
                  ref="files" id="foto" show-size counter small-chips v-model="cargaImg" multiple
                  prepend-icon="mdi-camera" label="Elegir Logo ">


                  <!-- <template v-slot:append-outer>

                  <v-tooltip top>
                <template v-slot:activator="{ on }"> 
                  
                <i style="font-size: 20px;" v-on="on" class="fa fa-eye"></i>
                </template>

<v-avatar  
                  size="230"
                  tile
                >
                  <v-img :src="GeneralConfiguracion.logo"></v-img>
             </v-avatar> 

                        sp  
                        </v-tooltip> 
                        
                  </template> -->
                  <!-- <span>Buscar Solicitud</span> getImgUrl() -->
                </v-file-input>

                <v-img max-height="200" style="border: 2px solid #000000;" :src="GeneralConfiguracion.logo"></v-img>
           
           <br>
           
                <v-file-input dense outlined accept="image/png, image/jpeg, image/bmp" @change="ConvertirImgfooter"
                  ref="files" id="imagenfooter" show-size counter small-chips v-model="cargaImgfooter" multiple
                  prepend-icon="mdi-camera" label="Imagen footer reporte factura " >
                  <template v-slot:append-outer>

<v-tooltip top>
  <template v-slot:activator="{ on }">
    <v-btn v-on="on" fab @click.prevent="GeneralConfiguracion.imgFooterRepfactura=null,cargaImgfooter=null" x-small color="red darken-3"><i
                        style="font-size: 25px;" class="fa fa-trash-o"></i></v-btn>

  </template>
  <span>quitar imagen</span>
</v-tooltip>

</template>
                </v-file-input>

                <v-img v-if="GeneralConfiguracion.imgFooterRepfactura!=null" max-height="200" style="border: 2px solid #000000;" :src="GeneralConfiguracion.imgFooterRepfactura"></v-img>
              
           
           
           
              </v-col>

              <v-col cols="12" md="4" sm="4" class=" py-0 my-0">

                <v-text-field label="* Ciudad:" dense outlined style="font-size:13px;" autocomplete="off"
                  v-model="GeneralConfiguracion.ciudad" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map"></i>
                  </template>
                </v-text-field>

                <v-text-field label="teléfono:" dense outlined autocomplete="off" style="font-size:13px;" maxlength="13"
                  v-mask="'(###)###-####'" v-model="GeneralConfiguracion.telefono">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-phone"></i>
                  </template>

                </v-text-field>

                <!-- -----------------------------  email -->
                <v-text-field label="E-mail:" style="font-size:13px;" dense outlined
                  v-model="GeneralConfiguracion.email">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                  </template>
                </v-text-field>


                <!-- -----------------------------  Contraseña -->
                <v-text-field label="clave del E-mail:" autocomplete="off" type="password" dense outlined
                          :rules="[$rules.required]" v-model="GeneralConfiguracion.clave">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                          </template>
                        </v-text-field>

                

                <v-text-field style="font-size:13px;" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense
                  outlined maxlength="13" :rules="[$rules.required,$rules.RulesCelRncValidar]" v-model="GeneralConfiguracion.rnc"
                  @focus="MakCelRnc = '#############'"  @blur="MakaraCel" v-on:blur="validandoCedRnc(GeneralConfiguracion.rnc)">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                  </template>
                </v-text-field>

                <v-text-field style="font-size:13px;" v-model="GeneralConfiguracion.paginaweb"
                  :rules="[$rules.required]" autocomplete="off" label=" Página Web:" required outlined dense>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-globe"></i>
                  </template>
                </v-text-field>


                <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda" item-text="nombrecorto"
                  :rules="[$rules.required]" v-model="GeneralConfiguracion.monedabase" @blur="SelctCbMoneda"
                  @focus="cargarMoneda">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-combobox>


              </v-col>

              <v-col cols="12" md="4" sm="4" class=" py-0 my-0">

                <v-combobox :items="RModFact" item-text="desc" @blur="SelctCbSelectModFact"
                  label="Modelo de Facturación:" @change="ChangeModFact" required outlined dense
                  v-model="ModuloFactura">
                  <template v-slot:prepend>
                    <i style="font-size: 18px;" class="fa fa-money"></i>
                  </template>
                </v-combobox>


                <v-text-field style="font-size:13px;" autocomplete="off" v-mask="'###-#####-#'"
                  label="RNC de compañía origen para enlazar a su sistema:" dense outlined maxlength="11"
                  :rules="[$rules.RulesRnc]" v-model="GeneralConfiguracion.rncorigen">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                  </template>
                </v-text-field>

                <!-- -----------------------------  email -->
                <v-text-field label="Email del contador que va enlazar a su sistema:" style="font-size:13px;" dense
                  outlined v-model="GeneralConfiguracion.emailcontador">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                  </template>
                </v-text-field>





                <!-- ----------------------------- comentario -->
                <v-textarea style="font-size:13px;" autocomplete="off" label="comentario:" dense outlined
                  v-model="GeneralConfiguracion.comentario">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-commenting"></i>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <!-- fn configuracion 1 -->


          <!-- configuracion 2 -->
          <v-col v-show="false" cols="12" md="12" sm="12">
            <v-row>
              configuracion 2
            </v-row>
          </v-col>
          <!-- fn configuracion 2 -->

        </div>




      </v-card>
    </v-dialog>



    <!-------------------------------------------------------- modales -->

  </div>

</template>

<script>
import { EventBus } from "../event-bus.js";
import { 
  validaCedRnc
} from "@/js/Funciones.js";
export default {

  created() {

  },


  mounted() {
    this.onResize()
  },


  data() {
    return {

      cargaImg: null,
      cargaImgfooter: null,
      titulo: '',
      NombreEmpresa: this.$nombreEmpresa,
      Logo: this.$ImgName,
      windowSize: { width: "auto", height: "auto" },
      OpenDialogConfigGeneral: false,
      GeneralConfiguracion: null,
      MakCelRnc: "#############",
      Rmoneda: [],


      ModuloFactura: null,
      RModFact: [
        { codigo: 1, desc: 'Punto de ventas' },
        { codigo: 2, desc: 'Facturación Estandar' },
      ],


      aalert: {
        estado: false,
        color: null
      },

    }
  },
  props: {

    PropsBtnConfig: {
      required: false,
      type: Boolean,
      default: false
    },


  },

  methods: {
    validaCedRnc,
    onResize() {


      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 }
      this.windowSize = jsn

    },

    saveConfg() {



      this.$axios.post(this.$hostname + this.$hName + "/configuraciones/GeneralConfiguracion", this.GeneralConfiguracion,
        { headers: this.$store.getters.GetheadersAxios }).
        then(res => { this.UpdateConfguracion(res.data), this.alerta("se guardo correctamente!", "bien") })
        .catch(error => this.alerta(error, "error"));

    },

    UpdateConfguracion(e) {

      console.log('UpdateConfguracion')
      console.log(e)


      var confgene = this.$store.getters.GetConfiguracionGeneral;
      confgene.config = JSON.parse(JSON.stringify(e))
      sessionStorage.setItem("ConfiguracionGeneral", JSON.stringify(confgene));
      this.$store.commit('MutationaddConfiguracionGeneral', confgene);
    },


    cargarConfiguraciones() {

      //if( this.$route.name!="Login" ){}

      var confgene = this.$store.getters.GetConfiguracionGeneral;
      this.GeneralConfiguracion = JSON.parse(JSON.stringify(confgene.config))
      /*if(this.GeneralConfiguracion.hasOwnProperty('cantidad')){}
      this.GeneralConfiguracion.imgFooterRepfactura*/
      var obj = this.RModFact.filter(e => e.codigo == confgene.config.modelofacturacion)
      this.ModuloFactura = obj[0]
      var _this = this;
        setTimeout(function () {_this.MakaraCel()}, 500);
   
    },


    CloseDialogConfigGeneral() {

      this.OpenDialogConfigGeneral = false
    },

    ConfigGeneral() {
      this.cargarConfiguraciones()
      this.OpenDialogConfigGeneral = true
    },

    CargarImgLogo(e) {
      var images
      if (e.gubernamental == true) {
        var pet = "/gobConWeb.png"
        var images = require("@/assets/icons_logo" + pet);
        return images;
      }

      if (e.transportnet == true) {
        var pet = "/TransporNet.jpg"
        var images = require("@/assets/icons_logo" + pet);
        return images;
      }

      if (e.micromarket == true) {
        var pet = "/logoFinalMK.gif"
        var images = require("@/assets/icons_logo" + pet);
        return images;
      } else {
        var pet = "/farmacon.png"
        var images = require("@/assets/icons_logo" + pet);
        return images;
      }

      // var pet="Decasystem.png"
      // var images = require("@/assets/icons_logo" + pet);
      // return images;     
    },


    ConvertirImg(e) {

      var _this = this;

      // var filesSelected = document.getElementById("foto").files;
      var filesSelected = e
      if (filesSelected.length > 0) {
        var fileToLoad = filesSelected[0];

        var fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64

          var newImage = document.createElement("img");
          newImage.src = srcData;
          _this.GeneralConfiguracion.logo = srcData;


          // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
          // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
          // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        };
        fileReader.readAsDataURL(fileToLoad);
      } else {

        this.GeneralConfiguracion.logo = require("@/assets/img/Nofoto.png");

      }
    },


    ConvertirImgfooter(e) {

var _this = this;

// var filesSelected = document.getElementById("foto").files;
var filesSelected = e
if (filesSelected.length > 0) {
  var fileToLoad = filesSelected[0];

  var fileReader = new FileReader();

  fileReader.onload = function (fileLoadedEvent) {
    var srcData = fileLoadedEvent.target.result; // <--- data: base64

    var newImage = document.createElement("img");
    newImage.src = srcData;
    _this.GeneralConfiguracion.imgFooterRepfactura = srcData;


    // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
    // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
    // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
  };
  fileReader.readAsDataURL(fileToLoad);
} else {

  this.GeneralConfiguracion.imgFooterRepfactura = '';

}
},




    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))

    },


    SelctCbMoneda() {

      if (this.GeneralConfiguracion.monedabase == null) {

        this.GeneralConfiguracion.monedabase = ''
      }

      if (this.GeneralConfiguracion.monedabase.codigo == undefined) {

        this.alerta("Seleccione una moneda", "error");
        this.GeneralConfiguracion.monedabase = ''
        return

      }

    },


    ChangeModFact(e) {

      this.GeneralConfiguracion.modelofacturacion = JSON.parse(JSON.stringify(e.codigo))

    },


    SelctCbSelectModFact() {

      if (this.ModuloFactura == null) {
        this.ModuloFactura = ''
      }
      if (this.ModuloFactura.codigo == undefined) {
        this.alerta("Seleccione un Modelo de Facturación", "error");
        this.ModuloFactura = ''
        return
      }
    },




    EjecConfigPv() {
      EventBus.$emit("emitEjecConfigPv");
    },

    MetReturnHome() {

      console.log()
      if (this.$route.name != "Principal") {
        this.$router.push({ path: '/Principal' })
      }

    },

    CerrarSesion() {
      sessionStorage.setItem("ConfiguracionGeneral", JSON.stringify(null));
      this.$store.commit('MutationaddConfiguracionGeneral', null);


      sessionStorage.setItem("OtrasConfigPv", JSON.stringify(null));
      this.$store.commit('MutationDataOtrasConfigPv', null);


      sessionStorage.setItem("AuthorizationToken", JSON.stringify(null));
      this.$store.commit('MutationHeadersAxios', null);
      this.$store.commit('MutationHeadersTenant', null);
      this.$store.commit('MutationHeadersAxios', null);


      sessionStorage.setItem("Usuario", JSON.stringify(null));

      var rou = JSON.parse(JSON.stringify(this.$store.getters.GetDatosItemsRouterDefour))
      this.$store.commit('MutationDataRouter', null);
      sessionStorage.setItem("itemsRouter", JSON.stringify(null));

      this.$store.commit('MutationaddUser', null);
      sessionStorage.setItem("AuthorizationToken", JSON.stringify(null));
      this.$store.commit('MutationHeadersAxios', null);

      this.$router.push({ path: '/' })
      location.reload();

      // var _this=this;
      // setTimeout(function(){
      //   location.reload();
      //  }, 200);

    },

    abrir_cerrar() {
      this.$emit('abrir_cerrar');
    },

    getletra(e) {

      //var letra ="http://fcialalinea.ddns.net/micromarket/imagenMM.mm?pic=imagenes_reportes/LALINEA.jpg"
      var letra
      if (e.length > 0) {
        letra = e.substr(0, 2)
      } else {
        letra = " "
      }

      return letra;


    },

    validandoCedRnc(e){

if(e!=null && e.length>0){
if(this.validaCedRnc(e)==false){
  var NOM= e.length>10?"CEDULA":"RNC"
  this.alerta(`${NOM} Invalida `, "error");
    return false;
    }
  }

  return true;

},

MakaraCel() {
      if (this.GeneralConfiguracion.rnc.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }
      if (this.GeneralConfiguracion.rnc.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
      }
    },



  }
}
</script>
<style lang="scss">
#imagen-mini {
  height: 48px;
}

@media screen and (max-width: 800px) {

  #imagen-mini {
    height: 40px;
  }

}
</style>