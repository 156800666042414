<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">
<!-- ContenidoBase-Tarjetas de Credito -->
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Tarjetas de Crédito'"
          :descripcion="'Creacion de Tarjetas de Crédito por bancos y el porciento que se cobra'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList" />
      </tr>
<!-- ContenidoBase-Tarjetas de Credito -->
      <tr>
        <!-- BarraPrimaria-Tarjetas de Credito --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small width="95%" color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>

                <BarraDinamicaNormal ref="BarraDiNor" :url="$hostname + $hName + '/tarjeta/findall/'"
                  :nombre="'Tarjeta de Crédito'" :emitnombre="'emitTarjeta'" :SeachNombre="'Búsqueda general'" :headers="[
                    { text: 'Código', value: 'codigo' },
                    { text: 'Tarjeta', value: 'nombre' },
                    { text: 'Porciento', value: 'porciento' }
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Tarjeta', NomValue: 'nombre', Ctsl: 1, Type: 0, State: true },
  { Text: 'Porciento', NomValue: 'porciento', Ctsl: 1, Type: 2, State: true },
]" />


                <!-- <v-navigation-drawer >  -->
                <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/TarjetasCredito/findall'"
              :nombre="'Tarjeta de Crédito'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitTarjeta'"
              :SeachNombre="'Búsqueda por nombre de la tarjeta'"
              :slotlist="slotlist"
            /> -->
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria-Tarjetas de Credito  ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">

            <div class="px-2">
              <!-- Formulario-Tarjetas de Credito  -->
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card color="grey lighten-3">
                  <div class="px-2">

                    <input type="number" v-model="Tarjeta.codigo" style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="12">
                        <!------------------------------------------ Nombre:-->

                        <v-text-field label="* Nombre:" dense outlined :rules="[$rules.required]"
                          v-model="Tarjeta.nombre" autocomplete="off">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>
                        <!--------------------------------------- Porcentaje:-->
                        <AutoNumeric autocomplete="off" label="* Porcentaje:" dense outlined
                          :rules="[$rules.required, $rules.porciento]" v-model="Tarjeta.porciento">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-list-alt"></i>
                          </template>
                        </AutoNumeric>

                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <v-checkbox class="mt-0" color="teal" label="" v-model="Tarjeta.cardnet" width="50"
                          hide-details>
                          <template v-slot:label>
                            <strong v-if="Tarjeta.cardnet == true" style="color:#000000; font-size:13px;">Cardnet:
                              Si</strong>
                            <strong v-if="Tarjeta.cardnet == false" style="color:#000000; font-size:13px;">Cardnet:
                              No</strong>
                          </template>
                        </v-checkbox>

                        <v-checkbox class="mt-0" color="teal" label="" v-model="Tarjeta.azul" width="50" hide-details>
                          <template v-slot:label>
                            <strong v-if="Tarjeta.azul == true" style="color:#000000; font-size:13px;">azul: Si</strong>
                            <strong v-if="Tarjeta.azul == false" style="color:#000000; font-size:13px;">azul:
                              No</strong>
                          </template>
                        </v-checkbox>
                      </v-col>


                      <!-- fn columna 1 ------------------------------>
                    </v-row>



                  </div>
                </v-card>


              </v-form>
              <!-- fn Formulario-Tarjetas de Credito  -->
            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data todo general-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <!-- <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>
 </template>
          <span>Imprimir Listado</span>
        </v-tooltip>  -->
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Tarjeta', value: 'nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Porciento', value: 'porciento', sortable: false, class: ['no-gutters black--text'] }
            ]" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{
                    currencyFormatter(props.item.porciento)
                }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data todo general-->

    <!-- ------------------------------------------------------ Modal-notificacion general -->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'eliminarTarjeta'" @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Modal-notificacion general -->

    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    BarraDinamicaNormal,
    ContenidoBase
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitTarjeta", this.datostabla);
    // EventBus.$on("tarjetaNew", this.new);
    // EventBus.$on("tarjetaSave", this.save);
    // EventBus.$on("tarjetaRemove", this.remove);
    // EventBus.$on("tarjetaAnular", this.anular);
    // EventBus.$on("tarjetaPrintList", this.imprimirList);

    this.filtro();
  },

  created() {
    var json = {
      titulo: "Tarjetas de Crédito",
      descripcion: "Creacion de Tarjetas de Credito por bancos y el porciento que se cobra ",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,

      accion: [
        "tarjetaNew",
        "tarjetaSave",
        "tarjetaRemove",
        "tarjetaAnular",
        "tarjetaPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      aalert: {
        estado: false,
        color: null
      },

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      Tarjeta: {
        codigo: 0,
        nombre: null,
        porciento: 0,
        azul: false,
        cardnet: false
      },

      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],


      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {


    currencyFormatter(params) {
      return this.formatNumber(params);
    },

    formatNumber(number) {
      //console.log(number+"->"+number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"))

      // var p=Math.trunc(number)
      // var pd=number-p
      //console.log(number.toFixed(2))

      return number
        .toFixed(2)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

      // return Math.floor(number)
      //   .toString()
      //   .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },

    // Envío de una solicitud a un api para guardar los datos.
    save() {


      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {

          this.ejecucion = false;
          var Tarjeta = JSON.parse(JSON.stringify(this.Tarjeta))
          this.$axios
            .post(this.$hostname + this.$hName + "/tarjeta/save", Tarjeta, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { console.log('Ressssspuuuu'), console.log(res.data), this.alerta("Dato guardado correctamente!", "bien") })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));

        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }





    },


   // Crear un objeto de notificación y luego llamar a la función Exec_notif.
    NotifMessage() {

      if (this.Tarjeta.codigo != 0 && this.Tarjeta.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea eliminar este registro?",
          Exec: "eliminarTarjeta"
        }

        this.Exec_notif(notif)
      } else { this.alerta("Seleccione una caja para eliminar", "error") }


    },

    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))
      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },



    // Envío de una solicitud de publicación al servidor para borrar la tarjeta seleccionada.
    eliminar() {
      if (this.Tarjeta.codigo != 0 && this.Tarjeta.codigo != null) {
        if (this.$refs.form.validate()) {

          var Tarjeta = JSON.parse(JSON.stringify(this.Tarjeta))
          console.log('Entrando A eliminar')
          this.$axios
            .post(this.$hostname + this.$hName + "/tarjeta/borrado/", Tarjeta, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta(res.data.mensage, res.data.estado) })
            .catch(error => this.alerta("Dato no guardado correctamente", "error"));
        }
      }
    },

    anular() {
      console.log("anular");
    },
   // Abriendo una nueva ventana y escribiendo el pdf en ella.
    imprimirList() {
      var headers = this.$store.getters.GetheadersAxios

      this.$axios.get(this.$hostname + this.$hName + "/tarjeta/imprimir/", { headers: headers }).then(res => {
        /*let pdfWindow = window.open("Reporte de imprecion")
        pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
          encodeURI(res.data) + "'></embed>")*/

          var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'tarjeta');

      });

    },
   // reinicia los datos del formulario.
    newnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.Tarjeta = {
        codigo: 0,
        nombre: null,
        porciento: 0,
        azul: false,
        cardnet: false
      };

      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

   // Una función que se llama cuando un usuario hace clic en una fila de una tabla.
    datostabla(e) {

      console.log(e)
      this.Tarjeta = JSON.parse(JSON.stringify(e));
      this.acti_tb_data = false;
      //console.log(e);


      if (this.windowSize.width <= 600) {

        this.BarraTablaDinamicaAprir = false


      }

    },

    // Un método que se llama cuando se hace clic en un botón.
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    // Hacer una solicitud al servidor y devolver los datos a la variable de lista.
    async filtro() {
      var headers = this.$store.getters.GetheadersAxios
      await this.$axios.get(this.$hostname + this.$hName + "/tarjeta/findall/", { headers: headers })
        .then(res => { this.list = res.data; });
    },

    // Abriendo una tabla y luego muestra los datos.
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

      if (this.BarraTablaDinamicaAprir == true) {
        this.$refs.BarraDiNor.list = []
        this.$refs.BarraDiNor.filtro()

      }



    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    }
    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  }
  ,
  computed: {

    ActDescBtn() {


      if (this.Tarjeta.codigo > 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: true,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }





        var objjct = {
          save: true,
          eliminar: true,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };

      }


      if (this.Tarjeta.codigo <= 0) {



        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
        }



        var objjct = {
          save: true,
          eliminar: false,
          anular: false,
          nuevo: true,
          imprimir: true,
          vercuenta: false,
        };


      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

      //EventBus.$emit("ActDesaBotones", objjct);
    }





  }




};
</script>

<style>
</style>
