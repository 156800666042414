<template>
 <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
 <tr max-height="48px" height="48px"> 
<ContenidoBase 
  :titulo="'Crear las Rutas'"
  :descripcion="'Para Registrar las Rutas de Servicio de Transporte'"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newwnuevo"
   @MethodGuardar="save"
   @MethodEliminar="NotifMessage"
   @MethodAnular="NotifMessage"
   @MethodImprimir="imprimirList"    
/>
    </tr>
    <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        <v-tooltip left>
          <template v-slot:activator="{ on }">
              <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
                
              
            </center>


            <BarraDinamicaNormal
              :url="$hostname + $hName + '/rutas/findall/'"
              :nombre="'Rutas'"
              :emitnombre="'emitTranspCrearRuta'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Código', value: 'id'},
        { text: 'Salida', value: 'sucursalSalida.descripcion'},
        { text: 'Llegada', value: 'sucursalLlegada.descripcion'}
           ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'id', Ctsl:1, Type:0, State:true},
              {Text:'Salida', NomValue:'sucursalLlegada', Nv2:'descripcion' , Ctsl:2, Type:0, State:true},
              {Text:'Llegada', NomValue:'sucursalSalida', Nv2:'descripcion' , Ctsl:2, Type:0, State:true},
              {Text:'Estado',NomValue:'activa', Ctsl:1, Type:0.1, State:true},
              ]"
            />
 


            <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitTranspCrearRuta'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>




      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
 

 <v-container >
  <v-card color="grey lighten-3">
  <v-container > <!-- style="border: 1px solid #000000;" -->
    
  <v-form ref="formCrearRuta" @submit.prevent=""  v-model="valid" lazy-validation>


    <input type="hidden" v-model.number="CrearRuta.id"/>

              <v-row>

<!-- <v-col cols="12" md="12" sm="12"> -->

<!-- <v-form ref="formEnvio"  @submit.prevent=""  v-model="valid" lazy-validation> </v-form>              
<v-row></v-row> -->

<v-col cols="12" md="3" sm="3">
  <!-- @change="validar"  -->
  <v-switch
              class="mt-0"
              color="green lighten-2"
              hide-details
              label="Ruta de Boletería"
              v-model="CrearRuta.rutaBoleteria"
            >
            <template v-slot:label>
         <strong style="color:#000000;">Ruta de Boletería</strong>
        </template>
            
            
            </v-switch>

</v-col>

<v-col cols="12" md="3" sm="3">

  <!-- -----------------------------  Valor  -->
<VueAutonumeric v-if="CrearRuta.rutaBoleteria==true"
                      label="Precio Pasaje:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      :rules="[$rules.required, $rules.NumNocero]"
                      outlined
                      dense
                      v-model="CrearRuta.precioPasaje"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!-- -----------------------------  Valor  -->
<VueAutonumeric v-if="CrearRuta.rutaBoleteria==false"
                      label="Precio Pasaje:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      outlined
                      dense
                      v-model="CrearRuta.precioPasaje"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>
</v-col>
<!-- </v-col>  -->


<v-col cols="12" md="12" sm="12" style="margin-top:-25px;" >
<v-row> 

  <v-col cols="12" md="3" sm="3">
    <v-switch
          class="mt-0"
          color="green lighten-2"
          hide-details
          label="Ruta de Envío / Entrega"
          v-model="CrearRuta.rutaEnvio">


            <template v-slot:label>
         <strong style="color:#000000;">Ruta de Envío / Entrega</strong>
        </template>
 </v-switch>
 
  </v-col>

  <v-col cols="12" md="3" sm="3" >

     <!-- -----------------------------  Precio libra  -->
<VueAutonumeric v-if="CrearRuta.rutaEnvio==true"
                      label="Precio libra:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      :rules="[$rules.required, $rules.NumNocero]"
                      outlined
                      dense
                      v-model="CrearRuta.precioLibra"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!-- -----------------------------  Precio libra  -->
<VueAutonumeric v-if="CrearRuta.rutaEnvio==false"
                      label="Precio libra:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      outlined
                      dense
                      v-model="CrearRuta.precioLibra"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>
  </v-col>

  <v-col cols="12" md="3" sm="3" >

 <!-- -----------------------------  Precio pie  -->
<VueAutonumeric v-if="CrearRuta.rutaEnvio==true"
                      label="Precio pie:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      :rules="[$rules.required, $rules.NumNocero]"
                      outlined
                      dense
                      v-model="CrearRuta.precioPie"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!-- -----------------------------  Precio pie  -->
<VueAutonumeric v-if="CrearRuta.rutaEnvio==false"
                      label="Precio pie:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      outlined
                      dense
                      v-model="CrearRuta.precioPie"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

  </v-col>

  <v-col cols="12" md="3" sm="3" >
    <!-- -----------------------------  Precio adicional envio  -->
<VueAutonumeric v-if="CrearRuta.rutaEnvio==true"
                      label="Precio adicional envio:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      :rules="[$rules.required, $rules.NumNocero]"
                      outlined
                      dense
                      v-model="CrearRuta.precioAdicionalAsientoEnvio"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!-- -----------------------------  Precio adicional envio -->
<VueAutonumeric v-if="CrearRuta.rutaEnvio==false"
                      label="Precio adicional envio:"
                      autocomplete="off"
                      style="font-size: 13px;"
                      outlined
                      dense
                      v-model="CrearRuta.precioAdicionalAsientoEnvio"
                    >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

  </v-col>
  
</v-row>
</v-col>

<v-col cols="12" md="12" sm="12" style="margin-top:-40px;" >
<v-row> 
  <!-- columna 1 -->
                <v-col cols="12" md="6" sm="6">

<!-- ----------------------------- Sucursales   @blur="SelctCbTipoCliente",@focus="cargarCliente" -->
<v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Paradas(Sucursales) de salida:"
            :items="sucursalSalida"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="CrearRuta.sucursalSalida"
            @focus="cargarRutaSucursales"
            @blur="SelctCbSucursalSalida"
            
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>
                </v-col>
  <!-- fn columna 1  -->

  <!-- columna 2 -->

                <v-col cols="12" md="6" sm="6">


            <v-combobox
            autocomplete="off"
            dense
            outlined
            label="* Paradas(Sucursales) de llegada:"
            :items="sucursaLlegada"
            item-text="descripcion"
            :rules="[$rules.required]"
            v-model="CrearRuta.sucursalLlegada" 
            @focus="cargarRutaSucursales"
            @blur="SelctCbSucursalLlegada"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-sitemap"></i>
            </template>
          </v-combobox>


                  <!-- <v-switch
                    label="Activo"
                    v-model="CrearRuta.activo"
                  ></v-switch> -->
                </v-col>

<!--  fn columna 2 -->
  
</v-row>
</v-col>

<v-col cols="12" md="12" sm="12" style="margin-top:-35px;" >

  <v-combobox
            autocomplete="off"
            dense
            outlined
            label="Impuesto:"
            :items="impuesto"
            item-text="descripcion"
            v-model="CrearRuta.impuesto" 
            @focus="cargarImpuesto"
            @blur="SelctCbImpuesto"
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-wpforms"></i>
            </template>
          </v-combobox>
</v-col> 


<v-col cols="12" md="6" sm="6" >
  <v-switch
              class="mt-0"
              color="green lighten-2"
              hide-details
              label="Cambiar Precio"
              v-model="CrearRuta.permiteCambiarPrecio"
            >
              <template v-slot:label>
         <strong style="color:#000000;">Cambiar Precio</strong>
        </template>
            
            </v-switch>

            <v-switch
              class="mt-0"
              color="green lighten-2"
              hide-details
              label="Activo"
              v-model="CrearRuta.activa"
            >
              <template v-slot:label>
                 <strong style="color:#000000;">Activo</strong>
             </template>
            
            </v-switch>

</v-col>
<v-col cols="12" md="6" sm="6" >

<v-tooltip top>
                <template v-slot:activator="{ on }"> 
                  <v-switch
                  v-on="on"
              class="mt-0"
              color="green lighten-2"
              hide-details
              label="Viaje de ida"
              v-model="CrearRuta.esSentidoIda"
            > 
          <template v-slot:label>
         <strong v-on="on" style="color:#000000;">Viaje de ida</strong>
        </template>
            
            
            </v-switch>
                
    </template>
       <span>Activo= viaje de ida / Inactivo= viaje de vuelta</span>
    </v-tooltip> 



</v-col>


              </v-row>

            </v-form>
       </v-container>
  </v-card>
 </v-container>

        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
<!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      max-width="100%"
      max-height="100%"
      transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>




          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 

          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Código', value: 'id'},
        { text: 'Salida', value: 'sucursalSalida.descripcion'},
        { text: 'Llegada', value: 'sucursalLlegada.descripcion'},
        {text:'Estado', value:'activa' }

           ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
            >
              <template v-slot:item.activa="props">
                <span v-if="props.item.activa==true">Activo</span>
                <span v-if="props.item.activa==false">Inactivo</span>
              </template>

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
   
<!-- ------------------------------------------------------ notif-->
    <v-dialog
      label="myAlert"
      v-model="notif.estado"
      persistent
      max-width="350"
      transition="fab-transition"
    >
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn
            v-if="notif.Exec=='eliminarRuta'"
            @click.prevent="eliminar(), (notif.estado = !notif.estado)"
            small
          >
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          

          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->



    <!------------otras funciones---------------->
    <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
      :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";
export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    BarraDinamicaNormal,
    ContenidoBase
  },
  created() {
    var json = {
      titulo: "Crear las Rutas",
      descripcion: "Para Registrar las Rutas de Servicio de Transporte",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "TranspCrearRutaNew",
        "TranspCrearRutaSave",
        "TranspCrearRutaRemove",
        "TranspCrearRutaAnular",
        "TranspCrearRutaPrintList"
      ], 
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("TranspCrearRutaNew", this.new);
    // EventBus.$on("TranspCrearRutaSave", this.save);
    // EventBus.$on("TranspCrearRutaRemove", this.remove);
    // EventBus.$on("TranspCrearRutaAnular", this.remove);
    // EventBus.$on("TranspCrearRutaPrintList", this.imprimirList);
    EventBus.$on("emitTranspCrearRuta", this.datostabla);
    this.cargarTodo();

    EventBus.$on("onResize", this.onResize);
  },

  data:()=> ({

      
      sucursaLlegada:[], 
      sucursalSalida:[],
      ListaTiposClientes: [],
      windowSize:{width:"auto", height:"auto"},

CrearRuta: {
id: 0,
activa:true,
descripcion: "",
esSentidoIda: false,
impuesto: null,
permiteCambiarPrecio: false,
precioAdicionalAsientoEnvio: 0,
precioLibra: 0,
precioPasaje: 0,
precioPie: 0,
precioValor: 0,
rutaBoleteria: true,
rutaEnvio: false,
sucursalLlegada: null,
sucursalSalida: null
    },
EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        },
     
     text: "",
     valid: true,
     ejecucion: true,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      slotHtabla: [
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,

notif: {
        estado: false,
        nombre: "",
        Exec:""
      },

      aalert: {
        estado: false,
        color: null
      },


impuesto:[],
tipoRuta:[]

          ,Rdata:[],
          valid: true,
          gridApi: null,
          gridOptions: null,
         acti_ag_grid:false,
         act_elim:false,
         activarbarrab:false,
         headers:[],


form: {

esSentidoIda:false,
id:0,
codigo:0,
descripcion:null,
rutaBoleteria:true,
rutaEnvio:false,
cambiarPrecio:false,
precioLibra:0,
precionPasaje:0,
precioPie:0,
precioValor:0,
precio_adicional_asiento_envio:0,

rutaLlegada:null,
rutaSalida:null,

 impuesto:null,
activo:true,
imp:null,


      }
  
  
  }),

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

onResize(e){

console.log("imprii ")

console.log(e)
this.windowSize=e


},


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    async cargarTodo() {
     // await 
    },
    datostabla(e) {

      this.CrearRuta = e;
      this.acti_tb_data=false
    },
    save() {

if(this.$refs.formCrearRuta.validate()) {

  console.log('entroooooo entro')
this.CrearRuta.descripcion=this.CrearRuta.sucursalSalida.descripcion+'-'+this.CrearRuta.sucursalLlegada.descripcion

if(this.ejecucion==true){

  this.ejecucion=false
 
var Json=JSON.parse(JSON.stringify(this.CrearRuta)) 

this.$axios
        .post(this.$hostname + this.$hName + "/rutas/save/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

}      
}else{
              this.alerta("los campos en rojo son requeridos", "error");
       }



    },




NotifMessage() {

if(this.CrearRuta.id!=0 && this.CrearRuta.id!=null){

var notif= {
        estado: true,
        nombre: "¿Seguro que desea eliminar este registro?",
        Exec:"eliminarRuta"
      }

this.Exec_notif(notif)

     
}else{ this.alerta("Seleccione una ruta para eliminar", "error")}


    },

Exec_notif(e) {

//console.log(e)
this.notif=JSON.parse(JSON.stringify(e))

//this.Elim_notif.estado = !this.Elim_notif.estado;
    },





    eliminar() {

if (this.CrearRuta.id!=0) {

var Json=JSON.parse(JSON.stringify(this.CrearRuta)) 

this.$axios
        .post(this.$hostname + this.$hName + "/rutas/borrado/",Json,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{this.alerta(res.data.mensage, res.data.estado)})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"))

      }


    },
    anular() {
   
      
    },
    imprimirList() {

          window.open(this.$hostname+this.$hName+'/services/rutas/printlistRuta/')

    },
    newwnuevo() {

this.CrearRuta= {
id: 0,
activa:true,
descripcion: "",
esSentidoIda: false,
impuesto: null,
permiteCambiarPrecio: false,
precioAdicionalAsientoEnvio: 0,
precioLibra: 0,
precioPasaje: 0,
precioPie: 0,
precioValor: 0,
rutaBoleteria: true,
rutaEnvio: false,
sucursalLlegada: null,
sucursalSalida: null
    },

        this.ejecucion = true;
        EventBus.$emit("actualizaBarraBusqueda2");
    },

SelctCbSucursalSalida(){

  if(this.CrearRuta.sucursalSalida==null){

    this.CrearRuta.sucursalSalida=''
  }

if(this.CrearRuta.sucursalSalida.codigo==undefined){

this.alerta("Seleccione una Salida", "error");
this.CrearRuta.sucursalSalida=''
return

}


},



SelctCbSucursalLlegada(){

 if(this.CrearRuta.sucursalLlegada==null){

    this.CrearRuta.sucursalLlegada=''
  }

if(this.CrearRuta.sucursalLlegada.codigo==undefined){

this.alerta("Seleccione una Llegada", "error");
this.CrearRuta.sucursalLlegada=''
return

}



},



cargarRutaSucursales(){


var headers=this.$store.getters.GetheadersAxios
this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/",{headers:headers})
        .then(res => {this.sucursaLlegada=res.data, this.sucursalSalida=res.data})
        .catch(error => console.log(error));
},

cargarImpuesto(){

// await axios.post(this.$hostname+'micromarket/services/impuestos/findall', {})
// .then((res) => {(res.data.forEach(element1 => {this.impuesto.push(element1)}))})

this.$axios.post(this.$hostname+this.$hName+'/services/impuestos/findall',{
usuario:JSON.parse(JSON.stringify(this.$user)) 
})
.then(res=>{this.impuesto=res.data})


},

SelctCbImpuesto(){


if(this.form.impuesto==null){

    this.form.impuesto=''
  }

if(this.form.impuesto.codigo==undefined){

this.alerta("Seleccione un Impuesto", "error");
this.form.impuesto=''
return

}



  
},







    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

       this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },


async filtro() {
      var headers=this.$store.getters.GetheadersAxios
      
      await this.$axios.get(this.$hostname + this.$hName + "/rutas/findall/", {headers:headers})
        .then(res => {this.list = res.data;});
    }

  },
  computed:{

    ActDescBtn(){


if(this.CrearRuta.id>0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }

}


if(this.CrearRuta.id<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        etiquetaXfactura:false
        }


}
     
//EventBus.$emit("ActDesaBotones", objjct);
    }
  }

  
};
</script>

<style></style>
