var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0 ma-0 py-0 my-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_vm._v(_vm._s(_vm.GetEjecucion)+" "),_c('v-toolbar',{attrs:{"dark":"","color":"#dd4b39","dense":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-toolbar-title',_vm._g({},on),[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"}}),_vm._v(" ver Pedido ")])]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v("ver Pedido: ")]),_vm._v(" Ver Pedido")])]),_c('v-spacer'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"id":"boton-mini","color":"orange","small":"","dark":""},on:{"click":function($event){return _vm.cancelarPedidoAfactura()}}},on),[_c('i',{staticClass:"fa fa-reply-all",staticStyle:{"font-size":"20px"}}),_vm._v(" CANCELAR ")])]}}])},[_c('span',[_vm._v("CANCELAR: Cancela y retornar atrás")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{attrs:{"color":"grey lighten-3"}},[_c('br'),_c('v-form',{ref:"formReimprecion",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"font-size":"12px"},attrs:{"label":"# Secuencia:","dense":"","autocomplete":"off","outlined":"","type":"number"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-hashtag",staticStyle:{"font-size":"20px"}})]},proxy:true}],null,true),model:{value:(_vm.PedidoAfacturaBusqueda.secuencia),callback:function ($$v) {_vm.$set(_vm.PedidoAfacturaBusqueda, "secuencia", _vm._n($$v))},expression:"PedidoAfacturaBusqueda.secuencia"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Aquí se registra el número de secuencia que deseamos búscar.")])])])],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"font-size":"12px"},attrs:{"dense":"","outlined":"","type":"date","label":"Fecha Inicial","rules":[_vm.$rules.required],"placeholder":" "},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-calendar",staticStyle:{"font-size":"18px"}})]},proxy:true}],null,true),model:{value:(_vm.PedidoAfacturaBusqueda.fechaInicial),callback:function ($$v) {_vm.$set(_vm.PedidoAfacturaBusqueda, "fechaInicial", $$v)},expression:"PedidoAfacturaBusqueda.fechaInicial"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Aquí se registra la fecha a partir de la cual queremos la búsqueda.")])])])],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"font-size":"12px"},attrs:{"dense":"","outlined":"","type":"date","label":"Fecha Final","rules":[_vm.$rules.required],"placeholder":" "},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-calendar",staticStyle:{"font-size":"18px"}})]},proxy:true}],null,true),model:{value:(_vm.PedidoAfacturaBusqueda.fechaFinal),callback:function ($$v) {_vm.$set(_vm.PedidoAfacturaBusqueda, "fechaFinal", $$v)},expression:"PedidoAfacturaBusqueda.fechaFinal"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Aquí se registra la fecha hasta donde queremos la búsqueda.")])])])],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"3","sm":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-combobox',_vm._g({ref:"RefCombClienteCosFac",staticStyle:{"font-size":"13px"},attrs:{"autocomplete":"off","dense":"","outlined":"","label":"cliente:","items":_vm.RCliente,"item-text":"codnom","no-filter":""},on:{"focus":_vm.cargarClienteBusqueda,"keyup":_vm.CbFilcliente},scopedSlots:_vm._u([{key:"item",fn:function(item){return [_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(item.item.codnom = (item.item.codigo) + " - " + (item.item.nombre.trim()) + " "))]),_c('span',{staticClass:"fontSize13"},[_c('b',[_vm._v(_vm._s(item.item.codigo))]),_vm._v(" - "+_vm._s(item.item.nombre.trim()))])]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('span',{staticClass:"fontSize13 colorNombre"},[_c('b',[_vm._v(_vm._s(item.codigo))]),_vm._v(" - "+_vm._s(item.nombre))])]}},{key:"prepend",fn:function(){return [_c('i',{staticClass:"fa fa-user-circle",staticStyle:{"font-size":"20px"}})]},proxy:true}],null,true),model:{value:(_vm.PedidoAfacturaBusqueda.cliente),callback:function ($$v) {_vm.$set(_vm.PedidoAfacturaBusqueda, "cliente", $$v)},expression:"PedidoAfacturaBusqueda.cliente"}},on))]}}])},[_c('i',{staticClass:"fa fa-info-circle",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Se selecciona el cliente que desea buscar.")])])])],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"1","sm":"1"}},[_c('v-btn',{attrs:{"small":"","color":"teal"},on:{"click":function($event){$event.preventDefault();return _vm.getPedidoAfacturalistFactCliente($event)}}},[_c('i',{staticClass:"fa fa-search",staticStyle:{"font-size":"20px"}})])],1)],1)],1),_c('v-col',{staticClass:" pa-0 ma-0 py-0 my-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-data-table',{staticClass:"RowSelectColor",attrs:{"item-key":"indx","headers":[
                { text: 'Fecha', sortable: false, value: 'nuevaFecha', width: 120, class: ['no-gutters black--text']  },
                { text: '# Secuencia', sortable: false, value: 'idpedido', width: 129, class: ['no-gutters black--text']  },
                { text: 'Cliente', sortable: false, value: 'cliente', width: 129, class: ['no-gutters black--text']  },
                { text: 'Valor', sortable: false, value: 'valorpedido', width: 'auto', width: 105, class: ['no-gutters black--text']  },
                { text: 'Acción',align:'center' ,sortable: false, value: 'action', width: '110', class: ['no-gutters black--text']  } ],"dense":"","items":_vm.VerPedidoAfacturalistFactCliente,"footer-props":_vm.arrayPropiedases,"items-per-page":_vm.PedidoAfacturaPaginator.itemsPerPage,"server-items-length":_vm.TotalRegistros},on:{"pagination":_vm.PedidoAfacturaCargarPaginacion},scopedSlots:_vm._u([{key:"item.nuevaFecha",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px","display":"none"}},[_vm._v(_vm._s(props.item.indx = _vm.VerPedidoAfacturalistFactCliente.indexOf(props.item)))]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.FormatoFecha(props.item.nuevaFecha)))])]}},{key:"item.idpedido",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(props.item.idpedido)+" ")])]}},{key:"item.cliente",fn:function(props){return [(props.item.cliente != null)?_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(props.item.cliente.nombre)+" ")]):_vm._e()]}},{key:"item.valorpedido",fn:function(props){return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.currencyFormatter(props.item.valorpedido)))])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"green"},on:{"click":function($event){$event.preventDefault();return _vm.VerPedidoAfacturaDatostabla(item)}}},on),[_c('i',{staticClass:"fa fa-check",staticStyle:{"font-size":"20px"}})])]}}],null,true)},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Pedido a Factura.")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"orange"},on:{"click":function($event){$event.preventDefault();return _vm.editarPedidoFactura(item)}}},on),[_c('i',{staticClass:"fa fa-pencil",staticStyle:{"font-size":"20px"}})])]}}],null,true)},[_c('i',{staticClass:"fa fa-pencil",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Editar registro.")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"blue"},on:{"click":function($event){$event.preventDefault();return _vm.ImprimirPedidoFactura(item)}}},on),[_c('i',{staticClass:"fa fa-print",staticStyle:{"font-size":"20px"}})])]}}],null,true)},[_c('i',{staticClass:"fa fa-trash-o",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Imprimir registro.")])])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"red lighten-1"},on:{"click":function($event){$event.preventDefault();return _vm.cerrarPedidoAfactura(item)}}},on),[_c('i',{staticClass:"fa fa-window-close-o",staticStyle:{"font-size":"20px"}})])]}}],null,true)},[_c('i',{staticClass:"fa fa-eye",staticStyle:{"font-size":"20px"},attrs:{"dark":"","dense":""}},[_c('span',[_vm._v(" Cerrar pedido.")])])])]}}])})],1)],1)],1),_c('v-snackbar',{staticStyle:{"margin-top":"20%"},attrs:{"color":_vm.aalert.color,"vertical":true,"timeout":120000,"top":"top"},model:{value:(_vm.aalert.estado),callback:function ($$v) {_vm.$set(_vm.aalert, "estado", $$v)},expression:"aalert.estado"}},[_vm._v(" "+_vm._s(_vm.aalert.nombre)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.aalert.estado = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }