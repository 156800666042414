<template>
  <div>{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
           :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase
            :titulo="'REPORTE ESTADO DE RESULTADO'"
            :descripcion="'IMPRIME REPORTE ESTADO DE RESULTADO'"
            :EstadoBtn="EstadoBtn"
            @abrirBarraTablaDinamica="abrirTabla"
            @MethodNuevo="newwnuevo"
            @MethodGuardar="save"
            @MethodEliminar="remove"
            @MethodAnular="anular"
            @MethodImprimir="imprimirList"
        />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn
                        small
                        color="yellow accent-4"
                        @click.prevent="actualiza_tb_ver"
                    >
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn
                    >
                    <v-btn
                        small
                        dark
                        color="deep-purple accent-2"
                        @click.prevent="notifi('Seguro que desea nuevo', 'n')"
                    >
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn
                    >
                  </v-btn-toggle>
                </center>
                <!--                <BarraBusqueda2-->
             
              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar
                    flat
                    color="#BDBDBD"
                    dense
                    style="border: 1px solid #000000;"
                >


                  <v-avatar tile size="45">
                    <img
                        src="@/assets/iconos_micromarket/custom-reports.png"
                        alt="Reporte"
                    >
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;">Reporte estado de resultado</span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
                    color="grey darken-4"
                    fab
                    small
                    @click="cerrarmodalcuenta"
                  >
                   <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn> -->
                </v-toolbar>

                <hr>
                
                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">
                        <!-- -----------------------------  desde  -->
                        <v-text-field
                            dense
                            outlined
                            type="date"
                            label="Fecha Inicial:"
                            :rules="[$rules.required]"
                            v-model="reporteProduccion.desde"
                            @blur="BlurDesde"
                            style="font-size: 13px;"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                         <!-------------------------------------------------- Producto -->
                   <v-combobox id="formdisabledInput" autocomplete="off" dense outlined label="Producto padre:" :items="Rproducto" style="font-size: 13px;"
                          item-text="productos.descripcion" @keyup="CbFilProducto" v-model="reporteProduccion.unidadesProductos"
                          @blur="SelctCbProduc" @focus="CargarRproducto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>
                          <template v-slot:selection="{ attrs, item, parent, selected}">  
                         <span v-if="item.hasOwnProperty('productos') && item.hasOwnProperty('unidades')"  >
                          <span class="black--text" >{{item.productos.descripcion}} - <b> {{item.unidades.descripcion}}</b></span>
                        </span> 
                        </template>

                          <template slot="item" slot-scope="item">
                            <span >{{item.item.productos.descripcion}} - <b> {{item.item.unidades.descripcion}}</b></span>
                          </template>
                          
                        </v-combobox>


                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col  cols="12" md="6" sm="6">
                        <!-- -----------------------------  hasta  -->
                        <v-text-field
                            dense
                            outlined
                            type="date"
                            label="Fecha final:"
                            :rules="[$rules.required]"
                            v-model="reporteProduccion.hasta"
                            @blur="BlurHasta"
                            style="font-size: 13px;"
                        >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <VueAutonumeric label="" :rules="[$rules.porciento]" outlined dense
                    v-model="reporteProduccion.porciento">
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-percent"></i>
                    </template>
                  </VueAutonumeric>



                      </v-col>
                      <!-- fn columna 2 -->


                    </v-row>


                  </v-form>
                </v-container>

                <hr>

<v-toolbar
          flat
          color="#BDBDBD"
          dense
          style="border: 1px solid #000000;"
          class="d-flex justify-center"
      >

        <v-btn-toggle dense rounded>
         
          <v-btn
              color="light-blue accent-2"
              target="_blank"
              @click.prevent="ReporteImprimir"

          >
            <i style="font-size: 28px;" class="fa fa-print"> </i>

            <span style="color:#FFFFFF;">Printer </span>
          </v-btn>


          <v-btn color="green darken-3"
                 @click.prevent="ReporteExel"
          >
            <i style="font-size: 28px;" class="fa fa-file-excel-o"> </i>
            <span style="color:#FFFFFF;">Excel </span>
          </v-btn
          >
       
        </v-btn-toggle>
      </v-toolbar>
                <!-- <ModalReporteFiltros
                    @DataReporteBasico="DDataReporteBasico"
                    :Datos="reporteProduccion"
                /> -->

              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
               v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
        label="myAlert"
        v-model="acti_tb_data"
        persistent
        max-width="100%"
        max-height="100%"
        transition="fab-transition"
    >
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE</v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn
              color="grey darken-4"
              fab
              small
              @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                  v-model="search"
                  @keyup="filtro"
                  label="Buscar"
                  single-line
                  hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
                :headers="slotHtabla"
                dense
                :items="list"
                :search="search"
                @click:row="datostabla"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!------------otras funciones---------------->
    <v-snackbar
        v-model="aalert.estado"
        :color="aalert.color"
        style="margin-top: 20%;"
        :vertical="true"
        :timeout="120000"
        top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import {EventBus} from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import {currencyFormatter,FormatoFecha,HoraFormatter,HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
import VueAutonumeric from "@/components/AutoNumericNuevo";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros,
    VueAutonumeric

  },
  created() {
 
  },
  mounted() {

  },

  data: () => ({
    Rpropietario: [],
    RSucursal: [],
    RCaja: [],
    ListaTiposClientes: [],
    Rproducto:[],
    windowSize: {width: "auto", height: "auto"},

    reporteProduccion: {
      desde: fechaNueva(),
      hasta: fechaNueva(),
      unidadesProductos:null,
      porciento:0,
      // nombre: 'REPORTE VOUCHER TARJETAS DE CREDITO',
      // UrlImprimir: '/reportes-pv/ImprimirListVoucherTarjetasCredito',
      // UrlExel: '/reportes-pv/ExcelListVoucherTarjetasCredito',
      // UrlCorreo: '/reportes-pv/CorreoListVoucherTarjetasCredito',
    },


    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },
    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,
    headers: [{text: "Tipo Clientes", value: "descripcion"}],
    slotlist: [
      {dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:"},
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      {dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo"},
    ],
    slotHtabla: [
      {text: "Código", value: "codigo"},
      {text: "Descripcion", value: "descripcion"}
    ],
    list: [],
    search: "",
    acti_tb_data: false,
  
    aalert: {
      estado: false,
      color: null
    },

  }),
  methods: {
    currencyFormatter,FormatoFecha,HoraFormatter,
    onResize(e) {
     // this.windowSize = e
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    datostabla(e) {
    },
    save() {},
    remove() {},
    eliminar() {
    },
    anular() {
    },
    imprimirList() {
    },
    newwnuevo() {

    },


BlurDesde(){

if(this.reporteProduccion.desde.toString().length==0){
  this.reporteProduccion.desde=this.fechaNueva()
}

 },

 BlurHasta(){

if(this.reporteProduccion.hasta.toString().length==0){
  this.reporteProduccion.hasta=this.fechaNueva()
}

 },


ReporteImprimir(){
  EventBus.$emit("loading", true);
var desde=this.reporteProduccion.desde
var hasta=this.reporteProduccion.hasta
var where=` fv.fecha >='${desde}' and fv.fecha <= '${hasta}'`
var prod=""
if(this.reporteProduccion.unidadesProductos!=null){
  where+=` and p.codigo=${this.reporteProduccion.unidadesProductos.unidadesProductosPK.producto}`
  prod=`${this.reporteProduccion.unidadesProductos.productos.descripcion} - ${this.reporteProduccion.unidadesProductos.unidades.descripcion}`
}

//var inform=`DESDE: ${this.FormatoFecha(desde)}      HASTA:  ${this.FormatoFecha(hasta)}`
  
var obj={
  fechaini:desde, 
  fechafin:hasta , 
  formula:prod,
  gasto:this.reporteProduccion.porciento,
  usuario:this.$store.getters.GetdatosInfUsu.userid,
  where:where
 }
 
this.$axios.post(this.$hostname + this.$hName +'/services/produccionReal/reporteEstadoResultado/', JSON.parse(JSON.stringify(obj)))
.then(res => {

/*let pdfWindow = window.open("Reporte de Estado Resultado")
pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
    encodeURI(res.data) + "'></embed>") */

    var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
      EventBus.$emit("openPDF",PDF ,'Reporte de Estado Resultado');
    EventBus.$emit("loading", false); 
      })
      .catch(error => {
            this.Reparaloading = false
             this.alerta("Reporte no Impreso(" + error + ")", "error")
            EventBus.$emit("loading", false); 
          });

},


ReporteExel(){
  EventBus.$emit("loading", true);
var desde=this.reporteProduccion.desde
var hasta=this.reporteProduccion.hasta
var where=` fv.fecha >='${desde}' and fv.fecha <= '${hasta}'`
var prod=""
if(this.reporteProduccion.unidadesProductos!=null){
  where+=` and p.codigo=${this.reporteProduccion.unidadesProductos.unidadesProductosPK.producto}`
  prod=`${this.reporteProduccion.unidadesProductos.productos.descripcion} - ${this.reporteProduccion.unidadesProductos.unidades.descripcion}`
}

//var inform=`DESDE: ${this.FormatoFecha(desde)}      HASTA:  ${this.FormatoFecha(hasta)}`
  
var obj={
  tipo:'excel',
  fechaini:desde, 
  fechafin:hasta , 
  formula:prod,
  gasto:this.reporteProduccion.porciento,
  usuario:this.$store.getters.GetdatosInfUsu.userid,
  where:where
 }
 
this.$axios.post(this.$hostname + this.$hName +'/services/produccionReal/reporteEstadoResultado/', JSON.parse(JSON.stringify(obj)))
.then(res => {
  EventBus.$emit("loading", false);
  var _this = this;
  setTimeout(function () {
              _this.verExel(res.data)
            }, 2000);
         
      })
      .catch(error => {
            this.Reparaloading = false
             this.alerta("Reporte no Impreso(" + error + ")", "error")
            EventBus.$emit("loading", false); 
          });

},

verExel(e) {
/*if (this.ExelGenerado == true) {
  var contentType = 'application/vnd-xls';
}

if (this.ExelGenerado == false) {
  var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
}*/

var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
var blob1 = this.b64toBlob(e, contentType);
var blobUrl1 = URL.createObjectURL(blob1);
let anchor = document.createElement('a');
anchor.href = blobUrl1;
anchor.download = 'ESTADO DE RESULTADO';
anchor.click();
URL.revokeObjectURL(blobUrl1);

},

b64toBlob(b64Data, contentType, sliceSize) {
contentType = contentType || '';
sliceSize = sliceSize || 512;

var byteCharacters = atob(b64Data);
var byteArrays = [];

for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  var slice = byteCharacters.slice(offset, offset + sliceSize);

  var byteNumbers = new Array(slice.length);
  for (var i = 0; i < slice.length; i++) {
    byteNumbers[i] = slice.charCodeAt(i);
  }

  var byteArray = new Uint8Array(byteNumbers);

  byteArrays.push(byteArray);
}

var blob = new Blob(byteArrays, {type: contentType});
return blob;
},






CargarRproducto() {

  var obj=`/unidades-productos/a/false/buscar`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });

/*var obj=`/services/producto/a/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{
this.Rproducto=res.data,
console.log(res.data)
})*/

},




CbFilProducto(e) {
if (e.target.value.length >= 2) {
//var obj=`/services/unidades-productos/${e.target.value}/false/buscar/${this.$store.getters.GetdatosInfUsu.userid}/`

var obj=`/unidades-productos/${e.target.value}/false/buscar`
      this.$axios.get(this.$hostname +this.$hName +obj,
      {headers:this.$store.getters.GetheadersAxios})
     .then(res => {this.Rproducto = res.data });

/*var obj=`/services/producto/${e.target.value}/false/buscar`
this.$axios.get(this.$hostname+this.$hName+obj)
.then(res=>{this.Rproducto = res.data})*/
}

},


SelctCbProduc() {

var _this = this;
    setTimeout(function () {
      if (_this.reporteProduccion.unidadesProductos == null) {
        _this.reporteProduccion.unidadesProductos= "";
      }

      if (!_this.reporteProduccion.unidadesProductos.hasOwnProperty('productos')) {
        _this.reporteProduccion.unidadesProductos = "";
        _this.reporteProduccion.unidadesProductos = null;
        return;
      }
    }, 300);
},


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
            (this.aalert.color = "success"),
            (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
            (this.aalert.color = "error"),
            (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },


    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
    },
    DDataReporteBasico(e) {
    },
  },

  computed:{

ActDescBtn(){ 
  this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 

}
}


};
</script>

<style></style>
