<template>
  <div id="ResponsiveCss">{{ActDescBtn}}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Produción de combos'" :descripcion="'Produción de combos'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodAplicarProducion="OpenAplicarProducion" />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->

                <center>
                  <!-- <v-btn-toggle></v-btn-toggle> -->
                  <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver" width="95%">
                    <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                  </v-btn>


                </center>
                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaLimitePlus :PropsNombre="'Produción de combos'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por producto'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'codigo' },
                 /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/]" :ContListSlot="[
                  {Text:'Productos',NomValue:'descripcion',  Ctsl:1, Type:0, State:true},
                  ]" />

                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">
            <div class="px-2">


              <v-card color="grey lighten-3">
                <div class="px-2">



                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="form.ProducionCombos.codigo" style="display:none;" />
                    <v-row>
                      <!-- columna 1 ------------------------------>
                      <v-col cols="12" md="12" sm="12">

                        <!-------------------------------------------------- Producto -->
                        <v-combobox autocomplete="off" dense outlined label="Productos:" :items="Rproducto"
                          item-text="codProDesc" @keyup="CbFilProducto" :rules="[$rules.required]"
                          v-model="form.ProducionCombos.producto" @blur="SelctCbProduc" @focus="CargarRproducto"
                          :disabled="this.form.ProducionCombos.codigo>0"
                          id="formdisabledInput" no-filter>
                          
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>
                        </v-combobox>



                      </v-col>
                      <!-- fn columna 1 ------------------------------>

                    </v-row>
                  </v-form>

                </div>

              </v-card>



              <!--------------------------------------------------- tb add producot  -->
              <v-col cols="12" md="12" sm="12">

                <!-- Tabla De producto  ------------------------------>
                <TablaProductoInventario ref="refTablaInventario" :ListProd="ListProd" :EntradaAlmacen="almacen"
                  :PropsProduccionCombos="true" @DataTotalList="DDataTotalList" />
                <!-- fn Tabla De producto ------------------------------>










              </v-col>
              <!--------------------------------------------------- fn tb add producot -->

            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">


            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-------------------------------------------------------- tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt=true">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.producto" @keyup.enter="CargarListTb" label="Búsqueda por producto"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>

              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'codigo', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Productos', value: 'descripcion', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Nombre Corto', value: 'descripcioncorta', sortable:false, class: ['no-gutters black--text'] },
              // { text: 'Existencia', value: 'existencias', sortable:false, class: ['no-gutters black--text'] },
              { text: 'Fabricante', value: 'fabricante.nombre',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Departamento', value: 'departamento.descripcion',sortable:false, class: ['no-gutters black--text'] },
             /* { text: 'Existencia minima', value: 'existenciaminima',sortable:false, class: ['no-gutters black--text'] },
              { text: 'Existencia maxima', value: 'existenciamaxima',sortable:false, class: ['no-gutters black--text'] }*/
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.producto" @click:row="datostabla" :server-items-length="TbTotalItem"
              :footer-props="arrayPropiedases">

            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->


    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec=='anularCombo'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->





    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->









    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE PEDIDO ALMACEN</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>

              <v-col cols="12" md="6" sm="6">


                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta ">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">
      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-- ------------------------------------------------------ Seleccionar impresora -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogAplicarProducion" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b> APLICAR PRODUCCIÓN </b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click.prevent="CloseAplicarProducion()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>
          <v-form ref="formAplicarProducto" v-model="valid" lazy-validation>
            <v-row>
              <!-- -----------------------------  columna-1 -->
              <v-col cols="12" md="6" sm="6">
                <v-text-field v-if="SelecAplicarProducto.producto!=null" label="Producto :" id="formdisabledInput"
                  disabled dense outlined v-model="SelecAplicarProducto.producto.productos.descripcion"
                  autocomplete="off" :rules="[$rules.required]" style="font-size:13px;">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                  </template>
                </v-text-field>

                <!------------------------------------------ Documentos -->
                <v-combobox autocomplete="off" dense outlined label="Documento de entrada :" :items="RDocumentoEntrada"
                  item-text="descripcion" :rules="[$rules.required]" v-model="SelecAplicarProducto.documentoEntrada"
                  @blur="SelctCbAplicarDocumentoEntrada" @focus="cargarDocumentoEntrada" style="font-size:13px;">
                  <template v-slot:prepend>
                    <i style="font-size: 18px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>


                <!-- ----------------------------- Cantidad -->
                <v-text-field type="number" label="Cantidad:" dense outlined :rules="[$rules.required]"
                  v-model.number="SelecAplicarProducto.cantidad">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-sort-amount-asc"></i>
                  </template>
                </v-text-field>

              </v-col>
              <!-- ----------------------------- fn columna-1 -->

              <!-- -----------------------------  columna-2 -->
              <v-col cols="12" md="6" sm="6">
                <v-combobox v-model="SelecAplicarProducto.almacen" label="Almacén" outlined dense autocomplete="off"
                  :items="Ralmacen" :rules="[$rules.required]" item-text="descripcion" @blur="SelctCbAplicarAlmacen"
                  @focus="CargarRalmacen" style="font-size:13px;">
                  <template v-slot:prepend>
                    <v-img height="18px" width="18px" src="../../assets/warehouse.png"></v-img>
                  </template>
                </v-combobox>

                <!------------------------------------------ Documentos -->
                <v-combobox autocomplete="off" dense outlined label="Documento de salida:" :items="RDocumentoSalida"
                  item-text="descripcion" :rules="[$rules.required]" v-model="SelecAplicarProducto.documentoSalida"
                  @blur="SelctCbAplicarDocumentoSalida" @focus="cargarDocumentoSalida" style="font-size:13px;">
                  <template v-slot:prepend>
                    <i style="font-size: 18px;" class="fa fa-book"></i>
                  </template>
                </v-combobox>

                <!-- -----------------------------  precio  -->
                <AutoNumeric label="Precio :" autocomplete="off" style="font-size: 13px;"
                  :rules="[$rules.required, $rules.NumNocero]" outlined dense v-model="SelecAplicarProducto.precio">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>
                </AutoNumeric>


              </v-col>
              <!-- ----------------------------- fn columna-2 -->

            </v-row>
          </v-form>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle rounded class="" dense>
            <v-btn color="blue lighten-2" @click.prevent="AplicarProducion">
              <i style="font-size: 28px;" class="fa fa-check-circle"> </i>
              Aplicar Produción
            </v-btn>
          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-------------------------------------------------------- fn Seleccionar impresora -->


    <!-- fn Modales ----------------------------------------------------------------------------------------------->

  </div>

</template>

<script>
import AgregarProducto from "../../CuentaXPagar/UtilidadComponents/AgregarProducto";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import CrearProducto from "../../CuentaXPagar/UtilidadComponents/CrearProducto";
import AutoNumeric from "../../components/AutoNumericNuevo";
import { EventBus } from "../../event-bus";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import { currencyFormatter, FormatoFecha, HoraFormatter } from "@/js/Funciones.js";
import TablaProductoInventario from "../../Inventario/UtilidadComponents/TablaProductoInventario";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

export default {
  components: {
    AutoNumeric,
    BarraBusqueda2,
    CrearProducto,
    AgregarProducto,
    ContenidoBase,
    BarraDinamicaLimitePlus,
    ModalReporteFiltros,
    ModalReporteBasico,
    TablaProductoInventario
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    EventBus.$on("emitProducionCombos", this.datostabla);
    // EventBus.$on("INVProducionCombosNew", this.new);
    // EventBus.$on("INVProducionCombosSave", this.save);
    // EventBus.$on("INVProducionCombosRemove", this.remove);
    // EventBus.$on("INVProducionCombosAnular", this.anular);
    // EventBus.$on("INVProducionCombosPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal()
    this.$refs.refTablaInventario.borradoReal = this.BorradoProductoTablaProducto
  },

  created() {
    var json = {
      titulo: "Produción de combos",
      descripcion: "Produción de combos",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: false,
      btnBarraDi: false,

      accion: [
        "INVProducionCombosNew",
        "INVProducionCombosSave",
        "INVProducionCombosRemove",
        "INVProducionCombosAnular",
        "INVProducionCombosPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {



      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,

      BarraTablaDinamicaAprir: false,

      dialogAplicarProducion: false,
      RDocumentoEntrada: [],
      RDocumentoSalida: [],
      Rdepartamento: [],
      Ralmacen: [],
      Rproducto: [],



      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'PRODUCIÓN DE COMBOS',
        UrlImprimir: '/combo/imprimir',
        UrlExel: '/combo/excel',
        UrlCorreo: '/combo/correo',
        codigo: 0,
      },


      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE PRODUCIÓN DE COMBOS',
        UrlImprimir: '/combo/printListado',
        UrlExel: '/combo/excelList',
        UrlCorreo: '/combo/correoList',
        desde: new Date().toISOString().substr(0, 10),
        hasta: new Date().toISOString().substr(0, 10),
      },

      aalert: {
        estado: false,
        color: null
      },


      dialogFiltros: false,
      Modallfiltro: {
        producto: null,
      },

      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },



      search: '',
      ObjTemp: null,

      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
      ListProd: [],
      almacen: null,


      form: {


        ProducionCombos: {

          codigo: 0,
          producto: null,
          prodAplicado: false
        }

      },

      SelecAplicarProducto:
      {
        producto: null,
        almacen: null,
        documentoEntrada: null,
        documentoSalida: null,
        precio: 0,
        cantidad: 0
      },


      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        AplicarProducion: true
      },


      TipoProveedor: {
        codigo: 0,
        nombre: null,
        porciento: 0
      },

      rules: {
        required: value => !!value || "Required.",
        edad: [
          val => val > 10 || `Edad debe ser mayor a 10`,
          val => val < 70 || `Edad debe ser menor a 120`
        ],
        porciento: value => value <= 100 || `Porciento debe ser  menor a 100`,
        counter: value => (value && value.length <= 50) || "Max 20 characters",
        RTel: value => value != 10 || `telefono debe tener 10 digitos`
        // email: value => {
        //   const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //   return pattern.test(value) || 'Invalid e-mail.'
        // },
      },

      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],

      slotHtabla: [
        // {
        //   //text: 'Dessert (100g serving)',
        //   align: 'left',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: "Código", value: "codigo" },
        { text: "Descripcion", value: "descripcion" },
        { text: "Porciento", value: "porciento" }
      ],

      list: [],
      search: "",
      acti_tb_data: false,



      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      BarraPrimariaAbrir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,

    onResize(e) {
      //this.windowSize=e
    },

    CbFilProducto(e) {
      if (e.target.value.length >= 0) {

        this.$axios
          .get(this.$hostname +
            this.$hName +
            "/unidades-productos/" + (e.target.value === "" ? "A" : e.target.value)+ "/false/buscar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.Rproducto = res.data });
      }
    },

    SelctCbProduc() {
      if (this.form.ProducionCombos.producto == null) {
        this.form.ProducionCombos.producto = "";
      }

      if (this.form.ProducionCombos.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.form.ProducionCombos.producto = "";
        return;
      }

    },

    CargarRproducto() {
      this.$axios.get(this.$hostname + this.$hName +
        "/unidades-productos/*/false/buscar",
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => { this.Rproducto = res.data });
    },

    save() {

      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false
          EventBus.$emit("loading", true);

          var ObjProRow = this.ListProd.filter((ee) => ee.producto != null);
          if (ObjProRow.length == 0) {
            this.alerta(error, 'Debe de Agregar al menos 1 producto a la lista')
            return;
          }

          var ListProdCom = []

          if (this.form.ProducionCombos.prodAplicado == false) {
            ObjProRow.forEach(element => {
              var oobj = {
                unidadProductos: JSON.parse(JSON.stringify(this.form.ProducionCombos.producto)),
                unidadProductosComponente: JSON.parse(JSON.stringify(element.unidadProducto)),
                almacen: JSON.parse(JSON.stringify(element.almacen)),
                cantidad: JSON.parse(JSON.stringify(element.cantidad)),
                fechaCreacion: new Date().toISOString().substr(0, 10),
                borrado: false,
              }
              ListProdCom.push(oobj)
            });
          } else {
            //var ObjProRow1 = this.ListProd.filter((ee) => ee.producto != null && !ee.hasOwnProperty('productoComponentePK'));
            var ObjProRow1 = this.ListProd.filter((ee) => ee.producto != null);
            ObjProRow1.forEach(element => {
              var oobj = {
                unidadProductos: JSON.parse(JSON.stringify(this.form.ProducionCombos.producto)),
                unidadProductosComponente: JSON.parse(JSON.stringify(element.unidadProducto)),
                almacen: JSON.parse(JSON.stringify(element.almacen)),
                cantidad: JSON.parse(JSON.stringify(element.cantidad)),
                fechaCreacion: new Date().toISOString().substr(0, 10),
                borrado: false,
              }
              ListProdCom.push(oobj)
            });

          }

          /*
          if (!element.hasOwnProperty('productoComponentePK')) {
              obj.productoComponentePK.componente = 0
            } else { 
              obj.productoComponentePK = element.productoComponentePK
            }
          */
   
          this.$axios.post(this.$hostname + this.$hName + '/combo/salvarFormula',
            ListProdCom, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { this.alerta("Dato guardado correctamente", "bien") })
            .catch(error => { this.alerta(error, 'error') })

          /*  this.$axios
              .post(
                this.$hostname + this.$hName + "/services/TarjetasCredito/save",
                JSON.parse(JSON.stringify(this.TipoProveedor))
              )
              .then(res => this.alerta("Dato guardado correctamente", "bien"))
              .catch(error => this.alerta(error, "error"));*/

        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    NotifMessage() {
      if (this.form.ProducionCombos.codigo != 0 && this.form.ProducionCombos.codigo != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea Anular este registro?",
          Exec: "anularCombo"
        }

        this.Exec_notif(notif)


      } else { this.alerta("Seleccione un Pedido para Anular", "error") }


    },

    Exec_notif(e) {
      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

    },


    eliminar() {

    },

    anular() {

      if (this.form.ProducionCombos.codigo != 0 && this.form.ProducionCombos.codigo != null) {
        EventBus.$emit("loading", true);
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/services/Produccioncombo/remove",
              JSON.parse(JSON.stringify(this.form.ProducionCombos))
            )
            .then(res => this.alerta("Dato eliminado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      }

    },
    imprimirList() {

      if (this.form.ProducionCombos.codigo > 0) {
        this.datosImprecion.codigo = this.form.ProducionCombos.codigo

        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },

    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },


    newwnuevo() {
      this.BarraTablaDinamicaAprir = false

      this.form = {
        ProducionCombos: {
          codigo: 0,
          producto: null,
          prodAplicado: false
        }
      }

      this.ListProd = []
      this.CloseAplicarProducion()

      this.TipoProveedor = {
        codigo: 0,
        nombre: null,
        porciento: 0
      };

      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },

    datostabla(e) {
      this.ListProd = []
      this.form.ProducionCombos.producto = null
      this.form.ProducionCombos.codigo = JSON.parse(JSON.stringify(e.codigo))

      this.$axios.get(this.$hostname + this.$hName +
        "/combo/findListProductoComponente/" + e.codigo,
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          console.log(res.data)
          if (res.data.listProCom.length > 0) {
            this.form.ProducionCombos.producto = res.data.listProCom[0].unidadProductos
            this.form.ProducionCombos.prodAplicado = res.data.prodAplicado
            // this.ListProd = JSON.parse(JSON.stringify(res.data))
            res.data.listProCom.forEach(element => {
              var obj = {}
              //JSON.parse(JSON.stringify(element))
              obj.almacen = JSON.parse(JSON.stringify(element.almacen))
              obj.cantidad = JSON.parse(JSON.stringify(element.cantidad))
              obj.producto = JSON.parse(JSON.stringify(element.unidadProductosComponente.productos))
              obj.unidad = JSON.parse(JSON.stringify(element.unidadProductosComponente.unidades))
              obj.unidadProducto = JSON.parse(JSON.stringify(element.unidadProductosComponente))
              obj.costo = JSON.parse(JSON.stringify(element.unidadProductosComponente.costo))
              obj.valor = element.unidadProductos.costo * element.cantidad
              obj.impuesto = 0
              obj.productoComponentePK = JSON.parse(JSON.stringify(element.productoComponentePK))
              obj.prodAplicado = res.data.prodAplicado
              obj.propsDesProdUnid=element.unidadProductosComponente.productos.descripcion
              obj.unidadProducto=JSON.parse(JSON.stringify(element.unidadProductos))
              // this.$refs.RefTablaProducInvent.getUnidadesProductosAddList(e.productos.codigo,e.unidades.codigo,detalle);
               this.ListProd.push(obj)     
            });

            // this.ListItems,,
            // me quede buscando el codigo que identifique producioncombox
            var _this = this;
        setTimeout(function () {
          _this.$refs.refTablaInventario.calcularTotales()
        }, 500);

            
          }


        });








      //  this.TipoProveedor = e;
      this.acti_tb_data = false;
      //console.log(e);
    },


    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = new Date().toISOString().substr(0, 10)
      }

    },


    ReportBlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = new Date().toISOString().substr(0, 10)
      }

    },



    OpenAplicarProducion() {

      this.$axios.get(this.$hostname + this.$hName + '/combo/verificarPeriodosFiscales',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          if (res.data.length == 0) {
            this.dialogAplicarProducion = true
            this.SelecAplicarProducto.producto = JSON.parse(JSON.stringify(this.form.ProducionCombos.producto))
          }

          if (res.data.length > 0) {
            this.alerta(error, res.data)
          }

        });

    },


    AplicarProducion() {

      if (this.$refs.formAplicarProducto.validate()) {
        //if (this.ejecucion == true) { }

        var ObjProRow = this.ListProd.filter((ee) => ee.producto != null);

        if (ObjProRow.length == 0) {
          this.alerta(error, 'Debe de Agregar al menos 1 producto a la lista')
          return;
        }
        EventBus.$emit("loading", true);

        var ListProdCom = []
        ObjProRow.forEach(element => {
          var oobj = {
            unidadProductos: JSON.parse(JSON.stringify(this.form.ProducionCombos.producto)),
            unidadProductosComponente: JSON.parse(JSON.stringify(element.unidadProducto)),
            almacen: JSON.parse(JSON.stringify(element.almacen)),
            cantidad: JSON.parse(JSON.stringify(element.cantidad)),
            fechaCreacion: new Date().toISOString().substr(0, 10),
            borrado: false,
          }

          /*if (this.form.ProducionCombos.codigo == 0) { 
            oobj.productoComponentePK
          }*/
          /* productoComponentePK:
           componente: 39
           producto: 23*/

          ListProdCom.push(oobj)
        });

        /**/
        var obj = this.SelecAplicarProducto
        obj.componentes = ListProdCom
        this.$axios.post(this.$hostname + this.$hName + '/combo/aplicarFormula',
          obj, { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            console.log("salvo");
            this.alerta("Dato guardado correctamente", "bien")
          })
          .catch(error => {
            console.log("error");
            this.alerta(error, 'error')
          })
        } else {
        console.log("error 2");
        this.alerta("los campos en rojo son Requeridos", "error");
      }


    },


    CloseAplicarProducion() {

      this.dialogAplicarProducion = false
      this.SelecAplicarProducto =
      {
        producto: null,
        almacen: null,
        documentoEntrada: null,
        documentoSalida: null,
        precio: 0,
        cantidad: 0
      }

    },



    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos", {
          headers: this.$store.getters.GetheadersAxios
        })
        .then(res => {
          this.Ralmacen = res.data;
        });
    },

    SelctCbAplicarAlmacen() {

      if (this.SelecAplicarProducto.almacen == null) {

        this.SelecAplicarProducto.almacen = ''
      }

      if (this.SelecAplicarProducto.almacen.codigo == undefined) {

        this.alerta("Seleccione un Almacen", "error");
        this.SelecAplicarProducto.almacen = ''
        return

      }

    },


    cargarDocumentoEntrada() {

      this.$axios.get(this.$hostname + this.$hName + '/documento-inv/entradas', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoEntrada = res.data))


    },

    cargarDocumentoSalida() {

      this.$axios.get(this.$hostname + this.$hName + '/documento-inv/salida', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumentoSalida = res.data))


    },
    SelctCbAplicarDocumentoEntrada() {
      if (this.SelecAplicarProducto.documentoEntrada == null) {

        this.SelecAplicarProducto.documentoEntrada = ''
      }

      if (this.SelecAplicarProducto.documentoEntrada.codigo == undefined) {

        this.alerta("Seleccione un documento de entrada", "error");
        this.SelecAplicarProducto.documentoEntrada = ''
        return

      }

    },


    SelctCbAplicarDocumentoSalida() {
      if (this.SelecAplicarProducto.documentoSalida == null) {

        this.SelecAplicarProducto.documentoSalida = ''
      }

      if (this.SelecAplicarProducto.documentoSalida.codigo == undefined) {

        this.alerta("Seleccione un documento de Salida", "error");
        this.SelecAplicarProducto.documentoSalida = ''
        return

      }

    },

    DDataTotalList(e) {
      this.TotalProd = JSON.parse(JSON.stringify(e))
    },
    BorradoProductoTablaProducto(e) {
      if (e != null) {
        if (e.hasOwnProperty('productoComponentePK') && e.hasOwnProperty('prodAplicado') && e.prodAplicado == true) {
          //webser  
          var prod = e.productoComponentePK.producto
          var comp = e.productoComponentePK.componente
          this.$axios.get(this.$hostname + this.$hName + '/combo/BorradoComboProductoComponent/' + prod + "/" + comp, { headers: this.$store.getters.GetheadersAxios })
            .then(res => { })
        }
      }
    },


    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.almacen = res.data
        })
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }
    },

    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },


    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.Modallfiltro = {
        producto: null
      }


      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.producto != null && this.Modallfiltro.producto.toString().length == 0) { this.Modallfiltro.producto = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.producto)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.producto = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.producto
      this.$axios.get(this.$hostname + this.$hName + '/combo/findListComboProductoPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem })
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },


    async filtro() { },


    // abrirTabla2() {
    //   // console.log("entro");

    //   return !this.abrirTablaDinamica
    // }
  },






  computed: {

    ActDescBtn() {

      if (this.form.ProducionCombos.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          AplicarProducion: true
        }
      }

      if (this.form.ProducionCombos.codigo <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          AplicarProducion: false
        }
      }





    }

  }


};
</script>

<style>

</style>
