<template>
  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">{{ ActDescBtn }}
    <v-card :height="windowSize.height + 110 + 'px'">

      <!------------otras funciones---------------->
      <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
        :timeout="120000" top="top">
        {{ aalert.nombre }}
        <v-btn dark text @click="aalert.estado = false">Close</v-btn>

      </v-snackbar>

      <v-toolbar dark color="#dd4b39" dense>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">

            <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Configuración
              general</v-toolbar-title>

          </template>
          <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Configuración general:
            </span>Configuración general</i>

        </v-tooltip>

        <v-spacer></v-spacer>

        <v-card-actions>


          <v-btn-toggle>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn id="boton-mini" v-on="on" @click.prevent="saveConfg" color="success" small dark>
                  SALVAR
                  <i style="font-size: 20px;" class="fa fa-check-circle"></i><!-- fab x-small dark  -->
                </v-btn>
              </template>
              <span>SALVAR: Configuración</span>
            </v-tooltip>

          </v-btn-toggle>

          <v-btn color="grey darken-4" fab small @click="CloseDialogConfigGeneral">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>



        </v-card-actions>

      </v-toolbar>
      <div style="border: 1px solid #000000;">
        <!-- configuracion 1 -->
        <v-form ref="formConfig" v-model="valid" lazy-validation>
          <v-col cols="12" md="12" sm="12">

            <v-row>

              <v-col cols="12" md="4" sm="4" class=" py-0 my-0">
                <v-text-field style="font-size:13px;" v-model="GeneralConfiguracion.nombre" :rules="[$rules.required]"
                  autocomplete="off" label="* Nombre:" required outlined dense>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-home"></i>
                  </template>
                </v-text-field>

                <!-- ----------------------------- direccion -->
                <v-textarea style="font-size:13px;" autocomplete="off" label="* Direccion:" dense outlined
                  v-model="GeneralConfiguracion.direccion" :rules="[$rules.required]" rows="3">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                  </template>
                </v-textarea>

                <v-file-input dense outlined accept="image/png, image/jpeg, image/bmp" @change="ConvertirImg" ref="files"
                  id="foto" show-size counter small-chips v-model="cargaImg" :rules="[$rules.required]" multiple
                  prepend-icon="mdi-camera" label="Elegir Logo ">
                </v-file-input>

                <v-img max-height="200" style="border: 2px solid #000000;" :src="GeneralConfiguracion.logo"></v-img>
              </v-col>

              <v-col cols="12" md="4" sm="4" class=" py-0 my-0">

                <v-text-field v-if="false" label="* Ciudad:" dense outlined style="font-size:13px;" autocomplete="off"
                  v-model="GeneralConfiguracion.ciudad" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-map"></i>
                  </template>
                </v-text-field>

                <v-text-field label="teléfono:" dense outlined autocomplete="off" style="font-size:13px;" maxlength="13"
                  v-mask="'(###)###-####'" v-model="GeneralConfiguracion.telefono" :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-phone"></i>
                  </template>

                </v-text-field>

                <!-- -----------------------------  email -->
                <v-text-field label="E-mail:" style="font-size:13px;" dense outlined v-model="GeneralConfiguracion.email">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                  </template>
                </v-text-field>


                <!-- -----------------------------  Contraseña -->
                <v-text-field label="clave del E-mail:" autocomplete="off" type="password" dense outlined
                  v-model="GeneralConfiguracion.clave">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                  </template>
                </v-text-field>



                <v-text-field style="font-size:13px;" autocomplete="off" v-mask="'###-#####-#'" label="RNC:" dense
                  outlined maxlength="11" :rules="[$rules.RulesRnc]" v-model="GeneralConfiguracion.rnc">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                  </template>
                </v-text-field>

                <v-text-field style="font-size:13px;" v-model="GeneralConfiguracion.paginaweb" autocomplete="off"
                  label=" Página Web:" required outlined dense>
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-globe"></i>
                  </template>
                </v-text-field>

                <v-text-field label="Nombre de base de dato:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="GeneralConfiguracion.nombreBase">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>


                <v-combobox v-if="false" autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                  item-text="nombrecorto" :rules="[$rules.required]" v-model="GeneralConfiguracion.monedabase"
                  @blur="SelctCbMoneda" @focus="cargarMoneda">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-combobox>


              </v-col>

              <v-col cols="12" md="4" sm="4" class=" py-0 my-0">

                <v-combobox :items="RModFact" item-text="desc" @blur="SelctCbSelectModFact" label="Modelo de Facturación:"
                  :rules="[$rules.required]" @change="ChangeModFact" required outlined dense v-model="ModuloFactura">
                  <template v-slot:prepend>
                    <i style="font-size: 18px;" class="fa fa-money"></i>
                  </template>
                </v-combobox>

                <!-- ----------------------------- comentario -->
                <v-textarea style="font-size:13px;" :rows="4" autocomplete="off" label="comentario:" dense outlined
                  v-model="GeneralConfiguracion.comentario">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-commenting"></i>
                  </template>
                </v-textarea>
                <!-- Nombre:-->
                <v-text-field label="usuario:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="GeneralConfiguracion.usuario">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>

                </v-text-field>


                <!-- contraseña:-->
                <v-text-field label="clave:" autocomplete="off" type="password" dense outlined :rules="[$rules.required]"
                  v-model="GeneralConfiguracion.claveUser">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                  </template>
                </v-text-field>

                <!-- contraseña:-->
                <v-text-field label="Confirmación:" autocomplete="off" type="password" dense outlined
                  :rules="[$rules.required]" v-model="ContraseñaConfirmacion">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-unlock-alt"></i>
                  </template>
                </v-text-field>

                <v-text-field label="Nombre de usuario:" autocomplete="off" dense outlined :rules="[$rules.required]"
                  v-model="GeneralConfiguracion.nombreUsuario">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>

              </v-col>
            </v-row>
          </v-col>
          <!-- fn configuracion 1 -->

          <!--  configuracion 2 -->

          <v-col cols="12" md="12" sm="12" class=" py-0 my-0">
            <v-row class="d-flex justify-center">
              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.agecia" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.agecia == true" style="color:#000000; font-size:13px;">Agencia:
                    Si</strong>
                  <strong v-if="GeneralConfiguracion.agecia == false" style="color:#000000; font-size:13px;">Agencia:
                    No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.multimoneda" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.multimoneda == true"
                    style="color:#000000; font-size:13px;">Multimoneda: Si</strong>
                  <strong v-if="GeneralConfiguracion.multimoneda == false"
                    style="color:#000000; font-size:13px;">Multimoneda: No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.mayorista" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.mayorista == true" style="color:#000000; font-size:13px;">Usar
                    lista de precio: Si</strong>
                  <strong v-if="GeneralConfiguracion.mayorista == false" style="color:#000000; font-size:13px;">Usar
                    lista de precio: No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.estacion" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.estacion == true" style="color:#000000; font-size:13px;">Estación:
                    Si</strong>
                  <strong v-if="GeneralConfiguracion.estacion == false" style="color:#000000; font-size:13px;">Estación:
                    No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.clinica" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.clinica == true" style="color:#000000; font-size:13px;">Clinica:
                    Si</strong>
                  <strong v-if="GeneralConfiguracion.clinica == false" style="color:#000000; font-size:13px;">Clinica:
                    No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.pagare" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.pagare == true" style="color:#000000; font-size:13px;">Pagaré:
                    Si</strong>
                  <strong v-if="GeneralConfiguracion.pagare == false" style="color:#000000; font-size:13px;">Pagaré:
                    No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.impresoraFiscal" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.impresoraFiscal == true"
                    style="color:#000000; font-size:13px;">Impresora Fiscal: Si</strong>
                  <strong v-if="GeneralConfiguracion.impresoraFiscal == false"
                    style="color:#000000; font-size:13px;">Impresora Fiscal: No</strong>
                </template>
              </v-checkbox>

              <v-checkbox class="px-4" color="teal" label="" v-model="GeneralConfiguracion.micromarket" width="50">
                <template v-slot:label>
                  <strong v-if="GeneralConfiguracion.micromarket == true"
                    style="color:#000000; font-size:13px;">Micromarket: Si</strong>
                  <strong v-if="GeneralConfiguracion.micromarket == false"
                    style="color:#000000; font-size:13px;">Micromarket: No</strong>
                </template>
              </v-checkbox>


            </v-row>

          </v-col>
          <!--  configuracion 2 -->



          <!-- configuracion 3 -->
          <v-col v-show="false" cols="12" md="12" sm="12">
            <v-row>
              configuracion 2
            </v-row>
          </v-col>
          <!-- fn configuracion 3 -->
        </v-form>
      </div>




    </v-card>

  </v-col>
</template>

<script>
import { EventBus } from "../event-bus";

export default {
  mounted() {
    //console.log(this.windowSize)
    //console.log(this.$store.getters.GetwindowSize)
  },

  created() {


  },

  data: () => ({
    cargaImg: null,
    ContraseñaConfirmacion: null,
    ModuloFactura: null,
    GeneralConfiguracion: {
      nombre: null,
      direccion: null,
      logo: '',
      ciudad: null,
      telefono: null,
      email: null,
      clave: null,
      rnc: null,
      paginaweb: null,
      monedabase: null,
      comentario: '',
      claveUser: null,
      usuario: null,
      agecia: false,
      multimoneda: false,
      mayorista: false,
      estacion: false,
      clinica: false,
      pagare: false,
      impresoraFiscal: false,
      nombreUsuario: null,
      nombreBase: null,
      micromarket: false
    },
    Rmoneda: [],
    RModFact: [
      { codigo: 1, desc: 'Punto de ventas' },
      { codigo: 2, desc: 'Facturación Estandar' },
    ],

    windowSize: { width: "auto", height: "auto" },
    valid: true,
    aalert: {
      estado: false,
      color: null
    },

  }),

  props: {

  },

  methods: {
    saveConfg() {

      var header = {
        "Content-Type": 'application/json',
        Authorization: '',
        "X-TENANT-ID": ''
      }

      if (this.$refs.formConfig.validate()) {
        console.log(this.GeneralConfiguracion)
        var tenantUser = {
          user: {
            usuario: this.GeneralConfiguracion.usuario, cedula: this.GeneralConfiguracion.rnc, nombre: this.GeneralConfiguracion.nombreUsuario, direccion: this.GeneralConfiguracion.direccion,
            ciudad: 0, email: this.GeneralConfiguracion.email, clave: this.GeneralConfiguracion.claveUser, status: true,
            dashboard: this.GeneralConfiguracion.dashboard, sudo: true, micromarket: this.GeneralConfiguracion.micromarket, menuUsuarioList: [], sucursal: null,
            usuarioPermisoPvList: [],
          },
          tenant: { tenantId: this.GeneralConfiguracion.nombreBase, schema: this.GeneralConfiguracion.nombreBase },
          c: {
            empresa: 0, nombre: this.GeneralConfiguracion.nombre, direccion: this.GeneralConfiguracion.direccion, ciudad: 0,
            email: this.GeneralConfiguracion.email, rnc: this.GeneralConfiguracion.rnc, paginaweb: this.GeneralConfiguracion.paginaweb,
            telefono: this.GeneralConfiguracion.telefono, activarconsolidado: false, logo: this.GeneralConfiguracion.logo, multimoneda: this.GeneralConfiguracion.multimoneda,
            mayorista: this.GeneralConfiguracion.mayorista, estacion: this.GeneralConfiguracion.estacion, borrado: false,
            clinica: this.GeneralConfiguracion.clinica, pagare: this.GeneralConfiguracion.pagare, clave: this.GeneralConfiguracion.clave, impresoraFiscal: this.GeneralConfiguracion.impresoraFiscal
          }
        }
        console.log(tenantUser);
        EventBus.$emit("loading", true);
        this.$axios.post(this.$hostname + this.$hName + "/tenant/", tenantUser).
          then(res => {
            if (res.data) {
              header.Authorization = "Bearer " + res.data.token;
              header["X-TENANT-ID"] = this.GeneralConfiguracion.nombreBase;
              var c = {
                empresa: 0, nombre: this.GeneralConfiguracion.nombre, direccion: this.GeneralConfiguracion.direccion, ciudad: 0,
                email: this.GeneralConfiguracion.email, rnc: this.GeneralConfiguracion.rnc, paginaweb: this.GeneralConfiguracion.paginaweb,
                telefono: this.GeneralConfiguracion.telefono, activarconsolidado: false, logo: this.GeneralConfiguracion.logo, multimoneda: this.GeneralConfiguracion.multimoneda,
                mayorista: this.GeneralConfiguracion.mayorista, estacion: this.GeneralConfiguracion.estacion, borrado: false,
                clinica: this.GeneralConfiguracion.clinica, pagare: this.GeneralConfiguracion.pagare, clave: this.GeneralConfiguracion.clave, impresoraFiscal: this.GeneralConfiguracion.impresoraFiscal
              }
              console.log(header);
              this.$axios.post(this.$hostname + this.$hName + "/tenant/subirDato", c, { headers: header }).
                then(res => {
                  this.alerta("se guardo correctamente!", "bien")
                }).catch(
                  error => {
                    EventBus.$emit("loading", false);
                  }
                )
            }
          })
          .catch(error => {
            EventBus.$emit("loading", false);
            this.alerta(error, "error")
          });

      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }

    },

    CloseDialogConfigGeneral() {
      //this.OpenDialogConfigGeneral = false
      this.$emit("CloseConfig", false)
    },


    ConvertirImg(e) {

      var _this = this;

      // var filesSelected = document.getElementById("foto").files;
      var filesSelected = e
      if (filesSelected.length > 0) {
        var fileToLoad = filesSelected[0];

        var fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result; // <--- data: base64

          var newImage = document.createElement("img");
          newImage.src = srcData;
          _this.GeneralConfiguracion.logo = srcData;


          // document.getElementById("imgTest").innerHTML = newImage.outerHTML;
          // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
          // console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
        };
        fileReader.readAsDataURL(fileToLoad);
      } else {

        this.GeneralConfiguracion.logo = require("@/assets/img/Nofoto.png");

      }
    },

    cargarMoneda() {

      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))

    },


    SelctCbMoneda() {
      var _this = this;
      setTimeout(function () {
        if (_this.GeneralConfiguracion.monedabase == null) {
          _this.GeneralConfiguracion.monedabase = "";
        }

        if (!_this.GeneralConfiguracion.monedabase.hasOwnProperty('codigo')) {
          _this.GeneralConfiguracion.monedabase = "";
          _this.GeneralConfiguracion.monedabase = null;
          this.alerta("Seleccione una moneda", "error");
          return;
        }
      }, 300);



    },

    ChangeModFact(e) {

      this.GeneralConfiguracion.modelofacturacion = JSON.parse(JSON.stringify(e.codigo))

    },


    SelctCbSelectModFact() {


      var _this = this;
      setTimeout(function () {
        if (_this.ModuloFactura == null) {
          _this.ModuloFactura = "";
        }

        if (!_this.ModuloFactura.hasOwnProperty('codigo')) {
          _this.ModuloFactura = "";
          _this.ModuloFactura = null;
          _this.alerta("Seleccione un Modelo de Facturación", "error");
          return;
        }
      }, 300);
    },


    NewConfigGeneral() {
     /* this.GeneralConfiguracion = {
        nombre: '',
        direccion: '',
        logo: '',
        ciudad: '',
        telefono: '',
        email: '',
        clave: '',
        rnc: '',
        paginaweb: '',
        monedabase: null,
        comentario: '',
        claveUser: null,
        usuario: null,
        agecia: false,
        multimoneda: false,
        mayorista: false,
        estacion: false,
        clinica: false,
        pagare: false,
        impresoraFiscal: false,
        nombreUsuario: '',
        nombreBase: ''
      }*/



    this.cargaImg= null,
    this.ModuloFactura= null,
    this.GeneralConfiguracion= {
      nombre: null,
      direccion: null,
      logo: '',
      ciudad: null,
      telefono: null,
      email: null,
      clave: null,
      rnc: null,
      paginaweb: null,
      monedabase: null,
      comentario: '',
      claveUser: null,
      usuario: null,
      agecia: false,
      multimoneda: false,
      mayorista: false,
      estacion: false,
      clinica: false,
      pagare: false,
      impresoraFiscal: false,
      nombreUsuario: null,
      nombreBase: null,
      micromarket: false
    },

      EventBus.$emit("loading", false);
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.NewConfigGeneral()

      }
      //Prueba
      if (l == "bienbien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        EventBus.$emit("loading", false);
        // this.ejecucion = true;
      }

    },

  },
  computed: {
    ActDescBtn() {
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
    }
  },


}
</script>

<style></style>