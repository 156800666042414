<template>
  <div id="ResponsiveCss">{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Órdenes de Compras'"
          :descripcion="'Esta función permite realizar ordenes de compras a proveedores.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodCompraSugeridas="openOrdenSugerida()" @MethodProductoProveedores="ProductoProveedores" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <!--------------------- aqui va la otra tabla izquierda --------->
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"> <i style="font-size: 25px;"
                        class="fa fa-eye"> </i> ver</v-btn>
                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam = JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>
                </center>

                <!-- <v-navigation-drawer >  -->
                <BarraDinamicaLimitePlus :PropsNombre="'Órdenes de Compras'" PropsSearchNumber
                  :PropsSeachNombre="'Búsqueda por código'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                    { text: 'Código', value: 'codigo' },
                    /*{ text: 'Nombre', value: 'nombre' },
                     { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[
  { Text: 'Código', NomValue: 'codigo', Ctsl: 1, Type: 0, State: true },
  { Text: 'Fecha', NomValue: 'fecha', Ctsl: 1, Type: 1, State: true },
  { Text: 'Proveedor', NomValue: 'proveedor', Nv2: 'nombre', Ctsl: 2, Type: 0, State: true },
  { Text: 'Valor $', NomValue: 'valor', Ctsl: 1, Type: 2, State: true },
  { Text: 'Concepto', NomValue: 'comentario', Ctsl: 1, Type: 0, State: true },
  { Text: 'Estado', NomValue: 'activada', Ctsl: 1, Type: 0.2, State: true },
]" />
                <!-- </v-navigation-drawer> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">
            <div class="px-2">


              <v-card color="grey lighten-3">
                <div class="px-2">
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <input type="number" v-model="OrdenCompra.codigo" style="display:none;" />

                    <!-- row 1 ------->
                    <v-row>
                      <v-col cols="12" md="2" sm="2">
                        <!----------------------------------------------  Fecha orden -->
                        <v-text-field dense outlined type="date" label="* Fecha orden:" :rules="[$rules.required]"
                          v-model="OrdenCompra.fecha" placeholder=" " style="font-size: 12px;">

                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="2" sm="2">
                        <!----------------------------------------------  Fecha entrada -->
                        <v-text-field dense outlined type="date" label="* Fecha Entrega:" :rules="[$rules.required]"
                          v-model="OrdenCompra.fechaentrega" placeholder=" " style="font-size: 12px;">

                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6" sm="6">

                        <!-------------------------------------------------- proveedor  :rules="[rules.required]"-->
                        <v-combobox id="formdisabledInput" autocomplete="off" dense outlined label="* Proveedor:"
                          :items="Rproveedor" item-text="nombre" :suffix="ProveedorTel" style="font-size: 13px;"
                          @change="ProveedorSelect" :disabled="disableAll" :rules="[$rules.required]"
                          v-model="OrdenCompra.proveedor" @blur="SelctCbProveedor" @focus="cargarProveedor"
                          @keyup="CbFilProveedor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="2" sm="2">

                        <!-- ----------------------------- descuento -->
                        <VueAutonumeric label="Desc Individual:" dense outlined autocomplete="off"
                          v-model.number="OrdenCompra.descuentoIndividual" style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                      </v-col>
                    </v-row>
                    <!-- fn row 1 ----->

                    <!-- row 2 -------->
                    <v-row style="margin-top:-30px">



                      <v-col cols="12" md="2" sm="2">
                        <!---------------------------------------------- moneda-->

                        <!-- -----------------------------------------Moneda-->
                        <v-combobox autocomplete="off" dense outlined label="Moneda" :items="Rmoneda"
                          item-text="nombrecorto" :rules="[$rules.required]" v-model="OrdenCompra.moneda"
                          @blur="SelctCbMoneda" @focus="cargarMoneda" @change="ChangeMonedaTasa">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>
                      </v-col>

                      <v-col cols="12" md="2" sm="2">
                        <VueAutonumeric label="Tasa:" dense outlined autocomplete="off"
                          v-model.number="OrdenCompra.tasa" style="font-size: 13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>

                      <v-col cols="12" md="4" sm="4">
                        <!-- enviado a: --------------------------------------------------->
                        <v-text-field label="Enviado a:" dense outlined v-model="OrdenCompra.enviarA">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-paper-plane"></i>
                          </template>
                        </v-text-field>
                      </v-col>


                      <v-col cols="12" md="4" sm="4">
                        <!-- enviado a: --------------------------------------------------->
                        <v-text-field label="Vía de Envío :" dense outlined v-model="OrdenCompra.viaEnvio">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-road"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                    </v-row>
                    <!-- fn row 2 -->

                    <!-- row 3 -->
                    <v-row style="margin-top:-30px">
                      <v-col cols="12" md="6" sm="6">
                        <!-- ----------------------------- direccion de envio -->
                        <v-textarea autocomplete="off" label="Dirección de envío:" dense outlined
                          v-model="OrdenCompra.direccionEnvio" rows="1">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>

                      </v-col>
                      <v-col cols="12" md="6" sm="6">

                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Concepto" rows="1" dense outlined
                          v-model="OrdenCompra.comentario">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <!-- fn row 3 -->

                  </v-form>

                  <v-col cols="12" md="12" sm="12" style="margin-top:-30px;">

                    <!-- Tabla De producto  :PropsUnidadminima="false" ------------------------------>
                    <TablaProductoCxp ref="detalleOrden" :ListProd="ListProd" :PropsOrdenCompra="true"
                      :EntradaAlmacen="almacen" :ProsActuAlmacen="ActualizaAlmacen" @DataTotalList="DDataTotalList"
                      @DataActAlmacen="ActualizaAlmacen = false" 
                      :PropsUnidadminima="this.$store.getters.GetConfiguracionGeneral.configCxp.unidadminima"
                      />
                    <!-- fn Tabla De producto ------------------------------>
                    

                  </v-col>



                </div>
              </v-card>


            </div>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">


            <!--        <BarraBusqueda2-->
            <!--              :url="$hostname + $hName + '/services/caja/findAll'"-->
            <!--              :nombre="'Cajas'"-->
            <!--              :headers="headers"-->
            <!--              :slotname="'item.descripcion'"-->
            <!--              :emitnombre="'emitCaja'"-->
            <!--              :slotlist="slotlist"-->
            <!--              :SeachNombre="'Búsqueda por descripción caja'"-->
            <!--            />-->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
      hide-overlay>
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light">
            REPORTE
          </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="OpendialogimprimirListFilt()">
                <i style="font-size: 25px;" class="fa fa-print"> </i>
                Listado
              </v-btn>

            </template>
            <span>Imprimir Listado</span>
          </v-tooltip>
          <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="buscar por codigo"
                single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table class="TbSize13" :headers="[
              { text: 'Código', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Fecha', value: 'fecha', sortable: false, width: 120, class: ['no-gutters black--text'] },
              { text: 'Proveedor', value: 'proveedor.nombre', sortable: false, class: ['no-gutters black--text'] },
              { text: 'direccion', value: 'direccionEnvio', sortable: false, class: ['no-gutters black--text'] },
              { text: 'Valor', value: 'valor', sortable: false, class: ['no-gutters black--text'] },
              { text: 'concepto', value: 'comentario', sortable: false, class: ['no-gutters black--text'] }
            ]" dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage"
              :page="TbPaginator.page" :pageCount="TbPaginator.pageCount" :items="ListItems"
              :search="Modallfiltro.factura" @click:row="datostabla" :server-items-length="TbTotalItem">
              <template v-slot:item.fecha="props">
                <span>{{
                    FormatoFecha(props.item.fecha)
                }}</span>
              </template>
              <template v-slot:item.valor="props">
                <span>{{ currencyFormatter(props.item.valor) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'AnularOrden'" @click.prevent="eliminar(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>



          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


    <!-- ------------------------------------------------------   alerta -->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" id="alerMov" :vertical="true" :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------  fn alerta -->


    <!-- ------------------------------------------------------ fn Modal- nueva producto-->

    <v-dialog v-model="dialogOrdenSugerida" persistent fullscreen hide-overlay transition="dialog-bottom-transition">

      <v-card>

        <v-toolbar dense dark color="indigo">

          <!-- <v-app-bar-nav-icon  @click.stop="CerrardialogOrdenSugerida()">
      </v-app-bar-nav-icon> -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on">
                Ordenes De Compras sugeridas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
              <span> Ordenes de Compras: </span> Crear Ordenes De Compras sugeridas</i>

          </v-tooltip>

          <!-- <v-toolbar-title>Ordenes De Compras sugeridas</v-toolbar-title> -->

          <v-spacer></v-spacer>

          <v-card-actions>

            <v-sheet id="scrolling-techniques-7" class="overflow-x-auto" :max-width="windowSize.width - 70"
              color="transparent">
              <v-btn-toggle>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="LimpiarOrdcomSug" color="#90A4AE" small dark>
                      <i style="font-size: 20px;" class="fa fa-file-o"> </i>
                      Limpiar
                    </v-btn>
                  </template>
                  <span>Limpiar </span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="ImpresionPreliminar" color="deep-purple lighten-3" small
                      dark>
                      <i style="font-size: 20px;" class="fa fa-print"> </i>
                      Impresion Preliminar
                    </v-btn>
                  </template>
                  <span>Impresion Preliminar </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="VerCondicionProveedor" color="orange lighten-2" small dark>
                      <i style="font-size: 20px;" class="fa fa-eye"> </i>
                      Ver Condición Proveedor
                    </v-btn>
                  </template>
                  <span>Ver Condición Proveedor </span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="GenerarOrdenes" color="blue lighten-3" small dark>
                      <i style="font-size: 20px;" class="fa fa-file-o"> </i>
                      Generar Ordenes
                    </v-btn>
                  </template>
                  <span>Generar Ordenes </span>
                </v-tooltip>


                <!--<v-tooltip top>
<template v-slot:activator="{ on }">
<v-btn id="boton-mini"  v-on="on" @click="saveComponentsNotaCredito" color="success" small   dark >
<i style="font-size: 20px;" class="fa fa-check-circle"></i> fab x-small dark  
SALVAR</v-btn>
 </template>
          <span>Guardar configuración</span>
  </v-tooltip>-->

                <!-- <v-tooltip top>
<template v-slot:activator="{ on }">
   <v-btn id="boton-mini" v-on="on" @click="removeComponentsNotaCredito" color="red"  small  dark>
    <i style="font-size: 20px;" class="fa fa-trash"> </i>
    ELIMINAR </v-btn>
 </template>
          <span>Eliminar configuración</span>
  </v-tooltip> -->

              </v-btn-toggle>

            </v-sheet>

          </v-card-actions>

          <v-btn color="grey darken-4" fab small @click="CerrardialogOrdenSugerida()">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-list three-line subheader>

          <div class="px-2">

            <v-form ref="formOrdenSugerida" v-model="valid" lazy-validation>

              <v-row>

                <!-- --------------------------------------columna 1 -->
                <v-col cols="12" md="3">

                  <!----------------------------------------------  Form_Orden_desde -->
                  <v-text-field dense outlined type="date" label="Desde" :rules="[$rules.required]"
                    @blur="calcularFecha" v-model="OrdenSugerida.desde">

                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-calendar"></i>
                    </template>
                  </v-text-field>


                  <v-combobox autocomplete="off" dense outlined label="* proveedor:" :items="Rproveedor"
                    item-text="nombre" :rules="[$rules.required]" v-model="OrdenSugerida.proveedor"
                    @blur="SelctCbProveedorOrden" @focus="cargarProveedor" @keyup="CbFilProveedor">
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                    </template>
                  </v-combobox>






                </v-col>
                <!-- -------------------------------------- fn columna 1 -->

                <!-- --------------------------------------columna 2 -->
                <v-col cols="12" md="3">
                  <!----------------------------------------------  Form_Orden_desde -->
                  <v-text-field dense outlined type="date" label="Hasta" :rules="[$rules.required]"
                    v-model="OrdenSugerida.hasta" @blur="calcularFecha">

                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-calendar"></i>
                    </template>
                  </v-text-field>


                  <!----------------------------------------------  Form_Orden_Almacen-->
                  <v-combobox v-model="OrdenSugerida.almacen" label="Almacén" required outlined dense autocomplete="off"
                    :items="Ralmacen" item-text="descripcion" @blur="SelctCbAlmacen" @focus="CargarRalmacen"
                    :rules="[$rules.required]">
                    <template v-slot:prepend>
                      <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                    </template>
                  </v-combobox>




                </v-col>
                <!-- -------------------------------------- fncolumna 2 -->

                <!-- --------------------------------------columna 3   @keyup="keyupDiaOrden"  -->
                <v-col cols="12" md="3">

                  <v-text-field label="Días" dense outlined v-model="OrdenSugerida.dias" disabled @keyup="KeyUpNumEnt">
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                    </template>
                  </v-text-field>



                  <v-tooltip top>
                    <template v-slot:activator="{ on }">


                      <v-btn v-on="on" color="blue lighten-3" @submit.prevent="" @click.prevent="SugerirProductos()">
                        <!-- <i style="font-size: 28px;" class="fa fa-file-o"> </i> -->
                        Sugerir Productos
                      </v-btn>
                    </template>
                    <span>Sugerir Productos </span>
                  </v-tooltip>


                </v-col>

                <!-- -------------------------------------- fn columna 3 -->

                <!-- --------------------------------------columna 4 -->
                <v-col cols="12" md="3">

                  <v-radio-group v-model="OrdenSugerida.opcion" column>
                    <v-radio label="Considerando solo las ventas en el periodo especificado" color="blue-grey darken-4"
                      :value="1"></v-radio>
                    <v-radio label="Pedido = existencia - ventas" color="blue-grey darken-4" :value="2"></v-radio>
                  </v-radio-group>



                </v-col>

              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12">

              <!-- Tabla De producto  ------------------------------>
              <TablaProductoCxp ref="tablaDetalle" :ListProd="ListProd" :PropsComprasSugeridas="true"
                :EntradaAlmacen="almacen" :ProsActuAlmacen="ActualizaAlmacen" @DataTotalList="DDataTotalList"
                @DataActAlmacen="ActualizaAlmacen = false" :Fecdesde="OrdenSugerida.desde"
                :Fechasta="OrdenSugerida.hasta" />
              <!-- fn Tabla De producto ------------------------------>



            </v-col>


          </div>

        </v-list>

      </v-card>


    </v-dialog>


    <!-- ------------------------------------------------------ Modal Filtro -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent
      max-width="90%" max-height="80%">
      <v-card>

        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>FILTROS</b></v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small
            @click="dialogFiltros = false, Modallfiltro = JSON.parse(JSON.stringify(ObjTemTbDinam))">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>


            <v-col cols="12" md="12">
              <!-------------------------------------------------- Nombre  -->

              <v-combobox autocomplete="off" dense outlined label="Proveedor:" style="font-size: 13px;"
                :items="Rproveedor" item-text="nombre" v-model="Modallfiltro.proveedores"
                @blur="SelctCbProveedoresFiltro" @focus="cargarProveedor" @keyup="CbFilProveedor">
                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                </template>
              </v-combobox>
            </v-col>

            <!-- -----------------------------  columna-1 -->
            <v-col cols="12" md="6" sm="6">

              <!----------------------------------------------  Desde -->
              <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>
              </v-text-field>



            </v-col>
            <!-- ----------------------------- fn columna-1 -->

            <!-- -----------------------------  columna-2 -->
            <v-col cols="12" md="6" sm="6">



              <!-------------------------------------------------- Hasta -->
              <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

                <template v-slot:prepend>
                  <i style="font-size: 20px;" class="fa fa-calendar"></i>
                </template>

              </v-text-field>


            </v-col>
            <!-- ----------------------------- fn columna-2 -->

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn-toggle dense rounded class="">


            <v-btn color="#90A4AE" @click.prevent="Limpiarfiltro">
              <i style="font-size: 28px;" class="fa fa-file-o"> </i>
              Limpiar filtro
            </v-btn>

            <v-btn color="blue lighten-1" @click.prevent="BuscarFiltros">
              <i style="font-size: 28px;" class="fa fa-search"> </i>
              Buscar
            </v-btn>

          </v-btn-toggle>

        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ------------------------------------------------------ fn Modal filtro -->



    <!-- ------------------------------------------------------ Modal- Reportes-->
    <v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
      max-width="80%" max-height="50%">
      <!----------------------------------------------------------------- Cuerpo Reporte -->
      <v-card color="grey lighten-3">

        <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

          <v-avatar tile size="45">
            <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
          </v-avatar>
          <span class="font-weight-light" style="font-size: 20px;"> <b>REPORTE LISTADO DE ORDENES DE COMPRAS</b> </span>

          <v-spacer></v-spacer>
          <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <hr>

        <v-container style="border: 1px solid #000000;">
          <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="6" sm="6">


                <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
                  :rules="[$rules.required]" @blur="ReportBlurDesde">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>

              <v-col cols="12" md="6" sm="6">


                <!-------------------------------------------------- Hasta -->
                <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
                  :rules="[$rules.required]" @blur="ReportBlurHasta">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>



              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <hr>

        <ModalReporteFiltros ref="ModRepfil" @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

      </v-card>
      <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal reportes -->


    <!-- ------------------------------------------------------ Modal- Reporte Basico-->
    <v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
      max-width="80%" max-height="50%">


      <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal Reporte Basico-->


    <!-------------------------------------------------------- Modal Condiciones Proveedor -->
    <v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogCondicionesProveedor" persistent
      max-width="90%" max-height="80%">
      <v-card>
        <v-toolbar flat color="indigo" dense>

          <i style="font-size: 20px;" class=""> </i>
          <v-toolbar-title style="color:white;" class="font-weight-light"> <b>CONDICIONES PROVEEDOR</b>
          </v-toolbar-title>
          <i style="font-size: 20px;" class=""> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="dialogCondicionesProveedor = false">
            <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <br>

          <v-row>

            <v-col class="pa-0 ma-0 py-0 my-0 black--text" cols="12" md="12" sm="12">
              <div v-if="OrdenSugerida.proveedor != null">
                <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size:20px;"><b>PROVEEDOR:</b>
                  {{ OrdenSugerida.proveedor.nombre }}</p>
                <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;"><b>DIRECCIÓN:</b>
                  {{ OrdenSugerida.proveedor.direccion }}</p>
                <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;"><b>TELEFONO:</b>
                  {{ OrdenSugerida.proveedor.telefonocontacto }}</p>
                <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;"><b>DIAS DE CRÉDITO:</b>
                  {{ OrdenSugerida.proveedor.diaCredito }}</p>
                <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;"><b>BALANCE:</b> {{
                    currencyFormatter(OrdenSugerida.proveedor.balanceproveedor.balance)
                }}</p>
                <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161;"><b>E-mail:</b>
                  {{ OrdenSugerida.proveedor.email }}</p>
              </div>
              <br>
              <v-card style="border: 1px solid  #616161;">
                <label style="font-size:15px;">CONDICIÓN</label>
                <p v-if="OrdenSugerida.proveedor != null && OrdenSugerida.proveedor.proveedorVencimientoList.length == 0"
                  class="pa-0 ma-0 py-0 my-0"><b>Este proveedor no posee condiciones de pago</b></p>

                <div
                  v-if="OrdenSugerida.proveedor != null && OrdenSugerida.proveedor.proveedorVencimientoList.length > 0">
                  <v-data-table :headers="[
                    { text: '', value: 'condicion', sortable: false, class: ['no-gutters'] },
                  ]" dense :items="OrdenSugerida.proveedor.proveedorVencimientoList" :items-per-page="15"
                    hide-default-footer>


                    <template v-slot:item.condicion="props">
                      <span style="font-size:13px;"> Hasta {{ props.item.proveedorVencimientoPK.hasta }} Días un {{
                          props.item.porcentaje
                      }}% </span>
                    </template>

                  </v-data-table>
                </div>

              </v-card>
            </v-col>

          </v-row>


        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-------------------------------------------------------- fn Modal Condiciones Proveedor-->


    <!--------------------------------------------------  Modal Cargando.... ---------------------------------------->

    <v-dialog v-model="Reparaloading" persistent width="300">
      <v-card color="grey darken-2" dark>
        <v-card-text>
          <br></br>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>

</template>

<script>
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";

import VueAutonumeric from "../../components/AutoNumericNuevo";
import BacAutonumeric from "../../components/VueAutonumeric";
import { EventBus } from "../../event-bus";

import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import TablaProductoCxp from "../../CuentaXPagar/UtilidadComponents/TablaProductoCxP";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,getFechaZone } from "@/js/Funciones.js";

export default {
  components: {
    VueAutonumeric,
    BarraBusqueda2,
    BarraDinamicaLimitePlus,
    BacAutonumeric,
    ContenidoBase,
    ModalReporteFiltros,
    ModalReporteBasico,
    TablaProductoCxp

  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    //EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("emitOrdenCompra", this.datostabla);
    // EventBus.$on("cxpOrdenCompraNew", this.new);
    // EventBus.$on("cxpOrdenCompraSave", this.save);
    // EventBus.$on("cxpOrdenCompraRemove", this.remove);
    // EventBus.$on("cxpOrdenCompraAnular", this.anular);
    // EventBus.$on("cxpOrdenCompraPrintList", this.imprimirList);
    EventBus.$on("onResize", this.onResize);
    this.filtro();
    this.findAlmacenPrincipal();


  },

  created() {
    var json = {
      titulo: "Órdenes de Compras",
      descripcion:
        "Esta función permite realizar ordenes de compras a proveedores.",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: false,

      accion: [
        "cxpOrdenCompraNew",
        "cxpOrdenCompraSave",
        "cxpOrdenCompraRemove",
        "cxpOrdenCompraAnular",
        "cxpOrdenCompraPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  data() {
    return {
      windowSize: { width: "auto", height: "auto" },
      valid: true,
      ejecucion: true,
      Reparaloading: false,
      dialogOrdenSugerida: false,
      dialogCondicionesProveedor: false,





      disableAll: false,

      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'ORDENES DE COMPRAS',
        UrlImprimir: '/ordenescompras/imprimir',
        UrlExel: '/ordenescompras/Excel',
        UrlCorreo: '/ordenescompras/Correo',
      },

      dialogimprimirListFilt: false,
      datosImprecionAll: {
        nombre: 'LISTADO DE ORDENES DE COMPRAS',
        UrlImprimir: '/ordenescompras/ImprimirList',
        UrlExel: '/ordenescompras/ExcelList',
        UrlCorreo: '/ordenescompras/CorreoList',
        desde: fechaNueva(),
        hasta: fechaNueva(),
      },

      dialogFiltros: false,
      Modallfiltro: {
        codigo: null,
        proveedores: null,
        Desde: null,
        Hasta: null
      },


      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },



      Rmoneda: [],
      Rproveedor: [],
      RProductoSug: [],
      Rdepartamento: [],
      Ralmacen: [],
      RCliente: [],
      RDocumento: [],


      aalert: {
        estado: false,
        color: null
      },


      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },



      search: '',
      ObjTemp: null,
      ProveedorTel: '',
      HeadListProducto: [{ sortable: false, text: 'Código', value: 'producto.codigo', width: 90 }, { sortable: false, text: 'Producto', value: 'producto.descripcion', width: 200 }, { sortable: false, text: 'Cantidad', value: 'cantidad' },
      { sortable: false, text: 'cantidad existencia', value: 'existenciaActual', width: 150 }, { sortable: false, text: 'Costo', value: 'precio', width: 120 }, { sortable: false, text: 'impuestos', value: 'impuesto', width: 120 },
      { sortable: false, text: 'descuento', value: 'descuento', width: 90 }, { sortable: false, text: '$ / %', value: 'descuentoPorciento' }, { sortable: false, text: 'importe', value: 'importt', width: 120, align: 'center' }, { sortable: false, text: 'actions', value: 'action', width: 120 }]
      ,

      ActualizaAlmacen: false,
      almacen: null,
      TotalProd: { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },

      ListProd: [],
      ListProdTemp: [],

      OrdenCompra: {
        codigo: 0,
        comentario: "",
        descuentoIndividual: 0,
        direccionEnvio: "",
        enviarA: null,
        fecha: fechaNueva(),
        fechaentrega: fechaNueva(),
        impuesto: 0,
        moneda: null,
        proveedor: null,
        tasa: 1,
        valor: 0,
        viaEnvio: '',
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        CompraSugeridas: true,
        ProductoProveedores: true
      },


      OrdenSugerida: {

        desde: fechaNueva(),
        hasta: fechaNueva(),
        dias: 0,
        proveedor: null,
        almacen: null,
        opcion: 1

      },
      limiteTotalRegistros: 0,
      paginator: {
        itemsPerPage: 5,
        pageStart: 1,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },


      headers: [{ text: "Tarjeta", value: "nombre" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "nombre:" },
        { dt0: "porciento", dt1: null, dt2: null, dt3: 5, dt4: "Porciento:" }
      ],



      list: [],
      search: "",
      acti_tb_data: false,

      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false
    };
  },

  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,getFechaZone,
    onResize(e) {
      // this.windowSize=e
    },
    findAlmacenPrincipal() {
      this.$axios.get(this.$hostname + this.$hName + '/almacen/principal',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.almacen = res.data
        })
    },
    calcularFecha() {
      var fechaini = new Date(this.OrdenSugerida.desde).getTime()
      var fechafin = new Date(this.OrdenSugerida.hasta).getTime()
      var diff = fechafin - fechaini
      this.OrdenSugerida.dias = diff / (1000 * 60 * 60 * 24)
    },
    CalculoListProd() {
      this.TotalProd = { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }
      if (this.ListProd.length > 0) {
        var ListP = JSON.parse(JSON.stringify(this.ListProd))
        this.ListProd = []
        ListP.forEach(element => {
          var Deporc = 0
          if (element.producto != null) {
            if (element.descuentoPorciento == false) {
              Deporc = element.descuento
            } else {
              if ((element.descuento > 0)) {
                Deporc = ((element.descuento * (element.precio * element.cantidad)) / 100)
              } else {
                Deporc = element.descuento
              }
            }
            if (element.producto.impuesto != null) {
              var itbinc = (element.producto.impuesto.tasa / 100)
              element.impuesto = (((element.precio - Deporc) * element.cantidad) * (itbinc))
            } else {
              element.impuesto = 0
            }
            var porc = 0
            if (element.descuentoPorciento == false) {
              // console.log('El Impuesto imp:' + element.impuesto)
              element.importt = (((element.precio - element.descuento) * element.cantidad) + element.impuesto)
            } else {
              if ((element.descuento > 0)) {
                porc = ((element.descuento * (element.precio * element.cantidad)) / 100)
                element.importt = (((element.precio - porc) * element.cantidad) + element.impuesto)
              } else {
                element.importt = (((element.precio - element.descuento) * element.cantidad) + element.impuesto)
              }
            }
          }
        });

        this.ListProd = JSON.parse(JSON.stringify(ListP));

        this.ListProd.forEach(element => {
          // element.importt=((element.precio*element.cantidad)-element.descuento)

          this.TotalProd.cantidad += element.cantidad

          this.TotalProd.cantExit += element.existenciaActual

          this.TotalProd.costo += element.precio

          this.TotalProd.impuesto += element.impuesto

          this.TotalProd.descuento += element.descuento

          this.TotalProd.importe += element.importt

        });
      }

    },
    LimpiarOrdcomSug() {
      this.RProductoSug = []
      this.ListProd = []

      this.OrdenSugerida = {
        desde: fechaNueva(),
        hasta: fechaNueva(),
        dias: 0,
        proveedor: null,
        almacen: null,
        opcion: 1
      }

    },

    ImpresionPreliminar() {
      var list = [];
      this.ListProd.filter(el=> el.cantidad>0).forEach(e => {
          var dat = JSON.parse(JSON.stringify(e))
          dat.proveedores = this.OrdenSugerida.proveedor;
          dat.Totalventasultimos =0.00;
          dat.ofertas = dat.oferta;
          dat.cantidadpedida = dat.cantidad;
          dat.totalexistencia = dat.existencia;
          dat.ultimosmese = 0.00;
          dat.venta = dat.ventas;
          dat.ultimosmese = 0.00;
          list.push(dat)
      });
      //return;
      this.$axios
        .post(this.$hostname + this.$hName + "/ordenescompras/ImprimirPreliminar", list, { headers: this.$store.getters.GetheadersAxios })
        .then(res =>{
          //console.log(res.data);
          var _this=this; 
          // this.Reparaloading=false
          EventBus.$emit("loading", false);
         setTimeout(function(){
        // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
     /* let pdfWindow = window.open("Reporte de impresión")
       pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
       encodeURI(res.data) + "'></embed>")  */

       var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
          EventBus.$emit("openPDF",PDF ,'Orden compra');
      
      }, 1000);
     // this.CrearObjecto=function(){};
      })
        .catch(error => {
          // this.Reparaloading=false
          EventBus.$emit("loading", true)
          , this.alerta("Reporte no Impreso("+error+")", "error")});

    },

    GenerarOrdenes() {
      this.dialogOrdenSugerida = false

      if(this.OrdenSugerida.proveedor!=null && this.OrdenSugerida.proveedor.hasOwnProperty('codigo')  ){
        this.OrdenCompra.proveedor=JSON.parse(JSON.stringify(this.OrdenSugerida.proveedor))
}

      this.ListProd = JSON.parse(JSON.stringify(this.ListProd.filter(e => e.cantidad > 0)))
      var list = []
      this.ListProd.forEach(e => {
        if (e.oferta > 0) {
          var dat = JSON.parse(JSON.stringify(e))
          dat.ofertaSelect = true
          dat.cantidad = e.oferta
          dat.costo = 0.00
          dat.importe = 0.00
          list.push(dat)
        }
        if (e.cantidad > 0) {
          e.oferta = 0
          list.push(e)
        }
      });
      this.ListProd = list;
    },

    VerCondicionProveedor() {
      if (this.OrdenSugerida.proveedor == null) {
        this.alerta("Seleccione un proveedor para ver las condiciones", "error");
      } else {
        this.dialogCondicionesProveedor = true
      }
    },
    SugerirProductos() {
      if (this.$refs.formOrdenSugerida.validate()) {
        this.ListProd=[];
        // this.Reparaloading = true
        EventBus.$emit("loading", true);
        if (this.ejecucion == true) {
          this.ejecucion = false;
          this.$axios
            .get(
              this.$hostname + this.$hName + "/ordenescompras/ordenesSujerida/" + this.OrdenSugerida.desde + "/" + this.OrdenSugerida.hasta + "/" + this.OrdenSugerida.proveedor.codigo + "/" + this.OrdenSugerida.almacen.codigo + "/" + this.OrdenSugerida.opcion,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => {
              if(this.OrdenSugerida.opcion==1){
                this.RProductoSug = JSON.parse(JSON.stringify(res.data.filter((ee) => ee.ventas>0)));
              }else{
                this.RProductoSug = JSON.parse(JSON.stringify(res.data))
              }

              this.RProductoSug.forEach(e => {
                this.$refs.tablaDetalle.addDetalleSugerido(e)
              });
             

              var _this = this;
              setTimeout(function () {
                var List = _this.ListProd[0]
                if (List.unidadProducto == null) {
                  const index = _this.ListProd.indexOf(List)
                  _this.ListProd.splice(index, 1)
                }
              }, 500);
              // this.Reparaloading = false
              EventBus.$emit("loading", false);
              this.ejecucion = true;
            })
            .catch(error => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    CargarRalmacen() {
      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Ralmacen = res.data;
        });
    },
    SelctCbAlmacen() {
      if (this.OrdenSugerida.almacen == null) {
        this.OrdenSugerida.almacen = "";
      }
      if (this.OrdenSugerida.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.OrdenSugerida.almacen = "";
        return;
      }
    },
    KeyUpNumEnt(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0
      }
      if (e.key) {
        var mynumeral = require('numeral')
        e.target.value = mynumeral(e.target.value).value()
        if (e.target.value == null) {
          e.target.value = 0
        }
      } else {
        e.key = ""
      }
      if (e.target.value.length == 0) {
        e.target.value = 0
        e.key = 0
      }
    },
    keyupDiaOrden() {
      if (e.key) {
        var mynumeral = require('numeral')
        this.OrdenSugerida.dia = mynumeral(this.OrdenSugerida.dia).value()
        if (this.OrdenSugerida.dia == null) {
          this.OrdenSugerida.dia = 0
        }
      } else {
        console.log("no se puede poner letras o signos")
      }
    },
    addClikProd() {


    },
    CerrardialogOrdenSugerida() {
      this.dialogOrdenSugerida = false
      this.newOrdenSugerida()
    },
    newOrdenSugerida() {
      this.LimpiarOrdcomSug()
    },
    newCrearProducto() {


    },
    newProducto() {
      this.ObjTemp = null
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.ejecucion == true) {
          EventBus.$emit("loading", true);
          this.ejecucion = false;
          if (this.ListProd.filter(e => e.cantidad > 0 && e.producto!=null).length <= 0) {
            this.alerta("Debe seleccionar almeno un producto para hacer una orden de compra", "error")
            return
          }
          this.OrdenCompra.ordencompraDetalleList = []

        await Promise.all(this.ListProd.filter((ee) =>  ee.cantidad > 0 && ee.producto!=null).map(async (e) => {  
          if (e.producto != null) {
              var det = {
                cantidad: e.cantidad,
                precio: e.costo,
                impuesto: e.impuesto,
                codigo: 0,
                descripcion: e.descripcion,
                oferta: e.oferta,
                descuento: e.descuento,
                impuestoIncluido: false,
                descuentoPorciento: false,
                borrado: false,
                existenciaActual: e.existencia,
                unidad: e.unidad,
                producto: e.producto
              }
              this.OrdenCompra.ordencompraDetalleList.push(det)
              this.OrdenCompra.valor = this.TotalProd.importe
            }
    }));
    
    this.OrdenCompra.fecha=this.getFechaZone(this.OrdenCompra.fecha)
          /*this.ListProd.forEach(e => {
          });*/

          this.$axios
            .post(
              this.$hostname + this.$hName + "/ordenescompras/salvar", this.OrdenCompra,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => this.alerta("Dato guardado correctamente", "bien"))
            .catch(error => this.alerta(error, "error"));
        }
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },
    NotifMessage() {
      if (this.OrdenCompra.codigo != 0 && this.OrdenCompra.codigo != null) {
        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular este registro?",
          Exec: "AnularOrden"
        }
        this.Exec_notif(notif)
      } else {
        this.alerta("Seleccione una orden de compra para eliminar", "error")
      }
    },
    Exec_notif(e) {
      this.notif = JSON.parse(JSON.stringify(e))
    },
    anular() {

    },
    eliminar() {
      if (this.OrdenCompra.codigo !== 0 && this.OrdenCompra.codigo !== null) {
        if (this.$refs.form.validate()) {
          this.$axios
            .post(
              this.$hostname + this.$hName + "/ordenescompras/anular", this.OrdenCompra,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then(res => {
              if (!res.data) {
                this.alerta("Registro anulado correctamentente", "bien")
              }
            })
            .catch(error => this.alerta(error, "error"));
        }
      }
    },
    imprimirList() {
      if (this.OrdenCompra.codigo > 0) {

        this.datosImprecion.moneda = this.moneda = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()))
        this.datosImprecion.codigo = this.OrdenCompra.codigo
        this.datosImprecion.schema = this.$store.getters.GetheadersAxios["X-TENANT-ID"]
        this.dialogimprimirList = true
      } else {
        this.alerta("Seleccione un Producto para poder imprimir", "error")
      }
    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },


    OpendialogimprimirListFilt() {
      this.dialogimprimirListFilt = true
      var _this = this;
      setTimeout(function () {
        _this.$refs.ModRepfil.CrearObjecto = _this.validarObjecto;
      }, 300);

    },


    validarObjecto() {
      //this.datosImprecionAll.tenant=this.datosImprecion.schema=this.$store.getters.GetheadersAxios["X-TENANT-ID"]
      var where = "op.codigo>0";
      where += ` and op.fecha>=' ${new Date(this.datosImprecionAll.desde).toISOString().substr(0, 10)} ' `
      where += ` and op.fecha <= ' ${new Date(this.datosImprecionAll.hasta).toISOString().substr(0, 10)} ' `
      this.datosImprecionAll.where = where
    },


    ProductoProveedores() {
      //alert('Producto por proveedores')
      if (this.$refs.form.validate()) {
        // this.Reparaloading = true
        EventBus.$emit("loading", true);
        this.$axios
          .get(
            this.$hostname + this.$hName + "/ordenescompras/cargarProductos/" + this.OrdenCompra.proveedor.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => {
            if (res.data.length > 0) {
              res.data.forEach(e => {
                this.$refs.detalleOrden.addDetalle(e)
              });
            }
            // this.Reparaloading = false
            EventBus.$emit("loading", false);
          })
          .catch(error => this.alerta(error, "error"));
      }else{
        this.alerta("Debe seleccionar todos los campos rojos","error")
        return
      }
    },
    newwnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.disableAll = false,
        this.TotalProd = { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 },
        this.ListProd = [],
        this.ListProdTemp = [],
        this.OrdenCompra = {
          codigo: 0,
          comentario: "",
          descuentoIndividual: 0,
          direccionEnvio: "",
          enviarA: null,
          fecha: fechaNueva(),
          fechaentrega: fechaNueva(),
          impuesto: 0,
          moneda: null,
          proveedor: null,
          tasa: 1,
          valor: 0,
          viaEnvio: '',
        },
        this.ejecucion = true;

      this.$refs.detalleOrden.TotalProd= { cantidad: 0, cantExit: 0, costo: 0, impuesto: 0, descuento: 0, importe: 0 }
      EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
    },
    datostabla(e) {
      EventBus.$emit("loading", true);
      this.ListProdTemp = []
      this.ListProd = []
      // setTimeout(this.$refs.detalleOrden.limpiar(),3000);
      this.OrdenCompra = JSON.parse(JSON.stringify(e));
      this.OrdenCompra.fecha = e.nuevaFecha
      // this.OrdenCompra.fecha = new Date(e.fecha).toISOString().substr(0, 10)
      this.OrdenCompra.fechaentrega = new Date(e.fechaentrega).toISOString().substr(0, 10)
      this.ListProdTemp = JSON.parse(JSON.stringify(e.ordencompraDetalleList));
      //this.ListProd = JSON.parse(JSON.stringify(e.ordencompraDetalleList));

  var bar = new Promise((resolve, reject) => {
    if(this.ListProdTemp.length>0){
      this.$refs.detalleOrden.getListOrden(this.ListProdTemp)
    }
      resolve()
});


bar.then(() => {
  setTimeout(this.CalculoListProd, 300);
      this.acti_tb_data = false;

      var _this = this;
        setTimeout(function () {
          _this.CalculoListProd()
          _this.ProveedorSelect(e.proveedor)
          _this.$refs.detalleOrden.calcularTotales()
          EventBus.$emit("loading", false);
         // _this.ListProdVer()
        }, 500);
});

       

    },

ListProdVer(){
console.log('this.$refs.ListProdBuenIhu')
console.log(this.$refs.detalleOrden.ListProd)
},


    cargarProveedor() {
      this.$axios.get(this.$hostname + this.$hName + '/proveedores/findallbyDesc/25/0/*', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rproveedor = res.data))
    },
    SelctCbProveedorOrden() {
      if (this.OrdenSugerida.proveedor == null) {
        this.OrdenSugerida.proveedor = ''
      }
      if (this.OrdenSugerida.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.OrdenSugerida.proveedor = ''
        return
      }
    },
    ProveedorSelect(e) {
      if (e != null) {
        if (e.hasOwnProperty('proveedoresTelefonosList') && e.proveedoresTelefonosList.length > 0) {
          this.ProveedorTel = 'Tel.' + e.proveedoresTelefonosList[0].proveedoresTelefonosPK.telefono
        } else {
          this.ProveedorTel = ''
        }
      }
    },
    SelctCbProveedor() {
      if (this.OrdenCompra.proveedor == null) {
        this.OrdenCompra.proveedor = ''
      }
      if (this.OrdenCompra.proveedor.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.OrdenCompra.proveedor = ''
        return
      }
    },
    CbFilProveedor(e) {
      if (e.target.value.length >= 0) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/proveedores/findallbyDesc/25/0/" + (e.target.value === '' ? "*" : e.target.value),
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => (this.Rproveedor = res.data));
      }
    },
    SelctCbMoneda() {
      if (this.OrdenCompra.moneda == null) {
        this.OrdenCompra.moneda = ''
      }
      if (this.OrdenCompra.moneda.codigo == undefined) {
        this.alerta("Seleccione una moneda", "error");
        this.OrdenCompra.moneda = ''
        return
      }
    },
    ChangeMonedaTasa(e) {
      if (e != null) {
        this.OrdenCompra.tasa = JSON.parse(JSON.stringify(e.tasa))
      }
    },
    cargarMoneda() {
      this.$axios.get(this.$hostname + this.$hName + '/monedas/findallActivos/', { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.Rmoneda = res.data))
    },

    SelctCbProveedoresFiltro() {
      if (this.Modallfiltro.proveedores == null) {
        this.Modallfiltro.proveedores = ''
      }
      if (this.Modallfiltro.proveedores.codigo == undefined) {
        this.alerta("Seleccione un proveedor", "error");
        this.Modallfiltro.proveedores = ''
        return
      }
    },


    ReportBlurDesde() {
      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }
    },
    ReportBlurHasta() {
      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }
    },
    DDataTotalList(e) {
      this.TotalProd = JSON.parse(JSON.stringify(e))
    },


    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        proveedores: null,
        Desde: null,
        Hasta: null
      }

      this.ListItems = []
      this.TbTotalItem = 0
      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) { this.Modallfiltro.codigo = null }

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) { e.itemsPerPage = 15 }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      var prov = null

      if (this.Modallfiltro.proveedores != null) { prov = this.Modallfiltro.proveedores.codigo }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + prov + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/ordenescompras/findListOrdenCompraPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {

          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem

        })
    },


openOrdenSugerida(){
this.dialogOrdenSugerida = true
if(this.OrdenCompra.proveedor!=null && this.OrdenCompra.proveedor.hasOwnProperty('codigo')  ){
  this.OrdenSugerida.proveedor=JSON.parse(JSON.stringify(this.OrdenCompra.proveedor))
}
},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.newwnuevo();
      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.ejecucion = true;
        EventBus.$emit("loading", false);
      }
    },
    async filtro() {
      await this.$axios
        .get(this.$hostname + this.$hName +
          "/ordenescompras/findall/" + this.paginator.itemsPerPage + "/" + this.paginator.pageStart + "/" + (this.search === "" ? "a" : this.search),
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.list = res.data;
        });
    },
    abrirTabla() {

      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) { this.Limpiarfiltro() }

    },
    abrirTabla2() {
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    }
  },
  computed: {


    ActDescBtn() {

      if (this.OrdenCompra.codigo > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          CompraSugeridas: true,
          ProductoProveedores: true
        }

        this.disableAll = true

        if (this.OrdenCompra.activada == false) {

          this.EstadoBtn.anular = false
          this.EstadoBtn.guardar = false

        }


      }


      if (this.OrdenCompra.codigo <= 0) {


        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: false,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          CompraSugeridas: true,
          ProductoProveedores: true
        }

        this.disableAll = false
      }
      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))
      //EventBus.$emit("ActDesaBotones", objjct);
    }
  }









};
</script>

<style>
#formdisabledInput {
  color: black;
}
</style>
