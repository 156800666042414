<template>
  <div>{{ActDescBtn}}
 <table style="position: absolute;" width="100%"  :height="this.windowSize.height-8"  :max-height="this.windowSize.height-8" >
   <tr max-height="48px" height="48px"  >
    
<ContenidoBase 
  :titulo="'Medicos afiliados'"
  :descripcion="'En esta Opcion se registran los medicos afiliados '"
   :EstadoBtn="EstadoBtn"
   @abrirBarraTablaDinamica="abrirTabla"
   @MethodNuevo="newnuevo"
   @MethodGuardar="save"
   @MethodEliminar="remove"
   @MethodAnular="anular"
   @MethodImprimir="imprimirList"
   
/>
    </tr>
   
   
  
   <tr>
<!-- BarraPrimaria --------------------------------------------------------------------------------->
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

         <div class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">
        
        

       <v-tooltip left>
          <template v-slot:activator="{ on }">
            <!--------------------- aqui va la otra tabla izquierda --------->
            <center>
              <!-- <v-btn-toggle></v-btn-toggle> -->
                <v-btn
                  small
                  width="95%"
                  color="yellow accent-4"
                  @click.prevent="actualiza_tb_ver"
                >
                  <i style="font-size: 25px;" class="fa fa-eye"> </i> ver</v-btn
                >
               
              
            </center>


            <BarraDinamicaNormal
               ref="BarraDiNor"
              :url="$hostname + $hName + '/medicoafiliados/findallTodo/'"
              :nombre="'Medicos Afiliados'"
              :emitnombre="'emitmedicoafiliados'"
              :SeachNombre="'Búsqueda general'"

              :headers="[
        { text: 'Nombre Medico', value: 'nombre' },
        { text: 'Direccion', value: 'direccion' },
        { text: 'Telefono', value: 'telefono' },
        { text: 'Centro de Salud', value: 'centroSalud.nombre' },
        { text: 'Especialidad', value: 'especialidad' }
       ]"
              :ContListSlot="[
              {Text:'Código', NomValue:'codigo', Ctsl:1, Type:0, State:true},
              {Text:'Nombre Medico', NomValue:'nombre', Ctsl:1, Type:0, State:true},
              {Text:'Dirección', NomValue:'direccion', Ctsl:1, Type:0, State:true},
              {Text:'Telefono', NomValue:'telefono', Ctsl:1, Type:0, State:true},
              {Text:'Centro de Salud', NomValue:'centroSalud', Nv2:'nombre', Ctsl:2, Type:0, State:true},
              {Text:'Especialidad', NomValue:'especialidad', Ctsl:1, Type:0, State:true},
              {Text:'Estado',NomValue:'activo', Ctsl:1, Type:0.1, State:true},
              ]"
            />

            <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/medicoafiliados/findall/'"
              :nombre="'Medicos Afiliados'"
              :headers="headers"
              :slotname="'item.nombre'"
              :emitnombre="'emitmedicoafiliados'"
              :SeachNombre="'Búsqueda por nombre del Medico'"
              :slotlist="slotlist"
            /> -->
          </template>
        </v-tooltip>

      </div>
      </td>
<!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
<!-- contenido central ------------------------------------------------------------------------------ -->
      <td :max-height="this.windowSize.height-96" >
        <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">

     <div class="px-2">
       
  <v-form ref="form" v-model="valid" lazy-validation >



    <v-card color="grey lighten-3">
              <div class="px-2">
                <v-row>
<!-- -------------------------------------------- fn columna 1 -->
                  <v-col cols="12" md="6" sm="6">
                    <!-- ------------------------------- nombre -->
                    <v-text-field
                      v-model="Medico.nombre"
                      :rules="[$rules.required]"
                      autocomplete="off"
                      label="Nombre"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="male"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>
                    <!-- ------------------------------- centro de salud -->
                    <v-combobox
                      v-model="Medico.centroSalud"
                      :items="ListaClinicas"
                      label="Centro de Salud"
                      @focus="CargarCentroSalud"
                      item-text="nombre"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="ambulance"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-combobox>


                  </v-col>
<!-- -------------------------------------------- fn columna 1 -->

<!-- --------------------------------------------columna 2 -->
                  <v-col cols="12" md="6" sm="6">
                    <!-- ------------------------------- cedula-->
                    <v-text-field
                      :rules="[$rules.required]"
                      autocomplete="off"
                      v-model="Medico.cedula"
                      v-mask="'###-#######-#'"
                      label="Cedula"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="id-card"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>
                    <!-- ------------------------------- telefono -->
                    <v-text-field
                      v-model="Medico.telefono"
                      autocomplete="off"
                      :rules="[$rules.required]"
                      v-mask="'(###)-###-####'"
                      label="Telefono"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="phone"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>

 <v-col cols="12" md="12" sm="12">
                    <!-- ------------------------------- direccion -->
                    <v-text-field
                      :rules="[$rules.required]"
                      v-model="Medico.direccion"
                      label="Direccion"
                      autocomplete="off"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="location-arrow"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>
              
 <v-col cols="12" md="6" sm="6">
                    <!-- ------------------------------- especialidad -->
                    <v-text-field
                      v-model="Medico.especialidad"
                      autocomplete="off"
                      label="Especialidad"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="user-md"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>

                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <!-- ------------------------------- exequatur -->
                    <v-text-field
                      autocomplete="off"
                      label="exequátur"
                      v-model="Medico.excequantum"
                      required
                      outlined
                      dense
                    >
                      <template v-slot:prepend>
                        <vue-fontawesome
                          icon="id-badge"
                          size="1.5"
                          color="black"
                        ></vue-fontawesome>
                      </template>
                    </v-text-field>
                  </v-col>
                
               <!-- ------------------------------- activo -->
                  <v-col cols="12" md="6">
                    <v-switch label="activo" v-model="Medico.activo"></v-switch>
                  </v-col>


                </v-row>
               
              </div>
</v-card>

            </v-form>
  </div>   
        </v-app> 
      </td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
      <td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir" >

 <div  class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">
        
        
        <!-- <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll/'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
</div>

      </td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



    </tr>
  </table>
<!-- Modales -------------------------------------------------------------------------------------------------->
  <v-snackbar
      v-model="aalert.estado"
      :color="aalert.color"
      style="margin-top: 20%;"
      :vertical="true"
     :timeout="120000"
      top="top"
    >
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog
      label="myAlert"
      v-model="acti_tb_data"
      persistent
      transition="dialog-bottom-transition"
      fullscreen hide-overlay 
    >
      <v-card>
        <v-toolbar dense flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>

          <v-tooltip top>
<template v-slot:activator="{ on }"> 
  <v-btn v-on="on"
  v-if="false"
                dark
                  small
                  color="deep-purple accent-2"
                  @click.prevent=""
                >
                  <i style="font-size: 25px;" class="fa fa-print"> </i>
                  Listado</v-btn>

 </template>
          <span>Imprimir Listado</span>
        </v-tooltip> 
          <v-btn
            color="grey darken-4"
            fab
            small
            @click="acti_tb_data = !acti_tb_data"
          >
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                @keyup="filtro"
                label="Buscar"
                single-line
                hide-details
              >
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table
              :headers="[
        { text: 'Nombre Medico', value: 'nombre', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Direccion', value: 'direccion', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Telefono', value: 'telefono', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Centro de Salud', value: 'centroSalud.nombre', sortable:false, class: ['no-gutters black--text']  },
        { text: 'Especialidad', value: 'especialidad', sortable:false, class: ['no-gutters black--text']  }
       ]"
              dense
              :items="list"
              :search="search"
              @click:row="datostabla"
              :items-per-page="15"
            >
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn tb-data-->

<!-- ------------------------------------------------------ Elim_notif-->
            <v-dialog
              label="myAlert"
              v-model="Elim_notif.estado"
              persistent
              max-width="350"
              transition="fab-transition"
            >
              <template justify="center" v-slot:activator="{ on }"></template>

              <v-card>
                <v-card-title>
                  <h4>
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                    CONFIRMACION
                    <i style="font-size: 28px;" class="fa fa-warning"></i>
                  </h4>
                </v-card-title>
                <hr />

                <v-card-text>
                  <h5>{{ Elim_notif.nombre }}</h5>
                </v-card-text>

                <hr />
                <v-card-actions>
                  <v-btn
                    @click.prevent="
                      eliminar(), (Elim_notif.estado = !Elim_notif.estado)
                    "
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-check"></i> Si
                  </v-btn>

                  <v-btn
                    @click.prevent="Elim_notif.estado = !Elim_notif.estado"
                    small
                  >
                    <i style="font-size: 20px;" class="fa fa-ban"></i> No
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- ------------------------------------------------------  fn Elim_notif -->



<!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>


</template>

<script>
import Autonumeric from "../../components/AutoNumericNuevo";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import BarraDinamicaNormal from "../../components/BarraDinamicaNormal";
import ContenidoBase from "../../components/ContenidoBase";

import { EventBus } from "../../event-bus.js";
export default {
  components: {
    BarraBusqueda2,
    Autonumeric,
    BarraDinamicaNormal,
    ContenidoBase
    
    
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
   EventBus.$on("emitmedicoafiliados", this.datostabla);
    // EventBus.$on("MedicoNew", this.new);
    // EventBus.$on("MedicoSave", this.save);
    // EventBus.$on("MedicoRemove", this.remove);
    // EventBus.$on("MedicoAnular", this.anular);
    // EventBus.$on("MedicoPrintList", this.imprimirList);
   
    this.cargarTodo();


  },
  created() {
    var json = {
      titulo: "Medicos afiliados",
      descripcion:
        "En esta Opcion se registran los medicos afiliados ",
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "MedicoNew",
        "MedicoSave",
        "MedicoRemove",
        "MedicoAnular",
        "MedicoPrintList"
      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
  },

  data() {
    return {
      ///////reglas

      ejecucion: true,
      windowSize:{width:"auto", height:"auto"},
     
      /////////////////////////////
      ListaMedicos: [],
      ListaClinicas: [

//{  
// borrado:false,
// ciudad:"SANTIAGO",
// codigo:1,
// direccion:"AUTOPISTA DUARTE KM 2 1/2",
// fechaCreacion:"2018-04-03T10:59:23.152-04:00",
// fechaModificacion:"2018-04-03T11:05:09.935-04:00",
// nombre:"HOSPITAL METROPOLITANO DE SANTIAGO (HOMS)",
// nombreCortoOriginal:"HOSPITAL METROPOLITANO DE SANTIAGO (HOMS)",
// rnc:"111127947",rncOriginal:"111127947",telefono:"(809)-582-5555",
// usuarioCreacion:"admin",usuarioModificacion:"admin"
//}


      ],


      EstadoBtn:{ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:true,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        },


      ////////////////////////////
      headers: [{ text: "Medico", value: "nombre" }],
      slotlist: [
        { dt0: "nombre", dt1: null, dt2: null, dt3: 1, dt4: "Nombre:" },
        { dt0: "telefono", dt1: null, dt2: null, dt3: 1, dt4: "Telefono:" },
        {
          dt0: "centroSalud",
          dt1: "nombre",
          dt2: null,
          dt3: 1,
          dt4: "Centro de Salud:"
        },
        {
          dt0: "especialidad:",
          dt1: "cedula",
          dt2: null,
          dt3: 1,
          dt4: "Expecialidad:"
        },
        { dt0: "exequatur", dt1: "", dt2: null, dt3: 1, dt4: "Exequatur:" },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],
      Elim_notif: {
        estado: false,
        nombre: "¿Seguro que desea eliminar este registro?"
      },

      aalert: {
        estado: false,
        color: null
      },
      list: [],
      search: "",
      acti_tb_data: false,
     BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,
      //////////////////////
      Medico: {
        codigo:0,
        nombre: null,
        telefono: '',
        centroSalud: null,
        cedula: '',
        direccion: null,
        especialidad: "",
        excequantum: "",
        activo: true
      },
      ////////////validacion del formulario
      valid: true,
      /////////////////////cabeza de la tabla ver
      
    };
  },
  methods: {

CargarCentroSalud(){

       this.$axios
        .get(this.$hostname + this.$hName + "/centrosalud/findall/", {headers:this.$store.getters.GetheadersAxios})
        .then(res => {this.ListaClinicas = res.data, console.log("Salud Salud")
        console.log(res.data)
        
        })
        .catch(error => console.log(error));

// .post(this.$hostname + this.$hName + "/services/clinicas/findall", {})

},



    async cargarTodo() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/medicoafiliados/findallTodo/",
          {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => (this.ListaMedicos = res.data))
        .catch(error => console.log(error));



    },
    datostabla(e) {

      if(!e.hasOwnProperty("especialidad")){ e.especialidad=""}
      if(!e.hasOwnProperty("excequantum")){ e.excequantum=""}

      this.Medico = e;
      this.acti_tb_data = false;


if(this.windowSize.width<=600){

this.BarraTablaDinamicaAprir=false

}


    },
    save() {

if (this.$refs.form.validate()) {

if (this.ejecucion == true) {
          this.ejecucion = false;

var jsn={
datos:JSON.parse(JSON.stringify(this.Medico)),
usuario:JSON.parse(JSON.stringify(this.$user)) 
}


this.$axios
        .post(this.$hostname + this.$hName + "/medicoafiliados/save/",this.Medico,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));
}
}else {
        this.alerta("los campos en rojo son requeridos", "error");
      }



    },
    remove() {



if(this.Medico.hasOwnProperty('codigo') && this.Medico.codigo!=0){

      this.Elim_notif.estado = !this.Elim_notif.estado;
  }else{this.alerta('Seleccione un médico afiliado para  eliminar',"error")}

    },
    eliminar() {

var jsn={
datos:JSON.parse(JSON.stringify(this.Medico)),
usuario:JSON.parse(JSON.stringify(this.$user)) 
}

this.$axios
        .post(this.$hostname + this.$hName + "/medicoafiliados/borrado/",this.Medico,{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")})
        .catch(error =>this.alerta("Dato no guardado correctamente", "error"));

    },
    anular() {},
    imprimirList() {
      this.Reparaloading = true
     this.$axios
        .get(this.$hostname + this.$hName + "/medicoafiliados/imprimir/",{headers:this.$store.getters.GetheadersAxios})
        .then(res =>{
          console.log(res.data);
          var _this=this; 
          this.Reparaloading=false
         setTimeout(function(){
        // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
      /*let pdfWindow = window.open("Reporte de impresión")
       pdfWindow.document.write("<embed width='100%' height='100%' type='application/pdf'   src='data:application/pdf;base64," +
       encodeURI(res.data) + "'></embed>") */ 
       var PDF=`data:application/pdf;base64,${encodeURI(res.data)}`;
       EventBus.$emit("openPDF",PDF ,'medico afiliado');
      
      }, 1000);
     // this.CrearObjecto=function(){};
      })
        .catch(error => {this.Reparaloading=false, this.alerta("Reporte no Impreso("+error+")", "error")});
    },
    newnuevo() {

      this.BarraTablaDinamicaAprir = false

      this.Medico= {
        codigo:0,
        nombre: null,
        telefono: '',
        centroSalud: null,
        cedula: '',
        direccion: null,
        especialidad: "",
        excequantum: "",
        activo: true
      };



      this.ejecucion = true;
      EventBus.$emit("actualizaBarraBusqueda2");
    },

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = true;
      }
    },
    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() {
      await this.$axios
        .get(
          this.$hostname + this.$hName + "/medicoafiliados/findallTodo/",
            {headers:this.$store.getters.GetheadersAxios}
        )
        .then(res => {
          this.list = res.data;
        });
    },
    abrirTabla() {
      // console.log("entro");
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

if (this.BarraTablaDinamicaAprir == true) {
this.$refs.BarraDiNor.list=[]
this.$refs.BarraDiNor.filtro()

    }




    },
    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    }
  },

computed:{

    ActDescBtn(){

if(this.Medico.codigo>0){


this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:true,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }



  var objjct = {
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta:false, 
    };

}


if(this.Medico.codigo<=0){

this.EstadoBtn={ 
        BarraTablaDinamicaAprir:true,
        nuevo:true,
        guardar:true,
        eliminar:false,
        anular:false,
        imprimir:true,
        vercuenta:false,
        guardartemporal:false,
        restaurartemporal:false,
        importarDato:false,
        cargarProducto:false,
        ImprimirproductoInv:false,
        }





var objjct = {
      save: true,
      eliminar: false,
      anular: false,
      nuevo: true,
      imprimir: true,
      vercuenta:false, 
    };


}


this.windowSize=JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize)) 
//EventBus.$emit("ActDesaBotones", objjct);
    }





  }


};
</script>

<style></style>
