<template>
  <div>
    <table style="position: absolute;" width="100%" :height="this.windowSize.height - 8"
      :max-height="this.windowSize.height - 8">

      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Reporte Existencia por Almacén'"
          :descripcion="'Imprime la Existencia de los Productos por Almacén.'" :EstadoBtn="EstadoBtn"
          @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save" @MethodEliminar="remove"
          @MethodAnular="anular" @MethodImprimir="imprimirList" />
      </tr>
      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>
                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver">
                      <i style="font-size: 25px;" class="fa fa-eye"> </i> ver
                    </v-btn>
                    <v-btn small dark color="deep-purple accent-2"
                      @click.prevent="notifi('Seguro que desea nuevo', 'n')">
                      <i style="font-size: 25px;" class="fa fa-print"> </i>
                      Listado
                    </v-btn>
                  </v-btn-toggle>
                </center>
                <!-- <BarraBusqueda2
              :url="
                $hostname + $hName + '/services/tipocliente/findalltipoclientes'
              "
              :nombre="'Tipo de Clientes'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitReportesAlfabeticoProductos'"
              :SeachNombre="'Búsqueda por descripcion '"
              :slotlist="slotlist"
            /> -->
              </template>
            </v-tooltip>




          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height - 96">
          <v-app id="inspire" class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px' }">


            <v-container>
              <v-card color="grey lighten-3">

                <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">


                  <v-avatar tile size="45">
                    <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
                  </v-avatar>
                  <span class="font-weight-light" style="font-size: 20px;">Reporte Existencia por Almacén</span>

                  <!-- <v-toolbar-title    class="font-weight-light"> Reporte Producción de boletos por unidades</v-toolbar-title > -->
                  <!-- style="color:white;" -->
                  <!-- <i class=""> </i> -->

                  <!-- <v-spacer></v-spacer> -->
                  <!-- <v-btn
          color="grey darken-4"
          fab
          small
          @click="cerrarmodalcuenta"
        >
         <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
        </v-btn> -->
                </v-toolbar>

                <hr>

                <v-container style="border: 1px solid #000000;">
                  <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- columna 1 -->
                      <v-col cols="12" md="6" sm="6">

                        <!-- ----------------------------- Tipo Factura -->
                        <v-combobox autocomplete="off" dense outlined label="Tipo Inventario:" :items="$RTipoInventario"
                          item-text="descripcion" v-model="ExistenciaAlmacen.tipoInventario"
                          @blur="SelctCbTipoInventario">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-cubes"></i>
                          </template>
                        </v-combobox>



                        <!------------------------------------------ Fabricante -->
                        <!--@keyup="CbFil", @blur="SelctCbDesc", @focus="", @change="VerifEspecial"  -->



                        <v-combobox autocomplete="off" dense outlined label="Fabricante:" :items="RFabricante"
                          item-text="nombre" v-model="ExistenciaAlmacen.fabricante" @focus="CargarFabricante"
                          @blur="SelctCbFabricante">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-wrench"></i>
                          </template>
                        </v-combobox>
                        <v-text-field label="Letra Inicial:" dense maxlength="1" autocomplete="off" outlined
                          v-model="ExistenciaAlmacen.letraIni">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sort-alpha-desc"></i>
                          </template>
                        </v-text-field>

                        <!----------------------------------------------  Departamento -->
                        <v-combobox autocomplete="off" dense outlined label="Departamento:" :items="Rdepartamento"
                          item-text="descripcion" v-model="ExistenciaAlmacen.departamento" @focus="cargarDepartamento"
                          @blur="SelctCbDesc">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-building"></i>
                          </template>
                        </v-combobox>


                        <v-switch class="mt-0" color="teal" inset hide-details label="Producto con Balance 0:"
                          v-model="ExistenciaAlmacen.balance0">
                          <template v-slot:label>
                            <strong v-if="ExistenciaAlmacen.balance0 == true" style="color:#000000;">Producto con
                              Balance
                              0: Si</strong>
                            <strong v-if="ExistenciaAlmacen.balance0 == false" style="color:#000000;">Producto con
                              Balance
                              0: No</strong>
                          </template>
                        </v-switch>

                        <v-switch color="teal" inset hide-details label="Excluir Existencia Negativa:"
                          v-model="ExistenciaAlmacen.ExistNegati">
                          <template v-slot:label>
                            <strong v-if="ExistenciaAlmacen.ExistNegati == true" style="color:#000000;">Excluir
                              Existencia
                              Negativa: Si</strong>
                            <strong v-if="ExistenciaAlmacen.ExistNegati == false" style="color:#000000;">Excluir
                              Existencia Negativa: No</strong>
                          </template>
                        </v-switch>

                        <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >

                        <v-row>

                        <v-col class=" py-0 my-0"  cols="12" md="8" sm="8">

                        <!-- <v-switch color="teal" inset hide-details label="Pedido ya:"
                          v-model="ExistenciaAlmacen.pedidoYa">
                          <template v-slot:label>
                            <strong v-if="ExistenciaAlmacen.pedidoYa == true" style="color:#000000;">Pedido ya: Si</strong>
                            <strong v-if="ExistenciaAlmacen.pedidoYa == false" style="color:#000000;">Pedido ya: No</strong>
                          </template>
                        </v-switch> -->

                       

                  <v-radio-group  hide-details class="py-0" v-model="ExistenciaAlmacenLink.opcion" row>     
                  <v-radio label="N/A" color="green" :value="1">
                    <template v-slot:label><strong style="color:#000000;">N/A</strong></template>
                  </v-radio>
                  <v-radio label="PEDIDOYA" color="green" :value="2">
                    <template v-slot:label><strong style="color:#000000;">PEDIDOYA</strong></template>
                  </v-radio>                                       
                  <v-radio label="UBBER EATS" color="green" :value="3">
                    <template v-slot:label><strong style="color:#000000;">UBBER EATS</strong></template>
                  </v-radio>                
                  </v-radio-group>

                        </v-col>
                        <!-- <v-col   cols="12" md="6" sm="6" v-show="ExistenciaAlmacen.pedidoYa==true"> -->
                  <v-col   cols="12" md="4" sm="4" v-show="ExistenciaAlmacenLink.opcion!=1">
                          
                          <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="generarCsv()" color="green darken-3" small dark>
                    <!-- <i style="font-size: 20px;" class="fa fa-reply-all"> </i> -->
                    GENERAR CSV
                  </v-btn>
                </template>
                <span>GENERAR CSV: genera un archivo csv</span>
              </v-tooltip>
              </v-col>




                      </v-row>


                        </v-col>
                      </v-col>
                      <!-- fn columna 1 -->


                      <!-- columna 2 -->
                      <v-col cols="12" md="6" sm="6">



                        <!-------------------------------------------------- Producto -->
                        <v-combobox autocomplete="off" dense outlined label="Productos:" :items="Rproducto"
                          item-text="codProDesc" @keyup="CbFilProducto" v-model="ExistenciaAlmacen.producto"
                          @blur="SelctCbProduc" @focus="CargarRproducto" no-filter>
                          <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codProDesc = `${item.item.productos.codigo} - ${item.item.productos.descripcion.trim()} ${item.item.unidades.descripcion.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.productos.codigo}}</b> - {{item.item.productos.descripcion.trim()}} <b>{{ item.item.unidades.descripcion.trim()}}</b> </span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('productos')"> <b>{{ item.productos.codigo}}</b> - {{item.productos.descripcion}} {{item.unidades.descripcion}}  </span>
                  </template>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shopping-cart"></i>
                          </template>

                        </v-combobox>



                        <v-combobox v-model="ExistenciaAlmacen.almacen" label="Almacén" :rules="[$rules.required]"
                          outlined dense autocomplete="off" :items="Ralmacen" item-text="descripcion"
                          @blur="SelctCbAlmacen" @focus="CargarRalmacen">
                          <template v-slot:prepend>
                            <v-img height="20px" width="20px" src="../../assets/warehouse.png"></v-img>
                          </template>
                        </v-combobox>

                        <v-text-field label="Letra Final:" maxlength="1" dense autocomplete="off" outlined
                          v-model="ExistenciaAlmacen.letrafin">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sort-alpha-asc"></i>
                          </template>
                        </v-text-field>

                        <!-- -----------------------------  corte  -->
                        <v-text-field id="formdisabledInput" :disabled="!ExistenciaAlmacen.GenExistCorte" dense outlined type="date" label="Fecha de Corte:" 
                          v-model="ExistenciaAlmacen.corte" @blur="BlurCorte">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>
                        </v-text-field>

                        <v-switch class="mt-0" color="teal" inset hide-details label="Generar Exist. Al Corte:"
                          v-model="ExistenciaAlmacen.GenExistCorte">
                          <template v-slot:label>
                            <strong v-if="ExistenciaAlmacen.GenExistCorte == true" style="color:#000000;">Generar Exist. Al Corte: Si</strong>
                            <strong v-if="ExistenciaAlmacen.GenExistCorte == false" style="color:#000000;">Generar Exist. Al Corte: No</strong>
                          </template>
                        </v-switch>
                       
                       
                        <v-switch  color="teal" inset hide-details label="Producto con Existencia:"
                          v-model="ExistenciaAlmacen.ProdExit">
                          <template v-slot:label>
                            <strong v-if="ExistenciaAlmacen.ProdExit == true" style="color:#000000;">Producto con
                              Existencia: Si</strong>
                            <strong v-if="ExistenciaAlmacen.ProdExit == false" style="color:#000000;">Producto con
                              Existencia: No</strong>
                          </template>
                        </v-switch>


                        <v-switch color="teal" inset hide-details label="Producto con Existencia Negativa:"
                          v-model="ExistenciaAlmacen.ProdExitNeg">
                          <template v-slot:label>
                            <strong v-if="ExistenciaAlmacen.ProdExitNeg == true" style="color:#000000;">Producto con
                              Existencia Negativa: Si</strong>
                            <strong v-if="ExistenciaAlmacen.ProdExitNeg == false" style="color:#000000;">Producto con
                              Existencia Negativa: No</strong>
                          </template>
                        </v-switch>

                        


                      </v-col>
                      <!-- fn columna 2 -->

                    </v-row>

                  </v-form>
                </v-container>

                <hr>

                <ModalReporteFiltros ref="ModRepFilt" @DataReporteBasico="DDataReporteBasico"
                  :Datos="ExistenciaAlmacenLink" />


              </v-card>
            </v-container>

          </v-app>
        </td>
        <!-- fn contenido central ------------------------------------------------------------------------------------->
        <!-- BarraSecundaria ------------------------------------------------------------------------------------------>
        <td width="109" :max-height="this.windowSize.height - 96" v-show="BarraSecundariaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{ 'max-height': this.windowSize.height - 96 + 'px', 'max-width': '325px', 'width': '325px' }">

            <!--         
        <BarraBusqueda2
              :url="$hostname + $hName + '/services/caja/findAll'"
              :nombre="'Cajas'"
              :headers="headers"
              :slotname="'item.descripcion'"
              :emitnombre="'emitCaja'"
              :slotlist="slotlist"
              :SeachNombre="'Búsqueda por descripción caja'"
            /> -->
          </div>

        </td>
        <!-- fn BarraSecundaria --------------------------------------------------------------------------------------->



      </tr>
    </table>
    <!-- Modales -------------------------------------------------------------------------------------------------->
    <!-- ------------------------------------------------------ tb-data-->
    <v-dialog label="myAlert" v-model="acti_tb_data" persistent max-width="100%" max-height="100%"
      transition="fab-transition">
      <v-card>
        <v-toolbar flat color="#BDBDBD">
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"></i>
          <v-toolbar-title class="font-weight-light"> REPORTE </v-toolbar-title>
          <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" fab small @click="acti_tb_data = !acti_tb_data">
            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-card>
            <v-card-title>
              <v-text-field v-model="search" @keyup="filtro" label="Buscar" single-line hide-details>
                <template v-slot:prepend-inner>
                  <i style="font-size: 35px;" class="fa fa-search"></i>
                </template>
              </v-text-field>
            </v-card-title>

            <v-data-table :headers="slotHtabla" dense :items="list" :search="search" @click:row="datostabla">
              <template v-slot:item.porciento="props">
                <span>{{ currencyFormatter(props.item.porciento) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Elim_notif-->
    <v-dialog label="myAlert" v-model="Elim_notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACION
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ Elim_notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn @click.prevent="
            eliminar(), (Elim_notif.estado = !Elim_notif.estado)
          " small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>

          <v-btn @click.prevent="Elim_notif.estado = !Elim_notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn Elim_notif -->

    <!------------otras funciones---------------->
    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="120000" top="top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>

    <!-- fn Modales ----------------------------------------------------------------------------------------------->


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import ContenidoBase from "../../components/ContenidoBase";
import ModalReporteFiltros from "../../components/ModalReporteFiltros2";
import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha
} from "@/js/Funciones.js";
export default {
  components: {
    BarraBusqueda2,
    ContenidoBase,
    ModalReporteFiltros
  },
  created() {
    var json = {
      titulo: "Reporte Existencia por Almacén",
      descripcion: "Imprime la Existencia de los Productos por Almacén.",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      btnBarraDi: false,
      accion: [
        "ReportesExistenciaAlmacenNew",
        "ReportesExistenciaAlmacenSave",
        "ReportesExistenciaAlmacenRemove",
        "ReportesExistenciaAlmacenAnular",
        "ReportesExistenciaAlmacenPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {
    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("ReportesExistenciaAlmacenNew", this.new);
    // EventBus.$on("ReportesExistenciaAlmacenSave", this.save);
    // EventBus.$on("ReportesExistenciaAlmacenRemove", this.remove);
    // EventBus.$on("ReportesExistenciaAlmacenAnular", this.remove);
    // EventBus.$on("ReportesExistenciaAlmacenPrintList", this.imprimirList);
    EventBus.$on("emitReportesExistenciaAlmacen", this.datostabla);

    EventBus.$on("onResize", this.onResize);
    this.$refs.ModRepFilt.CrearObjecto = this.validarObjecto;

  },

  data: () => ({

    Rpropietario: [],
    RSucursal: [],
    RCaja: [],


    Rproducto: [],
    RFabricante: [],
    Ralmacen: [],
    Rdepartamento: [],
    ListaTiposClientes: [],

    windowSize: { width: "auto", height: "auto" },


    ExistenciaAlmacenLink: {
      nombre: 'REPORTE EXISTENCIA POR ALMACÉN',
      UrlImprimir: '/reportesInv/analisis-almacen',
      UrlExel: '/reportesInv/excel-analisis-almacen',
      UrlCorreo: '/reportesInv/correo-analisis-almacen',
      opcion:1
    },

    ExistenciaAlmacen: {
      fabricante: null,
      letraIni: null,
      letrafin: null,
      tipoInventario: null,
      caja: null,
      producto: null,
      almacen: null,
      departamento: null,
      Resumen: false,
      balance0: false,
      ExistNegati: false,
      ProdExit: false,
      ProdExitNeg: false,
      GenExistCorte:false,
      pedidoYa:false,
      corte:fechaNueva(),
      //fechaCreacion: new,
      //  fechaModificacion: "", 
    },

    EstadoBtn: {
      BarraTablaDinamicaAprir: false,
      nuevo: false,
      guardar: false,
      eliminar: false,
      anular: false,
      imprimir: false,
      vercuenta: false,
      guardartemporal: false,
      restaurartemporal: false,
      importarDato: false,
      cargarProducto: false,
      ImprimirproductoInv: false,
    },



    text: "",
    valid: true,
    BarraTablaDinamicaAprir: false,
    BarraSecundariaAprir: false,

    headers: [{ text: "Tipo Clientes", value: "descripcion" }],

    slotlist: [
      { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
      {
        dt0: "descripcion",
        dt1: null,
        dt2: null,
        dt3: 1,
        dt4: "Descripcion:"
      },
      { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
    ],

    slotHtabla: [
      { text: "Código", value: "codigo" },
      { text: "Descripcion", value: "descripcion" }
    ],

    list: [],
    search: "",
    acti_tb_data: false,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?"
    },

    aalert: {
      estado: false,
      color: null
    },

  }),


  //   destroyed() {
  //     location.reload();
  //   },
  methods: {
  currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,

    onResize(e) {

      console.log("imprii ")

      console.log(e)
      this.windowSize = e


    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },

    datostabla(e) {

      this.ExistenciaAlmacen = e;
      this.acti_tb_data = false
    },
    save() { },
    remove() { },
    eliminar() { },
    anular() { },
    imprimirList() { },

    DDataReporteBasico() { },

    validarObjecto() {

      if (!this.$refs.form.validate()) {
        this.alerta("los campos en rojo son requeridos", "error");
        this.$refs.ModRepFilt.detener = true;
      } else {
        this.$refs.ModRepFilt.detener = false;
      }

      var where = "p.borrado=false and p.activo=true and up.unidadminima = true"
      var s = ""
      //where+=` and fv.fecha >= ' ${new Date(this.VentasProductosClientes.desde).toISOString().substr(0,10)} ' and fv.fecha  <=' ${new Date(this.VentasProductosClientes.hasta).toISOString().substr(0,10)} '`

      if (this.ExistenciaAlmacen.producto != null) {
        where += ` and p.codigo= ${this.ExistenciaAlmacen.producto.productos.codigo}`
        s += `Producto: ${this.ExistenciaAlmacen.producto.productos.descripcion.trim()}     `
      }

      if (this.ExistenciaAlmacen.almacen != null) {
        where += ` and a.codigo= ${this.ExistenciaAlmacen.almacen.codigo}`
        s += `Almacen: ${this.ExistenciaAlmacen.almacen.descripcion.trim()}     `
      }

      if (this.ExistenciaAlmacen.departamento != null) {
        where += ` and p.departamento= ${this.ExistenciaAlmacen.departamento.codigo}`
        s += `Departamento: ${this.ExistenciaAlmacen.departamento.descripcion.trim()}     `
      }


      if (this.ExistenciaAlmacen.fabricante != null) {
        where += ` and p.fabricante= ${this.ExistenciaAlmacen.fabricante.codigo}`
        s += `Fabricante: ${this.ExistenciaAlmacen.fabricante.nombre.trim()}     `
      }

      if (this.ExistenciaAlmacen.tipoInventario != null) {

        s += `Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion.trim()}     `
        where += ` and p.tipoinventario= '${this.ExistenciaAlmacen.tipoInventario.value.trim()}'`


        // if(this.ExistenciaAlmacen.tipoInventario.value="T"){s+=`Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion}`}
        // if(this.ExistenciaAlmacen.tipoInventario.value="M"){s+=`Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion}`}
        // if(this.ExistenciaAlmacen.tipoInventario.value="T"){s+=`Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion}`}   
      }


      if (this.ExistenciaAlmacen.letraIni != null && this.ExistenciaAlmacen.letraIni.length > 0) {
        where += ` and  upper(substring(p.descripcion from 1 for 1))>= '${this.ExistenciaAlmacen.letraIni.toUpperCase()}' `
      }

      if (this.ExistenciaAlmacen.letrafin != null && this.ExistenciaAlmacen.letrafin.length > 0) {
        where += ` and upper(substring(p.descripcion from 1 for 1))<= '${this.ExistenciaAlmacen.letrafin.toUpperCase()}' `
      }

      if (this.ExistenciaAlmacen.balance0 == true) { where += ` and b.balance = 0 ` }
      if (this.ExistenciaAlmacen.ExistNegati == true) { where += ` and b.balance/up.factor >=0 ` }
      if (this.ExistenciaAlmacen.ProdExit == true) { where += ` and b.balance/up.factor > 0 ` }
      if (this.ExistenciaAlmacen.ProdExitNeg == true) { where += ` and b.balance/up.factor < 0 ` }

     /* if(this.ExistenciaAlmacen.GenExistCorte==true){
        s += `   Fecha de corte: ${this.ExistenciaAlmacen.corte}`
        where += ` and fecha=<'${this.ExistenciaAlmacen.corte}'` 
         // ExistenciaAlmacen.corte--ExistenciaAlmacen.GenExistCorte
      }*/
      this.ExistenciaAlmacenLink.where = where
      this.ExistenciaAlmacenLink.s = s
      this.ExistenciaAlmacenLink.fecha= this.ExistenciaAlmacen.corte
      this.ExistenciaAlmacenLink.GenExistCorte= this.ExistenciaAlmacen.GenExistCorte
      this.ExistenciaAlmacenLink.pedidoYa= this.ExistenciaAlmacen.pedidoYa
      this.ExistenciaAlmacenLink.almacen= this.ExistenciaAlmacen.almacen
      this.ExistenciaAlmacenLink.ubberEatsOpc='exelUbberEatsInvePrecio' 
    },




    newwnuevo() {
      (this.ExistenciaAlmacen = {
        activo: true,
        borrado: false,
        codigo: 0,
        descripcion: "",
        descripcionOriginal: "",
        fechaCreacion: "",
        fechaModificacion: "",
        usuarioCreacion: "",
        usuarioModificacion: ""
      }),
        EventBus.$emit("actualizaBarraBusqueda2");
    },




    SelctCbTipoInventario() {
      if (this.ExistenciaAlmacen.tipoInventario == null) {

        this.ExistenciaAlmacen.tipoInventario = ''
      }

      if (this.ExistenciaAlmacen.tipoInventario.codigo == undefined) {

        this.alerta("Seleccione un tipo Inventario", "error");
        this.ExistenciaAlmacen.tipoInventario = ''
        return

      }

    },



    CargarRproducto() {

      this.$axios.get(this.$hostname + this.$hName +
        "/unidades-productos/*/false/buscar",
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => { this.Rproducto = res.data });
    },




    CbFilProducto(e) {


      if (e.target.value.length >= 0) {

        this.$axios
          .get(this.$hostname +
            this.$hName +
            "/unidades-productos/" +(e.target.value === "" ? "A" : e.target.value)+ "/false/buscar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => { this.Rproducto = res.data });
      }

    },

    SelctCbProduc() {
      if (this.ExistenciaAlmacen.producto == null) {
        this.ExistenciaAlmacen.producto = "";
      }

      if (this.ExistenciaAlmacen.producto.productos == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.ExistenciaAlmacen.producto = "";
        return;
      }
    },


    CargarRalmacen() {

      this.$axios
        .get(this.$hostname + this.$hName + "/almacen/activos",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then(res => {
          this.Ralmacen = res.data;
        });


    },

    SelctCbAlmacen() {
      if (this.ExistenciaAlmacen.almacen == null) {
        this.ExistenciaAlmacen.almacen = "";
      }

      if (this.ExistenciaAlmacen.almacen.codigo == undefined) {
        this.alerta("Seleccione un Almacén", "error");
        this.ExistenciaAlmacen.almacen = "";
        return;
      }
    },


    cargarDepartamento() {

      this.$axios
        .get(
          this.$hostname + this.$hName + "/departamento/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.Rdepartamento = res.data)
        .catch(error => {
          if (error.response) {
            this.alerta(error.response.request.responseText, 'error')
          } else {
            this.alerta(error, 'error')
          }
        });
    },


    SelctCbDesc() {
      // producto:null,
      //     departamento:null,

      if (this.ExistenciaAlmacen.departamento == null) {
        this.ExistenciaAlmacen.departamento = "";
      }

      if (this.ExistenciaAlmacen.departamento.codigo == undefined) {
        this.alerta("Seleccione un departamento", "error");
        this.ExistenciaAlmacen.departamento = "";
        return;
      }
    },


    CargarFabricante() {
      this.$axios
        .get(
          this.$hostname + this.$hName + "/fabricante/activo",
          {
            headers: this.$store.getters.GetheadersAxios
          }
        ).then(res => this.RFabricante = res.data)
        .catch()
    },




    SelctCbFabricante() {
      if (this.ExistenciaAlmacen.fabricante == null) {

        this.ExistenciaAlmacen.fabricante = ''
      }

      if (this.ExistenciaAlmacen.fabricante.codigo == undefined) {

        this.alerta("Seleccione un tipo Inventario", "error");
        this.ExistenciaAlmacen.fabricante = ''
        return

      }

    },


    BlurCorte() {

if (this.ExistenciaAlmacen.corte.toString().length == 0) {
  this.ExistenciaAlmacen.corte = this.fechaNueva()
}

},


generarCsv(){

  if(this.ExistenciaAlmacen.ProdExit== false){
    this.alerta("Active el check producto con existencia ", "error");
    return
  }

  if (!this.$refs.form.validate()) {
        this.alerta("los campos en rojo son requeridos", "error");
        return
      } 

      var where = "p.borrado=false and p.activo=true and up.unidadminima = true"
      var s = ""
      //where+=` and fv.fecha >= ' ${new Date(this.VentasProductosClientes.desde).toISOString().substr(0,10)} ' and fv.fecha  <=' ${new Date(this.VentasProductosClientes.hasta).toISOString().substr(0,10)} '`

      if (this.ExistenciaAlmacen.producto != null) {
        where += ` and p.codigo= ${this.ExistenciaAlmacen.producto.productos.codigo}`
        s += `Producto: ${this.ExistenciaAlmacen.producto.productos.descripcion.trim()}     `
      }

      if (this.ExistenciaAlmacen.almacen != null) {
        where += ` and a.codigo= ${this.ExistenciaAlmacen.almacen.codigo}`
        s += `Almacen: ${this.ExistenciaAlmacen.almacen.descripcion.trim()}     `
      }

      if (this.ExistenciaAlmacen.departamento != null) {
        where += ` and p.departamento= ${this.ExistenciaAlmacen.departamento.codigo}`
        s += `Departamento: ${this.ExistenciaAlmacen.departamento.descripcion.trim()}     `
      }


      if (this.ExistenciaAlmacen.fabricante != null) {
        where += ` and p.fabricante= ${this.ExistenciaAlmacen.fabricante.codigo}`
        s += `Fabricante: ${this.ExistenciaAlmacen.fabricante.nombre.trim()}     `
      }

      if (this.ExistenciaAlmacen.tipoInventario != null) {

        s += `Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion.trim()}     `
        where += ` and p.tipoinventario= '${this.ExistenciaAlmacen.tipoInventario.value.trim()}'`


        // if(this.ExistenciaAlmacen.tipoInventario.value="T"){s+=`Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion}`}
        // if(this.ExistenciaAlmacen.tipoInventario.value="M"){s+=`Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion}`}
        // if(this.ExistenciaAlmacen.tipoInventario.value="T"){s+=`Tipo De Inventario: ${this.ExistenciaAlmacen.tipoInventario.descripcion}`}   
      }


      if (this.ExistenciaAlmacen.letraIni != null && this.ExistenciaAlmacen.letraIni.length > 0) {
        where += ` and  upper(substring(p.descripcion from 1 for 1))>= '${this.ExistenciaAlmacen.letraIni.toUpperCase()}' `
      }

      if (this.ExistenciaAlmacen.letrafin != null && this.ExistenciaAlmacen.letrafin.length > 0) {
        where += ` and upper(substring(p.descripcion from 1 for 1))<= '${this.ExistenciaAlmacen.letrafin.toUpperCase()}' `
      }

      if (this.ExistenciaAlmacen.balance0 == true) { where += ` and b.balance = 0 ` }
      if (this.ExistenciaAlmacen.ExistNegati == true) { where += ` and b.balance/up.factor >=0 ` }
      if (this.ExistenciaAlmacen.ProdExit == true) { where += ` and b.balance/up.factor > 0 ` }
      if (this.ExistenciaAlmacen.ProdExitNeg == true) { where += ` and b.balance/up.factor < 0 ` }

     /* if(this.ExistenciaAlmacen.GenExistCorte==true){
        s += `   Fecha de corte: ${this.ExistenciaAlmacen.corte}`
        where += ` and fecha=<'${this.ExistenciaAlmacen.corte}'` 
         // ExistenciaAlmacen.corte--ExistenciaAlmacen.GenExistCorte
      }*/
      this.ExistenciaAlmacenLink.where = where
      this.ExistenciaAlmacenLink.s = s
      this.ExistenciaAlmacenLink.fecha= this.ExistenciaAlmacen.corte
      this.ExistenciaAlmacenLink.GenExistCorte= this.ExistenciaAlmacen.GenExistCorte
      this.ExistenciaAlmacenLink.pedidoYa= this.ExistenciaAlmacen.pedidoYa
      this.ExistenciaAlmacenLink.almacen= this.ExistenciaAlmacen.almacen
  
      this.ReporteExel()



},



ReporteExel() {

// this.Reparaloading = true
EventBus.$emit("loading", true);

var _this=this;
setTimeout(function(){ 

var NOMBRE_ID_PEDIDO= 'EXISTENCIA POR ALMACEN'

if(_this.ExistenciaAlmacenLink.opcion==2){
    NOMBRE_ID_PEDIDO= _this.ExistenciaAlmacen.almacen.hasOwnProperty('idpedidoya')&& _this.ExistenciaAlmacen.almacen.idpedidoya!=null && _this.ExistenciaAlmacen.almacen.idpedidoya.trim().length>0?_this.ExistenciaAlmacen.almacen.idpedidoya:"idpedidoya";
}
if(_this.ExistenciaAlmacenLink.opcion==3){
    NOMBRE_ID_PEDIDO= _this.ExistenciaAlmacen.almacen.hasOwnProperty('idubberEats')&& _this.ExistenciaAlmacen.almacen.idubberEats!=null && _this.ExistenciaAlmacen.almacen.idubberEats.trim().length>0?_this.ExistenciaAlmacen.almacen.idubberEats:"idubberEats";
}

var obj = JSON.parse(JSON.stringify(_this.ExistenciaAlmacenLink))
obj.NOMBRE_ID_PEDIDO=NOMBRE_ID_PEDIDO
obj.tenant = _this.$store.getters.GetheadersAxios["X-TENANT-ID"]

    
if(_this.ExistenciaAlmacenLink.opcion==3){
  var DATA = JSON.parse(JSON.stringify(obj))

  
  setTimeout(function(){
    DATA.NOMBRE_ID_PEDIDO=NOMBRE_ID_PEDIDO+"_inventario"
    DATA.ubberEatsOpc='exelUbberEatsInvePrecio'
    _this.getReportExel(DATA)
   }, 500);
  
   var DATA1 = JSON.parse(JSON.stringify(obj))
   setTimeout(function(){
    DATA1.NOMBRE_ID_PEDIDO=NOMBRE_ID_PEDIDO+"_Producto"
    DATA1.ubberEatsOpc='exelUbberEatsProduc'
    _this.getReportExel(DATA1)
   }, 500);
  
   var DATA2 = JSON.parse(JSON.stringify(obj))
   setTimeout(function(){
    DATA2.NOMBRE_ID_PEDIDO=NOMBRE_ID_PEDIDO+"_promocion"
    DATA2.ubberEatsOpc='exelUbberEatsPromoc'
    _this.getReportExel(DATA2)
   }, 500);



}else{
  _this.getReportExel(obj)
}



  }, 300);

},


getReportExel(DATA){

  var _this=this;
  setTimeout(function(){ 
    _this.$axios
  .post(_this.$hostname + _this.$hName + _this.ExistenciaAlmacenLink.UrlExel, DATA, { headers: _this.$store.getters.GetheadersAxios })
  .then(res => {
    // this.Reparaloading = false
    EventBus.$emit("loading", false);
    // var _this = this;
    // console.log('Ressssspuuuu'),console.log(res.data),this.alerta("Dato guardado correctamente!", "bien")
    setTimeout(function () {
      _this.verExel(res.data,DATA.NOMBRE_ID_PEDIDO)
    }, 2000);


  })
  .catch(error => { 
    let msg = "";
    if (error.response) {
      msg = error.response.data
    } else if (error.request) {
      msg = error.request.data
    } else {
      msg = error.message;
    }
    _this.catchErroExel(msg)
  
  });
  }, 300);


},



verExel(e,NOMBRE_ID_PEDIDO) {
// if (this.ExelGenerado == true) {
 
   //var contentType = 'application/vnd.ms-excel';
var contentType = 'text/csv'
var blob1 = this.b64toBlob(e, contentType);
var blobUrl1 = URL.createObjectURL(blob1);
let anchor = document.createElement('a');
anchor.href = blobUrl1;
anchor.download = NOMBRE_ID_PEDIDO;
anchor.click();
URL.revokeObjectURL(blobUrl1);

},

b64toBlob(b64Data, contentType, sliceSize) {
contentType = contentType || '';
sliceSize = sliceSize || 512;

var byteCharacters = atob(b64Data);
var byteArrays = [];

for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  var slice = byteCharacters.slice(offset, offset + sliceSize);

  var byteNumbers = new Array(slice.length);
  for (var i = 0; i < slice.length; i++) {
    byteNumbers[i] = slice.charCodeAt(i);
  }

  var byteArray = new Uint8Array(byteNumbers);

  byteArrays.push(byteArray);
}

var blob = new Blob(byteArrays, { type: contentType });
return blob;
},

catchErroExel(e){

EventBus.$emit("loading", false) 
if(e.includes("Invalid row number")){
var TXT="Este archivo de Excell contiene mas de 1,048,576 de registros, y no es soportado por Excel. Favor de generar otro rango de fecha."
this.alerta(TXT, "error")
}else{
this.alerta(e, "error")
}
},



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

        this.ejecucion = false;
      }
    },




    actualiza_tb_ver() {
      this.acti_tb_data = !this.acti_tb_data;
      this.filtro();
    },

    async filtro() { }
  }
};
</script>

<style>
</style>
